type supportedBrowser = {
  supported: boolean
  isMobile: boolean
  browser: string
}

declare global {
  interface Window {
    [key: string]: any
  }
}

export const isBrowserSafari = (): boolean => {
  if (
    typeof window === 'undefined' ||
    typeof window?.navigator === 'undefined'
  ) {
    return false
  }

  return (
    window.navigator.vendor &&
    window.navigator.vendor.indexOf('Apple') > -1 &&
    window.navigator.userAgent &&
    window.navigator.userAgent.indexOf('CriOS') == -1 &&
    window.navigator.userAgent.indexOf('FxiOS') == -1
  )
}

export function getBrowserName(window: Window): string {
  if (typeof window.navigator !== 'object') {
    return ''
  }

  if (typeof window.navigator['brave'] !== 'undefined') {
    return 'Brave'
  }

  if (typeof window.navigator['userAgentData'] !== 'undefined') {
    if (Array.isArray(window.navigator['userAgentData'].brands)) {
      const brands = window.navigator['userAgentData'].brands
      for (let i = 0; i < brands.length; i++) {
        switch (brands[i].brand) {
          case 'Google Chrome':
            return 'Chrome'
          case 'Microsoft Edge':
            return 'Edge'
          case 'Opera':
            return 'Opera'
        }
      }
    }
  }

  // Check Firefox
  if (typeof window['InstallTrigger'] !== 'undefined') return 'Firefox'

  return ''
}

export function isSupportedBrowser(): supportedBrowser {
  if (
    typeof window === 'undefined' ||
    typeof window?.navigator === 'undefined'
  ) {
    return { supported: false, isMobile: false, browser: '' }
  }
  try {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
        window.navigator?.userAgent
      ) ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
        window.navigator?.platform
      )

    const browser = getBrowserName(window)

    return {
      supported:
        typeof window?.chrome === 'undefined' || isMobile ? false : true,
      isMobile,
      browser,
    }
  } catch (err) {
    return {
      supported: false,
      isMobile: false,
      browser: '',
    }
  }
}
