import React from 'react'
import clsx from 'clsx'

interface ProgressLinearProps extends React.HTMLAttributes<HTMLDivElement> {
  visible?: boolean
  primaryColor?: string
  secondaryColor?: string
}

const ProgressLinear: React.FC<ProgressLinearProps> = ({
  visible,
  primaryColor = 'bg-orange-500',
  secondaryColor = 'bg-orange-100',
  className,
}) => {
  return (
    <div
      className={clsx(
        'relative h-[2px] overflow-hidden pointer-events-none transition-opacity',
        secondaryColor,
        className,
        {
          'opacity-0': !visible,
          'opacity-100': visible,
        }
      )}
    >
      <div
        className={clsx('absolute h-[2px]', primaryColor)}
        style={{
          animation: 'increase 2s ease-in-out infinite',
        }}
      />
      <div
        className={clsx('absolute h-[2px]', primaryColor)}
        style={{
          animation: 'decrease 2s 0.9s ease-in-out infinite',
        }}
      />
    </div>
  )
}

export default ProgressLinear
