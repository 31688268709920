import noop from 'lodash/noop'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { AuthProvider } from 'firebase/auth'
import { signOut as authSignOut } from '@carrotcart/client-common/lib/firebase'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import useDetectInstagramUA from '@carrotcart/app/hooks/useDetectInstagramUA'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'
import useImpersonateUser from '@carrotcart/app/hooks/useImpersonateUser'

interface ISignupProviderContext {
  authError: string
  canContinueSignup: boolean
  hasClaimedUsername: boolean
  hasSetFullName: boolean
  anonymousAuthUser: boolean
  notLoggedInAndNotManuallySignedOut: boolean
  loggedInOrManuallySignedOut: boolean
  manuallySignedOut: boolean
  setManuallySignedOut: React.Dispatch<React.SetStateAction<boolean>>
  currentProviderId: string
  setCurrentProviderId: Dispatch<SetStateAction<string>>
  currentProviders: AuthProvider[]
  setCurrentProviders: Dispatch<SetStateAction<AuthProvider[]>>
  emailSent: string
  setEmailSent: Dispatch<SetStateAction<string>>
  emailMode: boolean
  setEmailMode: Dispatch<SetStateAction<boolean>>
  signOut: () => Promise<void> | void
  isInstagramBrowser: boolean
}

const defaultContext: ISignupProviderContext = {
  authError: undefined,
  canContinueSignup: false,
  hasClaimedUsername: false,
  hasSetFullName: false,
  anonymousAuthUser: false,
  notLoggedInAndNotManuallySignedOut: false,
  loggedInOrManuallySignedOut: false,
  manuallySignedOut: false,
  setManuallySignedOut: noop,
  currentProviderId: undefined,
  setCurrentProviderId: noop,
  currentProviders: [],
  setCurrentProviders: noop,
  emailSent: undefined,
  setEmailSent: noop,
  emailMode: false,
  setEmailMode: noop,
  signOut: noop,
  isInstagramBrowser: false,
}

export const SignupContext = React.createContext(defaultContext)

export const useSignupContext = (): ISignupProviderContext => {
  return React.useContext<ISignupProviderContext>(SignupContext)
}

const SignupProvider: React.FC = ({ children }) => {
  const { clearOutPersistedAuthUserId } = useAuthUser()
  const {
    authUser,
    authError,
    currentUser,
    isAnAnonymousAuthUser,
    extensionState,
    signOutOfExtension,
  } = useWebAppContext()
  const { setImpersonationSignedOutAt } = useImpersonateUser()

  const isInstagramBrowser = useDetectInstagramUA()

  const [emailMode, setEmailMode] = useState(false)
  const [emailSent, setEmailSent] = useState<string>()
  const [manuallySignedOut, setManuallySignedOut] = useState(false)
  const [currentProviders, setCurrentProviders] =
    useState<AuthProvider[] | undefined>()
  const [currentProviderId, setCurrentProviderId] =
    useState<AuthProvider['providerId']>()

  const canContinueSignup = !!currentUser && extensionState?.installed

  const hasClaimedUsername = currentUser?.has_claimed_username_computed
  const hasSetFullName = currentUser?.has_set_name_computed

  const anonymousAuthUser =
    authUser &&
    (isAnAnonymousAuthUser ||
      (!authUser.email && !authUser.providerData?.[0]?.email))

  // Need to be able to distinquish between logged in
  // and logged out but also if the user clicked to
  // manually log out, in order to show buttons in
  // the correct position
  const notLoggedInAndNotManuallySignedOut =
    (!canContinueSignup || !authUser) && !manuallySignedOut
  const loggedInOrManuallySignedOut = canContinueSignup || manuallySignedOut

  const signOut = useCallback(async () => {
    setImpersonationSignedOutAt(authUser?.uid)

    setCurrentProviders(undefined)
    setCurrentProviderId(undefined)
    setManuallySignedOut(true)
    clearOutPersistedAuthUserId()

    // need to check since `user()` may not be available
    // (happens in Brave browser for some reason)
    if (window.analytics?.user) {
      window.analytics.user().logout()
    }

    await authSignOut()

    if (extensionState.installed && extensionState.signedIn) {
      await signOutOfExtension()
    }
  }, [
    setImpersonationSignedOutAt,
    authUser?.uid,
    clearOutPersistedAuthUserId,
    extensionState.installed,
    extensionState.signedIn,
    signOutOfExtension,
  ])

  return (
    <SignupContext.Provider
      value={{
        authError,
        canContinueSignup,
        hasClaimedUsername,
        hasSetFullName,
        anonymousAuthUser,
        notLoggedInAndNotManuallySignedOut,
        loggedInOrManuallySignedOut,
        manuallySignedOut,
        setManuallySignedOut,
        currentProviders,
        setCurrentProviders,
        currentProviderId,
        setCurrentProviderId,
        emailSent,
        setEmailSent,
        emailMode,
        setEmailMode,
        signOut,
        isInstagramBrowser,
      }}
    >
      {children}
    </SignupContext.Provider>
  )
}

export default SignupProvider
