export enum DefaultFeatureValues {
  On = 'on',
  Off = 'off',
}

export const DEFAULT_EXPERIMENT_TREATMENTS = {
  [DefaultFeatureValues.On]: DefaultFeatureValues.On,
  [DefaultFeatureValues.Off]: DefaultFeatureValues.Off,
}

export enum FeatureFlag {
  // User Splits go below this line
  CollectionAiSuggestions = 'collection_ai_suggestions',
  ProfileSubsTab = 'profile_subs_tab',
  SavedItemsHide = 'saved_items_hide',
  CollectionCollabManagerMode = 'collection_collab_manager_mode',
  DashboardPage = 'dashboard_page',
  DashboardExportSubscribers = 'dashboard_export_subscribers',
  DashboardCollectionCTR = 'dashboard_collection_ctr',
  CollectionLevelStats = 'collection_level_stats',
  SimilarProducts = 'similar_products',
  YoutubeExport = 'youtube_export',
  ExclusiveCollections = 'exclusive_collections',
  SpotifyPlaylist = 'spotify_playlist',
  MoreSocialEmbeds = 'more_social_embeds',
  TiktokCollectionCover = 'tiktok_collection_cover',
  TiktokCollectionCoverAnon = 'tiktok_collection_cover_anon',
  DealHopSavingsLabel = 'deal_hop_savings_label',
  DisplayCommissionValues = 'display_commission_values',
  GoogleSearchResultsCashback = 'google_search_results_cashback',
  AutoCashback = 'auto_cashback',
  PaywallGateTest = 'paywall_gate_test',
  DisableSerpInjectionRaktutenActive = 'disable_serp_injection_raktuten_active',
  DisableSerpInjectionRaktutenAny = 'disable_serp_injection_raktuten_any',
  DealHopSavedItemsTab = 'deal_hop_saved_items_tab',
  TriggerInteractionOnClose = 'trigger_interaction_on_close',

  // Mobile only Splits go below this line
  InAppPayments = 'in_app_payments',
  CashbackModalSafariExt = 'cashback_modal_safari_ext',
  MobileReferrerInvite = 'mobile_referrer_invite',
  EnableSafariExtensionBanner = 'enable_safari_extension_banner',
  TiktokCollectionCoverMobile = 'tiktok_collection_cover_mobile',
  MobileOnboardingSafariDismiss = 'onboarding_safari_dismiss',
  MobileLeaderboard = 'mobile_leaderboard',
  FeaturedTopBlockHome = 'featured_top_block_home',
  RequestAppReview = 'request_app_review',
  MobileEditProfile = 'mobile_edit_profile',
  OnboardingFlowV2 = 'onboarding_flow_v2',
  EnableSafariExtensionScreen = 'enable_safari_extension_screen',
  SafariExtensionPopupContentVariant = 'safari_extension_popup_content_variant',
  CarrotOnCampusOnboardingScreen = 'carrot_on_campus_onboarding_screen',
  InfluencerToIconCollectionType = 'mobile_create_collection_ii_type',
  MobileCartItemNotes = 'mobile_cart_item_notes',
  MobileSubscribe = 'mobile_app_subscribe',
  MobileHighlightShare = 'mobile_highlight_share',
  MobileHighlightCollab = 'mobile_highlight_collab',
  CollectionFeaturedDiscoveryBanner = 'collection_featured_discovery_banner',
  MobileEditCartItem = 'mobile_edit_cart_item',
  MobileCollectionVideoMedia = 'mobile_collection_video_media',
  MobileComments = 'mobile_comments',

  // Extension only Splits go below this line
  CreateCollectionPrompt = 'create_collection_prompt',
  OpenCollectionPage = 'open_collection_page',
  CollectionBudget = 'collection_budget',
  ExtensionArchieTab = 'extension_archie_tab',
  TopShoppersList = 'top_shoppers_list',
  PriceHop = 'price_hop',
  MobileImagePicker = 'mobile_image_picker',
  CashbackExtension = 'cashback_extension',

  // Anonymous Splits go below this line
}

// For any special experiment treatment values other than "on" or "off", add them below
//
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const FeatureFlagOptions: Record<
  FeatureFlag,
  Record<DefaultFeatureValues, DefaultFeatureValues | string>
> = {}
