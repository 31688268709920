import React, {
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  useMemo,
} from 'react'

export type SelectorFn<
  P = Record<string, unknown>,
  OP = Record<string, unknown>
> = (props: OP) => P

const connectToContext = <
  T extends React.ComponentType<React.ComponentProps<T>>,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  P extends Pick<React.ComponentProps<T>, keyof P>
>(
  WrappedComponent: T,
  selector: SelectorFn<P, Omit<React.ComponentProps<T>, keyof P>>
): React.ComponentType<Omit<React.ComponentProps<T>, keyof P>> => {
  return (props) => {
    const selectors = selector(props) as React.ComponentProps<T>

    return useMemo(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      () => <WrappedComponent {...selectors} {...props} />,
      [props, selectors]
    )
  }
}

export const connectToContextAndForwardRef = <
  T extends React.ComponentType<React.ComponentProps<T>>,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  P extends Pick<React.ComponentProps<T>, keyof P>
>(
  WrappedComponent: T,
  selector: SelectorFn<P, Omit<React.ComponentProps<T>, keyof P>>
): ForwardRefExoticComponent<
  PropsWithoutRef<Omit<React.ComponentProps<T>, keyof P>>
> => {
  return forwardRef<any, Omit<React.ComponentProps<T>, keyof P>>(
    (props, ref) => {
      const selectors = selector(props) as React.ComponentProps<T>

      return useMemo(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        () => <WrappedComponent {...selectors} {...props} ref={ref} />,
        [props, selectors, ref]
      )
    }
  )
}

export default connectToContext
