import Link from 'next/link'
import Image from 'next/legacy/image'

const IntlCTA: React.FC = () => {
  return (
    <div className="flex justify-center items-center text-black text-opacity-75 text-sm space-x-1">
      <span className="text-xl mr-1">
        <Image src="/images/us-flag.png" alt="US Flag" width={16} height={16} />
      </span>
      Not in the US?{' '}
      <Link
        href="/international"
        target="_blank"
        rel="noreferrer"
        className="font-semibold hover:underline"
      >
        Join waitlist
      </Link>
    </div>
  )
}

export default IntlCTA
