import { Logger } from 'pino'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient, Client as GqlWsClient } from 'graphql-ws'
import { onAuthStateChanged } from '@firebase/auth'
import {
  ApolloClient,
  NormalizedCacheObject,
  ApolloClientOptions,
  ApolloCache,
} from '@apollo/client'
import {
  initClientConfig,
  ClientOptions,
} from '@carrotcart/common/lib/apolloClient'
import { GRAPHQL_WS_ENDPOINT } from '@carrotcart/common/lib/constants'
import type { GenericStorage } from '@carrotcart/client-common/types'
import auth from '@carrotcart/client-common/lib/firebase/auth'
import { init as initBillingMode } from '@carrotcart/client-common/lib/billingMode'
import { authHeaders, authLink, shouldRetryError } from './links'

const isBrowserOrReactNative =
  typeof window !== 'undefined' ||
  (typeof navigator !== 'undefined' && navigator.product === 'ReactNative')

export class WrappingApolloClient<
  TCacheShape
> extends ApolloClient<TCacheShape> {
  private _authorized: boolean
  private gqlWsClient?: GqlWsClient

  constructor(
    options: ApolloClientOptions<TCacheShape>,
    gqlWsClient?: GqlWsClient
  ) {
    super(options)
    this._authorized = false
    this.gqlWsClient = gqlWsClient
    // Toggle the authorized state of the client based on the auth state changes
    // from firebase
    onAuthStateChanged(auth, async (authUser) => {
      this._authorized = !!authUser
      this.gqlWsClient?.terminate()
    })
  }

  public get authorized(): boolean {
    return this._authorized
  }
}

const initClient = (
  logger: Logger,
  cache: ApolloCache<NormalizedCacheObject>,
  storage?: GenericStorage
): WrappingApolloClient<NormalizedCacheObject> => {
  initBillingMode(storage)

  const options: ClientOptions = {
    cache,
    shouldRetryError,
    logger,
  }

  let gqlWsClient: GqlWsClient | undefined
  if (isBrowserOrReactNative) {
    gqlWsClient = createClient({
      url: GRAPHQL_WS_ENDPOINT,
      connectionParams: async () => {
        const authUser = auth.currentUser
        const headers = authUser ? await authHeaders({ authUser }) : {}
        return { headers }
      },
    })
    options.wsLink = new GraphQLWsLink(gqlWsClient)

    options.authLink = authLink()
  }

  return new WrappingApolloClient(initClientConfig(options), gqlWsClient)
}

export default initClient
