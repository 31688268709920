import { useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  load as loadIntercom,
  boot as bootIntercom,
  update as updateIntercom,
  INTERCOM_APP_ID,
} from '@carrotcart/app/lib/intercom'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'
import useMediaQuery from '@carrotcart/app/hooks/useMediaQuery'
import { useWebAppContext } from './WebAppProvider'
import tailwindConfig from '@carrotcart/app/tailwind.config'

interface Props {
  disabled?: boolean
}

const IntercomProvider: React.FC<Props> = ({ disabled, children }) => {
  const router = useRouter()
  const { authUser } = useAuthUser()
  const { currentUser } = useWebAppContext()

  const isSmallScreen = useMediaQuery(
    `(max-width: ${tailwindConfig.theme.extend.screens.md})`
  )

  useEffect(() => {
    if (disabled) return

    if (currentUser) {
      // created_at as UNIX timestamp (in seconds)
      const createdAt = Math.floor(
        new Date(currentUser.created_at).getTime() / 1000
      )

      loadIntercom()
      bootIntercom({
        app_id: INTERCOM_APP_ID,
        hide_default_launcher: isSmallScreen,
        user_id: authUser?.uid || currentUser.id, // User ID
        name: authUser?.displayName || currentUser.display_name, // Full name
        email: authUser?.email, // Email address
        phone: authUser?.phoneNumber, // Phone number
        created_at: createdAt, // Signup date as a Unix timestamp
        avatar: {
          type: 'avatar',
          image_url: currentUser.picture,
        },
      })
    }
  }, [authUser, currentUser, disabled, isSmallScreen])

  useEffect(() => {
    if (disabled) return

    const handleRouteChange = () => {
      if (typeof window !== 'undefined') {
        updateIntercom()
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [disabled, isSmallScreen, router.events])

  return <>{children}</>
}

export default IntercomProvider
