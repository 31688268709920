import type * as Types from '../../../data/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type CreateFreeCreatorSubscriptionMutationVariables = Types.Exact<{
  creatorId: Types.Scalars['String']
}>

export type CreateFreeCreatorSubscriptionMutation = {
  __typename?: 'mutation_root'
  subscribeToCreator: {
    __typename?: 'CreateSubscriptionOutput'
    user_subscription?: Types.Maybe<{
      __typename?: 'user_subscription'
      id: any
      user_id: string
      creator_id: string
      subscription_id?: Types.Maybe<string>
    }>
  }
}

export const CreateFreeCreatorSubscriptionDocument = gql`
  mutation CreateFreeCreatorSubscription($creatorId: String!) {
    subscribeToCreator(creatorId: $creatorId) {
      user_subscription {
        id
        user_id
        creator_id
        subscription_id
      }
    }
  }
`
export type CreateFreeCreatorSubscriptionMutationFn = Apollo.MutationFunction<
  CreateFreeCreatorSubscriptionMutation,
  CreateFreeCreatorSubscriptionMutationVariables
>

/**
 * __useCreateFreeCreatorSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateFreeCreatorSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFreeCreatorSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFreeCreatorSubscriptionMutation, { data, loading, error }] = useCreateFreeCreatorSubscriptionMutation({
 *   variables: {
 *      creatorId: // value for 'creatorId'
 *   },
 * });
 */
export function useCreateFreeCreatorSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFreeCreatorSubscriptionMutation,
    CreateFreeCreatorSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateFreeCreatorSubscriptionMutation,
    CreateFreeCreatorSubscriptionMutationVariables
  >(CreateFreeCreatorSubscriptionDocument, options)
}
export type CreateFreeCreatorSubscriptionMutationHookResult = ReturnType<
  typeof useCreateFreeCreatorSubscriptionMutation
>
export type CreateFreeCreatorSubscriptionMutationResult =
  Apollo.MutationResult<CreateFreeCreatorSubscriptionMutation>
export type CreateFreeCreatorSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateFreeCreatorSubscriptionMutation,
    CreateFreeCreatorSubscriptionMutationVariables
  >
