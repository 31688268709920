import { InMemoryCache } from '@apollo/client'
import { offsetLimitPagination } from '@apollo/client/utilities'

const cache = new InMemoryCache({
  // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
  typePolicies: {
    Query: {
      fields: {
        cart: offsetLimitPagination(['where', 'order_by']),
        collection_cart_item: offsetLimitPagination(['where', 'order_by']),
        cart_item: offsetLimitPagination(['where', 'order_by', 'distinct_on']),
        feed_item_like: offsetLimitPagination(['where', 'order_by']),
        shop_item: offsetLimitPagination(['where', 'order_by']),
        comment: offsetLimitPagination(['where', 'order_by']),
        creator_user_subscriptions: offsetLimitPagination([
          'where',
          'order_by',
        ]),
        workflow: {
          // Short for always preferring incoming over existing data
          merge: false, // silences warnings
        },
        collection: offsetLimitPagination(['where', 'order_by']),
        discovery_feed: offsetLimitPagination(),
        notification_center_item: offsetLimitPagination([
          'where',
          ['type', 'read_at'],
        ]),
        notificationCenterItems: offsetLimitPagination(['tasks', 'read']),
        searchProducts: {
          keyArgs: ['query'],
        },
        user_subscription: offsetLimitPagination(['where', 'order_by']),
      },
    },
    user: {
      fields: {
        carts: {
          merge: false, // silences warnings
        },
      },
    },
    cart: {
      fields: {
        cart_items: {
          merge: false, // silences warnings
        },
      },
    },
    collection: {
      fields: {
        collection_items: {
          merge: false, // silences warnings
        },
      },
    },
    user_flags: {
      keyFields: ['user_id'],
    },
    feed_item: {
      merge: false,
    },
  },
})

export default cache
