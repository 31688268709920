import React from 'react'

interface ErrorPageProps {
  statusCode: number
  message: React.ReactNode
}

const ErrorPage: React.FC<ErrorPageProps> = ({ statusCode, message }) => (
  <div className="flex justify-center items-center divide-x-[1px]">
    <div className="px-4 font-medium text-4xl">{statusCode}</div>
    <h1 className="px-4">{message}</h1>
  </div>
)

export default ErrorPage
