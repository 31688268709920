import * as Sentry from '@sentry/core'
import type { Severity } from '@sentry/types'
import { ApolloLink } from '@apollo/client'

const loggingLink = new ApolloLink((operation, forward) => {
  const { operationName, variables, query, extensions } = operation
  operation.setContext({ start: Date.now() })
  const { request_id } = operation.getContext()
  Sentry.addBreadcrumb({
    category: 'gql',
    message: 'GQL operation start',
    level: 'info' as Severity.Info,
    data: {
      operationName,
      variables,
      source: query.loc?.source?.body,
      extensions,
      request_id,
    },
  })

  const observable = forward(operation)
  if (typeof observable.map !== 'function') return observable

  return observable.map((data) => {
    const duration = Date.now() - operation.getContext().start
    Sentry.addBreadcrumb({
      category: 'gql',
      message: 'GQL operation end',
      level: 'info' as Severity.Info,
      data: {
        operationName,
        variables,
        query,
        extensions,
        duration,
        request_id,
      },
    })
    return data
  })
})

export default loggingLink
