import { SVGProps, memo } from 'react'

const MenuCollectionsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={21}
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7.966 20.3-.27-1.33c-.639-3.14-3.093-5.528-6.135-5.973L.273 12.81l1.272-.278c3.003-.657 5.283-3.213 5.699-6.39l.176-1.345.27 1.33c.638 3.139 3.092 5.528 6.135 5.972l1.288.188-1.272.279c-3.003.657-5.283 3.213-5.7 6.39l-.175 1.344ZM13.835 8.2l.041-.662c.099-1.565-.74-3-2.093-3.586l-.574-.248.624.025c1.475.06 2.853-.868 3.444-2.32l.25-.616-.041.663c-.098 1.565.74 3 2.094 3.586l.573.248-.624-.025c-1.475-.06-2.852.868-3.444 2.32l-.25.616Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(MenuCollectionsIcon)
export default Memo
