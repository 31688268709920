import React from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { MenuIcon, SearchIcon } from '@heroicons/react/outline'
import { toggleBillingMode } from '@carrotcart/client-common/lib/billingMode'
import { FeatureFlag } from '@carrotcart/common/lib/split'
import useIsFeatureOn from '@carrotcart/client-common/hooks/useIsFeatureOn'
import useBillingMode from '@carrotcart/app/hooks/useBillingMode'
import useImpersonateUser from '@carrotcart/app/hooks/useImpersonateUser'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import connectToContext, {
  SelectorFn,
} from '@carrotcart/client-common/hoc/connectToContext'
import { useSignupContext } from '@carrotcart/app/context/SignupProvider'
import PictureAvatar from '@carrotcart/app/components/PictureAvatar'
import useBrowserData from '@carrotcart/app/hooks/useBrowserData'
import useCreateAuthUrl from '@carrotcart/app/hooks/useCreateAuthUrl'
import NewCartItemsNotificationDot from '@carrotcart/app/components/LayoutHeader/NewCartItemsNotificationDot'
import ArchieEdgy from '@carrotcart/app/components/svg/ArchieEdgy'
import Toggle from '@carrotcart/app/components/Toggle'
import DropdownMenu from '@carrotcart/app/components/DropdownMenu'
import MenuDashboardIcon from '@carrotcart/app/components/svg/menu/MenuDashboardIcon'
import MenuCollectionsIcon from '@carrotcart/app/components/svg/menu/MenuCollectionsIcon'
import MenuSavedItemsIcon from '@carrotcart/app/components/svg/menu/MenuSavedItemsIcon'
import MenuWalletIcon from '@carrotcart/app/components/svg/menu/MenuWalletIcon'

interface ContextSelectorProps {
  currentUser: ReturnType<typeof useWebAppContext>['currentUser']
  isAdminUser: boolean
  impersonated: boolean
  extensionInstalled?: boolean
  signOut: ReturnType<typeof useSignupContext>['signOut']
}

const useSelector: SelectorFn<ContextSelectorProps> = () => {
  const { currentUser, isAdminUser, extensionState } = useWebAppContext()
  const { signOut } = useSignupContext()
  const { impersonated } = useImpersonateUser()
  const extensionInstalled = extensionState.installed

  return {
    currentUser,
    isAdminUser,
    impersonated,
    extensionInstalled,
    signOut,
  }
}

const UserMenu: React.FC<ContextSelectorProps> = ({
  currentUser,
  isAdminUser,
  impersonated,
  extensionInstalled,
  signOut,
}) => {
  const router = useRouter()
  const browserData = useBrowserData({ ref: router.asPath })
  const { billingMode, toggling } = useBillingMode()
  const { on: dashboardPageEnabled } = useIsFeatureOn(FeatureFlag.DashboardPage)
  const createAuthUrl = useCreateAuthUrl()
  const authUrl = extensionInstalled
    ? createAuthUrl('/signin', { ref: 'header' })
    : createAuthUrl('/signup', { ref: 'header' })

  return (
    <DropdownMenu
      menuItemsClassName="md:top-12 md:right-0"
      trigger={
        <div className="flex items-center cursor-pointer">
          <MenuIcon className="w-6 h-6 mx-1" />
          <div
            className={clsx('relative hidden md:block', {
              'rounded-full ring ring-orange ring-offset': impersonated,
            })}
          >
            <PictureAvatar
              picture={currentUser?.picture}
              size="large"
              rounded
            />
            <div className="absolute top-[-1px] left-[-1px]">
              <NewCartItemsNotificationDot />
            </div>
          </div>
        </div>
      }
    >
      {currentUser ? (
        <>
          {impersonated && (
            <div className="bg-orange-600 -m-2 mb-4 text-white p-2 text-sm font-semibold rounded-t-md text-center">
              User being impersonated
            </div>
          )}

          <DropdownMenu.MenuItem>
            <DropdownMenu.Link
              href={`/${currentUser.username}`}
              className="flex items-center space-x-4 pb-4"
            >
              <div>
                <PictureAvatar
                  picture={currentUser?.picture}
                  size="x-large"
                  rounded
                />
              </div>
              <div>
                <div>{currentUser?.name}</div>
                <div className="text-sm text-gray-400">
                  @{currentUser?.username}
                </div>
                <div className="text-xs text-gray-400 underline">
                  view profile
                </div>
              </div>
            </DropdownMenu.Link>
          </DropdownMenu.MenuItem>

          <hr className="m-2 border-gray-100" />

          <DropdownMenu.MenuItem>
            <DropdownMenu.Link
              href={`/${currentUser.username}#collections/all`}
              className={clsx('flex items-center space-x-2 py-1 text-sm')}
            >
              <div className="w-5">
                <MenuCollectionsIcon />
              </div>
              <span>Collections</span>
            </DropdownMenu.Link>
          </DropdownMenu.MenuItem>

          <DropdownMenu.MenuItem>
            <DropdownMenu.Link
              href={`/${currentUser.username}#items/all`}
              className={clsx('flex items-center space-x-2 py-1 text-sm')}
            >
              <div className="w-5">
                <MenuSavedItemsIcon />
              </div>
              <span>Saved items</span>
              <NewCartItemsNotificationDot />
            </DropdownMenu.Link>
          </DropdownMenu.MenuItem>

          <DropdownMenu.MenuItem>
            <DropdownMenu.Link
              href={`/wallet`}
              className={clsx('flex items-center space-x-2 py-1 text-sm')}
            >
              <div className="w-5">
                <MenuWalletIcon className="w-[15px] text-black" />
              </div>
              <span>Wallet</span>
            </DropdownMenu.Link>
          </DropdownMenu.MenuItem>

          {dashboardPageEnabled && (
            <DropdownMenu.MenuItem>
              <DropdownMenu.Link
                href={`/dashboard`}
                className={clsx('flex items-center space-x-2 py-1 text-sm')}
              >
                <div className="w-5">
                  <MenuDashboardIcon />
                </div>
                <span>Dashboard</span>
              </DropdownMenu.Link>
            </DropdownMenu.MenuItem>
          )}

          <DropdownMenu.MenuItem>
            <DropdownMenu.Link
              href={`/search`}
              className={clsx('flex items-center space-x-2 py-1 text-sm')}
            >
              <div className="w-5">
                <SearchIcon className="w-4 h-4" />
              </div>
              <span>Search</span>
            </DropdownMenu.Link>
          </DropdownMenu.MenuItem>

          <hr className="m-2 border-gray-100" />

          <DropdownMenu.MenuItem>
            <DropdownMenu.Link
              href={`/settings`}
              className={clsx('block py-1 text-sm text-gray-400')}
            >
              Settings
            </DropdownMenu.Link>
          </DropdownMenu.MenuItem>

          {/* <DropdownMenu.MenuItem>
            <DropdownMenu.Link
              href={`/settings#subscriptions`}
              className={clsx('block py-1 text-sm text-gray-400')}
            >
              Manage Subscriptions
            </DropdownMenu.Link>
          </DropdownMenu.MenuItem> */}

          <DropdownMenu.MenuItem>
            <DropdownMenu.Link
              href={`/feedback`}
              className={clsx('block py-1 text-sm text-gray-400')}
              target="_blank"
              rel="noreferrer"
            >
              Feedback
            </DropdownMenu.Link>
          </DropdownMenu.MenuItem>

          <DropdownMenu.MenuItem>
            <DropdownMenu.Link
              href={`https://help.carrot.link`}
              onClick={(e) => {
                e.preventDefault()
                window.Intercom('show')
              }}
              className={clsx('block py-1 text-sm text-gray-400')}
              target="_blank"
              rel="noreferrer"
            >
              Support
            </DropdownMenu.Link>
          </DropdownMenu.MenuItem>
          <DropdownMenu.MenuItem>
            {({ close }) => (
              <DropdownMenu.Link
                href="#sign-out"
                onClick={async (e) => {
                  e.preventDefault()
                  close()
                  await signOut()
                  router.reload()
                }}
                className={clsx('block py-1 text-sm text-gray-400')}
              >
                Sign out
              </DropdownMenu.Link>
            )}
          </DropdownMenu.MenuItem>
        </>
      ) : (
        <DropdownMenu.MenuItem>
          {() => (
            <>
              <DropdownMenu.MenuItem>
                <DropdownMenu.Link
                  href={'/search'}
                  className={clsx('block py-1')}
                >
                  Search
                </DropdownMenu.Link>
              </DropdownMenu.MenuItem>
              <DropdownMenu.MenuItem>
                <DropdownMenu.Link
                  href={'/shop'}
                  className={clsx('block py-1')}
                >
                  Shop
                </DropdownMenu.Link>
              </DropdownMenu.MenuItem>
              <DropdownMenu.MenuItem>
                <DropdownMenu.Link
                  href={'/deals'}
                  className={clsx('block py-1')}
                >
                  Deals
                </DropdownMenu.Link>
              </DropdownMenu.MenuItem>
              <DropdownMenu.MenuItem>
                <DropdownMenu.Link
                  href={'https://blog.carrot.link/?ref=header'}
                  className={clsx('block py-1')}
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </DropdownMenu.Link>
              </DropdownMenu.MenuItem>

              <hr className="m-2 border-gray-100" />

              <DropdownMenu.Link
                href={authUrl}
                className={clsx('block py-1 text-gray-400')}
              >
                <span>{extensionInstalled ? 'Sign In' : 'Sign Up'}</span>
              </DropdownMenu.Link>
            </>
          )}
        </DropdownMenu.MenuItem>
      )}

      <hr className="m-2 border-gray-100" />

      <DropdownMenu.Link
        href={browserData.linkPath}
        className="text-purple font-semibold"
        target="_blank"
        rel="noreferrer"
      >
        <div className="flex justify-between">
          <span>Get Carrot&apos;s Extension</span>
          <ArchieEdgy className="text-purple" />
        </div>
      </DropdownMenu.Link>

      <hr className="m-2 border-gray-100" />

      <div className="px-2 py-3 text-xs text-gray-400 flex space-x-4">
        <DropdownMenu.Link href="/" simple>
          About
        </DropdownMenu.Link>
        <DropdownMenu.Link href="/legal/privacy" simple>
          Privacy
        </DropdownMenu.Link>
        <DropdownMenu.Link href="/legal/terms" simple>
          Terms
        </DropdownMenu.Link>
      </div>

      {isAdminUser && (
        <>
          <hr className="m-2 border-gray-100" />
          <div className="flex items-center justify-between px-2 py-2">
            <div className="text-xs font-semibold text-red">
              Billing test mode
            </div>
            <Toggle
              size="sm"
              checkedColor="bg-red"
              checked={billingMode === 'test'}
              onChange={async () => {
                if (toggling) return

                const { updated } = await toggleBillingMode()
                if (updated) window.location.reload()
              }}
            />
          </div>
        </>
      )}
    </DropdownMenu>
  )
}

export default connectToContext(UserMenu, useSelector)
