import { SVGProps, memo } from 'react'

const MenuDashboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.167 3.667h2.5v8.166h-2.5V3.667Zm4.666-3.5h2.334v11.666H4.833V.167ZM9.5 6.833h2.333v5H9.5v-5Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(MenuDashboardIcon)
export default Memo
