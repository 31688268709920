import type * as Types from '../generated/types'

import { gql } from '@apollo/client'
export type BasicUserDataFragment = {
  __typename?: 'user'
  id: string
  name?: Types.Maybe<string>
  display_name?: Types.Maybe<string>
  username?: Types.Maybe<string>
  picture?: Types.Maybe<string>
  verified?: Types.Maybe<boolean>
  user_type?: Types.Maybe<Types.User_Account_Type_Enum>
  shopping_preference?: Types.Maybe<Types.User_Shopping_Preferences_Enum>
  internal_account?: Types.Maybe<boolean>
  is_creator_computed?: Types.Maybe<boolean>
  is_curator_computed?: Types.Maybe<boolean>
  metadata?: Types.Maybe<any>
  created_at?: Types.Maybe<any>
}

export type UserDataFragment = {
  __typename?: 'user'
  bio?: Types.Maybe<string>
  bio_link?: Types.Maybe<string>
  short_id: any
  has_claimed_username_computed?: Types.Maybe<boolean>
  has_set_name_computed?: Types.Maybe<boolean>
  has_picture_set?: Types.Maybe<boolean>
  has_email_set?: Types.Maybe<boolean>
  anonymous?: Types.Maybe<boolean>
  role?: Types.Maybe<any>
  onboarded?: Types.Maybe<boolean>
  has_onboarded?: Types.Maybe<boolean>
  user_points_multiplier?: Types.Maybe<any>
  created_at?: Types.Maybe<any>
  updated_at?: Types.Maybe<any>
  installed_at?: Types.Maybe<any>
  active_cart_items_quantity?: Types.Maybe<any>
  orphaned_at?: Types.Maybe<any>
  orphaned_by?: Types.Maybe<string>
  has_premium_subscription?: Types.Maybe<boolean>
  extension_version?: Types.Maybe<string>
  ios_version?: Types.Maybe<string>
  safari_extension_version?: Types.Maybe<string>
  collections_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Types.Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  flags?: Types.Maybe<{ __typename?: 'user_flags' } & UserFlagsFragment>
  referrer_user?: Types.Maybe<{ __typename?: 'user' } & BasicUserDataFragment>
} & BasicUserDataFragment

export type UserForPushNotificationDataFragment = {
  __typename?: 'user'
  ios_version?: Types.Maybe<string>
  push_notification_tokens: Array<{
    __typename?: 'user_push_notification_token'
    id: any
  }>
} & BasicUserDataFragment

export type UserFlagsFragment = {
  __typename?: 'user_flags'
  user_id: string
  opted_into_cart_onboarding: boolean
  last_cart_onboarding?: Types.Maybe<any>
  checked_history: boolean
  onboarding_state?: Types.Maybe<Types.User_Onboarding_State_Enum>
  features?: Types.Maybe<any>
  discovery_feed_sequential_id?: Types.Maybe<number>
  discovery_feed_sequential_id_last_set_at?: Types.Maybe<any>
  notification_center_last_viewed_sequential_id: number
  can_be_impersonated: boolean
  auto_cashback_enabled?: Types.Maybe<boolean>
  installed_safari_extension?: Types.Maybe<boolean>
  granted_full_host_permissions_current?: Types.Maybe<boolean>
  last_safari_extension_permission_check?: Types.Maybe<any>
}

export type CartDataFragment = {
  __typename?: 'cart'
  id: any
  user_id: string
  shop_key?: Types.Maybe<string>
  created_at: any
  updated_at: any
  cleared_at?: Types.Maybe<any>
  last_cart_item_created_at?: Types.Maybe<any>
  user: { __typename?: 'user'; short_id: any } & BasicUserDataFragment
  shop?: Types.Maybe<{ __typename?: 'shop' } & ShopDataFragment>
}

export type CartItemsDataFragment = {
  __typename?: 'cart'
  cart_items: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type CartItemsDataWithLimitFragment = {
  __typename?: 'cart'
  cart_items: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type CartItemDataFragment = {
  __typename?: 'cart_item'
  id: any
  cart_id: any
  item_id: any
  shop_key: string
  quantity: any
  bookmark: boolean
  in_collection?: Types.Maybe<boolean>
  archived_at?: Types.Maybe<any>
  removed_at?: Types.Maybe<any>
  removed_reason?: Types.Maybe<Types.Remove_Reason_Enum>
  deleted_at?: Types.Maybe<any>
  created_at: any
  updated_at: any
  source?: Types.Maybe<string>
  metadata: any
  categories_override?: Types.Maybe<Array<string>>
  affiliate_redirect_link_override?: Types.Maybe<string>
  item_image_override?: Types.Maybe<string>
  item_title_override?: Types.Maybe<string>
  item_pricing_override?: Types.Maybe<any>
  item_description_override?: Types.Maybe<string>
  item_video_url?: Types.Maybe<string>
  item_video_orientation?: Types.Maybe<string>
  display_title?: Types.Maybe<string>
  description?: Types.Maybe<string>
  pricing?: Types.Maybe<any>
  points?: Types.Maybe<any>
  hidden: boolean
  image?: Types.Maybe<string>
  image_metadata?: Types.Maybe<any>
  creator_rating?: Types.Maybe<any>
  image_color_palette?: Types.Maybe<Array<any>>
  like_count?: Types.Maybe<number>
  commentable_item_join?: Types.Maybe<{
    __typename?: 'commentable_item'
    comments: Array<{
      __typename?: 'comment'
      id: any
      comment: string
      user: {
        __typename?: 'user'
        username?: Types.Maybe<string>
        picture?: Types.Maybe<string>
      }
    }>
    comments_aggregate: {
      __typename?: 'comment_aggregate'
      aggregate?: Types.Maybe<{
        __typename?: 'comment_aggregate_fields'
        count: number
      }>
    }
  }>
  note?: Types.Maybe<{
    __typename?: 'cart_item_note'
    id: any
    note?: Types.Maybe<string>
    private: boolean
  }>
  cart: {
    __typename?: 'cart'
    id: any
    user: { __typename?: 'user' } & BasicUserDataFragment
  }
  shop: { __typename?: 'shop' } & ShopDataFragment
  item: { __typename?: 'shop_item' } & ItemDataFragment
  collection_item_claim?: Types.Maybe<
    { __typename?: 'collection_item_claim' } & CollectionItemClaimDataFragment
  >
  collection_cart_items: Array<{
    __typename?: 'collection_cart_item'
    collection_id: any
  }>
  user?: Types.Maybe<
    Array<{
      __typename?: 'user'
      id: string
      picture?: Types.Maybe<string>
      username?: Types.Maybe<string>
    }>
  >
}

export type PreloadedCollectionCartItemDataFragment = {
  __typename?: 'collection_cart_item'
  id: any
  collection_id: any
  cart_item: {
    __typename?: 'cart_item'
    id: any
    item_image_override?: Types.Maybe<string>
    display_title?: Types.Maybe<string>
    item: {
      __typename?: 'shop_item'
      shop: { __typename?: 'shop' } & ShopDataFragment
    } & ItemDataFragment
    cart: {
      __typename?: 'cart'
      user: { __typename?: 'user'; id: string; username?: Types.Maybe<string> }
    }
  }
}

export type CollectionItemClaimDataFragment = {
  __typename?: 'collection_item_claim'
  id: any
  status: Types.Collection_Item_Claim_Status_Enum
  user: { __typename?: 'user' } & BasicUserDataFragment
}

export type CartItemLikeDataFragment = {
  __typename?: 'cart_item_like'
  id: any
  user_id: string
  cart_item_id: any
  value: number
}

export type CollectionDataFragment = {
  __typename?: 'collection'
  id: any
  user_id?: Types.Maybe<string>
  short_id?: Types.Maybe<any>
  name: any
  description?: Types.Maybe<string>
  slug: any
  state?: Types.Maybe<Types.Collection_State_Enum>
  paid: boolean
  exclusive?: Types.Maybe<boolean>
  computed_is_visible?: Types.Maybe<boolean>
  has_valid_preview_images_computed?: Types.Maybe<boolean>
  private: boolean
  giftlist: boolean
  all: boolean
  collection_type: Types.Collection_Type_Enum
  curation: boolean
  metadata?: Types.Maybe<any>
  bookmarks: boolean
  ogimage_fingerprint_updated?: Types.Maybe<string>
  collection_link?: Types.Maybe<string>
  open_collab_link_computed?: Types.Maybe<string>
  cover_image?: Types.Maybe<string>
  cover_image_cached?: Types.Maybe<string>
  collection_cover_images?: Types.Maybe<Array<string>>
  cart_items_quantity?: Types.Maybe<any>
  cart_items_count?: Types.Maybe<any>
  created_at?: Types.Maybe<any>
  updated_at?: Types.Maybe<any>
  cart_item_max_updated_at?: Types.Maybe<any>
  budget?: Types.Maybe<any>
  has_collaborators: boolean
  path?: Types.Maybe<string>
  is_a_collaborator?: Types.Maybe<boolean>
  seo_fields?: Types.Maybe<any>
  user?: Types.Maybe<
    {
      __typename?: 'user'
      short_id: any
      anonymous?: Types.Maybe<boolean>
    } & BasicUserDataFragment
  >
}

export type CollectionItemsDataFragment = {
  __typename?: 'collection'
  collection_items: Array<{
    __typename?: 'collection_cart_item'
    id: any
    created_at: any
    updated_at: any
    cart_item: {
      __typename?: 'cart_item'
      creator_rating?: Types.Maybe<any>
      feed_item?: Types.Maybe<
        { __typename?: 'feed_item' } & FeedItemDataFragment
      >
      commentable_item_join?: Types.Maybe<{
        __typename?: 'commentable_item'
        comments: Array<{
          __typename?: 'comment'
          id: any
          comment: string
          user: {
            __typename?: 'user'
            username?: Types.Maybe<string>
            picture?: Types.Maybe<string>
          }
        }>
        comments_aggregate: {
          __typename?: 'comment_aggregate'
          aggregate?: Types.Maybe<{
            __typename?: 'comment_aggregate_fields'
            count: number
          }>
        }
      }>
    } & CartItemDataFragment
  }>
}

export type CollectionCollaboratorsDataFragment = {
  __typename?: 'collection'
  collection_collaborators: Array<{
    __typename?: 'collection_collaborator'
    id: any
    manager: boolean
    user: { __typename?: 'user' } & BasicUserDataFragment
  }>
}

export type CollectionAndItemsDataFragment = {
  __typename?: 'collection'
} & CollectionDataFragment &
  CollectionItemsDataFragment &
  CollectionCollaboratorsDataFragment

export type ShopDataFragment = {
  __typename?: 'shop'
  id: any
  key: string
  cart_path?: Types.Maybe<string>
  type?: Types.Maybe<any>
  platform?: Types.Maybe<string>
  name: any
  display_name?: Types.Maybe<string>
  cover_image?: Types.Maybe<string>
  banner_image?: Types.Maybe<string>
  slug: any
  favicon?: Types.Maybe<string>
  created_at?: Types.Maybe<any>
  updated_at?: Types.Maybe<any>
  has_pdp?: Types.Maybe<boolean>
  editorial_shop_logo?: Types.Maybe<string>
  parsed_take_rate?: Types.Maybe<any>
  metadata: any
}

export type AdminCategorizationShopDataFragment = {
  __typename?: 'shop'
  id: any
  key: string
  slug: any
  name: any
  name_override?: Types.Maybe<string>
  display_name?: Types.Maybe<string>
  description?: Types.Maybe<string>
  keywords?: Types.Maybe<Array<string>>
  favicon?: Types.Maybe<string>
  cover_image?: Types.Maybe<string>
  banner_image?: Types.Maybe<string>
  editorial_shop_logo?: Types.Maybe<string>
  shop_faqs?: Types.Maybe<any>
  featured: boolean
  points?: Types.Maybe<any>
  metadata: any
  shop_categorizations: Array<{
    __typename?: 'shop_categorization'
    id: any
    shop_key: string
    weight: number
    curation_score: number
    category: Types.Shop_Category_Enum
    shop_category: { __typename?: 'shop_category'; label?: Types.Maybe<string> }
  }>
}

export type ItemDataFragment = {
  __typename?: 'shop_item'
  id: any
  item_id: string
  shop_key: string
  source?: Types.Maybe<string>
  display_title?: Types.Maybe<string>
  description?: Types.Maybe<string>
  url?: Types.Maybe<string>
  image?: Types.Maybe<string>
  image_highres?: Types.Maybe<string>
  cached_image?: Types.Maybe<string>
  cached_placeholder_image?: Types.Maybe<string>
  image_metadata?: Types.Maybe<any>
  image_color_palette?: Types.Maybe<Array<any>>
  pricing?: Types.Maybe<any>
  parsed_pricing?: Types.Maybe<any>
  points?: Types.Maybe<any>
  metadata?: Types.Maybe<any>
  enrichment_status?: Types.Maybe<Types.Shop_Item_Enrichment_Status_Enum>
  is_inappropriate: boolean
  saved_by_current_user?: Types.Maybe<boolean>
  onboarding_categories?: Types.Maybe<Array<string>>
  categories?: Types.Maybe<Array<string>>
  keywords?: Types.Maybe<Array<string>>
  created_at: any
  updated_at: any
}

export type AffiliateFragment = {
  __typename?: 'affiliate'
  value: string
  meta?: Types.Maybe<{ __typename?: 'affiliate_meta'; data: any }>
}

export type ShopAffiliateFragment = {
  __typename?: 'shop_affiliate'
  affiliate: {
    __typename?: 'affiliate'
    value: string
    meta?: Types.Maybe<{ __typename?: 'affiliate_meta'; data: any }>
  }
}

export type FeedItemDataFragment = {
  __typename?: 'feed_item'
  id: any
  item_id: any
  item_type: Types.Feed_Item_Type_Enum
  in_discovery_backlog?: Types.Maybe<boolean>
  in_discovery_staging?: Types.Maybe<boolean>
  in_discovery_feed?: Types.Maybe<boolean>
  metadata: any
  liked_by_user?: Types.Maybe<boolean>
  like_count?: Types.Maybe<number>
}

export type DiscoveryCollectionCartItemFragment = {
  __typename?: 'collection_cart_item'
  id: any
  cart_item: {
    __typename?: 'cart_item'
    id: any
    created_at: any
    updated_at: any
    archived_at?: Types.Maybe<any>
    deleted_at?: Types.Maybe<any>
    item_image_override?: Types.Maybe<string>
    display_title?: Types.Maybe<string>
    description?: Types.Maybe<string>
    pricing?: Types.Maybe<any>
    creator_rating?: Types.Maybe<any>
    item: { __typename?: 'shop_item' } & ItemDataFragment
    shop: {
      __typename?: 'shop'
      id: any
      key: string
      slug: any
      name: any
      display_name?: Types.Maybe<string>
      favicon?: Types.Maybe<string>
    }
    cart: {
      __typename?: 'cart'
      user: { __typename?: 'user' } & BasicUserDataFragment
    }
    feed_item?: Types.Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
    note?: Types.Maybe<{
      __typename?: 'cart_item_note'
      id: any
      note?: Types.Maybe<string>
      private: boolean
    }>
    commentable_item_join?: Types.Maybe<{
      __typename?: 'commentable_item'
      comments: Array<{
        __typename?: 'comment'
        id: any
        comment: string
        user: {
          __typename?: 'user'
          username?: Types.Maybe<string>
          picture?: Types.Maybe<string>
        }
      }>
      comments_aggregate: {
        __typename?: 'comment_aggregate'
        aggregate?: Types.Maybe<{
          __typename?: 'comment_aggregate_fields'
          count: number
        }>
      }
    }>
  }
}

export type DiscoveryCollectionFragment = {
  __typename?: 'collection'
  id: any
  name: any
  slug: any
  user_id?: Types.Maybe<string>
  description?: Types.Maybe<string>
  cover_image?: Types.Maybe<string>
  cover_image_cached?: Types.Maybe<string>
  collection_cover_images?: Types.Maybe<Array<string>>
  cart_items_count?: Types.Maybe<any>
  has_collaborators: boolean
  followed_by_user?: Types.Maybe<boolean>
  is_a_collaborator?: Types.Maybe<boolean>
  metadata?: Types.Maybe<any>
  updated_at?: Types.Maybe<any>
  created_at?: Types.Maybe<any>
  total_likes_count?: Types.Maybe<any>
  feed_item?: Types.Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
  user?: Types.Maybe<{ __typename?: 'user' } & BasicUserDataFragment>
  collection_items?: Types.Maybe<
    Array<
      {
        __typename?: 'collection_cart_item'
      } & DiscoveryCollectionCartItemFragment
    >
  >
  commentable_item_join?: Types.Maybe<{
    __typename?: 'commentable_item'
    comments: Array<{
      __typename?: 'comment'
      id: any
      comment: string
      user: {
        __typename?: 'user'
        username?: Types.Maybe<string>
        picture?: Types.Maybe<string>
      }
    }>
    comments_aggregate: {
      __typename?: 'comment_aggregate'
      aggregate?: Types.Maybe<{
        __typename?: 'comment_aggregate_fields'
        count: number
      }>
    }
  }>
}

export type FeaturedShopAndFeaturedShopItemsFragment = {
  __typename?: 'featured_shop'
  id: any
  feed_item?: Types.Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
  shop: {
    __typename?: 'shop'
    id: any
    key: string
    name: any
    display_name?: Types.Maybe<string>
    favicon?: Types.Maybe<string>
  } & FirstCartItemFragment
  featured_shop_items: Array<{
    __typename?: 'featured_shop_item'
    id: any
    shop_item?: Types.Maybe<{ __typename?: 'shop_item' } & ItemDataFragment>
  }>
}

export type FirstCartItemFragment = {
  __typename?: 'shop'
  cart_items: Array<{
    __typename?: 'cart_item'
    id: any
    created_at: any
    cart: {
      __typename?: 'cart'
      id: any
      user: { __typename?: 'user' } & BasicUserDataFragment
    }
  }>
}

export type DiscoveryFeedCollectionCartItemCollectionFragment = {
  __typename?: 'collection_cart_item'
  collection: {
    __typename?: 'collection'
    id: any
    name: any
    slug: any
    has_collaborators: boolean
    user?: Types.Maybe<{ __typename?: 'user' } & BasicUserDataFragment>
  }
}

export type DiscoveryFeedFeedItemFragment = {
  __typename?: 'feed_item'
  collection?: Types.Maybe<
    { __typename?: 'collection' } & DiscoveryCollectionFragment
  >
  featured_shop?: Types.Maybe<
    { __typename?: 'featured_shop' } & FeaturedShopAndFeaturedShopItemsFragment
  >
  collection_cart_item?: Types.Maybe<
    {
      __typename?: 'collection_cart_item'
      collection: { __typename?: 'collection' } & DiscoveryCollectionFragment
    } & DiscoveryCollectionCartItemFragment
  >
} & FeedItemDataFragment

export type CollectionInviteFragment = {
  __typename?: 'collection_invite'
  id: any
  invitee_id?: Types.Maybe<string>
  collection_id: any
  status: Types.Collection_Invite_Status_Enum
  invitee?: Types.Maybe<{
    __typename?: 'user'
    id: string
    name?: Types.Maybe<string>
    picture?: Types.Maybe<string>
  }>
  collection_collaborator?: Types.Maybe<{
    __typename?: 'collection_collaborator'
    id: any
    manager: boolean
  }>
}

export type ContactInviteDataFragment = {
  __typename?: 'contact_invite'
  id: any
  contact_info: string
  type: Types.Contact_Invite_Type_Enum
}

export type CollectionCarouselDataFragment = {
  __typename?: 'collection'
  id: any
  slug: any
  name: any
  cart_items_count?: Types.Maybe<any>
  user?: Types.Maybe<{
    __typename?: 'user'
    id: string
    username?: Types.Maybe<string>
    picture?: Types.Maybe<string>
    created_at?: Types.Maybe<any>
  }>
  collection_items?: Types.Maybe<
    Array<{
      __typename?: 'collection_cart_item'
      id: any
      cart_item: {
        __typename?: 'cart_item'
        id: any
        item_image_override?: Types.Maybe<string>
        display_title?: Types.Maybe<string>
        description?: Types.Maybe<string>
        pricing?: Types.Maybe<any>
        item: {
          __typename?: 'shop_item'
          id: any
          image?: Types.Maybe<string>
          image_highres?: Types.Maybe<string>
          cached_image?: Types.Maybe<string>
        }
      }
    }>
  >
}

export type MediaDataFragment = {
  __typename?: 'media_item'
  id: any
  media_url: string
  media_type: Types.Media_Type_Enum
}

export type MediaEnabledItemDataFragment = {
  __typename?: 'media_enabled_item'
  associated_media: Array<{
    __typename?: 'media_items'
    media_id: any
    media: { __typename?: 'media_item' } & MediaDataFragment
  }>
}

export type UserCollectionsDataForProfilePageDataFragment = {
  __typename?: 'collection'
  id: any
  order?: Types.Maybe<any>
  slug: any
  state?: Types.Maybe<Types.Collection_State_Enum>
  paid: boolean
  exclusive?: Types.Maybe<boolean>
  private: boolean
  name: any
  description?: Types.Maybe<string>
  curation: boolean
  collection_type: Types.Collection_Type_Enum
  cover_image?: Types.Maybe<string>
  cover_image_cached?: Types.Maybe<string>
  collection_cover_images?: Types.Maybe<Array<string>>
  collection_link?: Types.Maybe<string>
  created_at?: Types.Maybe<any>
  updated_at?: Types.Maybe<any>
  cart_item_max_updated_at?: Types.Maybe<any>
  total_likes_count?: Types.Maybe<any>
  has_collaborators: boolean
  cart_items_count?: Types.Maybe<any>
  user?: Types.Maybe<{
    __typename?: 'user'
    id: string
    username?: Types.Maybe<string>
    picture?: Types.Maybe<string>
  }>
  collection_collaborators_aggregate: {
    __typename?: 'collection_collaborator_aggregate'
    aggregate?: Types.Maybe<{
      __typename?: 'collection_collaborator_aggregate_fields'
      count: number
    }>
  }
  collection_collaborators: Array<{
    __typename?: 'collection_collaborator'
    id: any
    user: { __typename?: 'user'; picture?: Types.Maybe<string> }
  }>
  collection_items?: Types.Maybe<
    Array<{
      __typename?: 'collection_cart_item'
      id: any
      cart_item: {
        __typename?: 'cart_item'
        id: any
        item_image_override?: Types.Maybe<string>
        item: {
          __typename?: 'shop_item'
          id: any
          image?: Types.Maybe<string>
          image_highres?: Types.Maybe<string>
          cached_image?: Types.Maybe<string>
          image_metadata?: Types.Maybe<any>
          metadata?: Types.Maybe<any>
          is_inappropriate: boolean
        }
      }
    }>
  >
}

export type DealShopDataFragment = {
  __typename?: 'shop'
  id: any
  key: string
  slug: any
  display_name?: Types.Maybe<string>
  favicon?: Types.Maybe<string>
  featured: boolean
  banner_image?: Types.Maybe<string>
  points?: Types.Maybe<any>
}

export type FmtcDataFragment = {
  __typename?: 'fmtc_deal'
  id: any
  title: string
  direct_url: string
  rating?: Types.Maybe<any>
  types?: Types.Maybe<Array<string>>
  code?: Types.Maybe<string>
  start_date: any
  end_date?: Types.Maybe<any>
  shop_key: string
  shop: { __typename?: 'shop' } & DealShopDataFragment
}

export type ShopPopularityWithPointsDataFragment = {
  __typename?: 'shop_popularity_with_points'
  shop?: Types.Maybe<{ __typename?: 'shop' } & DealShopDataFragment>
}

export type BrandDealsCollectionItemDataFragment = {
  __typename?: 'collection_cart_item'
  id: any
  cart_item: {
    __typename?: 'cart_item'
    id: any
    shop_key: string
    display_title?: Types.Maybe<string>
    image?: Types.Maybe<string>
    pricing?: Types.Maybe<any>
    metadata: any
    deal_ends_at?: Types.Maybe<any>
    shop: { __typename?: 'shop' } & DealShopDataFragment
  }
}

export type DealHopGateDataFragment = {
  __typename?: 'deal_hop_gate'
  user_id: string
  deal_hop_gated?: Types.Maybe<any>
  deal_hop_unlocked: boolean
  deal_hop_invited?: Types.Maybe<any>
  deal_hop_gate_pending?: Types.Maybe<any>
  paywall_gated_at?: Types.Maybe<any>
}

export const BasicUserDataFragmentDoc = gql`
  fragment basicUserData on user {
    id
    name
    display_name
    username
    picture
    verified
    user_type
    shopping_preference
    internal_account
    is_creator_computed
    is_curator_computed
    metadata
    created_at
  }
`
export const UserFlagsFragmentDoc = gql`
  fragment userFlags on user_flags {
    user_id
    opted_into_cart_onboarding
    last_cart_onboarding
    checked_history
    onboarding_state
    features
    discovery_feed_sequential_id
    discovery_feed_sequential_id_last_set_at
    notification_center_last_viewed_sequential_id
    can_be_impersonated
    auto_cashback_enabled
    installed_safari_extension
    granted_full_host_permissions_current
    last_safari_extension_permission_check
  }
`
export const UserDataFragmentDoc = gql`
  fragment userData on user {
    ...basicUserData
    bio
    bio_link
    short_id
    has_claimed_username_computed
    has_set_name_computed
    has_picture_set
    has_email_set
    anonymous
    role
    onboarded
    has_onboarded
    user_points_multiplier
    created_at
    updated_at
    installed_at
    active_cart_items_quantity
    orphaned_at
    orphaned_by
    has_premium_subscription
    collections_aggregate(where: { computed_is_visible: { _eq: true } }) {
      aggregate {
        count
      }
    }
    extension_version @include(if: $with_extension_version)
    ios_version @include(if: $with_ios_version)
    safari_extension_version @include(if: $with_safari_extension_version)
    flags {
      ...userFlags
    }
    referrer_user {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
  ${UserFlagsFragmentDoc}
`
export const UserForPushNotificationDataFragmentDoc = gql`
  fragment userForPushNotificationData on user {
    ...basicUserData
    ios_version
    push_notification_tokens: user_push_notification_tokens(
      where: { active: { _eq: true } }
      limit: 1
    ) {
      id
    }
  }
  ${BasicUserDataFragmentDoc}
`
export const ShopDataFragmentDoc = gql`
  fragment shopData on shop {
    id
    key
    cart_path
    type
    platform
    name
    display_name
    cover_image
    banner_image
    slug
    favicon
    created_at
    updated_at
    has_pdp
    editorial_shop_logo
    parsed_take_rate
    metadata
  }
`
export const CartDataFragmentDoc = gql`
  fragment cartData on cart {
    id
    user_id
    shop_key
    created_at
    updated_at
    cleared_at
    last_cart_item_created_at
    user {
      ...basicUserData
      short_id
    }
    shop {
      ...shopData
    }
  }
  ${BasicUserDataFragmentDoc}
  ${ShopDataFragmentDoc}
`
export const ItemDataFragmentDoc = gql`
  fragment itemData on shop_item {
    id
    item_id
    shop_key
    source
    display_title
    description
    url
    image
    image_highres
    cached_image
    cached_placeholder_image
    image_metadata
    image_color_palette
    pricing
    parsed_pricing
    points
    metadata
    enrichment_status
    is_inappropriate
    saved_by_current_user
    onboarding_categories
    categories
    keywords
    created_at
    updated_at
  }
`
export const CollectionItemClaimDataFragmentDoc = gql`
  fragment collectionItemClaimData on collection_item_claim {
    id
    status
    user {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
`
export const CartItemDataFragmentDoc = gql`
  fragment cartItemData on cart_item {
    id
    cart_id
    item_id
    shop_key
    quantity
    bookmark
    in_collection
    archived_at
    removed_at
    removed_reason
    deleted_at
    created_at
    updated_at
    source
    metadata
    categories_override
    affiliate_redirect_link_override
    item_image_override
    item_title_override
    item_pricing_override
    item_description_override
    item_video_url
    item_video_orientation
    display_title
    description
    pricing
    points
    hidden
    image
    image_metadata
    creator_rating
    commentable_item_join {
      comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
        id
        comment
        user {
          username
          picture
        }
      }
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    image_color_palette
    note {
      id
      note
      private
    }
    cart {
      id
      user {
        ...basicUserData
      }
    }
    shop {
      ...shopData
    }
    item {
      ...itemData
    }
    like_count
    collection_item_claim {
      ...collectionItemClaimData
    }
    collection_cart_items {
      collection_id
    }
    user {
      id
      picture
      username
    }
  }
  ${BasicUserDataFragmentDoc}
  ${ShopDataFragmentDoc}
  ${ItemDataFragmentDoc}
  ${CollectionItemClaimDataFragmentDoc}
`
export const CartItemsDataFragmentDoc = gql`
  fragment cartItemsData on cart {
    cart_items(
      limit: 50
      where: { deleted_at: { _is_null: true }, archived_at: { _is_null: true } }
      order_by: { created_at: desc_nulls_last }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`
export const CartItemsDataWithLimitFragmentDoc = gql`
  fragment cartItemsDataWithLimit on cart {
    cart_items(
      limit: $cartItemsLimit
      where: { deleted_at: { _is_null: true }, archived_at: { _is_null: true } }
      order_by: { created_at: desc_nulls_last }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`
export const PreloadedCollectionCartItemDataFragmentDoc = gql`
  fragment preloadedCollectionCartItemData on collection_cart_item {
    id
    collection_id
    cart_item {
      id
      item_image_override
      display_title
      item {
        ...itemData
        shop {
          ...shopData
        }
      }
      cart {
        user {
          id
          username
        }
      }
    }
  }
  ${ItemDataFragmentDoc}
  ${ShopDataFragmentDoc}
`
export const CartItemLikeDataFragmentDoc = gql`
  fragment cartItemLikeData on cart_item_like {
    id
    user_id
    cart_item_id
    value
  }
`
export const CollectionDataFragmentDoc = gql`
  fragment collectionData on collection {
    id
    user_id
    short_id
    name
    description
    slug
    state
    paid
    exclusive
    computed_is_visible
    has_valid_preview_images_computed
    private
    giftlist
    all
    collection_type
    curation
    metadata
    bookmarks
    ogimage_fingerprint_updated
    collection_link
    open_collab_link_computed
    cover_image
    cover_image_cached
    collection_cover_images
    cart_items_quantity
    cart_items_count
    created_at
    updated_at
    cart_item_max_updated_at
    budget
    has_collaborators
    path
    is_a_collaborator
    user {
      ...basicUserData
      short_id
      anonymous
    }
    seo_fields @include(if: $include_seo_fields)
  }
  ${BasicUserDataFragmentDoc}
`
export const FeedItemDataFragmentDoc = gql`
  fragment feedItemData on feed_item {
    id
    item_id
    item_type
    in_discovery_backlog
    in_discovery_staging
    in_discovery_feed
    metadata
    liked_by_user
    like_count
  }
`
export const CollectionItemsDataFragmentDoc = gql`
  fragment collectionItemsData on collection {
    collection_items(
      where: { cart_item: { deleted_at: { _is_null: true } } }
      order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
      limit: $itemLimit
    ) {
      id
      created_at
      updated_at
      cart_item {
        ...cartItemData
        feed_item {
          ...feedItemData
        }
        creator_rating
        commentable_item_join {
          comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
            id
            comment
            user {
              username
              picture
            }
          }
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
  ${CartItemDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`
export const CollectionCollaboratorsDataFragmentDoc = gql`
  fragment collectionCollaboratorsData on collection {
    collection_collaborators {
      id
      manager
      user {
        ...basicUserData
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`
export const CollectionAndItemsDataFragmentDoc = gql`
  fragment collectionAndItemsData on collection {
    ...collectionData
    ...collectionItemsData
    ...collectionCollaboratorsData
  }
  ${CollectionDataFragmentDoc}
  ${CollectionItemsDataFragmentDoc}
  ${CollectionCollaboratorsDataFragmentDoc}
`
export const AdminCategorizationShopDataFragmentDoc = gql`
  fragment adminCategorizationShopData on shop {
    id
    key
    slug
    name
    name_override
    display_name
    description
    keywords
    favicon
    cover_image
    banner_image
    editorial_shop_logo
    shop_faqs
    featured
    points
    metadata
    shop_categorizations {
      id
      shop_key
      weight
      curation_score
      category
      shop_category {
        label
      }
    }
  }
`
export const AffiliateFragmentDoc = gql`
  fragment affiliate on affiliate {
    value
    meta {
      data
    }
  }
`
export const ShopAffiliateFragmentDoc = gql`
  fragment shopAffiliate on shop_affiliate {
    affiliate {
      value
      meta {
        data
      }
    }
  }
`
export const DiscoveryFeedCollectionCartItemCollectionFragmentDoc = gql`
  fragment discoveryFeedCollectionCartItemCollection on collection_cart_item {
    collection {
      id
      name
      slug
      has_collaborators
      user {
        ...basicUserData
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`
export const DiscoveryCollectionCartItemFragmentDoc = gql`
  fragment discoveryCollectionCartItem on collection_cart_item {
    id
    cart_item {
      id
      created_at
      updated_at
      archived_at
      deleted_at
      item_image_override
      display_title
      description
      pricing
      item {
        ...itemData
      }
      shop {
        id
        key
        slug
        name
        display_name
        favicon
      }
      cart {
        user {
          ...basicUserData
        }
      }
      feed_item {
        ...feedItemData
      }
      note {
        id
        note
        private
      }
      creator_rating
      commentable_item_join {
        comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
          id
          comment
          user {
            username
            picture
          }
        }
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
  ${ItemDataFragmentDoc}
  ${BasicUserDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`
export const DiscoveryCollectionFragmentDoc = gql`
  fragment discoveryCollection on collection {
    id
    name
    slug
    user_id
    description
    cover_image
    cover_image_cached
    collection_cover_images
    cart_items_count
    has_collaborators
    followed_by_user
    is_a_collaborator
    metadata
    updated_at
    created_at
    total_likes_count
    feed_item {
      ...feedItemData
    }
    user {
      ...basicUserData
    }
    collection_items: preview_collection_items(limit: $collectionItemsCount) {
      ...discoveryCollectionCartItem
    }
    commentable_item_join {
      comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
        id
        comment
        user {
          username
          picture
        }
      }
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
  ${FeedItemDataFragmentDoc}
  ${BasicUserDataFragmentDoc}
  ${DiscoveryCollectionCartItemFragmentDoc}
`
export const FirstCartItemFragmentDoc = gql`
  fragment firstCartItem on shop {
    cart_items(order_by: { created_at: asc_nulls_last }, limit: 1) {
      id
      created_at
      cart {
        id
        user {
          ...basicUserData
        }
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`
export const FeaturedShopAndFeaturedShopItemsFragmentDoc = gql`
  fragment featuredShopAndFeaturedShopItems on featured_shop {
    id
    feed_item {
      ...feedItemData
    }
    shop {
      id
      key
      name
      display_name
      favicon
      ...firstCartItem
    }
    featured_shop_items {
      id
      shop_item {
        ...itemData
      }
    }
  }
  ${FeedItemDataFragmentDoc}
  ${FirstCartItemFragmentDoc}
  ${ItemDataFragmentDoc}
`
export const DiscoveryFeedFeedItemFragmentDoc = gql`
  fragment discoveryFeedFeedItem on feed_item {
    ...feedItemData
    collection {
      ...discoveryCollection
    }
    featured_shop {
      ...featuredShopAndFeaturedShopItems
    }
    collection_cart_item {
      ...discoveryCollectionCartItem
      collection {
        ...discoveryCollection
      }
    }
  }
  ${FeedItemDataFragmentDoc}
  ${DiscoveryCollectionFragmentDoc}
  ${FeaturedShopAndFeaturedShopItemsFragmentDoc}
  ${DiscoveryCollectionCartItemFragmentDoc}
`
export const CollectionInviteFragmentDoc = gql`
  fragment collectionInvite on collection_invite {
    id
    invitee_id
    collection_id
    status
    invitee {
      id
      name
      picture
    }
    collection_collaborator {
      id
      manager
    }
  }
`
export const ContactInviteDataFragmentDoc = gql`
  fragment contactInviteData on contact_invite {
    id
    contact_info
    type
  }
`
export const CollectionCarouselDataFragmentDoc = gql`
  fragment collectionCarouselData on collection {
    id
    slug
    name
    user {
      id
      username
      picture
      created_at
    }
    cart_items_count
    collection_items: preview_collection_items(limit: $itemLimit) {
      id
      cart_item {
        id
        item_image_override
        display_title
        description
        pricing
        item {
          id
          image
          image_highres
          cached_image
        }
      }
    }
  }
`
export const MediaDataFragmentDoc = gql`
  fragment mediaData on media_item {
    id
    media_url
    media_type
  }
`
export const MediaEnabledItemDataFragmentDoc = gql`
  fragment mediaEnabledItemData on media_enabled_item {
    associated_media {
      media_id
      media {
        ...mediaData
      }
    }
  }
  ${MediaDataFragmentDoc}
`
export const UserCollectionsDataForProfilePageDataFragmentDoc = gql`
  fragment userCollectionsDataForProfilePageData on collection {
    id
    order
    slug
    state
    paid
    exclusive
    private
    name
    description
    state
    curation
    collection_type
    cover_image
    cover_image_cached
    collection_cover_images
    collection_link
    created_at
    updated_at
    cart_item_max_updated_at
    total_likes_count
    user {
      id
      username
      picture
    }
    has_collaborators
    collection_collaborators_aggregate {
      aggregate {
        count
      }
    }
    collection_collaborators(
      order_by: { created_at: desc_nulls_last }
      limit: 4
    ) {
      id
      user {
        picture
      }
    }
    cart_items_count
    collection_items: preview_collection_items(limit: 3) {
      id
      cart_item {
        id
        item_image_override
        item {
          id
          image
          image_highres
          cached_image
          image_metadata
          metadata
          is_inappropriate
        }
      }
    }
  }
`
export const DealShopDataFragmentDoc = gql`
  fragment dealShopData on shop {
    id
    key
    slug
    display_name
    favicon
    featured
    banner_image
    points
  }
`
export const FmtcDataFragmentDoc = gql`
  fragment fmtcData on fmtc_deal {
    id
    title
    direct_url
    rating
    types
    code
    start_date
    end_date
    shop_key
    shop {
      ...dealShopData
    }
  }
  ${DealShopDataFragmentDoc}
`
export const ShopPopularityWithPointsDataFragmentDoc = gql`
  fragment shopPopularityWithPointsData on shop_popularity_with_points {
    shop {
      ...dealShopData
    }
  }
  ${DealShopDataFragmentDoc}
`
export const BrandDealsCollectionItemDataFragmentDoc = gql`
  fragment brandDealsCollectionItemData on collection_cart_item {
    id
    cart_item {
      id
      shop_key
      display_title
      image
      pricing
      metadata
      deal_ends_at
      shop {
        ...dealShopData
      }
    }
  }
  ${DealShopDataFragmentDoc}
`
export const DealHopGateDataFragmentDoc = gql`
  fragment dealHopGateData on deal_hop_gate {
    user_id
    deal_hop_gated
    deal_hop_unlocked
    deal_hop_invited
    deal_hop_gate_pending
    paywall_gated_at
  }
`
