import { useEffect, useRef, useState } from 'react'

const DEFAULT_THRESHOLD = 0

type ScrollDirection = 'up' | 'down'

const useScrollDirection = (threshold = DEFAULT_THRESHOLD): ScrollDirection => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>('up')

  const blocking = useRef(false)
  const prevScrollY = useRef(0)

  useEffect(() => {
    prevScrollY.current = window.pageYOffset

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - prevScrollY.current) >= threshold) {
        const newScrollDirection = scrollY > prevScrollY.current ? 'down' : 'up'

        setScrollDirection(newScrollDirection)

        prevScrollY.current = scrollY > 0 ? scrollY : 0
      }

      blocking.current = false
    }

    const onScroll = () => {
      if (!blocking.current) {
        blocking.current = true
        window.requestAnimationFrame(updateScrollDirection)
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDirection, threshold])

  return scrollDirection
}

export default useScrollDirection
