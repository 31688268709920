import React, { HTMLAttributes } from 'react'
import clsx from 'clsx'
import Image from 'next/legacy/image'
import LandingPageCTAButton from '@carrotcart/app/components/landing/LandingPageCTAButton'
import AppleIcon from '@carrotcart/app/components/svg/AppleIcon'

const AppCTA: React.FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  return (
    <div className={clsx('space-x-2', className)}>
      <div className="relative group">
        <a
          href={
            'https://apps.apple.com/us/app/carrot-shopping/id1605692440?pt=124011140&mt=8&ct=CarrotHeader'
          }
          target="_blank"
          rel="noreferrer"
          className={clsx(
            'inline-flex items-center justify-center h-10 px-4 bg-container text-black hover:bg-black hover:text-white text-sm space-x-2 rounded-full whitespace-nowrap transition-colors'
          )}
        >
          <AppleIcon className="relative top-[-2px]" />
          <span className="pr-[2px]">App</span>
        </a>
        <div className="hidden md:block absolute left-0 top-full pt-4 opacity-0 group-hover:opacity-100 transition-all pointer-events-none group-hover:pointer-events-auto">
          <div className="bg-[#000] p-6 rounded-xl">
            <div className="text-white text-opacity-90 font-semibold text-center">
              Scan to download the app
            </div>
            <Image
              src="/images/footer/qr-code.svg"
              width={250}
              height={250}
              layout="fixed"
            />
          </div>
        </div>
      </div>
      <div className="hidden md:block ml-2">
        <LandingPageCTAButton
          placement="header"
          linkRef="header"
          condensedLabel
          hideIntlLink
          hideAppStoreLink
          className="button-small"
        />
      </div>
    </div>
  )
}

export default AppCTA
