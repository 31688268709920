import { useEffect } from 'react'
import { GoogleAuthProvider } from 'firebase/auth'
import logger from '@carrotcart/client-common/lib/logger/web'
import { signInWithAuthCredential } from '@carrotcart/client-common/lib/firebase/firebaseAuth'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'
import useDetectInstagramUA from '@carrotcart/app/hooks/useDetectInstagramUA'
import { isSupportedBrowser } from '@carrotcart/app/lib/browserSupport'

declare global {
  interface Window {
    google: any
  }
}

const client_id =
  '726338359225-i7d705v8vrnmc8o4ra8b38mqu8pindup.apps.googleusercontent.com'

const GoogleOneTap: React.FC = () => {
  const { authUser, loading: authLoading } = useAuthUser()
  const isInstagramBrowser = useDetectInstagramUA()
  const { isMobile } = isSupportedBrowser()

  useEffect(() => {
    if (isMobile) return
    if (isInstagramBrowser) return
    if (typeof window.google === 'undefined') return
    if (authUser || authLoading) return

    window.google.accounts.id.initialize({
      client_id,
      callback: async ({ credential }) => {
        const authCredential = GoogleAuthProvider.credential(credential)
        const userCreds = await signInWithAuthCredential(authCredential)

        analytics.track('Google One-Tap Login', {
          user_id: userCreds.user.uid,
        })

        // a useEffect in `useAuthCallback` will
        // handle the actual registration
      },
      auto_select: true,
      cancel_on_tap_outside: false,
      itp_support: true,
      context: 'use',
      prompt_parent_id: 'put-google-one-tap-here-plz',
    })

    window.google.accounts.id.prompt((notification) => {
      logger.debug({
        message: 'Google One Tap prompt',
        'context.notification.isDisplayMoment': notification.isDisplayMoment(),
        'context.notification.getNotDisplayedReason':
          notification.getNotDisplayedReason(),
        'context.notification.isSkippedMoment': notification.isSkippedMoment(),
        'context.notification.getSkippedReason':
          notification.getSkippedReason(),
        'context.notification.isDismissedMoment':
          notification.isDismissedMoment(),
        'context.notification.getDismissedReason':
          notification.getDismissedReason(),
      })
    })
  }, [authLoading, authUser, isInstagramBrowser, isMobile])

  return (
    <div
      className="fixed top-6 right-6 z-[99] flex flex-col items-end"
      id="put-google-one-tap-here-plz"
    />
  )
}

export default GoogleOneTap
