import React, { Fragment, useEffect } from 'react'
import clsx from 'clsx'
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import useScrollDirection from '@carrotcart/app/hooks/useScrollDirection'

export interface Props {
  menuItemsClassName?: string
  trigger?: React.ReactNode
  tiggerLabel?: string
  disableOverlay?: boolean
  close?: () => void
}

const DropdownMenuContent: React.FC<Props> = ({
  children,
  menuItemsClassName,
  trigger,
  tiggerLabel,
  disableOverlay,
  close,
}) => {
  const scrollDirection = useScrollDirection()
  const scrollingDown = scrollDirection === 'down'

  useEffect(() => {
    if (scrollingDown && close) {
      close()
    }
  }, [close, scrollingDown])

  return (
    <>
      <Menu.Button as="div" className="flex flex-col w-full items-center">
        {trigger || (
          <button className="flex items-center space-x-1">
            <span className="text-sm">{tiggerLabel}</span>
            <DotsVerticalIcon className="relative w-4 h-4" />
          </button>
        )}
      </Menu.Button>

      <Transition
        className={clsx('fixed inset-0 bg-black bg-opacity-30', {
          'md:hidden': disableOverlay,
        })}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
      />

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 md:scale-95 translate-y-full md:translate-y-0"
        enterTo="transform opacity-100 md:scale-100 translate-y-0"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 md:scale-100 translate-y-0"
        leaveTo="transform opacity-0 md:scale-95 translate-y-full md:translate-y-0"
      >
        <Menu.Items
          className={clsx(
            'fixed bottom-0 inset-x-0 p-2 rounded-t-md md:rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            'md:absolute md:z-10 md:bottom-auto md:inset-x-auto md:w-72',
            menuItemsClassName
          )}
        >
          {children}
        </Menu.Items>
      </Transition>
    </>
  )
}

export default DropdownMenuContent
