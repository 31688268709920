import { SVGProps, memo } from 'react'

const ArchieEdgy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    // height="100%"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M16.19 26.507c-4.514 0-7.48-2.618-7.48-7.073 0-2.21.996-4.268 2.524-5.441-.763-.367-3.323-1.659-4.349-2.286-1.123-.689-1.799-1.216-2.17-1.698s-.486-.824-.507-1.227a1.323 1.323 0 0 1 .317-.971 1.27 1.27 0 0 1 .41-.312c.156-.074.325-.116.497-.121.729-.048 1.374.351 2.462 1.227.6.493 1.172 1.021 1.712 1.581a.044.044 0 0 0 .061.005c.009-.007.015-.018.017-.029s-.001-.023-.007-.033c-.45-.754-1.147-2.018-1.499-3.095-.271-.819-.3-1.405-.136-1.853.092-.238.25-.443.455-.59a1.26 1.26 0 0 1 .697-.237c.486-.012.865.164 1.251.598.46.515.853 1.394 1.291 2.607.675 1.863 1.26 4.496 1.461 5.446a7.321 7.321 0 0 1 2.368-.399c2.219 0 3.614 1.063 3.614 2.474 0 1.604-1.35 2.423-2.863 2.423-1.328 0-2.12-.498-2.12-.498-.077.458-.053.928.07 1.376a3.16 3.16 0 0 0 .639 1.213c.75.866 1.873 1.307 3.311 1.307 3.225 0 5.427-2.379 5.427-5.464.011-.988-.209-1.964-.643-2.847s-1.068-1.646-1.849-2.226C19.53 9.165 17.51 8.732 15.465 8.83h-.033l-.065-.543c-.36-2.76-1.068-4.835-2.126-6.182C12.287.892 10.979.203 9.457.203c-2.44 0-3.99 2.057-3.749 4.644 0 0-.828-.646-2.224-.646C1.438 4.201 0 5.924 0 8.18a4.93 4.93 0 0 0 .9 2.826 8.5 8.5 0 0 0 1.756 1.819 16.17 16.17 0 0 0 2.017 1.336l1.114.626c-1.011 1.58-1.417 3.527-1.417 5.299 0 2.88 1.026 5.484 2.947 7.412 2.194 2.201 5.335 3.142 8.676 3.142 4.064 0 6.886-1.38 6.886-1.38v-4.355a15.548 15.548 0 0 1-6.688 1.604z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(ArchieEdgy)
export default Memo
