/* eslint-disable @typescript-eslint/no-var-requires */
const config = require('@carrotcart/client-common/tailwind.config')

module.exports = {
  darkMode: 'media', // or 'media' or 'class'
  content: [
    '../client-common/**/*.{ts,tsx}',
    './pages/**/*.{ts,tsx}',
    './components/**/*.{ts,tsx}',
  ],
  ...config,
  plugins: [
    ...config.plugins,
    require('@tailwindcss/typography'),
    require('tailwindcss-text-fill-stroke'),
  ],
}
