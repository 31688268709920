import { SVGProps, memo } from 'react'

const CopyLinkSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 37}
    height={props.height || 37}
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.102 24.218a3.31 3.31 0 0 1 0-4.678l3.018-3.018-1.433-1.433-3.018 3.018a5.337 5.337 0 0 0 0 7.544 5.337 5.337 0 0 0 7.544 0l3.018-3.018-1.434-1.433-3.017 3.018a3.31 3.31 0 0 1-4.678 0Zm3.848-2.34 6.035-6.035-1.508-1.509-6.036 6.036 1.509 1.509Zm2.263-11.316-3.018 3.018 1.434 1.433 3.018-3.017a3.31 3.31 0 0 1 4.677 0 3.31 3.31 0 0 1 0 4.677l-3.018 3.018 1.434 1.433 3.018-3.017a5.337 5.337 0 0 0 0-7.545 5.337 5.337 0 0 0-7.545 0Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(CopyLinkSvg)
export default Memo
