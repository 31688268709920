import React, { HTMLAttributes, useEffect, useState } from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Image from 'next/legacy/image'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { Media, MediaContextProvider } from '@carrotcart/app/lib/media'
import Logo from '@carrotcart/app/components/Logo'
import ChromeWebStoreBadge from '@carrotcart/app/components/ChromeWebStoreBadge'
import AppleAppStoreBadge from '@carrotcart/app/components/AppleAppStoreBadge'

type Category = {
  title: string
  href: string
}

const categories: Category[] = [
  { title: 'Arts & Crafts', href: '/categories/arts-crafts' },
  { title: 'Baby & Kids', href: '/categories/baby-kids' },
  { title: 'Beauty & Cosmetics', href: '/categories/beauty-cosmetics' },
  { title: 'Books', href: '/categories/books' },
  { title: 'DIY', href: '/categories/dyi' },
  { title: 'Electronics & Media', href: '/categories/electronics-media' },
  { title: 'Eyewear', href: '/categories/eyewear' },
  { title: 'Fashion & Apparel', href: '/categories/fashion-apparel' },
  { title: 'Food & Beverage', href: '/categories/food-beverage' },
  { title: 'Footwear & Shoes', href: '/categories/footwear-shoes' },
  { title: 'Gaming & Entertainment', href: '/categories/gaming-entertainment' },
  { title: 'Haute Couture', href: '/categories/haute-couture' },
  {
    title: 'Health, Fitness & Nutrition',
    href: '/categories/health-fitness-nutrition',
  },
  { title: 'Home, Kitchen & Decor', href: '/categories/home-kitchen-decor' },
  { title: 'Intimates', href: '/categories/intimates' },
  { title: 'Jewelry', href: '/categories/jewelry' },
  { title: 'Office', href: '/categories/office' },
  // { title: 'Omnishop', href: '/categories/omnishop' },
  { title: 'Outdoor & Adventure', href: '/categories/outdoor-adventure' },
  { title: 'Pets & Animals', href: '/categories/pets-animals' },
  { title: 'Plants & Flowers', href: '/categories/plants-flowers' },
  { title: 'Sleep', href: '/categories/sleep' },
  // { title: 'Social Platform', href: '/categories/social-platform' },
  { title: 'Sports & Athletics', href: '/categories/sports-athletics' },
  {
    title: 'Tobacco & Related Products',
    href: '/categories/tobacco-related-products',
  },
  // { title: 'Travel', href: '/categories/travel' },
  { title: 'Well-being', href: '/categories/wellbeing' },
]

const CategoryLink = ({ category }) => {
  return (
    <Link
      href={`${category.href}?ref=footer`}
      key={category.title}
      className="block text-sm cursor-pointer hover:underline my-2 text-gray-700"
    >
      {category.title}
    </Link>
  )
}

const AppStoreLinks = () => {
  return (
    <div className="mb-6">
      <div className="flex mb-2">
        <AppleAppStoreBadge campaign="CarrotFooter" />
      </div>
      <div className="flex">
        <ChromeWebStoreBadge
          utmParams={{
            utm_source: 'CarrotFooter',
          }}
        />
      </div>
    </div>
  )
}

const Footer: React.FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  const [categoriesToDisplay, setCategoriesToDisplay] =
    useState<Category[] | undefined>()
  const router = useRouter()
  const [pagePath, setPagePath] = useState<string>()

  const limitedCategories = categories.slice(0, 16)

  useEffect(() => {
    if (router.isReady) {
      setPagePath(router.asPath)
    }
  }, [router.isReady, router.asPath])

  return (
    <footer
      className={clsx(
        'border-t-[1px] border-t-gray-200 px-4 py-16 bg-white',
        className
      )}
    >
      <div className="max-w-screen-xl mx-auto">
        <Link
          href="/"
          className="block relative -top-[6px] md:-top-[9px] min-w-[200px]"
        >
          <Logo width={'88px'} className="h-[49px]" />
        </Link>
        <div className="md:flex justify-between">
          <div className="flex basis-1/4">
            <div className="flex-1">
              <div className="font-semibold text-sm mb-4 mt-6">Company</div>
              <div className="text-sm">
                <Link
                  href="/deals?ref=footer"
                  className="block cursor-pointer hover:underline my-2 text-gray-700"
                  target="_blank"
                  rel="noreferrer"
                >
                  Deals
                </Link>
                <Link
                  href="/creator?ref=footer"
                  className="block cursor-pointer hover:underline my-2 text-gray-700"
                  target="_blank"
                  rel="noreferrer"
                >
                  For Creators 👉 💸
                </Link>
                <Link
                  href={`/signup?ref=footer&page=${pagePath}`}
                  className="block cursor-pointer hover:underline my-2 text-gray-700"
                >
                  Sign up
                </Link>
                <Link
                  href={`/signin?ref=footer&page=${pagePath}`}
                  className="block cursor-pointer hover:underline my-2 text-gray-700"
                >
                  Sign in
                </Link>
                <Link
                  href={`https://jobs.polymer.co/carrot?ref=footer&page=${pagePath}`}
                  className="block my-2 text-gray-700"
                >
                  Careers
                </Link>
                <Link
                  href="https://blog.carrot.link?ref=footer"
                  className="block cursor-pointer hover:underline my-2 text-gray-700"
                  target="_blank"
                  rel="noreferrer"
                >
                  Carrot Blog
                </Link>
                <Link
                  href="https://support.carrot.link?ref=footer"
                  className="block cursor-pointer hover:underline my-2 text-gray-700"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAQs
                </Link>
              </div>
              <div className="flex items-center space-x-2 mt-6 mr-2">
                <a
                  href="/carrot"
                  target="_blank"
                  rel="noreferrer"
                  className="p-1 flex justify-center items-center"
                >
                  <Image
                    width={19}
                    height={22}
                    src="/images/footer/ic-social-carrot.svg"
                  />
                </a>
                <a
                  href="https://www.tiktok.com/@carrotdotlink"
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-center items-center p-1"
                >
                  <Image
                    width={18}
                    height={23}
                    src="/images/footer/ic-social-tiktok.svg"
                  />
                </a>
                <a
                  href="https://www.instagram.com/carrotdotlink/"
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-center items-center p-1"
                >
                  <Image
                    width={22}
                    height={22}
                    src="/images/footer/ic-social-instagram.svg"
                  />
                </a>
                <a
                  href="https://twitter.com/carrotdotlink"
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-center items-center p-1"
                >
                  <Image
                    width={22}
                    height={22}
                    src="/images/footer/ic-social-twitter.svg"
                  />
                </a>
              </div>
            </div>
            <div className="block mt-6 md:hidden">
              <AppStoreLinks />
            </div>
          </div>
          <div className="flex-grow md:pr-4">
            <div className="font-semibold text-sm mb-2 mt-6">Shopping</div>
            <div className="columns-2 lg:columns-3">
              <MediaContextProvider disableDynamicMediaQueries>
                <Media lessThan="md">
                  {(categoriesToDisplay || limitedCategories).map(
                    (category, i) => (
                      <CategoryLink category={category} key={category.href} />
                    )
                  )}
                  {!categoriesToDisplay && (
                    <div
                      onClick={() => setCategoriesToDisplay(categories)}
                      className="flex justify-between items-center pr-4"
                    >
                      <div className="text-sm font-semibold cursor-pointer hover:underline text-black">
                        View {categories.length - limitedCategories.length} more
                      </div>
                      <ChevronDownIcon width="18px" color="#444" />
                    </div>
                  )}
                </Media>
                <Media greaterThanOrEqual="md">
                  {categories.map((category, i) => (
                    <CategoryLink category={category} key={category.href} />
                  ))}
                </Media>
              </MediaContextProvider>
            </div>

            <div className="pt-8">
              <div className="text-xs mb-2">
                Copyright © {new Date().getFullYear()} Carrot Cart, Inc., doing
                business as Carrot. All Rights Reserved
              </div>
              <div className="text-xs">
                <Link
                  href="/legal/privacy?ref=footer"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  Privacy Policy
                </Link>
                <Link
                  href="/legal/terms?ref=footer"
                  target="_blank"
                  rel="noreferrer"
                  className="pl-2 underline"
                >
                  Terms of Service
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-endd mt-12">
            <div className="hidden md:block">
              <Image
                src="/images/footer/qr-code.svg"
                width={177}
                height={177}
              />
            </div>
            <div className="hidden md:block">
              <AppStoreLinks />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
