import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import { Media, MediaContextProvider } from '@carrotcart/app/lib/media'
import connectToContext, {
  SelectorFn,
} from '@carrotcart/client-common/hoc/connectToContext'
import useCreateAuthUrl from '@carrotcart/app/hooks/useCreateAuthUrl'
import UserMenu from '@carrotcart/app/components/UserMenu'
import AppCTA from '@carrotcart/app/components/LayoutHeader/AppCTA'
import useScrollDirection from '@carrotcart/app/hooks/useScrollDirection'
import { useScrollPosition } from '@carrotcart/client-common/hooks/useScrollPosition'
import LogoSvg from '@carrotcart/app/components/svg/Logo'
import SearchIcon from '@carrotcart/app/components/svg/SearchIcon'
import Searchbar from '@carrotcart/app/components/LayoutHeader/Searchbar'
import CarrotHeader from '@carrotcart/app/components/LayoutHeader/CarrotHeader'

interface ContextSelectorProps {
  currentUserLoggedIn: boolean
  loadingApp: boolean
  extensionInstalled?: boolean
}

const useSelector: SelectorFn<ContextSelectorProps> = () => {
  const { currentUser, loadingApp, extensionState } = useWebAppContext()
  const extensionInstalled = extensionState.installed

  return {
    currentUserLoggedIn: !!currentUser?.id,
    extensionInstalled,
    loadingApp,
  }
}

interface Props extends ContextSelectorProps {
  showNavLinks?: boolean
  hideProductSearch?: boolean
  showProductSearchOnScroll?: boolean
  hideInstallCTA?: boolean
  isSticky?: boolean
}

const LayoutHeader: React.FC<Props> = ({
  showNavLinks,
  hideProductSearch,
  showProductSearchOnScroll,
  hideInstallCTA,
  loadingApp,
  currentUserLoggedIn,
  extensionInstalled,
  isSticky,
}) => {
  const scrollDirection = useScrollDirection(20)
  const [isAtTop, setIsAtTop] = React.useState(true)
  const createAuthUrl = useCreateAuthUrl()
  const [authUrl, setAuthUrl] = useState<string>('/signup')

  useEffect(() => {
    const authUrlValue = extensionInstalled
      ? createAuthUrl('/signin', { ref: 'header' })
      : createAuthUrl('/signup', { ref: 'header' })

    setAuthUrl(authUrlValue)
  }, [createAuthUrl, extensionInstalled])

  useScrollPosition(({ currPos }) => {
    if (showProductSearchOnScroll && currPos.y > -150) {
      setIsAtTop(true)
    } else {
      setIsAtTop(false)
    }
  })

  return (
    <>
      <div className="md:hidden">
        <CarrotHeader hideLogo />
      </div>
      <header
        className={clsx(
          'bg-white relative z-20 flex items-center justify-between w-full py-6 px-4 md:px-6 mb-4 space-x-4 md:space-x-6 transition-all',
          {
            'sticky top-0 z-50 inset-x-0 transition-transform ': isSticky,
          },
          {
            'border-b border-b-gray-100': isSticky && !isAtTop,
          },
          {
            '-translate-y-full duration-300':
              isSticky && scrollDirection === 'down',
          }
        )}
      >
        {/* Logo */}
        <Link href="/" className="block">
          <LogoSvg width={110} className="relative -top-[7px] md:-top-[8px]" />
        </Link>

        <div
          className={clsx(
            'flex flex-1 items-center justify-end transition-opacity min-h-[40px] space-x-2',
            {
              'opacity-0': loadingApp,
              'opacity-100': !loadingApp,
            }
          )}
        >
          {/* Nav links */}
          {showNavLinks && (
            <div
              className={clsx(
                'flex items-center justify-end md:justify-start text-gray text-sm md:text-base md:space-x-1',
                {
                  'flex-1': hideProductSearch && !showProductSearchOnScroll,
                }
              )}
            >
              <Link
                href="/shop"
                className={clsx(
                  'hidden md:block px-2 hover:underline whitespace-nowrap'
                )}
              >
                Shop
              </Link>
              <Link
                href="/deals"
                className={clsx(
                  'hidden md:block px-2 hover:underline whitespace-nowrap'
                )}
              >
                Deals
              </Link>
              <Link
                href="https://blog.carrot.link/?ref=header"
                target="_blank"
                rel="noreferrer"
                className={clsx(
                  'hidden md:block px-2 hover:underline whitespace-nowrap'
                )}
              >
                Blog
              </Link>
              <Link
                href="/search"
                className={clsx(
                  'hidden md:block px-2 hover:underline whitespace-nowrap'
                )}
              >
                <SearchIcon />
              </Link>
            </div>
          )}

          {/* Searchbar */}
          {(!hideProductSearch || showProductSearchOnScroll) && (
            <div
              className={clsx(
                'flex-1 flex items-center pr-2 md:px-4 transition-all',
                { 'opacity-0': showProductSearchOnScroll && isAtTop }
              )}
            >
              <Searchbar />
            </div>
          )}

          {/* CTAs */}
          {!hideInstallCTA && <AppCTA className="hidden md:flex" />}

          {/* User/Auth */}
          <MediaContextProvider disableDynamicMediaQueries>
            <div className={clsx('flex items-center ml-1 md:ml-2 relative')}>
              <Media lessThan="md">
                <UserMenu />
              </Media>

              <Media greaterThanOrEqual="md">
                {currentUserLoggedIn ? (
                  <UserMenu />
                ) : (
                  <Link
                    href={authUrl}
                    className="flex items-center px-2 md:px-4 md:h-10 md:bg-container md:rounded-full md:hover:bg-container-hover transition-colors whitespace-nowrap text-sm"
                  >
                    <span>{extensionInstalled ? 'Sign In' : 'Sign Up'}</span>
                  </Link>
                )}
              </Media>
            </div>
          </MediaContextProvider>
        </div>
      </header>
    </>
  )
}

export default connectToContext(LayoutHeader, useSelector)
