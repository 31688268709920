import {
  ApolloLink,
  Observable,
  Operation,
  FetchResult,
  RequestHandler,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'

// type Bar = Observable<FetchResult>
type ZenObservableSubscription = ReturnType<
  ReturnType<Parameters<RequestHandler>[1]>['subscribe']
>
type ZenObservableObserver = Parameters<
  ReturnType<Parameters<RequestHandler>[1]>['subscribe']
>
export interface RetryFunctionOptions<TData = { [key: string]: any }> {
  max?: number
  retryIf?: (fetchResult: FetchResult<TData>, operation: Operation) => boolean
}

export interface RetryFunction {
  (count: number, operation: Operation, fetchResult: FetchResult): boolean
}

const delayFor = (count: number) => {
  // If we're jittering, baseDelay is half of the maximum delay for that
  // attempt (and is, on average, the delay we will encounter).
  // If we're not jittering, adjust baseDelay so that the first attempt
  // lines up with initialDelay, for everyone's sanity.
  const baseDelay = 300

  const delay = Math.min(Infinity, baseDelay * 2 ** count)
  // We opt for a full jitter approach for a mostly uniform distribution,
  // but bound it within initialDelay and delay for everyone's sanity.
  return Math.random() * delay
}

export function buildRetryFunction(
  retryOptions?: RetryFunctionOptions
): RetryFunction {
  const defaultMax = 3
  const { retryIf, max: spreadMax } =
    retryOptions || ({} as RetryFunctionOptions)
  const max = typeof spreadMax === 'number' ? spreadMax : defaultMax

  return (count, operation, fetchResult) => {
    if (typeof retryIf !== 'function') return false
    if (count >= max) return false
    return retryIf(fetchResult, operation)
  }
}

const notFoundLink = new ApolloLink((operation, forward) => {
  const definition = getMainDefinition(operation.query)

  if (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  ) {
    return forward(operation)
  }

  const { notFoundOptions } = operation.getContext()
  const retryFn = buildRetryFunction(notFoundOptions)

  return new Observable((observer) => {
    const subs: ZenObservableSubscription[] = []
    let timeout: NodeJS.Timeout | undefined

    const cleanup = () => {
      if (typeof timeout !== 'undefined') {
        clearTimeout(timeout)
        timeout = undefined
      }

      while (subs.length > 0) {
        const sub = subs.pop()
        if (sub) sub.unsubscribe()
      }
    }

    const onComplete: ZenObservableObserver[2] = () => {
      // disable the previous sub from calling complete on observable
      // if retry is in flight.
      if (subs.length === 0) {
        observer.complete()
      }
    }

    const onNext: ZenObservableObserver[0] = (result) => {
      const shouldRetry = retryFn(subs.length, operation, result)
      if (shouldRetry) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        timeout = setTimeout(() => {
          timeout = undefined
          subs.push(
            forward(operation).subscribe({
              next: onNext,
              error: observer.error.bind(observer),
              complete: onComplete,
            })
          )
        }, delayFor(subs.length))
        return
      }

      observer.next(result)
      observer.complete()
    }
    try {
      subs.push(
        forward(operation).subscribe({
          next: onNext,
          error: observer.error.bind(observer),
          complete: onComplete,
        })
      )
    } catch (e) {
      observer.error(e)
    }

    return cleanup
  })
})

export default notFoundLink
