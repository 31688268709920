import { useCallback } from 'react'
import { FindUserQuery, useFindUserQuery } from '@carrotcart/data/generated'
import client from '@carrotcart/app/lib/apolloClient'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'

const useCurrentUser = (): {
  currentUser: FindUserQuery['user'] | null
  loading: boolean
  refetchCurrentUser: () => Promise<void>
} => {
  const { authUser, loading: loadingAuthUser } = useAuthUser()
  const clientAuthorized = !!authUser?.uid

  const {
    data: currentUserData,
    loading: loadingCurrentUser,
    refetch,
  } = useFindUserQuery({
    variables: {
      id: authUser?.uid,
      with_ios_version: true,
      with_extension_version: true,
    },
    fetchPolicy: 'network-only',
    skip: !clientAuthorized,
    client,
  })

  const loading = loadingAuthUser || loadingCurrentUser
  const currentUser = currentUserData?.user

  const refetchCurrentUser = useCallback(async () => {
    await refetch()
  }, [refetch])

  return {
    currentUser,
    loading,
    refetchCurrentUser,
  }
}

export default useCurrentUser
