import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import Link from 'next/link'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import type { BasicUserDataFragment } from '@carrotcart/data/graphql/fragments.generated'
import useSubscribeToCreator from '@carrotcart/app/hooks/useSubscribeToCreator'
import { AnalyticsEventName } from '@carrotcart/common/lib/constants'
import connectToContext, {
  SelectorFn,
} from '@carrotcart/client-common/hoc/connectToContext'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'
import { createProfileUrl } from '@carrotcart/client-common/lib/helpers'
import { Media, MediaContextProvider } from '@carrotcart/app/lib/media'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import useCreateAuthUrl from '@carrotcart/app/hooks/useCreateAuthUrl'
import useScrollDirection from '@carrotcart/app/hooks/useScrollDirection'
import { useSignupWallContext } from '@carrotcart/app/context/SignupWallProvider'
import { usePremiumContentContext } from '@carrotcart/app/context/PremiumContentProvider'
import useIsOwnPage from '@carrotcart/app/hooks/useIsOwnPage'
import CopyLinkSvg from '@carrotcart/app/components/svg/CopyLink'
import VerifiedIcon from '@carrotcart/app/components/svg/VerifiedIcon'
import PictureAvatar from '@carrotcart/app/components/PictureAvatar'
import UserMenu from '@carrotcart/app/components/UserMenu'
import SearchIcon from '@carrotcart/app/components/svg/SearchIcon'
import Searchbar from './Searchbar'
import CarrotHeader from './CarrotHeader'

interface ContextSelectorProps {
  currentUserLoggedIn: boolean
  extensionInstalled: boolean
  loadingApp: boolean
  subscriptionStatus: ReturnType<
    typeof usePremiumContentContext
  >['subscriptionStatus']
  refetchCreatorSubscriptions: ReturnType<
    typeof usePremiumContentContext
  >['refetchCreatorSubscriptions']
  hasBillingAccount: ReturnType<
    typeof usePremiumContentContext
  >['hasBillingAccount']
  loadingSubscriptionStatus: ReturnType<
    typeof usePremiumContentContext
  >['loadingSubscriptionStatus']
}

const useSelector: SelectorFn<ContextSelectorProps> = () => {
  const { persistedAuthUserId } = useAuthUser()
  const { extensionState, loadingApp } = useWebAppContext()
  const {
    subscriptionStatus,
    refetchCreatorSubscriptions,
    hasBillingAccount,
    loadingSubscriptionStatus,
  } = usePremiumContentContext()
  const extensionInstalled = extensionState.installed
  const currentUserLoggedIn = !!persistedAuthUserId

  return {
    currentUserLoggedIn,
    extensionInstalled,
    subscriptionStatus,
    refetchCreatorSubscriptions,
    hasBillingAccount,
    loadingSubscriptionStatus,
    loadingApp,
  }
}

interface Props extends ContextSelectorProps {
  user?: BasicUserDataFragment
  hideTitle?: boolean
  hideSubscribeCTA?: boolean
  copyLink?: string
  titleComponent?: React.ReactNode
}

const SCROLL_THRESHOLD = 40

const LayoutCreatorHeader: React.FC<Props> = ({
  currentUserLoggedIn,
  extensionInstalled,
  subscriptionStatus,
  refetchCreatorSubscriptions,
  hasBillingAccount,
  loadingSubscriptionStatus,
  user,
  hideTitle,
  hideSubscribeCTA,
  copyLink,
  titleComponent,
}) => {
  const router = useRouter()
  const createAuthUrl = useCreateAuthUrl()
  const { openSignupWall } = useSignupWallContext()
  const [authUrl, setAuthUrl] = useState<string>('/signup')

  const scrollDirection = useScrollDirection(SCROLL_THRESHOLD)

  const { isOwnPage } = useIsOwnPage(user?.id)
  const linkToCopy = copyLink || createProfileUrl(user?.username)

  const { subscribeCallback, subscribing } = useSubscribeToCreator()

  const loading = loadingSubscriptionStatus

  const paidSubscription = subscriptionStatus === 'paid'
  const freeSubscription = subscriptionStatus === 'unpaid'
  const notSubscribed = subscriptionStatus === 'none'

  const shouldRedirectToSubscribe =
    hasBillingAccount && (notSubscribed || freeSubscription)

  const signupWallcallback = useCallback(
    async (loggedIn) => {
      if (loggedIn) {
        const resp = await refetchCreatorSubscriptions()
        const notSubscribed =
          resp?.data?.creator?.subscription_status === 'none'
        const hasBillingAccount = resp?.data?.creator?.has_billing_account

        if (notSubscribed) {
          await subscribeCallback(user?.id)
          await refetchCreatorSubscriptions()

          if (hasBillingAccount) {
            router.push(
              `/${user.username}/subscribe?redirect=${encodeURIComponent(
                router.asPath
              )}`
            )
            return
          }
        }
      }
    },
    [
      subscribeCallback,
      router,
      user.username,
      user?.id,
      refetchCreatorSubscriptions,
    ]
  )

  const redirectToSubscribe = useCallback(() => {
    router.push(
      `/${user.username}/subscribe?redirect=${encodeURIComponent(
        router.asPath
      )}`
    )
  }, [router, user.username])

  const subscribeToCreator = async (e) => {
    e.preventDefault()

    analytics.track(AnalyticsEventName.ClickedSubscribeCTA, {
      creator_id: user.id,
      subscription_status: subscriptionStatus,
    })

    if (!currentUserLoggedIn) {
      e.preventDefault()
      openSignupWall({
        type: 'with_custom_callback',
        customTitle: `@${user.username}`,
        customCopy: 'Signup to subscribe and support this creator',
        trackingData: {
          source: 'header',
          trigger: 'subscribe',
        },
        callback: signupWallcallback,
      })
    } else {
      if (notSubscribed) {
        await subscribeCallback(user.id)
        analytics.track(AnalyticsEventName.SubscribedToCreator, {
          creator_id: user.id,
        })
      }

      if (shouldRedirectToSubscribe) {
        redirectToSubscribe()
      } else {
        await refetchCreatorSubscriptions()
      }
    }
  }

  useEffect(() => {
    setAuthUrl(
      extensionInstalled
        ? createAuthUrl('/signin', { ref: 'header' })
        : createAuthUrl('/signup', { ref: 'header' })
    )
  }, [createAuthUrl, extensionInstalled])

  return (
    <>
      <CarrotHeader />
      <header
        className={clsx('sticky top-0 z-50 inset-x-0 transition-transform', {
          '-translate-y-full duration-300': scrollDirection === 'down',
        })}
      >
        <div className="flex items-center justify-between bg-white md:h-[90px] border-b border-b-gray-100 py-5 px-4 md:px-6 space-x-2">
          <div className="lg:flex-1 flex items-center">
            <MediaContextProvider disableDynamicMediaQueries>
              <Link href={`/${user.username}`} className="relative block">
                <>
                  <Media greaterThanOrEqual="md">
                    <PictureAvatar picture={user.picture} size="menu" rounded />
                    {user.verified && (
                      <div className="absolute top-0 -left-2">
                        <VerifiedIcon width={24} />
                      </div>
                    )}
                  </Media>
                  <Media lessThan="md">
                    <PictureAvatar
                      picture={user.picture}
                      size="large"
                      rounded
                    />
                    {user.verified && (
                      <div className="absolute top-[-2px] left-[-4px]">
                        <VerifiedIcon width={18} />
                      </div>
                    )}
                  </Media>
                </>
              </Link>
            </MediaContextProvider>
          </div>

          {!hideTitle && (
            <>
              {titleComponent ? (
                <div className="hidden md:flex flex-1 items-center justify-center max-w-md">
                  {titleComponent}
                </div>
              ) : (
                <div className="flex-1 flex flex-col lg:items-center lg:min-w-fit">
                  <Link
                    href={`/${user.username}?ref=title`}
                    className="block font-semibold text-sm md:text-2xl !leading-tight md:line-clamp-1"
                  >
                    {user.name}
                  </Link>
                  <CopyToClipboard
                    text={linkToCopy}
                    onCopy={() => {
                      toast.info('Link copied!', {
                        hideProgressBar: true,
                        closeOnClick: true,
                      })

                      analytics.track(AnalyticsEventName.CopiedProfileUrl, {
                        source: 'header',
                      })
                    }}
                  >
                    <div
                      className={clsx(
                        'hidden md:flex text-sm text-gray-400 items-center cursor-pointer hover:underline'
                      )}
                    >
                      <div>carrot.link/{user.username}</div>
                      <div className="px-[2px]">
                        <CopyLinkSvg height={24} width={24} className="-m-1" />
                      </div>
                    </div>
                  </CopyToClipboard>
                </div>
              )}
            </>
          )}

          <div
            className={clsx(
              'lg:flex-1 flex items-center justify-end transition-opacity',
              {
                'opacity-0': loading,
                'opacity-100': !loading,
              }
            )}
          >
            <button className="relative hidden md:flex items-center justify-center mr-2 w-10 h-10 bg-container text-gray hover:bg-container-hover hover:text-black transition-colors rounded-full">
              <div className="absolute inset-0 opacity-0">
                <Searchbar detached placeholder="" />
              </div>
              <SearchIcon width={20} />
            </button>

            {!hideSubscribeCTA && !isOwnPage && (
              <button
                type="button"
                disabled={
                  (hasBillingAccount ? paidSubscription : freeSubscription) ||
                  subscribing
                }
                className={clsx(
                  'inline-flex mr-1 md:mr-2 items-center text-sm md:text-base justify-center h-10 px-3 md:px-4 rounded-full whitespace-nowrap transition-all',
                  'bg-skin-primary text-skin-a11y hover:bg-skin-primary-dark'
                )}
                onClick={subscribeToCreator}
              >
                {hasBillingAccount && freeSubscription
                  ? 'Upgrade'
                  : paidSubscription || freeSubscription
                  ? 'Subscribed'
                  : 'Subscribe'}
              </button>
            )}

            <div className="flex items-center relative">
              {currentUserLoggedIn ? (
                <UserMenu />
              ) : (
                <Link
                  href={authUrl}
                  className="hidden md:flex items-center pl-2 md:px-4 md:h-10 md:bg-container md:rounded-full md:hover:bg-container-hover transition-colors whitespace-nowrap text-sm"
                >
                  <span>{extensionInstalled ? 'Sign In' : 'Sign Up'}</span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default connectToContext(LayoutCreatorHeader, useSelector)
