/* eslint-disable @typescript-eslint/no-var-requires */
const defaultTheme = require('tailwindcss/defaultTheme')
const plugin = require('tailwindcss/plugin')

// DEFAULT TAILWIND CONFIG:
// https://unpkg.com/browse/tailwindcss@3.0.2/stubs/defaultConfig.stub.js

function withOpacity(variableName) {
  return ({ opacityValue }) => {
    if (opacityValue !== undefined) {
      return `rgba(var(${variableName}), ${opacityValue})`
    }
    return `rgb(var(${variableName}))`
  }
}

const defaultSans = [
  'SuisseIntl',
  'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
  ...defaultTheme.fontFamily.sans,
]

module.exports = {
  theme: {
    extend: {
      screens: {
        sm: '640px',
        // => @media (min-width: 640px) { ... }

        md: '768px',
        // => @media (min-width: 768px) { ... }

        lg: '1024px',
        // => @media (min-width: 1024px) { ... }

        onboarding: '1080px',
        // => @media (min-width: 1024px) { ... }

        xl: '1280px',
        // => @media (min-width: 1280px) { ... }

        '2xl': '1358px',
        // => @media (min-width: 1358px) { ... }

        xxl: '1440px',
        // => @media (min-width: 1440px) { ... }

        xxxl: '1920px',
        // => @media (min-width: 1920px) { ... }
      },
      spacing: {
        0.5: '2px',
        1: '4px',
        1.5: '6px',
        2: '8px',
        2.5: '10px',
        3: '12px',
        3.5: '14px',
        4: '16px',
        5: '20px',
        6: '24px',
        7: '28px',
        8: '32px',
        9: '36px',
        10: '40px',
        11: '44px',
        12: '48px',
        14: '56px',
        16: '64px',
        20: '80px',
        24: '96px',
        28: '112px',
        32: '128px',
        36: '144px',
        40: '160px',
        44: '176px',
        48: '192px',
        52: '208px',
        56: '224px',
        60: '240px',
        64: '256px',
        72: '288px',
        80: '320px',
        96: '384px',
      },
      fontFamily: {
        sans: defaultSans,
        display: ['ReallySansLarge', defaultSans],
        suisse: ['SuisseIntl', defaultSans],
        hatton: ['Hatton', defaultSans],
        oswald: ['Oswald', defaultSans],
        editorial: ['Editorial', defaultSans],
      },
      fontSize: {
        xxs: '10.4px',
        xs: ['12px', { lineHeight: '16px' }],
        sm: ['14px', { lineHeight: '20px' }],
        base: ['16px', { lineHeight: '24px' }],
        lg: ['18px', { lineHeight: '28px' }],
        xl: ['20px', { lineHeight: '28px' }],
        '2xl': ['24px', { lineHeight: '32px' }],
        '3xl': ['30px', { lineHeight: '36px' }],
        '4xl': ['36px', { lineHeight: '40px' }],
        '5xl': ['48px', { lineHeight: '1' }],
        '6xl': ['60px', { lineHeight: '1' }],
        '7xl': ['72px', { lineHeight: '1' }],
        '8xl': ['96px', { lineHeight: '1' }],
        '9xl': ['128px', { lineHeight: '1' }],
        s1: ['68px', { lineHeight: '75px' }],
        h1: ['36px', { lineHeight: '54px' }],
        h2: ['26px', { lineHeight: '31px' }],
        'body-lg': ['24px', { lineHeight: '36px' }],
        body: ['18px', { lineHeight: '27px' }],
        'body-sm': ['14px', { lineHeight: '21px' }],
        'body-xs': ['12px', { lineHeight: '18px' }],
        button: ['16px', { lineHeight: '24px' }],
        nav: ['14px', { lineHeight: '21px' }],
      },
      lineClamp: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        10: '10',
      },
      lineHeight: {
        3: '12px',
        4: '16px',
        5: '20px',
        6: '24px',
        7: '28px',
        8: '32px',
        9: '36px',
        10: '40px',
      },
      colors: {
        skin: {
          primary: withOpacity('--color-primary'),
          'primary-dark': withOpacity('--color-primary-dark'),
          a11y: withOpacity('--color-a11y'),
        },
        black: '#191919',
        blackblack: '#000000',
        rabbithole: '#303049',
        gold: '#8e6f31',
        placeholder: '#9d9d8f',
        'purple-light': '#ebebff',
        notif: '#d2d2ff',
        gray3: '#babbd7',
        gray5: '#dfdfd9',
        onpagegray: '#676A6D',
        onpagegray2: '#dfdfdf',
        onpagelight: '#F4F5FF',
        container: '#f3f3eb',
        'container-hover': '#e5e5d9',
        cloud: '#dfdfd9',
        white: '#ffffff',
        offwhite: '#fffffa',
        alt: '#f5f5ed',
        slate: '#7b7b75',
        rating: '#FFA858',
        confirm: '#3EB777',
        premium: '#FFF5DD',
        'premium-badge': '#C29C38',
        muted: {
          DEFAULT: '#faf9ff',
        },
        chrome: {
          DEFAULT: '#cfd1fa',
          light: '#ECECFF',
        },
        outline: {
          DEFAULT: '#e2e2ea',
        },
        line: {
          DEFAULT: '#daced0',
        },
        blend: {
          DEFAULT: '#f8f8fc',
          dark: '#e6e6f4',
          bookmark: '#eeeaf0',
        },
        'danger-bg': '#fbe8cc',
        'danger-text': '#ff7233',
        lock: {
          DEFAULT: '#ff7233',
          stroke: '#f2cdc5',
        },
        drop: {
          DEFAULT: '#FF7233',
        },
        gray: {
          DEFAULT: '#63636b',
          50: '#F4F4F4',
          100: '#efeff0',
          200: '#d8d8da',
          300: '#c1c1c4',
          400: '#929297',
          500: '#63636b',
          600: '#595960',
          700: '#4a4a50',
          800: '#3b3b40',
          900: '#313134',
        },
        'gray-alt': {
          DEFAULT: '#525365',
          100: '#F3F4F9',
          200: '#DCDDEC',
          300: '#ACADC7',
          400: '#7A7B93',
          500: '#525365',
          600: '#373847',
          700: '#21222D',
          800: '#14141C',
          900: '#0A0A0F',
        },
        neutral: {
          DEFAULT: '#d0c9bd',
          light: '#f6f2ec',
          50: '#fdfcfc',
          100: '#fafaf8',
          200: '#f3f2ef',
          300: '#ece9e5',
          400: '#ded9d1',
          500: '#d0c9bd',
          600: '#bbb5aa',
          700: '#9c978e',
          800: '#7d7971',
          900: '#66625d',
        },
        beige: {
          DEFAULT: '#F5E5C6',
          50: '#fffefc',
          100: '#fefcf9',
          200: '#fdf9f1',
          300: '#fbf5e8',
          400: '#f8edd7',
          500: '#f5e5c6',
          600: '#ddceb2',
          700: '#b8ac95',
          800: '#938977',
          900: '#787061',
        },
        purple: {
          DEFAULT: '#6066ed',
          50: '#f7f9fb',
          100: '#e3f0fd',
          200: '#c6d8fb',
          300: '#9db5f3',
          400: '#7b8deb',
          500: '#6066ed',
          600: '#524cd7',
          700: '#3f39b8',
          800: '#2b278a',
          900: '#181957',
        },
        orange: {
          DEFAULT: '#EE7A45',
          50: '#FDF2ED',
          100: '#FCE5DB',
          200: '#F8CAB5',
          300: '#F5AF90',
          400: '#F1956A',
          500: '#EE7A45',
          600: '#E65715',
          700: '#B24310',
          800: '#7F300C',
          900: '#4C1C07',
        },
        red: {
          DEFAULT: '#e23f35',
          50: '#fffefe',
          100: '#fce9e7',
          200: '#f5bebb',
          300: '#ef948e',
          400: '#e86962',
          500: '#e23f35',
          600: '#c7261d',
          700: '#9b1e16',
          800: '#6e1510',
          900: '#420d09',
        },
        green: {
          DEFAULT: '#34c759',
          50: '#f1fcf3',
          100: '#dff9e5',
          200: '#c0f2cc',
          300: '#8fe6a4',
          400: '#57d175',
          500: '#34c759',
          600: '#22973f',
          700: '#1e7735',
          800: '#1d5e2e',
          900: '#194e28',
          950: '#082b13',
        },
      },
      ringColor: (theme) => ({
        DEFAULT: theme('colors.purple.500', '#6066ed'),
        ...theme('colors'),
      }),
      borderRadius: {
        none: '0',
        sm: '2px',
        DEFAULT: '4px',
        md: '6px',
        lg: '8px',
        xl: '10px',
        '2xl': '16px',
        '3xl': '25px',
        '4xl': '40px',
        full: '9999px',
      },
      boxShadow: {
        inner: 'inset 0px 1px 2px rgba(0, 0, 0, 0.14)',
      },
      keyframes: {
        jiggle: {
          '0%, 100%': { transform: 'rotate(-1.5deg)' },
          '50%': { transform: 'rotate(1.5deg)' },
        },
        'into-view': {
          '0%': { transform: 'translateX(100%)' },
          '20%': { transform: 'translateX(0)' },
          '80%': { transform: 'translateX(0)' },
          '100%': { transform: 'translateX(100%)' },
        },
        marquee: {
          '0%': { transform: 'translateX(0%)' },
          '100%': { transform: 'translateX(-70%)' },
        },
        'marquee-reverse': {
          '0%': { transform: 'translateX(-70%)' },
          '100%': { transform: 'translateX(0%)' },
        },
        glow: {
          '0%': { boxShadow: '0 0 5px -5px currentColor' },
          '100%': { boxShadow: '0 0 5px 5px currentColor' },
        },
        'fade-in': {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          },
        },
        'fade-out': {
          '0%': {
            opacity: 1,
          },
          '100%': {
            opacity: 0,
          },
        },
      },
      animation: {
        jiggle: 'jiggle 0.2s 3',
        'into-view': 'into-view 3s ease-in-out',
        marquee: 'marquee 20s linear infinite alternate',
        'marquee-reverse': 'marquee-reverse 20s linear infinite alternate',
        glow: 'glow 1s 6 alternate',
        fadeInOut: 'fade-in 2s 2 alternate',
      },
      backgroundSize: {
        125: '125%',
        150: '150%',
      },
      backgroundImage: {
        'gradient-265': 'linear-gradient(265.69deg, var(--tw-gradient-stops))',
        'gradient-118': 'linear-gradient(118.25deg, var(--tw-gradient-stops))',
      },
      maxWidth: {
        0: '0',
        xs: '320px',
        sm: '384px',
        md: '448px',
        lg: '512px',
        xl: '576px',
        '2xl': '672px',
        '3xl': '768px',
        '4xl': '896px',
        '5xl': '1024px',
        '6xl': '1152px',
        '7xl': '1280px',
      },
    },
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/line-clamp'),
    require('tailwindcss-hyphens'),
    require('tailwindcss-animation-delay'),
    require('./vendor/tailwindcss-forms')({
      strategy: 'class',
    }),
    plugin(({ matchUtilities, theme }) => {
      matchUtilities(
        {
          'animation-delay': (value) => {
            return {
              'animation-delay': value,
            }
          },
        },
        {
          values: theme('transitionDelay'),
        }
      )
    }),
  ],
}
