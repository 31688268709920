import type * as Types from '../../data/generated/types'

import type { BasicUserDataFragment } from '../../data/graphql/fragments.generated'
import { gql } from '@apollo/client'
import { BasicUserDataFragmentDoc } from '../../data/graphql/fragments.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type GetCreatorsToSubscribeRandomQueryVariables = Types.Exact<{
  exclude_usernames?: Types.Maybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >
  seed?: Types.Maybe<Types.Scalars['seed_float']>
  limit?: Types.Maybe<Types.Scalars['Int']>
  include_other_creators?: Types.Maybe<Types.Scalars['Boolean']>
}>

export type GetCreatorsToSubscribeRandomQuery = {
  __typename?: 'query_root'
  vip_creators: Array<{ __typename?: 'user' } & BasicUserDataFragment>
  other_creators: Types.Maybe<
    Array<{ __typename?: 'user' } & BasicUserDataFragment>
  >
}

export const GetCreatorsToSubscribeRandomDocument = gql`
  query getCreatorsToSubscribeRandom(
    $exclude_usernames: [String!] = []
    $seed: seed_float = "0.0"
    $limit: Int = 15
    $include_other_creators: Boolean = true
  ) {
    vip_creators: creator_subscribers_random(
      where: {
        _and: [
          { username: { _nin: $exclude_usernames } }
          { username: { _niregex: "(test|carrot4)" } }
          { subscription_status: { _eq: "none" } }
          { icon_order: { _lt: 0 } }
        ]
      }
      args: { seed: $seed }
      limit: $limit
    ) {
      ...basicUserData
    }
    other_creators: creator_subscribers_random(
      where: {
        _and: [
          { username: { _nin: $exclude_usernames } }
          { username: { _niregex: "(test|carrot4)" } }
          { subscription_status: { _eq: "none" } }
          { icon_order: { _gte: 0 } }
        ]
      }
      args: { seed: $seed }
      limit: $limit
    ) @include(if: $include_other_creators) {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetCreatorsToSubscribeRandomQuery__
 *
 * To run a query within a React component, call `useGetCreatorsToSubscribeRandomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatorsToSubscribeRandomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatorsToSubscribeRandomQuery({
 *   variables: {
 *      exclude_usernames: // value for 'exclude_usernames'
 *      seed: // value for 'seed'
 *      limit: // value for 'limit'
 *      include_other_creators: // value for 'include_other_creators'
 *   },
 * });
 */
export function useGetCreatorsToSubscribeRandomQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCreatorsToSubscribeRandomQuery,
    GetCreatorsToSubscribeRandomQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCreatorsToSubscribeRandomQuery,
    GetCreatorsToSubscribeRandomQueryVariables
  >(GetCreatorsToSubscribeRandomDocument, options)
}
export function useGetCreatorsToSubscribeRandomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreatorsToSubscribeRandomQuery,
    GetCreatorsToSubscribeRandomQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCreatorsToSubscribeRandomQuery,
    GetCreatorsToSubscribeRandomQueryVariables
  >(GetCreatorsToSubscribeRandomDocument, options)
}
export type GetCreatorsToSubscribeRandomQueryHookResult = ReturnType<
  typeof useGetCreatorsToSubscribeRandomQuery
>
export type GetCreatorsToSubscribeRandomLazyQueryHookResult = ReturnType<
  typeof useGetCreatorsToSubscribeRandomLazyQuery
>
export type GetCreatorsToSubscribeRandomQueryResult = Apollo.QueryResult<
  GetCreatorsToSubscribeRandomQuery,
  GetCreatorsToSubscribeRandomQueryVariables
>
