import {
  BackgroundMessageType,
  DATA_SOURCE_CARROT,
  DATA_SOURCE_CARROT_TYPE,
  MessageListenerRuntime,
} from '@carrotcart/common/lib/constants'
import logger from '@carrotcart/client-common/lib/logger/web'

type PostMessageFn<T = any> = (message: TPostMessage<T>) => Promise<void>
const isValidPostMessage = (object: any): object is TPostMessage<any> => {
  return (
    typeof object === 'object' &&
    object.source === DATA_SOURCE_CARROT &&
    'action' in object &&
    'message' in object
  )
}

type MessageListener<T> = (event: MessageEvent<T>) => void

export interface TAwaitBgResponse<T = any> {
  type: BackgroundMessageType
  data?: T
}

export enum PostWindowMessageType {
  DetectShop = 'MESSAGE_DETECT_SHOP',
  CartOnboardingComplete = 'CART_ONBOARDING_COMPLETE',
  StartCartOnboarding = 'START_CART_ONBOARDING',
  AwaitBackgroundResponse = 'AWAIT_BACKGROUND_RESPONSE',
  SinglePageAppPageChange = 'SINGLE_PAGE_APP_PAGE_CHANGE',
  OnPageSaveButton = 'ON_PAGE_SAVE_BUTTON',
  SafariBookmark = 'SAFARI_BOOKMARK',
  ProductPageStatusUpdated = 'PRODUCT_PAGE_STATUS_UPDATED',
  DetectInit = 'DETECT_INIT',
  EvaluateShopConfig = 'EVALUATE_SHOP_CONFIG',
  StopDetectMessageListener = 'STOP_DETECT_MESSAGE_LISTENER',
  AddToCollection = 'ADD_TO_COLLECTION',
  PDPArchieTabClicked = 'PDP_ARCHIE_TAB_CLICKED',
  EvaluateWindowVariable = 'EVALUATE_WINDOW_VARIABLE',
  ExtensionClickInteraction = 'EXTENSION_CLICK_INTERACTION',
}

export type TPostMessage<T = any> = {
  source: DATA_SOURCE_CARROT_TYPE
  action: PostWindowMessageType
  listener: MessageListenerRuntime
  message: T
}

export const getPostMessageData = <T = any>(data: TPostMessage<T>): T => {
  return data.message
}

class PostWindowMessage {
  static postMessageListener<T = any>(
    listener: MessageListenerRuntime,
    listenerFn: PostMessageFn<T>
  ): MessageListener<TPostMessage<T>> {
    return async (event) => {
      const { source, data } = event
      if (
        source === window &&
        isValidPostMessage(data) &&
        data.listener === listener
      ) {
        try {
          await listenerFn(data)
        } catch (err) {
          logger.error({ err })
        }
      }
    }
  }

  // All window.postMessage messages are meant to be handled in the content script
  static postMessage<T = Record<string, unknown>>(
    listener: MessageListenerRuntime,
    action: PostWindowMessageType,
    message?: T
  ): void {
    const data: TPostMessage<T> = {
      source: DATA_SOURCE_CARROT,
      action,
      listener,
      message: (message || {}) as T,
    }
    window.postMessage(data, window.location.href)
  }
}

export default PostWindowMessage
