import React from 'react'
import clsx from 'clsx'
import { Switch } from '@headlessui/react'
//
import Loader from '@carrotcart/client-common/assets/images/loader.svg'

interface ToggleProps {
  checked?: boolean
  onChange?: (checked: boolean) => void
  checkedColor?: string
  uncheckedColor?: string
  label?: React.ReactNode
  icon?: React.ReactNode
  processing?: boolean
  labelClasses?: string
  size?: 'sm' | 'lg'
}

const Toggle: React.FC<ToggleProps> = ({
  checked,
  onChange = () => {
    // noop
  },
  checkedColor = 'bg-confirm',
  uncheckedColor = 'bg-gray-100',
  label,
  icon,
  processing,
  labelClasses,
  size = 'md',
  ...props
}) => {
  let sizeClasses = ''
  let toggleSizeClasses = ''
  let translateClasses = ''

  switch (size) {
    case 'sm':
      sizeClasses = 'h-[26px] w-[45px]'
      toggleSizeClasses = 'h-[21px] w-[21px]'
      translateClasses = 'translate-x-[18px]'
      break
    case 'lg':
      sizeClasses = 'h-[38px] w-[60px]'
      toggleSizeClasses = 'h-[32px] w-[32px]'
      translateClasses = 'translate-x-[22px]'
      break
    default:
      sizeClasses = 'h-[30px] w-[50px]'
      toggleSizeClasses = 'h-[24px] w-[24px]'
      translateClasses = 'translate-x-[20px]'
      break
  }

  return (
    <div className="inline-flex items-center group space-x-2">
      {label && (
        <div className={clsx('text-xs font-semibold', labelClasses)}>
          {label}
        </div>
      )}
      <Switch
        disabled={processing}
        checked={!!checked}
        onChange={onChange}
        className={clsx(
          checked ? checkedColor : uncheckedColor,
          'relative inline-flex items-center flex-shrink-0 border-[3px] border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
          sizeClasses
        )}
        {...props}
      >
        <div
          aria-hidden="true"
          className={clsx(
            checked ? translateClasses : 'translate-x-0',
            'relative pointer-events-none inline-flex items-center justify-center rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            toggleSizeClasses
          )}
        >
          {icon}
          {processing && (
            <div
              className={clsx(
                'absolute inset-0 animate-spin text-black',
                toggleSizeClasses
              )}
            >
              <Loader />
            </div>
          )}
        </div>
      </Switch>
    </div>
  )
}

export default Toggle
