import { useEffect, useState } from 'react'
import { shouldGenerateHasuraClaims } from '@carrotcart/common/lib/hasuraClaimHelpers'
import { HASURA_CLAIM_KEY } from '@carrotcart/common/lib/constants'
import type { ListenerObject } from '@carrotcart/client-common/lib/billingMode'
import {
  BillingModeToggleListener,
  getBillingModeState,
  addListener,
  removeListener,
} from '@carrotcart/client-common/lib/billingMode'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'
import { createCustomToken } from '@carrotcart/client-common/lib/firebase/createCustomToken'
import {
  getUserToken,
  signInWithToken,
} from '@carrotcart/client-common/lib/firebase'

const useBillingMode = (): ListenerObject => {
  const [billingModeCtx, setBillingModeCtx] = useState<ListenerObject>(
    getBillingModeState()
  )
  const { authUser } = useAuthUser()
  const [checkedBillingMode, setCheckedBillingMode] = useState<boolean>(false)

  useEffect(() => {
    let mounted = true
    const listener: BillingModeToggleListener = (ctx) => {
      if (mounted) {
        setBillingModeCtx(ctx)
      }
    }

    addListener(listener)

    return () => {
      mounted = false
      removeListener(listener)
    }
  }, [])

  useEffect(() => {
    if (checkedBillingMode) return
    if (authUser?.uid) {
      setCheckedBillingMode(true)
      ;(async () => {
        const idTokenResult = await getUserToken(authUser)
        const hasurClaimDetails = idTokenResult.claims[
          HASURA_CLAIM_KEY
        ] as Parameters<typeof shouldGenerateHasuraClaims>[0]

        // Checking the presence of the hasura claim key because we don't want to conflict with other places
        // in the code that might be generating the hasura claim details for the very first time
        if (
          hasurClaimDetails &&
          shouldGenerateHasuraClaims(hasurClaimDetails)
        ) {
          const customToken = await createCustomToken({
            uid: authUser.uid,
            accessToken: idTokenResult.token,
          })
          await signInWithToken(customToken)
        }
      })()
    }
  }, [authUser, checkedBillingMode])

  return billingModeCtx
}

export default useBillingMode
