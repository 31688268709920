import Color from 'color'

///////////////////////////////////////////////////////////////////////////////
// Change hex color into RGBA
///////////////////////////////////////////////////////////////////////////////
export const getRGBColor = (hex: string): string =>
  Color(hex).rgb().array().join(',')
export const getRGBDarkColor = (hex: string, amount = 0.1): string =>
  Color(hex).darken(amount).rgb().array().join(',')

///////////////////////////////////////////////////////////////////////////////
// Determine the accessible color of text
///////////////////////////////////////////////////////////////////////////////
export function getAccessibleColor({
  hex,
  lightColor = '#ffffff',
  darkColor = '#000000',
  defaultColor = '#000000',
}: {
  hex: string
  lightColor?: string
  darkColor?: string
  defaultColor?: string
}): string {
  if (!hex) return defaultColor

  const [r, g, b] = Color<string>(hex).rgb().array()

  const uicolors = [r / 255, g / 255, b / 255]
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]

  return L > 0.8 ? darkColor : lightColor
}
