import { useEffect, useState } from 'react'

const useDetectInstagramUA = (): boolean => {
  const [isInstagram, setIsInstagram] = useState(false)

  useEffect(() => {
    const ua = navigator.userAgent
    if (ua.indexOf('Instagram') > -1) {
      setIsInstagram(true)
    }
  }, [])

  return isInstagram
}

export default useDetectInstagramUA
