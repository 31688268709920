import {
  ApolloClient,
  NormalizedCacheObject,
  InMemoryCache,
} from '@apollo/client'
import type { FetchPolicy } from '@apollo/client'
import { initClient } from '@carrotcart/common/lib/apolloClient'

type ClientOptions = {
  fetchPolicy?: FetchPolicy
  ssrMode?: boolean
}

const client = (
  headers = {},
  { fetchPolicy, ssrMode }: ClientOptions = {
    fetchPolicy: 'network-only',
    ssrMode: true,
  }
): ApolloClient<NormalizedCacheObject> =>
  initClient({
    fetch,
    ssrMode,
    headers,
    fetchPolicy,
    cache: new InMemoryCache(),
  })

export default client
