import type * as Types from '../../../../data/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type SubsUserUnseenCartItemsSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['String']
}>

export type SubsUserUnseenCartItemsSubscription = {
  __typename?: 'subscription_root'
  user_by_pk?: Types.Maybe<{
    __typename?: 'user'
    unseen_cart_items?: Types.Maybe<number>
  }>
}

export const SubsUserUnseenCartItemsDocument = gql`
  subscription SubsUserUnseenCartItems($id: String!) {
    user_by_pk(id: $id) {
      unseen_cart_items
    }
  }
`

/**
 * __useSubsUserUnseenCartItemsSubscription__
 *
 * To run a query within a React component, call `useSubsUserUnseenCartItemsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubsUserUnseenCartItemsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubsUserUnseenCartItemsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubsUserUnseenCartItemsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubsUserUnseenCartItemsSubscription,
    SubsUserUnseenCartItemsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubsUserUnseenCartItemsSubscription,
    SubsUserUnseenCartItemsSubscriptionVariables
  >(SubsUserUnseenCartItemsDocument, options)
}
export type SubsUserUnseenCartItemsSubscriptionHookResult = ReturnType<
  typeof useSubsUserUnseenCartItemsSubscription
>
export type SubsUserUnseenCartItemsSubscriptionResult =
  Apollo.SubscriptionResult<SubsUserUnseenCartItemsSubscription>
