import { SVGProps, memo } from 'react'

const MenuSavedItemsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.211 7.65v5.561H2.09V7.65H.5v5.561c0 .874.715 1.589 1.589 1.589H13.21c.874 0 1.589-.715 1.589-1.589V7.65h-1.589Zm-4.767.532 2.058-2.05 1.12 1.12-3.972 3.973-3.972-3.972 1.12-1.12 2.058 2.05V.5h1.588v7.682Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(MenuSavedItemsIcon)
export default Memo
