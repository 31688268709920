import { ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import type { User as AuthUser } from '@firebase/auth'
import * as Sentry from '@sentry/core'
import type { AllowedHasuraRole } from '@carrotcart/common/types'
import { DEFAULT_ANONYMOUS_BILLING_MODE } from '@carrotcart/common/billingApiSharedConstants'
import {
  X_HASURA_BILLING_MODE,
  X_HASURA_ROLE,
} from '@carrotcart/common/lib/constants'
import { getRoleFromIdTokenResult } from '@carrotcart/common/lib/hasuraClaimHelpers'
import { ShouldRetryErrorFn } from '@carrotcart/common/lib/apolloClient/links'
import { WrappedError } from '@carrotcart/common/lib/errors'
import { getUserToken } from '@carrotcart/client-common/lib/firebase'
import auth from '@carrotcart/client-common/lib/firebase/auth'
import {
  getBillingMode,
  waitForBillingModeInit,
} from '@carrotcart/client-common/lib/billingMode'

const EXPIRED_JWT_REGEX = /JWTExpired/

interface AuthHeaders {
  authorization?: string
  [X_HASURA_ROLE]?: string
}

interface AuthLinkContext extends Record<string, any> {
  headers?: Record<string, string>
  role?: AllowedHasuraRole
  refreshJWT?: boolean
}

interface authHeadersArgs {
  authUser: AuthUser
  role?: AllowedHasuraRole
  forceRefresh?: boolean
}

export const authHeaders = async ({
  authUser,
  role,
  forceRefresh = false,
}: authHeadersArgs): Promise<Record<string, string>> => {
  const headers: AuthHeaders = {}
  try {
    const tokenResult = await getUserToken(authUser, forceRefresh)
    if (tokenResult) {
      headers.authorization = `Bearer ${tokenResult.token}`

      if (role) {
        const roleToPass = getRoleFromIdTokenResult(tokenResult, role)
        if (roleToPass) {
          headers['x-hasura-role'] = roleToPass
        }
      }

      return headers as Record<string, string>
    }

    Sentry.captureException(
      new Error(
        'Unable to create a user auth token when trying to make a GQL request'
      ),
      {
        user: {
          id: authUser.uid,
        },
      }
    )
  } catch (err: any) {
    const wrappedErr = new WrappedError(
      'Unable to create a user auth token when trying to make a GQL request',
      err
    )
    Sentry.captureException(wrappedErr, {
      user: {
        id: authUser.uid,
      },
    })
  }

  return {}
}

const authLink = (): ApolloLink => {
  return setContext(async (_request, ctx) => {
    const { headers, role, refreshJWT, ...restContext } = ctx as AuthLinkContext
    await waitForBillingModeInit()
    const authUser = auth.currentUser

    return {
      ...restContext,
      headers: {
        ...headers,
        [X_HASURA_BILLING_MODE]: authUser
          ? getBillingMode()
          : DEFAULT_ANONYMOUS_BILLING_MODE,
        ...(authUser
          ? await authHeaders({
              authUser,
              role,
              forceRefresh: refreshJWT === true,
            })
          : {}),
      },
    }
  })
}

export const shouldRetryError: ShouldRetryErrorFn = ({
  graphQLErrors,
  operation,
}) => {
  const definition = getMainDefinition(operation.query)

  if (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  ) {
    return false
  }

  if (graphQLErrors) {
    for (let i = 0; i < graphQLErrors.length; i++) {
      const { message, extensions } = graphQLErrors[i]
      const shouldRetry =
        EXPIRED_JWT_REGEX.test(message) && extensions?.code === 'invalid-jwt'

      if (shouldRetry) {
        const ctx = operation.getContext()
        operation.setContext(<AuthLinkContext>{
          ...ctx,
          refreshJWT: true,
        })
      }

      return shouldRetry
    }
  }

  return false
}

export default authLink
