import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react'
import clsx from 'clsx'
import truncate from 'lodash/truncate'
import Router from 'next/router'
import {
  autocomplete,
  AutocompleteApi,
  AutocompleteOptions,
  VNode,
} from '@algolia/autocomplete-js'
import type { AutocompleteState, BaseItem } from '@algolia/autocomplete-core'
import type { GetCreatorsToSubscribeRandomQuery } from '@carrotcart/app/data/getCreatorsToSubscribeRandom.generated'
import {
  recentSearchesPlugin,
  querySuggestionsPlugin,
  userSearchPlugin,
} from '@carrotcart/app/lib/typesense'
import { createProfileUrl } from '@carrotcart/client-common/lib/helpers'
import useGetFeaturedUsersForSearch from '@carrotcart/app/hooks/useGetFeaturedUsersForSearch'

type AutocompleteProps = Partial<AutocompleteOptions<BaseItem>> & {
  className?: string
  setQuery: (query: string, reset?: boolean) => void
}

type Hit = BaseItem &
  Partial<GetCreatorsToSubscribeRandomQuery['vip_creators'][0]>

const alterSectionChildren = (
  section: string,
  children: VNode<any>[]
): VNode<any>[] => {
  switch (section) {
    case 'trendingSearches': {
      return children.map((el) => {
        if (el?.type === 'ul') {
          el.props.className += ' flex flex-wrap'
        }
        return el
      })
    }
    case 'featuredUsersForSearch': {
      return children.map((el) => {
        if (el?.type === 'ul') {
          el.props.className += ' grid grid-cols-2 md:grid-cols-3'
        }
        return el
      })
    }
    default: {
      return children
    }
  }
}

const wrappingSectionClasses = (section: string): string => {
  // Make sure to keep consistent with the algolia styles
  // LINK: ../../styles/algolia-custom.css:1206
  const classNames = `aa-Source aa-Section-${section}`

  switch (section) {
    case 'trendingSearches': {
      return `${classNames} p-2`
    }
    default: {
      return classNames
    }
  }
}

const AlgoliaProductSearchAutocomplete: React.FC<AutocompleteProps> = ({
  className,
  setQuery,
  placeholder = 'Search Carrot',
  detachedMediaQuery = '(max-width: 1000px)',
}) => {
  const [autocompleteState, setAutocompleteState] =
    useState<AutocompleteState<BaseItem>>()
  const autocompleteContainer = useRef<HTMLDivElement>(null)
  const searchInput = useRef<HTMLInputElement>(null)
  const autocompleteInstanceRef = useRef<ReturnType<typeof autocomplete>>(null)

  const featuredUsersForSearch = useGetFeaturedUsersForSearch()

  const setQueryAndClearDetachedClass = useCallback(
    (query: string, reset?: boolean) => {
      setQuery(query, reset)
      // HACK: This is a hack to remove the detached class from the body
      // when a search is made. The detached class is
      // added to the body when the search is detached from the input
      // and the detached class is not removed when the search is made.
      // This is due to the `setQuery` function coming from the provider
      // and not the autocomplete instance.
      if (!reset) {
        document.body.classList.remove('aa-Detached')
      }
      if (query) {
        window.location.assign(`/search?q=${query}`)
      }
    },
    [setQuery]
  )

  const onOpenOverlayClickHandler = useCallback(() => {
    if (autocompleteInstanceRef.current) {
      autocompleteInstanceRef.current.setIsOpen(false)
    }
  }, [])

  const overlayClickHandler = autocompleteState?.isOpen
    ? onOpenOverlayClickHandler
    : undefined

  const plugins = useMemo(() => {
    const recentSearches = recentSearchesPlugin({
      transformSource({ source }) {
        return {
          ...source,
          onSelect({ item, setQuery }) {
            setQueryAndClearDetachedClass(item.label)
            if (searchInput.current) {
              searchInput.current.blur()
            }
            setQuery(item.label)
          },
          templates: {
            ...source.templates,
            header({ items, html }) {
              if (items.length === 0) {
                return
              }

              return html`<div class="font-semibold px-2">Recent</div>`
            },
          },
        }
      },
    })

    const querySuggestions = querySuggestionsPlugin({
      getSearchParams() {
        return recentSearches.data.getAlgoliaSearchParams({
          hitsPerPage: 6,
        })
      },
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'querySuggestionsPlugin',
          onSelect({ item, setQuery }) {
            setQueryAndClearDetachedClass(item.query)
            if (searchInput.current) {
              searchInput.current.blur()
            }
            setQuery(item.query)
          },
          getItems(params) {
            if (!params.state.query) {
              return []
            }

            return source.getItems(params)
          },
          templates: {
            ...source.templates,
            header({ items, html }) {
              if (items.length === 0) {
                return
              }

              return html`<div class="font-semibold px-2">Suggestions</div>`
            },
          },
        }
      },
    })

    return [
      recentSearches,
      userSearchPlugin,
      querySuggestions,
      // algoliaInsightsPlugin,
    ]
  }, [setQueryAndClearDetachedClass])

  useEffect(() => {
    if (!autocompleteContainer.current) {
      return
    }

    let initialQuery: string | undefined = undefined
    try {
      initialQuery = new URL(window.location.toString()).searchParams.get('q')
    } catch {
      // no-op
    }

    const autocompleteOptions: AutocompleteOptions<Hit> = {
      container: autocompleteContainer.current,
      placeholder,
      plugins,
      openOnFocus: true,
      detachedMediaQuery,
      initialState: { query: initialQuery },
      classNames: {
        root: 'rounded-[20px] bg-white border focus-within:border-white focus-within:rounded-b-none border-gray border-opacity-50 overflow-hidden',
        form: 'border-0 focus-within:ring-0',
        detachedSearchButton: 'rounded-[20px] border-none',
        panel: 'z-[51] p-0 rounded-b-[20px] -mt-1 mx-[-1px]',
        detachedContainer: 'md:rounded-[20px] md:mt-16',
      },
      render({ elements, render, html }, root) {
        const sections = Object.keys(elements || {}) || []
        render(
          html`<div class="aa-PanelLayout aa-Panel--scrollable space-y-2 p-y-2">
            ${sections.map((sectionKey) => {
              const section = elements[sectionKey]
              let sectionChildren = section?.props?.children
              if (Array.isArray(sectionChildren)) {
                sectionChildren = alterSectionChildren(
                  sectionKey,
                  sectionChildren as VNode<any>[]
                )
              }

              return html`<section
                class="${wrappingSectionClasses(sectionKey)}"
              >
                ${sectionChildren}
              </section>`
            })}
          </div>` as VNode,
          root
        )
      },
      onStateChange({ state }) {
        setAutocompleteState(state)
      },
      onReset() {
        setQueryAndClearDetachedClass('', true)
        setTimeout(() => {
          searchInput.current?.focus()
        }, 200)
      },
      onSubmit({ state }) {
        setQueryAndClearDetachedClass(state.query)
      },
      getSources() {
        return [
          {
            sourceId: 'featuredUsersForSearch',
            onSelect({ item }) {
              Router.push(createProfileUrl(item.username, { local: true }))
            },
            getItems() {
              return featuredUsersForSearch
            },
            templates: {
              header({ html }) {
                return html`<div
                  className="flex items-center space-x-1 mb-2 px-2"
                >
                  <div class="font-semibold">Featured users</div>
                </div>`
              },
              item({ item, html }) {
                return html`<div
                  class="cursor-pointer p-2 flex items-center space-x-2"
                >
                  <img
                    src="${item.picture}"
                    width="30"
                    height="30"
                    class="rounded-full overflow-hidden"
                  />
                  <div>
                    <div class="flex items-center space-x-1">
                      <div class="text-sm">
                        ${truncate(item.name, { length: 20 })}
                      </div>
                      <div class="${item.verified ? 'inline' : 'hidden'}">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path fill="#fff" d="M6 5h13v13H6z" />
                          <path
                            d="m23 12-2.44-2.79.34-3.69-3.61-.82-1.89-3.2L12 2.96 8.6 1.5 6.71 4.69 3.1 5.5l.34 3.7L1 12l2.44 2.79-.34 3.7 3.61.82L8.6 22.5l3.4-1.47 3.4 1.46 1.89-3.19 3.61-.82-.34-3.69L23 12Zm-12.91 4.72-3.8-3.81 1.48-1.48 2.32 2.33 5.85-5.87 1.48 1.48-7.33 7.35Z"
                            fill="#6066ED"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="text-sm text-gray-400">@${item.username}</div>
                  </div>
                </div>`
              },
            },
          },
        ]
      },
    }

    const autocompleteInstance: AutocompleteApi<Hit> =
      autocomplete(autocompleteOptions)

    autocompleteInstanceRef.current = autocompleteInstance
    searchInput.current = autocompleteContainer.current?.querySelector('input')

    return () => {
      autocompleteInstance.destroy()
    }
  }, [
    plugins,
    setQueryAndClearDetachedClass,
    featuredUsersForSearch,
    placeholder,
    detachedMediaQuery,
  ])

  return (
    <div className={clsx('flex-1 relative z-10')}>
      <div
        className={clsx('relative aa-CustomWrapper z-10', className, {
          'aa-IsOpen': autocompleteState?.isOpen,
        })}
        ref={autocompleteContainer}
      />
      <div
        className={clsx(
          'aa-CustomOverlay fixed inset-0 bg-black bg-opacity-50 transition-opacity',
          {
            'opacity-100 pointer-events-auto': autocompleteState?.isOpen,
            'opacity-0 pointer-events-none': !autocompleteState?.isOpen,
          }
        )}
        onClick={overlayClickHandler}
      />
    </div>
  )
}

export default AlgoliaProductSearchAutocomplete
