import { useEffect, useState } from 'react'
import { isSupportedBrowser } from '@carrotcart/app/lib/browserSupport'

export interface BrowserSupportData {
  supported: boolean
  loaded: boolean
  browser: string
  isMobile: boolean
}

const useBrowserSupport = (): BrowserSupportData => {
  const [browserSupporData, setBrowserSupportData] =
    useState<BrowserSupportData>({
      supported: false,
      loaded: false,
      browser: '',
      isMobile: false,
    })

  useEffect(() => {
    const { browser, supported, isMobile } = isSupportedBrowser()

    setBrowserSupportData({
      supported,
      loaded: true,
      browser,
      isMobile,
    })
  }, [])

  return browserSupporData
}

export default useBrowserSupport
