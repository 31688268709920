import React from 'react'
import clsx from 'clsx'
import LogoSvg from '@carrotcart/app/components/svg/Logo'

interface LogoProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  width?: string | number
  height?: string | number
}

const Logo: React.FC<LogoProps> = ({ className, width, height, ...props }) => {
  return (
    <div className={clsx('relative', className)} {...props}>
      <LogoSvg width={width} height={height} />
    </div>
  )
}

export default Logo
