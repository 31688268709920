import { setContext } from '@apollo/client/link/context'
import { v4 as uuidv4 } from 'uuid'

const requestIdLink = setContext(
  (_request, { headers = {}, ...restContext }) => {
    const request_id = uuidv4()
    return {
      ...restContext,
      request_id,
      headers: {
        ...headers,
        'x-request-id': request_id,
      },
    }
  }
)

export default requestIdLink
