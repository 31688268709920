import type * as Types from '../../../data/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type GetCreatorSubscriptionsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']
  include_subscriptions?: Types.Maybe<Types.Scalars['Boolean']>
}>

export type GetCreatorSubscriptionsQuery = {
  __typename?: 'query_root'
  availableSubscriptions?: Types.Maybe<
    Array<{
      __typename?: 'Subscription'
      id: string
      name: string
      description?: Types.Maybe<string>
      unit_amount: number
      currency: string
      interval: Types.RecurringInterval
    }>
  >
  creator?: Types.Maybe<{
    __typename?: 'user'
    subscription_status?: Types.Maybe<string>
    has_billing_account?: Types.Maybe<boolean>
  }>
}

export const GetCreatorSubscriptionsDocument = gql`
  query GetCreatorSubscriptions(
    $id: String!
    $include_subscriptions: Boolean = false
  ) {
    availableSubscriptions(creatorId: $id)
      @include(if: $include_subscriptions) {
      id
      name
      description
      unit_amount
      currency
      interval
    }
    creator: user_by_pk(id: $id) {
      subscription_status
      has_billing_account
    }
  }
`

/**
 * __useGetCreatorSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetCreatorSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatorSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatorSubscriptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      include_subscriptions: // value for 'include_subscriptions'
 *   },
 * });
 */
export function useGetCreatorSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreatorSubscriptionsQuery,
    GetCreatorSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCreatorSubscriptionsQuery,
    GetCreatorSubscriptionsQueryVariables
  >(GetCreatorSubscriptionsDocument, options)
}
export function useGetCreatorSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreatorSubscriptionsQuery,
    GetCreatorSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCreatorSubscriptionsQuery,
    GetCreatorSubscriptionsQueryVariables
  >(GetCreatorSubscriptionsDocument, options)
}
export type GetCreatorSubscriptionsQueryHookResult = ReturnType<
  typeof useGetCreatorSubscriptionsQuery
>
export type GetCreatorSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useGetCreatorSubscriptionsLazyQuery
>
export type GetCreatorSubscriptionsQueryResult = Apollo.QueryResult<
  GetCreatorSubscriptionsQuery,
  GetCreatorSubscriptionsQueryVariables
>
