import { useCallback } from 'react'
import { useRouter } from 'next/router'

const useCreateAuthUrl = (): ((
  path?: any,
  params?: Record<string, string>
) => string) => {
  const router = useRouter()

  const createAuthUrl = useCallback(
    (path = '', params: Record<string, string> = {}): string => {
      if (typeof window === 'undefined') return path

      const url = new URL(path, window.location.origin)

      // append query params to auth urls
      Object.keys(router.query)
        .filter((key) => key === 'redirect')
        .forEach((key) => {
          if (!url.searchParams.has(key)) {
            url.searchParams.set(key, router.query[key] as string)
          }
        })

      Object.keys(params).forEach((key) => {
        if (!url.searchParams.has(key)) {
          url.searchParams.set(key, params[key])
        }
      })

      if (
        !url.searchParams.has('redirect') &&
        !['signup', 'signin'].includes(router.asPath)
      ) {
        url.searchParams.set(
          'redirect',
          router.asPath.replace(/\/$/, '') || '/'
        )
      }

      return url.toString()
    },
    [router.asPath, router.query]
  )

  return createAuthUrl
}

export default useCreateAuthUrl
