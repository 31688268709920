import type * as Types from '../../data/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type FindCollectionCollaboratorQueryVariables = Types.Exact<{
  collectionId: Types.Scalars['uuid']
}>

export type FindCollectionCollaboratorQuery = {
  __typename?: 'query_root'
  collection_by_pk?: Types.Maybe<{
    __typename?: 'collection'
    id: any
    is_a_collaborator?: Types.Maybe<boolean>
  }>
}

export const FindCollectionCollaboratorDocument = gql`
  query FindCollectionCollaborator($collectionId: uuid!) {
    collection_by_pk(id: $collectionId) {
      id
      is_a_collaborator
    }
  }
`

/**
 * __useFindCollectionCollaboratorQuery__
 *
 * To run a query within a React component, call `useFindCollectionCollaboratorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCollectionCollaboratorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCollectionCollaboratorQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useFindCollectionCollaboratorQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCollectionCollaboratorQuery,
    FindCollectionCollaboratorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCollectionCollaboratorQuery,
    FindCollectionCollaboratorQueryVariables
  >(FindCollectionCollaboratorDocument, options)
}
export function useFindCollectionCollaboratorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCollectionCollaboratorQuery,
    FindCollectionCollaboratorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCollectionCollaboratorQuery,
    FindCollectionCollaboratorQueryVariables
  >(FindCollectionCollaboratorDocument, options)
}
export type FindCollectionCollaboratorQueryHookResult = ReturnType<
  typeof useFindCollectionCollaboratorQuery
>
export type FindCollectionCollaboratorLazyQueryHookResult = ReturnType<
  typeof useFindCollectionCollaboratorLazyQuery
>
export type FindCollectionCollaboratorQueryResult = Apollo.QueryResult<
  FindCollectionCollaboratorQuery,
  FindCollectionCollaboratorQueryVariables
>
