import { ParsedUrlQuery, parse } from 'querystring'
import { UTMParam, UTMParameters } from '@carrotcart/common/types'

type QueryParams = ParsedUrlQuery | Record<string, string> | string | undefined

const extractUtmFromParams = (query: QueryParams): UTMParameters => {
  const params = (typeof query === 'string' ? parse(query) : query) || {}

  return Object.keys(params).reduce<UTMParameters>((acc, key) => {
    if (key.startsWith('utm_')) {
      const utmKey = key as UTMParam
      const value = params[key]

      if (value) {
        acc[utmKey] = value
      }
    }
    return acc
  }, {})
}

export default extractUtmFromParams
