import React from 'react'
import clsx from 'clsx'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import connectToContext, {
  SelectorFn,
} from '@carrotcart/client-common/hoc/connectToContext'
import useBillingMode from '@carrotcart/app/hooks/useBillingMode'

interface ContextSelectorProps {
  isAdminUser: boolean
}

const useSelector: SelectorFn<ContextSelectorProps> = () => {
  const { isAdminUser } = useWebAppContext()
  return { isAdminUser }
}

const BillingModeIndicator: React.FC<ContextSelectorProps> = ({
  isAdminUser,
}) => {
  const billingModeCtx = useBillingMode()
  const { billingMode, initialized } = billingModeCtx

  const billingModeLive = billingMode === 'live'
  const billingModeTest = billingMode === 'test'

  if (!initialized) return null
  if (!isAdminUser) return null
  if (billingModeLive) return null

  return (
    <div
      className={clsx(
        'flex flex-col items-center fixed bottom-0 inset-x-0 border-b-4 z-[999] px-4 pointer-events-none',
        {
          'flex border-red': billingModeTest,
          'hidden md:flex border-green-500': billingModeLive,
        }
      )}
    >
      <div
        className={clsx(
          'text-white px-4 py-[1px] text-xxs uppercase tracking-widest rounded-t',
          {
            'bg-red': billingModeTest,
            'bg-green-500': billingModeLive,
          }
        )}
      >
        {`${billingMode} data`}
      </div>
    </div>
  )
}

export default connectToContext(BillingModeIndicator, useSelector)
