import Router from 'next/router'
import truncate from 'lodash/truncate'
import { AutocompletePlugin } from '@algolia/autocomplete-js'
import {
  createLocalStorageRecentSearchesPlugin,
  RecentSearchesPluginData,
} from '@algolia/autocomplete-plugin-recent-searches'
import { TYPESENSE_QUERY_SUGGESTIONS_INDEX } from '@carrotcart/common/lib/constants'
import { searchClient } from '@carrotcart/client-common/lib/typesense'
import clientServer from '@carrotcart/app/lib/apolloClientServer'
import {
  SearchForUsersWebDocument,
  SearchForUsersWebQuery,
  SearchForUsersWebQueryVariables,
} from '@carrotcart/app/data/searchForUsersWeb.generated'
import { createProfileUrl } from '@carrotcart/client-common/lib/helpers'
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import { RecentSearchesItem } from '@algolia/autocomplete-plugin-recent-searches/dist/esm/types'
import { AutocompleteQuerySuggestionsHit } from '@algolia/autocomplete-plugin-query-suggestions/dist/esm/types'

const client = clientServer()

export const recentSearchesPlugin = (
  options?: Omit<
    Parameters<typeof createLocalStorageRecentSearchesPlugin>[0],
    'key'
  >
): AutocompletePlugin<
  RecentSearchesItem,
  RecentSearchesPluginData<RecentSearchesItem>
> =>
  createLocalStorageRecentSearchesPlugin({
    key: 'RECENT_SEARCH',
    limit: 3,
    ...options,
  })

export const querySuggestionsPlugin = (
  options?: Omit<
    Parameters<typeof createQuerySuggestionsPlugin>[0],
    | 'searchClient'
    | 'indexName'
    | 'categoryAttribute'
    | 'itemsWithCategories'
    | 'categoriesPerItem'
  >
): AutocompletePlugin<AutocompleteQuerySuggestionsHit, undefined> =>
  createQuerySuggestionsPlugin({
    searchClient: searchClient,
    indexName: TYPESENSE_QUERY_SUGGESTIONS_INDEX,
    // TODO: Maybe later add the ability to show autocomplete query suggestions by category
    // categoryAttribute: [
    //   'Collection-prod',
    //   'facets',
    //   'exact_matches',
    //   'shop_categories.label',
    // ],
    // itemsWithCategories: 2,
    // categoriesPerItem: 2,
    ...options,
  })

export const userSearchPlugin: AutocompletePlugin<
  SearchForUsersWebQuery['users'][0],
  undefined
> = {
  getSources() {
    return [
      {
        sourceId: 'userSearchPlugin',
        async getItems({ query }) {
          if (!query || (query && query.length < 3)) {
            return []
          }

          const searchUsersResp = await client.query<
            SearchForUsersWebQuery,
            SearchForUsersWebQueryVariables
          >({
            query: SearchForUsersWebDocument,
            variables: {
              query,
            },
          })

          return searchUsersResp?.data?.users || []
        },
        onSelect({ item }) {
          Router.push(createProfileUrl(item.username, { local: true }))
        },
        templates: {
          header({ items, html }) {
            if (items.length === 0) {
              return
            }

            return html`<div class="font-semibold px-2">Users</div>`
          },
          item({ item, html }) {
            return html`<div
              class="cursor-pointer p-2 flex items-center space-x-2"
            >
              <img
                src="${item.picture}"
                width="30"
                height="30"
                class="rounded-full"
              />
              <div>
                <div class="flex items-center space-x-1">
                  <div>${truncate(item.name, { length: 20 })}</div>
                  <div class="${item.verified ? 'inline' : 'hidden'}">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="#fff" d="M6 5h13v13H6z" />
                      <path
                        d="m23 12-2.44-2.79.34-3.69-3.61-.82-1.89-3.2L12 2.96 8.6 1.5 6.71 4.69 3.1 5.5l.34 3.7L1 12l2.44 2.79-.34 3.7 3.61.82L8.6 22.5l3.4-1.47 3.4 1.46 1.89-3.19 3.61-.82-.34-3.69L23 12Zm-12.91 4.72-3.8-3.81 1.48-1.48 2.32 2.33 5.85-5.87 1.48 1.48-7.33 7.35Z"
                        fill="#6066ED"
                      />
                    </svg>
                  </div>
                </div>
                <div class="text-sm text-gray-400">@${item.username}</div>
              </div>
            </div>`
          },
        },
      },
    ]
  },
}
