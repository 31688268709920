import connectToContext, {
  SelectorFn,
} from '@carrotcart/client-common/hoc/connectToContext'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import { useSubsUserUnseenCartItemsSubscription } from './subsUserUnseenCartItems.generated'

interface ContextSelectorProps {
  loggedInUserId: string | undefined
}

const useSelector: SelectorFn<ContextSelectorProps> = () => {
  const { currentUser } = useWebAppContext()
  return { loggedInUserId: currentUser?.id }
}

const NewCartItemsNotificationDot: React.FC<ContextSelectorProps> = ({
  loggedInUserId,
}) => {
  const { data } = useSubsUserUnseenCartItemsSubscription({
    variables: { id: loggedInUserId },
    skip: !loggedInUserId,
  })

  if (!loggedInUserId) return null
  if (!data?.user_by_pk?.unseen_cart_items) return null
  if (data.user_by_pk.unseen_cart_items === 0) return null

  return <div className="bg-orange-500 rounded-full w-3 h-3" />
}

export default connectToContext(NewCartItemsNotificationDot, useSelector)
