import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { XIcon } from '@heroicons/react/solid'
import { ApolloQueryResult } from '@apollo/client'
import { CreatorSubscriptionStatus } from '@carrotcart/common/types'
import { FindCollectionType } from '@carrotcart/app/types'
import type { BasicUserDataFragment } from '@carrotcart/data/graphql/fragments.generated'
import { GetCreatorSubscriptionsQuery } from '@carrotcart/app/data/subscribe/getCreatorSubscriptions.generated'
import { useFindCollectionCollaboratorQuery } from '@carrotcart/app/data/findCollectionCollaborator.generated'
import useWindowSize from '@carrotcart/client-common/hooks/useWindowSize'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import useSubscriptionStatus from '@carrotcart/app/hooks/useSubscriptionStatus'

const Confetti = dynamic(() => import('react-confetti'))

interface IPremiumContentContext {
  shouldBlur: boolean
  isPremiumContent: boolean
  isCollaboratorOnPremiumCollection: boolean
  loadingCollaboratorData: boolean
  includeSubscriptions?: boolean
  subscriptionStatus?: CreatorSubscriptionStatus
  loadingSubscriptionStatus?: boolean
  hasBillingAccount?: boolean
  subscriptions: GetCreatorSubscriptionsQuery['availableSubscriptions']
  refetchCreatorSubscriptions: () => Promise<
    ApolloQueryResult<GetCreatorSubscriptionsQuery> | undefined
  >
  creator?: BasicUserDataFragment
}

const defaultContext: IPremiumContentContext = {
  shouldBlur: false,
  isPremiumContent: false,
  isCollaboratorOnPremiumCollection: false,
  loadingCollaboratorData: false,
  includeSubscriptions: false,
  subscriptionStatus: undefined,
  loadingSubscriptionStatus: false,
  hasBillingAccount: false,
  subscriptions: undefined,
  refetchCreatorSubscriptions: () => Promise.resolve(undefined),
  creator: undefined,
}

export const PremiumContentContext = React.createContext(defaultContext)

export const usePremiumContentContext = (): IPremiumContentContext => {
  return React.useContext<IPremiumContentContext>(PremiumContentContext)
}

interface Props {
  creator: BasicUserDataFragment
  premiumCollection?: FindCollectionType
  includeSubscriptions?: boolean
}

const PremiumContentProvider: React.FC<Props> = ({
  children,
  creator,
  premiumCollection,
  includeSubscriptions,
}) => {
  const router = useRouter()
  const subscribeSuccess = router.query.success === 'true'

  const { width, height } = useWindowSize()
  const { currentUser, loadingApp } = useWebAppContext()
  const [shouldBlur, setShouldBlur] = useState(false)
  const [shouldShowConfetti, setShouldShowConfetti] = useState(false)

  const {
    status: subscriptionStatus,
    loading: loadingSubscriptionStatus,
    hasBillingAccount,
    refetch: refetchCreatorSubscriptions,
    subscriptions,
  } = useSubscriptionStatus({
    creator,
    includeSubscriptions,
  })

  const {
    data,
    loading: loadingCollaboratorData,
    called,
  } = useFindCollectionCollaboratorQuery({
    variables: {
      collectionId: premiumCollection?.id,
    },
    skip: !premiumCollection?.id,
  })
  const isCollaboratorOnPremiumCollection =
    !!data?.collection_by_pk.is_a_collaborator
  const userHasPaidSubscription = subscriptionStatus === 'paid'
  const isOwnCollection = creator.id === currentUser?.id

  useEffect(() => {
    if (isOwnCollection) return
    if (!premiumCollection) return
    if (loadingCollaboratorData) return
    if (isCollaboratorOnPremiumCollection) return
    if (!called) return
    if (loadingApp) return
    if (loadingSubscriptionStatus) return

    setShouldBlur(!userHasPaidSubscription && !isOwnCollection)

    return () => setShouldBlur(false)
  }, [
    called,
    isCollaboratorOnPremiumCollection,
    isOwnCollection,
    loadingApp,
    loadingCollaboratorData,
    loadingSubscriptionStatus,
    premiumCollection,
    userHasPaidSubscription,
  ])

  useEffect(() => {
    if (subscribeSuccess) {
      setShouldShowConfetti(true)

      toast(
        <div className="flex flex-col items-center">
          <div>Your support means a lot, thanks so much!</div>
          <div className="absolute right-0 inset-y-0 p-4 flex flex-col items-center justify-center">
            <XIcon className="text-skin-a11y w-4 h-4" />
          </div>
        </div>,
        {
          type: 'success',
          delay: 500,
          autoClose: 4000,
          closeButton: false,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          draggable: false,
          position: toast.POSITION.TOP_CENTER,
          toastId: 'subscribe-success',
          className: 'bg-skin-primary text-skin-a11y',
          onClose: () => {
            // remove the "success" query param from the URL
            const url = new URL(window.location.href)
            url.searchParams.delete('success')

            // replace the URL with the new one
            router.replace(url.toString(), undefined, { shallow: true })
          },
        }
      )
    }

    return () => setShouldShowConfetti(false)
  }, [router, subscribeSuccess])

  return (
    <PremiumContentContext.Provider
      value={{
        isPremiumContent: !!premiumCollection,
        isCollaboratorOnPremiumCollection,
        loadingCollaboratorData,
        shouldBlur,
        subscriptionStatus,
        loadingSubscriptionStatus,
        hasBillingAccount,
        subscriptions,
        refetchCreatorSubscriptions,
        creator,
      }}
    >
      {children}

      {shouldShowConfetti && (
        <div className="fixed inset-0 z-50 pointer-events-none">
          <Confetti
            width={width}
            height={height}
            run
            onAnimationEnd={() => setShouldShowConfetti(false)}
            recycle={false}
            numberOfPieces={300}
            gravity={0.2}
            colors={['#F178B6', '#FF8C1B', '#77B255', '#FFCC4D']}
          />
        </div>
      )}
    </PremiumContentContext.Provider>
  )
}

export default PremiumContentProvider
