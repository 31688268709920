import React, { HtmlHTMLAttributes } from 'react'
import Image, { ImageProps } from 'next/legacy/image'
import Link from 'next/link'

export interface Props extends HtmlHTMLAttributes<HTMLAnchorElement> {
  href?: string
  src: string | ImageProps['src']
  width?: number
  height?: number
  alt: string
}

const StoreBadge: React.FC<Props> = ({
  href,
  src,
  width,
  height,
  alt,
  onClick,
}) => {
  return href ? (
    <Link
      href={href}
      className="flex flex-col items-center outline-none"
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
    >
      <Image
        src={src}
        width={width || 177}
        height={height || 55}
        alt={alt}
        layout="fixed"
      />
    </Link>
  ) : (
    <Image
      src={src}
      width={width || 177}
      height={height || 55}
      alt={alt}
      layout="fixed"
    />
  )
}

export default StoreBadge
