import React from 'react'
import StoreBadge, { Props as StoreBadgeProps } from './StoreBadge'

export type AppStoreCampaign =
  | 'CarrotHomePage'
  | 'ContactsInvite'
  | 'CollaborativeCollections'
  | 'Onboarding'
  | 'CollectionsPage'
  | 'CarrotFooter'
  | 'CarrotPDP'
  | 'AddViaLinkModal'
  | 'DupesLandingPage'
  | 'Wallet'

interface Props extends Pick<StoreBadgeProps, 'width' | 'height'> {
  campaign?: AppStoreCampaign
}

const AppleAppStoreBadge: React.FC<Props> = ({ width, height, campaign }) => {
  const url = new URL(
    'https://apps.apple.com/us/app/carrot-shopping/id1605692440?pt=124011140&mt=8'
  )

  if (campaign) {
    url.searchParams.set('ct', campaign)
  }

  return (
    <StoreBadge
      href={url.toString()}
      onClick={() => {
        analytics.track('Clicked Landing CTA', {
          copy: 'Download on the App Store',
          campaign,
        })
      }}
      src={'/images/available_app_store.png'}
      width={width || 177}
      height={height || 55}
      alt="Download on the App Store"
    />
  )
}

export default AppleAppStoreBadge
