import React from 'react'
import clsx from 'clsx'
import Head from 'next/head'
import { NextSeo, NextSeoProps } from 'next-seo'
import { ToastContainer } from 'react-toastify'
import type { BasicUserDataFragment } from '@carrotcart/data/graphql/fragments.generated'
import imageKitLoader from '@carrotcart/client-common/lib/imageKitLoader'
import ProgressLinear from '@carrotcart/client-common/components/ProgressLinear'
import useCreatorColors from '@carrotcart/app/hooks/useCreatorColors'
import LayoutHeader from '@carrotcart/app/components/LayoutHeader'
import LayoutCreatorHeader from '@carrotcart/app/components/LayoutHeader/LayoutCreatorHeader'
import GoogleOneTap from '@carrotcart/app/components/GoogleOneTap'
import Footer from '@carrotcart/app/components/Footer'

interface LayoutProps {
  layoutClassname?: HTMLElement['className']
  mainClassName?: HTMLElement['className']
  footerClassName?: HTMLElement['className']
  title?: string
  titleTemplate?: string
  loading?: boolean
  wide?: boolean
  full?: boolean
  hideHeader?: boolean
  hideFooter?: boolean
  showNavLinks?: boolean
  showGoogleOneTap?: boolean
  titleComponent?: React.ReactNode
  nextSeo?: NextSeoProps
  backgroundColor?: string
  showAppStoreBanner?: boolean
  hideProductSearch?: boolean
  showProductSearchOnScroll?: boolean
  hideInstallCTA?: boolean
  backgroundTheme?: string
  user?: BasicUserDataFragment
  copyLink?: string
  hideCreatorTitle?: boolean
  hideSubscribeCTA?: boolean
  hideStickySignupBanner?: boolean
  stickyHeader?: boolean
}

const Layout: React.FC<LayoutProps> = ({
  children,
  layoutClassname,
  mainClassName,
  footerClassName,
  title,
  titleTemplate,
  loading,
  wide,
  full,
  hideHeader,
  hideFooter,
  showNavLinks,
  showGoogleOneTap,
  titleComponent,
  nextSeo,
  backgroundColor,
  showAppStoreBanner = true,
  hideProductSearch,
  showProductSearchOnScroll,
  hideInstallCTA,
  backgroundTheme = backgroundColor || '#ffffff',
  user,
  copyLink,
  hideCreatorTitle,
  hideSubscribeCTA,
  stickyHeader,
}) => {
  const creatorColors = useCreatorColors(user)

  const userFavicon16 = user?.picture
    ? imageKitLoader({
        src: user.picture,
        width: 16,
        rounded: true,
      })
    : '/favicon-16x16.png'

  const userFavicon32 = user?.picture
    ? imageKitLoader({
        src: user.picture,
        width: 32,
        rounded: true,
      })
    : '/favicon-32x32.png'

  const userFaviconAppleTouch = user?.picture
    ? imageKitLoader({
        src: user.picture,
        width: 180,
        rounded: true,
      })
    : '/apple-touch-icon.png'

  return (
    <>
      <Head>
        <meta name="theme-color" content={backgroundTheme} />
        {showAppStoreBanner && (
          <meta
            name="apple-itunes-app"
            content="app-id=1605692440, affiliate-data=ct=SmartBanner"
          />
        )}

        {/* CSS var settings for color overrides based on `user.metadata.primary_color_hex` value */}
        <style>
          :root{' '}
          {`{${creatorColors.primaryColor} ${creatorColors.primaryColorDark} ${creatorColors.a11yColor}}`}
        </style>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={userFaviconAppleTouch}
        />
        <link rel="icon" type="image/png" sizes="16x16" href={userFavicon16} />
        <link rel="icon" type="image/png" sizes="32x32" href={userFavicon32} />
      </Head>
      <NextSeo
        title={title}
        titleTemplate={titleTemplate || nextSeo?.titleTemplate}
        {...nextSeo}
      />
      <article
        className={clsx(
          'bg-white flex flex-col min-h-screen mx-auto',
          layoutClassname
        )}
        style={{ backgroundColor }}
      >
        {showGoogleOneTap && <GoogleOneTap />}

        {!hideHeader &&
          (user ? (
            <LayoutCreatorHeader
              user={user}
              hideTitle={hideCreatorTitle}
              hideSubscribeCTA={hideSubscribeCTA}
              copyLink={copyLink}
              titleComponent={titleComponent}
            />
          ) : (
            <LayoutHeader
              hideProductSearch={hideProductSearch}
              showProductSearchOnScroll={showProductSearchOnScroll}
              hideInstallCTA={hideInstallCTA}
              showNavLinks={showNavLinks}
              isSticky={stickyHeader}
            />
          ))}
        <div className={clsx('absolute z-10 top-0 inset-x-0')}>
          <ProgressLinear visible={loading} />
        </div>
        <main
          className={clsx(
            'relative bg-white flex flex-col w-full mx-auto flex-1 pb-16',
            mainClassName,
            {
              'max-w-[1920px]': !full && wide,
              'max-w-[950px]': !full && !wide,
            }
          )}
        >
          {children}
        </main>
        {!hideFooter && <Footer className={footerClassName} />}
      </article>
      <ToastContainer position="top-center" autoClose={1500} />
    </>
  )
}

export default Layout
