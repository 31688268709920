import noop from 'lodash/noop'
import React, { useReducer } from 'react'
import type { BasicUserDataFragment } from '@carrotcart/data/generated'
import type {
  CartItemDataFragment,
  DiscoveryCollectionCartItemFragment,
} from '@carrotcart/data/graphql/fragments.generated'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import { AnalyticsEventName } from '@carrotcart/common/lib/constants'
import { FindCollectionType } from '@carrotcart/app/types'

export type SignupWallType =
  | 'general'
  | 'gifting'
  | 'liking'
  | 'premium'
  | 'with_custom_callback' // Poorly named but this is to ease the transition to a unified structure

interface SignupWallStateParams {
  cartItem?:
    | CartItemDataFragment
    | DiscoveryCollectionCartItemFragment['cart_item']
  callback?: (loggedIn?: boolean) => Promise<void> | void
  customTitle?: string
  customCopy?: string
  avatarSrc?: string
  user?: BasicUserDataFragment
  collection?: FindCollectionType
  dismissOnSignup?: boolean
  disableBgClick?: boolean
  disableClose?: boolean
  trackingData?: Record<string, unknown>
  type: SignupWallType
}

interface SignupWallState extends SignupWallStateParams {
  show: boolean
}

type SignupWallStateAction =
  | { type: 'show_signup_wall'; params?: SignupWallStateParams }
  | { type: 'hide_signup_wall' }
  | { type: 'clear_signup_wall_data' }

interface SignupWallState extends SignupWallStateParams {
  cartItem?:
    | CartItemDataFragment
    | DiscoveryCollectionCartItemFragment['cart_item']
  callback?: (loggedIn?: boolean) => Promise<void> | void
  show: boolean
}

const DEFAULT_SIGNUP_WALL_STATE: SignupWallState = {
  show: false,
  type: 'general',
  dismissOnSignup: true,
}

interface ISignupWallContext {
  alreadySignedUp?: boolean
  visible: boolean
  openSignupWall: (params?: SignupWallStateParams) => void
  closeSignupWall: () => void
  showSignupWall: boolean
  signupWallParams: SignupWallStateParams
  showMiniOnboarding: boolean
}

const defaultSignupWallContext: ISignupWallContext = {
  alreadySignedUp: false,
  visible: false,
  openSignupWall: noop,
  closeSignupWall: noop,
  showSignupWall: false,
  signupWallParams: { type: 'general' },
  showMiniOnboarding: false,
}

export const SignupWallContext = React.createContext(defaultSignupWallContext)

export const useSignupWallContext = (): ISignupWallContext => {
  return React.useContext<ISignupWallContext>(SignupWallContext)
}

const SignupWallProvider: React.FC = ({ children }) => {
  const { currentUser, isAnAnonymousAuthUser, authUser } = useWebAppContext()
  const showMiniOnboarding = !!(
    !isAnAnonymousAuthUser &&
    currentUser &&
    !currentUser?.has_claimed_username_computed
  )

  const reducer = (
    state: SignupWallState,
    action: SignupWallStateAction
  ): SignupWallState => {
    switch (action.type) {
      case 'show_signup_wall': {
        return {
          ...state,
          cartItem: action.params?.cartItem,
          callback: action.params?.callback,
          customTitle: action.params?.customTitle,
          customCopy: action.params?.customCopy,
          avatarSrc: action.params?.avatarSrc,
          user: action.params?.user,
          collection: action.params?.collection,
          disableBgClick: action.params?.disableBgClick,
          trackingData: action.params?.trackingData,
          disableClose: action.params?.disableClose,
          dismissOnSignup: action.params?.dismissOnSignup,
          type: action.params?.type,
          show: true,
        }
      }
      case 'hide_signup_wall': {
        return {
          ...state,
          show: false,
        }
      }
      case 'clear_signup_wall_data': {
        return {
          ...state,
          cartItem: undefined,
          callback: undefined,
          customTitle: undefined,
          customCopy: undefined,
          avatarSrc: undefined,
          user: undefined,
          collection: undefined,
          disableBgClick: undefined,
          trackingData: undefined,
          disableClose: undefined,
          ...DEFAULT_SIGNUP_WALL_STATE,
        }
      }
      default:
        return state
    }
  }

  const [signupWallState, dispatch] = useReducer(
    reducer,
    DEFAULT_SIGNUP_WALL_STATE
  )

  const openSignupWall = (params?: SignupWallStateParams) => {
    dispatch({ type: 'show_signup_wall', params })
    const trackingData = params?.trackingData || {}

    analytics.track(AnalyticsEventName.ViewedSignupModal, {
      ...trackingData,
    })
  }

  const closeSignupWall = () => {
    dispatch({ type: 'hide_signup_wall' })
    setTimeout(() => {
      dispatch({ type: 'clear_signup_wall_data' })
    }, 500)
  }

  return (
    <SignupWallContext.Provider
      value={{
        alreadySignedUp: !!authUser,
        visible: signupWallState.show,
        openSignupWall,
        closeSignupWall,
        showSignupWall: signupWallState.show,
        signupWallParams: {
          type: signupWallState.type,
          cartItem: signupWallState.cartItem,
          customTitle: signupWallState.customTitle,
          customCopy: signupWallState.customCopy,
          avatarSrc: signupWallState.avatarSrc,
          user: signupWallState.user,
          collection: signupWallState.collection,
          disableBgClick: signupWallState.disableBgClick,
          trackingData: signupWallState.trackingData,
          disableClose: signupWallState.disableClose,
          dismissOnSignup: signupWallState.dismissOnSignup,
          callback: signupWallState.callback,
        },
        showMiniOnboarding,
      }}
    >
      {children}
    </SignupWallContext.Provider>
  )
}

export default SignupWallProvider
