import { useRef } from 'react'
import { EXCLUDE_USERNAMES } from '@carrotcart/common/lib/constants'
import {
  useGetCreatorsToSubscribeRandomQuery,
  GetCreatorsToSubscribeRandomQuery,
} from '@carrotcart/app/data/getCreatorsToSubscribeRandom.generated'

const useGetFeaturedUsersForSearch = ():
  | GetCreatorsToSubscribeRandomQuery['vip_creators'] => {
  const seed = useRef(Math.random().toString()).current

  const { data } = useGetCreatorsToSubscribeRandomQuery({
    variables: {
      exclude_usernames: EXCLUDE_USERNAMES,
      seed,
      include_other_creators: false,
    },
    fetchPolicy: 'cache-and-network',
  })

  return data?.vip_creators || []
}

export default useGetFeaturedUsersForSearch
