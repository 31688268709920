import { HTMLAttributes } from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import Image from 'next/legacy/image'
import extractUtmFromParams from '@carrotcart/client-common/lib/extractUtmFromParams'
import IntlCTA from '@carrotcart/app/components/IntlCTA'
import useBrowserData from '@carrotcart/app/hooks/useBrowserData'
import AppleAppStoreBadge, {
  AppStoreCampaign,
} from '@carrotcart/app/components/AppleAppStoreBadge'

interface LandingCTAButtonProps extends HTMLAttributes<HTMLAnchorElement> {
  placement?: string
  inverted?: boolean
  small?: boolean
  condensedLabel?: boolean
  buttonCopyOverride?: string
  hideIntlLink?: boolean
  hideAppStoreLink?: boolean
  linkRef?: string
  campaign?: AppStoreCampaign
}

const LandingPageCTAButton: React.FC<LandingCTAButtonProps> = ({
  placement,
  inverted,
  small,
  condensedLabel,
  className,
  buttonCopyOverride,
  hideIntlLink,
  hideAppStoreLink,
  linkRef,
  campaign,
}) => {
  const router = useRouter()
  const utmParams = extractUtmFromParams(router.query)

  const browserData = useBrowserData({ ref: linkRef })

  const buttonCopy =
    buttonCopyOverride ||
    (condensedLabel ? (
      <span>Extension</span>
    ) : (
      <>
        <span>Add to </span>
        <span>{browserData.browser || 'Chrome'}</span>
        <span className="hidden lg:inline"> — it&apos;s free</span>
      </>
    ))

  return (
    <div className="space-y-3">
      <a
        href={browserData.linkPath}
        target="_blank"
        rel="noreferrer"
        onClick={() =>
          analytics.track('Clicked Landing CTA', {
            placement,
            copy: buttonCopy,
            browserData,
            ...utmParams,
          })
        }
        className={clsx(
          'button-base inline-flex space-x-2 hover:bg-container-hover rounded-full transition-all whitespace-nowrap',
          {
            'bg-white text-black': inverted,
            'bg-container': !inverted,
            'opacity-0': !browserData.loaded,
            'opacity-100': browserData.loaded,
          },
          className
        )}
      >
        {browserData && browserData.imageSrc && (
          <Image
            src={browserData.imageSrc}
            width={20}
            height={20}
            layout="fixed"
            objectFit="contain"
            objectPosition="center"
          />
        )}
        <span className="pr-[2px]">{buttonCopy}</span>
      </a>

      {!hideAppStoreLink && (
        <div className="md:hidden">
          <AppleAppStoreBadge
            campaign={campaign}
            height={small ? 35 : undefined}
            width={small ? 112 : undefined}
          />
        </div>
      )}

      {!hideIntlLink && <IntlCTA />}
    </div>
  )
}

export default LandingPageCTAButton
