import { Logger } from 'pino'
import { print } from 'graphql'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/core'
import { WrappedError } from '@carrotcart/common/lib/errors'
import { ApolloLink } from '@apollo/client'

export interface ShouldRetryErrorFn {
  (error: ErrorResponse): boolean
}

interface ErrorLinkArgs {
  shouldRetry?: ShouldRetryErrorFn
  logger?: Logger
}

// Log any GraphQL errors or network error that occurred
const errorLink = ({ shouldRetry, logger }: ErrorLinkArgs = {}): ApolloLink =>
  onError((error) => {
    const { forward, operation, ...restError } = error

    if (typeof shouldRetry === 'function' && shouldRetry(error)) {
      return forward(operation)
    }

    const { graphQLErrors } = restError
    const { role, request_id } = operation.getContext()
    const operationExtras = {
      'gql.variables': operation.variables,
      'gql.operation_name': operation.operationName,
      'gql.query': print(operation.query),
      'gql.context.role': role,
      'gql.context.requst_id': request_id,
    }

    if (graphQLErrors) {
      const wrappingErrorName = operation?.operationName
        ? `${operation.operationName} GraphQL error`
        : 'GraphQL error'
      for (let i = 0; i < graphQLErrors.length; i++) {
        const { message, locations, path, originalError, source } =
          graphQLErrors[i]
        const extras = {
          ...operationExtras,
          'gql.error.locations': locations,
          'gql.error.path': path,
          'gql.error.source': source,
        }
        if (logger) {
          const err = new WrappedError(wrappingErrorName, originalError, {
            additionalMessage: message,
            extras,
          })
          logger.error({ err })
        } else {
          Sentry.captureException(
            new WrappedError(wrappingErrorName, originalError, {
              name: `GraphQLError (${operation.operationName})`,
              additionalMessage: message,
            }),
            {
              extra: extras,
            }
          )
        }
      }
    }

    return undefined
  })

export default errorLink
