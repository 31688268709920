import React, { AnchorHTMLAttributes, HTMLAttributes, forwardRef } from 'react'
import clsx from 'clsx'
import { Menu } from '@headlessui/react'
import DropdownMenuContent, {
  Props as DropdownMenuProps,
} from './DropdownMenuContent'
import Link from 'next/link'

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  disableHover?: boolean
}

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  simple?: boolean
}

type SectionComponent = React.FC<HTMLAttributes<HTMLDivElement>>
type DividerComponent = React.FC<HTMLAttributes<HTMLHRElement>>
type ButtonComponent = React.FC<ButtonProps>
type LinkComponent = React.FC<LinkProps>
type MenuComponent = React.FC<DropdownMenuProps> & {
  MenuItem: typeof Menu.Item
  Section?: SectionComponent
  Divider?: DividerComponent
  Button?: ButtonComponent
  Link?: LinkComponent
}

const DropdownMenu: MenuComponent = (props) => {
  return (
    <Menu as="div" className="relative z-20">
      {(menuProps) => <DropdownMenuContent {...menuProps} {...props} />}
    </Menu>
  )
}

const Section: SectionComponent = ({ children, className, ...props }) => {
  return (
    <div className={clsx('bg-white', className)} {...props}>
      {children}
    </div>
  )
}

const Divider: DividerComponent = ({ className, ...props }) => {
  return <hr className={clsx('m-2 border-gray-100', className)} {...props} />
}

const Button: ButtonComponent = ({
  children,
  className,
  disableHover,
  ...props
}) => {
  return (
    <button
      className={clsx(
        'flex items-center w-full px-2 py-2.5 bg-white text-left rounded transition-colors',
        className,
        {
          'hover:bg-gray-100': !disableHover,
        }
      )}
      {...props}
    >
      {children}
    </button>
  )
}

const MenuLink = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { href, children, className, simple, ...rest } = props

  return (
    <Link
      href={href}
      scroll
      ref={ref}
      className={clsx(className, {
        'hover:underline': simple,
        'block px-2 py-2.5 rounded transition-colors hover:bg-gray-100':
          !simple,
      })}
      {...rest}
    >
      {children}
    </Link>
  )
})

DropdownMenu.MenuItem = Menu.Item
DropdownMenu.Section = Section
DropdownMenu.Divider = Divider
DropdownMenu.Button = Button
DropdownMenu.Link = MenuLink

export default DropdownMenu
