export enum PreRelease {
  Dev,
  Alpha,
  Beta,
  Not,
}

type SemVerPart = 'major' | 'minor' | 'patch' | 'prerelease'

const comparator = (a: number, b: number) => {
  return a > b ? 1 : a < b ? -1 : 0
}

export class SemVer {
  major: number
  minor: number
  patch: number
  prerelease: PreRelease
  raw: string

  constructor(version: string) {
    this.raw = version
    const versionNumber = version.replace(/^(\d+\.\d+\.\d+)(.*)/, '$1')
    const semver = versionNumber.split('.')
    this.major = Number(semver[0])
    this.minor = Number(semver[1]) || 0
    this.patch = Number(semver[2]) || 0
    const prerelease = version.replace(
      /(\d+\.\d+\.\d+)-(alpha|beta|dev|)(.*)/,
      '$2'
    )
    switch (prerelease) {
      case 'dev':
        this.prerelease = PreRelease.Dev
        break
      case 'alpha':
        this.prerelease = PreRelease.Alpha
        break
      case 'beta':
        this.prerelease = PreRelease.Beta
        break
      default:
        this.prerelease = PreRelease.Not
    }
  }

  isGreaterThan(versionString: string): boolean {
    return this.comparison(versionString, true, false)
  }

  isGreaterThanOrEqualTo(versionString: string): boolean {
    return this.comparison(versionString, true, true)
  }

  private comparison(
    versionString: string,
    greaterThan: boolean,
    orEqualto: boolean
  ): boolean {
    const version = new SemVer(versionString)
    const parts: SemVerPart[] = ['major', 'minor', 'patch', 'prerelease']
    let val = 0
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i]
      const compareArgs = [this[part]]
      if (greaterThan) {
        compareArgs.push(version[part])
      } else {
        compareArgs.unshift(version[part])
      }
      val = comparator(...(compareArgs as [number, number]))
      if (val > 0) return true
      if (val < 0) return false
    }

    return orEqualto
  }
}
