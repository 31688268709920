import SearchEngineProductSearchAutocomplete from '@carrotcart/app/components/search/SearchEngineProductSearchAutocomplete'
import { useSearchQueryContext } from '@carrotcart/app/context/SearchQueryProvider'

interface Props {
  detached?: boolean
  placeholder?: string
}

const Searchbar: React.FC<Props> = ({ detached, placeholder }) => {
  const { setQuery } = useSearchQueryContext()
  return (
    <SearchEngineProductSearchAutocomplete
      placeholder={placeholder}
      detachedMediaQuery={detached ? '(min-width: 300px)' : undefined}
      setQuery={setQuery}
    />
  )
}

export default Searchbar
