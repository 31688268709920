import noop from 'lodash/noop'
import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { StatusCodes } from 'http-status-codes'
import type { User as AuthUser } from 'firebase/auth'
import { IKContext } from 'imagekitio-react'
import type {
  BasicUserDataFragment,
  FindUserQuery,
} from '@carrotcart/data/generated'
import {
  IMAGEKIT_PUBLIC_KEY,
  IMAGEKIT_URL_ENDPOINT,
  IMAGEKIT_AUTHENTICATION_ENDPOINT,
} from '@carrotcart/common/lib/constants'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'
import useSignIntoExtension, {
  ExtensionState,
  SignIntoExtensionFn,
  DEFAULT_EXTENSION_STATE,
  SignOutOfExtensionFn,
} from '@carrotcart/app/hooks/useSignIntoExtension'
import useBrowserSupport, {
  BrowserSupportData,
} from '@carrotcart/app/hooks/useBrowserSupport'
import Layout from '@carrotcart/app/components/Layout'
import ErrorPage from '@carrotcart/app/components/ErrorPage'
import BillingModeIndicator from '@carrotcart/app/components/BillingModeIndicator'
import useCurrentUser from '@carrotcart/app/hooks/useCurrentUser'
import isAnonymousAuthUser from '@carrotcart/common/lib/isAnonymousAuthUser'
import SignupProvider from '@carrotcart/app/context/SignupProvider'
import IntercomProvider from '@carrotcart/app/context/IntercomProvider'
import PremiumContentProvider from '@carrotcart/app/context/PremiumContentProvider'
import CreatorProvider from '@carrotcart/app/context/CreatorProvider'
import { createUserAuthData } from '@carrotcart/client-common/lib/firebase'
import logger from '@carrotcart/client-common/lib/logger/web'
import { isVersionGreaterThan } from '@carrotcart/app/lib/versionHelper'
import { FindCollectionType } from '@carrotcart/app/types'

interface IWebAppContext {
  authUser: AuthUser | undefined
  isAnAnonymousAuthUser: boolean
  currentUser: FindUserQuery['user'] | undefined
  refetchCurrentUser: () => Promise<void> | void
  loadingAuthUser: boolean
  loadingApp: boolean
  extensionState: ExtensionState
  browserSupport: BrowserSupportData
  notLoggedIntoExtension: boolean
  shouldSignIntoExtension: boolean
  signIntoExtension: SignIntoExtensionFn
  signOutOfExtension: SignOutOfExtensionFn
  isAdminUser: boolean
  clientAuthorized: boolean
  adminEnabled: boolean
  setAdminEnabled: React.Dispatch<React.SetStateAction<boolean>>
  authError: string
  setAuthError: React.Dispatch<React.SetStateAction<string>>
}

const defaultWebAppContext: IWebAppContext = {
  authUser: undefined,
  isAnAnonymousAuthUser: false,
  currentUser: undefined,
  refetchCurrentUser: noop,
  loadingAuthUser: false,
  loadingApp: false,
  extensionState: DEFAULT_EXTENSION_STATE,
  browserSupport: undefined,
  notLoggedIntoExtension: true,
  shouldSignIntoExtension: false,
  signIntoExtension: () => Promise.resolve(),
  signOutOfExtension: () => Promise.resolve(),
  isAdminUser: false,
  clientAuthorized: false,
  adminEnabled: false,
  setAdminEnabled: noop,
  authError: undefined,
  setAuthError: noop,
}

export const WebAppContext = React.createContext(defaultWebAppContext)

export const useWebAppContext = (): IWebAppContext => {
  return React.useContext<IWebAppContext>(WebAppContext)
}

const WebAppProvider: React.FC<{
  authRequired?: boolean
  disableIntercom?: boolean
  withBillingModeIndicator?: boolean
  premiumCollection?: FindCollectionType
  creator?: BasicUserDataFragment
  includeSubscriptions?: boolean
}> = ({
  authRequired,
  disableIntercom,
  withBillingModeIndicator = false,
  premiumCollection,
  creator,
  includeSubscriptions,
  children,
}) => {
  const router = useRouter()
  const { authUser, loading: loadingAuthUser, error: authError } = useAuthUser()

  const clientAuthorized = !!authUser?.uid

  const {
    currentUser,
    loading: loadingCurrentUser,
    refetchCurrentUser,
  } = useCurrentUser()
  const [redirectingToSignin, setRedirectingToSignin] = useState(false)
  const [isAdminUser, setIsAdminUser] = useState(false)
  const [adminEnabled, setAdminEnabled] = useState(false)
  const [authErrorMessage, setAuthError] = useState<string>(authError?.message)

  const { extensionState, signIntoExtension, signOutOfExtension } =
    useSignIntoExtension()

  const shouldSyncUser =
    authUser &&
    currentUser &&
    authUser?.uid !== extensionState.loggedInId &&
    authUser?.uid === currentUser?.id &&
    !currentUser?.anonymous

  const [syncingAuthUser, setSyncingAuthUser] = useState(false)

  const browserSupport = useBrowserSupport()

  const isAnAnonymousAuthUser = isAnonymousAuthUser(authUser)

  const notLoggedIntoExtension =
    !extensionState.signedIn && !extensionState.loading

  const shouldSignIntoExtension =
    extensionState.installed &&
    !extensionState.loading &&
    (!extensionState.signedIn || extensionState.signedInAnonymously)

  const loadingApp = loadingAuthUser || loadingCurrentUser
  const isAdminRoute = /^\/admin\//.test(router.pathname)

  const inLoadingState = loadingApp || loadingAuthUser || redirectingToSignin
  const isAuthRequiredRoute = authRequired || isAdminRoute

  useEffect(() => {
    ;(async () => {
      // check version greater than 0.3.25
      const supportedVersion = await isVersionGreaterThan('0.3.25')
      if (!supportedVersion) return
      if (syncingAuthUser) return
      if (!shouldSyncUser) return
      setSyncingAuthUser(true)

      logger.debug({
        message: 'sync auth user with extension',
        authUser,
        currentUser,
        extensionState,
      })

      await signIntoExtension(
        await createUserAuthData(authUser),
        isAnonymousAuthUser(authUser)
      )
    })()
  }, [
    shouldSyncUser,
    authUser,
    currentUser,
    extensionState,
    signIntoExtension,
    syncingAuthUser,
  ])

  useEffect(() => {
    if (inLoadingState) return
    if (!currentUser?.role) return

    setIsAdminUser(currentUser?.role === 'admin')
  }, [inLoadingState, currentUser?.role, isAuthRequiredRoute])

  useEffect(() => {
    if (inLoadingState) return
    if (!isAuthRequiredRoute) return

    if (!authUser?.uid) {
      setRedirectingToSignin(true)
      router.replace(`/signin?redirect=${encodeURIComponent(router.asPath)}`)
    }
  }, [isAuthRequiredRoute, authUser?.uid, inLoadingState, router])

  if (
    isAuthRequiredRoute &&
    (loadingApp ||
      loadingAuthUser ||
      redirectingToSignin ||
      !currentUser ||
      !authUser)
  ) {
    return null
  }

  if (isAdminRoute && !isAdminUser)
    return (
      <Layout mainClassName="justify-center">
        <ErrorPage
          statusCode={StatusCodes.NOT_FOUND}
          message="This page could not be found."
        />
      </Layout>
    )

  return (
    <WebAppContext.Provider
      value={{
        authUser,
        isAnAnonymousAuthUser,
        authError: authErrorMessage,
        setAuthError,
        currentUser,
        refetchCurrentUser,
        loadingAuthUser,
        loadingApp,
        extensionState,
        browserSupport,
        notLoggedIntoExtension,
        shouldSignIntoExtension,
        signIntoExtension,
        signOutOfExtension,
        isAdminUser,
        clientAuthorized,
        adminEnabled,
        setAdminEnabled,
      }}
    >
      <IntercomProvider disabled={disableIntercom}>
        <SignupProvider>
          <IKContext
            publicKey={IMAGEKIT_PUBLIC_KEY}
            urlEndpoint={IMAGEKIT_URL_ENDPOINT}
            authenticationEndpoint={IMAGEKIT_AUTHENTICATION_ENDPOINT}
          >
            {withBillingModeIndicator && <BillingModeIndicator />}

            {creator ? (
              <CreatorProvider cid={creator.id}>
                <PremiumContentProvider
                  creator={creator}
                  premiumCollection={premiumCollection}
                  includeSubscriptions={includeSubscriptions}
                >
                  {children}
                </PremiumContentProvider>
              </CreatorProvider>
            ) : (
              children
            )}
          </IKContext>
        </SignupProvider>
      </IntercomProvider>
    </WebAppContext.Provider>
  )
}

export default WebAppProvider
