import { SVGProps, memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 15}
    height="100%"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m10.434 9.283-.22.34.286.286 3.116 3.116-.938.951-3.111-3.111-.292-.292-.341.231a5.224 5.224 0 0 1-2.954.899A5.36 5.36 0 0 1 .623 6.346c0-2.96 2.403-5.38 5.357-5.38 2.957 0 5.357 2.4 5.357 5.356 0 1.088-.34 2.095-.903 2.96ZM1.966 6.346A4.01 4.01 0 0 0 5.98 10.36a4.01 4.01 0 0 0 4.014-4.014A4.01 4.01 0 0 0 5.98 2.33a4.01 4.01 0 0 0-4.014 4.015Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(SvgComponent)
export default Memo
