import typesense from 'typesense'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import {
  TYPESENSE_API_KEY,
  TYPESENSE_COLLECTION_INDEX,
  TYPESENSE_HOST,
  TYPESENSE_PRODUCTS_INDEX,
} from '@carrotcart/common/lib/constants'

export {
  TYPESENSE_API_KEY,
  TYPESENSE_HOST,
  TYPESENSE_QUERY_SUGGESTIONS_INDEX,
  TYPESENSE_COLLECTION_INDEX,
  TYPESENSE_PRODUCTS_INDEX,
} from '@carrotcart/common/lib/constants'

export const nodes = [
  {
    host: TYPESENSE_HOST as string,
    port: 443,
    protocol: 'https',
  },
]

// Initialize the Typesense client
export const typesenseClient = new typesense.Client({
  nodes,
  apiKey: TYPESENSE_API_KEY as string,
})

export const searchClient = new TypesenseInstantSearchAdapter({
  server: {
    nodes,
    apiKey: TYPESENSE_API_KEY as string,
  },
  collectionSpecificSearchParameters: {
    [TYPESENSE_COLLECTION_INDEX as string]: {
      query_by: 'name, slug, item_titles, shop_categories.label, shops.label',
    },
    [TYPESENSE_PRODUCTS_INDEX as string]: {
      query_by:
        'display_title, related_search_terms.query, shop.label, shop_categories.label, description, keywords, categories',
    },
  },
}).searchClient
