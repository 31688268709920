import Link from 'next/link'
import ArchieEdgy from '@carrotcart/app/components/svg/ArchieEdgy'
import SearchIcon from '@carrotcart/app/components/svg/SearchIcon'

interface Props {
  hideLogo?: boolean
}

const CarrotHeader: React.FC<Props> = ({ hideLogo }) => {
  return (
    <nav
      className="relative flex items-center justify-center bg-[#F6F6F2] h-[36px]"
      style={{
        boxShadow: 'inset 0px -3px 4px rgba(0, 0, 0, 0.03)',
      }}
    >
      <div
        className="absolute inset-0 opacity-[0.05]"
        style={{
          background: 'url(/images/noise.png)',
        }}
      />
      <div className="relative flex items-center space-x-3 md:space-x-4">
        {!hideLogo && (
          <Link
            href="/"
            className="-mb-2 text-black opacity-80 hover:opacity-100 transition-opacity"
          >
            <ArchieEdgy height={28} width={28} />
          </Link>
        )}
        <Link
          href="/shop"
          className="px-2 md:px-3 text-sm text-black text-opacity-70 hover:text-opacity-100 whitespace-nowrap transition-colors"
        >
          Shop
        </Link>
        <Link
          href="/deals"
          className="px-2 md:px-3 text-sm text-black text-opacity-70 hover:text-opacity-100 whitespace-nowrap transition-colors"
        >
          Deals
        </Link>
        <Link
          href="https://blog.carrot.link/"
          target="_blank"
          rel="noreferrer"
          className="px-2 md:px-3 text-sm text-black text-opacity-70 hover:text-opacity-100 whitespace-nowrap transition-colors"
        >
          Blog
        </Link>
        <Link
          href="/search"
          target="_blank"
          rel="noreferrer"
          className="pl-3 text-sm text-black text-opacity-70 hover:text-opacity-100 whitespace-nowrap transition-colors"
        >
          <SearchIcon />
        </Link>
      </div>
    </nav>
  )
}

export default CarrotHeader
