import {
  CURRENT_EXTENSION_VERSION_DATA_ATTRIBUTE,
  CARROT_EXTENSION_DATA_ELEMENT_ID,
} from '@carrotcart/common/lib/constants'
import { SemVer } from '@carrotcart/client-common/lib/versionHelper'
import logger from '@carrotcart/client-common/lib/logger/web'

let currentSemVer: SemVer
let alreadyChecked = false

const getCurrentVersionElement = async (
  retries = 0
): Promise<HTMLElement | null> => {
  if (retries > 4) return null

  const el = document.getElementById(CARROT_EXTENSION_DATA_ELEMENT_ID)
  if (el) return el

  await new Promise((r) => setTimeout(r, 100))
  return await getCurrentVersionElement(retries + 1)
}

const currentVersion = async (): Promise<SemVer | undefined> => {
  if (alreadyChecked) return currentSemVer
  try {
    const el = await getCurrentVersionElement()
    if (!el) {
      return undefined
    }

    const version = el.dataset[CURRENT_EXTENSION_VERSION_DATA_ATTRIBUTE]
    if (!version) return undefined
    currentSemVer = new SemVer(version)
    return currentSemVer
  } catch (err) {
    logger.error({ err })
    return undefined
  } finally {
    alreadyChecked = true
  }
}

export const isVersionGreaterThan = async (
  version: string
): Promise<boolean> => {
  const current = await currentVersion()
  if (!current) return false

  const val = current.isGreaterThan(version)
  return val
}

export const isVersionGreaterThanOrEqualTo = async (
  version: string
): Promise<boolean> => {
  const current = await currentVersion()
  if (!current) return false

  return current.isGreaterThanOrEqualTo(version)
}
