import {
  ApolloLink,
  ApolloClient,
  split,
  HttpLink,
  HttpOptions,
  NormalizedCacheObject,
  FetchPolicy,
  ApolloCache,
  InMemoryCache,
  ApolloClientOptions,
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { Logger } from 'pino'
import {
  notFoundLink,
  errorLink,
  requestIdLink,
  loggingLink,
  retryLink,
  timeoutLink,
  ShouldRetryErrorFn,
} from './links'
import { GRAPHQL_HTTP_ENDPOINT } from '@carrotcart/common/lib/constants'

export interface ClientOptions {
  cache?: ApolloCache<NormalizedCacheObject>
  ssrMode?: boolean
  headers?: HttpOptions['headers']
  fetch?: WindowOrWorkerGlobalScope['fetch']
  fetchPolicy?: FetchPolicy
  authLink?: ApolloLink
  wsLink?: GraphQLWsLink | null
  shouldRetryError?: ShouldRetryErrorFn
  logger?: Logger
}

export const initClientConfig = (
  clientOptions?: ClientOptions
): ApolloClientOptions<NormalizedCacheObject> => {
  const gqlFetch = typeof fetch !== 'undefined' ? fetch : clientOptions?.fetch

  const headers = clientOptions?.headers || {}

  const httpLink = new HttpLink({
    uri: GRAPHQL_HTTP_ENDPOINT,
    headers,
    fetch: gqlFetch,
  })

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = clientOptions?.wsLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query)

          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          )
        },
        clientOptions.wsLink,
        httpLink
      )
    : httpLink

  const links = [requestIdLink, loggingLink, splitLink]
  if (clientOptions?.authLink) {
    links.unshift(clientOptions.authLink)
  }

  links.unshift(notFoundLink)
  const errorLinkOptions: Parameters<typeof errorLink>[0] = {}
  if (clientOptions?.shouldRetryError) {
    errorLinkOptions.shouldRetry = clientOptions?.shouldRetryError
  }
  if (clientOptions?.logger) {
    errorLinkOptions.logger = clientOptions?.logger
  }
  links.unshift(errorLink(errorLinkOptions))
  links.unshift(retryLink)
  links.unshift(timeoutLink)

  return {
    link: ApolloLink.from(links),
    cache: clientOptions?.cache || new InMemoryCache(),
    ssrMode: clientOptions?.ssrMode || typeof window === 'undefined',
    connectToDevTools: process.env.NODE_ENV !== 'production',
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
        ...(clientOptions?.fetchPolicy
          ? { fetchPolicy: clientOptions.fetchPolicy }
          : {}),
      },
      query: {
        errorPolicy: 'all',
        ...(clientOptions?.fetchPolicy
          ? { fetchPolicy: clientOptions.fetchPolicy }
          : {}),
      },
      mutate: {
        errorPolicy: 'all',
        ...(clientOptions?.fetchPolicy === 'no-cache' ||
        clientOptions?.fetchPolicy === 'network-only'
          ? { fetchPolicy: clientOptions.fetchPolicy }
          : {}),
      },
    },
  }
}

export const initClient = (
  clientOptions?: ClientOptions
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient(initClientConfig(clientOptions))
}
