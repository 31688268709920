import { useEffect, useState } from 'react'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'

const useIsOwnPage = (
  userId?: string | undefined | null
): {
  isOwnPage: boolean
  loadingPersistedAuthUserId: boolean
} => {
  const { persistedAuthUserId, loadingPersistedAuthUserId } = useAuthUser()
  const [isOwnPage, setIsOwnPage] = useState(persistedAuthUserId === userId)

  useEffect(() => {
    setIsOwnPage(persistedAuthUserId === userId)
  }, [persistedAuthUserId, userId])

  return {
    isOwnPage,
    loadingPersistedAuthUserId,
  }
}

export default useIsOwnPage
