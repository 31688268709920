import { useCallback, useEffect, useReducer } from 'react'
import { UserAuthData } from '@carrotcart/common/types'
import { BackgroundMessageType } from '@carrotcart/common/lib/constants'
import waitForBgResponse from '@carrotcart/app/lib/waitForBgResponse'
import logger from '@carrotcart/client-common/lib/logger/web'

type ExtensionStateAction =
  | {
      type: 'signed_in'
      version?: string
      loggedInId?: string
      isSafari?: boolean
    }
  | { type: 'signed_out'; version?: string; isSafari?: boolean }
  | { type: 'signed_in_anonymous'; version?: string; isSafari?: boolean }
  | { type: 'extension_uninstalled' }

export interface ExtensionState {
  signedIn: boolean
  loading: boolean
  installed: boolean
  version?: string
  signedInAnonymously?: boolean
  loggedInId?: string
  isSafari?: boolean
}

export interface SignIntoExtensionFn {
  (userAuthData: UserAuthData, isAnonymous?: boolean): Promise<void>
}

export interface SignOutOfExtensionFn {
  (): Promise<void>
}

export const DEFAULT_EXTENSION_STATE: ExtensionState = {
  signedIn: false,
  loading: true,
  installed: false,
  version: undefined,
  signedInAnonymously: undefined,
  loggedInId: undefined,
  isSafari: undefined,
}

const useSignIntoExtension = (): {
  extensionState: ExtensionState
  signIntoExtension: SignIntoExtensionFn
  signOutOfExtension: SignOutOfExtensionFn
} => {
  const reducer = (
    state: ExtensionState,
    action: ExtensionStateAction
  ): ExtensionState => {
    switch (action.type) {
      case 'signed_in': {
        return {
          ...state,
          loading: false,
          signedIn: true,
          installed: true,
          version: action.version,
          signedInAnonymously: false,
          loggedInId: action.loggedInId,
          isSafari: action?.isSafari,
        }
      }
      case 'signed_in_anonymous': {
        return {
          ...state,
          loading: false,
          signedIn: true,
          installed: true,
          version: action.version,
          signedInAnonymously: true,
          isSafari: action?.isSafari,
        }
      }
      case 'signed_out': {
        return {
          ...state,
          loading: false,
          signedIn: false,
          installed: true,
          version: action.version,
          signedInAnonymously: false,
          isSafari: action?.isSafari,
        }
      }
      case 'extension_uninstalled': {
        return {
          ...state,
          loading: false,
          installed: false,
          isSafari: false,
        }
      }
      default:
        return state
    }
  }

  const [extensionState, dispatch] = useReducer(
    reducer,
    DEFAULT_EXTENSION_STATE
  )

  const signIntoExtension: SignIntoExtensionFn = useCallback(
    async (userAuthData: UserAuthData, isAnonymous: boolean | undefined) => {
      logger.debug({ message: 'attempt to sign in to extension', userAuthData })
      const { response, installed } = await waitForBgResponse({
        type: BackgroundMessageType.AuthSignin,
        data: userAuthData,
        timeout: 1500,
        retries: 3,
      })
      if (!installed || !response || response.message !== 'OK') {
        logger.error(
          'Unable to sign the user into the extension. The background script responded with an error',
          {
            user: { id: userAuthData.uid },
            extra: { response, installed },
          }
        )
      } else {
        dispatch({ type: isAnonymous ? 'signed_in_anonymous' : 'signed_in' })
      }
    },
    [dispatch]
  )

  const signOutOfExtension: SignOutOfExtensionFn = useCallback(async () => {
    const { response } = await waitForBgResponse({
      type: BackgroundMessageType.AuthSignout,
      timeout: 1500,
      retries: 3,
    })

    if (!response || response.message !== 'OK') {
      logger.error({
        message:
          'Unable to sign the user out of the extension. The background script responded with an error',
        'context.response': response,
      })
    } else {
      dispatch({ type: 'signed_out' })
    }
  }, [dispatch])

  useEffect(() => {
    ;(async () => {
      // TODO: Remove the check for the boolean return type once we feel like
      // enough users have updated their extension versions
      const { installed, response } = await waitForBgResponse<
        | {
            signedIn: boolean
            version?: string
            anonymous?: boolean
            loggedInId?: string
            isSafari?: boolean
          }
        | boolean
      >({
        type: BackgroundMessageType.CheckIfSignedIn,
        timeout: 20,
        retries: 5,
      })

      if (!installed) {
        dispatch({ type: 'extension_uninstalled' })
      } else {
        if (!response || response.error === 'ERROR') {
          logger.error({
            message:
              'Unexpected error happened when trying to check if the user is signed into the extension',
            'context.response': response,
          })
          dispatch({ type: 'signed_out' })
        } else {
          if (typeof response.data === 'boolean') {
            if (response.data) {
              dispatch({
                type: 'signed_in',
                version: undefined,
              })
            } else {
              dispatch({
                type: 'signed_out',
                version: undefined,
              })
            }
          } else {
            const { signedIn, version, anonymous, loggedInId, isSafari } =
              response?.data || {}

            if (signedIn) {
              dispatch({
                type: anonymous ? 'signed_in_anonymous' : 'signed_in',
                version,
                loggedInId: anonymous ? undefined : loggedInId,
                isSafari,
              })
            } else {
              dispatch({ type: 'signed_out', version, isSafari })
            }
          }
        }
      }
    })()
  }, [dispatch])

  return { extensionState, signIntoExtension, signOutOfExtension }
}

export default useSignIntoExtension
