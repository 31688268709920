import { SVGProps, memo } from 'react'

const MenuWalletIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1471_3438)">
      <path
        d="M3.20561 2.99975L15.0659 6.37C15.8688 6.63371 16.5701 7.14006 17.0731 7.81919C17.576 8.49832 17.8558 9.31677 17.8739 10.1617V21.5475C17.9077 21.907 17.8535 22.2692 17.7158 22.603C17.5782 22.9368 17.3613 23.232 17.084 23.4632C16.8066 23.6944 16.4771 23.8545 16.124 23.9297C15.7709 24.005 15.4048 23.9931 15.0573 23.8951L5.2542 21.2247C4.45117 20.9748 3.74708 20.4787 3.24158 19.8066C2.73608 19.1345 2.45479 18.3204 2.43753 17.4796V5.07867C2.41974 4.29157 2.71401 3.52939 3.25612 2.95847C3.79822 2.38754 4.54415 2.05422 5.33111 2.03125H20.3114C21.1734 2.03125 22.0001 2.37366 22.6095 2.98315C23.219 3.59265 23.5614 4.4193 23.5614 5.28125V14.9803C23.5576 15.4185 23.4674 15.8516 23.2959 16.2548C23.1244 16.658 22.875 17.0234 22.562 17.3301C22.249 17.6367 21.8786 17.8786 21.4719 18.0418C21.0653 18.205 20.6304 18.2864 20.1923 18.2813H17.875"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4052 13.4062C13.1642 13.4065 12.9286 13.4781 12.7284 13.6122C12.5281 13.7462 12.372 13.9366 12.28 14.1594C12.1879 14.3821 12.1639 14.6271 12.2111 14.8634C12.2582 15.0998 12.3744 15.3168 12.5448 15.4872C12.7153 15.6575 12.9325 15.7735 13.1689 15.8204C13.4053 15.8673 13.6503 15.8432 13.8729 15.7509C14.0955 15.6586 14.2858 15.5024 14.4197 15.302C14.5535 15.1016 14.625 14.866 14.625 14.625C14.625 14.3018 14.4966 13.9918 14.268 13.7632C14.0395 13.5347 13.7295 13.4062 13.4063 13.4062H13.4052Z"
        fill="currentColor"
      />
      <path
        d="M23.5614 10.1562H17.8739"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1471_3438">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const Memo = memo(MenuWalletIcon)
export default Memo
