import { IMAGEKIT_URL_ENDPOINT } from '@carrotcart/common/lib/constants'

interface Props {
  src: string | undefined | null
  width: number
  quality?: number
  dpr?: string | number
  rounded?: boolean
}

const imageKitLoader = ({
  src,
  width,
  quality = 75,
  dpr = 'auto',
  rounded = false,
}: Props): string => {
  if (!src) return ''
  if (src[0] === '/') src = src.slice(1)

  const params = new Set([`w-${width}`, `dpr-${dpr}`, 'fo-auto', 'f-auto'])

  if (quality) {
    params.add(`q-${quality}`)
  }

  if (rounded) {
    params.add('r-max')
    params.add(`h-${width}`)
    params.add(`w-${width}`)
  }

  if (src.toLocaleLowerCase().startsWith('https://ik.imagekit.io/carrot')) {
    const srcUrl = new URL(src)
    const existingTransformationParams = new Set(
      (srcUrl.searchParams.get('tr') || '').split(',')
    )

    existingTransformationParams.forEach((param) => {
      if (param && !param.startsWith('w-')) {
        params.add(param)
      }
    })

    // convert existing /tr: transformations to query params
    // and append the new ones
    const existingTransformationMatches = src.match(/\/tr:[^/]+/)

    if (existingTransformationMatches) {
      const existingTransformationsString = existingTransformationMatches[0]
      const existingTransformationsMatchParams = existingTransformationsString
        .replace('/tr:', '')
        .split(',')

      existingTransformationsMatchParams.forEach((param) => {
        if (!param.startsWith('w-')) {
          params.add(param)
        }
      })
    }

    try {
      const url = new URL(src)
      url.searchParams.set('tr', Array.from(params).join(','))

      // replace the existing transformation parameters
      // with the new ones passed in
      const urlString = url.toString().replace(/\/tr:[^/]+/, '')
      return urlString
    } catch (err) {
      return src
    }
  }

  let urlEndpoint = IMAGEKIT_URL_ENDPOINT
  if (urlEndpoint[urlEndpoint.length - 1] === '/') {
    urlEndpoint = urlEndpoint.substring(0, urlEndpoint.length - 1)
  }

  const imageUrl = `${urlEndpoint}/${encodeURIComponent(src)}?tr=${Array.from(
    params
  ).join(',')}`

  return imageUrl
}

export default imageKitLoader
