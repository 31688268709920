import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import useBrowserSupport from '@carrotcart/app/hooks/useBrowserSupport'
import { CHROME_WEB_STORE_URL } from '@carrotcart/common/lib/constants'

interface BrowserData {
  linkPath: string
  supported: boolean
  loaded: boolean
  browser: string
  imageSrc: string
}

const BROWSER_IMAGE_MAPPING = {
  Brave: '/images/browsers/brave.png',
  Chrome: '/images/browsers/chrome.png',
  Edge: '/images/browsers/edge.png',
}

const useBrowserData = ({ ref }: { ref?: string } = {}): BrowserData => {
  const router = useRouter()
  const { query } = router
  const queryParams = { ...query, ref }
  const { supported, loaded, browser } = useBrowserSupport()
  const [imageSrc, setImageSrc] = useState(BROWSER_IMAGE_MAPPING.Chrome)

  let linkPath = CHROME_WEB_STORE_URL

  try {
    const linkUrl = new URL(linkPath)

    // append query params to the link so we can
    // capture and track any UTM params
    Object.keys(queryParams).forEach((key) =>
      linkUrl.searchParams.append(key, queryParams[key])
    )

    linkPath = linkUrl.toString()
  } catch (err) {
    // ignore
  }

  useEffect(() => {
    if (loaded && supported) {
      setImageSrc(BROWSER_IMAGE_MAPPING[browser])
    }
  }, [loaded, supported, browser])

  return {
    linkPath,
    supported,
    loaded,
    browser,
    imageSrc,
  }
}

export default useBrowserData
