import {
  getRGBColor,
  getRGBDarkColor,
  getAccessibleColor,
} from '@carrotcart/client-common/lib/colorHelpers'
import type { BasicUserDataFragment } from '@carrotcart/data/graphql/fragments.generated'
import tailwindConfig from '@carrotcart/app/tailwind.config'

const useCreatorColors = (
  creator?: BasicUserDataFragment
): {
  hasColorOverride: boolean
  primaryColor?: string
  primaryColorDark?: string
  a11yColor?: string
} => {
  const hasColorOverride = !!creator?.metadata?.primary_color_hex
  const creatorPrimaryColorHex =
    creator?.metadata?.primary_color_hex ||
    tailwindConfig.theme.extend.colors.black

  const primaryColorRGB = getRGBColor(creatorPrimaryColorHex)
  const primaryColor = `--color-primary: ${primaryColorRGB};`

  const primaryColorDarkRGB = getRGBDarkColor(creatorPrimaryColorHex, 0.25)
  const primaryColorDark = `--color-primary-dark: ${primaryColorDarkRGB};`

  const a11yColorRGB = getRGBColor(
    getAccessibleColor({
      hex: creatorPrimaryColorHex,
    })
  )
  const a11yColor = `--color-a11y: ${a11yColorRGB};`

  return {
    hasColorOverride,
    primaryColor,
    primaryColorDark,
    a11yColor,
  }
}

export default useCreatorColors
