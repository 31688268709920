import retry from 'async-retry'
import { MessageListener, TMessageResponse } from '@carrotcart/common/types'
import PostWindowMessage, {
  getPostMessageData,
  PostWindowMessageType,
  TAwaitBgResponse,
  TPostMessage,
} from '@carrotcart/client-common/lib/PostWindowMessage'
import {
  BackgroundMessageType,
  MessageListenerRuntime,
} from '@carrotcart/common/lib/constants'

interface WaitForBgResponseArgs<Y> {
  type: BackgroundMessageType
  data?: Y
  timeout?: number
  retries?: number
}

// `T` is the expected return value from the bg script that is
// going to be passed into the passed in callback function.
// `Y` is the data that is being passed to the bg script
async function waitForBgResponse<T = any, Y = any>(
  args: WaitForBgResponseArgs<Y>
): Promise<{ installed: boolean; response?: TMessageResponse<T> }> {
  try {
    let listener: MessageListener<TPostMessage<any>>
    const timeout = args.timeout || 50
    const retries = args.retries || 5

    return await retry(
      async (_bail) => {
        const forBgResponse = new Promise<TMessageResponse<T>>(
          (resolve, reject) => {
            listener = PostWindowMessage.postMessageListener(
              MessageListenerRuntime.WebApp,
              async (data) => {
                switch (data.action) {
                  case PostWindowMessageType.AwaitBackgroundResponse: {
                    const val = getPostMessageData<TMessageResponse<T>>(data)
                    resolve(val)
                  }
                }
              }
            )

            window.addEventListener('message', listener)

            setTimeout(() => {
              window.removeEventListener('message', listener)
              reject(
                new Error(
                  'Trying to get a response from the bg script timed out'
                )
              )
            }, timeout)
          }
        )

        const bgMessage: TAwaitBgResponse = { type: args.type }
        if (args.data) bgMessage.data = args.data

        PostWindowMessage.postMessage<TAwaitBgResponse<T>>(
          MessageListenerRuntime.ContentScript,
          PostWindowMessageType.AwaitBackgroundResponse,
          bgMessage
        )

        const val = await forBgResponse

        if (listener) window.removeEventListener('message', listener)

        return { installed: true, response: val }
      },
      {
        retries,
        minTimeout: 10,
      }
    )
  } catch {
    // returning false here means we did not get a response
    // from the background script, so most likely the extension
    // is not installed
    return { installed: false }
  }
}

export default waitForBgResponse
