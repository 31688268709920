import type * as Types from '../../data/generated/types'

import type { BasicUserDataFragment } from '../../data/graphql/fragments.generated'
import { gql } from '@apollo/client'
import { BasicUserDataFragmentDoc } from '../../data/graphql/fragments.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type SearchForUsersWebQueryVariables = Types.Exact<{
  query: Types.Scalars['String']
}>

export type SearchForUsersWebQuery = {
  __typename?: 'query_root'
  users: Array<{ __typename?: 'user' } & BasicUserDataFragment>
}

export const SearchForUsersWebDocument = gql`
  query SearchForUsersWeb($query: String!) {
    users: users_search(args: { search: $query }) {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useSearchForUsersWebQuery__
 *
 * To run a query within a React component, call `useSearchForUsersWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchForUsersWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchForUsersWebQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchForUsersWebQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchForUsersWebQuery,
    SearchForUsersWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchForUsersWebQuery,
    SearchForUsersWebQueryVariables
  >(SearchForUsersWebDocument, options)
}
export function useSearchForUsersWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchForUsersWebQuery,
    SearchForUsersWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchForUsersWebQuery,
    SearchForUsersWebQueryVariables
  >(SearchForUsersWebDocument, options)
}
export type SearchForUsersWebQueryHookResult = ReturnType<
  typeof useSearchForUsersWebQuery
>
export type SearchForUsersWebLazyQueryHookResult = ReturnType<
  typeof useSearchForUsersWebLazyQuery
>
export type SearchForUsersWebQueryResult = Apollo.QueryResult<
  SearchForUsersWebQuery,
  SearchForUsersWebQueryVariables
>
