import { useEffect, useState } from 'react'
import { FeatureFlag } from '@carrotcart/common/lib/split'
import type { TrafficType } from '@carrotcart/client-common/lib/split'
import {
  useSplitContext,
  splitLib,
  splits,
  updateSplit,
} from '@carrotcart/client-common/context/SplitProvider'

const isExpired = (date: Date): boolean => {
  return date.valueOf() < Date.now()
}

// IMPORTANT: must have the <SplitProvider> wrapping the components where you want to
// use this React hook.
const useIsFeatureOn = (
  feature: FeatureFlag,
  trafficType: TrafficType = 'user',
  track?: boolean
): { ready: boolean; loading: boolean; on: boolean | undefined } => {
  const { userClientReady, anonymousClientReady } = useSplitContext()
  const memoizedFlag = splits[feature]
  // Using a ttl here. Really, this is for when we are client-side routing. Each time
  // we navigate to a new page or we render a new component with this hook, it will wait
  // some time initialize the value, which is unecessary since we should already know the value.
  const [ttl, setTtl] = useState<Date | undefined>(memoizedFlag?.ttl)
  const featureOn = memoizedFlag?.on
  const isReady =
    trafficType === 'anonymous' ? anonymousClientReady : userClientReady

  useEffect(() => {
    ;(async () => {
      if (!isReady) return
      if (!feature) return
      if (typeof ttl !== 'undefined' && !isExpired(ttl)) return
      const shouldTrack =
        typeof track === 'boolean' ? track : trafficType !== 'anonymous'
      const isOn = await splitLib?.isFeatureOn(
        feature,
        trafficType,
        shouldTrack
      )
      const newTtl = updateSplit(feature, isOn)
      setTtl(newTtl)
    })()
  }, [feature, featureOn, isReady, track, trafficType, ttl])

  const loading = isReady && typeof featureOn === 'undefined'

  return { ready: isReady, loading, on: featureOn }
}

export default useIsFeatureOn
