import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  _text: any
  _uuid: any
  bigint: any
  cidr: any
  date: any
  float8: any
  json: any
  jsonb: any
  name: any
  numeric: any
  seed_float: any
  smallint: any
  timestamp: any
  timestamptz: any
  uuid: any
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>
  _gt?: Maybe<Scalars['Boolean']>
  _gte?: Maybe<Scalars['Boolean']>
  _in?: Maybe<Array<Scalars['Boolean']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['Boolean']>
  _lte?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Scalars['Boolean']>
  _nin?: Maybe<Array<Scalars['Boolean']>>
}

export type CollectionCollaboratorInviteInput = {
  collection_id: Scalars['uuid']
  email?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

export type CollectionInviteOutput = {
  __typename?: 'CollectionInviteOutput'
  collection_invite?: Maybe<Collection_Invite>
  collection_invite_id?: Maybe<Scalars['uuid']>
}

export type ContactConnection = {
  __typename?: 'ContactConnection'
  contact_id: Scalars['String']
  friends_on_platform: Scalars['Int']
}

export type ContactSubscriptionSuggestion = {
  __typename?: 'ContactSubscriptionSuggestion'
  contact_id?: Maybe<Scalars['String']>
  degrees_of_separation: Scalars['Int']
  mutual_connections?: Maybe<Scalars['Int']>
  user?: Maybe<User>
  user_id: Scalars['String']
}

export type CreateSubscriptionOutput = {
  __typename?: 'CreateSubscriptionOutput'
  user_subscription?: Maybe<User_Subscription>
  user_subscription_id: Scalars['uuid']
}

export type CreatorSubscriberChartDataOutput = {
  __typename?: 'CreatorSubscriberChartDataOutput'
  day?: Maybe<Scalars['String']>
  paid_subscribers?: Maybe<Scalars['Int']>
  subscribers?: Maybe<Scalars['Int']>
  total_paid_subscribers?: Maybe<Scalars['Int']>
  total_subscribers?: Maybe<Scalars['Int']>
}

export type FeedItemLikeInput = {
  feed_item_id: Scalars['uuid']
  value: Scalars['Boolean']
}

export type FeedItemLikeOutput = {
  __typename?: 'FeedItemLikeOutput'
  feed_item_like?: Maybe<Feed_Item_Like>
  feed_item_like_id: Scalars['uuid']
}

export type FullyUnsubscribeOutput = {
  __typename?: 'FullyUnsubscribeOutput'
  user_subscription_id: Scalars['String']
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>
  _gt?: Maybe<Scalars['Int']>
  _gte?: Maybe<Scalars['Int']>
  _in?: Maybe<Array<Scalars['Int']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['Int']>
  _lte?: Maybe<Scalars['Int']>
  _neq?: Maybe<Scalars['Int']>
  _nin?: Maybe<Array<Scalars['Int']>>
}

export enum RecurringInterval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

export type SearchProductsOutput = {
  __typename?: 'SearchProductsOutput'
  id?: Maybe<Scalars['uuid']>
  pagination?: Maybe<Scalars['String']>
  search_query?: Maybe<Search_Query>
}

export type ShoppingProductOutput = {
  __typename?: 'ShoppingProductOutput'
  delivery?: Maybe<Scalars['String']>
  extracted_price?: Maybe<Scalars['Float']>
  link?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['String']>
  product_link?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  tag?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SimilarProductOutput = {
  __typename?: 'SimilarProductOutput'
  cart_Item?: Maybe<Cart_Item>
  cart_item_id?: Maybe<Scalars['uuid']>
  link?: Maybe<Scalars['String']>
  max_take_rate?: Maybe<Scalars['Int']>
  original_link?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  position?: Maybe<Scalars['Int']>
  price?: Maybe<SimilarProductPrice>
  shop_item?: Maybe<Shop_Item>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_popularity?: Maybe<Scalars['Int']>
  source?: Maybe<Scalars['String']>
  source_icon?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SimilarProductPrice = {
  __typename?: 'SimilarProductPrice'
  currency?: Maybe<Scalars['String']>
  extracted_value?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: Maybe<Array<Scalars['String']>>
  /** does the array contain the given value */
  _contains?: Maybe<Array<Scalars['String']>>
  _eq?: Maybe<Array<Scalars['String']>>
  _gt?: Maybe<Array<Scalars['String']>>
  _gte?: Maybe<Array<Scalars['String']>>
  _in?: Maybe<Array<Array<Scalars['String']>>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Array<Scalars['String']>>
  _lte?: Maybe<Array<Scalars['String']>>
  _neq?: Maybe<Array<Scalars['String']>>
  _nin?: Maybe<Array<Array<Scalars['String']>>>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>
  _gt?: Maybe<Scalars['String']>
  _gte?: Maybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>
  _in?: Maybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>
  _is_null?: Maybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>
  _lt?: Maybe<Scalars['String']>
  _lte?: Maybe<Scalars['String']>
  _neq?: Maybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>
  _nin?: Maybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>
}

export type Subscription = {
  __typename?: 'Subscription'
  currency: Scalars['String']
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  interval: RecurringInterval
  name: Scalars['String']
  trial_period_days?: Maybe<Scalars['Int']>
  unit_amount: Scalars['Int']
}

export type WrappedNotificationCenterItem = {
  __typename?: 'WrappedNotificationCenterItem'
  body?: Maybe<Scalars['String']>
  created_at: Scalars['String']
  id: Scalars['String']
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  navigation?: Maybe<Scalars['json']>
  read_at?: Maybe<Scalars['String']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id: Scalars['Int']
  type: Scalars['String']
}

/** Account deletion reasons enum table */
export type Account_Deletion_Reason = {
  __typename?: 'account_deletion_reason'
  description: Scalars['String']
  value: Scalars['String']
}

/** aggregated selection of "account_deletion_reason" */
export type Account_Deletion_Reason_Aggregate = {
  __typename?: 'account_deletion_reason_aggregate'
  aggregate?: Maybe<Account_Deletion_Reason_Aggregate_Fields>
  nodes: Array<Account_Deletion_Reason>
}

/** aggregate fields of "account_deletion_reason" */
export type Account_Deletion_Reason_Aggregate_Fields = {
  __typename?: 'account_deletion_reason_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Account_Deletion_Reason_Max_Fields>
  min?: Maybe<Account_Deletion_Reason_Min_Fields>
}

/** aggregate fields of "account_deletion_reason" */
export type Account_Deletion_Reason_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Deletion_Reason_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "account_deletion_reason". All fields are combined with a logical 'AND'. */
export type Account_Deletion_Reason_Bool_Exp = {
  _and?: Maybe<Array<Account_Deletion_Reason_Bool_Exp>>
  _not?: Maybe<Account_Deletion_Reason_Bool_Exp>
  _or?: Maybe<Array<Account_Deletion_Reason_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "account_deletion_reason" */
export enum Account_Deletion_Reason_Constraint {
  /** unique or primary key constraint on columns "value" */
  AccountDeletionReasonPkey = 'account_deletion_reason_pkey',
}

/** input type for inserting data into table "account_deletion_reason" */
export type Account_Deletion_Reason_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Account_Deletion_Reason_Max_Fields = {
  __typename?: 'account_deletion_reason_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Account_Deletion_Reason_Min_Fields = {
  __typename?: 'account_deletion_reason_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "account_deletion_reason" */
export type Account_Deletion_Reason_Mutation_Response = {
  __typename?: 'account_deletion_reason_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Account_Deletion_Reason>
}

/** on_conflict condition type for table "account_deletion_reason" */
export type Account_Deletion_Reason_On_Conflict = {
  constraint: Account_Deletion_Reason_Constraint
  update_columns?: Array<Account_Deletion_Reason_Update_Column>
  where?: Maybe<Account_Deletion_Reason_Bool_Exp>
}

/** Ordering options when selecting data from "account_deletion_reason". */
export type Account_Deletion_Reason_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: account_deletion_reason */
export type Account_Deletion_Reason_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "account_deletion_reason" */
export enum Account_Deletion_Reason_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "account_deletion_reason" */
export type Account_Deletion_Reason_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "account_deletion_reason" */
export type Account_Deletion_Reason_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Deletion_Reason_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Account_Deletion_Reason_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "account_deletion_reason" */
export enum Account_Deletion_Reason_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Account_Deletion_Reason_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Account_Deletion_Reason_Set_Input>
  /** filter the rows which have to be updated */
  where: Account_Deletion_Reason_Bool_Exp
}

/** columns and relationships of "affiliate" */
export type Affiliate = {
  __typename?: 'affiliate'
  description?: Maybe<Scalars['String']>
  /** An object relationship */
  meta?: Maybe<Affiliate_Meta>
  value: Scalars['String']
}

/** This table maps the affillite with a shop_key and advertiser_id */
export type Affiliate_Advertiser_Id = {
  __typename?: 'affiliate_advertiser_id'
  advertiser_id: Scalars['String']
  affiliate: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  shop_key: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Aggregate = {
  __typename?: 'affiliate_advertiser_id_aggregate'
  aggregate?: Maybe<Affiliate_Advertiser_Id_Aggregate_Fields>
  nodes: Array<Affiliate_Advertiser_Id>
}

/** aggregate fields of "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Aggregate_Fields = {
  __typename?: 'affiliate_advertiser_id_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Affiliate_Advertiser_Id_Max_Fields>
  min?: Maybe<Affiliate_Advertiser_Id_Min_Fields>
}

/** aggregate fields of "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Affiliate_Advertiser_Id_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "affiliate_advertiser_id". All fields are combined with a logical 'AND'. */
export type Affiliate_Advertiser_Id_Bool_Exp = {
  _and?: Maybe<Array<Affiliate_Advertiser_Id_Bool_Exp>>
  _not?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
  _or?: Maybe<Array<Affiliate_Advertiser_Id_Bool_Exp>>
  advertiser_id?: Maybe<String_Comparison_Exp>
  affiliate?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "affiliate_advertiser_id" */
export enum Affiliate_Advertiser_Id_Constraint {
  /** unique or primary key constraint on columns "shop_key", "affiliate", "advertiser_id" */
  AffiliateAdvertiserIdAffiliateShopKeyAdvertiserIdKey = 'affiliate_advertiser_id_affiliate_shop_key_advertiser_id_key',
  /** unique or primary key constraint on columns "id" */
  AffiliateAdvertiserIdPkey = 'affiliate_advertiser_id_pkey',
}

/** input type for inserting data into table "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Insert_Input = {
  advertiser_id?: Maybe<Scalars['String']>
  affiliate?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Affiliate_Advertiser_Id_Max_Fields = {
  __typename?: 'affiliate_advertiser_id_max_fields'
  advertiser_id?: Maybe<Scalars['String']>
  affiliate?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Affiliate_Advertiser_Id_Min_Fields = {
  __typename?: 'affiliate_advertiser_id_min_fields'
  advertiser_id?: Maybe<Scalars['String']>
  affiliate?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Mutation_Response = {
  __typename?: 'affiliate_advertiser_id_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Affiliate_Advertiser_Id>
}

/** on_conflict condition type for table "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_On_Conflict = {
  constraint: Affiliate_Advertiser_Id_Constraint
  update_columns?: Array<Affiliate_Advertiser_Id_Update_Column>
  where?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
}

/** Ordering options when selecting data from "affiliate_advertiser_id". */
export type Affiliate_Advertiser_Id_Order_By = {
  advertiser_id?: Maybe<Order_By>
  affiliate?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: affiliate_advertiser_id */
export type Affiliate_Advertiser_Id_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "affiliate_advertiser_id" */
export enum Affiliate_Advertiser_Id_Select_Column {
  /** column name */
  AdvertiserId = 'advertiser_id',
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Set_Input = {
  advertiser_id?: Maybe<Scalars['String']>
  affiliate?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Affiliate_Advertiser_Id_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Affiliate_Advertiser_Id_Stream_Cursor_Value_Input = {
  advertiser_id?: Maybe<Scalars['String']>
  affiliate?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "affiliate_advertiser_id" */
export enum Affiliate_Advertiser_Id_Update_Column {
  /** column name */
  AdvertiserId = 'advertiser_id',
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Affiliate_Advertiser_Id_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Affiliate_Advertiser_Id_Set_Input>
  /** filter the rows which have to be updated */
  where: Affiliate_Advertiser_Id_Bool_Exp
}

/** aggregated selection of "affiliate" */
export type Affiliate_Aggregate = {
  __typename?: 'affiliate_aggregate'
  aggregate?: Maybe<Affiliate_Aggregate_Fields>
  nodes: Array<Affiliate>
}

/** aggregate fields of "affiliate" */
export type Affiliate_Aggregate_Fields = {
  __typename?: 'affiliate_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Affiliate_Max_Fields>
  min?: Maybe<Affiliate_Min_Fields>
}

/** aggregate fields of "affiliate" */
export type Affiliate_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Affiliate_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "affiliate". All fields are combined with a logical 'AND'. */
export type Affiliate_Bool_Exp = {
  _and?: Maybe<Array<Affiliate_Bool_Exp>>
  _not?: Maybe<Affiliate_Bool_Exp>
  _or?: Maybe<Array<Affiliate_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  meta?: Maybe<Affiliate_Meta_Bool_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** columns and relationships of "affiliate_commission_summary" */
export type Affiliate_Commission_Summary = {
  __typename?: 'affiliate_commission_summary'
  count_commission_cleared?: Maybe<Scalars['bigint']>
  count_commission_pending?: Maybe<Scalars['bigint']>
  sum_commission?: Maybe<Scalars['numeric']>
  sum_commission_cleared?: Maybe<Scalars['numeric']>
  sum_commission_pending?: Maybe<Scalars['numeric']>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "affiliate_commission_summary" */
export type Affiliate_Commission_Summary_Aggregate = {
  __typename?: 'affiliate_commission_summary_aggregate'
  aggregate?: Maybe<Affiliate_Commission_Summary_Aggregate_Fields>
  nodes: Array<Affiliate_Commission_Summary>
}

/** aggregate fields of "affiliate_commission_summary" */
export type Affiliate_Commission_Summary_Aggregate_Fields = {
  __typename?: 'affiliate_commission_summary_aggregate_fields'
  avg?: Maybe<Affiliate_Commission_Summary_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Affiliate_Commission_Summary_Max_Fields>
  min?: Maybe<Affiliate_Commission_Summary_Min_Fields>
  stddev?: Maybe<Affiliate_Commission_Summary_Stddev_Fields>
  stddev_pop?: Maybe<Affiliate_Commission_Summary_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Affiliate_Commission_Summary_Stddev_Samp_Fields>
  sum?: Maybe<Affiliate_Commission_Summary_Sum_Fields>
  var_pop?: Maybe<Affiliate_Commission_Summary_Var_Pop_Fields>
  var_samp?: Maybe<Affiliate_Commission_Summary_Var_Samp_Fields>
  variance?: Maybe<Affiliate_Commission_Summary_Variance_Fields>
}

/** aggregate fields of "affiliate_commission_summary" */
export type Affiliate_Commission_Summary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Affiliate_Commission_Summary_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Affiliate_Commission_Summary_Avg_Fields = {
  __typename?: 'affiliate_commission_summary_avg_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "affiliate_commission_summary". All fields are combined with a logical 'AND'. */
export type Affiliate_Commission_Summary_Bool_Exp = {
  _and?: Maybe<Array<Affiliate_Commission_Summary_Bool_Exp>>
  _not?: Maybe<Affiliate_Commission_Summary_Bool_Exp>
  _or?: Maybe<Array<Affiliate_Commission_Summary_Bool_Exp>>
  count_commission_cleared?: Maybe<Bigint_Comparison_Exp>
  count_commission_pending?: Maybe<Bigint_Comparison_Exp>
  sum_commission?: Maybe<Numeric_Comparison_Exp>
  sum_commission_cleared?: Maybe<Numeric_Comparison_Exp>
  sum_commission_pending?: Maybe<Numeric_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Affiliate_Commission_Summary_Max_Fields = {
  __typename?: 'affiliate_commission_summary_max_fields'
  count_commission_cleared?: Maybe<Scalars['bigint']>
  count_commission_pending?: Maybe<Scalars['bigint']>
  sum_commission?: Maybe<Scalars['numeric']>
  sum_commission_cleared?: Maybe<Scalars['numeric']>
  sum_commission_pending?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Affiliate_Commission_Summary_Min_Fields = {
  __typename?: 'affiliate_commission_summary_min_fields'
  count_commission_cleared?: Maybe<Scalars['bigint']>
  count_commission_pending?: Maybe<Scalars['bigint']>
  sum_commission?: Maybe<Scalars['numeric']>
  sum_commission_cleared?: Maybe<Scalars['numeric']>
  sum_commission_pending?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "affiliate_commission_summary". */
export type Affiliate_Commission_Summary_Order_By = {
  count_commission_cleared?: Maybe<Order_By>
  count_commission_pending?: Maybe<Order_By>
  sum_commission?: Maybe<Order_By>
  sum_commission_cleared?: Maybe<Order_By>
  sum_commission_pending?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "affiliate_commission_summary" */
export enum Affiliate_Commission_Summary_Select_Column {
  /** column name */
  CountCommissionCleared = 'count_commission_cleared',
  /** column name */
  CountCommissionPending = 'count_commission_pending',
  /** column name */
  SumCommission = 'sum_commission',
  /** column name */
  SumCommissionCleared = 'sum_commission_cleared',
  /** column name */
  SumCommissionPending = 'sum_commission_pending',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type Affiliate_Commission_Summary_Stddev_Fields = {
  __typename?: 'affiliate_commission_summary_stddev_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Affiliate_Commission_Summary_Stddev_Pop_Fields = {
  __typename?: 'affiliate_commission_summary_stddev_pop_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Affiliate_Commission_Summary_Stddev_Samp_Fields = {
  __typename?: 'affiliate_commission_summary_stddev_samp_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "affiliate_commission_summary" */
export type Affiliate_Commission_Summary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Affiliate_Commission_Summary_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Affiliate_Commission_Summary_Stream_Cursor_Value_Input = {
  count_commission_cleared?: Maybe<Scalars['bigint']>
  count_commission_pending?: Maybe<Scalars['bigint']>
  sum_commission?: Maybe<Scalars['numeric']>
  sum_commission_cleared?: Maybe<Scalars['numeric']>
  sum_commission_pending?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Affiliate_Commission_Summary_Sum_Fields = {
  __typename?: 'affiliate_commission_summary_sum_fields'
  count_commission_cleared?: Maybe<Scalars['bigint']>
  count_commission_pending?: Maybe<Scalars['bigint']>
  sum_commission?: Maybe<Scalars['numeric']>
  sum_commission_cleared?: Maybe<Scalars['numeric']>
  sum_commission_pending?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type Affiliate_Commission_Summary_Var_Pop_Fields = {
  __typename?: 'affiliate_commission_summary_var_pop_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Affiliate_Commission_Summary_Var_Samp_Fields = {
  __typename?: 'affiliate_commission_summary_var_samp_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Affiliate_Commission_Summary_Variance_Fields = {
  __typename?: 'affiliate_commission_summary_variance_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** unique or primary key constraints on table "affiliate" */
export enum Affiliate_Constraint {
  /** unique or primary key constraint on columns "value" */
  AffiliatePkey = 'affiliate_pkey',
}

export enum Affiliate_Enum {
  /** Amazon affiliate network */
  Amazon = 'amazon',
  /** AvantLink affiliate network */
  Avantlink = 'avantlink',
  /** AWIN affiliate network */
  Awin = 'awin',
  /** Commission Junction affiliate network */
  Cj = 'cj',
  /** Ebay affiliate network */
  Ebay = 'ebay',
  /** FlexOffers affiliate network */
  FlexOffers = 'flex_offers',
  /** Impact affiliate network */
  Impact = 'impact',
  /** LinkConnector affiliate network */
  Linkconnector = 'linkconnector',
  /** Partnerize affiliate network */
  Partnerize = 'partnerize',
  /** Pepperjam affiliate network */
  Pepperjam = 'pepperjam',
  /** Rakuten affiliate network */
  Rakuten = 'rakuten',
  /** Share-A-Sale affiliate network */
  ShareASale = 'share_a_sale',
  /** Sovrn affiliate network */
  Sovrn = 'sovrn',
}

/** Boolean expression to compare columns of type "affiliate_enum". All fields are combined with logical 'AND'. */
export type Affiliate_Enum_Comparison_Exp = {
  _eq?: Maybe<Affiliate_Enum>
  _in?: Maybe<Array<Affiliate_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Affiliate_Enum>
  _nin?: Maybe<Array<Affiliate_Enum>>
}

/** input type for inserting data into table "affiliate" */
export type Affiliate_Insert_Input = {
  description?: Maybe<Scalars['String']>
  meta?: Maybe<Affiliate_Meta_Obj_Rel_Insert_Input>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Affiliate_Max_Fields = {
  __typename?: 'affiliate_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** columns and relationships of "affiliate_meta" */
export type Affiliate_Meta = {
  __typename?: 'affiliate_meta'
  affiliate: Affiliate_Enum
  created_at: Scalars['timestamptz']
  data: Scalars['jsonb']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "affiliate_meta" */
export type Affiliate_MetaDataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "affiliate_meta" */
export type Affiliate_Meta_Aggregate = {
  __typename?: 'affiliate_meta_aggregate'
  aggregate?: Maybe<Affiliate_Meta_Aggregate_Fields>
  nodes: Array<Affiliate_Meta>
}

/** aggregate fields of "affiliate_meta" */
export type Affiliate_Meta_Aggregate_Fields = {
  __typename?: 'affiliate_meta_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Affiliate_Meta_Max_Fields>
  min?: Maybe<Affiliate_Meta_Min_Fields>
}

/** aggregate fields of "affiliate_meta" */
export type Affiliate_Meta_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Affiliate_Meta_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Affiliate_Meta_Append_Input = {
  data?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "affiliate_meta". All fields are combined with a logical 'AND'. */
export type Affiliate_Meta_Bool_Exp = {
  _and?: Maybe<Array<Affiliate_Meta_Bool_Exp>>
  _not?: Maybe<Affiliate_Meta_Bool_Exp>
  _or?: Maybe<Array<Affiliate_Meta_Bool_Exp>>
  affiliate?: Maybe<Affiliate_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  data?: Maybe<Jsonb_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "affiliate_meta" */
export enum Affiliate_Meta_Constraint {
  /** unique or primary key constraint on columns "affiliate" */
  AffiliateMetaPkey = 'affiliate_meta_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Affiliate_Meta_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Affiliate_Meta_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Affiliate_Meta_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "affiliate_meta" */
export type Affiliate_Meta_Insert_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Affiliate_Meta_Max_Fields = {
  __typename?: 'affiliate_meta_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Affiliate_Meta_Min_Fields = {
  __typename?: 'affiliate_meta_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "affiliate_meta" */
export type Affiliate_Meta_Mutation_Response = {
  __typename?: 'affiliate_meta_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Affiliate_Meta>
}

/** input type for inserting object relation for remote table "affiliate_meta" */
export type Affiliate_Meta_Obj_Rel_Insert_Input = {
  data: Affiliate_Meta_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Affiliate_Meta_On_Conflict>
}

/** on_conflict condition type for table "affiliate_meta" */
export type Affiliate_Meta_On_Conflict = {
  constraint: Affiliate_Meta_Constraint
  update_columns?: Array<Affiliate_Meta_Update_Column>
  where?: Maybe<Affiliate_Meta_Bool_Exp>
}

/** Ordering options when selecting data from "affiliate_meta". */
export type Affiliate_Meta_Order_By = {
  affiliate?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  data?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: affiliate_meta */
export type Affiliate_Meta_Pk_Columns_Input = {
  affiliate: Affiliate_Enum
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Affiliate_Meta_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>
}

/** select columns of table "affiliate_meta" */
export enum Affiliate_Meta_Select_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "affiliate_meta" */
export type Affiliate_Meta_Set_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "affiliate_meta" */
export type Affiliate_Meta_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Affiliate_Meta_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Affiliate_Meta_Stream_Cursor_Value_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "affiliate_meta" */
export enum Affiliate_Meta_Update_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Affiliate_Meta_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Affiliate_Meta_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Affiliate_Meta_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Affiliate_Meta_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Affiliate_Meta_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Affiliate_Meta_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Affiliate_Meta_Set_Input>
  /** filter the rows which have to be updated */
  where: Affiliate_Meta_Bool_Exp
}

/** aggregate min on columns */
export type Affiliate_Min_Fields = {
  __typename?: 'affiliate_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "affiliate" */
export type Affiliate_Mutation_Response = {
  __typename?: 'affiliate_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Affiliate>
}

/** input type for inserting object relation for remote table "affiliate" */
export type Affiliate_Obj_Rel_Insert_Input = {
  data: Affiliate_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Affiliate_On_Conflict>
}

/** on_conflict condition type for table "affiliate" */
export type Affiliate_On_Conflict = {
  constraint: Affiliate_Constraint
  update_columns?: Array<Affiliate_Update_Column>
  where?: Maybe<Affiliate_Bool_Exp>
}

/** Ordering options when selecting data from "affiliate". */
export type Affiliate_Order_By = {
  description?: Maybe<Order_By>
  meta?: Maybe<Affiliate_Meta_Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: affiliate */
export type Affiliate_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "affiliate" */
export enum Affiliate_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "affiliate" */
export type Affiliate_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "affiliate" */
export type Affiliate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Affiliate_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Affiliate_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** This table stores the trasactions done of every affiliate */
export type Affiliate_Transaction = {
  __typename?: 'affiliate_transaction'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  affiliate: Affiliate_Enum
  clicking_user_id?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['numeric']>
  created_at: Scalars['timestamptz']
  creator_id?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  metadata: Scalars['jsonb']
  order_id?: Maybe<Scalars['String']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  redirect_ids_id?: Maybe<Scalars['uuid']>
  sale_amount?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transaction_date: Scalars['timestamptz']
  transaction_id: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
}

/** This table stores the trasactions done of every affiliate */
export type Affiliate_TransactionMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "affiliate_transaction" */
export type Affiliate_Transaction_Aggregate = {
  __typename?: 'affiliate_transaction_aggregate'
  aggregate?: Maybe<Affiliate_Transaction_Aggregate_Fields>
  nodes: Array<Affiliate_Transaction>
}

export type Affiliate_Transaction_Aggregate_Bool_Exp = {
  count?: Maybe<Affiliate_Transaction_Aggregate_Bool_Exp_Count>
}

export type Affiliate_Transaction_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Affiliate_Transaction_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "affiliate_transaction" */
export type Affiliate_Transaction_Aggregate_Fields = {
  __typename?: 'affiliate_transaction_aggregate_fields'
  avg?: Maybe<Affiliate_Transaction_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Affiliate_Transaction_Max_Fields>
  min?: Maybe<Affiliate_Transaction_Min_Fields>
  stddev?: Maybe<Affiliate_Transaction_Stddev_Fields>
  stddev_pop?: Maybe<Affiliate_Transaction_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Affiliate_Transaction_Stddev_Samp_Fields>
  sum?: Maybe<Affiliate_Transaction_Sum_Fields>
  var_pop?: Maybe<Affiliate_Transaction_Var_Pop_Fields>
  var_samp?: Maybe<Affiliate_Transaction_Var_Samp_Fields>
  variance?: Maybe<Affiliate_Transaction_Variance_Fields>
}

/** aggregate fields of "affiliate_transaction" */
export type Affiliate_Transaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "affiliate_transaction" */
export type Affiliate_Transaction_Aggregate_Order_By = {
  avg?: Maybe<Affiliate_Transaction_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Affiliate_Transaction_Max_Order_By>
  min?: Maybe<Affiliate_Transaction_Min_Order_By>
  stddev?: Maybe<Affiliate_Transaction_Stddev_Order_By>
  stddev_pop?: Maybe<Affiliate_Transaction_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Affiliate_Transaction_Stddev_Samp_Order_By>
  sum?: Maybe<Affiliate_Transaction_Sum_Order_By>
  var_pop?: Maybe<Affiliate_Transaction_Var_Pop_Order_By>
  var_samp?: Maybe<Affiliate_Transaction_Var_Samp_Order_By>
  variance?: Maybe<Affiliate_Transaction_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Affiliate_Transaction_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "affiliate_transaction" */
export type Affiliate_Transaction_Arr_Rel_Insert_Input = {
  data: Array<Affiliate_Transaction_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Affiliate_Transaction_On_Conflict>
}

/** aggregate avg on columns */
export type Affiliate_Transaction_Avg_Fields = {
  __typename?: 'affiliate_transaction_avg_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Avg_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "affiliate_transaction". All fields are combined with a logical 'AND'. */
export type Affiliate_Transaction_Bool_Exp = {
  _and?: Maybe<Array<Affiliate_Transaction_Bool_Exp>>
  _not?: Maybe<Affiliate_Transaction_Bool_Exp>
  _or?: Maybe<Array<Affiliate_Transaction_Bool_Exp>>
  adjusted_commission?: Maybe<Numeric_Comparison_Exp>
  affiliate?: Maybe<Affiliate_Enum_Comparison_Exp>
  clicking_user_id?: Maybe<String_Comparison_Exp>
  commission?: Maybe<Numeric_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  creator_id?: Maybe<String_Comparison_Exp>
  currency?: Maybe<String_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<String_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  order_id?: Maybe<String_Comparison_Exp>
  redeemed_at?: Maybe<Timestamptz_Comparison_Exp>
  redirect_ids_id?: Maybe<Uuid_Comparison_Exp>
  sale_amount?: Maybe<Numeric_Comparison_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  transaction_date?: Maybe<Timestamptz_Comparison_Exp>
  transaction_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
}

/** unique or primary key constraints on table "affiliate_transaction" */
export enum Affiliate_Transaction_Constraint {
  /** unique or primary key constraint on columns "affiliate", "transaction_id" */
  AffiliateTransactionAffiliateTransactionIdKey = 'affiliate_transaction_affiliate_transaction_id_key',
  /** unique or primary key constraint on columns "id" */
  AffiliateTransactionPkey = 'affiliate_transaction_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Affiliate_Transaction_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Affiliate_Transaction_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Affiliate_Transaction_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "affiliate_transaction" */
export type Affiliate_Transaction_Inc_Input = {
  commission?: Maybe<Scalars['numeric']>
  sale_amount?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "affiliate_transaction" */
export type Affiliate_Transaction_Insert_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  clicking_user_id?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  order_id?: Maybe<Scalars['String']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  redirect_ids_id?: Maybe<Scalars['uuid']>
  sale_amount?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transaction_date?: Maybe<Scalars['timestamptz']>
  transaction_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Affiliate_Transaction_Max_Fields = {
  __typename?: 'affiliate_transaction_max_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  clicking_user_id?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  order_id?: Maybe<Scalars['String']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  redirect_ids_id?: Maybe<Scalars['uuid']>
  sale_amount?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transaction_date?: Maybe<Scalars['timestamptz']>
  transaction_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Max_Order_By = {
  clicking_user_id?: Maybe<Order_By>
  commission?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator_id?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  order_id?: Maybe<Order_By>
  redeemed_at?: Maybe<Order_By>
  redirect_ids_id?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  status?: Maybe<Order_By>
  transaction_date?: Maybe<Order_By>
  transaction_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Affiliate_Transaction_Min_Fields = {
  __typename?: 'affiliate_transaction_min_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  clicking_user_id?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  order_id?: Maybe<Scalars['String']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  redirect_ids_id?: Maybe<Scalars['uuid']>
  sale_amount?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transaction_date?: Maybe<Scalars['timestamptz']>
  transaction_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Min_Order_By = {
  clicking_user_id?: Maybe<Order_By>
  commission?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator_id?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  order_id?: Maybe<Order_By>
  redeemed_at?: Maybe<Order_By>
  redirect_ids_id?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  status?: Maybe<Order_By>
  transaction_date?: Maybe<Order_By>
  transaction_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "affiliate_transaction" */
export type Affiliate_Transaction_Mutation_Response = {
  __typename?: 'affiliate_transaction_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Affiliate_Transaction>
}

/** on_conflict condition type for table "affiliate_transaction" */
export type Affiliate_Transaction_On_Conflict = {
  constraint: Affiliate_Transaction_Constraint
  update_columns?: Array<Affiliate_Transaction_Update_Column>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

/** Ordering options when selecting data from "affiliate_transaction". */
export type Affiliate_Transaction_Order_By = {
  adjusted_commission?: Maybe<Order_By>
  affiliate?: Maybe<Order_By>
  clicking_user_id?: Maybe<Order_By>
  commission?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator_id?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  order_id?: Maybe<Order_By>
  redeemed_at?: Maybe<Order_By>
  redirect_ids_id?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  status?: Maybe<Order_By>
  transaction_date?: Maybe<Order_By>
  transaction_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
}

/** primary key columns input for table: affiliate_transaction */
export type Affiliate_Transaction_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Affiliate_Transaction_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "affiliate_transaction" */
export enum Affiliate_Transaction_Select_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  ClickingUserId = 'clicking_user_id',
  /** column name */
  Commission = 'commission',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  RedeemedAt = 'redeemed_at',
  /** column name */
  RedirectIdsId = 'redirect_ids_id',
  /** column name */
  SaleAmount = 'sale_amount',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionDate = 'transaction_date',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "affiliate_transaction" */
export type Affiliate_Transaction_Set_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  clicking_user_id?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  order_id?: Maybe<Scalars['String']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  redirect_ids_id?: Maybe<Scalars['uuid']>
  sale_amount?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transaction_date?: Maybe<Scalars['timestamptz']>
  transaction_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Affiliate_Transaction_Stddev_Fields = {
  __typename?: 'affiliate_transaction_stddev_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Stddev_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Affiliate_Transaction_Stddev_Pop_Fields = {
  __typename?: 'affiliate_transaction_stddev_pop_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Stddev_Pop_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Affiliate_Transaction_Stddev_Samp_Fields = {
  __typename?: 'affiliate_transaction_stddev_samp_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Stddev_Samp_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** Streaming cursor of the table "affiliate_transaction" */
export type Affiliate_Transaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Affiliate_Transaction_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Affiliate_Transaction_Stream_Cursor_Value_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  clicking_user_id?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  order_id?: Maybe<Scalars['String']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  redirect_ids_id?: Maybe<Scalars['uuid']>
  sale_amount?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transaction_date?: Maybe<Scalars['timestamptz']>
  transaction_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Affiliate_Transaction_Sum_Fields = {
  __typename?: 'affiliate_transaction_sum_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['numeric']>
  sale_amount?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Sum_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** update columns of table "affiliate_transaction" */
export enum Affiliate_Transaction_Update_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  ClickingUserId = 'clicking_user_id',
  /** column name */
  Commission = 'commission',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  RedeemedAt = 'redeemed_at',
  /** column name */
  RedirectIdsId = 'redirect_ids_id',
  /** column name */
  SaleAmount = 'sale_amount',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionDate = 'transaction_date',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Affiliate_Transaction_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Affiliate_Transaction_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Affiliate_Transaction_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Affiliate_Transaction_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Affiliate_Transaction_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Affiliate_Transaction_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Affiliate_Transaction_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Affiliate_Transaction_Set_Input>
  /** filter the rows which have to be updated */
  where: Affiliate_Transaction_Bool_Exp
}

/** aggregate var_pop on columns */
export type Affiliate_Transaction_Var_Pop_Fields = {
  __typename?: 'affiliate_transaction_var_pop_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Var_Pop_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Affiliate_Transaction_Var_Samp_Fields = {
  __typename?: 'affiliate_transaction_var_samp_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Var_Samp_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Affiliate_Transaction_Variance_Fields = {
  __typename?: 'affiliate_transaction_variance_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Variance_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** update columns of table "affiliate" */
export enum Affiliate_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Affiliate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Affiliate_Set_Input>
  /** filter the rows which have to be updated */
  where: Affiliate_Bool_Exp
}

/** columns and relationships of "amazon_categories" */
export type Amazon_Categories = {
  __typename?: 'amazon_categories'
  name?: Maybe<Scalars['String']>
}

/** aggregated selection of "amazon_categories" */
export type Amazon_Categories_Aggregate = {
  __typename?: 'amazon_categories_aggregate'
  aggregate?: Maybe<Amazon_Categories_Aggregate_Fields>
  nodes: Array<Amazon_Categories>
}

/** aggregate fields of "amazon_categories" */
export type Amazon_Categories_Aggregate_Fields = {
  __typename?: 'amazon_categories_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Amazon_Categories_Max_Fields>
  min?: Maybe<Amazon_Categories_Min_Fields>
}

/** aggregate fields of "amazon_categories" */
export type Amazon_Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Amazon_Categories_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "amazon_categories". All fields are combined with a logical 'AND'. */
export type Amazon_Categories_Bool_Exp = {
  _and?: Maybe<Array<Amazon_Categories_Bool_Exp>>
  _not?: Maybe<Amazon_Categories_Bool_Exp>
  _or?: Maybe<Array<Amazon_Categories_Bool_Exp>>
  name?: Maybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Amazon_Categories_Max_Fields = {
  __typename?: 'amazon_categories_max_fields'
  name?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Amazon_Categories_Min_Fields = {
  __typename?: 'amazon_categories_min_fields'
  name?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "amazon_categories". */
export type Amazon_Categories_Order_By = {
  name?: Maybe<Order_By>
}

/** select columns of table "amazon_categories" */
export enum Amazon_Categories_Select_Column {
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "amazon_categories" */
export type Amazon_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Amazon_Categories_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Amazon_Categories_Stream_Cursor_Value_Input = {
  name?: Maybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>
  _gt?: Maybe<Scalars['bigint']>
  _gte?: Maybe<Scalars['bigint']>
  _in?: Maybe<Array<Scalars['bigint']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['bigint']>
  _lte?: Maybe<Scalars['bigint']>
  _neq?: Maybe<Scalars['bigint']>
  _nin?: Maybe<Array<Scalars['bigint']>>
}

/** A table to hold the value of billing mode that we can have in our database */
export type Billing_Mode = {
  __typename?: 'billing_mode'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "billing_mode" */
export type Billing_Mode_Aggregate = {
  __typename?: 'billing_mode_aggregate'
  aggregate?: Maybe<Billing_Mode_Aggregate_Fields>
  nodes: Array<Billing_Mode>
}

/** aggregate fields of "billing_mode" */
export type Billing_Mode_Aggregate_Fields = {
  __typename?: 'billing_mode_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Billing_Mode_Max_Fields>
  min?: Maybe<Billing_Mode_Min_Fields>
}

/** aggregate fields of "billing_mode" */
export type Billing_Mode_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Mode_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "billing_mode". All fields are combined with a logical 'AND'. */
export type Billing_Mode_Bool_Exp = {
  _and?: Maybe<Array<Billing_Mode_Bool_Exp>>
  _not?: Maybe<Billing_Mode_Bool_Exp>
  _or?: Maybe<Array<Billing_Mode_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "billing_mode" */
export enum Billing_Mode_Constraint {
  /** unique or primary key constraint on columns "value" */
  BillingModePkey = 'billing_mode_pkey',
}

export enum Billing_Mode_Enum {
  Live = 'live',
  Test = 'test',
}

/** Boolean expression to compare columns of type "billing_mode_enum". All fields are combined with logical 'AND'. */
export type Billing_Mode_Enum_Comparison_Exp = {
  _eq?: Maybe<Billing_Mode_Enum>
  _in?: Maybe<Array<Billing_Mode_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Billing_Mode_Enum>
  _nin?: Maybe<Array<Billing_Mode_Enum>>
}

/** input type for inserting data into table "billing_mode" */
export type Billing_Mode_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Billing_Mode_Max_Fields = {
  __typename?: 'billing_mode_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Billing_Mode_Min_Fields = {
  __typename?: 'billing_mode_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "billing_mode" */
export type Billing_Mode_Mutation_Response = {
  __typename?: 'billing_mode_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Mode>
}

/** on_conflict condition type for table "billing_mode" */
export type Billing_Mode_On_Conflict = {
  constraint: Billing_Mode_Constraint
  update_columns?: Array<Billing_Mode_Update_Column>
  where?: Maybe<Billing_Mode_Bool_Exp>
}

/** Ordering options when selecting data from "billing_mode". */
export type Billing_Mode_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: billing_mode */
export type Billing_Mode_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "billing_mode" */
export enum Billing_Mode_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "billing_mode" */
export type Billing_Mode_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "billing_mode" */
export type Billing_Mode_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Mode_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Billing_Mode_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "billing_mode" */
export enum Billing_Mode_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Billing_Mode_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Billing_Mode_Set_Input>
  /** filter the rows which have to be updated */
  where: Billing_Mode_Bool_Exp
}

/** A table to hold connected account  */
export type Billing_Platform_Connected_Account = {
  __typename?: 'billing_platform_connected_account'
  account_id: Scalars['String']
  billing_mode: Billing_Mode_Enum
  created_at: Scalars['timestamptz']
  platform_type: Scalars['String']
  status: Billing_Platform_Connected_Account_Status_Enum
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Aggregate = {
  __typename?: 'billing_platform_connected_account_aggregate'
  aggregate?: Maybe<Billing_Platform_Connected_Account_Aggregate_Fields>
  nodes: Array<Billing_Platform_Connected_Account>
}

/** aggregate fields of "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Aggregate_Fields = {
  __typename?: 'billing_platform_connected_account_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Billing_Platform_Connected_Account_Max_Fields>
  min?: Maybe<Billing_Platform_Connected_Account_Min_Fields>
}

/** aggregate fields of "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Platform_Connected_Account_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "billing_platform_connected_account". All fields are combined with a logical 'AND'. */
export type Billing_Platform_Connected_Account_Bool_Exp = {
  _and?: Maybe<Array<Billing_Platform_Connected_Account_Bool_Exp>>
  _not?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
  _or?: Maybe<Array<Billing_Platform_Connected_Account_Bool_Exp>>
  account_id?: Maybe<String_Comparison_Exp>
  billing_mode?: Maybe<Billing_Mode_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  platform_type?: Maybe<String_Comparison_Exp>
  status?: Maybe<Billing_Platform_Connected_Account_Status_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "billing_platform_connected_account" */
export enum Billing_Platform_Connected_Account_Constraint {
  /** unique or primary key constraint on columns "platform_type", "user_id", "billing_mode" */
  BillingPlatformConnectedAccountPkey = 'billing_platform_connected_account_pkey',
}

/** input type for inserting data into table "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Insert_Input = {
  account_id?: Maybe<Scalars['String']>
  billing_mode?: Maybe<Billing_Mode_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  platform_type?: Maybe<Scalars['String']>
  status?: Maybe<Billing_Platform_Connected_Account_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Billing_Platform_Connected_Account_Max_Fields = {
  __typename?: 'billing_platform_connected_account_max_fields'
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Billing_Platform_Connected_Account_Min_Fields = {
  __typename?: 'billing_platform_connected_account_min_fields'
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Mutation_Response = {
  __typename?: 'billing_platform_connected_account_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Platform_Connected_Account>
}

/** on_conflict condition type for table "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_On_Conflict = {
  constraint: Billing_Platform_Connected_Account_Constraint
  update_columns?: Array<Billing_Platform_Connected_Account_Update_Column>
  where?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
}

/** Ordering options when selecting data from "billing_platform_connected_account". */
export type Billing_Platform_Connected_Account_Order_By = {
  account_id?: Maybe<Order_By>
  billing_mode?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  platform_type?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: billing_platform_connected_account */
export type Billing_Platform_Connected_Account_Pk_Columns_Input = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

/** select columns of table "billing_platform_connected_account" */
export enum Billing_Platform_Connected_Account_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PlatformType = 'platform_type',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Set_Input = {
  account_id?: Maybe<Scalars['String']>
  billing_mode?: Maybe<Billing_Mode_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  platform_type?: Maybe<Scalars['String']>
  status?: Maybe<Billing_Platform_Connected_Account_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** A table to hold the different statuses of our billing platform connected accounts */
export type Billing_Platform_Connected_Account_Status = {
  __typename?: 'billing_platform_connected_account_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Aggregate = {
  __typename?: 'billing_platform_connected_account_status_aggregate'
  aggregate?: Maybe<Billing_Platform_Connected_Account_Status_Aggregate_Fields>
  nodes: Array<Billing_Platform_Connected_Account_Status>
}

/** aggregate fields of "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Aggregate_Fields = {
  __typename?: 'billing_platform_connected_account_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Billing_Platform_Connected_Account_Status_Max_Fields>
  min?: Maybe<Billing_Platform_Connected_Account_Status_Min_Fields>
}

/** aggregate fields of "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Billing_Platform_Connected_Account_Status_Select_Column>
    >
    distinct?: Maybe<Scalars['Boolean']>
  }

/** Boolean expression to filter rows from the table "billing_platform_connected_account_status". All fields are combined with a logical 'AND'. */
export type Billing_Platform_Connected_Account_Status_Bool_Exp = {
  _and?: Maybe<Array<Billing_Platform_Connected_Account_Status_Bool_Exp>>
  _not?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
  _or?: Maybe<Array<Billing_Platform_Connected_Account_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "billing_platform_connected_account_status" */
export enum Billing_Platform_Connected_Account_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  BillingPlatformConnectedAccountStatusPkey = 'billing_platform_connected_account_status_pkey',
}

export enum Billing_Platform_Connected_Account_Status_Enum {
  Active = 'active',
  Disabled = 'disabled',
  Pending = 'pending',
}

/** Boolean expression to compare columns of type "billing_platform_connected_account_status_enum". All fields are combined with logical 'AND'. */
export type Billing_Platform_Connected_Account_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Billing_Platform_Connected_Account_Status_Enum>
  _in?: Maybe<Array<Billing_Platform_Connected_Account_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Billing_Platform_Connected_Account_Status_Enum>
  _nin?: Maybe<Array<Billing_Platform_Connected_Account_Status_Enum>>
}

/** input type for inserting data into table "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Billing_Platform_Connected_Account_Status_Max_Fields = {
  __typename?: 'billing_platform_connected_account_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Billing_Platform_Connected_Account_Status_Min_Fields = {
  __typename?: 'billing_platform_connected_account_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Mutation_Response = {
  __typename?: 'billing_platform_connected_account_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Platform_Connected_Account_Status>
}

/** on_conflict condition type for table "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_On_Conflict = {
  constraint: Billing_Platform_Connected_Account_Status_Constraint
  update_columns?: Array<Billing_Platform_Connected_Account_Status_Update_Column>
  where?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
}

/** Ordering options when selecting data from "billing_platform_connected_account_status". */
export type Billing_Platform_Connected_Account_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: billing_platform_connected_account_status */
export type Billing_Platform_Connected_Account_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "billing_platform_connected_account_status" */
export enum Billing_Platform_Connected_Account_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Platform_Connected_Account_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Billing_Platform_Connected_Account_Status_Stream_Cursor_Value_Input =
  {
    description?: Maybe<Scalars['String']>
    value?: Maybe<Scalars['String']>
  }

/** update columns of table "billing_platform_connected_account_status" */
export enum Billing_Platform_Connected_Account_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Billing_Platform_Connected_Account_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Billing_Platform_Connected_Account_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Billing_Platform_Connected_Account_Status_Bool_Exp
}

/** Streaming cursor of the table "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Platform_Connected_Account_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Billing_Platform_Connected_Account_Stream_Cursor_Value_Input = {
  account_id?: Maybe<Scalars['String']>
  billing_mode?: Maybe<Billing_Mode_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  platform_type?: Maybe<Scalars['String']>
  status?: Maybe<Billing_Platform_Connected_Account_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "billing_platform_connected_account" */
export enum Billing_Platform_Connected_Account_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PlatformType = 'platform_type',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Billing_Platform_Connected_Account_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Billing_Platform_Connected_Account_Set_Input>
  /** filter the rows which have to be updated */
  where: Billing_Platform_Connected_Account_Bool_Exp
}

/** A table to hold the relationship between our users and those of our billing platform */
export type Billing_Platform_Customer = {
  __typename?: 'billing_platform_customer'
  billing_mode: Billing_Mode_Enum
  created_at: Scalars['timestamptz']
  metadata: Scalars['jsonb']
  platform_id: Scalars['String']
  platform_type: Scalars['String']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** A table to hold the relationship between our users and those of our billing platform */
export type Billing_Platform_CustomerMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "billing_platform_customer" */
export type Billing_Platform_Customer_Aggregate = {
  __typename?: 'billing_platform_customer_aggregate'
  aggregate?: Maybe<Billing_Platform_Customer_Aggregate_Fields>
  nodes: Array<Billing_Platform_Customer>
}

/** aggregate fields of "billing_platform_customer" */
export type Billing_Platform_Customer_Aggregate_Fields = {
  __typename?: 'billing_platform_customer_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Billing_Platform_Customer_Max_Fields>
  min?: Maybe<Billing_Platform_Customer_Min_Fields>
}

/** aggregate fields of "billing_platform_customer" */
export type Billing_Platform_Customer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Platform_Customer_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Billing_Platform_Customer_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "billing_platform_customer". All fields are combined with a logical 'AND'. */
export type Billing_Platform_Customer_Bool_Exp = {
  _and?: Maybe<Array<Billing_Platform_Customer_Bool_Exp>>
  _not?: Maybe<Billing_Platform_Customer_Bool_Exp>
  _or?: Maybe<Array<Billing_Platform_Customer_Bool_Exp>>
  billing_mode?: Maybe<Billing_Mode_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  platform_id?: Maybe<String_Comparison_Exp>
  platform_type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "billing_platform_customer" */
export enum Billing_Platform_Customer_Constraint {
  /** unique or primary key constraint on columns "platform_type", "user_id", "billing_mode" */
  BillingPlatformCustomerPkey = 'billing_platform_customer_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Billing_Platform_Customer_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Billing_Platform_Customer_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Billing_Platform_Customer_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "billing_platform_customer" */
export type Billing_Platform_Customer_Insert_Input = {
  billing_mode?: Maybe<Billing_Mode_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  metadata?: Maybe<Scalars['jsonb']>
  platform_id?: Maybe<Scalars['String']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Billing_Platform_Customer_Max_Fields = {
  __typename?: 'billing_platform_customer_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  platform_id?: Maybe<Scalars['String']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Billing_Platform_Customer_Min_Fields = {
  __typename?: 'billing_platform_customer_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  platform_id?: Maybe<Scalars['String']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "billing_platform_customer" */
export type Billing_Platform_Customer_Mutation_Response = {
  __typename?: 'billing_platform_customer_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Platform_Customer>
}

/** on_conflict condition type for table "billing_platform_customer" */
export type Billing_Platform_Customer_On_Conflict = {
  constraint: Billing_Platform_Customer_Constraint
  update_columns?: Array<Billing_Platform_Customer_Update_Column>
  where?: Maybe<Billing_Platform_Customer_Bool_Exp>
}

/** Ordering options when selecting data from "billing_platform_customer". */
export type Billing_Platform_Customer_Order_By = {
  billing_mode?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  platform_id?: Maybe<Order_By>
  platform_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: billing_platform_customer */
export type Billing_Platform_Customer_Pk_Columns_Input = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Billing_Platform_Customer_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "billing_platform_customer" */
export enum Billing_Platform_Customer_Select_Column {
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PlatformId = 'platform_id',
  /** column name */
  PlatformType = 'platform_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "billing_platform_customer" */
export type Billing_Platform_Customer_Set_Input = {
  billing_mode?: Maybe<Billing_Mode_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  metadata?: Maybe<Scalars['jsonb']>
  platform_id?: Maybe<Scalars['String']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "billing_platform_customer" */
export type Billing_Platform_Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Platform_Customer_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Billing_Platform_Customer_Stream_Cursor_Value_Input = {
  billing_mode?: Maybe<Billing_Mode_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  metadata?: Maybe<Scalars['jsonb']>
  platform_id?: Maybe<Scalars['String']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "billing_platform_customer" */
export enum Billing_Platform_Customer_Update_Column {
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PlatformId = 'platform_id',
  /** column name */
  PlatformType = 'platform_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Billing_Platform_Customer_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Billing_Platform_Customer_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Billing_Platform_Customer_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Billing_Platform_Customer_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Billing_Platform_Customer_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Billing_Platform_Customer_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Billing_Platform_Customer_Set_Input>
  /** filter the rows which have to be updated */
  where: Billing_Platform_Customer_Bool_Exp
}

/** columns and relationships of "cart" */
export type Cart = {
  __typename?: 'cart'
  /** An array relationship */
  cart_items: Array<Cart_Item>
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate
  cleared_at?: Maybe<Scalars['date']>
  created_at: Scalars['timestamptz']
  hidden: Scalars['Boolean']
  id: Scalars['uuid']
  /** Retrieves the last cart item's created_at */
  last_cart_item_created_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  shop?: Maybe<Shop>
  shop_key?: Maybe<Scalars['String']>
  short_id?: Maybe<Scalars['name']>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** columns and relationships of "cart" */
export type CartCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "cart" */
export type CartCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** aggregated selection of "cart" */
export type Cart_Aggregate = {
  __typename?: 'cart_aggregate'
  aggregate?: Maybe<Cart_Aggregate_Fields>
  nodes: Array<Cart>
}

export type Cart_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Cart_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Cart_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Cart_Aggregate_Bool_Exp_Count>
}

export type Cart_Aggregate_Bool_Exp_Bool_And = {
  arguments: Cart_Select_Column_Cart_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Cart_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Cart_Select_Column_Cart_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Cart_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Cart_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "cart" */
export type Cart_Aggregate_Fields = {
  __typename?: 'cart_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Cart_Max_Fields>
  min?: Maybe<Cart_Min_Fields>
}

/** aggregate fields of "cart" */
export type Cart_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cart_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "cart" */
export type Cart_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Cart_Max_Order_By>
  min?: Maybe<Cart_Min_Order_By>
}

/** input type for inserting array relation for remote table "cart" */
export type Cart_Arr_Rel_Insert_Input = {
  data: Array<Cart_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Cart_On_Conflict>
}

/** Boolean expression to filter rows from the table "cart". All fields are combined with a logical 'AND'. */
export type Cart_Bool_Exp = {
  _and?: Maybe<Array<Cart_Bool_Exp>>
  _not?: Maybe<Cart_Bool_Exp>
  _or?: Maybe<Array<Cart_Bool_Exp>>
  cart_items?: Maybe<Cart_Item_Bool_Exp>
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Bool_Exp>
  cleared_at?: Maybe<Date_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  hidden?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  last_cart_item_created_at?: Maybe<Timestamptz_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  short_id?: Maybe<Name_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "cart" */
export enum Cart_Constraint {
  /** unique or primary key constraint on columns "id" */
  CartPkey = 'cart_pkey',
  /** unique or primary key constraint on columns "shop_key", "user_id" */
  CartUserIdShopKeyKey = 'cart_user_id_shop_key_key',
}

/** input type for inserting data into table "cart" */
export type Cart_Insert_Input = {
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>
  cleared_at?: Maybe<Scalars['date']>
  created_at?: Maybe<Scalars['timestamptz']>
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  short_id?: Maybe<Scalars['name']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** columns and relationships of "cart_item" */
export type Cart_Item = {
  __typename?: 'cart_item'
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  archived?: Maybe<Scalars['Boolean']>
  archived_at?: Maybe<Scalars['timestamptz']>
  bookmark: Scalars['Boolean']
  /** An object relationship */
  cart: Cart
  cart_id: Scalars['uuid']
  /** An array relationship */
  cart_item_likes: Array<Cart_Item_Like>
  /** An aggregate relationship */
  cart_item_likes_aggregate: Cart_Item_Like_Aggregate
  categories_override?: Maybe<Array<Scalars['String']>>
  /** An object relationship */
  checkout?: Maybe<Checkout>
  checkout_id?: Maybe<Scalars['uuid']>
  client_sources?: Maybe<Array<Scalars['String']>>
  /** An array relationship */
  collection_cart_items: Array<Collection_Cart_Item>
  /** An aggregate relationship */
  collection_cart_items_aggregate: Collection_Cart_Item_Aggregate
  /** An object relationship */
  collection_item_claim?: Maybe<Collection_Item_Claim>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  commentable_item_join?: Maybe<Commentable_Item>
  created_at: Scalars['timestamptz']
  creator_rating?: Maybe<Scalars['numeric']>
  deal_ends_at?: Maybe<Scalars['timestamp']>
  deleted?: Maybe<Scalars['Boolean']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  /** Computed field that returns the description override or the real description of the item. */
  description?: Maybe<Scalars['String']>
  /** Computed field that returns the tittle override or the real title. */
  display_title?: Maybe<Scalars['String']>
  /** An object relationship */
  feed_item?: Maybe<Feed_Item>
  feed_item_type: Feed_Item_Type_Enum
  hidden: Scalars['Boolean']
  id: Scalars['uuid']
  /** Computed field on `cart_item` that returns the image for the cart item. */
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  /** Computed field that returns true if cart_item is in any collections */
  in_collection?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  item: Shop_Item
  item_description_override?: Maybe<Scalars['String']>
  item_id: Scalars['uuid']
  item_image_override?: Maybe<Scalars['String']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  item_title_override?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  item_video_url?: Maybe<Scalars['String']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "cart_item_liked_by_user" */
  liked_by_user?: Maybe<Scalars['Boolean']>
  metadata: Scalars['jsonb']
  /** An object relationship */
  note?: Maybe<Cart_Item_Note>
  notifiable_item_type: Notifiable_Item_Type_Enum
  /** A computed field, executes function "originating_cart_item" */
  originating_cart_item?: Maybe<Array<Cart_Item>>
  /** A computed field, executes function "originating_collection" */
  originating_collection?: Maybe<Array<Collection>>
  /** A computed field, executes function "originating_user" */
  originating_user?: Maybe<Array<User>>
  pinned: Scalars['Boolean']
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  polymorphic_type: Commentable_Item_Type_Enum
  /** Computed field that returns the pricing override or the real pricing of the item. */
  pricing?: Maybe<Scalars['jsonb']>
  quantity: Scalars['numeric']
  remind_me_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  remove_reason?: Maybe<Remove_Reason>
  removed?: Maybe<Scalars['Boolean']>
  removed_at?: Maybe<Scalars['timestamptz']>
  removed_reason?: Maybe<Remove_Reason_Enum>
  /** An object relationship */
  shop: Shop
  shop_key: Scalars['String']
  /** An array relationship */
  similar_products_histories: Array<Similar_Products_History>
  /** An aggregate relationship */
  similar_products_histories_aggregate: Similar_Products_History_Aggregate
  source?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  user?: Maybe<Array<User>>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemCart_Item_LikesArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemCart_Item_Likes_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemCollection_Cart_ItemsArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemCollection_Cart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemImage_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemItem_Pricing_OverrideArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemOriginating_Cart_ItemArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemOriginating_CollectionArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemOriginating_UserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemPricingArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemSimilar_Products_HistoriesArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemSimilar_Products_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** aggregated selection of "cart_item" */
export type Cart_Item_Aggregate = {
  __typename?: 'cart_item_aggregate'
  aggregate?: Maybe<Cart_Item_Aggregate_Fields>
  nodes: Array<Cart_Item>
}

export type Cart_Item_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Cart_Item_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Cart_Item_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Cart_Item_Aggregate_Bool_Exp_Count>
}

export type Cart_Item_Aggregate_Bool_Exp_Bool_And = {
  arguments: Cart_Item_Select_Column_Cart_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Item_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Cart_Item_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Cart_Item_Select_Column_Cart_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Item_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Cart_Item_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Cart_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "cart_item" */
export type Cart_Item_Aggregate_Fields = {
  __typename?: 'cart_item_aggregate_fields'
  avg?: Maybe<Cart_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Cart_Item_Max_Fields>
  min?: Maybe<Cart_Item_Min_Fields>
  stddev?: Maybe<Cart_Item_Stddev_Fields>
  stddev_pop?: Maybe<Cart_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Cart_Item_Stddev_Samp_Fields>
  sum?: Maybe<Cart_Item_Sum_Fields>
  var_pop?: Maybe<Cart_Item_Var_Pop_Fields>
  var_samp?: Maybe<Cart_Item_Var_Samp_Fields>
  variance?: Maybe<Cart_Item_Variance_Fields>
}

/** aggregate fields of "cart_item" */
export type Cart_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cart_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "cart_item" */
export type Cart_Item_Aggregate_Order_By = {
  avg?: Maybe<Cart_Item_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Cart_Item_Max_Order_By>
  min?: Maybe<Cart_Item_Min_Order_By>
  stddev?: Maybe<Cart_Item_Stddev_Order_By>
  stddev_pop?: Maybe<Cart_Item_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Cart_Item_Stddev_Samp_Order_By>
  sum?: Maybe<Cart_Item_Sum_Order_By>
  var_pop?: Maybe<Cart_Item_Var_Pop_Order_By>
  var_samp?: Maybe<Cart_Item_Var_Samp_Order_By>
  variance?: Maybe<Cart_Item_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cart_Item_Append_Input = {
  image_metadata?: Maybe<Scalars['jsonb']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "cart_item" */
export type Cart_Item_Arr_Rel_Insert_Input = {
  data: Array<Cart_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Cart_Item_On_Conflict>
}

/** aggregate avg on columns */
export type Cart_Item_Avg_Fields = {
  __typename?: 'cart_item_avg_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "cart_item" */
export type Cart_Item_Avg_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "cart_item". All fields are combined with a logical 'AND'. */
export type Cart_Item_Bool_Exp = {
  _and?: Maybe<Array<Cart_Item_Bool_Exp>>
  _not?: Maybe<Cart_Item_Bool_Exp>
  _or?: Maybe<Array<Cart_Item_Bool_Exp>>
  affiliate_redirect_link_override?: Maybe<String_Comparison_Exp>
  archived?: Maybe<Boolean_Comparison_Exp>
  archived_at?: Maybe<Timestamptz_Comparison_Exp>
  bookmark?: Maybe<Boolean_Comparison_Exp>
  cart?: Maybe<Cart_Bool_Exp>
  cart_id?: Maybe<Uuid_Comparison_Exp>
  cart_item_likes?: Maybe<Cart_Item_Like_Bool_Exp>
  cart_item_likes_aggregate?: Maybe<Cart_Item_Like_Aggregate_Bool_Exp>
  categories_override?: Maybe<String_Array_Comparison_Exp>
  checkout?: Maybe<Checkout_Bool_Exp>
  checkout_id?: Maybe<Uuid_Comparison_Exp>
  client_sources?: Maybe<String_Array_Comparison_Exp>
  collection_cart_items?: Maybe<Collection_Cart_Item_Bool_Exp>
  collection_cart_items_aggregate?: Maybe<Collection_Cart_Item_Aggregate_Bool_Exp>
  collection_item_claim?: Maybe<Collection_Item_Claim_Bool_Exp>
  color_thief_processed?: Maybe<Boolean_Comparison_Exp>
  commentable_item_join?: Maybe<Commentable_Item_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  creator_rating?: Maybe<Numeric_Comparison_Exp>
  deal_ends_at?: Maybe<Timestamp_Comparison_Exp>
  deleted?: Maybe<Boolean_Comparison_Exp>
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  display_title?: Maybe<String_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_type?: Maybe<Feed_Item_Type_Enum_Comparison_Exp>
  hidden?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  image?: Maybe<String_Comparison_Exp>
  image_color_palette?: Maybe<Smallint_Array_Comparison_Exp>
  image_metadata?: Maybe<Jsonb_Comparison_Exp>
  image_primary_b?: Maybe<Smallint_Comparison_Exp>
  image_primary_g?: Maybe<Smallint_Comparison_Exp>
  image_primary_r?: Maybe<Smallint_Comparison_Exp>
  in_collection?: Maybe<Boolean_Comparison_Exp>
  item?: Maybe<Shop_Item_Bool_Exp>
  item_description_override?: Maybe<String_Comparison_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_image_override?: Maybe<String_Comparison_Exp>
  item_pricing_override?: Maybe<Jsonb_Comparison_Exp>
  item_title_override?: Maybe<String_Comparison_Exp>
  item_video_orientation?: Maybe<String_Comparison_Exp>
  item_video_url?: Maybe<String_Comparison_Exp>
  like_count?: Maybe<Int_Comparison_Exp>
  liked_by_user?: Maybe<Boolean_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  note?: Maybe<Cart_Item_Note_Bool_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  originating_cart_item?: Maybe<Cart_Item_Bool_Exp>
  originating_collection?: Maybe<Collection_Bool_Exp>
  originating_user?: Maybe<User_Bool_Exp>
  pinned?: Maybe<Boolean_Comparison_Exp>
  points?: Maybe<Numeric_Comparison_Exp>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum_Comparison_Exp>
  pricing?: Maybe<Jsonb_Comparison_Exp>
  quantity?: Maybe<Numeric_Comparison_Exp>
  remind_me_at?: Maybe<Timestamptz_Comparison_Exp>
  remove_reason?: Maybe<Remove_Reason_Bool_Exp>
  removed?: Maybe<Boolean_Comparison_Exp>
  removed_at?: Maybe<Timestamptz_Comparison_Exp>
  removed_reason?: Maybe<Remove_Reason_Enum_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  similar_products_histories?: Maybe<Similar_Products_History_Bool_Exp>
  similar_products_histories_aggregate?: Maybe<Similar_Products_History_Aggregate_Bool_Exp>
  source?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
}

/** unique or primary key constraints on table "cart_item" */
export enum Cart_Item_Constraint {
  /** unique or primary key constraint on columns "cart_id", "shop_key", "item_id" */
  CartItemItemIdShopKeyCartIdKey = 'cart_item_item_id_shop_key_cart_id_key',
  /** unique or primary key constraint on columns "id" */
  CartItemPkey = 'cart_item_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cart_Item_Delete_At_Path_Input = {
  image_metadata?: Maybe<Array<Scalars['String']>>
  item_pricing_override?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cart_Item_Delete_Elem_Input = {
  image_metadata?: Maybe<Scalars['Int']>
  item_pricing_override?: Maybe<Scalars['Int']>
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cart_Item_Delete_Key_Input = {
  image_metadata?: Maybe<Scalars['String']>
  item_pricing_override?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "cart_item" */
export type Cart_Item_Inc_Input = {
  creator_rating?: Maybe<Scalars['numeric']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  quantity?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "cart_item" */
export type Cart_Item_Insert_Input = {
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  archived?: Maybe<Scalars['Boolean']>
  archived_at?: Maybe<Scalars['timestamptz']>
  bookmark?: Maybe<Scalars['Boolean']>
  cart?: Maybe<Cart_Obj_Rel_Insert_Input>
  cart_id?: Maybe<Scalars['uuid']>
  cart_item_likes?: Maybe<Cart_Item_Like_Arr_Rel_Insert_Input>
  categories_override?: Maybe<Array<Scalars['String']>>
  checkout?: Maybe<Checkout_Obj_Rel_Insert_Input>
  checkout_id?: Maybe<Scalars['uuid']>
  client_sources?: Maybe<Array<Scalars['String']>>
  collection_cart_items?: Maybe<Collection_Cart_Item_Arr_Rel_Insert_Input>
  collection_item_claim?: Maybe<Collection_Item_Claim_Obj_Rel_Insert_Input>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  commentable_item_join?: Maybe<Commentable_Item_Obj_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_rating?: Maybe<Scalars['numeric']>
  deleted?: Maybe<Scalars['Boolean']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item?: Maybe<Shop_Item_Obj_Rel_Insert_Input>
  item_description_override?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['uuid']>
  item_image_override?: Maybe<Scalars['String']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  item_title_override?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  item_video_url?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  note?: Maybe<Cart_Item_Note_Obj_Rel_Insert_Input>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  pinned?: Maybe<Scalars['Boolean']>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum>
  quantity?: Maybe<Scalars['numeric']>
  remind_me_at?: Maybe<Scalars['timestamptz']>
  remove_reason?: Maybe<Remove_Reason_Obj_Rel_Insert_Input>
  removed?: Maybe<Scalars['Boolean']>
  removed_at?: Maybe<Scalars['timestamptz']>
  removed_reason?: Maybe<Remove_Reason_Enum>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  similar_products_histories?: Maybe<Similar_Products_History_Arr_Rel_Insert_Input>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "cart_item_like" */
export type Cart_Item_Like = {
  __typename?: 'cart_item_like'
  /** An object relationship */
  cart_item: Cart_Item
  cart_item_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
  value: Scalars['Int']
}

/** aggregated selection of "cart_item_like" */
export type Cart_Item_Like_Aggregate = {
  __typename?: 'cart_item_like_aggregate'
  aggregate?: Maybe<Cart_Item_Like_Aggregate_Fields>
  nodes: Array<Cart_Item_Like>
}

export type Cart_Item_Like_Aggregate_Bool_Exp = {
  count?: Maybe<Cart_Item_Like_Aggregate_Bool_Exp_Count>
}

export type Cart_Item_Like_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Cart_Item_Like_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Item_Like_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "cart_item_like" */
export type Cart_Item_Like_Aggregate_Fields = {
  __typename?: 'cart_item_like_aggregate_fields'
  avg?: Maybe<Cart_Item_Like_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Cart_Item_Like_Max_Fields>
  min?: Maybe<Cart_Item_Like_Min_Fields>
  stddev?: Maybe<Cart_Item_Like_Stddev_Fields>
  stddev_pop?: Maybe<Cart_Item_Like_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Cart_Item_Like_Stddev_Samp_Fields>
  sum?: Maybe<Cart_Item_Like_Sum_Fields>
  var_pop?: Maybe<Cart_Item_Like_Var_Pop_Fields>
  var_samp?: Maybe<Cart_Item_Like_Var_Samp_Fields>
  variance?: Maybe<Cart_Item_Like_Variance_Fields>
}

/** aggregate fields of "cart_item_like" */
export type Cart_Item_Like_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cart_Item_Like_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "cart_item_like" */
export type Cart_Item_Like_Aggregate_Order_By = {
  avg?: Maybe<Cart_Item_Like_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Cart_Item_Like_Max_Order_By>
  min?: Maybe<Cart_Item_Like_Min_Order_By>
  stddev?: Maybe<Cart_Item_Like_Stddev_Order_By>
  stddev_pop?: Maybe<Cart_Item_Like_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Cart_Item_Like_Stddev_Samp_Order_By>
  sum?: Maybe<Cart_Item_Like_Sum_Order_By>
  var_pop?: Maybe<Cart_Item_Like_Var_Pop_Order_By>
  var_samp?: Maybe<Cart_Item_Like_Var_Samp_Order_By>
  variance?: Maybe<Cart_Item_Like_Variance_Order_By>
}

/** input type for inserting array relation for remote table "cart_item_like" */
export type Cart_Item_Like_Arr_Rel_Insert_Input = {
  data: Array<Cart_Item_Like_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Cart_Item_Like_On_Conflict>
}

/** aggregate avg on columns */
export type Cart_Item_Like_Avg_Fields = {
  __typename?: 'cart_item_like_avg_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "cart_item_like" */
export type Cart_Item_Like_Avg_Order_By = {
  value?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "cart_item_like". All fields are combined with a logical 'AND'. */
export type Cart_Item_Like_Bool_Exp = {
  _and?: Maybe<Array<Cart_Item_Like_Bool_Exp>>
  _not?: Maybe<Cart_Item_Like_Bool_Exp>
  _or?: Maybe<Array<Cart_Item_Like_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  cart_item_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  value?: Maybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "cart_item_like" */
export enum Cart_Item_Like_Constraint {
  /** unique or primary key constraint on columns "id" */
  CartItemLikeIdKey = 'cart_item_like_id_key',
  /** unique or primary key constraint on columns "cart_item_id", "user_id" */
  CartItemLikePkey = 'cart_item_like_pkey',
}

/** input type for incrementing numeric columns in table "cart_item_like" */
export type Cart_Item_Like_Inc_Input = {
  value?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "cart_item_like" */
export type Cart_Item_Like_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** aggregate max on columns */
export type Cart_Item_Like_Max_Fields = {
  __typename?: 'cart_item_like_max_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "cart_item_like" */
export type Cart_Item_Like_Max_Order_By = {
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Cart_Item_Like_Min_Fields = {
  __typename?: 'cart_item_like_min_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "cart_item_like" */
export type Cart_Item_Like_Min_Order_By = {
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** response of any mutation on the table "cart_item_like" */
export type Cart_Item_Like_Mutation_Response = {
  __typename?: 'cart_item_like_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Cart_Item_Like>
}

/** on_conflict condition type for table "cart_item_like" */
export type Cart_Item_Like_On_Conflict = {
  constraint: Cart_Item_Like_Constraint
  update_columns?: Array<Cart_Item_Like_Update_Column>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

/** Ordering options when selecting data from "cart_item_like". */
export type Cart_Item_Like_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: cart_item_like */
export type Cart_Item_Like_Pk_Columns_Input = {
  cart_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

/** select columns of table "cart_item_like" */
export enum Cart_Item_Like_Select_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "cart_item_like" */
export type Cart_Item_Like_Set_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Cart_Item_Like_Stddev_Fields = {
  __typename?: 'cart_item_like_stddev_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "cart_item_like" */
export type Cart_Item_Like_Stddev_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Cart_Item_Like_Stddev_Pop_Fields = {
  __typename?: 'cart_item_like_stddev_pop_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "cart_item_like" */
export type Cart_Item_Like_Stddev_Pop_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Cart_Item_Like_Stddev_Samp_Fields = {
  __typename?: 'cart_item_like_stddev_samp_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "cart_item_like" */
export type Cart_Item_Like_Stddev_Samp_Order_By = {
  value?: Maybe<Order_By>
}

/** Streaming cursor of the table "cart_item_like" */
export type Cart_Item_Like_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cart_Item_Like_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cart_Item_Like_Stream_Cursor_Value_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Cart_Item_Like_Sum_Fields = {
  __typename?: 'cart_item_like_sum_fields'
  value?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "cart_item_like" */
export type Cart_Item_Like_Sum_Order_By = {
  value?: Maybe<Order_By>
}

/** update columns of table "cart_item_like" */
export enum Cart_Item_Like_Update_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
}

export type Cart_Item_Like_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Cart_Item_Like_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Cart_Item_Like_Set_Input>
  /** filter the rows which have to be updated */
  where: Cart_Item_Like_Bool_Exp
}

/** aggregate var_pop on columns */
export type Cart_Item_Like_Var_Pop_Fields = {
  __typename?: 'cart_item_like_var_pop_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "cart_item_like" */
export type Cart_Item_Like_Var_Pop_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Cart_Item_Like_Var_Samp_Fields = {
  __typename?: 'cart_item_like_var_samp_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "cart_item_like" */
export type Cart_Item_Like_Var_Samp_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Cart_Item_Like_Variance_Fields = {
  __typename?: 'cart_item_like_variance_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "cart_item_like" */
export type Cart_Item_Like_Variance_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate max on columns */
export type Cart_Item_Max_Fields = {
  __typename?: 'cart_item_max_fields'
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  archived_at?: Maybe<Scalars['timestamptz']>
  cart_id?: Maybe<Scalars['uuid']>
  categories_override?: Maybe<Array<Scalars['String']>>
  checkout_id?: Maybe<Scalars['uuid']>
  client_sources?: Maybe<Array<Scalars['String']>>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_rating?: Maybe<Scalars['numeric']>
  deal_ends_at?: Maybe<Scalars['timestamp']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  /** Computed field that returns the description override or the real description of the item. */
  description?: Maybe<Scalars['String']>
  /** Computed field that returns the tittle override or the real title. */
  display_title?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  /** Computed field on `cart_item` that returns the image for the cart item. */
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item_description_override?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['uuid']>
  item_image_override?: Maybe<Scalars['String']>
  item_title_override?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  item_video_url?: Maybe<Scalars['String']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['numeric']>
  remind_me_at?: Maybe<Scalars['timestamptz']>
  removed_at?: Maybe<Scalars['timestamptz']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "cart_item" */
export type Cart_Item_Max_Order_By = {
  affiliate_redirect_link_override?: Maybe<Order_By>
  archived_at?: Maybe<Order_By>
  cart_id?: Maybe<Order_By>
  categories_override?: Maybe<Order_By>
  checkout_id?: Maybe<Order_By>
  client_sources?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator_rating?: Maybe<Order_By>
  deal_ends_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image_color_palette?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  item_description_override?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_image_override?: Maybe<Order_By>
  item_title_override?: Maybe<Order_By>
  item_video_orientation?: Maybe<Order_By>
  item_video_url?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
  remind_me_at?: Maybe<Order_By>
  removed_at?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Cart_Item_Min_Fields = {
  __typename?: 'cart_item_min_fields'
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  archived_at?: Maybe<Scalars['timestamptz']>
  cart_id?: Maybe<Scalars['uuid']>
  categories_override?: Maybe<Array<Scalars['String']>>
  checkout_id?: Maybe<Scalars['uuid']>
  client_sources?: Maybe<Array<Scalars['String']>>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_rating?: Maybe<Scalars['numeric']>
  deal_ends_at?: Maybe<Scalars['timestamp']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  /** Computed field that returns the description override or the real description of the item. */
  description?: Maybe<Scalars['String']>
  /** Computed field that returns the tittle override or the real title. */
  display_title?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  /** Computed field on `cart_item` that returns the image for the cart item. */
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item_description_override?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['uuid']>
  item_image_override?: Maybe<Scalars['String']>
  item_title_override?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  item_video_url?: Maybe<Scalars['String']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['numeric']>
  remind_me_at?: Maybe<Scalars['timestamptz']>
  removed_at?: Maybe<Scalars['timestamptz']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "cart_item" */
export type Cart_Item_Min_Order_By = {
  affiliate_redirect_link_override?: Maybe<Order_By>
  archived_at?: Maybe<Order_By>
  cart_id?: Maybe<Order_By>
  categories_override?: Maybe<Order_By>
  checkout_id?: Maybe<Order_By>
  client_sources?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator_rating?: Maybe<Order_By>
  deal_ends_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image_color_palette?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  item_description_override?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_image_override?: Maybe<Order_By>
  item_title_override?: Maybe<Order_By>
  item_video_orientation?: Maybe<Order_By>
  item_video_url?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
  remind_me_at?: Maybe<Order_By>
  removed_at?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "cart_item" */
export type Cart_Item_Mutation_Response = {
  __typename?: 'cart_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Cart_Item>
}

/** columns and relationships of "cart_item_note" */
export type Cart_Item_Note = {
  __typename?: 'cart_item_note'
  /** An object relationship */
  cart_item: Cart_Item
  cart_item_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  note?: Maybe<Scalars['String']>
  private: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "cart_item_note" */
export type Cart_Item_Note_Aggregate = {
  __typename?: 'cart_item_note_aggregate'
  aggregate?: Maybe<Cart_Item_Note_Aggregate_Fields>
  nodes: Array<Cart_Item_Note>
}

/** aggregate fields of "cart_item_note" */
export type Cart_Item_Note_Aggregate_Fields = {
  __typename?: 'cart_item_note_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Cart_Item_Note_Max_Fields>
  min?: Maybe<Cart_Item_Note_Min_Fields>
}

/** aggregate fields of "cart_item_note" */
export type Cart_Item_Note_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cart_Item_Note_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "cart_item_note". All fields are combined with a logical 'AND'. */
export type Cart_Item_Note_Bool_Exp = {
  _and?: Maybe<Array<Cart_Item_Note_Bool_Exp>>
  _not?: Maybe<Cart_Item_Note_Bool_Exp>
  _or?: Maybe<Array<Cart_Item_Note_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  cart_item_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  note?: Maybe<String_Comparison_Exp>
  private?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "cart_item_note" */
export enum Cart_Item_Note_Constraint {
  /** unique or primary key constraint on columns "cart_item_id" */
  CartItemNoteCartItemIdKey = 'cart_item_note_cart_item_id_key',
  /** unique or primary key constraint on columns "id" */
  CartItemNoteIdKey = 'cart_item_note_id_key',
  /** unique or primary key constraint on columns "id" */
  CartItemNotePkey = 'cart_item_note_pkey',
}

/** input type for inserting data into table "cart_item_note" */
export type Cart_Item_Note_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  note?: Maybe<Scalars['String']>
  private?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Cart_Item_Note_Max_Fields = {
  __typename?: 'cart_item_note_max_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  note?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Cart_Item_Note_Min_Fields = {
  __typename?: 'cart_item_note_min_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  note?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "cart_item_note" */
export type Cart_Item_Note_Mutation_Response = {
  __typename?: 'cart_item_note_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Cart_Item_Note>
}

/** input type for inserting object relation for remote table "cart_item_note" */
export type Cart_Item_Note_Obj_Rel_Insert_Input = {
  data: Cart_Item_Note_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Cart_Item_Note_On_Conflict>
}

/** on_conflict condition type for table "cart_item_note" */
export type Cart_Item_Note_On_Conflict = {
  constraint: Cart_Item_Note_Constraint
  update_columns?: Array<Cart_Item_Note_Update_Column>
  where?: Maybe<Cart_Item_Note_Bool_Exp>
}

/** Ordering options when selecting data from "cart_item_note". */
export type Cart_Item_Note_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  note?: Maybe<Order_By>
  private?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: cart_item_note */
export type Cart_Item_Note_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "cart_item_note" */
export enum Cart_Item_Note_Select_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Private = 'private',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "cart_item_note" */
export type Cart_Item_Note_Set_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  note?: Maybe<Scalars['String']>
  private?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "cart_item_note" */
export type Cart_Item_Note_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cart_Item_Note_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cart_Item_Note_Stream_Cursor_Value_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  note?: Maybe<Scalars['String']>
  private?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "cart_item_note" */
export enum Cart_Item_Note_Update_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Private = 'private',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Cart_Item_Note_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Cart_Item_Note_Set_Input>
  /** filter the rows which have to be updated */
  where: Cart_Item_Note_Bool_Exp
}

/** input type for inserting object relation for remote table "cart_item" */
export type Cart_Item_Obj_Rel_Insert_Input = {
  data: Cart_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Cart_Item_On_Conflict>
}

/** on_conflict condition type for table "cart_item" */
export type Cart_Item_On_Conflict = {
  constraint: Cart_Item_Constraint
  update_columns?: Array<Cart_Item_Update_Column>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** Ordering options when selecting data from "cart_item". */
export type Cart_Item_Order_By = {
  affiliate_redirect_link_override?: Maybe<Order_By>
  archived?: Maybe<Order_By>
  archived_at?: Maybe<Order_By>
  bookmark?: Maybe<Order_By>
  cart?: Maybe<Cart_Order_By>
  cart_id?: Maybe<Order_By>
  cart_item_likes_aggregate?: Maybe<Cart_Item_Like_Aggregate_Order_By>
  categories_override?: Maybe<Order_By>
  checkout?: Maybe<Checkout_Order_By>
  checkout_id?: Maybe<Order_By>
  client_sources?: Maybe<Order_By>
  collection_cart_items_aggregate?: Maybe<Collection_Cart_Item_Aggregate_Order_By>
  collection_item_claim?: Maybe<Collection_Item_Claim_Order_By>
  color_thief_processed?: Maybe<Order_By>
  commentable_item_join?: Maybe<Commentable_Item_Order_By>
  created_at?: Maybe<Order_By>
  creator_rating?: Maybe<Order_By>
  deal_ends_at?: Maybe<Order_By>
  deleted?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  display_title?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_type?: Maybe<Order_By>
  hidden?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image?: Maybe<Order_By>
  image_color_palette?: Maybe<Order_By>
  image_metadata?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  in_collection?: Maybe<Order_By>
  item?: Maybe<Shop_Item_Order_By>
  item_description_override?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_image_override?: Maybe<Order_By>
  item_pricing_override?: Maybe<Order_By>
  item_title_override?: Maybe<Order_By>
  item_video_orientation?: Maybe<Order_By>
  item_video_url?: Maybe<Order_By>
  like_count?: Maybe<Order_By>
  liked_by_user?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  note?: Maybe<Cart_Item_Note_Order_By>
  notifiable_item_type?: Maybe<Order_By>
  originating_cart_item_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>
  originating_collection_aggregate?: Maybe<Collection_Aggregate_Order_By>
  originating_user_aggregate?: Maybe<User_Aggregate_Order_By>
  pinned?: Maybe<Order_By>
  points?: Maybe<Order_By>
  polymorphic_type?: Maybe<Order_By>
  pricing?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
  remind_me_at?: Maybe<Order_By>
  remove_reason?: Maybe<Remove_Reason_Order_By>
  removed?: Maybe<Order_By>
  removed_at?: Maybe<Order_By>
  removed_reason?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  similar_products_histories_aggregate?: Maybe<Similar_Products_History_Aggregate_Order_By>
  source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_aggregate?: Maybe<User_Aggregate_Order_By>
}

/** primary key columns input for table: cart_item */
export type Cart_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cart_Item_Prepend_Input = {
  image_metadata?: Maybe<Scalars['jsonb']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "cart_item" */
export enum Cart_Item_Select_Column {
  /** column name */
  AffiliateRedirectLinkOverride = 'affiliate_redirect_link_override',
  /** column name */
  Archived = 'archived',
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  Bookmark = 'bookmark',
  /** column name */
  CartId = 'cart_id',
  /** column name */
  CategoriesOverride = 'categories_override',
  /** column name */
  CheckoutId = 'checkout_id',
  /** column name */
  ClientSources = 'client_sources',
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorRating = 'creator_rating',
  /** column name */
  DealEndsAt = 'deal_ends_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  ImageColorPalette = 'image_color_palette',
  /** column name */
  ImageMetadata = 'image_metadata',
  /** column name */
  ImagePrimaryB = 'image_primary_b',
  /** column name */
  ImagePrimaryG = 'image_primary_g',
  /** column name */
  ImagePrimaryR = 'image_primary_r',
  /** column name */
  ItemDescriptionOverride = 'item_description_override',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageOverride = 'item_image_override',
  /** column name */
  ItemPricingOverride = 'item_pricing_override',
  /** column name */
  ItemTitleOverride = 'item_title_override',
  /** column name */
  ItemVideoOrientation = 'item_video_orientation',
  /** column name */
  ItemVideoUrl = 'item_video_url',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  Pinned = 'pinned',
  /** column name */
  PolymorphicType = 'polymorphic_type',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RemindMeAt = 'remind_me_at',
  /** column name */
  Removed = 'removed',
  /** column name */
  RemovedAt = 'removed_at',
  /** column name */
  RemovedReason = 'removed_reason',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "cart_item_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cart_item" */
export enum Cart_Item_Select_Column_Cart_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Bookmark = 'bookmark',
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Pinned = 'pinned',
  /** column name */
  Removed = 'removed',
}

/** select "cart_item_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cart_item" */
export enum Cart_Item_Select_Column_Cart_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Bookmark = 'bookmark',
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Pinned = 'pinned',
  /** column name */
  Removed = 'removed',
}

/** input type for updating data in table "cart_item" */
export type Cart_Item_Set_Input = {
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  archived?: Maybe<Scalars['Boolean']>
  archived_at?: Maybe<Scalars['timestamptz']>
  bookmark?: Maybe<Scalars['Boolean']>
  cart_id?: Maybe<Scalars['uuid']>
  categories_override?: Maybe<Array<Scalars['String']>>
  checkout_id?: Maybe<Scalars['uuid']>
  client_sources?: Maybe<Array<Scalars['String']>>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_rating?: Maybe<Scalars['numeric']>
  deleted?: Maybe<Scalars['Boolean']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item_description_override?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['uuid']>
  item_image_override?: Maybe<Scalars['String']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  item_title_override?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  item_video_url?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  pinned?: Maybe<Scalars['Boolean']>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum>
  quantity?: Maybe<Scalars['numeric']>
  remind_me_at?: Maybe<Scalars['timestamptz']>
  removed?: Maybe<Scalars['Boolean']>
  removed_at?: Maybe<Scalars['timestamptz']>
  removed_reason?: Maybe<Remove_Reason_Enum>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Cart_Item_Stddev_Fields = {
  __typename?: 'cart_item_stddev_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "cart_item" */
export type Cart_Item_Stddev_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Cart_Item_Stddev_Pop_Fields = {
  __typename?: 'cart_item_stddev_pop_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "cart_item" */
export type Cart_Item_Stddev_Pop_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Cart_Item_Stddev_Samp_Fields = {
  __typename?: 'cart_item_stddev_samp_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "cart_item" */
export type Cart_Item_Stddev_Samp_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** Streaming cursor of the table "cart_item" */
export type Cart_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cart_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cart_Item_Stream_Cursor_Value_Input = {
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  archived?: Maybe<Scalars['Boolean']>
  archived_at?: Maybe<Scalars['timestamptz']>
  bookmark?: Maybe<Scalars['Boolean']>
  cart_id?: Maybe<Scalars['uuid']>
  categories_override?: Maybe<Array<Scalars['String']>>
  checkout_id?: Maybe<Scalars['uuid']>
  client_sources?: Maybe<Array<Scalars['String']>>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_rating?: Maybe<Scalars['numeric']>
  deal_ends_at?: Maybe<Scalars['timestamp']>
  deleted?: Maybe<Scalars['Boolean']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item_description_override?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['uuid']>
  item_image_override?: Maybe<Scalars['String']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  item_title_override?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  item_video_url?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  pinned?: Maybe<Scalars['Boolean']>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum>
  quantity?: Maybe<Scalars['numeric']>
  remind_me_at?: Maybe<Scalars['timestamptz']>
  removed?: Maybe<Scalars['Boolean']>
  removed_at?: Maybe<Scalars['timestamptz']>
  removed_reason?: Maybe<Remove_Reason_Enum>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Cart_Item_Sum_Fields = {
  __typename?: 'cart_item_sum_fields'
  creator_rating?: Maybe<Scalars['numeric']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "cart_item" */
export type Cart_Item_Sum_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** update columns of table "cart_item" */
export enum Cart_Item_Update_Column {
  /** column name */
  AffiliateRedirectLinkOverride = 'affiliate_redirect_link_override',
  /** column name */
  Archived = 'archived',
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  Bookmark = 'bookmark',
  /** column name */
  CartId = 'cart_id',
  /** column name */
  CategoriesOverride = 'categories_override',
  /** column name */
  CheckoutId = 'checkout_id',
  /** column name */
  ClientSources = 'client_sources',
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorRating = 'creator_rating',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  ImageColorPalette = 'image_color_palette',
  /** column name */
  ImageMetadata = 'image_metadata',
  /** column name */
  ImagePrimaryB = 'image_primary_b',
  /** column name */
  ImagePrimaryG = 'image_primary_g',
  /** column name */
  ImagePrimaryR = 'image_primary_r',
  /** column name */
  ItemDescriptionOverride = 'item_description_override',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageOverride = 'item_image_override',
  /** column name */
  ItemPricingOverride = 'item_pricing_override',
  /** column name */
  ItemTitleOverride = 'item_title_override',
  /** column name */
  ItemVideoOrientation = 'item_video_orientation',
  /** column name */
  ItemVideoUrl = 'item_video_url',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  Pinned = 'pinned',
  /** column name */
  PolymorphicType = 'polymorphic_type',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RemindMeAt = 'remind_me_at',
  /** column name */
  Removed = 'removed',
  /** column name */
  RemovedAt = 'removed_at',
  /** column name */
  RemovedReason = 'removed_reason',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Cart_Item_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Cart_Item_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Cart_Item_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Cart_Item_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Cart_Item_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Cart_Item_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Cart_Item_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Cart_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Cart_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Cart_Item_Var_Pop_Fields = {
  __typename?: 'cart_item_var_pop_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "cart_item" */
export type Cart_Item_Var_Pop_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Cart_Item_Var_Samp_Fields = {
  __typename?: 'cart_item_var_samp_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "cart_item" */
export type Cart_Item_Var_Samp_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Cart_Item_Variance_Fields = {
  __typename?: 'cart_item_variance_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "cart_item" */
export type Cart_Item_Variance_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** aggregate max on columns */
export type Cart_Max_Fields = {
  __typename?: 'cart_max_fields'
  cleared_at?: Maybe<Scalars['date']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  /** Retrieves the last cart item's created_at */
  last_cart_item_created_at?: Maybe<Scalars['timestamptz']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "cart" */
export type Cart_Max_Order_By = {
  cleared_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Cart_Min_Fields = {
  __typename?: 'cart_min_fields'
  cleared_at?: Maybe<Scalars['date']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  /** Retrieves the last cart item's created_at */
  last_cart_item_created_at?: Maybe<Scalars['timestamptz']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "cart" */
export type Cart_Min_Order_By = {
  cleared_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "cart" */
export type Cart_Mutation_Response = {
  __typename?: 'cart_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Cart>
}

/** input type for inserting object relation for remote table "cart" */
export type Cart_Obj_Rel_Insert_Input = {
  data: Cart_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Cart_On_Conflict>
}

/** on_conflict condition type for table "cart" */
export type Cart_On_Conflict = {
  constraint: Cart_Constraint
  update_columns?: Array<Cart_Update_Column>
  where?: Maybe<Cart_Bool_Exp>
}

/** Ordering options when selecting data from "cart". */
export type Cart_Order_By = {
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>
  cleared_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  hidden?: Maybe<Order_By>
  id?: Maybe<Order_By>
  last_cart_item_created_at?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  short_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: cart */
export type Cart_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "cart" */
export enum Cart_Select_Column {
  /** column name */
  ClearedAt = 'cleared_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "cart_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cart" */
export enum Cart_Select_Column_Cart_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Hidden = 'hidden',
}

/** select "cart_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cart" */
export enum Cart_Select_Column_Cart_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Hidden = 'hidden',
}

/** input type for updating data in table "cart" */
export type Cart_Set_Input = {
  cleared_at?: Maybe<Scalars['date']>
  created_at?: Maybe<Scalars['timestamptz']>
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  short_id?: Maybe<Scalars['name']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "cart" */
export type Cart_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cart_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cart_Stream_Cursor_Value_Input = {
  cleared_at?: Maybe<Scalars['date']>
  created_at?: Maybe<Scalars['timestamptz']>
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  short_id?: Maybe<Scalars['name']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "cart" */
export enum Cart_Update_Column {
  /** column name */
  ClearedAt = 'cleared_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Cart_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Cart_Set_Input>
  /** filter the rows which have to be updated */
  where: Cart_Bool_Exp
}

/** This table contains the categories tree */
export type Categories = {
  __typename?: 'categories'
  /** Creates a JSONB array of the breadcrumb list for a given child category */
  breadcrumb_list?: Maybe<Scalars['jsonb']>
  /** An array relationship */
  child_categories: Array<Categories>
  /** An aggregate relationship */
  child_categories_aggregate: Categories_Aggregate
  created_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  name: Scalars['String']
  /** An object relationship */
  parent_category?: Maybe<Categories>
  parent_id?: Maybe<Scalars['uuid']>
  source: Scalars['String']
  source_category_id: Scalars['String']
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** This table contains the categories tree */
export type CategoriesBreadcrumb_ListArgs = {
  path?: Maybe<Scalars['String']>
}

/** This table contains the categories tree */
export type CategoriesChild_CategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Categories_Order_By>>
  where?: Maybe<Categories_Bool_Exp>
}

/** This table contains the categories tree */
export type CategoriesChild_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Categories_Order_By>>
  where?: Maybe<Categories_Bool_Exp>
}

/** aggregated selection of "categories" */
export type Categories_Aggregate = {
  __typename?: 'categories_aggregate'
  aggregate?: Maybe<Categories_Aggregate_Fields>
  nodes: Array<Categories>
}

export type Categories_Aggregate_Bool_Exp = {
  count?: Maybe<Categories_Aggregate_Bool_Exp_Count>
}

export type Categories_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Categories_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Categories_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "categories" */
export type Categories_Aggregate_Fields = {
  __typename?: 'categories_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Categories_Max_Fields>
  min?: Maybe<Categories_Min_Fields>
}

/** aggregate fields of "categories" */
export type Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Categories_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "categories" */
export type Categories_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Categories_Max_Order_By>
  min?: Maybe<Categories_Min_Order_By>
}

/** input type for inserting array relation for remote table "categories" */
export type Categories_Arr_Rel_Insert_Input = {
  data: Array<Categories_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Categories_On_Conflict>
}

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: Maybe<Array<Categories_Bool_Exp>>
  _not?: Maybe<Categories_Bool_Exp>
  _or?: Maybe<Array<Categories_Bool_Exp>>
  breadcrumb_list?: Maybe<Jsonb_Comparison_Exp>
  child_categories?: Maybe<Categories_Bool_Exp>
  child_categories_aggregate?: Maybe<Categories_Aggregate_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  parent_category?: Maybe<Categories_Bool_Exp>
  parent_id?: Maybe<Uuid_Comparison_Exp>
  source?: Maybe<String_Comparison_Exp>
  source_category_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  CategoriesPkey = 'categories_pkey',
}

/** input type for inserting data into table "categories" */
export type Categories_Insert_Input = {
  child_categories?: Maybe<Categories_Arr_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  parent_category?: Maybe<Categories_Obj_Rel_Insert_Input>
  parent_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  source_category_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Categories_Max_Fields = {
  __typename?: 'categories_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  parent_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  source_category_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "categories" */
export type Categories_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  parent_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  source_category_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Categories_Min_Fields = {
  __typename?: 'categories_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  parent_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  source_category_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "categories" */
export type Categories_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  parent_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  source_category_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "categories" */
export type Categories_Mutation_Response = {
  __typename?: 'categories_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Categories>
}

/** input type for inserting object relation for remote table "categories" */
export type Categories_Obj_Rel_Insert_Input = {
  data: Categories_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Categories_On_Conflict>
}

/** on_conflict condition type for table "categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint
  update_columns?: Array<Categories_Update_Column>
  where?: Maybe<Categories_Bool_Exp>
}

/** Ordering options when selecting data from "categories". */
export type Categories_Order_By = {
  breadcrumb_list?: Maybe<Order_By>
  child_categories_aggregate?: Maybe<Categories_Aggregate_Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  parent_category?: Maybe<Categories_Order_By>
  parent_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  source_category_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: categories */
export type Categories_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "categories" */
export enum Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceCategoryId = 'source_category_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "categories" */
export type Categories_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  parent_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  source_category_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "categories" */
export type Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Categories_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Categories_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  parent_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  source_category_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "categories" */
export enum Categories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceCategoryId = 'source_category_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Categories_Set_Input>
  /** filter the rows which have to be updated */
  where: Categories_Bool_Exp
}

/** Associates a shop item to a category in our database */
export type Category_Shop_Item = {
  __typename?: 'category_shop_item'
  category_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  match_percentage: Scalars['float8']
  shop_item_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "category_shop_item" */
export type Category_Shop_Item_Aggregate = {
  __typename?: 'category_shop_item_aggregate'
  aggregate?: Maybe<Category_Shop_Item_Aggregate_Fields>
  nodes: Array<Category_Shop_Item>
}

/** aggregate fields of "category_shop_item" */
export type Category_Shop_Item_Aggregate_Fields = {
  __typename?: 'category_shop_item_aggregate_fields'
  avg?: Maybe<Category_Shop_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Category_Shop_Item_Max_Fields>
  min?: Maybe<Category_Shop_Item_Min_Fields>
  stddev?: Maybe<Category_Shop_Item_Stddev_Fields>
  stddev_pop?: Maybe<Category_Shop_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Category_Shop_Item_Stddev_Samp_Fields>
  sum?: Maybe<Category_Shop_Item_Sum_Fields>
  var_pop?: Maybe<Category_Shop_Item_Var_Pop_Fields>
  var_samp?: Maybe<Category_Shop_Item_Var_Samp_Fields>
  variance?: Maybe<Category_Shop_Item_Variance_Fields>
}

/** aggregate fields of "category_shop_item" */
export type Category_Shop_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Category_Shop_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Category_Shop_Item_Avg_Fields = {
  __typename?: 'category_shop_item_avg_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "category_shop_item". All fields are combined with a logical 'AND'. */
export type Category_Shop_Item_Bool_Exp = {
  _and?: Maybe<Array<Category_Shop_Item_Bool_Exp>>
  _not?: Maybe<Category_Shop_Item_Bool_Exp>
  _or?: Maybe<Array<Category_Shop_Item_Bool_Exp>>
  category_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  match_percentage?: Maybe<Float8_Comparison_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "category_shop_item" */
export enum Category_Shop_Item_Constraint {
  /** unique or primary key constraint on columns "category_id", "shop_item_id" */
  CategoryShopItemCategoryIdShopItemIdKey = 'category_shop_item_category_id_shop_item_id_key',
  /** unique or primary key constraint on columns "id" */
  CategoryShopItemPkey = 'category_shop_item_pkey',
}

/** input type for incrementing numeric columns in table "category_shop_item" */
export type Category_Shop_Item_Inc_Input = {
  match_percentage?: Maybe<Scalars['float8']>
}

/** input type for inserting data into table "category_shop_item" */
export type Category_Shop_Item_Insert_Input = {
  category_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  match_percentage?: Maybe<Scalars['float8']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Category_Shop_Item_Max_Fields = {
  __typename?: 'category_shop_item_max_fields'
  category_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  match_percentage?: Maybe<Scalars['float8']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Category_Shop_Item_Min_Fields = {
  __typename?: 'category_shop_item_min_fields'
  category_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  match_percentage?: Maybe<Scalars['float8']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "category_shop_item" */
export type Category_Shop_Item_Mutation_Response = {
  __typename?: 'category_shop_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Category_Shop_Item>
}

/** on_conflict condition type for table "category_shop_item" */
export type Category_Shop_Item_On_Conflict = {
  constraint: Category_Shop_Item_Constraint
  update_columns?: Array<Category_Shop_Item_Update_Column>
  where?: Maybe<Category_Shop_Item_Bool_Exp>
}

/** Ordering options when selecting data from "category_shop_item". */
export type Category_Shop_Item_Order_By = {
  category_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  match_percentage?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: category_shop_item */
export type Category_Shop_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "category_shop_item" */
export enum Category_Shop_Item_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MatchPercentage = 'match_percentage',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "category_shop_item" */
export type Category_Shop_Item_Set_Input = {
  category_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  match_percentage?: Maybe<Scalars['float8']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Category_Shop_Item_Stddev_Fields = {
  __typename?: 'category_shop_item_stddev_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Category_Shop_Item_Stddev_Pop_Fields = {
  __typename?: 'category_shop_item_stddev_pop_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Category_Shop_Item_Stddev_Samp_Fields = {
  __typename?: 'category_shop_item_stddev_samp_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "category_shop_item" */
export type Category_Shop_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Category_Shop_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Category_Shop_Item_Stream_Cursor_Value_Input = {
  category_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  match_percentage?: Maybe<Scalars['float8']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Category_Shop_Item_Sum_Fields = {
  __typename?: 'category_shop_item_sum_fields'
  match_percentage?: Maybe<Scalars['float8']>
}

/** update columns of table "category_shop_item" */
export enum Category_Shop_Item_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MatchPercentage = 'match_percentage',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Category_Shop_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Category_Shop_Item_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Category_Shop_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Category_Shop_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Category_Shop_Item_Var_Pop_Fields = {
  __typename?: 'category_shop_item_var_pop_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Category_Shop_Item_Var_Samp_Fields = {
  __typename?: 'category_shop_item_var_samp_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Category_Shop_Item_Variance_Fields = {
  __typename?: 'category_shop_item_variance_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** columns and relationships of "checkout" */
export type Checkout = {
  __typename?: 'checkout'
  /** An array relationship */
  cart_items: Array<Cart_Item>
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate
  created_at: Scalars['timestamptz']
  currency?: Maybe<Scalars['String']>
  customer_id?: Maybe<Scalars['String']>
  html_output?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  metadata?: Maybe<Scalars['jsonb']>
  order_created_at?: Maybe<Scalars['timestamptz']>
  order_id: Scalars['String']
  /** An object relationship */
  shop?: Maybe<Shop>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  subtotal_price: Scalars['numeric']
  total_price: Scalars['numeric']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** columns and relationships of "checkout" */
export type CheckoutCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "checkout" */
export type CheckoutCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "checkout" */
export type CheckoutMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "checkout" */
export type Checkout_Aggregate = {
  __typename?: 'checkout_aggregate'
  aggregate?: Maybe<Checkout_Aggregate_Fields>
  nodes: Array<Checkout>
}

/** aggregate fields of "checkout" */
export type Checkout_Aggregate_Fields = {
  __typename?: 'checkout_aggregate_fields'
  avg?: Maybe<Checkout_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Checkout_Max_Fields>
  min?: Maybe<Checkout_Min_Fields>
  stddev?: Maybe<Checkout_Stddev_Fields>
  stddev_pop?: Maybe<Checkout_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Checkout_Stddev_Samp_Fields>
  sum?: Maybe<Checkout_Sum_Fields>
  var_pop?: Maybe<Checkout_Var_Pop_Fields>
  var_samp?: Maybe<Checkout_Var_Samp_Fields>
  variance?: Maybe<Checkout_Variance_Fields>
}

/** aggregate fields of "checkout" */
export type Checkout_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Checkout_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Checkout_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Checkout_Avg_Fields = {
  __typename?: 'checkout_avg_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "checkout". All fields are combined with a logical 'AND'. */
export type Checkout_Bool_Exp = {
  _and?: Maybe<Array<Checkout_Bool_Exp>>
  _not?: Maybe<Checkout_Bool_Exp>
  _or?: Maybe<Array<Checkout_Bool_Exp>>
  cart_items?: Maybe<Cart_Item_Bool_Exp>
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  currency?: Maybe<String_Comparison_Exp>
  customer_id?: Maybe<String_Comparison_Exp>
  html_output?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  order_created_at?: Maybe<Timestamptz_Comparison_Exp>
  order_id?: Maybe<String_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  source?: Maybe<String_Comparison_Exp>
  subtotal_price?: Maybe<Numeric_Comparison_Exp>
  total_price?: Maybe<Numeric_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "checkout" */
export enum Checkout_Constraint {
  /** unique or primary key constraint on columns "id" */
  CheckoutPkey = 'checkout_pkey',
  /** unique or primary key constraint on columns "shop_key", "order_id" */
  CheckoutShopKeyOrderIdKey = 'checkout_shop_key_order_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Checkout_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Checkout_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Checkout_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "checkout" */
export type Checkout_Inc_Input = {
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "checkout" */
export type Checkout_Insert_Input = {
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  customer_id?: Maybe<Scalars['String']>
  html_output?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  order_created_at?: Maybe<Scalars['timestamptz']>
  order_id?: Maybe<Scalars['String']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Checkout_Max_Fields = {
  __typename?: 'checkout_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  customer_id?: Maybe<Scalars['String']>
  html_output?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  order_created_at?: Maybe<Scalars['timestamptz']>
  order_id?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Checkout_Min_Fields = {
  __typename?: 'checkout_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  customer_id?: Maybe<Scalars['String']>
  html_output?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  order_created_at?: Maybe<Scalars['timestamptz']>
  order_id?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "checkout" */
export type Checkout_Mutation_Response = {
  __typename?: 'checkout_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Checkout>
}

/** input type for inserting object relation for remote table "checkout" */
export type Checkout_Obj_Rel_Insert_Input = {
  data: Checkout_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Checkout_On_Conflict>
}

/** on_conflict condition type for table "checkout" */
export type Checkout_On_Conflict = {
  constraint: Checkout_Constraint
  update_columns?: Array<Checkout_Update_Column>
  where?: Maybe<Checkout_Bool_Exp>
}

/** Ordering options when selecting data from "checkout". */
export type Checkout_Order_By = {
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>
  created_at?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  customer_id?: Maybe<Order_By>
  html_output?: Maybe<Order_By>
  id?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  order_created_at?: Maybe<Order_By>
  order_id?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  source?: Maybe<Order_By>
  subtotal_price?: Maybe<Order_By>
  total_price?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: checkout */
export type Checkout_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Checkout_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "checkout" */
export enum Checkout_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  HtmlOutput = 'html_output',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderCreatedAt = 'order_created_at',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Source = 'source',
  /** column name */
  SubtotalPrice = 'subtotal_price',
  /** column name */
  TotalPrice = 'total_price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "checkout" */
export type Checkout_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  customer_id?: Maybe<Scalars['String']>
  html_output?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  order_created_at?: Maybe<Scalars['timestamptz']>
  order_id?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Checkout_Stddev_Fields = {
  __typename?: 'checkout_stddev_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Checkout_Stddev_Pop_Fields = {
  __typename?: 'checkout_stddev_pop_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Checkout_Stddev_Samp_Fields = {
  __typename?: 'checkout_stddev_samp_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "checkout" */
export type Checkout_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Checkout_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Checkout_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  customer_id?: Maybe<Scalars['String']>
  html_output?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  order_created_at?: Maybe<Scalars['timestamptz']>
  order_id?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Checkout_Sum_Fields = {
  __typename?: 'checkout_sum_fields'
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
}

/** update columns of table "checkout" */
export enum Checkout_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  HtmlOutput = 'html_output',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderCreatedAt = 'order_created_at',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Source = 'source',
  /** column name */
  SubtotalPrice = 'subtotal_price',
  /** column name */
  TotalPrice = 'total_price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Checkout_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Checkout_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Checkout_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Checkout_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Checkout_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Checkout_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Checkout_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Checkout_Set_Input>
  /** filter the rows which have to be updated */
  where: Checkout_Bool_Exp
}

/** aggregate var_pop on columns */
export type Checkout_Var_Pop_Fields = {
  __typename?: 'checkout_var_pop_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Checkout_Var_Samp_Fields = {
  __typename?: 'checkout_var_samp_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Checkout_Variance_Fields = {
  __typename?: 'checkout_variance_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** Boolean expression to compare columns of type "cidr". All fields are combined with logical 'AND'. */
export type Cidr_Comparison_Exp = {
  _eq?: Maybe<Scalars['cidr']>
  _gt?: Maybe<Scalars['cidr']>
  _gte?: Maybe<Scalars['cidr']>
  _in?: Maybe<Array<Scalars['cidr']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['cidr']>
  _lte?: Maybe<Scalars['cidr']>
  _neq?: Maybe<Scalars['cidr']>
  _nin?: Maybe<Array<Scalars['cidr']>>
}

/** columns and relationships of "collection" */
export type Collection = {
  __typename?: 'collection'
  all: Scalars['Boolean']
  announce_collection: Scalars['Boolean']
  bookmarks: Scalars['Boolean']
  budget?: Maybe<Scalars['numeric']>
  cart_item_max_updated_at?: Maybe<Scalars['timestamptz']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  /** An array relationship */
  collection_collaborators: Array<Collection_Collaborator>
  /** An aggregate relationship */
  collection_collaborators_aggregate: Collection_Collaborator_Aggregate
  collection_cover_image?: Maybe<Scalars['String']>
  collection_cover_images?: Maybe<Array<Scalars['String']>>
  /** An array relationship */
  collection_invites: Array<Collection_Invite>
  /** An aggregate relationship */
  collection_invites_aggregate: Collection_Invite_Aggregate
  /** An array relationship */
  collection_items: Array<Collection_Cart_Item>
  /** An aggregate relationship */
  collection_items_aggregate: Collection_Cart_Item_Aggregate
  collection_link?: Maybe<Scalars['String']>
  /** An array relationship */
  collection_sections: Array<Collection_Section>
  /** An aggregate relationship */
  collection_sections_aggregate: Collection_Section_Aggregate
  /** An array relationship */
  collection_slug_histories: Array<Collection_Slug_History>
  /** An aggregate relationship */
  collection_slug_histories_aggregate: Collection_Slug_History_Aggregate
  /** An object relationship */
  collection_state?: Maybe<Collection_State>
  collection_type: Collection_Type_Enum
  /** An object relationship */
  commentable_item_join?: Maybe<Commentable_Item>
  /** A computed field, executes function "collection_is_visible" */
  computed_is_visible?: Maybe<Scalars['Boolean']>
  cover_image?: Maybe<Scalars['String']>
  /** The generated cached image url that is based on our Imgix web proxy source */
  cover_image_cached?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  curation: Scalars['Boolean']
  description?: Maybe<Scalars['String']>
  email_excerpt?: Maybe<Scalars['String']>
  exclusive?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  feed_item?: Maybe<Feed_Item>
  feed_item_type: Feed_Item_Type_Enum
  followable_item_type: Followable_Item_Type_Enum
  /** A computed field, executes function "collection_followed_by_user" */
  followed_by_user?: Maybe<Scalars['Boolean']>
  giftlist: Scalars['Boolean']
  has_collaborators: Scalars['Boolean']
  /** A computed field, executes function "collection_has_valid_preview_images" */
  has_valid_preview_images_computed?: Maybe<Scalars['Boolean']>
  id: Scalars['uuid']
  /** A computed field, executes function "collection_is_a_collaborator" */
  is_a_collaborator?: Maybe<Scalars['Boolean']>
  item_notifications: Scalars['Boolean']
  /** A computed field, executes function "collection_liked_by_user" */
  liked_by_user?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  media_enabled_join?: Maybe<Media_Enabled_Item>
  metadata?: Maybe<Scalars['jsonb']>
  name: Scalars['name']
  notifiable_item_type: Notifiable_Item_Type_Enum
  notification_preferences: Scalars['jsonb']
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  /** A computed field, executes function "collection_open_collab_link" */
  open_collab_link_computed?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  paid: Scalars['Boolean']
  /** A computed field, executes function "collection_path" */
  path?: Maybe<Scalars['String']>
  polymorphic_type: Commentable_Item_Type_Enum
  /** A computed field, executes function "collection_preview_collection_items" */
  preview_collection_items?: Maybe<Array<Collection_Cart_Item>>
  private: Scalars['Boolean']
  searchable_name?: Maybe<Scalars['String']>
  seo_fields?: Maybe<Scalars['jsonb']>
  short_id?: Maybe<Scalars['name']>
  slug: Scalars['name']
  state?: Maybe<Collection_State_Enum>
  steal_their_look?: Maybe<Scalars['Boolean']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
  /** A computed field, executes function "collection_viewable_collection_items" */
  viewable_collection_items?: Maybe<Array<Collection_Cart_Item>>
}

/** columns and relationships of "collection" */
export type CollectionCollection_CollaboratorsArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_Collaborators_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_InvitesArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Order_By>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_Invites_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Order_By>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_ItemsArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_SectionsArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Order_By>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_Sections_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Order_By>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_Slug_HistoriesArgs = {
  distinct_on?: Maybe<Array<Collection_Slug_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Slug_History_Order_By>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_Slug_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Slug_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Slug_History_Order_By>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "collection" */
export type CollectionNotification_PreferencesArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "collection" */
export type CollectionPreview_Collection_ItemsArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionSeo_FieldsArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "collection" */
export type CollectionViewable_Collection_ItemsArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** aggregated selection of "collection" */
export type Collection_Aggregate = {
  __typename?: 'collection_aggregate'
  aggregate?: Maybe<Collection_Aggregate_Fields>
  nodes: Array<Collection>
}

export type Collection_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Collection_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Collection_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Collection_Aggregate_Bool_Exp_Count>
}

export type Collection_Aggregate_Bool_Exp_Bool_And = {
  arguments: Collection_Select_Column_Collection_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Collection_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Collection_Select_Column_Collection_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Collection_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection" */
export type Collection_Aggregate_Fields = {
  __typename?: 'collection_aggregate_fields'
  avg?: Maybe<Collection_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Collection_Max_Fields>
  min?: Maybe<Collection_Min_Fields>
  stddev?: Maybe<Collection_Stddev_Fields>
  stddev_pop?: Maybe<Collection_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Collection_Stddev_Samp_Fields>
  sum?: Maybe<Collection_Sum_Fields>
  var_pop?: Maybe<Collection_Var_Pop_Fields>
  var_samp?: Maybe<Collection_Var_Samp_Fields>
  variance?: Maybe<Collection_Variance_Fields>
}

/** aggregate fields of "collection" */
export type Collection_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection" */
export type Collection_Aggregate_Order_By = {
  avg?: Maybe<Collection_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Max_Order_By>
  min?: Maybe<Collection_Min_Order_By>
  stddev?: Maybe<Collection_Stddev_Order_By>
  stddev_pop?: Maybe<Collection_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Collection_Stddev_Samp_Order_By>
  sum?: Maybe<Collection_Sum_Order_By>
  var_pop?: Maybe<Collection_Var_Pop_Order_By>
  var_samp?: Maybe<Collection_Var_Samp_Order_By>
  variance?: Maybe<Collection_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Collection_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
  notification_preferences?: Maybe<Scalars['jsonb']>
  seo_fields?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "collection" */
export type Collection_Arr_Rel_Insert_Input = {
  data: Array<Collection_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_On_Conflict>
}

/** aggregate avg on columns */
export type Collection_Avg_Fields = {
  __typename?: 'collection_avg_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by avg() on columns of table "collection" */
export type Collection_Avg_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "collection". All fields are combined with a logical 'AND'. */
export type Collection_Bool_Exp = {
  _and?: Maybe<Array<Collection_Bool_Exp>>
  _not?: Maybe<Collection_Bool_Exp>
  _or?: Maybe<Array<Collection_Bool_Exp>>
  all?: Maybe<Boolean_Comparison_Exp>
  announce_collection?: Maybe<Boolean_Comparison_Exp>
  bookmarks?: Maybe<Boolean_Comparison_Exp>
  budget?: Maybe<Numeric_Comparison_Exp>
  cart_item_max_updated_at?: Maybe<Timestamptz_Comparison_Exp>
  cart_items_count?: Maybe<Numeric_Comparison_Exp>
  cart_items_quantity?: Maybe<Numeric_Comparison_Exp>
  collaborators_count?: Maybe<Int_Comparison_Exp>
  collection_collaborators?: Maybe<Collection_Collaborator_Bool_Exp>
  collection_collaborators_aggregate?: Maybe<Collection_Collaborator_Aggregate_Bool_Exp>
  collection_cover_image?: Maybe<String_Comparison_Exp>
  collection_cover_images?: Maybe<String_Array_Comparison_Exp>
  collection_invites?: Maybe<Collection_Invite_Bool_Exp>
  collection_invites_aggregate?: Maybe<Collection_Invite_Aggregate_Bool_Exp>
  collection_items?: Maybe<Collection_Cart_Item_Bool_Exp>
  collection_items_aggregate?: Maybe<Collection_Cart_Item_Aggregate_Bool_Exp>
  collection_link?: Maybe<String_Comparison_Exp>
  collection_sections?: Maybe<Collection_Section_Bool_Exp>
  collection_sections_aggregate?: Maybe<Collection_Section_Aggregate_Bool_Exp>
  collection_slug_histories?: Maybe<Collection_Slug_History_Bool_Exp>
  collection_slug_histories_aggregate?: Maybe<Collection_Slug_History_Aggregate_Bool_Exp>
  collection_state?: Maybe<Collection_State_Bool_Exp>
  collection_type?: Maybe<Collection_Type_Enum_Comparison_Exp>
  commentable_item_join?: Maybe<Commentable_Item_Bool_Exp>
  computed_is_visible?: Maybe<Boolean_Comparison_Exp>
  cover_image?: Maybe<String_Comparison_Exp>
  cover_image_cached?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  curation?: Maybe<Boolean_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  email_excerpt?: Maybe<String_Comparison_Exp>
  exclusive?: Maybe<Boolean_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_type?: Maybe<Feed_Item_Type_Enum_Comparison_Exp>
  followable_item_type?: Maybe<Followable_Item_Type_Enum_Comparison_Exp>
  followed_by_user?: Maybe<Boolean_Comparison_Exp>
  giftlist?: Maybe<Boolean_Comparison_Exp>
  has_collaborators?: Maybe<Boolean_Comparison_Exp>
  has_valid_preview_images_computed?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_a_collaborator?: Maybe<Boolean_Comparison_Exp>
  item_notifications?: Maybe<Boolean_Comparison_Exp>
  liked_by_user?: Maybe<Boolean_Comparison_Exp>
  media_enabled_join?: Maybe<Media_Enabled_Item_Bool_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  name?: Maybe<Name_Comparison_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  notification_preferences?: Maybe<Jsonb_Comparison_Exp>
  ogimage_fingerprint?: Maybe<String_Comparison_Exp>
  ogimage_fingerprint_updated?: Maybe<String_Comparison_Exp>
  open_collab_link_computed?: Maybe<String_Comparison_Exp>
  order?: Maybe<Numeric_Comparison_Exp>
  paid?: Maybe<Boolean_Comparison_Exp>
  path?: Maybe<String_Comparison_Exp>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum_Comparison_Exp>
  preview_collection_items?: Maybe<Collection_Cart_Item_Bool_Exp>
  private?: Maybe<Boolean_Comparison_Exp>
  searchable_name?: Maybe<String_Comparison_Exp>
  seo_fields?: Maybe<Jsonb_Comparison_Exp>
  short_id?: Maybe<Name_Comparison_Exp>
  slug?: Maybe<Name_Comparison_Exp>
  state?: Maybe<Collection_State_Enum_Comparison_Exp>
  steal_their_look?: Maybe<Boolean_Comparison_Exp>
  total_comments_count?: Maybe<Numeric_Comparison_Exp>
  total_likes_count?: Maybe<Numeric_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  viewable_collection_items?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** columns and relationships of "collection_cart_item" */
export type Collection_Cart_Item = {
  __typename?: 'collection_cart_item'
  /** An object relationship */
  cart_item: Cart_Item
  cart_item_id: Scalars['uuid']
  /** An object relationship */
  collection: Collection
  collection_id: Scalars['uuid']
  /** An object relationship */
  collection_section?: Maybe<Collection_Section>
  /** An object relationship */
  commentable_item_join?: Maybe<Commentable_Item>
  created_at: Scalars['timestamptz']
  /** An object relationship */
  feed_item?: Maybe<Feed_Item>
  feed_item_type: Feed_Item_Type_Enum
  id: Scalars['uuid']
  /** A computed field, executes function "collection_cart_item_liked_by_user" */
  liked_by_user?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_item_type: Notifiable_Item_Type_Enum
  order?: Maybe<Scalars['numeric']>
  polymorphic_type: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "collection_cart_item" */
export type Collection_Cart_ItemMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "collection_cart_item" */
export type Collection_Cart_Item_Aggregate = {
  __typename?: 'collection_cart_item_aggregate'
  aggregate?: Maybe<Collection_Cart_Item_Aggregate_Fields>
  nodes: Array<Collection_Cart_Item>
}

export type Collection_Cart_Item_Aggregate_Bool_Exp = {
  count?: Maybe<Collection_Cart_Item_Aggregate_Bool_Exp_Count>
}

export type Collection_Cart_Item_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Cart_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_cart_item" */
export type Collection_Cart_Item_Aggregate_Fields = {
  __typename?: 'collection_cart_item_aggregate_fields'
  avg?: Maybe<Collection_Cart_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Collection_Cart_Item_Max_Fields>
  min?: Maybe<Collection_Cart_Item_Min_Fields>
  stddev?: Maybe<Collection_Cart_Item_Stddev_Fields>
  stddev_pop?: Maybe<Collection_Cart_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Collection_Cart_Item_Stddev_Samp_Fields>
  sum?: Maybe<Collection_Cart_Item_Sum_Fields>
  var_pop?: Maybe<Collection_Cart_Item_Var_Pop_Fields>
  var_samp?: Maybe<Collection_Cart_Item_Var_Samp_Fields>
  variance?: Maybe<Collection_Cart_Item_Variance_Fields>
}

/** aggregate fields of "collection_cart_item" */
export type Collection_Cart_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_cart_item" */
export type Collection_Cart_Item_Aggregate_Order_By = {
  avg?: Maybe<Collection_Cart_Item_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Cart_Item_Max_Order_By>
  min?: Maybe<Collection_Cart_Item_Min_Order_By>
  stddev?: Maybe<Collection_Cart_Item_Stddev_Order_By>
  stddev_pop?: Maybe<Collection_Cart_Item_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Collection_Cart_Item_Stddev_Samp_Order_By>
  sum?: Maybe<Collection_Cart_Item_Sum_Order_By>
  var_pop?: Maybe<Collection_Cart_Item_Var_Pop_Order_By>
  var_samp?: Maybe<Collection_Cart_Item_Var_Samp_Order_By>
  variance?: Maybe<Collection_Cart_Item_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Collection_Cart_Item_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "collection_cart_item" */
export type Collection_Cart_Item_Arr_Rel_Insert_Input = {
  data: Array<Collection_Cart_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Cart_Item_On_Conflict>
}

/** aggregate avg on columns */
export type Collection_Cart_Item_Avg_Fields = {
  __typename?: 'collection_cart_item_avg_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Avg_Order_By = {
  order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "collection_cart_item". All fields are combined with a logical 'AND'. */
export type Collection_Cart_Item_Bool_Exp = {
  _and?: Maybe<Array<Collection_Cart_Item_Bool_Exp>>
  _not?: Maybe<Collection_Cart_Item_Bool_Exp>
  _or?: Maybe<Array<Collection_Cart_Item_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  cart_item_id?: Maybe<Uuid_Comparison_Exp>
  collection?: Maybe<Collection_Bool_Exp>
  collection_id?: Maybe<Uuid_Comparison_Exp>
  collection_section?: Maybe<Collection_Section_Bool_Exp>
  commentable_item_join?: Maybe<Commentable_Item_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_type?: Maybe<Feed_Item_Type_Enum_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  liked_by_user?: Maybe<Boolean_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  order?: Maybe<Numeric_Comparison_Exp>
  polymorphic_type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_cart_item" */
export enum Collection_Cart_Item_Constraint {
  /** unique or primary key constraint on columns "cart_item_id", "collection_id" */
  CollectionCartItemCollectionIdCartItemIdKey = 'collection_cart_item_collection_id_cart_item_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionCartItemIdKey = 'collection_cart_item_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionCartItemPkey = 'collection_cart_item_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Collection_Cart_Item_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Collection_Cart_Item_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Collection_Cart_Item_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "collection_cart_item" */
export type Collection_Cart_Item_Inc_Input = {
  order?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "collection_cart_item" */
export type Collection_Cart_Item_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  cart_item_id?: Maybe<Scalars['uuid']>
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_id?: Maybe<Scalars['uuid']>
  collection_section?: Maybe<Collection_Section_Obj_Rel_Insert_Input>
  commentable_item_join?: Maybe<Commentable_Item_Obj_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  order?: Maybe<Scalars['numeric']>
  polymorphic_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Collection_Cart_Item_Max_Fields = {
  __typename?: 'collection_cart_item_max_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  polymorphic_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Max_Order_By = {
  cart_item_id?: Maybe<Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  order?: Maybe<Order_By>
  polymorphic_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Cart_Item_Min_Fields = {
  __typename?: 'collection_cart_item_min_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  polymorphic_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Min_Order_By = {
  cart_item_id?: Maybe<Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  order?: Maybe<Order_By>
  polymorphic_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_cart_item" */
export type Collection_Cart_Item_Mutation_Response = {
  __typename?: 'collection_cart_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Cart_Item>
}

/** input type for inserting object relation for remote table "collection_cart_item" */
export type Collection_Cart_Item_Obj_Rel_Insert_Input = {
  data: Collection_Cart_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_Cart_Item_On_Conflict>
}

/** on_conflict condition type for table "collection_cart_item" */
export type Collection_Cart_Item_On_Conflict = {
  constraint: Collection_Cart_Item_Constraint
  update_columns?: Array<Collection_Cart_Item_Update_Column>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** Ordering options when selecting data from "collection_cart_item". */
export type Collection_Cart_Item_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  cart_item_id?: Maybe<Order_By>
  collection?: Maybe<Collection_Order_By>
  collection_id?: Maybe<Order_By>
  collection_section?: Maybe<Collection_Section_Order_By>
  commentable_item_join?: Maybe<Commentable_Item_Order_By>
  created_at?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  liked_by_user?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  notifiable_item_type?: Maybe<Order_By>
  order?: Maybe<Order_By>
  polymorphic_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: collection_cart_item */
export type Collection_Cart_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Collection_Cart_Item_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "collection_cart_item" */
export enum Collection_Cart_Item_Select_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  Order = 'order',
  /** column name */
  PolymorphicType = 'polymorphic_type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "collection_cart_item" */
export type Collection_Cart_Item_Set_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  order?: Maybe<Scalars['numeric']>
  polymorphic_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Collection_Cart_Item_Stddev_Fields = {
  __typename?: 'collection_cart_item_stddev_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Stddev_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Collection_Cart_Item_Stddev_Pop_Fields = {
  __typename?: 'collection_cart_item_stddev_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Collection_Cart_Item_Stddev_Samp_Fields = {
  __typename?: 'collection_cart_item_stddev_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** Streaming cursor of the table "collection_cart_item" */
export type Collection_Cart_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Cart_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Cart_Item_Stream_Cursor_Value_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  order?: Maybe<Scalars['numeric']>
  polymorphic_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Collection_Cart_Item_Sum_Fields = {
  __typename?: 'collection_cart_item_sum_fields'
  order?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Sum_Order_By = {
  order?: Maybe<Order_By>
}

/** update columns of table "collection_cart_item" */
export enum Collection_Cart_Item_Update_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  Order = 'order',
  /** column name */
  PolymorphicType = 'polymorphic_type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Collection_Cart_Item_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Collection_Cart_Item_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Collection_Cart_Item_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Collection_Cart_Item_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Collection_Cart_Item_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Collection_Cart_Item_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Collection_Cart_Item_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Cart_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Cart_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Collection_Cart_Item_Var_Pop_Fields = {
  __typename?: 'collection_cart_item_var_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Var_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Collection_Cart_Item_Var_Samp_Fields = {
  __typename?: 'collection_cart_item_var_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Var_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Collection_Cart_Item_Variance_Fields = {
  __typename?: 'collection_cart_item_variance_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Variance_Order_By = {
  order?: Maybe<Order_By>
}

/** columns and relationships of "collection_collaborator" */
export type Collection_Collaborator = {
  __typename?: 'collection_collaborator'
  /** An object relationship */
  collection: Collection
  collection_id: Scalars['uuid']
  collection_invite_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  manager: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "collection_collaborator" */
export type Collection_Collaborator_Aggregate = {
  __typename?: 'collection_collaborator_aggregate'
  aggregate?: Maybe<Collection_Collaborator_Aggregate_Fields>
  nodes: Array<Collection_Collaborator>
}

export type Collection_Collaborator_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Collection_Collaborator_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Collection_Collaborator_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Collection_Collaborator_Aggregate_Bool_Exp_Count>
}

export type Collection_Collaborator_Aggregate_Bool_Exp_Bool_And = {
  arguments: Collection_Collaborator_Select_Column_Collection_Collaborator_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Collaborator_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Collection_Collaborator_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Collection_Collaborator_Select_Column_Collection_Collaborator_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Collaborator_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Collection_Collaborator_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Collaborator_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Collaborator_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_collaborator" */
export type Collection_Collaborator_Aggregate_Fields = {
  __typename?: 'collection_collaborator_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Collaborator_Max_Fields>
  min?: Maybe<Collection_Collaborator_Min_Fields>
}

/** aggregate fields of "collection_collaborator" */
export type Collection_Collaborator_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Collaborator_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_collaborator" */
export type Collection_Collaborator_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Collaborator_Max_Order_By>
  min?: Maybe<Collection_Collaborator_Min_Order_By>
}

/** input type for inserting array relation for remote table "collection_collaborator" */
export type Collection_Collaborator_Arr_Rel_Insert_Input = {
  data: Array<Collection_Collaborator_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Collaborator_On_Conflict>
}

/** Boolean expression to filter rows from the table "collection_collaborator". All fields are combined with a logical 'AND'. */
export type Collection_Collaborator_Bool_Exp = {
  _and?: Maybe<Array<Collection_Collaborator_Bool_Exp>>
  _not?: Maybe<Collection_Collaborator_Bool_Exp>
  _or?: Maybe<Array<Collection_Collaborator_Bool_Exp>>
  collection?: Maybe<Collection_Bool_Exp>
  collection_id?: Maybe<Uuid_Comparison_Exp>
  collection_invite_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  manager?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_collaborator" */
export enum Collection_Collaborator_Constraint {
  /** unique or primary key constraint on columns "user_id", "collection_id" */
  CollectionCollaboratorCollectionIdUserIdKey = 'collection_collaborator_collection_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionCollaboratorPkey = 'collection_collaborator_pkey',
}

/** input type for inserting data into table "collection_collaborator" */
export type Collection_Collaborator_Insert_Input = {
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_id?: Maybe<Scalars['uuid']>
  collection_invite_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  manager?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Collection_Collaborator_Max_Fields = {
  __typename?: 'collection_collaborator_max_fields'
  collection_id?: Maybe<Scalars['uuid']>
  collection_invite_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "collection_collaborator" */
export type Collection_Collaborator_Max_Order_By = {
  collection_id?: Maybe<Order_By>
  collection_invite_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Collaborator_Min_Fields = {
  __typename?: 'collection_collaborator_min_fields'
  collection_id?: Maybe<Scalars['uuid']>
  collection_invite_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "collection_collaborator" */
export type Collection_Collaborator_Min_Order_By = {
  collection_id?: Maybe<Order_By>
  collection_invite_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_collaborator" */
export type Collection_Collaborator_Mutation_Response = {
  __typename?: 'collection_collaborator_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Collaborator>
}

/** input type for inserting object relation for remote table "collection_collaborator" */
export type Collection_Collaborator_Obj_Rel_Insert_Input = {
  data: Collection_Collaborator_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_Collaborator_On_Conflict>
}

/** on_conflict condition type for table "collection_collaborator" */
export type Collection_Collaborator_On_Conflict = {
  constraint: Collection_Collaborator_Constraint
  update_columns?: Array<Collection_Collaborator_Update_Column>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

/** Ordering options when selecting data from "collection_collaborator". */
export type Collection_Collaborator_Order_By = {
  collection?: Maybe<Collection_Order_By>
  collection_id?: Maybe<Order_By>
  collection_invite_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  manager?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: collection_collaborator */
export type Collection_Collaborator_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "collection_collaborator" */
export enum Collection_Collaborator_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CollectionInviteId = 'collection_invite_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Manager = 'manager',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "collection_collaborator_aggregate_bool_exp_bool_and_arguments_columns" columns of table "collection_collaborator" */
export enum Collection_Collaborator_Select_Column_Collection_Collaborator_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Manager = 'manager',
}

/** select "collection_collaborator_aggregate_bool_exp_bool_or_arguments_columns" columns of table "collection_collaborator" */
export enum Collection_Collaborator_Select_Column_Collection_Collaborator_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Manager = 'manager',
}

/** input type for updating data in table "collection_collaborator" */
export type Collection_Collaborator_Set_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  collection_invite_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  manager?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "collection_collaborator" */
export type Collection_Collaborator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Collaborator_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Collaborator_Stream_Cursor_Value_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  collection_invite_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  manager?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "collection_collaborator" */
export enum Collection_Collaborator_Update_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CollectionInviteId = 'collection_invite_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Manager = 'manager',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Collection_Collaborator_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Collaborator_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Collaborator_Bool_Exp
}

/** unique or primary key constraints on table "collection" */
export enum Collection_Constraint {
  /** unique or primary key constraint on columns "id" */
  CollectionIdKey = 'collection_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionPkey = 'collection_pkey',
  /** unique or primary key constraint on columns "short_id" */
  CollectionShortIdKey = 'collection_short_id_key',
  /** unique or primary key constraint on columns "slug", "user_id" */
  CollectionUserIdSlugKey = 'collection_user_id_slug_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Collection_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
  notification_preferences?: Maybe<Array<Scalars['String']>>
  seo_fields?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Collection_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
  notification_preferences?: Maybe<Scalars['Int']>
  seo_fields?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Collection_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
  notification_preferences?: Maybe<Scalars['String']>
  seo_fields?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "collection" */
export type Collection_Inc_Input = {
  budget?: Maybe<Scalars['numeric']>
  order?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "collection" */
export type Collection_Insert_Input = {
  all?: Maybe<Scalars['Boolean']>
  announce_collection?: Maybe<Scalars['Boolean']>
  bookmarks?: Maybe<Scalars['Boolean']>
  budget?: Maybe<Scalars['numeric']>
  cart_item_max_updated_at?: Maybe<Scalars['timestamptz']>
  collection_collaborators?: Maybe<Collection_Collaborator_Arr_Rel_Insert_Input>
  collection_cover_image?: Maybe<Scalars['String']>
  collection_cover_images?: Maybe<Array<Scalars['String']>>
  collection_invites?: Maybe<Collection_Invite_Arr_Rel_Insert_Input>
  collection_items?: Maybe<Collection_Cart_Item_Arr_Rel_Insert_Input>
  collection_link?: Maybe<Scalars['String']>
  collection_sections?: Maybe<Collection_Section_Arr_Rel_Insert_Input>
  collection_slug_histories?: Maybe<Collection_Slug_History_Arr_Rel_Insert_Input>
  collection_state?: Maybe<Collection_State_Obj_Rel_Insert_Input>
  collection_type?: Maybe<Collection_Type_Enum>
  commentable_item_join?: Maybe<Commentable_Item_Obj_Rel_Insert_Input>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  curation?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  email_excerpt?: Maybe<Scalars['String']>
  exclusive?: Maybe<Scalars['Boolean']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  followable_item_type?: Maybe<Followable_Item_Type_Enum>
  giftlist?: Maybe<Scalars['Boolean']>
  has_collaborators?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  item_notifications?: Maybe<Scalars['Boolean']>
  media_enabled_join?: Maybe<Media_Enabled_Item_Obj_Rel_Insert_Input>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['name']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  notification_preferences?: Maybe<Scalars['jsonb']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  paid?: Maybe<Scalars['Boolean']>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum>
  private?: Maybe<Scalars['Boolean']>
  seo_fields?: Maybe<Scalars['jsonb']>
  short_id?: Maybe<Scalars['name']>
  slug?: Maybe<Scalars['name']>
  state?: Maybe<Collection_State_Enum>
  steal_their_look?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** columns and relationships of "collection_invite" */
export type Collection_Invite = {
  __typename?: 'collection_invite'
  /** An object relationship */
  collection: Collection
  /** An object relationship */
  collection_collaborator?: Maybe<Collection_Collaborator>
  collection_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  /** A computed field, executes function "collection_invite_for_new_user" */
  for_new_user?: Maybe<Scalars['Boolean']>
  id: Scalars['uuid']
  /** An object relationship */
  invitee?: Maybe<User>
  invitee_email?: Maybe<Scalars['String']>
  invitee_id?: Maybe<Scalars['String']>
  notifiable_item_type: Notifiable_Item_Type_Enum
  /** An object relationship */
  requester: User
  requester_id: Scalars['String']
  status: Collection_Invite_Status_Enum
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "collection_invite" */
export type Collection_Invite_Aggregate = {
  __typename?: 'collection_invite_aggregate'
  aggregate?: Maybe<Collection_Invite_Aggregate_Fields>
  nodes: Array<Collection_Invite>
}

export type Collection_Invite_Aggregate_Bool_Exp = {
  count?: Maybe<Collection_Invite_Aggregate_Bool_Exp_Count>
}

export type Collection_Invite_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Invite_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Invite_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_invite" */
export type Collection_Invite_Aggregate_Fields = {
  __typename?: 'collection_invite_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Invite_Max_Fields>
  min?: Maybe<Collection_Invite_Min_Fields>
}

/** aggregate fields of "collection_invite" */
export type Collection_Invite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Invite_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_invite" */
export type Collection_Invite_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Invite_Max_Order_By>
  min?: Maybe<Collection_Invite_Min_Order_By>
}

/** input type for inserting array relation for remote table "collection_invite" */
export type Collection_Invite_Arr_Rel_Insert_Input = {
  data: Array<Collection_Invite_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Invite_On_Conflict>
}

/** Boolean expression to filter rows from the table "collection_invite". All fields are combined with a logical 'AND'. */
export type Collection_Invite_Bool_Exp = {
  _and?: Maybe<Array<Collection_Invite_Bool_Exp>>
  _not?: Maybe<Collection_Invite_Bool_Exp>
  _or?: Maybe<Array<Collection_Invite_Bool_Exp>>
  collection?: Maybe<Collection_Bool_Exp>
  collection_collaborator?: Maybe<Collection_Collaborator_Bool_Exp>
  collection_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  for_new_user?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  invitee?: Maybe<User_Bool_Exp>
  invitee_email?: Maybe<String_Comparison_Exp>
  invitee_id?: Maybe<String_Comparison_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  requester?: Maybe<User_Bool_Exp>
  requester_id?: Maybe<String_Comparison_Exp>
  status?: Maybe<Collection_Invite_Status_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_invite" */
export enum Collection_Invite_Constraint {
  /** unique or primary key constraint on columns "invitee_id", "collection_id" */
  CollectionInviteCollectionIdInviteeIdKey = 'collection_invite_collection_id_invitee_id_key',
  /** unique or primary key constraint on columns "invitee_email", "invitee_id", "collection_id" */
  CollectionInviteEmailCollectionIdInviteeIdNotNullKey = 'collection_invite_email_collection_id_invitee_id_not_null_key',
  /** unique or primary key constraint on columns "invitee_email", "invitee_id", "collection_id" */
  CollectionInviteEmailCollectionIdInviteeIdNullKey = 'collection_invite_email_collection_id_invitee_id_null_key',
  /** unique or primary key constraint on columns "invitee_email", "collection_id" */
  CollectionInviteInviteeEmailCollectionIdKey = 'collection_invite_invitee_email_collection_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionInvitePkey = 'collection_invite_pkey',
}

/** input type for inserting data into table "collection_invite" */
export type Collection_Invite_Insert_Input = {
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_collaborator?: Maybe<Collection_Collaborator_Obj_Rel_Insert_Input>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee?: Maybe<User_Obj_Rel_Insert_Input>
  invitee_email?: Maybe<Scalars['String']>
  invitee_id?: Maybe<Scalars['String']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  requester?: Maybe<User_Obj_Rel_Insert_Input>
  requester_id?: Maybe<Scalars['String']>
  status?: Maybe<Collection_Invite_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Collection_Invite_Max_Fields = {
  __typename?: 'collection_invite_max_fields'
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_email?: Maybe<Scalars['String']>
  invitee_id?: Maybe<Scalars['String']>
  requester_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "collection_invite" */
export type Collection_Invite_Max_Order_By = {
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  invitee_email?: Maybe<Order_By>
  invitee_id?: Maybe<Order_By>
  requester_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Invite_Min_Fields = {
  __typename?: 'collection_invite_min_fields'
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_email?: Maybe<Scalars['String']>
  invitee_id?: Maybe<Scalars['String']>
  requester_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "collection_invite" */
export type Collection_Invite_Min_Order_By = {
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  invitee_email?: Maybe<Order_By>
  invitee_id?: Maybe<Order_By>
  requester_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_invite" */
export type Collection_Invite_Mutation_Response = {
  __typename?: 'collection_invite_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Invite>
}

/** input type for inserting object relation for remote table "collection_invite" */
export type Collection_Invite_Obj_Rel_Insert_Input = {
  data: Collection_Invite_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_Invite_On_Conflict>
}

/** on_conflict condition type for table "collection_invite" */
export type Collection_Invite_On_Conflict = {
  constraint: Collection_Invite_Constraint
  update_columns?: Array<Collection_Invite_Update_Column>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

/** Ordering options when selecting data from "collection_invite". */
export type Collection_Invite_Order_By = {
  collection?: Maybe<Collection_Order_By>
  collection_collaborator?: Maybe<Collection_Collaborator_Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  for_new_user?: Maybe<Order_By>
  id?: Maybe<Order_By>
  invitee?: Maybe<User_Order_By>
  invitee_email?: Maybe<Order_By>
  invitee_id?: Maybe<Order_By>
  notifiable_item_type?: Maybe<Order_By>
  requester?: Maybe<User_Order_By>
  requester_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: collection_invite */
export type Collection_Invite_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "collection_invite" */
export enum Collection_Invite_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeEmail = 'invitee_email',
  /** column name */
  InviteeId = 'invitee_id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  RequesterId = 'requester_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "collection_invite" */
export type Collection_Invite_Set_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_email?: Maybe<Scalars['String']>
  invitee_id?: Maybe<Scalars['String']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  requester_id?: Maybe<Scalars['String']>
  status?: Maybe<Collection_Invite_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "collection_invite_status" */
export type Collection_Invite_Status = {
  __typename?: 'collection_invite_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "collection_invite_status" */
export type Collection_Invite_Status_Aggregate = {
  __typename?: 'collection_invite_status_aggregate'
  aggregate?: Maybe<Collection_Invite_Status_Aggregate_Fields>
  nodes: Array<Collection_Invite_Status>
}

/** aggregate fields of "collection_invite_status" */
export type Collection_Invite_Status_Aggregate_Fields = {
  __typename?: 'collection_invite_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Invite_Status_Max_Fields>
  min?: Maybe<Collection_Invite_Status_Min_Fields>
}

/** aggregate fields of "collection_invite_status" */
export type Collection_Invite_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Invite_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "collection_invite_status". All fields are combined with a logical 'AND'. */
export type Collection_Invite_Status_Bool_Exp = {
  _and?: Maybe<Array<Collection_Invite_Status_Bool_Exp>>
  _not?: Maybe<Collection_Invite_Status_Bool_Exp>
  _or?: Maybe<Array<Collection_Invite_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_invite_status" */
export enum Collection_Invite_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  CollectionInviteStatusPkey = 'collection_invite_status_pkey',
}

export enum Collection_Invite_Status_Enum {
  Accepted = 'accepted',
  Pending = 'pending',
  Viewed = 'viewed',
}

/** Boolean expression to compare columns of type "collection_invite_status_enum". All fields are combined with logical 'AND'. */
export type Collection_Invite_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Collection_Invite_Status_Enum>
  _in?: Maybe<Array<Collection_Invite_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Collection_Invite_Status_Enum>
  _nin?: Maybe<Array<Collection_Invite_Status_Enum>>
}

/** input type for inserting data into table "collection_invite_status" */
export type Collection_Invite_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Collection_Invite_Status_Max_Fields = {
  __typename?: 'collection_invite_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Collection_Invite_Status_Min_Fields = {
  __typename?: 'collection_invite_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "collection_invite_status" */
export type Collection_Invite_Status_Mutation_Response = {
  __typename?: 'collection_invite_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Invite_Status>
}

/** on_conflict condition type for table "collection_invite_status" */
export type Collection_Invite_Status_On_Conflict = {
  constraint: Collection_Invite_Status_Constraint
  update_columns?: Array<Collection_Invite_Status_Update_Column>
  where?: Maybe<Collection_Invite_Status_Bool_Exp>
}

/** Ordering options when selecting data from "collection_invite_status". */
export type Collection_Invite_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: collection_invite_status */
export type Collection_Invite_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "collection_invite_status" */
export enum Collection_Invite_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "collection_invite_status" */
export type Collection_Invite_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "collection_invite_status" */
export type Collection_Invite_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Invite_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Invite_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "collection_invite_status" */
export enum Collection_Invite_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Collection_Invite_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Invite_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Invite_Status_Bool_Exp
}

/** Streaming cursor of the table "collection_invite" */
export type Collection_Invite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Invite_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Invite_Stream_Cursor_Value_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_email?: Maybe<Scalars['String']>
  invitee_id?: Maybe<Scalars['String']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  requester_id?: Maybe<Scalars['String']>
  status?: Maybe<Collection_Invite_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "collection_invite" */
export enum Collection_Invite_Update_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeEmail = 'invitee_email',
  /** column name */
  InviteeId = 'invitee_id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  RequesterId = 'requester_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Collection_Invite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Invite_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Invite_Bool_Exp
}

/** Used for claiming collections items on wishlists */
export type Collection_Item_Claim = {
  __typename?: 'collection_item_claim'
  /** An object relationship */
  cart_item: Cart_Item
  cart_item_id: Scalars['uuid']
  /** An object relationship */
  claim_status: Collection_Item_Claim_Status
  /** An object relationship */
  collection: Collection
  collection_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  status: Collection_Item_Claim_Status_Enum
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "collection_item_claim" */
export type Collection_Item_Claim_Aggregate = {
  __typename?: 'collection_item_claim_aggregate'
  aggregate?: Maybe<Collection_Item_Claim_Aggregate_Fields>
  nodes: Array<Collection_Item_Claim>
}

export type Collection_Item_Claim_Aggregate_Bool_Exp = {
  count?: Maybe<Collection_Item_Claim_Aggregate_Bool_Exp_Count>
}

export type Collection_Item_Claim_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Item_Claim_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_item_claim" */
export type Collection_Item_Claim_Aggregate_Fields = {
  __typename?: 'collection_item_claim_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Item_Claim_Max_Fields>
  min?: Maybe<Collection_Item_Claim_Min_Fields>
}

/** aggregate fields of "collection_item_claim" */
export type Collection_Item_Claim_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_item_claim" */
export type Collection_Item_Claim_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Item_Claim_Max_Order_By>
  min?: Maybe<Collection_Item_Claim_Min_Order_By>
}

/** input type for inserting array relation for remote table "collection_item_claim" */
export type Collection_Item_Claim_Arr_Rel_Insert_Input = {
  data: Array<Collection_Item_Claim_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Item_Claim_On_Conflict>
}

/** Boolean expression to filter rows from the table "collection_item_claim". All fields are combined with a logical 'AND'. */
export type Collection_Item_Claim_Bool_Exp = {
  _and?: Maybe<Array<Collection_Item_Claim_Bool_Exp>>
  _not?: Maybe<Collection_Item_Claim_Bool_Exp>
  _or?: Maybe<Array<Collection_Item_Claim_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  cart_item_id?: Maybe<Uuid_Comparison_Exp>
  claim_status?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
  collection?: Maybe<Collection_Bool_Exp>
  collection_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  status?: Maybe<Collection_Item_Claim_Status_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_item_claim" */
export enum Collection_Item_Claim_Constraint {
  /** unique or primary key constraint on columns "cart_item_id" */
  CollectionItemClaimCartItemIdKey = 'collection_item_claim_cart_item_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionItemClaimPkey = 'collection_item_claim_pkey',
}

/** input type for inserting data into table "collection_item_claim" */
export type Collection_Item_Claim_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  cart_item_id?: Maybe<Scalars['uuid']>
  claim_status?: Maybe<Collection_Item_Claim_Status_Obj_Rel_Insert_Input>
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  status?: Maybe<Collection_Item_Claim_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Collection_Item_Claim_Max_Fields = {
  __typename?: 'collection_item_claim_max_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "collection_item_claim" */
export type Collection_Item_Claim_Max_Order_By = {
  cart_item_id?: Maybe<Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Item_Claim_Min_Fields = {
  __typename?: 'collection_item_claim_min_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "collection_item_claim" */
export type Collection_Item_Claim_Min_Order_By = {
  cart_item_id?: Maybe<Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_item_claim" */
export type Collection_Item_Claim_Mutation_Response = {
  __typename?: 'collection_item_claim_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Item_Claim>
}

/** input type for inserting object relation for remote table "collection_item_claim" */
export type Collection_Item_Claim_Obj_Rel_Insert_Input = {
  data: Collection_Item_Claim_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_Item_Claim_On_Conflict>
}

/** on_conflict condition type for table "collection_item_claim" */
export type Collection_Item_Claim_On_Conflict = {
  constraint: Collection_Item_Claim_Constraint
  update_columns?: Array<Collection_Item_Claim_Update_Column>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

/** Ordering options when selecting data from "collection_item_claim". */
export type Collection_Item_Claim_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  cart_item_id?: Maybe<Order_By>
  claim_status?: Maybe<Collection_Item_Claim_Status_Order_By>
  collection?: Maybe<Collection_Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: collection_item_claim */
export type Collection_Item_Claim_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "collection_item_claim" */
export enum Collection_Item_Claim_Select_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "collection_item_claim" */
export type Collection_Item_Claim_Set_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  status?: Maybe<Collection_Item_Claim_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Enum table to hold collection item claim statuses */
export type Collection_Item_Claim_Status = {
  __typename?: 'collection_item_claim_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Aggregate = {
  __typename?: 'collection_item_claim_status_aggregate'
  aggregate?: Maybe<Collection_Item_Claim_Status_Aggregate_Fields>
  nodes: Array<Collection_Item_Claim_Status>
}

/** aggregate fields of "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Aggregate_Fields = {
  __typename?: 'collection_item_claim_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Item_Claim_Status_Max_Fields>
  min?: Maybe<Collection_Item_Claim_Status_Min_Fields>
}

/** aggregate fields of "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Item_Claim_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "collection_item_claim_status". All fields are combined with a logical 'AND'. */
export type Collection_Item_Claim_Status_Bool_Exp = {
  _and?: Maybe<Array<Collection_Item_Claim_Status_Bool_Exp>>
  _not?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
  _or?: Maybe<Array<Collection_Item_Claim_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_item_claim_status" */
export enum Collection_Item_Claim_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  CollectionItemClaimStatusPkey = 'collection_item_claim_status_pkey',
}

export enum Collection_Item_Claim_Status_Enum {
  /** Collection item claimed */
  Claimed = 'claimed',
  /** Default collection item claim status */
  Unclaimed = 'unclaimed',
}

/** Boolean expression to compare columns of type "collection_item_claim_status_enum". All fields are combined with logical 'AND'. */
export type Collection_Item_Claim_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Collection_Item_Claim_Status_Enum>
  _in?: Maybe<Array<Collection_Item_Claim_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Collection_Item_Claim_Status_Enum>
  _nin?: Maybe<Array<Collection_Item_Claim_Status_Enum>>
}

/** input type for inserting data into table "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Collection_Item_Claim_Status_Max_Fields = {
  __typename?: 'collection_item_claim_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Collection_Item_Claim_Status_Min_Fields = {
  __typename?: 'collection_item_claim_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Mutation_Response = {
  __typename?: 'collection_item_claim_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Item_Claim_Status>
}

/** input type for inserting object relation for remote table "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Obj_Rel_Insert_Input = {
  data: Collection_Item_Claim_Status_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_Item_Claim_Status_On_Conflict>
}

/** on_conflict condition type for table "collection_item_claim_status" */
export type Collection_Item_Claim_Status_On_Conflict = {
  constraint: Collection_Item_Claim_Status_Constraint
  update_columns?: Array<Collection_Item_Claim_Status_Update_Column>
  where?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
}

/** Ordering options when selecting data from "collection_item_claim_status". */
export type Collection_Item_Claim_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: collection_item_claim_status */
export type Collection_Item_Claim_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "collection_item_claim_status" */
export enum Collection_Item_Claim_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Item_Claim_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Item_Claim_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "collection_item_claim_status" */
export enum Collection_Item_Claim_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Collection_Item_Claim_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Item_Claim_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Item_Claim_Status_Bool_Exp
}

/** Streaming cursor of the table "collection_item_claim" */
export type Collection_Item_Claim_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Item_Claim_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Item_Claim_Stream_Cursor_Value_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  status?: Maybe<Collection_Item_Claim_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "collection_item_claim" */
export enum Collection_Item_Claim_Update_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Collection_Item_Claim_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Item_Claim_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Item_Claim_Bool_Exp
}

/** aggregate max on columns */
export type Collection_Max_Fields = {
  __typename?: 'collection_max_fields'
  budget?: Maybe<Scalars['numeric']>
  cart_item_max_updated_at?: Maybe<Scalars['timestamptz']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  collection_cover_image?: Maybe<Scalars['String']>
  collection_cover_images?: Maybe<Array<Scalars['String']>>
  collection_link?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  /** The generated cached image url that is based on our Imgix web proxy source */
  cover_image_cached?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  email_excerpt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  /** A computed field, executes function "collection_open_collab_link" */
  open_collab_link_computed?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "collection_path" */
  path?: Maybe<Scalars['String']>
  searchable_name?: Maybe<Scalars['String']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "collection" */
export type Collection_Max_Order_By = {
  budget?: Maybe<Order_By>
  cart_item_max_updated_at?: Maybe<Order_By>
  collection_cover_image?: Maybe<Order_By>
  collection_cover_images?: Maybe<Order_By>
  collection_link?: Maybe<Order_By>
  cover_image?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  email_excerpt?: Maybe<Order_By>
  id?: Maybe<Order_By>
  ogimage_fingerprint?: Maybe<Order_By>
  ogimage_fingerprint_updated?: Maybe<Order_By>
  order?: Maybe<Order_By>
  searchable_name?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Min_Fields = {
  __typename?: 'collection_min_fields'
  budget?: Maybe<Scalars['numeric']>
  cart_item_max_updated_at?: Maybe<Scalars['timestamptz']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  collection_cover_image?: Maybe<Scalars['String']>
  collection_cover_images?: Maybe<Array<Scalars['String']>>
  collection_link?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  /** The generated cached image url that is based on our Imgix web proxy source */
  cover_image_cached?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  email_excerpt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  /** A computed field, executes function "collection_open_collab_link" */
  open_collab_link_computed?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "collection_path" */
  path?: Maybe<Scalars['String']>
  searchable_name?: Maybe<Scalars['String']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "collection" */
export type Collection_Min_Order_By = {
  budget?: Maybe<Order_By>
  cart_item_max_updated_at?: Maybe<Order_By>
  collection_cover_image?: Maybe<Order_By>
  collection_cover_images?: Maybe<Order_By>
  collection_link?: Maybe<Order_By>
  cover_image?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  email_excerpt?: Maybe<Order_By>
  id?: Maybe<Order_By>
  ogimage_fingerprint?: Maybe<Order_By>
  ogimage_fingerprint_updated?: Maybe<Order_By>
  order?: Maybe<Order_By>
  searchable_name?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "collection" */
export type Collection_Mutation_Response = {
  __typename?: 'collection_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection>
}

/** input type for inserting object relation for remote table "collection" */
export type Collection_Obj_Rel_Insert_Input = {
  data: Collection_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_On_Conflict>
}

/** on_conflict condition type for table "collection" */
export type Collection_On_Conflict = {
  constraint: Collection_Constraint
  update_columns?: Array<Collection_Update_Column>
  where?: Maybe<Collection_Bool_Exp>
}

/** Ordering options when selecting data from "collection". */
export type Collection_Order_By = {
  all?: Maybe<Order_By>
  announce_collection?: Maybe<Order_By>
  bookmarks?: Maybe<Order_By>
  budget?: Maybe<Order_By>
  cart_item_max_updated_at?: Maybe<Order_By>
  cart_items_count?: Maybe<Order_By>
  cart_items_quantity?: Maybe<Order_By>
  collaborators_count?: Maybe<Order_By>
  collection_collaborators_aggregate?: Maybe<Collection_Collaborator_Aggregate_Order_By>
  collection_cover_image?: Maybe<Order_By>
  collection_cover_images?: Maybe<Order_By>
  collection_invites_aggregate?: Maybe<Collection_Invite_Aggregate_Order_By>
  collection_items_aggregate?: Maybe<Collection_Cart_Item_Aggregate_Order_By>
  collection_link?: Maybe<Order_By>
  collection_sections_aggregate?: Maybe<Collection_Section_Aggregate_Order_By>
  collection_slug_histories_aggregate?: Maybe<Collection_Slug_History_Aggregate_Order_By>
  collection_state?: Maybe<Collection_State_Order_By>
  collection_type?: Maybe<Order_By>
  commentable_item_join?: Maybe<Commentable_Item_Order_By>
  computed_is_visible?: Maybe<Order_By>
  cover_image?: Maybe<Order_By>
  cover_image_cached?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  curation?: Maybe<Order_By>
  description?: Maybe<Order_By>
  email_excerpt?: Maybe<Order_By>
  exclusive?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_type?: Maybe<Order_By>
  followable_item_type?: Maybe<Order_By>
  followed_by_user?: Maybe<Order_By>
  giftlist?: Maybe<Order_By>
  has_collaborators?: Maybe<Order_By>
  has_valid_preview_images_computed?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_a_collaborator?: Maybe<Order_By>
  item_notifications?: Maybe<Order_By>
  liked_by_user?: Maybe<Order_By>
  media_enabled_join?: Maybe<Media_Enabled_Item_Order_By>
  metadata?: Maybe<Order_By>
  name?: Maybe<Order_By>
  notifiable_item_type?: Maybe<Order_By>
  notification_preferences?: Maybe<Order_By>
  ogimage_fingerprint?: Maybe<Order_By>
  ogimage_fingerprint_updated?: Maybe<Order_By>
  open_collab_link_computed?: Maybe<Order_By>
  order?: Maybe<Order_By>
  paid?: Maybe<Order_By>
  path?: Maybe<Order_By>
  polymorphic_type?: Maybe<Order_By>
  preview_collection_items_aggregate?: Maybe<Collection_Cart_Item_Aggregate_Order_By>
  private?: Maybe<Order_By>
  searchable_name?: Maybe<Order_By>
  seo_fields?: Maybe<Order_By>
  short_id?: Maybe<Order_By>
  slug?: Maybe<Order_By>
  state?: Maybe<Order_By>
  steal_their_look?: Maybe<Order_By>
  total_comments_count?: Maybe<Order_By>
  total_likes_count?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  viewable_collection_items_aggregate?: Maybe<Collection_Cart_Item_Aggregate_Order_By>
}

/** primary key columns input for table: collection */
export type Collection_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Collection_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
  notification_preferences?: Maybe<Scalars['jsonb']>
  seo_fields?: Maybe<Scalars['jsonb']>
}

export type Collection_Random_Args = {
  seed?: Maybe<Scalars['seed_float']>
}

/** Used for Collection sections */
export type Collection_Section = {
  __typename?: 'collection_section'
  /** An object relationship */
  collection: Collection
  collection_id: Scalars['uuid']
  /** An array relationship */
  collection_section_cart_items: Array<Collection_Section_Cart_Item>
  /** An aggregate relationship */
  collection_section_cart_items_aggregate: Collection_Section_Cart_Item_Aggregate
  created_at: Scalars['timestamptz']
  description?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  name: Scalars['String']
  order: Scalars['numeric']
  slug: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** Used for Collection sections */
export type Collection_SectionCollection_Section_Cart_ItemsArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Cart_Item_Order_By>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

/** Used for Collection sections */
export type Collection_SectionCollection_Section_Cart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Cart_Item_Order_By>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

/** aggregated selection of "collection_section" */
export type Collection_Section_Aggregate = {
  __typename?: 'collection_section_aggregate'
  aggregate?: Maybe<Collection_Section_Aggregate_Fields>
  nodes: Array<Collection_Section>
}

export type Collection_Section_Aggregate_Bool_Exp = {
  count?: Maybe<Collection_Section_Aggregate_Bool_Exp_Count>
}

export type Collection_Section_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Section_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Section_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_section" */
export type Collection_Section_Aggregate_Fields = {
  __typename?: 'collection_section_aggregate_fields'
  avg?: Maybe<Collection_Section_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Collection_Section_Max_Fields>
  min?: Maybe<Collection_Section_Min_Fields>
  stddev?: Maybe<Collection_Section_Stddev_Fields>
  stddev_pop?: Maybe<Collection_Section_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Collection_Section_Stddev_Samp_Fields>
  sum?: Maybe<Collection_Section_Sum_Fields>
  var_pop?: Maybe<Collection_Section_Var_Pop_Fields>
  var_samp?: Maybe<Collection_Section_Var_Samp_Fields>
  variance?: Maybe<Collection_Section_Variance_Fields>
}

/** aggregate fields of "collection_section" */
export type Collection_Section_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Section_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_section" */
export type Collection_Section_Aggregate_Order_By = {
  avg?: Maybe<Collection_Section_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Section_Max_Order_By>
  min?: Maybe<Collection_Section_Min_Order_By>
  stddev?: Maybe<Collection_Section_Stddev_Order_By>
  stddev_pop?: Maybe<Collection_Section_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Collection_Section_Stddev_Samp_Order_By>
  sum?: Maybe<Collection_Section_Sum_Order_By>
  var_pop?: Maybe<Collection_Section_Var_Pop_Order_By>
  var_samp?: Maybe<Collection_Section_Var_Samp_Order_By>
  variance?: Maybe<Collection_Section_Variance_Order_By>
}

/** input type for inserting array relation for remote table "collection_section" */
export type Collection_Section_Arr_Rel_Insert_Input = {
  data: Array<Collection_Section_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Section_On_Conflict>
}

/** aggregate avg on columns */
export type Collection_Section_Avg_Fields = {
  __typename?: 'collection_section_avg_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "collection_section" */
export type Collection_Section_Avg_Order_By = {
  order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "collection_section". All fields are combined with a logical 'AND'. */
export type Collection_Section_Bool_Exp = {
  _and?: Maybe<Array<Collection_Section_Bool_Exp>>
  _not?: Maybe<Collection_Section_Bool_Exp>
  _or?: Maybe<Array<Collection_Section_Bool_Exp>>
  collection?: Maybe<Collection_Bool_Exp>
  collection_id?: Maybe<Uuid_Comparison_Exp>
  collection_section_cart_items?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
  collection_section_cart_items_aggregate?: Maybe<Collection_Section_Cart_Item_Aggregate_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  order?: Maybe<Numeric_Comparison_Exp>
  slug?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** Join table for collection cart items and collection sections */
export type Collection_Section_Cart_Item = {
  __typename?: 'collection_section_cart_item'
  /** An object relationship */
  collection_cart_item: Collection_Cart_Item
  collection_cart_item_id: Scalars['uuid']
  /** An object relationship */
  collection_section: Collection_Section
  collection_section_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  order?: Maybe<Scalars['numeric']>
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Aggregate = {
  __typename?: 'collection_section_cart_item_aggregate'
  aggregate?: Maybe<Collection_Section_Cart_Item_Aggregate_Fields>
  nodes: Array<Collection_Section_Cart_Item>
}

export type Collection_Section_Cart_Item_Aggregate_Bool_Exp = {
  count?: Maybe<Collection_Section_Cart_Item_Aggregate_Bool_Exp_Count>
}

export type Collection_Section_Cart_Item_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Aggregate_Fields = {
  __typename?: 'collection_section_cart_item_aggregate_fields'
  avg?: Maybe<Collection_Section_Cart_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Collection_Section_Cart_Item_Max_Fields>
  min?: Maybe<Collection_Section_Cart_Item_Min_Fields>
  stddev?: Maybe<Collection_Section_Cart_Item_Stddev_Fields>
  stddev_pop?: Maybe<Collection_Section_Cart_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Collection_Section_Cart_Item_Stddev_Samp_Fields>
  sum?: Maybe<Collection_Section_Cart_Item_Sum_Fields>
  var_pop?: Maybe<Collection_Section_Cart_Item_Var_Pop_Fields>
  var_samp?: Maybe<Collection_Section_Cart_Item_Var_Samp_Fields>
  variance?: Maybe<Collection_Section_Cart_Item_Variance_Fields>
}

/** aggregate fields of "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Aggregate_Order_By = {
  avg?: Maybe<Collection_Section_Cart_Item_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Section_Cart_Item_Max_Order_By>
  min?: Maybe<Collection_Section_Cart_Item_Min_Order_By>
  stddev?: Maybe<Collection_Section_Cart_Item_Stddev_Order_By>
  stddev_pop?: Maybe<Collection_Section_Cart_Item_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Collection_Section_Cart_Item_Stddev_Samp_Order_By>
  sum?: Maybe<Collection_Section_Cart_Item_Sum_Order_By>
  var_pop?: Maybe<Collection_Section_Cart_Item_Var_Pop_Order_By>
  var_samp?: Maybe<Collection_Section_Cart_Item_Var_Samp_Order_By>
  variance?: Maybe<Collection_Section_Cart_Item_Variance_Order_By>
}

/** input type for inserting array relation for remote table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Arr_Rel_Insert_Input = {
  data: Array<Collection_Section_Cart_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Section_Cart_Item_On_Conflict>
}

/** aggregate avg on columns */
export type Collection_Section_Cart_Item_Avg_Fields = {
  __typename?: 'collection_section_cart_item_avg_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Avg_Order_By = {
  order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "collection_section_cart_item". All fields are combined with a logical 'AND'. */
export type Collection_Section_Cart_Item_Bool_Exp = {
  _and?: Maybe<Array<Collection_Section_Cart_Item_Bool_Exp>>
  _not?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
  _or?: Maybe<Array<Collection_Section_Cart_Item_Bool_Exp>>
  collection_cart_item?: Maybe<Collection_Cart_Item_Bool_Exp>
  collection_cart_item_id?: Maybe<Uuid_Comparison_Exp>
  collection_section?: Maybe<Collection_Section_Bool_Exp>
  collection_section_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  order?: Maybe<Numeric_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_section_cart_item" */
export enum Collection_Section_Cart_Item_Constraint {
  /** unique or primary key constraint on columns "collection_section_id", "collection_cart_item_id" */
  CollectionSectionCartItemCartItemIdCollectionSectioKey = 'collection_section_cart_item_cart_item_id_collection_sectio_key',
  /** unique or primary key constraint on columns "id" */
  CollectionSectionCartItemPkey = 'collection_section_cart_item_pkey',
}

/** input type for incrementing numeric columns in table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Inc_Input = {
  order?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Insert_Input = {
  collection_cart_item?: Maybe<Collection_Cart_Item_Obj_Rel_Insert_Input>
  collection_cart_item_id?: Maybe<Scalars['uuid']>
  collection_section?: Maybe<Collection_Section_Obj_Rel_Insert_Input>
  collection_section_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Collection_Section_Cart_Item_Max_Fields = {
  __typename?: 'collection_section_cart_item_max_fields'
  collection_cart_item_id?: Maybe<Scalars['uuid']>
  collection_section_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Max_Order_By = {
  collection_cart_item_id?: Maybe<Order_By>
  collection_section_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  order?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Section_Cart_Item_Min_Fields = {
  __typename?: 'collection_section_cart_item_min_fields'
  collection_cart_item_id?: Maybe<Scalars['uuid']>
  collection_section_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Min_Order_By = {
  collection_cart_item_id?: Maybe<Order_By>
  collection_section_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  order?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Mutation_Response = {
  __typename?: 'collection_section_cart_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Section_Cart_Item>
}

/** on_conflict condition type for table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_On_Conflict = {
  constraint: Collection_Section_Cart_Item_Constraint
  update_columns?: Array<Collection_Section_Cart_Item_Update_Column>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

/** Ordering options when selecting data from "collection_section_cart_item". */
export type Collection_Section_Cart_Item_Order_By = {
  collection_cart_item?: Maybe<Collection_Cart_Item_Order_By>
  collection_cart_item_id?: Maybe<Order_By>
  collection_section?: Maybe<Collection_Section_Order_By>
  collection_section_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  order?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: collection_section_cart_item */
export type Collection_Section_Cart_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "collection_section_cart_item" */
export enum Collection_Section_Cart_Item_Select_Column {
  /** column name */
  CollectionCartItemId = 'collection_cart_item_id',
  /** column name */
  CollectionSectionId = 'collection_section_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Set_Input = {
  collection_cart_item_id?: Maybe<Scalars['uuid']>
  collection_section_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Collection_Section_Cart_Item_Stddev_Fields = {
  __typename?: 'collection_section_cart_item_stddev_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Stddev_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Collection_Section_Cart_Item_Stddev_Pop_Fields = {
  __typename?: 'collection_section_cart_item_stddev_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Collection_Section_Cart_Item_Stddev_Samp_Fields = {
  __typename?: 'collection_section_cart_item_stddev_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** Streaming cursor of the table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Section_Cart_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Section_Cart_Item_Stream_Cursor_Value_Input = {
  collection_cart_item_id?: Maybe<Scalars['uuid']>
  collection_section_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Collection_Section_Cart_Item_Sum_Fields = {
  __typename?: 'collection_section_cart_item_sum_fields'
  order?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Sum_Order_By = {
  order?: Maybe<Order_By>
}

/** update columns of table "collection_section_cart_item" */
export enum Collection_Section_Cart_Item_Update_Column {
  /** column name */
  CollectionCartItemId = 'collection_cart_item_id',
  /** column name */
  CollectionSectionId = 'collection_section_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Collection_Section_Cart_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Collection_Section_Cart_Item_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Section_Cart_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Section_Cart_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Collection_Section_Cart_Item_Var_Pop_Fields = {
  __typename?: 'collection_section_cart_item_var_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Var_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Collection_Section_Cart_Item_Var_Samp_Fields = {
  __typename?: 'collection_section_cart_item_var_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Var_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Collection_Section_Cart_Item_Variance_Fields = {
  __typename?: 'collection_section_cart_item_variance_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Variance_Order_By = {
  order?: Maybe<Order_By>
}

/** unique or primary key constraints on table "collection_section" */
export enum Collection_Section_Constraint {
  /** unique or primary key constraint on columns "id" */
  CollectionSectionPkey = 'collection_section_pkey',
}

/** input type for incrementing numeric columns in table "collection_section" */
export type Collection_Section_Inc_Input = {
  order?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "collection_section" */
export type Collection_Section_Insert_Input = {
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_id?: Maybe<Scalars['uuid']>
  collection_section_cart_items?: Maybe<Collection_Section_Cart_Item_Arr_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  slug?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Collection_Section_Max_Fields = {
  __typename?: 'collection_section_max_fields'
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  slug?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "collection_section" */
export type Collection_Section_Max_Order_By = {
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  order?: Maybe<Order_By>
  slug?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Section_Min_Fields = {
  __typename?: 'collection_section_min_fields'
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  slug?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "collection_section" */
export type Collection_Section_Min_Order_By = {
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  order?: Maybe<Order_By>
  slug?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_section" */
export type Collection_Section_Mutation_Response = {
  __typename?: 'collection_section_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Section>
}

/** input type for inserting object relation for remote table "collection_section" */
export type Collection_Section_Obj_Rel_Insert_Input = {
  data: Collection_Section_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_Section_On_Conflict>
}

/** on_conflict condition type for table "collection_section" */
export type Collection_Section_On_Conflict = {
  constraint: Collection_Section_Constraint
  update_columns?: Array<Collection_Section_Update_Column>
  where?: Maybe<Collection_Section_Bool_Exp>
}

/** Ordering options when selecting data from "collection_section". */
export type Collection_Section_Order_By = {
  collection?: Maybe<Collection_Order_By>
  collection_id?: Maybe<Order_By>
  collection_section_cart_items_aggregate?: Maybe<Collection_Section_Cart_Item_Aggregate_Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  order?: Maybe<Order_By>
  slug?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: collection_section */
export type Collection_Section_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "collection_section" */
export enum Collection_Section_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "collection_section" */
export type Collection_Section_Set_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  slug?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Collection_Section_Stddev_Fields = {
  __typename?: 'collection_section_stddev_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "collection_section" */
export type Collection_Section_Stddev_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Collection_Section_Stddev_Pop_Fields = {
  __typename?: 'collection_section_stddev_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "collection_section" */
export type Collection_Section_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Collection_Section_Stddev_Samp_Fields = {
  __typename?: 'collection_section_stddev_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "collection_section" */
export type Collection_Section_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** Streaming cursor of the table "collection_section" */
export type Collection_Section_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Section_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Section_Stream_Cursor_Value_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  slug?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Collection_Section_Sum_Fields = {
  __typename?: 'collection_section_sum_fields'
  order?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "collection_section" */
export type Collection_Section_Sum_Order_By = {
  order?: Maybe<Order_By>
}

/** update columns of table "collection_section" */
export enum Collection_Section_Update_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Collection_Section_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Collection_Section_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Section_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Section_Bool_Exp
}

/** aggregate var_pop on columns */
export type Collection_Section_Var_Pop_Fields = {
  __typename?: 'collection_section_var_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "collection_section" */
export type Collection_Section_Var_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Collection_Section_Var_Samp_Fields = {
  __typename?: 'collection_section_var_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "collection_section" */
export type Collection_Section_Var_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Collection_Section_Variance_Fields = {
  __typename?: 'collection_section_variance_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "collection_section" */
export type Collection_Section_Variance_Order_By = {
  order?: Maybe<Order_By>
}

/** select columns of table "collection" */
export enum Collection_Select_Column {
  /** column name */
  All = 'all',
  /** column name */
  AnnounceCollection = 'announce_collection',
  /** column name */
  Bookmarks = 'bookmarks',
  /** column name */
  Budget = 'budget',
  /** column name */
  CartItemMaxUpdatedAt = 'cart_item_max_updated_at',
  /** column name */
  CollectionCoverImage = 'collection_cover_image',
  /** column name */
  CollectionCoverImages = 'collection_cover_images',
  /** column name */
  CollectionLink = 'collection_link',
  /** column name */
  CollectionType = 'collection_type',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Curation = 'curation',
  /** column name */
  Description = 'description',
  /** column name */
  EmailExcerpt = 'email_excerpt',
  /** column name */
  Exclusive = 'exclusive',
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  FollowableItemType = 'followable_item_type',
  /** column name */
  Giftlist = 'giftlist',
  /** column name */
  HasCollaborators = 'has_collaborators',
  /** column name */
  Id = 'id',
  /** column name */
  ItemNotifications = 'item_notifications',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  NotificationPreferences = 'notification_preferences',
  /** column name */
  OgimageFingerprint = 'ogimage_fingerprint',
  /** column name */
  OgimageFingerprintUpdated = 'ogimage_fingerprint_updated',
  /** column name */
  Order = 'order',
  /** column name */
  Paid = 'paid',
  /** column name */
  PolymorphicType = 'polymorphic_type',
  /** column name */
  Private = 'private',
  /** column name */
  SearchableName = 'searchable_name',
  /** column name */
  SeoFields = 'seo_fields',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  State = 'state',
  /** column name */
  StealTheirLook = 'steal_their_look',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "collection_aggregate_bool_exp_bool_and_arguments_columns" columns of table "collection" */
export enum Collection_Select_Column_Collection_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  All = 'all',
  /** column name */
  AnnounceCollection = 'announce_collection',
  /** column name */
  Bookmarks = 'bookmarks',
  /** column name */
  Curation = 'curation',
  /** column name */
  Exclusive = 'exclusive',
  /** column name */
  Giftlist = 'giftlist',
  /** column name */
  HasCollaborators = 'has_collaborators',
  /** column name */
  ItemNotifications = 'item_notifications',
  /** column name */
  Paid = 'paid',
  /** column name */
  Private = 'private',
  /** column name */
  StealTheirLook = 'steal_their_look',
}

/** select "collection_aggregate_bool_exp_bool_or_arguments_columns" columns of table "collection" */
export enum Collection_Select_Column_Collection_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  All = 'all',
  /** column name */
  AnnounceCollection = 'announce_collection',
  /** column name */
  Bookmarks = 'bookmarks',
  /** column name */
  Curation = 'curation',
  /** column name */
  Exclusive = 'exclusive',
  /** column name */
  Giftlist = 'giftlist',
  /** column name */
  HasCollaborators = 'has_collaborators',
  /** column name */
  ItemNotifications = 'item_notifications',
  /** column name */
  Paid = 'paid',
  /** column name */
  Private = 'private',
  /** column name */
  StealTheirLook = 'steal_their_look',
}

/** input type for updating data in table "collection" */
export type Collection_Set_Input = {
  all?: Maybe<Scalars['Boolean']>
  announce_collection?: Maybe<Scalars['Boolean']>
  bookmarks?: Maybe<Scalars['Boolean']>
  budget?: Maybe<Scalars['numeric']>
  cart_item_max_updated_at?: Maybe<Scalars['timestamptz']>
  collection_cover_image?: Maybe<Scalars['String']>
  collection_cover_images?: Maybe<Array<Scalars['String']>>
  collection_link?: Maybe<Scalars['String']>
  collection_type?: Maybe<Collection_Type_Enum>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  curation?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  email_excerpt?: Maybe<Scalars['String']>
  exclusive?: Maybe<Scalars['Boolean']>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  followable_item_type?: Maybe<Followable_Item_Type_Enum>
  giftlist?: Maybe<Scalars['Boolean']>
  has_collaborators?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  item_notifications?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['name']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  notification_preferences?: Maybe<Scalars['jsonb']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  paid?: Maybe<Scalars['Boolean']>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum>
  private?: Maybe<Scalars['Boolean']>
  seo_fields?: Maybe<Scalars['jsonb']>
  short_id?: Maybe<Scalars['name']>
  slug?: Maybe<Scalars['name']>
  state?: Maybe<Collection_State_Enum>
  steal_their_look?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Table to keep track of collection slug changes */
export type Collection_Slug_History = {
  __typename?: 'collection_slug_history'
  /** An object relationship */
  collection: Collection
  collection_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  slug: Scalars['name']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "collection_slug_history" */
export type Collection_Slug_History_Aggregate = {
  __typename?: 'collection_slug_history_aggregate'
  aggregate?: Maybe<Collection_Slug_History_Aggregate_Fields>
  nodes: Array<Collection_Slug_History>
}

export type Collection_Slug_History_Aggregate_Bool_Exp = {
  count?: Maybe<Collection_Slug_History_Aggregate_Bool_Exp_Count>
}

export type Collection_Slug_History_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Slug_History_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Slug_History_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_slug_history" */
export type Collection_Slug_History_Aggregate_Fields = {
  __typename?: 'collection_slug_history_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Slug_History_Max_Fields>
  min?: Maybe<Collection_Slug_History_Min_Fields>
}

/** aggregate fields of "collection_slug_history" */
export type Collection_Slug_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Slug_History_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_slug_history" */
export type Collection_Slug_History_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Slug_History_Max_Order_By>
  min?: Maybe<Collection_Slug_History_Min_Order_By>
}

/** input type for inserting array relation for remote table "collection_slug_history" */
export type Collection_Slug_History_Arr_Rel_Insert_Input = {
  data: Array<Collection_Slug_History_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Slug_History_On_Conflict>
}

/** Boolean expression to filter rows from the table "collection_slug_history". All fields are combined with a logical 'AND'. */
export type Collection_Slug_History_Bool_Exp = {
  _and?: Maybe<Array<Collection_Slug_History_Bool_Exp>>
  _not?: Maybe<Collection_Slug_History_Bool_Exp>
  _or?: Maybe<Array<Collection_Slug_History_Bool_Exp>>
  collection?: Maybe<Collection_Bool_Exp>
  collection_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  slug?: Maybe<Name_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_slug_history" */
export enum Collection_Slug_History_Constraint {
  /** unique or primary key constraint on columns "slug", "collection_id" */
  CollectionSlugHistoryPkey = 'collection_slug_history_pkey',
}

/** input type for inserting data into table "collection_slug_history" */
export type Collection_Slug_History_Insert_Input = {
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  slug?: Maybe<Scalars['name']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Collection_Slug_History_Max_Fields = {
  __typename?: 'collection_slug_history_max_fields'
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "collection_slug_history" */
export type Collection_Slug_History_Max_Order_By = {
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Slug_History_Min_Fields = {
  __typename?: 'collection_slug_history_min_fields'
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "collection_slug_history" */
export type Collection_Slug_History_Min_Order_By = {
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_slug_history" */
export type Collection_Slug_History_Mutation_Response = {
  __typename?: 'collection_slug_history_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Slug_History>
}

/** on_conflict condition type for table "collection_slug_history" */
export type Collection_Slug_History_On_Conflict = {
  constraint: Collection_Slug_History_Constraint
  update_columns?: Array<Collection_Slug_History_Update_Column>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

/** Ordering options when selecting data from "collection_slug_history". */
export type Collection_Slug_History_Order_By = {
  collection?: Maybe<Collection_Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  slug?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: collection_slug_history */
export type Collection_Slug_History_Pk_Columns_Input = {
  collection_id: Scalars['uuid']
  slug: Scalars['name']
}

/** select columns of table "collection_slug_history" */
export enum Collection_Slug_History_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "collection_slug_history" */
export type Collection_Slug_History_Set_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  slug?: Maybe<Scalars['name']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "collection_slug_history" */
export type Collection_Slug_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Slug_History_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Slug_History_Stream_Cursor_Value_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  slug?: Maybe<Scalars['name']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "collection_slug_history" */
export enum Collection_Slug_History_Update_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Collection_Slug_History_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Slug_History_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Slug_History_Bool_Exp
}

/** Collection state enum (ie, Draft, Published) */
export type Collection_State = {
  __typename?: 'collection_state'
  description: Scalars['String']
  value: Scalars['String']
}

/** aggregated selection of "collection_state" */
export type Collection_State_Aggregate = {
  __typename?: 'collection_state_aggregate'
  aggregate?: Maybe<Collection_State_Aggregate_Fields>
  nodes: Array<Collection_State>
}

/** aggregate fields of "collection_state" */
export type Collection_State_Aggregate_Fields = {
  __typename?: 'collection_state_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_State_Max_Fields>
  min?: Maybe<Collection_State_Min_Fields>
}

/** aggregate fields of "collection_state" */
export type Collection_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_State_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "collection_state". All fields are combined with a logical 'AND'. */
export type Collection_State_Bool_Exp = {
  _and?: Maybe<Array<Collection_State_Bool_Exp>>
  _not?: Maybe<Collection_State_Bool_Exp>
  _or?: Maybe<Array<Collection_State_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_state" */
export enum Collection_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  CollectionStatePkey = 'collection_state_pkey',
}

export enum Collection_State_Enum {
  /** Draft */
  Draft = 'draft',
  /** Published */
  Published = 'published',
}

/** Boolean expression to compare columns of type "collection_state_enum". All fields are combined with logical 'AND'. */
export type Collection_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Collection_State_Enum>
  _in?: Maybe<Array<Collection_State_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Collection_State_Enum>
  _nin?: Maybe<Array<Collection_State_Enum>>
}

/** input type for inserting data into table "collection_state" */
export type Collection_State_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Collection_State_Max_Fields = {
  __typename?: 'collection_state_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Collection_State_Min_Fields = {
  __typename?: 'collection_state_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "collection_state" */
export type Collection_State_Mutation_Response = {
  __typename?: 'collection_state_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_State>
}

/** input type for inserting object relation for remote table "collection_state" */
export type Collection_State_Obj_Rel_Insert_Input = {
  data: Collection_State_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_State_On_Conflict>
}

/** on_conflict condition type for table "collection_state" */
export type Collection_State_On_Conflict = {
  constraint: Collection_State_Constraint
  update_columns?: Array<Collection_State_Update_Column>
  where?: Maybe<Collection_State_Bool_Exp>
}

/** Ordering options when selecting data from "collection_state". */
export type Collection_State_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: collection_state */
export type Collection_State_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "collection_state" */
export enum Collection_State_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "collection_state" */
export type Collection_State_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "collection_state" */
export type Collection_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_State_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_State_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "collection_state" */
export enum Collection_State_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Collection_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_State_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_State_Bool_Exp
}

/** aggregate stddev on columns */
export type Collection_Stddev_Fields = {
  __typename?: 'collection_stddev_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by stddev() on columns of table "collection" */
export type Collection_Stddev_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Collection_Stddev_Pop_Fields = {
  __typename?: 'collection_stddev_pop_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by stddev_pop() on columns of table "collection" */
export type Collection_Stddev_Pop_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Collection_Stddev_Samp_Fields = {
  __typename?: 'collection_stddev_samp_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by stddev_samp() on columns of table "collection" */
export type Collection_Stddev_Samp_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** Streaming cursor of the table "collection" */
export type Collection_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Stream_Cursor_Value_Input = {
  all?: Maybe<Scalars['Boolean']>
  announce_collection?: Maybe<Scalars['Boolean']>
  bookmarks?: Maybe<Scalars['Boolean']>
  budget?: Maybe<Scalars['numeric']>
  cart_item_max_updated_at?: Maybe<Scalars['timestamptz']>
  collection_cover_image?: Maybe<Scalars['String']>
  collection_cover_images?: Maybe<Array<Scalars['String']>>
  collection_link?: Maybe<Scalars['String']>
  collection_type?: Maybe<Collection_Type_Enum>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  curation?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  email_excerpt?: Maybe<Scalars['String']>
  exclusive?: Maybe<Scalars['Boolean']>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  followable_item_type?: Maybe<Followable_Item_Type_Enum>
  giftlist?: Maybe<Scalars['Boolean']>
  has_collaborators?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  item_notifications?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['name']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  notification_preferences?: Maybe<Scalars['jsonb']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  paid?: Maybe<Scalars['Boolean']>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum>
  private?: Maybe<Scalars['Boolean']>
  searchable_name?: Maybe<Scalars['String']>
  seo_fields?: Maybe<Scalars['jsonb']>
  short_id?: Maybe<Scalars['name']>
  slug?: Maybe<Scalars['name']>
  state?: Maybe<Collection_State_Enum>
  steal_their_look?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Collection_Sum_Fields = {
  __typename?: 'collection_sum_fields'
  budget?: Maybe<Scalars['numeric']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "collection" */
export type Collection_Sum_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** Collection type enum table */
export type Collection_Type = {
  __typename?: 'collection_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "collection_type" */
export type Collection_Type_Aggregate = {
  __typename?: 'collection_type_aggregate'
  aggregate?: Maybe<Collection_Type_Aggregate_Fields>
  nodes: Array<Collection_Type>
}

/** aggregate fields of "collection_type" */
export type Collection_Type_Aggregate_Fields = {
  __typename?: 'collection_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Type_Max_Fields>
  min?: Maybe<Collection_Type_Min_Fields>
}

/** aggregate fields of "collection_type" */
export type Collection_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "collection_type". All fields are combined with a logical 'AND'. */
export type Collection_Type_Bool_Exp = {
  _and?: Maybe<Array<Collection_Type_Bool_Exp>>
  _not?: Maybe<Collection_Type_Bool_Exp>
  _or?: Maybe<Array<Collection_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_type" */
export enum Collection_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  CollectionTypePkey = 'collection_type_pkey',
}

export enum Collection_Type_Enum {
  /** Contest collection type (ie, I-to-I) */
  Contest = 'contest',
  /** Default collection type */
  Default = 'default',
  /** Landing Page */
  LandingPage = 'landing_page',
  /** Shared collection type */
  Shared = 'shared',
}

/** Boolean expression to compare columns of type "collection_type_enum". All fields are combined with logical 'AND'. */
export type Collection_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Collection_Type_Enum>
  _in?: Maybe<Array<Collection_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Collection_Type_Enum>
  _nin?: Maybe<Array<Collection_Type_Enum>>
}

/** input type for inserting data into table "collection_type" */
export type Collection_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Collection_Type_Max_Fields = {
  __typename?: 'collection_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Collection_Type_Min_Fields = {
  __typename?: 'collection_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "collection_type" */
export type Collection_Type_Mutation_Response = {
  __typename?: 'collection_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Type>
}

/** on_conflict condition type for table "collection_type" */
export type Collection_Type_On_Conflict = {
  constraint: Collection_Type_Constraint
  update_columns?: Array<Collection_Type_Update_Column>
  where?: Maybe<Collection_Type_Bool_Exp>
}

/** Ordering options when selecting data from "collection_type". */
export type Collection_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: collection_type */
export type Collection_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "collection_type" */
export enum Collection_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "collection_type" */
export type Collection_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "collection_type" */
export type Collection_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "collection_type" */
export enum Collection_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Collection_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Type_Bool_Exp
}

/** update columns of table "collection" */
export enum Collection_Update_Column {
  /** column name */
  All = 'all',
  /** column name */
  AnnounceCollection = 'announce_collection',
  /** column name */
  Bookmarks = 'bookmarks',
  /** column name */
  Budget = 'budget',
  /** column name */
  CartItemMaxUpdatedAt = 'cart_item_max_updated_at',
  /** column name */
  CollectionCoverImage = 'collection_cover_image',
  /** column name */
  CollectionCoverImages = 'collection_cover_images',
  /** column name */
  CollectionLink = 'collection_link',
  /** column name */
  CollectionType = 'collection_type',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Curation = 'curation',
  /** column name */
  Description = 'description',
  /** column name */
  EmailExcerpt = 'email_excerpt',
  /** column name */
  Exclusive = 'exclusive',
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  FollowableItemType = 'followable_item_type',
  /** column name */
  Giftlist = 'giftlist',
  /** column name */
  HasCollaborators = 'has_collaborators',
  /** column name */
  Id = 'id',
  /** column name */
  ItemNotifications = 'item_notifications',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  NotificationPreferences = 'notification_preferences',
  /** column name */
  OgimageFingerprint = 'ogimage_fingerprint',
  /** column name */
  OgimageFingerprintUpdated = 'ogimage_fingerprint_updated',
  /** column name */
  Order = 'order',
  /** column name */
  Paid = 'paid',
  /** column name */
  PolymorphicType = 'polymorphic_type',
  /** column name */
  Private = 'private',
  /** column name */
  SeoFields = 'seo_fields',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  State = 'state',
  /** column name */
  StealTheirLook = 'steal_their_look',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Collection_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Collection_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Collection_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Collection_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Collection_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Collection_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Collection_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Bool_Exp
}

/** aggregate var_pop on columns */
export type Collection_Var_Pop_Fields = {
  __typename?: 'collection_var_pop_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by var_pop() on columns of table "collection" */
export type Collection_Var_Pop_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Collection_Var_Samp_Fields = {
  __typename?: 'collection_var_samp_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by var_samp() on columns of table "collection" */
export type Collection_Var_Samp_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Collection_Variance_Fields = {
  __typename?: 'collection_variance_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by variance() on columns of table "collection" */
export type Collection_Variance_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** Generalized polymorphic comment table */
export type Comment = {
  __typename?: 'comment'
  comment: Scalars['String']
  /** An object relationship */
  commentable_item?: Maybe<Commentable_Item>
  commentable_item_id: Scalars['uuid']
  commentable_item_type: Commentable_Item_Type_Enum
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  notifiable_item_type: Notifiable_Item_Type_Enum
  sequential_id: Scalars['Int']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "comment" */
export type Comment_Aggregate = {
  __typename?: 'comment_aggregate'
  aggregate?: Maybe<Comment_Aggregate_Fields>
  nodes: Array<Comment>
}

export type Comment_Aggregate_Bool_Exp = {
  count?: Maybe<Comment_Aggregate_Bool_Exp_Count>
}

export type Comment_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Comment_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Comment_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "comment" */
export type Comment_Aggregate_Fields = {
  __typename?: 'comment_aggregate_fields'
  avg?: Maybe<Comment_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Comment_Max_Fields>
  min?: Maybe<Comment_Min_Fields>
  stddev?: Maybe<Comment_Stddev_Fields>
  stddev_pop?: Maybe<Comment_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Comment_Stddev_Samp_Fields>
  sum?: Maybe<Comment_Sum_Fields>
  var_pop?: Maybe<Comment_Var_Pop_Fields>
  var_samp?: Maybe<Comment_Var_Samp_Fields>
  variance?: Maybe<Comment_Variance_Fields>
}

/** aggregate fields of "comment" */
export type Comment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Comment_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "comment" */
export type Comment_Aggregate_Order_By = {
  avg?: Maybe<Comment_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Comment_Max_Order_By>
  min?: Maybe<Comment_Min_Order_By>
  stddev?: Maybe<Comment_Stddev_Order_By>
  stddev_pop?: Maybe<Comment_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Comment_Stddev_Samp_Order_By>
  sum?: Maybe<Comment_Sum_Order_By>
  var_pop?: Maybe<Comment_Var_Pop_Order_By>
  var_samp?: Maybe<Comment_Var_Samp_Order_By>
  variance?: Maybe<Comment_Variance_Order_By>
}

/** input type for inserting array relation for remote table "comment" */
export type Comment_Arr_Rel_Insert_Input = {
  data: Array<Comment_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Comment_On_Conflict>
}

/** aggregate avg on columns */
export type Comment_Avg_Fields = {
  __typename?: 'comment_avg_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "comment" */
export type Comment_Avg_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "comment". All fields are combined with a logical 'AND'. */
export type Comment_Bool_Exp = {
  _and?: Maybe<Array<Comment_Bool_Exp>>
  _not?: Maybe<Comment_Bool_Exp>
  _or?: Maybe<Array<Comment_Bool_Exp>>
  comment?: Maybe<String_Comparison_Exp>
  commentable_item?: Maybe<Commentable_Item_Bool_Exp>
  commentable_item_id?: Maybe<Uuid_Comparison_Exp>
  commentable_item_type?: Maybe<Commentable_Item_Type_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  sequential_id?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "comment" */
export enum Comment_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentPkey = 'comment_pkey',
  /** unique or primary key constraint on columns "sequential_id" */
  CommentSequentialIdKey = 'comment_sequential_id_key',
}

/** input type for incrementing numeric columns in table "comment" */
export type Comment_Inc_Input = {
  sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "comment" */
export type Comment_Insert_Input = {
  comment?: Maybe<Scalars['String']>
  commentable_item?: Maybe<Commentable_Item_Obj_Rel_Insert_Input>
  commentable_item_id?: Maybe<Scalars['uuid']>
  commentable_item_type?: Maybe<Commentable_Item_Type_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Comment_Max_Fields = {
  __typename?: 'comment_max_fields'
  comment?: Maybe<Scalars['String']>
  commentable_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "comment" */
export type Comment_Max_Order_By = {
  comment?: Maybe<Order_By>
  commentable_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Comment_Min_Fields = {
  __typename?: 'comment_min_fields'
  comment?: Maybe<Scalars['String']>
  commentable_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "comment" */
export type Comment_Min_Order_By = {
  comment?: Maybe<Order_By>
  commentable_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "comment" */
export type Comment_Mutation_Response = {
  __typename?: 'comment_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Comment>
}

/** input type for inserting object relation for remote table "comment" */
export type Comment_Obj_Rel_Insert_Input = {
  data: Comment_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Comment_On_Conflict>
}

/** on_conflict condition type for table "comment" */
export type Comment_On_Conflict = {
  constraint: Comment_Constraint
  update_columns?: Array<Comment_Update_Column>
  where?: Maybe<Comment_Bool_Exp>
}

/** Ordering options when selecting data from "comment". */
export type Comment_Order_By = {
  comment?: Maybe<Order_By>
  commentable_item?: Maybe<Commentable_Item_Order_By>
  commentable_item_id?: Maybe<Order_By>
  commentable_item_type?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_item_type?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: comment */
export type Comment_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "comment" */
export enum Comment_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CommentableItemId = 'commentable_item_id',
  /** column name */
  CommentableItemType = 'commentable_item_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "comment" */
export type Comment_Set_Input = {
  comment?: Maybe<Scalars['String']>
  commentable_item_id?: Maybe<Scalars['uuid']>
  commentable_item_type?: Maybe<Commentable_Item_Type_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Comment_Stddev_Fields = {
  __typename?: 'comment_stddev_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "comment" */
export type Comment_Stddev_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Comment_Stddev_Pop_Fields = {
  __typename?: 'comment_stddev_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "comment" */
export type Comment_Stddev_Pop_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Comment_Stddev_Samp_Fields = {
  __typename?: 'comment_stddev_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "comment" */
export type Comment_Stddev_Samp_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** Streaming cursor of the table "comment" */
export type Comment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Comment_Stream_Cursor_Value_Input = {
  comment?: Maybe<Scalars['String']>
  commentable_item_id?: Maybe<Scalars['uuid']>
  commentable_item_type?: Maybe<Commentable_Item_Type_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Comment_Sum_Fields = {
  __typename?: 'comment_sum_fields'
  sequential_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "comment" */
export type Comment_Sum_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** update columns of table "comment" */
export enum Comment_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CommentableItemId = 'commentable_item_id',
  /** column name */
  CommentableItemType = 'commentable_item_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Comment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Comment_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Comment_Set_Input>
  /** filter the rows which have to be updated */
  where: Comment_Bool_Exp
}

/** aggregate var_pop on columns */
export type Comment_Var_Pop_Fields = {
  __typename?: 'comment_var_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "comment" */
export type Comment_Var_Pop_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Comment_Var_Samp_Fields = {
  __typename?: 'comment_var_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "comment" */
export type Comment_Var_Samp_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Comment_Variance_Fields = {
  __typename?: 'comment_variance_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "comment" */
export type Comment_Variance_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** A table to keep track of all the allowable polymorphic commentable items */
export type Commentable_Item = {
  __typename?: 'commentable_item'
  /** An object relationship */
  cart_item?: Maybe<Cart_Item>
  /** An object relationship */
  collection?: Maybe<Collection>
  /** An array relationship */
  comments: Array<Comment>
  /** An aggregate relationship */
  comments_aggregate: Comment_Aggregate
  item_id: Scalars['uuid']
  item_type: Commentable_Item_Type_Enum
}

/** A table to keep track of all the allowable polymorphic commentable items */
export type Commentable_ItemCommentsArgs = {
  distinct_on?: Maybe<Array<Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Comment_Order_By>>
  where?: Maybe<Comment_Bool_Exp>
}

/** A table to keep track of all the allowable polymorphic commentable items */
export type Commentable_ItemComments_AggregateArgs = {
  distinct_on?: Maybe<Array<Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Comment_Order_By>>
  where?: Maybe<Comment_Bool_Exp>
}

/** aggregated selection of "commentable_item" */
export type Commentable_Item_Aggregate = {
  __typename?: 'commentable_item_aggregate'
  aggregate?: Maybe<Commentable_Item_Aggregate_Fields>
  nodes: Array<Commentable_Item>
}

/** aggregate fields of "commentable_item" */
export type Commentable_Item_Aggregate_Fields = {
  __typename?: 'commentable_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Commentable_Item_Max_Fields>
  min?: Maybe<Commentable_Item_Min_Fields>
}

/** aggregate fields of "commentable_item" */
export type Commentable_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Commentable_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "commentable_item". All fields are combined with a logical 'AND'. */
export type Commentable_Item_Bool_Exp = {
  _and?: Maybe<Array<Commentable_Item_Bool_Exp>>
  _not?: Maybe<Commentable_Item_Bool_Exp>
  _or?: Maybe<Array<Commentable_Item_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  collection?: Maybe<Collection_Bool_Exp>
  comments?: Maybe<Comment_Bool_Exp>
  comments_aggregate?: Maybe<Comment_Aggregate_Bool_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<Commentable_Item_Type_Enum_Comparison_Exp>
}

/** unique or primary key constraints on table "commentable_item" */
export enum Commentable_Item_Constraint {
  /** unique or primary key constraint on columns "item_id", "item_type" */
  CommentableItemPkey = 'commentable_item_pkey',
}

/** input type for inserting data into table "commentable_item" */
export type Commentable_Item_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  comments?: Maybe<Comment_Arr_Rel_Insert_Input>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Commentable_Item_Type_Enum>
}

/** aggregate max on columns */
export type Commentable_Item_Max_Fields = {
  __typename?: 'commentable_item_max_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type Commentable_Item_Min_Fields = {
  __typename?: 'commentable_item_min_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "commentable_item" */
export type Commentable_Item_Mutation_Response = {
  __typename?: 'commentable_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Commentable_Item>
}

/** input type for inserting object relation for remote table "commentable_item" */
export type Commentable_Item_Obj_Rel_Insert_Input = {
  data: Commentable_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Commentable_Item_On_Conflict>
}

/** on_conflict condition type for table "commentable_item" */
export type Commentable_Item_On_Conflict = {
  constraint: Commentable_Item_Constraint
  update_columns?: Array<Commentable_Item_Update_Column>
  where?: Maybe<Commentable_Item_Bool_Exp>
}

/** Ordering options when selecting data from "commentable_item". */
export type Commentable_Item_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  collection?: Maybe<Collection_Order_By>
  comments_aggregate?: Maybe<Comment_Aggregate_Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
}

/** primary key columns input for table: commentable_item */
export type Commentable_Item_Pk_Columns_Input = {
  item_id: Scalars['uuid']
  item_type: Commentable_Item_Type_Enum
}

/** select columns of table "commentable_item" */
export enum Commentable_Item_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

/** input type for updating data in table "commentable_item" */
export type Commentable_Item_Set_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Commentable_Item_Type_Enum>
}

/** Streaming cursor of the table "commentable_item" */
export type Commentable_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commentable_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Commentable_Item_Stream_Cursor_Value_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Commentable_Item_Type_Enum>
}

/** An enum table to hold the different commentable item types */
export type Commentable_Item_Type = {
  __typename?: 'commentable_item_type'
  value: Scalars['String']
}

/** aggregated selection of "commentable_item_type" */
export type Commentable_Item_Type_Aggregate = {
  __typename?: 'commentable_item_type_aggregate'
  aggregate?: Maybe<Commentable_Item_Type_Aggregate_Fields>
  nodes: Array<Commentable_Item_Type>
}

/** aggregate fields of "commentable_item_type" */
export type Commentable_Item_Type_Aggregate_Fields = {
  __typename?: 'commentable_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Commentable_Item_Type_Max_Fields>
  min?: Maybe<Commentable_Item_Type_Min_Fields>
}

/** aggregate fields of "commentable_item_type" */
export type Commentable_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Commentable_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "commentable_item_type". All fields are combined with a logical 'AND'. */
export type Commentable_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Commentable_Item_Type_Bool_Exp>>
  _not?: Maybe<Commentable_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Commentable_Item_Type_Bool_Exp>>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "commentable_item_type" */
export enum Commentable_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  CommentableItemTypePkey = 'commentable_item_type_pkey',
}

export enum Commentable_Item_Type_Enum {
  CartItem = 'cart_item',
  Collection = 'collection',
}

/** Boolean expression to compare columns of type "commentable_item_type_enum". All fields are combined with logical 'AND'. */
export type Commentable_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Commentable_Item_Type_Enum>
  _in?: Maybe<Array<Commentable_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Commentable_Item_Type_Enum>
  _nin?: Maybe<Array<Commentable_Item_Type_Enum>>
}

/** input type for inserting data into table "commentable_item_type" */
export type Commentable_Item_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Commentable_Item_Type_Max_Fields = {
  __typename?: 'commentable_item_type_max_fields'
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Commentable_Item_Type_Min_Fields = {
  __typename?: 'commentable_item_type_min_fields'
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "commentable_item_type" */
export type Commentable_Item_Type_Mutation_Response = {
  __typename?: 'commentable_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Commentable_Item_Type>
}

/** on_conflict condition type for table "commentable_item_type" */
export type Commentable_Item_Type_On_Conflict = {
  constraint: Commentable_Item_Type_Constraint
  update_columns?: Array<Commentable_Item_Type_Update_Column>
  where?: Maybe<Commentable_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "commentable_item_type". */
export type Commentable_Item_Type_Order_By = {
  value?: Maybe<Order_By>
}

/** primary key columns input for table: commentable_item_type */
export type Commentable_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "commentable_item_type" */
export enum Commentable_Item_Type_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "commentable_item_type" */
export type Commentable_Item_Type_Set_Input = {
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "commentable_item_type" */
export type Commentable_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commentable_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Commentable_Item_Type_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>
}

/** update columns of table "commentable_item_type" */
export enum Commentable_Item_Type_Update_Column {
  /** column name */
  Value = 'value',
}

export type Commentable_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Commentable_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Commentable_Item_Type_Bool_Exp
}

/** update columns of table "commentable_item" */
export enum Commentable_Item_Update_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

export type Commentable_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Commentable_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Commentable_Item_Bool_Exp
}

/** An enum table to keep track of the different notification templates. These templates can have 1-to-1 associations with mobile push notifications and/or email templates */
export type Communication_Template = {
  __typename?: 'communication_template'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "communication_template" */
export type Communication_Template_Aggregate = {
  __typename?: 'communication_template_aggregate'
  aggregate?: Maybe<Communication_Template_Aggregate_Fields>
  nodes: Array<Communication_Template>
}

/** aggregate fields of "communication_template" */
export type Communication_Template_Aggregate_Fields = {
  __typename?: 'communication_template_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Communication_Template_Max_Fields>
  min?: Maybe<Communication_Template_Min_Fields>
}

/** aggregate fields of "communication_template" */
export type Communication_Template_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Communication_Template_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "communication_template". All fields are combined with a logical 'AND'. */
export type Communication_Template_Bool_Exp = {
  _and?: Maybe<Array<Communication_Template_Bool_Exp>>
  _not?: Maybe<Communication_Template_Bool_Exp>
  _or?: Maybe<Array<Communication_Template_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "communication_template" */
export enum Communication_Template_Constraint {
  /** unique or primary key constraint on columns "value" */
  NotificationTemplatePkey = 'notification_template_pkey',
}

export enum Communication_Template_Enum {
  AddProductsToCarrotTask = 'add_products_to_carrot_task',
  /** Used when a user saves a cart item from another's collection */
  CartItemSaved = 'cart_item_saved',
  /** Inform the user the deals hop for the recent cart item saved */
  CartItemSavedDealHop = 'cart_item_saved_deal_hop',
  /** Users who have saved passively cart items from partner sites */
  CartItemsSavedFromPartners = 'cart_items_saved_from_partners',
  /** A template identifying the communication going out to users that a user has accepted their collaboration request */
  CollaborationInviteAccepted = 'collaboration_invite_accepted',
  /** A template identifying the communication going out to collaborators of a collection that another collaborator has added an item to a collection */
  CollaborativeCollectionItemAdded = 'collaborative_collection_item_added',
  /** A template identifying the communication going out about a collection collaboration invite request */
  CollectionCollaborationInvite = 'collection_collaboration_invite',
  CreateCollectionsTask = 'create_collections_task',
  /** Inform the user that deal hop feature was unlocked */
  DealHopUnlocked = 'deal_hop_unlocked',
  EnableSafariExtensionTask = 'enable_safari_extension_task',
  /** Used when a user has a collection that is featured in the discovery feed */
  FeaturedCollectionInDiscoveryFeed = 'featured_collection_in_discovery_feed',
  FeedItemLike = 'feed_item_like',
  InviteFriendsTask = 'invite_friends_task',
  NewFollower = 'new_follower',
  /** A template identifying the communication going out to subscribers of a creator of newly published collections */
  SubscriberNotifiedOfNewPublishedCollection = 'subscriber_notified_of_new_published_collection',
  /** Used when a user makes a comment on something */
  UserCommented = 'user_commented',
  /** User mentioned in one comment */
  UserMentioned = 'user_mentioned',
}

/** Boolean expression to compare columns of type "communication_template_enum". All fields are combined with logical 'AND'. */
export type Communication_Template_Enum_Comparison_Exp = {
  _eq?: Maybe<Communication_Template_Enum>
  _in?: Maybe<Array<Communication_Template_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Communication_Template_Enum>
  _nin?: Maybe<Array<Communication_Template_Enum>>
}

/** A table to hold all of our kebabed-case communication template enums */
export type Communication_Template_Full_List = {
  __typename?: 'communication_template_full_list'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "communication_template_full_list" */
export type Communication_Template_Full_List_Aggregate = {
  __typename?: 'communication_template_full_list_aggregate'
  aggregate?: Maybe<Communication_Template_Full_List_Aggregate_Fields>
  nodes: Array<Communication_Template_Full_List>
}

/** aggregate fields of "communication_template_full_list" */
export type Communication_Template_Full_List_Aggregate_Fields = {
  __typename?: 'communication_template_full_list_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Communication_Template_Full_List_Max_Fields>
  min?: Maybe<Communication_Template_Full_List_Min_Fields>
}

/** aggregate fields of "communication_template_full_list" */
export type Communication_Template_Full_List_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Communication_Template_Full_List_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "communication_template_full_list". All fields are combined with a logical 'AND'. */
export type Communication_Template_Full_List_Bool_Exp = {
  _and?: Maybe<Array<Communication_Template_Full_List_Bool_Exp>>
  _not?: Maybe<Communication_Template_Full_List_Bool_Exp>
  _or?: Maybe<Array<Communication_Template_Full_List_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "communication_template_full_list" */
export enum Communication_Template_Full_List_Constraint {
  /** unique or primary key constraint on columns "value" */
  CommunicationTemplateFullListPkey = 'communication_template_full_list_pkey',
}

/** input type for inserting data into table "communication_template_full_list" */
export type Communication_Template_Full_List_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Communication_Template_Full_List_Max_Fields = {
  __typename?: 'communication_template_full_list_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Communication_Template_Full_List_Min_Fields = {
  __typename?: 'communication_template_full_list_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "communication_template_full_list" */
export type Communication_Template_Full_List_Mutation_Response = {
  __typename?: 'communication_template_full_list_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Communication_Template_Full_List>
}

/** on_conflict condition type for table "communication_template_full_list" */
export type Communication_Template_Full_List_On_Conflict = {
  constraint: Communication_Template_Full_List_Constraint
  update_columns?: Array<Communication_Template_Full_List_Update_Column>
  where?: Maybe<Communication_Template_Full_List_Bool_Exp>
}

/** Ordering options when selecting data from "communication_template_full_list". */
export type Communication_Template_Full_List_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: communication_template_full_list */
export type Communication_Template_Full_List_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "communication_template_full_list" */
export enum Communication_Template_Full_List_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "communication_template_full_list" */
export type Communication_Template_Full_List_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "communication_template_full_list" */
export type Communication_Template_Full_List_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_Template_Full_List_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Communication_Template_Full_List_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "communication_template_full_list" */
export enum Communication_Template_Full_List_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Communication_Template_Full_List_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Communication_Template_Full_List_Set_Input>
  /** filter the rows which have to be updated */
  where: Communication_Template_Full_List_Bool_Exp
}

/** input type for inserting data into table "communication_template" */
export type Communication_Template_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Communication_Template_Max_Fields = {
  __typename?: 'communication_template_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Communication_Template_Min_Fields = {
  __typename?: 'communication_template_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "communication_template" */
export type Communication_Template_Mutation_Response = {
  __typename?: 'communication_template_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Communication_Template>
}

/** on_conflict condition type for table "communication_template" */
export type Communication_Template_On_Conflict = {
  constraint: Communication_Template_Constraint
  update_columns?: Array<Communication_Template_Update_Column>
  where?: Maybe<Communication_Template_Bool_Exp>
}

/** Ordering options when selecting data from "communication_template". */
export type Communication_Template_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: communication_template */
export type Communication_Template_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "communication_template" */
export enum Communication_Template_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "communication_template" */
export type Communication_Template_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "communication_template" */
export type Communication_Template_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_Template_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Communication_Template_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "communication_template" */
export enum Communication_Template_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Communication_Template_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Communication_Template_Set_Input>
  /** filter the rows which have to be updated */
  where: Communication_Template_Bool_Exp
}

/** columns and relationships of "contact_info_type" */
export type Contact_Info_Type = {
  __typename?: 'contact_info_type'
  value: Scalars['String']
}

/** aggregated selection of "contact_info_type" */
export type Contact_Info_Type_Aggregate = {
  __typename?: 'contact_info_type_aggregate'
  aggregate?: Maybe<Contact_Info_Type_Aggregate_Fields>
  nodes: Array<Contact_Info_Type>
}

/** aggregate fields of "contact_info_type" */
export type Contact_Info_Type_Aggregate_Fields = {
  __typename?: 'contact_info_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Contact_Info_Type_Max_Fields>
  min?: Maybe<Contact_Info_Type_Min_Fields>
}

/** aggregate fields of "contact_info_type" */
export type Contact_Info_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Info_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "contact_info_type". All fields are combined with a logical 'AND'. */
export type Contact_Info_Type_Bool_Exp = {
  _and?: Maybe<Array<Contact_Info_Type_Bool_Exp>>
  _not?: Maybe<Contact_Info_Type_Bool_Exp>
  _or?: Maybe<Array<Contact_Info_Type_Bool_Exp>>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "contact_info_type" */
export enum Contact_Info_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ContactInfoTypePkey = 'contact_info_type_pkey',
}

export enum Contact_Info_Type_Enum {
  Email = 'email',
  Phone = 'phone',
}

/** Boolean expression to compare columns of type "contact_info_type_enum". All fields are combined with logical 'AND'. */
export type Contact_Info_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Contact_Info_Type_Enum>
  _in?: Maybe<Array<Contact_Info_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Contact_Info_Type_Enum>
  _nin?: Maybe<Array<Contact_Info_Type_Enum>>
}

/** input type for inserting data into table "contact_info_type" */
export type Contact_Info_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Contact_Info_Type_Max_Fields = {
  __typename?: 'contact_info_type_max_fields'
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Contact_Info_Type_Min_Fields = {
  __typename?: 'contact_info_type_min_fields'
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "contact_info_type" */
export type Contact_Info_Type_Mutation_Response = {
  __typename?: 'contact_info_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Info_Type>
}

/** on_conflict condition type for table "contact_info_type" */
export type Contact_Info_Type_On_Conflict = {
  constraint: Contact_Info_Type_Constraint
  update_columns?: Array<Contact_Info_Type_Update_Column>
  where?: Maybe<Contact_Info_Type_Bool_Exp>
}

/** Ordering options when selecting data from "contact_info_type". */
export type Contact_Info_Type_Order_By = {
  value?: Maybe<Order_By>
}

/** primary key columns input for table: contact_info_type */
export type Contact_Info_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "contact_info_type" */
export enum Contact_Info_Type_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "contact_info_type" */
export type Contact_Info_Type_Set_Input = {
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "contact_info_type" */
export type Contact_Info_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Info_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Contact_Info_Type_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>
}

/** update columns of table "contact_info_type" */
export enum Contact_Info_Type_Update_Column {
  /** column name */
  Value = 'value',
}

export type Contact_Info_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contact_Info_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Contact_Info_Type_Bool_Exp
}

/** columns and relationships of "contact_invite" */
export type Contact_Invite = {
  __typename?: 'contact_invite'
  contact_info: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  invitee?: Maybe<User>
  invitee_id?: Maybe<Scalars['String']>
  /** An object relationship */
  requester: User
  requester_id: Scalars['String']
  status: Contact_Invite_Status_Enum
  type: Contact_Invite_Type_Enum
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "contact_invite" */
export type Contact_Invite_Aggregate = {
  __typename?: 'contact_invite_aggregate'
  aggregate?: Maybe<Contact_Invite_Aggregate_Fields>
  nodes: Array<Contact_Invite>
}

/** aggregate fields of "contact_invite" */
export type Contact_Invite_Aggregate_Fields = {
  __typename?: 'contact_invite_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Contact_Invite_Max_Fields>
  min?: Maybe<Contact_Invite_Min_Fields>
}

/** aggregate fields of "contact_invite" */
export type Contact_Invite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Invite_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "contact_invite". All fields are combined with a logical 'AND'. */
export type Contact_Invite_Bool_Exp = {
  _and?: Maybe<Array<Contact_Invite_Bool_Exp>>
  _not?: Maybe<Contact_Invite_Bool_Exp>
  _or?: Maybe<Array<Contact_Invite_Bool_Exp>>
  contact_info?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  invitee?: Maybe<User_Bool_Exp>
  invitee_id?: Maybe<String_Comparison_Exp>
  requester?: Maybe<User_Bool_Exp>
  requester_id?: Maybe<String_Comparison_Exp>
  status?: Maybe<Contact_Invite_Status_Enum_Comparison_Exp>
  type?: Maybe<Contact_Invite_Type_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "contact_invite" */
export enum Contact_Invite_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactInvitePkey = 'contact_invite_pkey',
  /** unique or primary key constraint on columns "requester_id", "contact_info" */
  ContactInviteRequesterIdContactInfoKey = 'contact_invite_requester_id_contact_info_key',
}

/** input type for inserting data into table "contact_invite" */
export type Contact_Invite_Insert_Input = {
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee?: Maybe<User_Obj_Rel_Insert_Input>
  invitee_id?: Maybe<Scalars['String']>
  requester?: Maybe<User_Obj_Rel_Insert_Input>
  requester_id?: Maybe<Scalars['String']>
  status?: Maybe<Contact_Invite_Status_Enum>
  type?: Maybe<Contact_Invite_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Contact_Invite_Max_Fields = {
  __typename?: 'contact_invite_max_fields'
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_id?: Maybe<Scalars['String']>
  requester_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Contact_Invite_Min_Fields = {
  __typename?: 'contact_invite_min_fields'
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_id?: Maybe<Scalars['String']>
  requester_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "contact_invite" */
export type Contact_Invite_Mutation_Response = {
  __typename?: 'contact_invite_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Invite>
}

/** on_conflict condition type for table "contact_invite" */
export type Contact_Invite_On_Conflict = {
  constraint: Contact_Invite_Constraint
  update_columns?: Array<Contact_Invite_Update_Column>
  where?: Maybe<Contact_Invite_Bool_Exp>
}

/** Ordering options when selecting data from "contact_invite". */
export type Contact_Invite_Order_By = {
  contact_info?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  invitee?: Maybe<User_Order_By>
  invitee_id?: Maybe<Order_By>
  requester?: Maybe<User_Order_By>
  requester_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: contact_invite */
export type Contact_Invite_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "contact_invite" */
export enum Contact_Invite_Select_Column {
  /** column name */
  ContactInfo = 'contact_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeId = 'invitee_id',
  /** column name */
  RequesterId = 'requester_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "contact_invite" */
export type Contact_Invite_Set_Input = {
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_id?: Maybe<Scalars['String']>
  requester_id?: Maybe<Scalars['String']>
  status?: Maybe<Contact_Invite_Status_Enum>
  type?: Maybe<Contact_Invite_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "contact_invite_status" */
export type Contact_Invite_Status = {
  __typename?: 'contact_invite_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "contact_invite_status" */
export type Contact_Invite_Status_Aggregate = {
  __typename?: 'contact_invite_status_aggregate'
  aggregate?: Maybe<Contact_Invite_Status_Aggregate_Fields>
  nodes: Array<Contact_Invite_Status>
}

/** aggregate fields of "contact_invite_status" */
export type Contact_Invite_Status_Aggregate_Fields = {
  __typename?: 'contact_invite_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Contact_Invite_Status_Max_Fields>
  min?: Maybe<Contact_Invite_Status_Min_Fields>
}

/** aggregate fields of "contact_invite_status" */
export type Contact_Invite_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Invite_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "contact_invite_status". All fields are combined with a logical 'AND'. */
export type Contact_Invite_Status_Bool_Exp = {
  _and?: Maybe<Array<Contact_Invite_Status_Bool_Exp>>
  _not?: Maybe<Contact_Invite_Status_Bool_Exp>
  _or?: Maybe<Array<Contact_Invite_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "contact_invite_status" */
export enum Contact_Invite_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserInviteStatusPkey = 'user_invite_status_pkey',
}

export enum Contact_Invite_Status_Enum {
  Accepted = 'accepted',
  Pending = 'pending',
}

/** Boolean expression to compare columns of type "contact_invite_status_enum". All fields are combined with logical 'AND'. */
export type Contact_Invite_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Contact_Invite_Status_Enum>
  _in?: Maybe<Array<Contact_Invite_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Contact_Invite_Status_Enum>
  _nin?: Maybe<Array<Contact_Invite_Status_Enum>>
}

/** input type for inserting data into table "contact_invite_status" */
export type Contact_Invite_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Contact_Invite_Status_Max_Fields = {
  __typename?: 'contact_invite_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Contact_Invite_Status_Min_Fields = {
  __typename?: 'contact_invite_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "contact_invite_status" */
export type Contact_Invite_Status_Mutation_Response = {
  __typename?: 'contact_invite_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Invite_Status>
}

/** on_conflict condition type for table "contact_invite_status" */
export type Contact_Invite_Status_On_Conflict = {
  constraint: Contact_Invite_Status_Constraint
  update_columns?: Array<Contact_Invite_Status_Update_Column>
  where?: Maybe<Contact_Invite_Status_Bool_Exp>
}

/** Ordering options when selecting data from "contact_invite_status". */
export type Contact_Invite_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: contact_invite_status */
export type Contact_Invite_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "contact_invite_status" */
export enum Contact_Invite_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "contact_invite_status" */
export type Contact_Invite_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "contact_invite_status" */
export type Contact_Invite_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Invite_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Contact_Invite_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "contact_invite_status" */
export enum Contact_Invite_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Contact_Invite_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contact_Invite_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Contact_Invite_Status_Bool_Exp
}

/** Streaming cursor of the table "contact_invite" */
export type Contact_Invite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Invite_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Contact_Invite_Stream_Cursor_Value_Input = {
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_id?: Maybe<Scalars['String']>
  requester_id?: Maybe<Scalars['String']>
  status?: Maybe<Contact_Invite_Status_Enum>
  type?: Maybe<Contact_Invite_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "contact_invite_type" */
export type Contact_Invite_Type = {
  __typename?: 'contact_invite_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "contact_invite_type" */
export type Contact_Invite_Type_Aggregate = {
  __typename?: 'contact_invite_type_aggregate'
  aggregate?: Maybe<Contact_Invite_Type_Aggregate_Fields>
  nodes: Array<Contact_Invite_Type>
}

/** aggregate fields of "contact_invite_type" */
export type Contact_Invite_Type_Aggregate_Fields = {
  __typename?: 'contact_invite_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Contact_Invite_Type_Max_Fields>
  min?: Maybe<Contact_Invite_Type_Min_Fields>
}

/** aggregate fields of "contact_invite_type" */
export type Contact_Invite_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Invite_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "contact_invite_type". All fields are combined with a logical 'AND'. */
export type Contact_Invite_Type_Bool_Exp = {
  _and?: Maybe<Array<Contact_Invite_Type_Bool_Exp>>
  _not?: Maybe<Contact_Invite_Type_Bool_Exp>
  _or?: Maybe<Array<Contact_Invite_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "contact_invite_type" */
export enum Contact_Invite_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserInviteTypePkey = 'user_invite_type_pkey',
}

export enum Contact_Invite_Type_Enum {
  Email = 'email',
  Phone = 'phone',
}

/** Boolean expression to compare columns of type "contact_invite_type_enum". All fields are combined with logical 'AND'. */
export type Contact_Invite_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Contact_Invite_Type_Enum>
  _in?: Maybe<Array<Contact_Invite_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Contact_Invite_Type_Enum>
  _nin?: Maybe<Array<Contact_Invite_Type_Enum>>
}

/** input type for inserting data into table "contact_invite_type" */
export type Contact_Invite_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Contact_Invite_Type_Max_Fields = {
  __typename?: 'contact_invite_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Contact_Invite_Type_Min_Fields = {
  __typename?: 'contact_invite_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "contact_invite_type" */
export type Contact_Invite_Type_Mutation_Response = {
  __typename?: 'contact_invite_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Invite_Type>
}

/** on_conflict condition type for table "contact_invite_type" */
export type Contact_Invite_Type_On_Conflict = {
  constraint: Contact_Invite_Type_Constraint
  update_columns?: Array<Contact_Invite_Type_Update_Column>
  where?: Maybe<Contact_Invite_Type_Bool_Exp>
}

/** Ordering options when selecting data from "contact_invite_type". */
export type Contact_Invite_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: contact_invite_type */
export type Contact_Invite_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "contact_invite_type" */
export enum Contact_Invite_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "contact_invite_type" */
export type Contact_Invite_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "contact_invite_type" */
export type Contact_Invite_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Invite_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Contact_Invite_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "contact_invite_type" */
export enum Contact_Invite_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Contact_Invite_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contact_Invite_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Contact_Invite_Type_Bool_Exp
}

/** update columns of table "contact_invite" */
export enum Contact_Invite_Update_Column {
  /** column name */
  ContactInfo = 'contact_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeId = 'invitee_id',
  /** column name */
  RequesterId = 'requester_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Contact_Invite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contact_Invite_Set_Input>
  /** filter the rows which have to be updated */
  where: Contact_Invite_Bool_Exp
}

export type Creator_Subscribers_Random_Args = {
  seed?: Maybe<Scalars['seed_float']>
}

/** columns and relationships of "creator_user_subscriptions" */
export type Creator_User_Subscriptions = {
  __typename?: 'creator_user_subscriptions'
  billing_mode?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  creator?: Maybe<User>
  creator_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  interval?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  /** An object relationship */
  subscriber?: Maybe<User>
  subscriber_email?: Maybe<Scalars['String']>
  subscriber_phone?: Maybe<Scalars['String']>
  subscription_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "creator_user_subscriptions" */
export type Creator_User_Subscriptions_Aggregate = {
  __typename?: 'creator_user_subscriptions_aggregate'
  aggregate?: Maybe<Creator_User_Subscriptions_Aggregate_Fields>
  nodes: Array<Creator_User_Subscriptions>
}

/** aggregate fields of "creator_user_subscriptions" */
export type Creator_User_Subscriptions_Aggregate_Fields = {
  __typename?: 'creator_user_subscriptions_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Creator_User_Subscriptions_Max_Fields>
  min?: Maybe<Creator_User_Subscriptions_Min_Fields>
}

/** aggregate fields of "creator_user_subscriptions" */
export type Creator_User_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Creator_User_Subscriptions_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "creator_user_subscriptions". All fields are combined with a logical 'AND'. */
export type Creator_User_Subscriptions_Bool_Exp = {
  _and?: Maybe<Array<Creator_User_Subscriptions_Bool_Exp>>
  _not?: Maybe<Creator_User_Subscriptions_Bool_Exp>
  _or?: Maybe<Array<Creator_User_Subscriptions_Bool_Exp>>
  billing_mode?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  creator?: Maybe<User_Bool_Exp>
  creator_id?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  interval?: Maybe<String_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  subscriber?: Maybe<User_Bool_Exp>
  subscriber_email?: Maybe<String_Comparison_Exp>
  subscriber_phone?: Maybe<String_Comparison_Exp>
  subscription_type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Creator_User_Subscriptions_Max_Fields = {
  __typename?: 'creator_user_subscriptions_max_fields'
  billing_mode?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  interval?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  subscriber_email?: Maybe<Scalars['String']>
  subscriber_phone?: Maybe<Scalars['String']>
  subscription_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Creator_User_Subscriptions_Min_Fields = {
  __typename?: 'creator_user_subscriptions_min_fields'
  billing_mode?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  interval?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  subscriber_email?: Maybe<Scalars['String']>
  subscriber_phone?: Maybe<Scalars['String']>
  subscription_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "creator_user_subscriptions". */
export type Creator_User_Subscriptions_Order_By = {
  billing_mode?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator?: Maybe<User_Order_By>
  creator_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  interval?: Maybe<Order_By>
  status?: Maybe<Order_By>
  subscriber?: Maybe<User_Order_By>
  subscriber_email?: Maybe<Order_By>
  subscriber_phone?: Maybe<Order_By>
  subscription_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "creator_user_subscriptions" */
export enum Creator_User_Subscriptions_Select_Column {
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  Interval = 'interval',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriberEmail = 'subscriber_email',
  /** column name */
  SubscriberPhone = 'subscriber_phone',
  /** column name */
  SubscriptionType = 'subscription_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "creator_user_subscriptions" */
export type Creator_User_Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Creator_User_Subscriptions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Creator_User_Subscriptions_Stream_Cursor_Value_Input = {
  billing_mode?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  interval?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  subscriber_email?: Maybe<Scalars['String']>
  subscriber_phone?: Maybe<Scalars['String']>
  subscription_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>
  _gt?: Maybe<Scalars['date']>
  _gte?: Maybe<Scalars['date']>
  _in?: Maybe<Array<Scalars['date']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['date']>
  _lte?: Maybe<Scalars['date']>
  _neq?: Maybe<Scalars['date']>
  _nin?: Maybe<Array<Scalars['date']>>
}

/** A table to track deal hop gated status */
export type Deal_Hop_Gate = {
  __typename?: 'deal_hop_gate'
  created_at: Scalars['timestamptz']
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked: Scalars['Boolean']
  paywall_gated_at?: Maybe<Scalars['timestamptz']>
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** A table to track deal hop gated status */
export type Deal_Hop_GateDeal_Hop_InvitesArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "deal_hop_gate" */
export type Deal_Hop_Gate_Aggregate = {
  __typename?: 'deal_hop_gate_aggregate'
  aggregate?: Maybe<Deal_Hop_Gate_Aggregate_Fields>
  nodes: Array<Deal_Hop_Gate>
}

/** aggregate fields of "deal_hop_gate" */
export type Deal_Hop_Gate_Aggregate_Fields = {
  __typename?: 'deal_hop_gate_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Deal_Hop_Gate_Max_Fields>
  min?: Maybe<Deal_Hop_Gate_Min_Fields>
}

/** aggregate fields of "deal_hop_gate" */
export type Deal_Hop_Gate_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deal_Hop_Gate_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Deal_Hop_Gate_Append_Input = {
  deal_hop_invites?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "deal_hop_gate". All fields are combined with a logical 'AND'. */
export type Deal_Hop_Gate_Bool_Exp = {
  _and?: Maybe<Array<Deal_Hop_Gate_Bool_Exp>>
  _not?: Maybe<Deal_Hop_Gate_Bool_Exp>
  _or?: Maybe<Array<Deal_Hop_Gate_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_gate_pending?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_gated?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_invited?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_invites?: Maybe<Jsonb_Comparison_Exp>
  deal_hop_unlocked?: Maybe<Boolean_Comparison_Exp>
  paywall_gated_at?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "deal_hop_gate" */
export enum Deal_Hop_Gate_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  DealHopGatePkey = 'deal_hop_gate_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Deal_Hop_Gate_Delete_At_Path_Input = {
  deal_hop_invites?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Deal_Hop_Gate_Delete_Elem_Input = {
  deal_hop_invites?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Deal_Hop_Gate_Delete_Key_Input = {
  deal_hop_invites?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "deal_hop_gate" */
export type Deal_Hop_Gate_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  paywall_gated_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Deal_Hop_Gate_Max_Fields = {
  __typename?: 'deal_hop_gate_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  paywall_gated_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Deal_Hop_Gate_Min_Fields = {
  __typename?: 'deal_hop_gate_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  paywall_gated_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "deal_hop_gate" */
export type Deal_Hop_Gate_Mutation_Response = {
  __typename?: 'deal_hop_gate_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Hop_Gate>
}

/** on_conflict condition type for table "deal_hop_gate" */
export type Deal_Hop_Gate_On_Conflict = {
  constraint: Deal_Hop_Gate_Constraint
  update_columns?: Array<Deal_Hop_Gate_Update_Column>
  where?: Maybe<Deal_Hop_Gate_Bool_Exp>
}

/** Ordering options when selecting data from "deal_hop_gate". */
export type Deal_Hop_Gate_Order_By = {
  created_at?: Maybe<Order_By>
  deal_hop_gate_pending?: Maybe<Order_By>
  deal_hop_gated?: Maybe<Order_By>
  deal_hop_invited?: Maybe<Order_By>
  deal_hop_invites?: Maybe<Order_By>
  deal_hop_unlocked?: Maybe<Order_By>
  paywall_gated_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: deal_hop_gate */
export type Deal_Hop_Gate_Pk_Columns_Input = {
  user_id: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Deal_Hop_Gate_Prepend_Input = {
  deal_hop_invites?: Maybe<Scalars['jsonb']>
}

/** select columns of table "deal_hop_gate" */
export enum Deal_Hop_Gate_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealHopGatePending = 'deal_hop_gate_pending',
  /** column name */
  DealHopGated = 'deal_hop_gated',
  /** column name */
  DealHopInvited = 'deal_hop_invited',
  /** column name */
  DealHopInvites = 'deal_hop_invites',
  /** column name */
  DealHopUnlocked = 'deal_hop_unlocked',
  /** column name */
  PaywallGatedAt = 'paywall_gated_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "deal_hop_gate" */
export type Deal_Hop_Gate_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  paywall_gated_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "deal_hop_gate" */
export type Deal_Hop_Gate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deal_Hop_Gate_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Deal_Hop_Gate_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  paywall_gated_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "deal_hop_gate" */
export enum Deal_Hop_Gate_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealHopGatePending = 'deal_hop_gate_pending',
  /** column name */
  DealHopGated = 'deal_hop_gated',
  /** column name */
  DealHopInvited = 'deal_hop_invited',
  /** column name */
  DealHopInvites = 'deal_hop_invites',
  /** column name */
  DealHopUnlocked = 'deal_hop_unlocked',
  /** column name */
  PaywallGatedAt = 'paywall_gated_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Deal_Hop_Gate_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Deal_Hop_Gate_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Deal_Hop_Gate_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Deal_Hop_Gate_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Deal_Hop_Gate_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Deal_Hop_Gate_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deal_Hop_Gate_Set_Input>
  /** filter the rows which have to be updated */
  where: Deal_Hop_Gate_Bool_Exp
}

/** columns and relationships of "device_client_data" */
export type Device_Client_Data = {
  __typename?: 'device_client_data'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  metadata: Scalars['json']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** columns and relationships of "device_client_data" */
export type Device_Client_DataMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "device_client_data" */
export type Device_Client_Data_Aggregate = {
  __typename?: 'device_client_data_aggregate'
  aggregate?: Maybe<Device_Client_Data_Aggregate_Fields>
  nodes: Array<Device_Client_Data>
}

/** aggregate fields of "device_client_data" */
export type Device_Client_Data_Aggregate_Fields = {
  __typename?: 'device_client_data_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Device_Client_Data_Max_Fields>
  min?: Maybe<Device_Client_Data_Min_Fields>
}

/** aggregate fields of "device_client_data" */
export type Device_Client_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Device_Client_Data_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "device_client_data". All fields are combined with a logical 'AND'. */
export type Device_Client_Data_Bool_Exp = {
  _and?: Maybe<Array<Device_Client_Data_Bool_Exp>>
  _not?: Maybe<Device_Client_Data_Bool_Exp>
  _or?: Maybe<Array<Device_Client_Data_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  metadata?: Maybe<Json_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "device_client_data" */
export enum Device_Client_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  DeviceClientDataPkey = 'device_client_data_pkey',
}

/** input type for inserting data into table "device_client_data" */
export type Device_Client_Data_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['json']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Device_Client_Data_Max_Fields = {
  __typename?: 'device_client_data_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Device_Client_Data_Min_Fields = {
  __typename?: 'device_client_data_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "device_client_data" */
export type Device_Client_Data_Mutation_Response = {
  __typename?: 'device_client_data_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Device_Client_Data>
}

/** on_conflict condition type for table "device_client_data" */
export type Device_Client_Data_On_Conflict = {
  constraint: Device_Client_Data_Constraint
  update_columns?: Array<Device_Client_Data_Update_Column>
  where?: Maybe<Device_Client_Data_Bool_Exp>
}

/** Ordering options when selecting data from "device_client_data". */
export type Device_Client_Data_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: device_client_data */
export type Device_Client_Data_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "device_client_data" */
export enum Device_Client_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "device_client_data" */
export type Device_Client_Data_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['json']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "device_client_data" */
export type Device_Client_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Client_Data_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Device_Client_Data_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['json']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "device_client_data" */
export enum Device_Client_Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Device_Client_Data_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Device_Client_Data_Set_Input>
  /** filter the rows which have to be updated */
  where: Device_Client_Data_Bool_Exp
}

/** columns and relationships of "discovery_backlog" */
export type Discovery_Backlog = {
  __typename?: 'discovery_backlog'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  feed_item: Feed_Item
  feed_item_id: Scalars['uuid']
  id: Scalars['uuid']
  sequential_id: Scalars['Int']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "discovery_backlog" */
export type Discovery_Backlog_Aggregate = {
  __typename?: 'discovery_backlog_aggregate'
  aggregate?: Maybe<Discovery_Backlog_Aggregate_Fields>
  nodes: Array<Discovery_Backlog>
}

/** aggregate fields of "discovery_backlog" */
export type Discovery_Backlog_Aggregate_Fields = {
  __typename?: 'discovery_backlog_aggregate_fields'
  avg?: Maybe<Discovery_Backlog_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Discovery_Backlog_Max_Fields>
  min?: Maybe<Discovery_Backlog_Min_Fields>
  stddev?: Maybe<Discovery_Backlog_Stddev_Fields>
  stddev_pop?: Maybe<Discovery_Backlog_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Discovery_Backlog_Stddev_Samp_Fields>
  sum?: Maybe<Discovery_Backlog_Sum_Fields>
  var_pop?: Maybe<Discovery_Backlog_Var_Pop_Fields>
  var_samp?: Maybe<Discovery_Backlog_Var_Samp_Fields>
  variance?: Maybe<Discovery_Backlog_Variance_Fields>
}

/** aggregate fields of "discovery_backlog" */
export type Discovery_Backlog_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Discovery_Backlog_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Discovery_Backlog_Avg_Fields = {
  __typename?: 'discovery_backlog_avg_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "discovery_backlog". All fields are combined with a logical 'AND'. */
export type Discovery_Backlog_Bool_Exp = {
  _and?: Maybe<Array<Discovery_Backlog_Bool_Exp>>
  _not?: Maybe<Discovery_Backlog_Bool_Exp>
  _or?: Maybe<Array<Discovery_Backlog_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  sequential_id?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "discovery_backlog" */
export enum Discovery_Backlog_Constraint {
  /** unique or primary key constraint on columns "feed_item_id" */
  DiscoveryBacklogFeedItemIdKey = 'discovery_backlog_feed_item_id_key',
  /** unique or primary key constraint on columns "id" */
  DiscoveryBacklogPkey = 'discovery_backlog_pkey',
  /** unique or primary key constraint on columns "sequential_id" */
  DiscoveryBacklogSequentialIdKey = 'discovery_backlog_sequential_id_key',
}

/** input type for incrementing numeric columns in table "discovery_backlog" */
export type Discovery_Backlog_Inc_Input = {
  sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "discovery_backlog" */
export type Discovery_Backlog_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Discovery_Backlog_Max_Fields = {
  __typename?: 'discovery_backlog_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Discovery_Backlog_Min_Fields = {
  __typename?: 'discovery_backlog_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "discovery_backlog" */
export type Discovery_Backlog_Mutation_Response = {
  __typename?: 'discovery_backlog_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Discovery_Backlog>
}

/** on_conflict condition type for table "discovery_backlog" */
export type Discovery_Backlog_On_Conflict = {
  constraint: Discovery_Backlog_Constraint
  update_columns?: Array<Discovery_Backlog_Update_Column>
  where?: Maybe<Discovery_Backlog_Bool_Exp>
}

/** Ordering options when selecting data from "discovery_backlog". */
export type Discovery_Backlog_Order_By = {
  created_at?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: discovery_backlog */
export type Discovery_Backlog_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "discovery_backlog" */
export enum Discovery_Backlog_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "discovery_backlog" */
export type Discovery_Backlog_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Discovery_Backlog_Stddev_Fields = {
  __typename?: 'discovery_backlog_stddev_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Discovery_Backlog_Stddev_Pop_Fields = {
  __typename?: 'discovery_backlog_stddev_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Discovery_Backlog_Stddev_Samp_Fields = {
  __typename?: 'discovery_backlog_stddev_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "discovery_backlog" */
export type Discovery_Backlog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Discovery_Backlog_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Discovery_Backlog_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Discovery_Backlog_Sum_Fields = {
  __typename?: 'discovery_backlog_sum_fields'
  sequential_id?: Maybe<Scalars['Int']>
}

/** update columns of table "discovery_backlog" */
export enum Discovery_Backlog_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Discovery_Backlog_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Discovery_Backlog_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Discovery_Backlog_Set_Input>
  /** filter the rows which have to be updated */
  where: Discovery_Backlog_Bool_Exp
}

/** aggregate var_pop on columns */
export type Discovery_Backlog_Var_Pop_Fields = {
  __typename?: 'discovery_backlog_var_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Discovery_Backlog_Var_Samp_Fields = {
  __typename?: 'discovery_backlog_var_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Discovery_Backlog_Variance_Fields = {
  __typename?: 'discovery_backlog_variance_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** A table of items for the discovery feed */
export type Discovery_Feed = {
  __typename?: 'discovery_feed'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  feed_item: Feed_Item
  feed_item_id: Scalars['uuid']
  id: Scalars['uuid']
  sequential_id: Scalars['Int']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "discovery_feed" */
export type Discovery_Feed_Aggregate = {
  __typename?: 'discovery_feed_aggregate'
  aggregate?: Maybe<Discovery_Feed_Aggregate_Fields>
  nodes: Array<Discovery_Feed>
}

/** aggregate fields of "discovery_feed" */
export type Discovery_Feed_Aggregate_Fields = {
  __typename?: 'discovery_feed_aggregate_fields'
  avg?: Maybe<Discovery_Feed_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Discovery_Feed_Max_Fields>
  min?: Maybe<Discovery_Feed_Min_Fields>
  stddev?: Maybe<Discovery_Feed_Stddev_Fields>
  stddev_pop?: Maybe<Discovery_Feed_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Discovery_Feed_Stddev_Samp_Fields>
  sum?: Maybe<Discovery_Feed_Sum_Fields>
  var_pop?: Maybe<Discovery_Feed_Var_Pop_Fields>
  var_samp?: Maybe<Discovery_Feed_Var_Samp_Fields>
  variance?: Maybe<Discovery_Feed_Variance_Fields>
}

/** aggregate fields of "discovery_feed" */
export type Discovery_Feed_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Discovery_Feed_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Discovery_Feed_Avg_Fields = {
  __typename?: 'discovery_feed_avg_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "discovery_feed". All fields are combined with a logical 'AND'. */
export type Discovery_Feed_Bool_Exp = {
  _and?: Maybe<Array<Discovery_Feed_Bool_Exp>>
  _not?: Maybe<Discovery_Feed_Bool_Exp>
  _or?: Maybe<Array<Discovery_Feed_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  sequential_id?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "discovery_feed" */
export enum Discovery_Feed_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiscoveryFeedPkey = 'discovery_feed_pkey',
  /** unique or primary key constraint on columns "sequential_id" */
  DiscoveryFeedSequentialIdKey = 'discovery_feed_sequential_id_key',
}

/** input type for incrementing numeric columns in table "discovery_feed" */
export type Discovery_Feed_Inc_Input = {
  sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "discovery_feed" */
export type Discovery_Feed_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Discovery_Feed_Max_Fields = {
  __typename?: 'discovery_feed_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Discovery_Feed_Min_Fields = {
  __typename?: 'discovery_feed_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "discovery_feed" */
export type Discovery_Feed_Mutation_Response = {
  __typename?: 'discovery_feed_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Discovery_Feed>
}

/** on_conflict condition type for table "discovery_feed" */
export type Discovery_Feed_On_Conflict = {
  constraint: Discovery_Feed_Constraint
  update_columns?: Array<Discovery_Feed_Update_Column>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

/** Ordering options when selecting data from "discovery_feed". */
export type Discovery_Feed_Order_By = {
  created_at?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: discovery_feed */
export type Discovery_Feed_Pk_Columns_Input = {
  id: Scalars['uuid']
}

export type Discovery_Feed_Random_Args = {
  seed?: Maybe<Scalars['seed_float']>
}

/** select columns of table "discovery_feed" */
export enum Discovery_Feed_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "discovery_feed" */
export type Discovery_Feed_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Discovery_Feed_Stddev_Fields = {
  __typename?: 'discovery_feed_stddev_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Discovery_Feed_Stddev_Pop_Fields = {
  __typename?: 'discovery_feed_stddev_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Discovery_Feed_Stddev_Samp_Fields = {
  __typename?: 'discovery_feed_stddev_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "discovery_feed" */
export type Discovery_Feed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Discovery_Feed_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Discovery_Feed_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Discovery_Feed_Sum_Fields = {
  __typename?: 'discovery_feed_sum_fields'
  sequential_id?: Maybe<Scalars['Int']>
}

/** update columns of table "discovery_feed" */
export enum Discovery_Feed_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Discovery_Feed_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Discovery_Feed_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Discovery_Feed_Set_Input>
  /** filter the rows which have to be updated */
  where: Discovery_Feed_Bool_Exp
}

/** aggregate var_pop on columns */
export type Discovery_Feed_Var_Pop_Fields = {
  __typename?: 'discovery_feed_var_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Discovery_Feed_Var_Samp_Fields = {
  __typename?: 'discovery_feed_var_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Discovery_Feed_Variance_Fields = {
  __typename?: 'discovery_feed_variance_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** columns and relationships of "discovery_staging" */
export type Discovery_Staging = {
  __typename?: 'discovery_staging'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  feed_item: Feed_Item
  feed_item_id: Scalars['uuid']
  id: Scalars['uuid']
  sequential_id: Scalars['Int']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "discovery_staging" */
export type Discovery_Staging_Aggregate = {
  __typename?: 'discovery_staging_aggregate'
  aggregate?: Maybe<Discovery_Staging_Aggregate_Fields>
  nodes: Array<Discovery_Staging>
}

/** aggregate fields of "discovery_staging" */
export type Discovery_Staging_Aggregate_Fields = {
  __typename?: 'discovery_staging_aggregate_fields'
  avg?: Maybe<Discovery_Staging_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Discovery_Staging_Max_Fields>
  min?: Maybe<Discovery_Staging_Min_Fields>
  stddev?: Maybe<Discovery_Staging_Stddev_Fields>
  stddev_pop?: Maybe<Discovery_Staging_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Discovery_Staging_Stddev_Samp_Fields>
  sum?: Maybe<Discovery_Staging_Sum_Fields>
  var_pop?: Maybe<Discovery_Staging_Var_Pop_Fields>
  var_samp?: Maybe<Discovery_Staging_Var_Samp_Fields>
  variance?: Maybe<Discovery_Staging_Variance_Fields>
}

/** aggregate fields of "discovery_staging" */
export type Discovery_Staging_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Discovery_Staging_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Discovery_Staging_Avg_Fields = {
  __typename?: 'discovery_staging_avg_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "discovery_staging". All fields are combined with a logical 'AND'. */
export type Discovery_Staging_Bool_Exp = {
  _and?: Maybe<Array<Discovery_Staging_Bool_Exp>>
  _not?: Maybe<Discovery_Staging_Bool_Exp>
  _or?: Maybe<Array<Discovery_Staging_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  sequential_id?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "discovery_staging" */
export enum Discovery_Staging_Constraint {
  /** unique or primary key constraint on columns "feed_item_id" */
  DiscoveryStagingFeedItemIdKey = 'discovery_staging_feed_item_id_key',
  /** unique or primary key constraint on columns "id" */
  DiscoveryStagingPkey = 'discovery_staging_pkey',
  /** unique or primary key constraint on columns "sequential_id" */
  DiscoveryStagingSequentialIdKey = 'discovery_staging_sequential_id_key',
}

/** input type for incrementing numeric columns in table "discovery_staging" */
export type Discovery_Staging_Inc_Input = {
  sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "discovery_staging" */
export type Discovery_Staging_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Discovery_Staging_Max_Fields = {
  __typename?: 'discovery_staging_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Discovery_Staging_Min_Fields = {
  __typename?: 'discovery_staging_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "discovery_staging" */
export type Discovery_Staging_Mutation_Response = {
  __typename?: 'discovery_staging_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Discovery_Staging>
}

/** on_conflict condition type for table "discovery_staging" */
export type Discovery_Staging_On_Conflict = {
  constraint: Discovery_Staging_Constraint
  update_columns?: Array<Discovery_Staging_Update_Column>
  where?: Maybe<Discovery_Staging_Bool_Exp>
}

/** Ordering options when selecting data from "discovery_staging". */
export type Discovery_Staging_Order_By = {
  created_at?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: discovery_staging */
export type Discovery_Staging_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "discovery_staging" */
export enum Discovery_Staging_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "discovery_staging" */
export type Discovery_Staging_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Discovery_Staging_Stddev_Fields = {
  __typename?: 'discovery_staging_stddev_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Discovery_Staging_Stddev_Pop_Fields = {
  __typename?: 'discovery_staging_stddev_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Discovery_Staging_Stddev_Samp_Fields = {
  __typename?: 'discovery_staging_stddev_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "discovery_staging" */
export type Discovery_Staging_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Discovery_Staging_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Discovery_Staging_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Discovery_Staging_Sum_Fields = {
  __typename?: 'discovery_staging_sum_fields'
  sequential_id?: Maybe<Scalars['Int']>
}

/** update columns of table "discovery_staging" */
export enum Discovery_Staging_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Discovery_Staging_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Discovery_Staging_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Discovery_Staging_Set_Input>
  /** filter the rows which have to be updated */
  where: Discovery_Staging_Bool_Exp
}

/** aggregate var_pop on columns */
export type Discovery_Staging_Var_Pop_Fields = {
  __typename?: 'discovery_staging_var_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Discovery_Staging_Var_Samp_Fields = {
  __typename?: 'discovery_staging_var_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Discovery_Staging_Variance_Fields = {
  __typename?: 'discovery_staging_variance_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** A shop that is being featured within the discovery feed */
export type Featured_Shop = {
  __typename?: 'featured_shop'
  /** An array relationship */
  featured_shop_items: Array<Featured_Shop_Item>
  /** An aggregate relationship */
  featured_shop_items_aggregate: Featured_Shop_Item_Aggregate
  /** An object relationship */
  feed_item?: Maybe<Feed_Item>
  feed_item_type: Feed_Item_Type_Enum
  id: Scalars['uuid']
  /** A computed field, executes function "featured_shop_liked_by_user" */
  liked_by_user?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  shop: Shop
  shop_key: Scalars['String']
}

/** A shop that is being featured within the discovery feed */
export type Featured_ShopFeatured_Shop_ItemsArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Item_Order_By>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

/** A shop that is being featured within the discovery feed */
export type Featured_ShopFeatured_Shop_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Item_Order_By>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

/** aggregated selection of "featured_shop" */
export type Featured_Shop_Aggregate = {
  __typename?: 'featured_shop_aggregate'
  aggregate?: Maybe<Featured_Shop_Aggregate_Fields>
  nodes: Array<Featured_Shop>
}

/** aggregate fields of "featured_shop" */
export type Featured_Shop_Aggregate_Fields = {
  __typename?: 'featured_shop_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Featured_Shop_Max_Fields>
  min?: Maybe<Featured_Shop_Min_Fields>
}

/** aggregate fields of "featured_shop" */
export type Featured_Shop_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Featured_Shop_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "featured_shop". All fields are combined with a logical 'AND'. */
export type Featured_Shop_Bool_Exp = {
  _and?: Maybe<Array<Featured_Shop_Bool_Exp>>
  _not?: Maybe<Featured_Shop_Bool_Exp>
  _or?: Maybe<Array<Featured_Shop_Bool_Exp>>
  featured_shop_items?: Maybe<Featured_Shop_Item_Bool_Exp>
  featured_shop_items_aggregate?: Maybe<Featured_Shop_Item_Aggregate_Bool_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_type?: Maybe<Feed_Item_Type_Enum_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  liked_by_user?: Maybe<Boolean_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "featured_shop" */
export enum Featured_Shop_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeaturedShopPkey = 'featured_shop_pkey',
  /** unique or primary key constraint on columns "shop_key" */
  FeaturedShopShopKeyKey = 'featured_shop_shop_key_key',
}

/** input type for inserting data into table "featured_shop" */
export type Featured_Shop_Insert_Input = {
  featured_shop_items?: Maybe<Featured_Shop_Item_Arr_Rel_Insert_Input>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  id?: Maybe<Scalars['uuid']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
}

/** A table to hold references to the items of a shop that are featured */
export type Featured_Shop_Item = {
  __typename?: 'featured_shop_item'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  featured_shop: Featured_Shop
  id: Scalars['uuid']
  /** An object relationship */
  shop_item?: Maybe<Shop_Item>
  shop_item_id: Scalars['uuid']
  shop_key: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "featured_shop_item" */
export type Featured_Shop_Item_Aggregate = {
  __typename?: 'featured_shop_item_aggregate'
  aggregate?: Maybe<Featured_Shop_Item_Aggregate_Fields>
  nodes: Array<Featured_Shop_Item>
}

export type Featured_Shop_Item_Aggregate_Bool_Exp = {
  count?: Maybe<Featured_Shop_Item_Aggregate_Bool_Exp_Count>
}

export type Featured_Shop_Item_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Featured_Shop_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "featured_shop_item" */
export type Featured_Shop_Item_Aggregate_Fields = {
  __typename?: 'featured_shop_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Featured_Shop_Item_Max_Fields>
  min?: Maybe<Featured_Shop_Item_Min_Fields>
}

/** aggregate fields of "featured_shop_item" */
export type Featured_Shop_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "featured_shop_item" */
export type Featured_Shop_Item_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Featured_Shop_Item_Max_Order_By>
  min?: Maybe<Featured_Shop_Item_Min_Order_By>
}

/** input type for inserting array relation for remote table "featured_shop_item" */
export type Featured_Shop_Item_Arr_Rel_Insert_Input = {
  data: Array<Featured_Shop_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Featured_Shop_Item_On_Conflict>
}

/** Boolean expression to filter rows from the table "featured_shop_item". All fields are combined with a logical 'AND'. */
export type Featured_Shop_Item_Bool_Exp = {
  _and?: Maybe<Array<Featured_Shop_Item_Bool_Exp>>
  _not?: Maybe<Featured_Shop_Item_Bool_Exp>
  _or?: Maybe<Array<Featured_Shop_Item_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  featured_shop?: Maybe<Featured_Shop_Bool_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  shop_item?: Maybe<Shop_Item_Bool_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "featured_shop_item" */
export enum Featured_Shop_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeaturedShopItemPkey = 'featured_shop_item_pkey',
  /** unique or primary key constraint on columns "shop_item_id" */
  FeaturedShopItemShopItemIdKey = 'featured_shop_item_shop_item_id_key',
  /** unique or primary key constraint on columns "shop_key", "shop_item_id" */
  FeaturedShopItemShopKeyShopItemIdKey = 'featured_shop_item_shop_key_shop_item_id_key',
}

/** input type for inserting data into table "featured_shop_item" */
export type Featured_Shop_Item_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  featured_shop?: Maybe<Featured_Shop_Obj_Rel_Insert_Input>
  id?: Maybe<Scalars['uuid']>
  shop_item?: Maybe<Shop_Item_Obj_Rel_Insert_Input>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Featured_Shop_Item_Max_Fields = {
  __typename?: 'featured_shop_item_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "featured_shop_item" */
export type Featured_Shop_Item_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Featured_Shop_Item_Min_Fields = {
  __typename?: 'featured_shop_item_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "featured_shop_item" */
export type Featured_Shop_Item_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "featured_shop_item" */
export type Featured_Shop_Item_Mutation_Response = {
  __typename?: 'featured_shop_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Featured_Shop_Item>
}

/** on_conflict condition type for table "featured_shop_item" */
export type Featured_Shop_Item_On_Conflict = {
  constraint: Featured_Shop_Item_Constraint
  update_columns?: Array<Featured_Shop_Item_Update_Column>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

/** Ordering options when selecting data from "featured_shop_item". */
export type Featured_Shop_Item_Order_By = {
  created_at?: Maybe<Order_By>
  featured_shop?: Maybe<Featured_Shop_Order_By>
  id?: Maybe<Order_By>
  shop_item?: Maybe<Shop_Item_Order_By>
  shop_item_id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: featured_shop_item */
export type Featured_Shop_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "featured_shop_item" */
export enum Featured_Shop_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "featured_shop_item" */
export type Featured_Shop_Item_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "featured_shop_item" */
export type Featured_Shop_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Featured_Shop_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Featured_Shop_Item_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "featured_shop_item" */
export enum Featured_Shop_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Featured_Shop_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Featured_Shop_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Featured_Shop_Item_Bool_Exp
}

/** aggregate max on columns */
export type Featured_Shop_Max_Fields = {
  __typename?: 'featured_shop_max_fields'
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Featured_Shop_Min_Fields = {
  __typename?: 'featured_shop_min_fields'
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "featured_shop" */
export type Featured_Shop_Mutation_Response = {
  __typename?: 'featured_shop_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Featured_Shop>
}

/** input type for inserting object relation for remote table "featured_shop" */
export type Featured_Shop_Obj_Rel_Insert_Input = {
  data: Featured_Shop_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Featured_Shop_On_Conflict>
}

/** on_conflict condition type for table "featured_shop" */
export type Featured_Shop_On_Conflict = {
  constraint: Featured_Shop_Constraint
  update_columns?: Array<Featured_Shop_Update_Column>
  where?: Maybe<Featured_Shop_Bool_Exp>
}

/** Ordering options when selecting data from "featured_shop". */
export type Featured_Shop_Order_By = {
  featured_shop_items_aggregate?: Maybe<Featured_Shop_Item_Aggregate_Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  liked_by_user?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
}

/** primary key columns input for table: featured_shop */
export type Featured_Shop_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "featured_shop" */
export enum Featured_Shop_Select_Column {
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
}

/** input type for updating data in table "featured_shop" */
export type Featured_Shop_Set_Input = {
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "featured_shop" */
export type Featured_Shop_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Featured_Shop_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Featured_Shop_Stream_Cursor_Value_Input = {
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
}

/** update columns of table "featured_shop" */
export enum Featured_Shop_Update_Column {
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
}

export type Featured_Shop_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Featured_Shop_Set_Input>
  /** filter the rows which have to be updated */
  where: Featured_Shop_Bool_Exp
}

/** A table to keep track of all the allowable polymorphic feed items */
export type Feed_Item = {
  __typename?: 'feed_item'
  /** An object relationship */
  cart_item?: Maybe<Cart_Item>
  /** An object relationship */
  collection?: Maybe<Collection>
  /** An object relationship */
  collection_cart_item?: Maybe<Collection_Cart_Item>
  /** An object relationship */
  featured_shop?: Maybe<Featured_Shop>
  /** An array relationship */
  feed_item_likes: Array<Feed_Item_Like>
  /** An aggregate relationship */
  feed_item_likes_aggregate: Feed_Item_Like_Aggregate
  id: Scalars['uuid']
  /** A computed field, executes function "is_feed_item_in_discovery_backlog" */
  in_discovery_backlog?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "is_feed_item_in_discovery_feed" */
  in_discovery_feed?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "is_feed_item_in_discovery_staging" */
  in_discovery_staging?: Maybe<Scalars['Boolean']>
  item_id: Scalars['uuid']
  item_type: Feed_Item_Type_Enum
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "feed_item_liked_by_user_field" */
  liked_by_user?: Maybe<Scalars['Boolean']>
  metadata: Scalars['jsonb']
  /** An object relationship */
  notifiable_user?: Maybe<Notifiable_Feed_Item_User>
}

/** A table to keep track of all the allowable polymorphic feed items */
export type Feed_ItemFeed_Item_LikesArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

/** A table to keep track of all the allowable polymorphic feed items */
export type Feed_ItemFeed_Item_Likes_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

/** A table to keep track of all the allowable polymorphic feed items */
export type Feed_ItemMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "feed_item" */
export type Feed_Item_Aggregate = {
  __typename?: 'feed_item_aggregate'
  aggregate?: Maybe<Feed_Item_Aggregate_Fields>
  nodes: Array<Feed_Item>
}

/** aggregate fields of "feed_item" */
export type Feed_Item_Aggregate_Fields = {
  __typename?: 'feed_item_aggregate_fields'
  avg?: Maybe<Feed_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Feed_Item_Max_Fields>
  min?: Maybe<Feed_Item_Min_Fields>
  stddev?: Maybe<Feed_Item_Stddev_Fields>
  stddev_pop?: Maybe<Feed_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Feed_Item_Stddev_Samp_Fields>
  sum?: Maybe<Feed_Item_Sum_Fields>
  var_pop?: Maybe<Feed_Item_Var_Pop_Fields>
  var_samp?: Maybe<Feed_Item_Var_Samp_Fields>
  variance?: Maybe<Feed_Item_Variance_Fields>
}

/** aggregate fields of "feed_item" */
export type Feed_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Feed_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Feed_Item_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Feed_Item_Avg_Fields = {
  __typename?: 'feed_item_avg_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "feed_item". All fields are combined with a logical 'AND'. */
export type Feed_Item_Bool_Exp = {
  _and?: Maybe<Array<Feed_Item_Bool_Exp>>
  _not?: Maybe<Feed_Item_Bool_Exp>
  _or?: Maybe<Array<Feed_Item_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  collection?: Maybe<Collection_Bool_Exp>
  collection_cart_item?: Maybe<Collection_Cart_Item_Bool_Exp>
  featured_shop?: Maybe<Featured_Shop_Bool_Exp>
  feed_item_likes?: Maybe<Feed_Item_Like_Bool_Exp>
  feed_item_likes_aggregate?: Maybe<Feed_Item_Like_Aggregate_Bool_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  in_discovery_backlog?: Maybe<Boolean_Comparison_Exp>
  in_discovery_feed?: Maybe<Boolean_Comparison_Exp>
  in_discovery_staging?: Maybe<Boolean_Comparison_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<Feed_Item_Type_Enum_Comparison_Exp>
  like_count?: Maybe<Int_Comparison_Exp>
  liked_by_user?: Maybe<Boolean_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  notifiable_user?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
}

/** unique or primary key constraints on table "feed_item" */
export enum Feed_Item_Constraint {
  /** unique or primary key constraint on columns "item_id", "item_type" */
  FeedItemItemTypeItemIdKey = 'feed_item_item_type_item_id_key',
  /** unique or primary key constraint on columns "id" */
  FeedItemPkey = 'feed_item_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Feed_Item_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Feed_Item_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Feed_Item_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "feed_item" */
export type Feed_Item_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_cart_item?: Maybe<Collection_Cart_Item_Obj_Rel_Insert_Input>
  featured_shop?: Maybe<Featured_Shop_Obj_Rel_Insert_Input>
  feed_item_likes?: Maybe<Feed_Item_Like_Arr_Rel_Insert_Input>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Feed_Item_Type_Enum>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_user?: Maybe<Notifiable_Feed_Item_User_Obj_Rel_Insert_Input>
}

/** The accumulation of likes items that can go in the discovery feed */
export type Feed_Item_Like = {
  __typename?: 'feed_item_like'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  feed_item: Feed_Item
  feed_item_id: Scalars['uuid']
  id: Scalars['uuid']
  notifiable_item_type: Notifiable_Item_Type_Enum
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
  value: Scalars['Int']
}

/** aggregated selection of "feed_item_like" */
export type Feed_Item_Like_Aggregate = {
  __typename?: 'feed_item_like_aggregate'
  aggregate?: Maybe<Feed_Item_Like_Aggregate_Fields>
  nodes: Array<Feed_Item_Like>
}

export type Feed_Item_Like_Aggregate_Bool_Exp = {
  count?: Maybe<Feed_Item_Like_Aggregate_Bool_Exp_Count>
}

export type Feed_Item_Like_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Feed_Item_Like_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Feed_Item_Like_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "feed_item_like" */
export type Feed_Item_Like_Aggregate_Fields = {
  __typename?: 'feed_item_like_aggregate_fields'
  avg?: Maybe<Feed_Item_Like_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Feed_Item_Like_Max_Fields>
  min?: Maybe<Feed_Item_Like_Min_Fields>
  stddev?: Maybe<Feed_Item_Like_Stddev_Fields>
  stddev_pop?: Maybe<Feed_Item_Like_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Feed_Item_Like_Stddev_Samp_Fields>
  sum?: Maybe<Feed_Item_Like_Sum_Fields>
  var_pop?: Maybe<Feed_Item_Like_Var_Pop_Fields>
  var_samp?: Maybe<Feed_Item_Like_Var_Samp_Fields>
  variance?: Maybe<Feed_Item_Like_Variance_Fields>
}

/** aggregate fields of "feed_item_like" */
export type Feed_Item_Like_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Feed_Item_Like_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "feed_item_like" */
export type Feed_Item_Like_Aggregate_Order_By = {
  avg?: Maybe<Feed_Item_Like_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Feed_Item_Like_Max_Order_By>
  min?: Maybe<Feed_Item_Like_Min_Order_By>
  stddev?: Maybe<Feed_Item_Like_Stddev_Order_By>
  stddev_pop?: Maybe<Feed_Item_Like_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Feed_Item_Like_Stddev_Samp_Order_By>
  sum?: Maybe<Feed_Item_Like_Sum_Order_By>
  var_pop?: Maybe<Feed_Item_Like_Var_Pop_Order_By>
  var_samp?: Maybe<Feed_Item_Like_Var_Samp_Order_By>
  variance?: Maybe<Feed_Item_Like_Variance_Order_By>
}

/** input type for inserting array relation for remote table "feed_item_like" */
export type Feed_Item_Like_Arr_Rel_Insert_Input = {
  data: Array<Feed_Item_Like_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Feed_Item_Like_On_Conflict>
}

/** aggregate avg on columns */
export type Feed_Item_Like_Avg_Fields = {
  __typename?: 'feed_item_like_avg_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "feed_item_like" */
export type Feed_Item_Like_Avg_Order_By = {
  value?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "feed_item_like". All fields are combined with a logical 'AND'. */
export type Feed_Item_Like_Bool_Exp = {
  _and?: Maybe<Array<Feed_Item_Like_Bool_Exp>>
  _not?: Maybe<Feed_Item_Like_Bool_Exp>
  _or?: Maybe<Array<Feed_Item_Like_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  value?: Maybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "feed_item_like" */
export enum Feed_Item_Like_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeedItemLikeIdKey = 'feed_item_like_id_key',
  /** unique or primary key constraint on columns "feed_item_id", "user_id" */
  FeedItemLikePkey = 'feed_item_like_pkey',
}

/** input type for incrementing numeric columns in table "feed_item_like" */
export type Feed_Item_Like_Inc_Input = {
  value?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "feed_item_like" */
export type Feed_Item_Like_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** aggregate max on columns */
export type Feed_Item_Like_Max_Fields = {
  __typename?: 'feed_item_like_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "feed_item_like" */
export type Feed_Item_Like_Max_Order_By = {
  created_at?: Maybe<Order_By>
  feed_item_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Feed_Item_Like_Min_Fields = {
  __typename?: 'feed_item_like_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "feed_item_like" */
export type Feed_Item_Like_Min_Order_By = {
  created_at?: Maybe<Order_By>
  feed_item_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** response of any mutation on the table "feed_item_like" */
export type Feed_Item_Like_Mutation_Response = {
  __typename?: 'feed_item_like_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Feed_Item_Like>
}

/** input type for inserting object relation for remote table "feed_item_like" */
export type Feed_Item_Like_Obj_Rel_Insert_Input = {
  data: Feed_Item_Like_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Feed_Item_Like_On_Conflict>
}

/** on_conflict condition type for table "feed_item_like" */
export type Feed_Item_Like_On_Conflict = {
  constraint: Feed_Item_Like_Constraint
  update_columns?: Array<Feed_Item_Like_Update_Column>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

/** Ordering options when selecting data from "feed_item_like". */
export type Feed_Item_Like_Order_By = {
  created_at?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_item_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: feed_item_like */
export type Feed_Item_Like_Pk_Columns_Input = {
  feed_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Feed_Item_Like_Random_Args = {
  seed?: Maybe<Scalars['seed_float']>
}

/** select columns of table "feed_item_like" */
export enum Feed_Item_Like_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "feed_item_like" */
export type Feed_Item_Like_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Feed_Item_Like_Stddev_Fields = {
  __typename?: 'feed_item_like_stddev_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "feed_item_like" */
export type Feed_Item_Like_Stddev_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Feed_Item_Like_Stddev_Pop_Fields = {
  __typename?: 'feed_item_like_stddev_pop_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "feed_item_like" */
export type Feed_Item_Like_Stddev_Pop_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Feed_Item_Like_Stddev_Samp_Fields = {
  __typename?: 'feed_item_like_stddev_samp_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "feed_item_like" */
export type Feed_Item_Like_Stddev_Samp_Order_By = {
  value?: Maybe<Order_By>
}

/** Streaming cursor of the table "feed_item_like" */
export type Feed_Item_Like_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feed_Item_Like_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Feed_Item_Like_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Feed_Item_Like_Sum_Fields = {
  __typename?: 'feed_item_like_sum_fields'
  value?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "feed_item_like" */
export type Feed_Item_Like_Sum_Order_By = {
  value?: Maybe<Order_By>
}

/** update columns of table "feed_item_like" */
export enum Feed_Item_Like_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
}

export type Feed_Item_Like_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Feed_Item_Like_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Feed_Item_Like_Set_Input>
  /** filter the rows which have to be updated */
  where: Feed_Item_Like_Bool_Exp
}

/** aggregate var_pop on columns */
export type Feed_Item_Like_Var_Pop_Fields = {
  __typename?: 'feed_item_like_var_pop_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "feed_item_like" */
export type Feed_Item_Like_Var_Pop_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Feed_Item_Like_Var_Samp_Fields = {
  __typename?: 'feed_item_like_var_samp_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "feed_item_like" */
export type Feed_Item_Like_Var_Samp_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Feed_Item_Like_Variance_Fields = {
  __typename?: 'feed_item_like_variance_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "feed_item_like" */
export type Feed_Item_Like_Variance_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate max on columns */
export type Feed_Item_Max_Fields = {
  __typename?: 'feed_item_max_fields'
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** aggregate min on columns */
export type Feed_Item_Min_Fields = {
  __typename?: 'feed_item_min_fields'
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** response of any mutation on the table "feed_item" */
export type Feed_Item_Mutation_Response = {
  __typename?: 'feed_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Feed_Item>
}

/** input type for inserting object relation for remote table "feed_item" */
export type Feed_Item_Obj_Rel_Insert_Input = {
  data: Feed_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Feed_Item_On_Conflict>
}

/** on_conflict condition type for table "feed_item" */
export type Feed_Item_On_Conflict = {
  constraint: Feed_Item_Constraint
  update_columns?: Array<Feed_Item_Update_Column>
  where?: Maybe<Feed_Item_Bool_Exp>
}

/** Ordering options when selecting data from "feed_item". */
export type Feed_Item_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  collection?: Maybe<Collection_Order_By>
  collection_cart_item?: Maybe<Collection_Cart_Item_Order_By>
  featured_shop?: Maybe<Featured_Shop_Order_By>
  feed_item_likes_aggregate?: Maybe<Feed_Item_Like_Aggregate_Order_By>
  id?: Maybe<Order_By>
  in_discovery_backlog?: Maybe<Order_By>
  in_discovery_feed?: Maybe<Order_By>
  in_discovery_staging?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  like_count?: Maybe<Order_By>
  liked_by_user?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  notifiable_user?: Maybe<Notifiable_Feed_Item_User_Order_By>
}

/** primary key columns input for table: feed_item */
export type Feed_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Feed_Item_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "feed_item" */
export enum Feed_Item_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Metadata = 'metadata',
}

/** input type for updating data in table "feed_item" */
export type Feed_Item_Set_Input = {
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Feed_Item_Type_Enum>
  metadata?: Maybe<Scalars['jsonb']>
}

/** aggregate stddev on columns */
export type Feed_Item_Stddev_Fields = {
  __typename?: 'feed_item_stddev_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** aggregate stddev_pop on columns */
export type Feed_Item_Stddev_Pop_Fields = {
  __typename?: 'feed_item_stddev_pop_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** aggregate stddev_samp on columns */
export type Feed_Item_Stddev_Samp_Fields = {
  __typename?: 'feed_item_stddev_samp_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** Streaming cursor of the table "feed_item" */
export type Feed_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feed_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Feed_Item_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Feed_Item_Type_Enum>
  metadata?: Maybe<Scalars['jsonb']>
}

/** aggregate sum on columns */
export type Feed_Item_Sum_Fields = {
  __typename?: 'feed_item_sum_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** An enum table to hold the different feed item types */
export type Feed_Item_Type = {
  __typename?: 'feed_item_type'
  description: Scalars['String']
  value: Scalars['String']
}

/** aggregated selection of "feed_item_type" */
export type Feed_Item_Type_Aggregate = {
  __typename?: 'feed_item_type_aggregate'
  aggregate?: Maybe<Feed_Item_Type_Aggregate_Fields>
  nodes: Array<Feed_Item_Type>
}

/** aggregate fields of "feed_item_type" */
export type Feed_Item_Type_Aggregate_Fields = {
  __typename?: 'feed_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Feed_Item_Type_Max_Fields>
  min?: Maybe<Feed_Item_Type_Min_Fields>
}

/** aggregate fields of "feed_item_type" */
export type Feed_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Feed_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "feed_item_type". All fields are combined with a logical 'AND'. */
export type Feed_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Feed_Item_Type_Bool_Exp>>
  _not?: Maybe<Feed_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Feed_Item_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "feed_item_type" */
export enum Feed_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  FeedItemTypePkey = 'feed_item_type_pkey',
}

export enum Feed_Item_Type_Enum {
  CartItem = 'cart_item',
  Collection = 'collection',
  CollectionCartItem = 'collection_cart_item',
  FeaturedShop = 'featured_shop',
}

/** Boolean expression to compare columns of type "feed_item_type_enum". All fields are combined with logical 'AND'. */
export type Feed_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Feed_Item_Type_Enum>
  _in?: Maybe<Array<Feed_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Feed_Item_Type_Enum>
  _nin?: Maybe<Array<Feed_Item_Type_Enum>>
}

/** input type for inserting data into table "feed_item_type" */
export type Feed_Item_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Feed_Item_Type_Max_Fields = {
  __typename?: 'feed_item_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Feed_Item_Type_Min_Fields = {
  __typename?: 'feed_item_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "feed_item_type" */
export type Feed_Item_Type_Mutation_Response = {
  __typename?: 'feed_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Feed_Item_Type>
}

/** on_conflict condition type for table "feed_item_type" */
export type Feed_Item_Type_On_Conflict = {
  constraint: Feed_Item_Type_Constraint
  update_columns?: Array<Feed_Item_Type_Update_Column>
  where?: Maybe<Feed_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "feed_item_type". */
export type Feed_Item_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: feed_item_type */
export type Feed_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "feed_item_type" */
export enum Feed_Item_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "feed_item_type" */
export type Feed_Item_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "feed_item_type" */
export type Feed_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feed_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Feed_Item_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "feed_item_type" */
export enum Feed_Item_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Feed_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Feed_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Feed_Item_Type_Bool_Exp
}

/** update columns of table "feed_item" */
export enum Feed_Item_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Metadata = 'metadata',
}

export type Feed_Item_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Feed_Item_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Feed_Item_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Feed_Item_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Feed_Item_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Feed_Item_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Feed_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Feed_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Feed_Item_Var_Pop_Fields = {
  __typename?: 'feed_item_var_pop_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** aggregate var_samp on columns */
export type Feed_Item_Var_Samp_Fields = {
  __typename?: 'feed_item_var_samp_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** aggregate variance on columns */
export type Feed_Item_Variance_Fields = {
  __typename?: 'feed_item_variance_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

export type Feed_Items_For_User_Args = {
  in_username?: Maybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>
  _gt?: Maybe<Scalars['float8']>
  _gte?: Maybe<Scalars['float8']>
  _in?: Maybe<Array<Scalars['float8']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['float8']>
  _lte?: Maybe<Scalars['float8']>
  _neq?: Maybe<Scalars['float8']>
  _nin?: Maybe<Array<Scalars['float8']>>
}

/** columns and relationships of "fmtc_deal" */
export type Fmtc_Deal = {
  __typename?: 'fmtc_deal'
  affiliate: Scalars['String']
  affiliate_url?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  code?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  direct_url: Scalars['String']
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  end_date?: Maybe<Scalars['timestamptz']>
  fmtc_id: Scalars['numeric']
  fmtc_url: Scalars['String']
  id: Scalars['uuid']
  merchant_id: Scalars['numeric']
  merchant_name: Scalars['String']
  metadata: Scalars['jsonb']
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
  /** An object relationship */
  shop: Shop
  shop_key: Scalars['String']
  start_date: Scalars['timestamptz']
  status: Scalars['String']
  title: Scalars['String']
  types?: Maybe<Array<Scalars['String']>>
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "fmtc_deal" */
export type Fmtc_DealMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "fmtc_deal" */
export type Fmtc_Deal_Aggregate = {
  __typename?: 'fmtc_deal_aggregate'
  aggregate?: Maybe<Fmtc_Deal_Aggregate_Fields>
  nodes: Array<Fmtc_Deal>
}

/** aggregate fields of "fmtc_deal" */
export type Fmtc_Deal_Aggregate_Fields = {
  __typename?: 'fmtc_deal_aggregate_fields'
  avg?: Maybe<Fmtc_Deal_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Fmtc_Deal_Max_Fields>
  min?: Maybe<Fmtc_Deal_Min_Fields>
  stddev?: Maybe<Fmtc_Deal_Stddev_Fields>
  stddev_pop?: Maybe<Fmtc_Deal_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Fmtc_Deal_Stddev_Samp_Fields>
  sum?: Maybe<Fmtc_Deal_Sum_Fields>
  var_pop?: Maybe<Fmtc_Deal_Var_Pop_Fields>
  var_samp?: Maybe<Fmtc_Deal_Var_Samp_Fields>
  variance?: Maybe<Fmtc_Deal_Variance_Fields>
}

/** aggregate fields of "fmtc_deal" */
export type Fmtc_Deal_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fmtc_Deal_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Fmtc_Deal_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Fmtc_Deal_Avg_Fields = {
  __typename?: 'fmtc_deal_avg_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "fmtc_deal". All fields are combined with a logical 'AND'. */
export type Fmtc_Deal_Bool_Exp = {
  _and?: Maybe<Array<Fmtc_Deal_Bool_Exp>>
  _not?: Maybe<Fmtc_Deal_Bool_Exp>
  _or?: Maybe<Array<Fmtc_Deal_Bool_Exp>>
  affiliate?: Maybe<String_Comparison_Exp>
  affiliate_url?: Maybe<String_Comparison_Exp>
  categories?: Maybe<String_Array_Comparison_Exp>
  code?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  direct_url?: Maybe<String_Comparison_Exp>
  discount?: Maybe<Numeric_Comparison_Exp>
  discount_percent?: Maybe<Numeric_Comparison_Exp>
  end_date?: Maybe<Timestamptz_Comparison_Exp>
  fmtc_id?: Maybe<Numeric_Comparison_Exp>
  fmtc_url?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  merchant_id?: Maybe<Numeric_Comparison_Exp>
  merchant_name?: Maybe<String_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  rating?: Maybe<Numeric_Comparison_Exp>
  sale_price?: Maybe<Numeric_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  start_date?: Maybe<Timestamptz_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  title?: Maybe<String_Comparison_Exp>
  types?: Maybe<String_Array_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "fmtc_deal" */
export enum Fmtc_Deal_Constraint {
  /** unique or primary key constraint on columns "fmtc_id" */
  FmtcDealFmtcId = 'fmtc_deal_fmtc_id',
  /** unique or primary key constraint on columns "id" */
  FmtcDealPkey = 'fmtc_deal_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Fmtc_Deal_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Fmtc_Deal_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Fmtc_Deal_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "fmtc_deal" */
export type Fmtc_Deal_Inc_Input = {
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  fmtc_id?: Maybe<Scalars['numeric']>
  merchant_id?: Maybe<Scalars['numeric']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "fmtc_deal" */
export type Fmtc_Deal_Insert_Input = {
  affiliate?: Maybe<Scalars['String']>
  affiliate_url?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  created_at?: Maybe<Scalars['timestamptz']>
  direct_url?: Maybe<Scalars['String']>
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  end_date?: Maybe<Scalars['timestamptz']>
  fmtc_id?: Maybe<Scalars['numeric']>
  fmtc_url?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  merchant_id?: Maybe<Scalars['numeric']>
  merchant_name?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  types?: Maybe<Array<Scalars['String']>>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Fmtc_Deal_Max_Fields = {
  __typename?: 'fmtc_deal_max_fields'
  affiliate?: Maybe<Scalars['String']>
  affiliate_url?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  code?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  direct_url?: Maybe<Scalars['String']>
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  end_date?: Maybe<Scalars['timestamptz']>
  fmtc_id?: Maybe<Scalars['numeric']>
  fmtc_url?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  merchant_id?: Maybe<Scalars['numeric']>
  merchant_name?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  types?: Maybe<Array<Scalars['String']>>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Fmtc_Deal_Min_Fields = {
  __typename?: 'fmtc_deal_min_fields'
  affiliate?: Maybe<Scalars['String']>
  affiliate_url?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  code?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  direct_url?: Maybe<Scalars['String']>
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  end_date?: Maybe<Scalars['timestamptz']>
  fmtc_id?: Maybe<Scalars['numeric']>
  fmtc_url?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  merchant_id?: Maybe<Scalars['numeric']>
  merchant_name?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  types?: Maybe<Array<Scalars['String']>>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "fmtc_deal" */
export type Fmtc_Deal_Mutation_Response = {
  __typename?: 'fmtc_deal_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Fmtc_Deal>
}

/** on_conflict condition type for table "fmtc_deal" */
export type Fmtc_Deal_On_Conflict = {
  constraint: Fmtc_Deal_Constraint
  update_columns?: Array<Fmtc_Deal_Update_Column>
  where?: Maybe<Fmtc_Deal_Bool_Exp>
}

/** Ordering options when selecting data from "fmtc_deal". */
export type Fmtc_Deal_Order_By = {
  affiliate?: Maybe<Order_By>
  affiliate_url?: Maybe<Order_By>
  categories?: Maybe<Order_By>
  code?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  direct_url?: Maybe<Order_By>
  discount?: Maybe<Order_By>
  discount_percent?: Maybe<Order_By>
  end_date?: Maybe<Order_By>
  fmtc_id?: Maybe<Order_By>
  fmtc_url?: Maybe<Order_By>
  id?: Maybe<Order_By>
  merchant_id?: Maybe<Order_By>
  merchant_name?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  rating?: Maybe<Order_By>
  sale_price?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  start_date?: Maybe<Order_By>
  status?: Maybe<Order_By>
  title?: Maybe<Order_By>
  types?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: fmtc_deal */
export type Fmtc_Deal_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Fmtc_Deal_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "fmtc_deal" */
export enum Fmtc_Deal_Select_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  AffiliateUrl = 'affiliate_url',
  /** column name */
  Categories = 'categories',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DirectUrl = 'direct_url',
  /** column name */
  Discount = 'discount',
  /** column name */
  DiscountPercent = 'discount_percent',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FmtcId = 'fmtc_id',
  /** column name */
  FmtcUrl = 'fmtc_url',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchant_id',
  /** column name */
  MerchantName = 'merchant_name',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Rating = 'rating',
  /** column name */
  SalePrice = 'sale_price',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Types = 'types',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "fmtc_deal" */
export type Fmtc_Deal_Set_Input = {
  affiliate?: Maybe<Scalars['String']>
  affiliate_url?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  created_at?: Maybe<Scalars['timestamptz']>
  direct_url?: Maybe<Scalars['String']>
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  end_date?: Maybe<Scalars['timestamptz']>
  fmtc_id?: Maybe<Scalars['numeric']>
  fmtc_url?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  merchant_id?: Maybe<Scalars['numeric']>
  merchant_name?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  types?: Maybe<Array<Scalars['String']>>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Fmtc_Deal_Stddev_Fields = {
  __typename?: 'fmtc_deal_stddev_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Fmtc_Deal_Stddev_Pop_Fields = {
  __typename?: 'fmtc_deal_stddev_pop_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Fmtc_Deal_Stddev_Samp_Fields = {
  __typename?: 'fmtc_deal_stddev_samp_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "fmtc_deal" */
export type Fmtc_Deal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fmtc_Deal_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Fmtc_Deal_Stream_Cursor_Value_Input = {
  affiliate?: Maybe<Scalars['String']>
  affiliate_url?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  code?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  direct_url?: Maybe<Scalars['String']>
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  end_date?: Maybe<Scalars['timestamptz']>
  fmtc_id?: Maybe<Scalars['numeric']>
  fmtc_url?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  merchant_id?: Maybe<Scalars['numeric']>
  merchant_name?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  types?: Maybe<Array<Scalars['String']>>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Fmtc_Deal_Sum_Fields = {
  __typename?: 'fmtc_deal_sum_fields'
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  fmtc_id?: Maybe<Scalars['numeric']>
  merchant_id?: Maybe<Scalars['numeric']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
}

/** update columns of table "fmtc_deal" */
export enum Fmtc_Deal_Update_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  AffiliateUrl = 'affiliate_url',
  /** column name */
  Categories = 'categories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DirectUrl = 'direct_url',
  /** column name */
  Discount = 'discount',
  /** column name */
  DiscountPercent = 'discount_percent',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FmtcId = 'fmtc_id',
  /** column name */
  FmtcUrl = 'fmtc_url',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchant_id',
  /** column name */
  MerchantName = 'merchant_name',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Rating = 'rating',
  /** column name */
  SalePrice = 'sale_price',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Types = 'types',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Fmtc_Deal_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Fmtc_Deal_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Fmtc_Deal_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Fmtc_Deal_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Fmtc_Deal_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Fmtc_Deal_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Fmtc_Deal_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Fmtc_Deal_Set_Input>
  /** filter the rows which have to be updated */
  where: Fmtc_Deal_Bool_Exp
}

/** aggregate var_pop on columns */
export type Fmtc_Deal_Var_Pop_Fields = {
  __typename?: 'fmtc_deal_var_pop_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Fmtc_Deal_Var_Samp_Fields = {
  __typename?: 'fmtc_deal_var_samp_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Fmtc_Deal_Variance_Fields = {
  __typename?: 'fmtc_deal_variance_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** A polymorphic table to identify items that can be followed */
export type Followable_Item = {
  __typename?: 'followable_item'
  /** An object relationship */
  collection?: Maybe<Collection>
  item_id: Scalars['uuid']
  item_type: Followable_Item_Type_Enum
}

/** aggregated selection of "followable_item" */
export type Followable_Item_Aggregate = {
  __typename?: 'followable_item_aggregate'
  aggregate?: Maybe<Followable_Item_Aggregate_Fields>
  nodes: Array<Followable_Item>
}

/** aggregate fields of "followable_item" */
export type Followable_Item_Aggregate_Fields = {
  __typename?: 'followable_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Followable_Item_Max_Fields>
  min?: Maybe<Followable_Item_Min_Fields>
}

/** aggregate fields of "followable_item" */
export type Followable_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Followable_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "followable_item". All fields are combined with a logical 'AND'. */
export type Followable_Item_Bool_Exp = {
  _and?: Maybe<Array<Followable_Item_Bool_Exp>>
  _not?: Maybe<Followable_Item_Bool_Exp>
  _or?: Maybe<Array<Followable_Item_Bool_Exp>>
  collection?: Maybe<Collection_Bool_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<Followable_Item_Type_Enum_Comparison_Exp>
}

/** unique or primary key constraints on table "followable_item" */
export enum Followable_Item_Constraint {
  /** unique or primary key constraint on columns "item_id", "item_type" */
  FollowingItemItemIdItemTypeKey = 'following_item_item_id_item_type_key',
  /** unique or primary key constraint on columns "item_id", "item_type" */
  FollowingItemPkey = 'following_item_pkey',
}

/** input type for inserting data into table "followable_item" */
export type Followable_Item_Insert_Input = {
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Followable_Item_Type_Enum>
}

/** aggregate max on columns */
export type Followable_Item_Max_Fields = {
  __typename?: 'followable_item_max_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type Followable_Item_Min_Fields = {
  __typename?: 'followable_item_min_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "followable_item" */
export type Followable_Item_Mutation_Response = {
  __typename?: 'followable_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Followable_Item>
}

/** input type for inserting object relation for remote table "followable_item" */
export type Followable_Item_Obj_Rel_Insert_Input = {
  data: Followable_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Followable_Item_On_Conflict>
}

/** on_conflict condition type for table "followable_item" */
export type Followable_Item_On_Conflict = {
  constraint: Followable_Item_Constraint
  update_columns?: Array<Followable_Item_Update_Column>
  where?: Maybe<Followable_Item_Bool_Exp>
}

/** Ordering options when selecting data from "followable_item". */
export type Followable_Item_Order_By = {
  collection?: Maybe<Collection_Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
}

/** primary key columns input for table: followable_item */
export type Followable_Item_Pk_Columns_Input = {
  item_id: Scalars['uuid']
  item_type: Followable_Item_Type_Enum
}

/** select columns of table "followable_item" */
export enum Followable_Item_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

/** input type for updating data in table "followable_item" */
export type Followable_Item_Set_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Followable_Item_Type_Enum>
}

/** Streaming cursor of the table "followable_item" */
export type Followable_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Followable_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Followable_Item_Stream_Cursor_Value_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Followable_Item_Type_Enum>
}

/** An enum table to hold the different followable item types */
export type Followable_Item_Type = {
  __typename?: 'followable_item_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "followable_item_type" */
export type Followable_Item_Type_Aggregate = {
  __typename?: 'followable_item_type_aggregate'
  aggregate?: Maybe<Followable_Item_Type_Aggregate_Fields>
  nodes: Array<Followable_Item_Type>
}

/** aggregate fields of "followable_item_type" */
export type Followable_Item_Type_Aggregate_Fields = {
  __typename?: 'followable_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Followable_Item_Type_Max_Fields>
  min?: Maybe<Followable_Item_Type_Min_Fields>
}

/** aggregate fields of "followable_item_type" */
export type Followable_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Followable_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "followable_item_type". All fields are combined with a logical 'AND'. */
export type Followable_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Followable_Item_Type_Bool_Exp>>
  _not?: Maybe<Followable_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Followable_Item_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "followable_item_type" */
export enum Followable_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  FollowableItemTypePkey = 'followable_item_type_pkey',
}

export enum Followable_Item_Type_Enum {
  Collection = 'collection',
}

/** Boolean expression to compare columns of type "followable_item_type_enum". All fields are combined with logical 'AND'. */
export type Followable_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Followable_Item_Type_Enum>
  _in?: Maybe<Array<Followable_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Followable_Item_Type_Enum>
  _nin?: Maybe<Array<Followable_Item_Type_Enum>>
}

/** input type for inserting data into table "followable_item_type" */
export type Followable_Item_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Followable_Item_Type_Max_Fields = {
  __typename?: 'followable_item_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Followable_Item_Type_Min_Fields = {
  __typename?: 'followable_item_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "followable_item_type" */
export type Followable_Item_Type_Mutation_Response = {
  __typename?: 'followable_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Followable_Item_Type>
}

/** on_conflict condition type for table "followable_item_type" */
export type Followable_Item_Type_On_Conflict = {
  constraint: Followable_Item_Type_Constraint
  update_columns?: Array<Followable_Item_Type_Update_Column>
  where?: Maybe<Followable_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "followable_item_type". */
export type Followable_Item_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: followable_item_type */
export type Followable_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "followable_item_type" */
export enum Followable_Item_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "followable_item_type" */
export type Followable_Item_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "followable_item_type" */
export type Followable_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Followable_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Followable_Item_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "followable_item_type" */
export enum Followable_Item_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Followable_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Followable_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Followable_Item_Type_Bool_Exp
}

/** update columns of table "followable_item" */
export enum Followable_Item_Update_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

export type Followable_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Followable_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Followable_Item_Bool_Exp
}

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>
  _gt?: Maybe<Scalars['json']>
  _gte?: Maybe<Scalars['json']>
  _in?: Maybe<Array<Scalars['json']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['json']>
  _lte?: Maybe<Scalars['json']>
  _neq?: Maybe<Scalars['json']>
  _nin?: Maybe<Array<Scalars['json']>>
}

export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>
  _eq?: Maybe<Scalars['jsonb']>
  _gt?: Maybe<Scalars['jsonb']>
  _gte?: Maybe<Scalars['jsonb']>
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>
  _in?: Maybe<Array<Scalars['jsonb']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['jsonb']>
  _lte?: Maybe<Scalars['jsonb']>
  _neq?: Maybe<Scalars['jsonb']>
  _nin?: Maybe<Array<Scalars['jsonb']>>
}

/** A table to hold event data for a creator context */
export type Leaderboard_Event = {
  __typename?: 'leaderboard_event'
  /** An object relationship */
  actor?: Maybe<User>
  created_at: Scalars['timestamptz']
  /** An object relationship */
  creator: User
  creator_id: Scalars['String']
  emitted_at?: Maybe<Scalars['timestamptz']>
  event_hash: Scalars['String']
  event_name: Leaderboard_Event_Name_Enum
  id: Scalars['uuid']
  identifier_id?: Maybe<Scalars['String']>
  identifier_type?: Maybe<Scalars['String']>
  points: Scalars['Int']
  updated_at: Scalars['timestamptz']
  /** Can be a user id in the database or an anonymous analytics uuid */
  user_id: Scalars['String']
}

/** aggregated selection of "leaderboard_event" */
export type Leaderboard_Event_Aggregate = {
  __typename?: 'leaderboard_event_aggregate'
  aggregate?: Maybe<Leaderboard_Event_Aggregate_Fields>
  nodes: Array<Leaderboard_Event>
}

/** aggregate fields of "leaderboard_event" */
export type Leaderboard_Event_Aggregate_Fields = {
  __typename?: 'leaderboard_event_aggregate_fields'
  avg?: Maybe<Leaderboard_Event_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Leaderboard_Event_Max_Fields>
  min?: Maybe<Leaderboard_Event_Min_Fields>
  stddev?: Maybe<Leaderboard_Event_Stddev_Fields>
  stddev_pop?: Maybe<Leaderboard_Event_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Leaderboard_Event_Stddev_Samp_Fields>
  sum?: Maybe<Leaderboard_Event_Sum_Fields>
  var_pop?: Maybe<Leaderboard_Event_Var_Pop_Fields>
  var_samp?: Maybe<Leaderboard_Event_Var_Samp_Fields>
  variance?: Maybe<Leaderboard_Event_Variance_Fields>
}

/** aggregate fields of "leaderboard_event" */
export type Leaderboard_Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Leaderboard_Event_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Leaderboard_Event_Avg_Fields = {
  __typename?: 'leaderboard_event_avg_fields'
  points?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "leaderboard_event". All fields are combined with a logical 'AND'. */
export type Leaderboard_Event_Bool_Exp = {
  _and?: Maybe<Array<Leaderboard_Event_Bool_Exp>>
  _not?: Maybe<Leaderboard_Event_Bool_Exp>
  _or?: Maybe<Array<Leaderboard_Event_Bool_Exp>>
  actor?: Maybe<User_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  creator?: Maybe<User_Bool_Exp>
  creator_id?: Maybe<String_Comparison_Exp>
  emitted_at?: Maybe<Timestamptz_Comparison_Exp>
  event_hash?: Maybe<String_Comparison_Exp>
  event_name?: Maybe<Leaderboard_Event_Name_Enum_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  identifier_id?: Maybe<String_Comparison_Exp>
  identifier_type?: Maybe<String_Comparison_Exp>
  points?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "leaderboard_event" */
export enum Leaderboard_Event_Constraint {
  /** unique or primary key constraint on columns "event_hash" */
  LeaderboardEventsEventHashKey = 'leaderboard_events_event_hash_key',
  /** unique or primary key constraint on columns "id" */
  LeaderboardEventsPkey = 'leaderboard_events_pkey',
}

/** input type for incrementing numeric columns in table "leaderboard_event" */
export type Leaderboard_Event_Inc_Input = {
  points?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "leaderboard_event" */
export type Leaderboard_Event_Insert_Input = {
  actor?: Maybe<User_Obj_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  creator?: Maybe<User_Obj_Rel_Insert_Input>
  creator_id?: Maybe<Scalars['String']>
  emitted_at?: Maybe<Scalars['timestamptz']>
  event_hash?: Maybe<Scalars['String']>
  event_name?: Maybe<Leaderboard_Event_Name_Enum>
  id?: Maybe<Scalars['uuid']>
  identifier_id?: Maybe<Scalars['String']>
  identifier_type?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** Can be a user id in the database or an anonymous analytics uuid */
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Leaderboard_Event_Max_Fields = {
  __typename?: 'leaderboard_event_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  emitted_at?: Maybe<Scalars['timestamptz']>
  event_hash?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  identifier_id?: Maybe<Scalars['String']>
  identifier_type?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** Can be a user id in the database or an anonymous analytics uuid */
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Leaderboard_Event_Min_Fields = {
  __typename?: 'leaderboard_event_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  emitted_at?: Maybe<Scalars['timestamptz']>
  event_hash?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  identifier_id?: Maybe<Scalars['String']>
  identifier_type?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** Can be a user id in the database or an anonymous analytics uuid */
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "leaderboard_event" */
export type Leaderboard_Event_Mutation_Response = {
  __typename?: 'leaderboard_event_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Leaderboard_Event>
}

/** An enum table to hold all of the allowable leaderboard event names */
export type Leaderboard_Event_Name = {
  __typename?: 'leaderboard_event_name'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "leaderboard_event_name" */
export type Leaderboard_Event_Name_Aggregate = {
  __typename?: 'leaderboard_event_name_aggregate'
  aggregate?: Maybe<Leaderboard_Event_Name_Aggregate_Fields>
  nodes: Array<Leaderboard_Event_Name>
}

/** aggregate fields of "leaderboard_event_name" */
export type Leaderboard_Event_Name_Aggregate_Fields = {
  __typename?: 'leaderboard_event_name_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Leaderboard_Event_Name_Max_Fields>
  min?: Maybe<Leaderboard_Event_Name_Min_Fields>
}

/** aggregate fields of "leaderboard_event_name" */
export type Leaderboard_Event_Name_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Leaderboard_Event_Name_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "leaderboard_event_name". All fields are combined with a logical 'AND'. */
export type Leaderboard_Event_Name_Bool_Exp = {
  _and?: Maybe<Array<Leaderboard_Event_Name_Bool_Exp>>
  _not?: Maybe<Leaderboard_Event_Name_Bool_Exp>
  _or?: Maybe<Array<Leaderboard_Event_Name_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "leaderboard_event_name" */
export enum Leaderboard_Event_Name_Constraint {
  /** unique or primary key constraint on columns "value" */
  LeaderboardEventNamePkey = 'leaderboard_event_name_pkey',
}

export enum Leaderboard_Event_Name_Enum {
  AcceptsCollaborationInvite = 'accepts_collaboration_invite',
  AddsItemToCollaborativeCollection = 'adds_item_to_collaborative_collection',
  ClickedAddToCarrot = 'clicked_add_to_carrot',
  CollectionCollaborateCtaClick = 'collection_collaborate_cta_click',
  CommentsOnCartItem = 'comments_on_cart_item',
  CopiedShareUrl = 'copied_share_url',
  ExtensionInstall = 'extension_install',
  FollowsCollection = 'follows_collection',
  LikesACartItem = 'likes_a_cart_item',
  NewPaidAnnualSubscriber = 'new_paid_annual_subscriber',
  NewPaidMonthlySubscriber = 'new_paid_monthly_subscriber',
  NewSubscriber = 'new_subscriber',
  OpenedItem = 'opened_item',
  ViewedPdpModal = 'viewed_pdp_modal',
}

/** Boolean expression to compare columns of type "leaderboard_event_name_enum". All fields are combined with logical 'AND'. */
export type Leaderboard_Event_Name_Enum_Comparison_Exp = {
  _eq?: Maybe<Leaderboard_Event_Name_Enum>
  _in?: Maybe<Array<Leaderboard_Event_Name_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Leaderboard_Event_Name_Enum>
  _nin?: Maybe<Array<Leaderboard_Event_Name_Enum>>
}

/** input type for inserting data into table "leaderboard_event_name" */
export type Leaderboard_Event_Name_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Leaderboard_Event_Name_Max_Fields = {
  __typename?: 'leaderboard_event_name_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Leaderboard_Event_Name_Min_Fields = {
  __typename?: 'leaderboard_event_name_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "leaderboard_event_name" */
export type Leaderboard_Event_Name_Mutation_Response = {
  __typename?: 'leaderboard_event_name_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Leaderboard_Event_Name>
}

/** on_conflict condition type for table "leaderboard_event_name" */
export type Leaderboard_Event_Name_On_Conflict = {
  constraint: Leaderboard_Event_Name_Constraint
  update_columns?: Array<Leaderboard_Event_Name_Update_Column>
  where?: Maybe<Leaderboard_Event_Name_Bool_Exp>
}

/** Ordering options when selecting data from "leaderboard_event_name". */
export type Leaderboard_Event_Name_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: leaderboard_event_name */
export type Leaderboard_Event_Name_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "leaderboard_event_name" */
export enum Leaderboard_Event_Name_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "leaderboard_event_name" */
export type Leaderboard_Event_Name_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "leaderboard_event_name" */
export type Leaderboard_Event_Name_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leaderboard_Event_Name_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Leaderboard_Event_Name_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "leaderboard_event_name" */
export enum Leaderboard_Event_Name_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Leaderboard_Event_Name_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Leaderboard_Event_Name_Set_Input>
  /** filter the rows which have to be updated */
  where: Leaderboard_Event_Name_Bool_Exp
}

/** on_conflict condition type for table "leaderboard_event" */
export type Leaderboard_Event_On_Conflict = {
  constraint: Leaderboard_Event_Constraint
  update_columns?: Array<Leaderboard_Event_Update_Column>
  where?: Maybe<Leaderboard_Event_Bool_Exp>
}

/** Ordering options when selecting data from "leaderboard_event". */
export type Leaderboard_Event_Order_By = {
  actor?: Maybe<User_Order_By>
  created_at?: Maybe<Order_By>
  creator?: Maybe<User_Order_By>
  creator_id?: Maybe<Order_By>
  emitted_at?: Maybe<Order_By>
  event_hash?: Maybe<Order_By>
  event_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  identifier_id?: Maybe<Order_By>
  identifier_type?: Maybe<Order_By>
  points?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: leaderboard_event */
export type Leaderboard_Event_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "leaderboard_event" */
export enum Leaderboard_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  EmittedAt = 'emitted_at',
  /** column name */
  EventHash = 'event_hash',
  /** column name */
  EventName = 'event_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdentifierId = 'identifier_id',
  /** column name */
  IdentifierType = 'identifier_type',
  /** column name */
  Points = 'points',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "leaderboard_event" */
export type Leaderboard_Event_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  emitted_at?: Maybe<Scalars['timestamptz']>
  event_hash?: Maybe<Scalars['String']>
  event_name?: Maybe<Leaderboard_Event_Name_Enum>
  id?: Maybe<Scalars['uuid']>
  identifier_id?: Maybe<Scalars['String']>
  identifier_type?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** Can be a user id in the database or an anonymous analytics uuid */
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Leaderboard_Event_Stddev_Fields = {
  __typename?: 'leaderboard_event_stddev_fields'
  points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Leaderboard_Event_Stddev_Pop_Fields = {
  __typename?: 'leaderboard_event_stddev_pop_fields'
  points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Leaderboard_Event_Stddev_Samp_Fields = {
  __typename?: 'leaderboard_event_stddev_samp_fields'
  points?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "leaderboard_event" */
export type Leaderboard_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leaderboard_Event_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Leaderboard_Event_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  emitted_at?: Maybe<Scalars['timestamptz']>
  event_hash?: Maybe<Scalars['String']>
  event_name?: Maybe<Leaderboard_Event_Name_Enum>
  id?: Maybe<Scalars['uuid']>
  identifier_id?: Maybe<Scalars['String']>
  identifier_type?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** Can be a user id in the database or an anonymous analytics uuid */
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Leaderboard_Event_Sum_Fields = {
  __typename?: 'leaderboard_event_sum_fields'
  points?: Maybe<Scalars['Int']>
}

/** update columns of table "leaderboard_event" */
export enum Leaderboard_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  EmittedAt = 'emitted_at',
  /** column name */
  EventHash = 'event_hash',
  /** column name */
  EventName = 'event_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdentifierId = 'identifier_id',
  /** column name */
  IdentifierType = 'identifier_type',
  /** column name */
  Points = 'points',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Leaderboard_Event_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Leaderboard_Event_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Leaderboard_Event_Set_Input>
  /** filter the rows which have to be updated */
  where: Leaderboard_Event_Bool_Exp
}

/** aggregate var_pop on columns */
export type Leaderboard_Event_Var_Pop_Fields = {
  __typename?: 'leaderboard_event_var_pop_fields'
  points?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Leaderboard_Event_Var_Samp_Fields = {
  __typename?: 'leaderboard_event_var_samp_fields'
  points?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Leaderboard_Event_Variance_Fields = {
  __typename?: 'leaderboard_event_variance_fields'
  points?: Maybe<Scalars['Float']>
}

/** Enum of different user link types */
export type Link_Type = {
  __typename?: 'link_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "link_type" */
export type Link_Type_Aggregate = {
  __typename?: 'link_type_aggregate'
  aggregate?: Maybe<Link_Type_Aggregate_Fields>
  nodes: Array<Link_Type>
}

/** aggregate fields of "link_type" */
export type Link_Type_Aggregate_Fields = {
  __typename?: 'link_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Link_Type_Max_Fields>
  min?: Maybe<Link_Type_Min_Fields>
}

/** aggregate fields of "link_type" */
export type Link_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Link_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "link_type". All fields are combined with a logical 'AND'. */
export type Link_Type_Bool_Exp = {
  _and?: Maybe<Array<Link_Type_Bool_Exp>>
  _not?: Maybe<Link_Type_Bool_Exp>
  _or?: Maybe<Array<Link_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "link_type" */
export enum Link_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  LinkTypePkey = 'link_type_pkey',
}

export enum Link_Type_Enum {
  /** Instagram */
  Instagram = 'instagram',
  /** Other */
  Other = 'other',
  /** TikTok */
  Tiktok = 'tiktok',
  /** Twitter */
  Twitter = 'twitter',
  /** YouTube */
  Youtube = 'youtube',
}

/** Boolean expression to compare columns of type "link_type_enum". All fields are combined with logical 'AND'. */
export type Link_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Link_Type_Enum>
  _in?: Maybe<Array<Link_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Link_Type_Enum>
  _nin?: Maybe<Array<Link_Type_Enum>>
}

/** input type for inserting data into table "link_type" */
export type Link_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Link_Type_Max_Fields = {
  __typename?: 'link_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Link_Type_Min_Fields = {
  __typename?: 'link_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "link_type" */
export type Link_Type_Mutation_Response = {
  __typename?: 'link_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Link_Type>
}

/** input type for inserting object relation for remote table "link_type" */
export type Link_Type_Obj_Rel_Insert_Input = {
  data: Link_Type_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Link_Type_On_Conflict>
}

/** on_conflict condition type for table "link_type" */
export type Link_Type_On_Conflict = {
  constraint: Link_Type_Constraint
  update_columns?: Array<Link_Type_Update_Column>
  where?: Maybe<Link_Type_Bool_Exp>
}

/** Ordering options when selecting data from "link_type". */
export type Link_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: link_type */
export type Link_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "link_type" */
export enum Link_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "link_type" */
export type Link_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "link_type" */
export type Link_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Link_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Link_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "link_type" */
export enum Link_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Link_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Link_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Link_Type_Bool_Exp
}

/** A polymorphic join table to associate media to different objects in the database */
export type Media_Enabled_Item = {
  __typename?: 'media_enabled_item'
  /** An array relationship */
  associated_media: Array<Media_Items>
  /** An aggregate relationship */
  associated_media_aggregate: Media_Items_Aggregate
  item_id: Scalars['uuid']
  item_type: Media_Item_Type_Enum
  /** An object relationship */
  media_item_type: Media_Item_Type
}

/** A polymorphic join table to associate media to different objects in the database */
export type Media_Enabled_ItemAssociated_MediaArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

/** A polymorphic join table to associate media to different objects in the database */
export type Media_Enabled_ItemAssociated_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

/** aggregated selection of "media_enabled_item" */
export type Media_Enabled_Item_Aggregate = {
  __typename?: 'media_enabled_item_aggregate'
  aggregate?: Maybe<Media_Enabled_Item_Aggregate_Fields>
  nodes: Array<Media_Enabled_Item>
}

/** aggregate fields of "media_enabled_item" */
export type Media_Enabled_Item_Aggregate_Fields = {
  __typename?: 'media_enabled_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Media_Enabled_Item_Max_Fields>
  min?: Maybe<Media_Enabled_Item_Min_Fields>
}

/** aggregate fields of "media_enabled_item" */
export type Media_Enabled_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Enabled_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "media_enabled_item". All fields are combined with a logical 'AND'. */
export type Media_Enabled_Item_Bool_Exp = {
  _and?: Maybe<Array<Media_Enabled_Item_Bool_Exp>>
  _not?: Maybe<Media_Enabled_Item_Bool_Exp>
  _or?: Maybe<Array<Media_Enabled_Item_Bool_Exp>>
  associated_media?: Maybe<Media_Items_Bool_Exp>
  associated_media_aggregate?: Maybe<Media_Items_Aggregate_Bool_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<Media_Item_Type_Enum_Comparison_Exp>
  media_item_type?: Maybe<Media_Item_Type_Bool_Exp>
}

/** unique or primary key constraints on table "media_enabled_item" */
export enum Media_Enabled_Item_Constraint {
  /** unique or primary key constraint on columns "item_id", "item_type" */
  MediaEnabledItemPkey = 'media_enabled_item_pkey',
}

/** input type for inserting data into table "media_enabled_item" */
export type Media_Enabled_Item_Insert_Input = {
  associated_media?: Maybe<Media_Items_Arr_Rel_Insert_Input>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Media_Item_Type_Enum>
  media_item_type?: Maybe<Media_Item_Type_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Media_Enabled_Item_Max_Fields = {
  __typename?: 'media_enabled_item_max_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type Media_Enabled_Item_Min_Fields = {
  __typename?: 'media_enabled_item_min_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "media_enabled_item" */
export type Media_Enabled_Item_Mutation_Response = {
  __typename?: 'media_enabled_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Media_Enabled_Item>
}

/** input type for inserting object relation for remote table "media_enabled_item" */
export type Media_Enabled_Item_Obj_Rel_Insert_Input = {
  data: Media_Enabled_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Media_Enabled_Item_On_Conflict>
}

/** on_conflict condition type for table "media_enabled_item" */
export type Media_Enabled_Item_On_Conflict = {
  constraint: Media_Enabled_Item_Constraint
  update_columns?: Array<Media_Enabled_Item_Update_Column>
  where?: Maybe<Media_Enabled_Item_Bool_Exp>
}

/** Ordering options when selecting data from "media_enabled_item". */
export type Media_Enabled_Item_Order_By = {
  associated_media_aggregate?: Maybe<Media_Items_Aggregate_Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  media_item_type?: Maybe<Media_Item_Type_Order_By>
}

/** primary key columns input for table: media_enabled_item */
export type Media_Enabled_Item_Pk_Columns_Input = {
  item_id: Scalars['uuid']
  item_type: Media_Item_Type_Enum
}

/** select columns of table "media_enabled_item" */
export enum Media_Enabled_Item_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

/** input type for updating data in table "media_enabled_item" */
export type Media_Enabled_Item_Set_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Media_Item_Type_Enum>
}

/** Streaming cursor of the table "media_enabled_item" */
export type Media_Enabled_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Enabled_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Media_Enabled_Item_Stream_Cursor_Value_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Media_Item_Type_Enum>
}

/** update columns of table "media_enabled_item" */
export enum Media_Enabled_Item_Update_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

export type Media_Enabled_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Media_Enabled_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Media_Enabled_Item_Bool_Exp
}

/** columns and relationships of "media_item" */
export type Media_Item = {
  __typename?: 'media_item'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  media_item_type: Media_Type
  /** An array relationship */
  media_items: Array<Media_Items>
  /** An aggregate relationship */
  media_items_aggregate: Media_Items_Aggregate
  media_type: Media_Type_Enum
  media_url: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "media_item" */
export type Media_ItemMedia_ItemsArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

/** columns and relationships of "media_item" */
export type Media_ItemMedia_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

/** aggregated selection of "media_item" */
export type Media_Item_Aggregate = {
  __typename?: 'media_item_aggregate'
  aggregate?: Maybe<Media_Item_Aggregate_Fields>
  nodes: Array<Media_Item>
}

/** aggregate fields of "media_item" */
export type Media_Item_Aggregate_Fields = {
  __typename?: 'media_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Media_Item_Max_Fields>
  min?: Maybe<Media_Item_Min_Fields>
}

/** aggregate fields of "media_item" */
export type Media_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "media_item". All fields are combined with a logical 'AND'. */
export type Media_Item_Bool_Exp = {
  _and?: Maybe<Array<Media_Item_Bool_Exp>>
  _not?: Maybe<Media_Item_Bool_Exp>
  _or?: Maybe<Array<Media_Item_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  media_item_type?: Maybe<Media_Type_Bool_Exp>
  media_items?: Maybe<Media_Items_Bool_Exp>
  media_items_aggregate?: Maybe<Media_Items_Aggregate_Bool_Exp>
  media_type?: Maybe<Media_Type_Enum_Comparison_Exp>
  media_url?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "media_item" */
export enum Media_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  MediaItemPkey = 'media_item_pkey',
}

/** input type for inserting data into table "media_item" */
export type Media_Item_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  media_item_type?: Maybe<Media_Type_Obj_Rel_Insert_Input>
  media_items?: Maybe<Media_Items_Arr_Rel_Insert_Input>
  media_type?: Maybe<Media_Type_Enum>
  media_url?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Media_Item_Max_Fields = {
  __typename?: 'media_item_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  media_url?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Media_Item_Min_Fields = {
  __typename?: 'media_item_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  media_url?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "media_item" */
export type Media_Item_Mutation_Response = {
  __typename?: 'media_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Media_Item>
}

/** input type for inserting object relation for remote table "media_item" */
export type Media_Item_Obj_Rel_Insert_Input = {
  data: Media_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Media_Item_On_Conflict>
}

/** on_conflict condition type for table "media_item" */
export type Media_Item_On_Conflict = {
  constraint: Media_Item_Constraint
  update_columns?: Array<Media_Item_Update_Column>
  where?: Maybe<Media_Item_Bool_Exp>
}

/** Ordering options when selecting data from "media_item". */
export type Media_Item_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  media_item_type?: Maybe<Media_Type_Order_By>
  media_items_aggregate?: Maybe<Media_Items_Aggregate_Order_By>
  media_type?: Maybe<Order_By>
  media_url?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: media_item */
export type Media_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "media_item" */
export enum Media_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MediaType = 'media_type',
  /** column name */
  MediaUrl = 'media_url',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "media_item" */
export type Media_Item_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  media_type?: Maybe<Media_Type_Enum>
  media_url?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "media_item" */
export type Media_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Media_Item_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  media_type?: Maybe<Media_Type_Enum>
  media_url?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "media_item_type" */
export type Media_Item_Type = {
  __typename?: 'media_item_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "media_item_type" */
export type Media_Item_Type_Aggregate = {
  __typename?: 'media_item_type_aggregate'
  aggregate?: Maybe<Media_Item_Type_Aggregate_Fields>
  nodes: Array<Media_Item_Type>
}

/** aggregate fields of "media_item_type" */
export type Media_Item_Type_Aggregate_Fields = {
  __typename?: 'media_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Media_Item_Type_Max_Fields>
  min?: Maybe<Media_Item_Type_Min_Fields>
}

/** aggregate fields of "media_item_type" */
export type Media_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "media_item_type". All fields are combined with a logical 'AND'. */
export type Media_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Media_Item_Type_Bool_Exp>>
  _not?: Maybe<Media_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Media_Item_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "media_item_type" */
export enum Media_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  MediaItemTypePkey = 'media_item_type_pkey',
}

export enum Media_Item_Type_Enum {
  Collection = 'collection',
}

/** Boolean expression to compare columns of type "media_item_type_enum". All fields are combined with logical 'AND'. */
export type Media_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Media_Item_Type_Enum>
  _in?: Maybe<Array<Media_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Media_Item_Type_Enum>
  _nin?: Maybe<Array<Media_Item_Type_Enum>>
}

/** input type for inserting data into table "media_item_type" */
export type Media_Item_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Media_Item_Type_Max_Fields = {
  __typename?: 'media_item_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Media_Item_Type_Min_Fields = {
  __typename?: 'media_item_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "media_item_type" */
export type Media_Item_Type_Mutation_Response = {
  __typename?: 'media_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Media_Item_Type>
}

/** input type for inserting object relation for remote table "media_item_type" */
export type Media_Item_Type_Obj_Rel_Insert_Input = {
  data: Media_Item_Type_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Media_Item_Type_On_Conflict>
}

/** on_conflict condition type for table "media_item_type" */
export type Media_Item_Type_On_Conflict = {
  constraint: Media_Item_Type_Constraint
  update_columns?: Array<Media_Item_Type_Update_Column>
  where?: Maybe<Media_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "media_item_type". */
export type Media_Item_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: media_item_type */
export type Media_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "media_item_type" */
export enum Media_Item_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "media_item_type" */
export type Media_Item_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "media_item_type" */
export type Media_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Media_Item_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "media_item_type" */
export enum Media_Item_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Media_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Media_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Media_Item_Type_Bool_Exp
}

/** update columns of table "media_item" */
export enum Media_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MediaType = 'media_type',
  /** column name */
  MediaUrl = 'media_url',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Media_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Media_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Media_Item_Bool_Exp
}

/** Table to hold media item polymorphic relationships */
export type Media_Items = {
  __typename?: 'media_items'
  /** An object relationship */
  media: Media_Item
  /** An object relationship */
  media_enabled_item?: Maybe<Media_Enabled_Item>
  media_enabled_item_id: Scalars['uuid']
  media_enabled_item_type: Scalars['String']
  media_id: Scalars['uuid']
}

/** aggregated selection of "media_items" */
export type Media_Items_Aggregate = {
  __typename?: 'media_items_aggregate'
  aggregate?: Maybe<Media_Items_Aggregate_Fields>
  nodes: Array<Media_Items>
}

export type Media_Items_Aggregate_Bool_Exp = {
  count?: Maybe<Media_Items_Aggregate_Bool_Exp_Count>
}

export type Media_Items_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Media_Items_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Media_Items_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "media_items" */
export type Media_Items_Aggregate_Fields = {
  __typename?: 'media_items_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Media_Items_Max_Fields>
  min?: Maybe<Media_Items_Min_Fields>
}

/** aggregate fields of "media_items" */
export type Media_Items_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Items_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "media_items" */
export type Media_Items_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Media_Items_Max_Order_By>
  min?: Maybe<Media_Items_Min_Order_By>
}

/** input type for inserting array relation for remote table "media_items" */
export type Media_Items_Arr_Rel_Insert_Input = {
  data: Array<Media_Items_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Media_Items_On_Conflict>
}

/** Boolean expression to filter rows from the table "media_items". All fields are combined with a logical 'AND'. */
export type Media_Items_Bool_Exp = {
  _and?: Maybe<Array<Media_Items_Bool_Exp>>
  _not?: Maybe<Media_Items_Bool_Exp>
  _or?: Maybe<Array<Media_Items_Bool_Exp>>
  media?: Maybe<Media_Item_Bool_Exp>
  media_enabled_item?: Maybe<Media_Enabled_Item_Bool_Exp>
  media_enabled_item_id?: Maybe<Uuid_Comparison_Exp>
  media_enabled_item_type?: Maybe<String_Comparison_Exp>
  media_id?: Maybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "media_items" */
export enum Media_Items_Constraint {
  /** unique or primary key constraint on columns "media_enabled_item_id", "media_enabled_item_type", "media_id" */
  MediaItemsPkey = 'media_items_pkey',
}

/** input type for inserting data into table "media_items" */
export type Media_Items_Insert_Input = {
  media?: Maybe<Media_Item_Obj_Rel_Insert_Input>
  media_enabled_item?: Maybe<Media_Enabled_Item_Obj_Rel_Insert_Input>
  media_enabled_item_id?: Maybe<Scalars['uuid']>
  media_enabled_item_type?: Maybe<Scalars['String']>
  media_id?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type Media_Items_Max_Fields = {
  __typename?: 'media_items_max_fields'
  media_enabled_item_id?: Maybe<Scalars['uuid']>
  media_enabled_item_type?: Maybe<Scalars['String']>
  media_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "media_items" */
export type Media_Items_Max_Order_By = {
  media_enabled_item_id?: Maybe<Order_By>
  media_enabled_item_type?: Maybe<Order_By>
  media_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Media_Items_Min_Fields = {
  __typename?: 'media_items_min_fields'
  media_enabled_item_id?: Maybe<Scalars['uuid']>
  media_enabled_item_type?: Maybe<Scalars['String']>
  media_id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "media_items" */
export type Media_Items_Min_Order_By = {
  media_enabled_item_id?: Maybe<Order_By>
  media_enabled_item_type?: Maybe<Order_By>
  media_id?: Maybe<Order_By>
}

/** response of any mutation on the table "media_items" */
export type Media_Items_Mutation_Response = {
  __typename?: 'media_items_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Media_Items>
}

/** on_conflict condition type for table "media_items" */
export type Media_Items_On_Conflict = {
  constraint: Media_Items_Constraint
  update_columns?: Array<Media_Items_Update_Column>
  where?: Maybe<Media_Items_Bool_Exp>
}

/** Ordering options when selecting data from "media_items". */
export type Media_Items_Order_By = {
  media?: Maybe<Media_Item_Order_By>
  media_enabled_item?: Maybe<Media_Enabled_Item_Order_By>
  media_enabled_item_id?: Maybe<Order_By>
  media_enabled_item_type?: Maybe<Order_By>
  media_id?: Maybe<Order_By>
}

/** primary key columns input for table: media_items */
export type Media_Items_Pk_Columns_Input = {
  media_enabled_item_id: Scalars['uuid']
  media_enabled_item_type: Scalars['String']
  media_id: Scalars['uuid']
}

/** select columns of table "media_items" */
export enum Media_Items_Select_Column {
  /** column name */
  MediaEnabledItemId = 'media_enabled_item_id',
  /** column name */
  MediaEnabledItemType = 'media_enabled_item_type',
  /** column name */
  MediaId = 'media_id',
}

/** input type for updating data in table "media_items" */
export type Media_Items_Set_Input = {
  media_enabled_item_id?: Maybe<Scalars['uuid']>
  media_enabled_item_type?: Maybe<Scalars['String']>
  media_id?: Maybe<Scalars['uuid']>
}

/** Streaming cursor of the table "media_items" */
export type Media_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Items_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Media_Items_Stream_Cursor_Value_Input = {
  media_enabled_item_id?: Maybe<Scalars['uuid']>
  media_enabled_item_type?: Maybe<Scalars['String']>
  media_id?: Maybe<Scalars['uuid']>
}

/** update columns of table "media_items" */
export enum Media_Items_Update_Column {
  /** column name */
  MediaEnabledItemId = 'media_enabled_item_id',
  /** column name */
  MediaEnabledItemType = 'media_enabled_item_type',
  /** column name */
  MediaId = 'media_id',
}

export type Media_Items_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Media_Items_Set_Input>
  /** filter the rows which have to be updated */
  where: Media_Items_Bool_Exp
}

/** columns and relationships of "media_type" */
export type Media_Type = {
  __typename?: 'media_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "media_type" */
export type Media_Type_Aggregate = {
  __typename?: 'media_type_aggregate'
  aggregate?: Maybe<Media_Type_Aggregate_Fields>
  nodes: Array<Media_Type>
}

/** aggregate fields of "media_type" */
export type Media_Type_Aggregate_Fields = {
  __typename?: 'media_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Media_Type_Max_Fields>
  min?: Maybe<Media_Type_Min_Fields>
}

/** aggregate fields of "media_type" */
export type Media_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "media_type". All fields are combined with a logical 'AND'. */
export type Media_Type_Bool_Exp = {
  _and?: Maybe<Array<Media_Type_Bool_Exp>>
  _not?: Maybe<Media_Type_Bool_Exp>
  _or?: Maybe<Array<Media_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "media_type" */
export enum Media_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  MediaTypePkey = 'media_type_pkey',
}

export enum Media_Type_Enum {
  /** Instagram Media */
  Instagram = 'instagram',
  /** TikTok Video */
  Tiktok = 'tiktok',
  /** Twitch Video */
  Twitch = 'twitch',
  /** YouTube Video */
  Youtube = 'youtube',
  /** YouTube Short media type */
  YoutubeShort = 'youtube_short',
}

/** Boolean expression to compare columns of type "media_type_enum". All fields are combined with logical 'AND'. */
export type Media_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Media_Type_Enum>
  _in?: Maybe<Array<Media_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Media_Type_Enum>
  _nin?: Maybe<Array<Media_Type_Enum>>
}

/** input type for inserting data into table "media_type" */
export type Media_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Media_Type_Max_Fields = {
  __typename?: 'media_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Media_Type_Min_Fields = {
  __typename?: 'media_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "media_type" */
export type Media_Type_Mutation_Response = {
  __typename?: 'media_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Media_Type>
}

/** input type for inserting object relation for remote table "media_type" */
export type Media_Type_Obj_Rel_Insert_Input = {
  data: Media_Type_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Media_Type_On_Conflict>
}

/** on_conflict condition type for table "media_type" */
export type Media_Type_On_Conflict = {
  constraint: Media_Type_Constraint
  update_columns?: Array<Media_Type_Update_Column>
  where?: Maybe<Media_Type_Bool_Exp>
}

/** Ordering options when selecting data from "media_type". */
export type Media_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: media_type */
export type Media_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "media_type" */
export enum Media_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "media_type" */
export type Media_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "media_type" */
export type Media_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Media_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "media_type" */
export enum Media_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Media_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Media_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Media_Type_Bool_Exp
}

export type Mention_Search_Users_Args = {
  page_num?: Maybe<Scalars['Int']>
  page_size?: Maybe<Scalars['Int']>
  search_string?: Maybe<Scalars['String']>
}

/** Table used to display and adjust content shown on the home tab of the mobile app */
export type Mobile_App_Home = {
  __typename?: 'mobile_app_home'
  block_enabled?: Maybe<Scalars['Boolean']>
  block_image?: Maybe<Scalars['String']>
  block_link?: Maybe<Scalars['String']>
  block_type: Scalars['String']
  created_at: Scalars['timestamptz']
  deep_link?: Maybe<Scalars['String']>
  id: Scalars['Int']
  link_type?: Maybe<Scalars['String']>
  link_type_value?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  parent: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "mobile_app_home" */
export type Mobile_App_Home_Aggregate = {
  __typename?: 'mobile_app_home_aggregate'
  aggregate?: Maybe<Mobile_App_Home_Aggregate_Fields>
  nodes: Array<Mobile_App_Home>
}

/** aggregate fields of "mobile_app_home" */
export type Mobile_App_Home_Aggregate_Fields = {
  __typename?: 'mobile_app_home_aggregate_fields'
  avg?: Maybe<Mobile_App_Home_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Mobile_App_Home_Max_Fields>
  min?: Maybe<Mobile_App_Home_Min_Fields>
  stddev?: Maybe<Mobile_App_Home_Stddev_Fields>
  stddev_pop?: Maybe<Mobile_App_Home_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Mobile_App_Home_Stddev_Samp_Fields>
  sum?: Maybe<Mobile_App_Home_Sum_Fields>
  var_pop?: Maybe<Mobile_App_Home_Var_Pop_Fields>
  var_samp?: Maybe<Mobile_App_Home_Var_Samp_Fields>
  variance?: Maybe<Mobile_App_Home_Variance_Fields>
}

/** aggregate fields of "mobile_app_home" */
export type Mobile_App_Home_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mobile_App_Home_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Mobile_App_Home_Avg_Fields = {
  __typename?: 'mobile_app_home_avg_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "mobile_app_home". All fields are combined with a logical 'AND'. */
export type Mobile_App_Home_Bool_Exp = {
  _and?: Maybe<Array<Mobile_App_Home_Bool_Exp>>
  _not?: Maybe<Mobile_App_Home_Bool_Exp>
  _or?: Maybe<Array<Mobile_App_Home_Bool_Exp>>
  block_enabled?: Maybe<Boolean_Comparison_Exp>
  block_image?: Maybe<String_Comparison_Exp>
  block_link?: Maybe<String_Comparison_Exp>
  block_type?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deep_link?: Maybe<String_Comparison_Exp>
  id?: Maybe<Int_Comparison_Exp>
  link_type?: Maybe<String_Comparison_Exp>
  link_type_value?: Maybe<String_Comparison_Exp>
  note?: Maybe<String_Comparison_Exp>
  order?: Maybe<Int_Comparison_Exp>
  parent?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "mobile_app_home" */
export enum Mobile_App_Home_Constraint {
  /** unique or primary key constraint on columns "id" */
  MobileAppHomePkey = 'mobile_app_home_pkey',
}

/** input type for incrementing numeric columns in table "mobile_app_home" */
export type Mobile_App_Home_Inc_Input = {
  id?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "mobile_app_home" */
export type Mobile_App_Home_Insert_Input = {
  block_enabled?: Maybe<Scalars['Boolean']>
  block_image?: Maybe<Scalars['String']>
  block_link?: Maybe<Scalars['String']>
  block_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deep_link?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  link_type?: Maybe<Scalars['String']>
  link_type_value?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  parent?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Mobile_App_Home_Max_Fields = {
  __typename?: 'mobile_app_home_max_fields'
  block_image?: Maybe<Scalars['String']>
  block_link?: Maybe<Scalars['String']>
  block_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deep_link?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  link_type?: Maybe<Scalars['String']>
  link_type_value?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Mobile_App_Home_Min_Fields = {
  __typename?: 'mobile_app_home_min_fields'
  block_image?: Maybe<Scalars['String']>
  block_link?: Maybe<Scalars['String']>
  block_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deep_link?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  link_type?: Maybe<Scalars['String']>
  link_type_value?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "mobile_app_home" */
export type Mobile_App_Home_Mutation_Response = {
  __typename?: 'mobile_app_home_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_App_Home>
}

/** on_conflict condition type for table "mobile_app_home" */
export type Mobile_App_Home_On_Conflict = {
  constraint: Mobile_App_Home_Constraint
  update_columns?: Array<Mobile_App_Home_Update_Column>
  where?: Maybe<Mobile_App_Home_Bool_Exp>
}

/** Ordering options when selecting data from "mobile_app_home". */
export type Mobile_App_Home_Order_By = {
  block_enabled?: Maybe<Order_By>
  block_image?: Maybe<Order_By>
  block_link?: Maybe<Order_By>
  block_type?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deep_link?: Maybe<Order_By>
  id?: Maybe<Order_By>
  link_type?: Maybe<Order_By>
  link_type_value?: Maybe<Order_By>
  note?: Maybe<Order_By>
  order?: Maybe<Order_By>
  parent?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: mobile_app_home */
export type Mobile_App_Home_Pk_Columns_Input = {
  id: Scalars['Int']
}

/** select columns of table "mobile_app_home" */
export enum Mobile_App_Home_Select_Column {
  /** column name */
  BlockEnabled = 'block_enabled',
  /** column name */
  BlockImage = 'block_image',
  /** column name */
  BlockLink = 'block_link',
  /** column name */
  BlockType = 'block_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeepLink = 'deep_link',
  /** column name */
  Id = 'id',
  /** column name */
  LinkType = 'link_type',
  /** column name */
  LinkTypeValue = 'link_type_value',
  /** column name */
  Note = 'note',
  /** column name */
  Order = 'order',
  /** column name */
  Parent = 'parent',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "mobile_app_home" */
export type Mobile_App_Home_Set_Input = {
  block_enabled?: Maybe<Scalars['Boolean']>
  block_image?: Maybe<Scalars['String']>
  block_link?: Maybe<Scalars['String']>
  block_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deep_link?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  link_type?: Maybe<Scalars['String']>
  link_type_value?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  parent?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Mobile_App_Home_Stddev_Fields = {
  __typename?: 'mobile_app_home_stddev_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Mobile_App_Home_Stddev_Pop_Fields = {
  __typename?: 'mobile_app_home_stddev_pop_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Mobile_App_Home_Stddev_Samp_Fields = {
  __typename?: 'mobile_app_home_stddev_samp_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "mobile_app_home" */
export type Mobile_App_Home_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_App_Home_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Mobile_App_Home_Stream_Cursor_Value_Input = {
  block_enabled?: Maybe<Scalars['Boolean']>
  block_image?: Maybe<Scalars['String']>
  block_link?: Maybe<Scalars['String']>
  block_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deep_link?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  link_type?: Maybe<Scalars['String']>
  link_type_value?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  parent?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Mobile_App_Home_Sum_Fields = {
  __typename?: 'mobile_app_home_sum_fields'
  id?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Int']>
}

/** update columns of table "mobile_app_home" */
export enum Mobile_App_Home_Update_Column {
  /** column name */
  BlockEnabled = 'block_enabled',
  /** column name */
  BlockImage = 'block_image',
  /** column name */
  BlockLink = 'block_link',
  /** column name */
  BlockType = 'block_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeepLink = 'deep_link',
  /** column name */
  Id = 'id',
  /** column name */
  LinkType = 'link_type',
  /** column name */
  LinkTypeValue = 'link_type_value',
  /** column name */
  Note = 'note',
  /** column name */
  Order = 'order',
  /** column name */
  Parent = 'parent',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Mobile_App_Home_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Mobile_App_Home_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Mobile_App_Home_Set_Input>
  /** filter the rows which have to be updated */
  where: Mobile_App_Home_Bool_Exp
}

/** aggregate var_pop on columns */
export type Mobile_App_Home_Var_Pop_Fields = {
  __typename?: 'mobile_app_home_var_pop_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Mobile_App_Home_Var_Samp_Fields = {
  __typename?: 'mobile_app_home_var_samp_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Mobile_App_Home_Variance_Fields = {
  __typename?: 'mobile_app_home_variance_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** columns and relationships of "mobile_home" */
export type Mobile_Home = {
  __typename?: 'mobile_home'
  active: Scalars['Boolean']
  asset_url: Scalars['String']
  created_at: Scalars['timestamptz']
  enabled: Scalars['Boolean']
  id: Scalars['uuid']
  link_url?: Maybe<Scalars['String']>
  order: Scalars['Int']
  type?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "mobile_home" */
export type Mobile_Home_Aggregate = {
  __typename?: 'mobile_home_aggregate'
  aggregate?: Maybe<Mobile_Home_Aggregate_Fields>
  nodes: Array<Mobile_Home>
}

/** aggregate fields of "mobile_home" */
export type Mobile_Home_Aggregate_Fields = {
  __typename?: 'mobile_home_aggregate_fields'
  avg?: Maybe<Mobile_Home_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Mobile_Home_Max_Fields>
  min?: Maybe<Mobile_Home_Min_Fields>
  stddev?: Maybe<Mobile_Home_Stddev_Fields>
  stddev_pop?: Maybe<Mobile_Home_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Mobile_Home_Stddev_Samp_Fields>
  sum?: Maybe<Mobile_Home_Sum_Fields>
  var_pop?: Maybe<Mobile_Home_Var_Pop_Fields>
  var_samp?: Maybe<Mobile_Home_Var_Samp_Fields>
  variance?: Maybe<Mobile_Home_Variance_Fields>
}

/** aggregate fields of "mobile_home" */
export type Mobile_Home_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mobile_Home_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Mobile_Home_Avg_Fields = {
  __typename?: 'mobile_home_avg_fields'
  order?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "mobile_home". All fields are combined with a logical 'AND'. */
export type Mobile_Home_Bool_Exp = {
  _and?: Maybe<Array<Mobile_Home_Bool_Exp>>
  _not?: Maybe<Mobile_Home_Bool_Exp>
  _or?: Maybe<Array<Mobile_Home_Bool_Exp>>
  active?: Maybe<Boolean_Comparison_Exp>
  asset_url?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  enabled?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  link_url?: Maybe<String_Comparison_Exp>
  order?: Maybe<Int_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "mobile_home" */
export enum Mobile_Home_Constraint {
  /** unique or primary key constraint on columns "asset_url", "link_url" */
  MobileHomeAssetUrlLinkUrlKey = 'mobile_home_asset_url_link_url_key',
  /** unique or primary key constraint on columns "asset_url", "type", "link_url" */
  MobileHomeAssetUrlLinkUrlTypeKey = 'mobile_home_asset_url_link_url_type_key',
  /** unique or primary key constraint on columns "id" */
  MobileHomePkey = 'mobile_home_pkey',
}

/** input type for incrementing numeric columns in table "mobile_home" */
export type Mobile_Home_Inc_Input = {
  order?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "mobile_home" */
export type Mobile_Home_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>
  asset_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  link_url?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Mobile_Home_Max_Fields = {
  __typename?: 'mobile_home_max_fields'
  asset_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link_url?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Mobile_Home_Min_Fields = {
  __typename?: 'mobile_home_min_fields'
  asset_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link_url?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "mobile_home" */
export type Mobile_Home_Mutation_Response = {
  __typename?: 'mobile_home_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Home>
}

/** on_conflict condition type for table "mobile_home" */
export type Mobile_Home_On_Conflict = {
  constraint: Mobile_Home_Constraint
  update_columns?: Array<Mobile_Home_Update_Column>
  where?: Maybe<Mobile_Home_Bool_Exp>
}

/** Ordering options when selecting data from "mobile_home". */
export type Mobile_Home_Order_By = {
  active?: Maybe<Order_By>
  asset_url?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  enabled?: Maybe<Order_By>
  id?: Maybe<Order_By>
  link_url?: Maybe<Order_By>
  order?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: mobile_home */
export type Mobile_Home_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "mobile_home" */
export enum Mobile_Home_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AssetUrl = 'asset_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  LinkUrl = 'link_url',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "mobile_home" */
export type Mobile_Home_Set_Input = {
  active?: Maybe<Scalars['Boolean']>
  asset_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  link_url?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Mobile_Home_Stddev_Fields = {
  __typename?: 'mobile_home_stddev_fields'
  order?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Mobile_Home_Stddev_Pop_Fields = {
  __typename?: 'mobile_home_stddev_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Mobile_Home_Stddev_Samp_Fields = {
  __typename?: 'mobile_home_stddev_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "mobile_home" */
export type Mobile_Home_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Home_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Mobile_Home_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>
  asset_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  link_url?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Mobile_Home_Sum_Fields = {
  __typename?: 'mobile_home_sum_fields'
  order?: Maybe<Scalars['Int']>
}

/** update columns of table "mobile_home" */
export enum Mobile_Home_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AssetUrl = 'asset_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  LinkUrl = 'link_url',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Mobile_Home_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Mobile_Home_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Mobile_Home_Set_Input>
  /** filter the rows which have to be updated */
  where: Mobile_Home_Bool_Exp
}

/** aggregate var_pop on columns */
export type Mobile_Home_Var_Pop_Fields = {
  __typename?: 'mobile_home_var_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Mobile_Home_Var_Samp_Fields = {
  __typename?: 'mobile_home_var_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Mobile_Home_Variance_Fields = {
  __typename?: 'mobile_home_variance_fields'
  order?: Maybe<Scalars['Float']>
}

/** This is a table to handle progressing mobile push notifications through different states of its lifecycle */
export type Mobile_Push_Notification = {
  __typename?: 'mobile_push_notification'
  attempts: Scalars['Int']
  bulk: Scalars['Boolean']
  bulk_id?: Maybe<Scalars['Int']>
  created_at: Scalars['timestamptz']
  error_details?: Maybe<Scalars['jsonb']>
  id: Scalars['uuid']
  message_payload: Scalars['json']
  push_notification_token: Scalars['String']
  receipt_id?: Maybe<Scalars['String']>
  scheduled_at?: Maybe<Scalars['timestamptz']>
  sent_at?: Maybe<Scalars['timestamptz']>
  status: Mobile_Push_Notification_Status_Enum
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id: Scalars['String']
  /** An object relationship */
  user_push_notification_token?: Maybe<User_Push_Notification_Token>
}

/** This is a table to handle progressing mobile push notifications through different states of its lifecycle */
export type Mobile_Push_NotificationError_DetailsArgs = {
  path?: Maybe<Scalars['String']>
}

/** This is a table to handle progressing mobile push notifications through different states of its lifecycle */
export type Mobile_Push_NotificationMessage_PayloadArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "mobile_push_notification" */
export type Mobile_Push_Notification_Aggregate = {
  __typename?: 'mobile_push_notification_aggregate'
  aggregate?: Maybe<Mobile_Push_Notification_Aggregate_Fields>
  nodes: Array<Mobile_Push_Notification>
}

/** aggregate fields of "mobile_push_notification" */
export type Mobile_Push_Notification_Aggregate_Fields = {
  __typename?: 'mobile_push_notification_aggregate_fields'
  avg?: Maybe<Mobile_Push_Notification_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Mobile_Push_Notification_Max_Fields>
  min?: Maybe<Mobile_Push_Notification_Min_Fields>
  stddev?: Maybe<Mobile_Push_Notification_Stddev_Fields>
  stddev_pop?: Maybe<Mobile_Push_Notification_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Mobile_Push_Notification_Stddev_Samp_Fields>
  sum?: Maybe<Mobile_Push_Notification_Sum_Fields>
  var_pop?: Maybe<Mobile_Push_Notification_Var_Pop_Fields>
  var_samp?: Maybe<Mobile_Push_Notification_Var_Samp_Fields>
  variance?: Maybe<Mobile_Push_Notification_Variance_Fields>
}

/** aggregate fields of "mobile_push_notification" */
export type Mobile_Push_Notification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mobile_Push_Notification_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mobile_Push_Notification_Append_Input = {
  error_details?: Maybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Mobile_Push_Notification_Avg_Fields = {
  __typename?: 'mobile_push_notification_avg_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "mobile_push_notification". All fields are combined with a logical 'AND'. */
export type Mobile_Push_Notification_Bool_Exp = {
  _and?: Maybe<Array<Mobile_Push_Notification_Bool_Exp>>
  _not?: Maybe<Mobile_Push_Notification_Bool_Exp>
  _or?: Maybe<Array<Mobile_Push_Notification_Bool_Exp>>
  attempts?: Maybe<Int_Comparison_Exp>
  bulk?: Maybe<Boolean_Comparison_Exp>
  bulk_id?: Maybe<Int_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  error_details?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  message_payload?: Maybe<Json_Comparison_Exp>
  push_notification_token?: Maybe<String_Comparison_Exp>
  receipt_id?: Maybe<String_Comparison_Exp>
  scheduled_at?: Maybe<Timestamptz_Comparison_Exp>
  sent_at?: Maybe<Timestamptz_Comparison_Exp>
  status?: Maybe<Mobile_Push_Notification_Status_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  user_push_notification_token?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

/** unique or primary key constraints on table "mobile_push_notification" */
export enum Mobile_Push_Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  MobilePushNotificationPkey = 'mobile_push_notification_pkey',
  /** unique or primary key constraint on columns "receipt_id" */
  MobilePushNotificationReceiptIdKey = 'mobile_push_notification_receipt_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mobile_Push_Notification_Delete_At_Path_Input = {
  error_details?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mobile_Push_Notification_Delete_Elem_Input = {
  error_details?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mobile_Push_Notification_Delete_Key_Input = {
  error_details?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "mobile_push_notification" */
export type Mobile_Push_Notification_Inc_Input = {
  attempts?: Maybe<Scalars['Int']>
  bulk_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "mobile_push_notification" */
export type Mobile_Push_Notification_Insert_Input = {
  attempts?: Maybe<Scalars['Int']>
  bulk?: Maybe<Scalars['Boolean']>
  bulk_id?: Maybe<Scalars['Int']>
  created_at?: Maybe<Scalars['timestamptz']>
  error_details?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  message_payload?: Maybe<Scalars['json']>
  push_notification_token?: Maybe<Scalars['String']>
  receipt_id?: Maybe<Scalars['String']>
  scheduled_at?: Maybe<Scalars['timestamptz']>
  sent_at?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Mobile_Push_Notification_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  user_push_notification_token?: Maybe<User_Push_Notification_Token_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Mobile_Push_Notification_Max_Fields = {
  __typename?: 'mobile_push_notification_max_fields'
  attempts?: Maybe<Scalars['Int']>
  bulk_id?: Maybe<Scalars['Int']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  push_notification_token?: Maybe<Scalars['String']>
  receipt_id?: Maybe<Scalars['String']>
  scheduled_at?: Maybe<Scalars['timestamptz']>
  sent_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Mobile_Push_Notification_Min_Fields = {
  __typename?: 'mobile_push_notification_min_fields'
  attempts?: Maybe<Scalars['Int']>
  bulk_id?: Maybe<Scalars['Int']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  push_notification_token?: Maybe<Scalars['String']>
  receipt_id?: Maybe<Scalars['String']>
  scheduled_at?: Maybe<Scalars['timestamptz']>
  sent_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "mobile_push_notification" */
export type Mobile_Push_Notification_Mutation_Response = {
  __typename?: 'mobile_push_notification_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Push_Notification>
}

/** on_conflict condition type for table "mobile_push_notification" */
export type Mobile_Push_Notification_On_Conflict = {
  constraint: Mobile_Push_Notification_Constraint
  update_columns?: Array<Mobile_Push_Notification_Update_Column>
  where?: Maybe<Mobile_Push_Notification_Bool_Exp>
}

/** Ordering options when selecting data from "mobile_push_notification". */
export type Mobile_Push_Notification_Order_By = {
  attempts?: Maybe<Order_By>
  bulk?: Maybe<Order_By>
  bulk_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  error_details?: Maybe<Order_By>
  id?: Maybe<Order_By>
  message_payload?: Maybe<Order_By>
  push_notification_token?: Maybe<Order_By>
  receipt_id?: Maybe<Order_By>
  scheduled_at?: Maybe<Order_By>
  sent_at?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  user_push_notification_token?: Maybe<User_Push_Notification_Token_Order_By>
}

/** primary key columns input for table: mobile_push_notification */
export type Mobile_Push_Notification_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mobile_Push_Notification_Prepend_Input = {
  error_details?: Maybe<Scalars['jsonb']>
}

/** select columns of table "mobile_push_notification" */
export enum Mobile_Push_Notification_Select_Column {
  /** column name */
  Attempts = 'attempts',
  /** column name */
  Bulk = 'bulk',
  /** column name */
  BulkId = 'bulk_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorDetails = 'error_details',
  /** column name */
  Id = 'id',
  /** column name */
  MessagePayload = 'message_payload',
  /** column name */
  PushNotificationToken = 'push_notification_token',
  /** column name */
  ReceiptId = 'receipt_id',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "mobile_push_notification" */
export type Mobile_Push_Notification_Set_Input = {
  attempts?: Maybe<Scalars['Int']>
  bulk?: Maybe<Scalars['Boolean']>
  bulk_id?: Maybe<Scalars['Int']>
  created_at?: Maybe<Scalars['timestamptz']>
  error_details?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  message_payload?: Maybe<Scalars['json']>
  push_notification_token?: Maybe<Scalars['String']>
  receipt_id?: Maybe<Scalars['String']>
  scheduled_at?: Maybe<Scalars['timestamptz']>
  sent_at?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Mobile_Push_Notification_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** An enum table to describe the different states that a mobile push notification can be in */
export type Mobile_Push_Notification_Status = {
  __typename?: 'mobile_push_notification_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Aggregate = {
  __typename?: 'mobile_push_notification_status_aggregate'
  aggregate?: Maybe<Mobile_Push_Notification_Status_Aggregate_Fields>
  nodes: Array<Mobile_Push_Notification_Status>
}

/** aggregate fields of "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Aggregate_Fields = {
  __typename?: 'mobile_push_notification_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Mobile_Push_Notification_Status_Max_Fields>
  min?: Maybe<Mobile_Push_Notification_Status_Min_Fields>
}

/** aggregate fields of "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mobile_Push_Notification_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "mobile_push_notification_status". All fields are combined with a logical 'AND'. */
export type Mobile_Push_Notification_Status_Bool_Exp = {
  _and?: Maybe<Array<Mobile_Push_Notification_Status_Bool_Exp>>
  _not?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
  _or?: Maybe<Array<Mobile_Push_Notification_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "mobile_push_notification_status" */
export enum Mobile_Push_Notification_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  MobilePushNotificationStatusPkey = 'mobile_push_notification_status_pkey',
}

export enum Mobile_Push_Notification_Status_Enum {
  Expired = 'expired',
  Failed = 'failed',
  Pending = 'pending',
  Scheduled = 'scheduled',
  Sending = 'sending',
  Sent = 'sent',
  Succeeded = 'succeeded',
  WontSend = 'wont_send',
}

/** Boolean expression to compare columns of type "mobile_push_notification_status_enum". All fields are combined with logical 'AND'. */
export type Mobile_Push_Notification_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Mobile_Push_Notification_Status_Enum>
  _in?: Maybe<Array<Mobile_Push_Notification_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Mobile_Push_Notification_Status_Enum>
  _nin?: Maybe<Array<Mobile_Push_Notification_Status_Enum>>
}

/** input type for inserting data into table "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Mobile_Push_Notification_Status_Max_Fields = {
  __typename?: 'mobile_push_notification_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Mobile_Push_Notification_Status_Min_Fields = {
  __typename?: 'mobile_push_notification_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Mutation_Response = {
  __typename?: 'mobile_push_notification_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Push_Notification_Status>
}

/** on_conflict condition type for table "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_On_Conflict = {
  constraint: Mobile_Push_Notification_Status_Constraint
  update_columns?: Array<Mobile_Push_Notification_Status_Update_Column>
  where?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
}

/** Ordering options when selecting data from "mobile_push_notification_status". */
export type Mobile_Push_Notification_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: mobile_push_notification_status */
export type Mobile_Push_Notification_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "mobile_push_notification_status" */
export enum Mobile_Push_Notification_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Push_Notification_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Mobile_Push_Notification_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "mobile_push_notification_status" */
export enum Mobile_Push_Notification_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Mobile_Push_Notification_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Mobile_Push_Notification_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Mobile_Push_Notification_Status_Bool_Exp
}

/** aggregate stddev on columns */
export type Mobile_Push_Notification_Stddev_Fields = {
  __typename?: 'mobile_push_notification_stddev_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Mobile_Push_Notification_Stddev_Pop_Fields = {
  __typename?: 'mobile_push_notification_stddev_pop_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Mobile_Push_Notification_Stddev_Samp_Fields = {
  __typename?: 'mobile_push_notification_stddev_samp_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "mobile_push_notification" */
export type Mobile_Push_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Push_Notification_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Mobile_Push_Notification_Stream_Cursor_Value_Input = {
  attempts?: Maybe<Scalars['Int']>
  bulk?: Maybe<Scalars['Boolean']>
  bulk_id?: Maybe<Scalars['Int']>
  created_at?: Maybe<Scalars['timestamptz']>
  error_details?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  message_payload?: Maybe<Scalars['json']>
  push_notification_token?: Maybe<Scalars['String']>
  receipt_id?: Maybe<Scalars['String']>
  scheduled_at?: Maybe<Scalars['timestamptz']>
  sent_at?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Mobile_Push_Notification_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Mobile_Push_Notification_Sum_Fields = {
  __typename?: 'mobile_push_notification_sum_fields'
  attempts?: Maybe<Scalars['Int']>
  bulk_id?: Maybe<Scalars['Int']>
}

/** update columns of table "mobile_push_notification" */
export enum Mobile_Push_Notification_Update_Column {
  /** column name */
  Attempts = 'attempts',
  /** column name */
  Bulk = 'bulk',
  /** column name */
  BulkId = 'bulk_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorDetails = 'error_details',
  /** column name */
  Id = 'id',
  /** column name */
  MessagePayload = 'message_payload',
  /** column name */
  PushNotificationToken = 'push_notification_token',
  /** column name */
  ReceiptId = 'receipt_id',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Mobile_Push_Notification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Mobile_Push_Notification_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Mobile_Push_Notification_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Mobile_Push_Notification_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Mobile_Push_Notification_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Mobile_Push_Notification_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Mobile_Push_Notification_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Mobile_Push_Notification_Set_Input>
  /** filter the rows which have to be updated */
  where: Mobile_Push_Notification_Bool_Exp
}

/** aggregate var_pop on columns */
export type Mobile_Push_Notification_Var_Pop_Fields = {
  __typename?: 'mobile_push_notification_var_pop_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Mobile_Push_Notification_Var_Samp_Fields = {
  __typename?: 'mobile_push_notification_var_samp_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Mobile_Push_Notification_Variance_Fields = {
  __typename?: 'mobile_push_notification_variance_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  /** delete data from the table: "account_deletion_reason" */
  delete_account_deletion_reason?: Maybe<Account_Deletion_Reason_Mutation_Response>
  /** delete single row from the table: "account_deletion_reason" */
  delete_account_deletion_reason_by_pk?: Maybe<Account_Deletion_Reason>
  /** delete data from the table: "affiliate" */
  delete_affiliate?: Maybe<Affiliate_Mutation_Response>
  /** delete data from the table: "affiliate_advertiser_id" */
  delete_affiliate_advertiser_id?: Maybe<Affiliate_Advertiser_Id_Mutation_Response>
  /** delete single row from the table: "affiliate_advertiser_id" */
  delete_affiliate_advertiser_id_by_pk?: Maybe<Affiliate_Advertiser_Id>
  /** delete single row from the table: "affiliate" */
  delete_affiliate_by_pk?: Maybe<Affiliate>
  /** delete data from the table: "affiliate_meta" */
  delete_affiliate_meta?: Maybe<Affiliate_Meta_Mutation_Response>
  /** delete single row from the table: "affiliate_meta" */
  delete_affiliate_meta_by_pk?: Maybe<Affiliate_Meta>
  /** delete data from the table: "affiliate_transaction" */
  delete_affiliate_transaction?: Maybe<Affiliate_Transaction_Mutation_Response>
  /** delete single row from the table: "affiliate_transaction" */
  delete_affiliate_transaction_by_pk?: Maybe<Affiliate_Transaction>
  /** delete data from the table: "billing_mode" */
  delete_billing_mode?: Maybe<Billing_Mode_Mutation_Response>
  /** delete single row from the table: "billing_mode" */
  delete_billing_mode_by_pk?: Maybe<Billing_Mode>
  /** delete data from the table: "billing_platform_connected_account" */
  delete_billing_platform_connected_account?: Maybe<Billing_Platform_Connected_Account_Mutation_Response>
  /** delete single row from the table: "billing_platform_connected_account" */
  delete_billing_platform_connected_account_by_pk?: Maybe<Billing_Platform_Connected_Account>
  /** delete data from the table: "billing_platform_connected_account_status" */
  delete_billing_platform_connected_account_status?: Maybe<Billing_Platform_Connected_Account_Status_Mutation_Response>
  /** delete single row from the table: "billing_platform_connected_account_status" */
  delete_billing_platform_connected_account_status_by_pk?: Maybe<Billing_Platform_Connected_Account_Status>
  /** delete data from the table: "billing_platform_customer" */
  delete_billing_platform_customer?: Maybe<Billing_Platform_Customer_Mutation_Response>
  /** delete single row from the table: "billing_platform_customer" */
  delete_billing_platform_customer_by_pk?: Maybe<Billing_Platform_Customer>
  /** delete data from the table: "cart" */
  delete_cart?: Maybe<Cart_Mutation_Response>
  /** delete single row from the table: "cart" */
  delete_cart_by_pk?: Maybe<Cart>
  /** delete data from the table: "cart_item" */
  delete_cart_item?: Maybe<Cart_Item_Mutation_Response>
  /** delete single row from the table: "cart_item" */
  delete_cart_item_by_pk?: Maybe<Cart_Item>
  /** delete data from the table: "cart_item_like" */
  delete_cart_item_like?: Maybe<Cart_Item_Like_Mutation_Response>
  /** delete single row from the table: "cart_item_like" */
  delete_cart_item_like_by_pk?: Maybe<Cart_Item_Like>
  /** delete data from the table: "cart_item_note" */
  delete_cart_item_note?: Maybe<Cart_Item_Note_Mutation_Response>
  /** delete single row from the table: "cart_item_note" */
  delete_cart_item_note_by_pk?: Maybe<Cart_Item_Note>
  /** delete data from the table: "categories" */
  delete_categories?: Maybe<Categories_Mutation_Response>
  /** delete single row from the table: "categories" */
  delete_categories_by_pk?: Maybe<Categories>
  /** delete data from the table: "category_shop_item" */
  delete_category_shop_item?: Maybe<Category_Shop_Item_Mutation_Response>
  /** delete single row from the table: "category_shop_item" */
  delete_category_shop_item_by_pk?: Maybe<Category_Shop_Item>
  /** delete data from the table: "checkout" */
  delete_checkout?: Maybe<Checkout_Mutation_Response>
  /** delete single row from the table: "checkout" */
  delete_checkout_by_pk?: Maybe<Checkout>
  /** delete data from the table: "collection" */
  delete_collection?: Maybe<Collection_Mutation_Response>
  /** delete single row from the table: "collection" */
  delete_collection_by_pk?: Maybe<Collection>
  /** delete data from the table: "collection_cart_item" */
  delete_collection_cart_item?: Maybe<Collection_Cart_Item_Mutation_Response>
  /** delete single row from the table: "collection_cart_item" */
  delete_collection_cart_item_by_pk?: Maybe<Collection_Cart_Item>
  /** delete data from the table: "collection_collaborator" */
  delete_collection_collaborator?: Maybe<Collection_Collaborator_Mutation_Response>
  /** delete single row from the table: "collection_collaborator" */
  delete_collection_collaborator_by_pk?: Maybe<Collection_Collaborator>
  /** delete data from the table: "collection_invite" */
  delete_collection_invite?: Maybe<Collection_Invite_Mutation_Response>
  /** delete single row from the table: "collection_invite" */
  delete_collection_invite_by_pk?: Maybe<Collection_Invite>
  /** delete data from the table: "collection_invite_status" */
  delete_collection_invite_status?: Maybe<Collection_Invite_Status_Mutation_Response>
  /** delete single row from the table: "collection_invite_status" */
  delete_collection_invite_status_by_pk?: Maybe<Collection_Invite_Status>
  /** delete data from the table: "collection_item_claim" */
  delete_collection_item_claim?: Maybe<Collection_Item_Claim_Mutation_Response>
  /** delete single row from the table: "collection_item_claim" */
  delete_collection_item_claim_by_pk?: Maybe<Collection_Item_Claim>
  /** delete data from the table: "collection_item_claim_status" */
  delete_collection_item_claim_status?: Maybe<Collection_Item_Claim_Status_Mutation_Response>
  /** delete single row from the table: "collection_item_claim_status" */
  delete_collection_item_claim_status_by_pk?: Maybe<Collection_Item_Claim_Status>
  /** delete data from the table: "collection_section" */
  delete_collection_section?: Maybe<Collection_Section_Mutation_Response>
  /** delete single row from the table: "collection_section" */
  delete_collection_section_by_pk?: Maybe<Collection_Section>
  /** delete data from the table: "collection_section_cart_item" */
  delete_collection_section_cart_item?: Maybe<Collection_Section_Cart_Item_Mutation_Response>
  /** delete single row from the table: "collection_section_cart_item" */
  delete_collection_section_cart_item_by_pk?: Maybe<Collection_Section_Cart_Item>
  /** delete data from the table: "collection_slug_history" */
  delete_collection_slug_history?: Maybe<Collection_Slug_History_Mutation_Response>
  /** delete single row from the table: "collection_slug_history" */
  delete_collection_slug_history_by_pk?: Maybe<Collection_Slug_History>
  /** delete data from the table: "collection_state" */
  delete_collection_state?: Maybe<Collection_State_Mutation_Response>
  /** delete single row from the table: "collection_state" */
  delete_collection_state_by_pk?: Maybe<Collection_State>
  /** delete data from the table: "collection_type" */
  delete_collection_type?: Maybe<Collection_Type_Mutation_Response>
  /** delete single row from the table: "collection_type" */
  delete_collection_type_by_pk?: Maybe<Collection_Type>
  /** delete data from the table: "comment" */
  delete_comment?: Maybe<Comment_Mutation_Response>
  /** delete single row from the table: "comment" */
  delete_comment_by_pk?: Maybe<Comment>
  /** delete data from the table: "commentable_item" */
  delete_commentable_item?: Maybe<Commentable_Item_Mutation_Response>
  /** delete single row from the table: "commentable_item" */
  delete_commentable_item_by_pk?: Maybe<Commentable_Item>
  /** delete data from the table: "commentable_item_type" */
  delete_commentable_item_type?: Maybe<Commentable_Item_Type_Mutation_Response>
  /** delete single row from the table: "commentable_item_type" */
  delete_commentable_item_type_by_pk?: Maybe<Commentable_Item_Type>
  /** delete data from the table: "communication_template" */
  delete_communication_template?: Maybe<Communication_Template_Mutation_Response>
  /** delete single row from the table: "communication_template" */
  delete_communication_template_by_pk?: Maybe<Communication_Template>
  /** delete data from the table: "communication_template_full_list" */
  delete_communication_template_full_list?: Maybe<Communication_Template_Full_List_Mutation_Response>
  /** delete single row from the table: "communication_template_full_list" */
  delete_communication_template_full_list_by_pk?: Maybe<Communication_Template_Full_List>
  /** delete data from the table: "contact_info_type" */
  delete_contact_info_type?: Maybe<Contact_Info_Type_Mutation_Response>
  /** delete single row from the table: "contact_info_type" */
  delete_contact_info_type_by_pk?: Maybe<Contact_Info_Type>
  /** delete data from the table: "contact_invite" */
  delete_contact_invite?: Maybe<Contact_Invite_Mutation_Response>
  /** delete single row from the table: "contact_invite" */
  delete_contact_invite_by_pk?: Maybe<Contact_Invite>
  /** delete data from the table: "contact_invite_status" */
  delete_contact_invite_status?: Maybe<Contact_Invite_Status_Mutation_Response>
  /** delete single row from the table: "contact_invite_status" */
  delete_contact_invite_status_by_pk?: Maybe<Contact_Invite_Status>
  /** delete data from the table: "contact_invite_type" */
  delete_contact_invite_type?: Maybe<Contact_Invite_Type_Mutation_Response>
  /** delete single row from the table: "contact_invite_type" */
  delete_contact_invite_type_by_pk?: Maybe<Contact_Invite_Type>
  /** delete data from the table: "deal_hop_gate" */
  delete_deal_hop_gate?: Maybe<Deal_Hop_Gate_Mutation_Response>
  /** delete single row from the table: "deal_hop_gate" */
  delete_deal_hop_gate_by_pk?: Maybe<Deal_Hop_Gate>
  /** delete data from the table: "device_client_data" */
  delete_device_client_data?: Maybe<Device_Client_Data_Mutation_Response>
  /** delete single row from the table: "device_client_data" */
  delete_device_client_data_by_pk?: Maybe<Device_Client_Data>
  /** delete data from the table: "discovery_backlog" */
  delete_discovery_backlog?: Maybe<Discovery_Backlog_Mutation_Response>
  /** delete single row from the table: "discovery_backlog" */
  delete_discovery_backlog_by_pk?: Maybe<Discovery_Backlog>
  /** delete data from the table: "discovery_feed" */
  delete_discovery_feed?: Maybe<Discovery_Feed_Mutation_Response>
  /** delete single row from the table: "discovery_feed" */
  delete_discovery_feed_by_pk?: Maybe<Discovery_Feed>
  /** delete data from the table: "discovery_staging" */
  delete_discovery_staging?: Maybe<Discovery_Staging_Mutation_Response>
  /** delete single row from the table: "discovery_staging" */
  delete_discovery_staging_by_pk?: Maybe<Discovery_Staging>
  /** delete data from the table: "featured_shop" */
  delete_featured_shop?: Maybe<Featured_Shop_Mutation_Response>
  /** delete single row from the table: "featured_shop" */
  delete_featured_shop_by_pk?: Maybe<Featured_Shop>
  /** delete data from the table: "featured_shop_item" */
  delete_featured_shop_item?: Maybe<Featured_Shop_Item_Mutation_Response>
  /** delete single row from the table: "featured_shop_item" */
  delete_featured_shop_item_by_pk?: Maybe<Featured_Shop_Item>
  /** delete data from the table: "feed_item" */
  delete_feed_item?: Maybe<Feed_Item_Mutation_Response>
  /** delete single row from the table: "feed_item" */
  delete_feed_item_by_pk?: Maybe<Feed_Item>
  /** delete data from the table: "feed_item_like" */
  delete_feed_item_like?: Maybe<Feed_Item_Like_Mutation_Response>
  /** delete single row from the table: "feed_item_like" */
  delete_feed_item_like_by_pk?: Maybe<Feed_Item_Like>
  /** delete data from the table: "feed_item_type" */
  delete_feed_item_type?: Maybe<Feed_Item_Type_Mutation_Response>
  /** delete single row from the table: "feed_item_type" */
  delete_feed_item_type_by_pk?: Maybe<Feed_Item_Type>
  /** delete data from the table: "fmtc_deal" */
  delete_fmtc_deal?: Maybe<Fmtc_Deal_Mutation_Response>
  /** delete single row from the table: "fmtc_deal" */
  delete_fmtc_deal_by_pk?: Maybe<Fmtc_Deal>
  /** delete data from the table: "followable_item" */
  delete_followable_item?: Maybe<Followable_Item_Mutation_Response>
  /** delete single row from the table: "followable_item" */
  delete_followable_item_by_pk?: Maybe<Followable_Item>
  /** delete data from the table: "followable_item_type" */
  delete_followable_item_type?: Maybe<Followable_Item_Type_Mutation_Response>
  /** delete single row from the table: "followable_item_type" */
  delete_followable_item_type_by_pk?: Maybe<Followable_Item_Type>
  /** delete data from the table: "leaderboard_event" */
  delete_leaderboard_event?: Maybe<Leaderboard_Event_Mutation_Response>
  /** delete single row from the table: "leaderboard_event" */
  delete_leaderboard_event_by_pk?: Maybe<Leaderboard_Event>
  /** delete data from the table: "leaderboard_event_name" */
  delete_leaderboard_event_name?: Maybe<Leaderboard_Event_Name_Mutation_Response>
  /** delete single row from the table: "leaderboard_event_name" */
  delete_leaderboard_event_name_by_pk?: Maybe<Leaderboard_Event_Name>
  /** delete data from the table: "link_type" */
  delete_link_type?: Maybe<Link_Type_Mutation_Response>
  /** delete single row from the table: "link_type" */
  delete_link_type_by_pk?: Maybe<Link_Type>
  /** delete data from the table: "media_enabled_item" */
  delete_media_enabled_item?: Maybe<Media_Enabled_Item_Mutation_Response>
  /** delete single row from the table: "media_enabled_item" */
  delete_media_enabled_item_by_pk?: Maybe<Media_Enabled_Item>
  /** delete data from the table: "media_item" */
  delete_media_item?: Maybe<Media_Item_Mutation_Response>
  /** delete single row from the table: "media_item" */
  delete_media_item_by_pk?: Maybe<Media_Item>
  /** delete data from the table: "media_item_type" */
  delete_media_item_type?: Maybe<Media_Item_Type_Mutation_Response>
  /** delete single row from the table: "media_item_type" */
  delete_media_item_type_by_pk?: Maybe<Media_Item_Type>
  /** delete data from the table: "media_items" */
  delete_media_items?: Maybe<Media_Items_Mutation_Response>
  /** delete single row from the table: "media_items" */
  delete_media_items_by_pk?: Maybe<Media_Items>
  /** delete data from the table: "media_type" */
  delete_media_type?: Maybe<Media_Type_Mutation_Response>
  /** delete single row from the table: "media_type" */
  delete_media_type_by_pk?: Maybe<Media_Type>
  /** delete data from the table: "mobile_app_home" */
  delete_mobile_app_home?: Maybe<Mobile_App_Home_Mutation_Response>
  /** delete single row from the table: "mobile_app_home" */
  delete_mobile_app_home_by_pk?: Maybe<Mobile_App_Home>
  /** delete data from the table: "mobile_home" */
  delete_mobile_home?: Maybe<Mobile_Home_Mutation_Response>
  /** delete single row from the table: "mobile_home" */
  delete_mobile_home_by_pk?: Maybe<Mobile_Home>
  /** delete data from the table: "mobile_push_notification" */
  delete_mobile_push_notification?: Maybe<Mobile_Push_Notification_Mutation_Response>
  /** delete single row from the table: "mobile_push_notification" */
  delete_mobile_push_notification_by_pk?: Maybe<Mobile_Push_Notification>
  /** delete data from the table: "mobile_push_notification_status" */
  delete_mobile_push_notification_status?: Maybe<Mobile_Push_Notification_Status_Mutation_Response>
  /** delete single row from the table: "mobile_push_notification_status" */
  delete_mobile_push_notification_status_by_pk?: Maybe<Mobile_Push_Notification_Status>
  /** delete data from the table: "notifiable_item" */
  delete_notifiable_item?: Maybe<Notifiable_Item_Mutation_Response>
  /** delete single row from the table: "notifiable_item" */
  delete_notifiable_item_by_pk?: Maybe<Notifiable_Item>
  /** delete data from the table: "notifiable_item_type" */
  delete_notifiable_item_type?: Maybe<Notifiable_Item_Type_Mutation_Response>
  /** delete single row from the table: "notifiable_item_type" */
  delete_notifiable_item_type_by_pk?: Maybe<Notifiable_Item_Type>
  /** delete data from the table: "notification" */
  delete_notification?: Maybe<Notification_Mutation_Response>
  /** delete single row from the table: "notification" */
  delete_notification_by_pk?: Maybe<Notification>
  /** delete data from the table: "notification_center_item" */
  delete_notification_center_item?: Maybe<Notification_Center_Item_Mutation_Response>
  /** delete single row from the table: "notification_center_item" */
  delete_notification_center_item_by_pk?: Maybe<Notification_Center_Item>
  /** delete data from the table: "notification_center_item_read_status" */
  delete_notification_center_item_read_status?: Maybe<Notification_Center_Item_Read_Status_Mutation_Response>
  /** delete single row from the table: "notification_center_item_read_status" */
  delete_notification_center_item_read_status_by_pk?: Maybe<Notification_Center_Item_Read_Status>
  /** delete data from the table: "notification_center_item_type" */
  delete_notification_center_item_type?: Maybe<Notification_Center_Item_Type_Mutation_Response>
  /** delete single row from the table: "notification_center_item_type" */
  delete_notification_center_item_type_by_pk?: Maybe<Notification_Center_Item_Type>
  /** delete data from the table: "notification_preference" */
  delete_notification_preference?: Maybe<Notification_Preference_Mutation_Response>
  /** delete single row from the table: "notification_preference" */
  delete_notification_preference_by_pk?: Maybe<Notification_Preference>
  /** delete data from the table: "notification_status" */
  delete_notification_status?: Maybe<Notification_Status_Mutation_Response>
  /** delete single row from the table: "notification_status" */
  delete_notification_status_by_pk?: Maybe<Notification_Status>
  /** delete data from the table: "onboarding_categories" */
  delete_onboarding_categories?: Maybe<Onboarding_Categories_Mutation_Response>
  /** delete single row from the table: "onboarding_categories" */
  delete_onboarding_categories_by_pk?: Maybe<Onboarding_Categories>
  /** delete data from the table: "onboarding_sources" */
  delete_onboarding_sources?: Maybe<Onboarding_Sources_Mutation_Response>
  /** delete single row from the table: "onboarding_sources" */
  delete_onboarding_sources_by_pk?: Maybe<Onboarding_Sources>
  /** delete data from the table: "one_off_email" */
  delete_one_off_email?: Maybe<One_Off_Email_Mutation_Response>
  /** delete single row from the table: "one_off_email" */
  delete_one_off_email_by_pk?: Maybe<One_Off_Email>
  /** delete data from the table: "pageview_event" */
  delete_pageview_event?: Maybe<Pageview_Event_Mutation_Response>
  /** delete single row from the table: "pageview_event" */
  delete_pageview_event_by_pk?: Maybe<Pageview_Event>
  /** delete data from the table: "promo_period" */
  delete_promo_period?: Maybe<Promo_Period_Mutation_Response>
  /** delete single row from the table: "promo_period" */
  delete_promo_period_by_pk?: Maybe<Promo_Period>
  /** delete data from the table: "push_notification" */
  delete_push_notification?: Maybe<Push_Notification_Mutation_Response>
  /** delete single row from the table: "push_notification" */
  delete_push_notification_by_pk?: Maybe<Push_Notification>
  /** delete data from the table: "push_notification_status" */
  delete_push_notification_status?: Maybe<Push_Notification_Status_Mutation_Response>
  /** delete single row from the table: "push_notification_status" */
  delete_push_notification_status_by_pk?: Maybe<Push_Notification_Status>
  /** delete data from the table: "redirect_ids" */
  delete_redirect_ids?: Maybe<Redirect_Ids_Mutation_Response>
  /** delete single row from the table: "redirect_ids" */
  delete_redirect_ids_by_pk?: Maybe<Redirect_Ids>
  /** delete data from the table: "remove_reason" */
  delete_remove_reason?: Maybe<Remove_Reason_Mutation_Response>
  /** delete single row from the table: "remove_reason" */
  delete_remove_reason_by_pk?: Maybe<Remove_Reason>
  /** delete data from the table: "rewardable_action_names" */
  delete_rewardable_action_names?: Maybe<Rewardable_Action_Names_Mutation_Response>
  /** delete single row from the table: "rewardable_action_names" */
  delete_rewardable_action_names_by_pk?: Maybe<Rewardable_Action_Names>
  /** delete data from the table: "rewardable_item_type" */
  delete_rewardable_item_type?: Maybe<Rewardable_Item_Type_Mutation_Response>
  /** delete single row from the table: "rewardable_item_type" */
  delete_rewardable_item_type_by_pk?: Maybe<Rewardable_Item_Type>
  /** delete data from the table: "search_query" */
  delete_search_query?: Maybe<Search_Query_Mutation_Response>
  /** delete single row from the table: "search_query" */
  delete_search_query_by_pk?: Maybe<Search_Query>
  /** delete data from the table: "search_query_result" */
  delete_search_query_result?: Maybe<Search_Query_Result_Mutation_Response>
  /** delete single row from the table: "search_query_result" */
  delete_search_query_result_by_pk?: Maybe<Search_Query_Result>
  /** delete data from the table: "shop" */
  delete_shop?: Maybe<Shop_Mutation_Response>
  /** delete data from the table: "shop_affiliate" */
  delete_shop_affiliate?: Maybe<Shop_Affiliate_Mutation_Response>
  /** delete single row from the table: "shop_affiliate" */
  delete_shop_affiliate_by_pk?: Maybe<Shop_Affiliate>
  /** delete single row from the table: "shop" */
  delete_shop_by_pk?: Maybe<Shop>
  /** delete data from the table: "shop_categorization" */
  delete_shop_categorization?: Maybe<Shop_Categorization_Mutation_Response>
  /** delete single row from the table: "shop_categorization" */
  delete_shop_categorization_by_pk?: Maybe<Shop_Categorization>
  /** delete data from the table: "shop_category" */
  delete_shop_category?: Maybe<Shop_Category_Mutation_Response>
  /** delete single row from the table: "shop_category" */
  delete_shop_category_by_pk?: Maybe<Shop_Category>
  /** delete data from the table: "shop_item" */
  delete_shop_item?: Maybe<Shop_Item_Mutation_Response>
  /** delete data from the table: "shop_item_availability" */
  delete_shop_item_availability?: Maybe<Shop_Item_Availability_Mutation_Response>
  /** delete single row from the table: "shop_item_availability" */
  delete_shop_item_availability_by_pk?: Maybe<Shop_Item_Availability>
  /** delete single row from the table: "shop_item" */
  delete_shop_item_by_pk?: Maybe<Shop_Item>
  /** delete data from the table: "shop_item_enrichment_status" */
  delete_shop_item_enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Mutation_Response>
  /** delete single row from the table: "shop_item_enrichment_status" */
  delete_shop_item_enrichment_status_by_pk?: Maybe<Shop_Item_Enrichment_Status>
  /** delete data from the table: "shop_item_history" */
  delete_shop_item_history?: Maybe<Shop_Item_History_Mutation_Response>
  /** delete single row from the table: "shop_item_history" */
  delete_shop_item_history_by_pk?: Maybe<Shop_Item_History>
  /** delete data from the table: "shop_item_image" */
  delete_shop_item_image?: Maybe<Shop_Item_Image_Mutation_Response>
  /** delete single row from the table: "shop_item_image" */
  delete_shop_item_image_by_pk?: Maybe<Shop_Item_Image>
  /** delete data from the table: "shop_item_index" */
  delete_shop_item_index?: Maybe<Shop_Item_Index_Mutation_Response>
  /** delete single row from the table: "shop_item_index" */
  delete_shop_item_index_by_pk?: Maybe<Shop_Item_Index>
  /** delete data from the table: "shop_item_to_be_scraped" */
  delete_shop_item_to_be_scraped?: Maybe<Shop_Item_To_Be_Scraped_Mutation_Response>
  /** delete data from the table: "shop_rating" */
  delete_shop_rating?: Maybe<Shop_Rating_Mutation_Response>
  /** delete single row from the table: "shop_rating" */
  delete_shop_rating_by_pk?: Maybe<Shop_Rating>
  /** delete data from the table: "similar_products_history" */
  delete_similar_products_history?: Maybe<Similar_Products_History_Mutation_Response>
  /** delete single row from the table: "similar_products_history" */
  delete_similar_products_history_by_pk?: Maybe<Similar_Products_History>
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>
  /** delete data from the table: "user_account_type" */
  delete_user_account_type?: Maybe<User_Account_Type_Mutation_Response>
  /** delete single row from the table: "user_account_type" */
  delete_user_account_type_by_pk?: Maybe<User_Account_Type>
  /** delete data from the table: "user_action" */
  delete_user_action?: Maybe<User_Action_Mutation_Response>
  /** delete single row from the table: "user_action" */
  delete_user_action_by_pk?: Maybe<User_Action>
  /** delete data from the table: "user_app_version" */
  delete_user_app_version?: Maybe<User_App_Version_Mutation_Response>
  /** delete single row from the table: "user_app_version" */
  delete_user_app_version_by_pk?: Maybe<User_App_Version>
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>
  /** delete data from the table: "user_carts_onboarding" */
  delete_user_carts_onboarding?: Maybe<User_Carts_Onboarding_Mutation_Response>
  /** delete single row from the table: "user_carts_onboarding" */
  delete_user_carts_onboarding_by_pk?: Maybe<User_Carts_Onboarding>
  /** delete data from the table: "user_category" */
  delete_user_category?: Maybe<User_Category_Mutation_Response>
  /** delete single row from the table: "user_category" */
  delete_user_category_by_pk?: Maybe<User_Category>
  /** delete data from the table: "user_deletion" */
  delete_user_deletion?: Maybe<User_Deletion_Mutation_Response>
  /** delete single row from the table: "user_deletion" */
  delete_user_deletion_by_pk?: Maybe<User_Deletion>
  /** delete data from the table: "user_feedback" */
  delete_user_feedback?: Maybe<User_Feedback_Mutation_Response>
  /** delete single row from the table: "user_feedback" */
  delete_user_feedback_by_pk?: Maybe<User_Feedback>
  /** delete data from the table: "user_feedback_item" */
  delete_user_feedback_item?: Maybe<User_Feedback_Item_Mutation_Response>
  /** delete single row from the table: "user_feedback_item" */
  delete_user_feedback_item_by_pk?: Maybe<User_Feedback_Item>
  /** delete data from the table: "user_feedback_type" */
  delete_user_feedback_type?: Maybe<User_Feedback_Type_Mutation_Response>
  /** delete single row from the table: "user_feedback_type" */
  delete_user_feedback_type_by_pk?: Maybe<User_Feedback_Type>
  /** delete data from the table: "user_flags" */
  delete_user_flags?: Maybe<User_Flags_Mutation_Response>
  /** delete single row from the table: "user_flags" */
  delete_user_flags_by_pk?: Maybe<User_Flags>
  /** delete data from the table: "user_impersonation" */
  delete_user_impersonation?: Maybe<User_Impersonation_Mutation_Response>
  /** delete single row from the table: "user_impersonation" */
  delete_user_impersonation_by_pk?: Maybe<User_Impersonation>
  /** delete data from the table: "user_ip_address" */
  delete_user_ip_address?: Maybe<User_Ip_Address_Mutation_Response>
  /** delete single row from the table: "user_ip_address" */
  delete_user_ip_address_by_pk?: Maybe<User_Ip_Address>
  /** delete data from the table: "user_items_followed" */
  delete_user_items_followed?: Maybe<User_Items_Followed_Mutation_Response>
  /** delete single row from the table: "user_items_followed" */
  delete_user_items_followed_by_pk?: Maybe<User_Items_Followed>
  /** delete data from the table: "user_links" */
  delete_user_links?: Maybe<User_Links_Mutation_Response>
  /** delete single row from the table: "user_links" */
  delete_user_links_by_pk?: Maybe<User_Links>
  /** delete data from the table: "user_onboarding_source" */
  delete_user_onboarding_source?: Maybe<User_Onboarding_Source_Mutation_Response>
  /** delete single row from the table: "user_onboarding_source" */
  delete_user_onboarding_source_by_pk?: Maybe<User_Onboarding_Source>
  /** delete data from the table: "user_onboarding_state" */
  delete_user_onboarding_state?: Maybe<User_Onboarding_State_Mutation_Response>
  /** delete single row from the table: "user_onboarding_state" */
  delete_user_onboarding_state_by_pk?: Maybe<User_Onboarding_State>
  /** delete data from the table: "user_phone_contact" */
  delete_user_phone_contact?: Maybe<User_Phone_Contact_Mutation_Response>
  /** delete single row from the table: "user_phone_contact" */
  delete_user_phone_contact_by_pk?: Maybe<User_Phone_Contact>
  /** delete data from the table: "user_purchase_subscription" */
  delete_user_purchase_subscription?: Maybe<User_Purchase_Subscription_Mutation_Response>
  /** delete single row from the table: "user_purchase_subscription" */
  delete_user_purchase_subscription_by_pk?: Maybe<User_Purchase_Subscription>
  /** delete data from the table: "user_purchases" */
  delete_user_purchases?: Maybe<User_Purchases_Mutation_Response>
  /** delete single row from the table: "user_purchases" */
  delete_user_purchases_by_pk?: Maybe<User_Purchases>
  /** delete data from the table: "user_push_notification" */
  delete_user_push_notification?: Maybe<User_Push_Notification_Mutation_Response>
  /** delete data from the table: "user_push_notification_token" */
  delete_user_push_notification_token?: Maybe<User_Push_Notification_Token_Mutation_Response>
  /** delete single row from the table: "user_push_notification_token" */
  delete_user_push_notification_token_by_pk?: Maybe<User_Push_Notification_Token>
  /** delete data from the table: "user_referral" */
  delete_user_referral?: Maybe<User_Referral_Mutation_Response>
  /** delete single row from the table: "user_referral" */
  delete_user_referral_by_pk?: Maybe<User_Referral>
  /** delete data from the table: "user_reward_multiplier_config" */
  delete_user_reward_multiplier_config?: Maybe<User_Reward_Multiplier_Config_Mutation_Response>
  /** delete single row from the table: "user_reward_multiplier_config" */
  delete_user_reward_multiplier_config_by_pk?: Maybe<User_Reward_Multiplier_Config>
  /** delete data from the table: "user_reward_redemption" */
  delete_user_reward_redemption?: Maybe<User_Reward_Redemption_Mutation_Response>
  /** delete single row from the table: "user_reward_redemption" */
  delete_user_reward_redemption_by_pk?: Maybe<User_Reward_Redemption>
  /** delete data from the table: "user_reward_reference" */
  delete_user_reward_reference?: Maybe<User_Reward_Reference_Mutation_Response>
  /** delete single row from the table: "user_reward_reference" */
  delete_user_reward_reference_by_pk?: Maybe<User_Reward_Reference>
  /** delete data from the table: "user_shop_favorite" */
  delete_user_shop_favorite?: Maybe<User_Shop_Favorite_Mutation_Response>
  /** delete single row from the table: "user_shop_favorite" */
  delete_user_shop_favorite_by_pk?: Maybe<User_Shop_Favorite>
  /** delete data from the table: "user_shopping_preferences" */
  delete_user_shopping_preferences?: Maybe<User_Shopping_Preferences_Mutation_Response>
  /** delete single row from the table: "user_shopping_preferences" */
  delete_user_shopping_preferences_by_pk?: Maybe<User_Shopping_Preferences>
  /** delete data from the table: "user_subscription" */
  delete_user_subscription?: Maybe<User_Subscription_Mutation_Response>
  /** delete single row from the table: "user_subscription" */
  delete_user_subscription_by_pk?: Maybe<User_Subscription>
  /** delete data from the table: "user_subscription_status" */
  delete_user_subscription_status?: Maybe<User_Subscription_Status_Mutation_Response>
  /** delete single row from the table: "user_subscription_status" */
  delete_user_subscription_status_by_pk?: Maybe<User_Subscription_Status>
  /** delete data from the table: "user_temp" */
  delete_user_temp?: Maybe<User_Temp_Mutation_Response>
  /** delete single row from the table: "user_temp" */
  delete_user_temp_by_pk?: Maybe<User_Temp>
  /** delete data from the table: "worflow_type" */
  delete_worflow_type?: Maybe<Worflow_Type_Mutation_Response>
  /** delete single row from the table: "worflow_type" */
  delete_worflow_type_by_pk?: Maybe<Worflow_Type>
  /** delete data from the table: "workflow" */
  delete_workflow?: Maybe<Workflow_Mutation_Response>
  /** delete single row from the table: "workflow" */
  delete_workflow_by_pk?: Maybe<Workflow>
  /** delete data from the table: "workflow_status" */
  delete_workflow_status?: Maybe<Workflow_Status_Mutation_Response>
  /** delete single row from the table: "workflow_status" */
  delete_workflow_status_by_pk?: Maybe<Workflow_Status>
  /** A mutation to fully unsubscribe from a creator */
  fullyUnsubscribeFromCreator: FullyUnsubscribeOutput
  /** insert data into the table: "account_deletion_reason" */
  insert_account_deletion_reason?: Maybe<Account_Deletion_Reason_Mutation_Response>
  /** insert a single row into the table: "account_deletion_reason" */
  insert_account_deletion_reason_one?: Maybe<Account_Deletion_Reason>
  /** insert data into the table: "affiliate" */
  insert_affiliate?: Maybe<Affiliate_Mutation_Response>
  /** insert data into the table: "affiliate_advertiser_id" */
  insert_affiliate_advertiser_id?: Maybe<Affiliate_Advertiser_Id_Mutation_Response>
  /** insert a single row into the table: "affiliate_advertiser_id" */
  insert_affiliate_advertiser_id_one?: Maybe<Affiliate_Advertiser_Id>
  /** insert data into the table: "affiliate_meta" */
  insert_affiliate_meta?: Maybe<Affiliate_Meta_Mutation_Response>
  /** insert a single row into the table: "affiliate_meta" */
  insert_affiliate_meta_one?: Maybe<Affiliate_Meta>
  /** insert a single row into the table: "affiliate" */
  insert_affiliate_one?: Maybe<Affiliate>
  /** insert data into the table: "affiliate_transaction" */
  insert_affiliate_transaction?: Maybe<Affiliate_Transaction_Mutation_Response>
  /** insert a single row into the table: "affiliate_transaction" */
  insert_affiliate_transaction_one?: Maybe<Affiliate_Transaction>
  /** insert data into the table: "billing_mode" */
  insert_billing_mode?: Maybe<Billing_Mode_Mutation_Response>
  /** insert a single row into the table: "billing_mode" */
  insert_billing_mode_one?: Maybe<Billing_Mode>
  /** insert data into the table: "billing_platform_connected_account" */
  insert_billing_platform_connected_account?: Maybe<Billing_Platform_Connected_Account_Mutation_Response>
  /** insert a single row into the table: "billing_platform_connected_account" */
  insert_billing_platform_connected_account_one?: Maybe<Billing_Platform_Connected_Account>
  /** insert data into the table: "billing_platform_connected_account_status" */
  insert_billing_platform_connected_account_status?: Maybe<Billing_Platform_Connected_Account_Status_Mutation_Response>
  /** insert a single row into the table: "billing_platform_connected_account_status" */
  insert_billing_platform_connected_account_status_one?: Maybe<Billing_Platform_Connected_Account_Status>
  /** insert data into the table: "billing_platform_customer" */
  insert_billing_platform_customer?: Maybe<Billing_Platform_Customer_Mutation_Response>
  /** insert a single row into the table: "billing_platform_customer" */
  insert_billing_platform_customer_one?: Maybe<Billing_Platform_Customer>
  /** insert data into the table: "cart" */
  insert_cart?: Maybe<Cart_Mutation_Response>
  /** insert data into the table: "cart_item" */
  insert_cart_item?: Maybe<Cart_Item_Mutation_Response>
  /** insert data into the table: "cart_item_like" */
  insert_cart_item_like?: Maybe<Cart_Item_Like_Mutation_Response>
  /** insert a single row into the table: "cart_item_like" */
  insert_cart_item_like_one?: Maybe<Cart_Item_Like>
  /** insert data into the table: "cart_item_note" */
  insert_cart_item_note?: Maybe<Cart_Item_Note_Mutation_Response>
  /** insert a single row into the table: "cart_item_note" */
  insert_cart_item_note_one?: Maybe<Cart_Item_Note>
  /** insert a single row into the table: "cart_item" */
  insert_cart_item_one?: Maybe<Cart_Item>
  /** insert a single row into the table: "cart" */
  insert_cart_one?: Maybe<Cart>
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<Categories_Mutation_Response>
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<Categories>
  /** insert data into the table: "category_shop_item" */
  insert_category_shop_item?: Maybe<Category_Shop_Item_Mutation_Response>
  /** insert a single row into the table: "category_shop_item" */
  insert_category_shop_item_one?: Maybe<Category_Shop_Item>
  /** insert data into the table: "checkout" */
  insert_checkout?: Maybe<Checkout_Mutation_Response>
  /** insert a single row into the table: "checkout" */
  insert_checkout_one?: Maybe<Checkout>
  /** insert data into the table: "collection" */
  insert_collection?: Maybe<Collection_Mutation_Response>
  /** insert data into the table: "collection_cart_item" */
  insert_collection_cart_item?: Maybe<Collection_Cart_Item_Mutation_Response>
  /** insert a single row into the table: "collection_cart_item" */
  insert_collection_cart_item_one?: Maybe<Collection_Cart_Item>
  /** insert data into the table: "collection_collaborator" */
  insert_collection_collaborator?: Maybe<Collection_Collaborator_Mutation_Response>
  /** insert a single row into the table: "collection_collaborator" */
  insert_collection_collaborator_one?: Maybe<Collection_Collaborator>
  /** insert data into the table: "collection_invite" */
  insert_collection_invite?: Maybe<Collection_Invite_Mutation_Response>
  /** insert a single row into the table: "collection_invite" */
  insert_collection_invite_one?: Maybe<Collection_Invite>
  /** insert data into the table: "collection_invite_status" */
  insert_collection_invite_status?: Maybe<Collection_Invite_Status_Mutation_Response>
  /** insert a single row into the table: "collection_invite_status" */
  insert_collection_invite_status_one?: Maybe<Collection_Invite_Status>
  /** insert data into the table: "collection_item_claim" */
  insert_collection_item_claim?: Maybe<Collection_Item_Claim_Mutation_Response>
  /** insert a single row into the table: "collection_item_claim" */
  insert_collection_item_claim_one?: Maybe<Collection_Item_Claim>
  /** insert data into the table: "collection_item_claim_status" */
  insert_collection_item_claim_status?: Maybe<Collection_Item_Claim_Status_Mutation_Response>
  /** insert a single row into the table: "collection_item_claim_status" */
  insert_collection_item_claim_status_one?: Maybe<Collection_Item_Claim_Status>
  /** insert a single row into the table: "collection" */
  insert_collection_one?: Maybe<Collection>
  /** insert data into the table: "collection_section" */
  insert_collection_section?: Maybe<Collection_Section_Mutation_Response>
  /** insert data into the table: "collection_section_cart_item" */
  insert_collection_section_cart_item?: Maybe<Collection_Section_Cart_Item_Mutation_Response>
  /** insert a single row into the table: "collection_section_cart_item" */
  insert_collection_section_cart_item_one?: Maybe<Collection_Section_Cart_Item>
  /** insert a single row into the table: "collection_section" */
  insert_collection_section_one?: Maybe<Collection_Section>
  /** insert data into the table: "collection_slug_history" */
  insert_collection_slug_history?: Maybe<Collection_Slug_History_Mutation_Response>
  /** insert a single row into the table: "collection_slug_history" */
  insert_collection_slug_history_one?: Maybe<Collection_Slug_History>
  /** insert data into the table: "collection_state" */
  insert_collection_state?: Maybe<Collection_State_Mutation_Response>
  /** insert a single row into the table: "collection_state" */
  insert_collection_state_one?: Maybe<Collection_State>
  /** insert data into the table: "collection_type" */
  insert_collection_type?: Maybe<Collection_Type_Mutation_Response>
  /** insert a single row into the table: "collection_type" */
  insert_collection_type_one?: Maybe<Collection_Type>
  /** insert data into the table: "comment" */
  insert_comment?: Maybe<Comment_Mutation_Response>
  /** insert a single row into the table: "comment" */
  insert_comment_one?: Maybe<Comment>
  /** insert data into the table: "commentable_item" */
  insert_commentable_item?: Maybe<Commentable_Item_Mutation_Response>
  /** insert a single row into the table: "commentable_item" */
  insert_commentable_item_one?: Maybe<Commentable_Item>
  /** insert data into the table: "commentable_item_type" */
  insert_commentable_item_type?: Maybe<Commentable_Item_Type_Mutation_Response>
  /** insert a single row into the table: "commentable_item_type" */
  insert_commentable_item_type_one?: Maybe<Commentable_Item_Type>
  /** insert data into the table: "communication_template" */
  insert_communication_template?: Maybe<Communication_Template_Mutation_Response>
  /** insert data into the table: "communication_template_full_list" */
  insert_communication_template_full_list?: Maybe<Communication_Template_Full_List_Mutation_Response>
  /** insert a single row into the table: "communication_template_full_list" */
  insert_communication_template_full_list_one?: Maybe<Communication_Template_Full_List>
  /** insert a single row into the table: "communication_template" */
  insert_communication_template_one?: Maybe<Communication_Template>
  /** insert data into the table: "contact_info_type" */
  insert_contact_info_type?: Maybe<Contact_Info_Type_Mutation_Response>
  /** insert a single row into the table: "contact_info_type" */
  insert_contact_info_type_one?: Maybe<Contact_Info_Type>
  /** insert data into the table: "contact_invite" */
  insert_contact_invite?: Maybe<Contact_Invite_Mutation_Response>
  /** insert a single row into the table: "contact_invite" */
  insert_contact_invite_one?: Maybe<Contact_Invite>
  /** insert data into the table: "contact_invite_status" */
  insert_contact_invite_status?: Maybe<Contact_Invite_Status_Mutation_Response>
  /** insert a single row into the table: "contact_invite_status" */
  insert_contact_invite_status_one?: Maybe<Contact_Invite_Status>
  /** insert data into the table: "contact_invite_type" */
  insert_contact_invite_type?: Maybe<Contact_Invite_Type_Mutation_Response>
  /** insert a single row into the table: "contact_invite_type" */
  insert_contact_invite_type_one?: Maybe<Contact_Invite_Type>
  /** insert data into the table: "deal_hop_gate" */
  insert_deal_hop_gate?: Maybe<Deal_Hop_Gate_Mutation_Response>
  /** insert a single row into the table: "deal_hop_gate" */
  insert_deal_hop_gate_one?: Maybe<Deal_Hop_Gate>
  /** insert data into the table: "device_client_data" */
  insert_device_client_data?: Maybe<Device_Client_Data_Mutation_Response>
  /** insert a single row into the table: "device_client_data" */
  insert_device_client_data_one?: Maybe<Device_Client_Data>
  /** insert data into the table: "discovery_backlog" */
  insert_discovery_backlog?: Maybe<Discovery_Backlog_Mutation_Response>
  /** insert a single row into the table: "discovery_backlog" */
  insert_discovery_backlog_one?: Maybe<Discovery_Backlog>
  /** insert data into the table: "discovery_feed" */
  insert_discovery_feed?: Maybe<Discovery_Feed_Mutation_Response>
  /** insert a single row into the table: "discovery_feed" */
  insert_discovery_feed_one?: Maybe<Discovery_Feed>
  /** insert data into the table: "discovery_staging" */
  insert_discovery_staging?: Maybe<Discovery_Staging_Mutation_Response>
  /** insert a single row into the table: "discovery_staging" */
  insert_discovery_staging_one?: Maybe<Discovery_Staging>
  /** insert data into the table: "featured_shop" */
  insert_featured_shop?: Maybe<Featured_Shop_Mutation_Response>
  /** insert data into the table: "featured_shop_item" */
  insert_featured_shop_item?: Maybe<Featured_Shop_Item_Mutation_Response>
  /** insert a single row into the table: "featured_shop_item" */
  insert_featured_shop_item_one?: Maybe<Featured_Shop_Item>
  /** insert a single row into the table: "featured_shop" */
  insert_featured_shop_one?: Maybe<Featured_Shop>
  /** insert data into the table: "feed_item" */
  insert_feed_item?: Maybe<Feed_Item_Mutation_Response>
  /** insert data into the table: "feed_item_like" */
  insert_feed_item_like?: Maybe<Feed_Item_Like_Mutation_Response>
  /** insert a single row into the table: "feed_item_like" */
  insert_feed_item_like_one?: Maybe<Feed_Item_Like>
  /** insert a single row into the table: "feed_item" */
  insert_feed_item_one?: Maybe<Feed_Item>
  /** insert data into the table: "feed_item_type" */
  insert_feed_item_type?: Maybe<Feed_Item_Type_Mutation_Response>
  /** insert a single row into the table: "feed_item_type" */
  insert_feed_item_type_one?: Maybe<Feed_Item_Type>
  /** insert data into the table: "fmtc_deal" */
  insert_fmtc_deal?: Maybe<Fmtc_Deal_Mutation_Response>
  /** insert a single row into the table: "fmtc_deal" */
  insert_fmtc_deal_one?: Maybe<Fmtc_Deal>
  /** insert data into the table: "followable_item" */
  insert_followable_item?: Maybe<Followable_Item_Mutation_Response>
  /** insert a single row into the table: "followable_item" */
  insert_followable_item_one?: Maybe<Followable_Item>
  /** insert data into the table: "followable_item_type" */
  insert_followable_item_type?: Maybe<Followable_Item_Type_Mutation_Response>
  /** insert a single row into the table: "followable_item_type" */
  insert_followable_item_type_one?: Maybe<Followable_Item_Type>
  /** insert data into the table: "leaderboard_event" */
  insert_leaderboard_event?: Maybe<Leaderboard_Event_Mutation_Response>
  /** insert data into the table: "leaderboard_event_name" */
  insert_leaderboard_event_name?: Maybe<Leaderboard_Event_Name_Mutation_Response>
  /** insert a single row into the table: "leaderboard_event_name" */
  insert_leaderboard_event_name_one?: Maybe<Leaderboard_Event_Name>
  /** insert a single row into the table: "leaderboard_event" */
  insert_leaderboard_event_one?: Maybe<Leaderboard_Event>
  /** insert data into the table: "link_type" */
  insert_link_type?: Maybe<Link_Type_Mutation_Response>
  /** insert a single row into the table: "link_type" */
  insert_link_type_one?: Maybe<Link_Type>
  /** insert data into the table: "media_enabled_item" */
  insert_media_enabled_item?: Maybe<Media_Enabled_Item_Mutation_Response>
  /** insert a single row into the table: "media_enabled_item" */
  insert_media_enabled_item_one?: Maybe<Media_Enabled_Item>
  /** insert data into the table: "media_item" */
  insert_media_item?: Maybe<Media_Item_Mutation_Response>
  /** insert a single row into the table: "media_item" */
  insert_media_item_one?: Maybe<Media_Item>
  /** insert data into the table: "media_item_type" */
  insert_media_item_type?: Maybe<Media_Item_Type_Mutation_Response>
  /** insert a single row into the table: "media_item_type" */
  insert_media_item_type_one?: Maybe<Media_Item_Type>
  /** insert data into the table: "media_items" */
  insert_media_items?: Maybe<Media_Items_Mutation_Response>
  /** insert a single row into the table: "media_items" */
  insert_media_items_one?: Maybe<Media_Items>
  /** insert data into the table: "media_type" */
  insert_media_type?: Maybe<Media_Type_Mutation_Response>
  /** insert a single row into the table: "media_type" */
  insert_media_type_one?: Maybe<Media_Type>
  /** insert data into the table: "mobile_app_home" */
  insert_mobile_app_home?: Maybe<Mobile_App_Home_Mutation_Response>
  /** insert a single row into the table: "mobile_app_home" */
  insert_mobile_app_home_one?: Maybe<Mobile_App_Home>
  /** insert data into the table: "mobile_home" */
  insert_mobile_home?: Maybe<Mobile_Home_Mutation_Response>
  /** insert a single row into the table: "mobile_home" */
  insert_mobile_home_one?: Maybe<Mobile_Home>
  /** insert data into the table: "mobile_push_notification" */
  insert_mobile_push_notification?: Maybe<Mobile_Push_Notification_Mutation_Response>
  /** insert a single row into the table: "mobile_push_notification" */
  insert_mobile_push_notification_one?: Maybe<Mobile_Push_Notification>
  /** insert data into the table: "mobile_push_notification_status" */
  insert_mobile_push_notification_status?: Maybe<Mobile_Push_Notification_Status_Mutation_Response>
  /** insert a single row into the table: "mobile_push_notification_status" */
  insert_mobile_push_notification_status_one?: Maybe<Mobile_Push_Notification_Status>
  /** insert data into the table: "notifiable_item" */
  insert_notifiable_item?: Maybe<Notifiable_Item_Mutation_Response>
  /** insert a single row into the table: "notifiable_item" */
  insert_notifiable_item_one?: Maybe<Notifiable_Item>
  /** insert data into the table: "notifiable_item_type" */
  insert_notifiable_item_type?: Maybe<Notifiable_Item_Type_Mutation_Response>
  /** insert a single row into the table: "notifiable_item_type" */
  insert_notifiable_item_type_one?: Maybe<Notifiable_Item_Type>
  /** insert data into the table: "notification" */
  insert_notification?: Maybe<Notification_Mutation_Response>
  /** insert data into the table: "notification_center_item" */
  insert_notification_center_item?: Maybe<Notification_Center_Item_Mutation_Response>
  /** insert a single row into the table: "notification_center_item" */
  insert_notification_center_item_one?: Maybe<Notification_Center_Item>
  /** insert data into the table: "notification_center_item_read_status" */
  insert_notification_center_item_read_status?: Maybe<Notification_Center_Item_Read_Status_Mutation_Response>
  /** insert a single row into the table: "notification_center_item_read_status" */
  insert_notification_center_item_read_status_one?: Maybe<Notification_Center_Item_Read_Status>
  /** insert data into the table: "notification_center_item_type" */
  insert_notification_center_item_type?: Maybe<Notification_Center_Item_Type_Mutation_Response>
  /** insert a single row into the table: "notification_center_item_type" */
  insert_notification_center_item_type_one?: Maybe<Notification_Center_Item_Type>
  /** insert a single row into the table: "notification" */
  insert_notification_one?: Maybe<Notification>
  /** insert data into the table: "notification_preference" */
  insert_notification_preference?: Maybe<Notification_Preference_Mutation_Response>
  /** insert a single row into the table: "notification_preference" */
  insert_notification_preference_one?: Maybe<Notification_Preference>
  /** insert data into the table: "notification_status" */
  insert_notification_status?: Maybe<Notification_Status_Mutation_Response>
  /** insert a single row into the table: "notification_status" */
  insert_notification_status_one?: Maybe<Notification_Status>
  /** insert data into the table: "onboarding_categories" */
  insert_onboarding_categories?: Maybe<Onboarding_Categories_Mutation_Response>
  /** insert a single row into the table: "onboarding_categories" */
  insert_onboarding_categories_one?: Maybe<Onboarding_Categories>
  /** insert data into the table: "onboarding_sources" */
  insert_onboarding_sources?: Maybe<Onboarding_Sources_Mutation_Response>
  /** insert a single row into the table: "onboarding_sources" */
  insert_onboarding_sources_one?: Maybe<Onboarding_Sources>
  /** insert data into the table: "one_off_email" */
  insert_one_off_email?: Maybe<One_Off_Email_Mutation_Response>
  /** insert a single row into the table: "one_off_email" */
  insert_one_off_email_one?: Maybe<One_Off_Email>
  /** insert data into the table: "pageview_event" */
  insert_pageview_event?: Maybe<Pageview_Event_Mutation_Response>
  /** insert a single row into the table: "pageview_event" */
  insert_pageview_event_one?: Maybe<Pageview_Event>
  /** insert data into the table: "promo_period" */
  insert_promo_period?: Maybe<Promo_Period_Mutation_Response>
  /** insert a single row into the table: "promo_period" */
  insert_promo_period_one?: Maybe<Promo_Period>
  /** insert data into the table: "push_notification" */
  insert_push_notification?: Maybe<Push_Notification_Mutation_Response>
  /** insert a single row into the table: "push_notification" */
  insert_push_notification_one?: Maybe<Push_Notification>
  /** insert data into the table: "push_notification_status" */
  insert_push_notification_status?: Maybe<Push_Notification_Status_Mutation_Response>
  /** insert a single row into the table: "push_notification_status" */
  insert_push_notification_status_one?: Maybe<Push_Notification_Status>
  /** insert data into the table: "redirect_ids" */
  insert_redirect_ids?: Maybe<Redirect_Ids_Mutation_Response>
  /** insert a single row into the table: "redirect_ids" */
  insert_redirect_ids_one?: Maybe<Redirect_Ids>
  /** insert data into the table: "remove_reason" */
  insert_remove_reason?: Maybe<Remove_Reason_Mutation_Response>
  /** insert a single row into the table: "remove_reason" */
  insert_remove_reason_one?: Maybe<Remove_Reason>
  /** insert data into the table: "rewardable_action_names" */
  insert_rewardable_action_names?: Maybe<Rewardable_Action_Names_Mutation_Response>
  /** insert a single row into the table: "rewardable_action_names" */
  insert_rewardable_action_names_one?: Maybe<Rewardable_Action_Names>
  /** insert data into the table: "rewardable_item_type" */
  insert_rewardable_item_type?: Maybe<Rewardable_Item_Type_Mutation_Response>
  /** insert a single row into the table: "rewardable_item_type" */
  insert_rewardable_item_type_one?: Maybe<Rewardable_Item_Type>
  /** insert data into the table: "search_query" */
  insert_search_query?: Maybe<Search_Query_Mutation_Response>
  /** insert a single row into the table: "search_query" */
  insert_search_query_one?: Maybe<Search_Query>
  /** insert data into the table: "search_query_result" */
  insert_search_query_result?: Maybe<Search_Query_Result_Mutation_Response>
  /** insert a single row into the table: "search_query_result" */
  insert_search_query_result_one?: Maybe<Search_Query_Result>
  /** insert data into the table: "shop" */
  insert_shop?: Maybe<Shop_Mutation_Response>
  /** insert data into the table: "shop_affiliate" */
  insert_shop_affiliate?: Maybe<Shop_Affiliate_Mutation_Response>
  /** insert a single row into the table: "shop_affiliate" */
  insert_shop_affiliate_one?: Maybe<Shop_Affiliate>
  /** insert data into the table: "shop_categorization" */
  insert_shop_categorization?: Maybe<Shop_Categorization_Mutation_Response>
  /** insert a single row into the table: "shop_categorization" */
  insert_shop_categorization_one?: Maybe<Shop_Categorization>
  /** insert data into the table: "shop_category" */
  insert_shop_category?: Maybe<Shop_Category_Mutation_Response>
  /** insert a single row into the table: "shop_category" */
  insert_shop_category_one?: Maybe<Shop_Category>
  /** insert data into the table: "shop_item" */
  insert_shop_item?: Maybe<Shop_Item_Mutation_Response>
  /** insert data into the table: "shop_item_availability" */
  insert_shop_item_availability?: Maybe<Shop_Item_Availability_Mutation_Response>
  /** insert a single row into the table: "shop_item_availability" */
  insert_shop_item_availability_one?: Maybe<Shop_Item_Availability>
  /** insert data into the table: "shop_item_enrichment_status" */
  insert_shop_item_enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Mutation_Response>
  /** insert a single row into the table: "shop_item_enrichment_status" */
  insert_shop_item_enrichment_status_one?: Maybe<Shop_Item_Enrichment_Status>
  /** insert data into the table: "shop_item_history" */
  insert_shop_item_history?: Maybe<Shop_Item_History_Mutation_Response>
  /** insert a single row into the table: "shop_item_history" */
  insert_shop_item_history_one?: Maybe<Shop_Item_History>
  /** insert data into the table: "shop_item_image" */
  insert_shop_item_image?: Maybe<Shop_Item_Image_Mutation_Response>
  /** insert a single row into the table: "shop_item_image" */
  insert_shop_item_image_one?: Maybe<Shop_Item_Image>
  /** insert data into the table: "shop_item_index" */
  insert_shop_item_index?: Maybe<Shop_Item_Index_Mutation_Response>
  /** insert a single row into the table: "shop_item_index" */
  insert_shop_item_index_one?: Maybe<Shop_Item_Index>
  /** insert a single row into the table: "shop_item" */
  insert_shop_item_one?: Maybe<Shop_Item>
  /** insert data into the table: "shop_item_to_be_scraped" */
  insert_shop_item_to_be_scraped?: Maybe<Shop_Item_To_Be_Scraped_Mutation_Response>
  /** insert a single row into the table: "shop_item_to_be_scraped" */
  insert_shop_item_to_be_scraped_one?: Maybe<Shop_Item_To_Be_Scraped>
  /** insert a single row into the table: "shop" */
  insert_shop_one?: Maybe<Shop>
  /** insert data into the table: "shop_rating" */
  insert_shop_rating?: Maybe<Shop_Rating_Mutation_Response>
  /** insert a single row into the table: "shop_rating" */
  insert_shop_rating_one?: Maybe<Shop_Rating>
  /** insert data into the table: "similar_products_history" */
  insert_similar_products_history?: Maybe<Similar_Products_History_Mutation_Response>
  /** insert a single row into the table: "similar_products_history" */
  insert_similar_products_history_one?: Maybe<Similar_Products_History>
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>
  /** insert data into the table: "user_account_type" */
  insert_user_account_type?: Maybe<User_Account_Type_Mutation_Response>
  /** insert a single row into the table: "user_account_type" */
  insert_user_account_type_one?: Maybe<User_Account_Type>
  /** insert data into the table: "user_action" */
  insert_user_action?: Maybe<User_Action_Mutation_Response>
  /** insert a single row into the table: "user_action" */
  insert_user_action_one?: Maybe<User_Action>
  /** insert data into the table: "user_app_version" */
  insert_user_app_version?: Maybe<User_App_Version_Mutation_Response>
  /** insert a single row into the table: "user_app_version" */
  insert_user_app_version_one?: Maybe<User_App_Version>
  /** insert data into the table: "user_carts_onboarding" */
  insert_user_carts_onboarding?: Maybe<User_Carts_Onboarding_Mutation_Response>
  /** insert a single row into the table: "user_carts_onboarding" */
  insert_user_carts_onboarding_one?: Maybe<User_Carts_Onboarding>
  /** insert data into the table: "user_category" */
  insert_user_category?: Maybe<User_Category_Mutation_Response>
  /** insert a single row into the table: "user_category" */
  insert_user_category_one?: Maybe<User_Category>
  /** insert data into the table: "user_deletion" */
  insert_user_deletion?: Maybe<User_Deletion_Mutation_Response>
  /** insert a single row into the table: "user_deletion" */
  insert_user_deletion_one?: Maybe<User_Deletion>
  /** insert data into the table: "user_feedback" */
  insert_user_feedback?: Maybe<User_Feedback_Mutation_Response>
  /** insert data into the table: "user_feedback_item" */
  insert_user_feedback_item?: Maybe<User_Feedback_Item_Mutation_Response>
  /** insert a single row into the table: "user_feedback_item" */
  insert_user_feedback_item_one?: Maybe<User_Feedback_Item>
  /** insert a single row into the table: "user_feedback" */
  insert_user_feedback_one?: Maybe<User_Feedback>
  /** insert data into the table: "user_feedback_type" */
  insert_user_feedback_type?: Maybe<User_Feedback_Type_Mutation_Response>
  /** insert a single row into the table: "user_feedback_type" */
  insert_user_feedback_type_one?: Maybe<User_Feedback_Type>
  /** insert data into the table: "user_flags" */
  insert_user_flags?: Maybe<User_Flags_Mutation_Response>
  /** insert a single row into the table: "user_flags" */
  insert_user_flags_one?: Maybe<User_Flags>
  /** insert data into the table: "user_impersonation" */
  insert_user_impersonation?: Maybe<User_Impersonation_Mutation_Response>
  /** insert a single row into the table: "user_impersonation" */
  insert_user_impersonation_one?: Maybe<User_Impersonation>
  /** insert data into the table: "user_ip_address" */
  insert_user_ip_address?: Maybe<User_Ip_Address_Mutation_Response>
  /** insert a single row into the table: "user_ip_address" */
  insert_user_ip_address_one?: Maybe<User_Ip_Address>
  /** insert data into the table: "user_items_followed" */
  insert_user_items_followed?: Maybe<User_Items_Followed_Mutation_Response>
  /** insert a single row into the table: "user_items_followed" */
  insert_user_items_followed_one?: Maybe<User_Items_Followed>
  /** insert data into the table: "user_links" */
  insert_user_links?: Maybe<User_Links_Mutation_Response>
  /** insert a single row into the table: "user_links" */
  insert_user_links_one?: Maybe<User_Links>
  /** insert data into the table: "user_onboarding_source" */
  insert_user_onboarding_source?: Maybe<User_Onboarding_Source_Mutation_Response>
  /** insert a single row into the table: "user_onboarding_source" */
  insert_user_onboarding_source_one?: Maybe<User_Onboarding_Source>
  /** insert data into the table: "user_onboarding_state" */
  insert_user_onboarding_state?: Maybe<User_Onboarding_State_Mutation_Response>
  /** insert a single row into the table: "user_onboarding_state" */
  insert_user_onboarding_state_one?: Maybe<User_Onboarding_State>
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>
  /** insert data into the table: "user_phone_contact" */
  insert_user_phone_contact?: Maybe<User_Phone_Contact_Mutation_Response>
  /** insert a single row into the table: "user_phone_contact" */
  insert_user_phone_contact_one?: Maybe<User_Phone_Contact>
  /** insert data into the table: "user_purchase_subscription" */
  insert_user_purchase_subscription?: Maybe<User_Purchase_Subscription_Mutation_Response>
  /** insert a single row into the table: "user_purchase_subscription" */
  insert_user_purchase_subscription_one?: Maybe<User_Purchase_Subscription>
  /** insert data into the table: "user_purchases" */
  insert_user_purchases?: Maybe<User_Purchases_Mutation_Response>
  /** insert a single row into the table: "user_purchases" */
  insert_user_purchases_one?: Maybe<User_Purchases>
  /** insert data into the table: "user_push_notification" */
  insert_user_push_notification?: Maybe<User_Push_Notification_Mutation_Response>
  /** insert a single row into the table: "user_push_notification" */
  insert_user_push_notification_one?: Maybe<User_Push_Notification>
  /** insert data into the table: "user_push_notification_token" */
  insert_user_push_notification_token?: Maybe<User_Push_Notification_Token_Mutation_Response>
  /** insert a single row into the table: "user_push_notification_token" */
  insert_user_push_notification_token_one?: Maybe<User_Push_Notification_Token>
  /** insert data into the table: "user_referral" */
  insert_user_referral?: Maybe<User_Referral_Mutation_Response>
  /** insert a single row into the table: "user_referral" */
  insert_user_referral_one?: Maybe<User_Referral>
  /** insert data into the table: "user_reward_multiplier_config" */
  insert_user_reward_multiplier_config?: Maybe<User_Reward_Multiplier_Config_Mutation_Response>
  /** insert a single row into the table: "user_reward_multiplier_config" */
  insert_user_reward_multiplier_config_one?: Maybe<User_Reward_Multiplier_Config>
  /** insert data into the table: "user_reward_redemption" */
  insert_user_reward_redemption?: Maybe<User_Reward_Redemption_Mutation_Response>
  /** insert a single row into the table: "user_reward_redemption" */
  insert_user_reward_redemption_one?: Maybe<User_Reward_Redemption>
  /** insert data into the table: "user_reward_reference" */
  insert_user_reward_reference?: Maybe<User_Reward_Reference_Mutation_Response>
  /** insert a single row into the table: "user_reward_reference" */
  insert_user_reward_reference_one?: Maybe<User_Reward_Reference>
  /** insert data into the table: "user_shop_favorite" */
  insert_user_shop_favorite?: Maybe<User_Shop_Favorite_Mutation_Response>
  /** insert a single row into the table: "user_shop_favorite" */
  insert_user_shop_favorite_one?: Maybe<User_Shop_Favorite>
  /** insert data into the table: "user_shopping_preferences" */
  insert_user_shopping_preferences?: Maybe<User_Shopping_Preferences_Mutation_Response>
  /** insert a single row into the table: "user_shopping_preferences" */
  insert_user_shopping_preferences_one?: Maybe<User_Shopping_Preferences>
  /** insert data into the table: "user_subscription" */
  insert_user_subscription?: Maybe<User_Subscription_Mutation_Response>
  /** insert a single row into the table: "user_subscription" */
  insert_user_subscription_one?: Maybe<User_Subscription>
  /** insert data into the table: "user_subscription_status" */
  insert_user_subscription_status?: Maybe<User_Subscription_Status_Mutation_Response>
  /** insert a single row into the table: "user_subscription_status" */
  insert_user_subscription_status_one?: Maybe<User_Subscription_Status>
  /** insert data into the table: "user_temp" */
  insert_user_temp?: Maybe<User_Temp_Mutation_Response>
  /** insert a single row into the table: "user_temp" */
  insert_user_temp_one?: Maybe<User_Temp>
  /** insert data into the table: "worflow_type" */
  insert_worflow_type?: Maybe<Worflow_Type_Mutation_Response>
  /** insert a single row into the table: "worflow_type" */
  insert_worflow_type_one?: Maybe<Worflow_Type>
  /** insert data into the table: "workflow" */
  insert_workflow?: Maybe<Workflow_Mutation_Response>
  /** insert a single row into the table: "workflow" */
  insert_workflow_one?: Maybe<Workflow>
  /** insert data into the table: "workflow_status" */
  insert_workflow_status?: Maybe<Workflow_Status_Mutation_Response>
  /** insert a single row into the table: "workflow_status" */
  insert_workflow_status_one?: Maybe<Workflow_Status>
  /** This action is to be able to invite a collection collaborator via either their user id or via an email address */
  inviteCollaborator?: Maybe<CollectionInviteOutput>
  /** This action is to be able to like feed items by just passing in their item id */
  likeFeedItem?: Maybe<FeedItemLikeOutput>
  /** An action to follow a creator for free */
  subscribeToCreator: CreateSubscriptionOutput
  /** update data of the table: "account_deletion_reason" */
  update_account_deletion_reason?: Maybe<Account_Deletion_Reason_Mutation_Response>
  /** update single row of the table: "account_deletion_reason" */
  update_account_deletion_reason_by_pk?: Maybe<Account_Deletion_Reason>
  /** update multiples rows of table: "account_deletion_reason" */
  update_account_deletion_reason_many?: Maybe<
    Array<Maybe<Account_Deletion_Reason_Mutation_Response>>
  >
  /** update data of the table: "affiliate" */
  update_affiliate?: Maybe<Affiliate_Mutation_Response>
  /** update data of the table: "affiliate_advertiser_id" */
  update_affiliate_advertiser_id?: Maybe<Affiliate_Advertiser_Id_Mutation_Response>
  /** update single row of the table: "affiliate_advertiser_id" */
  update_affiliate_advertiser_id_by_pk?: Maybe<Affiliate_Advertiser_Id>
  /** update multiples rows of table: "affiliate_advertiser_id" */
  update_affiliate_advertiser_id_many?: Maybe<
    Array<Maybe<Affiliate_Advertiser_Id_Mutation_Response>>
  >
  /** update single row of the table: "affiliate" */
  update_affiliate_by_pk?: Maybe<Affiliate>
  /** update multiples rows of table: "affiliate" */
  update_affiliate_many?: Maybe<Array<Maybe<Affiliate_Mutation_Response>>>
  /** update data of the table: "affiliate_meta" */
  update_affiliate_meta?: Maybe<Affiliate_Meta_Mutation_Response>
  /** update single row of the table: "affiliate_meta" */
  update_affiliate_meta_by_pk?: Maybe<Affiliate_Meta>
  /** update multiples rows of table: "affiliate_meta" */
  update_affiliate_meta_many?: Maybe<
    Array<Maybe<Affiliate_Meta_Mutation_Response>>
  >
  /** update data of the table: "affiliate_transaction" */
  update_affiliate_transaction?: Maybe<Affiliate_Transaction_Mutation_Response>
  /** update single row of the table: "affiliate_transaction" */
  update_affiliate_transaction_by_pk?: Maybe<Affiliate_Transaction>
  /** update multiples rows of table: "affiliate_transaction" */
  update_affiliate_transaction_many?: Maybe<
    Array<Maybe<Affiliate_Transaction_Mutation_Response>>
  >
  /** update data of the table: "billing_mode" */
  update_billing_mode?: Maybe<Billing_Mode_Mutation_Response>
  /** update single row of the table: "billing_mode" */
  update_billing_mode_by_pk?: Maybe<Billing_Mode>
  /** update multiples rows of table: "billing_mode" */
  update_billing_mode_many?: Maybe<Array<Maybe<Billing_Mode_Mutation_Response>>>
  /** update data of the table: "billing_platform_connected_account" */
  update_billing_platform_connected_account?: Maybe<Billing_Platform_Connected_Account_Mutation_Response>
  /** update single row of the table: "billing_platform_connected_account" */
  update_billing_platform_connected_account_by_pk?: Maybe<Billing_Platform_Connected_Account>
  /** update multiples rows of table: "billing_platform_connected_account" */
  update_billing_platform_connected_account_many?: Maybe<
    Array<Maybe<Billing_Platform_Connected_Account_Mutation_Response>>
  >
  /** update data of the table: "billing_platform_connected_account_status" */
  update_billing_platform_connected_account_status?: Maybe<Billing_Platform_Connected_Account_Status_Mutation_Response>
  /** update single row of the table: "billing_platform_connected_account_status" */
  update_billing_platform_connected_account_status_by_pk?: Maybe<Billing_Platform_Connected_Account_Status>
  /** update multiples rows of table: "billing_platform_connected_account_status" */
  update_billing_platform_connected_account_status_many?: Maybe<
    Array<Maybe<Billing_Platform_Connected_Account_Status_Mutation_Response>>
  >
  /** update data of the table: "billing_platform_customer" */
  update_billing_platform_customer?: Maybe<Billing_Platform_Customer_Mutation_Response>
  /** update single row of the table: "billing_platform_customer" */
  update_billing_platform_customer_by_pk?: Maybe<Billing_Platform_Customer>
  /** update multiples rows of table: "billing_platform_customer" */
  update_billing_platform_customer_many?: Maybe<
    Array<Maybe<Billing_Platform_Customer_Mutation_Response>>
  >
  /** update data of the table: "cart" */
  update_cart?: Maybe<Cart_Mutation_Response>
  /** update single row of the table: "cart" */
  update_cart_by_pk?: Maybe<Cart>
  /** update data of the table: "cart_item" */
  update_cart_item?: Maybe<Cart_Item_Mutation_Response>
  /** update single row of the table: "cart_item" */
  update_cart_item_by_pk?: Maybe<Cart_Item>
  /** update data of the table: "cart_item_like" */
  update_cart_item_like?: Maybe<Cart_Item_Like_Mutation_Response>
  /** update single row of the table: "cart_item_like" */
  update_cart_item_like_by_pk?: Maybe<Cart_Item_Like>
  /** update multiples rows of table: "cart_item_like" */
  update_cart_item_like_many?: Maybe<
    Array<Maybe<Cart_Item_Like_Mutation_Response>>
  >
  /** update multiples rows of table: "cart_item" */
  update_cart_item_many?: Maybe<Array<Maybe<Cart_Item_Mutation_Response>>>
  /** update data of the table: "cart_item_note" */
  update_cart_item_note?: Maybe<Cart_Item_Note_Mutation_Response>
  /** update single row of the table: "cart_item_note" */
  update_cart_item_note_by_pk?: Maybe<Cart_Item_Note>
  /** update multiples rows of table: "cart_item_note" */
  update_cart_item_note_many?: Maybe<
    Array<Maybe<Cart_Item_Note_Mutation_Response>>
  >
  /** update multiples rows of table: "cart" */
  update_cart_many?: Maybe<Array<Maybe<Cart_Mutation_Response>>>
  /** update data of the table: "categories" */
  update_categories?: Maybe<Categories_Mutation_Response>
  /** update single row of the table: "categories" */
  update_categories_by_pk?: Maybe<Categories>
  /** update multiples rows of table: "categories" */
  update_categories_many?: Maybe<Array<Maybe<Categories_Mutation_Response>>>
  /** update data of the table: "category_shop_item" */
  update_category_shop_item?: Maybe<Category_Shop_Item_Mutation_Response>
  /** update single row of the table: "category_shop_item" */
  update_category_shop_item_by_pk?: Maybe<Category_Shop_Item>
  /** update multiples rows of table: "category_shop_item" */
  update_category_shop_item_many?: Maybe<
    Array<Maybe<Category_Shop_Item_Mutation_Response>>
  >
  /** update data of the table: "checkout" */
  update_checkout?: Maybe<Checkout_Mutation_Response>
  /** update single row of the table: "checkout" */
  update_checkout_by_pk?: Maybe<Checkout>
  /** update multiples rows of table: "checkout" */
  update_checkout_many?: Maybe<Array<Maybe<Checkout_Mutation_Response>>>
  /** update data of the table: "collection" */
  update_collection?: Maybe<Collection_Mutation_Response>
  /** update single row of the table: "collection" */
  update_collection_by_pk?: Maybe<Collection>
  /** update data of the table: "collection_cart_item" */
  update_collection_cart_item?: Maybe<Collection_Cart_Item_Mutation_Response>
  /** update single row of the table: "collection_cart_item" */
  update_collection_cart_item_by_pk?: Maybe<Collection_Cart_Item>
  /** update multiples rows of table: "collection_cart_item" */
  update_collection_cart_item_many?: Maybe<
    Array<Maybe<Collection_Cart_Item_Mutation_Response>>
  >
  /** update data of the table: "collection_collaborator" */
  update_collection_collaborator?: Maybe<Collection_Collaborator_Mutation_Response>
  /** update single row of the table: "collection_collaborator" */
  update_collection_collaborator_by_pk?: Maybe<Collection_Collaborator>
  /** update multiples rows of table: "collection_collaborator" */
  update_collection_collaborator_many?: Maybe<
    Array<Maybe<Collection_Collaborator_Mutation_Response>>
  >
  /** update data of the table: "collection_invite" */
  update_collection_invite?: Maybe<Collection_Invite_Mutation_Response>
  /** update single row of the table: "collection_invite" */
  update_collection_invite_by_pk?: Maybe<Collection_Invite>
  /** update multiples rows of table: "collection_invite" */
  update_collection_invite_many?: Maybe<
    Array<Maybe<Collection_Invite_Mutation_Response>>
  >
  /** update data of the table: "collection_invite_status" */
  update_collection_invite_status?: Maybe<Collection_Invite_Status_Mutation_Response>
  /** update single row of the table: "collection_invite_status" */
  update_collection_invite_status_by_pk?: Maybe<Collection_Invite_Status>
  /** update multiples rows of table: "collection_invite_status" */
  update_collection_invite_status_many?: Maybe<
    Array<Maybe<Collection_Invite_Status_Mutation_Response>>
  >
  /** update data of the table: "collection_item_claim" */
  update_collection_item_claim?: Maybe<Collection_Item_Claim_Mutation_Response>
  /** update single row of the table: "collection_item_claim" */
  update_collection_item_claim_by_pk?: Maybe<Collection_Item_Claim>
  /** update multiples rows of table: "collection_item_claim" */
  update_collection_item_claim_many?: Maybe<
    Array<Maybe<Collection_Item_Claim_Mutation_Response>>
  >
  /** update data of the table: "collection_item_claim_status" */
  update_collection_item_claim_status?: Maybe<Collection_Item_Claim_Status_Mutation_Response>
  /** update single row of the table: "collection_item_claim_status" */
  update_collection_item_claim_status_by_pk?: Maybe<Collection_Item_Claim_Status>
  /** update multiples rows of table: "collection_item_claim_status" */
  update_collection_item_claim_status_many?: Maybe<
    Array<Maybe<Collection_Item_Claim_Status_Mutation_Response>>
  >
  /** update multiples rows of table: "collection" */
  update_collection_many?: Maybe<Array<Maybe<Collection_Mutation_Response>>>
  /** update data of the table: "collection_section" */
  update_collection_section?: Maybe<Collection_Section_Mutation_Response>
  /** update single row of the table: "collection_section" */
  update_collection_section_by_pk?: Maybe<Collection_Section>
  /** update data of the table: "collection_section_cart_item" */
  update_collection_section_cart_item?: Maybe<Collection_Section_Cart_Item_Mutation_Response>
  /** update single row of the table: "collection_section_cart_item" */
  update_collection_section_cart_item_by_pk?: Maybe<Collection_Section_Cart_Item>
  /** update multiples rows of table: "collection_section_cart_item" */
  update_collection_section_cart_item_many?: Maybe<
    Array<Maybe<Collection_Section_Cart_Item_Mutation_Response>>
  >
  /** update multiples rows of table: "collection_section" */
  update_collection_section_many?: Maybe<
    Array<Maybe<Collection_Section_Mutation_Response>>
  >
  /** update data of the table: "collection_slug_history" */
  update_collection_slug_history?: Maybe<Collection_Slug_History_Mutation_Response>
  /** update single row of the table: "collection_slug_history" */
  update_collection_slug_history_by_pk?: Maybe<Collection_Slug_History>
  /** update multiples rows of table: "collection_slug_history" */
  update_collection_slug_history_many?: Maybe<
    Array<Maybe<Collection_Slug_History_Mutation_Response>>
  >
  /** update data of the table: "collection_state" */
  update_collection_state?: Maybe<Collection_State_Mutation_Response>
  /** update single row of the table: "collection_state" */
  update_collection_state_by_pk?: Maybe<Collection_State>
  /** update multiples rows of table: "collection_state" */
  update_collection_state_many?: Maybe<
    Array<Maybe<Collection_State_Mutation_Response>>
  >
  /** update data of the table: "collection_type" */
  update_collection_type?: Maybe<Collection_Type_Mutation_Response>
  /** update single row of the table: "collection_type" */
  update_collection_type_by_pk?: Maybe<Collection_Type>
  /** update multiples rows of table: "collection_type" */
  update_collection_type_many?: Maybe<
    Array<Maybe<Collection_Type_Mutation_Response>>
  >
  /** update data of the table: "comment" */
  update_comment?: Maybe<Comment_Mutation_Response>
  /** update single row of the table: "comment" */
  update_comment_by_pk?: Maybe<Comment>
  /** update multiples rows of table: "comment" */
  update_comment_many?: Maybe<Array<Maybe<Comment_Mutation_Response>>>
  /** update data of the table: "commentable_item" */
  update_commentable_item?: Maybe<Commentable_Item_Mutation_Response>
  /** update single row of the table: "commentable_item" */
  update_commentable_item_by_pk?: Maybe<Commentable_Item>
  /** update multiples rows of table: "commentable_item" */
  update_commentable_item_many?: Maybe<
    Array<Maybe<Commentable_Item_Mutation_Response>>
  >
  /** update data of the table: "commentable_item_type" */
  update_commentable_item_type?: Maybe<Commentable_Item_Type_Mutation_Response>
  /** update single row of the table: "commentable_item_type" */
  update_commentable_item_type_by_pk?: Maybe<Commentable_Item_Type>
  /** update multiples rows of table: "commentable_item_type" */
  update_commentable_item_type_many?: Maybe<
    Array<Maybe<Commentable_Item_Type_Mutation_Response>>
  >
  /** update data of the table: "communication_template" */
  update_communication_template?: Maybe<Communication_Template_Mutation_Response>
  /** update single row of the table: "communication_template" */
  update_communication_template_by_pk?: Maybe<Communication_Template>
  /** update data of the table: "communication_template_full_list" */
  update_communication_template_full_list?: Maybe<Communication_Template_Full_List_Mutation_Response>
  /** update single row of the table: "communication_template_full_list" */
  update_communication_template_full_list_by_pk?: Maybe<Communication_Template_Full_List>
  /** update multiples rows of table: "communication_template_full_list" */
  update_communication_template_full_list_many?: Maybe<
    Array<Maybe<Communication_Template_Full_List_Mutation_Response>>
  >
  /** update multiples rows of table: "communication_template" */
  update_communication_template_many?: Maybe<
    Array<Maybe<Communication_Template_Mutation_Response>>
  >
  /** update data of the table: "contact_info_type" */
  update_contact_info_type?: Maybe<Contact_Info_Type_Mutation_Response>
  /** update single row of the table: "contact_info_type" */
  update_contact_info_type_by_pk?: Maybe<Contact_Info_Type>
  /** update multiples rows of table: "contact_info_type" */
  update_contact_info_type_many?: Maybe<
    Array<Maybe<Contact_Info_Type_Mutation_Response>>
  >
  /** update data of the table: "contact_invite" */
  update_contact_invite?: Maybe<Contact_Invite_Mutation_Response>
  /** update single row of the table: "contact_invite" */
  update_contact_invite_by_pk?: Maybe<Contact_Invite>
  /** update multiples rows of table: "contact_invite" */
  update_contact_invite_many?: Maybe<
    Array<Maybe<Contact_Invite_Mutation_Response>>
  >
  /** update data of the table: "contact_invite_status" */
  update_contact_invite_status?: Maybe<Contact_Invite_Status_Mutation_Response>
  /** update single row of the table: "contact_invite_status" */
  update_contact_invite_status_by_pk?: Maybe<Contact_Invite_Status>
  /** update multiples rows of table: "contact_invite_status" */
  update_contact_invite_status_many?: Maybe<
    Array<Maybe<Contact_Invite_Status_Mutation_Response>>
  >
  /** update data of the table: "contact_invite_type" */
  update_contact_invite_type?: Maybe<Contact_Invite_Type_Mutation_Response>
  /** update single row of the table: "contact_invite_type" */
  update_contact_invite_type_by_pk?: Maybe<Contact_Invite_Type>
  /** update multiples rows of table: "contact_invite_type" */
  update_contact_invite_type_many?: Maybe<
    Array<Maybe<Contact_Invite_Type_Mutation_Response>>
  >
  /** update data of the table: "deal_hop_gate" */
  update_deal_hop_gate?: Maybe<Deal_Hop_Gate_Mutation_Response>
  /** update single row of the table: "deal_hop_gate" */
  update_deal_hop_gate_by_pk?: Maybe<Deal_Hop_Gate>
  /** update multiples rows of table: "deal_hop_gate" */
  update_deal_hop_gate_many?: Maybe<
    Array<Maybe<Deal_Hop_Gate_Mutation_Response>>
  >
  /** update data of the table: "device_client_data" */
  update_device_client_data?: Maybe<Device_Client_Data_Mutation_Response>
  /** update single row of the table: "device_client_data" */
  update_device_client_data_by_pk?: Maybe<Device_Client_Data>
  /** update multiples rows of table: "device_client_data" */
  update_device_client_data_many?: Maybe<
    Array<Maybe<Device_Client_Data_Mutation_Response>>
  >
  /** update data of the table: "discovery_backlog" */
  update_discovery_backlog?: Maybe<Discovery_Backlog_Mutation_Response>
  /** update single row of the table: "discovery_backlog" */
  update_discovery_backlog_by_pk?: Maybe<Discovery_Backlog>
  /** update multiples rows of table: "discovery_backlog" */
  update_discovery_backlog_many?: Maybe<
    Array<Maybe<Discovery_Backlog_Mutation_Response>>
  >
  /** update data of the table: "discovery_feed" */
  update_discovery_feed?: Maybe<Discovery_Feed_Mutation_Response>
  /** update single row of the table: "discovery_feed" */
  update_discovery_feed_by_pk?: Maybe<Discovery_Feed>
  /** update multiples rows of table: "discovery_feed" */
  update_discovery_feed_many?: Maybe<
    Array<Maybe<Discovery_Feed_Mutation_Response>>
  >
  /** update data of the table: "discovery_staging" */
  update_discovery_staging?: Maybe<Discovery_Staging_Mutation_Response>
  /** update single row of the table: "discovery_staging" */
  update_discovery_staging_by_pk?: Maybe<Discovery_Staging>
  /** update multiples rows of table: "discovery_staging" */
  update_discovery_staging_many?: Maybe<
    Array<Maybe<Discovery_Staging_Mutation_Response>>
  >
  /** update data of the table: "featured_shop" */
  update_featured_shop?: Maybe<Featured_Shop_Mutation_Response>
  /** update single row of the table: "featured_shop" */
  update_featured_shop_by_pk?: Maybe<Featured_Shop>
  /** update data of the table: "featured_shop_item" */
  update_featured_shop_item?: Maybe<Featured_Shop_Item_Mutation_Response>
  /** update single row of the table: "featured_shop_item" */
  update_featured_shop_item_by_pk?: Maybe<Featured_Shop_Item>
  /** update multiples rows of table: "featured_shop_item" */
  update_featured_shop_item_many?: Maybe<
    Array<Maybe<Featured_Shop_Item_Mutation_Response>>
  >
  /** update multiples rows of table: "featured_shop" */
  update_featured_shop_many?: Maybe<
    Array<Maybe<Featured_Shop_Mutation_Response>>
  >
  /** update data of the table: "feed_item" */
  update_feed_item?: Maybe<Feed_Item_Mutation_Response>
  /** update single row of the table: "feed_item" */
  update_feed_item_by_pk?: Maybe<Feed_Item>
  /** update data of the table: "feed_item_like" */
  update_feed_item_like?: Maybe<Feed_Item_Like_Mutation_Response>
  /** update single row of the table: "feed_item_like" */
  update_feed_item_like_by_pk?: Maybe<Feed_Item_Like>
  /** update multiples rows of table: "feed_item_like" */
  update_feed_item_like_many?: Maybe<
    Array<Maybe<Feed_Item_Like_Mutation_Response>>
  >
  /** update multiples rows of table: "feed_item" */
  update_feed_item_many?: Maybe<Array<Maybe<Feed_Item_Mutation_Response>>>
  /** update data of the table: "feed_item_type" */
  update_feed_item_type?: Maybe<Feed_Item_Type_Mutation_Response>
  /** update single row of the table: "feed_item_type" */
  update_feed_item_type_by_pk?: Maybe<Feed_Item_Type>
  /** update multiples rows of table: "feed_item_type" */
  update_feed_item_type_many?: Maybe<
    Array<Maybe<Feed_Item_Type_Mutation_Response>>
  >
  /** update data of the table: "fmtc_deal" */
  update_fmtc_deal?: Maybe<Fmtc_Deal_Mutation_Response>
  /** update single row of the table: "fmtc_deal" */
  update_fmtc_deal_by_pk?: Maybe<Fmtc_Deal>
  /** update multiples rows of table: "fmtc_deal" */
  update_fmtc_deal_many?: Maybe<Array<Maybe<Fmtc_Deal_Mutation_Response>>>
  /** update data of the table: "followable_item" */
  update_followable_item?: Maybe<Followable_Item_Mutation_Response>
  /** update single row of the table: "followable_item" */
  update_followable_item_by_pk?: Maybe<Followable_Item>
  /** update multiples rows of table: "followable_item" */
  update_followable_item_many?: Maybe<
    Array<Maybe<Followable_Item_Mutation_Response>>
  >
  /** update data of the table: "followable_item_type" */
  update_followable_item_type?: Maybe<Followable_Item_Type_Mutation_Response>
  /** update single row of the table: "followable_item_type" */
  update_followable_item_type_by_pk?: Maybe<Followable_Item_Type>
  /** update multiples rows of table: "followable_item_type" */
  update_followable_item_type_many?: Maybe<
    Array<Maybe<Followable_Item_Type_Mutation_Response>>
  >
  /** execute VOLATILE function "update_last_seen_notification_center_item_sequential_id" which returns "user_flags" */
  update_last_seen_notification_center_item_sequential_id: Array<User_Flags>
  /** update data of the table: "leaderboard_event" */
  update_leaderboard_event?: Maybe<Leaderboard_Event_Mutation_Response>
  /** update single row of the table: "leaderboard_event" */
  update_leaderboard_event_by_pk?: Maybe<Leaderboard_Event>
  /** update multiples rows of table: "leaderboard_event" */
  update_leaderboard_event_many?: Maybe<
    Array<Maybe<Leaderboard_Event_Mutation_Response>>
  >
  /** update data of the table: "leaderboard_event_name" */
  update_leaderboard_event_name?: Maybe<Leaderboard_Event_Name_Mutation_Response>
  /** update single row of the table: "leaderboard_event_name" */
  update_leaderboard_event_name_by_pk?: Maybe<Leaderboard_Event_Name>
  /** update multiples rows of table: "leaderboard_event_name" */
  update_leaderboard_event_name_many?: Maybe<
    Array<Maybe<Leaderboard_Event_Name_Mutation_Response>>
  >
  /** update data of the table: "link_type" */
  update_link_type?: Maybe<Link_Type_Mutation_Response>
  /** update single row of the table: "link_type" */
  update_link_type_by_pk?: Maybe<Link_Type>
  /** update multiples rows of table: "link_type" */
  update_link_type_many?: Maybe<Array<Maybe<Link_Type_Mutation_Response>>>
  /** update data of the table: "media_enabled_item" */
  update_media_enabled_item?: Maybe<Media_Enabled_Item_Mutation_Response>
  /** update single row of the table: "media_enabled_item" */
  update_media_enabled_item_by_pk?: Maybe<Media_Enabled_Item>
  /** update multiples rows of table: "media_enabled_item" */
  update_media_enabled_item_many?: Maybe<
    Array<Maybe<Media_Enabled_Item_Mutation_Response>>
  >
  /** update data of the table: "media_item" */
  update_media_item?: Maybe<Media_Item_Mutation_Response>
  /** update single row of the table: "media_item" */
  update_media_item_by_pk?: Maybe<Media_Item>
  /** update multiples rows of table: "media_item" */
  update_media_item_many?: Maybe<Array<Maybe<Media_Item_Mutation_Response>>>
  /** update data of the table: "media_item_type" */
  update_media_item_type?: Maybe<Media_Item_Type_Mutation_Response>
  /** update single row of the table: "media_item_type" */
  update_media_item_type_by_pk?: Maybe<Media_Item_Type>
  /** update multiples rows of table: "media_item_type" */
  update_media_item_type_many?: Maybe<
    Array<Maybe<Media_Item_Type_Mutation_Response>>
  >
  /** update data of the table: "media_items" */
  update_media_items?: Maybe<Media_Items_Mutation_Response>
  /** update single row of the table: "media_items" */
  update_media_items_by_pk?: Maybe<Media_Items>
  /** update multiples rows of table: "media_items" */
  update_media_items_many?: Maybe<Array<Maybe<Media_Items_Mutation_Response>>>
  /** update data of the table: "media_type" */
  update_media_type?: Maybe<Media_Type_Mutation_Response>
  /** update single row of the table: "media_type" */
  update_media_type_by_pk?: Maybe<Media_Type>
  /** update multiples rows of table: "media_type" */
  update_media_type_many?: Maybe<Array<Maybe<Media_Type_Mutation_Response>>>
  /** update data of the table: "mobile_app_home" */
  update_mobile_app_home?: Maybe<Mobile_App_Home_Mutation_Response>
  /** update single row of the table: "mobile_app_home" */
  update_mobile_app_home_by_pk?: Maybe<Mobile_App_Home>
  /** update multiples rows of table: "mobile_app_home" */
  update_mobile_app_home_many?: Maybe<
    Array<Maybe<Mobile_App_Home_Mutation_Response>>
  >
  /** update data of the table: "mobile_home" */
  update_mobile_home?: Maybe<Mobile_Home_Mutation_Response>
  /** update single row of the table: "mobile_home" */
  update_mobile_home_by_pk?: Maybe<Mobile_Home>
  /** update multiples rows of table: "mobile_home" */
  update_mobile_home_many?: Maybe<Array<Maybe<Mobile_Home_Mutation_Response>>>
  /** update data of the table: "mobile_push_notification" */
  update_mobile_push_notification?: Maybe<Mobile_Push_Notification_Mutation_Response>
  /** update single row of the table: "mobile_push_notification" */
  update_mobile_push_notification_by_pk?: Maybe<Mobile_Push_Notification>
  /** update multiples rows of table: "mobile_push_notification" */
  update_mobile_push_notification_many?: Maybe<
    Array<Maybe<Mobile_Push_Notification_Mutation_Response>>
  >
  /** update data of the table: "mobile_push_notification_status" */
  update_mobile_push_notification_status?: Maybe<Mobile_Push_Notification_Status_Mutation_Response>
  /** update single row of the table: "mobile_push_notification_status" */
  update_mobile_push_notification_status_by_pk?: Maybe<Mobile_Push_Notification_Status>
  /** update multiples rows of table: "mobile_push_notification_status" */
  update_mobile_push_notification_status_many?: Maybe<
    Array<Maybe<Mobile_Push_Notification_Status_Mutation_Response>>
  >
  /** update data of the table: "notifiable_item" */
  update_notifiable_item?: Maybe<Notifiable_Item_Mutation_Response>
  /** update single row of the table: "notifiable_item" */
  update_notifiable_item_by_pk?: Maybe<Notifiable_Item>
  /** update multiples rows of table: "notifiable_item" */
  update_notifiable_item_many?: Maybe<
    Array<Maybe<Notifiable_Item_Mutation_Response>>
  >
  /** update data of the table: "notifiable_item_type" */
  update_notifiable_item_type?: Maybe<Notifiable_Item_Type_Mutation_Response>
  /** update single row of the table: "notifiable_item_type" */
  update_notifiable_item_type_by_pk?: Maybe<Notifiable_Item_Type>
  /** update multiples rows of table: "notifiable_item_type" */
  update_notifiable_item_type_many?: Maybe<
    Array<Maybe<Notifiable_Item_Type_Mutation_Response>>
  >
  /** update data of the table: "notification" */
  update_notification?: Maybe<Notification_Mutation_Response>
  /** update single row of the table: "notification" */
  update_notification_by_pk?: Maybe<Notification>
  /** update data of the table: "notification_center_item" */
  update_notification_center_item?: Maybe<Notification_Center_Item_Mutation_Response>
  /** update single row of the table: "notification_center_item" */
  update_notification_center_item_by_pk?: Maybe<Notification_Center_Item>
  /** update multiples rows of table: "notification_center_item" */
  update_notification_center_item_many?: Maybe<
    Array<Maybe<Notification_Center_Item_Mutation_Response>>
  >
  /** update data of the table: "notification_center_item_read_status" */
  update_notification_center_item_read_status?: Maybe<Notification_Center_Item_Read_Status_Mutation_Response>
  /** update single row of the table: "notification_center_item_read_status" */
  update_notification_center_item_read_status_by_pk?: Maybe<Notification_Center_Item_Read_Status>
  /** update multiples rows of table: "notification_center_item_read_status" */
  update_notification_center_item_read_status_many?: Maybe<
    Array<Maybe<Notification_Center_Item_Read_Status_Mutation_Response>>
  >
  /** update data of the table: "notification_center_item_type" */
  update_notification_center_item_type?: Maybe<Notification_Center_Item_Type_Mutation_Response>
  /** update single row of the table: "notification_center_item_type" */
  update_notification_center_item_type_by_pk?: Maybe<Notification_Center_Item_Type>
  /** update multiples rows of table: "notification_center_item_type" */
  update_notification_center_item_type_many?: Maybe<
    Array<Maybe<Notification_Center_Item_Type_Mutation_Response>>
  >
  /** update multiples rows of table: "notification" */
  update_notification_many?: Maybe<Array<Maybe<Notification_Mutation_Response>>>
  /** update data of the table: "notification_preference" */
  update_notification_preference?: Maybe<Notification_Preference_Mutation_Response>
  /** update single row of the table: "notification_preference" */
  update_notification_preference_by_pk?: Maybe<Notification_Preference>
  /** update multiples rows of table: "notification_preference" */
  update_notification_preference_many?: Maybe<
    Array<Maybe<Notification_Preference_Mutation_Response>>
  >
  /** update data of the table: "notification_status" */
  update_notification_status?: Maybe<Notification_Status_Mutation_Response>
  /** update single row of the table: "notification_status" */
  update_notification_status_by_pk?: Maybe<Notification_Status>
  /** update multiples rows of table: "notification_status" */
  update_notification_status_many?: Maybe<
    Array<Maybe<Notification_Status_Mutation_Response>>
  >
  /** update data of the table: "onboarding_categories" */
  update_onboarding_categories?: Maybe<Onboarding_Categories_Mutation_Response>
  /** update single row of the table: "onboarding_categories" */
  update_onboarding_categories_by_pk?: Maybe<Onboarding_Categories>
  /** update multiples rows of table: "onboarding_categories" */
  update_onboarding_categories_many?: Maybe<
    Array<Maybe<Onboarding_Categories_Mutation_Response>>
  >
  /** update data of the table: "onboarding_sources" */
  update_onboarding_sources?: Maybe<Onboarding_Sources_Mutation_Response>
  /** update single row of the table: "onboarding_sources" */
  update_onboarding_sources_by_pk?: Maybe<Onboarding_Sources>
  /** update multiples rows of table: "onboarding_sources" */
  update_onboarding_sources_many?: Maybe<
    Array<Maybe<Onboarding_Sources_Mutation_Response>>
  >
  /** update data of the table: "one_off_email" */
  update_one_off_email?: Maybe<One_Off_Email_Mutation_Response>
  /** update single row of the table: "one_off_email" */
  update_one_off_email_by_pk?: Maybe<One_Off_Email>
  /** update multiples rows of table: "one_off_email" */
  update_one_off_email_many?: Maybe<
    Array<Maybe<One_Off_Email_Mutation_Response>>
  >
  /** update data of the table: "pageview_event" */
  update_pageview_event?: Maybe<Pageview_Event_Mutation_Response>
  /** update single row of the table: "pageview_event" */
  update_pageview_event_by_pk?: Maybe<Pageview_Event>
  /** update multiples rows of table: "pageview_event" */
  update_pageview_event_many?: Maybe<
    Array<Maybe<Pageview_Event_Mutation_Response>>
  >
  /** update data of the table: "promo_period" */
  update_promo_period?: Maybe<Promo_Period_Mutation_Response>
  /** update single row of the table: "promo_period" */
  update_promo_period_by_pk?: Maybe<Promo_Period>
  /** update multiples rows of table: "promo_period" */
  update_promo_period_many?: Maybe<Array<Maybe<Promo_Period_Mutation_Response>>>
  /** update data of the table: "push_notification" */
  update_push_notification?: Maybe<Push_Notification_Mutation_Response>
  /** update single row of the table: "push_notification" */
  update_push_notification_by_pk?: Maybe<Push_Notification>
  /** update multiples rows of table: "push_notification" */
  update_push_notification_many?: Maybe<
    Array<Maybe<Push_Notification_Mutation_Response>>
  >
  /** update data of the table: "push_notification_status" */
  update_push_notification_status?: Maybe<Push_Notification_Status_Mutation_Response>
  /** update single row of the table: "push_notification_status" */
  update_push_notification_status_by_pk?: Maybe<Push_Notification_Status>
  /** update multiples rows of table: "push_notification_status" */
  update_push_notification_status_many?: Maybe<
    Array<Maybe<Push_Notification_Status_Mutation_Response>>
  >
  /** update data of the table: "redirect_ids" */
  update_redirect_ids?: Maybe<Redirect_Ids_Mutation_Response>
  /** update single row of the table: "redirect_ids" */
  update_redirect_ids_by_pk?: Maybe<Redirect_Ids>
  /** update multiples rows of table: "redirect_ids" */
  update_redirect_ids_many?: Maybe<Array<Maybe<Redirect_Ids_Mutation_Response>>>
  /** update data of the table: "remove_reason" */
  update_remove_reason?: Maybe<Remove_Reason_Mutation_Response>
  /** update single row of the table: "remove_reason" */
  update_remove_reason_by_pk?: Maybe<Remove_Reason>
  /** update multiples rows of table: "remove_reason" */
  update_remove_reason_many?: Maybe<
    Array<Maybe<Remove_Reason_Mutation_Response>>
  >
  /** update data of the table: "rewardable_action_names" */
  update_rewardable_action_names?: Maybe<Rewardable_Action_Names_Mutation_Response>
  /** update single row of the table: "rewardable_action_names" */
  update_rewardable_action_names_by_pk?: Maybe<Rewardable_Action_Names>
  /** update multiples rows of table: "rewardable_action_names" */
  update_rewardable_action_names_many?: Maybe<
    Array<Maybe<Rewardable_Action_Names_Mutation_Response>>
  >
  /** update data of the table: "rewardable_item_type" */
  update_rewardable_item_type?: Maybe<Rewardable_Item_Type_Mutation_Response>
  /** update single row of the table: "rewardable_item_type" */
  update_rewardable_item_type_by_pk?: Maybe<Rewardable_Item_Type>
  /** update multiples rows of table: "rewardable_item_type" */
  update_rewardable_item_type_many?: Maybe<
    Array<Maybe<Rewardable_Item_Type_Mutation_Response>>
  >
  /** update data of the table: "search_query" */
  update_search_query?: Maybe<Search_Query_Mutation_Response>
  /** update single row of the table: "search_query" */
  update_search_query_by_pk?: Maybe<Search_Query>
  /** update multiples rows of table: "search_query" */
  update_search_query_many?: Maybe<Array<Maybe<Search_Query_Mutation_Response>>>
  /** update data of the table: "search_query_result" */
  update_search_query_result?: Maybe<Search_Query_Result_Mutation_Response>
  /** update single row of the table: "search_query_result" */
  update_search_query_result_by_pk?: Maybe<Search_Query_Result>
  /** update multiples rows of table: "search_query_result" */
  update_search_query_result_many?: Maybe<
    Array<Maybe<Search_Query_Result_Mutation_Response>>
  >
  /** update data of the table: "shop" */
  update_shop?: Maybe<Shop_Mutation_Response>
  /** update data of the table: "shop_affiliate" */
  update_shop_affiliate?: Maybe<Shop_Affiliate_Mutation_Response>
  /** update single row of the table: "shop_affiliate" */
  update_shop_affiliate_by_pk?: Maybe<Shop_Affiliate>
  /** update multiples rows of table: "shop_affiliate" */
  update_shop_affiliate_many?: Maybe<
    Array<Maybe<Shop_Affiliate_Mutation_Response>>
  >
  /** update single row of the table: "shop" */
  update_shop_by_pk?: Maybe<Shop>
  /** update data of the table: "shop_categorization" */
  update_shop_categorization?: Maybe<Shop_Categorization_Mutation_Response>
  /** update single row of the table: "shop_categorization" */
  update_shop_categorization_by_pk?: Maybe<Shop_Categorization>
  /** update multiples rows of table: "shop_categorization" */
  update_shop_categorization_many?: Maybe<
    Array<Maybe<Shop_Categorization_Mutation_Response>>
  >
  /** update data of the table: "shop_category" */
  update_shop_category?: Maybe<Shop_Category_Mutation_Response>
  /** update single row of the table: "shop_category" */
  update_shop_category_by_pk?: Maybe<Shop_Category>
  /** update multiples rows of table: "shop_category" */
  update_shop_category_many?: Maybe<
    Array<Maybe<Shop_Category_Mutation_Response>>
  >
  /** update data of the table: "shop_item" */
  update_shop_item?: Maybe<Shop_Item_Mutation_Response>
  /** update data of the table: "shop_item_availability" */
  update_shop_item_availability?: Maybe<Shop_Item_Availability_Mutation_Response>
  /** update single row of the table: "shop_item_availability" */
  update_shop_item_availability_by_pk?: Maybe<Shop_Item_Availability>
  /** update multiples rows of table: "shop_item_availability" */
  update_shop_item_availability_many?: Maybe<
    Array<Maybe<Shop_Item_Availability_Mutation_Response>>
  >
  /** update single row of the table: "shop_item" */
  update_shop_item_by_pk?: Maybe<Shop_Item>
  /** update data of the table: "shop_item_enrichment_status" */
  update_shop_item_enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Mutation_Response>
  /** update single row of the table: "shop_item_enrichment_status" */
  update_shop_item_enrichment_status_by_pk?: Maybe<Shop_Item_Enrichment_Status>
  /** update multiples rows of table: "shop_item_enrichment_status" */
  update_shop_item_enrichment_status_many?: Maybe<
    Array<Maybe<Shop_Item_Enrichment_Status_Mutation_Response>>
  >
  /** update data of the table: "shop_item_history" */
  update_shop_item_history?: Maybe<Shop_Item_History_Mutation_Response>
  /** update single row of the table: "shop_item_history" */
  update_shop_item_history_by_pk?: Maybe<Shop_Item_History>
  /** update multiples rows of table: "shop_item_history" */
  update_shop_item_history_many?: Maybe<
    Array<Maybe<Shop_Item_History_Mutation_Response>>
  >
  /** update data of the table: "shop_item_image" */
  update_shop_item_image?: Maybe<Shop_Item_Image_Mutation_Response>
  /** update single row of the table: "shop_item_image" */
  update_shop_item_image_by_pk?: Maybe<Shop_Item_Image>
  /** update multiples rows of table: "shop_item_image" */
  update_shop_item_image_many?: Maybe<
    Array<Maybe<Shop_Item_Image_Mutation_Response>>
  >
  /** update data of the table: "shop_item_index" */
  update_shop_item_index?: Maybe<Shop_Item_Index_Mutation_Response>
  /** update single row of the table: "shop_item_index" */
  update_shop_item_index_by_pk?: Maybe<Shop_Item_Index>
  /** update multiples rows of table: "shop_item_index" */
  update_shop_item_index_many?: Maybe<
    Array<Maybe<Shop_Item_Index_Mutation_Response>>
  >
  /** update multiples rows of table: "shop_item" */
  update_shop_item_many?: Maybe<Array<Maybe<Shop_Item_Mutation_Response>>>
  /** update data of the table: "shop_item_to_be_scraped" */
  update_shop_item_to_be_scraped?: Maybe<Shop_Item_To_Be_Scraped_Mutation_Response>
  /** update multiples rows of table: "shop_item_to_be_scraped" */
  update_shop_item_to_be_scraped_many?: Maybe<
    Array<Maybe<Shop_Item_To_Be_Scraped_Mutation_Response>>
  >
  /** update multiples rows of table: "shop" */
  update_shop_many?: Maybe<Array<Maybe<Shop_Mutation_Response>>>
  /** update data of the table: "shop_rating" */
  update_shop_rating?: Maybe<Shop_Rating_Mutation_Response>
  /** update single row of the table: "shop_rating" */
  update_shop_rating_by_pk?: Maybe<Shop_Rating>
  /** update multiples rows of table: "shop_rating" */
  update_shop_rating_many?: Maybe<Array<Maybe<Shop_Rating_Mutation_Response>>>
  /** update data of the table: "similar_products_history" */
  update_similar_products_history?: Maybe<Similar_Products_History_Mutation_Response>
  /** update single row of the table: "similar_products_history" */
  update_similar_products_history_by_pk?: Maybe<Similar_Products_History>
  /** update multiples rows of table: "similar_products_history" */
  update_similar_products_history_many?: Maybe<
    Array<Maybe<Similar_Products_History_Mutation_Response>>
  >
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>
  /** update data of the table: "user_account_type" */
  update_user_account_type?: Maybe<User_Account_Type_Mutation_Response>
  /** update single row of the table: "user_account_type" */
  update_user_account_type_by_pk?: Maybe<User_Account_Type>
  /** update multiples rows of table: "user_account_type" */
  update_user_account_type_many?: Maybe<
    Array<Maybe<User_Account_Type_Mutation_Response>>
  >
  /** update data of the table: "user_action" */
  update_user_action?: Maybe<User_Action_Mutation_Response>
  /** update single row of the table: "user_action" */
  update_user_action_by_pk?: Maybe<User_Action>
  /** update multiples rows of table: "user_action" */
  update_user_action_many?: Maybe<Array<Maybe<User_Action_Mutation_Response>>>
  /** update data of the table: "user_app_version" */
  update_user_app_version?: Maybe<User_App_Version_Mutation_Response>
  /** update single row of the table: "user_app_version" */
  update_user_app_version_by_pk?: Maybe<User_App_Version>
  /** update multiples rows of table: "user_app_version" */
  update_user_app_version_many?: Maybe<
    Array<Maybe<User_App_Version_Mutation_Response>>
  >
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>
  /** update data of the table: "user_carts_onboarding" */
  update_user_carts_onboarding?: Maybe<User_Carts_Onboarding_Mutation_Response>
  /** update single row of the table: "user_carts_onboarding" */
  update_user_carts_onboarding_by_pk?: Maybe<User_Carts_Onboarding>
  /** update multiples rows of table: "user_carts_onboarding" */
  update_user_carts_onboarding_many?: Maybe<
    Array<Maybe<User_Carts_Onboarding_Mutation_Response>>
  >
  /** update data of the table: "user_category" */
  update_user_category?: Maybe<User_Category_Mutation_Response>
  /** update single row of the table: "user_category" */
  update_user_category_by_pk?: Maybe<User_Category>
  /** update multiples rows of table: "user_category" */
  update_user_category_many?: Maybe<
    Array<Maybe<User_Category_Mutation_Response>>
  >
  /** update data of the table: "user_deletion" */
  update_user_deletion?: Maybe<User_Deletion_Mutation_Response>
  /** update single row of the table: "user_deletion" */
  update_user_deletion_by_pk?: Maybe<User_Deletion>
  /** update multiples rows of table: "user_deletion" */
  update_user_deletion_many?: Maybe<
    Array<Maybe<User_Deletion_Mutation_Response>>
  >
  /** update data of the table: "user_feedback" */
  update_user_feedback?: Maybe<User_Feedback_Mutation_Response>
  /** update single row of the table: "user_feedback" */
  update_user_feedback_by_pk?: Maybe<User_Feedback>
  /** update data of the table: "user_feedback_item" */
  update_user_feedback_item?: Maybe<User_Feedback_Item_Mutation_Response>
  /** update single row of the table: "user_feedback_item" */
  update_user_feedback_item_by_pk?: Maybe<User_Feedback_Item>
  /** update multiples rows of table: "user_feedback_item" */
  update_user_feedback_item_many?: Maybe<
    Array<Maybe<User_Feedback_Item_Mutation_Response>>
  >
  /** update multiples rows of table: "user_feedback" */
  update_user_feedback_many?: Maybe<
    Array<Maybe<User_Feedback_Mutation_Response>>
  >
  /** update data of the table: "user_feedback_type" */
  update_user_feedback_type?: Maybe<User_Feedback_Type_Mutation_Response>
  /** update single row of the table: "user_feedback_type" */
  update_user_feedback_type_by_pk?: Maybe<User_Feedback_Type>
  /** update multiples rows of table: "user_feedback_type" */
  update_user_feedback_type_many?: Maybe<
    Array<Maybe<User_Feedback_Type_Mutation_Response>>
  >
  /** update data of the table: "user_flags" */
  update_user_flags?: Maybe<User_Flags_Mutation_Response>
  /** update single row of the table: "user_flags" */
  update_user_flags_by_pk?: Maybe<User_Flags>
  /** update multiples rows of table: "user_flags" */
  update_user_flags_many?: Maybe<Array<Maybe<User_Flags_Mutation_Response>>>
  /** update data of the table: "user_impersonation" */
  update_user_impersonation?: Maybe<User_Impersonation_Mutation_Response>
  /** update single row of the table: "user_impersonation" */
  update_user_impersonation_by_pk?: Maybe<User_Impersonation>
  /** update multiples rows of table: "user_impersonation" */
  update_user_impersonation_many?: Maybe<
    Array<Maybe<User_Impersonation_Mutation_Response>>
  >
  /** update data of the table: "user_ip_address" */
  update_user_ip_address?: Maybe<User_Ip_Address_Mutation_Response>
  /** update single row of the table: "user_ip_address" */
  update_user_ip_address_by_pk?: Maybe<User_Ip_Address>
  /** update multiples rows of table: "user_ip_address" */
  update_user_ip_address_many?: Maybe<
    Array<Maybe<User_Ip_Address_Mutation_Response>>
  >
  /** update data of the table: "user_items_followed" */
  update_user_items_followed?: Maybe<User_Items_Followed_Mutation_Response>
  /** update single row of the table: "user_items_followed" */
  update_user_items_followed_by_pk?: Maybe<User_Items_Followed>
  /** update multiples rows of table: "user_items_followed" */
  update_user_items_followed_many?: Maybe<
    Array<Maybe<User_Items_Followed_Mutation_Response>>
  >
  /** update data of the table: "user_links" */
  update_user_links?: Maybe<User_Links_Mutation_Response>
  /** update single row of the table: "user_links" */
  update_user_links_by_pk?: Maybe<User_Links>
  /** update multiples rows of table: "user_links" */
  update_user_links_many?: Maybe<Array<Maybe<User_Links_Mutation_Response>>>
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>
  /** update data of the table: "user_onboarding_source" */
  update_user_onboarding_source?: Maybe<User_Onboarding_Source_Mutation_Response>
  /** update single row of the table: "user_onboarding_source" */
  update_user_onboarding_source_by_pk?: Maybe<User_Onboarding_Source>
  /** update multiples rows of table: "user_onboarding_source" */
  update_user_onboarding_source_many?: Maybe<
    Array<Maybe<User_Onboarding_Source_Mutation_Response>>
  >
  /** update data of the table: "user_onboarding_state" */
  update_user_onboarding_state?: Maybe<User_Onboarding_State_Mutation_Response>
  /** update single row of the table: "user_onboarding_state" */
  update_user_onboarding_state_by_pk?: Maybe<User_Onboarding_State>
  /** update multiples rows of table: "user_onboarding_state" */
  update_user_onboarding_state_many?: Maybe<
    Array<Maybe<User_Onboarding_State_Mutation_Response>>
  >
  /** update data of the table: "user_phone_contact" */
  update_user_phone_contact?: Maybe<User_Phone_Contact_Mutation_Response>
  /** update single row of the table: "user_phone_contact" */
  update_user_phone_contact_by_pk?: Maybe<User_Phone_Contact>
  /** update multiples rows of table: "user_phone_contact" */
  update_user_phone_contact_many?: Maybe<
    Array<Maybe<User_Phone_Contact_Mutation_Response>>
  >
  /** update data of the table: "user_purchase_subscription" */
  update_user_purchase_subscription?: Maybe<User_Purchase_Subscription_Mutation_Response>
  /** update single row of the table: "user_purchase_subscription" */
  update_user_purchase_subscription_by_pk?: Maybe<User_Purchase_Subscription>
  /** update multiples rows of table: "user_purchase_subscription" */
  update_user_purchase_subscription_many?: Maybe<
    Array<Maybe<User_Purchase_Subscription_Mutation_Response>>
  >
  /** update data of the table: "user_purchases" */
  update_user_purchases?: Maybe<User_Purchases_Mutation_Response>
  /** update single row of the table: "user_purchases" */
  update_user_purchases_by_pk?: Maybe<User_Purchases>
  /** update multiples rows of table: "user_purchases" */
  update_user_purchases_many?: Maybe<
    Array<Maybe<User_Purchases_Mutation_Response>>
  >
  /** update data of the table: "user_push_notification" */
  update_user_push_notification?: Maybe<User_Push_Notification_Mutation_Response>
  /** update multiples rows of table: "user_push_notification" */
  update_user_push_notification_many?: Maybe<
    Array<Maybe<User_Push_Notification_Mutation_Response>>
  >
  /** update data of the table: "user_push_notification_token" */
  update_user_push_notification_token?: Maybe<User_Push_Notification_Token_Mutation_Response>
  /** update single row of the table: "user_push_notification_token" */
  update_user_push_notification_token_by_pk?: Maybe<User_Push_Notification_Token>
  /** update multiples rows of table: "user_push_notification_token" */
  update_user_push_notification_token_many?: Maybe<
    Array<Maybe<User_Push_Notification_Token_Mutation_Response>>
  >
  /** update data of the table: "user_referral" */
  update_user_referral?: Maybe<User_Referral_Mutation_Response>
  /** update single row of the table: "user_referral" */
  update_user_referral_by_pk?: Maybe<User_Referral>
  /** update multiples rows of table: "user_referral" */
  update_user_referral_many?: Maybe<
    Array<Maybe<User_Referral_Mutation_Response>>
  >
  /** update data of the table: "user_reward_multiplier_config" */
  update_user_reward_multiplier_config?: Maybe<User_Reward_Multiplier_Config_Mutation_Response>
  /** update single row of the table: "user_reward_multiplier_config" */
  update_user_reward_multiplier_config_by_pk?: Maybe<User_Reward_Multiplier_Config>
  /** update multiples rows of table: "user_reward_multiplier_config" */
  update_user_reward_multiplier_config_many?: Maybe<
    Array<Maybe<User_Reward_Multiplier_Config_Mutation_Response>>
  >
  /** update data of the table: "user_reward_redemption" */
  update_user_reward_redemption?: Maybe<User_Reward_Redemption_Mutation_Response>
  /** update single row of the table: "user_reward_redemption" */
  update_user_reward_redemption_by_pk?: Maybe<User_Reward_Redemption>
  /** update multiples rows of table: "user_reward_redemption" */
  update_user_reward_redemption_many?: Maybe<
    Array<Maybe<User_Reward_Redemption_Mutation_Response>>
  >
  /** update data of the table: "user_reward_reference" */
  update_user_reward_reference?: Maybe<User_Reward_Reference_Mutation_Response>
  /** update single row of the table: "user_reward_reference" */
  update_user_reward_reference_by_pk?: Maybe<User_Reward_Reference>
  /** update multiples rows of table: "user_reward_reference" */
  update_user_reward_reference_many?: Maybe<
    Array<Maybe<User_Reward_Reference_Mutation_Response>>
  >
  /** update data of the table: "user_shop_favorite" */
  update_user_shop_favorite?: Maybe<User_Shop_Favorite_Mutation_Response>
  /** update single row of the table: "user_shop_favorite" */
  update_user_shop_favorite_by_pk?: Maybe<User_Shop_Favorite>
  /** update multiples rows of table: "user_shop_favorite" */
  update_user_shop_favorite_many?: Maybe<
    Array<Maybe<User_Shop_Favorite_Mutation_Response>>
  >
  /** update data of the table: "user_shopping_preferences" */
  update_user_shopping_preferences?: Maybe<User_Shopping_Preferences_Mutation_Response>
  /** update single row of the table: "user_shopping_preferences" */
  update_user_shopping_preferences_by_pk?: Maybe<User_Shopping_Preferences>
  /** update multiples rows of table: "user_shopping_preferences" */
  update_user_shopping_preferences_many?: Maybe<
    Array<Maybe<User_Shopping_Preferences_Mutation_Response>>
  >
  /** update data of the table: "user_subscription" */
  update_user_subscription?: Maybe<User_Subscription_Mutation_Response>
  /** update single row of the table: "user_subscription" */
  update_user_subscription_by_pk?: Maybe<User_Subscription>
  /** update multiples rows of table: "user_subscription" */
  update_user_subscription_many?: Maybe<
    Array<Maybe<User_Subscription_Mutation_Response>>
  >
  /** update data of the table: "user_subscription_status" */
  update_user_subscription_status?: Maybe<User_Subscription_Status_Mutation_Response>
  /** update single row of the table: "user_subscription_status" */
  update_user_subscription_status_by_pk?: Maybe<User_Subscription_Status>
  /** update multiples rows of table: "user_subscription_status" */
  update_user_subscription_status_many?: Maybe<
    Array<Maybe<User_Subscription_Status_Mutation_Response>>
  >
  /** update data of the table: "user_temp" */
  update_user_temp?: Maybe<User_Temp_Mutation_Response>
  /** update single row of the table: "user_temp" */
  update_user_temp_by_pk?: Maybe<User_Temp>
  /** update multiples rows of table: "user_temp" */
  update_user_temp_many?: Maybe<Array<Maybe<User_Temp_Mutation_Response>>>
  /** update data of the table: "worflow_type" */
  update_worflow_type?: Maybe<Worflow_Type_Mutation_Response>
  /** update single row of the table: "worflow_type" */
  update_worflow_type_by_pk?: Maybe<Worflow_Type>
  /** update multiples rows of table: "worflow_type" */
  update_worflow_type_many?: Maybe<Array<Maybe<Worflow_Type_Mutation_Response>>>
  /** update data of the table: "workflow" */
  update_workflow?: Maybe<Workflow_Mutation_Response>
  /** update single row of the table: "workflow" */
  update_workflow_by_pk?: Maybe<Workflow>
  /** update multiples rows of table: "workflow" */
  update_workflow_many?: Maybe<Array<Maybe<Workflow_Mutation_Response>>>
  /** update data of the table: "workflow_status" */
  update_workflow_status?: Maybe<Workflow_Status_Mutation_Response>
  /** update single row of the table: "workflow_status" */
  update_workflow_status_by_pk?: Maybe<Workflow_Status>
  /** update multiples rows of table: "workflow_status" */
  update_workflow_status_many?: Maybe<
    Array<Maybe<Workflow_Status_Mutation_Response>>
  >
  /** execute VOLATILE function "upsert_notification_preference" which returns "notification_preference" */
  upsert_notification_preference: Array<Notification_Preference>
}

/** mutation root */
export type Mutation_RootDelete_Account_Deletion_ReasonArgs = {
  where: Account_Deletion_Reason_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Account_Deletion_Reason_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_AffiliateArgs = {
  where: Affiliate_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_Advertiser_IdArgs = {
  where: Affiliate_Advertiser_Id_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_Advertiser_Id_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_MetaArgs = {
  where: Affiliate_Meta_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_Meta_By_PkArgs = {
  affiliate: Affiliate_Enum
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_TransactionArgs = {
  where: Affiliate_Transaction_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_Transaction_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Billing_ModeArgs = {
  where: Billing_Mode_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Billing_Mode_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Billing_Platform_Connected_AccountArgs = {
  where: Billing_Platform_Connected_Account_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Billing_Platform_Connected_Account_By_PkArgs = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Billing_Platform_Connected_Account_StatusArgs =
  {
    where: Billing_Platform_Connected_Account_Status_Bool_Exp
  }

/** mutation root */
export type Mutation_RootDelete_Billing_Platform_Connected_Account_Status_By_PkArgs =
  {
    value: Scalars['String']
  }

/** mutation root */
export type Mutation_RootDelete_Billing_Platform_CustomerArgs = {
  where: Billing_Platform_Customer_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Billing_Platform_Customer_By_PkArgs = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_CartArgs = {
  where: Cart_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cart_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Cart_ItemArgs = {
  where: Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Cart_Item_LikeArgs = {
  where: Cart_Item_Like_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cart_Item_Like_By_PkArgs = {
  cart_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Cart_Item_NoteArgs = {
  where: Cart_Item_Note_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cart_Item_Note_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_CategoriesArgs = {
  where: Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Categories_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Category_Shop_ItemArgs = {
  where: Category_Shop_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Category_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_CheckoutArgs = {
  where: Checkout_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Checkout_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_CollectionArgs = {
  where: Collection_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_Cart_ItemArgs = {
  where: Collection_Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_CollaboratorArgs = {
  where: Collection_Collaborator_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Collaborator_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_InviteArgs = {
  where: Collection_Invite_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Invite_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_Invite_StatusArgs = {
  where: Collection_Invite_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Invite_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Collection_Item_ClaimArgs = {
  where: Collection_Item_Claim_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Item_Claim_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_Item_Claim_StatusArgs = {
  where: Collection_Item_Claim_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Item_Claim_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Collection_SectionArgs = {
  where: Collection_Section_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Section_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_Section_Cart_ItemArgs = {
  where: Collection_Section_Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Section_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_Slug_HistoryArgs = {
  where: Collection_Slug_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Slug_History_By_PkArgs = {
  collection_id: Scalars['uuid']
  slug: Scalars['name']
}

/** mutation root */
export type Mutation_RootDelete_Collection_StateArgs = {
  where: Collection_State_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_State_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Collection_TypeArgs = {
  where: Collection_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_CommentArgs = {
  where: Comment_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Comment_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Commentable_ItemArgs = {
  where: Commentable_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Commentable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Commentable_Item_Type_Enum
}

/** mutation root */
export type Mutation_RootDelete_Commentable_Item_TypeArgs = {
  where: Commentable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Commentable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Communication_TemplateArgs = {
  where: Communication_Template_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Communication_Template_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Communication_Template_Full_ListArgs = {
  where: Communication_Template_Full_List_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Communication_Template_Full_List_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Contact_Info_TypeArgs = {
  where: Contact_Info_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Contact_Info_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Contact_InviteArgs = {
  where: Contact_Invite_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Contact_Invite_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Contact_Invite_StatusArgs = {
  where: Contact_Invite_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Contact_Invite_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Contact_Invite_TypeArgs = {
  where: Contact_Invite_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Contact_Invite_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Deal_Hop_GateArgs = {
  where: Deal_Hop_Gate_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Deal_Hop_Gate_By_PkArgs = {
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Device_Client_DataArgs = {
  where: Device_Client_Data_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Device_Client_Data_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Discovery_BacklogArgs = {
  where: Discovery_Backlog_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Discovery_Backlog_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Discovery_FeedArgs = {
  where: Discovery_Feed_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Discovery_Feed_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Discovery_StagingArgs = {
  where: Discovery_Staging_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Discovery_Staging_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Featured_ShopArgs = {
  where: Featured_Shop_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Featured_Shop_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Featured_Shop_ItemArgs = {
  where: Featured_Shop_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Featured_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Feed_ItemArgs = {
  where: Feed_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Feed_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Feed_Item_LikeArgs = {
  where: Feed_Item_Like_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Feed_Item_Like_By_PkArgs = {
  feed_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Feed_Item_TypeArgs = {
  where: Feed_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Feed_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Fmtc_DealArgs = {
  where: Fmtc_Deal_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Fmtc_Deal_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Followable_ItemArgs = {
  where: Followable_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Followable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Followable_Item_Type_Enum
}

/** mutation root */
export type Mutation_RootDelete_Followable_Item_TypeArgs = {
  where: Followable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Followable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Leaderboard_EventArgs = {
  where: Leaderboard_Event_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Leaderboard_Event_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Leaderboard_Event_NameArgs = {
  where: Leaderboard_Event_Name_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Leaderboard_Event_Name_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Link_TypeArgs = {
  where: Link_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Link_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Media_Enabled_ItemArgs = {
  where: Media_Enabled_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Media_Enabled_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Media_Item_Type_Enum
}

/** mutation root */
export type Mutation_RootDelete_Media_ItemArgs = {
  where: Media_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Media_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Media_Item_TypeArgs = {
  where: Media_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Media_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Media_ItemsArgs = {
  where: Media_Items_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Media_Items_By_PkArgs = {
  media_enabled_item_id: Scalars['uuid']
  media_enabled_item_type: Scalars['String']
  media_id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Media_TypeArgs = {
  where: Media_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Media_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Mobile_App_HomeArgs = {
  where: Mobile_App_Home_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Mobile_App_Home_By_PkArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_Mobile_HomeArgs = {
  where: Mobile_Home_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Mobile_Home_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Mobile_Push_NotificationArgs = {
  where: Mobile_Push_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Mobile_Push_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Mobile_Push_Notification_StatusArgs = {
  where: Mobile_Push_Notification_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Mobile_Push_Notification_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Notifiable_ItemArgs = {
  where: Notifiable_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notifiable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Notifiable_Item_Type_Enum
}

/** mutation root */
export type Mutation_RootDelete_Notifiable_Item_TypeArgs = {
  where: Notifiable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notifiable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_NotificationArgs = {
  where: Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Notification_Center_ItemArgs = {
  where: Notification_Center_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notification_Center_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Notification_Center_Item_Read_StatusArgs = {
  where: Notification_Center_Item_Read_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notification_Center_Item_Read_Status_By_PkArgs =
  {
    notification_center_item_id: Scalars['uuid']
    user_id: Scalars['String']
  }

/** mutation root */
export type Mutation_RootDelete_Notification_Center_Item_TypeArgs = {
  where: Notification_Center_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notification_Center_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Notification_PreferenceArgs = {
  where: Notification_Preference_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notification_Preference_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Notification_StatusArgs = {
  where: Notification_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notification_Status_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Onboarding_CategoriesArgs = {
  where: Onboarding_Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Onboarding_Categories_By_PkArgs = {
  category: Shop_Category_Enum
}

/** mutation root */
export type Mutation_RootDelete_Onboarding_SourcesArgs = {
  where: Onboarding_Sources_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Onboarding_Sources_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_One_Off_EmailArgs = {
  where: One_Off_Email_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_One_Off_Email_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Pageview_EventArgs = {
  where: Pageview_Event_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Pageview_Event_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Promo_PeriodArgs = {
  where: Promo_Period_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Promo_Period_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Push_NotificationArgs = {
  where: Push_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Push_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Push_Notification_StatusArgs = {
  where: Push_Notification_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Push_Notification_Status_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Redirect_IdsArgs = {
  where: Redirect_Ids_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Redirect_Ids_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Remove_ReasonArgs = {
  where: Remove_Reason_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Remove_Reason_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Rewardable_Action_NamesArgs = {
  where: Rewardable_Action_Names_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Rewardable_Action_Names_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Rewardable_Item_TypeArgs = {
  where: Rewardable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Rewardable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Search_QueryArgs = {
  where: Search_Query_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Search_Query_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Search_Query_ResultArgs = {
  where: Search_Query_Result_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Search_Query_Result_By_PkArgs = {
  search_query_id: Scalars['uuid']
  shop_item_id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_ShopArgs = {
  where: Shop_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_AffiliateArgs = {
  where: Shop_Affiliate_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Affiliate_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Shop_By_PkArgs = {
  key: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Shop_CategorizationArgs = {
  where: Shop_Categorization_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Categorization_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Shop_CategoryArgs = {
  where: Shop_Category_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Category_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Shop_ItemArgs = {
  where: Shop_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_AvailabilityArgs = {
  where: Shop_Item_Availability_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_Availability_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_Enrichment_StatusArgs = {
  where: Shop_Item_Enrichment_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_Enrichment_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_HistoryArgs = {
  where: Shop_Item_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_History_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_ImageArgs = {
  where: Shop_Item_Image_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_Image_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_IndexArgs = {
  where: Shop_Item_Index_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_Index_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_To_Be_ScrapedArgs = {
  where: Shop_Item_To_Be_Scraped_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_RatingArgs = {
  where: Shop_Rating_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Rating_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Similar_Products_HistoryArgs = {
  where: Similar_Products_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Similar_Products_History_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Account_TypeArgs = {
  where: User_Account_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Account_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_ActionArgs = {
  where: User_Action_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Action_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_App_VersionArgs = {
  where: User_App_Version_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_App_Version_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_Carts_OnboardingArgs = {
  where: User_Carts_Onboarding_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Carts_Onboarding_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_CategoryArgs = {
  where: User_Category_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Category_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_DeletionArgs = {
  where: User_Deletion_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Deletion_By_PkArgs = {
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_FeedbackArgs = {
  where: User_Feedback_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Feedback_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Feedback_ItemArgs = {
  where: User_Feedback_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Feedback_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: User_Feedback_Type_Enum
}

/** mutation root */
export type Mutation_RootDelete_User_Feedback_TypeArgs = {
  where: User_Feedback_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Feedback_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_FlagsArgs = {
  where: User_Flags_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Flags_By_PkArgs = {
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_ImpersonationArgs = {
  where: User_Impersonation_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Impersonation_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Ip_AddressArgs = {
  where: User_Ip_Address_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Ip_Address_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Items_FollowedArgs = {
  where: User_Items_Followed_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Items_Followed_By_PkArgs = {
  followable_item_id: Scalars['uuid']
  followable_item_type: Scalars['String']
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_LinksArgs = {
  where: User_Links_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Links_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Onboarding_SourceArgs = {
  where: User_Onboarding_Source_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Onboarding_Source_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Onboarding_StateArgs = {
  where: User_Onboarding_State_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Onboarding_State_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_Phone_ContactArgs = {
  where: User_Phone_Contact_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Phone_Contact_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Purchase_SubscriptionArgs = {
  where: User_Purchase_Subscription_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Purchase_Subscription_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_PurchasesArgs = {
  where: User_Purchases_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Purchases_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Push_NotificationArgs = {
  where: User_Push_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Push_Notification_TokenArgs = {
  where: User_Push_Notification_Token_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Push_Notification_Token_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_ReferralArgs = {
  where: User_Referral_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Referral_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Reward_Multiplier_ConfigArgs = {
  where: User_Reward_Multiplier_Config_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Reward_Multiplier_Config_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Reward_RedemptionArgs = {
  where: User_Reward_Redemption_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Reward_Redemption_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Reward_ReferenceArgs = {
  where: User_Reward_Reference_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Reward_Reference_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Shop_FavoriteArgs = {
  where: User_Shop_Favorite_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Shop_Favorite_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Shopping_PreferencesArgs = {
  where: User_Shopping_Preferences_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Shopping_Preferences_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_SubscriptionArgs = {
  where: User_Subscription_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Subscription_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Subscription_StatusArgs = {
  where: User_Subscription_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Subscription_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_TempArgs = {
  where: User_Temp_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Temp_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Worflow_TypeArgs = {
  where: Worflow_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Worflow_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_WorkflowArgs = {
  where: Workflow_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Workflow_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Workflow_StatusArgs = {
  where: Workflow_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Workflow_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootFullyUnsubscribeFromCreatorArgs = {
  creatorId: Scalars['String']
}

/** mutation root */
export type Mutation_RootInsert_Account_Deletion_ReasonArgs = {
  objects: Array<Account_Deletion_Reason_Insert_Input>
  on_conflict?: Maybe<Account_Deletion_Reason_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Account_Deletion_Reason_OneArgs = {
  object: Account_Deletion_Reason_Insert_Input
  on_conflict?: Maybe<Account_Deletion_Reason_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_AffiliateArgs = {
  objects: Array<Affiliate_Insert_Input>
  on_conflict?: Maybe<Affiliate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_Advertiser_IdArgs = {
  objects: Array<Affiliate_Advertiser_Id_Insert_Input>
  on_conflict?: Maybe<Affiliate_Advertiser_Id_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_Advertiser_Id_OneArgs = {
  object: Affiliate_Advertiser_Id_Insert_Input
  on_conflict?: Maybe<Affiliate_Advertiser_Id_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_MetaArgs = {
  objects: Array<Affiliate_Meta_Insert_Input>
  on_conflict?: Maybe<Affiliate_Meta_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_Meta_OneArgs = {
  object: Affiliate_Meta_Insert_Input
  on_conflict?: Maybe<Affiliate_Meta_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_OneArgs = {
  object: Affiliate_Insert_Input
  on_conflict?: Maybe<Affiliate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_TransactionArgs = {
  objects: Array<Affiliate_Transaction_Insert_Input>
  on_conflict?: Maybe<Affiliate_Transaction_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_Transaction_OneArgs = {
  object: Affiliate_Transaction_Insert_Input
  on_conflict?: Maybe<Affiliate_Transaction_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Billing_ModeArgs = {
  objects: Array<Billing_Mode_Insert_Input>
  on_conflict?: Maybe<Billing_Mode_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Billing_Mode_OneArgs = {
  object: Billing_Mode_Insert_Input
  on_conflict?: Maybe<Billing_Mode_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Billing_Platform_Connected_AccountArgs = {
  objects: Array<Billing_Platform_Connected_Account_Insert_Input>
  on_conflict?: Maybe<Billing_Platform_Connected_Account_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Billing_Platform_Connected_Account_OneArgs = {
  object: Billing_Platform_Connected_Account_Insert_Input
  on_conflict?: Maybe<Billing_Platform_Connected_Account_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Billing_Platform_Connected_Account_StatusArgs =
  {
    objects: Array<Billing_Platform_Connected_Account_Status_Insert_Input>
    on_conflict?: Maybe<Billing_Platform_Connected_Account_Status_On_Conflict>
  }

/** mutation root */
export type Mutation_RootInsert_Billing_Platform_Connected_Account_Status_OneArgs =
  {
    object: Billing_Platform_Connected_Account_Status_Insert_Input
    on_conflict?: Maybe<Billing_Platform_Connected_Account_Status_On_Conflict>
  }

/** mutation root */
export type Mutation_RootInsert_Billing_Platform_CustomerArgs = {
  objects: Array<Billing_Platform_Customer_Insert_Input>
  on_conflict?: Maybe<Billing_Platform_Customer_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Billing_Platform_Customer_OneArgs = {
  object: Billing_Platform_Customer_Insert_Input
  on_conflict?: Maybe<Billing_Platform_Customer_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CartArgs = {
  objects: Array<Cart_Insert_Input>
  on_conflict?: Maybe<Cart_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_ItemArgs = {
  objects: Array<Cart_Item_Insert_Input>
  on_conflict?: Maybe<Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_Item_LikeArgs = {
  objects: Array<Cart_Item_Like_Insert_Input>
  on_conflict?: Maybe<Cart_Item_Like_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_Item_Like_OneArgs = {
  object: Cart_Item_Like_Insert_Input
  on_conflict?: Maybe<Cart_Item_Like_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_Item_NoteArgs = {
  objects: Array<Cart_Item_Note_Insert_Input>
  on_conflict?: Maybe<Cart_Item_Note_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_Item_Note_OneArgs = {
  object: Cart_Item_Note_Insert_Input
  on_conflict?: Maybe<Cart_Item_Note_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_Item_OneArgs = {
  object: Cart_Item_Insert_Input
  on_conflict?: Maybe<Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_OneArgs = {
  object: Cart_Insert_Input
  on_conflict?: Maybe<Cart_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>
  on_conflict?: Maybe<Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input
  on_conflict?: Maybe<Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Category_Shop_ItemArgs = {
  objects: Array<Category_Shop_Item_Insert_Input>
  on_conflict?: Maybe<Category_Shop_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Category_Shop_Item_OneArgs = {
  object: Category_Shop_Item_Insert_Input
  on_conflict?: Maybe<Category_Shop_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CheckoutArgs = {
  objects: Array<Checkout_Insert_Input>
  on_conflict?: Maybe<Checkout_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Checkout_OneArgs = {
  object: Checkout_Insert_Input
  on_conflict?: Maybe<Checkout_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CollectionArgs = {
  objects: Array<Collection_Insert_Input>
  on_conflict?: Maybe<Collection_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Cart_ItemArgs = {
  objects: Array<Collection_Cart_Item_Insert_Input>
  on_conflict?: Maybe<Collection_Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Cart_Item_OneArgs = {
  object: Collection_Cart_Item_Insert_Input
  on_conflict?: Maybe<Collection_Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_CollaboratorArgs = {
  objects: Array<Collection_Collaborator_Insert_Input>
  on_conflict?: Maybe<Collection_Collaborator_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Collaborator_OneArgs = {
  object: Collection_Collaborator_Insert_Input
  on_conflict?: Maybe<Collection_Collaborator_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_InviteArgs = {
  objects: Array<Collection_Invite_Insert_Input>
  on_conflict?: Maybe<Collection_Invite_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Invite_OneArgs = {
  object: Collection_Invite_Insert_Input
  on_conflict?: Maybe<Collection_Invite_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Invite_StatusArgs = {
  objects: Array<Collection_Invite_Status_Insert_Input>
  on_conflict?: Maybe<Collection_Invite_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Invite_Status_OneArgs = {
  object: Collection_Invite_Status_Insert_Input
  on_conflict?: Maybe<Collection_Invite_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Item_ClaimArgs = {
  objects: Array<Collection_Item_Claim_Insert_Input>
  on_conflict?: Maybe<Collection_Item_Claim_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Item_Claim_OneArgs = {
  object: Collection_Item_Claim_Insert_Input
  on_conflict?: Maybe<Collection_Item_Claim_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Item_Claim_StatusArgs = {
  objects: Array<Collection_Item_Claim_Status_Insert_Input>
  on_conflict?: Maybe<Collection_Item_Claim_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Item_Claim_Status_OneArgs = {
  object: Collection_Item_Claim_Status_Insert_Input
  on_conflict?: Maybe<Collection_Item_Claim_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_OneArgs = {
  object: Collection_Insert_Input
  on_conflict?: Maybe<Collection_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_SectionArgs = {
  objects: Array<Collection_Section_Insert_Input>
  on_conflict?: Maybe<Collection_Section_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Section_Cart_ItemArgs = {
  objects: Array<Collection_Section_Cart_Item_Insert_Input>
  on_conflict?: Maybe<Collection_Section_Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Section_Cart_Item_OneArgs = {
  object: Collection_Section_Cart_Item_Insert_Input
  on_conflict?: Maybe<Collection_Section_Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Section_OneArgs = {
  object: Collection_Section_Insert_Input
  on_conflict?: Maybe<Collection_Section_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Slug_HistoryArgs = {
  objects: Array<Collection_Slug_History_Insert_Input>
  on_conflict?: Maybe<Collection_Slug_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Slug_History_OneArgs = {
  object: Collection_Slug_History_Insert_Input
  on_conflict?: Maybe<Collection_Slug_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_StateArgs = {
  objects: Array<Collection_State_Insert_Input>
  on_conflict?: Maybe<Collection_State_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_State_OneArgs = {
  object: Collection_State_Insert_Input
  on_conflict?: Maybe<Collection_State_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_TypeArgs = {
  objects: Array<Collection_Type_Insert_Input>
  on_conflict?: Maybe<Collection_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Type_OneArgs = {
  object: Collection_Type_Insert_Input
  on_conflict?: Maybe<Collection_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CommentArgs = {
  objects: Array<Comment_Insert_Input>
  on_conflict?: Maybe<Comment_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Comment_OneArgs = {
  object: Comment_Insert_Input
  on_conflict?: Maybe<Comment_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Commentable_ItemArgs = {
  objects: Array<Commentable_Item_Insert_Input>
  on_conflict?: Maybe<Commentable_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Commentable_Item_OneArgs = {
  object: Commentable_Item_Insert_Input
  on_conflict?: Maybe<Commentable_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Commentable_Item_TypeArgs = {
  objects: Array<Commentable_Item_Type_Insert_Input>
  on_conflict?: Maybe<Commentable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Commentable_Item_Type_OneArgs = {
  object: Commentable_Item_Type_Insert_Input
  on_conflict?: Maybe<Commentable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_TemplateArgs = {
  objects: Array<Communication_Template_Insert_Input>
  on_conflict?: Maybe<Communication_Template_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_Template_Full_ListArgs = {
  objects: Array<Communication_Template_Full_List_Insert_Input>
  on_conflict?: Maybe<Communication_Template_Full_List_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_Template_Full_List_OneArgs = {
  object: Communication_Template_Full_List_Insert_Input
  on_conflict?: Maybe<Communication_Template_Full_List_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_Template_OneArgs = {
  object: Communication_Template_Insert_Input
  on_conflict?: Maybe<Communication_Template_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Info_TypeArgs = {
  objects: Array<Contact_Info_Type_Insert_Input>
  on_conflict?: Maybe<Contact_Info_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Info_Type_OneArgs = {
  object: Contact_Info_Type_Insert_Input
  on_conflict?: Maybe<Contact_Info_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_InviteArgs = {
  objects: Array<Contact_Invite_Insert_Input>
  on_conflict?: Maybe<Contact_Invite_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Invite_OneArgs = {
  object: Contact_Invite_Insert_Input
  on_conflict?: Maybe<Contact_Invite_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Invite_StatusArgs = {
  objects: Array<Contact_Invite_Status_Insert_Input>
  on_conflict?: Maybe<Contact_Invite_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Invite_Status_OneArgs = {
  object: Contact_Invite_Status_Insert_Input
  on_conflict?: Maybe<Contact_Invite_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Invite_TypeArgs = {
  objects: Array<Contact_Invite_Type_Insert_Input>
  on_conflict?: Maybe<Contact_Invite_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Invite_Type_OneArgs = {
  object: Contact_Invite_Type_Insert_Input
  on_conflict?: Maybe<Contact_Invite_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Deal_Hop_GateArgs = {
  objects: Array<Deal_Hop_Gate_Insert_Input>
  on_conflict?: Maybe<Deal_Hop_Gate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Deal_Hop_Gate_OneArgs = {
  object: Deal_Hop_Gate_Insert_Input
  on_conflict?: Maybe<Deal_Hop_Gate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_Client_DataArgs = {
  objects: Array<Device_Client_Data_Insert_Input>
  on_conflict?: Maybe<Device_Client_Data_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_Client_Data_OneArgs = {
  object: Device_Client_Data_Insert_Input
  on_conflict?: Maybe<Device_Client_Data_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Discovery_BacklogArgs = {
  objects: Array<Discovery_Backlog_Insert_Input>
  on_conflict?: Maybe<Discovery_Backlog_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Discovery_Backlog_OneArgs = {
  object: Discovery_Backlog_Insert_Input
  on_conflict?: Maybe<Discovery_Backlog_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Discovery_FeedArgs = {
  objects: Array<Discovery_Feed_Insert_Input>
  on_conflict?: Maybe<Discovery_Feed_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Discovery_Feed_OneArgs = {
  object: Discovery_Feed_Insert_Input
  on_conflict?: Maybe<Discovery_Feed_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Discovery_StagingArgs = {
  objects: Array<Discovery_Staging_Insert_Input>
  on_conflict?: Maybe<Discovery_Staging_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Discovery_Staging_OneArgs = {
  object: Discovery_Staging_Insert_Input
  on_conflict?: Maybe<Discovery_Staging_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Featured_ShopArgs = {
  objects: Array<Featured_Shop_Insert_Input>
  on_conflict?: Maybe<Featured_Shop_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Featured_Shop_ItemArgs = {
  objects: Array<Featured_Shop_Item_Insert_Input>
  on_conflict?: Maybe<Featured_Shop_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Featured_Shop_Item_OneArgs = {
  object: Featured_Shop_Item_Insert_Input
  on_conflict?: Maybe<Featured_Shop_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Featured_Shop_OneArgs = {
  object: Featured_Shop_Insert_Input
  on_conflict?: Maybe<Featured_Shop_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Feed_ItemArgs = {
  objects: Array<Feed_Item_Insert_Input>
  on_conflict?: Maybe<Feed_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Feed_Item_LikeArgs = {
  objects: Array<Feed_Item_Like_Insert_Input>
  on_conflict?: Maybe<Feed_Item_Like_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Feed_Item_Like_OneArgs = {
  object: Feed_Item_Like_Insert_Input
  on_conflict?: Maybe<Feed_Item_Like_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Feed_Item_OneArgs = {
  object: Feed_Item_Insert_Input
  on_conflict?: Maybe<Feed_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Feed_Item_TypeArgs = {
  objects: Array<Feed_Item_Type_Insert_Input>
  on_conflict?: Maybe<Feed_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Feed_Item_Type_OneArgs = {
  object: Feed_Item_Type_Insert_Input
  on_conflict?: Maybe<Feed_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Fmtc_DealArgs = {
  objects: Array<Fmtc_Deal_Insert_Input>
  on_conflict?: Maybe<Fmtc_Deal_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Fmtc_Deal_OneArgs = {
  object: Fmtc_Deal_Insert_Input
  on_conflict?: Maybe<Fmtc_Deal_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Followable_ItemArgs = {
  objects: Array<Followable_Item_Insert_Input>
  on_conflict?: Maybe<Followable_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Followable_Item_OneArgs = {
  object: Followable_Item_Insert_Input
  on_conflict?: Maybe<Followable_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Followable_Item_TypeArgs = {
  objects: Array<Followable_Item_Type_Insert_Input>
  on_conflict?: Maybe<Followable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Followable_Item_Type_OneArgs = {
  object: Followable_Item_Type_Insert_Input
  on_conflict?: Maybe<Followable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Leaderboard_EventArgs = {
  objects: Array<Leaderboard_Event_Insert_Input>
  on_conflict?: Maybe<Leaderboard_Event_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Leaderboard_Event_NameArgs = {
  objects: Array<Leaderboard_Event_Name_Insert_Input>
  on_conflict?: Maybe<Leaderboard_Event_Name_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Leaderboard_Event_Name_OneArgs = {
  object: Leaderboard_Event_Name_Insert_Input
  on_conflict?: Maybe<Leaderboard_Event_Name_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Leaderboard_Event_OneArgs = {
  object: Leaderboard_Event_Insert_Input
  on_conflict?: Maybe<Leaderboard_Event_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Link_TypeArgs = {
  objects: Array<Link_Type_Insert_Input>
  on_conflict?: Maybe<Link_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Link_Type_OneArgs = {
  object: Link_Type_Insert_Input
  on_conflict?: Maybe<Link_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Enabled_ItemArgs = {
  objects: Array<Media_Enabled_Item_Insert_Input>
  on_conflict?: Maybe<Media_Enabled_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Enabled_Item_OneArgs = {
  object: Media_Enabled_Item_Insert_Input
  on_conflict?: Maybe<Media_Enabled_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_ItemArgs = {
  objects: Array<Media_Item_Insert_Input>
  on_conflict?: Maybe<Media_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Item_OneArgs = {
  object: Media_Item_Insert_Input
  on_conflict?: Maybe<Media_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Item_TypeArgs = {
  objects: Array<Media_Item_Type_Insert_Input>
  on_conflict?: Maybe<Media_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Item_Type_OneArgs = {
  object: Media_Item_Type_Insert_Input
  on_conflict?: Maybe<Media_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_ItemsArgs = {
  objects: Array<Media_Items_Insert_Input>
  on_conflict?: Maybe<Media_Items_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Items_OneArgs = {
  object: Media_Items_Insert_Input
  on_conflict?: Maybe<Media_Items_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_TypeArgs = {
  objects: Array<Media_Type_Insert_Input>
  on_conflict?: Maybe<Media_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Type_OneArgs = {
  object: Media_Type_Insert_Input
  on_conflict?: Maybe<Media_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_App_HomeArgs = {
  objects: Array<Mobile_App_Home_Insert_Input>
  on_conflict?: Maybe<Mobile_App_Home_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_App_Home_OneArgs = {
  object: Mobile_App_Home_Insert_Input
  on_conflict?: Maybe<Mobile_App_Home_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_HomeArgs = {
  objects: Array<Mobile_Home_Insert_Input>
  on_conflict?: Maybe<Mobile_Home_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_Home_OneArgs = {
  object: Mobile_Home_Insert_Input
  on_conflict?: Maybe<Mobile_Home_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_Push_NotificationArgs = {
  objects: Array<Mobile_Push_Notification_Insert_Input>
  on_conflict?: Maybe<Mobile_Push_Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_Push_Notification_OneArgs = {
  object: Mobile_Push_Notification_Insert_Input
  on_conflict?: Maybe<Mobile_Push_Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_Push_Notification_StatusArgs = {
  objects: Array<Mobile_Push_Notification_Status_Insert_Input>
  on_conflict?: Maybe<Mobile_Push_Notification_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_Push_Notification_Status_OneArgs = {
  object: Mobile_Push_Notification_Status_Insert_Input
  on_conflict?: Maybe<Mobile_Push_Notification_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notifiable_ItemArgs = {
  objects: Array<Notifiable_Item_Insert_Input>
  on_conflict?: Maybe<Notifiable_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notifiable_Item_OneArgs = {
  object: Notifiable_Item_Insert_Input
  on_conflict?: Maybe<Notifiable_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notifiable_Item_TypeArgs = {
  objects: Array<Notifiable_Item_Type_Insert_Input>
  on_conflict?: Maybe<Notifiable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notifiable_Item_Type_OneArgs = {
  object: Notifiable_Item_Type_Insert_Input
  on_conflict?: Maybe<Notifiable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_NotificationArgs = {
  objects: Array<Notification_Insert_Input>
  on_conflict?: Maybe<Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Center_ItemArgs = {
  objects: Array<Notification_Center_Item_Insert_Input>
  on_conflict?: Maybe<Notification_Center_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Center_Item_OneArgs = {
  object: Notification_Center_Item_Insert_Input
  on_conflict?: Maybe<Notification_Center_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Center_Item_Read_StatusArgs = {
  objects: Array<Notification_Center_Item_Read_Status_Insert_Input>
  on_conflict?: Maybe<Notification_Center_Item_Read_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Center_Item_Read_Status_OneArgs = {
  object: Notification_Center_Item_Read_Status_Insert_Input
  on_conflict?: Maybe<Notification_Center_Item_Read_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Center_Item_TypeArgs = {
  objects: Array<Notification_Center_Item_Type_Insert_Input>
  on_conflict?: Maybe<Notification_Center_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Center_Item_Type_OneArgs = {
  object: Notification_Center_Item_Type_Insert_Input
  on_conflict?: Maybe<Notification_Center_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_OneArgs = {
  object: Notification_Insert_Input
  on_conflict?: Maybe<Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_PreferenceArgs = {
  objects: Array<Notification_Preference_Insert_Input>
  on_conflict?: Maybe<Notification_Preference_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Preference_OneArgs = {
  object: Notification_Preference_Insert_Input
  on_conflict?: Maybe<Notification_Preference_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_StatusArgs = {
  objects: Array<Notification_Status_Insert_Input>
  on_conflict?: Maybe<Notification_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Status_OneArgs = {
  object: Notification_Status_Insert_Input
  on_conflict?: Maybe<Notification_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Onboarding_CategoriesArgs = {
  objects: Array<Onboarding_Categories_Insert_Input>
  on_conflict?: Maybe<Onboarding_Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Onboarding_Categories_OneArgs = {
  object: Onboarding_Categories_Insert_Input
  on_conflict?: Maybe<Onboarding_Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Onboarding_SourcesArgs = {
  objects: Array<Onboarding_Sources_Insert_Input>
  on_conflict?: Maybe<Onboarding_Sources_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Onboarding_Sources_OneArgs = {
  object: Onboarding_Sources_Insert_Input
  on_conflict?: Maybe<Onboarding_Sources_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_One_Off_EmailArgs = {
  objects: Array<One_Off_Email_Insert_Input>
  on_conflict?: Maybe<One_Off_Email_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_One_Off_Email_OneArgs = {
  object: One_Off_Email_Insert_Input
  on_conflict?: Maybe<One_Off_Email_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Pageview_EventArgs = {
  objects: Array<Pageview_Event_Insert_Input>
  on_conflict?: Maybe<Pageview_Event_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Pageview_Event_OneArgs = {
  object: Pageview_Event_Insert_Input
  on_conflict?: Maybe<Pageview_Event_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Promo_PeriodArgs = {
  objects: Array<Promo_Period_Insert_Input>
  on_conflict?: Maybe<Promo_Period_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Promo_Period_OneArgs = {
  object: Promo_Period_Insert_Input
  on_conflict?: Maybe<Promo_Period_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Push_NotificationArgs = {
  objects: Array<Push_Notification_Insert_Input>
  on_conflict?: Maybe<Push_Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Push_Notification_OneArgs = {
  object: Push_Notification_Insert_Input
  on_conflict?: Maybe<Push_Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Push_Notification_StatusArgs = {
  objects: Array<Push_Notification_Status_Insert_Input>
  on_conflict?: Maybe<Push_Notification_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Push_Notification_Status_OneArgs = {
  object: Push_Notification_Status_Insert_Input
  on_conflict?: Maybe<Push_Notification_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Redirect_IdsArgs = {
  objects: Array<Redirect_Ids_Insert_Input>
  on_conflict?: Maybe<Redirect_Ids_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Redirect_Ids_OneArgs = {
  object: Redirect_Ids_Insert_Input
  on_conflict?: Maybe<Redirect_Ids_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Remove_ReasonArgs = {
  objects: Array<Remove_Reason_Insert_Input>
  on_conflict?: Maybe<Remove_Reason_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Remove_Reason_OneArgs = {
  object: Remove_Reason_Insert_Input
  on_conflict?: Maybe<Remove_Reason_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rewardable_Action_NamesArgs = {
  objects: Array<Rewardable_Action_Names_Insert_Input>
  on_conflict?: Maybe<Rewardable_Action_Names_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rewardable_Action_Names_OneArgs = {
  object: Rewardable_Action_Names_Insert_Input
  on_conflict?: Maybe<Rewardable_Action_Names_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rewardable_Item_TypeArgs = {
  objects: Array<Rewardable_Item_Type_Insert_Input>
  on_conflict?: Maybe<Rewardable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rewardable_Item_Type_OneArgs = {
  object: Rewardable_Item_Type_Insert_Input
  on_conflict?: Maybe<Rewardable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Search_QueryArgs = {
  objects: Array<Search_Query_Insert_Input>
  on_conflict?: Maybe<Search_Query_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Search_Query_OneArgs = {
  object: Search_Query_Insert_Input
  on_conflict?: Maybe<Search_Query_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Search_Query_ResultArgs = {
  objects: Array<Search_Query_Result_Insert_Input>
  on_conflict?: Maybe<Search_Query_Result_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Search_Query_Result_OneArgs = {
  object: Search_Query_Result_Insert_Input
  on_conflict?: Maybe<Search_Query_Result_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ShopArgs = {
  objects: Array<Shop_Insert_Input>
  on_conflict?: Maybe<Shop_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_AffiliateArgs = {
  objects: Array<Shop_Affiliate_Insert_Input>
  on_conflict?: Maybe<Shop_Affiliate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Affiliate_OneArgs = {
  object: Shop_Affiliate_Insert_Input
  on_conflict?: Maybe<Shop_Affiliate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_CategorizationArgs = {
  objects: Array<Shop_Categorization_Insert_Input>
  on_conflict?: Maybe<Shop_Categorization_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Categorization_OneArgs = {
  object: Shop_Categorization_Insert_Input
  on_conflict?: Maybe<Shop_Categorization_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_CategoryArgs = {
  objects: Array<Shop_Category_Insert_Input>
  on_conflict?: Maybe<Shop_Category_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Category_OneArgs = {
  object: Shop_Category_Insert_Input
  on_conflict?: Maybe<Shop_Category_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_ItemArgs = {
  objects: Array<Shop_Item_Insert_Input>
  on_conflict?: Maybe<Shop_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_AvailabilityArgs = {
  objects: Array<Shop_Item_Availability_Insert_Input>
  on_conflict?: Maybe<Shop_Item_Availability_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_Availability_OneArgs = {
  object: Shop_Item_Availability_Insert_Input
  on_conflict?: Maybe<Shop_Item_Availability_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_Enrichment_StatusArgs = {
  objects: Array<Shop_Item_Enrichment_Status_Insert_Input>
  on_conflict?: Maybe<Shop_Item_Enrichment_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_Enrichment_Status_OneArgs = {
  object: Shop_Item_Enrichment_Status_Insert_Input
  on_conflict?: Maybe<Shop_Item_Enrichment_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_HistoryArgs = {
  objects: Array<Shop_Item_History_Insert_Input>
  on_conflict?: Maybe<Shop_Item_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_History_OneArgs = {
  object: Shop_Item_History_Insert_Input
  on_conflict?: Maybe<Shop_Item_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_ImageArgs = {
  objects: Array<Shop_Item_Image_Insert_Input>
  on_conflict?: Maybe<Shop_Item_Image_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_Image_OneArgs = {
  object: Shop_Item_Image_Insert_Input
  on_conflict?: Maybe<Shop_Item_Image_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_IndexArgs = {
  objects: Array<Shop_Item_Index_Insert_Input>
  on_conflict?: Maybe<Shop_Item_Index_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_Index_OneArgs = {
  object: Shop_Item_Index_Insert_Input
  on_conflict?: Maybe<Shop_Item_Index_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_OneArgs = {
  object: Shop_Item_Insert_Input
  on_conflict?: Maybe<Shop_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_To_Be_ScrapedArgs = {
  objects: Array<Shop_Item_To_Be_Scraped_Insert_Input>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_To_Be_Scraped_OneArgs = {
  object: Shop_Item_To_Be_Scraped_Insert_Input
}

/** mutation root */
export type Mutation_RootInsert_Shop_OneArgs = {
  object: Shop_Insert_Input
  on_conflict?: Maybe<Shop_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_RatingArgs = {
  objects: Array<Shop_Rating_Insert_Input>
  on_conflict?: Maybe<Shop_Rating_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Rating_OneArgs = {
  object: Shop_Rating_Insert_Input
  on_conflict?: Maybe<Shop_Rating_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Similar_Products_HistoryArgs = {
  objects: Array<Similar_Products_History_Insert_Input>
  on_conflict?: Maybe<Similar_Products_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Similar_Products_History_OneArgs = {
  object: Similar_Products_History_Insert_Input
  on_conflict?: Maybe<Similar_Products_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>
  on_conflict?: Maybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Account_TypeArgs = {
  objects: Array<User_Account_Type_Insert_Input>
  on_conflict?: Maybe<User_Account_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Account_Type_OneArgs = {
  object: User_Account_Type_Insert_Input
  on_conflict?: Maybe<User_Account_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_ActionArgs = {
  objects: Array<User_Action_Insert_Input>
  on_conflict?: Maybe<User_Action_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Action_OneArgs = {
  object: User_Action_Insert_Input
  on_conflict?: Maybe<User_Action_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_App_VersionArgs = {
  objects: Array<User_App_Version_Insert_Input>
  on_conflict?: Maybe<User_App_Version_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_App_Version_OneArgs = {
  object: User_App_Version_Insert_Input
  on_conflict?: Maybe<User_App_Version_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Carts_OnboardingArgs = {
  objects: Array<User_Carts_Onboarding_Insert_Input>
  on_conflict?: Maybe<User_Carts_Onboarding_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Carts_Onboarding_OneArgs = {
  object: User_Carts_Onboarding_Insert_Input
  on_conflict?: Maybe<User_Carts_Onboarding_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_CategoryArgs = {
  objects: Array<User_Category_Insert_Input>
  on_conflict?: Maybe<User_Category_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Category_OneArgs = {
  object: User_Category_Insert_Input
  on_conflict?: Maybe<User_Category_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_DeletionArgs = {
  objects: Array<User_Deletion_Insert_Input>
  on_conflict?: Maybe<User_Deletion_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Deletion_OneArgs = {
  object: User_Deletion_Insert_Input
  on_conflict?: Maybe<User_Deletion_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_FeedbackArgs = {
  objects: Array<User_Feedback_Insert_Input>
  on_conflict?: Maybe<User_Feedback_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Feedback_ItemArgs = {
  objects: Array<User_Feedback_Item_Insert_Input>
  on_conflict?: Maybe<User_Feedback_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Feedback_Item_OneArgs = {
  object: User_Feedback_Item_Insert_Input
  on_conflict?: Maybe<User_Feedback_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Feedback_OneArgs = {
  object: User_Feedback_Insert_Input
  on_conflict?: Maybe<User_Feedback_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Feedback_TypeArgs = {
  objects: Array<User_Feedback_Type_Insert_Input>
  on_conflict?: Maybe<User_Feedback_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Feedback_Type_OneArgs = {
  object: User_Feedback_Type_Insert_Input
  on_conflict?: Maybe<User_Feedback_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_FlagsArgs = {
  objects: Array<User_Flags_Insert_Input>
  on_conflict?: Maybe<User_Flags_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Flags_OneArgs = {
  object: User_Flags_Insert_Input
  on_conflict?: Maybe<User_Flags_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_ImpersonationArgs = {
  objects: Array<User_Impersonation_Insert_Input>
  on_conflict?: Maybe<User_Impersonation_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Impersonation_OneArgs = {
  object: User_Impersonation_Insert_Input
  on_conflict?: Maybe<User_Impersonation_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Ip_AddressArgs = {
  objects: Array<User_Ip_Address_Insert_Input>
  on_conflict?: Maybe<User_Ip_Address_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Ip_Address_OneArgs = {
  object: User_Ip_Address_Insert_Input
  on_conflict?: Maybe<User_Ip_Address_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Items_FollowedArgs = {
  objects: Array<User_Items_Followed_Insert_Input>
  on_conflict?: Maybe<User_Items_Followed_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Items_Followed_OneArgs = {
  object: User_Items_Followed_Insert_Input
  on_conflict?: Maybe<User_Items_Followed_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_LinksArgs = {
  objects: Array<User_Links_Insert_Input>
  on_conflict?: Maybe<User_Links_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Links_OneArgs = {
  object: User_Links_Insert_Input
  on_conflict?: Maybe<User_Links_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Onboarding_SourceArgs = {
  objects: Array<User_Onboarding_Source_Insert_Input>
  on_conflict?: Maybe<User_Onboarding_Source_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Onboarding_Source_OneArgs = {
  object: User_Onboarding_Source_Insert_Input
  on_conflict?: Maybe<User_Onboarding_Source_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Onboarding_StateArgs = {
  objects: Array<User_Onboarding_State_Insert_Input>
  on_conflict?: Maybe<User_Onboarding_State_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Onboarding_State_OneArgs = {
  object: User_Onboarding_State_Insert_Input
  on_conflict?: Maybe<User_Onboarding_State_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input
  on_conflict?: Maybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Phone_ContactArgs = {
  objects: Array<User_Phone_Contact_Insert_Input>
  on_conflict?: Maybe<User_Phone_Contact_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Phone_Contact_OneArgs = {
  object: User_Phone_Contact_Insert_Input
  on_conflict?: Maybe<User_Phone_Contact_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Purchase_SubscriptionArgs = {
  objects: Array<User_Purchase_Subscription_Insert_Input>
  on_conflict?: Maybe<User_Purchase_Subscription_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Purchase_Subscription_OneArgs = {
  object: User_Purchase_Subscription_Insert_Input
  on_conflict?: Maybe<User_Purchase_Subscription_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_PurchasesArgs = {
  objects: Array<User_Purchases_Insert_Input>
  on_conflict?: Maybe<User_Purchases_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Purchases_OneArgs = {
  object: User_Purchases_Insert_Input
  on_conflict?: Maybe<User_Purchases_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Push_NotificationArgs = {
  objects: Array<User_Push_Notification_Insert_Input>
}

/** mutation root */
export type Mutation_RootInsert_User_Push_Notification_OneArgs = {
  object: User_Push_Notification_Insert_Input
}

/** mutation root */
export type Mutation_RootInsert_User_Push_Notification_TokenArgs = {
  objects: Array<User_Push_Notification_Token_Insert_Input>
  on_conflict?: Maybe<User_Push_Notification_Token_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Push_Notification_Token_OneArgs = {
  object: User_Push_Notification_Token_Insert_Input
  on_conflict?: Maybe<User_Push_Notification_Token_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_ReferralArgs = {
  objects: Array<User_Referral_Insert_Input>
  on_conflict?: Maybe<User_Referral_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Referral_OneArgs = {
  object: User_Referral_Insert_Input
  on_conflict?: Maybe<User_Referral_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Reward_Multiplier_ConfigArgs = {
  objects: Array<User_Reward_Multiplier_Config_Insert_Input>
  on_conflict?: Maybe<User_Reward_Multiplier_Config_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Reward_Multiplier_Config_OneArgs = {
  object: User_Reward_Multiplier_Config_Insert_Input
  on_conflict?: Maybe<User_Reward_Multiplier_Config_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Reward_RedemptionArgs = {
  objects: Array<User_Reward_Redemption_Insert_Input>
  on_conflict?: Maybe<User_Reward_Redemption_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Reward_Redemption_OneArgs = {
  object: User_Reward_Redemption_Insert_Input
  on_conflict?: Maybe<User_Reward_Redemption_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Reward_ReferenceArgs = {
  objects: Array<User_Reward_Reference_Insert_Input>
  on_conflict?: Maybe<User_Reward_Reference_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Reward_Reference_OneArgs = {
  object: User_Reward_Reference_Insert_Input
  on_conflict?: Maybe<User_Reward_Reference_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Shop_FavoriteArgs = {
  objects: Array<User_Shop_Favorite_Insert_Input>
  on_conflict?: Maybe<User_Shop_Favorite_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Shop_Favorite_OneArgs = {
  object: User_Shop_Favorite_Insert_Input
  on_conflict?: Maybe<User_Shop_Favorite_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Shopping_PreferencesArgs = {
  objects: Array<User_Shopping_Preferences_Insert_Input>
  on_conflict?: Maybe<User_Shopping_Preferences_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Shopping_Preferences_OneArgs = {
  object: User_Shopping_Preferences_Insert_Input
  on_conflict?: Maybe<User_Shopping_Preferences_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_SubscriptionArgs = {
  objects: Array<User_Subscription_Insert_Input>
  on_conflict?: Maybe<User_Subscription_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Subscription_OneArgs = {
  object: User_Subscription_Insert_Input
  on_conflict?: Maybe<User_Subscription_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Subscription_StatusArgs = {
  objects: Array<User_Subscription_Status_Insert_Input>
  on_conflict?: Maybe<User_Subscription_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Subscription_Status_OneArgs = {
  object: User_Subscription_Status_Insert_Input
  on_conflict?: Maybe<User_Subscription_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_TempArgs = {
  objects: Array<User_Temp_Insert_Input>
  on_conflict?: Maybe<User_Temp_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Temp_OneArgs = {
  object: User_Temp_Insert_Input
  on_conflict?: Maybe<User_Temp_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Worflow_TypeArgs = {
  objects: Array<Worflow_Type_Insert_Input>
  on_conflict?: Maybe<Worflow_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Worflow_Type_OneArgs = {
  object: Worflow_Type_Insert_Input
  on_conflict?: Maybe<Worflow_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_WorkflowArgs = {
  objects: Array<Workflow_Insert_Input>
  on_conflict?: Maybe<Workflow_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Workflow_OneArgs = {
  object: Workflow_Insert_Input
  on_conflict?: Maybe<Workflow_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Workflow_StatusArgs = {
  objects: Array<Workflow_Status_Insert_Input>
  on_conflict?: Maybe<Workflow_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Workflow_Status_OneArgs = {
  object: Workflow_Status_Insert_Input
  on_conflict?: Maybe<Workflow_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInviteCollaboratorArgs = {
  invite: CollectionCollaboratorInviteInput
}

/** mutation root */
export type Mutation_RootLikeFeedItemArgs = {
  likeData: FeedItemLikeInput
}

/** mutation root */
export type Mutation_RootSubscribeToCreatorArgs = {
  creatorId: Scalars['String']
}

/** mutation root */
export type Mutation_RootUpdate_Account_Deletion_ReasonArgs = {
  _set?: Maybe<Account_Deletion_Reason_Set_Input>
  where: Account_Deletion_Reason_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Account_Deletion_Reason_By_PkArgs = {
  _set?: Maybe<Account_Deletion_Reason_Set_Input>
  pk_columns: Account_Deletion_Reason_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Account_Deletion_Reason_ManyArgs = {
  updates: Array<Account_Deletion_Reason_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_AffiliateArgs = {
  _set?: Maybe<Affiliate_Set_Input>
  where: Affiliate_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Advertiser_IdArgs = {
  _set?: Maybe<Affiliate_Advertiser_Id_Set_Input>
  where: Affiliate_Advertiser_Id_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Advertiser_Id_By_PkArgs = {
  _set?: Maybe<Affiliate_Advertiser_Id_Set_Input>
  pk_columns: Affiliate_Advertiser_Id_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Advertiser_Id_ManyArgs = {
  updates: Array<Affiliate_Advertiser_Id_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_By_PkArgs = {
  _set?: Maybe<Affiliate_Set_Input>
  pk_columns: Affiliate_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_ManyArgs = {
  updates: Array<Affiliate_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_MetaArgs = {
  _append?: Maybe<Affiliate_Meta_Append_Input>
  _delete_at_path?: Maybe<Affiliate_Meta_Delete_At_Path_Input>
  _delete_elem?: Maybe<Affiliate_Meta_Delete_Elem_Input>
  _delete_key?: Maybe<Affiliate_Meta_Delete_Key_Input>
  _prepend?: Maybe<Affiliate_Meta_Prepend_Input>
  _set?: Maybe<Affiliate_Meta_Set_Input>
  where: Affiliate_Meta_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Meta_By_PkArgs = {
  _append?: Maybe<Affiliate_Meta_Append_Input>
  _delete_at_path?: Maybe<Affiliate_Meta_Delete_At_Path_Input>
  _delete_elem?: Maybe<Affiliate_Meta_Delete_Elem_Input>
  _delete_key?: Maybe<Affiliate_Meta_Delete_Key_Input>
  _prepend?: Maybe<Affiliate_Meta_Prepend_Input>
  _set?: Maybe<Affiliate_Meta_Set_Input>
  pk_columns: Affiliate_Meta_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Meta_ManyArgs = {
  updates: Array<Affiliate_Meta_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_TransactionArgs = {
  _append?: Maybe<Affiliate_Transaction_Append_Input>
  _delete_at_path?: Maybe<Affiliate_Transaction_Delete_At_Path_Input>
  _delete_elem?: Maybe<Affiliate_Transaction_Delete_Elem_Input>
  _delete_key?: Maybe<Affiliate_Transaction_Delete_Key_Input>
  _inc?: Maybe<Affiliate_Transaction_Inc_Input>
  _prepend?: Maybe<Affiliate_Transaction_Prepend_Input>
  _set?: Maybe<Affiliate_Transaction_Set_Input>
  where: Affiliate_Transaction_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Transaction_By_PkArgs = {
  _append?: Maybe<Affiliate_Transaction_Append_Input>
  _delete_at_path?: Maybe<Affiliate_Transaction_Delete_At_Path_Input>
  _delete_elem?: Maybe<Affiliate_Transaction_Delete_Elem_Input>
  _delete_key?: Maybe<Affiliate_Transaction_Delete_Key_Input>
  _inc?: Maybe<Affiliate_Transaction_Inc_Input>
  _prepend?: Maybe<Affiliate_Transaction_Prepend_Input>
  _set?: Maybe<Affiliate_Transaction_Set_Input>
  pk_columns: Affiliate_Transaction_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Transaction_ManyArgs = {
  updates: Array<Affiliate_Transaction_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Billing_ModeArgs = {
  _set?: Maybe<Billing_Mode_Set_Input>
  where: Billing_Mode_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Mode_By_PkArgs = {
  _set?: Maybe<Billing_Mode_Set_Input>
  pk_columns: Billing_Mode_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Mode_ManyArgs = {
  updates: Array<Billing_Mode_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Connected_AccountArgs = {
  _set?: Maybe<Billing_Platform_Connected_Account_Set_Input>
  where: Billing_Platform_Connected_Account_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Connected_Account_By_PkArgs = {
  _set?: Maybe<Billing_Platform_Connected_Account_Set_Input>
  pk_columns: Billing_Platform_Connected_Account_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Connected_Account_ManyArgs = {
  updates: Array<Billing_Platform_Connected_Account_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Connected_Account_StatusArgs =
  {
    _set?: Maybe<Billing_Platform_Connected_Account_Status_Set_Input>
    where: Billing_Platform_Connected_Account_Status_Bool_Exp
  }

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Connected_Account_Status_By_PkArgs =
  {
    _set?: Maybe<Billing_Platform_Connected_Account_Status_Set_Input>
    pk_columns: Billing_Platform_Connected_Account_Status_Pk_Columns_Input
  }

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Connected_Account_Status_ManyArgs =
  {
    updates: Array<Billing_Platform_Connected_Account_Status_Updates>
  }

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_CustomerArgs = {
  _append?: Maybe<Billing_Platform_Customer_Append_Input>
  _delete_at_path?: Maybe<Billing_Platform_Customer_Delete_At_Path_Input>
  _delete_elem?: Maybe<Billing_Platform_Customer_Delete_Elem_Input>
  _delete_key?: Maybe<Billing_Platform_Customer_Delete_Key_Input>
  _prepend?: Maybe<Billing_Platform_Customer_Prepend_Input>
  _set?: Maybe<Billing_Platform_Customer_Set_Input>
  where: Billing_Platform_Customer_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Customer_By_PkArgs = {
  _append?: Maybe<Billing_Platform_Customer_Append_Input>
  _delete_at_path?: Maybe<Billing_Platform_Customer_Delete_At_Path_Input>
  _delete_elem?: Maybe<Billing_Platform_Customer_Delete_Elem_Input>
  _delete_key?: Maybe<Billing_Platform_Customer_Delete_Key_Input>
  _prepend?: Maybe<Billing_Platform_Customer_Prepend_Input>
  _set?: Maybe<Billing_Platform_Customer_Set_Input>
  pk_columns: Billing_Platform_Customer_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Customer_ManyArgs = {
  updates: Array<Billing_Platform_Customer_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CartArgs = {
  _set?: Maybe<Cart_Set_Input>
  where: Cart_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cart_By_PkArgs = {
  _set?: Maybe<Cart_Set_Input>
  pk_columns: Cart_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cart_ItemArgs = {
  _append?: Maybe<Cart_Item_Append_Input>
  _delete_at_path?: Maybe<Cart_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Cart_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Cart_Item_Delete_Key_Input>
  _inc?: Maybe<Cart_Item_Inc_Input>
  _prepend?: Maybe<Cart_Item_Prepend_Input>
  _set?: Maybe<Cart_Item_Set_Input>
  where: Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_By_PkArgs = {
  _append?: Maybe<Cart_Item_Append_Input>
  _delete_at_path?: Maybe<Cart_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Cart_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Cart_Item_Delete_Key_Input>
  _inc?: Maybe<Cart_Item_Inc_Input>
  _prepend?: Maybe<Cart_Item_Prepend_Input>
  _set?: Maybe<Cart_Item_Set_Input>
  pk_columns: Cart_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_LikeArgs = {
  _inc?: Maybe<Cart_Item_Like_Inc_Input>
  _set?: Maybe<Cart_Item_Like_Set_Input>
  where: Cart_Item_Like_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_Like_By_PkArgs = {
  _inc?: Maybe<Cart_Item_Like_Inc_Input>
  _set?: Maybe<Cart_Item_Like_Set_Input>
  pk_columns: Cart_Item_Like_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_Like_ManyArgs = {
  updates: Array<Cart_Item_Like_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_ManyArgs = {
  updates: Array<Cart_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_NoteArgs = {
  _set?: Maybe<Cart_Item_Note_Set_Input>
  where: Cart_Item_Note_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_Note_By_PkArgs = {
  _set?: Maybe<Cart_Item_Note_Set_Input>
  pk_columns: Cart_Item_Note_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_Note_ManyArgs = {
  updates: Array<Cart_Item_Note_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Cart_ManyArgs = {
  updates: Array<Cart_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _set?: Maybe<Categories_Set_Input>
  where: Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _set?: Maybe<Categories_Set_Input>
  pk_columns: Categories_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Categories_ManyArgs = {
  updates: Array<Categories_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Category_Shop_ItemArgs = {
  _inc?: Maybe<Category_Shop_Item_Inc_Input>
  _set?: Maybe<Category_Shop_Item_Set_Input>
  where: Category_Shop_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Category_Shop_Item_By_PkArgs = {
  _inc?: Maybe<Category_Shop_Item_Inc_Input>
  _set?: Maybe<Category_Shop_Item_Set_Input>
  pk_columns: Category_Shop_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Category_Shop_Item_ManyArgs = {
  updates: Array<Category_Shop_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CheckoutArgs = {
  _append?: Maybe<Checkout_Append_Input>
  _delete_at_path?: Maybe<Checkout_Delete_At_Path_Input>
  _delete_elem?: Maybe<Checkout_Delete_Elem_Input>
  _delete_key?: Maybe<Checkout_Delete_Key_Input>
  _inc?: Maybe<Checkout_Inc_Input>
  _prepend?: Maybe<Checkout_Prepend_Input>
  _set?: Maybe<Checkout_Set_Input>
  where: Checkout_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Checkout_By_PkArgs = {
  _append?: Maybe<Checkout_Append_Input>
  _delete_at_path?: Maybe<Checkout_Delete_At_Path_Input>
  _delete_elem?: Maybe<Checkout_Delete_Elem_Input>
  _delete_key?: Maybe<Checkout_Delete_Key_Input>
  _inc?: Maybe<Checkout_Inc_Input>
  _prepend?: Maybe<Checkout_Prepend_Input>
  _set?: Maybe<Checkout_Set_Input>
  pk_columns: Checkout_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Checkout_ManyArgs = {
  updates: Array<Checkout_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CollectionArgs = {
  _append?: Maybe<Collection_Append_Input>
  _delete_at_path?: Maybe<Collection_Delete_At_Path_Input>
  _delete_elem?: Maybe<Collection_Delete_Elem_Input>
  _delete_key?: Maybe<Collection_Delete_Key_Input>
  _inc?: Maybe<Collection_Inc_Input>
  _prepend?: Maybe<Collection_Prepend_Input>
  _set?: Maybe<Collection_Set_Input>
  where: Collection_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_By_PkArgs = {
  _append?: Maybe<Collection_Append_Input>
  _delete_at_path?: Maybe<Collection_Delete_At_Path_Input>
  _delete_elem?: Maybe<Collection_Delete_Elem_Input>
  _delete_key?: Maybe<Collection_Delete_Key_Input>
  _inc?: Maybe<Collection_Inc_Input>
  _prepend?: Maybe<Collection_Prepend_Input>
  _set?: Maybe<Collection_Set_Input>
  pk_columns: Collection_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Cart_ItemArgs = {
  _append?: Maybe<Collection_Cart_Item_Append_Input>
  _delete_at_path?: Maybe<Collection_Cart_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Collection_Cart_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Collection_Cart_Item_Delete_Key_Input>
  _inc?: Maybe<Collection_Cart_Item_Inc_Input>
  _prepend?: Maybe<Collection_Cart_Item_Prepend_Input>
  _set?: Maybe<Collection_Cart_Item_Set_Input>
  where: Collection_Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Cart_Item_By_PkArgs = {
  _append?: Maybe<Collection_Cart_Item_Append_Input>
  _delete_at_path?: Maybe<Collection_Cart_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Collection_Cart_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Collection_Cart_Item_Delete_Key_Input>
  _inc?: Maybe<Collection_Cart_Item_Inc_Input>
  _prepend?: Maybe<Collection_Cart_Item_Prepend_Input>
  _set?: Maybe<Collection_Cart_Item_Set_Input>
  pk_columns: Collection_Cart_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Cart_Item_ManyArgs = {
  updates: Array<Collection_Cart_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_CollaboratorArgs = {
  _set?: Maybe<Collection_Collaborator_Set_Input>
  where: Collection_Collaborator_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Collaborator_By_PkArgs = {
  _set?: Maybe<Collection_Collaborator_Set_Input>
  pk_columns: Collection_Collaborator_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Collaborator_ManyArgs = {
  updates: Array<Collection_Collaborator_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_InviteArgs = {
  _set?: Maybe<Collection_Invite_Set_Input>
  where: Collection_Invite_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Invite_By_PkArgs = {
  _set?: Maybe<Collection_Invite_Set_Input>
  pk_columns: Collection_Invite_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Invite_ManyArgs = {
  updates: Array<Collection_Invite_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Invite_StatusArgs = {
  _set?: Maybe<Collection_Invite_Status_Set_Input>
  where: Collection_Invite_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Invite_Status_By_PkArgs = {
  _set?: Maybe<Collection_Invite_Status_Set_Input>
  pk_columns: Collection_Invite_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Invite_Status_ManyArgs = {
  updates: Array<Collection_Invite_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Item_ClaimArgs = {
  _set?: Maybe<Collection_Item_Claim_Set_Input>
  where: Collection_Item_Claim_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Item_Claim_By_PkArgs = {
  _set?: Maybe<Collection_Item_Claim_Set_Input>
  pk_columns: Collection_Item_Claim_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Item_Claim_ManyArgs = {
  updates: Array<Collection_Item_Claim_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Item_Claim_StatusArgs = {
  _set?: Maybe<Collection_Item_Claim_Status_Set_Input>
  where: Collection_Item_Claim_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Item_Claim_Status_By_PkArgs = {
  _set?: Maybe<Collection_Item_Claim_Status_Set_Input>
  pk_columns: Collection_Item_Claim_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Item_Claim_Status_ManyArgs = {
  updates: Array<Collection_Item_Claim_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_ManyArgs = {
  updates: Array<Collection_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_SectionArgs = {
  _inc?: Maybe<Collection_Section_Inc_Input>
  _set?: Maybe<Collection_Section_Set_Input>
  where: Collection_Section_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Section_By_PkArgs = {
  _inc?: Maybe<Collection_Section_Inc_Input>
  _set?: Maybe<Collection_Section_Set_Input>
  pk_columns: Collection_Section_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Section_Cart_ItemArgs = {
  _inc?: Maybe<Collection_Section_Cart_Item_Inc_Input>
  _set?: Maybe<Collection_Section_Cart_Item_Set_Input>
  where: Collection_Section_Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Section_Cart_Item_By_PkArgs = {
  _inc?: Maybe<Collection_Section_Cart_Item_Inc_Input>
  _set?: Maybe<Collection_Section_Cart_Item_Set_Input>
  pk_columns: Collection_Section_Cart_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Section_Cart_Item_ManyArgs = {
  updates: Array<Collection_Section_Cart_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Section_ManyArgs = {
  updates: Array<Collection_Section_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Slug_HistoryArgs = {
  _set?: Maybe<Collection_Slug_History_Set_Input>
  where: Collection_Slug_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Slug_History_By_PkArgs = {
  _set?: Maybe<Collection_Slug_History_Set_Input>
  pk_columns: Collection_Slug_History_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Slug_History_ManyArgs = {
  updates: Array<Collection_Slug_History_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_StateArgs = {
  _set?: Maybe<Collection_State_Set_Input>
  where: Collection_State_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_State_By_PkArgs = {
  _set?: Maybe<Collection_State_Set_Input>
  pk_columns: Collection_State_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_State_ManyArgs = {
  updates: Array<Collection_State_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_TypeArgs = {
  _set?: Maybe<Collection_Type_Set_Input>
  where: Collection_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Type_By_PkArgs = {
  _set?: Maybe<Collection_Type_Set_Input>
  pk_columns: Collection_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Type_ManyArgs = {
  updates: Array<Collection_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CommentArgs = {
  _inc?: Maybe<Comment_Inc_Input>
  _set?: Maybe<Comment_Set_Input>
  where: Comment_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Comment_By_PkArgs = {
  _inc?: Maybe<Comment_Inc_Input>
  _set?: Maybe<Comment_Set_Input>
  pk_columns: Comment_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Comment_ManyArgs = {
  updates: Array<Comment_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Commentable_ItemArgs = {
  _set?: Maybe<Commentable_Item_Set_Input>
  where: Commentable_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Commentable_Item_By_PkArgs = {
  _set?: Maybe<Commentable_Item_Set_Input>
  pk_columns: Commentable_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Commentable_Item_ManyArgs = {
  updates: Array<Commentable_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Commentable_Item_TypeArgs = {
  _set?: Maybe<Commentable_Item_Type_Set_Input>
  where: Commentable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Commentable_Item_Type_By_PkArgs = {
  _set?: Maybe<Commentable_Item_Type_Set_Input>
  pk_columns: Commentable_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Commentable_Item_Type_ManyArgs = {
  updates: Array<Commentable_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Communication_TemplateArgs = {
  _set?: Maybe<Communication_Template_Set_Input>
  where: Communication_Template_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Template_By_PkArgs = {
  _set?: Maybe<Communication_Template_Set_Input>
  pk_columns: Communication_Template_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Template_Full_ListArgs = {
  _set?: Maybe<Communication_Template_Full_List_Set_Input>
  where: Communication_Template_Full_List_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Template_Full_List_By_PkArgs = {
  _set?: Maybe<Communication_Template_Full_List_Set_Input>
  pk_columns: Communication_Template_Full_List_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Template_Full_List_ManyArgs = {
  updates: Array<Communication_Template_Full_List_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Template_ManyArgs = {
  updates: Array<Communication_Template_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Info_TypeArgs = {
  _set?: Maybe<Contact_Info_Type_Set_Input>
  where: Contact_Info_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Info_Type_By_PkArgs = {
  _set?: Maybe<Contact_Info_Type_Set_Input>
  pk_columns: Contact_Info_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Info_Type_ManyArgs = {
  updates: Array<Contact_Info_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Contact_InviteArgs = {
  _set?: Maybe<Contact_Invite_Set_Input>
  where: Contact_Invite_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_By_PkArgs = {
  _set?: Maybe<Contact_Invite_Set_Input>
  pk_columns: Contact_Invite_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_ManyArgs = {
  updates: Array<Contact_Invite_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_StatusArgs = {
  _set?: Maybe<Contact_Invite_Status_Set_Input>
  where: Contact_Invite_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_Status_By_PkArgs = {
  _set?: Maybe<Contact_Invite_Status_Set_Input>
  pk_columns: Contact_Invite_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_Status_ManyArgs = {
  updates: Array<Contact_Invite_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_TypeArgs = {
  _set?: Maybe<Contact_Invite_Type_Set_Input>
  where: Contact_Invite_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_Type_By_PkArgs = {
  _set?: Maybe<Contact_Invite_Type_Set_Input>
  pk_columns: Contact_Invite_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_Type_ManyArgs = {
  updates: Array<Contact_Invite_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Deal_Hop_GateArgs = {
  _append?: Maybe<Deal_Hop_Gate_Append_Input>
  _delete_at_path?: Maybe<Deal_Hop_Gate_Delete_At_Path_Input>
  _delete_elem?: Maybe<Deal_Hop_Gate_Delete_Elem_Input>
  _delete_key?: Maybe<Deal_Hop_Gate_Delete_Key_Input>
  _prepend?: Maybe<Deal_Hop_Gate_Prepend_Input>
  _set?: Maybe<Deal_Hop_Gate_Set_Input>
  where: Deal_Hop_Gate_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Deal_Hop_Gate_By_PkArgs = {
  _append?: Maybe<Deal_Hop_Gate_Append_Input>
  _delete_at_path?: Maybe<Deal_Hop_Gate_Delete_At_Path_Input>
  _delete_elem?: Maybe<Deal_Hop_Gate_Delete_Elem_Input>
  _delete_key?: Maybe<Deal_Hop_Gate_Delete_Key_Input>
  _prepend?: Maybe<Deal_Hop_Gate_Prepend_Input>
  _set?: Maybe<Deal_Hop_Gate_Set_Input>
  pk_columns: Deal_Hop_Gate_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Deal_Hop_Gate_ManyArgs = {
  updates: Array<Deal_Hop_Gate_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Device_Client_DataArgs = {
  _set?: Maybe<Device_Client_Data_Set_Input>
  where: Device_Client_Data_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Device_Client_Data_By_PkArgs = {
  _set?: Maybe<Device_Client_Data_Set_Input>
  pk_columns: Device_Client_Data_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Device_Client_Data_ManyArgs = {
  updates: Array<Device_Client_Data_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_BacklogArgs = {
  _inc?: Maybe<Discovery_Backlog_Inc_Input>
  _set?: Maybe<Discovery_Backlog_Set_Input>
  where: Discovery_Backlog_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_Backlog_By_PkArgs = {
  _inc?: Maybe<Discovery_Backlog_Inc_Input>
  _set?: Maybe<Discovery_Backlog_Set_Input>
  pk_columns: Discovery_Backlog_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_Backlog_ManyArgs = {
  updates: Array<Discovery_Backlog_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_FeedArgs = {
  _inc?: Maybe<Discovery_Feed_Inc_Input>
  _set?: Maybe<Discovery_Feed_Set_Input>
  where: Discovery_Feed_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_Feed_By_PkArgs = {
  _inc?: Maybe<Discovery_Feed_Inc_Input>
  _set?: Maybe<Discovery_Feed_Set_Input>
  pk_columns: Discovery_Feed_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_Feed_ManyArgs = {
  updates: Array<Discovery_Feed_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_StagingArgs = {
  _inc?: Maybe<Discovery_Staging_Inc_Input>
  _set?: Maybe<Discovery_Staging_Set_Input>
  where: Discovery_Staging_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_Staging_By_PkArgs = {
  _inc?: Maybe<Discovery_Staging_Inc_Input>
  _set?: Maybe<Discovery_Staging_Set_Input>
  pk_columns: Discovery_Staging_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_Staging_ManyArgs = {
  updates: Array<Discovery_Staging_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Featured_ShopArgs = {
  _set?: Maybe<Featured_Shop_Set_Input>
  where: Featured_Shop_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Featured_Shop_By_PkArgs = {
  _set?: Maybe<Featured_Shop_Set_Input>
  pk_columns: Featured_Shop_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Featured_Shop_ItemArgs = {
  _set?: Maybe<Featured_Shop_Item_Set_Input>
  where: Featured_Shop_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Featured_Shop_Item_By_PkArgs = {
  _set?: Maybe<Featured_Shop_Item_Set_Input>
  pk_columns: Featured_Shop_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Featured_Shop_Item_ManyArgs = {
  updates: Array<Featured_Shop_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Featured_Shop_ManyArgs = {
  updates: Array<Featured_Shop_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Feed_ItemArgs = {
  _append?: Maybe<Feed_Item_Append_Input>
  _delete_at_path?: Maybe<Feed_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Feed_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Feed_Item_Delete_Key_Input>
  _prepend?: Maybe<Feed_Item_Prepend_Input>
  _set?: Maybe<Feed_Item_Set_Input>
  where: Feed_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_By_PkArgs = {
  _append?: Maybe<Feed_Item_Append_Input>
  _delete_at_path?: Maybe<Feed_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Feed_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Feed_Item_Delete_Key_Input>
  _prepend?: Maybe<Feed_Item_Prepend_Input>
  _set?: Maybe<Feed_Item_Set_Input>
  pk_columns: Feed_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_LikeArgs = {
  _inc?: Maybe<Feed_Item_Like_Inc_Input>
  _set?: Maybe<Feed_Item_Like_Set_Input>
  where: Feed_Item_Like_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_Like_By_PkArgs = {
  _inc?: Maybe<Feed_Item_Like_Inc_Input>
  _set?: Maybe<Feed_Item_Like_Set_Input>
  pk_columns: Feed_Item_Like_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_Like_ManyArgs = {
  updates: Array<Feed_Item_Like_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_ManyArgs = {
  updates: Array<Feed_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_TypeArgs = {
  _set?: Maybe<Feed_Item_Type_Set_Input>
  where: Feed_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_Type_By_PkArgs = {
  _set?: Maybe<Feed_Item_Type_Set_Input>
  pk_columns: Feed_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_Type_ManyArgs = {
  updates: Array<Feed_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Fmtc_DealArgs = {
  _append?: Maybe<Fmtc_Deal_Append_Input>
  _delete_at_path?: Maybe<Fmtc_Deal_Delete_At_Path_Input>
  _delete_elem?: Maybe<Fmtc_Deal_Delete_Elem_Input>
  _delete_key?: Maybe<Fmtc_Deal_Delete_Key_Input>
  _inc?: Maybe<Fmtc_Deal_Inc_Input>
  _prepend?: Maybe<Fmtc_Deal_Prepend_Input>
  _set?: Maybe<Fmtc_Deal_Set_Input>
  where: Fmtc_Deal_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Fmtc_Deal_By_PkArgs = {
  _append?: Maybe<Fmtc_Deal_Append_Input>
  _delete_at_path?: Maybe<Fmtc_Deal_Delete_At_Path_Input>
  _delete_elem?: Maybe<Fmtc_Deal_Delete_Elem_Input>
  _delete_key?: Maybe<Fmtc_Deal_Delete_Key_Input>
  _inc?: Maybe<Fmtc_Deal_Inc_Input>
  _prepend?: Maybe<Fmtc_Deal_Prepend_Input>
  _set?: Maybe<Fmtc_Deal_Set_Input>
  pk_columns: Fmtc_Deal_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Fmtc_Deal_ManyArgs = {
  updates: Array<Fmtc_Deal_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Followable_ItemArgs = {
  _set?: Maybe<Followable_Item_Set_Input>
  where: Followable_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Followable_Item_By_PkArgs = {
  _set?: Maybe<Followable_Item_Set_Input>
  pk_columns: Followable_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Followable_Item_ManyArgs = {
  updates: Array<Followable_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Followable_Item_TypeArgs = {
  _set?: Maybe<Followable_Item_Type_Set_Input>
  where: Followable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Followable_Item_Type_By_PkArgs = {
  _set?: Maybe<Followable_Item_Type_Set_Input>
  pk_columns: Followable_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Followable_Item_Type_ManyArgs = {
  updates: Array<Followable_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Last_Seen_Notification_Center_Item_Sequential_IdArgs =
  {
    args?: Maybe<Update_Last_Seen_Notification_Center_Item_Sequential_Id_Args>
    distinct_on?: Maybe<Array<User_Flags_Select_Column>>
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<User_Flags_Order_By>>
    where?: Maybe<User_Flags_Bool_Exp>
  }

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_EventArgs = {
  _inc?: Maybe<Leaderboard_Event_Inc_Input>
  _set?: Maybe<Leaderboard_Event_Set_Input>
  where: Leaderboard_Event_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_Event_By_PkArgs = {
  _inc?: Maybe<Leaderboard_Event_Inc_Input>
  _set?: Maybe<Leaderboard_Event_Set_Input>
  pk_columns: Leaderboard_Event_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_Event_ManyArgs = {
  updates: Array<Leaderboard_Event_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_Event_NameArgs = {
  _set?: Maybe<Leaderboard_Event_Name_Set_Input>
  where: Leaderboard_Event_Name_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_Event_Name_By_PkArgs = {
  _set?: Maybe<Leaderboard_Event_Name_Set_Input>
  pk_columns: Leaderboard_Event_Name_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_Event_Name_ManyArgs = {
  updates: Array<Leaderboard_Event_Name_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Link_TypeArgs = {
  _set?: Maybe<Link_Type_Set_Input>
  where: Link_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Link_Type_By_PkArgs = {
  _set?: Maybe<Link_Type_Set_Input>
  pk_columns: Link_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Link_Type_ManyArgs = {
  updates: Array<Link_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Media_Enabled_ItemArgs = {
  _set?: Maybe<Media_Enabled_Item_Set_Input>
  where: Media_Enabled_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Media_Enabled_Item_By_PkArgs = {
  _set?: Maybe<Media_Enabled_Item_Set_Input>
  pk_columns: Media_Enabled_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Media_Enabled_Item_ManyArgs = {
  updates: Array<Media_Enabled_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Media_ItemArgs = {
  _set?: Maybe<Media_Item_Set_Input>
  where: Media_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Media_Item_By_PkArgs = {
  _set?: Maybe<Media_Item_Set_Input>
  pk_columns: Media_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Media_Item_ManyArgs = {
  updates: Array<Media_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Media_Item_TypeArgs = {
  _set?: Maybe<Media_Item_Type_Set_Input>
  where: Media_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Media_Item_Type_By_PkArgs = {
  _set?: Maybe<Media_Item_Type_Set_Input>
  pk_columns: Media_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Media_Item_Type_ManyArgs = {
  updates: Array<Media_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Media_ItemsArgs = {
  _set?: Maybe<Media_Items_Set_Input>
  where: Media_Items_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Media_Items_By_PkArgs = {
  _set?: Maybe<Media_Items_Set_Input>
  pk_columns: Media_Items_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Media_Items_ManyArgs = {
  updates: Array<Media_Items_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Media_TypeArgs = {
  _set?: Maybe<Media_Type_Set_Input>
  where: Media_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Media_Type_By_PkArgs = {
  _set?: Maybe<Media_Type_Set_Input>
  pk_columns: Media_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Media_Type_ManyArgs = {
  updates: Array<Media_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_App_HomeArgs = {
  _inc?: Maybe<Mobile_App_Home_Inc_Input>
  _set?: Maybe<Mobile_App_Home_Set_Input>
  where: Mobile_App_Home_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_App_Home_By_PkArgs = {
  _inc?: Maybe<Mobile_App_Home_Inc_Input>
  _set?: Maybe<Mobile_App_Home_Set_Input>
  pk_columns: Mobile_App_Home_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_App_Home_ManyArgs = {
  updates: Array<Mobile_App_Home_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_HomeArgs = {
  _inc?: Maybe<Mobile_Home_Inc_Input>
  _set?: Maybe<Mobile_Home_Set_Input>
  where: Mobile_Home_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Home_By_PkArgs = {
  _inc?: Maybe<Mobile_Home_Inc_Input>
  _set?: Maybe<Mobile_Home_Set_Input>
  pk_columns: Mobile_Home_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Home_ManyArgs = {
  updates: Array<Mobile_Home_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Push_NotificationArgs = {
  _append?: Maybe<Mobile_Push_Notification_Append_Input>
  _delete_at_path?: Maybe<Mobile_Push_Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<Mobile_Push_Notification_Delete_Elem_Input>
  _delete_key?: Maybe<Mobile_Push_Notification_Delete_Key_Input>
  _inc?: Maybe<Mobile_Push_Notification_Inc_Input>
  _prepend?: Maybe<Mobile_Push_Notification_Prepend_Input>
  _set?: Maybe<Mobile_Push_Notification_Set_Input>
  where: Mobile_Push_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Push_Notification_By_PkArgs = {
  _append?: Maybe<Mobile_Push_Notification_Append_Input>
  _delete_at_path?: Maybe<Mobile_Push_Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<Mobile_Push_Notification_Delete_Elem_Input>
  _delete_key?: Maybe<Mobile_Push_Notification_Delete_Key_Input>
  _inc?: Maybe<Mobile_Push_Notification_Inc_Input>
  _prepend?: Maybe<Mobile_Push_Notification_Prepend_Input>
  _set?: Maybe<Mobile_Push_Notification_Set_Input>
  pk_columns: Mobile_Push_Notification_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Push_Notification_ManyArgs = {
  updates: Array<Mobile_Push_Notification_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Push_Notification_StatusArgs = {
  _set?: Maybe<Mobile_Push_Notification_Status_Set_Input>
  where: Mobile_Push_Notification_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Push_Notification_Status_By_PkArgs = {
  _set?: Maybe<Mobile_Push_Notification_Status_Set_Input>
  pk_columns: Mobile_Push_Notification_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Push_Notification_Status_ManyArgs = {
  updates: Array<Mobile_Push_Notification_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Notifiable_ItemArgs = {
  _set?: Maybe<Notifiable_Item_Set_Input>
  where: Notifiable_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notifiable_Item_By_PkArgs = {
  _set?: Maybe<Notifiable_Item_Set_Input>
  pk_columns: Notifiable_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notifiable_Item_ManyArgs = {
  updates: Array<Notifiable_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Notifiable_Item_TypeArgs = {
  _set?: Maybe<Notifiable_Item_Type_Set_Input>
  where: Notifiable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notifiable_Item_Type_By_PkArgs = {
  _set?: Maybe<Notifiable_Item_Type_Set_Input>
  pk_columns: Notifiable_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notifiable_Item_Type_ManyArgs = {
  updates: Array<Notifiable_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_NotificationArgs = {
  _append?: Maybe<Notification_Append_Input>
  _delete_at_path?: Maybe<Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<Notification_Delete_Elem_Input>
  _delete_key?: Maybe<Notification_Delete_Key_Input>
  _prepend?: Maybe<Notification_Prepend_Input>
  _set?: Maybe<Notification_Set_Input>
  where: Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notification_By_PkArgs = {
  _append?: Maybe<Notification_Append_Input>
  _delete_at_path?: Maybe<Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<Notification_Delete_Elem_Input>
  _delete_key?: Maybe<Notification_Delete_Key_Input>
  _prepend?: Maybe<Notification_Prepend_Input>
  _set?: Maybe<Notification_Set_Input>
  pk_columns: Notification_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_ItemArgs = {
  _inc?: Maybe<Notification_Center_Item_Inc_Input>
  _set?: Maybe<Notification_Center_Item_Set_Input>
  where: Notification_Center_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_By_PkArgs = {
  _inc?: Maybe<Notification_Center_Item_Inc_Input>
  _set?: Maybe<Notification_Center_Item_Set_Input>
  pk_columns: Notification_Center_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_ManyArgs = {
  updates: Array<Notification_Center_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_Read_StatusArgs = {
  _set?: Maybe<Notification_Center_Item_Read_Status_Set_Input>
  where: Notification_Center_Item_Read_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_Read_Status_By_PkArgs =
  {
    _set?: Maybe<Notification_Center_Item_Read_Status_Set_Input>
    pk_columns: Notification_Center_Item_Read_Status_Pk_Columns_Input
  }

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_Read_Status_ManyArgs =
  {
    updates: Array<Notification_Center_Item_Read_Status_Updates>
  }

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_TypeArgs = {
  _set?: Maybe<Notification_Center_Item_Type_Set_Input>
  where: Notification_Center_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_Type_By_PkArgs = {
  _set?: Maybe<Notification_Center_Item_Type_Set_Input>
  pk_columns: Notification_Center_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_Type_ManyArgs = {
  updates: Array<Notification_Center_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Notification_ManyArgs = {
  updates: Array<Notification_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Notification_PreferenceArgs = {
  _set?: Maybe<Notification_Preference_Set_Input>
  where: Notification_Preference_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Preference_By_PkArgs = {
  _set?: Maybe<Notification_Preference_Set_Input>
  pk_columns: Notification_Preference_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Preference_ManyArgs = {
  updates: Array<Notification_Preference_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Notification_StatusArgs = {
  _set?: Maybe<Notification_Status_Set_Input>
  where: Notification_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Status_By_PkArgs = {
  _set?: Maybe<Notification_Status_Set_Input>
  pk_columns: Notification_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Status_ManyArgs = {
  updates: Array<Notification_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Onboarding_CategoriesArgs = {
  _set?: Maybe<Onboarding_Categories_Set_Input>
  where: Onboarding_Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Categories_By_PkArgs = {
  _set?: Maybe<Onboarding_Categories_Set_Input>
  pk_columns: Onboarding_Categories_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Categories_ManyArgs = {
  updates: Array<Onboarding_Categories_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Onboarding_SourcesArgs = {
  _set?: Maybe<Onboarding_Sources_Set_Input>
  where: Onboarding_Sources_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Sources_By_PkArgs = {
  _set?: Maybe<Onboarding_Sources_Set_Input>
  pk_columns: Onboarding_Sources_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Sources_ManyArgs = {
  updates: Array<Onboarding_Sources_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_One_Off_EmailArgs = {
  _append?: Maybe<One_Off_Email_Append_Input>
  _delete_at_path?: Maybe<One_Off_Email_Delete_At_Path_Input>
  _delete_elem?: Maybe<One_Off_Email_Delete_Elem_Input>
  _delete_key?: Maybe<One_Off_Email_Delete_Key_Input>
  _prepend?: Maybe<One_Off_Email_Prepend_Input>
  _set?: Maybe<One_Off_Email_Set_Input>
  where: One_Off_Email_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_One_Off_Email_By_PkArgs = {
  _append?: Maybe<One_Off_Email_Append_Input>
  _delete_at_path?: Maybe<One_Off_Email_Delete_At_Path_Input>
  _delete_elem?: Maybe<One_Off_Email_Delete_Elem_Input>
  _delete_key?: Maybe<One_Off_Email_Delete_Key_Input>
  _prepend?: Maybe<One_Off_Email_Prepend_Input>
  _set?: Maybe<One_Off_Email_Set_Input>
  pk_columns: One_Off_Email_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_One_Off_Email_ManyArgs = {
  updates: Array<One_Off_Email_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Pageview_EventArgs = {
  _append?: Maybe<Pageview_Event_Append_Input>
  _delete_at_path?: Maybe<Pageview_Event_Delete_At_Path_Input>
  _delete_elem?: Maybe<Pageview_Event_Delete_Elem_Input>
  _delete_key?: Maybe<Pageview_Event_Delete_Key_Input>
  _prepend?: Maybe<Pageview_Event_Prepend_Input>
  _set?: Maybe<Pageview_Event_Set_Input>
  where: Pageview_Event_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Pageview_Event_By_PkArgs = {
  _append?: Maybe<Pageview_Event_Append_Input>
  _delete_at_path?: Maybe<Pageview_Event_Delete_At_Path_Input>
  _delete_elem?: Maybe<Pageview_Event_Delete_Elem_Input>
  _delete_key?: Maybe<Pageview_Event_Delete_Key_Input>
  _prepend?: Maybe<Pageview_Event_Prepend_Input>
  _set?: Maybe<Pageview_Event_Set_Input>
  pk_columns: Pageview_Event_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Pageview_Event_ManyArgs = {
  updates: Array<Pageview_Event_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Promo_PeriodArgs = {
  _set?: Maybe<Promo_Period_Set_Input>
  where: Promo_Period_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Promo_Period_By_PkArgs = {
  _set?: Maybe<Promo_Period_Set_Input>
  pk_columns: Promo_Period_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Promo_Period_ManyArgs = {
  updates: Array<Promo_Period_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Push_NotificationArgs = {
  _append?: Maybe<Push_Notification_Append_Input>
  _delete_at_path?: Maybe<Push_Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<Push_Notification_Delete_Elem_Input>
  _delete_key?: Maybe<Push_Notification_Delete_Key_Input>
  _prepend?: Maybe<Push_Notification_Prepend_Input>
  _set?: Maybe<Push_Notification_Set_Input>
  where: Push_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Push_Notification_By_PkArgs = {
  _append?: Maybe<Push_Notification_Append_Input>
  _delete_at_path?: Maybe<Push_Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<Push_Notification_Delete_Elem_Input>
  _delete_key?: Maybe<Push_Notification_Delete_Key_Input>
  _prepend?: Maybe<Push_Notification_Prepend_Input>
  _set?: Maybe<Push_Notification_Set_Input>
  pk_columns: Push_Notification_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Push_Notification_ManyArgs = {
  updates: Array<Push_Notification_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Push_Notification_StatusArgs = {
  _set?: Maybe<Push_Notification_Status_Set_Input>
  where: Push_Notification_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Push_Notification_Status_By_PkArgs = {
  _set?: Maybe<Push_Notification_Status_Set_Input>
  pk_columns: Push_Notification_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Push_Notification_Status_ManyArgs = {
  updates: Array<Push_Notification_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Redirect_IdsArgs = {
  _set?: Maybe<Redirect_Ids_Set_Input>
  where: Redirect_Ids_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Redirect_Ids_By_PkArgs = {
  _set?: Maybe<Redirect_Ids_Set_Input>
  pk_columns: Redirect_Ids_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Redirect_Ids_ManyArgs = {
  updates: Array<Redirect_Ids_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Remove_ReasonArgs = {
  _set?: Maybe<Remove_Reason_Set_Input>
  where: Remove_Reason_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Remove_Reason_By_PkArgs = {
  _set?: Maybe<Remove_Reason_Set_Input>
  pk_columns: Remove_Reason_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Remove_Reason_ManyArgs = {
  updates: Array<Remove_Reason_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Rewardable_Action_NamesArgs = {
  _set?: Maybe<Rewardable_Action_Names_Set_Input>
  where: Rewardable_Action_Names_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Rewardable_Action_Names_By_PkArgs = {
  _set?: Maybe<Rewardable_Action_Names_Set_Input>
  pk_columns: Rewardable_Action_Names_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Rewardable_Action_Names_ManyArgs = {
  updates: Array<Rewardable_Action_Names_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Rewardable_Item_TypeArgs = {
  _set?: Maybe<Rewardable_Item_Type_Set_Input>
  where: Rewardable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Rewardable_Item_Type_By_PkArgs = {
  _set?: Maybe<Rewardable_Item_Type_Set_Input>
  pk_columns: Rewardable_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Rewardable_Item_Type_ManyArgs = {
  updates: Array<Rewardable_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Search_QueryArgs = {
  _inc?: Maybe<Search_Query_Inc_Input>
  _set?: Maybe<Search_Query_Set_Input>
  where: Search_Query_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Search_Query_By_PkArgs = {
  _inc?: Maybe<Search_Query_Inc_Input>
  _set?: Maybe<Search_Query_Set_Input>
  pk_columns: Search_Query_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Search_Query_ManyArgs = {
  updates: Array<Search_Query_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Search_Query_ResultArgs = {
  _inc?: Maybe<Search_Query_Result_Inc_Input>
  _set?: Maybe<Search_Query_Result_Set_Input>
  where: Search_Query_Result_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Search_Query_Result_By_PkArgs = {
  _inc?: Maybe<Search_Query_Result_Inc_Input>
  _set?: Maybe<Search_Query_Result_Set_Input>
  pk_columns: Search_Query_Result_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Search_Query_Result_ManyArgs = {
  updates: Array<Search_Query_Result_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_ShopArgs = {
  _append?: Maybe<Shop_Append_Input>
  _delete_at_path?: Maybe<Shop_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Delete_Key_Input>
  _prepend?: Maybe<Shop_Prepend_Input>
  _set?: Maybe<Shop_Set_Input>
  where: Shop_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_AffiliateArgs = {
  _set?: Maybe<Shop_Affiliate_Set_Input>
  where: Shop_Affiliate_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Affiliate_By_PkArgs = {
  _set?: Maybe<Shop_Affiliate_Set_Input>
  pk_columns: Shop_Affiliate_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Affiliate_ManyArgs = {
  updates: Array<Shop_Affiliate_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_By_PkArgs = {
  _append?: Maybe<Shop_Append_Input>
  _delete_at_path?: Maybe<Shop_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Delete_Key_Input>
  _prepend?: Maybe<Shop_Prepend_Input>
  _set?: Maybe<Shop_Set_Input>
  pk_columns: Shop_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_CategorizationArgs = {
  _inc?: Maybe<Shop_Categorization_Inc_Input>
  _set?: Maybe<Shop_Categorization_Set_Input>
  where: Shop_Categorization_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Categorization_By_PkArgs = {
  _inc?: Maybe<Shop_Categorization_Inc_Input>
  _set?: Maybe<Shop_Categorization_Set_Input>
  pk_columns: Shop_Categorization_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Categorization_ManyArgs = {
  updates: Array<Shop_Categorization_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_CategoryArgs = {
  _set?: Maybe<Shop_Category_Set_Input>
  where: Shop_Category_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Category_By_PkArgs = {
  _set?: Maybe<Shop_Category_Set_Input>
  pk_columns: Shop_Category_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Category_ManyArgs = {
  updates: Array<Shop_Category_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_ItemArgs = {
  _append?: Maybe<Shop_Item_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_Delete_Key_Input>
  _inc?: Maybe<Shop_Item_Inc_Input>
  _prepend?: Maybe<Shop_Item_Prepend_Input>
  _set?: Maybe<Shop_Item_Set_Input>
  where: Shop_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_AvailabilityArgs = {
  _set?: Maybe<Shop_Item_Availability_Set_Input>
  where: Shop_Item_Availability_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Availability_By_PkArgs = {
  _set?: Maybe<Shop_Item_Availability_Set_Input>
  pk_columns: Shop_Item_Availability_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Availability_ManyArgs = {
  updates: Array<Shop_Item_Availability_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_By_PkArgs = {
  _append?: Maybe<Shop_Item_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_Delete_Key_Input>
  _inc?: Maybe<Shop_Item_Inc_Input>
  _prepend?: Maybe<Shop_Item_Prepend_Input>
  _set?: Maybe<Shop_Item_Set_Input>
  pk_columns: Shop_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Enrichment_StatusArgs = {
  _set?: Maybe<Shop_Item_Enrichment_Status_Set_Input>
  where: Shop_Item_Enrichment_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Enrichment_Status_By_PkArgs = {
  _set?: Maybe<Shop_Item_Enrichment_Status_Set_Input>
  pk_columns: Shop_Item_Enrichment_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Enrichment_Status_ManyArgs = {
  updates: Array<Shop_Item_Enrichment_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_HistoryArgs = {
  _append?: Maybe<Shop_Item_History_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_History_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_History_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_History_Delete_Key_Input>
  _inc?: Maybe<Shop_Item_History_Inc_Input>
  _prepend?: Maybe<Shop_Item_History_Prepend_Input>
  _set?: Maybe<Shop_Item_History_Set_Input>
  where: Shop_Item_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_History_By_PkArgs = {
  _append?: Maybe<Shop_Item_History_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_History_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_History_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_History_Delete_Key_Input>
  _inc?: Maybe<Shop_Item_History_Inc_Input>
  _prepend?: Maybe<Shop_Item_History_Prepend_Input>
  _set?: Maybe<Shop_Item_History_Set_Input>
  pk_columns: Shop_Item_History_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_History_ManyArgs = {
  updates: Array<Shop_Item_History_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_ImageArgs = {
  _append?: Maybe<Shop_Item_Image_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_Image_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_Image_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_Image_Delete_Key_Input>
  _prepend?: Maybe<Shop_Item_Image_Prepend_Input>
  _set?: Maybe<Shop_Item_Image_Set_Input>
  where: Shop_Item_Image_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Image_By_PkArgs = {
  _append?: Maybe<Shop_Item_Image_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_Image_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_Image_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_Image_Delete_Key_Input>
  _prepend?: Maybe<Shop_Item_Image_Prepend_Input>
  _set?: Maybe<Shop_Item_Image_Set_Input>
  pk_columns: Shop_Item_Image_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Image_ManyArgs = {
  updates: Array<Shop_Item_Image_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_IndexArgs = {
  _set?: Maybe<Shop_Item_Index_Set_Input>
  where: Shop_Item_Index_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Index_By_PkArgs = {
  _set?: Maybe<Shop_Item_Index_Set_Input>
  pk_columns: Shop_Item_Index_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Index_ManyArgs = {
  updates: Array<Shop_Item_Index_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_ManyArgs = {
  updates: Array<Shop_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_To_Be_ScrapedArgs = {
  _append?: Maybe<Shop_Item_To_Be_Scraped_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_To_Be_Scraped_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_To_Be_Scraped_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_To_Be_Scraped_Delete_Key_Input>
  _prepend?: Maybe<Shop_Item_To_Be_Scraped_Prepend_Input>
  _set?: Maybe<Shop_Item_To_Be_Scraped_Set_Input>
  where: Shop_Item_To_Be_Scraped_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_To_Be_Scraped_ManyArgs = {
  updates: Array<Shop_Item_To_Be_Scraped_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_ManyArgs = {
  updates: Array<Shop_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_RatingArgs = {
  _inc?: Maybe<Shop_Rating_Inc_Input>
  _set?: Maybe<Shop_Rating_Set_Input>
  where: Shop_Rating_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Rating_By_PkArgs = {
  _inc?: Maybe<Shop_Rating_Inc_Input>
  _set?: Maybe<Shop_Rating_Set_Input>
  pk_columns: Shop_Rating_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Rating_ManyArgs = {
  updates: Array<Shop_Rating_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Similar_Products_HistoryArgs = {
  _append?: Maybe<Similar_Products_History_Append_Input>
  _delete_at_path?: Maybe<Similar_Products_History_Delete_At_Path_Input>
  _delete_elem?: Maybe<Similar_Products_History_Delete_Elem_Input>
  _delete_key?: Maybe<Similar_Products_History_Delete_Key_Input>
  _prepend?: Maybe<Similar_Products_History_Prepend_Input>
  _set?: Maybe<Similar_Products_History_Set_Input>
  where: Similar_Products_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Similar_Products_History_By_PkArgs = {
  _append?: Maybe<Similar_Products_History_Append_Input>
  _delete_at_path?: Maybe<Similar_Products_History_Delete_At_Path_Input>
  _delete_elem?: Maybe<Similar_Products_History_Delete_Elem_Input>
  _delete_key?: Maybe<Similar_Products_History_Delete_Key_Input>
  _prepend?: Maybe<Similar_Products_History_Prepend_Input>
  _set?: Maybe<Similar_Products_History_Set_Input>
  pk_columns: Similar_Products_History_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Similar_Products_History_ManyArgs = {
  updates: Array<Similar_Products_History_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _append?: Maybe<User_Append_Input>
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Delete_Elem_Input>
  _delete_key?: Maybe<User_Delete_Key_Input>
  _inc?: Maybe<User_Inc_Input>
  _prepend?: Maybe<User_Prepend_Input>
  _set?: Maybe<User_Set_Input>
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Account_TypeArgs = {
  _set?: Maybe<User_Account_Type_Set_Input>
  where: User_Account_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Account_Type_By_PkArgs = {
  _set?: Maybe<User_Account_Type_Set_Input>
  pk_columns: User_Account_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Account_Type_ManyArgs = {
  updates: Array<User_Account_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ActionArgs = {
  _inc?: Maybe<User_Action_Inc_Input>
  _set?: Maybe<User_Action_Set_Input>
  where: User_Action_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Action_By_PkArgs = {
  _inc?: Maybe<User_Action_Inc_Input>
  _set?: Maybe<User_Action_Set_Input>
  pk_columns: User_Action_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Action_ManyArgs = {
  updates: Array<User_Action_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_App_VersionArgs = {
  _append?: Maybe<User_App_Version_Append_Input>
  _delete_at_path?: Maybe<User_App_Version_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_App_Version_Delete_Elem_Input>
  _delete_key?: Maybe<User_App_Version_Delete_Key_Input>
  _inc?: Maybe<User_App_Version_Inc_Input>
  _prepend?: Maybe<User_App_Version_Prepend_Input>
  _set?: Maybe<User_App_Version_Set_Input>
  where: User_App_Version_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_App_Version_By_PkArgs = {
  _append?: Maybe<User_App_Version_Append_Input>
  _delete_at_path?: Maybe<User_App_Version_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_App_Version_Delete_Elem_Input>
  _delete_key?: Maybe<User_App_Version_Delete_Key_Input>
  _inc?: Maybe<User_App_Version_Inc_Input>
  _prepend?: Maybe<User_App_Version_Prepend_Input>
  _set?: Maybe<User_App_Version_Set_Input>
  pk_columns: User_App_Version_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_App_Version_ManyArgs = {
  updates: Array<User_App_Version_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _append?: Maybe<User_Append_Input>
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Delete_Elem_Input>
  _delete_key?: Maybe<User_Delete_Key_Input>
  _inc?: Maybe<User_Inc_Input>
  _prepend?: Maybe<User_Prepend_Input>
  _set?: Maybe<User_Set_Input>
  pk_columns: User_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Carts_OnboardingArgs = {
  _inc?: Maybe<User_Carts_Onboarding_Inc_Input>
  _set?: Maybe<User_Carts_Onboarding_Set_Input>
  where: User_Carts_Onboarding_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Carts_Onboarding_By_PkArgs = {
  _inc?: Maybe<User_Carts_Onboarding_Inc_Input>
  _set?: Maybe<User_Carts_Onboarding_Set_Input>
  pk_columns: User_Carts_Onboarding_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Carts_Onboarding_ManyArgs = {
  updates: Array<User_Carts_Onboarding_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_CategoryArgs = {
  _set?: Maybe<User_Category_Set_Input>
  where: User_Category_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Category_By_PkArgs = {
  _set?: Maybe<User_Category_Set_Input>
  pk_columns: User_Category_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Category_ManyArgs = {
  updates: Array<User_Category_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_DeletionArgs = {
  _append?: Maybe<User_Deletion_Append_Input>
  _delete_at_path?: Maybe<User_Deletion_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Deletion_Delete_Elem_Input>
  _delete_key?: Maybe<User_Deletion_Delete_Key_Input>
  _prepend?: Maybe<User_Deletion_Prepend_Input>
  _set?: Maybe<User_Deletion_Set_Input>
  where: User_Deletion_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Deletion_By_PkArgs = {
  _append?: Maybe<User_Deletion_Append_Input>
  _delete_at_path?: Maybe<User_Deletion_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Deletion_Delete_Elem_Input>
  _delete_key?: Maybe<User_Deletion_Delete_Key_Input>
  _prepend?: Maybe<User_Deletion_Prepend_Input>
  _set?: Maybe<User_Deletion_Set_Input>
  pk_columns: User_Deletion_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Deletion_ManyArgs = {
  updates: Array<User_Deletion_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_FeedbackArgs = {
  _set?: Maybe<User_Feedback_Set_Input>
  where: User_Feedback_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_By_PkArgs = {
  _set?: Maybe<User_Feedback_Set_Input>
  pk_columns: User_Feedback_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_ItemArgs = {
  _set?: Maybe<User_Feedback_Item_Set_Input>
  where: User_Feedback_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_Item_By_PkArgs = {
  _set?: Maybe<User_Feedback_Item_Set_Input>
  pk_columns: User_Feedback_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_Item_ManyArgs = {
  updates: Array<User_Feedback_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_ManyArgs = {
  updates: Array<User_Feedback_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_TypeArgs = {
  _set?: Maybe<User_Feedback_Type_Set_Input>
  where: User_Feedback_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_Type_By_PkArgs = {
  _set?: Maybe<User_Feedback_Type_Set_Input>
  pk_columns: User_Feedback_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_Type_ManyArgs = {
  updates: Array<User_Feedback_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_FlagsArgs = {
  _append?: Maybe<User_Flags_Append_Input>
  _delete_at_path?: Maybe<User_Flags_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Flags_Delete_Elem_Input>
  _delete_key?: Maybe<User_Flags_Delete_Key_Input>
  _inc?: Maybe<User_Flags_Inc_Input>
  _prepend?: Maybe<User_Flags_Prepend_Input>
  _set?: Maybe<User_Flags_Set_Input>
  where: User_Flags_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Flags_By_PkArgs = {
  _append?: Maybe<User_Flags_Append_Input>
  _delete_at_path?: Maybe<User_Flags_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Flags_Delete_Elem_Input>
  _delete_key?: Maybe<User_Flags_Delete_Key_Input>
  _inc?: Maybe<User_Flags_Inc_Input>
  _prepend?: Maybe<User_Flags_Prepend_Input>
  _set?: Maybe<User_Flags_Set_Input>
  pk_columns: User_Flags_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Flags_ManyArgs = {
  updates: Array<User_Flags_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ImpersonationArgs = {
  _set?: Maybe<User_Impersonation_Set_Input>
  where: User_Impersonation_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Impersonation_By_PkArgs = {
  _set?: Maybe<User_Impersonation_Set_Input>
  pk_columns: User_Impersonation_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Impersonation_ManyArgs = {
  updates: Array<User_Impersonation_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Ip_AddressArgs = {
  _set?: Maybe<User_Ip_Address_Set_Input>
  where: User_Ip_Address_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Ip_Address_By_PkArgs = {
  _set?: Maybe<User_Ip_Address_Set_Input>
  pk_columns: User_Ip_Address_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Ip_Address_ManyArgs = {
  updates: Array<User_Ip_Address_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Items_FollowedArgs = {
  _set?: Maybe<User_Items_Followed_Set_Input>
  where: User_Items_Followed_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Items_Followed_By_PkArgs = {
  _set?: Maybe<User_Items_Followed_Set_Input>
  pk_columns: User_Items_Followed_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Items_Followed_ManyArgs = {
  updates: Array<User_Items_Followed_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_LinksArgs = {
  _set?: Maybe<User_Links_Set_Input>
  where: User_Links_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Links_By_PkArgs = {
  _set?: Maybe<User_Links_Set_Input>
  pk_columns: User_Links_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Links_ManyArgs = {
  updates: Array<User_Links_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_SourceArgs = {
  _set?: Maybe<User_Onboarding_Source_Set_Input>
  where: User_Onboarding_Source_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_Source_By_PkArgs = {
  _set?: Maybe<User_Onboarding_Source_Set_Input>
  pk_columns: User_Onboarding_Source_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_Source_ManyArgs = {
  updates: Array<User_Onboarding_Source_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_StateArgs = {
  _set?: Maybe<User_Onboarding_State_Set_Input>
  where: User_Onboarding_State_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_State_By_PkArgs = {
  _set?: Maybe<User_Onboarding_State_Set_Input>
  pk_columns: User_Onboarding_State_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_State_ManyArgs = {
  updates: Array<User_Onboarding_State_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Phone_ContactArgs = {
  _set?: Maybe<User_Phone_Contact_Set_Input>
  where: User_Phone_Contact_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Phone_Contact_By_PkArgs = {
  _set?: Maybe<User_Phone_Contact_Set_Input>
  pk_columns: User_Phone_Contact_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Phone_Contact_ManyArgs = {
  updates: Array<User_Phone_Contact_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Purchase_SubscriptionArgs = {
  _set?: Maybe<User_Purchase_Subscription_Set_Input>
  where: User_Purchase_Subscription_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Purchase_Subscription_By_PkArgs = {
  _set?: Maybe<User_Purchase_Subscription_Set_Input>
  pk_columns: User_Purchase_Subscription_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Purchase_Subscription_ManyArgs = {
  updates: Array<User_Purchase_Subscription_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_PurchasesArgs = {
  _set?: Maybe<User_Purchases_Set_Input>
  where: User_Purchases_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Purchases_By_PkArgs = {
  _set?: Maybe<User_Purchases_Set_Input>
  pk_columns: User_Purchases_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Purchases_ManyArgs = {
  updates: Array<User_Purchases_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Push_NotificationArgs = {
  _append?: Maybe<User_Push_Notification_Append_Input>
  _delete_at_path?: Maybe<User_Push_Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Push_Notification_Delete_Elem_Input>
  _delete_key?: Maybe<User_Push_Notification_Delete_Key_Input>
  _prepend?: Maybe<User_Push_Notification_Prepend_Input>
  _set?: Maybe<User_Push_Notification_Set_Input>
  where: User_Push_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Push_Notification_ManyArgs = {
  updates: Array<User_Push_Notification_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Push_Notification_TokenArgs = {
  _set?: Maybe<User_Push_Notification_Token_Set_Input>
  where: User_Push_Notification_Token_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Push_Notification_Token_By_PkArgs = {
  _set?: Maybe<User_Push_Notification_Token_Set_Input>
  pk_columns: User_Push_Notification_Token_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Push_Notification_Token_ManyArgs = {
  updates: Array<User_Push_Notification_Token_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ReferralArgs = {
  _inc?: Maybe<User_Referral_Inc_Input>
  _set?: Maybe<User_Referral_Set_Input>
  where: User_Referral_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Referral_By_PkArgs = {
  _inc?: Maybe<User_Referral_Inc_Input>
  _set?: Maybe<User_Referral_Set_Input>
  pk_columns: User_Referral_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Referral_ManyArgs = {
  updates: Array<User_Referral_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Multiplier_ConfigArgs = {
  _inc?: Maybe<User_Reward_Multiplier_Config_Inc_Input>
  _set?: Maybe<User_Reward_Multiplier_Config_Set_Input>
  where: User_Reward_Multiplier_Config_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Multiplier_Config_By_PkArgs = {
  _inc?: Maybe<User_Reward_Multiplier_Config_Inc_Input>
  _set?: Maybe<User_Reward_Multiplier_Config_Set_Input>
  pk_columns: User_Reward_Multiplier_Config_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Multiplier_Config_ManyArgs = {
  updates: Array<User_Reward_Multiplier_Config_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_RedemptionArgs = {
  _inc?: Maybe<User_Reward_Redemption_Inc_Input>
  _set?: Maybe<User_Reward_Redemption_Set_Input>
  where: User_Reward_Redemption_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Redemption_By_PkArgs = {
  _inc?: Maybe<User_Reward_Redemption_Inc_Input>
  _set?: Maybe<User_Reward_Redemption_Set_Input>
  pk_columns: User_Reward_Redemption_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Redemption_ManyArgs = {
  updates: Array<User_Reward_Redemption_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_ReferenceArgs = {
  _append?: Maybe<User_Reward_Reference_Append_Input>
  _delete_at_path?: Maybe<User_Reward_Reference_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Reward_Reference_Delete_Elem_Input>
  _delete_key?: Maybe<User_Reward_Reference_Delete_Key_Input>
  _inc?: Maybe<User_Reward_Reference_Inc_Input>
  _prepend?: Maybe<User_Reward_Reference_Prepend_Input>
  _set?: Maybe<User_Reward_Reference_Set_Input>
  where: User_Reward_Reference_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Reference_By_PkArgs = {
  _append?: Maybe<User_Reward_Reference_Append_Input>
  _delete_at_path?: Maybe<User_Reward_Reference_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Reward_Reference_Delete_Elem_Input>
  _delete_key?: Maybe<User_Reward_Reference_Delete_Key_Input>
  _inc?: Maybe<User_Reward_Reference_Inc_Input>
  _prepend?: Maybe<User_Reward_Reference_Prepend_Input>
  _set?: Maybe<User_Reward_Reference_Set_Input>
  pk_columns: User_Reward_Reference_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Reference_ManyArgs = {
  updates: Array<User_Reward_Reference_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Shop_FavoriteArgs = {
  _set?: Maybe<User_Shop_Favorite_Set_Input>
  where: User_Shop_Favorite_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Shop_Favorite_By_PkArgs = {
  _set?: Maybe<User_Shop_Favorite_Set_Input>
  pk_columns: User_Shop_Favorite_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Shop_Favorite_ManyArgs = {
  updates: Array<User_Shop_Favorite_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Shopping_PreferencesArgs = {
  _set?: Maybe<User_Shopping_Preferences_Set_Input>
  where: User_Shopping_Preferences_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Shopping_Preferences_By_PkArgs = {
  _set?: Maybe<User_Shopping_Preferences_Set_Input>
  pk_columns: User_Shopping_Preferences_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Shopping_Preferences_ManyArgs = {
  updates: Array<User_Shopping_Preferences_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_SubscriptionArgs = {
  _append?: Maybe<User_Subscription_Append_Input>
  _delete_at_path?: Maybe<User_Subscription_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Subscription_Delete_Elem_Input>
  _delete_key?: Maybe<User_Subscription_Delete_Key_Input>
  _prepend?: Maybe<User_Subscription_Prepend_Input>
  _set?: Maybe<User_Subscription_Set_Input>
  where: User_Subscription_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_By_PkArgs = {
  _append?: Maybe<User_Subscription_Append_Input>
  _delete_at_path?: Maybe<User_Subscription_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Subscription_Delete_Elem_Input>
  _delete_key?: Maybe<User_Subscription_Delete_Key_Input>
  _prepend?: Maybe<User_Subscription_Prepend_Input>
  _set?: Maybe<User_Subscription_Set_Input>
  pk_columns: User_Subscription_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_ManyArgs = {
  updates: Array<User_Subscription_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_StatusArgs = {
  _set?: Maybe<User_Subscription_Status_Set_Input>
  where: User_Subscription_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_Status_By_PkArgs = {
  _set?: Maybe<User_Subscription_Status_Set_Input>
  pk_columns: User_Subscription_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_Status_ManyArgs = {
  updates: Array<User_Subscription_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_TempArgs = {
  _set?: Maybe<User_Temp_Set_Input>
  where: User_Temp_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Temp_By_PkArgs = {
  _set?: Maybe<User_Temp_Set_Input>
  pk_columns: User_Temp_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Temp_ManyArgs = {
  updates: Array<User_Temp_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Worflow_TypeArgs = {
  _set?: Maybe<Worflow_Type_Set_Input>
  where: Worflow_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Worflow_Type_By_PkArgs = {
  _set?: Maybe<Worflow_Type_Set_Input>
  pk_columns: Worflow_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Worflow_Type_ManyArgs = {
  updates: Array<Worflow_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_WorkflowArgs = {
  _append?: Maybe<Workflow_Append_Input>
  _delete_at_path?: Maybe<Workflow_Delete_At_Path_Input>
  _delete_elem?: Maybe<Workflow_Delete_Elem_Input>
  _delete_key?: Maybe<Workflow_Delete_Key_Input>
  _prepend?: Maybe<Workflow_Prepend_Input>
  _set?: Maybe<Workflow_Set_Input>
  where: Workflow_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Workflow_By_PkArgs = {
  _append?: Maybe<Workflow_Append_Input>
  _delete_at_path?: Maybe<Workflow_Delete_At_Path_Input>
  _delete_elem?: Maybe<Workflow_Delete_Elem_Input>
  _delete_key?: Maybe<Workflow_Delete_Key_Input>
  _prepend?: Maybe<Workflow_Prepend_Input>
  _set?: Maybe<Workflow_Set_Input>
  pk_columns: Workflow_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Workflow_ManyArgs = {
  updates: Array<Workflow_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Workflow_StatusArgs = {
  _set?: Maybe<Workflow_Status_Set_Input>
  where: Workflow_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Workflow_Status_By_PkArgs = {
  _set?: Maybe<Workflow_Status_Set_Input>
  pk_columns: Workflow_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Workflow_Status_ManyArgs = {
  updates: Array<Workflow_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpsert_Notification_PreferenceArgs = {
  args: Upsert_Notification_Preference_Args
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: Maybe<Scalars['name']>
  _gt?: Maybe<Scalars['name']>
  _gte?: Maybe<Scalars['name']>
  _in?: Maybe<Array<Scalars['name']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['name']>
  _lte?: Maybe<Scalars['name']>
  _neq?: Maybe<Scalars['name']>
  _nin?: Maybe<Array<Scalars['name']>>
}

/** columns and relationships of "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User = {
  __typename?: 'notifiable_feed_item_user'
  id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User_Aggregate = {
  __typename?: 'notifiable_feed_item_user_aggregate'
  aggregate?: Maybe<Notifiable_Feed_Item_User_Aggregate_Fields>
  nodes: Array<Notifiable_Feed_Item_User>
}

/** aggregate fields of "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User_Aggregate_Fields = {
  __typename?: 'notifiable_feed_item_user_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notifiable_Feed_Item_User_Max_Fields>
  min?: Maybe<Notifiable_Feed_Item_User_Min_Fields>
}

/** aggregate fields of "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notifiable_Feed_Item_User_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "notifiable_feed_item_user". All fields are combined with a logical 'AND'. */
export type Notifiable_Feed_Item_User_Bool_Exp = {
  _and?: Maybe<Array<Notifiable_Feed_Item_User_Bool_Exp>>
  _not?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
  _or?: Maybe<Array<Notifiable_Feed_Item_User_Bool_Exp>>
  id?: Maybe<Uuid_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** input type for inserting data into table "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User_Insert_Input = {
  id?: Maybe<Scalars['uuid']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notifiable_Feed_Item_User_Max_Fields = {
  __typename?: 'notifiable_feed_item_user_max_fields'
  id?: Maybe<Scalars['uuid']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Notifiable_Feed_Item_User_Min_Fields = {
  __typename?: 'notifiable_feed_item_user_min_fields'
  id?: Maybe<Scalars['uuid']>
  user_id?: Maybe<Scalars['String']>
}

/** input type for inserting object relation for remote table "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User_Obj_Rel_Insert_Input = {
  data: Notifiable_Feed_Item_User_Insert_Input
}

/** Ordering options when selecting data from "notifiable_feed_item_user". */
export type Notifiable_Feed_Item_User_Order_By = {
  id?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "notifiable_feed_item_user" */
export enum Notifiable_Feed_Item_User_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifiable_Feed_Item_User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notifiable_Feed_Item_User_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>
  user_id?: Maybe<Scalars['String']>
}

/** A table to keep track of all the allowable polymorphic notifiable items */
export type Notifiable_Item = {
  __typename?: 'notifiable_item'
  /** An object relationship */
  cart_item?: Maybe<Cart_Item>
  /** An object relationship */
  collection?: Maybe<Collection>
  /** An object relationship */
  collection_cart_item?: Maybe<Collection_Cart_Item>
  /** An object relationship */
  collection_invite?: Maybe<Collection_Invite>
  /** An object relationship */
  comment?: Maybe<Comment>
  /** An object relationship */
  feed_item_like?: Maybe<Feed_Item_Like>
  /** An object relationship */
  follow?: Maybe<User_Items_Followed>
  item_id: Scalars['uuid']
  item_type: Notifiable_Item_Type_Enum
}

/** aggregated selection of "notifiable_item" */
export type Notifiable_Item_Aggregate = {
  __typename?: 'notifiable_item_aggregate'
  aggregate?: Maybe<Notifiable_Item_Aggregate_Fields>
  nodes: Array<Notifiable_Item>
}

/** aggregate fields of "notifiable_item" */
export type Notifiable_Item_Aggregate_Fields = {
  __typename?: 'notifiable_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notifiable_Item_Max_Fields>
  min?: Maybe<Notifiable_Item_Min_Fields>
}

/** aggregate fields of "notifiable_item" */
export type Notifiable_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notifiable_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "notifiable_item". All fields are combined with a logical 'AND'. */
export type Notifiable_Item_Bool_Exp = {
  _and?: Maybe<Array<Notifiable_Item_Bool_Exp>>
  _not?: Maybe<Notifiable_Item_Bool_Exp>
  _or?: Maybe<Array<Notifiable_Item_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  collection?: Maybe<Collection_Bool_Exp>
  collection_cart_item?: Maybe<Collection_Cart_Item_Bool_Exp>
  collection_invite?: Maybe<Collection_Invite_Bool_Exp>
  comment?: Maybe<Comment_Bool_Exp>
  feed_item_like?: Maybe<Feed_Item_Like_Bool_Exp>
  follow?: Maybe<User_Items_Followed_Bool_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
}

/** unique or primary key constraints on table "notifiable_item" */
export enum Notifiable_Item_Constraint {
  /** unique or primary key constraint on columns "item_id", "item_type" */
  NotifiableItemItemIdItemTypeKey = 'notifiable_item_item_id_item_type_key',
  /** unique or primary key constraint on columns "item_id", "item_type" */
  NotifiableItemPkey = 'notifiable_item_pkey',
}

/** input type for inserting data into table "notifiable_item" */
export type Notifiable_Item_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_cart_item?: Maybe<Collection_Cart_Item_Obj_Rel_Insert_Input>
  collection_invite?: Maybe<Collection_Invite_Obj_Rel_Insert_Input>
  comment?: Maybe<Comment_Obj_Rel_Insert_Input>
  feed_item_like?: Maybe<Feed_Item_Like_Obj_Rel_Insert_Input>
  follow?: Maybe<User_Items_Followed_Obj_Rel_Insert_Input>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Notifiable_Item_Type_Enum>
}

/** aggregate max on columns */
export type Notifiable_Item_Max_Fields = {
  __typename?: 'notifiable_item_max_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type Notifiable_Item_Min_Fields = {
  __typename?: 'notifiable_item_min_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "notifiable_item" */
export type Notifiable_Item_Mutation_Response = {
  __typename?: 'notifiable_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notifiable_Item>
}

/** input type for inserting object relation for remote table "notifiable_item" */
export type Notifiable_Item_Obj_Rel_Insert_Input = {
  data: Notifiable_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Notifiable_Item_On_Conflict>
}

/** on_conflict condition type for table "notifiable_item" */
export type Notifiable_Item_On_Conflict = {
  constraint: Notifiable_Item_Constraint
  update_columns?: Array<Notifiable_Item_Update_Column>
  where?: Maybe<Notifiable_Item_Bool_Exp>
}

/** Ordering options when selecting data from "notifiable_item". */
export type Notifiable_Item_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  collection?: Maybe<Collection_Order_By>
  collection_cart_item?: Maybe<Collection_Cart_Item_Order_By>
  collection_invite?: Maybe<Collection_Invite_Order_By>
  comment?: Maybe<Comment_Order_By>
  feed_item_like?: Maybe<Feed_Item_Like_Order_By>
  follow?: Maybe<User_Items_Followed_Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
}

/** primary key columns input for table: notifiable_item */
export type Notifiable_Item_Pk_Columns_Input = {
  item_id: Scalars['uuid']
  item_type: Notifiable_Item_Type_Enum
}

/** select columns of table "notifiable_item" */
export enum Notifiable_Item_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

/** input type for updating data in table "notifiable_item" */
export type Notifiable_Item_Set_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Notifiable_Item_Type_Enum>
}

/** Streaming cursor of the table "notifiable_item" */
export type Notifiable_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifiable_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notifiable_Item_Stream_Cursor_Value_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Notifiable_Item_Type_Enum>
}

/** An enum table to hold the different notifiable item types */
export type Notifiable_Item_Type = {
  __typename?: 'notifiable_item_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "notifiable_item_type" */
export type Notifiable_Item_Type_Aggregate = {
  __typename?: 'notifiable_item_type_aggregate'
  aggregate?: Maybe<Notifiable_Item_Type_Aggregate_Fields>
  nodes: Array<Notifiable_Item_Type>
}

/** aggregate fields of "notifiable_item_type" */
export type Notifiable_Item_Type_Aggregate_Fields = {
  __typename?: 'notifiable_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notifiable_Item_Type_Max_Fields>
  min?: Maybe<Notifiable_Item_Type_Min_Fields>
}

/** aggregate fields of "notifiable_item_type" */
export type Notifiable_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notifiable_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "notifiable_item_type". All fields are combined with a logical 'AND'. */
export type Notifiable_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Notifiable_Item_Type_Bool_Exp>>
  _not?: Maybe<Notifiable_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Notifiable_Item_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notifiable_item_type" */
export enum Notifiable_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  NotifiableItemTypePkey = 'notifiable_item_type_pkey',
}

export enum Notifiable_Item_Type_Enum {
  CartItem = 'cart_item',
  Collection = 'collection',
  CollectionCartItem = 'collection_cart_item',
  CollectionInvite = 'collection_invite',
  Comment = 'comment',
  DealHopGate = 'deal_hop_gate',
  FeedItemLike = 'feed_item_like',
  UserItemsFollowed = 'user_items_followed',
}

/** Boolean expression to compare columns of type "notifiable_item_type_enum". All fields are combined with logical 'AND'. */
export type Notifiable_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Notifiable_Item_Type_Enum>
  _in?: Maybe<Array<Notifiable_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Notifiable_Item_Type_Enum>
  _nin?: Maybe<Array<Notifiable_Item_Type_Enum>>
}

/** input type for inserting data into table "notifiable_item_type" */
export type Notifiable_Item_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notifiable_Item_Type_Max_Fields = {
  __typename?: 'notifiable_item_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Notifiable_Item_Type_Min_Fields = {
  __typename?: 'notifiable_item_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "notifiable_item_type" */
export type Notifiable_Item_Type_Mutation_Response = {
  __typename?: 'notifiable_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notifiable_Item_Type>
}

/** on_conflict condition type for table "notifiable_item_type" */
export type Notifiable_Item_Type_On_Conflict = {
  constraint: Notifiable_Item_Type_Constraint
  update_columns?: Array<Notifiable_Item_Type_Update_Column>
  where?: Maybe<Notifiable_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "notifiable_item_type". */
export type Notifiable_Item_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: notifiable_item_type */
export type Notifiable_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "notifiable_item_type" */
export enum Notifiable_Item_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "notifiable_item_type" */
export type Notifiable_Item_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "notifiable_item_type" */
export type Notifiable_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifiable_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notifiable_Item_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "notifiable_item_type" */
export enum Notifiable_Item_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Notifiable_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notifiable_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Notifiable_Item_Type_Bool_Exp
}

/** update columns of table "notifiable_item" */
export enum Notifiable_Item_Update_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

export type Notifiable_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notifiable_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Notifiable_Item_Bool_Exp
}

/** columns and relationships of "notification" */
export type Notification = {
  __typename?: 'notification'
  active: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  criteria: Scalars['jsonb']
  id: Scalars['uuid']
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  /** An array relationship */
  notification_status: Array<Notification_Status>
  /** An aggregate relationship */
  notification_status_aggregate: Notification_Status_Aggregate
  payload: Scalars['jsonb']
  show_after: Scalars['timestamptz']
  show_before: Scalars['timestamptz']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "notification" */
export type NotificationCriteriaArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "notification" */
export type NotificationNotification_StatusArgs = {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Status_Order_By>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

/** columns and relationships of "notification" */
export type NotificationNotification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Status_Order_By>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

/** columns and relationships of "notification" */
export type NotificationPayloadArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "notification" */
export type Notification_Aggregate = {
  __typename?: 'notification_aggregate'
  aggregate?: Maybe<Notification_Aggregate_Fields>
  nodes: Array<Notification>
}

/** aggregate fields of "notification" */
export type Notification_Aggregate_Fields = {
  __typename?: 'notification_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notification_Max_Fields>
  min?: Maybe<Notification_Min_Fields>
}

/** aggregate fields of "notification" */
export type Notification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Notification_Append_Input = {
  criteria?: Maybe<Scalars['jsonb']>
  payload?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type Notification_Bool_Exp = {
  _and?: Maybe<Array<Notification_Bool_Exp>>
  _not?: Maybe<Notification_Bool_Exp>
  _or?: Maybe<Array<Notification_Bool_Exp>>
  active?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  criteria?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_id?: Maybe<String_Comparison_Exp>
  notifiable_type?: Maybe<String_Comparison_Exp>
  notification_status?: Maybe<Notification_Status_Bool_Exp>
  notification_status_aggregate?: Maybe<Notification_Status_Aggregate_Bool_Exp>
  payload?: Maybe<Jsonb_Comparison_Exp>
  show_after?: Maybe<Timestamptz_Comparison_Exp>
  show_before?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

export type Notification_Center_Feed_For_User_Args = {
  read?: Maybe<Scalars['Boolean']>
  tasks?: Maybe<Scalars['Boolean']>
  user_id?: Maybe<Scalars['String']>
}

/** A table to hold distinct items that should show up in the notification center */
export type Notification_Center_Item = {
  __typename?: 'notification_center_item'
  banner?: Maybe<Scalars['Boolean']>
  body?: Maybe<Scalars['String']>
  communication_template?: Maybe<Communication_Template_Enum>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  notifiable_item?: Maybe<Notifiable_Item>
  notifiable_type?: Maybe<Scalars['String']>
  /** Grabs the timestamp for when a notification center item was read for a given user */
  read_at?: Maybe<Scalars['timestamptz']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id: Scalars['Int']
  type: Notification_Center_Item_Type_Enum
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "notification_center_item" */
export type Notification_Center_Item_Aggregate = {
  __typename?: 'notification_center_item_aggregate'
  aggregate?: Maybe<Notification_Center_Item_Aggregate_Fields>
  nodes: Array<Notification_Center_Item>
}

export type Notification_Center_Item_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Notification_Center_Item_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Notification_Center_Item_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Notification_Center_Item_Aggregate_Bool_Exp_Count>
}

export type Notification_Center_Item_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notification_Center_Item_Select_Column_Notification_Center_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Center_Item_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notification_Center_Item_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notification_Center_Item_Select_Column_Notification_Center_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Center_Item_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notification_Center_Item_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Notification_Center_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Center_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "notification_center_item" */
export type Notification_Center_Item_Aggregate_Fields = {
  __typename?: 'notification_center_item_aggregate_fields'
  avg?: Maybe<Notification_Center_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Notification_Center_Item_Max_Fields>
  min?: Maybe<Notification_Center_Item_Min_Fields>
  stddev?: Maybe<Notification_Center_Item_Stddev_Fields>
  stddev_pop?: Maybe<Notification_Center_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Notification_Center_Item_Stddev_Samp_Fields>
  sum?: Maybe<Notification_Center_Item_Sum_Fields>
  var_pop?: Maybe<Notification_Center_Item_Var_Pop_Fields>
  var_samp?: Maybe<Notification_Center_Item_Var_Samp_Fields>
  variance?: Maybe<Notification_Center_Item_Variance_Fields>
}

/** aggregate fields of "notification_center_item" */
export type Notification_Center_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Center_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "notification_center_item" */
export type Notification_Center_Item_Aggregate_Order_By = {
  avg?: Maybe<Notification_Center_Item_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Notification_Center_Item_Max_Order_By>
  min?: Maybe<Notification_Center_Item_Min_Order_By>
  stddev?: Maybe<Notification_Center_Item_Stddev_Order_By>
  stddev_pop?: Maybe<Notification_Center_Item_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Notification_Center_Item_Stddev_Samp_Order_By>
  sum?: Maybe<Notification_Center_Item_Sum_Order_By>
  var_pop?: Maybe<Notification_Center_Item_Var_Pop_Order_By>
  var_samp?: Maybe<Notification_Center_Item_Var_Samp_Order_By>
  variance?: Maybe<Notification_Center_Item_Variance_Order_By>
}

/** input type for inserting array relation for remote table "notification_center_item" */
export type Notification_Center_Item_Arr_Rel_Insert_Input = {
  data: Array<Notification_Center_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Notification_Center_Item_On_Conflict>
}

/** aggregate avg on columns */
export type Notification_Center_Item_Avg_Fields = {
  __typename?: 'notification_center_item_avg_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "notification_center_item" */
export type Notification_Center_Item_Avg_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "notification_center_item". All fields are combined with a logical 'AND'. */
export type Notification_Center_Item_Bool_Exp = {
  _and?: Maybe<Array<Notification_Center_Item_Bool_Exp>>
  _not?: Maybe<Notification_Center_Item_Bool_Exp>
  _or?: Maybe<Array<Notification_Center_Item_Bool_Exp>>
  banner?: Maybe<Boolean_Comparison_Exp>
  body?: Maybe<String_Comparison_Exp>
  communication_template?: Maybe<Communication_Template_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  link?: Maybe<String_Comparison_Exp>
  main_image?: Maybe<String_Comparison_Exp>
  notifiable_id?: Maybe<Uuid_Comparison_Exp>
  notifiable_item?: Maybe<Notifiable_Item_Bool_Exp>
  notifiable_type?: Maybe<String_Comparison_Exp>
  read_at?: Maybe<Timestamptz_Comparison_Exp>
  secondary_image?: Maybe<String_Comparison_Exp>
  sequential_id?: Maybe<Int_Comparison_Exp>
  type?: Maybe<Notification_Center_Item_Type_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notification_center_item" */
export enum Notification_Center_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationCenterItemPkey = 'notification_center_item_pkey',
  /** unique or primary key constraint on columns "sequential_id" */
  NotificationCenterItemSequentialIdKey = 'notification_center_item_sequential_id_key',
  /** unique or primary key constraint on columns "user_id", "communication_template", "notifiable_type", "notifiable_id" */
  NotificationCenterItemUserIdCommunicationTemplateNotIdx = 'notification_center_item_user_id_communication_template_not_idx',
}

/** input type for incrementing numeric columns in table "notification_center_item" */
export type Notification_Center_Item_Inc_Input = {
  sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "notification_center_item" */
export type Notification_Center_Item_Insert_Input = {
  banner?: Maybe<Scalars['Boolean']>
  body?: Maybe<Scalars['String']>
  communication_template?: Maybe<Communication_Template_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_item?: Maybe<Notifiable_Item_Obj_Rel_Insert_Input>
  notifiable_type?: Maybe<Scalars['String']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id?: Maybe<Scalars['Int']>
  type?: Maybe<Notification_Center_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notification_Center_Item_Max_Fields = {
  __typename?: 'notification_center_item_max_fields'
  body?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  /** Grabs the timestamp for when a notification center item was read for a given user */
  read_at?: Maybe<Scalars['timestamptz']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "notification_center_item" */
export type Notification_Center_Item_Max_Order_By = {
  body?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  link?: Maybe<Order_By>
  main_image?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  secondary_image?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Notification_Center_Item_Min_Fields = {
  __typename?: 'notification_center_item_min_fields'
  body?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  /** Grabs the timestamp for when a notification center item was read for a given user */
  read_at?: Maybe<Scalars['timestamptz']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "notification_center_item" */
export type Notification_Center_Item_Min_Order_By = {
  body?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  link?: Maybe<Order_By>
  main_image?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  secondary_image?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "notification_center_item" */
export type Notification_Center_Item_Mutation_Response = {
  __typename?: 'notification_center_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Center_Item>
}

/** on_conflict condition type for table "notification_center_item" */
export type Notification_Center_Item_On_Conflict = {
  constraint: Notification_Center_Item_Constraint
  update_columns?: Array<Notification_Center_Item_Update_Column>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

/** Ordering options when selecting data from "notification_center_item". */
export type Notification_Center_Item_Order_By = {
  banner?: Maybe<Order_By>
  body?: Maybe<Order_By>
  communication_template?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  link?: Maybe<Order_By>
  main_image?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_item?: Maybe<Notifiable_Item_Order_By>
  notifiable_type?: Maybe<Order_By>
  read_at?: Maybe<Order_By>
  secondary_image?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: notification_center_item */
export type Notification_Center_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** A table to hold whether a user has or has not read a particular notification center item */
export type Notification_Center_Item_Read_Status = {
  __typename?: 'notification_center_item_read_status'
  created_at: Scalars['timestamptz']
  notification_center_item_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Aggregate = {
  __typename?: 'notification_center_item_read_status_aggregate'
  aggregate?: Maybe<Notification_Center_Item_Read_Status_Aggregate_Fields>
  nodes: Array<Notification_Center_Item_Read_Status>
}

/** aggregate fields of "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Aggregate_Fields = {
  __typename?: 'notification_center_item_read_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notification_Center_Item_Read_Status_Max_Fields>
  min?: Maybe<Notification_Center_Item_Read_Status_Min_Fields>
}

/** aggregate fields of "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Center_Item_Read_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "notification_center_item_read_status". All fields are combined with a logical 'AND'. */
export type Notification_Center_Item_Read_Status_Bool_Exp = {
  _and?: Maybe<Array<Notification_Center_Item_Read_Status_Bool_Exp>>
  _not?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
  _or?: Maybe<Array<Notification_Center_Item_Read_Status_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  notification_center_item_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notification_center_item_read_status" */
export enum Notification_Center_Item_Read_Status_Constraint {
  /** unique or primary key constraint on columns "user_id", "notification_center_item_id" */
  NotificationCenterItemReadStatusPkey = 'notification_center_item_read_status_pkey',
}

/** input type for inserting data into table "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  notification_center_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notification_Center_Item_Read_Status_Max_Fields = {
  __typename?: 'notification_center_item_read_status_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  notification_center_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Notification_Center_Item_Read_Status_Min_Fields = {
  __typename?: 'notification_center_item_read_status_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  notification_center_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Mutation_Response = {
  __typename?: 'notification_center_item_read_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Center_Item_Read_Status>
}

/** on_conflict condition type for table "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_On_Conflict = {
  constraint: Notification_Center_Item_Read_Status_Constraint
  update_columns?: Array<Notification_Center_Item_Read_Status_Update_Column>
  where?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
}

/** Ordering options when selecting data from "notification_center_item_read_status". */
export type Notification_Center_Item_Read_Status_Order_By = {
  created_at?: Maybe<Order_By>
  notification_center_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: notification_center_item_read_status */
export type Notification_Center_Item_Read_Status_Pk_Columns_Input = {
  notification_center_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

/** select columns of table "notification_center_item_read_status" */
export enum Notification_Center_Item_Read_Status_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NotificationCenterItemId = 'notification_center_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  notification_center_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Center_Item_Read_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notification_Center_Item_Read_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  notification_center_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "notification_center_item_read_status" */
export enum Notification_Center_Item_Read_Status_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NotificationCenterItemId = 'notification_center_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Notification_Center_Item_Read_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Center_Item_Read_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Notification_Center_Item_Read_Status_Bool_Exp
}

/** select columns of table "notification_center_item" */
export enum Notification_Center_Item_Select_Column {
  /** column name */
  Banner = 'banner',
  /** column name */
  Body = 'body',
  /** column name */
  CommunicationTemplate = 'communication_template',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  MainImage = 'main_image',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  SecondaryImage = 'secondary_image',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "notification_center_item_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notification_center_item" */
export enum Notification_Center_Item_Select_Column_Notification_Center_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Banner = 'banner',
}

/** select "notification_center_item_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notification_center_item" */
export enum Notification_Center_Item_Select_Column_Notification_Center_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Banner = 'banner',
}

/** input type for updating data in table "notification_center_item" */
export type Notification_Center_Item_Set_Input = {
  banner?: Maybe<Scalars['Boolean']>
  body?: Maybe<Scalars['String']>
  communication_template?: Maybe<Communication_Template_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id?: Maybe<Scalars['Int']>
  type?: Maybe<Notification_Center_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Notification_Center_Item_Stddev_Fields = {
  __typename?: 'notification_center_item_stddev_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "notification_center_item" */
export type Notification_Center_Item_Stddev_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Notification_Center_Item_Stddev_Pop_Fields = {
  __typename?: 'notification_center_item_stddev_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "notification_center_item" */
export type Notification_Center_Item_Stddev_Pop_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Notification_Center_Item_Stddev_Samp_Fields = {
  __typename?: 'notification_center_item_stddev_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "notification_center_item" */
export type Notification_Center_Item_Stddev_Samp_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** Streaming cursor of the table "notification_center_item" */
export type Notification_Center_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Center_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notification_Center_Item_Stream_Cursor_Value_Input = {
  banner?: Maybe<Scalars['Boolean']>
  body?: Maybe<Scalars['String']>
  communication_template?: Maybe<Communication_Template_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id?: Maybe<Scalars['Int']>
  type?: Maybe<Notification_Center_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Notification_Center_Item_Sum_Fields = {
  __typename?: 'notification_center_item_sum_fields'
  sequential_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "notification_center_item" */
export type Notification_Center_Item_Sum_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** An enum table of the different types of notification center items */
export type Notification_Center_Item_Type = {
  __typename?: 'notification_center_item_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "notification_center_item_type" */
export type Notification_Center_Item_Type_Aggregate = {
  __typename?: 'notification_center_item_type_aggregate'
  aggregate?: Maybe<Notification_Center_Item_Type_Aggregate_Fields>
  nodes: Array<Notification_Center_Item_Type>
}

/** aggregate fields of "notification_center_item_type" */
export type Notification_Center_Item_Type_Aggregate_Fields = {
  __typename?: 'notification_center_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notification_Center_Item_Type_Max_Fields>
  min?: Maybe<Notification_Center_Item_Type_Min_Fields>
}

/** aggregate fields of "notification_center_item_type" */
export type Notification_Center_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Center_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "notification_center_item_type". All fields are combined with a logical 'AND'. */
export type Notification_Center_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Notification_Center_Item_Type_Bool_Exp>>
  _not?: Maybe<Notification_Center_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Notification_Center_Item_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notification_center_item_type" */
export enum Notification_Center_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  NotificationCenterItemTypePkey = 'notification_center_item_type_pkey',
}

export enum Notification_Center_Item_Type_Enum {
  Communication = 'communication',
  Informational = 'informational',
  Task = 'task',
}

/** Boolean expression to compare columns of type "notification_center_item_type_enum". All fields are combined with logical 'AND'. */
export type Notification_Center_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Notification_Center_Item_Type_Enum>
  _in?: Maybe<Array<Notification_Center_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Notification_Center_Item_Type_Enum>
  _nin?: Maybe<Array<Notification_Center_Item_Type_Enum>>
}

/** input type for inserting data into table "notification_center_item_type" */
export type Notification_Center_Item_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notification_Center_Item_Type_Max_Fields = {
  __typename?: 'notification_center_item_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Notification_Center_Item_Type_Min_Fields = {
  __typename?: 'notification_center_item_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "notification_center_item_type" */
export type Notification_Center_Item_Type_Mutation_Response = {
  __typename?: 'notification_center_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Center_Item_Type>
}

/** on_conflict condition type for table "notification_center_item_type" */
export type Notification_Center_Item_Type_On_Conflict = {
  constraint: Notification_Center_Item_Type_Constraint
  update_columns?: Array<Notification_Center_Item_Type_Update_Column>
  where?: Maybe<Notification_Center_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "notification_center_item_type". */
export type Notification_Center_Item_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: notification_center_item_type */
export type Notification_Center_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "notification_center_item_type" */
export enum Notification_Center_Item_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "notification_center_item_type" */
export type Notification_Center_Item_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "notification_center_item_type" */
export type Notification_Center_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Center_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notification_Center_Item_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "notification_center_item_type" */
export enum Notification_Center_Item_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Notification_Center_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Center_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Notification_Center_Item_Type_Bool_Exp
}

/** update columns of table "notification_center_item" */
export enum Notification_Center_Item_Update_Column {
  /** column name */
  Banner = 'banner',
  /** column name */
  Body = 'body',
  /** column name */
  CommunicationTemplate = 'communication_template',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  MainImage = 'main_image',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  SecondaryImage = 'secondary_image',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Notification_Center_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Notification_Center_Item_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Center_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Notification_Center_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Notification_Center_Item_Var_Pop_Fields = {
  __typename?: 'notification_center_item_var_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "notification_center_item" */
export type Notification_Center_Item_Var_Pop_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Notification_Center_Item_Var_Samp_Fields = {
  __typename?: 'notification_center_item_var_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "notification_center_item" */
export type Notification_Center_Item_Var_Samp_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Notification_Center_Item_Variance_Fields = {
  __typename?: 'notification_center_item_variance_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "notification_center_item" */
export type Notification_Center_Item_Variance_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** unique or primary key constraints on table "notification" */
export enum Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationPkey = 'notification_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Notification_Delete_At_Path_Input = {
  criteria?: Maybe<Array<Scalars['String']>>
  payload?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Notification_Delete_Elem_Input = {
  criteria?: Maybe<Scalars['Int']>
  payload?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Notification_Delete_Key_Input = {
  criteria?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "notification" */
export type Notification_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  notification_status?: Maybe<Notification_Status_Arr_Rel_Insert_Input>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Notification_Max_Fields = {
  __typename?: 'notification_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Notification_Min_Fields = {
  __typename?: 'notification_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "notification" */
export type Notification_Mutation_Response = {
  __typename?: 'notification_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notification>
}

/** input type for inserting object relation for remote table "notification" */
export type Notification_Obj_Rel_Insert_Input = {
  data: Notification_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Notification_On_Conflict>
}

/** on_conflict condition type for table "notification" */
export type Notification_On_Conflict = {
  constraint: Notification_Constraint
  update_columns?: Array<Notification_Update_Column>
  where?: Maybe<Notification_Bool_Exp>
}

/** Ordering options when selecting data from "notification". */
export type Notification_Order_By = {
  active?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  criteria?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  notification_status_aggregate?: Maybe<Notification_Status_Aggregate_Order_By>
  payload?: Maybe<Order_By>
  show_after?: Maybe<Order_By>
  show_before?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: notification */
export type Notification_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** A table to hold our users' notification preferences */
export type Notification_Preference = {
  __typename?: 'notification_preference'
  allowable_communication: Array<Scalars['String']>
  allowed: Scalars['Boolean']
  communication_template: Scalars['String']
  id: Scalars['uuid']
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "notification_preference" */
export type Notification_Preference_Aggregate = {
  __typename?: 'notification_preference_aggregate'
  aggregate?: Maybe<Notification_Preference_Aggregate_Fields>
  nodes: Array<Notification_Preference>
}

export type Notification_Preference_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Notification_Preference_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Notification_Preference_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Notification_Preference_Aggregate_Bool_Exp_Count>
}

export type Notification_Preference_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notification_Preference_Select_Column_Notification_Preference_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Preference_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notification_Preference_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notification_Preference_Select_Column_Notification_Preference_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Preference_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notification_Preference_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Notification_Preference_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Preference_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "notification_preference" */
export type Notification_Preference_Aggregate_Fields = {
  __typename?: 'notification_preference_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notification_Preference_Max_Fields>
  min?: Maybe<Notification_Preference_Min_Fields>
}

/** aggregate fields of "notification_preference" */
export type Notification_Preference_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Preference_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "notification_preference" */
export type Notification_Preference_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Notification_Preference_Max_Order_By>
  min?: Maybe<Notification_Preference_Min_Order_By>
}

/** input type for inserting array relation for remote table "notification_preference" */
export type Notification_Preference_Arr_Rel_Insert_Input = {
  data: Array<Notification_Preference_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Notification_Preference_On_Conflict>
}

/** Boolean expression to filter rows from the table "notification_preference". All fields are combined with a logical 'AND'. */
export type Notification_Preference_Bool_Exp = {
  _and?: Maybe<Array<Notification_Preference_Bool_Exp>>
  _not?: Maybe<Notification_Preference_Bool_Exp>
  _or?: Maybe<Array<Notification_Preference_Bool_Exp>>
  allowable_communication?: Maybe<String_Array_Comparison_Exp>
  allowed?: Maybe<Boolean_Comparison_Exp>
  communication_template?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_id?: Maybe<Uuid_Comparison_Exp>
  notifiable_type?: Maybe<String_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notification_preference" */
export enum Notification_Preference_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationPreferencePkey = 'notification_preference_pkey',
  /** unique or primary key constraint on columns "user_id", "communication_template" */
  NotificationPreferenceUniqueIsNullIdx = 'notification_preference_unique_is_null_idx',
  /** unique or primary key constraint on columns "user_id", "communication_template", "notifiable_type", "notifiable_id" */
  NotificationPreferenceUniqueNotNullIdx = 'notification_preference_unique_not_null_idx',
}

/** input type for inserting data into table "notification_preference" */
export type Notification_Preference_Insert_Input = {
  allowable_communication?: Maybe<Array<Scalars['String']>>
  allowed?: Maybe<Scalars['Boolean']>
  communication_template?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notification_Preference_Max_Fields = {
  __typename?: 'notification_preference_max_fields'
  allowable_communication?: Maybe<Array<Scalars['String']>>
  communication_template?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "notification_preference" */
export type Notification_Preference_Max_Order_By = {
  allowable_communication?: Maybe<Order_By>
  communication_template?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Notification_Preference_Min_Fields = {
  __typename?: 'notification_preference_min_fields'
  allowable_communication?: Maybe<Array<Scalars['String']>>
  communication_template?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "notification_preference" */
export type Notification_Preference_Min_Order_By = {
  allowable_communication?: Maybe<Order_By>
  communication_template?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "notification_preference" */
export type Notification_Preference_Mutation_Response = {
  __typename?: 'notification_preference_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Preference>
}

/** on_conflict condition type for table "notification_preference" */
export type Notification_Preference_On_Conflict = {
  constraint: Notification_Preference_Constraint
  update_columns?: Array<Notification_Preference_Update_Column>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

/** Ordering options when selecting data from "notification_preference". */
export type Notification_Preference_Order_By = {
  allowable_communication?: Maybe<Order_By>
  allowed?: Maybe<Order_By>
  communication_template?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: notification_preference */
export type Notification_Preference_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "notification_preference" */
export enum Notification_Preference_Select_Column {
  /** column name */
  AllowableCommunication = 'allowable_communication',
  /** column name */
  Allowed = 'allowed',
  /** column name */
  CommunicationTemplate = 'communication_template',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  UserId = 'user_id',
}

/** select "notification_preference_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notification_preference" */
export enum Notification_Preference_Select_Column_Notification_Preference_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Allowed = 'allowed',
}

/** select "notification_preference_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notification_preference" */
export enum Notification_Preference_Select_Column_Notification_Preference_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Allowed = 'allowed',
}

/** input type for updating data in table "notification_preference" */
export type Notification_Preference_Set_Input = {
  allowable_communication?: Maybe<Array<Scalars['String']>>
  allowed?: Maybe<Scalars['Boolean']>
  communication_template?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "notification_preference" */
export type Notification_Preference_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Preference_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notification_Preference_Stream_Cursor_Value_Input = {
  allowable_communication?: Maybe<Array<Scalars['String']>>
  allowed?: Maybe<Scalars['Boolean']>
  communication_template?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "notification_preference" */
export enum Notification_Preference_Update_Column {
  /** column name */
  AllowableCommunication = 'allowable_communication',
  /** column name */
  Allowed = 'allowed',
  /** column name */
  CommunicationTemplate = 'communication_template',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  UserId = 'user_id',
}

export type Notification_Preference_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Preference_Set_Input>
  /** filter the rows which have to be updated */
  where: Notification_Preference_Bool_Exp
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Notification_Prepend_Input = {
  criteria?: Maybe<Scalars['jsonb']>
  payload?: Maybe<Scalars['jsonb']>
}

/** select columns of table "notification" */
export enum Notification_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  Payload = 'payload',
  /** column name */
  ShowAfter = 'show_after',
  /** column name */
  ShowBefore = 'show_before',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "notification" */
export type Notification_Set_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "notification_status" */
export type Notification_Status = {
  __typename?: 'notification_status'
  clicked: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  notification: Notification
  notification_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "notification_status" */
export type Notification_Status_Aggregate = {
  __typename?: 'notification_status_aggregate'
  aggregate?: Maybe<Notification_Status_Aggregate_Fields>
  nodes: Array<Notification_Status>
}

export type Notification_Status_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Notification_Status_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Notification_Status_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Notification_Status_Aggregate_Bool_Exp_Count>
}

export type Notification_Status_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notification_Status_Select_Column_Notification_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Status_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notification_Status_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notification_Status_Select_Column_Notification_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Status_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notification_Status_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Notification_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Status_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "notification_status" */
export type Notification_Status_Aggregate_Fields = {
  __typename?: 'notification_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notification_Status_Max_Fields>
  min?: Maybe<Notification_Status_Min_Fields>
}

/** aggregate fields of "notification_status" */
export type Notification_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "notification_status" */
export type Notification_Status_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Notification_Status_Max_Order_By>
  min?: Maybe<Notification_Status_Min_Order_By>
}

/** input type for inserting array relation for remote table "notification_status" */
export type Notification_Status_Arr_Rel_Insert_Input = {
  data: Array<Notification_Status_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Notification_Status_On_Conflict>
}

/** Boolean expression to filter rows from the table "notification_status". All fields are combined with a logical 'AND'. */
export type Notification_Status_Bool_Exp = {
  _and?: Maybe<Array<Notification_Status_Bool_Exp>>
  _not?: Maybe<Notification_Status_Bool_Exp>
  _or?: Maybe<Array<Notification_Status_Bool_Exp>>
  clicked?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notification?: Maybe<Notification_Bool_Exp>
  notification_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notification_status" */
export enum Notification_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationStatusPkey = 'notification_status_pkey',
  /** unique or primary key constraint on columns "user_id", "notification_id" */
  NotificationStatusUserIdNotificationIdKey = 'notification_status_user_id_notification_id_key',
}

/** input type for inserting data into table "notification_status" */
export type Notification_Status_Insert_Input = {
  clicked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification?: Maybe<Notification_Obj_Rel_Insert_Input>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notification_Status_Max_Fields = {
  __typename?: 'notification_status_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "notification_status" */
export type Notification_Status_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notification_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Notification_Status_Min_Fields = {
  __typename?: 'notification_status_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "notification_status" */
export type Notification_Status_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notification_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "notification_status" */
export type Notification_Status_Mutation_Response = {
  __typename?: 'notification_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Status>
}

/** on_conflict condition type for table "notification_status" */
export type Notification_Status_On_Conflict = {
  constraint: Notification_Status_Constraint
  update_columns?: Array<Notification_Status_Update_Column>
  where?: Maybe<Notification_Status_Bool_Exp>
}

/** Ordering options when selecting data from "notification_status". */
export type Notification_Status_Order_By = {
  clicked?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notification?: Maybe<Notification_Order_By>
  notification_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: notification_status */
export type Notification_Status_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "notification_status" */
export enum Notification_Status_Select_Column {
  /** column name */
  Clicked = 'clicked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "notification_status_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notification_status" */
export enum Notification_Status_Select_Column_Notification_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Clicked = 'clicked',
}

/** select "notification_status_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notification_status" */
export enum Notification_Status_Select_Column_Notification_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Clicked = 'clicked',
}

/** input type for updating data in table "notification_status" */
export type Notification_Status_Set_Input = {
  clicked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "notification_status" */
export type Notification_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notification_Status_Stream_Cursor_Value_Input = {
  clicked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "notification_status" */
export enum Notification_Status_Update_Column {
  /** column name */
  Clicked = 'clicked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Notification_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Notification_Status_Bool_Exp
}

/** Streaming cursor of the table "notification" */
export type Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notification_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "notification" */
export enum Notification_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  Payload = 'payload',
  /** column name */
  ShowAfter = 'show_after',
  /** column name */
  ShowBefore = 'show_before',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Notification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Notification_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Notification_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Notification_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Notification_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Notification_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Set_Input>
  /** filter the rows which have to be updated */
  where: Notification_Bool_Exp
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>
  _gt?: Maybe<Scalars['numeric']>
  _gte?: Maybe<Scalars['numeric']>
  _in?: Maybe<Array<Scalars['numeric']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['numeric']>
  _lte?: Maybe<Scalars['numeric']>
  _neq?: Maybe<Scalars['numeric']>
  _nin?: Maybe<Array<Scalars['numeric']>>
}

/** Used for showing category selection during onboarding */
export type Onboarding_Categories = {
  __typename?: 'onboarding_categories'
  category: Shop_Category_Enum
  featured: Scalars['Boolean']
  image: Scalars['String']
  /** An object relationship */
  shop_category: Shop_Category
}

/** aggregated selection of "onboarding_categories" */
export type Onboarding_Categories_Aggregate = {
  __typename?: 'onboarding_categories_aggregate'
  aggregate?: Maybe<Onboarding_Categories_Aggregate_Fields>
  nodes: Array<Onboarding_Categories>
}

/** aggregate fields of "onboarding_categories" */
export type Onboarding_Categories_Aggregate_Fields = {
  __typename?: 'onboarding_categories_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Onboarding_Categories_Max_Fields>
  min?: Maybe<Onboarding_Categories_Min_Fields>
}

/** aggregate fields of "onboarding_categories" */
export type Onboarding_Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Onboarding_Categories_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "onboarding_categories". All fields are combined with a logical 'AND'. */
export type Onboarding_Categories_Bool_Exp = {
  _and?: Maybe<Array<Onboarding_Categories_Bool_Exp>>
  _not?: Maybe<Onboarding_Categories_Bool_Exp>
  _or?: Maybe<Array<Onboarding_Categories_Bool_Exp>>
  category?: Maybe<Shop_Category_Enum_Comparison_Exp>
  featured?: Maybe<Boolean_Comparison_Exp>
  image?: Maybe<String_Comparison_Exp>
  shop_category?: Maybe<Shop_Category_Bool_Exp>
}

/** unique or primary key constraints on table "onboarding_categories" */
export enum Onboarding_Categories_Constraint {
  /** unique or primary key constraint on columns "category" */
  OnboardingCategoriesPkey = 'onboarding_categories_pkey',
}

/** input type for inserting data into table "onboarding_categories" */
export type Onboarding_Categories_Insert_Input = {
  category?: Maybe<Shop_Category_Enum>
  featured?: Maybe<Scalars['Boolean']>
  image?: Maybe<Scalars['String']>
  shop_category?: Maybe<Shop_Category_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Onboarding_Categories_Max_Fields = {
  __typename?: 'onboarding_categories_max_fields'
  image?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Onboarding_Categories_Min_Fields = {
  __typename?: 'onboarding_categories_min_fields'
  image?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "onboarding_categories" */
export type Onboarding_Categories_Mutation_Response = {
  __typename?: 'onboarding_categories_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Onboarding_Categories>
}

/** input type for inserting object relation for remote table "onboarding_categories" */
export type Onboarding_Categories_Obj_Rel_Insert_Input = {
  data: Onboarding_Categories_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Onboarding_Categories_On_Conflict>
}

/** on_conflict condition type for table "onboarding_categories" */
export type Onboarding_Categories_On_Conflict = {
  constraint: Onboarding_Categories_Constraint
  update_columns?: Array<Onboarding_Categories_Update_Column>
  where?: Maybe<Onboarding_Categories_Bool_Exp>
}

/** Ordering options when selecting data from "onboarding_categories". */
export type Onboarding_Categories_Order_By = {
  category?: Maybe<Order_By>
  featured?: Maybe<Order_By>
  image?: Maybe<Order_By>
  shop_category?: Maybe<Shop_Category_Order_By>
}

/** primary key columns input for table: onboarding_categories */
export type Onboarding_Categories_Pk_Columns_Input = {
  category: Shop_Category_Enum
}

/** select columns of table "onboarding_categories" */
export enum Onboarding_Categories_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Featured = 'featured',
  /** column name */
  Image = 'image',
}

/** input type for updating data in table "onboarding_categories" */
export type Onboarding_Categories_Set_Input = {
  category?: Maybe<Shop_Category_Enum>
  featured?: Maybe<Scalars['Boolean']>
  image?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "onboarding_categories" */
export type Onboarding_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Onboarding_Categories_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Onboarding_Categories_Stream_Cursor_Value_Input = {
  category?: Maybe<Shop_Category_Enum>
  featured?: Maybe<Scalars['Boolean']>
  image?: Maybe<Scalars['String']>
}

/** update columns of table "onboarding_categories" */
export enum Onboarding_Categories_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Featured = 'featured',
  /** column name */
  Image = 'image',
}

export type Onboarding_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Onboarding_Categories_Set_Input>
  /** filter the rows which have to be updated */
  where: Onboarding_Categories_Bool_Exp
}

/** How did you hear about Carrot enum */
export type Onboarding_Sources = {
  __typename?: 'onboarding_sources'
  label?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "onboarding_sources" */
export type Onboarding_Sources_Aggregate = {
  __typename?: 'onboarding_sources_aggregate'
  aggregate?: Maybe<Onboarding_Sources_Aggregate_Fields>
  nodes: Array<Onboarding_Sources>
}

/** aggregate fields of "onboarding_sources" */
export type Onboarding_Sources_Aggregate_Fields = {
  __typename?: 'onboarding_sources_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Onboarding_Sources_Max_Fields>
  min?: Maybe<Onboarding_Sources_Min_Fields>
}

/** aggregate fields of "onboarding_sources" */
export type Onboarding_Sources_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Onboarding_Sources_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "onboarding_sources". All fields are combined with a logical 'AND'. */
export type Onboarding_Sources_Bool_Exp = {
  _and?: Maybe<Array<Onboarding_Sources_Bool_Exp>>
  _not?: Maybe<Onboarding_Sources_Bool_Exp>
  _or?: Maybe<Array<Onboarding_Sources_Bool_Exp>>
  label?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "onboarding_sources" */
export enum Onboarding_Sources_Constraint {
  /** unique or primary key constraint on columns "value" */
  OnboardingSourcesPkey = 'onboarding_sources_pkey',
}

export enum Onboarding_Sources_Enum {
  /** Blog or news article */
  BlogNews = 'blog_news',
  /** Recommended by a friend */
  Friend = 'friend',
  /** Other */
  Other = 'other',
  /** Search engine (Google, Yahoo, etc.) */
  Search = 'search',
  /** Carrot's social media (@addtocarrot) */
  SocialCarrot = 'social_carrot',
  /** Someone else's social media */
  SocialOther = 'social_other',
}

/** Boolean expression to compare columns of type "onboarding_sources_enum". All fields are combined with logical 'AND'. */
export type Onboarding_Sources_Enum_Comparison_Exp = {
  _eq?: Maybe<Onboarding_Sources_Enum>
  _in?: Maybe<Array<Onboarding_Sources_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Onboarding_Sources_Enum>
  _nin?: Maybe<Array<Onboarding_Sources_Enum>>
}

/** input type for inserting data into table "onboarding_sources" */
export type Onboarding_Sources_Insert_Input = {
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Onboarding_Sources_Max_Fields = {
  __typename?: 'onboarding_sources_max_fields'
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Onboarding_Sources_Min_Fields = {
  __typename?: 'onboarding_sources_min_fields'
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "onboarding_sources" */
export type Onboarding_Sources_Mutation_Response = {
  __typename?: 'onboarding_sources_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Onboarding_Sources>
}

/** input type for inserting object relation for remote table "onboarding_sources" */
export type Onboarding_Sources_Obj_Rel_Insert_Input = {
  data: Onboarding_Sources_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Onboarding_Sources_On_Conflict>
}

/** on_conflict condition type for table "onboarding_sources" */
export type Onboarding_Sources_On_Conflict = {
  constraint: Onboarding_Sources_Constraint
  update_columns?: Array<Onboarding_Sources_Update_Column>
  where?: Maybe<Onboarding_Sources_Bool_Exp>
}

/** Ordering options when selecting data from "onboarding_sources". */
export type Onboarding_Sources_Order_By = {
  label?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: onboarding_sources */
export type Onboarding_Sources_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "onboarding_sources" */
export enum Onboarding_Sources_Select_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "onboarding_sources" */
export type Onboarding_Sources_Set_Input = {
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "onboarding_sources" */
export type Onboarding_Sources_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Onboarding_Sources_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Onboarding_Sources_Stream_Cursor_Value_Input = {
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "onboarding_sources" */
export enum Onboarding_Sources_Update_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Value = 'value',
}

export type Onboarding_Sources_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Onboarding_Sources_Set_Input>
  /** filter the rows which have to be updated */
  where: Onboarding_Sources_Bool_Exp
}

/** Table to keep track of one off emails sent to users */
export type One_Off_Email = {
  __typename?: 'one_off_email'
  created_at: Scalars['timestamptz']
  data: Scalars['jsonb']
  id: Scalars['uuid']
  sent_at?: Maybe<Scalars['timestamptz']>
  template?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** Table to keep track of one off emails sent to users */
export type One_Off_EmailDataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "one_off_email" */
export type One_Off_Email_Aggregate = {
  __typename?: 'one_off_email_aggregate'
  aggregate?: Maybe<One_Off_Email_Aggregate_Fields>
  nodes: Array<One_Off_Email>
}

/** aggregate fields of "one_off_email" */
export type One_Off_Email_Aggregate_Fields = {
  __typename?: 'one_off_email_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<One_Off_Email_Max_Fields>
  min?: Maybe<One_Off_Email_Min_Fields>
}

/** aggregate fields of "one_off_email" */
export type One_Off_Email_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<One_Off_Email_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type One_Off_Email_Append_Input = {
  data?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "one_off_email". All fields are combined with a logical 'AND'. */
export type One_Off_Email_Bool_Exp = {
  _and?: Maybe<Array<One_Off_Email_Bool_Exp>>
  _not?: Maybe<One_Off_Email_Bool_Exp>
  _or?: Maybe<Array<One_Off_Email_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  data?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  sent_at?: Maybe<Timestamptz_Comparison_Exp>
  template?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "one_off_email" */
export enum One_Off_Email_Constraint {
  /** unique or primary key constraint on columns "id" */
  OneOffEmailPkey = 'one_off_email_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type One_Off_Email_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type One_Off_Email_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type One_Off_Email_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "one_off_email" */
export type One_Off_Email_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  sent_at?: Maybe<Scalars['timestamptz']>
  template?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type One_Off_Email_Max_Fields = {
  __typename?: 'one_off_email_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  sent_at?: Maybe<Scalars['timestamptz']>
  template?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type One_Off_Email_Min_Fields = {
  __typename?: 'one_off_email_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  sent_at?: Maybe<Scalars['timestamptz']>
  template?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "one_off_email" */
export type One_Off_Email_Mutation_Response = {
  __typename?: 'one_off_email_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<One_Off_Email>
}

/** on_conflict condition type for table "one_off_email" */
export type One_Off_Email_On_Conflict = {
  constraint: One_Off_Email_Constraint
  update_columns?: Array<One_Off_Email_Update_Column>
  where?: Maybe<One_Off_Email_Bool_Exp>
}

/** Ordering options when selecting data from "one_off_email". */
export type One_Off_Email_Order_By = {
  created_at?: Maybe<Order_By>
  data?: Maybe<Order_By>
  id?: Maybe<Order_By>
  sent_at?: Maybe<Order_By>
  template?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: one_off_email */
export type One_Off_Email_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type One_Off_Email_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>
}

/** select columns of table "one_off_email" */
export enum One_Off_Email_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Template = 'template',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "one_off_email" */
export type One_Off_Email_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  sent_at?: Maybe<Scalars['timestamptz']>
  template?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "one_off_email" */
export type One_Off_Email_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: One_Off_Email_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type One_Off_Email_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  sent_at?: Maybe<Scalars['timestamptz']>
  template?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "one_off_email" */
export enum One_Off_Email_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Template = 'template',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type One_Off_Email_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<One_Off_Email_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<One_Off_Email_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<One_Off_Email_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<One_Off_Email_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<One_Off_Email_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<One_Off_Email_Set_Input>
  /** filter the rows which have to be updated */
  where: One_Off_Email_Bool_Exp
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** This table stores the information of every page view */
export type Pageview_Event = {
  __typename?: 'pageview_event'
  /** An object relationship */
  actor?: Maybe<User>
  created_at: Scalars['timestamptz']
  event_data: Scalars['jsonb']
  id: Scalars['uuid']
  is_product_page: Scalars['Boolean']
  message_id: Scalars['String']
  path?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  /** An object relationship */
  shop?: Maybe<Shop>
  shop_key: Scalars['String']
  title?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  url: Scalars['String']
  user_id: Scalars['String']
}

/** This table stores the information of every page view */
export type Pageview_EventEvent_DataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "pageview_event" */
export type Pageview_Event_Aggregate = {
  __typename?: 'pageview_event_aggregate'
  aggregate?: Maybe<Pageview_Event_Aggregate_Fields>
  nodes: Array<Pageview_Event>
}

/** aggregate fields of "pageview_event" */
export type Pageview_Event_Aggregate_Fields = {
  __typename?: 'pageview_event_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Pageview_Event_Max_Fields>
  min?: Maybe<Pageview_Event_Min_Fields>
}

/** aggregate fields of "pageview_event" */
export type Pageview_Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pageview_Event_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pageview_Event_Append_Input = {
  event_data?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "pageview_event". All fields are combined with a logical 'AND'. */
export type Pageview_Event_Bool_Exp = {
  _and?: Maybe<Array<Pageview_Event_Bool_Exp>>
  _not?: Maybe<Pageview_Event_Bool_Exp>
  _or?: Maybe<Array<Pageview_Event_Bool_Exp>>
  actor?: Maybe<User_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  event_data?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_product_page?: Maybe<Boolean_Comparison_Exp>
  message_id?: Maybe<String_Comparison_Exp>
  path?: Maybe<String_Comparison_Exp>
  search?: Maybe<String_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  title?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  url?: Maybe<String_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "pageview_event" */
export enum Pageview_Event_Constraint {
  /** unique or primary key constraint on columns "message_id" */
  PageviewEventMessageIdKey = 'pageview_event_message_id_key',
  /** unique or primary key constraint on columns "id" */
  PageviewEventPkey = 'pageview_event_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pageview_Event_Delete_At_Path_Input = {
  event_data?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pageview_Event_Delete_Elem_Input = {
  event_data?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pageview_Event_Delete_Key_Input = {
  event_data?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "pageview_event" */
export type Pageview_Event_Insert_Input = {
  actor?: Maybe<User_Obj_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  event_data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  is_product_page?: Maybe<Scalars['Boolean']>
  message_id?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Pageview_Event_Max_Fields = {
  __typename?: 'pageview_event_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  message_id?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Pageview_Event_Min_Fields = {
  __typename?: 'pageview_event_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  message_id?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "pageview_event" */
export type Pageview_Event_Mutation_Response = {
  __typename?: 'pageview_event_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Pageview_Event>
}

/** on_conflict condition type for table "pageview_event" */
export type Pageview_Event_On_Conflict = {
  constraint: Pageview_Event_Constraint
  update_columns?: Array<Pageview_Event_Update_Column>
  where?: Maybe<Pageview_Event_Bool_Exp>
}

/** Ordering options when selecting data from "pageview_event". */
export type Pageview_Event_Order_By = {
  actor?: Maybe<User_Order_By>
  created_at?: Maybe<Order_By>
  event_data?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_product_page?: Maybe<Order_By>
  message_id?: Maybe<Order_By>
  path?: Maybe<Order_By>
  search?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: pageview_event */
export type Pageview_Event_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pageview_Event_Prepend_Input = {
  event_data?: Maybe<Scalars['jsonb']>
}

/** select columns of table "pageview_event" */
export enum Pageview_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventData = 'event_data',
  /** column name */
  Id = 'id',
  /** column name */
  IsProductPage = 'is_product_page',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  Path = 'path',
  /** column name */
  Search = 'search',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "pageview_event" */
export type Pageview_Event_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  event_data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  is_product_page?: Maybe<Scalars['Boolean']>
  message_id?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "pageview_event" */
export type Pageview_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pageview_Event_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Pageview_Event_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  event_data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  is_product_page?: Maybe<Scalars['Boolean']>
  message_id?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** columns and relationships of "pageview_event_summary" */
export type Pageview_Event_Summary = {
  __typename?: 'pageview_event_summary'
  count?: Maybe<Scalars['bigint']>
  created_at?: Maybe<Scalars['timestamptz']>
  key?: Maybe<Scalars['String']>
  /** An object relationship */
  shop?: Maybe<Shop>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "pageview_event_summary" */
export type Pageview_Event_Summary_Aggregate = {
  __typename?: 'pageview_event_summary_aggregate'
  aggregate?: Maybe<Pageview_Event_Summary_Aggregate_Fields>
  nodes: Array<Pageview_Event_Summary>
}

/** aggregate fields of "pageview_event_summary" */
export type Pageview_Event_Summary_Aggregate_Fields = {
  __typename?: 'pageview_event_summary_aggregate_fields'
  avg?: Maybe<Pageview_Event_Summary_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Pageview_Event_Summary_Max_Fields>
  min?: Maybe<Pageview_Event_Summary_Min_Fields>
  stddev?: Maybe<Pageview_Event_Summary_Stddev_Fields>
  stddev_pop?: Maybe<Pageview_Event_Summary_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Pageview_Event_Summary_Stddev_Samp_Fields>
  sum?: Maybe<Pageview_Event_Summary_Sum_Fields>
  var_pop?: Maybe<Pageview_Event_Summary_Var_Pop_Fields>
  var_samp?: Maybe<Pageview_Event_Summary_Var_Samp_Fields>
  variance?: Maybe<Pageview_Event_Summary_Variance_Fields>
}

/** aggregate fields of "pageview_event_summary" */
export type Pageview_Event_Summary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pageview_Event_Summary_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Pageview_Event_Summary_Avg_Fields = {
  __typename?: 'pageview_event_summary_avg_fields'
  count?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "pageview_event_summary". All fields are combined with a logical 'AND'. */
export type Pageview_Event_Summary_Bool_Exp = {
  _and?: Maybe<Array<Pageview_Event_Summary_Bool_Exp>>
  _not?: Maybe<Pageview_Event_Summary_Bool_Exp>
  _or?: Maybe<Array<Pageview_Event_Summary_Bool_Exp>>
  count?: Maybe<Bigint_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  key?: Maybe<String_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Pageview_Event_Summary_Max_Fields = {
  __typename?: 'pageview_event_summary_max_fields'
  count?: Maybe<Scalars['bigint']>
  created_at?: Maybe<Scalars['timestamptz']>
  key?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Pageview_Event_Summary_Min_Fields = {
  __typename?: 'pageview_event_summary_min_fields'
  count?: Maybe<Scalars['bigint']>
  created_at?: Maybe<Scalars['timestamptz']>
  key?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "pageview_event_summary". */
export type Pageview_Event_Summary_Order_By = {
  count?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  key?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "pageview_event_summary" */
export enum Pageview_Event_Summary_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type Pageview_Event_Summary_Stddev_Fields = {
  __typename?: 'pageview_event_summary_stddev_fields'
  count?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Pageview_Event_Summary_Stddev_Pop_Fields = {
  __typename?: 'pageview_event_summary_stddev_pop_fields'
  count?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Pageview_Event_Summary_Stddev_Samp_Fields = {
  __typename?: 'pageview_event_summary_stddev_samp_fields'
  count?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "pageview_event_summary" */
export type Pageview_Event_Summary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pageview_Event_Summary_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Pageview_Event_Summary_Stream_Cursor_Value_Input = {
  count?: Maybe<Scalars['bigint']>
  created_at?: Maybe<Scalars['timestamptz']>
  key?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Pageview_Event_Summary_Sum_Fields = {
  __typename?: 'pageview_event_summary_sum_fields'
  count?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Pageview_Event_Summary_Var_Pop_Fields = {
  __typename?: 'pageview_event_summary_var_pop_fields'
  count?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Pageview_Event_Summary_Var_Samp_Fields = {
  __typename?: 'pageview_event_summary_var_samp_fields'
  count?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Pageview_Event_Summary_Variance_Fields = {
  __typename?: 'pageview_event_summary_variance_fields'
  count?: Maybe<Scalars['Float']>
}

/** update columns of table "pageview_event" */
export enum Pageview_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventData = 'event_data',
  /** column name */
  Id = 'id',
  /** column name */
  IsProductPage = 'is_product_page',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  Path = 'path',
  /** column name */
  Search = 'search',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id',
}

export type Pageview_Event_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Pageview_Event_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Pageview_Event_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Pageview_Event_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Pageview_Event_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Pageview_Event_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Pageview_Event_Set_Input>
  /** filter the rows which have to be updated */
  where: Pageview_Event_Bool_Exp
}

/** Table to track rewards promo periods for invite payouts */
export type Promo_Period = {
  __typename?: 'promo_period'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  promo_end_at?: Maybe<Scalars['timestamptz']>
  promo_name: Scalars['String']
  promo_start_at: Scalars['timestamptz']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "promo_period" */
export type Promo_Period_Aggregate = {
  __typename?: 'promo_period_aggregate'
  aggregate?: Maybe<Promo_Period_Aggregate_Fields>
  nodes: Array<Promo_Period>
}

/** aggregate fields of "promo_period" */
export type Promo_Period_Aggregate_Fields = {
  __typename?: 'promo_period_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Promo_Period_Max_Fields>
  min?: Maybe<Promo_Period_Min_Fields>
}

/** aggregate fields of "promo_period" */
export type Promo_Period_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Promo_Period_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "promo_period". All fields are combined with a logical 'AND'. */
export type Promo_Period_Bool_Exp = {
  _and?: Maybe<Array<Promo_Period_Bool_Exp>>
  _not?: Maybe<Promo_Period_Bool_Exp>
  _or?: Maybe<Array<Promo_Period_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  promo_end_at?: Maybe<Timestamptz_Comparison_Exp>
  promo_name?: Maybe<String_Comparison_Exp>
  promo_start_at?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "promo_period" */
export enum Promo_Period_Constraint {
  /** unique or primary key constraint on columns "id" */
  PromoPeriodPkey = 'promo_period_pkey',
}

/** input type for inserting data into table "promo_period" */
export type Promo_Period_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_end_at?: Maybe<Scalars['timestamptz']>
  promo_name?: Maybe<Scalars['String']>
  promo_start_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Promo_Period_Max_Fields = {
  __typename?: 'promo_period_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_end_at?: Maybe<Scalars['timestamptz']>
  promo_name?: Maybe<Scalars['String']>
  promo_start_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Promo_Period_Min_Fields = {
  __typename?: 'promo_period_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_end_at?: Maybe<Scalars['timestamptz']>
  promo_name?: Maybe<Scalars['String']>
  promo_start_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "promo_period" */
export type Promo_Period_Mutation_Response = {
  __typename?: 'promo_period_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Promo_Period>
}

/** on_conflict condition type for table "promo_period" */
export type Promo_Period_On_Conflict = {
  constraint: Promo_Period_Constraint
  update_columns?: Array<Promo_Period_Update_Column>
  where?: Maybe<Promo_Period_Bool_Exp>
}

/** Ordering options when selecting data from "promo_period". */
export type Promo_Period_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  promo_end_at?: Maybe<Order_By>
  promo_name?: Maybe<Order_By>
  promo_start_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: promo_period */
export type Promo_Period_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "promo_period" */
export enum Promo_Period_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PromoEndAt = 'promo_end_at',
  /** column name */
  PromoName = 'promo_name',
  /** column name */
  PromoStartAt = 'promo_start_at',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "promo_period" */
export type Promo_Period_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_end_at?: Maybe<Scalars['timestamptz']>
  promo_name?: Maybe<Scalars['String']>
  promo_start_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "promo_period" */
export type Promo_Period_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Promo_Period_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Promo_Period_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_end_at?: Maybe<Scalars['timestamptz']>
  promo_name?: Maybe<Scalars['String']>
  promo_start_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "promo_period" */
export enum Promo_Period_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PromoEndAt = 'promo_end_at',
  /** column name */
  PromoName = 'promo_name',
  /** column name */
  PromoStartAt = 'promo_start_at',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Promo_Period_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Promo_Period_Set_Input>
  /** filter the rows which have to be updated */
  where: Promo_Period_Bool_Exp
}

/** columns and relationships of "push_notification" */
export type Push_Notification = {
  __typename?: 'push_notification'
  active: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  criteria: Scalars['jsonb']
  id: Scalars['uuid']
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload: Scalars['jsonb']
  /** An array relationship */
  push_notification_status: Array<Push_Notification_Status>
  /** An aggregate relationship */
  push_notification_status_aggregate: Push_Notification_Status_Aggregate
  show_after: Scalars['timestamptz']
  show_before: Scalars['timestamptz']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
}

/** columns and relationships of "push_notification" */
export type Push_NotificationCriteriaArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "push_notification" */
export type Push_NotificationPayloadArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "push_notification" */
export type Push_NotificationPush_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

/** columns and relationships of "push_notification" */
export type Push_NotificationPush_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

/** aggregated selection of "push_notification" */
export type Push_Notification_Aggregate = {
  __typename?: 'push_notification_aggregate'
  aggregate?: Maybe<Push_Notification_Aggregate_Fields>
  nodes: Array<Push_Notification>
}

/** aggregate fields of "push_notification" */
export type Push_Notification_Aggregate_Fields = {
  __typename?: 'push_notification_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Push_Notification_Max_Fields>
  min?: Maybe<Push_Notification_Min_Fields>
}

/** aggregate fields of "push_notification" */
export type Push_Notification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Push_Notification_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Push_Notification_Append_Input = {
  criteria?: Maybe<Scalars['jsonb']>
  payload?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "push_notification". All fields are combined with a logical 'AND'. */
export type Push_Notification_Bool_Exp = {
  _and?: Maybe<Array<Push_Notification_Bool_Exp>>
  _not?: Maybe<Push_Notification_Bool_Exp>
  _or?: Maybe<Array<Push_Notification_Bool_Exp>>
  active?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  criteria?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_id?: Maybe<String_Comparison_Exp>
  notifiable_type?: Maybe<String_Comparison_Exp>
  payload?: Maybe<Jsonb_Comparison_Exp>
  push_notification_status?: Maybe<Push_Notification_Status_Bool_Exp>
  push_notification_status_aggregate?: Maybe<Push_Notification_Status_Aggregate_Bool_Exp>
  show_after?: Maybe<Timestamptz_Comparison_Exp>
  show_before?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
}

/** unique or primary key constraints on table "push_notification" */
export enum Push_Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  PushNotificationPkey = 'push_notification_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Push_Notification_Delete_At_Path_Input = {
  criteria?: Maybe<Array<Scalars['String']>>
  payload?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Push_Notification_Delete_Elem_Input = {
  criteria?: Maybe<Scalars['Int']>
  payload?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Push_Notification_Delete_Key_Input = {
  criteria?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "push_notification" */
export type Push_Notification_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  push_notification_status?: Maybe<Push_Notification_Status_Arr_Rel_Insert_Input>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Push_Notification_Max_Fields = {
  __typename?: 'push_notification_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Push_Notification_Min_Fields = {
  __typename?: 'push_notification_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "push_notification" */
export type Push_Notification_Mutation_Response = {
  __typename?: 'push_notification_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Push_Notification>
}

/** on_conflict condition type for table "push_notification" */
export type Push_Notification_On_Conflict = {
  constraint: Push_Notification_Constraint
  update_columns?: Array<Push_Notification_Update_Column>
  where?: Maybe<Push_Notification_Bool_Exp>
}

/** Ordering options when selecting data from "push_notification". */
export type Push_Notification_Order_By = {
  active?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  criteria?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  payload?: Maybe<Order_By>
  push_notification_status_aggregate?: Maybe<Push_Notification_Status_Aggregate_Order_By>
  show_after?: Maybe<Order_By>
  show_before?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
}

/** primary key columns input for table: push_notification */
export type Push_Notification_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Push_Notification_Prepend_Input = {
  criteria?: Maybe<Scalars['jsonb']>
  payload?: Maybe<Scalars['jsonb']>
}

/** select columns of table "push_notification" */
export enum Push_Notification_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  Payload = 'payload',
  /** column name */
  ShowAfter = 'show_after',
  /** column name */
  ShowBefore = 'show_before',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "push_notification" */
export type Push_Notification_Set_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "push_notification_status" */
export type Push_Notification_Status = {
  __typename?: 'push_notification_status'
  clicked: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  notification_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "push_notification_status" */
export type Push_Notification_Status_Aggregate = {
  __typename?: 'push_notification_status_aggregate'
  aggregate?: Maybe<Push_Notification_Status_Aggregate_Fields>
  nodes: Array<Push_Notification_Status>
}

export type Push_Notification_Status_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Push_Notification_Status_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Push_Notification_Status_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Push_Notification_Status_Aggregate_Bool_Exp_Count>
}

export type Push_Notification_Status_Aggregate_Bool_Exp_Bool_And = {
  arguments: Push_Notification_Status_Select_Column_Push_Notification_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Push_Notification_Status_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Push_Notification_Status_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Push_Notification_Status_Select_Column_Push_Notification_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Push_Notification_Status_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Push_Notification_Status_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Push_Notification_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Push_Notification_Status_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "push_notification_status" */
export type Push_Notification_Status_Aggregate_Fields = {
  __typename?: 'push_notification_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Push_Notification_Status_Max_Fields>
  min?: Maybe<Push_Notification_Status_Min_Fields>
}

/** aggregate fields of "push_notification_status" */
export type Push_Notification_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Push_Notification_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "push_notification_status" */
export type Push_Notification_Status_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Push_Notification_Status_Max_Order_By>
  min?: Maybe<Push_Notification_Status_Min_Order_By>
}

/** input type for inserting array relation for remote table "push_notification_status" */
export type Push_Notification_Status_Arr_Rel_Insert_Input = {
  data: Array<Push_Notification_Status_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Push_Notification_Status_On_Conflict>
}

/** Boolean expression to filter rows from the table "push_notification_status". All fields are combined with a logical 'AND'. */
export type Push_Notification_Status_Bool_Exp = {
  _and?: Maybe<Array<Push_Notification_Status_Bool_Exp>>
  _not?: Maybe<Push_Notification_Status_Bool_Exp>
  _or?: Maybe<Array<Push_Notification_Status_Bool_Exp>>
  clicked?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notification_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "push_notification_status" */
export enum Push_Notification_Status_Constraint {
  /** unique or primary key constraint on columns "user_id", "notification_id" */
  PushNotificationStatusNotificationIdUserIdKey = 'push_notification_status_notification_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  PushNotificationStatusPkey = 'push_notification_status_pkey',
}

/** input type for inserting data into table "push_notification_status" */
export type Push_Notification_Status_Insert_Input = {
  clicked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Push_Notification_Status_Max_Fields = {
  __typename?: 'push_notification_status_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "push_notification_status" */
export type Push_Notification_Status_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notification_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Push_Notification_Status_Min_Fields = {
  __typename?: 'push_notification_status_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "push_notification_status" */
export type Push_Notification_Status_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notification_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "push_notification_status" */
export type Push_Notification_Status_Mutation_Response = {
  __typename?: 'push_notification_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Push_Notification_Status>
}

/** on_conflict condition type for table "push_notification_status" */
export type Push_Notification_Status_On_Conflict = {
  constraint: Push_Notification_Status_Constraint
  update_columns?: Array<Push_Notification_Status_Update_Column>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

/** Ordering options when selecting data from "push_notification_status". */
export type Push_Notification_Status_Order_By = {
  clicked?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notification_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: push_notification_status */
export type Push_Notification_Status_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "push_notification_status" */
export enum Push_Notification_Status_Select_Column {
  /** column name */
  Clicked = 'clicked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "push_notification_status_aggregate_bool_exp_bool_and_arguments_columns" columns of table "push_notification_status" */
export enum Push_Notification_Status_Select_Column_Push_Notification_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Clicked = 'clicked',
}

/** select "push_notification_status_aggregate_bool_exp_bool_or_arguments_columns" columns of table "push_notification_status" */
export enum Push_Notification_Status_Select_Column_Push_Notification_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Clicked = 'clicked',
}

/** input type for updating data in table "push_notification_status" */
export type Push_Notification_Status_Set_Input = {
  clicked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "push_notification_status" */
export type Push_Notification_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Push_Notification_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Push_Notification_Status_Stream_Cursor_Value_Input = {
  clicked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "push_notification_status" */
export enum Push_Notification_Status_Update_Column {
  /** column name */
  Clicked = 'clicked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Push_Notification_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Push_Notification_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Push_Notification_Status_Bool_Exp
}

/** Streaming cursor of the table "push_notification" */
export type Push_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Push_Notification_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Push_Notification_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "push_notification" */
export enum Push_Notification_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  Payload = 'payload',
  /** column name */
  ShowAfter = 'show_after',
  /** column name */
  ShowBefore = 'show_before',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Push_Notification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Push_Notification_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Push_Notification_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Push_Notification_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Push_Notification_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Push_Notification_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Push_Notification_Set_Input>
  /** filter the rows which have to be updated */
  where: Push_Notification_Bool_Exp
}

export type Query_Root = {
  __typename?: 'query_root'
  /** fetch data from the table: "account_deletion_reason" */
  account_deletion_reason: Array<Account_Deletion_Reason>
  /** fetch aggregated fields from the table: "account_deletion_reason" */
  account_deletion_reason_aggregate: Account_Deletion_Reason_Aggregate
  /** fetch data from the table: "account_deletion_reason" using primary key columns */
  account_deletion_reason_by_pk?: Maybe<Account_Deletion_Reason>
  /** fetch data from the table: "affiliate" */
  affiliate: Array<Affiliate>
  /** fetch data from the table: "affiliate_advertiser_id" */
  affiliate_advertiser_id: Array<Affiliate_Advertiser_Id>
  /** fetch aggregated fields from the table: "affiliate_advertiser_id" */
  affiliate_advertiser_id_aggregate: Affiliate_Advertiser_Id_Aggregate
  /** fetch data from the table: "affiliate_advertiser_id" using primary key columns */
  affiliate_advertiser_id_by_pk?: Maybe<Affiliate_Advertiser_Id>
  /** fetch aggregated fields from the table: "affiliate" */
  affiliate_aggregate: Affiliate_Aggregate
  /** fetch data from the table: "affiliate" using primary key columns */
  affiliate_by_pk?: Maybe<Affiliate>
  /** fetch data from the table: "affiliate_commission_summary" */
  affiliate_commission_summary: Array<Affiliate_Commission_Summary>
  /** fetch aggregated fields from the table: "affiliate_commission_summary" */
  affiliate_commission_summary_aggregate: Affiliate_Commission_Summary_Aggregate
  /** fetch data from the table: "affiliate_meta" */
  affiliate_meta: Array<Affiliate_Meta>
  /** fetch aggregated fields from the table: "affiliate_meta" */
  affiliate_meta_aggregate: Affiliate_Meta_Aggregate
  /** fetch data from the table: "affiliate_meta" using primary key columns */
  affiliate_meta_by_pk?: Maybe<Affiliate_Meta>
  /** fetch data from the table: "affiliate_transaction" */
  affiliate_transaction: Array<Affiliate_Transaction>
  /** fetch aggregated fields from the table: "affiliate_transaction" */
  affiliate_transaction_aggregate: Affiliate_Transaction_Aggregate
  /** fetch data from the table: "affiliate_transaction" using primary key columns */
  affiliate_transaction_by_pk?: Maybe<Affiliate_Transaction>
  /** execute function "all_shops_for_shop_page" which returns "shop" */
  all_shops_for_shop_page: Array<Shop>
  /** execute function "all_shops_for_shop_page" and query aggregates on result of table type "shop" */
  all_shops_for_shop_page_aggregate: Shop_Aggregate
  /** fetch data from the table: "amazon_categories" */
  amazon_categories: Array<Amazon_Categories>
  /** fetch aggregated fields from the table: "amazon_categories" */
  amazon_categories_aggregate: Amazon_Categories_Aggregate
  /** A query to grab the available subscriptions for a given creator and/or influencer */
  availableSubscriptions: Array<Subscription>
  /** fetch data from the table: "billing_mode" */
  billing_mode: Array<Billing_Mode>
  /** fetch aggregated fields from the table: "billing_mode" */
  billing_mode_aggregate: Billing_Mode_Aggregate
  /** fetch data from the table: "billing_mode" using primary key columns */
  billing_mode_by_pk?: Maybe<Billing_Mode>
  /** fetch data from the table: "billing_platform_connected_account" */
  billing_platform_connected_account: Array<Billing_Platform_Connected_Account>
  /** fetch aggregated fields from the table: "billing_platform_connected_account" */
  billing_platform_connected_account_aggregate: Billing_Platform_Connected_Account_Aggregate
  /** fetch data from the table: "billing_platform_connected_account" using primary key columns */
  billing_platform_connected_account_by_pk?: Maybe<Billing_Platform_Connected_Account>
  /** fetch data from the table: "billing_platform_connected_account_status" */
  billing_platform_connected_account_status: Array<Billing_Platform_Connected_Account_Status>
  /** fetch aggregated fields from the table: "billing_platform_connected_account_status" */
  billing_platform_connected_account_status_aggregate: Billing_Platform_Connected_Account_Status_Aggregate
  /** fetch data from the table: "billing_platform_connected_account_status" using primary key columns */
  billing_platform_connected_account_status_by_pk?: Maybe<Billing_Platform_Connected_Account_Status>
  /** fetch data from the table: "billing_platform_customer" */
  billing_platform_customer: Array<Billing_Platform_Customer>
  /** fetch aggregated fields from the table: "billing_platform_customer" */
  billing_platform_customer_aggregate: Billing_Platform_Customer_Aggregate
  /** fetch data from the table: "billing_platform_customer" using primary key columns */
  billing_platform_customer_by_pk?: Maybe<Billing_Platform_Customer>
  /** fetch data from the table: "cart" */
  cart: Array<Cart>
  /** fetch aggregated fields from the table: "cart" */
  cart_aggregate: Cart_Aggregate
  /** fetch data from the table: "cart" using primary key columns */
  cart_by_pk?: Maybe<Cart>
  /** fetch data from the table: "cart_item" */
  cart_item: Array<Cart_Item>
  /** fetch aggregated fields from the table: "cart_item" */
  cart_item_aggregate: Cart_Item_Aggregate
  /** fetch data from the table: "cart_item" using primary key columns */
  cart_item_by_pk?: Maybe<Cart_Item>
  /** fetch data from the table: "cart_item_like" */
  cart_item_like: Array<Cart_Item_Like>
  /** fetch aggregated fields from the table: "cart_item_like" */
  cart_item_like_aggregate: Cart_Item_Like_Aggregate
  /** fetch data from the table: "cart_item_like" using primary key columns */
  cart_item_like_by_pk?: Maybe<Cart_Item_Like>
  /** fetch data from the table: "cart_item_note" */
  cart_item_note: Array<Cart_Item_Note>
  /** fetch aggregated fields from the table: "cart_item_note" */
  cart_item_note_aggregate: Cart_Item_Note_Aggregate
  /** fetch data from the table: "cart_item_note" using primary key columns */
  cart_item_note_by_pk?: Maybe<Cart_Item_Note>
  /** fetch data from the table: "categories" */
  categories: Array<Categories>
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>
  /** fetch data from the table: "category_shop_item" */
  category_shop_item: Array<Category_Shop_Item>
  /** fetch aggregated fields from the table: "category_shop_item" */
  category_shop_item_aggregate: Category_Shop_Item_Aggregate
  /** fetch data from the table: "category_shop_item" using primary key columns */
  category_shop_item_by_pk?: Maybe<Category_Shop_Item>
  /** fetch data from the table: "checkout" */
  checkout: Array<Checkout>
  /** fetch aggregated fields from the table: "checkout" */
  checkout_aggregate: Checkout_Aggregate
  /** fetch data from the table: "checkout" using primary key columns */
  checkout_by_pk?: Maybe<Checkout>
  /** fetch data from the table: "collection" */
  collection: Array<Collection>
  /** fetch aggregated fields from the table: "collection" */
  collection_aggregate: Collection_Aggregate
  /** fetch data from the table: "collection" using primary key columns */
  collection_by_pk?: Maybe<Collection>
  /** fetch data from the table: "collection_cart_item" */
  collection_cart_item: Array<Collection_Cart_Item>
  /** fetch aggregated fields from the table: "collection_cart_item" */
  collection_cart_item_aggregate: Collection_Cart_Item_Aggregate
  /** fetch data from the table: "collection_cart_item" using primary key columns */
  collection_cart_item_by_pk?: Maybe<Collection_Cart_Item>
  /** fetch data from the table: "collection_collaborator" */
  collection_collaborator: Array<Collection_Collaborator>
  /** fetch aggregated fields from the table: "collection_collaborator" */
  collection_collaborator_aggregate: Collection_Collaborator_Aggregate
  /** fetch data from the table: "collection_collaborator" using primary key columns */
  collection_collaborator_by_pk?: Maybe<Collection_Collaborator>
  /** fetch data from the table: "collection_invite" */
  collection_invite: Array<Collection_Invite>
  /** fetch aggregated fields from the table: "collection_invite" */
  collection_invite_aggregate: Collection_Invite_Aggregate
  /** fetch data from the table: "collection_invite" using primary key columns */
  collection_invite_by_pk?: Maybe<Collection_Invite>
  /** fetch data from the table: "collection_invite_status" */
  collection_invite_status: Array<Collection_Invite_Status>
  /** fetch aggregated fields from the table: "collection_invite_status" */
  collection_invite_status_aggregate: Collection_Invite_Status_Aggregate
  /** fetch data from the table: "collection_invite_status" using primary key columns */
  collection_invite_status_by_pk?: Maybe<Collection_Invite_Status>
  /** fetch data from the table: "collection_item_claim" */
  collection_item_claim: Array<Collection_Item_Claim>
  /** fetch aggregated fields from the table: "collection_item_claim" */
  collection_item_claim_aggregate: Collection_Item_Claim_Aggregate
  /** fetch data from the table: "collection_item_claim" using primary key columns */
  collection_item_claim_by_pk?: Maybe<Collection_Item_Claim>
  /** fetch data from the table: "collection_item_claim_status" */
  collection_item_claim_status: Array<Collection_Item_Claim_Status>
  /** fetch aggregated fields from the table: "collection_item_claim_status" */
  collection_item_claim_status_aggregate: Collection_Item_Claim_Status_Aggregate
  /** fetch data from the table: "collection_item_claim_status" using primary key columns */
  collection_item_claim_status_by_pk?: Maybe<Collection_Item_Claim_Status>
  /** execute function "collection_random" which returns "collection" */
  collection_random: Array<Collection>
  /** execute function "collection_random" and query aggregates on result of table type "collection" */
  collection_random_aggregate: Collection_Aggregate
  /** fetch data from the table: "collection_section" */
  collection_section: Array<Collection_Section>
  /** fetch aggregated fields from the table: "collection_section" */
  collection_section_aggregate: Collection_Section_Aggregate
  /** fetch data from the table: "collection_section" using primary key columns */
  collection_section_by_pk?: Maybe<Collection_Section>
  /** fetch data from the table: "collection_section_cart_item" */
  collection_section_cart_item: Array<Collection_Section_Cart_Item>
  /** fetch aggregated fields from the table: "collection_section_cart_item" */
  collection_section_cart_item_aggregate: Collection_Section_Cart_Item_Aggregate
  /** fetch data from the table: "collection_section_cart_item" using primary key columns */
  collection_section_cart_item_by_pk?: Maybe<Collection_Section_Cart_Item>
  /** fetch data from the table: "collection_slug_history" */
  collection_slug_history: Array<Collection_Slug_History>
  /** fetch aggregated fields from the table: "collection_slug_history" */
  collection_slug_history_aggregate: Collection_Slug_History_Aggregate
  /** fetch data from the table: "collection_slug_history" using primary key columns */
  collection_slug_history_by_pk?: Maybe<Collection_Slug_History>
  /** fetch data from the table: "collection_state" */
  collection_state: Array<Collection_State>
  /** fetch aggregated fields from the table: "collection_state" */
  collection_state_aggregate: Collection_State_Aggregate
  /** fetch data from the table: "collection_state" using primary key columns */
  collection_state_by_pk?: Maybe<Collection_State>
  /** fetch data from the table: "collection_type" */
  collection_type: Array<Collection_Type>
  /** fetch aggregated fields from the table: "collection_type" */
  collection_type_aggregate: Collection_Type_Aggregate
  /** fetch data from the table: "collection_type" using primary key columns */
  collection_type_by_pk?: Maybe<Collection_Type>
  /** fetch data from the table: "comment" */
  comment: Array<Comment>
  /** fetch aggregated fields from the table: "comment" */
  comment_aggregate: Comment_Aggregate
  /** fetch data from the table: "comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>
  /** fetch data from the table: "commentable_item" */
  commentable_item: Array<Commentable_Item>
  /** fetch aggregated fields from the table: "commentable_item" */
  commentable_item_aggregate: Commentable_Item_Aggregate
  /** fetch data from the table: "commentable_item" using primary key columns */
  commentable_item_by_pk?: Maybe<Commentable_Item>
  /** fetch data from the table: "commentable_item_type" */
  commentable_item_type: Array<Commentable_Item_Type>
  /** fetch aggregated fields from the table: "commentable_item_type" */
  commentable_item_type_aggregate: Commentable_Item_Type_Aggregate
  /** fetch data from the table: "commentable_item_type" using primary key columns */
  commentable_item_type_by_pk?: Maybe<Commentable_Item_Type>
  /** fetch data from the table: "communication_template" */
  communication_template: Array<Communication_Template>
  /** fetch aggregated fields from the table: "communication_template" */
  communication_template_aggregate: Communication_Template_Aggregate
  /** fetch data from the table: "communication_template" using primary key columns */
  communication_template_by_pk?: Maybe<Communication_Template>
  /** fetch data from the table: "communication_template_full_list" */
  communication_template_full_list: Array<Communication_Template_Full_List>
  /** fetch aggregated fields from the table: "communication_template_full_list" */
  communication_template_full_list_aggregate: Communication_Template_Full_List_Aggregate
  /** fetch data from the table: "communication_template_full_list" using primary key columns */
  communication_template_full_list_by_pk?: Maybe<Communication_Template_Full_List>
  contactConnections: Array<ContactConnection>
  /** fetch data from the table: "contact_info_type" */
  contact_info_type: Array<Contact_Info_Type>
  /** fetch aggregated fields from the table: "contact_info_type" */
  contact_info_type_aggregate: Contact_Info_Type_Aggregate
  /** fetch data from the table: "contact_info_type" using primary key columns */
  contact_info_type_by_pk?: Maybe<Contact_Info_Type>
  /** fetch data from the table: "contact_invite" */
  contact_invite: Array<Contact_Invite>
  /** fetch aggregated fields from the table: "contact_invite" */
  contact_invite_aggregate: Contact_Invite_Aggregate
  /** fetch data from the table: "contact_invite" using primary key columns */
  contact_invite_by_pk?: Maybe<Contact_Invite>
  /** fetch data from the table: "contact_invite_status" */
  contact_invite_status: Array<Contact_Invite_Status>
  /** fetch aggregated fields from the table: "contact_invite_status" */
  contact_invite_status_aggregate: Contact_Invite_Status_Aggregate
  /** fetch data from the table: "contact_invite_status" using primary key columns */
  contact_invite_status_by_pk?: Maybe<Contact_Invite_Status>
  /** fetch data from the table: "contact_invite_type" */
  contact_invite_type: Array<Contact_Invite_Type>
  /** fetch aggregated fields from the table: "contact_invite_type" */
  contact_invite_type_aggregate: Contact_Invite_Type_Aggregate
  /** fetch data from the table: "contact_invite_type" using primary key columns */
  contact_invite_type_by_pk?: Maybe<Contact_Invite_Type>
  /** This is a query for fetching the subscriber chart data for a creator */
  creatorSubscriberChartData: Array<CreatorSubscriberChartDataOutput>
  /** execute function "creator_subscribers_random" which returns "user" */
  creator_subscribers_random: Array<User>
  /** execute function "creator_subscribers_random" and query aggregates on result of table type "user" */
  creator_subscribers_random_aggregate: User_Aggregate
  /** fetch data from the table: "creator_user_subscriptions" */
  creator_user_subscriptions: Array<Creator_User_Subscriptions>
  /** fetch aggregated fields from the table: "creator_user_subscriptions" */
  creator_user_subscriptions_aggregate: Creator_User_Subscriptions_Aggregate
  /** fetch data from the table: "deal_hop_gate" */
  deal_hop_gate: Array<Deal_Hop_Gate>
  /** fetch aggregated fields from the table: "deal_hop_gate" */
  deal_hop_gate_aggregate: Deal_Hop_Gate_Aggregate
  /** fetch data from the table: "deal_hop_gate" using primary key columns */
  deal_hop_gate_by_pk?: Maybe<Deal_Hop_Gate>
  /** fetch data from the table: "device_client_data" */
  device_client_data: Array<Device_Client_Data>
  /** fetch aggregated fields from the table: "device_client_data" */
  device_client_data_aggregate: Device_Client_Data_Aggregate
  /** fetch data from the table: "device_client_data" using primary key columns */
  device_client_data_by_pk?: Maybe<Device_Client_Data>
  /** fetch data from the table: "discovery_backlog" */
  discovery_backlog: Array<Discovery_Backlog>
  /** fetch aggregated fields from the table: "discovery_backlog" */
  discovery_backlog_aggregate: Discovery_Backlog_Aggregate
  /** fetch data from the table: "discovery_backlog" using primary key columns */
  discovery_backlog_by_pk?: Maybe<Discovery_Backlog>
  /** fetch data from the table: "discovery_feed" */
  discovery_feed: Array<Discovery_Feed>
  /** fetch aggregated fields from the table: "discovery_feed" */
  discovery_feed_aggregate: Discovery_Feed_Aggregate
  /** fetch data from the table: "discovery_feed" using primary key columns */
  discovery_feed_by_pk?: Maybe<Discovery_Feed>
  /** execute function "discovery_feed_random" which returns "discovery_feed" */
  discovery_feed_random: Array<Discovery_Feed>
  /** execute function "discovery_feed_random" and query aggregates on result of table type "discovery_feed" */
  discovery_feed_random_aggregate: Discovery_Feed_Aggregate
  /** fetch data from the table: "discovery_staging" */
  discovery_staging: Array<Discovery_Staging>
  /** fetch aggregated fields from the table: "discovery_staging" */
  discovery_staging_aggregate: Discovery_Staging_Aggregate
  /** fetch data from the table: "discovery_staging" using primary key columns */
  discovery_staging_by_pk?: Maybe<Discovery_Staging>
  /** fetch data from the table: "featured_shop" */
  featured_shop: Array<Featured_Shop>
  /** fetch aggregated fields from the table: "featured_shop" */
  featured_shop_aggregate: Featured_Shop_Aggregate
  /** fetch data from the table: "featured_shop" using primary key columns */
  featured_shop_by_pk?: Maybe<Featured_Shop>
  /** fetch data from the table: "featured_shop_item" */
  featured_shop_item: Array<Featured_Shop_Item>
  /** fetch aggregated fields from the table: "featured_shop_item" */
  featured_shop_item_aggregate: Featured_Shop_Item_Aggregate
  /** fetch data from the table: "featured_shop_item" using primary key columns */
  featured_shop_item_by_pk?: Maybe<Featured_Shop_Item>
  /** execute function "featured_shops_for_onboarding" which returns "shop" */
  featured_shops_for_onboarding: Array<Shop>
  /** execute function "featured_shops_for_onboarding" and query aggregates on result of table type "shop" */
  featured_shops_for_onboarding_aggregate: Shop_Aggregate
  /** fetch data from the table: "feed_item" */
  feed_item: Array<Feed_Item>
  /** fetch aggregated fields from the table: "feed_item" */
  feed_item_aggregate: Feed_Item_Aggregate
  /** fetch data from the table: "feed_item" using primary key columns */
  feed_item_by_pk?: Maybe<Feed_Item>
  /** fetch data from the table: "feed_item_like" */
  feed_item_like: Array<Feed_Item_Like>
  /** fetch aggregated fields from the table: "feed_item_like" */
  feed_item_like_aggregate: Feed_Item_Like_Aggregate
  /** fetch data from the table: "feed_item_like" using primary key columns */
  feed_item_like_by_pk?: Maybe<Feed_Item_Like>
  /** execute function "feed_item_like_random" which returns "feed_item_like" */
  feed_item_like_random: Array<Feed_Item_Like>
  /** execute function "feed_item_like_random" and query aggregates on result of table type "feed_item_like" */
  feed_item_like_random_aggregate: Feed_Item_Like_Aggregate
  /** fetch data from the table: "feed_item_type" */
  feed_item_type: Array<Feed_Item_Type>
  /** fetch aggregated fields from the table: "feed_item_type" */
  feed_item_type_aggregate: Feed_Item_Type_Aggregate
  /** fetch data from the table: "feed_item_type" using primary key columns */
  feed_item_type_by_pk?: Maybe<Feed_Item_Type>
  /** execute function "feed_items_for_style_accounts" which returns "feed_item" */
  feed_items_for_style_accounts: Array<Feed_Item>
  /** execute function "feed_items_for_style_accounts" and query aggregates on result of table type "feed_item" */
  feed_items_for_style_accounts_aggregate: Feed_Item_Aggregate
  /** execute function "feed_items_for_user" which returns "feed_item" */
  feed_items_for_user: Array<Feed_Item>
  /** execute function "feed_items_for_user" and query aggregates on result of table type "feed_item" */
  feed_items_for_user_aggregate: Feed_Item_Aggregate
  /** execute function "feed_items_for_user_subscriptions" which returns "feed_item" */
  feed_items_for_user_subscriptions: Array<Feed_Item>
  /** execute function "feed_items_for_user_subscriptions" and query aggregates on result of table type "feed_item" */
  feed_items_for_user_subscriptions_aggregate: Feed_Item_Aggregate
  /** fetch data from the table: "fmtc_deal" */
  fmtc_deal: Array<Fmtc_Deal>
  /** fetch aggregated fields from the table: "fmtc_deal" */
  fmtc_deal_aggregate: Fmtc_Deal_Aggregate
  /** fetch data from the table: "fmtc_deal" using primary key columns */
  fmtc_deal_by_pk?: Maybe<Fmtc_Deal>
  /** fetch data from the table: "followable_item" */
  followable_item: Array<Followable_Item>
  /** fetch aggregated fields from the table: "followable_item" */
  followable_item_aggregate: Followable_Item_Aggregate
  /** fetch data from the table: "followable_item" using primary key columns */
  followable_item_by_pk?: Maybe<Followable_Item>
  /** fetch data from the table: "followable_item_type" */
  followable_item_type: Array<Followable_Item_Type>
  /** fetch aggregated fields from the table: "followable_item_type" */
  followable_item_type_aggregate: Followable_Item_Type_Aggregate
  /** fetch data from the table: "followable_item_type" using primary key columns */
  followable_item_type_by_pk?: Maybe<Followable_Item_Type>
  /** fetch data from the table: "leaderboard_event" */
  leaderboard_event: Array<Leaderboard_Event>
  /** fetch aggregated fields from the table: "leaderboard_event" */
  leaderboard_event_aggregate: Leaderboard_Event_Aggregate
  /** fetch data from the table: "leaderboard_event" using primary key columns */
  leaderboard_event_by_pk?: Maybe<Leaderboard_Event>
  /** fetch data from the table: "leaderboard_event_name" */
  leaderboard_event_name: Array<Leaderboard_Event_Name>
  /** fetch aggregated fields from the table: "leaderboard_event_name" */
  leaderboard_event_name_aggregate: Leaderboard_Event_Name_Aggregate
  /** fetch data from the table: "leaderboard_event_name" using primary key columns */
  leaderboard_event_name_by_pk?: Maybe<Leaderboard_Event_Name>
  /** fetch data from the table: "link_type" */
  link_type: Array<Link_Type>
  /** fetch aggregated fields from the table: "link_type" */
  link_type_aggregate: Link_Type_Aggregate
  /** fetch data from the table: "link_type" using primary key columns */
  link_type_by_pk?: Maybe<Link_Type>
  /** fetch data from the table: "media_enabled_item" */
  media_enabled_item: Array<Media_Enabled_Item>
  /** fetch aggregated fields from the table: "media_enabled_item" */
  media_enabled_item_aggregate: Media_Enabled_Item_Aggregate
  /** fetch data from the table: "media_enabled_item" using primary key columns */
  media_enabled_item_by_pk?: Maybe<Media_Enabled_Item>
  /** fetch data from the table: "media_item" */
  media_item: Array<Media_Item>
  /** fetch aggregated fields from the table: "media_item" */
  media_item_aggregate: Media_Item_Aggregate
  /** fetch data from the table: "media_item" using primary key columns */
  media_item_by_pk?: Maybe<Media_Item>
  /** fetch data from the table: "media_item_type" */
  media_item_type: Array<Media_Item_Type>
  /** fetch aggregated fields from the table: "media_item_type" */
  media_item_type_aggregate: Media_Item_Type_Aggregate
  /** fetch data from the table: "media_item_type" using primary key columns */
  media_item_type_by_pk?: Maybe<Media_Item_Type>
  /** An array relationship */
  media_items: Array<Media_Items>
  /** An aggregate relationship */
  media_items_aggregate: Media_Items_Aggregate
  /** fetch data from the table: "media_items" using primary key columns */
  media_items_by_pk?: Maybe<Media_Items>
  /** fetch data from the table: "media_type" */
  media_type: Array<Media_Type>
  /** fetch aggregated fields from the table: "media_type" */
  media_type_aggregate: Media_Type_Aggregate
  /** fetch data from the table: "media_type" using primary key columns */
  media_type_by_pk?: Maybe<Media_Type>
  /** execute function "mention_search_users" which returns "user" */
  mention_search_users: Array<User>
  /** execute function "mention_search_users" and query aggregates on result of table type "user" */
  mention_search_users_aggregate: User_Aggregate
  /** fetch data from the table: "mobile_app_home" */
  mobile_app_home: Array<Mobile_App_Home>
  /** fetch aggregated fields from the table: "mobile_app_home" */
  mobile_app_home_aggregate: Mobile_App_Home_Aggregate
  /** fetch data from the table: "mobile_app_home" using primary key columns */
  mobile_app_home_by_pk?: Maybe<Mobile_App_Home>
  /** fetch data from the table: "mobile_home" */
  mobile_home: Array<Mobile_Home>
  /** fetch aggregated fields from the table: "mobile_home" */
  mobile_home_aggregate: Mobile_Home_Aggregate
  /** fetch data from the table: "mobile_home" using primary key columns */
  mobile_home_by_pk?: Maybe<Mobile_Home>
  /** fetch data from the table: "mobile_push_notification" */
  mobile_push_notification: Array<Mobile_Push_Notification>
  /** fetch aggregated fields from the table: "mobile_push_notification" */
  mobile_push_notification_aggregate: Mobile_Push_Notification_Aggregate
  /** fetch data from the table: "mobile_push_notification" using primary key columns */
  mobile_push_notification_by_pk?: Maybe<Mobile_Push_Notification>
  /** fetch data from the table: "mobile_push_notification_status" */
  mobile_push_notification_status: Array<Mobile_Push_Notification_Status>
  /** fetch aggregated fields from the table: "mobile_push_notification_status" */
  mobile_push_notification_status_aggregate: Mobile_Push_Notification_Status_Aggregate
  /** fetch data from the table: "mobile_push_notification_status" using primary key columns */
  mobile_push_notification_status_by_pk?: Maybe<Mobile_Push_Notification_Status>
  /** fetch data from the table: "notifiable_feed_item_user" */
  notifiable_feed_item_user: Array<Notifiable_Feed_Item_User>
  /** fetch aggregated fields from the table: "notifiable_feed_item_user" */
  notifiable_feed_item_user_aggregate: Notifiable_Feed_Item_User_Aggregate
  /** fetch data from the table: "notifiable_item" */
  notifiable_item: Array<Notifiable_Item>
  /** fetch aggregated fields from the table: "notifiable_item" */
  notifiable_item_aggregate: Notifiable_Item_Aggregate
  /** fetch data from the table: "notifiable_item" using primary key columns */
  notifiable_item_by_pk?: Maybe<Notifiable_Item>
  /** fetch data from the table: "notifiable_item_type" */
  notifiable_item_type: Array<Notifiable_Item_Type>
  /** fetch aggregated fields from the table: "notifiable_item_type" */
  notifiable_item_type_aggregate: Notifiable_Item_Type_Aggregate
  /** fetch data from the table: "notifiable_item_type" using primary key columns */
  notifiable_item_type_by_pk?: Maybe<Notifiable_Item_Type>
  /** fetch data from the table: "notification" */
  notification: Array<Notification>
  /** A wrapper for getting notification center item data so that we can template-ize the notification copy */
  notificationCenterItems: Array<WrappedNotificationCenterItem>
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>
  /** execute function "notification_center_feed_for_user" which returns "notification_center_item" */
  notification_center_feed_for_user: Array<Notification_Center_Item>
  /** execute function "notification_center_feed_for_user" and query aggregates on result of table type "notification_center_item" */
  notification_center_feed_for_user_aggregate: Notification_Center_Item_Aggregate
  /** fetch data from the table: "notification_center_item" */
  notification_center_item: Array<Notification_Center_Item>
  /** fetch aggregated fields from the table: "notification_center_item" */
  notification_center_item_aggregate: Notification_Center_Item_Aggregate
  /** fetch data from the table: "notification_center_item" using primary key columns */
  notification_center_item_by_pk?: Maybe<Notification_Center_Item>
  /** fetch data from the table: "notification_center_item_read_status" */
  notification_center_item_read_status: Array<Notification_Center_Item_Read_Status>
  /** fetch aggregated fields from the table: "notification_center_item_read_status" */
  notification_center_item_read_status_aggregate: Notification_Center_Item_Read_Status_Aggregate
  /** fetch data from the table: "notification_center_item_read_status" using primary key columns */
  notification_center_item_read_status_by_pk?: Maybe<Notification_Center_Item_Read_Status>
  /** fetch data from the table: "notification_center_item_type" */
  notification_center_item_type: Array<Notification_Center_Item_Type>
  /** fetch aggregated fields from the table: "notification_center_item_type" */
  notification_center_item_type_aggregate: Notification_Center_Item_Type_Aggregate
  /** fetch data from the table: "notification_center_item_type" using primary key columns */
  notification_center_item_type_by_pk?: Maybe<Notification_Center_Item_Type>
  /** fetch data from the table: "notification_preference" */
  notification_preference: Array<Notification_Preference>
  /** fetch aggregated fields from the table: "notification_preference" */
  notification_preference_aggregate: Notification_Preference_Aggregate
  /** fetch data from the table: "notification_preference" using primary key columns */
  notification_preference_by_pk?: Maybe<Notification_Preference>
  /** An array relationship */
  notification_status: Array<Notification_Status>
  /** An aggregate relationship */
  notification_status_aggregate: Notification_Status_Aggregate
  /** fetch data from the table: "notification_status" using primary key columns */
  notification_status_by_pk?: Maybe<Notification_Status>
  /** fetch data from the table: "onboarding_categories" */
  onboarding_categories: Array<Onboarding_Categories>
  /** fetch aggregated fields from the table: "onboarding_categories" */
  onboarding_categories_aggregate: Onboarding_Categories_Aggregate
  /** fetch data from the table: "onboarding_categories" using primary key columns */
  onboarding_categories_by_pk?: Maybe<Onboarding_Categories>
  /** fetch data from the table: "onboarding_sources" */
  onboarding_sources: Array<Onboarding_Sources>
  /** fetch aggregated fields from the table: "onboarding_sources" */
  onboarding_sources_aggregate: Onboarding_Sources_Aggregate
  /** fetch data from the table: "onboarding_sources" using primary key columns */
  onboarding_sources_by_pk?: Maybe<Onboarding_Sources>
  /** fetch data from the table: "one_off_email" */
  one_off_email: Array<One_Off_Email>
  /** fetch aggregated fields from the table: "one_off_email" */
  one_off_email_aggregate: One_Off_Email_Aggregate
  /** fetch data from the table: "one_off_email" using primary key columns */
  one_off_email_by_pk?: Maybe<One_Off_Email>
  /** execute function "other_shops_for_shop_page" which returns "shop" */
  other_shops_for_shop_page: Array<Shop>
  /** execute function "other_shops_for_shop_page" and query aggregates on result of table type "shop" */
  other_shops_for_shop_page_aggregate: Shop_Aggregate
  /** fetch data from the table: "pageview_event" */
  pageview_event: Array<Pageview_Event>
  /** fetch aggregated fields from the table: "pageview_event" */
  pageview_event_aggregate: Pageview_Event_Aggregate
  /** fetch data from the table: "pageview_event" using primary key columns */
  pageview_event_by_pk?: Maybe<Pageview_Event>
  /** fetch data from the table: "pageview_event_summary" */
  pageview_event_summary: Array<Pageview_Event_Summary>
  /** fetch aggregated fields from the table: "pageview_event_summary" */
  pageview_event_summary_aggregate: Pageview_Event_Summary_Aggregate
  /** fetch data from the table: "promo_period" */
  promo_period: Array<Promo_Period>
  /** fetch aggregated fields from the table: "promo_period" */
  promo_period_aggregate: Promo_Period_Aggregate
  /** fetch data from the table: "promo_period" using primary key columns */
  promo_period_by_pk?: Maybe<Promo_Period>
  /** fetch data from the table: "push_notification" */
  push_notification: Array<Push_Notification>
  /** fetch aggregated fields from the table: "push_notification" */
  push_notification_aggregate: Push_Notification_Aggregate
  /** fetch data from the table: "push_notification" using primary key columns */
  push_notification_by_pk?: Maybe<Push_Notification>
  /** An array relationship */
  push_notification_status: Array<Push_Notification_Status>
  /** An aggregate relationship */
  push_notification_status_aggregate: Push_Notification_Status_Aggregate
  /** fetch data from the table: "push_notification_status" using primary key columns */
  push_notification_status_by_pk?: Maybe<Push_Notification_Status>
  /** execute function "random_discovery_feed_items" which returns "discovery_feed" */
  random_discovery_feed_items: Array<Discovery_Feed>
  /** execute function "random_discovery_feed_items" and query aggregates on result of table type "discovery_feed" */
  random_discovery_feed_items_aggregate: Discovery_Feed_Aggregate
  /** execute function "recommended_shops_by_user" which returns "shop" */
  recommended_shops_by_user: Array<Shop>
  /** execute function "recommended_shops_by_user" and query aggregates on result of table type "shop" */
  recommended_shops_by_user_aggregate: Shop_Aggregate
  /** fetch data from the table: "redirect_ids" */
  redirect_ids: Array<Redirect_Ids>
  /** fetch aggregated fields from the table: "redirect_ids" */
  redirect_ids_aggregate: Redirect_Ids_Aggregate
  /** fetch data from the table: "redirect_ids" using primary key columns */
  redirect_ids_by_pk?: Maybe<Redirect_Ids>
  /** execute function "related_shops_by_category" which returns "shop" */
  related_shops_by_category: Array<Shop>
  /** execute function "related_shops_by_category" and query aggregates on result of table type "shop" */
  related_shops_by_category_aggregate: Shop_Aggregate
  /** execute function "related_users_for_shop_item" which returns "user" */
  related_users_for_shop_item: Array<User>
  /** execute function "related_users_for_shop_item" and query aggregates on result of table type "user" */
  related_users_for_shop_item_aggregate: User_Aggregate
  /** fetch data from the table: "remove_reason" */
  remove_reason: Array<Remove_Reason>
  /** fetch aggregated fields from the table: "remove_reason" */
  remove_reason_aggregate: Remove_Reason_Aggregate
  /** fetch data from the table: "remove_reason" using primary key columns */
  remove_reason_by_pk?: Maybe<Remove_Reason>
  /** fetch data from the table: "rewardable_action_names" */
  rewardable_action_names: Array<Rewardable_Action_Names>
  /** fetch aggregated fields from the table: "rewardable_action_names" */
  rewardable_action_names_aggregate: Rewardable_Action_Names_Aggregate
  /** fetch data from the table: "rewardable_action_names" using primary key columns */
  rewardable_action_names_by_pk?: Maybe<Rewardable_Action_Names>
  /** fetch data from the table: "rewardable_item_type" */
  rewardable_item_type: Array<Rewardable_Item_Type>
  /** fetch aggregated fields from the table: "rewardable_item_type" */
  rewardable_item_type_aggregate: Rewardable_Item_Type_Aggregate
  /** fetch data from the table: "rewardable_item_type" using primary key columns */
  rewardable_item_type_by_pk?: Maybe<Rewardable_Item_Type>
  /** This is a query for searching products */
  searchProducts: SearchProductsOutput
  /** fetch data from the table: "search_query" */
  search_query: Array<Search_Query>
  /** fetch aggregated fields from the table: "search_query" */
  search_query_aggregate: Search_Query_Aggregate
  /** fetch data from the table: "search_query" using primary key columns */
  search_query_by_pk?: Maybe<Search_Query>
  /** fetch data from the table: "search_query_result" */
  search_query_result: Array<Search_Query_Result>
  /** fetch aggregated fields from the table: "search_query_result" */
  search_query_result_aggregate: Search_Query_Result_Aggregate
  /** fetch data from the table: "search_query_result" using primary key columns */
  search_query_result_by_pk?: Maybe<Search_Query_Result>
  /** execute function "search_shop_items" which returns "shop_item" */
  search_shop_items: Array<Shop_Item>
  /** execute function "search_shop_items" and query aggregates on result of table type "shop_item" */
  search_shop_items_aggregate: Shop_Item_Aggregate
  /** execute function "search_shops" which returns "shop" */
  search_shops: Array<Shop>
  /** execute function "search_shops" and query aggregates on result of table type "shop" */
  search_shops_aggregate: Shop_Aggregate
  /** execute function "search_users" which returns "user" */
  search_users: Array<User>
  /** execute function "search_users" and query aggregates on result of table type "user" */
  search_users_aggregate: User_Aggregate
  /** fetch data from the table: "shop" */
  shop: Array<Shop>
  /** fetch data from the table: "shop_affiliate" */
  shop_affiliate: Array<Shop_Affiliate>
  /** fetch aggregated fields from the table: "shop_affiliate" */
  shop_affiliate_aggregate: Shop_Affiliate_Aggregate
  /** fetch data from the table: "shop_affiliate" using primary key columns */
  shop_affiliate_by_pk?: Maybe<Shop_Affiliate>
  /** fetch aggregated fields from the table: "shop" */
  shop_aggregate: Shop_Aggregate
  /** fetch data from the table: "shop" using primary key columns */
  shop_by_pk?: Maybe<Shop>
  /** fetch data from the table: "shop_categorization" */
  shop_categorization: Array<Shop_Categorization>
  /** fetch aggregated fields from the table: "shop_categorization" */
  shop_categorization_aggregate: Shop_Categorization_Aggregate
  /** fetch data from the table: "shop_categorization" using primary key columns */
  shop_categorization_by_pk?: Maybe<Shop_Categorization>
  /** fetch data from the table: "shop_categorized_deals" */
  shop_categorized_deals: Array<Shop_Categorized_Deals>
  /** fetch aggregated fields from the table: "shop_categorized_deals" */
  shop_categorized_deals_aggregate: Shop_Categorized_Deals_Aggregate
  /** fetch data from the table: "shop_category" */
  shop_category: Array<Shop_Category>
  /** fetch aggregated fields from the table: "shop_category" */
  shop_category_aggregate: Shop_Category_Aggregate
  /** fetch data from the table: "shop_category" using primary key columns */
  shop_category_by_pk?: Maybe<Shop_Category>
  /** execute function "shop_collections" which returns "collection" */
  shop_collections: Array<Collection>
  /** execute function "shop_collections" and query aggregates on result of table type "collection" */
  shop_collections_aggregate: Collection_Aggregate
  /** fetch data from the table: "shop_item" */
  shop_item: Array<Shop_Item>
  /** fetch aggregated fields from the table: "shop_item" */
  shop_item_aggregate: Shop_Item_Aggregate
  /** fetch data from the table: "shop_item_availability" */
  shop_item_availability: Array<Shop_Item_Availability>
  /** fetch aggregated fields from the table: "shop_item_availability" */
  shop_item_availability_aggregate: Shop_Item_Availability_Aggregate
  /** fetch data from the table: "shop_item_availability" using primary key columns */
  shop_item_availability_by_pk?: Maybe<Shop_Item_Availability>
  /** fetch data from the table: "shop_item" using primary key columns */
  shop_item_by_pk?: Maybe<Shop_Item>
  /** fetch data from the table: "shop_item_enrichment_status" */
  shop_item_enrichment_status: Array<Shop_Item_Enrichment_Status>
  /** fetch aggregated fields from the table: "shop_item_enrichment_status" */
  shop_item_enrichment_status_aggregate: Shop_Item_Enrichment_Status_Aggregate
  /** fetch data from the table: "shop_item_enrichment_status" using primary key columns */
  shop_item_enrichment_status_by_pk?: Maybe<Shop_Item_Enrichment_Status>
  /** fetch data from the table: "shop_item_history" */
  shop_item_history: Array<Shop_Item_History>
  /** fetch aggregated fields from the table: "shop_item_history" */
  shop_item_history_aggregate: Shop_Item_History_Aggregate
  /** fetch data from the table: "shop_item_history" using primary key columns */
  shop_item_history_by_pk?: Maybe<Shop_Item_History>
  /** fetch data from the table: "shop_item_image" */
  shop_item_image: Array<Shop_Item_Image>
  /** fetch aggregated fields from the table: "shop_item_image" */
  shop_item_image_aggregate: Shop_Item_Image_Aggregate
  /** fetch data from the table: "shop_item_image" using primary key columns */
  shop_item_image_by_pk?: Maybe<Shop_Item_Image>
  /** fetch data from the table: "shop_item_index" */
  shop_item_index: Array<Shop_Item_Index>
  /** fetch aggregated fields from the table: "shop_item_index" */
  shop_item_index_aggregate: Shop_Item_Index_Aggregate
  /** fetch data from the table: "shop_item_index" using primary key columns */
  shop_item_index_by_pk?: Maybe<Shop_Item_Index>
  /** fetch data from the table: "shop_item_to_be_scraped" */
  shop_item_to_be_scraped: Array<Shop_Item_To_Be_Scraped>
  /** fetch aggregated fields from the table: "shop_item_to_be_scraped" */
  shop_item_to_be_scraped_aggregate: Shop_Item_To_Be_Scraped_Aggregate
  /** fetch data from the table: "shop_popularity" */
  shop_popularity: Array<Shop_Popularity>
  /** fetch aggregated fields from the table: "shop_popularity" */
  shop_popularity_aggregate: Shop_Popularity_Aggregate
  /** fetch data from the table: "shop_popularity_with_points" */
  shop_popularity_with_points: Array<Shop_Popularity_With_Points>
  /** fetch aggregated fields from the table: "shop_popularity_with_points" */
  shop_popularity_with_points_aggregate: Shop_Popularity_With_Points_Aggregate
  /** fetch data from the table: "shop_rating" */
  shop_rating: Array<Shop_Rating>
  /** fetch aggregated fields from the table: "shop_rating" */
  shop_rating_aggregate: Shop_Rating_Aggregate
  /** fetch data from the table: "shop_rating" using primary key columns */
  shop_rating_by_pk?: Maybe<Shop_Rating>
  /** Returns shopping products based on query */
  shoppingProducts: Array<ShoppingProductOutput>
  /** Returns similar products based on image URL */
  similarProducts: Array<SimilarProductOutput>
  /** fetch data from the table: "similar_products_history" */
  similar_products_history: Array<Similar_Products_History>
  /** fetch aggregated fields from the table: "similar_products_history" */
  similar_products_history_aggregate: Similar_Products_History_Aggregate
  /** fetch data from the table: "similar_products_history" using primary key columns */
  similar_products_history_by_pk?: Maybe<Similar_Products_History>
  /** execute function "similar_products_history_unique" which returns "similar_products_history" */
  similar_products_history_unique: Array<Similar_Products_History>
  /** execute function "similar_products_history_unique" and query aggregates on result of table type "similar_products_history" */
  similar_products_history_unique_aggregate: Similar_Products_History_Aggregate
  /** execute function "single_unread_notification_banner_item_for_user" which returns "notification_center_item" */
  single_unread_notification_banner_item_for_user?: Maybe<Notification_Center_Item>
  /** execute function "single_unread_notification_banner_item_for_user" and query aggregates on result of table type "notification_center_item" */
  single_unread_notification_banner_item_for_user_aggregate: Notification_Center_Item_Aggregate
  /** execute function "steal_their_looks" which returns "collection" */
  steal_their_looks: Array<Collection>
  /** execute function "steal_their_looks" and query aggregates on result of table type "collection" */
  steal_their_looks_aggregate: Collection_Aggregate
  /** execute function "steal_their_looks_random" which returns "collection" */
  steal_their_looks_random: Array<Collection>
  /** execute function "steal_their_looks_random" and query aggregates on result of table type "collection" */
  steal_their_looks_random_aggregate: Collection_Aggregate
  /** A query to be able to grab user's related to a user's phone contacts that are on Carrot as well as people that a user may know due to the information in the contacts */
  subscriptionSuggestions: Array<ContactSubscriptionSuggestion>
  /** execute function "used_creator_redirect" which returns "user" */
  used_creator_redirect: Array<User>
  /** execute function "used_creator_redirect" and query aggregates on result of table type "user" */
  used_creator_redirect_aggregate: User_Aggregate
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch data from the table: "user_account_type" */
  user_account_type: Array<User_Account_Type>
  /** fetch aggregated fields from the table: "user_account_type" */
  user_account_type_aggregate: User_Account_Type_Aggregate
  /** fetch data from the table: "user_account_type" using primary key columns */
  user_account_type_by_pk?: Maybe<User_Account_Type>
  /** fetch data from the table: "user_action" */
  user_action: Array<User_Action>
  /** fetch aggregated fields from the table: "user_action" */
  user_action_aggregate: User_Action_Aggregate
  /** fetch data from the table: "user_action" using primary key columns */
  user_action_by_pk?: Maybe<User_Action>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user_app_version" */
  user_app_version: Array<User_App_Version>
  /** fetch aggregated fields from the table: "user_app_version" */
  user_app_version_aggregate: User_App_Version_Aggregate
  /** fetch data from the table: "user_app_version" using primary key columns */
  user_app_version_by_pk?: Maybe<User_App_Version>
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table: "user_carts_onboarding" */
  user_carts_onboarding: Array<User_Carts_Onboarding>
  /** fetch aggregated fields from the table: "user_carts_onboarding" */
  user_carts_onboarding_aggregate: User_Carts_Onboarding_Aggregate
  /** fetch data from the table: "user_carts_onboarding" using primary key columns */
  user_carts_onboarding_by_pk?: Maybe<User_Carts_Onboarding>
  /** fetch data from the table: "user_category" */
  user_category: Array<User_Category>
  /** fetch aggregated fields from the table: "user_category" */
  user_category_aggregate: User_Category_Aggregate
  /** fetch data from the table: "user_category" using primary key columns */
  user_category_by_pk?: Maybe<User_Category>
  /** fetch data from the table: "user_deletion" */
  user_deletion: Array<User_Deletion>
  /** fetch aggregated fields from the table: "user_deletion" */
  user_deletion_aggregate: User_Deletion_Aggregate
  /** fetch data from the table: "user_deletion" using primary key columns */
  user_deletion_by_pk?: Maybe<User_Deletion>
  /** fetch data from the table: "user_feedback" */
  user_feedback: Array<User_Feedback>
  /** fetch aggregated fields from the table: "user_feedback" */
  user_feedback_aggregate: User_Feedback_Aggregate
  /** fetch data from the table: "user_feedback" using primary key columns */
  user_feedback_by_pk?: Maybe<User_Feedback>
  /** fetch data from the table: "user_feedback_item" */
  user_feedback_item: Array<User_Feedback_Item>
  /** fetch aggregated fields from the table: "user_feedback_item" */
  user_feedback_item_aggregate: User_Feedback_Item_Aggregate
  /** fetch data from the table: "user_feedback_item" using primary key columns */
  user_feedback_item_by_pk?: Maybe<User_Feedback_Item>
  /** fetch data from the table: "user_feedback_type" */
  user_feedback_type: Array<User_Feedback_Type>
  /** fetch aggregated fields from the table: "user_feedback_type" */
  user_feedback_type_aggregate: User_Feedback_Type_Aggregate
  /** fetch data from the table: "user_feedback_type" using primary key columns */
  user_feedback_type_by_pk?: Maybe<User_Feedback_Type>
  /** fetch data from the table: "user_flags" */
  user_flags: Array<User_Flags>
  /** fetch aggregated fields from the table: "user_flags" */
  user_flags_aggregate: User_Flags_Aggregate
  /** fetch data from the table: "user_flags" using primary key columns */
  user_flags_by_pk?: Maybe<User_Flags>
  /** fetch data from the table: "user_impersonation" */
  user_impersonation: Array<User_Impersonation>
  /** fetch aggregated fields from the table: "user_impersonation" */
  user_impersonation_aggregate: User_Impersonation_Aggregate
  /** fetch data from the table: "user_impersonation" using primary key columns */
  user_impersonation_by_pk?: Maybe<User_Impersonation>
  /** fetch data from the table: "user_ip_address" */
  user_ip_address: Array<User_Ip_Address>
  /** fetch aggregated fields from the table: "user_ip_address" */
  user_ip_address_aggregate: User_Ip_Address_Aggregate
  /** fetch data from the table: "user_ip_address" using primary key columns */
  user_ip_address_by_pk?: Maybe<User_Ip_Address>
  /** fetch data from the table: "user_items_followed" */
  user_items_followed: Array<User_Items_Followed>
  /** fetch aggregated fields from the table: "user_items_followed" */
  user_items_followed_aggregate: User_Items_Followed_Aggregate
  /** fetch data from the table: "user_items_followed" using primary key columns */
  user_items_followed_by_pk?: Maybe<User_Items_Followed>
  /** fetch data from the table: "user_links" */
  user_links: Array<User_Links>
  /** fetch aggregated fields from the table: "user_links" */
  user_links_aggregate: User_Links_Aggregate
  /** fetch data from the table: "user_links" using primary key columns */
  user_links_by_pk?: Maybe<User_Links>
  /** fetch data from the table: "user_onboarding_source" */
  user_onboarding_source: Array<User_Onboarding_Source>
  /** fetch aggregated fields from the table: "user_onboarding_source" */
  user_onboarding_source_aggregate: User_Onboarding_Source_Aggregate
  /** fetch data from the table: "user_onboarding_source" using primary key columns */
  user_onboarding_source_by_pk?: Maybe<User_Onboarding_Source>
  /** fetch data from the table: "user_onboarding_state" */
  user_onboarding_state: Array<User_Onboarding_State>
  /** fetch aggregated fields from the table: "user_onboarding_state" */
  user_onboarding_state_aggregate: User_Onboarding_State_Aggregate
  /** fetch data from the table: "user_onboarding_state" using primary key columns */
  user_onboarding_state_by_pk?: Maybe<User_Onboarding_State>
  /** fetch data from the table: "user_phone_contact" */
  user_phone_contact: Array<User_Phone_Contact>
  /** fetch aggregated fields from the table: "user_phone_contact" */
  user_phone_contact_aggregate: User_Phone_Contact_Aggregate
  /** fetch data from the table: "user_phone_contact" using primary key columns */
  user_phone_contact_by_pk?: Maybe<User_Phone_Contact>
  /** fetch data from the table: "user_points_redeemed_summary" */
  user_points_redeemed_summary: Array<User_Points_Redeemed_Summary>
  /** fetch aggregated fields from the table: "user_points_redeemed_summary" */
  user_points_redeemed_summary_aggregate: User_Points_Redeemed_Summary_Aggregate
  /** fetch data from the table: "user_points_summary" */
  user_points_summary: Array<User_Points_Summary>
  /** fetch aggregated fields from the table: "user_points_summary" */
  user_points_summary_aggregate: User_Points_Summary_Aggregate
  /** fetch data from the table: "user_purchase_subscription" */
  user_purchase_subscription: Array<User_Purchase_Subscription>
  /** fetch aggregated fields from the table: "user_purchase_subscription" */
  user_purchase_subscription_aggregate: User_Purchase_Subscription_Aggregate
  /** fetch data from the table: "user_purchase_subscription" using primary key columns */
  user_purchase_subscription_by_pk?: Maybe<User_Purchase_Subscription>
  /** fetch data from the table: "user_purchases" */
  user_purchases: Array<User_Purchases>
  /** fetch aggregated fields from the table: "user_purchases" */
  user_purchases_aggregate: User_Purchases_Aggregate
  /** fetch data from the table: "user_purchases" using primary key columns */
  user_purchases_by_pk?: Maybe<User_Purchases>
  /** fetch data from the table: "user_push_notification" */
  user_push_notification: Array<User_Push_Notification>
  /** fetch aggregated fields from the table: "user_push_notification" */
  user_push_notification_aggregate: User_Push_Notification_Aggregate
  /** fetch data from the table: "user_push_notification_token" */
  user_push_notification_token: Array<User_Push_Notification_Token>
  /** fetch aggregated fields from the table: "user_push_notification_token" */
  user_push_notification_token_aggregate: User_Push_Notification_Token_Aggregate
  /** fetch data from the table: "user_push_notification_token" using primary key columns */
  user_push_notification_token_by_pk?: Maybe<User_Push_Notification_Token>
  /** fetch data from the table: "user_referral" */
  user_referral: Array<User_Referral>
  /** fetch aggregated fields from the table: "user_referral" */
  user_referral_aggregate: User_Referral_Aggregate
  /** fetch data from the table: "user_referral" using primary key columns */
  user_referral_by_pk?: Maybe<User_Referral>
  /** fetch data from the table: "user_reward_multiplier_config" */
  user_reward_multiplier_config: Array<User_Reward_Multiplier_Config>
  /** fetch aggregated fields from the table: "user_reward_multiplier_config" */
  user_reward_multiplier_config_aggregate: User_Reward_Multiplier_Config_Aggregate
  /** fetch data from the table: "user_reward_multiplier_config" using primary key columns */
  user_reward_multiplier_config_by_pk?: Maybe<User_Reward_Multiplier_Config>
  /** fetch data from the table: "user_reward_redemption" */
  user_reward_redemption: Array<User_Reward_Redemption>
  /** fetch aggregated fields from the table: "user_reward_redemption" */
  user_reward_redemption_aggregate: User_Reward_Redemption_Aggregate
  /** fetch data from the table: "user_reward_redemption" using primary key columns */
  user_reward_redemption_by_pk?: Maybe<User_Reward_Redemption>
  /** fetch data from the table: "user_reward_reference" */
  user_reward_reference: Array<User_Reward_Reference>
  /** fetch aggregated fields from the table: "user_reward_reference" */
  user_reward_reference_aggregate: User_Reward_Reference_Aggregate
  /** fetch data from the table: "user_reward_reference" using primary key columns */
  user_reward_reference_by_pk?: Maybe<User_Reward_Reference>
  /** fetch data from the table: "user_shop_favorite" */
  user_shop_favorite: Array<User_Shop_Favorite>
  /** fetch aggregated fields from the table: "user_shop_favorite" */
  user_shop_favorite_aggregate: User_Shop_Favorite_Aggregate
  /** fetch data from the table: "user_shop_favorite" using primary key columns */
  user_shop_favorite_by_pk?: Maybe<User_Shop_Favorite>
  /** fetch data from the table: "user_shopping_preferences" */
  user_shopping_preferences: Array<User_Shopping_Preferences>
  /** fetch aggregated fields from the table: "user_shopping_preferences" */
  user_shopping_preferences_aggregate: User_Shopping_Preferences_Aggregate
  /** fetch data from the table: "user_shopping_preferences" using primary key columns */
  user_shopping_preferences_by_pk?: Maybe<User_Shopping_Preferences>
  /** fetch data from the table: "user_subscription" */
  user_subscription: Array<User_Subscription>
  /** fetch aggregated fields from the table: "user_subscription" */
  user_subscription_aggregate: User_Subscription_Aggregate
  /** fetch data from the table: "user_subscription" using primary key columns */
  user_subscription_by_pk?: Maybe<User_Subscription>
  /** fetch data from the table: "user_subscription_status" */
  user_subscription_status: Array<User_Subscription_Status>
  /** fetch aggregated fields from the table: "user_subscription_status" */
  user_subscription_status_aggregate: User_Subscription_Status_Aggregate
  /** fetch data from the table: "user_subscription_status" using primary key columns */
  user_subscription_status_by_pk?: Maybe<User_Subscription_Status>
  /** fetch data from the table: "user_temp" */
  user_temp: Array<User_Temp>
  /** fetch aggregated fields from the table: "user_temp" */
  user_temp_aggregate: User_Temp_Aggregate
  /** fetch data from the table: "user_temp" using primary key columns */
  user_temp_by_pk?: Maybe<User_Temp>
  /** execute function "users_search" which returns "user" */
  users_search: Array<User>
  /** execute function "users_search" and query aggregates on result of table type "user" */
  users_search_aggregate: User_Aggregate
  /** fetch data from the table: "worflow_type" */
  worflow_type: Array<Worflow_Type>
  /** fetch aggregated fields from the table: "worflow_type" */
  worflow_type_aggregate: Worflow_Type_Aggregate
  /** fetch data from the table: "worflow_type" using primary key columns */
  worflow_type_by_pk?: Maybe<Worflow_Type>
  /** fetch data from the table: "workflow" */
  workflow: Array<Workflow>
  /** fetch aggregated fields from the table: "workflow" */
  workflow_aggregate: Workflow_Aggregate
  /** fetch data from the table: "workflow" using primary key columns */
  workflow_by_pk?: Maybe<Workflow>
  /** fetch data from the table: "workflow_status" */
  workflow_status: Array<Workflow_Status>
  /** fetch aggregated fields from the table: "workflow_status" */
  workflow_status_aggregate: Workflow_Status_Aggregate
  /** fetch data from the table: "workflow_status" using primary key columns */
  workflow_status_by_pk?: Maybe<Workflow_Status>
}

export type Query_RootAccount_Deletion_ReasonArgs = {
  distinct_on?: Maybe<Array<Account_Deletion_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Account_Deletion_Reason_Order_By>>
  where?: Maybe<Account_Deletion_Reason_Bool_Exp>
}

export type Query_RootAccount_Deletion_Reason_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Deletion_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Account_Deletion_Reason_Order_By>>
  where?: Maybe<Account_Deletion_Reason_Bool_Exp>
}

export type Query_RootAccount_Deletion_Reason_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootAffiliateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Order_By>>
  where?: Maybe<Affiliate_Bool_Exp>
}

export type Query_RootAffiliate_Advertiser_IdArgs = {
  distinct_on?: Maybe<Array<Affiliate_Advertiser_Id_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Advertiser_Id_Order_By>>
  where?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
}

export type Query_RootAffiliate_Advertiser_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Advertiser_Id_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Advertiser_Id_Order_By>>
  where?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
}

export type Query_RootAffiliate_Advertiser_Id_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootAffiliate_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Order_By>>
  where?: Maybe<Affiliate_Bool_Exp>
}

export type Query_RootAffiliate_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootAffiliate_Commission_SummaryArgs = {
  distinct_on?: Maybe<Array<Affiliate_Commission_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Commission_Summary_Order_By>>
  where?: Maybe<Affiliate_Commission_Summary_Bool_Exp>
}

export type Query_RootAffiliate_Commission_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Commission_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Commission_Summary_Order_By>>
  where?: Maybe<Affiliate_Commission_Summary_Bool_Exp>
}

export type Query_RootAffiliate_MetaArgs = {
  distinct_on?: Maybe<Array<Affiliate_Meta_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Meta_Order_By>>
  where?: Maybe<Affiliate_Meta_Bool_Exp>
}

export type Query_RootAffiliate_Meta_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Meta_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Meta_Order_By>>
  where?: Maybe<Affiliate_Meta_Bool_Exp>
}

export type Query_RootAffiliate_Meta_By_PkArgs = {
  affiliate: Affiliate_Enum
}

export type Query_RootAffiliate_TransactionArgs = {
  distinct_on?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Transaction_Order_By>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

export type Query_RootAffiliate_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Transaction_Order_By>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

export type Query_RootAffiliate_Transaction_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootAll_Shops_For_Shop_PageArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootAll_Shops_For_Shop_Page_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootAmazon_CategoriesArgs = {
  distinct_on?: Maybe<Array<Amazon_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Amazon_Categories_Order_By>>
  where?: Maybe<Amazon_Categories_Bool_Exp>
}

export type Query_RootAmazon_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Amazon_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Amazon_Categories_Order_By>>
  where?: Maybe<Amazon_Categories_Bool_Exp>
}

export type Query_RootAvailableSubscriptionsArgs = {
  creatorId: Scalars['String']
}

export type Query_RootBilling_ModeArgs = {
  distinct_on?: Maybe<Array<Billing_Mode_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Mode_Order_By>>
  where?: Maybe<Billing_Mode_Bool_Exp>
}

export type Query_RootBilling_Mode_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Mode_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Mode_Order_By>>
  where?: Maybe<Billing_Mode_Bool_Exp>
}

export type Query_RootBilling_Mode_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootBilling_Platform_Connected_AccountArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Connected_Account_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Connected_Account_Order_By>>
  where?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
}

export type Query_RootBilling_Platform_Connected_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Connected_Account_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Connected_Account_Order_By>>
  where?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
}

export type Query_RootBilling_Platform_Connected_Account_By_PkArgs = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

export type Query_RootBilling_Platform_Connected_Account_StatusArgs = {
  distinct_on?: Maybe<
    Array<Billing_Platform_Connected_Account_Status_Select_Column>
  >
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Connected_Account_Status_Order_By>>
  where?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
}

export type Query_RootBilling_Platform_Connected_Account_Status_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Billing_Platform_Connected_Account_Status_Select_Column>
    >
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Billing_Platform_Connected_Account_Status_Order_By>>
    where?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
  }

export type Query_RootBilling_Platform_Connected_Account_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootBilling_Platform_CustomerArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Customer_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Customer_Order_By>>
  where?: Maybe<Billing_Platform_Customer_Bool_Exp>
}

export type Query_RootBilling_Platform_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Customer_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Customer_Order_By>>
  where?: Maybe<Billing_Platform_Customer_Bool_Exp>
}

export type Query_RootBilling_Platform_Customer_By_PkArgs = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

export type Query_RootCartArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

export type Query_RootCart_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

export type Query_RootCart_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCart_ItemArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

export type Query_RootCart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

export type Query_RootCart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCart_Item_LikeArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

export type Query_RootCart_Item_Like_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

export type Query_RootCart_Item_Like_By_PkArgs = {
  cart_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Query_RootCart_Item_NoteArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Note_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Note_Order_By>>
  where?: Maybe<Cart_Item_Note_Bool_Exp>
}

export type Query_RootCart_Item_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Note_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Note_Order_By>>
  where?: Maybe<Cart_Item_Note_Bool_Exp>
}

export type Query_RootCart_Item_Note_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Categories_Order_By>>
  where?: Maybe<Categories_Bool_Exp>
}

export type Query_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Categories_Order_By>>
  where?: Maybe<Categories_Bool_Exp>
}

export type Query_RootCategories_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCategory_Shop_ItemArgs = {
  distinct_on?: Maybe<Array<Category_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Category_Shop_Item_Order_By>>
  where?: Maybe<Category_Shop_Item_Bool_Exp>
}

export type Query_RootCategory_Shop_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Category_Shop_Item_Order_By>>
  where?: Maybe<Category_Shop_Item_Bool_Exp>
}

export type Query_RootCategory_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCheckoutArgs = {
  distinct_on?: Maybe<Array<Checkout_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Checkout_Order_By>>
  where?: Maybe<Checkout_Bool_Exp>
}

export type Query_RootCheckout_AggregateArgs = {
  distinct_on?: Maybe<Array<Checkout_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Checkout_Order_By>>
  where?: Maybe<Checkout_Bool_Exp>
}

export type Query_RootCheckout_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollectionArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootCollection_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootCollection_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_Cart_ItemArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

export type Query_RootCollection_Cart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

export type Query_RootCollection_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_CollaboratorArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

export type Query_RootCollection_Collaborator_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

export type Query_RootCollection_Collaborator_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_InviteArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Order_By>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

export type Query_RootCollection_Invite_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Order_By>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

export type Query_RootCollection_Invite_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_Invite_StatusArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Status_Order_By>>
  where?: Maybe<Collection_Invite_Status_Bool_Exp>
}

export type Query_RootCollection_Invite_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Status_Order_By>>
  where?: Maybe<Collection_Invite_Status_Bool_Exp>
}

export type Query_RootCollection_Invite_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCollection_Item_ClaimArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Order_By>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

export type Query_RootCollection_Item_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Order_By>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

export type Query_RootCollection_Item_Claim_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_Item_Claim_StatusArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Status_Order_By>>
  where?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
}

export type Query_RootCollection_Item_Claim_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Status_Order_By>>
  where?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
}

export type Query_RootCollection_Item_Claim_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCollection_RandomArgs = {
  args?: Maybe<Collection_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootCollection_Random_AggregateArgs = {
  args?: Maybe<Collection_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootCollection_SectionArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Order_By>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

export type Query_RootCollection_Section_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Order_By>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

export type Query_RootCollection_Section_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_Section_Cart_ItemArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Cart_Item_Order_By>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

export type Query_RootCollection_Section_Cart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Cart_Item_Order_By>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

export type Query_RootCollection_Section_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_Slug_HistoryArgs = {
  distinct_on?: Maybe<Array<Collection_Slug_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Slug_History_Order_By>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

export type Query_RootCollection_Slug_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Slug_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Slug_History_Order_By>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

export type Query_RootCollection_Slug_History_By_PkArgs = {
  collection_id: Scalars['uuid']
  slug: Scalars['name']
}

export type Query_RootCollection_StateArgs = {
  distinct_on?: Maybe<Array<Collection_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_State_Order_By>>
  where?: Maybe<Collection_State_Bool_Exp>
}

export type Query_RootCollection_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_State_Order_By>>
  where?: Maybe<Collection_State_Bool_Exp>
}

export type Query_RootCollection_State_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCollection_TypeArgs = {
  distinct_on?: Maybe<Array<Collection_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Type_Order_By>>
  where?: Maybe<Collection_Type_Bool_Exp>
}

export type Query_RootCollection_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Type_Order_By>>
  where?: Maybe<Collection_Type_Bool_Exp>
}

export type Query_RootCollection_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCommentArgs = {
  distinct_on?: Maybe<Array<Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Comment_Order_By>>
  where?: Maybe<Comment_Bool_Exp>
}

export type Query_RootComment_AggregateArgs = {
  distinct_on?: Maybe<Array<Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Comment_Order_By>>
  where?: Maybe<Comment_Bool_Exp>
}

export type Query_RootComment_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCommentable_ItemArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Order_By>>
  where?: Maybe<Commentable_Item_Bool_Exp>
}

export type Query_RootCommentable_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Order_By>>
  where?: Maybe<Commentable_Item_Bool_Exp>
}

export type Query_RootCommentable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Commentable_Item_Type_Enum
}

export type Query_RootCommentable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Type_Order_By>>
  where?: Maybe<Commentable_Item_Type_Bool_Exp>
}

export type Query_RootCommentable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Type_Order_By>>
  where?: Maybe<Commentable_Item_Type_Bool_Exp>
}

export type Query_RootCommentable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCommunication_TemplateArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Order_By>>
  where?: Maybe<Communication_Template_Bool_Exp>
}

export type Query_RootCommunication_Template_AggregateArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Order_By>>
  where?: Maybe<Communication_Template_Bool_Exp>
}

export type Query_RootCommunication_Template_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCommunication_Template_Full_ListArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Full_List_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Full_List_Order_By>>
  where?: Maybe<Communication_Template_Full_List_Bool_Exp>
}

export type Query_RootCommunication_Template_Full_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Full_List_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Full_List_Order_By>>
  where?: Maybe<Communication_Template_Full_List_Bool_Exp>
}

export type Query_RootCommunication_Template_Full_List_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootContactConnectionsArgs = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}

export type Query_RootContact_Info_TypeArgs = {
  distinct_on?: Maybe<Array<Contact_Info_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Info_Type_Order_By>>
  where?: Maybe<Contact_Info_Type_Bool_Exp>
}

export type Query_RootContact_Info_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Info_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Info_Type_Order_By>>
  where?: Maybe<Contact_Info_Type_Bool_Exp>
}

export type Query_RootContact_Info_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootContact_InviteArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Order_By>>
  where?: Maybe<Contact_Invite_Bool_Exp>
}

export type Query_RootContact_Invite_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Order_By>>
  where?: Maybe<Contact_Invite_Bool_Exp>
}

export type Query_RootContact_Invite_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootContact_Invite_StatusArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Status_Order_By>>
  where?: Maybe<Contact_Invite_Status_Bool_Exp>
}

export type Query_RootContact_Invite_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Status_Order_By>>
  where?: Maybe<Contact_Invite_Status_Bool_Exp>
}

export type Query_RootContact_Invite_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootContact_Invite_TypeArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Type_Order_By>>
  where?: Maybe<Contact_Invite_Type_Bool_Exp>
}

export type Query_RootContact_Invite_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Type_Order_By>>
  where?: Maybe<Contact_Invite_Type_Bool_Exp>
}

export type Query_RootContact_Invite_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCreatorSubscriberChartDataArgs = {
  creator_id?: Maybe<Scalars['String']>
  end_date?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['String']>
}

export type Query_RootCreator_Subscribers_RandomArgs = {
  args?: Maybe<Creator_Subscribers_Random_Args>
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootCreator_Subscribers_Random_AggregateArgs = {
  args?: Maybe<Creator_Subscribers_Random_Args>
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootCreator_User_SubscriptionsArgs = {
  distinct_on?: Maybe<Array<Creator_User_Subscriptions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Creator_User_Subscriptions_Order_By>>
  where?: Maybe<Creator_User_Subscriptions_Bool_Exp>
}

export type Query_RootCreator_User_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Creator_User_Subscriptions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Creator_User_Subscriptions_Order_By>>
  where?: Maybe<Creator_User_Subscriptions_Bool_Exp>
}

export type Query_RootDeal_Hop_GateArgs = {
  distinct_on?: Maybe<Array<Deal_Hop_Gate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Deal_Hop_Gate_Order_By>>
  where?: Maybe<Deal_Hop_Gate_Bool_Exp>
}

export type Query_RootDeal_Hop_Gate_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Hop_Gate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Deal_Hop_Gate_Order_By>>
  where?: Maybe<Deal_Hop_Gate_Bool_Exp>
}

export type Query_RootDeal_Hop_Gate_By_PkArgs = {
  user_id: Scalars['String']
}

export type Query_RootDevice_Client_DataArgs = {
  distinct_on?: Maybe<Array<Device_Client_Data_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Device_Client_Data_Order_By>>
  where?: Maybe<Device_Client_Data_Bool_Exp>
}

export type Query_RootDevice_Client_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Device_Client_Data_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Device_Client_Data_Order_By>>
  where?: Maybe<Device_Client_Data_Bool_Exp>
}

export type Query_RootDevice_Client_Data_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootDiscovery_BacklogArgs = {
  distinct_on?: Maybe<Array<Discovery_Backlog_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Backlog_Order_By>>
  where?: Maybe<Discovery_Backlog_Bool_Exp>
}

export type Query_RootDiscovery_Backlog_AggregateArgs = {
  distinct_on?: Maybe<Array<Discovery_Backlog_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Backlog_Order_By>>
  where?: Maybe<Discovery_Backlog_Bool_Exp>
}

export type Query_RootDiscovery_Backlog_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootDiscovery_FeedArgs = {
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Query_RootDiscovery_Feed_AggregateArgs = {
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Query_RootDiscovery_Feed_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootDiscovery_Feed_RandomArgs = {
  args?: Maybe<Discovery_Feed_Random_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Query_RootDiscovery_Feed_Random_AggregateArgs = {
  args?: Maybe<Discovery_Feed_Random_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Query_RootDiscovery_StagingArgs = {
  distinct_on?: Maybe<Array<Discovery_Staging_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Staging_Order_By>>
  where?: Maybe<Discovery_Staging_Bool_Exp>
}

export type Query_RootDiscovery_Staging_AggregateArgs = {
  distinct_on?: Maybe<Array<Discovery_Staging_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Staging_Order_By>>
  where?: Maybe<Discovery_Staging_Bool_Exp>
}

export type Query_RootDiscovery_Staging_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFeatured_ShopArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Order_By>>
  where?: Maybe<Featured_Shop_Bool_Exp>
}

export type Query_RootFeatured_Shop_AggregateArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Order_By>>
  where?: Maybe<Featured_Shop_Bool_Exp>
}

export type Query_RootFeatured_Shop_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFeatured_Shop_ItemArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Item_Order_By>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

export type Query_RootFeatured_Shop_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Item_Order_By>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

export type Query_RootFeatured_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFeatured_Shops_For_OnboardingArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootFeatured_Shops_For_Onboarding_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootFeed_ItemArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFeed_Item_LikeArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Query_RootFeed_Item_Like_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Query_RootFeed_Item_Like_By_PkArgs = {
  feed_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Query_RootFeed_Item_Like_RandomArgs = {
  args?: Maybe<Feed_Item_Like_Random_Args>
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Query_RootFeed_Item_Like_Random_AggregateArgs = {
  args?: Maybe<Feed_Item_Like_Random_Args>
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Query_RootFeed_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Type_Order_By>>
  where?: Maybe<Feed_Item_Type_Bool_Exp>
}

export type Query_RootFeed_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Type_Order_By>>
  where?: Maybe<Feed_Item_Type_Bool_Exp>
}

export type Query_RootFeed_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootFeed_Items_For_Style_AccountsArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Items_For_Style_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Items_For_UserArgs = {
  args: Feed_Items_For_User_Args
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Items_For_User_AggregateArgs = {
  args: Feed_Items_For_User_Args
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Items_For_User_SubscriptionsArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Items_For_User_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFmtc_DealArgs = {
  distinct_on?: Maybe<Array<Fmtc_Deal_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Fmtc_Deal_Order_By>>
  where?: Maybe<Fmtc_Deal_Bool_Exp>
}

export type Query_RootFmtc_Deal_AggregateArgs = {
  distinct_on?: Maybe<Array<Fmtc_Deal_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Fmtc_Deal_Order_By>>
  where?: Maybe<Fmtc_Deal_Bool_Exp>
}

export type Query_RootFmtc_Deal_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFollowable_ItemArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Order_By>>
  where?: Maybe<Followable_Item_Bool_Exp>
}

export type Query_RootFollowable_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Order_By>>
  where?: Maybe<Followable_Item_Bool_Exp>
}

export type Query_RootFollowable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Followable_Item_Type_Enum
}

export type Query_RootFollowable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Type_Order_By>>
  where?: Maybe<Followable_Item_Type_Bool_Exp>
}

export type Query_RootFollowable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Type_Order_By>>
  where?: Maybe<Followable_Item_Type_Bool_Exp>
}

export type Query_RootFollowable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootLeaderboard_EventArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Order_By>>
  where?: Maybe<Leaderboard_Event_Bool_Exp>
}

export type Query_RootLeaderboard_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Order_By>>
  where?: Maybe<Leaderboard_Event_Bool_Exp>
}

export type Query_RootLeaderboard_Event_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLeaderboard_Event_NameArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Name_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Name_Order_By>>
  where?: Maybe<Leaderboard_Event_Name_Bool_Exp>
}

export type Query_RootLeaderboard_Event_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Name_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Name_Order_By>>
  where?: Maybe<Leaderboard_Event_Name_Bool_Exp>
}

export type Query_RootLeaderboard_Event_Name_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootLink_TypeArgs = {
  distinct_on?: Maybe<Array<Link_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Link_Type_Order_By>>
  where?: Maybe<Link_Type_Bool_Exp>
}

export type Query_RootLink_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Link_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Link_Type_Order_By>>
  where?: Maybe<Link_Type_Bool_Exp>
}

export type Query_RootLink_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootMedia_Enabled_ItemArgs = {
  distinct_on?: Maybe<Array<Media_Enabled_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Enabled_Item_Order_By>>
  where?: Maybe<Media_Enabled_Item_Bool_Exp>
}

export type Query_RootMedia_Enabled_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Enabled_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Enabled_Item_Order_By>>
  where?: Maybe<Media_Enabled_Item_Bool_Exp>
}

export type Query_RootMedia_Enabled_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Media_Item_Type_Enum
}

export type Query_RootMedia_ItemArgs = {
  distinct_on?: Maybe<Array<Media_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Order_By>>
  where?: Maybe<Media_Item_Bool_Exp>
}

export type Query_RootMedia_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Order_By>>
  where?: Maybe<Media_Item_Bool_Exp>
}

export type Query_RootMedia_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMedia_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Media_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Type_Order_By>>
  where?: Maybe<Media_Item_Type_Bool_Exp>
}

export type Query_RootMedia_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Type_Order_By>>
  where?: Maybe<Media_Item_Type_Bool_Exp>
}

export type Query_RootMedia_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootMedia_ItemsArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

export type Query_RootMedia_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

export type Query_RootMedia_Items_By_PkArgs = {
  media_enabled_item_id: Scalars['uuid']
  media_enabled_item_type: Scalars['String']
  media_id: Scalars['uuid']
}

export type Query_RootMedia_TypeArgs = {
  distinct_on?: Maybe<Array<Media_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Type_Order_By>>
  where?: Maybe<Media_Type_Bool_Exp>
}

export type Query_RootMedia_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Type_Order_By>>
  where?: Maybe<Media_Type_Bool_Exp>
}

export type Query_RootMedia_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootMention_Search_UsersArgs = {
  args: Mention_Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootMention_Search_Users_AggregateArgs = {
  args: Mention_Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootMobile_App_HomeArgs = {
  distinct_on?: Maybe<Array<Mobile_App_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_App_Home_Order_By>>
  where?: Maybe<Mobile_App_Home_Bool_Exp>
}

export type Query_RootMobile_App_Home_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_App_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_App_Home_Order_By>>
  where?: Maybe<Mobile_App_Home_Bool_Exp>
}

export type Query_RootMobile_App_Home_By_PkArgs = {
  id: Scalars['Int']
}

export type Query_RootMobile_HomeArgs = {
  distinct_on?: Maybe<Array<Mobile_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Home_Order_By>>
  where?: Maybe<Mobile_Home_Bool_Exp>
}

export type Query_RootMobile_Home_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Home_Order_By>>
  where?: Maybe<Mobile_Home_Bool_Exp>
}

export type Query_RootMobile_Home_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMobile_Push_NotificationArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Bool_Exp>
}

export type Query_RootMobile_Push_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Bool_Exp>
}

export type Query_RootMobile_Push_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMobile_Push_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Status_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
}

export type Query_RootMobile_Push_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Status_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
}

export type Query_RootMobile_Push_Notification_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootNotifiable_Feed_Item_UserArgs = {
  distinct_on?: Maybe<Array<Notifiable_Feed_Item_User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Feed_Item_User_Order_By>>
  where?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
}

export type Query_RootNotifiable_Feed_Item_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifiable_Feed_Item_User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Feed_Item_User_Order_By>>
  where?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
}

export type Query_RootNotifiable_ItemArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Order_By>>
  where?: Maybe<Notifiable_Item_Bool_Exp>
}

export type Query_RootNotifiable_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Order_By>>
  where?: Maybe<Notifiable_Item_Bool_Exp>
}

export type Query_RootNotifiable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Notifiable_Item_Type_Enum
}

export type Query_RootNotifiable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Type_Order_By>>
  where?: Maybe<Notifiable_Item_Type_Bool_Exp>
}

export type Query_RootNotifiable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Type_Order_By>>
  where?: Maybe<Notifiable_Item_Type_Bool_Exp>
}

export type Query_RootNotifiable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootNotificationArgs = {
  distinct_on?: Maybe<Array<Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Order_By>>
  where?: Maybe<Notification_Bool_Exp>
}

export type Query_RootNotificationCenterItemsArgs = {
  cursor?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  read?: Maybe<Scalars['Boolean']>
  tasks?: Maybe<Scalars['Boolean']>
}

export type Query_RootNotification_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Order_By>>
  where?: Maybe<Notification_Bool_Exp>
}

export type Query_RootNotification_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootNotification_Center_Feed_For_UserArgs = {
  args: Notification_Center_Feed_For_User_Args
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Query_RootNotification_Center_Feed_For_User_AggregateArgs = {
  args: Notification_Center_Feed_For_User_Args
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Query_RootNotification_Center_ItemArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Query_RootNotification_Center_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Query_RootNotification_Center_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootNotification_Center_Item_Read_StatusArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Read_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Read_Status_Order_By>>
  where?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
}

export type Query_RootNotification_Center_Item_Read_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Read_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Read_Status_Order_By>>
  where?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
}

export type Query_RootNotification_Center_Item_Read_Status_By_PkArgs = {
  notification_center_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Query_RootNotification_Center_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Type_Order_By>>
  where?: Maybe<Notification_Center_Item_Type_Bool_Exp>
}

export type Query_RootNotification_Center_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Type_Order_By>>
  where?: Maybe<Notification_Center_Item_Type_Bool_Exp>
}

export type Query_RootNotification_Center_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootNotification_PreferenceArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

export type Query_RootNotification_Preference_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

export type Query_RootNotification_Preference_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootNotification_StatusArgs = {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Status_Order_By>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

export type Query_RootNotification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Status_Order_By>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

export type Query_RootNotification_Status_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootOnboarding_CategoriesArgs = {
  distinct_on?: Maybe<Array<Onboarding_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Categories_Order_By>>
  where?: Maybe<Onboarding_Categories_Bool_Exp>
}

export type Query_RootOnboarding_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Onboarding_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Categories_Order_By>>
  where?: Maybe<Onboarding_Categories_Bool_Exp>
}

export type Query_RootOnboarding_Categories_By_PkArgs = {
  category: Shop_Category_Enum
}

export type Query_RootOnboarding_SourcesArgs = {
  distinct_on?: Maybe<Array<Onboarding_Sources_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Sources_Order_By>>
  where?: Maybe<Onboarding_Sources_Bool_Exp>
}

export type Query_RootOnboarding_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Onboarding_Sources_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Sources_Order_By>>
  where?: Maybe<Onboarding_Sources_Bool_Exp>
}

export type Query_RootOnboarding_Sources_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootOne_Off_EmailArgs = {
  distinct_on?: Maybe<Array<One_Off_Email_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<One_Off_Email_Order_By>>
  where?: Maybe<One_Off_Email_Bool_Exp>
}

export type Query_RootOne_Off_Email_AggregateArgs = {
  distinct_on?: Maybe<Array<One_Off_Email_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<One_Off_Email_Order_By>>
  where?: Maybe<One_Off_Email_Bool_Exp>
}

export type Query_RootOne_Off_Email_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootOther_Shops_For_Shop_PageArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootOther_Shops_For_Shop_Page_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootPageview_EventArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Order_By>>
  where?: Maybe<Pageview_Event_Bool_Exp>
}

export type Query_RootPageview_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Order_By>>
  where?: Maybe<Pageview_Event_Bool_Exp>
}

export type Query_RootPageview_Event_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPageview_Event_SummaryArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Summary_Order_By>>
  where?: Maybe<Pageview_Event_Summary_Bool_Exp>
}

export type Query_RootPageview_Event_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Summary_Order_By>>
  where?: Maybe<Pageview_Event_Summary_Bool_Exp>
}

export type Query_RootPromo_PeriodArgs = {
  distinct_on?: Maybe<Array<Promo_Period_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Promo_Period_Order_By>>
  where?: Maybe<Promo_Period_Bool_Exp>
}

export type Query_RootPromo_Period_AggregateArgs = {
  distinct_on?: Maybe<Array<Promo_Period_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Promo_Period_Order_By>>
  where?: Maybe<Promo_Period_Bool_Exp>
}

export type Query_RootPromo_Period_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPush_NotificationArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Order_By>>
  where?: Maybe<Push_Notification_Bool_Exp>
}

export type Query_RootPush_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Order_By>>
  where?: Maybe<Push_Notification_Bool_Exp>
}

export type Query_RootPush_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPush_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

export type Query_RootPush_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

export type Query_RootPush_Notification_Status_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRandom_Discovery_Feed_ItemsArgs = {
  args?: Maybe<Random_Discovery_Feed_Items_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Query_RootRandom_Discovery_Feed_Items_AggregateArgs = {
  args?: Maybe<Random_Discovery_Feed_Items_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Query_RootRecommended_Shops_By_UserArgs = {
  args: Recommended_Shops_By_User_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootRecommended_Shops_By_User_AggregateArgs = {
  args: Recommended_Shops_By_User_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootRedirect_IdsArgs = {
  distinct_on?: Maybe<Array<Redirect_Ids_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Redirect_Ids_Order_By>>
  where?: Maybe<Redirect_Ids_Bool_Exp>
}

export type Query_RootRedirect_Ids_AggregateArgs = {
  distinct_on?: Maybe<Array<Redirect_Ids_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Redirect_Ids_Order_By>>
  where?: Maybe<Redirect_Ids_Bool_Exp>
}

export type Query_RootRedirect_Ids_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRelated_Shops_By_CategoryArgs = {
  args: Related_Shops_By_Category_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootRelated_Shops_By_Category_AggregateArgs = {
  args: Related_Shops_By_Category_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootRelated_Users_For_Shop_ItemArgs = {
  args: Related_Users_For_Shop_Item_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootRelated_Users_For_Shop_Item_AggregateArgs = {
  args: Related_Users_For_Shop_Item_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootRemove_ReasonArgs = {
  distinct_on?: Maybe<Array<Remove_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Remove_Reason_Order_By>>
  where?: Maybe<Remove_Reason_Bool_Exp>
}

export type Query_RootRemove_Reason_AggregateArgs = {
  distinct_on?: Maybe<Array<Remove_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Remove_Reason_Order_By>>
  where?: Maybe<Remove_Reason_Bool_Exp>
}

export type Query_RootRemove_Reason_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootRewardable_Action_NamesArgs = {
  distinct_on?: Maybe<Array<Rewardable_Action_Names_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Action_Names_Order_By>>
  where?: Maybe<Rewardable_Action_Names_Bool_Exp>
}

export type Query_RootRewardable_Action_Names_AggregateArgs = {
  distinct_on?: Maybe<Array<Rewardable_Action_Names_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Action_Names_Order_By>>
  where?: Maybe<Rewardable_Action_Names_Bool_Exp>
}

export type Query_RootRewardable_Action_Names_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootRewardable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Rewardable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Item_Type_Order_By>>
  where?: Maybe<Rewardable_Item_Type_Bool_Exp>
}

export type Query_RootRewardable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Rewardable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Item_Type_Order_By>>
  where?: Maybe<Rewardable_Item_Type_Bool_Exp>
}

export type Query_RootRewardable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootSearchProductsArgs = {
  pagination?: Maybe<Scalars['String']>
  query: Scalars['String']
}

export type Query_RootSearch_QueryArgs = {
  distinct_on?: Maybe<Array<Search_Query_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Order_By>>
  where?: Maybe<Search_Query_Bool_Exp>
}

export type Query_RootSearch_Query_AggregateArgs = {
  distinct_on?: Maybe<Array<Search_Query_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Order_By>>
  where?: Maybe<Search_Query_Bool_Exp>
}

export type Query_RootSearch_Query_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootSearch_Query_ResultArgs = {
  distinct_on?: Maybe<Array<Search_Query_Result_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Result_Order_By>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

export type Query_RootSearch_Query_Result_AggregateArgs = {
  distinct_on?: Maybe<Array<Search_Query_Result_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Result_Order_By>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

export type Query_RootSearch_Query_Result_By_PkArgs = {
  search_query_id: Scalars['uuid']
  shop_item_id: Scalars['uuid']
}

export type Query_RootSearch_Shop_ItemsArgs = {
  args: Search_Shop_Items_Args
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Query_RootSearch_Shop_Items_AggregateArgs = {
  args: Search_Shop_Items_Args
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Query_RootSearch_ShopsArgs = {
  args: Search_Shops_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootSearch_Shops_AggregateArgs = {
  args: Search_Shops_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootSearch_UsersArgs = {
  args: Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootShopArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootShop_AffiliateArgs = {
  distinct_on?: Maybe<Array<Shop_Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Affiliate_Order_By>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

export type Query_RootShop_Affiliate_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Affiliate_Order_By>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

export type Query_RootShop_Affiliate_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShop_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootShop_By_PkArgs = {
  key: Scalars['String']
}

export type Query_RootShop_CategorizationArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

export type Query_RootShop_Categorization_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

export type Query_RootShop_Categorization_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShop_Categorized_DealsArgs = {
  distinct_on?: Maybe<Array<Shop_Categorized_Deals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorized_Deals_Order_By>>
  where?: Maybe<Shop_Categorized_Deals_Bool_Exp>
}

export type Query_RootShop_Categorized_Deals_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Categorized_Deals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorized_Deals_Order_By>>
  where?: Maybe<Shop_Categorized_Deals_Bool_Exp>
}

export type Query_RootShop_CategoryArgs = {
  distinct_on?: Maybe<Array<Shop_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Category_Order_By>>
  where?: Maybe<Shop_Category_Bool_Exp>
}

export type Query_RootShop_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Category_Order_By>>
  where?: Maybe<Shop_Category_Bool_Exp>
}

export type Query_RootShop_Category_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootShop_CollectionsArgs = {
  args: Shop_Collections_Args
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootShop_Collections_AggregateArgs = {
  args: Shop_Collections_Args
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootShop_ItemArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Query_RootShop_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Query_RootShop_Item_AvailabilityArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Availability_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Availability_Order_By>>
  where?: Maybe<Shop_Item_Availability_Bool_Exp>
}

export type Query_RootShop_Item_Availability_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Availability_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Availability_Order_By>>
  where?: Maybe<Shop_Item_Availability_Bool_Exp>
}

export type Query_RootShop_Item_Availability_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootShop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShop_Item_Enrichment_StatusArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Enrichment_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Enrichment_Status_Order_By>>
  where?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
}

export type Query_RootShop_Item_Enrichment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Enrichment_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Enrichment_Status_Order_By>>
  where?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
}

export type Query_RootShop_Item_Enrichment_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootShop_Item_HistoryArgs = {
  distinct_on?: Maybe<Array<Shop_Item_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_History_Order_By>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

export type Query_RootShop_Item_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_History_Order_By>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

export type Query_RootShop_Item_History_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShop_Item_ImageArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Image_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Image_Order_By>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

export type Query_RootShop_Item_Image_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Image_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Image_Order_By>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

export type Query_RootShop_Item_Image_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShop_Item_IndexArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Index_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Index_Order_By>>
  where?: Maybe<Shop_Item_Index_Bool_Exp>
}

export type Query_RootShop_Item_Index_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Index_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Index_Order_By>>
  where?: Maybe<Shop_Item_Index_Bool_Exp>
}

export type Query_RootShop_Item_Index_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShop_Item_To_Be_ScrapedArgs = {
  distinct_on?: Maybe<Array<Shop_Item_To_Be_Scraped_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_To_Be_Scraped_Order_By>>
  where?: Maybe<Shop_Item_To_Be_Scraped_Bool_Exp>
}

export type Query_RootShop_Item_To_Be_Scraped_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_To_Be_Scraped_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_To_Be_Scraped_Order_By>>
  where?: Maybe<Shop_Item_To_Be_Scraped_Bool_Exp>
}

export type Query_RootShop_PopularityArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_Order_By>>
  where?: Maybe<Shop_Popularity_Bool_Exp>
}

export type Query_RootShop_Popularity_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_Order_By>>
  where?: Maybe<Shop_Popularity_Bool_Exp>
}

export type Query_RootShop_Popularity_With_PointsArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_With_Points_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_With_Points_Order_By>>
  where?: Maybe<Shop_Popularity_With_Points_Bool_Exp>
}

export type Query_RootShop_Popularity_With_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_With_Points_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_With_Points_Order_By>>
  where?: Maybe<Shop_Popularity_With_Points_Bool_Exp>
}

export type Query_RootShop_RatingArgs = {
  distinct_on?: Maybe<Array<Shop_Rating_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Rating_Order_By>>
  where?: Maybe<Shop_Rating_Bool_Exp>
}

export type Query_RootShop_Rating_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Rating_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Rating_Order_By>>
  where?: Maybe<Shop_Rating_Bool_Exp>
}

export type Query_RootShop_Rating_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShoppingProductsArgs = {
  blue?: Maybe<Scalars['Int']>
  green?: Maybe<Scalars['Int']>
  location?: Maybe<Scalars['String']>
  query: Scalars['String']
  red?: Maybe<Scalars['Int']>
}

export type Query_RootSimilarProductsArgs = {
  allResults?: Maybe<Scalars['Boolean']>
  cartItemId?: Maybe<Scalars['uuid']>
  imageUrl: Scalars['String']
  noCache?: Maybe<Scalars['Boolean']>
  shopItemId?: Maybe<Scalars['uuid']>
}

export type Query_RootSimilar_Products_HistoryArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Query_RootSimilar_Products_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Query_RootSimilar_Products_History_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootSimilar_Products_History_UniqueArgs = {
  args: Similar_Products_History_Unique_Args
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Query_RootSimilar_Products_History_Unique_AggregateArgs = {
  args: Similar_Products_History_Unique_Args
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Query_RootSingle_Unread_Notification_Banner_Item_For_UserArgs = {
  args: Single_Unread_Notification_Banner_Item_For_User_Args
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Query_RootSingle_Unread_Notification_Banner_Item_For_User_AggregateArgs =
  {
    args: Single_Unread_Notification_Banner_Item_For_User_Args
    distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
    where?: Maybe<Notification_Center_Item_Bool_Exp>
  }

export type Query_RootSteal_Their_LooksArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootSteal_Their_Looks_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootSteal_Their_Looks_RandomArgs = {
  args?: Maybe<Steal_Their_Looks_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootSteal_Their_Looks_Random_AggregateArgs = {
  args?: Maybe<Steal_Their_Looks_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootSubscriptionSuggestionsArgs = {
  degreesOfSeparation?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}

export type Query_RootUsed_Creator_RedirectArgs = {
  args: Used_Creator_Redirect_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootUsed_Creator_Redirect_AggregateArgs = {
  args: Used_Creator_Redirect_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootUser_Account_TypeArgs = {
  distinct_on?: Maybe<Array<User_Account_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Account_Type_Order_By>>
  where?: Maybe<User_Account_Type_Bool_Exp>
}

export type Query_RootUser_Account_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Account_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Account_Type_Order_By>>
  where?: Maybe<User_Account_Type_Bool_Exp>
}

export type Query_RootUser_Account_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootUser_ActionArgs = {
  distinct_on?: Maybe<Array<User_Action_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Action_Order_By>>
  where?: Maybe<User_Action_Bool_Exp>
}

export type Query_RootUser_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Action_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Action_Order_By>>
  where?: Maybe<User_Action_Bool_Exp>
}

export type Query_RootUser_Action_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootUser_App_VersionArgs = {
  distinct_on?: Maybe<Array<User_App_Version_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_App_Version_Order_By>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

export type Query_RootUser_App_Version_AggregateArgs = {
  distinct_on?: Maybe<Array<User_App_Version_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_App_Version_Order_By>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

export type Query_RootUser_App_Version_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_By_PkArgs = {
  id: Scalars['String']
}

export type Query_RootUser_Carts_OnboardingArgs = {
  distinct_on?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Carts_Onboarding_Order_By>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

export type Query_RootUser_Carts_Onboarding_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Carts_Onboarding_Order_By>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

export type Query_RootUser_Carts_Onboarding_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_CategoryArgs = {
  distinct_on?: Maybe<Array<User_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Category_Order_By>>
  where?: Maybe<User_Category_Bool_Exp>
}

export type Query_RootUser_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Category_Order_By>>
  where?: Maybe<User_Category_Bool_Exp>
}

export type Query_RootUser_Category_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_DeletionArgs = {
  distinct_on?: Maybe<Array<User_Deletion_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Deletion_Order_By>>
  where?: Maybe<User_Deletion_Bool_Exp>
}

export type Query_RootUser_Deletion_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Deletion_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Deletion_Order_By>>
  where?: Maybe<User_Deletion_Bool_Exp>
}

export type Query_RootUser_Deletion_By_PkArgs = {
  user_id: Scalars['String']
}

export type Query_RootUser_FeedbackArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Order_By>>
  where?: Maybe<User_Feedback_Bool_Exp>
}

export type Query_RootUser_Feedback_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Order_By>>
  where?: Maybe<User_Feedback_Bool_Exp>
}

export type Query_RootUser_Feedback_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Feedback_ItemArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Item_Order_By>>
  where?: Maybe<User_Feedback_Item_Bool_Exp>
}

export type Query_RootUser_Feedback_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Item_Order_By>>
  where?: Maybe<User_Feedback_Item_Bool_Exp>
}

export type Query_RootUser_Feedback_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: User_Feedback_Type_Enum
}

export type Query_RootUser_Feedback_TypeArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Type_Order_By>>
  where?: Maybe<User_Feedback_Type_Bool_Exp>
}

export type Query_RootUser_Feedback_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Type_Order_By>>
  where?: Maybe<User_Feedback_Type_Bool_Exp>
}

export type Query_RootUser_Feedback_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootUser_FlagsArgs = {
  distinct_on?: Maybe<Array<User_Flags_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Flags_Order_By>>
  where?: Maybe<User_Flags_Bool_Exp>
}

export type Query_RootUser_Flags_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Flags_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Flags_Order_By>>
  where?: Maybe<User_Flags_Bool_Exp>
}

export type Query_RootUser_Flags_By_PkArgs = {
  user_id: Scalars['String']
}

export type Query_RootUser_ImpersonationArgs = {
  distinct_on?: Maybe<Array<User_Impersonation_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Impersonation_Order_By>>
  where?: Maybe<User_Impersonation_Bool_Exp>
}

export type Query_RootUser_Impersonation_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Impersonation_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Impersonation_Order_By>>
  where?: Maybe<User_Impersonation_Bool_Exp>
}

export type Query_RootUser_Impersonation_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Ip_AddressArgs = {
  distinct_on?: Maybe<Array<User_Ip_Address_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Ip_Address_Order_By>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

export type Query_RootUser_Ip_Address_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Ip_Address_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Ip_Address_Order_By>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

export type Query_RootUser_Ip_Address_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Items_FollowedArgs = {
  distinct_on?: Maybe<Array<User_Items_Followed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Items_Followed_Order_By>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

export type Query_RootUser_Items_Followed_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Items_Followed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Items_Followed_Order_By>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

export type Query_RootUser_Items_Followed_By_PkArgs = {
  followable_item_id: Scalars['uuid']
  followable_item_type: Scalars['String']
  user_id: Scalars['String']
}

export type Query_RootUser_LinksArgs = {
  distinct_on?: Maybe<Array<User_Links_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Links_Order_By>>
  where?: Maybe<User_Links_Bool_Exp>
}

export type Query_RootUser_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Links_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Links_Order_By>>
  where?: Maybe<User_Links_Bool_Exp>
}

export type Query_RootUser_Links_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Onboarding_SourceArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_Source_Order_By>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

export type Query_RootUser_Onboarding_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_Source_Order_By>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

export type Query_RootUser_Onboarding_Source_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Onboarding_StateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_State_Order_By>>
  where?: Maybe<User_Onboarding_State_Bool_Exp>
}

export type Query_RootUser_Onboarding_State_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_State_Order_By>>
  where?: Maybe<User_Onboarding_State_Bool_Exp>
}

export type Query_RootUser_Onboarding_State_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootUser_Phone_ContactArgs = {
  distinct_on?: Maybe<Array<User_Phone_Contact_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Phone_Contact_Order_By>>
  where?: Maybe<User_Phone_Contact_Bool_Exp>
}

export type Query_RootUser_Phone_Contact_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Phone_Contact_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Phone_Contact_Order_By>>
  where?: Maybe<User_Phone_Contact_Bool_Exp>
}

export type Query_RootUser_Phone_Contact_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Points_Redeemed_SummaryArgs = {
  distinct_on?: Maybe<Array<User_Points_Redeemed_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Redeemed_Summary_Order_By>>
  where?: Maybe<User_Points_Redeemed_Summary_Bool_Exp>
}

export type Query_RootUser_Points_Redeemed_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Points_Redeemed_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Redeemed_Summary_Order_By>>
  where?: Maybe<User_Points_Redeemed_Summary_Bool_Exp>
}

export type Query_RootUser_Points_SummaryArgs = {
  distinct_on?: Maybe<Array<User_Points_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Summary_Order_By>>
  where?: Maybe<User_Points_Summary_Bool_Exp>
}

export type Query_RootUser_Points_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Points_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Summary_Order_By>>
  where?: Maybe<User_Points_Summary_Bool_Exp>
}

export type Query_RootUser_Purchase_SubscriptionArgs = {
  distinct_on?: Maybe<Array<User_Purchase_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchase_Subscription_Order_By>>
  where?: Maybe<User_Purchase_Subscription_Bool_Exp>
}

export type Query_RootUser_Purchase_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Purchase_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchase_Subscription_Order_By>>
  where?: Maybe<User_Purchase_Subscription_Bool_Exp>
}

export type Query_RootUser_Purchase_Subscription_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_PurchasesArgs = {
  distinct_on?: Maybe<Array<User_Purchases_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchases_Order_By>>
  where?: Maybe<User_Purchases_Bool_Exp>
}

export type Query_RootUser_Purchases_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Purchases_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchases_Order_By>>
  where?: Maybe<User_Purchases_Bool_Exp>
}

export type Query_RootUser_Purchases_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Push_NotificationArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Order_By>>
  where?: Maybe<User_Push_Notification_Bool_Exp>
}

export type Query_RootUser_Push_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Order_By>>
  where?: Maybe<User_Push_Notification_Bool_Exp>
}

export type Query_RootUser_Push_Notification_TokenArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Token_Order_By>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

export type Query_RootUser_Push_Notification_Token_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Token_Order_By>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

export type Query_RootUser_Push_Notification_Token_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_ReferralArgs = {
  distinct_on?: Maybe<Array<User_Referral_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Referral_Order_By>>
  where?: Maybe<User_Referral_Bool_Exp>
}

export type Query_RootUser_Referral_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Referral_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Referral_Order_By>>
  where?: Maybe<User_Referral_Bool_Exp>
}

export type Query_RootUser_Referral_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Reward_Multiplier_ConfigArgs = {
  distinct_on?: Maybe<Array<User_Reward_Multiplier_Config_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Multiplier_Config_Order_By>>
  where?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
}

export type Query_RootUser_Reward_Multiplier_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Multiplier_Config_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Multiplier_Config_Order_By>>
  where?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
}

export type Query_RootUser_Reward_Multiplier_Config_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Reward_RedemptionArgs = {
  distinct_on?: Maybe<Array<User_Reward_Redemption_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Redemption_Order_By>>
  where?: Maybe<User_Reward_Redemption_Bool_Exp>
}

export type Query_RootUser_Reward_Redemption_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Redemption_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Redemption_Order_By>>
  where?: Maybe<User_Reward_Redemption_Bool_Exp>
}

export type Query_RootUser_Reward_Redemption_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Reward_ReferenceArgs = {
  distinct_on?: Maybe<Array<User_Reward_Reference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Reference_Order_By>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

export type Query_RootUser_Reward_Reference_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Reference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Reference_Order_By>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

export type Query_RootUser_Reward_Reference_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Shop_FavoriteArgs = {
  distinct_on?: Maybe<Array<User_Shop_Favorite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shop_Favorite_Order_By>>
  where?: Maybe<User_Shop_Favorite_Bool_Exp>
}

export type Query_RootUser_Shop_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Shop_Favorite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shop_Favorite_Order_By>>
  where?: Maybe<User_Shop_Favorite_Bool_Exp>
}

export type Query_RootUser_Shop_Favorite_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Shopping_PreferencesArgs = {
  distinct_on?: Maybe<Array<User_Shopping_Preferences_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shopping_Preferences_Order_By>>
  where?: Maybe<User_Shopping_Preferences_Bool_Exp>
}

export type Query_RootUser_Shopping_Preferences_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Shopping_Preferences_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shopping_Preferences_Order_By>>
  where?: Maybe<User_Shopping_Preferences_Bool_Exp>
}

export type Query_RootUser_Shopping_Preferences_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootUser_SubscriptionArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Order_By>>
  where?: Maybe<User_Subscription_Bool_Exp>
}

export type Query_RootUser_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Order_By>>
  where?: Maybe<User_Subscription_Bool_Exp>
}

export type Query_RootUser_Subscription_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Subscription_StatusArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Status_Order_By>>
  where?: Maybe<User_Subscription_Status_Bool_Exp>
}

export type Query_RootUser_Subscription_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Status_Order_By>>
  where?: Maybe<User_Subscription_Status_Bool_Exp>
}

export type Query_RootUser_Subscription_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootUser_TempArgs = {
  distinct_on?: Maybe<Array<User_Temp_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Temp_Order_By>>
  where?: Maybe<User_Temp_Bool_Exp>
}

export type Query_RootUser_Temp_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Temp_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Temp_Order_By>>
  where?: Maybe<User_Temp_Bool_Exp>
}

export type Query_RootUser_Temp_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUsers_SearchArgs = {
  args: Users_Search_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootUsers_Search_AggregateArgs = {
  args: Users_Search_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootWorflow_TypeArgs = {
  distinct_on?: Maybe<Array<Worflow_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Worflow_Type_Order_By>>
  where?: Maybe<Worflow_Type_Bool_Exp>
}

export type Query_RootWorflow_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Worflow_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Worflow_Type_Order_By>>
  where?: Maybe<Worflow_Type_Bool_Exp>
}

export type Query_RootWorflow_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootWorkflowArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

export type Query_RootWorkflow_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

export type Query_RootWorkflow_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootWorkflow_StatusArgs = {
  distinct_on?: Maybe<Array<Workflow_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Status_Order_By>>
  where?: Maybe<Workflow_Status_Bool_Exp>
}

export type Query_RootWorkflow_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Status_Order_By>>
  where?: Maybe<Workflow_Status_Bool_Exp>
}

export type Query_RootWorkflow_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Random_Discovery_Feed_Items_Args = {
  discover_feed_ids?: Maybe<Scalars['_uuid']>
  offset?: Maybe<Scalars['Int']>
  seed?: Maybe<Scalars['seed_float']>
}

export type Recommended_Shops_By_User_Args = {
  user_id?: Maybe<Scalars['String']>
}

/** This table stores the cid and uid of each redirect transaction */
export type Redirect_Ids = {
  __typename?: 'redirect_ids'
  cid: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "redirect_ids" */
export type Redirect_Ids_Aggregate = {
  __typename?: 'redirect_ids_aggregate'
  aggregate?: Maybe<Redirect_Ids_Aggregate_Fields>
  nodes: Array<Redirect_Ids>
}

/** aggregate fields of "redirect_ids" */
export type Redirect_Ids_Aggregate_Fields = {
  __typename?: 'redirect_ids_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Redirect_Ids_Max_Fields>
  min?: Maybe<Redirect_Ids_Min_Fields>
}

/** aggregate fields of "redirect_ids" */
export type Redirect_Ids_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Redirect_Ids_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "redirect_ids". All fields are combined with a logical 'AND'. */
export type Redirect_Ids_Bool_Exp = {
  _and?: Maybe<Array<Redirect_Ids_Bool_Exp>>
  _not?: Maybe<Redirect_Ids_Bool_Exp>
  _or?: Maybe<Array<Redirect_Ids_Bool_Exp>>
  cid?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<String_Comparison_Exp>
  uid?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "redirect_ids" */
export enum Redirect_Ids_Constraint {
  /** unique or primary key constraint on columns "id" */
  AffiliateCidUidPkey = 'affiliate_cid_uid_pkey',
}

/** input type for inserting data into table "redirect_ids" */
export type Redirect_Ids_Insert_Input = {
  cid?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Redirect_Ids_Max_Fields = {
  __typename?: 'redirect_ids_max_fields'
  cid?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Redirect_Ids_Min_Fields = {
  __typename?: 'redirect_ids_min_fields'
  cid?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "redirect_ids" */
export type Redirect_Ids_Mutation_Response = {
  __typename?: 'redirect_ids_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Redirect_Ids>
}

/** on_conflict condition type for table "redirect_ids" */
export type Redirect_Ids_On_Conflict = {
  constraint: Redirect_Ids_Constraint
  update_columns?: Array<Redirect_Ids_Update_Column>
  where?: Maybe<Redirect_Ids_Bool_Exp>
}

/** Ordering options when selecting data from "redirect_ids". */
export type Redirect_Ids_Order_By = {
  cid?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  uid?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: redirect_ids */
export type Redirect_Ids_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "redirect_ids" */
export enum Redirect_Ids_Select_Column {
  /** column name */
  Cid = 'cid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "redirect_ids" */
export type Redirect_Ids_Set_Input = {
  cid?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "redirect_ids" */
export type Redirect_Ids_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Redirect_Ids_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Redirect_Ids_Stream_Cursor_Value_Input = {
  cid?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "redirect_ids" */
export enum Redirect_Ids_Update_Column {
  /** column name */
  Cid = 'cid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Redirect_Ids_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Redirect_Ids_Set_Input>
  /** filter the rows which have to be updated */
  where: Redirect_Ids_Bool_Exp
}

export type Related_Shops_By_Category_Args = {
  shop_key?: Maybe<Scalars['String']>
}

export type Related_Users_For_Shop_Item_Args = {
  shop_item_id?: Maybe<Scalars['uuid']>
}

/** Enum table for Cart Item removal reasons */
export type Remove_Reason = {
  __typename?: 'remove_reason'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "remove_reason" */
export type Remove_Reason_Aggregate = {
  __typename?: 'remove_reason_aggregate'
  aggregate?: Maybe<Remove_Reason_Aggregate_Fields>
  nodes: Array<Remove_Reason>
}

/** aggregate fields of "remove_reason" */
export type Remove_Reason_Aggregate_Fields = {
  __typename?: 'remove_reason_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Remove_Reason_Max_Fields>
  min?: Maybe<Remove_Reason_Min_Fields>
}

/** aggregate fields of "remove_reason" */
export type Remove_Reason_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Remove_Reason_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "remove_reason". All fields are combined with a logical 'AND'. */
export type Remove_Reason_Bool_Exp = {
  _and?: Maybe<Array<Remove_Reason_Bool_Exp>>
  _not?: Maybe<Remove_Reason_Bool_Exp>
  _or?: Maybe<Array<Remove_Reason_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "remove_reason" */
export enum Remove_Reason_Constraint {
  /** unique or primary key constraint on columns "value" */
  RemoveReasonPkey = 'remove_reason_pkey',
}

export enum Remove_Reason_Enum {
  /** Not longer interested in item */
  NotInterested = 'not_interested',
  /** Item was purchased */
  Purchased = 'purchased',
}

/** Boolean expression to compare columns of type "remove_reason_enum". All fields are combined with logical 'AND'. */
export type Remove_Reason_Enum_Comparison_Exp = {
  _eq?: Maybe<Remove_Reason_Enum>
  _in?: Maybe<Array<Remove_Reason_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Remove_Reason_Enum>
  _nin?: Maybe<Array<Remove_Reason_Enum>>
}

/** input type for inserting data into table "remove_reason" */
export type Remove_Reason_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Remove_Reason_Max_Fields = {
  __typename?: 'remove_reason_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Remove_Reason_Min_Fields = {
  __typename?: 'remove_reason_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "remove_reason" */
export type Remove_Reason_Mutation_Response = {
  __typename?: 'remove_reason_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Remove_Reason>
}

/** input type for inserting object relation for remote table "remove_reason" */
export type Remove_Reason_Obj_Rel_Insert_Input = {
  data: Remove_Reason_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Remove_Reason_On_Conflict>
}

/** on_conflict condition type for table "remove_reason" */
export type Remove_Reason_On_Conflict = {
  constraint: Remove_Reason_Constraint
  update_columns?: Array<Remove_Reason_Update_Column>
  where?: Maybe<Remove_Reason_Bool_Exp>
}

/** Ordering options when selecting data from "remove_reason". */
export type Remove_Reason_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: remove_reason */
export type Remove_Reason_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "remove_reason" */
export enum Remove_Reason_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "remove_reason" */
export type Remove_Reason_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "remove_reason" */
export type Remove_Reason_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Remove_Reason_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Remove_Reason_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "remove_reason" */
export enum Remove_Reason_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Remove_Reason_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Remove_Reason_Set_Input>
  /** filter the rows which have to be updated */
  where: Remove_Reason_Bool_Exp
}

/** Rewardable user actions enum table */
export type Rewardable_Action_Names = {
  __typename?: 'rewardable_action_names'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "rewardable_action_names" */
export type Rewardable_Action_Names_Aggregate = {
  __typename?: 'rewardable_action_names_aggregate'
  aggregate?: Maybe<Rewardable_Action_Names_Aggregate_Fields>
  nodes: Array<Rewardable_Action_Names>
}

/** aggregate fields of "rewardable_action_names" */
export type Rewardable_Action_Names_Aggregate_Fields = {
  __typename?: 'rewardable_action_names_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Rewardable_Action_Names_Max_Fields>
  min?: Maybe<Rewardable_Action_Names_Min_Fields>
}

/** aggregate fields of "rewardable_action_names" */
export type Rewardable_Action_Names_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rewardable_Action_Names_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "rewardable_action_names". All fields are combined with a logical 'AND'. */
export type Rewardable_Action_Names_Bool_Exp = {
  _and?: Maybe<Array<Rewardable_Action_Names_Bool_Exp>>
  _not?: Maybe<Rewardable_Action_Names_Bool_Exp>
  _or?: Maybe<Array<Rewardable_Action_Names_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "rewardable_action_names" */
export enum Rewardable_Action_Names_Constraint {
  /** unique or primary key constraint on columns "value" */
  RewardableUserActionsPkey = 'rewardable_user_actions_pkey',
}

export enum Rewardable_Action_Names_Enum {
  BrandSelection = 'brand_selection',
  CategorySelection = 'category_selection',
  CompleteProfile = 'complete_profile',
  EnableDesktopExtension = 'enable_desktop_extension',
  EnablePushNotifications = 'enable_push_notifications',
  EnableSafariExtension = 'enable_safari_extension',
  /** 100 */
  OnboardingPromo = 'onboarding_promo',
  ReferFriend = 'refer_friend',
  /** 5 */
  RevealDeal = 'reveal_deal',
  Transaction = 'transaction',
  UpgradePro = 'upgrade_pro',
}

/** Boolean expression to compare columns of type "rewardable_action_names_enum". All fields are combined with logical 'AND'. */
export type Rewardable_Action_Names_Enum_Comparison_Exp = {
  _eq?: Maybe<Rewardable_Action_Names_Enum>
  _in?: Maybe<Array<Rewardable_Action_Names_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Rewardable_Action_Names_Enum>
  _nin?: Maybe<Array<Rewardable_Action_Names_Enum>>
}

/** input type for inserting data into table "rewardable_action_names" */
export type Rewardable_Action_Names_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Rewardable_Action_Names_Max_Fields = {
  __typename?: 'rewardable_action_names_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Rewardable_Action_Names_Min_Fields = {
  __typename?: 'rewardable_action_names_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "rewardable_action_names" */
export type Rewardable_Action_Names_Mutation_Response = {
  __typename?: 'rewardable_action_names_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Rewardable_Action_Names>
}

/** input type for inserting object relation for remote table "rewardable_action_names" */
export type Rewardable_Action_Names_Obj_Rel_Insert_Input = {
  data: Rewardable_Action_Names_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Rewardable_Action_Names_On_Conflict>
}

/** on_conflict condition type for table "rewardable_action_names" */
export type Rewardable_Action_Names_On_Conflict = {
  constraint: Rewardable_Action_Names_Constraint
  update_columns?: Array<Rewardable_Action_Names_Update_Column>
  where?: Maybe<Rewardable_Action_Names_Bool_Exp>
}

/** Ordering options when selecting data from "rewardable_action_names". */
export type Rewardable_Action_Names_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: rewardable_action_names */
export type Rewardable_Action_Names_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "rewardable_action_names" */
export enum Rewardable_Action_Names_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "rewardable_action_names" */
export type Rewardable_Action_Names_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "rewardable_action_names" */
export type Rewardable_Action_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewardable_Action_Names_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Rewardable_Action_Names_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "rewardable_action_names" */
export enum Rewardable_Action_Names_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Rewardable_Action_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rewardable_Action_Names_Set_Input>
  /** filter the rows which have to be updated */
  where: Rewardable_Action_Names_Bool_Exp
}

/** Enum table of rewardable item types */
export type Rewardable_Item_Type = {
  __typename?: 'rewardable_item_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "rewardable_item_type" */
export type Rewardable_Item_Type_Aggregate = {
  __typename?: 'rewardable_item_type_aggregate'
  aggregate?: Maybe<Rewardable_Item_Type_Aggregate_Fields>
  nodes: Array<Rewardable_Item_Type>
}

/** aggregate fields of "rewardable_item_type" */
export type Rewardable_Item_Type_Aggregate_Fields = {
  __typename?: 'rewardable_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Rewardable_Item_Type_Max_Fields>
  min?: Maybe<Rewardable_Item_Type_Min_Fields>
}

/** aggregate fields of "rewardable_item_type" */
export type Rewardable_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rewardable_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "rewardable_item_type". All fields are combined with a logical 'AND'. */
export type Rewardable_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Rewardable_Item_Type_Bool_Exp>>
  _not?: Maybe<Rewardable_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Rewardable_Item_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "rewardable_item_type" */
export enum Rewardable_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  RewardableItemTypePkey = 'rewardable_item_type_pkey',
}

export enum Rewardable_Item_Type_Enum {
  AffiliateTransaction = 'affiliate_transaction',
  CartItem = 'cart_item',
  CollectionCartItem = 'collection_cart_item',
  User = 'user',
}

/** Boolean expression to compare columns of type "rewardable_item_type_enum". All fields are combined with logical 'AND'. */
export type Rewardable_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Rewardable_Item_Type_Enum>
  _in?: Maybe<Array<Rewardable_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Rewardable_Item_Type_Enum>
  _nin?: Maybe<Array<Rewardable_Item_Type_Enum>>
}

/** input type for inserting data into table "rewardable_item_type" */
export type Rewardable_Item_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Rewardable_Item_Type_Max_Fields = {
  __typename?: 'rewardable_item_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Rewardable_Item_Type_Min_Fields = {
  __typename?: 'rewardable_item_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "rewardable_item_type" */
export type Rewardable_Item_Type_Mutation_Response = {
  __typename?: 'rewardable_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Rewardable_Item_Type>
}

/** on_conflict condition type for table "rewardable_item_type" */
export type Rewardable_Item_Type_On_Conflict = {
  constraint: Rewardable_Item_Type_Constraint
  update_columns?: Array<Rewardable_Item_Type_Update_Column>
  where?: Maybe<Rewardable_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "rewardable_item_type". */
export type Rewardable_Item_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: rewardable_item_type */
export type Rewardable_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "rewardable_item_type" */
export enum Rewardable_Item_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "rewardable_item_type" */
export type Rewardable_Item_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "rewardable_item_type" */
export type Rewardable_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewardable_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Rewardable_Item_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "rewardable_item_type" */
export enum Rewardable_Item_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Rewardable_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rewardable_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Rewardable_Item_Type_Bool_Exp
}

/** A table to keep tabs on what searches our users are making */
export type Search_Query = {
  __typename?: 'search_query'
  bookmark?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  last_fetched_at: Scalars['timestamptz']
  page: Scalars['Int']
  /** An object relationship */
  previous_page?: Maybe<Search_Query>
  previous_page_id?: Maybe<Scalars['uuid']>
  query: Scalars['String']
  /** An array relationship */
  results: Array<Search_Query_Result>
  /** An aggregate relationship */
  results_aggregate: Search_Query_Result_Aggregate
  source: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** A table to keep tabs on what searches our users are making */
export type Search_QueryResultsArgs = {
  distinct_on?: Maybe<Array<Search_Query_Result_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Result_Order_By>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

/** A table to keep tabs on what searches our users are making */
export type Search_QueryResults_AggregateArgs = {
  distinct_on?: Maybe<Array<Search_Query_Result_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Result_Order_By>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

/** aggregated selection of "search_query" */
export type Search_Query_Aggregate = {
  __typename?: 'search_query_aggregate'
  aggregate?: Maybe<Search_Query_Aggregate_Fields>
  nodes: Array<Search_Query>
}

/** aggregate fields of "search_query" */
export type Search_Query_Aggregate_Fields = {
  __typename?: 'search_query_aggregate_fields'
  avg?: Maybe<Search_Query_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Search_Query_Max_Fields>
  min?: Maybe<Search_Query_Min_Fields>
  stddev?: Maybe<Search_Query_Stddev_Fields>
  stddev_pop?: Maybe<Search_Query_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Search_Query_Stddev_Samp_Fields>
  sum?: Maybe<Search_Query_Sum_Fields>
  var_pop?: Maybe<Search_Query_Var_Pop_Fields>
  var_samp?: Maybe<Search_Query_Var_Samp_Fields>
  variance?: Maybe<Search_Query_Variance_Fields>
}

/** aggregate fields of "search_query" */
export type Search_Query_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Search_Query_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Search_Query_Avg_Fields = {
  __typename?: 'search_query_avg_fields'
  page?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "search_query". All fields are combined with a logical 'AND'. */
export type Search_Query_Bool_Exp = {
  _and?: Maybe<Array<Search_Query_Bool_Exp>>
  _not?: Maybe<Search_Query_Bool_Exp>
  _or?: Maybe<Array<Search_Query_Bool_Exp>>
  bookmark?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  last_fetched_at?: Maybe<Timestamptz_Comparison_Exp>
  page?: Maybe<Int_Comparison_Exp>
  previous_page?: Maybe<Search_Query_Bool_Exp>
  previous_page_id?: Maybe<Uuid_Comparison_Exp>
  query?: Maybe<String_Comparison_Exp>
  results?: Maybe<Search_Query_Result_Bool_Exp>
  results_aggregate?: Maybe<Search_Query_Result_Aggregate_Bool_Exp>
  source?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "search_query" */
export enum Search_Query_Constraint {
  /** unique or primary key constraint on columns "id" */
  SearchQueryPkey = 'search_query_pkey',
  /** unique or primary key constraint on columns "query", "source", "page" */
  SearchQueryQueryPageSourceKey = 'search_query_query_page_source_key',
}

/** input type for incrementing numeric columns in table "search_query" */
export type Search_Query_Inc_Input = {
  page?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "search_query" */
export type Search_Query_Insert_Input = {
  bookmark?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  last_fetched_at?: Maybe<Scalars['timestamptz']>
  page?: Maybe<Scalars['Int']>
  previous_page?: Maybe<Search_Query_Obj_Rel_Insert_Input>
  previous_page_id?: Maybe<Scalars['uuid']>
  query?: Maybe<Scalars['String']>
  results?: Maybe<Search_Query_Result_Arr_Rel_Insert_Input>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Search_Query_Max_Fields = {
  __typename?: 'search_query_max_fields'
  bookmark?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  last_fetched_at?: Maybe<Scalars['timestamptz']>
  page?: Maybe<Scalars['Int']>
  previous_page_id?: Maybe<Scalars['uuid']>
  query?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Search_Query_Min_Fields = {
  __typename?: 'search_query_min_fields'
  bookmark?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  last_fetched_at?: Maybe<Scalars['timestamptz']>
  page?: Maybe<Scalars['Int']>
  previous_page_id?: Maybe<Scalars['uuid']>
  query?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "search_query" */
export type Search_Query_Mutation_Response = {
  __typename?: 'search_query_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Search_Query>
}

/** input type for inserting object relation for remote table "search_query" */
export type Search_Query_Obj_Rel_Insert_Input = {
  data: Search_Query_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Search_Query_On_Conflict>
}

/** on_conflict condition type for table "search_query" */
export type Search_Query_On_Conflict = {
  constraint: Search_Query_Constraint
  update_columns?: Array<Search_Query_Update_Column>
  where?: Maybe<Search_Query_Bool_Exp>
}

/** Ordering options when selecting data from "search_query". */
export type Search_Query_Order_By = {
  bookmark?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  last_fetched_at?: Maybe<Order_By>
  page?: Maybe<Order_By>
  previous_page?: Maybe<Search_Query_Order_By>
  previous_page_id?: Maybe<Order_By>
  query?: Maybe<Order_By>
  results_aggregate?: Maybe<Search_Query_Result_Aggregate_Order_By>
  source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: search_query */
export type Search_Query_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** A table to keep track of all search results */
export type Search_Query_Result = {
  __typename?: 'search_query_result'
  created_at: Scalars['timestamptz']
  order: Scalars['Int']
  search_query_id: Scalars['uuid']
  /** An object relationship */
  shop_item: Shop_Item
  shop_item_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "search_query_result" */
export type Search_Query_Result_Aggregate = {
  __typename?: 'search_query_result_aggregate'
  aggregate?: Maybe<Search_Query_Result_Aggregate_Fields>
  nodes: Array<Search_Query_Result>
}

export type Search_Query_Result_Aggregate_Bool_Exp = {
  count?: Maybe<Search_Query_Result_Aggregate_Bool_Exp_Count>
}

export type Search_Query_Result_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Search_Query_Result_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Search_Query_Result_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "search_query_result" */
export type Search_Query_Result_Aggregate_Fields = {
  __typename?: 'search_query_result_aggregate_fields'
  avg?: Maybe<Search_Query_Result_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Search_Query_Result_Max_Fields>
  min?: Maybe<Search_Query_Result_Min_Fields>
  stddev?: Maybe<Search_Query_Result_Stddev_Fields>
  stddev_pop?: Maybe<Search_Query_Result_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Search_Query_Result_Stddev_Samp_Fields>
  sum?: Maybe<Search_Query_Result_Sum_Fields>
  var_pop?: Maybe<Search_Query_Result_Var_Pop_Fields>
  var_samp?: Maybe<Search_Query_Result_Var_Samp_Fields>
  variance?: Maybe<Search_Query_Result_Variance_Fields>
}

/** aggregate fields of "search_query_result" */
export type Search_Query_Result_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Search_Query_Result_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "search_query_result" */
export type Search_Query_Result_Aggregate_Order_By = {
  avg?: Maybe<Search_Query_Result_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Search_Query_Result_Max_Order_By>
  min?: Maybe<Search_Query_Result_Min_Order_By>
  stddev?: Maybe<Search_Query_Result_Stddev_Order_By>
  stddev_pop?: Maybe<Search_Query_Result_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Search_Query_Result_Stddev_Samp_Order_By>
  sum?: Maybe<Search_Query_Result_Sum_Order_By>
  var_pop?: Maybe<Search_Query_Result_Var_Pop_Order_By>
  var_samp?: Maybe<Search_Query_Result_Var_Samp_Order_By>
  variance?: Maybe<Search_Query_Result_Variance_Order_By>
}

/** input type for inserting array relation for remote table "search_query_result" */
export type Search_Query_Result_Arr_Rel_Insert_Input = {
  data: Array<Search_Query_Result_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Search_Query_Result_On_Conflict>
}

/** aggregate avg on columns */
export type Search_Query_Result_Avg_Fields = {
  __typename?: 'search_query_result_avg_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "search_query_result" */
export type Search_Query_Result_Avg_Order_By = {
  order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "search_query_result". All fields are combined with a logical 'AND'. */
export type Search_Query_Result_Bool_Exp = {
  _and?: Maybe<Array<Search_Query_Result_Bool_Exp>>
  _not?: Maybe<Search_Query_Result_Bool_Exp>
  _or?: Maybe<Array<Search_Query_Result_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  order?: Maybe<Int_Comparison_Exp>
  search_query_id?: Maybe<Uuid_Comparison_Exp>
  shop_item?: Maybe<Shop_Item_Bool_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "search_query_result" */
export enum Search_Query_Result_Constraint {
  /** unique or primary key constraint on columns "search_query_id", "shop_item_id" */
  SearchQueryResultsPkey = 'search_query_results_pkey',
}

/** input type for incrementing numeric columns in table "search_query_result" */
export type Search_Query_Result_Inc_Input = {
  order?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "search_query_result" */
export type Search_Query_Result_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  order?: Maybe<Scalars['Int']>
  search_query_id?: Maybe<Scalars['uuid']>
  shop_item?: Maybe<Shop_Item_Obj_Rel_Insert_Input>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Search_Query_Result_Max_Fields = {
  __typename?: 'search_query_result_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  order?: Maybe<Scalars['Int']>
  search_query_id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "search_query_result" */
export type Search_Query_Result_Max_Order_By = {
  created_at?: Maybe<Order_By>
  order?: Maybe<Order_By>
  search_query_id?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Search_Query_Result_Min_Fields = {
  __typename?: 'search_query_result_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  order?: Maybe<Scalars['Int']>
  search_query_id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "search_query_result" */
export type Search_Query_Result_Min_Order_By = {
  created_at?: Maybe<Order_By>
  order?: Maybe<Order_By>
  search_query_id?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "search_query_result" */
export type Search_Query_Result_Mutation_Response = {
  __typename?: 'search_query_result_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Search_Query_Result>
}

/** on_conflict condition type for table "search_query_result" */
export type Search_Query_Result_On_Conflict = {
  constraint: Search_Query_Result_Constraint
  update_columns?: Array<Search_Query_Result_Update_Column>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

/** Ordering options when selecting data from "search_query_result". */
export type Search_Query_Result_Order_By = {
  created_at?: Maybe<Order_By>
  order?: Maybe<Order_By>
  search_query_id?: Maybe<Order_By>
  shop_item?: Maybe<Shop_Item_Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: search_query_result */
export type Search_Query_Result_Pk_Columns_Input = {
  search_query_id: Scalars['uuid']
  shop_item_id: Scalars['uuid']
}

/** select columns of table "search_query_result" */
export enum Search_Query_Result_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Order = 'order',
  /** column name */
  SearchQueryId = 'search_query_id',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "search_query_result" */
export type Search_Query_Result_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  order?: Maybe<Scalars['Int']>
  search_query_id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Search_Query_Result_Stddev_Fields = {
  __typename?: 'search_query_result_stddev_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "search_query_result" */
export type Search_Query_Result_Stddev_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Search_Query_Result_Stddev_Pop_Fields = {
  __typename?: 'search_query_result_stddev_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "search_query_result" */
export type Search_Query_Result_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Search_Query_Result_Stddev_Samp_Fields = {
  __typename?: 'search_query_result_stddev_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "search_query_result" */
export type Search_Query_Result_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** Streaming cursor of the table "search_query_result" */
export type Search_Query_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Search_Query_Result_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Search_Query_Result_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  order?: Maybe<Scalars['Int']>
  search_query_id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Search_Query_Result_Sum_Fields = {
  __typename?: 'search_query_result_sum_fields'
  order?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "search_query_result" */
export type Search_Query_Result_Sum_Order_By = {
  order?: Maybe<Order_By>
}

/** update columns of table "search_query_result" */
export enum Search_Query_Result_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Order = 'order',
  /** column name */
  SearchQueryId = 'search_query_id',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Search_Query_Result_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Search_Query_Result_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Search_Query_Result_Set_Input>
  /** filter the rows which have to be updated */
  where: Search_Query_Result_Bool_Exp
}

/** aggregate var_pop on columns */
export type Search_Query_Result_Var_Pop_Fields = {
  __typename?: 'search_query_result_var_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "search_query_result" */
export type Search_Query_Result_Var_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Search_Query_Result_Var_Samp_Fields = {
  __typename?: 'search_query_result_var_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "search_query_result" */
export type Search_Query_Result_Var_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Search_Query_Result_Variance_Fields = {
  __typename?: 'search_query_result_variance_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "search_query_result" */
export type Search_Query_Result_Variance_Order_By = {
  order?: Maybe<Order_By>
}

/** select columns of table "search_query" */
export enum Search_Query_Select_Column {
  /** column name */
  Bookmark = 'bookmark',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastFetchedAt = 'last_fetched_at',
  /** column name */
  Page = 'page',
  /** column name */
  PreviousPageId = 'previous_page_id',
  /** column name */
  Query = 'query',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "search_query" */
export type Search_Query_Set_Input = {
  bookmark?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  last_fetched_at?: Maybe<Scalars['timestamptz']>
  page?: Maybe<Scalars['Int']>
  previous_page_id?: Maybe<Scalars['uuid']>
  query?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Search_Query_Stddev_Fields = {
  __typename?: 'search_query_stddev_fields'
  page?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Search_Query_Stddev_Pop_Fields = {
  __typename?: 'search_query_stddev_pop_fields'
  page?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Search_Query_Stddev_Samp_Fields = {
  __typename?: 'search_query_stddev_samp_fields'
  page?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "search_query" */
export type Search_Query_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Search_Query_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Search_Query_Stream_Cursor_Value_Input = {
  bookmark?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  last_fetched_at?: Maybe<Scalars['timestamptz']>
  page?: Maybe<Scalars['Int']>
  previous_page_id?: Maybe<Scalars['uuid']>
  query?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Search_Query_Sum_Fields = {
  __typename?: 'search_query_sum_fields'
  page?: Maybe<Scalars['Int']>
}

/** update columns of table "search_query" */
export enum Search_Query_Update_Column {
  /** column name */
  Bookmark = 'bookmark',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastFetchedAt = 'last_fetched_at',
  /** column name */
  Page = 'page',
  /** column name */
  PreviousPageId = 'previous_page_id',
  /** column name */
  Query = 'query',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Search_Query_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Search_Query_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Search_Query_Set_Input>
  /** filter the rows which have to be updated */
  where: Search_Query_Bool_Exp
}

/** aggregate var_pop on columns */
export type Search_Query_Var_Pop_Fields = {
  __typename?: 'search_query_var_pop_fields'
  page?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Search_Query_Var_Samp_Fields = {
  __typename?: 'search_query_var_samp_fields'
  page?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Search_Query_Variance_Fields = {
  __typename?: 'search_query_variance_fields'
  page?: Maybe<Scalars['Float']>
}

export type Search_Shop_Items_Args = {
  limit?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

export type Search_Shops_Args = {
  limit?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

export type Search_Users_Args = {
  limit?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop" */
export type Shop = {
  __typename?: 'shop'
  affiliate?: Maybe<Affiliate_Enum>
  /** An object relationship */
  associated_affiliate?: Maybe<Affiliate>
  banner_image?: Maybe<Scalars['String']>
  /** An array relationship */
  cart_items: Array<Cart_Item>
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  cart_path?: Maybe<Scalars['String']>
  /** An array relationship */
  carts: Array<Cart>
  /** An aggregate relationship */
  carts_aggregate: Cart_Aggregate
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_display_name" */
  display_name?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  editorial_shop_logo?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  featured: Scalars['Boolean']
  /** An object relationship */
  featured_shop?: Maybe<Featured_Shop>
  has_deals?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "shop_should_show_pdp" */
  has_pdp?: Maybe<Scalars['Boolean']>
  id: Scalars['uuid']
  /** An array relationship */
  items: Array<Shop_Item>
  /** An aggregate relationship */
  items_aggregate: Shop_Item_Aggregate
  key: Scalars['String']
  keywords?: Maybe<Array<Scalars['String']>>
  metadata: Scalars['jsonb']
  name: Scalars['name']
  name_override?: Maybe<Scalars['String']>
  /** An object relationship */
  parent_shop?: Maybe<Shop>
  parent_shop_key?: Maybe<Scalars['String']>
  parsed_take_rate?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  potential_spam?: Maybe<Scalars['Boolean']>
  search_tokens?: Maybe<Array<Scalars['String']>>
  searchable_slug?: Maybe<Scalars['String']>
  /** An array relationship */
  shop_affiliates: Array<Shop_Affiliate>
  /** An aggregate relationship */
  shop_affiliates_aggregate: Shop_Affiliate_Aggregate
  /** An array relationship */
  shop_categorizations: Array<Shop_Categorization>
  /** An aggregate relationship */
  shop_categorizations_aggregate: Shop_Categorization_Aggregate
  shop_faqs?: Maybe<Scalars['jsonb']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
  /** An object relationship */
  shop_popularity?: Maybe<Shop_Popularity>
  /** Determines if we should show price drops in the UI */
  show_price_drops?: Maybe<Scalars['Boolean']>
  slug: Scalars['name']
  type?: Maybe<Scalars['name']>
  unknown?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "shop" */
export type ShopCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopCartsArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopCarts_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopItemsArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopItems_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop" */
export type ShopParsed_Take_RateArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop" */
export type ShopShop_AffiliatesArgs = {
  distinct_on?: Maybe<Array<Shop_Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Affiliate_Order_By>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopShop_Affiliates_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Affiliate_Order_By>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopShop_CategorizationsArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopShop_Categorizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopShop_FaqsArgs = {
  path?: Maybe<Scalars['String']>
}

/** This table has the relationship between one shop and its affiliates */
export type Shop_Affiliate = {
  __typename?: 'shop_affiliate'
  /** An object relationship */
  affiliate: Affiliate
  affiliate_value: Affiliate_Enum
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  shop: Shop
  shop_key: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "shop_affiliate" */
export type Shop_Affiliate_Aggregate = {
  __typename?: 'shop_affiliate_aggregate'
  aggregate?: Maybe<Shop_Affiliate_Aggregate_Fields>
  nodes: Array<Shop_Affiliate>
}

export type Shop_Affiliate_Aggregate_Bool_Exp = {
  count?: Maybe<Shop_Affiliate_Aggregate_Bool_Exp_Count>
}

export type Shop_Affiliate_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shop_Affiliate_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Affiliate_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "shop_affiliate" */
export type Shop_Affiliate_Aggregate_Fields = {
  __typename?: 'shop_affiliate_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Affiliate_Max_Fields>
  min?: Maybe<Shop_Affiliate_Min_Fields>
}

/** aggregate fields of "shop_affiliate" */
export type Shop_Affiliate_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Affiliate_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "shop_affiliate" */
export type Shop_Affiliate_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Shop_Affiliate_Max_Order_By>
  min?: Maybe<Shop_Affiliate_Min_Order_By>
}

/** input type for inserting array relation for remote table "shop_affiliate" */
export type Shop_Affiliate_Arr_Rel_Insert_Input = {
  data: Array<Shop_Affiliate_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Shop_Affiliate_On_Conflict>
}

/** Boolean expression to filter rows from the table "shop_affiliate". All fields are combined with a logical 'AND'. */
export type Shop_Affiliate_Bool_Exp = {
  _and?: Maybe<Array<Shop_Affiliate_Bool_Exp>>
  _not?: Maybe<Shop_Affiliate_Bool_Exp>
  _or?: Maybe<Array<Shop_Affiliate_Bool_Exp>>
  affiliate?: Maybe<Affiliate_Bool_Exp>
  affiliate_value?: Maybe<Affiliate_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_affiliate" */
export enum Shop_Affiliate_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopAffiliatePkey = 'shop_affiliate_pkey',
  /** unique or primary key constraint on columns "shop_key", "affiliate_value" */
  ShopAffiliateShopKeyAffiliateKey = 'shop_affiliate_shop_key_affiliate_key',
}

/** input type for inserting data into table "shop_affiliate" */
export type Shop_Affiliate_Insert_Input = {
  affiliate?: Maybe<Affiliate_Obj_Rel_Insert_Input>
  affiliate_value?: Maybe<Affiliate_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Shop_Affiliate_Max_Fields = {
  __typename?: 'shop_affiliate_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "shop_affiliate" */
export type Shop_Affiliate_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Shop_Affiliate_Min_Fields = {
  __typename?: 'shop_affiliate_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "shop_affiliate" */
export type Shop_Affiliate_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "shop_affiliate" */
export type Shop_Affiliate_Mutation_Response = {
  __typename?: 'shop_affiliate_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Affiliate>
}

/** on_conflict condition type for table "shop_affiliate" */
export type Shop_Affiliate_On_Conflict = {
  constraint: Shop_Affiliate_Constraint
  update_columns?: Array<Shop_Affiliate_Update_Column>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

/** Ordering options when selecting data from "shop_affiliate". */
export type Shop_Affiliate_Order_By = {
  affiliate?: Maybe<Affiliate_Order_By>
  affiliate_value?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: shop_affiliate */
export type Shop_Affiliate_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "shop_affiliate" */
export enum Shop_Affiliate_Select_Column {
  /** column name */
  AffiliateValue = 'affiliate_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "shop_affiliate" */
export type Shop_Affiliate_Set_Input = {
  affiliate_value?: Maybe<Affiliate_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "shop_affiliate" */
export type Shop_Affiliate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Affiliate_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Affiliate_Stream_Cursor_Value_Input = {
  affiliate_value?: Maybe<Affiliate_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "shop_affiliate" */
export enum Shop_Affiliate_Update_Column {
  /** column name */
  AffiliateValue = 'affiliate_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Shop_Affiliate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Affiliate_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Affiliate_Bool_Exp
}

export type Shop_Aggregate = {
  __typename?: 'shop_aggregate'
  aggregate?: Maybe<Shop_Aggregate_Fields>
  nodes: Array<Shop>
}

/** aggregate fields of "shop" */
export type Shop_Aggregate_Fields = {
  __typename?: 'shop_aggregate_fields'
  avg?: Maybe<Shop_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Max_Fields>
  min?: Maybe<Shop_Min_Fields>
  stddev?: Maybe<Shop_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Sum_Fields>
  var_pop?: Maybe<Shop_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Var_Samp_Fields>
  variance?: Maybe<Shop_Variance_Fields>
}

/** aggregate fields of "shop" */
export type Shop_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shop_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
  shop_faqs?: Maybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Shop_Avg_Fields = {
  __typename?: 'shop_avg_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** Boolean expression to filter rows from the table "shop". All fields are combined with a logical 'AND'. */
export type Shop_Bool_Exp = {
  _and?: Maybe<Array<Shop_Bool_Exp>>
  _not?: Maybe<Shop_Bool_Exp>
  _or?: Maybe<Array<Shop_Bool_Exp>>
  affiliate?: Maybe<Affiliate_Enum_Comparison_Exp>
  associated_affiliate?: Maybe<Affiliate_Bool_Exp>
  banner_image?: Maybe<String_Comparison_Exp>
  cart_items?: Maybe<Cart_Item_Bool_Exp>
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Bool_Exp>
  cart_items_count?: Maybe<Numeric_Comparison_Exp>
  cart_path?: Maybe<String_Comparison_Exp>
  carts?: Maybe<Cart_Bool_Exp>
  carts_aggregate?: Maybe<Cart_Aggregate_Bool_Exp>
  cover_image?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  display_name?: Maybe<String_Comparison_Exp>
  domains?: Maybe<String_Array_Comparison_Exp>
  editorial_shop_logo?: Maybe<String_Comparison_Exp>
  favicon?: Maybe<String_Comparison_Exp>
  featured?: Maybe<Boolean_Comparison_Exp>
  featured_shop?: Maybe<Featured_Shop_Bool_Exp>
  has_deals?: Maybe<Boolean_Comparison_Exp>
  has_pdp?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  items?: Maybe<Shop_Item_Bool_Exp>
  items_aggregate?: Maybe<Shop_Item_Aggregate_Bool_Exp>
  key?: Maybe<String_Comparison_Exp>
  keywords?: Maybe<String_Array_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  name?: Maybe<Name_Comparison_Exp>
  name_override?: Maybe<String_Comparison_Exp>
  parent_shop?: Maybe<Shop_Bool_Exp>
  parent_shop_key?: Maybe<String_Comparison_Exp>
  parsed_take_rate?: Maybe<Jsonb_Comparison_Exp>
  platform?: Maybe<String_Comparison_Exp>
  points?: Maybe<Numeric_Comparison_Exp>
  potential_spam?: Maybe<Boolean_Comparison_Exp>
  search_tokens?: Maybe<String_Array_Comparison_Exp>
  searchable_slug?: Maybe<String_Comparison_Exp>
  shop_affiliates?: Maybe<Shop_Affiliate_Bool_Exp>
  shop_affiliates_aggregate?: Maybe<Shop_Affiliate_Aggregate_Bool_Exp>
  shop_categorizations?: Maybe<Shop_Categorization_Bool_Exp>
  shop_categorizations_aggregate?: Maybe<Shop_Categorization_Aggregate_Bool_Exp>
  shop_faqs?: Maybe<Jsonb_Comparison_Exp>
  shop_items_count?: Maybe<Numeric_Comparison_Exp>
  shop_popularity?: Maybe<Shop_Popularity_Bool_Exp>
  show_price_drops?: Maybe<Boolean_Comparison_Exp>
  slug?: Maybe<Name_Comparison_Exp>
  type?: Maybe<Name_Comparison_Exp>
  unknown?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** columns and relationships of "shop_categorization" */
export type Shop_Categorization = {
  __typename?: 'shop_categorization'
  category: Shop_Category_Enum
  created_at: Scalars['timestamptz']
  curation_score: Scalars['Int']
  id: Scalars['uuid']
  /** An object relationship */
  shop: Shop
  /** An object relationship */
  shop_category: Shop_Category
  shop_key: Scalars['String']
  updated_at: Scalars['timestamptz']
  weight: Scalars['Int']
}

/** aggregated selection of "shop_categorization" */
export type Shop_Categorization_Aggregate = {
  __typename?: 'shop_categorization_aggregate'
  aggregate?: Maybe<Shop_Categorization_Aggregate_Fields>
  nodes: Array<Shop_Categorization>
}

export type Shop_Categorization_Aggregate_Bool_Exp = {
  count?: Maybe<Shop_Categorization_Aggregate_Bool_Exp_Count>
}

export type Shop_Categorization_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shop_Categorization_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Categorization_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "shop_categorization" */
export type Shop_Categorization_Aggregate_Fields = {
  __typename?: 'shop_categorization_aggregate_fields'
  avg?: Maybe<Shop_Categorization_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Categorization_Max_Fields>
  min?: Maybe<Shop_Categorization_Min_Fields>
  stddev?: Maybe<Shop_Categorization_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Categorization_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Categorization_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Categorization_Sum_Fields>
  var_pop?: Maybe<Shop_Categorization_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Categorization_Var_Samp_Fields>
  variance?: Maybe<Shop_Categorization_Variance_Fields>
}

/** aggregate fields of "shop_categorization" */
export type Shop_Categorization_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Categorization_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "shop_categorization" */
export type Shop_Categorization_Aggregate_Order_By = {
  avg?: Maybe<Shop_Categorization_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Shop_Categorization_Max_Order_By>
  min?: Maybe<Shop_Categorization_Min_Order_By>
  stddev?: Maybe<Shop_Categorization_Stddev_Order_By>
  stddev_pop?: Maybe<Shop_Categorization_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Shop_Categorization_Stddev_Samp_Order_By>
  sum?: Maybe<Shop_Categorization_Sum_Order_By>
  var_pop?: Maybe<Shop_Categorization_Var_Pop_Order_By>
  var_samp?: Maybe<Shop_Categorization_Var_Samp_Order_By>
  variance?: Maybe<Shop_Categorization_Variance_Order_By>
}

/** input type for inserting array relation for remote table "shop_categorization" */
export type Shop_Categorization_Arr_Rel_Insert_Input = {
  data: Array<Shop_Categorization_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Shop_Categorization_On_Conflict>
}

/** aggregate avg on columns */
export type Shop_Categorization_Avg_Fields = {
  __typename?: 'shop_categorization_avg_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "shop_categorization" */
export type Shop_Categorization_Avg_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "shop_categorization". All fields are combined with a logical 'AND'. */
export type Shop_Categorization_Bool_Exp = {
  _and?: Maybe<Array<Shop_Categorization_Bool_Exp>>
  _not?: Maybe<Shop_Categorization_Bool_Exp>
  _or?: Maybe<Array<Shop_Categorization_Bool_Exp>>
  category?: Maybe<Shop_Category_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  curation_score?: Maybe<Int_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_category?: Maybe<Shop_Category_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  weight?: Maybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_categorization" */
export enum Shop_Categorization_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopCategorizationPkey = 'shop_categorization_pkey',
  /** unique or primary key constraint on columns "shop_key", "weight", "category" */
  ShopCategorizationShopKeyCategoryWeightKey = 'shop_categorization_shop_key_category_weight_key',
  /** unique or primary key constraint on columns "shop_key", "weight" */
  ShopCategorizationShopKeyWeightKey = 'shop_categorization_shop_key_weight_key',
}

/** input type for incrementing numeric columns in table "shop_categorization" */
export type Shop_Categorization_Inc_Input = {
  curation_score?: Maybe<Scalars['Int']>
  weight?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "shop_categorization" */
export type Shop_Categorization_Insert_Input = {
  category?: Maybe<Shop_Category_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  curation_score?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_category?: Maybe<Shop_Category_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  weight?: Maybe<Scalars['Int']>
}

/** aggregate max on columns */
export type Shop_Categorization_Max_Fields = {
  __typename?: 'shop_categorization_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  curation_score?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  weight?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "shop_categorization" */
export type Shop_Categorization_Max_Order_By = {
  created_at?: Maybe<Order_By>
  curation_score?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Shop_Categorization_Min_Fields = {
  __typename?: 'shop_categorization_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  curation_score?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  weight?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "shop_categorization" */
export type Shop_Categorization_Min_Order_By = {
  created_at?: Maybe<Order_By>
  curation_score?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** response of any mutation on the table "shop_categorization" */
export type Shop_Categorization_Mutation_Response = {
  __typename?: 'shop_categorization_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Categorization>
}

/** on_conflict condition type for table "shop_categorization" */
export type Shop_Categorization_On_Conflict = {
  constraint: Shop_Categorization_Constraint
  update_columns?: Array<Shop_Categorization_Update_Column>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

/** Ordering options when selecting data from "shop_categorization". */
export type Shop_Categorization_Order_By = {
  category?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  curation_score?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_category?: Maybe<Shop_Category_Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** primary key columns input for table: shop_categorization */
export type Shop_Categorization_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "shop_categorization" */
export enum Shop_Categorization_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurationScore = 'curation_score',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weight = 'weight',
}

/** input type for updating data in table "shop_categorization" */
export type Shop_Categorization_Set_Input = {
  category?: Maybe<Shop_Category_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  curation_score?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  weight?: Maybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Shop_Categorization_Stddev_Fields = {
  __typename?: 'shop_categorization_stddev_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "shop_categorization" */
export type Shop_Categorization_Stddev_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Shop_Categorization_Stddev_Pop_Fields = {
  __typename?: 'shop_categorization_stddev_pop_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "shop_categorization" */
export type Shop_Categorization_Stddev_Pop_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Shop_Categorization_Stddev_Samp_Fields = {
  __typename?: 'shop_categorization_stddev_samp_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "shop_categorization" */
export type Shop_Categorization_Stddev_Samp_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** Streaming cursor of the table "shop_categorization" */
export type Shop_Categorization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Categorization_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Categorization_Stream_Cursor_Value_Input = {
  category?: Maybe<Shop_Category_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  curation_score?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  weight?: Maybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Shop_Categorization_Sum_Fields = {
  __typename?: 'shop_categorization_sum_fields'
  curation_score?: Maybe<Scalars['Int']>
  weight?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "shop_categorization" */
export type Shop_Categorization_Sum_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** update columns of table "shop_categorization" */
export enum Shop_Categorization_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurationScore = 'curation_score',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weight = 'weight',
}

export type Shop_Categorization_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Shop_Categorization_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Categorization_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Categorization_Bool_Exp
}

/** aggregate var_pop on columns */
export type Shop_Categorization_Var_Pop_Fields = {
  __typename?: 'shop_categorization_var_pop_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "shop_categorization" */
export type Shop_Categorization_Var_Pop_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Shop_Categorization_Var_Samp_Fields = {
  __typename?: 'shop_categorization_var_samp_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "shop_categorization" */
export type Shop_Categorization_Var_Samp_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Shop_Categorization_Variance_Fields = {
  __typename?: 'shop_categorization_variance_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "shop_categorization" */
export type Shop_Categorization_Variance_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** columns and relationships of "shop_categorized_deals" */
export type Shop_Categorized_Deals = {
  __typename?: 'shop_categorized_deals'
  categories?: Maybe<Array<Scalars['String']>>
  deals_count?: Maybe<Scalars['bigint']>
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  /** An object relationship */
  shop?: Maybe<Shop>
}

/** aggregated selection of "shop_categorized_deals" */
export type Shop_Categorized_Deals_Aggregate = {
  __typename?: 'shop_categorized_deals_aggregate'
  aggregate?: Maybe<Shop_Categorized_Deals_Aggregate_Fields>
  nodes: Array<Shop_Categorized_Deals>
}

/** aggregate fields of "shop_categorized_deals" */
export type Shop_Categorized_Deals_Aggregate_Fields = {
  __typename?: 'shop_categorized_deals_aggregate_fields'
  avg?: Maybe<Shop_Categorized_Deals_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Categorized_Deals_Max_Fields>
  min?: Maybe<Shop_Categorized_Deals_Min_Fields>
  stddev?: Maybe<Shop_Categorized_Deals_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Categorized_Deals_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Categorized_Deals_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Categorized_Deals_Sum_Fields>
  var_pop?: Maybe<Shop_Categorized_Deals_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Categorized_Deals_Var_Samp_Fields>
  variance?: Maybe<Shop_Categorized_Deals_Variance_Fields>
}

/** aggregate fields of "shop_categorized_deals" */
export type Shop_Categorized_Deals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Categorized_Deals_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Shop_Categorized_Deals_Avg_Fields = {
  __typename?: 'shop_categorized_deals_avg_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "shop_categorized_deals". All fields are combined with a logical 'AND'. */
export type Shop_Categorized_Deals_Bool_Exp = {
  _and?: Maybe<Array<Shop_Categorized_Deals_Bool_Exp>>
  _not?: Maybe<Shop_Categorized_Deals_Bool_Exp>
  _or?: Maybe<Array<Shop_Categorized_Deals_Bool_Exp>>
  categories?: Maybe<String_Array_Comparison_Exp>
  deals_count?: Maybe<Bigint_Comparison_Exp>
  key?: Maybe<String_Comparison_Exp>
  popularity_rank?: Maybe<Numeric_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
}

/** aggregate max on columns */
export type Shop_Categorized_Deals_Max_Fields = {
  __typename?: 'shop_categorized_deals_max_fields'
  categories?: Maybe<Array<Scalars['String']>>
  deals_count?: Maybe<Scalars['bigint']>
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** aggregate min on columns */
export type Shop_Categorized_Deals_Min_Fields = {
  __typename?: 'shop_categorized_deals_min_fields'
  categories?: Maybe<Array<Scalars['String']>>
  deals_count?: Maybe<Scalars['bigint']>
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** Ordering options when selecting data from "shop_categorized_deals". */
export type Shop_Categorized_Deals_Order_By = {
  categories?: Maybe<Order_By>
  deals_count?: Maybe<Order_By>
  key?: Maybe<Order_By>
  popularity_rank?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
}

/** select columns of table "shop_categorized_deals" */
export enum Shop_Categorized_Deals_Select_Column {
  /** column name */
  Categories = 'categories',
  /** column name */
  DealsCount = 'deals_count',
  /** column name */
  Key = 'key',
  /** column name */
  PopularityRank = 'popularity_rank',
}

/** aggregate stddev on columns */
export type Shop_Categorized_Deals_Stddev_Fields = {
  __typename?: 'shop_categorized_deals_stddev_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Shop_Categorized_Deals_Stddev_Pop_Fields = {
  __typename?: 'shop_categorized_deals_stddev_pop_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Shop_Categorized_Deals_Stddev_Samp_Fields = {
  __typename?: 'shop_categorized_deals_stddev_samp_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "shop_categorized_deals" */
export type Shop_Categorized_Deals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Categorized_Deals_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Categorized_Deals_Stream_Cursor_Value_Input = {
  categories?: Maybe<Array<Scalars['String']>>
  deals_count?: Maybe<Scalars['bigint']>
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** aggregate sum on columns */
export type Shop_Categorized_Deals_Sum_Fields = {
  __typename?: 'shop_categorized_deals_sum_fields'
  deals_count?: Maybe<Scalars['bigint']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type Shop_Categorized_Deals_Var_Pop_Fields = {
  __typename?: 'shop_categorized_deals_var_pop_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Shop_Categorized_Deals_Var_Samp_Fields = {
  __typename?: 'shop_categorized_deals_var_samp_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Shop_Categorized_Deals_Variance_Fields = {
  __typename?: 'shop_categorized_deals_variance_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** columns and relationships of "shop_category" */
export type Shop_Category = {
  __typename?: 'shop_category'
  label?: Maybe<Scalars['String']>
  /** An object relationship */
  onboarding_category?: Maybe<Onboarding_Categories>
  /** An array relationship */
  shop_categorizations: Array<Shop_Categorization>
  /** An aggregate relationship */
  shop_categorizations_aggregate: Shop_Categorization_Aggregate
  value: Scalars['String']
}

/** columns and relationships of "shop_category" */
export type Shop_CategoryShop_CategorizationsArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

/** columns and relationships of "shop_category" */
export type Shop_CategoryShop_Categorizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

/** aggregated selection of "shop_category" */
export type Shop_Category_Aggregate = {
  __typename?: 'shop_category_aggregate'
  aggregate?: Maybe<Shop_Category_Aggregate_Fields>
  nodes: Array<Shop_Category>
}

/** aggregate fields of "shop_category" */
export type Shop_Category_Aggregate_Fields = {
  __typename?: 'shop_category_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Category_Max_Fields>
  min?: Maybe<Shop_Category_Min_Fields>
}

/** aggregate fields of "shop_category" */
export type Shop_Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Category_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "shop_category". All fields are combined with a logical 'AND'. */
export type Shop_Category_Bool_Exp = {
  _and?: Maybe<Array<Shop_Category_Bool_Exp>>
  _not?: Maybe<Shop_Category_Bool_Exp>
  _or?: Maybe<Array<Shop_Category_Bool_Exp>>
  label?: Maybe<String_Comparison_Exp>
  onboarding_category?: Maybe<Onboarding_Categories_Bool_Exp>
  shop_categorizations?: Maybe<Shop_Categorization_Bool_Exp>
  shop_categorizations_aggregate?: Maybe<Shop_Categorization_Aggregate_Bool_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_category" */
export enum Shop_Category_Constraint {
  /** unique or primary key constraint on columns "value" */
  ShopCategoryPkey = 'shop_category_pkey',
}

export enum Shop_Category_Enum {
  /** Arts & Crafts */
  ArtsCrafts = 'arts_crafts',
  /** Baby & Kids */
  BabyKids = 'baby_kids',
  /** Beauty & Cosmetics */
  BeautyCosmetics = 'beauty_cosmetics',
  /** Books */
  Books = 'books',
  /** DIY */
  Dyi = 'dyi',
  /** Electronics & Media */
  ElectronicsMedia = 'electronics_media',
  /** Eyewear */
  Eyewear = 'eyewear',
  /** Fashion & Apparel */
  FashionApparel = 'fashion_apparel',
  /** Food & Beverage */
  FoodBeverage = 'food_beverage',
  /** Footwear & Shoes */
  FootwearShoes = 'footwear_shoes',
  /** Gaming & Entertainment */
  GamingEntertainment = 'gaming_entertainment',
  /** Haute Couture */
  HauteCouture = 'haute_couture',
  /** Health, Fitness & Nutrition */
  HealthFitnessNutrition = 'health_fitness_nutrition',
  /** Home, Kitchen & Decor */
  HomeKitchenDecor = 'home_kitchen_decor',
  /** Intimates */
  Intimates = 'intimates',
  /** Jewelry */
  Jewelry = 'jewelry',
  /** Office */
  Office = 'office',
  /** Omnishop */
  Omnishop = 'omnishop',
  /** Outdoor & Adventure */
  OutdoorAdventure = 'outdoor_adventure',
  /** Pets & Animals */
  PetsAnimals = 'pets_animals',
  /** Plants & Flowers */
  PlantsFlowers = 'plants_flowers',
  /** Sleep */
  Sleep = 'sleep',
  /** Social Platform */
  SocialPlatform = 'social_platform',
  /** Sports & Athletics */
  SportsAthletics = 'sports_athletics',
  /** Tobacco and Related Products */
  TobaccoRelatedProducts = 'tobacco_related_products',
  /** Travel */
  Travel = 'travel',
  /** Well-being */
  Wellbeing = 'wellbeing',
}

/** Boolean expression to compare columns of type "shop_category_enum". All fields are combined with logical 'AND'. */
export type Shop_Category_Enum_Comparison_Exp = {
  _eq?: Maybe<Shop_Category_Enum>
  _in?: Maybe<Array<Shop_Category_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Shop_Category_Enum>
  _nin?: Maybe<Array<Shop_Category_Enum>>
}

/** input type for inserting data into table "shop_category" */
export type Shop_Category_Insert_Input = {
  label?: Maybe<Scalars['String']>
  onboarding_category?: Maybe<Onboarding_Categories_Obj_Rel_Insert_Input>
  shop_categorizations?: Maybe<Shop_Categorization_Arr_Rel_Insert_Input>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Shop_Category_Max_Fields = {
  __typename?: 'shop_category_max_fields'
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Shop_Category_Min_Fields = {
  __typename?: 'shop_category_min_fields'
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "shop_category" */
export type Shop_Category_Mutation_Response = {
  __typename?: 'shop_category_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Category>
}

/** input type for inserting object relation for remote table "shop_category" */
export type Shop_Category_Obj_Rel_Insert_Input = {
  data: Shop_Category_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Shop_Category_On_Conflict>
}

/** on_conflict condition type for table "shop_category" */
export type Shop_Category_On_Conflict = {
  constraint: Shop_Category_Constraint
  update_columns?: Array<Shop_Category_Update_Column>
  where?: Maybe<Shop_Category_Bool_Exp>
}

/** Ordering options when selecting data from "shop_category". */
export type Shop_Category_Order_By = {
  label?: Maybe<Order_By>
  onboarding_category?: Maybe<Onboarding_Categories_Order_By>
  shop_categorizations_aggregate?: Maybe<Shop_Categorization_Aggregate_Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: shop_category */
export type Shop_Category_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "shop_category" */
export enum Shop_Category_Select_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "shop_category" */
export type Shop_Category_Set_Input = {
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "shop_category" */
export type Shop_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Category_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Category_Stream_Cursor_Value_Input = {
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "shop_category" */
export enum Shop_Category_Update_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Value = 'value',
}

export type Shop_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Category_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Category_Bool_Exp
}

export type Shop_Collections_Args = {
  shop_key?: Maybe<Scalars['String']>
}

/** unique or primary key constraints on table "shop" */
export enum Shop_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopIdKey = 'shop_id_key',
  /** unique or primary key constraint on columns "key" */
  ShopPkey = 'shop_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shop_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
  shop_faqs?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shop_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
  shop_faqs?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shop_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
  shop_faqs?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "shop" */
export type Shop_Insert_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  associated_affiliate?: Maybe<Affiliate_Obj_Rel_Insert_Input>
  banner_image?: Maybe<Scalars['String']>
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>
  cart_path?: Maybe<Scalars['String']>
  carts?: Maybe<Cart_Arr_Rel_Insert_Input>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  editorial_shop_logo?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  featured?: Maybe<Scalars['Boolean']>
  featured_shop?: Maybe<Featured_Shop_Obj_Rel_Insert_Input>
  has_deals?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  items?: Maybe<Shop_Item_Arr_Rel_Insert_Input>
  key?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['name']>
  name_override?: Maybe<Scalars['String']>
  parent_shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  parent_shop_key?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  potential_spam?: Maybe<Scalars['Boolean']>
  search_tokens?: Maybe<Array<Scalars['String']>>
  shop_affiliates?: Maybe<Shop_Affiliate_Arr_Rel_Insert_Input>
  shop_categorizations?: Maybe<Shop_Categorization_Arr_Rel_Insert_Input>
  shop_faqs?: Maybe<Scalars['jsonb']>
  shop_popularity?: Maybe<Shop_Popularity_Obj_Rel_Insert_Input>
  slug?: Maybe<Scalars['name']>
  type?: Maybe<Scalars['name']>
  unknown?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "shop_item" */
export type Shop_Item = {
  __typename?: 'shop_item'
  availability?: Maybe<Shop_Item_Availability_Enum>
  /** The generated cached image url that is based on our Imgix web proxy source */
  cached_image?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_item_cached_placeholder_image" */
  cached_placeholder_image?: Maybe<Scalars['String']>
  /** An array relationship */
  cart_items: Array<Cart_Item>
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate
  categories?: Maybe<Array<Scalars['String']>>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "shop_item_indexed" */
  computed_should_index?: Maybe<Scalars['Boolean']>
  created_at: Scalars['timestamptz']
  description?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_item_display_title" */
  display_title?: Maybe<Scalars['String']>
  enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum>
  /** A computed field, executes function "shop_item_has_broken_image" */
  has_broken_image?: Maybe<Scalars['Boolean']>
  hashtags?: Maybe<Array<Scalars['String']>>
  id: Scalars['uuid']
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_highres?: Maybe<Scalars['String']>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  is_inappropriate: Scalars['Boolean']
  item_id: Scalars['String']
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  onboarding_categories?: Maybe<Array<Scalars['String']>>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pdp_views?: Maybe<Scalars['Int']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
  pricing?: Maybe<Scalars['jsonb']>
  /** A function used to determine if a `shop_item` has been saved by the requesting user. */
  saved_by_current_user?: Maybe<Scalars['Boolean']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  serapi_metadata?: Maybe<Scalars['jsonb']>
  serpapi_metadata?: Maybe<Scalars['jsonb']>
  sharesheet_html?: Maybe<Scalars['String']>
  /** An object relationship */
  shop: Shop
  /** An object relationship */
  shop_item_availability?: Maybe<Shop_Item_Availability>
  /** An array relationship */
  shop_item_histories: Array<Shop_Item_History>
  /** An aggregate relationship */
  shop_item_histories_aggregate: Shop_Item_History_Aggregate
  /** An array relationship */
  shop_item_images: Array<Shop_Item_Image>
  /** An aggregate relationship */
  shop_item_images_aggregate: Shop_Item_Image_Aggregate
  shop_key: Scalars['String']
  /** An array relationship */
  similar_products_histories: Array<Similar_Products_History>
  /** An aggregate relationship */
  similar_products_histories_aggregate: Similar_Products_History_Aggregate
  /** An object relationship */
  similar_products_history?: Maybe<Similar_Products_History>
  similar_products_history_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
  url?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemImage_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemParsed_PricingArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemPricingArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemSchema_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemSerapi_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemSerpapi_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemShop_Item_HistoriesArgs = {
  distinct_on?: Maybe<Array<Shop_Item_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_History_Order_By>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemShop_Item_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_History_Order_By>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemShop_Item_ImagesArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Image_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Image_Order_By>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemShop_Item_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Image_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Image_Order_By>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemSimilar_Products_HistoriesArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemSimilar_Products_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** aggregated selection of "shop_item" */
export type Shop_Item_Aggregate = {
  __typename?: 'shop_item_aggregate'
  aggregate?: Maybe<Shop_Item_Aggregate_Fields>
  nodes: Array<Shop_Item>
}

export type Shop_Item_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Shop_Item_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Shop_Item_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Shop_Item_Aggregate_Bool_Exp_Count>
}

export type Shop_Item_Aggregate_Bool_Exp_Bool_And = {
  arguments: Shop_Item_Select_Column_Shop_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Shop_Item_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Shop_Item_Select_Column_Shop_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Shop_Item_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shop_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "shop_item" */
export type Shop_Item_Aggregate_Fields = {
  __typename?: 'shop_item_aggregate_fields'
  avg?: Maybe<Shop_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Item_Max_Fields>
  min?: Maybe<Shop_Item_Min_Fields>
  stddev?: Maybe<Shop_Item_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Item_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Item_Sum_Fields>
  var_pop?: Maybe<Shop_Item_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Item_Var_Samp_Fields>
  variance?: Maybe<Shop_Item_Variance_Fields>
}

/** aggregate fields of "shop_item" */
export type Shop_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "shop_item" */
export type Shop_Item_Aggregate_Order_By = {
  avg?: Maybe<Shop_Item_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Shop_Item_Max_Order_By>
  min?: Maybe<Shop_Item_Min_Order_By>
  stddev?: Maybe<Shop_Item_Stddev_Order_By>
  stddev_pop?: Maybe<Shop_Item_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Shop_Item_Stddev_Samp_Order_By>
  sum?: Maybe<Shop_Item_Sum_Order_By>
  var_pop?: Maybe<Shop_Item_Var_Pop_Order_By>
  var_samp?: Maybe<Shop_Item_Var_Samp_Order_By>
  variance?: Maybe<Shop_Item_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_Append_Input = {
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  serapi_metadata?: Maybe<Scalars['jsonb']>
  serpapi_metadata?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "shop_item" */
export type Shop_Item_Arr_Rel_Insert_Input = {
  data: Array<Shop_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Shop_Item_On_Conflict>
}

/** Shop item availability Enum table */
export type Shop_Item_Availability = {
  __typename?: 'shop_item_availability'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "shop_item_availability" */
export type Shop_Item_Availability_Aggregate = {
  __typename?: 'shop_item_availability_aggregate'
  aggregate?: Maybe<Shop_Item_Availability_Aggregate_Fields>
  nodes: Array<Shop_Item_Availability>
}

/** aggregate fields of "shop_item_availability" */
export type Shop_Item_Availability_Aggregate_Fields = {
  __typename?: 'shop_item_availability_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Item_Availability_Max_Fields>
  min?: Maybe<Shop_Item_Availability_Min_Fields>
}

/** aggregate fields of "shop_item_availability" */
export type Shop_Item_Availability_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_Availability_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "shop_item_availability". All fields are combined with a logical 'AND'. */
export type Shop_Item_Availability_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_Availability_Bool_Exp>>
  _not?: Maybe<Shop_Item_Availability_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_Availability_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_item_availability" */
export enum Shop_Item_Availability_Constraint {
  /** unique or primary key constraint on columns "value" */
  ShopItemAvailabilityPkey = 'shop_item_availability_pkey',
}

export enum Shop_Item_Availability_Enum {
  /** Back Order */
  BackOrder = 'BackOrder',
  /** Discontinued */
  Discontinued = 'Discontinued',
  /** In Stock */
  InStock = 'InStock',
  /** In Store Only */
  InStoreOnly = 'InStoreOnly',
  /** Limited Availability */
  LimitedAvailability = 'LimitedAvailability',
  /** Online Only */
  OnlineOnly = 'OnlineOnly',
  /** Out Of Stock */
  OutOfStock = 'OutOfStock',
  /** Pre-Order */
  PreOrder = 'PreOrder',
  /** Pre-Sale */
  PreSale = 'PreSale',
  /** Sold Out */
  SoldOut = 'SoldOut',
  /** Unknown */
  Unknown = 'Unknown',
}

/** Boolean expression to compare columns of type "shop_item_availability_enum". All fields are combined with logical 'AND'. */
export type Shop_Item_Availability_Enum_Comparison_Exp = {
  _eq?: Maybe<Shop_Item_Availability_Enum>
  _in?: Maybe<Array<Shop_Item_Availability_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Shop_Item_Availability_Enum>
  _nin?: Maybe<Array<Shop_Item_Availability_Enum>>
}

/** input type for inserting data into table "shop_item_availability" */
export type Shop_Item_Availability_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Shop_Item_Availability_Max_Fields = {
  __typename?: 'shop_item_availability_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Shop_Item_Availability_Min_Fields = {
  __typename?: 'shop_item_availability_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "shop_item_availability" */
export type Shop_Item_Availability_Mutation_Response = {
  __typename?: 'shop_item_availability_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item_Availability>
}

/** input type for inserting object relation for remote table "shop_item_availability" */
export type Shop_Item_Availability_Obj_Rel_Insert_Input = {
  data: Shop_Item_Availability_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Shop_Item_Availability_On_Conflict>
}

/** on_conflict condition type for table "shop_item_availability" */
export type Shop_Item_Availability_On_Conflict = {
  constraint: Shop_Item_Availability_Constraint
  update_columns?: Array<Shop_Item_Availability_Update_Column>
  where?: Maybe<Shop_Item_Availability_Bool_Exp>
}

/** Ordering options when selecting data from "shop_item_availability". */
export type Shop_Item_Availability_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: shop_item_availability */
export type Shop_Item_Availability_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "shop_item_availability" */
export enum Shop_Item_Availability_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "shop_item_availability" */
export type Shop_Item_Availability_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "shop_item_availability" */
export type Shop_Item_Availability_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_Availability_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_Availability_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "shop_item_availability" */
export enum Shop_Item_Availability_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Shop_Item_Availability_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_Availability_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_Availability_Bool_Exp
}

/** aggregate avg on columns */
export type Shop_Item_Avg_Fields = {
  __typename?: 'shop_item_avg_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by avg() on columns of table "shop_item" */
export type Shop_Item_Avg_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "shop_item". All fields are combined with a logical 'AND'. */
export type Shop_Item_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_Bool_Exp>>
  _not?: Maybe<Shop_Item_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_Bool_Exp>>
  availability?: Maybe<Shop_Item_Availability_Enum_Comparison_Exp>
  cached_image?: Maybe<String_Comparison_Exp>
  cached_placeholder_image?: Maybe<String_Comparison_Exp>
  cart_items?: Maybe<Cart_Item_Bool_Exp>
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Bool_Exp>
  categories?: Maybe<String_Array_Comparison_Exp>
  color_thief_processed?: Maybe<Boolean_Comparison_Exp>
  computed_should_index?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  display_title?: Maybe<String_Comparison_Exp>
  enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum_Comparison_Exp>
  has_broken_image?: Maybe<Boolean_Comparison_Exp>
  hashtags?: Maybe<String_Array_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  image?: Maybe<String_Comparison_Exp>
  image_color_palette?: Maybe<Smallint_Array_Comparison_Exp>
  image_highres?: Maybe<String_Comparison_Exp>
  image_metadata?: Maybe<Jsonb_Comparison_Exp>
  image_primary_b?: Maybe<Smallint_Comparison_Exp>
  image_primary_g?: Maybe<Smallint_Comparison_Exp>
  image_primary_r?: Maybe<Smallint_Comparison_Exp>
  is_inappropriate?: Maybe<Boolean_Comparison_Exp>
  item_id?: Maybe<String_Comparison_Exp>
  keywords?: Maybe<String_Array_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  number_of_collections?: Maybe<Int_Comparison_Exp>
  onboarding_categories?: Maybe<String_Array_Comparison_Exp>
  open_ai_attempts?: Maybe<Smallint_Comparison_Exp>
  parsed_pricing?: Maybe<Jsonb_Comparison_Exp>
  pdp_views?: Maybe<Int_Comparison_Exp>
  points?: Maybe<Numeric_Comparison_Exp>
  pricing?: Maybe<Jsonb_Comparison_Exp>
  saved_by_current_user?: Maybe<Boolean_Comparison_Exp>
  schema_metadata?: Maybe<Jsonb_Comparison_Exp>
  serapi_metadata?: Maybe<Jsonb_Comparison_Exp>
  serpapi_metadata?: Maybe<Jsonb_Comparison_Exp>
  sharesheet_html?: Maybe<String_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_item_availability?: Maybe<Shop_Item_Availability_Bool_Exp>
  shop_item_histories?: Maybe<Shop_Item_History_Bool_Exp>
  shop_item_histories_aggregate?: Maybe<Shop_Item_History_Aggregate_Bool_Exp>
  shop_item_images?: Maybe<Shop_Item_Image_Bool_Exp>
  shop_item_images_aggregate?: Maybe<Shop_Item_Image_Aggregate_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  similar_products_histories?: Maybe<Similar_Products_History_Bool_Exp>
  similar_products_histories_aggregate?: Maybe<Similar_Products_History_Aggregate_Bool_Exp>
  similar_products_history?: Maybe<Similar_Products_History_Bool_Exp>
  similar_products_history_id?: Maybe<Uuid_Comparison_Exp>
  source?: Maybe<String_Comparison_Exp>
  title?: Maybe<String_Comparison_Exp>
  to_be_scraped?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  url?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_item" */
export enum Shop_Item_Constraint {
  /** unique or primary key constraint on columns "shop_key", "id" */
  ShopItemIdShopKeyKey = 'shop_item_id_shop_key_key',
  /** unique or primary key constraint on columns "id" */
  ShopItemPkey = 'shop_item_pkey',
  /** unique or primary key constraint on columns "shop_key", "item_id" */
  ShopItemShopKeyItemIdKey = 'shop_item_shop_key_item_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shop_Item_Delete_At_Path_Input = {
  image_metadata?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Array<Scalars['String']>>
  parsed_pricing?: Maybe<Array<Scalars['String']>>
  pricing?: Maybe<Array<Scalars['String']>>
  schema_metadata?: Maybe<Array<Scalars['String']>>
  serapi_metadata?: Maybe<Array<Scalars['String']>>
  serpapi_metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shop_Item_Delete_Elem_Input = {
  image_metadata?: Maybe<Scalars['Int']>
  metadata?: Maybe<Scalars['Int']>
  parsed_pricing?: Maybe<Scalars['Int']>
  pricing?: Maybe<Scalars['Int']>
  schema_metadata?: Maybe<Scalars['Int']>
  serapi_metadata?: Maybe<Scalars['Int']>
  serpapi_metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shop_Item_Delete_Key_Input = {
  image_metadata?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['String']>
  parsed_pricing?: Maybe<Scalars['String']>
  pricing?: Maybe<Scalars['String']>
  schema_metadata?: Maybe<Scalars['String']>
  serapi_metadata?: Maybe<Scalars['String']>
  serpapi_metadata?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status = {
  __typename?: 'shop_item_enrichment_status'
  description: Scalars['String']
  value: Scalars['String']
}

/** aggregated selection of "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Aggregate = {
  __typename?: 'shop_item_enrichment_status_aggregate'
  aggregate?: Maybe<Shop_Item_Enrichment_Status_Aggregate_Fields>
  nodes: Array<Shop_Item_Enrichment_Status>
}

/** aggregate fields of "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Aggregate_Fields = {
  __typename?: 'shop_item_enrichment_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Item_Enrichment_Status_Max_Fields>
  min?: Maybe<Shop_Item_Enrichment_Status_Min_Fields>
}

/** aggregate fields of "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_Enrichment_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "shop_item_enrichment_status". All fields are combined with a logical 'AND'. */
export type Shop_Item_Enrichment_Status_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_Enrichment_Status_Bool_Exp>>
  _not?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_Enrichment_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_item_enrichment_status" */
export enum Shop_Item_Enrichment_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnrichmentStatusPkey = 'enrichment_status_pkey',
}

export enum Shop_Item_Enrichment_Status_Enum {
  Failed = 'failed',
  /** A status to let the custom database trigger know to set either the succeeded or failed status */
  FinishedScraperApi = 'finished_scraper_api',
  NoOp = 'no_op',
  Pending = 'pending',
  /** A status to indicate that we are awaiting results from scraper API */
  PendingScraperApi = 'pending_scraper_api',
  Processing = 'processing',
  Succeeded = 'succeeded',
  /** Used to trigger a shop item enrichment upon insertion into the database */
  Trigger = 'trigger',
}

/** Boolean expression to compare columns of type "shop_item_enrichment_status_enum". All fields are combined with logical 'AND'. */
export type Shop_Item_Enrichment_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Shop_Item_Enrichment_Status_Enum>
  _in?: Maybe<Array<Shop_Item_Enrichment_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Shop_Item_Enrichment_Status_Enum>
  _nin?: Maybe<Array<Shop_Item_Enrichment_Status_Enum>>
}

/** input type for inserting data into table "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Shop_Item_Enrichment_Status_Max_Fields = {
  __typename?: 'shop_item_enrichment_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Shop_Item_Enrichment_Status_Min_Fields = {
  __typename?: 'shop_item_enrichment_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Mutation_Response = {
  __typename?: 'shop_item_enrichment_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item_Enrichment_Status>
}

/** on_conflict condition type for table "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_On_Conflict = {
  constraint: Shop_Item_Enrichment_Status_Constraint
  update_columns?: Array<Shop_Item_Enrichment_Status_Update_Column>
  where?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
}

/** Ordering options when selecting data from "shop_item_enrichment_status". */
export type Shop_Item_Enrichment_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: shop_item_enrichment_status */
export type Shop_Item_Enrichment_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "shop_item_enrichment_status" */
export enum Shop_Item_Enrichment_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_Enrichment_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_Enrichment_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "shop_item_enrichment_status" */
export enum Shop_Item_Enrichment_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Shop_Item_Enrichment_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_Enrichment_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_Enrichment_Status_Bool_Exp
}

/** columns and relationships of "shop_item_history" */
export type Shop_Item_History = {
  __typename?: 'shop_item_history'
  availability?: Maybe<Shop_Item_Availability_Enum>
  created_at: Scalars['timestamptz']
  http_status_code?: Maybe<Scalars['Int']>
  id: Scalars['uuid']
  metadata: Scalars['jsonb']
  possible_unavailable: Scalars['Boolean']
  pricing?: Maybe<Scalars['jsonb']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
  /** An object relationship */
  shop_item: Shop_Item
  /** An object relationship */
  shop_item_availability?: Maybe<Shop_Item_Availability>
  shop_item_id: Scalars['uuid']
  strategy: Scalars['String']
  updated_at: Scalars['timestamptz']
  valid: Scalars['Boolean']
}

/** columns and relationships of "shop_item_history" */
export type Shop_Item_HistoryMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item_history" */
export type Shop_Item_HistoryPricingArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "shop_item_history" */
export type Shop_Item_History_Aggregate = {
  __typename?: 'shop_item_history_aggregate'
  aggregate?: Maybe<Shop_Item_History_Aggregate_Fields>
  nodes: Array<Shop_Item_History>
}

export type Shop_Item_History_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Shop_Item_History_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Shop_Item_History_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Shop_Item_History_Aggregate_Bool_Exp_Count>
}

export type Shop_Item_History_Aggregate_Bool_Exp_Bool_And = {
  arguments: Shop_Item_History_Select_Column_Shop_Item_History_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_History_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Shop_Item_History_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Shop_Item_History_Select_Column_Shop_Item_History_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_History_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Shop_Item_History_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shop_Item_History_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_History_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "shop_item_history" */
export type Shop_Item_History_Aggregate_Fields = {
  __typename?: 'shop_item_history_aggregate_fields'
  avg?: Maybe<Shop_Item_History_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Item_History_Max_Fields>
  min?: Maybe<Shop_Item_History_Min_Fields>
  stddev?: Maybe<Shop_Item_History_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Item_History_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Item_History_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Item_History_Sum_Fields>
  var_pop?: Maybe<Shop_Item_History_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Item_History_Var_Samp_Fields>
  variance?: Maybe<Shop_Item_History_Variance_Fields>
}

/** aggregate fields of "shop_item_history" */
export type Shop_Item_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_History_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "shop_item_history" */
export type Shop_Item_History_Aggregate_Order_By = {
  avg?: Maybe<Shop_Item_History_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Shop_Item_History_Max_Order_By>
  min?: Maybe<Shop_Item_History_Min_Order_By>
  stddev?: Maybe<Shop_Item_History_Stddev_Order_By>
  stddev_pop?: Maybe<Shop_Item_History_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Shop_Item_History_Stddev_Samp_Order_By>
  sum?: Maybe<Shop_Item_History_Sum_Order_By>
  var_pop?: Maybe<Shop_Item_History_Var_Pop_Order_By>
  var_samp?: Maybe<Shop_Item_History_Var_Samp_Order_By>
  variance?: Maybe<Shop_Item_History_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_History_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "shop_item_history" */
export type Shop_Item_History_Arr_Rel_Insert_Input = {
  data: Array<Shop_Item_History_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Shop_Item_History_On_Conflict>
}

/** aggregate avg on columns */
export type Shop_Item_History_Avg_Fields = {
  __typename?: 'shop_item_history_avg_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "shop_item_history" */
export type Shop_Item_History_Avg_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "shop_item_history". All fields are combined with a logical 'AND'. */
export type Shop_Item_History_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_History_Bool_Exp>>
  _not?: Maybe<Shop_Item_History_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_History_Bool_Exp>>
  availability?: Maybe<Shop_Item_Availability_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  http_status_code?: Maybe<Int_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  possible_unavailable?: Maybe<Boolean_Comparison_Exp>
  pricing?: Maybe<Jsonb_Comparison_Exp>
  scraper_http_status_code?: Maybe<Int_Comparison_Exp>
  shop_item?: Maybe<Shop_Item_Bool_Exp>
  shop_item_availability?: Maybe<Shop_Item_Availability_Bool_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  strategy?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  valid?: Maybe<Boolean_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_item_history" */
export enum Shop_Item_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopItemHistoryPkey = 'shop_item_history_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shop_Item_History_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
  pricing?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shop_Item_History_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
  pricing?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shop_Item_History_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
  pricing?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "shop_item_history" */
export type Shop_Item_History_Inc_Input = {
  http_status_code?: Maybe<Scalars['Int']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "shop_item_history" */
export type Shop_Item_History_Insert_Input = {
  availability?: Maybe<Shop_Item_Availability_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  http_status_code?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  possible_unavailable?: Maybe<Scalars['Boolean']>
  pricing?: Maybe<Scalars['jsonb']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
  shop_item?: Maybe<Shop_Item_Obj_Rel_Insert_Input>
  shop_item_availability?: Maybe<Shop_Item_Availability_Obj_Rel_Insert_Input>
  shop_item_id?: Maybe<Scalars['uuid']>
  strategy?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  valid?: Maybe<Scalars['Boolean']>
}

/** aggregate max on columns */
export type Shop_Item_History_Max_Fields = {
  __typename?: 'shop_item_history_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  http_status_code?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
  shop_item_id?: Maybe<Scalars['uuid']>
  strategy?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "shop_item_history" */
export type Shop_Item_History_Max_Order_By = {
  created_at?: Maybe<Order_By>
  http_status_code?: Maybe<Order_By>
  id?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  strategy?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Shop_Item_History_Min_Fields = {
  __typename?: 'shop_item_history_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  http_status_code?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
  shop_item_id?: Maybe<Scalars['uuid']>
  strategy?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "shop_item_history" */
export type Shop_Item_History_Min_Order_By = {
  created_at?: Maybe<Order_By>
  http_status_code?: Maybe<Order_By>
  id?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  strategy?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "shop_item_history" */
export type Shop_Item_History_Mutation_Response = {
  __typename?: 'shop_item_history_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item_History>
}

/** on_conflict condition type for table "shop_item_history" */
export type Shop_Item_History_On_Conflict = {
  constraint: Shop_Item_History_Constraint
  update_columns?: Array<Shop_Item_History_Update_Column>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

/** Ordering options when selecting data from "shop_item_history". */
export type Shop_Item_History_Order_By = {
  availability?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  http_status_code?: Maybe<Order_By>
  id?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  possible_unavailable?: Maybe<Order_By>
  pricing?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
  shop_item?: Maybe<Shop_Item_Order_By>
  shop_item_availability?: Maybe<Shop_Item_Availability_Order_By>
  shop_item_id?: Maybe<Order_By>
  strategy?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  valid?: Maybe<Order_By>
}

/** primary key columns input for table: shop_item_history */
export type Shop_Item_History_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_History_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
}

/** select columns of table "shop_item_history" */
export enum Shop_Item_History_Select_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HttpStatusCode = 'http_status_code',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PossibleUnavailable = 'possible_unavailable',
  /** column name */
  Pricing = 'pricing',
  /** column name */
  ScraperHttpStatusCode = 'scraper_http_status_code',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  Strategy = 'strategy',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Valid = 'valid',
}

/** select "shop_item_history_aggregate_bool_exp_bool_and_arguments_columns" columns of table "shop_item_history" */
export enum Shop_Item_History_Select_Column_Shop_Item_History_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PossibleUnavailable = 'possible_unavailable',
  /** column name */
  Valid = 'valid',
}

/** select "shop_item_history_aggregate_bool_exp_bool_or_arguments_columns" columns of table "shop_item_history" */
export enum Shop_Item_History_Select_Column_Shop_Item_History_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PossibleUnavailable = 'possible_unavailable',
  /** column name */
  Valid = 'valid',
}

/** input type for updating data in table "shop_item_history" */
export type Shop_Item_History_Set_Input = {
  availability?: Maybe<Shop_Item_Availability_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  http_status_code?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  possible_unavailable?: Maybe<Scalars['Boolean']>
  pricing?: Maybe<Scalars['jsonb']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
  shop_item_id?: Maybe<Scalars['uuid']>
  strategy?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  valid?: Maybe<Scalars['Boolean']>
}

/** aggregate stddev on columns */
export type Shop_Item_History_Stddev_Fields = {
  __typename?: 'shop_item_history_stddev_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "shop_item_history" */
export type Shop_Item_History_Stddev_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Shop_Item_History_Stddev_Pop_Fields = {
  __typename?: 'shop_item_history_stddev_pop_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "shop_item_history" */
export type Shop_Item_History_Stddev_Pop_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Shop_Item_History_Stddev_Samp_Fields = {
  __typename?: 'shop_item_history_stddev_samp_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "shop_item_history" */
export type Shop_Item_History_Stddev_Samp_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** Streaming cursor of the table "shop_item_history" */
export type Shop_Item_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_History_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_History_Stream_Cursor_Value_Input = {
  availability?: Maybe<Shop_Item_Availability_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  http_status_code?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  possible_unavailable?: Maybe<Scalars['Boolean']>
  pricing?: Maybe<Scalars['jsonb']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
  shop_item_id?: Maybe<Scalars['uuid']>
  strategy?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  valid?: Maybe<Scalars['Boolean']>
}

/** aggregate sum on columns */
export type Shop_Item_History_Sum_Fields = {
  __typename?: 'shop_item_history_sum_fields'
  http_status_code?: Maybe<Scalars['Int']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "shop_item_history" */
export type Shop_Item_History_Sum_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** update columns of table "shop_item_history" */
export enum Shop_Item_History_Update_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HttpStatusCode = 'http_status_code',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PossibleUnavailable = 'possible_unavailable',
  /** column name */
  Pricing = 'pricing',
  /** column name */
  ScraperHttpStatusCode = 'scraper_http_status_code',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  Strategy = 'strategy',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Valid = 'valid',
}

export type Shop_Item_History_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Shop_Item_History_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Shop_Item_History_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Shop_Item_History_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Shop_Item_History_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Shop_Item_History_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Shop_Item_History_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_History_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_History_Bool_Exp
}

/** aggregate var_pop on columns */
export type Shop_Item_History_Var_Pop_Fields = {
  __typename?: 'shop_item_history_var_pop_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "shop_item_history" */
export type Shop_Item_History_Var_Pop_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Shop_Item_History_Var_Samp_Fields = {
  __typename?: 'shop_item_history_var_samp_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "shop_item_history" */
export type Shop_Item_History_Var_Samp_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Shop_Item_History_Variance_Fields = {
  __typename?: 'shop_item_history_variance_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "shop_item_history" */
export type Shop_Item_History_Variance_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** This table contains the list of images of one specifi item */
export type Shop_Item_Image = {
  __typename?: 'shop_item_image'
  /** A computed field, executes function "shop_item_image_cached" */
  cached?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata: Scalars['jsonb']
  /** An object relationship */
  shop_item: Shop_Item
  shop_item_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  url: Scalars['String']
}

/** This table contains the list of images of one specifi item */
export type Shop_Item_ImageImage_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** This table contains the list of images of one specifi item */
export type Shop_Item_ImageMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "shop_item_image" */
export type Shop_Item_Image_Aggregate = {
  __typename?: 'shop_item_image_aggregate'
  aggregate?: Maybe<Shop_Item_Image_Aggregate_Fields>
  nodes: Array<Shop_Item_Image>
}

export type Shop_Item_Image_Aggregate_Bool_Exp = {
  count?: Maybe<Shop_Item_Image_Aggregate_Bool_Exp_Count>
}

export type Shop_Item_Image_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shop_Item_Image_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_Image_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "shop_item_image" */
export type Shop_Item_Image_Aggregate_Fields = {
  __typename?: 'shop_item_image_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Item_Image_Max_Fields>
  min?: Maybe<Shop_Item_Image_Min_Fields>
}

/** aggregate fields of "shop_item_image" */
export type Shop_Item_Image_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_Image_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "shop_item_image" */
export type Shop_Item_Image_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Shop_Item_Image_Max_Order_By>
  min?: Maybe<Shop_Item_Image_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_Image_Append_Input = {
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "shop_item_image" */
export type Shop_Item_Image_Arr_Rel_Insert_Input = {
  data: Array<Shop_Item_Image_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Shop_Item_Image_On_Conflict>
}

/** Boolean expression to filter rows from the table "shop_item_image". All fields are combined with a logical 'AND'. */
export type Shop_Item_Image_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_Image_Bool_Exp>>
  _not?: Maybe<Shop_Item_Image_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_Image_Bool_Exp>>
  cached?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  image_metadata?: Maybe<Jsonb_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  shop_item?: Maybe<Shop_Item_Bool_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  url?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_item_image" */
export enum Shop_Item_Image_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopItemImagePkey = 'shop_item_image_pkey',
  /** unique or primary key constraint on columns "shop_item_id", "url" */
  ShopItemImageShopItemIdUrlKey = 'shop_item_image_shop_item_id_url_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shop_Item_Image_Delete_At_Path_Input = {
  image_metadata?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shop_Item_Image_Delete_Elem_Input = {
  image_metadata?: Maybe<Scalars['Int']>
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shop_Item_Image_Delete_Key_Input = {
  image_metadata?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "shop_item_image" */
export type Shop_Item_Image_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
  shop_item?: Maybe<Shop_Item_Obj_Rel_Insert_Input>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Shop_Item_Image_Max_Fields = {
  __typename?: 'shop_item_image_max_fields'
  /** A computed field, executes function "shop_item_image_cached" */
  cached?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "shop_item_image" */
export type Shop_Item_Image_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Shop_Item_Image_Min_Fields = {
  __typename?: 'shop_item_image_min_fields'
  /** A computed field, executes function "shop_item_image_cached" */
  cached?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "shop_item_image" */
export type Shop_Item_Image_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** response of any mutation on the table "shop_item_image" */
export type Shop_Item_Image_Mutation_Response = {
  __typename?: 'shop_item_image_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item_Image>
}

/** on_conflict condition type for table "shop_item_image" */
export type Shop_Item_Image_On_Conflict = {
  constraint: Shop_Item_Image_Constraint
  update_columns?: Array<Shop_Item_Image_Update_Column>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

/** Ordering options when selecting data from "shop_item_image". */
export type Shop_Item_Image_Order_By = {
  cached?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image_metadata?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  shop_item?: Maybe<Shop_Item_Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** primary key columns input for table: shop_item_image */
export type Shop_Item_Image_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_Image_Prepend_Input = {
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "shop_item_image" */
export enum Shop_Item_Image_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageMetadata = 'image_metadata',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "shop_item_image" */
export type Shop_Item_Image_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "shop_item_image" */
export type Shop_Item_Image_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_Image_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_Image_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** update columns of table "shop_item_image" */
export enum Shop_Item_Image_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageMetadata = 'image_metadata',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

export type Shop_Item_Image_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Shop_Item_Image_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Shop_Item_Image_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Shop_Item_Image_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Shop_Item_Image_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Shop_Item_Image_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_Image_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_Image_Bool_Exp
}

/** input type for incrementing numeric columns in table "shop_item" */
export type Shop_Item_Inc_Input = {
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  pdp_views?: Maybe<Scalars['Int']>
}

/** Table to determine if a shop item PDP can & has been indexed  */
export type Shop_Item_Index = {
  __typename?: 'shop_item_index'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  index: Scalars['Boolean']
  indexable: Scalars['Boolean']
  shop_item_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "shop_item_index" */
export type Shop_Item_Index_Aggregate = {
  __typename?: 'shop_item_index_aggregate'
  aggregate?: Maybe<Shop_Item_Index_Aggregate_Fields>
  nodes: Array<Shop_Item_Index>
}

/** aggregate fields of "shop_item_index" */
export type Shop_Item_Index_Aggregate_Fields = {
  __typename?: 'shop_item_index_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Item_Index_Max_Fields>
  min?: Maybe<Shop_Item_Index_Min_Fields>
}

/** aggregate fields of "shop_item_index" */
export type Shop_Item_Index_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_Index_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "shop_item_index". All fields are combined with a logical 'AND'. */
export type Shop_Item_Index_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_Index_Bool_Exp>>
  _not?: Maybe<Shop_Item_Index_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_Index_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  index?: Maybe<Boolean_Comparison_Exp>
  indexable?: Maybe<Boolean_Comparison_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_item_index" */
export enum Shop_Item_Index_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopItemIndexPkey = 'shop_item_index_pkey',
  /** unique or primary key constraint on columns "shop_item_id" */
  ShopItemIndexShopItemIdKey = 'shop_item_index_shop_item_id_key',
}

/** input type for inserting data into table "shop_item_index" */
export type Shop_Item_Index_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  index?: Maybe<Scalars['Boolean']>
  indexable?: Maybe<Scalars['Boolean']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Shop_Item_Index_Max_Fields = {
  __typename?: 'shop_item_index_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Shop_Item_Index_Min_Fields = {
  __typename?: 'shop_item_index_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "shop_item_index" */
export type Shop_Item_Index_Mutation_Response = {
  __typename?: 'shop_item_index_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item_Index>
}

/** on_conflict condition type for table "shop_item_index" */
export type Shop_Item_Index_On_Conflict = {
  constraint: Shop_Item_Index_Constraint
  update_columns?: Array<Shop_Item_Index_Update_Column>
  where?: Maybe<Shop_Item_Index_Bool_Exp>
}

/** Ordering options when selecting data from "shop_item_index". */
export type Shop_Item_Index_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  index?: Maybe<Order_By>
  indexable?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: shop_item_index */
export type Shop_Item_Index_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "shop_item_index" */
export enum Shop_Item_Index_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Indexable = 'indexable',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "shop_item_index" */
export type Shop_Item_Index_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  index?: Maybe<Scalars['Boolean']>
  indexable?: Maybe<Scalars['Boolean']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "shop_item_index" */
export type Shop_Item_Index_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_Index_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_Index_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  index?: Maybe<Scalars['Boolean']>
  indexable?: Maybe<Scalars['Boolean']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "shop_item_index" */
export enum Shop_Item_Index_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Indexable = 'indexable',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Shop_Item_Index_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_Index_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_Index_Bool_Exp
}

/** input type for inserting data into table "shop_item" */
export type Shop_Item_Insert_Input = {
  availability?: Maybe<Shop_Item_Availability_Enum>
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>
  categories?: Maybe<Array<Scalars['String']>>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum>
  hashtags?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_highres?: Maybe<Scalars['String']>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  is_inappropriate?: Maybe<Scalars['Boolean']>
  item_id?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  onboarding_categories?: Maybe<Array<Scalars['String']>>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pdp_views?: Maybe<Scalars['Int']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  serapi_metadata?: Maybe<Scalars['jsonb']>
  serpapi_metadata?: Maybe<Scalars['jsonb']>
  sharesheet_html?: Maybe<Scalars['String']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_item_availability?: Maybe<Shop_Item_Availability_Obj_Rel_Insert_Input>
  shop_item_histories?: Maybe<Shop_Item_History_Arr_Rel_Insert_Input>
  shop_item_images?: Maybe<Shop_Item_Image_Arr_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  similar_products_histories?: Maybe<Similar_Products_History_Arr_Rel_Insert_Input>
  similar_products_history?: Maybe<Similar_Products_History_Obj_Rel_Insert_Input>
  similar_products_history_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Shop_Item_Max_Fields = {
  __typename?: 'shop_item_max_fields'
  /** The generated cached image url that is based on our Imgix web proxy source */
  cached_image?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_item_cached_placeholder_image" */
  cached_placeholder_image?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_item_display_title" */
  display_title?: Maybe<Scalars['String']>
  hashtags?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_highres?: Maybe<Scalars['String']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item_id?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  onboarding_categories?: Maybe<Array<Scalars['String']>>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  pdp_views?: Maybe<Scalars['Int']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
  sharesheet_html?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  similar_products_history_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "shop_item" */
export type Shop_Item_Max_Order_By = {
  categories?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  hashtags?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image?: Maybe<Order_By>
  image_color_palette?: Maybe<Order_By>
  image_highres?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  keywords?: Maybe<Order_By>
  onboarding_categories?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
  sharesheet_html?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  similar_products_history_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Shop_Item_Min_Fields = {
  __typename?: 'shop_item_min_fields'
  /** The generated cached image url that is based on our Imgix web proxy source */
  cached_image?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_item_cached_placeholder_image" */
  cached_placeholder_image?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_item_display_title" */
  display_title?: Maybe<Scalars['String']>
  hashtags?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_highres?: Maybe<Scalars['String']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item_id?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  onboarding_categories?: Maybe<Array<Scalars['String']>>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  pdp_views?: Maybe<Scalars['Int']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
  sharesheet_html?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  similar_products_history_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "shop_item" */
export type Shop_Item_Min_Order_By = {
  categories?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  hashtags?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image?: Maybe<Order_By>
  image_color_palette?: Maybe<Order_By>
  image_highres?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  keywords?: Maybe<Order_By>
  onboarding_categories?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
  sharesheet_html?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  similar_products_history_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** response of any mutation on the table "shop_item" */
export type Shop_Item_Mutation_Response = {
  __typename?: 'shop_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item>
}

/** input type for inserting object relation for remote table "shop_item" */
export type Shop_Item_Obj_Rel_Insert_Input = {
  data: Shop_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Shop_Item_On_Conflict>
}

/** on_conflict condition type for table "shop_item" */
export type Shop_Item_On_Conflict = {
  constraint: Shop_Item_Constraint
  update_columns?: Array<Shop_Item_Update_Column>
  where?: Maybe<Shop_Item_Bool_Exp>
}

/** Ordering options when selecting data from "shop_item". */
export type Shop_Item_Order_By = {
  availability?: Maybe<Order_By>
  cached_image?: Maybe<Order_By>
  cached_placeholder_image?: Maybe<Order_By>
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>
  categories?: Maybe<Order_By>
  color_thief_processed?: Maybe<Order_By>
  computed_should_index?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  display_title?: Maybe<Order_By>
  enrichment_status?: Maybe<Order_By>
  has_broken_image?: Maybe<Order_By>
  hashtags?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image?: Maybe<Order_By>
  image_color_palette?: Maybe<Order_By>
  image_highres?: Maybe<Order_By>
  image_metadata?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  is_inappropriate?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  keywords?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  number_of_collections?: Maybe<Order_By>
  onboarding_categories?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  parsed_pricing?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
  points?: Maybe<Order_By>
  pricing?: Maybe<Order_By>
  saved_by_current_user?: Maybe<Order_By>
  schema_metadata?: Maybe<Order_By>
  serapi_metadata?: Maybe<Order_By>
  serpapi_metadata?: Maybe<Order_By>
  sharesheet_html?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_item_availability?: Maybe<Shop_Item_Availability_Order_By>
  shop_item_histories_aggregate?: Maybe<Shop_Item_History_Aggregate_Order_By>
  shop_item_images_aggregate?: Maybe<Shop_Item_Image_Aggregate_Order_By>
  shop_key?: Maybe<Order_By>
  similar_products_histories_aggregate?: Maybe<Similar_Products_History_Aggregate_Order_By>
  similar_products_history?: Maybe<Similar_Products_History_Order_By>
  similar_products_history_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  title?: Maybe<Order_By>
  to_be_scraped?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** primary key columns input for table: shop_item */
export type Shop_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_Prepend_Input = {
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  serapi_metadata?: Maybe<Scalars['jsonb']>
  serpapi_metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "shop_item" */
export enum Shop_Item_Select_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  Categories = 'categories',
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnrichmentStatus = 'enrichment_status',
  /** column name */
  Hashtags = 'hashtags',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  ImageColorPalette = 'image_color_palette',
  /** column name */
  ImageHighres = 'image_highres',
  /** column name */
  ImageMetadata = 'image_metadata',
  /** column name */
  ImagePrimaryB = 'image_primary_b',
  /** column name */
  ImagePrimaryG = 'image_primary_g',
  /** column name */
  ImagePrimaryR = 'image_primary_r',
  /** column name */
  IsInappropriate = 'is_inappropriate',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OnboardingCategories = 'onboarding_categories',
  /** column name */
  OpenAiAttempts = 'open_ai_attempts',
  /** column name */
  ParsedPricing = 'parsed_pricing',
  /** column name */
  PdpViews = 'pdp_views',
  /** column name */
  Pricing = 'pricing',
  /** column name */
  SchemaMetadata = 'schema_metadata',
  /** column name */
  SerapiMetadata = 'serapi_metadata',
  /** column name */
  SerpapiMetadata = 'serpapi_metadata',
  /** column name */
  SharesheetHtml = 'sharesheet_html',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  SimilarProductsHistoryId = 'similar_products_history_id',
  /** column name */
  Source = 'source',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeScraped = 'to_be_scraped',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

/** select "shop_item_aggregate_bool_exp_bool_and_arguments_columns" columns of table "shop_item" */
export enum Shop_Item_Select_Column_Shop_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  IsInappropriate = 'is_inappropriate',
  /** column name */
  ToBeScraped = 'to_be_scraped',
}

/** select "shop_item_aggregate_bool_exp_bool_or_arguments_columns" columns of table "shop_item" */
export enum Shop_Item_Select_Column_Shop_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  IsInappropriate = 'is_inappropriate',
  /** column name */
  ToBeScraped = 'to_be_scraped',
}

/** input type for updating data in table "shop_item" */
export type Shop_Item_Set_Input = {
  availability?: Maybe<Shop_Item_Availability_Enum>
  categories?: Maybe<Array<Scalars['String']>>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum>
  hashtags?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_highres?: Maybe<Scalars['String']>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  is_inappropriate?: Maybe<Scalars['Boolean']>
  item_id?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  onboarding_categories?: Maybe<Array<Scalars['String']>>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pdp_views?: Maybe<Scalars['Int']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  serapi_metadata?: Maybe<Scalars['jsonb']>
  serpapi_metadata?: Maybe<Scalars['jsonb']>
  sharesheet_html?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  similar_products_history_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Shop_Item_Stddev_Fields = {
  __typename?: 'shop_item_stddev_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by stddev() on columns of table "shop_item" */
export type Shop_Item_Stddev_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Shop_Item_Stddev_Pop_Fields = {
  __typename?: 'shop_item_stddev_pop_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by stddev_pop() on columns of table "shop_item" */
export type Shop_Item_Stddev_Pop_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Shop_Item_Stddev_Samp_Fields = {
  __typename?: 'shop_item_stddev_samp_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by stddev_samp() on columns of table "shop_item" */
export type Shop_Item_Stddev_Samp_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** Streaming cursor of the table "shop_item" */
export type Shop_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_Stream_Cursor_Value_Input = {
  availability?: Maybe<Shop_Item_Availability_Enum>
  categories?: Maybe<Array<Scalars['String']>>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum>
  hashtags?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_highres?: Maybe<Scalars['String']>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  is_inappropriate?: Maybe<Scalars['Boolean']>
  item_id?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  onboarding_categories?: Maybe<Array<Scalars['String']>>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pdp_views?: Maybe<Scalars['Int']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  serapi_metadata?: Maybe<Scalars['jsonb']>
  serpapi_metadata?: Maybe<Scalars['jsonb']>
  sharesheet_html?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  similar_products_history_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Shop_Item_Sum_Fields = {
  __typename?: 'shop_item_sum_fields'
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  pdp_views?: Maybe<Scalars['Int']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "shop_item" */
export type Shop_Item_Sum_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** columns and relationships of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped = {
  __typename?: 'shop_item_to_be_scraped'
  availability?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_highres?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  /** An object relationship */
  shop?: Maybe<Shop>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_ScrapedParsed_PricingArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_ScrapedPricingArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_ScrapedSchema_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Aggregate = {
  __typename?: 'shop_item_to_be_scraped_aggregate'
  aggregate?: Maybe<Shop_Item_To_Be_Scraped_Aggregate_Fields>
  nodes: Array<Shop_Item_To_Be_Scraped>
}

/** aggregate fields of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Aggregate_Fields = {
  __typename?: 'shop_item_to_be_scraped_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Item_To_Be_Scraped_Max_Fields>
  min?: Maybe<Shop_Item_To_Be_Scraped_Min_Fields>
}

/** aggregate fields of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_To_Be_Scraped_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_To_Be_Scraped_Append_Input = {
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "shop_item_to_be_scraped". All fields are combined with a logical 'AND'. */
export type Shop_Item_To_Be_Scraped_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_To_Be_Scraped_Bool_Exp>>
  _not?: Maybe<Shop_Item_To_Be_Scraped_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_To_Be_Scraped_Bool_Exp>>
  availability?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  image?: Maybe<String_Comparison_Exp>
  image_highres?: Maybe<String_Comparison_Exp>
  item_id?: Maybe<String_Comparison_Exp>
  parsed_pricing?: Maybe<Jsonb_Comparison_Exp>
  pricing?: Maybe<Jsonb_Comparison_Exp>
  schema_metadata?: Maybe<Jsonb_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  source?: Maybe<String_Comparison_Exp>
  title?: Maybe<String_Comparison_Exp>
  to_be_scraped?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  url?: Maybe<String_Comparison_Exp>
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shop_Item_To_Be_Scraped_Delete_At_Path_Input = {
  parsed_pricing?: Maybe<Array<Scalars['String']>>
  pricing?: Maybe<Array<Scalars['String']>>
  schema_metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shop_Item_To_Be_Scraped_Delete_Elem_Input = {
  parsed_pricing?: Maybe<Scalars['Int']>
  pricing?: Maybe<Scalars['Int']>
  schema_metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shop_Item_To_Be_Scraped_Delete_Key_Input = {
  parsed_pricing?: Maybe<Scalars['String']>
  pricing?: Maybe<Scalars['String']>
  schema_metadata?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Insert_Input = {
  availability?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_highres?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Shop_Item_To_Be_Scraped_Max_Fields = {
  __typename?: 'shop_item_to_be_scraped_max_fields'
  availability?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_highres?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Shop_Item_To_Be_Scraped_Min_Fields = {
  __typename?: 'shop_item_to_be_scraped_min_fields'
  availability?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_highres?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Mutation_Response = {
  __typename?: 'shop_item_to_be_scraped_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item_To_Be_Scraped>
}

/** Ordering options when selecting data from "shop_item_to_be_scraped". */
export type Shop_Item_To_Be_Scraped_Order_By = {
  availability?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image?: Maybe<Order_By>
  image_highres?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  parsed_pricing?: Maybe<Order_By>
  pricing?: Maybe<Order_By>
  schema_metadata?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  source?: Maybe<Order_By>
  title?: Maybe<Order_By>
  to_be_scraped?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_To_Be_Scraped_Prepend_Input = {
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "shop_item_to_be_scraped" */
export enum Shop_Item_To_Be_Scraped_Select_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  ImageHighres = 'image_highres',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ParsedPricing = 'parsed_pricing',
  /** column name */
  Pricing = 'pricing',
  /** column name */
  SchemaMetadata = 'schema_metadata',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Source = 'source',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeScraped = 'to_be_scraped',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Set_Input = {
  availability?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_highres?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_To_Be_Scraped_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_To_Be_Scraped_Stream_Cursor_Value_Input = {
  availability?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_highres?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

export type Shop_Item_To_Be_Scraped_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Shop_Item_To_Be_Scraped_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Shop_Item_To_Be_Scraped_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Shop_Item_To_Be_Scraped_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Shop_Item_To_Be_Scraped_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Shop_Item_To_Be_Scraped_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_To_Be_Scraped_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_To_Be_Scraped_Bool_Exp
}

/** update columns of table "shop_item" */
export enum Shop_Item_Update_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  Categories = 'categories',
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnrichmentStatus = 'enrichment_status',
  /** column name */
  Hashtags = 'hashtags',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  ImageColorPalette = 'image_color_palette',
  /** column name */
  ImageHighres = 'image_highres',
  /** column name */
  ImageMetadata = 'image_metadata',
  /** column name */
  ImagePrimaryB = 'image_primary_b',
  /** column name */
  ImagePrimaryG = 'image_primary_g',
  /** column name */
  ImagePrimaryR = 'image_primary_r',
  /** column name */
  IsInappropriate = 'is_inappropriate',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OnboardingCategories = 'onboarding_categories',
  /** column name */
  OpenAiAttempts = 'open_ai_attempts',
  /** column name */
  ParsedPricing = 'parsed_pricing',
  /** column name */
  PdpViews = 'pdp_views',
  /** column name */
  Pricing = 'pricing',
  /** column name */
  SchemaMetadata = 'schema_metadata',
  /** column name */
  SerapiMetadata = 'serapi_metadata',
  /** column name */
  SerpapiMetadata = 'serpapi_metadata',
  /** column name */
  SharesheetHtml = 'sharesheet_html',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  SimilarProductsHistoryId = 'similar_products_history_id',
  /** column name */
  Source = 'source',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeScraped = 'to_be_scraped',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

export type Shop_Item_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Shop_Item_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Shop_Item_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Shop_Item_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Shop_Item_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Shop_Item_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Shop_Item_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Shop_Item_Var_Pop_Fields = {
  __typename?: 'shop_item_var_pop_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by var_pop() on columns of table "shop_item" */
export type Shop_Item_Var_Pop_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Shop_Item_Var_Samp_Fields = {
  __typename?: 'shop_item_var_samp_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by var_samp() on columns of table "shop_item" */
export type Shop_Item_Var_Samp_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Shop_Item_Variance_Fields = {
  __typename?: 'shop_item_variance_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by variance() on columns of table "shop_item" */
export type Shop_Item_Variance_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** aggregate max on columns */
export type Shop_Max_Fields = {
  __typename?: 'shop_max_fields'
  banner_image?: Maybe<Scalars['String']>
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  cart_path?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_display_name" */
  display_name?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  editorial_shop_logo?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  key?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  name_override?: Maybe<Scalars['String']>
  parent_shop_key?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  search_tokens?: Maybe<Array<Scalars['String']>>
  searchable_slug?: Maybe<Scalars['String']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Shop_Min_Fields = {
  __typename?: 'shop_min_fields'
  banner_image?: Maybe<Scalars['String']>
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  cart_path?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_display_name" */
  display_name?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  editorial_shop_logo?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  key?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  name_override?: Maybe<Scalars['String']>
  parent_shop_key?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  search_tokens?: Maybe<Array<Scalars['String']>>
  searchable_slug?: Maybe<Scalars['String']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "shop" */
export type Shop_Mutation_Response = {
  __typename?: 'shop_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop>
}

/** input type for inserting object relation for remote table "shop" */
export type Shop_Obj_Rel_Insert_Input = {
  data: Shop_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Shop_On_Conflict>
}

/** on_conflict condition type for table "shop" */
export type Shop_On_Conflict = {
  constraint: Shop_Constraint
  update_columns?: Array<Shop_Update_Column>
  where?: Maybe<Shop_Bool_Exp>
}

/** Ordering options when selecting data from "shop". */
export type Shop_Order_By = {
  affiliate?: Maybe<Order_By>
  associated_affiliate?: Maybe<Affiliate_Order_By>
  banner_image?: Maybe<Order_By>
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>
  cart_items_count?: Maybe<Order_By>
  cart_path?: Maybe<Order_By>
  carts_aggregate?: Maybe<Cart_Aggregate_Order_By>
  cover_image?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  display_name?: Maybe<Order_By>
  domains?: Maybe<Order_By>
  editorial_shop_logo?: Maybe<Order_By>
  favicon?: Maybe<Order_By>
  featured?: Maybe<Order_By>
  featured_shop?: Maybe<Featured_Shop_Order_By>
  has_deals?: Maybe<Order_By>
  has_pdp?: Maybe<Order_By>
  id?: Maybe<Order_By>
  items_aggregate?: Maybe<Shop_Item_Aggregate_Order_By>
  key?: Maybe<Order_By>
  keywords?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  name?: Maybe<Order_By>
  name_override?: Maybe<Order_By>
  parent_shop?: Maybe<Shop_Order_By>
  parent_shop_key?: Maybe<Order_By>
  parsed_take_rate?: Maybe<Order_By>
  platform?: Maybe<Order_By>
  points?: Maybe<Order_By>
  potential_spam?: Maybe<Order_By>
  search_tokens?: Maybe<Order_By>
  searchable_slug?: Maybe<Order_By>
  shop_affiliates_aggregate?: Maybe<Shop_Affiliate_Aggregate_Order_By>
  shop_categorizations_aggregate?: Maybe<Shop_Categorization_Aggregate_Order_By>
  shop_faqs?: Maybe<Order_By>
  shop_items_count?: Maybe<Order_By>
  shop_popularity?: Maybe<Shop_Popularity_Order_By>
  show_price_drops?: Maybe<Order_By>
  slug?: Maybe<Order_By>
  type?: Maybe<Order_By>
  unknown?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: shop */
export type Shop_Pk_Columns_Input = {
  key: Scalars['String']
}

/** columns and relationships of "shop_popularity" */
export type Shop_Popularity = {
  __typename?: 'shop_popularity'
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  /** An object relationship */
  shop?: Maybe<Shop>
}

/** aggregated selection of "shop_popularity" */
export type Shop_Popularity_Aggregate = {
  __typename?: 'shop_popularity_aggregate'
  aggregate?: Maybe<Shop_Popularity_Aggregate_Fields>
  nodes: Array<Shop_Popularity>
}

/** aggregate fields of "shop_popularity" */
export type Shop_Popularity_Aggregate_Fields = {
  __typename?: 'shop_popularity_aggregate_fields'
  avg?: Maybe<Shop_Popularity_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Popularity_Max_Fields>
  min?: Maybe<Shop_Popularity_Min_Fields>
  stddev?: Maybe<Shop_Popularity_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Popularity_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Popularity_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Popularity_Sum_Fields>
  var_pop?: Maybe<Shop_Popularity_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Popularity_Var_Samp_Fields>
  variance?: Maybe<Shop_Popularity_Variance_Fields>
}

/** aggregate fields of "shop_popularity" */
export type Shop_Popularity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Popularity_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Shop_Popularity_Avg_Fields = {
  __typename?: 'shop_popularity_avg_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "shop_popularity". All fields are combined with a logical 'AND'. */
export type Shop_Popularity_Bool_Exp = {
  _and?: Maybe<Array<Shop_Popularity_Bool_Exp>>
  _not?: Maybe<Shop_Popularity_Bool_Exp>
  _or?: Maybe<Array<Shop_Popularity_Bool_Exp>>
  key?: Maybe<String_Comparison_Exp>
  popularity_rank?: Maybe<Numeric_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
}

/** unique or primary key constraints on table "shop_popularity" */
export enum Shop_Popularity_Constraint {
  /** unique or primary key constraint on columns "key" */
  ShopPopularityKeyIdx = 'shop_popularity_key_idx',
}

/** input type for inserting data into table "shop_popularity" */
export type Shop_Popularity_Insert_Input = {
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Shop_Popularity_Max_Fields = {
  __typename?: 'shop_popularity_max_fields'
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** aggregate min on columns */
export type Shop_Popularity_Min_Fields = {
  __typename?: 'shop_popularity_min_fields'
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** input type for inserting object relation for remote table "shop_popularity" */
export type Shop_Popularity_Obj_Rel_Insert_Input = {
  data: Shop_Popularity_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Shop_Popularity_On_Conflict>
}

/** on_conflict condition type for table "shop_popularity" */
export type Shop_Popularity_On_Conflict = {
  constraint: Shop_Popularity_Constraint
  update_columns?: Array<Shop_Popularity_Update_Column>
  where?: Maybe<Shop_Popularity_Bool_Exp>
}

/** Ordering options when selecting data from "shop_popularity". */
export type Shop_Popularity_Order_By = {
  key?: Maybe<Order_By>
  popularity_rank?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
}

/** select columns of table "shop_popularity" */
export enum Shop_Popularity_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  PopularityRank = 'popularity_rank',
}

/** aggregate stddev on columns */
export type Shop_Popularity_Stddev_Fields = {
  __typename?: 'shop_popularity_stddev_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Shop_Popularity_Stddev_Pop_Fields = {
  __typename?: 'shop_popularity_stddev_pop_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Shop_Popularity_Stddev_Samp_Fields = {
  __typename?: 'shop_popularity_stddev_samp_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "shop_popularity" */
export type Shop_Popularity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Popularity_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Popularity_Stream_Cursor_Value_Input = {
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** aggregate sum on columns */
export type Shop_Popularity_Sum_Fields = {
  __typename?: 'shop_popularity_sum_fields'
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** update columns of table "shop_popularity" */
export enum Shop_Popularity_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  PopularityRank = 'popularity_rank',
}

/** aggregate var_pop on columns */
export type Shop_Popularity_Var_Pop_Fields = {
  __typename?: 'shop_popularity_var_pop_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Shop_Popularity_Var_Samp_Fields = {
  __typename?: 'shop_popularity_var_samp_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Shop_Popularity_Variance_Fields = {
  __typename?: 'shop_popularity_variance_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** columns and relationships of "shop_popularity_with_points" */
export type Shop_Popularity_With_Points = {
  __typename?: 'shop_popularity_with_points'
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  /** An object relationship */
  shop?: Maybe<Shop>
  shop_points?: Maybe<Scalars['numeric']>
}

/** aggregated selection of "shop_popularity_with_points" */
export type Shop_Popularity_With_Points_Aggregate = {
  __typename?: 'shop_popularity_with_points_aggregate'
  aggregate?: Maybe<Shop_Popularity_With_Points_Aggregate_Fields>
  nodes: Array<Shop_Popularity_With_Points>
}

/** aggregate fields of "shop_popularity_with_points" */
export type Shop_Popularity_With_Points_Aggregate_Fields = {
  __typename?: 'shop_popularity_with_points_aggregate_fields'
  avg?: Maybe<Shop_Popularity_With_Points_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Popularity_With_Points_Max_Fields>
  min?: Maybe<Shop_Popularity_With_Points_Min_Fields>
  stddev?: Maybe<Shop_Popularity_With_Points_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Popularity_With_Points_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Popularity_With_Points_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Popularity_With_Points_Sum_Fields>
  var_pop?: Maybe<Shop_Popularity_With_Points_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Popularity_With_Points_Var_Samp_Fields>
  variance?: Maybe<Shop_Popularity_With_Points_Variance_Fields>
}

/** aggregate fields of "shop_popularity_with_points" */
export type Shop_Popularity_With_Points_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Popularity_With_Points_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Shop_Popularity_With_Points_Avg_Fields = {
  __typename?: 'shop_popularity_with_points_avg_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "shop_popularity_with_points". All fields are combined with a logical 'AND'. */
export type Shop_Popularity_With_Points_Bool_Exp = {
  _and?: Maybe<Array<Shop_Popularity_With_Points_Bool_Exp>>
  _not?: Maybe<Shop_Popularity_With_Points_Bool_Exp>
  _or?: Maybe<Array<Shop_Popularity_With_Points_Bool_Exp>>
  key?: Maybe<String_Comparison_Exp>
  popularity_rank?: Maybe<Numeric_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_points?: Maybe<Numeric_Comparison_Exp>
}

/** aggregate max on columns */
export type Shop_Popularity_With_Points_Max_Fields = {
  __typename?: 'shop_popularity_with_points_max_fields'
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  shop_points?: Maybe<Scalars['numeric']>
}

/** aggregate min on columns */
export type Shop_Popularity_With_Points_Min_Fields = {
  __typename?: 'shop_popularity_with_points_min_fields'
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  shop_points?: Maybe<Scalars['numeric']>
}

/** Ordering options when selecting data from "shop_popularity_with_points". */
export type Shop_Popularity_With_Points_Order_By = {
  key?: Maybe<Order_By>
  popularity_rank?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_points?: Maybe<Order_By>
}

/** select columns of table "shop_popularity_with_points" */
export enum Shop_Popularity_With_Points_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  PopularityRank = 'popularity_rank',
  /** column name */
  ShopPoints = 'shop_points',
}

/** aggregate stddev on columns */
export type Shop_Popularity_With_Points_Stddev_Fields = {
  __typename?: 'shop_popularity_with_points_stddev_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Shop_Popularity_With_Points_Stddev_Pop_Fields = {
  __typename?: 'shop_popularity_with_points_stddev_pop_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Shop_Popularity_With_Points_Stddev_Samp_Fields = {
  __typename?: 'shop_popularity_with_points_stddev_samp_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "shop_popularity_with_points" */
export type Shop_Popularity_With_Points_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Popularity_With_Points_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Popularity_With_Points_Stream_Cursor_Value_Input = {
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  shop_points?: Maybe<Scalars['numeric']>
}

/** aggregate sum on columns */
export type Shop_Popularity_With_Points_Sum_Fields = {
  __typename?: 'shop_popularity_with_points_sum_fields'
  popularity_rank?: Maybe<Scalars['numeric']>
  shop_points?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type Shop_Popularity_With_Points_Var_Pop_Fields = {
  __typename?: 'shop_popularity_with_points_var_pop_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Shop_Popularity_With_Points_Var_Samp_Fields = {
  __typename?: 'shop_popularity_with_points_var_samp_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Shop_Popularity_With_Points_Variance_Fields = {
  __typename?: 'shop_popularity_with_points_variance_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shop_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
  shop_faqs?: Maybe<Scalars['jsonb']>
}

/** Shop rating and review data sourced from the web */
export type Shop_Rating = {
  __typename?: 'shop_rating'
  business_unit_id?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  is_claimed?: Maybe<Scalars['Boolean']>
  num_reviews: Scalars['numeric']
  shop_key: Scalars['String']
  stars: Scalars['numeric']
  trust_score: Scalars['numeric']
  updated_at: Scalars['timestamptz']
  verified_business?: Maybe<Scalars['Boolean']>
}

/** aggregated selection of "shop_rating" */
export type Shop_Rating_Aggregate = {
  __typename?: 'shop_rating_aggregate'
  aggregate?: Maybe<Shop_Rating_Aggregate_Fields>
  nodes: Array<Shop_Rating>
}

/** aggregate fields of "shop_rating" */
export type Shop_Rating_Aggregate_Fields = {
  __typename?: 'shop_rating_aggregate_fields'
  avg?: Maybe<Shop_Rating_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Rating_Max_Fields>
  min?: Maybe<Shop_Rating_Min_Fields>
  stddev?: Maybe<Shop_Rating_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Rating_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Rating_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Rating_Sum_Fields>
  var_pop?: Maybe<Shop_Rating_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Rating_Var_Samp_Fields>
  variance?: Maybe<Shop_Rating_Variance_Fields>
}

/** aggregate fields of "shop_rating" */
export type Shop_Rating_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Rating_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Shop_Rating_Avg_Fields = {
  __typename?: 'shop_rating_avg_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "shop_rating". All fields are combined with a logical 'AND'. */
export type Shop_Rating_Bool_Exp = {
  _and?: Maybe<Array<Shop_Rating_Bool_Exp>>
  _not?: Maybe<Shop_Rating_Bool_Exp>
  _or?: Maybe<Array<Shop_Rating_Bool_Exp>>
  business_unit_id?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_claimed?: Maybe<Boolean_Comparison_Exp>
  num_reviews?: Maybe<Numeric_Comparison_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  stars?: Maybe<Numeric_Comparison_Exp>
  trust_score?: Maybe<Numeric_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  verified_business?: Maybe<Boolean_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_rating" */
export enum Shop_Rating_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopRatingPkey = 'shop_rating_pkey',
  /** unique or primary key constraint on columns "shop_key" */
  ShopRatingShopKeyKey = 'shop_rating_shop_key_key',
}

/** input type for incrementing numeric columns in table "shop_rating" */
export type Shop_Rating_Inc_Input = {
  num_reviews?: Maybe<Scalars['numeric']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "shop_rating" */
export type Shop_Rating_Insert_Input = {
  business_unit_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  is_claimed?: Maybe<Scalars['Boolean']>
  num_reviews?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  verified_business?: Maybe<Scalars['Boolean']>
}

/** aggregate max on columns */
export type Shop_Rating_Max_Fields = {
  __typename?: 'shop_rating_max_fields'
  business_unit_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  num_reviews?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Shop_Rating_Min_Fields = {
  __typename?: 'shop_rating_min_fields'
  business_unit_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  num_reviews?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "shop_rating" */
export type Shop_Rating_Mutation_Response = {
  __typename?: 'shop_rating_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Rating>
}

/** on_conflict condition type for table "shop_rating" */
export type Shop_Rating_On_Conflict = {
  constraint: Shop_Rating_Constraint
  update_columns?: Array<Shop_Rating_Update_Column>
  where?: Maybe<Shop_Rating_Bool_Exp>
}

/** Ordering options when selecting data from "shop_rating". */
export type Shop_Rating_Order_By = {
  business_unit_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_claimed?: Maybe<Order_By>
  num_reviews?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  stars?: Maybe<Order_By>
  trust_score?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  verified_business?: Maybe<Order_By>
}

/** primary key columns input for table: shop_rating */
export type Shop_Rating_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "shop_rating" */
export enum Shop_Rating_Select_Column {
  /** column name */
  BusinessUnitId = 'business_unit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsClaimed = 'is_claimed',
  /** column name */
  NumReviews = 'num_reviews',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Stars = 'stars',
  /** column name */
  TrustScore = 'trust_score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifiedBusiness = 'verified_business',
}

/** input type for updating data in table "shop_rating" */
export type Shop_Rating_Set_Input = {
  business_unit_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  is_claimed?: Maybe<Scalars['Boolean']>
  num_reviews?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  verified_business?: Maybe<Scalars['Boolean']>
}

/** aggregate stddev on columns */
export type Shop_Rating_Stddev_Fields = {
  __typename?: 'shop_rating_stddev_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Shop_Rating_Stddev_Pop_Fields = {
  __typename?: 'shop_rating_stddev_pop_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Shop_Rating_Stddev_Samp_Fields = {
  __typename?: 'shop_rating_stddev_samp_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "shop_rating" */
export type Shop_Rating_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Rating_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Rating_Stream_Cursor_Value_Input = {
  business_unit_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  is_claimed?: Maybe<Scalars['Boolean']>
  num_reviews?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  verified_business?: Maybe<Scalars['Boolean']>
}

/** aggregate sum on columns */
export type Shop_Rating_Sum_Fields = {
  __typename?: 'shop_rating_sum_fields'
  num_reviews?: Maybe<Scalars['numeric']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
}

/** update columns of table "shop_rating" */
export enum Shop_Rating_Update_Column {
  /** column name */
  BusinessUnitId = 'business_unit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsClaimed = 'is_claimed',
  /** column name */
  NumReviews = 'num_reviews',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Stars = 'stars',
  /** column name */
  TrustScore = 'trust_score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifiedBusiness = 'verified_business',
}

export type Shop_Rating_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Shop_Rating_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Rating_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Rating_Bool_Exp
}

/** aggregate var_pop on columns */
export type Shop_Rating_Var_Pop_Fields = {
  __typename?: 'shop_rating_var_pop_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Shop_Rating_Var_Samp_Fields = {
  __typename?: 'shop_rating_var_samp_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Shop_Rating_Variance_Fields = {
  __typename?: 'shop_rating_variance_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** select columns of table "shop" */
export enum Shop_Select_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  BannerImage = 'banner_image',
  /** column name */
  CartPath = 'cart_path',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Domains = 'domains',
  /** column name */
  EditorialShopLogo = 'editorial_shop_logo',
  /** column name */
  Favicon = 'favicon',
  /** column name */
  Featured = 'featured',
  /** column name */
  HasDeals = 'has_deals',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NameOverride = 'name_override',
  /** column name */
  ParentShopKey = 'parent_shop_key',
  /** column name */
  ParsedTakeRate = 'parsed_take_rate',
  /** column name */
  Platform = 'platform',
  /** column name */
  PotentialSpam = 'potential_spam',
  /** column name */
  SearchTokens = 'search_tokens',
  /** column name */
  SearchableSlug = 'searchable_slug',
  /** column name */
  ShopFaqs = 'shop_faqs',
  /** column name */
  Slug = 'slug',
  /** column name */
  Type = 'type',
  /** column name */
  Unknown = 'unknown',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "shop" */
export type Shop_Set_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  banner_image?: Maybe<Scalars['String']>
  cart_path?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  editorial_shop_logo?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  featured?: Maybe<Scalars['Boolean']>
  has_deals?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  key?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['name']>
  name_override?: Maybe<Scalars['String']>
  parent_shop_key?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  potential_spam?: Maybe<Scalars['Boolean']>
  search_tokens?: Maybe<Array<Scalars['String']>>
  shop_faqs?: Maybe<Scalars['jsonb']>
  slug?: Maybe<Scalars['name']>
  type?: Maybe<Scalars['name']>
  unknown?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Shop_Stddev_Fields = {
  __typename?: 'shop_stddev_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** aggregate stddev_pop on columns */
export type Shop_Stddev_Pop_Fields = {
  __typename?: 'shop_stddev_pop_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** aggregate stddev_samp on columns */
export type Shop_Stddev_Samp_Fields = {
  __typename?: 'shop_stddev_samp_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** Streaming cursor of the table "shop" */
export type Shop_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Stream_Cursor_Value_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  banner_image?: Maybe<Scalars['String']>
  cart_path?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  editorial_shop_logo?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  featured?: Maybe<Scalars['Boolean']>
  has_deals?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  key?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['name']>
  name_override?: Maybe<Scalars['String']>
  parent_shop_key?: Maybe<Scalars['String']>
  parsed_take_rate?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  potential_spam?: Maybe<Scalars['Boolean']>
  search_tokens?: Maybe<Array<Scalars['String']>>
  searchable_slug?: Maybe<Scalars['String']>
  shop_faqs?: Maybe<Scalars['jsonb']>
  slug?: Maybe<Scalars['name']>
  type?: Maybe<Scalars['name']>
  unknown?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Shop_Sum_Fields = {
  __typename?: 'shop_sum_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** update columns of table "shop" */
export enum Shop_Update_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  BannerImage = 'banner_image',
  /** column name */
  CartPath = 'cart_path',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Domains = 'domains',
  /** column name */
  EditorialShopLogo = 'editorial_shop_logo',
  /** column name */
  Favicon = 'favicon',
  /** column name */
  Featured = 'featured',
  /** column name */
  HasDeals = 'has_deals',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NameOverride = 'name_override',
  /** column name */
  ParentShopKey = 'parent_shop_key',
  /** column name */
  Platform = 'platform',
  /** column name */
  PotentialSpam = 'potential_spam',
  /** column name */
  SearchTokens = 'search_tokens',
  /** column name */
  ShopFaqs = 'shop_faqs',
  /** column name */
  Slug = 'slug',
  /** column name */
  Type = 'type',
  /** column name */
  Unknown = 'unknown',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Shop_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Shop_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Shop_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Shop_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Shop_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Shop_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Bool_Exp
}

/** aggregate var_pop on columns */
export type Shop_Var_Pop_Fields = {
  __typename?: 'shop_var_pop_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** aggregate var_samp on columns */
export type Shop_Var_Samp_Fields = {
  __typename?: 'shop_var_samp_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** aggregate variance on columns */
export type Shop_Variance_Fields = {
  __typename?: 'shop_variance_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** This table contains the history of the similar_producst api call */
export type Similar_Products_History = {
  __typename?: 'similar_products_history'
  /** An object relationship */
  cart_item?: Maybe<Cart_Item>
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  image_url: Scalars['String']
  /** An object relationship */
  shop_item?: Maybe<Shop_Item>
  shop_item_id?: Maybe<Scalars['uuid']>
  /** An array relationship */
  shop_items: Array<Shop_Item>
  /** An aggregate relationship */
  shop_items_aggregate: Shop_Item_Aggregate
  similar_products: Scalars['jsonb']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id: Scalars['String']
}

/** This table contains the history of the similar_producst api call */
export type Similar_Products_HistoryShop_ItemsArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

/** This table contains the history of the similar_producst api call */
export type Similar_Products_HistoryShop_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

/** This table contains the history of the similar_producst api call */
export type Similar_Products_HistorySimilar_ProductsArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "similar_products_history" */
export type Similar_Products_History_Aggregate = {
  __typename?: 'similar_products_history_aggregate'
  aggregate?: Maybe<Similar_Products_History_Aggregate_Fields>
  nodes: Array<Similar_Products_History>
}

export type Similar_Products_History_Aggregate_Bool_Exp = {
  count?: Maybe<Similar_Products_History_Aggregate_Bool_Exp_Count>
}

export type Similar_Products_History_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Similar_Products_History_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Similar_Products_History_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "similar_products_history" */
export type Similar_Products_History_Aggregate_Fields = {
  __typename?: 'similar_products_history_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Similar_Products_History_Max_Fields>
  min?: Maybe<Similar_Products_History_Min_Fields>
}

/** aggregate fields of "similar_products_history" */
export type Similar_Products_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Similar_Products_History_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "similar_products_history" */
export type Similar_Products_History_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Similar_Products_History_Max_Order_By>
  min?: Maybe<Similar_Products_History_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Similar_Products_History_Append_Input = {
  similar_products?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "similar_products_history" */
export type Similar_Products_History_Arr_Rel_Insert_Input = {
  data: Array<Similar_Products_History_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Similar_Products_History_On_Conflict>
}

/** Boolean expression to filter rows from the table "similar_products_history". All fields are combined with a logical 'AND'. */
export type Similar_Products_History_Bool_Exp = {
  _and?: Maybe<Array<Similar_Products_History_Bool_Exp>>
  _not?: Maybe<Similar_Products_History_Bool_Exp>
  _or?: Maybe<Array<Similar_Products_History_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  cart_item_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  image_url?: Maybe<String_Comparison_Exp>
  shop_item?: Maybe<Shop_Item_Bool_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  shop_items?: Maybe<Shop_Item_Bool_Exp>
  shop_items_aggregate?: Maybe<Shop_Item_Aggregate_Bool_Exp>
  similar_products?: Maybe<Jsonb_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "similar_products_history" */
export enum Similar_Products_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  SimilarProductsHistoryPkey = 'similar_products_history_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Similar_Products_History_Delete_At_Path_Input = {
  similar_products?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Similar_Products_History_Delete_Elem_Input = {
  similar_products?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Similar_Products_History_Delete_Key_Input = {
  similar_products?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "similar_products_history" */
export type Similar_Products_History_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_url?: Maybe<Scalars['String']>
  shop_item?: Maybe<Shop_Item_Obj_Rel_Insert_Input>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_items?: Maybe<Shop_Item_Arr_Rel_Insert_Input>
  similar_products?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Similar_Products_History_Max_Fields = {
  __typename?: 'similar_products_history_max_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_url?: Maybe<Scalars['String']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "similar_products_history" */
export type Similar_Products_History_Max_Order_By = {
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image_url?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Similar_Products_History_Min_Fields = {
  __typename?: 'similar_products_history_min_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_url?: Maybe<Scalars['String']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "similar_products_history" */
export type Similar_Products_History_Min_Order_By = {
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image_url?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "similar_products_history" */
export type Similar_Products_History_Mutation_Response = {
  __typename?: 'similar_products_history_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Similar_Products_History>
}

/** input type for inserting object relation for remote table "similar_products_history" */
export type Similar_Products_History_Obj_Rel_Insert_Input = {
  data: Similar_Products_History_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Similar_Products_History_On_Conflict>
}

/** on_conflict condition type for table "similar_products_history" */
export type Similar_Products_History_On_Conflict = {
  constraint: Similar_Products_History_Constraint
  update_columns?: Array<Similar_Products_History_Update_Column>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** Ordering options when selecting data from "similar_products_history". */
export type Similar_Products_History_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image_url?: Maybe<Order_By>
  shop_item?: Maybe<Shop_Item_Order_By>
  shop_item_id?: Maybe<Order_By>
  shop_items_aggregate?: Maybe<Shop_Item_Aggregate_Order_By>
  similar_products?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: similar_products_history */
export type Similar_Products_History_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Similar_Products_History_Prepend_Input = {
  similar_products?: Maybe<Scalars['jsonb']>
}

/** select columns of table "similar_products_history" */
export enum Similar_Products_History_Select_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  SimilarProducts = 'similar_products',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "similar_products_history" */
export type Similar_Products_History_Set_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_url?: Maybe<Scalars['String']>
  shop_item_id?: Maybe<Scalars['uuid']>
  similar_products?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "similar_products_history" */
export type Similar_Products_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Similar_Products_History_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Similar_Products_History_Stream_Cursor_Value_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_url?: Maybe<Scalars['String']>
  shop_item_id?: Maybe<Scalars['uuid']>
  similar_products?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

export type Similar_Products_History_Unique_Args = {
  limit?: Maybe<Scalars['Int']>
  user_id_arg?: Maybe<Scalars['String']>
}

/** update columns of table "similar_products_history" */
export enum Similar_Products_History_Update_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  SimilarProducts = 'similar_products',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Similar_Products_History_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Similar_Products_History_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Similar_Products_History_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Similar_Products_History_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Similar_Products_History_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Similar_Products_History_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Similar_Products_History_Set_Input>
  /** filter the rows which have to be updated */
  where: Similar_Products_History_Bool_Exp
}

export type Single_Unread_Notification_Banner_Item_For_User_Args = {
  notifiable_type?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: Maybe<Array<Scalars['smallint']>>
  /** does the array contain the given value */
  _contains?: Maybe<Array<Scalars['smallint']>>
  _eq?: Maybe<Array<Scalars['smallint']>>
  _gt?: Maybe<Array<Scalars['smallint']>>
  _gte?: Maybe<Array<Scalars['smallint']>>
  _in?: Maybe<Array<Array<Scalars['smallint']>>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Array<Scalars['smallint']>>
  _lte?: Maybe<Array<Scalars['smallint']>>
  _neq?: Maybe<Array<Scalars['smallint']>>
  _nin?: Maybe<Array<Array<Scalars['smallint']>>>
}

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: Maybe<Scalars['smallint']>
  _gt?: Maybe<Scalars['smallint']>
  _gte?: Maybe<Scalars['smallint']>
  _in?: Maybe<Array<Scalars['smallint']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['smallint']>
  _lte?: Maybe<Scalars['smallint']>
  _neq?: Maybe<Scalars['smallint']>
  _nin?: Maybe<Array<Scalars['smallint']>>
}

export type Steal_Their_Looks_Random_Args = {
  seed?: Maybe<Scalars['seed_float']>
}

export type Subscription_Root = {
  __typename?: 'subscription_root'
  /** fetch data from the table: "account_deletion_reason" */
  account_deletion_reason: Array<Account_Deletion_Reason>
  /** fetch aggregated fields from the table: "account_deletion_reason" */
  account_deletion_reason_aggregate: Account_Deletion_Reason_Aggregate
  /** fetch data from the table: "account_deletion_reason" using primary key columns */
  account_deletion_reason_by_pk?: Maybe<Account_Deletion_Reason>
  /** fetch data from the table in a streaming manner: "account_deletion_reason" */
  account_deletion_reason_stream: Array<Account_Deletion_Reason>
  /** fetch data from the table: "affiliate" */
  affiliate: Array<Affiliate>
  /** fetch data from the table: "affiliate_advertiser_id" */
  affiliate_advertiser_id: Array<Affiliate_Advertiser_Id>
  /** fetch aggregated fields from the table: "affiliate_advertiser_id" */
  affiliate_advertiser_id_aggregate: Affiliate_Advertiser_Id_Aggregate
  /** fetch data from the table: "affiliate_advertiser_id" using primary key columns */
  affiliate_advertiser_id_by_pk?: Maybe<Affiliate_Advertiser_Id>
  /** fetch data from the table in a streaming manner: "affiliate_advertiser_id" */
  affiliate_advertiser_id_stream: Array<Affiliate_Advertiser_Id>
  /** fetch aggregated fields from the table: "affiliate" */
  affiliate_aggregate: Affiliate_Aggregate
  /** fetch data from the table: "affiliate" using primary key columns */
  affiliate_by_pk?: Maybe<Affiliate>
  /** fetch data from the table: "affiliate_commission_summary" */
  affiliate_commission_summary: Array<Affiliate_Commission_Summary>
  /** fetch aggregated fields from the table: "affiliate_commission_summary" */
  affiliate_commission_summary_aggregate: Affiliate_Commission_Summary_Aggregate
  /** fetch data from the table in a streaming manner: "affiliate_commission_summary" */
  affiliate_commission_summary_stream: Array<Affiliate_Commission_Summary>
  /** fetch data from the table: "affiliate_meta" */
  affiliate_meta: Array<Affiliate_Meta>
  /** fetch aggregated fields from the table: "affiliate_meta" */
  affiliate_meta_aggregate: Affiliate_Meta_Aggregate
  /** fetch data from the table: "affiliate_meta" using primary key columns */
  affiliate_meta_by_pk?: Maybe<Affiliate_Meta>
  /** fetch data from the table in a streaming manner: "affiliate_meta" */
  affiliate_meta_stream: Array<Affiliate_Meta>
  /** fetch data from the table in a streaming manner: "affiliate" */
  affiliate_stream: Array<Affiliate>
  /** fetch data from the table: "affiliate_transaction" */
  affiliate_transaction: Array<Affiliate_Transaction>
  /** fetch aggregated fields from the table: "affiliate_transaction" */
  affiliate_transaction_aggregate: Affiliate_Transaction_Aggregate
  /** fetch data from the table: "affiliate_transaction" using primary key columns */
  affiliate_transaction_by_pk?: Maybe<Affiliate_Transaction>
  /** fetch data from the table in a streaming manner: "affiliate_transaction" */
  affiliate_transaction_stream: Array<Affiliate_Transaction>
  /** execute function "all_shops_for_shop_page" which returns "shop" */
  all_shops_for_shop_page: Array<Shop>
  /** execute function "all_shops_for_shop_page" and query aggregates on result of table type "shop" */
  all_shops_for_shop_page_aggregate: Shop_Aggregate
  /** fetch data from the table: "amazon_categories" */
  amazon_categories: Array<Amazon_Categories>
  /** fetch aggregated fields from the table: "amazon_categories" */
  amazon_categories_aggregate: Amazon_Categories_Aggregate
  /** fetch data from the table in a streaming manner: "amazon_categories" */
  amazon_categories_stream: Array<Amazon_Categories>
  /** fetch data from the table: "billing_mode" */
  billing_mode: Array<Billing_Mode>
  /** fetch aggregated fields from the table: "billing_mode" */
  billing_mode_aggregate: Billing_Mode_Aggregate
  /** fetch data from the table: "billing_mode" using primary key columns */
  billing_mode_by_pk?: Maybe<Billing_Mode>
  /** fetch data from the table in a streaming manner: "billing_mode" */
  billing_mode_stream: Array<Billing_Mode>
  /** fetch data from the table: "billing_platform_connected_account" */
  billing_platform_connected_account: Array<Billing_Platform_Connected_Account>
  /** fetch aggregated fields from the table: "billing_platform_connected_account" */
  billing_platform_connected_account_aggregate: Billing_Platform_Connected_Account_Aggregate
  /** fetch data from the table: "billing_platform_connected_account" using primary key columns */
  billing_platform_connected_account_by_pk?: Maybe<Billing_Platform_Connected_Account>
  /** fetch data from the table: "billing_platform_connected_account_status" */
  billing_platform_connected_account_status: Array<Billing_Platform_Connected_Account_Status>
  /** fetch aggregated fields from the table: "billing_platform_connected_account_status" */
  billing_platform_connected_account_status_aggregate: Billing_Platform_Connected_Account_Status_Aggregate
  /** fetch data from the table: "billing_platform_connected_account_status" using primary key columns */
  billing_platform_connected_account_status_by_pk?: Maybe<Billing_Platform_Connected_Account_Status>
  /** fetch data from the table in a streaming manner: "billing_platform_connected_account_status" */
  billing_platform_connected_account_status_stream: Array<Billing_Platform_Connected_Account_Status>
  /** fetch data from the table in a streaming manner: "billing_platform_connected_account" */
  billing_platform_connected_account_stream: Array<Billing_Platform_Connected_Account>
  /** fetch data from the table: "billing_platform_customer" */
  billing_platform_customer: Array<Billing_Platform_Customer>
  /** fetch aggregated fields from the table: "billing_platform_customer" */
  billing_platform_customer_aggregate: Billing_Platform_Customer_Aggregate
  /** fetch data from the table: "billing_platform_customer" using primary key columns */
  billing_platform_customer_by_pk?: Maybe<Billing_Platform_Customer>
  /** fetch data from the table in a streaming manner: "billing_platform_customer" */
  billing_platform_customer_stream: Array<Billing_Platform_Customer>
  /** fetch data from the table: "cart" */
  cart: Array<Cart>
  /** fetch aggregated fields from the table: "cart" */
  cart_aggregate: Cart_Aggregate
  /** fetch data from the table: "cart" using primary key columns */
  cart_by_pk?: Maybe<Cart>
  /** fetch data from the table: "cart_item" */
  cart_item: Array<Cart_Item>
  /** fetch aggregated fields from the table: "cart_item" */
  cart_item_aggregate: Cart_Item_Aggregate
  /** fetch data from the table: "cart_item" using primary key columns */
  cart_item_by_pk?: Maybe<Cart_Item>
  /** fetch data from the table: "cart_item_like" */
  cart_item_like: Array<Cart_Item_Like>
  /** fetch aggregated fields from the table: "cart_item_like" */
  cart_item_like_aggregate: Cart_Item_Like_Aggregate
  /** fetch data from the table: "cart_item_like" using primary key columns */
  cart_item_like_by_pk?: Maybe<Cart_Item_Like>
  /** fetch data from the table in a streaming manner: "cart_item_like" */
  cart_item_like_stream: Array<Cart_Item_Like>
  /** fetch data from the table: "cart_item_note" */
  cart_item_note: Array<Cart_Item_Note>
  /** fetch aggregated fields from the table: "cart_item_note" */
  cart_item_note_aggregate: Cart_Item_Note_Aggregate
  /** fetch data from the table: "cart_item_note" using primary key columns */
  cart_item_note_by_pk?: Maybe<Cart_Item_Note>
  /** fetch data from the table in a streaming manner: "cart_item_note" */
  cart_item_note_stream: Array<Cart_Item_Note>
  /** fetch data from the table in a streaming manner: "cart_item" */
  cart_item_stream: Array<Cart_Item>
  /** fetch data from the table in a streaming manner: "cart" */
  cart_stream: Array<Cart>
  /** fetch data from the table: "categories" */
  categories: Array<Categories>
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>
  /** fetch data from the table in a streaming manner: "categories" */
  categories_stream: Array<Categories>
  /** fetch data from the table: "category_shop_item" */
  category_shop_item: Array<Category_Shop_Item>
  /** fetch aggregated fields from the table: "category_shop_item" */
  category_shop_item_aggregate: Category_Shop_Item_Aggregate
  /** fetch data from the table: "category_shop_item" using primary key columns */
  category_shop_item_by_pk?: Maybe<Category_Shop_Item>
  /** fetch data from the table in a streaming manner: "category_shop_item" */
  category_shop_item_stream: Array<Category_Shop_Item>
  /** fetch data from the table: "checkout" */
  checkout: Array<Checkout>
  /** fetch aggregated fields from the table: "checkout" */
  checkout_aggregate: Checkout_Aggregate
  /** fetch data from the table: "checkout" using primary key columns */
  checkout_by_pk?: Maybe<Checkout>
  /** fetch data from the table in a streaming manner: "checkout" */
  checkout_stream: Array<Checkout>
  /** fetch data from the table: "collection" */
  collection: Array<Collection>
  /** fetch aggregated fields from the table: "collection" */
  collection_aggregate: Collection_Aggregate
  /** fetch data from the table: "collection" using primary key columns */
  collection_by_pk?: Maybe<Collection>
  /** fetch data from the table: "collection_cart_item" */
  collection_cart_item: Array<Collection_Cart_Item>
  /** fetch aggregated fields from the table: "collection_cart_item" */
  collection_cart_item_aggregate: Collection_Cart_Item_Aggregate
  /** fetch data from the table: "collection_cart_item" using primary key columns */
  collection_cart_item_by_pk?: Maybe<Collection_Cart_Item>
  /** fetch data from the table in a streaming manner: "collection_cart_item" */
  collection_cart_item_stream: Array<Collection_Cart_Item>
  /** fetch data from the table: "collection_collaborator" */
  collection_collaborator: Array<Collection_Collaborator>
  /** fetch aggregated fields from the table: "collection_collaborator" */
  collection_collaborator_aggregate: Collection_Collaborator_Aggregate
  /** fetch data from the table: "collection_collaborator" using primary key columns */
  collection_collaborator_by_pk?: Maybe<Collection_Collaborator>
  /** fetch data from the table in a streaming manner: "collection_collaborator" */
  collection_collaborator_stream: Array<Collection_Collaborator>
  /** fetch data from the table: "collection_invite" */
  collection_invite: Array<Collection_Invite>
  /** fetch aggregated fields from the table: "collection_invite" */
  collection_invite_aggregate: Collection_Invite_Aggregate
  /** fetch data from the table: "collection_invite" using primary key columns */
  collection_invite_by_pk?: Maybe<Collection_Invite>
  /** fetch data from the table: "collection_invite_status" */
  collection_invite_status: Array<Collection_Invite_Status>
  /** fetch aggregated fields from the table: "collection_invite_status" */
  collection_invite_status_aggregate: Collection_Invite_Status_Aggregate
  /** fetch data from the table: "collection_invite_status" using primary key columns */
  collection_invite_status_by_pk?: Maybe<Collection_Invite_Status>
  /** fetch data from the table in a streaming manner: "collection_invite_status" */
  collection_invite_status_stream: Array<Collection_Invite_Status>
  /** fetch data from the table in a streaming manner: "collection_invite" */
  collection_invite_stream: Array<Collection_Invite>
  /** fetch data from the table: "collection_item_claim" */
  collection_item_claim: Array<Collection_Item_Claim>
  /** fetch aggregated fields from the table: "collection_item_claim" */
  collection_item_claim_aggregate: Collection_Item_Claim_Aggregate
  /** fetch data from the table: "collection_item_claim" using primary key columns */
  collection_item_claim_by_pk?: Maybe<Collection_Item_Claim>
  /** fetch data from the table: "collection_item_claim_status" */
  collection_item_claim_status: Array<Collection_Item_Claim_Status>
  /** fetch aggregated fields from the table: "collection_item_claim_status" */
  collection_item_claim_status_aggregate: Collection_Item_Claim_Status_Aggregate
  /** fetch data from the table: "collection_item_claim_status" using primary key columns */
  collection_item_claim_status_by_pk?: Maybe<Collection_Item_Claim_Status>
  /** fetch data from the table in a streaming manner: "collection_item_claim_status" */
  collection_item_claim_status_stream: Array<Collection_Item_Claim_Status>
  /** fetch data from the table in a streaming manner: "collection_item_claim" */
  collection_item_claim_stream: Array<Collection_Item_Claim>
  /** execute function "collection_random" which returns "collection" */
  collection_random: Array<Collection>
  /** execute function "collection_random" and query aggregates on result of table type "collection" */
  collection_random_aggregate: Collection_Aggregate
  /** fetch data from the table: "collection_section" */
  collection_section: Array<Collection_Section>
  /** fetch aggregated fields from the table: "collection_section" */
  collection_section_aggregate: Collection_Section_Aggregate
  /** fetch data from the table: "collection_section" using primary key columns */
  collection_section_by_pk?: Maybe<Collection_Section>
  /** fetch data from the table: "collection_section_cart_item" */
  collection_section_cart_item: Array<Collection_Section_Cart_Item>
  /** fetch aggregated fields from the table: "collection_section_cart_item" */
  collection_section_cart_item_aggregate: Collection_Section_Cart_Item_Aggregate
  /** fetch data from the table: "collection_section_cart_item" using primary key columns */
  collection_section_cart_item_by_pk?: Maybe<Collection_Section_Cart_Item>
  /** fetch data from the table in a streaming manner: "collection_section_cart_item" */
  collection_section_cart_item_stream: Array<Collection_Section_Cart_Item>
  /** fetch data from the table in a streaming manner: "collection_section" */
  collection_section_stream: Array<Collection_Section>
  /** fetch data from the table: "collection_slug_history" */
  collection_slug_history: Array<Collection_Slug_History>
  /** fetch aggregated fields from the table: "collection_slug_history" */
  collection_slug_history_aggregate: Collection_Slug_History_Aggregate
  /** fetch data from the table: "collection_slug_history" using primary key columns */
  collection_slug_history_by_pk?: Maybe<Collection_Slug_History>
  /** fetch data from the table in a streaming manner: "collection_slug_history" */
  collection_slug_history_stream: Array<Collection_Slug_History>
  /** fetch data from the table: "collection_state" */
  collection_state: Array<Collection_State>
  /** fetch aggregated fields from the table: "collection_state" */
  collection_state_aggregate: Collection_State_Aggregate
  /** fetch data from the table: "collection_state" using primary key columns */
  collection_state_by_pk?: Maybe<Collection_State>
  /** fetch data from the table in a streaming manner: "collection_state" */
  collection_state_stream: Array<Collection_State>
  /** fetch data from the table in a streaming manner: "collection" */
  collection_stream: Array<Collection>
  /** fetch data from the table: "collection_type" */
  collection_type: Array<Collection_Type>
  /** fetch aggregated fields from the table: "collection_type" */
  collection_type_aggregate: Collection_Type_Aggregate
  /** fetch data from the table: "collection_type" using primary key columns */
  collection_type_by_pk?: Maybe<Collection_Type>
  /** fetch data from the table in a streaming manner: "collection_type" */
  collection_type_stream: Array<Collection_Type>
  /** fetch data from the table: "comment" */
  comment: Array<Comment>
  /** fetch aggregated fields from the table: "comment" */
  comment_aggregate: Comment_Aggregate
  /** fetch data from the table: "comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>
  /** fetch data from the table in a streaming manner: "comment" */
  comment_stream: Array<Comment>
  /** fetch data from the table: "commentable_item" */
  commentable_item: Array<Commentable_Item>
  /** fetch aggregated fields from the table: "commentable_item" */
  commentable_item_aggregate: Commentable_Item_Aggregate
  /** fetch data from the table: "commentable_item" using primary key columns */
  commentable_item_by_pk?: Maybe<Commentable_Item>
  /** fetch data from the table in a streaming manner: "commentable_item" */
  commentable_item_stream: Array<Commentable_Item>
  /** fetch data from the table: "commentable_item_type" */
  commentable_item_type: Array<Commentable_Item_Type>
  /** fetch aggregated fields from the table: "commentable_item_type" */
  commentable_item_type_aggregate: Commentable_Item_Type_Aggregate
  /** fetch data from the table: "commentable_item_type" using primary key columns */
  commentable_item_type_by_pk?: Maybe<Commentable_Item_Type>
  /** fetch data from the table in a streaming manner: "commentable_item_type" */
  commentable_item_type_stream: Array<Commentable_Item_Type>
  /** fetch data from the table: "communication_template" */
  communication_template: Array<Communication_Template>
  /** fetch aggregated fields from the table: "communication_template" */
  communication_template_aggregate: Communication_Template_Aggregate
  /** fetch data from the table: "communication_template" using primary key columns */
  communication_template_by_pk?: Maybe<Communication_Template>
  /** fetch data from the table: "communication_template_full_list" */
  communication_template_full_list: Array<Communication_Template_Full_List>
  /** fetch aggregated fields from the table: "communication_template_full_list" */
  communication_template_full_list_aggregate: Communication_Template_Full_List_Aggregate
  /** fetch data from the table: "communication_template_full_list" using primary key columns */
  communication_template_full_list_by_pk?: Maybe<Communication_Template_Full_List>
  /** fetch data from the table in a streaming manner: "communication_template_full_list" */
  communication_template_full_list_stream: Array<Communication_Template_Full_List>
  /** fetch data from the table in a streaming manner: "communication_template" */
  communication_template_stream: Array<Communication_Template>
  /** fetch data from the table: "contact_info_type" */
  contact_info_type: Array<Contact_Info_Type>
  /** fetch aggregated fields from the table: "contact_info_type" */
  contact_info_type_aggregate: Contact_Info_Type_Aggregate
  /** fetch data from the table: "contact_info_type" using primary key columns */
  contact_info_type_by_pk?: Maybe<Contact_Info_Type>
  /** fetch data from the table in a streaming manner: "contact_info_type" */
  contact_info_type_stream: Array<Contact_Info_Type>
  /** fetch data from the table: "contact_invite" */
  contact_invite: Array<Contact_Invite>
  /** fetch aggregated fields from the table: "contact_invite" */
  contact_invite_aggregate: Contact_Invite_Aggregate
  /** fetch data from the table: "contact_invite" using primary key columns */
  contact_invite_by_pk?: Maybe<Contact_Invite>
  /** fetch data from the table: "contact_invite_status" */
  contact_invite_status: Array<Contact_Invite_Status>
  /** fetch aggregated fields from the table: "contact_invite_status" */
  contact_invite_status_aggregate: Contact_Invite_Status_Aggregate
  /** fetch data from the table: "contact_invite_status" using primary key columns */
  contact_invite_status_by_pk?: Maybe<Contact_Invite_Status>
  /** fetch data from the table in a streaming manner: "contact_invite_status" */
  contact_invite_status_stream: Array<Contact_Invite_Status>
  /** fetch data from the table in a streaming manner: "contact_invite" */
  contact_invite_stream: Array<Contact_Invite>
  /** fetch data from the table: "contact_invite_type" */
  contact_invite_type: Array<Contact_Invite_Type>
  /** fetch aggregated fields from the table: "contact_invite_type" */
  contact_invite_type_aggregate: Contact_Invite_Type_Aggregate
  /** fetch data from the table: "contact_invite_type" using primary key columns */
  contact_invite_type_by_pk?: Maybe<Contact_Invite_Type>
  /** fetch data from the table in a streaming manner: "contact_invite_type" */
  contact_invite_type_stream: Array<Contact_Invite_Type>
  /** execute function "creator_subscribers_random" which returns "user" */
  creator_subscribers_random: Array<User>
  /** execute function "creator_subscribers_random" and query aggregates on result of table type "user" */
  creator_subscribers_random_aggregate: User_Aggregate
  /** fetch data from the table: "creator_user_subscriptions" */
  creator_user_subscriptions: Array<Creator_User_Subscriptions>
  /** fetch aggregated fields from the table: "creator_user_subscriptions" */
  creator_user_subscriptions_aggregate: Creator_User_Subscriptions_Aggregate
  /** fetch data from the table in a streaming manner: "creator_user_subscriptions" */
  creator_user_subscriptions_stream: Array<Creator_User_Subscriptions>
  /** fetch data from the table: "deal_hop_gate" */
  deal_hop_gate: Array<Deal_Hop_Gate>
  /** fetch aggregated fields from the table: "deal_hop_gate" */
  deal_hop_gate_aggregate: Deal_Hop_Gate_Aggregate
  /** fetch data from the table: "deal_hop_gate" using primary key columns */
  deal_hop_gate_by_pk?: Maybe<Deal_Hop_Gate>
  /** fetch data from the table in a streaming manner: "deal_hop_gate" */
  deal_hop_gate_stream: Array<Deal_Hop_Gate>
  /** fetch data from the table: "device_client_data" */
  device_client_data: Array<Device_Client_Data>
  /** fetch aggregated fields from the table: "device_client_data" */
  device_client_data_aggregate: Device_Client_Data_Aggregate
  /** fetch data from the table: "device_client_data" using primary key columns */
  device_client_data_by_pk?: Maybe<Device_Client_Data>
  /** fetch data from the table in a streaming manner: "device_client_data" */
  device_client_data_stream: Array<Device_Client_Data>
  /** fetch data from the table: "discovery_backlog" */
  discovery_backlog: Array<Discovery_Backlog>
  /** fetch aggregated fields from the table: "discovery_backlog" */
  discovery_backlog_aggregate: Discovery_Backlog_Aggregate
  /** fetch data from the table: "discovery_backlog" using primary key columns */
  discovery_backlog_by_pk?: Maybe<Discovery_Backlog>
  /** fetch data from the table in a streaming manner: "discovery_backlog" */
  discovery_backlog_stream: Array<Discovery_Backlog>
  /** fetch data from the table: "discovery_feed" */
  discovery_feed: Array<Discovery_Feed>
  /** fetch aggregated fields from the table: "discovery_feed" */
  discovery_feed_aggregate: Discovery_Feed_Aggregate
  /** fetch data from the table: "discovery_feed" using primary key columns */
  discovery_feed_by_pk?: Maybe<Discovery_Feed>
  /** execute function "discovery_feed_random" which returns "discovery_feed" */
  discovery_feed_random: Array<Discovery_Feed>
  /** execute function "discovery_feed_random" and query aggregates on result of table type "discovery_feed" */
  discovery_feed_random_aggregate: Discovery_Feed_Aggregate
  /** fetch data from the table in a streaming manner: "discovery_feed" */
  discovery_feed_stream: Array<Discovery_Feed>
  /** fetch data from the table: "discovery_staging" */
  discovery_staging: Array<Discovery_Staging>
  /** fetch aggregated fields from the table: "discovery_staging" */
  discovery_staging_aggregate: Discovery_Staging_Aggregate
  /** fetch data from the table: "discovery_staging" using primary key columns */
  discovery_staging_by_pk?: Maybe<Discovery_Staging>
  /** fetch data from the table in a streaming manner: "discovery_staging" */
  discovery_staging_stream: Array<Discovery_Staging>
  /** fetch data from the table: "featured_shop" */
  featured_shop: Array<Featured_Shop>
  /** fetch aggregated fields from the table: "featured_shop" */
  featured_shop_aggregate: Featured_Shop_Aggregate
  /** fetch data from the table: "featured_shop" using primary key columns */
  featured_shop_by_pk?: Maybe<Featured_Shop>
  /** fetch data from the table: "featured_shop_item" */
  featured_shop_item: Array<Featured_Shop_Item>
  /** fetch aggregated fields from the table: "featured_shop_item" */
  featured_shop_item_aggregate: Featured_Shop_Item_Aggregate
  /** fetch data from the table: "featured_shop_item" using primary key columns */
  featured_shop_item_by_pk?: Maybe<Featured_Shop_Item>
  /** fetch data from the table in a streaming manner: "featured_shop_item" */
  featured_shop_item_stream: Array<Featured_Shop_Item>
  /** fetch data from the table in a streaming manner: "featured_shop" */
  featured_shop_stream: Array<Featured_Shop>
  /** execute function "featured_shops_for_onboarding" which returns "shop" */
  featured_shops_for_onboarding: Array<Shop>
  /** execute function "featured_shops_for_onboarding" and query aggregates on result of table type "shop" */
  featured_shops_for_onboarding_aggregate: Shop_Aggregate
  /** fetch data from the table: "feed_item" */
  feed_item: Array<Feed_Item>
  /** fetch aggregated fields from the table: "feed_item" */
  feed_item_aggregate: Feed_Item_Aggregate
  /** fetch data from the table: "feed_item" using primary key columns */
  feed_item_by_pk?: Maybe<Feed_Item>
  /** fetch data from the table: "feed_item_like" */
  feed_item_like: Array<Feed_Item_Like>
  /** fetch aggregated fields from the table: "feed_item_like" */
  feed_item_like_aggregate: Feed_Item_Like_Aggregate
  /** fetch data from the table: "feed_item_like" using primary key columns */
  feed_item_like_by_pk?: Maybe<Feed_Item_Like>
  /** execute function "feed_item_like_random" which returns "feed_item_like" */
  feed_item_like_random: Array<Feed_Item_Like>
  /** execute function "feed_item_like_random" and query aggregates on result of table type "feed_item_like" */
  feed_item_like_random_aggregate: Feed_Item_Like_Aggregate
  /** fetch data from the table in a streaming manner: "feed_item_like" */
  feed_item_like_stream: Array<Feed_Item_Like>
  /** fetch data from the table in a streaming manner: "feed_item" */
  feed_item_stream: Array<Feed_Item>
  /** fetch data from the table: "feed_item_type" */
  feed_item_type: Array<Feed_Item_Type>
  /** fetch aggregated fields from the table: "feed_item_type" */
  feed_item_type_aggregate: Feed_Item_Type_Aggregate
  /** fetch data from the table: "feed_item_type" using primary key columns */
  feed_item_type_by_pk?: Maybe<Feed_Item_Type>
  /** fetch data from the table in a streaming manner: "feed_item_type" */
  feed_item_type_stream: Array<Feed_Item_Type>
  /** execute function "feed_items_for_style_accounts" which returns "feed_item" */
  feed_items_for_style_accounts: Array<Feed_Item>
  /** execute function "feed_items_for_style_accounts" and query aggregates on result of table type "feed_item" */
  feed_items_for_style_accounts_aggregate: Feed_Item_Aggregate
  /** execute function "feed_items_for_user" which returns "feed_item" */
  feed_items_for_user: Array<Feed_Item>
  /** execute function "feed_items_for_user" and query aggregates on result of table type "feed_item" */
  feed_items_for_user_aggregate: Feed_Item_Aggregate
  /** execute function "feed_items_for_user_subscriptions" which returns "feed_item" */
  feed_items_for_user_subscriptions: Array<Feed_Item>
  /** execute function "feed_items_for_user_subscriptions" and query aggregates on result of table type "feed_item" */
  feed_items_for_user_subscriptions_aggregate: Feed_Item_Aggregate
  /** fetch data from the table: "fmtc_deal" */
  fmtc_deal: Array<Fmtc_Deal>
  /** fetch aggregated fields from the table: "fmtc_deal" */
  fmtc_deal_aggregate: Fmtc_Deal_Aggregate
  /** fetch data from the table: "fmtc_deal" using primary key columns */
  fmtc_deal_by_pk?: Maybe<Fmtc_Deal>
  /** fetch data from the table in a streaming manner: "fmtc_deal" */
  fmtc_deal_stream: Array<Fmtc_Deal>
  /** fetch data from the table: "followable_item" */
  followable_item: Array<Followable_Item>
  /** fetch aggregated fields from the table: "followable_item" */
  followable_item_aggregate: Followable_Item_Aggregate
  /** fetch data from the table: "followable_item" using primary key columns */
  followable_item_by_pk?: Maybe<Followable_Item>
  /** fetch data from the table in a streaming manner: "followable_item" */
  followable_item_stream: Array<Followable_Item>
  /** fetch data from the table: "followable_item_type" */
  followable_item_type: Array<Followable_Item_Type>
  /** fetch aggregated fields from the table: "followable_item_type" */
  followable_item_type_aggregate: Followable_Item_Type_Aggregate
  /** fetch data from the table: "followable_item_type" using primary key columns */
  followable_item_type_by_pk?: Maybe<Followable_Item_Type>
  /** fetch data from the table in a streaming manner: "followable_item_type" */
  followable_item_type_stream: Array<Followable_Item_Type>
  /** fetch data from the table: "leaderboard_event" */
  leaderboard_event: Array<Leaderboard_Event>
  /** fetch aggregated fields from the table: "leaderboard_event" */
  leaderboard_event_aggregate: Leaderboard_Event_Aggregate
  /** fetch data from the table: "leaderboard_event" using primary key columns */
  leaderboard_event_by_pk?: Maybe<Leaderboard_Event>
  /** fetch data from the table: "leaderboard_event_name" */
  leaderboard_event_name: Array<Leaderboard_Event_Name>
  /** fetch aggregated fields from the table: "leaderboard_event_name" */
  leaderboard_event_name_aggregate: Leaderboard_Event_Name_Aggregate
  /** fetch data from the table: "leaderboard_event_name" using primary key columns */
  leaderboard_event_name_by_pk?: Maybe<Leaderboard_Event_Name>
  /** fetch data from the table in a streaming manner: "leaderboard_event_name" */
  leaderboard_event_name_stream: Array<Leaderboard_Event_Name>
  /** fetch data from the table in a streaming manner: "leaderboard_event" */
  leaderboard_event_stream: Array<Leaderboard_Event>
  /** fetch data from the table: "link_type" */
  link_type: Array<Link_Type>
  /** fetch aggregated fields from the table: "link_type" */
  link_type_aggregate: Link_Type_Aggregate
  /** fetch data from the table: "link_type" using primary key columns */
  link_type_by_pk?: Maybe<Link_Type>
  /** fetch data from the table in a streaming manner: "link_type" */
  link_type_stream: Array<Link_Type>
  /** fetch data from the table: "media_enabled_item" */
  media_enabled_item: Array<Media_Enabled_Item>
  /** fetch aggregated fields from the table: "media_enabled_item" */
  media_enabled_item_aggregate: Media_Enabled_Item_Aggregate
  /** fetch data from the table: "media_enabled_item" using primary key columns */
  media_enabled_item_by_pk?: Maybe<Media_Enabled_Item>
  /** fetch data from the table in a streaming manner: "media_enabled_item" */
  media_enabled_item_stream: Array<Media_Enabled_Item>
  /** fetch data from the table: "media_item" */
  media_item: Array<Media_Item>
  /** fetch aggregated fields from the table: "media_item" */
  media_item_aggregate: Media_Item_Aggregate
  /** fetch data from the table: "media_item" using primary key columns */
  media_item_by_pk?: Maybe<Media_Item>
  /** fetch data from the table in a streaming manner: "media_item" */
  media_item_stream: Array<Media_Item>
  /** fetch data from the table: "media_item_type" */
  media_item_type: Array<Media_Item_Type>
  /** fetch aggregated fields from the table: "media_item_type" */
  media_item_type_aggregate: Media_Item_Type_Aggregate
  /** fetch data from the table: "media_item_type" using primary key columns */
  media_item_type_by_pk?: Maybe<Media_Item_Type>
  /** fetch data from the table in a streaming manner: "media_item_type" */
  media_item_type_stream: Array<Media_Item_Type>
  /** An array relationship */
  media_items: Array<Media_Items>
  /** An aggregate relationship */
  media_items_aggregate: Media_Items_Aggregate
  /** fetch data from the table: "media_items" using primary key columns */
  media_items_by_pk?: Maybe<Media_Items>
  /** fetch data from the table in a streaming manner: "media_items" */
  media_items_stream: Array<Media_Items>
  /** fetch data from the table: "media_type" */
  media_type: Array<Media_Type>
  /** fetch aggregated fields from the table: "media_type" */
  media_type_aggregate: Media_Type_Aggregate
  /** fetch data from the table: "media_type" using primary key columns */
  media_type_by_pk?: Maybe<Media_Type>
  /** fetch data from the table in a streaming manner: "media_type" */
  media_type_stream: Array<Media_Type>
  /** execute function "mention_search_users" which returns "user" */
  mention_search_users: Array<User>
  /** execute function "mention_search_users" and query aggregates on result of table type "user" */
  mention_search_users_aggregate: User_Aggregate
  /** fetch data from the table: "mobile_app_home" */
  mobile_app_home: Array<Mobile_App_Home>
  /** fetch aggregated fields from the table: "mobile_app_home" */
  mobile_app_home_aggregate: Mobile_App_Home_Aggregate
  /** fetch data from the table: "mobile_app_home" using primary key columns */
  mobile_app_home_by_pk?: Maybe<Mobile_App_Home>
  /** fetch data from the table in a streaming manner: "mobile_app_home" */
  mobile_app_home_stream: Array<Mobile_App_Home>
  /** fetch data from the table: "mobile_home" */
  mobile_home: Array<Mobile_Home>
  /** fetch aggregated fields from the table: "mobile_home" */
  mobile_home_aggregate: Mobile_Home_Aggregate
  /** fetch data from the table: "mobile_home" using primary key columns */
  mobile_home_by_pk?: Maybe<Mobile_Home>
  /** fetch data from the table in a streaming manner: "mobile_home" */
  mobile_home_stream: Array<Mobile_Home>
  /** fetch data from the table: "mobile_push_notification" */
  mobile_push_notification: Array<Mobile_Push_Notification>
  /** fetch aggregated fields from the table: "mobile_push_notification" */
  mobile_push_notification_aggregate: Mobile_Push_Notification_Aggregate
  /** fetch data from the table: "mobile_push_notification" using primary key columns */
  mobile_push_notification_by_pk?: Maybe<Mobile_Push_Notification>
  /** fetch data from the table: "mobile_push_notification_status" */
  mobile_push_notification_status: Array<Mobile_Push_Notification_Status>
  /** fetch aggregated fields from the table: "mobile_push_notification_status" */
  mobile_push_notification_status_aggregate: Mobile_Push_Notification_Status_Aggregate
  /** fetch data from the table: "mobile_push_notification_status" using primary key columns */
  mobile_push_notification_status_by_pk?: Maybe<Mobile_Push_Notification_Status>
  /** fetch data from the table in a streaming manner: "mobile_push_notification_status" */
  mobile_push_notification_status_stream: Array<Mobile_Push_Notification_Status>
  /** fetch data from the table in a streaming manner: "mobile_push_notification" */
  mobile_push_notification_stream: Array<Mobile_Push_Notification>
  /** fetch data from the table: "notifiable_feed_item_user" */
  notifiable_feed_item_user: Array<Notifiable_Feed_Item_User>
  /** fetch aggregated fields from the table: "notifiable_feed_item_user" */
  notifiable_feed_item_user_aggregate: Notifiable_Feed_Item_User_Aggregate
  /** fetch data from the table in a streaming manner: "notifiable_feed_item_user" */
  notifiable_feed_item_user_stream: Array<Notifiable_Feed_Item_User>
  /** fetch data from the table: "notifiable_item" */
  notifiable_item: Array<Notifiable_Item>
  /** fetch aggregated fields from the table: "notifiable_item" */
  notifiable_item_aggregate: Notifiable_Item_Aggregate
  /** fetch data from the table: "notifiable_item" using primary key columns */
  notifiable_item_by_pk?: Maybe<Notifiable_Item>
  /** fetch data from the table in a streaming manner: "notifiable_item" */
  notifiable_item_stream: Array<Notifiable_Item>
  /** fetch data from the table: "notifiable_item_type" */
  notifiable_item_type: Array<Notifiable_Item_Type>
  /** fetch aggregated fields from the table: "notifiable_item_type" */
  notifiable_item_type_aggregate: Notifiable_Item_Type_Aggregate
  /** fetch data from the table: "notifiable_item_type" using primary key columns */
  notifiable_item_type_by_pk?: Maybe<Notifiable_Item_Type>
  /** fetch data from the table in a streaming manner: "notifiable_item_type" */
  notifiable_item_type_stream: Array<Notifiable_Item_Type>
  /** fetch data from the table: "notification" */
  notification: Array<Notification>
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>
  /** execute function "notification_center_feed_for_user" which returns "notification_center_item" */
  notification_center_feed_for_user: Array<Notification_Center_Item>
  /** execute function "notification_center_feed_for_user" and query aggregates on result of table type "notification_center_item" */
  notification_center_feed_for_user_aggregate: Notification_Center_Item_Aggregate
  /** fetch data from the table: "notification_center_item" */
  notification_center_item: Array<Notification_Center_Item>
  /** fetch aggregated fields from the table: "notification_center_item" */
  notification_center_item_aggregate: Notification_Center_Item_Aggregate
  /** fetch data from the table: "notification_center_item" using primary key columns */
  notification_center_item_by_pk?: Maybe<Notification_Center_Item>
  /** fetch data from the table: "notification_center_item_read_status" */
  notification_center_item_read_status: Array<Notification_Center_Item_Read_Status>
  /** fetch aggregated fields from the table: "notification_center_item_read_status" */
  notification_center_item_read_status_aggregate: Notification_Center_Item_Read_Status_Aggregate
  /** fetch data from the table: "notification_center_item_read_status" using primary key columns */
  notification_center_item_read_status_by_pk?: Maybe<Notification_Center_Item_Read_Status>
  /** fetch data from the table in a streaming manner: "notification_center_item_read_status" */
  notification_center_item_read_status_stream: Array<Notification_Center_Item_Read_Status>
  /** fetch data from the table in a streaming manner: "notification_center_item" */
  notification_center_item_stream: Array<Notification_Center_Item>
  /** fetch data from the table: "notification_center_item_type" */
  notification_center_item_type: Array<Notification_Center_Item_Type>
  /** fetch aggregated fields from the table: "notification_center_item_type" */
  notification_center_item_type_aggregate: Notification_Center_Item_Type_Aggregate
  /** fetch data from the table: "notification_center_item_type" using primary key columns */
  notification_center_item_type_by_pk?: Maybe<Notification_Center_Item_Type>
  /** fetch data from the table in a streaming manner: "notification_center_item_type" */
  notification_center_item_type_stream: Array<Notification_Center_Item_Type>
  /** fetch data from the table: "notification_preference" */
  notification_preference: Array<Notification_Preference>
  /** fetch aggregated fields from the table: "notification_preference" */
  notification_preference_aggregate: Notification_Preference_Aggregate
  /** fetch data from the table: "notification_preference" using primary key columns */
  notification_preference_by_pk?: Maybe<Notification_Preference>
  /** fetch data from the table in a streaming manner: "notification_preference" */
  notification_preference_stream: Array<Notification_Preference>
  /** An array relationship */
  notification_status: Array<Notification_Status>
  /** An aggregate relationship */
  notification_status_aggregate: Notification_Status_Aggregate
  /** fetch data from the table: "notification_status" using primary key columns */
  notification_status_by_pk?: Maybe<Notification_Status>
  /** fetch data from the table in a streaming manner: "notification_status" */
  notification_status_stream: Array<Notification_Status>
  /** fetch data from the table in a streaming manner: "notification" */
  notification_stream: Array<Notification>
  /** fetch data from the table: "onboarding_categories" */
  onboarding_categories: Array<Onboarding_Categories>
  /** fetch aggregated fields from the table: "onboarding_categories" */
  onboarding_categories_aggregate: Onboarding_Categories_Aggregate
  /** fetch data from the table: "onboarding_categories" using primary key columns */
  onboarding_categories_by_pk?: Maybe<Onboarding_Categories>
  /** fetch data from the table in a streaming manner: "onboarding_categories" */
  onboarding_categories_stream: Array<Onboarding_Categories>
  /** fetch data from the table: "onboarding_sources" */
  onboarding_sources: Array<Onboarding_Sources>
  /** fetch aggregated fields from the table: "onboarding_sources" */
  onboarding_sources_aggregate: Onboarding_Sources_Aggregate
  /** fetch data from the table: "onboarding_sources" using primary key columns */
  onboarding_sources_by_pk?: Maybe<Onboarding_Sources>
  /** fetch data from the table in a streaming manner: "onboarding_sources" */
  onboarding_sources_stream: Array<Onboarding_Sources>
  /** fetch data from the table: "one_off_email" */
  one_off_email: Array<One_Off_Email>
  /** fetch aggregated fields from the table: "one_off_email" */
  one_off_email_aggregate: One_Off_Email_Aggregate
  /** fetch data from the table: "one_off_email" using primary key columns */
  one_off_email_by_pk?: Maybe<One_Off_Email>
  /** fetch data from the table in a streaming manner: "one_off_email" */
  one_off_email_stream: Array<One_Off_Email>
  /** execute function "other_shops_for_shop_page" which returns "shop" */
  other_shops_for_shop_page: Array<Shop>
  /** execute function "other_shops_for_shop_page" and query aggregates on result of table type "shop" */
  other_shops_for_shop_page_aggregate: Shop_Aggregate
  /** fetch data from the table: "pageview_event" */
  pageview_event: Array<Pageview_Event>
  /** fetch aggregated fields from the table: "pageview_event" */
  pageview_event_aggregate: Pageview_Event_Aggregate
  /** fetch data from the table: "pageview_event" using primary key columns */
  pageview_event_by_pk?: Maybe<Pageview_Event>
  /** fetch data from the table in a streaming manner: "pageview_event" */
  pageview_event_stream: Array<Pageview_Event>
  /** fetch data from the table: "pageview_event_summary" */
  pageview_event_summary: Array<Pageview_Event_Summary>
  /** fetch aggregated fields from the table: "pageview_event_summary" */
  pageview_event_summary_aggregate: Pageview_Event_Summary_Aggregate
  /** fetch data from the table in a streaming manner: "pageview_event_summary" */
  pageview_event_summary_stream: Array<Pageview_Event_Summary>
  /** fetch data from the table: "promo_period" */
  promo_period: Array<Promo_Period>
  /** fetch aggregated fields from the table: "promo_period" */
  promo_period_aggregate: Promo_Period_Aggregate
  /** fetch data from the table: "promo_period" using primary key columns */
  promo_period_by_pk?: Maybe<Promo_Period>
  /** fetch data from the table in a streaming manner: "promo_period" */
  promo_period_stream: Array<Promo_Period>
  /** fetch data from the table: "push_notification" */
  push_notification: Array<Push_Notification>
  /** fetch aggregated fields from the table: "push_notification" */
  push_notification_aggregate: Push_Notification_Aggregate
  /** fetch data from the table: "push_notification" using primary key columns */
  push_notification_by_pk?: Maybe<Push_Notification>
  /** An array relationship */
  push_notification_status: Array<Push_Notification_Status>
  /** An aggregate relationship */
  push_notification_status_aggregate: Push_Notification_Status_Aggregate
  /** fetch data from the table: "push_notification_status" using primary key columns */
  push_notification_status_by_pk?: Maybe<Push_Notification_Status>
  /** fetch data from the table in a streaming manner: "push_notification_status" */
  push_notification_status_stream: Array<Push_Notification_Status>
  /** fetch data from the table in a streaming manner: "push_notification" */
  push_notification_stream: Array<Push_Notification>
  /** execute function "random_discovery_feed_items" which returns "discovery_feed" */
  random_discovery_feed_items: Array<Discovery_Feed>
  /** execute function "random_discovery_feed_items" and query aggregates on result of table type "discovery_feed" */
  random_discovery_feed_items_aggregate: Discovery_Feed_Aggregate
  /** execute function "recommended_shops_by_user" which returns "shop" */
  recommended_shops_by_user: Array<Shop>
  /** execute function "recommended_shops_by_user" and query aggregates on result of table type "shop" */
  recommended_shops_by_user_aggregate: Shop_Aggregate
  /** fetch data from the table: "redirect_ids" */
  redirect_ids: Array<Redirect_Ids>
  /** fetch aggregated fields from the table: "redirect_ids" */
  redirect_ids_aggregate: Redirect_Ids_Aggregate
  /** fetch data from the table: "redirect_ids" using primary key columns */
  redirect_ids_by_pk?: Maybe<Redirect_Ids>
  /** fetch data from the table in a streaming manner: "redirect_ids" */
  redirect_ids_stream: Array<Redirect_Ids>
  /** execute function "related_shops_by_category" which returns "shop" */
  related_shops_by_category: Array<Shop>
  /** execute function "related_shops_by_category" and query aggregates on result of table type "shop" */
  related_shops_by_category_aggregate: Shop_Aggregate
  /** execute function "related_users_for_shop_item" which returns "user" */
  related_users_for_shop_item: Array<User>
  /** execute function "related_users_for_shop_item" and query aggregates on result of table type "user" */
  related_users_for_shop_item_aggregate: User_Aggregate
  /** fetch data from the table: "remove_reason" */
  remove_reason: Array<Remove_Reason>
  /** fetch aggregated fields from the table: "remove_reason" */
  remove_reason_aggregate: Remove_Reason_Aggregate
  /** fetch data from the table: "remove_reason" using primary key columns */
  remove_reason_by_pk?: Maybe<Remove_Reason>
  /** fetch data from the table in a streaming manner: "remove_reason" */
  remove_reason_stream: Array<Remove_Reason>
  /** fetch data from the table: "rewardable_action_names" */
  rewardable_action_names: Array<Rewardable_Action_Names>
  /** fetch aggregated fields from the table: "rewardable_action_names" */
  rewardable_action_names_aggregate: Rewardable_Action_Names_Aggregate
  /** fetch data from the table: "rewardable_action_names" using primary key columns */
  rewardable_action_names_by_pk?: Maybe<Rewardable_Action_Names>
  /** fetch data from the table in a streaming manner: "rewardable_action_names" */
  rewardable_action_names_stream: Array<Rewardable_Action_Names>
  /** fetch data from the table: "rewardable_item_type" */
  rewardable_item_type: Array<Rewardable_Item_Type>
  /** fetch aggregated fields from the table: "rewardable_item_type" */
  rewardable_item_type_aggregate: Rewardable_Item_Type_Aggregate
  /** fetch data from the table: "rewardable_item_type" using primary key columns */
  rewardable_item_type_by_pk?: Maybe<Rewardable_Item_Type>
  /** fetch data from the table in a streaming manner: "rewardable_item_type" */
  rewardable_item_type_stream: Array<Rewardable_Item_Type>
  /** fetch data from the table: "search_query" */
  search_query: Array<Search_Query>
  /** fetch aggregated fields from the table: "search_query" */
  search_query_aggregate: Search_Query_Aggregate
  /** fetch data from the table: "search_query" using primary key columns */
  search_query_by_pk?: Maybe<Search_Query>
  /** fetch data from the table: "search_query_result" */
  search_query_result: Array<Search_Query_Result>
  /** fetch aggregated fields from the table: "search_query_result" */
  search_query_result_aggregate: Search_Query_Result_Aggregate
  /** fetch data from the table: "search_query_result" using primary key columns */
  search_query_result_by_pk?: Maybe<Search_Query_Result>
  /** fetch data from the table in a streaming manner: "search_query_result" */
  search_query_result_stream: Array<Search_Query_Result>
  /** fetch data from the table in a streaming manner: "search_query" */
  search_query_stream: Array<Search_Query>
  /** execute function "search_shop_items" which returns "shop_item" */
  search_shop_items: Array<Shop_Item>
  /** execute function "search_shop_items" and query aggregates on result of table type "shop_item" */
  search_shop_items_aggregate: Shop_Item_Aggregate
  /** execute function "search_shops" which returns "shop" */
  search_shops: Array<Shop>
  /** execute function "search_shops" and query aggregates on result of table type "shop" */
  search_shops_aggregate: Shop_Aggregate
  /** execute function "search_users" which returns "user" */
  search_users: Array<User>
  /** execute function "search_users" and query aggregates on result of table type "user" */
  search_users_aggregate: User_Aggregate
  /** fetch data from the table: "shop" */
  shop: Array<Shop>
  /** fetch data from the table: "shop_affiliate" */
  shop_affiliate: Array<Shop_Affiliate>
  /** fetch aggregated fields from the table: "shop_affiliate" */
  shop_affiliate_aggregate: Shop_Affiliate_Aggregate
  /** fetch data from the table: "shop_affiliate" using primary key columns */
  shop_affiliate_by_pk?: Maybe<Shop_Affiliate>
  /** fetch data from the table in a streaming manner: "shop_affiliate" */
  shop_affiliate_stream: Array<Shop_Affiliate>
  /** fetch aggregated fields from the table: "shop" */
  shop_aggregate: Shop_Aggregate
  /** fetch data from the table: "shop" using primary key columns */
  shop_by_pk?: Maybe<Shop>
  /** fetch data from the table: "shop_categorization" */
  shop_categorization: Array<Shop_Categorization>
  /** fetch aggregated fields from the table: "shop_categorization" */
  shop_categorization_aggregate: Shop_Categorization_Aggregate
  /** fetch data from the table: "shop_categorization" using primary key columns */
  shop_categorization_by_pk?: Maybe<Shop_Categorization>
  /** fetch data from the table in a streaming manner: "shop_categorization" */
  shop_categorization_stream: Array<Shop_Categorization>
  /** fetch data from the table: "shop_categorized_deals" */
  shop_categorized_deals: Array<Shop_Categorized_Deals>
  /** fetch aggregated fields from the table: "shop_categorized_deals" */
  shop_categorized_deals_aggregate: Shop_Categorized_Deals_Aggregate
  /** fetch data from the table in a streaming manner: "shop_categorized_deals" */
  shop_categorized_deals_stream: Array<Shop_Categorized_Deals>
  /** fetch data from the table: "shop_category" */
  shop_category: Array<Shop_Category>
  /** fetch aggregated fields from the table: "shop_category" */
  shop_category_aggregate: Shop_Category_Aggregate
  /** fetch data from the table: "shop_category" using primary key columns */
  shop_category_by_pk?: Maybe<Shop_Category>
  /** fetch data from the table in a streaming manner: "shop_category" */
  shop_category_stream: Array<Shop_Category>
  /** execute function "shop_collections" which returns "collection" */
  shop_collections: Array<Collection>
  /** execute function "shop_collections" and query aggregates on result of table type "collection" */
  shop_collections_aggregate: Collection_Aggregate
  /** fetch data from the table: "shop_item" */
  shop_item: Array<Shop_Item>
  /** fetch aggregated fields from the table: "shop_item" */
  shop_item_aggregate: Shop_Item_Aggregate
  /** fetch data from the table: "shop_item_availability" */
  shop_item_availability: Array<Shop_Item_Availability>
  /** fetch aggregated fields from the table: "shop_item_availability" */
  shop_item_availability_aggregate: Shop_Item_Availability_Aggregate
  /** fetch data from the table: "shop_item_availability" using primary key columns */
  shop_item_availability_by_pk?: Maybe<Shop_Item_Availability>
  /** fetch data from the table in a streaming manner: "shop_item_availability" */
  shop_item_availability_stream: Array<Shop_Item_Availability>
  /** fetch data from the table: "shop_item" using primary key columns */
  shop_item_by_pk?: Maybe<Shop_Item>
  /** fetch data from the table: "shop_item_enrichment_status" */
  shop_item_enrichment_status: Array<Shop_Item_Enrichment_Status>
  /** fetch aggregated fields from the table: "shop_item_enrichment_status" */
  shop_item_enrichment_status_aggregate: Shop_Item_Enrichment_Status_Aggregate
  /** fetch data from the table: "shop_item_enrichment_status" using primary key columns */
  shop_item_enrichment_status_by_pk?: Maybe<Shop_Item_Enrichment_Status>
  /** fetch data from the table in a streaming manner: "shop_item_enrichment_status" */
  shop_item_enrichment_status_stream: Array<Shop_Item_Enrichment_Status>
  /** fetch data from the table: "shop_item_history" */
  shop_item_history: Array<Shop_Item_History>
  /** fetch aggregated fields from the table: "shop_item_history" */
  shop_item_history_aggregate: Shop_Item_History_Aggregate
  /** fetch data from the table: "shop_item_history" using primary key columns */
  shop_item_history_by_pk?: Maybe<Shop_Item_History>
  /** fetch data from the table in a streaming manner: "shop_item_history" */
  shop_item_history_stream: Array<Shop_Item_History>
  /** fetch data from the table: "shop_item_image" */
  shop_item_image: Array<Shop_Item_Image>
  /** fetch aggregated fields from the table: "shop_item_image" */
  shop_item_image_aggregate: Shop_Item_Image_Aggregate
  /** fetch data from the table: "shop_item_image" using primary key columns */
  shop_item_image_by_pk?: Maybe<Shop_Item_Image>
  /** fetch data from the table in a streaming manner: "shop_item_image" */
  shop_item_image_stream: Array<Shop_Item_Image>
  /** fetch data from the table: "shop_item_index" */
  shop_item_index: Array<Shop_Item_Index>
  /** fetch aggregated fields from the table: "shop_item_index" */
  shop_item_index_aggregate: Shop_Item_Index_Aggregate
  /** fetch data from the table: "shop_item_index" using primary key columns */
  shop_item_index_by_pk?: Maybe<Shop_Item_Index>
  /** fetch data from the table in a streaming manner: "shop_item_index" */
  shop_item_index_stream: Array<Shop_Item_Index>
  /** fetch data from the table in a streaming manner: "shop_item" */
  shop_item_stream: Array<Shop_Item>
  /** fetch data from the table: "shop_item_to_be_scraped" */
  shop_item_to_be_scraped: Array<Shop_Item_To_Be_Scraped>
  /** fetch aggregated fields from the table: "shop_item_to_be_scraped" */
  shop_item_to_be_scraped_aggregate: Shop_Item_To_Be_Scraped_Aggregate
  /** fetch data from the table in a streaming manner: "shop_item_to_be_scraped" */
  shop_item_to_be_scraped_stream: Array<Shop_Item_To_Be_Scraped>
  /** fetch data from the table: "shop_popularity" */
  shop_popularity: Array<Shop_Popularity>
  /** fetch aggregated fields from the table: "shop_popularity" */
  shop_popularity_aggregate: Shop_Popularity_Aggregate
  /** fetch data from the table in a streaming manner: "shop_popularity" */
  shop_popularity_stream: Array<Shop_Popularity>
  /** fetch data from the table: "shop_popularity_with_points" */
  shop_popularity_with_points: Array<Shop_Popularity_With_Points>
  /** fetch aggregated fields from the table: "shop_popularity_with_points" */
  shop_popularity_with_points_aggregate: Shop_Popularity_With_Points_Aggregate
  /** fetch data from the table in a streaming manner: "shop_popularity_with_points" */
  shop_popularity_with_points_stream: Array<Shop_Popularity_With_Points>
  /** fetch data from the table: "shop_rating" */
  shop_rating: Array<Shop_Rating>
  /** fetch aggregated fields from the table: "shop_rating" */
  shop_rating_aggregate: Shop_Rating_Aggregate
  /** fetch data from the table: "shop_rating" using primary key columns */
  shop_rating_by_pk?: Maybe<Shop_Rating>
  /** fetch data from the table in a streaming manner: "shop_rating" */
  shop_rating_stream: Array<Shop_Rating>
  /** fetch data from the table in a streaming manner: "shop" */
  shop_stream: Array<Shop>
  /** fetch data from the table: "similar_products_history" */
  similar_products_history: Array<Similar_Products_History>
  /** fetch aggregated fields from the table: "similar_products_history" */
  similar_products_history_aggregate: Similar_Products_History_Aggregate
  /** fetch data from the table: "similar_products_history" using primary key columns */
  similar_products_history_by_pk?: Maybe<Similar_Products_History>
  /** fetch data from the table in a streaming manner: "similar_products_history" */
  similar_products_history_stream: Array<Similar_Products_History>
  /** execute function "similar_products_history_unique" which returns "similar_products_history" */
  similar_products_history_unique: Array<Similar_Products_History>
  /** execute function "similar_products_history_unique" and query aggregates on result of table type "similar_products_history" */
  similar_products_history_unique_aggregate: Similar_Products_History_Aggregate
  /** execute function "single_unread_notification_banner_item_for_user" which returns "notification_center_item" */
  single_unread_notification_banner_item_for_user?: Maybe<Notification_Center_Item>
  /** execute function "single_unread_notification_banner_item_for_user" and query aggregates on result of table type "notification_center_item" */
  single_unread_notification_banner_item_for_user_aggregate: Notification_Center_Item_Aggregate
  /** execute function "steal_their_looks" which returns "collection" */
  steal_their_looks: Array<Collection>
  /** execute function "steal_their_looks" and query aggregates on result of table type "collection" */
  steal_their_looks_aggregate: Collection_Aggregate
  /** execute function "steal_their_looks_random" which returns "collection" */
  steal_their_looks_random: Array<Collection>
  /** execute function "steal_their_looks_random" and query aggregates on result of table type "collection" */
  steal_their_looks_random_aggregate: Collection_Aggregate
  /** execute function "used_creator_redirect" which returns "user" */
  used_creator_redirect: Array<User>
  /** execute function "used_creator_redirect" and query aggregates on result of table type "user" */
  used_creator_redirect_aggregate: User_Aggregate
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch data from the table: "user_account_type" */
  user_account_type: Array<User_Account_Type>
  /** fetch aggregated fields from the table: "user_account_type" */
  user_account_type_aggregate: User_Account_Type_Aggregate
  /** fetch data from the table: "user_account_type" using primary key columns */
  user_account_type_by_pk?: Maybe<User_Account_Type>
  /** fetch data from the table in a streaming manner: "user_account_type" */
  user_account_type_stream: Array<User_Account_Type>
  /** fetch data from the table: "user_action" */
  user_action: Array<User_Action>
  /** fetch aggregated fields from the table: "user_action" */
  user_action_aggregate: User_Action_Aggregate
  /** fetch data from the table: "user_action" using primary key columns */
  user_action_by_pk?: Maybe<User_Action>
  /** fetch data from the table in a streaming manner: "user_action" */
  user_action_stream: Array<User_Action>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user_app_version" */
  user_app_version: Array<User_App_Version>
  /** fetch aggregated fields from the table: "user_app_version" */
  user_app_version_aggregate: User_App_Version_Aggregate
  /** fetch data from the table: "user_app_version" using primary key columns */
  user_app_version_by_pk?: Maybe<User_App_Version>
  /** fetch data from the table in a streaming manner: "user_app_version" */
  user_app_version_stream: Array<User_App_Version>
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table: "user_carts_onboarding" */
  user_carts_onboarding: Array<User_Carts_Onboarding>
  /** fetch aggregated fields from the table: "user_carts_onboarding" */
  user_carts_onboarding_aggregate: User_Carts_Onboarding_Aggregate
  /** fetch data from the table: "user_carts_onboarding" using primary key columns */
  user_carts_onboarding_by_pk?: Maybe<User_Carts_Onboarding>
  /** fetch data from the table in a streaming manner: "user_carts_onboarding" */
  user_carts_onboarding_stream: Array<User_Carts_Onboarding>
  /** fetch data from the table: "user_category" */
  user_category: Array<User_Category>
  /** fetch aggregated fields from the table: "user_category" */
  user_category_aggregate: User_Category_Aggregate
  /** fetch data from the table: "user_category" using primary key columns */
  user_category_by_pk?: Maybe<User_Category>
  /** fetch data from the table in a streaming manner: "user_category" */
  user_category_stream: Array<User_Category>
  /** fetch data from the table: "user_deletion" */
  user_deletion: Array<User_Deletion>
  /** fetch aggregated fields from the table: "user_deletion" */
  user_deletion_aggregate: User_Deletion_Aggregate
  /** fetch data from the table: "user_deletion" using primary key columns */
  user_deletion_by_pk?: Maybe<User_Deletion>
  /** fetch data from the table in a streaming manner: "user_deletion" */
  user_deletion_stream: Array<User_Deletion>
  /** fetch data from the table: "user_feedback" */
  user_feedback: Array<User_Feedback>
  /** fetch aggregated fields from the table: "user_feedback" */
  user_feedback_aggregate: User_Feedback_Aggregate
  /** fetch data from the table: "user_feedback" using primary key columns */
  user_feedback_by_pk?: Maybe<User_Feedback>
  /** fetch data from the table: "user_feedback_item" */
  user_feedback_item: Array<User_Feedback_Item>
  /** fetch aggregated fields from the table: "user_feedback_item" */
  user_feedback_item_aggregate: User_Feedback_Item_Aggregate
  /** fetch data from the table: "user_feedback_item" using primary key columns */
  user_feedback_item_by_pk?: Maybe<User_Feedback_Item>
  /** fetch data from the table in a streaming manner: "user_feedback_item" */
  user_feedback_item_stream: Array<User_Feedback_Item>
  /** fetch data from the table in a streaming manner: "user_feedback" */
  user_feedback_stream: Array<User_Feedback>
  /** fetch data from the table: "user_feedback_type" */
  user_feedback_type: Array<User_Feedback_Type>
  /** fetch aggregated fields from the table: "user_feedback_type" */
  user_feedback_type_aggregate: User_Feedback_Type_Aggregate
  /** fetch data from the table: "user_feedback_type" using primary key columns */
  user_feedback_type_by_pk?: Maybe<User_Feedback_Type>
  /** fetch data from the table in a streaming manner: "user_feedback_type" */
  user_feedback_type_stream: Array<User_Feedback_Type>
  /** fetch data from the table: "user_flags" */
  user_flags: Array<User_Flags>
  /** fetch aggregated fields from the table: "user_flags" */
  user_flags_aggregate: User_Flags_Aggregate
  /** fetch data from the table: "user_flags" using primary key columns */
  user_flags_by_pk?: Maybe<User_Flags>
  /** fetch data from the table in a streaming manner: "user_flags" */
  user_flags_stream: Array<User_Flags>
  /** fetch data from the table: "user_impersonation" */
  user_impersonation: Array<User_Impersonation>
  /** fetch aggregated fields from the table: "user_impersonation" */
  user_impersonation_aggregate: User_Impersonation_Aggregate
  /** fetch data from the table: "user_impersonation" using primary key columns */
  user_impersonation_by_pk?: Maybe<User_Impersonation>
  /** fetch data from the table in a streaming manner: "user_impersonation" */
  user_impersonation_stream: Array<User_Impersonation>
  /** fetch data from the table: "user_ip_address" */
  user_ip_address: Array<User_Ip_Address>
  /** fetch aggregated fields from the table: "user_ip_address" */
  user_ip_address_aggregate: User_Ip_Address_Aggregate
  /** fetch data from the table: "user_ip_address" using primary key columns */
  user_ip_address_by_pk?: Maybe<User_Ip_Address>
  /** fetch data from the table in a streaming manner: "user_ip_address" */
  user_ip_address_stream: Array<User_Ip_Address>
  /** fetch data from the table: "user_items_followed" */
  user_items_followed: Array<User_Items_Followed>
  /** fetch aggregated fields from the table: "user_items_followed" */
  user_items_followed_aggregate: User_Items_Followed_Aggregate
  /** fetch data from the table: "user_items_followed" using primary key columns */
  user_items_followed_by_pk?: Maybe<User_Items_Followed>
  /** fetch data from the table in a streaming manner: "user_items_followed" */
  user_items_followed_stream: Array<User_Items_Followed>
  /** fetch data from the table: "user_links" */
  user_links: Array<User_Links>
  /** fetch aggregated fields from the table: "user_links" */
  user_links_aggregate: User_Links_Aggregate
  /** fetch data from the table: "user_links" using primary key columns */
  user_links_by_pk?: Maybe<User_Links>
  /** fetch data from the table in a streaming manner: "user_links" */
  user_links_stream: Array<User_Links>
  /** fetch data from the table: "user_onboarding_source" */
  user_onboarding_source: Array<User_Onboarding_Source>
  /** fetch aggregated fields from the table: "user_onboarding_source" */
  user_onboarding_source_aggregate: User_Onboarding_Source_Aggregate
  /** fetch data from the table: "user_onboarding_source" using primary key columns */
  user_onboarding_source_by_pk?: Maybe<User_Onboarding_Source>
  /** fetch data from the table in a streaming manner: "user_onboarding_source" */
  user_onboarding_source_stream: Array<User_Onboarding_Source>
  /** fetch data from the table: "user_onboarding_state" */
  user_onboarding_state: Array<User_Onboarding_State>
  /** fetch aggregated fields from the table: "user_onboarding_state" */
  user_onboarding_state_aggregate: User_Onboarding_State_Aggregate
  /** fetch data from the table: "user_onboarding_state" using primary key columns */
  user_onboarding_state_by_pk?: Maybe<User_Onboarding_State>
  /** fetch data from the table in a streaming manner: "user_onboarding_state" */
  user_onboarding_state_stream: Array<User_Onboarding_State>
  /** fetch data from the table: "user_phone_contact" */
  user_phone_contact: Array<User_Phone_Contact>
  /** fetch aggregated fields from the table: "user_phone_contact" */
  user_phone_contact_aggregate: User_Phone_Contact_Aggregate
  /** fetch data from the table: "user_phone_contact" using primary key columns */
  user_phone_contact_by_pk?: Maybe<User_Phone_Contact>
  /** fetch data from the table in a streaming manner: "user_phone_contact" */
  user_phone_contact_stream: Array<User_Phone_Contact>
  /** fetch data from the table: "user_points_redeemed_summary" */
  user_points_redeemed_summary: Array<User_Points_Redeemed_Summary>
  /** fetch aggregated fields from the table: "user_points_redeemed_summary" */
  user_points_redeemed_summary_aggregate: User_Points_Redeemed_Summary_Aggregate
  /** fetch data from the table in a streaming manner: "user_points_redeemed_summary" */
  user_points_redeemed_summary_stream: Array<User_Points_Redeemed_Summary>
  /** fetch data from the table: "user_points_summary" */
  user_points_summary: Array<User_Points_Summary>
  /** fetch aggregated fields from the table: "user_points_summary" */
  user_points_summary_aggregate: User_Points_Summary_Aggregate
  /** fetch data from the table in a streaming manner: "user_points_summary" */
  user_points_summary_stream: Array<User_Points_Summary>
  /** fetch data from the table: "user_purchase_subscription" */
  user_purchase_subscription: Array<User_Purchase_Subscription>
  /** fetch aggregated fields from the table: "user_purchase_subscription" */
  user_purchase_subscription_aggregate: User_Purchase_Subscription_Aggregate
  /** fetch data from the table: "user_purchase_subscription" using primary key columns */
  user_purchase_subscription_by_pk?: Maybe<User_Purchase_Subscription>
  /** fetch data from the table in a streaming manner: "user_purchase_subscription" */
  user_purchase_subscription_stream: Array<User_Purchase_Subscription>
  /** fetch data from the table: "user_purchases" */
  user_purchases: Array<User_Purchases>
  /** fetch aggregated fields from the table: "user_purchases" */
  user_purchases_aggregate: User_Purchases_Aggregate
  /** fetch data from the table: "user_purchases" using primary key columns */
  user_purchases_by_pk?: Maybe<User_Purchases>
  /** fetch data from the table in a streaming manner: "user_purchases" */
  user_purchases_stream: Array<User_Purchases>
  /** fetch data from the table: "user_push_notification" */
  user_push_notification: Array<User_Push_Notification>
  /** fetch aggregated fields from the table: "user_push_notification" */
  user_push_notification_aggregate: User_Push_Notification_Aggregate
  /** fetch data from the table in a streaming manner: "user_push_notification" */
  user_push_notification_stream: Array<User_Push_Notification>
  /** fetch data from the table: "user_push_notification_token" */
  user_push_notification_token: Array<User_Push_Notification_Token>
  /** fetch aggregated fields from the table: "user_push_notification_token" */
  user_push_notification_token_aggregate: User_Push_Notification_Token_Aggregate
  /** fetch data from the table: "user_push_notification_token" using primary key columns */
  user_push_notification_token_by_pk?: Maybe<User_Push_Notification_Token>
  /** fetch data from the table in a streaming manner: "user_push_notification_token" */
  user_push_notification_token_stream: Array<User_Push_Notification_Token>
  /** fetch data from the table: "user_referral" */
  user_referral: Array<User_Referral>
  /** fetch aggregated fields from the table: "user_referral" */
  user_referral_aggregate: User_Referral_Aggregate
  /** fetch data from the table: "user_referral" using primary key columns */
  user_referral_by_pk?: Maybe<User_Referral>
  /** fetch data from the table in a streaming manner: "user_referral" */
  user_referral_stream: Array<User_Referral>
  /** fetch data from the table: "user_reward_multiplier_config" */
  user_reward_multiplier_config: Array<User_Reward_Multiplier_Config>
  /** fetch aggregated fields from the table: "user_reward_multiplier_config" */
  user_reward_multiplier_config_aggregate: User_Reward_Multiplier_Config_Aggregate
  /** fetch data from the table: "user_reward_multiplier_config" using primary key columns */
  user_reward_multiplier_config_by_pk?: Maybe<User_Reward_Multiplier_Config>
  /** fetch data from the table in a streaming manner: "user_reward_multiplier_config" */
  user_reward_multiplier_config_stream: Array<User_Reward_Multiplier_Config>
  /** fetch data from the table: "user_reward_redemption" */
  user_reward_redemption: Array<User_Reward_Redemption>
  /** fetch aggregated fields from the table: "user_reward_redemption" */
  user_reward_redemption_aggregate: User_Reward_Redemption_Aggregate
  /** fetch data from the table: "user_reward_redemption" using primary key columns */
  user_reward_redemption_by_pk?: Maybe<User_Reward_Redemption>
  /** fetch data from the table in a streaming manner: "user_reward_redemption" */
  user_reward_redemption_stream: Array<User_Reward_Redemption>
  /** fetch data from the table: "user_reward_reference" */
  user_reward_reference: Array<User_Reward_Reference>
  /** fetch aggregated fields from the table: "user_reward_reference" */
  user_reward_reference_aggregate: User_Reward_Reference_Aggregate
  /** fetch data from the table: "user_reward_reference" using primary key columns */
  user_reward_reference_by_pk?: Maybe<User_Reward_Reference>
  /** fetch data from the table in a streaming manner: "user_reward_reference" */
  user_reward_reference_stream: Array<User_Reward_Reference>
  /** fetch data from the table: "user_shop_favorite" */
  user_shop_favorite: Array<User_Shop_Favorite>
  /** fetch aggregated fields from the table: "user_shop_favorite" */
  user_shop_favorite_aggregate: User_Shop_Favorite_Aggregate
  /** fetch data from the table: "user_shop_favorite" using primary key columns */
  user_shop_favorite_by_pk?: Maybe<User_Shop_Favorite>
  /** fetch data from the table in a streaming manner: "user_shop_favorite" */
  user_shop_favorite_stream: Array<User_Shop_Favorite>
  /** fetch data from the table: "user_shopping_preferences" */
  user_shopping_preferences: Array<User_Shopping_Preferences>
  /** fetch aggregated fields from the table: "user_shopping_preferences" */
  user_shopping_preferences_aggregate: User_Shopping_Preferences_Aggregate
  /** fetch data from the table: "user_shopping_preferences" using primary key columns */
  user_shopping_preferences_by_pk?: Maybe<User_Shopping_Preferences>
  /** fetch data from the table in a streaming manner: "user_shopping_preferences" */
  user_shopping_preferences_stream: Array<User_Shopping_Preferences>
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>
  /** fetch data from the table: "user_subscription" */
  user_subscription: Array<User_Subscription>
  /** fetch aggregated fields from the table: "user_subscription" */
  user_subscription_aggregate: User_Subscription_Aggregate
  /** fetch data from the table: "user_subscription" using primary key columns */
  user_subscription_by_pk?: Maybe<User_Subscription>
  /** fetch data from the table: "user_subscription_status" */
  user_subscription_status: Array<User_Subscription_Status>
  /** fetch aggregated fields from the table: "user_subscription_status" */
  user_subscription_status_aggregate: User_Subscription_Status_Aggregate
  /** fetch data from the table: "user_subscription_status" using primary key columns */
  user_subscription_status_by_pk?: Maybe<User_Subscription_Status>
  /** fetch data from the table in a streaming manner: "user_subscription_status" */
  user_subscription_status_stream: Array<User_Subscription_Status>
  /** fetch data from the table in a streaming manner: "user_subscription" */
  user_subscription_stream: Array<User_Subscription>
  /** fetch data from the table: "user_temp" */
  user_temp: Array<User_Temp>
  /** fetch aggregated fields from the table: "user_temp" */
  user_temp_aggregate: User_Temp_Aggregate
  /** fetch data from the table: "user_temp" using primary key columns */
  user_temp_by_pk?: Maybe<User_Temp>
  /** fetch data from the table in a streaming manner: "user_temp" */
  user_temp_stream: Array<User_Temp>
  /** execute function "users_search" which returns "user" */
  users_search: Array<User>
  /** execute function "users_search" and query aggregates on result of table type "user" */
  users_search_aggregate: User_Aggregate
  /** fetch data from the table: "worflow_type" */
  worflow_type: Array<Worflow_Type>
  /** fetch aggregated fields from the table: "worflow_type" */
  worflow_type_aggregate: Worflow_Type_Aggregate
  /** fetch data from the table: "worflow_type" using primary key columns */
  worflow_type_by_pk?: Maybe<Worflow_Type>
  /** fetch data from the table in a streaming manner: "worflow_type" */
  worflow_type_stream: Array<Worflow_Type>
  /** fetch data from the table: "workflow" */
  workflow: Array<Workflow>
  /** fetch aggregated fields from the table: "workflow" */
  workflow_aggregate: Workflow_Aggregate
  /** fetch data from the table: "workflow" using primary key columns */
  workflow_by_pk?: Maybe<Workflow>
  /** fetch data from the table: "workflow_status" */
  workflow_status: Array<Workflow_Status>
  /** fetch aggregated fields from the table: "workflow_status" */
  workflow_status_aggregate: Workflow_Status_Aggregate
  /** fetch data from the table: "workflow_status" using primary key columns */
  workflow_status_by_pk?: Maybe<Workflow_Status>
  /** fetch data from the table in a streaming manner: "workflow_status" */
  workflow_status_stream: Array<Workflow_Status>
  /** fetch data from the table in a streaming manner: "workflow" */
  workflow_stream: Array<Workflow>
}

export type Subscription_RootAccount_Deletion_ReasonArgs = {
  distinct_on?: Maybe<Array<Account_Deletion_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Account_Deletion_Reason_Order_By>>
  where?: Maybe<Account_Deletion_Reason_Bool_Exp>
}

export type Subscription_RootAccount_Deletion_Reason_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Deletion_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Account_Deletion_Reason_Order_By>>
  where?: Maybe<Account_Deletion_Reason_Bool_Exp>
}

export type Subscription_RootAccount_Deletion_Reason_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootAccount_Deletion_Reason_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Account_Deletion_Reason_Stream_Cursor_Input>>
  where?: Maybe<Account_Deletion_Reason_Bool_Exp>
}

export type Subscription_RootAffiliateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Order_By>>
  where?: Maybe<Affiliate_Bool_Exp>
}

export type Subscription_RootAffiliate_Advertiser_IdArgs = {
  distinct_on?: Maybe<Array<Affiliate_Advertiser_Id_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Advertiser_Id_Order_By>>
  where?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
}

export type Subscription_RootAffiliate_Advertiser_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Advertiser_Id_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Advertiser_Id_Order_By>>
  where?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
}

export type Subscription_RootAffiliate_Advertiser_Id_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootAffiliate_Advertiser_Id_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Affiliate_Advertiser_Id_Stream_Cursor_Input>>
  where?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
}

export type Subscription_RootAffiliate_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Order_By>>
  where?: Maybe<Affiliate_Bool_Exp>
}

export type Subscription_RootAffiliate_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootAffiliate_Commission_SummaryArgs = {
  distinct_on?: Maybe<Array<Affiliate_Commission_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Commission_Summary_Order_By>>
  where?: Maybe<Affiliate_Commission_Summary_Bool_Exp>
}

export type Subscription_RootAffiliate_Commission_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Commission_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Commission_Summary_Order_By>>
  where?: Maybe<Affiliate_Commission_Summary_Bool_Exp>
}

export type Subscription_RootAffiliate_Commission_Summary_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Affiliate_Commission_Summary_Stream_Cursor_Input>>
  where?: Maybe<Affiliate_Commission_Summary_Bool_Exp>
}

export type Subscription_RootAffiliate_MetaArgs = {
  distinct_on?: Maybe<Array<Affiliate_Meta_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Meta_Order_By>>
  where?: Maybe<Affiliate_Meta_Bool_Exp>
}

export type Subscription_RootAffiliate_Meta_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Meta_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Meta_Order_By>>
  where?: Maybe<Affiliate_Meta_Bool_Exp>
}

export type Subscription_RootAffiliate_Meta_By_PkArgs = {
  affiliate: Affiliate_Enum
}

export type Subscription_RootAffiliate_Meta_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Affiliate_Meta_Stream_Cursor_Input>>
  where?: Maybe<Affiliate_Meta_Bool_Exp>
}

export type Subscription_RootAffiliate_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Affiliate_Stream_Cursor_Input>>
  where?: Maybe<Affiliate_Bool_Exp>
}

export type Subscription_RootAffiliate_TransactionArgs = {
  distinct_on?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Transaction_Order_By>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

export type Subscription_RootAffiliate_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Transaction_Order_By>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

export type Subscription_RootAffiliate_Transaction_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootAffiliate_Transaction_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Affiliate_Transaction_Stream_Cursor_Input>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

export type Subscription_RootAll_Shops_For_Shop_PageArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootAll_Shops_For_Shop_Page_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootAmazon_CategoriesArgs = {
  distinct_on?: Maybe<Array<Amazon_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Amazon_Categories_Order_By>>
  where?: Maybe<Amazon_Categories_Bool_Exp>
}

export type Subscription_RootAmazon_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Amazon_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Amazon_Categories_Order_By>>
  where?: Maybe<Amazon_Categories_Bool_Exp>
}

export type Subscription_RootAmazon_Categories_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Amazon_Categories_Stream_Cursor_Input>>
  where?: Maybe<Amazon_Categories_Bool_Exp>
}

export type Subscription_RootBilling_ModeArgs = {
  distinct_on?: Maybe<Array<Billing_Mode_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Mode_Order_By>>
  where?: Maybe<Billing_Mode_Bool_Exp>
}

export type Subscription_RootBilling_Mode_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Mode_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Mode_Order_By>>
  where?: Maybe<Billing_Mode_Bool_Exp>
}

export type Subscription_RootBilling_Mode_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootBilling_Mode_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Billing_Mode_Stream_Cursor_Input>>
  where?: Maybe<Billing_Mode_Bool_Exp>
}

export type Subscription_RootBilling_Platform_Connected_AccountArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Connected_Account_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Connected_Account_Order_By>>
  where?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
}

export type Subscription_RootBilling_Platform_Connected_Account_AggregateArgs =
  {
    distinct_on?: Maybe<Array<Billing_Platform_Connected_Account_Select_Column>>
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Billing_Platform_Connected_Account_Order_By>>
    where?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
  }

export type Subscription_RootBilling_Platform_Connected_Account_By_PkArgs = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

export type Subscription_RootBilling_Platform_Connected_Account_StatusArgs = {
  distinct_on?: Maybe<
    Array<Billing_Platform_Connected_Account_Status_Select_Column>
  >
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Connected_Account_Status_Order_By>>
  where?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
}

export type Subscription_RootBilling_Platform_Connected_Account_Status_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Billing_Platform_Connected_Account_Status_Select_Column>
    >
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Billing_Platform_Connected_Account_Status_Order_By>>
    where?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
  }

export type Subscription_RootBilling_Platform_Connected_Account_Status_By_PkArgs =
  {
    value: Scalars['String']
  }

export type Subscription_RootBilling_Platform_Connected_Account_Status_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      Maybe<Billing_Platform_Connected_Account_Status_Stream_Cursor_Input>
    >
    where?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
  }

export type Subscription_RootBilling_Platform_Connected_Account_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Billing_Platform_Connected_Account_Stream_Cursor_Input>>
  where?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
}

export type Subscription_RootBilling_Platform_CustomerArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Customer_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Customer_Order_By>>
  where?: Maybe<Billing_Platform_Customer_Bool_Exp>
}

export type Subscription_RootBilling_Platform_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Customer_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Customer_Order_By>>
  where?: Maybe<Billing_Platform_Customer_Bool_Exp>
}

export type Subscription_RootBilling_Platform_Customer_By_PkArgs = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

export type Subscription_RootBilling_Platform_Customer_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Billing_Platform_Customer_Stream_Cursor_Input>>
  where?: Maybe<Billing_Platform_Customer_Bool_Exp>
}

export type Subscription_RootCartArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

export type Subscription_RootCart_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

export type Subscription_RootCart_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCart_ItemArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

export type Subscription_RootCart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

export type Subscription_RootCart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCart_Item_LikeArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

export type Subscription_RootCart_Item_Like_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

export type Subscription_RootCart_Item_Like_By_PkArgs = {
  cart_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Subscription_RootCart_Item_Like_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Cart_Item_Like_Stream_Cursor_Input>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

export type Subscription_RootCart_Item_NoteArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Note_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Note_Order_By>>
  where?: Maybe<Cart_Item_Note_Bool_Exp>
}

export type Subscription_RootCart_Item_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Note_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Note_Order_By>>
  where?: Maybe<Cart_Item_Note_Bool_Exp>
}

export type Subscription_RootCart_Item_Note_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCart_Item_Note_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Cart_Item_Note_Stream_Cursor_Input>>
  where?: Maybe<Cart_Item_Note_Bool_Exp>
}

export type Subscription_RootCart_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Cart_Item_Stream_Cursor_Input>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

export type Subscription_RootCart_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Cart_Stream_Cursor_Input>>
  where?: Maybe<Cart_Bool_Exp>
}

export type Subscription_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Categories_Order_By>>
  where?: Maybe<Categories_Bool_Exp>
}

export type Subscription_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Categories_Order_By>>
  where?: Maybe<Categories_Bool_Exp>
}

export type Subscription_RootCategories_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCategories_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Categories_Stream_Cursor_Input>>
  where?: Maybe<Categories_Bool_Exp>
}

export type Subscription_RootCategory_Shop_ItemArgs = {
  distinct_on?: Maybe<Array<Category_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Category_Shop_Item_Order_By>>
  where?: Maybe<Category_Shop_Item_Bool_Exp>
}

export type Subscription_RootCategory_Shop_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Category_Shop_Item_Order_By>>
  where?: Maybe<Category_Shop_Item_Bool_Exp>
}

export type Subscription_RootCategory_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCategory_Shop_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Category_Shop_Item_Stream_Cursor_Input>>
  where?: Maybe<Category_Shop_Item_Bool_Exp>
}

export type Subscription_RootCheckoutArgs = {
  distinct_on?: Maybe<Array<Checkout_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Checkout_Order_By>>
  where?: Maybe<Checkout_Bool_Exp>
}

export type Subscription_RootCheckout_AggregateArgs = {
  distinct_on?: Maybe<Array<Checkout_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Checkout_Order_By>>
  where?: Maybe<Checkout_Bool_Exp>
}

export type Subscription_RootCheckout_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCheckout_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Checkout_Stream_Cursor_Input>>
  where?: Maybe<Checkout_Bool_Exp>
}

export type Subscription_RootCollectionArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootCollection_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootCollection_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Cart_ItemArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

export type Subscription_RootCollection_Cart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

export type Subscription_RootCollection_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Cart_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Cart_Item_Stream_Cursor_Input>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

export type Subscription_RootCollection_CollaboratorArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

export type Subscription_RootCollection_Collaborator_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

export type Subscription_RootCollection_Collaborator_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Collaborator_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Collaborator_Stream_Cursor_Input>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

export type Subscription_RootCollection_InviteArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Order_By>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

export type Subscription_RootCollection_Invite_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Order_By>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

export type Subscription_RootCollection_Invite_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Invite_StatusArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Status_Order_By>>
  where?: Maybe<Collection_Invite_Status_Bool_Exp>
}

export type Subscription_RootCollection_Invite_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Status_Order_By>>
  where?: Maybe<Collection_Invite_Status_Bool_Exp>
}

export type Subscription_RootCollection_Invite_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCollection_Invite_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Invite_Status_Stream_Cursor_Input>>
  where?: Maybe<Collection_Invite_Status_Bool_Exp>
}

export type Subscription_RootCollection_Invite_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Invite_Stream_Cursor_Input>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

export type Subscription_RootCollection_Item_ClaimArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Order_By>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

export type Subscription_RootCollection_Item_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Order_By>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

export type Subscription_RootCollection_Item_Claim_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Item_Claim_StatusArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Status_Order_By>>
  where?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
}

export type Subscription_RootCollection_Item_Claim_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Status_Order_By>>
  where?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
}

export type Subscription_RootCollection_Item_Claim_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCollection_Item_Claim_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Item_Claim_Status_Stream_Cursor_Input>>
  where?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
}

export type Subscription_RootCollection_Item_Claim_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Item_Claim_Stream_Cursor_Input>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

export type Subscription_RootCollection_RandomArgs = {
  args?: Maybe<Collection_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootCollection_Random_AggregateArgs = {
  args?: Maybe<Collection_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootCollection_SectionArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Order_By>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

export type Subscription_RootCollection_Section_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Order_By>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

export type Subscription_RootCollection_Section_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Section_Cart_ItemArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Cart_Item_Order_By>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

export type Subscription_RootCollection_Section_Cart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Cart_Item_Order_By>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

export type Subscription_RootCollection_Section_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Section_Cart_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Section_Cart_Item_Stream_Cursor_Input>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

export type Subscription_RootCollection_Section_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Section_Stream_Cursor_Input>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

export type Subscription_RootCollection_Slug_HistoryArgs = {
  distinct_on?: Maybe<Array<Collection_Slug_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Slug_History_Order_By>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

export type Subscription_RootCollection_Slug_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Slug_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Slug_History_Order_By>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

export type Subscription_RootCollection_Slug_History_By_PkArgs = {
  collection_id: Scalars['uuid']
  slug: Scalars['name']
}

export type Subscription_RootCollection_Slug_History_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Slug_History_Stream_Cursor_Input>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

export type Subscription_RootCollection_StateArgs = {
  distinct_on?: Maybe<Array<Collection_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_State_Order_By>>
  where?: Maybe<Collection_State_Bool_Exp>
}

export type Subscription_RootCollection_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_State_Order_By>>
  where?: Maybe<Collection_State_Bool_Exp>
}

export type Subscription_RootCollection_State_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCollection_State_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_State_Stream_Cursor_Input>>
  where?: Maybe<Collection_State_Bool_Exp>
}

export type Subscription_RootCollection_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Stream_Cursor_Input>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootCollection_TypeArgs = {
  distinct_on?: Maybe<Array<Collection_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Type_Order_By>>
  where?: Maybe<Collection_Type_Bool_Exp>
}

export type Subscription_RootCollection_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Type_Order_By>>
  where?: Maybe<Collection_Type_Bool_Exp>
}

export type Subscription_RootCollection_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCollection_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Type_Stream_Cursor_Input>>
  where?: Maybe<Collection_Type_Bool_Exp>
}

export type Subscription_RootCommentArgs = {
  distinct_on?: Maybe<Array<Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Comment_Order_By>>
  where?: Maybe<Comment_Bool_Exp>
}

export type Subscription_RootComment_AggregateArgs = {
  distinct_on?: Maybe<Array<Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Comment_Order_By>>
  where?: Maybe<Comment_Bool_Exp>
}

export type Subscription_RootComment_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootComment_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Comment_Stream_Cursor_Input>>
  where?: Maybe<Comment_Bool_Exp>
}

export type Subscription_RootCommentable_ItemArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Order_By>>
  where?: Maybe<Commentable_Item_Bool_Exp>
}

export type Subscription_RootCommentable_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Order_By>>
  where?: Maybe<Commentable_Item_Bool_Exp>
}

export type Subscription_RootCommentable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Commentable_Item_Type_Enum
}

export type Subscription_RootCommentable_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Commentable_Item_Stream_Cursor_Input>>
  where?: Maybe<Commentable_Item_Bool_Exp>
}

export type Subscription_RootCommentable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Type_Order_By>>
  where?: Maybe<Commentable_Item_Type_Bool_Exp>
}

export type Subscription_RootCommentable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Type_Order_By>>
  where?: Maybe<Commentable_Item_Type_Bool_Exp>
}

export type Subscription_RootCommentable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCommentable_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Commentable_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Commentable_Item_Type_Bool_Exp>
}

export type Subscription_RootCommunication_TemplateArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Order_By>>
  where?: Maybe<Communication_Template_Bool_Exp>
}

export type Subscription_RootCommunication_Template_AggregateArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Order_By>>
  where?: Maybe<Communication_Template_Bool_Exp>
}

export type Subscription_RootCommunication_Template_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCommunication_Template_Full_ListArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Full_List_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Full_List_Order_By>>
  where?: Maybe<Communication_Template_Full_List_Bool_Exp>
}

export type Subscription_RootCommunication_Template_Full_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Full_List_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Full_List_Order_By>>
  where?: Maybe<Communication_Template_Full_List_Bool_Exp>
}

export type Subscription_RootCommunication_Template_Full_List_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCommunication_Template_Full_List_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Communication_Template_Full_List_Stream_Cursor_Input>>
  where?: Maybe<Communication_Template_Full_List_Bool_Exp>
}

export type Subscription_RootCommunication_Template_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Communication_Template_Stream_Cursor_Input>>
  where?: Maybe<Communication_Template_Bool_Exp>
}

export type Subscription_RootContact_Info_TypeArgs = {
  distinct_on?: Maybe<Array<Contact_Info_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Info_Type_Order_By>>
  where?: Maybe<Contact_Info_Type_Bool_Exp>
}

export type Subscription_RootContact_Info_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Info_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Info_Type_Order_By>>
  where?: Maybe<Contact_Info_Type_Bool_Exp>
}

export type Subscription_RootContact_Info_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootContact_Info_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Contact_Info_Type_Stream_Cursor_Input>>
  where?: Maybe<Contact_Info_Type_Bool_Exp>
}

export type Subscription_RootContact_InviteArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Order_By>>
  where?: Maybe<Contact_Invite_Bool_Exp>
}

export type Subscription_RootContact_Invite_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Order_By>>
  where?: Maybe<Contact_Invite_Bool_Exp>
}

export type Subscription_RootContact_Invite_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootContact_Invite_StatusArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Status_Order_By>>
  where?: Maybe<Contact_Invite_Status_Bool_Exp>
}

export type Subscription_RootContact_Invite_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Status_Order_By>>
  where?: Maybe<Contact_Invite_Status_Bool_Exp>
}

export type Subscription_RootContact_Invite_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootContact_Invite_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Contact_Invite_Status_Stream_Cursor_Input>>
  where?: Maybe<Contact_Invite_Status_Bool_Exp>
}

export type Subscription_RootContact_Invite_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Contact_Invite_Stream_Cursor_Input>>
  where?: Maybe<Contact_Invite_Bool_Exp>
}

export type Subscription_RootContact_Invite_TypeArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Type_Order_By>>
  where?: Maybe<Contact_Invite_Type_Bool_Exp>
}

export type Subscription_RootContact_Invite_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Type_Order_By>>
  where?: Maybe<Contact_Invite_Type_Bool_Exp>
}

export type Subscription_RootContact_Invite_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootContact_Invite_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Contact_Invite_Type_Stream_Cursor_Input>>
  where?: Maybe<Contact_Invite_Type_Bool_Exp>
}

export type Subscription_RootCreator_Subscribers_RandomArgs = {
  args?: Maybe<Creator_Subscribers_Random_Args>
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootCreator_Subscribers_Random_AggregateArgs = {
  args?: Maybe<Creator_Subscribers_Random_Args>
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootCreator_User_SubscriptionsArgs = {
  distinct_on?: Maybe<Array<Creator_User_Subscriptions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Creator_User_Subscriptions_Order_By>>
  where?: Maybe<Creator_User_Subscriptions_Bool_Exp>
}

export type Subscription_RootCreator_User_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Creator_User_Subscriptions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Creator_User_Subscriptions_Order_By>>
  where?: Maybe<Creator_User_Subscriptions_Bool_Exp>
}

export type Subscription_RootCreator_User_Subscriptions_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Creator_User_Subscriptions_Stream_Cursor_Input>>
  where?: Maybe<Creator_User_Subscriptions_Bool_Exp>
}

export type Subscription_RootDeal_Hop_GateArgs = {
  distinct_on?: Maybe<Array<Deal_Hop_Gate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Deal_Hop_Gate_Order_By>>
  where?: Maybe<Deal_Hop_Gate_Bool_Exp>
}

export type Subscription_RootDeal_Hop_Gate_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Hop_Gate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Deal_Hop_Gate_Order_By>>
  where?: Maybe<Deal_Hop_Gate_Bool_Exp>
}

export type Subscription_RootDeal_Hop_Gate_By_PkArgs = {
  user_id: Scalars['String']
}

export type Subscription_RootDeal_Hop_Gate_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Deal_Hop_Gate_Stream_Cursor_Input>>
  where?: Maybe<Deal_Hop_Gate_Bool_Exp>
}

export type Subscription_RootDevice_Client_DataArgs = {
  distinct_on?: Maybe<Array<Device_Client_Data_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Device_Client_Data_Order_By>>
  where?: Maybe<Device_Client_Data_Bool_Exp>
}

export type Subscription_RootDevice_Client_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Device_Client_Data_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Device_Client_Data_Order_By>>
  where?: Maybe<Device_Client_Data_Bool_Exp>
}

export type Subscription_RootDevice_Client_Data_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootDevice_Client_Data_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Device_Client_Data_Stream_Cursor_Input>>
  where?: Maybe<Device_Client_Data_Bool_Exp>
}

export type Subscription_RootDiscovery_BacklogArgs = {
  distinct_on?: Maybe<Array<Discovery_Backlog_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Backlog_Order_By>>
  where?: Maybe<Discovery_Backlog_Bool_Exp>
}

export type Subscription_RootDiscovery_Backlog_AggregateArgs = {
  distinct_on?: Maybe<Array<Discovery_Backlog_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Backlog_Order_By>>
  where?: Maybe<Discovery_Backlog_Bool_Exp>
}

export type Subscription_RootDiscovery_Backlog_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootDiscovery_Backlog_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Discovery_Backlog_Stream_Cursor_Input>>
  where?: Maybe<Discovery_Backlog_Bool_Exp>
}

export type Subscription_RootDiscovery_FeedArgs = {
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootDiscovery_Feed_AggregateArgs = {
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootDiscovery_Feed_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootDiscovery_Feed_RandomArgs = {
  args?: Maybe<Discovery_Feed_Random_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootDiscovery_Feed_Random_AggregateArgs = {
  args?: Maybe<Discovery_Feed_Random_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootDiscovery_Feed_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Discovery_Feed_Stream_Cursor_Input>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootDiscovery_StagingArgs = {
  distinct_on?: Maybe<Array<Discovery_Staging_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Staging_Order_By>>
  where?: Maybe<Discovery_Staging_Bool_Exp>
}

export type Subscription_RootDiscovery_Staging_AggregateArgs = {
  distinct_on?: Maybe<Array<Discovery_Staging_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Staging_Order_By>>
  where?: Maybe<Discovery_Staging_Bool_Exp>
}

export type Subscription_RootDiscovery_Staging_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootDiscovery_Staging_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Discovery_Staging_Stream_Cursor_Input>>
  where?: Maybe<Discovery_Staging_Bool_Exp>
}

export type Subscription_RootFeatured_ShopArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Order_By>>
  where?: Maybe<Featured_Shop_Bool_Exp>
}

export type Subscription_RootFeatured_Shop_AggregateArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Order_By>>
  where?: Maybe<Featured_Shop_Bool_Exp>
}

export type Subscription_RootFeatured_Shop_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootFeatured_Shop_ItemArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Item_Order_By>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

export type Subscription_RootFeatured_Shop_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Item_Order_By>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

export type Subscription_RootFeatured_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootFeatured_Shop_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Featured_Shop_Item_Stream_Cursor_Input>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

export type Subscription_RootFeatured_Shop_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Featured_Shop_Stream_Cursor_Input>>
  where?: Maybe<Featured_Shop_Bool_Exp>
}

export type Subscription_RootFeatured_Shops_For_OnboardingArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootFeatured_Shops_For_Onboarding_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootFeed_ItemArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootFeed_Item_LikeArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Subscription_RootFeed_Item_Like_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Subscription_RootFeed_Item_Like_By_PkArgs = {
  feed_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Subscription_RootFeed_Item_Like_RandomArgs = {
  args?: Maybe<Feed_Item_Like_Random_Args>
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Subscription_RootFeed_Item_Like_Random_AggregateArgs = {
  args?: Maybe<Feed_Item_Like_Random_Args>
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Subscription_RootFeed_Item_Like_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Feed_Item_Like_Stream_Cursor_Input>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Subscription_RootFeed_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Feed_Item_Stream_Cursor_Input>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Type_Order_By>>
  where?: Maybe<Feed_Item_Type_Bool_Exp>
}

export type Subscription_RootFeed_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Type_Order_By>>
  where?: Maybe<Feed_Item_Type_Bool_Exp>
}

export type Subscription_RootFeed_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootFeed_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Feed_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Feed_Item_Type_Bool_Exp>
}

export type Subscription_RootFeed_Items_For_Style_AccountsArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Items_For_Style_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Items_For_UserArgs = {
  args: Feed_Items_For_User_Args
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Items_For_User_AggregateArgs = {
  args: Feed_Items_For_User_Args
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Items_For_User_SubscriptionsArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Items_For_User_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFmtc_DealArgs = {
  distinct_on?: Maybe<Array<Fmtc_Deal_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Fmtc_Deal_Order_By>>
  where?: Maybe<Fmtc_Deal_Bool_Exp>
}

export type Subscription_RootFmtc_Deal_AggregateArgs = {
  distinct_on?: Maybe<Array<Fmtc_Deal_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Fmtc_Deal_Order_By>>
  where?: Maybe<Fmtc_Deal_Bool_Exp>
}

export type Subscription_RootFmtc_Deal_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootFmtc_Deal_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Fmtc_Deal_Stream_Cursor_Input>>
  where?: Maybe<Fmtc_Deal_Bool_Exp>
}

export type Subscription_RootFollowable_ItemArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Order_By>>
  where?: Maybe<Followable_Item_Bool_Exp>
}

export type Subscription_RootFollowable_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Order_By>>
  where?: Maybe<Followable_Item_Bool_Exp>
}

export type Subscription_RootFollowable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Followable_Item_Type_Enum
}

export type Subscription_RootFollowable_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Followable_Item_Stream_Cursor_Input>>
  where?: Maybe<Followable_Item_Bool_Exp>
}

export type Subscription_RootFollowable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Type_Order_By>>
  where?: Maybe<Followable_Item_Type_Bool_Exp>
}

export type Subscription_RootFollowable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Type_Order_By>>
  where?: Maybe<Followable_Item_Type_Bool_Exp>
}

export type Subscription_RootFollowable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootFollowable_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Followable_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Followable_Item_Type_Bool_Exp>
}

export type Subscription_RootLeaderboard_EventArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Order_By>>
  where?: Maybe<Leaderboard_Event_Bool_Exp>
}

export type Subscription_RootLeaderboard_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Order_By>>
  where?: Maybe<Leaderboard_Event_Bool_Exp>
}

export type Subscription_RootLeaderboard_Event_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLeaderboard_Event_NameArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Name_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Name_Order_By>>
  where?: Maybe<Leaderboard_Event_Name_Bool_Exp>
}

export type Subscription_RootLeaderboard_Event_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Name_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Name_Order_By>>
  where?: Maybe<Leaderboard_Event_Name_Bool_Exp>
}

export type Subscription_RootLeaderboard_Event_Name_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootLeaderboard_Event_Name_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Leaderboard_Event_Name_Stream_Cursor_Input>>
  where?: Maybe<Leaderboard_Event_Name_Bool_Exp>
}

export type Subscription_RootLeaderboard_Event_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Leaderboard_Event_Stream_Cursor_Input>>
  where?: Maybe<Leaderboard_Event_Bool_Exp>
}

export type Subscription_RootLink_TypeArgs = {
  distinct_on?: Maybe<Array<Link_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Link_Type_Order_By>>
  where?: Maybe<Link_Type_Bool_Exp>
}

export type Subscription_RootLink_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Link_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Link_Type_Order_By>>
  where?: Maybe<Link_Type_Bool_Exp>
}

export type Subscription_RootLink_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootLink_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Link_Type_Stream_Cursor_Input>>
  where?: Maybe<Link_Type_Bool_Exp>
}

export type Subscription_RootMedia_Enabled_ItemArgs = {
  distinct_on?: Maybe<Array<Media_Enabled_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Enabled_Item_Order_By>>
  where?: Maybe<Media_Enabled_Item_Bool_Exp>
}

export type Subscription_RootMedia_Enabled_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Enabled_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Enabled_Item_Order_By>>
  where?: Maybe<Media_Enabled_Item_Bool_Exp>
}

export type Subscription_RootMedia_Enabled_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Media_Item_Type_Enum
}

export type Subscription_RootMedia_Enabled_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Media_Enabled_Item_Stream_Cursor_Input>>
  where?: Maybe<Media_Enabled_Item_Bool_Exp>
}

export type Subscription_RootMedia_ItemArgs = {
  distinct_on?: Maybe<Array<Media_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Order_By>>
  where?: Maybe<Media_Item_Bool_Exp>
}

export type Subscription_RootMedia_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Order_By>>
  where?: Maybe<Media_Item_Bool_Exp>
}

export type Subscription_RootMedia_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMedia_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Media_Item_Stream_Cursor_Input>>
  where?: Maybe<Media_Item_Bool_Exp>
}

export type Subscription_RootMedia_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Media_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Type_Order_By>>
  where?: Maybe<Media_Item_Type_Bool_Exp>
}

export type Subscription_RootMedia_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Type_Order_By>>
  where?: Maybe<Media_Item_Type_Bool_Exp>
}

export type Subscription_RootMedia_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootMedia_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Media_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Media_Item_Type_Bool_Exp>
}

export type Subscription_RootMedia_ItemsArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

export type Subscription_RootMedia_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

export type Subscription_RootMedia_Items_By_PkArgs = {
  media_enabled_item_id: Scalars['uuid']
  media_enabled_item_type: Scalars['String']
  media_id: Scalars['uuid']
}

export type Subscription_RootMedia_Items_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Media_Items_Stream_Cursor_Input>>
  where?: Maybe<Media_Items_Bool_Exp>
}

export type Subscription_RootMedia_TypeArgs = {
  distinct_on?: Maybe<Array<Media_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Type_Order_By>>
  where?: Maybe<Media_Type_Bool_Exp>
}

export type Subscription_RootMedia_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Type_Order_By>>
  where?: Maybe<Media_Type_Bool_Exp>
}

export type Subscription_RootMedia_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootMedia_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Media_Type_Stream_Cursor_Input>>
  where?: Maybe<Media_Type_Bool_Exp>
}

export type Subscription_RootMention_Search_UsersArgs = {
  args: Mention_Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootMention_Search_Users_AggregateArgs = {
  args: Mention_Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootMobile_App_HomeArgs = {
  distinct_on?: Maybe<Array<Mobile_App_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_App_Home_Order_By>>
  where?: Maybe<Mobile_App_Home_Bool_Exp>
}

export type Subscription_RootMobile_App_Home_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_App_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_App_Home_Order_By>>
  where?: Maybe<Mobile_App_Home_Bool_Exp>
}

export type Subscription_RootMobile_App_Home_By_PkArgs = {
  id: Scalars['Int']
}

export type Subscription_RootMobile_App_Home_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Mobile_App_Home_Stream_Cursor_Input>>
  where?: Maybe<Mobile_App_Home_Bool_Exp>
}

export type Subscription_RootMobile_HomeArgs = {
  distinct_on?: Maybe<Array<Mobile_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Home_Order_By>>
  where?: Maybe<Mobile_Home_Bool_Exp>
}

export type Subscription_RootMobile_Home_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Home_Order_By>>
  where?: Maybe<Mobile_Home_Bool_Exp>
}

export type Subscription_RootMobile_Home_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMobile_Home_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Mobile_Home_Stream_Cursor_Input>>
  where?: Maybe<Mobile_Home_Bool_Exp>
}

export type Subscription_RootMobile_Push_NotificationArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Bool_Exp>
}

export type Subscription_RootMobile_Push_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Bool_Exp>
}

export type Subscription_RootMobile_Push_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMobile_Push_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Status_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
}

export type Subscription_RootMobile_Push_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Status_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
}

export type Subscription_RootMobile_Push_Notification_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootMobile_Push_Notification_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Mobile_Push_Notification_Status_Stream_Cursor_Input>>
  where?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
}

export type Subscription_RootMobile_Push_Notification_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Mobile_Push_Notification_Stream_Cursor_Input>>
  where?: Maybe<Mobile_Push_Notification_Bool_Exp>
}

export type Subscription_RootNotifiable_Feed_Item_UserArgs = {
  distinct_on?: Maybe<Array<Notifiable_Feed_Item_User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Feed_Item_User_Order_By>>
  where?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
}

export type Subscription_RootNotifiable_Feed_Item_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifiable_Feed_Item_User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Feed_Item_User_Order_By>>
  where?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
}

export type Subscription_RootNotifiable_Feed_Item_User_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notifiable_Feed_Item_User_Stream_Cursor_Input>>
  where?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
}

export type Subscription_RootNotifiable_ItemArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Order_By>>
  where?: Maybe<Notifiable_Item_Bool_Exp>
}

export type Subscription_RootNotifiable_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Order_By>>
  where?: Maybe<Notifiable_Item_Bool_Exp>
}

export type Subscription_RootNotifiable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Notifiable_Item_Type_Enum
}

export type Subscription_RootNotifiable_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notifiable_Item_Stream_Cursor_Input>>
  where?: Maybe<Notifiable_Item_Bool_Exp>
}

export type Subscription_RootNotifiable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Type_Order_By>>
  where?: Maybe<Notifiable_Item_Type_Bool_Exp>
}

export type Subscription_RootNotifiable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Type_Order_By>>
  where?: Maybe<Notifiable_Item_Type_Bool_Exp>
}

export type Subscription_RootNotifiable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootNotifiable_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notifiable_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Notifiable_Item_Type_Bool_Exp>
}

export type Subscription_RootNotificationArgs = {
  distinct_on?: Maybe<Array<Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Order_By>>
  where?: Maybe<Notification_Bool_Exp>
}

export type Subscription_RootNotification_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Order_By>>
  where?: Maybe<Notification_Bool_Exp>
}

export type Subscription_RootNotification_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootNotification_Center_Feed_For_UserArgs = {
  args: Notification_Center_Feed_For_User_Args
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Subscription_RootNotification_Center_Feed_For_User_AggregateArgs = {
  args: Notification_Center_Feed_For_User_Args
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Subscription_RootNotification_Center_ItemArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootNotification_Center_Item_Read_StatusArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Read_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Read_Status_Order_By>>
  where?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_Read_Status_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Notification_Center_Item_Read_Status_Select_Column>
    >
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Notification_Center_Item_Read_Status_Order_By>>
    where?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
  }

export type Subscription_RootNotification_Center_Item_Read_Status_By_PkArgs = {
  notification_center_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Subscription_RootNotification_Center_Item_Read_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notification_Center_Item_Read_Status_Stream_Cursor_Input>>
  where?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notification_Center_Item_Stream_Cursor_Input>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Type_Order_By>>
  where?: Maybe<Notification_Center_Item_Type_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Type_Order_By>>
  where?: Maybe<Notification_Center_Item_Type_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootNotification_Center_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notification_Center_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Notification_Center_Item_Type_Bool_Exp>
}

export type Subscription_RootNotification_PreferenceArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

export type Subscription_RootNotification_Preference_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

export type Subscription_RootNotification_Preference_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootNotification_Preference_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notification_Preference_Stream_Cursor_Input>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

export type Subscription_RootNotification_StatusArgs = {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Status_Order_By>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

export type Subscription_RootNotification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Status_Order_By>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

export type Subscription_RootNotification_Status_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootNotification_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notification_Status_Stream_Cursor_Input>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

export type Subscription_RootNotification_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notification_Stream_Cursor_Input>>
  where?: Maybe<Notification_Bool_Exp>
}

export type Subscription_RootOnboarding_CategoriesArgs = {
  distinct_on?: Maybe<Array<Onboarding_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Categories_Order_By>>
  where?: Maybe<Onboarding_Categories_Bool_Exp>
}

export type Subscription_RootOnboarding_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Onboarding_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Categories_Order_By>>
  where?: Maybe<Onboarding_Categories_Bool_Exp>
}

export type Subscription_RootOnboarding_Categories_By_PkArgs = {
  category: Shop_Category_Enum
}

export type Subscription_RootOnboarding_Categories_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Onboarding_Categories_Stream_Cursor_Input>>
  where?: Maybe<Onboarding_Categories_Bool_Exp>
}

export type Subscription_RootOnboarding_SourcesArgs = {
  distinct_on?: Maybe<Array<Onboarding_Sources_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Sources_Order_By>>
  where?: Maybe<Onboarding_Sources_Bool_Exp>
}

export type Subscription_RootOnboarding_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Onboarding_Sources_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Sources_Order_By>>
  where?: Maybe<Onboarding_Sources_Bool_Exp>
}

export type Subscription_RootOnboarding_Sources_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootOnboarding_Sources_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Onboarding_Sources_Stream_Cursor_Input>>
  where?: Maybe<Onboarding_Sources_Bool_Exp>
}

export type Subscription_RootOne_Off_EmailArgs = {
  distinct_on?: Maybe<Array<One_Off_Email_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<One_Off_Email_Order_By>>
  where?: Maybe<One_Off_Email_Bool_Exp>
}

export type Subscription_RootOne_Off_Email_AggregateArgs = {
  distinct_on?: Maybe<Array<One_Off_Email_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<One_Off_Email_Order_By>>
  where?: Maybe<One_Off_Email_Bool_Exp>
}

export type Subscription_RootOne_Off_Email_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootOne_Off_Email_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<One_Off_Email_Stream_Cursor_Input>>
  where?: Maybe<One_Off_Email_Bool_Exp>
}

export type Subscription_RootOther_Shops_For_Shop_PageArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootOther_Shops_For_Shop_Page_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootPageview_EventArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Order_By>>
  where?: Maybe<Pageview_Event_Bool_Exp>
}

export type Subscription_RootPageview_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Order_By>>
  where?: Maybe<Pageview_Event_Bool_Exp>
}

export type Subscription_RootPageview_Event_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPageview_Event_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Pageview_Event_Stream_Cursor_Input>>
  where?: Maybe<Pageview_Event_Bool_Exp>
}

export type Subscription_RootPageview_Event_SummaryArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Summary_Order_By>>
  where?: Maybe<Pageview_Event_Summary_Bool_Exp>
}

export type Subscription_RootPageview_Event_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Summary_Order_By>>
  where?: Maybe<Pageview_Event_Summary_Bool_Exp>
}

export type Subscription_RootPageview_Event_Summary_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Pageview_Event_Summary_Stream_Cursor_Input>>
  where?: Maybe<Pageview_Event_Summary_Bool_Exp>
}

export type Subscription_RootPromo_PeriodArgs = {
  distinct_on?: Maybe<Array<Promo_Period_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Promo_Period_Order_By>>
  where?: Maybe<Promo_Period_Bool_Exp>
}

export type Subscription_RootPromo_Period_AggregateArgs = {
  distinct_on?: Maybe<Array<Promo_Period_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Promo_Period_Order_By>>
  where?: Maybe<Promo_Period_Bool_Exp>
}

export type Subscription_RootPromo_Period_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPromo_Period_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Promo_Period_Stream_Cursor_Input>>
  where?: Maybe<Promo_Period_Bool_Exp>
}

export type Subscription_RootPush_NotificationArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Order_By>>
  where?: Maybe<Push_Notification_Bool_Exp>
}

export type Subscription_RootPush_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Order_By>>
  where?: Maybe<Push_Notification_Bool_Exp>
}

export type Subscription_RootPush_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPush_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

export type Subscription_RootPush_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

export type Subscription_RootPush_Notification_Status_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPush_Notification_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Push_Notification_Status_Stream_Cursor_Input>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

export type Subscription_RootPush_Notification_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Push_Notification_Stream_Cursor_Input>>
  where?: Maybe<Push_Notification_Bool_Exp>
}

export type Subscription_RootRandom_Discovery_Feed_ItemsArgs = {
  args?: Maybe<Random_Discovery_Feed_Items_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootRandom_Discovery_Feed_Items_AggregateArgs = {
  args?: Maybe<Random_Discovery_Feed_Items_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootRecommended_Shops_By_UserArgs = {
  args: Recommended_Shops_By_User_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootRecommended_Shops_By_User_AggregateArgs = {
  args: Recommended_Shops_By_User_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootRedirect_IdsArgs = {
  distinct_on?: Maybe<Array<Redirect_Ids_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Redirect_Ids_Order_By>>
  where?: Maybe<Redirect_Ids_Bool_Exp>
}

export type Subscription_RootRedirect_Ids_AggregateArgs = {
  distinct_on?: Maybe<Array<Redirect_Ids_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Redirect_Ids_Order_By>>
  where?: Maybe<Redirect_Ids_Bool_Exp>
}

export type Subscription_RootRedirect_Ids_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRedirect_Ids_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Redirect_Ids_Stream_Cursor_Input>>
  where?: Maybe<Redirect_Ids_Bool_Exp>
}

export type Subscription_RootRelated_Shops_By_CategoryArgs = {
  args: Related_Shops_By_Category_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootRelated_Shops_By_Category_AggregateArgs = {
  args: Related_Shops_By_Category_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootRelated_Users_For_Shop_ItemArgs = {
  args: Related_Users_For_Shop_Item_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootRelated_Users_For_Shop_Item_AggregateArgs = {
  args: Related_Users_For_Shop_Item_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootRemove_ReasonArgs = {
  distinct_on?: Maybe<Array<Remove_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Remove_Reason_Order_By>>
  where?: Maybe<Remove_Reason_Bool_Exp>
}

export type Subscription_RootRemove_Reason_AggregateArgs = {
  distinct_on?: Maybe<Array<Remove_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Remove_Reason_Order_By>>
  where?: Maybe<Remove_Reason_Bool_Exp>
}

export type Subscription_RootRemove_Reason_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootRemove_Reason_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Remove_Reason_Stream_Cursor_Input>>
  where?: Maybe<Remove_Reason_Bool_Exp>
}

export type Subscription_RootRewardable_Action_NamesArgs = {
  distinct_on?: Maybe<Array<Rewardable_Action_Names_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Action_Names_Order_By>>
  where?: Maybe<Rewardable_Action_Names_Bool_Exp>
}

export type Subscription_RootRewardable_Action_Names_AggregateArgs = {
  distinct_on?: Maybe<Array<Rewardable_Action_Names_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Action_Names_Order_By>>
  where?: Maybe<Rewardable_Action_Names_Bool_Exp>
}

export type Subscription_RootRewardable_Action_Names_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootRewardable_Action_Names_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Rewardable_Action_Names_Stream_Cursor_Input>>
  where?: Maybe<Rewardable_Action_Names_Bool_Exp>
}

export type Subscription_RootRewardable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Rewardable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Item_Type_Order_By>>
  where?: Maybe<Rewardable_Item_Type_Bool_Exp>
}

export type Subscription_RootRewardable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Rewardable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Item_Type_Order_By>>
  where?: Maybe<Rewardable_Item_Type_Bool_Exp>
}

export type Subscription_RootRewardable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootRewardable_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Rewardable_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Rewardable_Item_Type_Bool_Exp>
}

export type Subscription_RootSearch_QueryArgs = {
  distinct_on?: Maybe<Array<Search_Query_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Order_By>>
  where?: Maybe<Search_Query_Bool_Exp>
}

export type Subscription_RootSearch_Query_AggregateArgs = {
  distinct_on?: Maybe<Array<Search_Query_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Order_By>>
  where?: Maybe<Search_Query_Bool_Exp>
}

export type Subscription_RootSearch_Query_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootSearch_Query_ResultArgs = {
  distinct_on?: Maybe<Array<Search_Query_Result_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Result_Order_By>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

export type Subscription_RootSearch_Query_Result_AggregateArgs = {
  distinct_on?: Maybe<Array<Search_Query_Result_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Result_Order_By>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

export type Subscription_RootSearch_Query_Result_By_PkArgs = {
  search_query_id: Scalars['uuid']
  shop_item_id: Scalars['uuid']
}

export type Subscription_RootSearch_Query_Result_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Search_Query_Result_Stream_Cursor_Input>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

export type Subscription_RootSearch_Query_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Search_Query_Stream_Cursor_Input>>
  where?: Maybe<Search_Query_Bool_Exp>
}

export type Subscription_RootSearch_Shop_ItemsArgs = {
  args: Search_Shop_Items_Args
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Subscription_RootSearch_Shop_Items_AggregateArgs = {
  args: Search_Shop_Items_Args
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Subscription_RootSearch_ShopsArgs = {
  args: Search_Shops_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootSearch_Shops_AggregateArgs = {
  args: Search_Shops_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootSearch_UsersArgs = {
  args: Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootShopArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootShop_AffiliateArgs = {
  distinct_on?: Maybe<Array<Shop_Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Affiliate_Order_By>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

export type Subscription_RootShop_Affiliate_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Affiliate_Order_By>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

export type Subscription_RootShop_Affiliate_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Affiliate_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Affiliate_Stream_Cursor_Input>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

export type Subscription_RootShop_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootShop_By_PkArgs = {
  key: Scalars['String']
}

export type Subscription_RootShop_CategorizationArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

export type Subscription_RootShop_Categorization_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

export type Subscription_RootShop_Categorization_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Categorization_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Categorization_Stream_Cursor_Input>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

export type Subscription_RootShop_Categorized_DealsArgs = {
  distinct_on?: Maybe<Array<Shop_Categorized_Deals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorized_Deals_Order_By>>
  where?: Maybe<Shop_Categorized_Deals_Bool_Exp>
}

export type Subscription_RootShop_Categorized_Deals_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Categorized_Deals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorized_Deals_Order_By>>
  where?: Maybe<Shop_Categorized_Deals_Bool_Exp>
}

export type Subscription_RootShop_Categorized_Deals_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Categorized_Deals_Stream_Cursor_Input>>
  where?: Maybe<Shop_Categorized_Deals_Bool_Exp>
}

export type Subscription_RootShop_CategoryArgs = {
  distinct_on?: Maybe<Array<Shop_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Category_Order_By>>
  where?: Maybe<Shop_Category_Bool_Exp>
}

export type Subscription_RootShop_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Category_Order_By>>
  where?: Maybe<Shop_Category_Bool_Exp>
}

export type Subscription_RootShop_Category_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootShop_Category_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Category_Stream_Cursor_Input>>
  where?: Maybe<Shop_Category_Bool_Exp>
}

export type Subscription_RootShop_CollectionsArgs = {
  args: Shop_Collections_Args
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootShop_Collections_AggregateArgs = {
  args: Shop_Collections_Args
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootShop_ItemArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Subscription_RootShop_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Subscription_RootShop_Item_AvailabilityArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Availability_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Availability_Order_By>>
  where?: Maybe<Shop_Item_Availability_Bool_Exp>
}

export type Subscription_RootShop_Item_Availability_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Availability_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Availability_Order_By>>
  where?: Maybe<Shop_Item_Availability_Bool_Exp>
}

export type Subscription_RootShop_Item_Availability_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootShop_Item_Availability_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_Availability_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_Availability_Bool_Exp>
}

export type Subscription_RootShop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Item_Enrichment_StatusArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Enrichment_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Enrichment_Status_Order_By>>
  where?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
}

export type Subscription_RootShop_Item_Enrichment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Enrichment_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Enrichment_Status_Order_By>>
  where?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
}

export type Subscription_RootShop_Item_Enrichment_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootShop_Item_Enrichment_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_Enrichment_Status_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
}

export type Subscription_RootShop_Item_HistoryArgs = {
  distinct_on?: Maybe<Array<Shop_Item_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_History_Order_By>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

export type Subscription_RootShop_Item_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_History_Order_By>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

export type Subscription_RootShop_Item_History_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Item_History_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_History_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

export type Subscription_RootShop_Item_ImageArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Image_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Image_Order_By>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

export type Subscription_RootShop_Item_Image_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Image_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Image_Order_By>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

export type Subscription_RootShop_Item_Image_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Item_Image_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_Image_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

export type Subscription_RootShop_Item_IndexArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Index_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Index_Order_By>>
  where?: Maybe<Shop_Item_Index_Bool_Exp>
}

export type Subscription_RootShop_Item_Index_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Index_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Index_Order_By>>
  where?: Maybe<Shop_Item_Index_Bool_Exp>
}

export type Subscription_RootShop_Item_Index_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Item_Index_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_Index_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_Index_Bool_Exp>
}

export type Subscription_RootShop_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Subscription_RootShop_Item_To_Be_ScrapedArgs = {
  distinct_on?: Maybe<Array<Shop_Item_To_Be_Scraped_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_To_Be_Scraped_Order_By>>
  where?: Maybe<Shop_Item_To_Be_Scraped_Bool_Exp>
}

export type Subscription_RootShop_Item_To_Be_Scraped_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_To_Be_Scraped_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_To_Be_Scraped_Order_By>>
  where?: Maybe<Shop_Item_To_Be_Scraped_Bool_Exp>
}

export type Subscription_RootShop_Item_To_Be_Scraped_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_To_Be_Scraped_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_To_Be_Scraped_Bool_Exp>
}

export type Subscription_RootShop_PopularityArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_Order_By>>
  where?: Maybe<Shop_Popularity_Bool_Exp>
}

export type Subscription_RootShop_Popularity_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_Order_By>>
  where?: Maybe<Shop_Popularity_Bool_Exp>
}

export type Subscription_RootShop_Popularity_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Popularity_Stream_Cursor_Input>>
  where?: Maybe<Shop_Popularity_Bool_Exp>
}

export type Subscription_RootShop_Popularity_With_PointsArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_With_Points_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_With_Points_Order_By>>
  where?: Maybe<Shop_Popularity_With_Points_Bool_Exp>
}

export type Subscription_RootShop_Popularity_With_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_With_Points_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_With_Points_Order_By>>
  where?: Maybe<Shop_Popularity_With_Points_Bool_Exp>
}

export type Subscription_RootShop_Popularity_With_Points_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Popularity_With_Points_Stream_Cursor_Input>>
  where?: Maybe<Shop_Popularity_With_Points_Bool_Exp>
}

export type Subscription_RootShop_RatingArgs = {
  distinct_on?: Maybe<Array<Shop_Rating_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Rating_Order_By>>
  where?: Maybe<Shop_Rating_Bool_Exp>
}

export type Subscription_RootShop_Rating_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Rating_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Rating_Order_By>>
  where?: Maybe<Shop_Rating_Bool_Exp>
}

export type Subscription_RootShop_Rating_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Rating_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Rating_Stream_Cursor_Input>>
  where?: Maybe<Shop_Rating_Bool_Exp>
}

export type Subscription_RootShop_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Stream_Cursor_Input>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootSimilar_Products_HistoryArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Subscription_RootSimilar_Products_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Subscription_RootSimilar_Products_History_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootSimilar_Products_History_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Similar_Products_History_Stream_Cursor_Input>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Subscription_RootSimilar_Products_History_UniqueArgs = {
  args: Similar_Products_History_Unique_Args
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Subscription_RootSimilar_Products_History_Unique_AggregateArgs = {
  args: Similar_Products_History_Unique_Args
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Subscription_RootSingle_Unread_Notification_Banner_Item_For_UserArgs =
  {
    args: Single_Unread_Notification_Banner_Item_For_User_Args
    distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
    where?: Maybe<Notification_Center_Item_Bool_Exp>
  }

export type Subscription_RootSingle_Unread_Notification_Banner_Item_For_User_AggregateArgs =
  {
    args: Single_Unread_Notification_Banner_Item_For_User_Args
    distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
    where?: Maybe<Notification_Center_Item_Bool_Exp>
  }

export type Subscription_RootSteal_Their_LooksArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootSteal_Their_Looks_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootSteal_Their_Looks_RandomArgs = {
  args?: Maybe<Steal_Their_Looks_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootSteal_Their_Looks_Random_AggregateArgs = {
  args?: Maybe<Steal_Their_Looks_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootUsed_Creator_RedirectArgs = {
  args: Used_Creator_Redirect_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUsed_Creator_Redirect_AggregateArgs = {
  args: Used_Creator_Redirect_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUser_Account_TypeArgs = {
  distinct_on?: Maybe<Array<User_Account_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Account_Type_Order_By>>
  where?: Maybe<User_Account_Type_Bool_Exp>
}

export type Subscription_RootUser_Account_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Account_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Account_Type_Order_By>>
  where?: Maybe<User_Account_Type_Bool_Exp>
}

export type Subscription_RootUser_Account_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootUser_Account_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Account_Type_Stream_Cursor_Input>>
  where?: Maybe<User_Account_Type_Bool_Exp>
}

export type Subscription_RootUser_ActionArgs = {
  distinct_on?: Maybe<Array<User_Action_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Action_Order_By>>
  where?: Maybe<User_Action_Bool_Exp>
}

export type Subscription_RootUser_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Action_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Action_Order_By>>
  where?: Maybe<User_Action_Bool_Exp>
}

export type Subscription_RootUser_Action_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Action_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Action_Stream_Cursor_Input>>
  where?: Maybe<User_Action_Bool_Exp>
}

export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUser_App_VersionArgs = {
  distinct_on?: Maybe<Array<User_App_Version_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_App_Version_Order_By>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

export type Subscription_RootUser_App_Version_AggregateArgs = {
  distinct_on?: Maybe<Array<User_App_Version_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_App_Version_Order_By>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

export type Subscription_RootUser_App_Version_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_App_Version_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_App_Version_Stream_Cursor_Input>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['String']
}

export type Subscription_RootUser_Carts_OnboardingArgs = {
  distinct_on?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Carts_Onboarding_Order_By>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

export type Subscription_RootUser_Carts_Onboarding_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Carts_Onboarding_Order_By>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

export type Subscription_RootUser_Carts_Onboarding_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Carts_Onboarding_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Carts_Onboarding_Stream_Cursor_Input>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

export type Subscription_RootUser_CategoryArgs = {
  distinct_on?: Maybe<Array<User_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Category_Order_By>>
  where?: Maybe<User_Category_Bool_Exp>
}

export type Subscription_RootUser_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Category_Order_By>>
  where?: Maybe<User_Category_Bool_Exp>
}

export type Subscription_RootUser_Category_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Category_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Category_Stream_Cursor_Input>>
  where?: Maybe<User_Category_Bool_Exp>
}

export type Subscription_RootUser_DeletionArgs = {
  distinct_on?: Maybe<Array<User_Deletion_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Deletion_Order_By>>
  where?: Maybe<User_Deletion_Bool_Exp>
}

export type Subscription_RootUser_Deletion_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Deletion_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Deletion_Order_By>>
  where?: Maybe<User_Deletion_Bool_Exp>
}

export type Subscription_RootUser_Deletion_By_PkArgs = {
  user_id: Scalars['String']
}

export type Subscription_RootUser_Deletion_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Deletion_Stream_Cursor_Input>>
  where?: Maybe<User_Deletion_Bool_Exp>
}

export type Subscription_RootUser_FeedbackArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Order_By>>
  where?: Maybe<User_Feedback_Bool_Exp>
}

export type Subscription_RootUser_Feedback_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Order_By>>
  where?: Maybe<User_Feedback_Bool_Exp>
}

export type Subscription_RootUser_Feedback_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Feedback_ItemArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Item_Order_By>>
  where?: Maybe<User_Feedback_Item_Bool_Exp>
}

export type Subscription_RootUser_Feedback_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Item_Order_By>>
  where?: Maybe<User_Feedback_Item_Bool_Exp>
}

export type Subscription_RootUser_Feedback_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: User_Feedback_Type_Enum
}

export type Subscription_RootUser_Feedback_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Feedback_Item_Stream_Cursor_Input>>
  where?: Maybe<User_Feedback_Item_Bool_Exp>
}

export type Subscription_RootUser_Feedback_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Feedback_Stream_Cursor_Input>>
  where?: Maybe<User_Feedback_Bool_Exp>
}

export type Subscription_RootUser_Feedback_TypeArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Type_Order_By>>
  where?: Maybe<User_Feedback_Type_Bool_Exp>
}

export type Subscription_RootUser_Feedback_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Type_Order_By>>
  where?: Maybe<User_Feedback_Type_Bool_Exp>
}

export type Subscription_RootUser_Feedback_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootUser_Feedback_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Feedback_Type_Stream_Cursor_Input>>
  where?: Maybe<User_Feedback_Type_Bool_Exp>
}

export type Subscription_RootUser_FlagsArgs = {
  distinct_on?: Maybe<Array<User_Flags_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Flags_Order_By>>
  where?: Maybe<User_Flags_Bool_Exp>
}

export type Subscription_RootUser_Flags_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Flags_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Flags_Order_By>>
  where?: Maybe<User_Flags_Bool_Exp>
}

export type Subscription_RootUser_Flags_By_PkArgs = {
  user_id: Scalars['String']
}

export type Subscription_RootUser_Flags_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Flags_Stream_Cursor_Input>>
  where?: Maybe<User_Flags_Bool_Exp>
}

export type Subscription_RootUser_ImpersonationArgs = {
  distinct_on?: Maybe<Array<User_Impersonation_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Impersonation_Order_By>>
  where?: Maybe<User_Impersonation_Bool_Exp>
}

export type Subscription_RootUser_Impersonation_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Impersonation_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Impersonation_Order_By>>
  where?: Maybe<User_Impersonation_Bool_Exp>
}

export type Subscription_RootUser_Impersonation_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Impersonation_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Impersonation_Stream_Cursor_Input>>
  where?: Maybe<User_Impersonation_Bool_Exp>
}

export type Subscription_RootUser_Ip_AddressArgs = {
  distinct_on?: Maybe<Array<User_Ip_Address_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Ip_Address_Order_By>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

export type Subscription_RootUser_Ip_Address_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Ip_Address_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Ip_Address_Order_By>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

export type Subscription_RootUser_Ip_Address_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Ip_Address_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Ip_Address_Stream_Cursor_Input>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

export type Subscription_RootUser_Items_FollowedArgs = {
  distinct_on?: Maybe<Array<User_Items_Followed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Items_Followed_Order_By>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

export type Subscription_RootUser_Items_Followed_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Items_Followed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Items_Followed_Order_By>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

export type Subscription_RootUser_Items_Followed_By_PkArgs = {
  followable_item_id: Scalars['uuid']
  followable_item_type: Scalars['String']
  user_id: Scalars['String']
}

export type Subscription_RootUser_Items_Followed_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Items_Followed_Stream_Cursor_Input>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

export type Subscription_RootUser_LinksArgs = {
  distinct_on?: Maybe<Array<User_Links_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Links_Order_By>>
  where?: Maybe<User_Links_Bool_Exp>
}

export type Subscription_RootUser_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Links_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Links_Order_By>>
  where?: Maybe<User_Links_Bool_Exp>
}

export type Subscription_RootUser_Links_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Links_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Links_Stream_Cursor_Input>>
  where?: Maybe<User_Links_Bool_Exp>
}

export type Subscription_RootUser_Onboarding_SourceArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_Source_Order_By>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

export type Subscription_RootUser_Onboarding_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_Source_Order_By>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

export type Subscription_RootUser_Onboarding_Source_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Onboarding_Source_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Onboarding_Source_Stream_Cursor_Input>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

export type Subscription_RootUser_Onboarding_StateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_State_Order_By>>
  where?: Maybe<User_Onboarding_State_Bool_Exp>
}

export type Subscription_RootUser_Onboarding_State_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_State_Order_By>>
  where?: Maybe<User_Onboarding_State_Bool_Exp>
}

export type Subscription_RootUser_Onboarding_State_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootUser_Onboarding_State_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Onboarding_State_Stream_Cursor_Input>>
  where?: Maybe<User_Onboarding_State_Bool_Exp>
}

export type Subscription_RootUser_Phone_ContactArgs = {
  distinct_on?: Maybe<Array<User_Phone_Contact_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Phone_Contact_Order_By>>
  where?: Maybe<User_Phone_Contact_Bool_Exp>
}

export type Subscription_RootUser_Phone_Contact_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Phone_Contact_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Phone_Contact_Order_By>>
  where?: Maybe<User_Phone_Contact_Bool_Exp>
}

export type Subscription_RootUser_Phone_Contact_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Phone_Contact_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Phone_Contact_Stream_Cursor_Input>>
  where?: Maybe<User_Phone_Contact_Bool_Exp>
}

export type Subscription_RootUser_Points_Redeemed_SummaryArgs = {
  distinct_on?: Maybe<Array<User_Points_Redeemed_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Redeemed_Summary_Order_By>>
  where?: Maybe<User_Points_Redeemed_Summary_Bool_Exp>
}

export type Subscription_RootUser_Points_Redeemed_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Points_Redeemed_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Redeemed_Summary_Order_By>>
  where?: Maybe<User_Points_Redeemed_Summary_Bool_Exp>
}

export type Subscription_RootUser_Points_Redeemed_Summary_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Points_Redeemed_Summary_Stream_Cursor_Input>>
  where?: Maybe<User_Points_Redeemed_Summary_Bool_Exp>
}

export type Subscription_RootUser_Points_SummaryArgs = {
  distinct_on?: Maybe<Array<User_Points_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Summary_Order_By>>
  where?: Maybe<User_Points_Summary_Bool_Exp>
}

export type Subscription_RootUser_Points_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Points_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Summary_Order_By>>
  where?: Maybe<User_Points_Summary_Bool_Exp>
}

export type Subscription_RootUser_Points_Summary_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Points_Summary_Stream_Cursor_Input>>
  where?: Maybe<User_Points_Summary_Bool_Exp>
}

export type Subscription_RootUser_Purchase_SubscriptionArgs = {
  distinct_on?: Maybe<Array<User_Purchase_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchase_Subscription_Order_By>>
  where?: Maybe<User_Purchase_Subscription_Bool_Exp>
}

export type Subscription_RootUser_Purchase_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Purchase_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchase_Subscription_Order_By>>
  where?: Maybe<User_Purchase_Subscription_Bool_Exp>
}

export type Subscription_RootUser_Purchase_Subscription_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Purchase_Subscription_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Purchase_Subscription_Stream_Cursor_Input>>
  where?: Maybe<User_Purchase_Subscription_Bool_Exp>
}

export type Subscription_RootUser_PurchasesArgs = {
  distinct_on?: Maybe<Array<User_Purchases_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchases_Order_By>>
  where?: Maybe<User_Purchases_Bool_Exp>
}

export type Subscription_RootUser_Purchases_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Purchases_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchases_Order_By>>
  where?: Maybe<User_Purchases_Bool_Exp>
}

export type Subscription_RootUser_Purchases_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Purchases_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Purchases_Stream_Cursor_Input>>
  where?: Maybe<User_Purchases_Bool_Exp>
}

export type Subscription_RootUser_Push_NotificationArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Order_By>>
  where?: Maybe<User_Push_Notification_Bool_Exp>
}

export type Subscription_RootUser_Push_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Order_By>>
  where?: Maybe<User_Push_Notification_Bool_Exp>
}

export type Subscription_RootUser_Push_Notification_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Push_Notification_Stream_Cursor_Input>>
  where?: Maybe<User_Push_Notification_Bool_Exp>
}

export type Subscription_RootUser_Push_Notification_TokenArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Token_Order_By>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

export type Subscription_RootUser_Push_Notification_Token_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Token_Order_By>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

export type Subscription_RootUser_Push_Notification_Token_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Push_Notification_Token_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Push_Notification_Token_Stream_Cursor_Input>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

export type Subscription_RootUser_ReferralArgs = {
  distinct_on?: Maybe<Array<User_Referral_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Referral_Order_By>>
  where?: Maybe<User_Referral_Bool_Exp>
}

export type Subscription_RootUser_Referral_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Referral_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Referral_Order_By>>
  where?: Maybe<User_Referral_Bool_Exp>
}

export type Subscription_RootUser_Referral_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Referral_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Referral_Stream_Cursor_Input>>
  where?: Maybe<User_Referral_Bool_Exp>
}

export type Subscription_RootUser_Reward_Multiplier_ConfigArgs = {
  distinct_on?: Maybe<Array<User_Reward_Multiplier_Config_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Multiplier_Config_Order_By>>
  where?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
}

export type Subscription_RootUser_Reward_Multiplier_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Multiplier_Config_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Multiplier_Config_Order_By>>
  where?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
}

export type Subscription_RootUser_Reward_Multiplier_Config_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Reward_Multiplier_Config_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Reward_Multiplier_Config_Stream_Cursor_Input>>
  where?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
}

export type Subscription_RootUser_Reward_RedemptionArgs = {
  distinct_on?: Maybe<Array<User_Reward_Redemption_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Redemption_Order_By>>
  where?: Maybe<User_Reward_Redemption_Bool_Exp>
}

export type Subscription_RootUser_Reward_Redemption_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Redemption_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Redemption_Order_By>>
  where?: Maybe<User_Reward_Redemption_Bool_Exp>
}

export type Subscription_RootUser_Reward_Redemption_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Reward_Redemption_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Reward_Redemption_Stream_Cursor_Input>>
  where?: Maybe<User_Reward_Redemption_Bool_Exp>
}

export type Subscription_RootUser_Reward_ReferenceArgs = {
  distinct_on?: Maybe<Array<User_Reward_Reference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Reference_Order_By>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

export type Subscription_RootUser_Reward_Reference_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Reference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Reference_Order_By>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

export type Subscription_RootUser_Reward_Reference_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Reward_Reference_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Reward_Reference_Stream_Cursor_Input>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

export type Subscription_RootUser_Shop_FavoriteArgs = {
  distinct_on?: Maybe<Array<User_Shop_Favorite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shop_Favorite_Order_By>>
  where?: Maybe<User_Shop_Favorite_Bool_Exp>
}

export type Subscription_RootUser_Shop_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Shop_Favorite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shop_Favorite_Order_By>>
  where?: Maybe<User_Shop_Favorite_Bool_Exp>
}

export type Subscription_RootUser_Shop_Favorite_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Shop_Favorite_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Shop_Favorite_Stream_Cursor_Input>>
  where?: Maybe<User_Shop_Favorite_Bool_Exp>
}

export type Subscription_RootUser_Shopping_PreferencesArgs = {
  distinct_on?: Maybe<Array<User_Shopping_Preferences_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shopping_Preferences_Order_By>>
  where?: Maybe<User_Shopping_Preferences_Bool_Exp>
}

export type Subscription_RootUser_Shopping_Preferences_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Shopping_Preferences_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shopping_Preferences_Order_By>>
  where?: Maybe<User_Shopping_Preferences_Bool_Exp>
}

export type Subscription_RootUser_Shopping_Preferences_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootUser_Shopping_Preferences_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Shopping_Preferences_Stream_Cursor_Input>>
  where?: Maybe<User_Shopping_Preferences_Bool_Exp>
}

export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Stream_Cursor_Input>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUser_SubscriptionArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Order_By>>
  where?: Maybe<User_Subscription_Bool_Exp>
}

export type Subscription_RootUser_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Order_By>>
  where?: Maybe<User_Subscription_Bool_Exp>
}

export type Subscription_RootUser_Subscription_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Subscription_StatusArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Status_Order_By>>
  where?: Maybe<User_Subscription_Status_Bool_Exp>
}

export type Subscription_RootUser_Subscription_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Status_Order_By>>
  where?: Maybe<User_Subscription_Status_Bool_Exp>
}

export type Subscription_RootUser_Subscription_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootUser_Subscription_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Subscription_Status_Stream_Cursor_Input>>
  where?: Maybe<User_Subscription_Status_Bool_Exp>
}

export type Subscription_RootUser_Subscription_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Subscription_Stream_Cursor_Input>>
  where?: Maybe<User_Subscription_Bool_Exp>
}

export type Subscription_RootUser_TempArgs = {
  distinct_on?: Maybe<Array<User_Temp_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Temp_Order_By>>
  where?: Maybe<User_Temp_Bool_Exp>
}

export type Subscription_RootUser_Temp_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Temp_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Temp_Order_By>>
  where?: Maybe<User_Temp_Bool_Exp>
}

export type Subscription_RootUser_Temp_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Temp_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Temp_Stream_Cursor_Input>>
  where?: Maybe<User_Temp_Bool_Exp>
}

export type Subscription_RootUsers_SearchArgs = {
  args: Users_Search_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUsers_Search_AggregateArgs = {
  args: Users_Search_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootWorflow_TypeArgs = {
  distinct_on?: Maybe<Array<Worflow_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Worflow_Type_Order_By>>
  where?: Maybe<Worflow_Type_Bool_Exp>
}

export type Subscription_RootWorflow_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Worflow_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Worflow_Type_Order_By>>
  where?: Maybe<Worflow_Type_Bool_Exp>
}

export type Subscription_RootWorflow_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootWorflow_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Worflow_Type_Stream_Cursor_Input>>
  where?: Maybe<Worflow_Type_Bool_Exp>
}

export type Subscription_RootWorkflowArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

export type Subscription_RootWorkflow_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

export type Subscription_RootWorkflow_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootWorkflow_StatusArgs = {
  distinct_on?: Maybe<Array<Workflow_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Status_Order_By>>
  where?: Maybe<Workflow_Status_Bool_Exp>
}

export type Subscription_RootWorkflow_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Status_Order_By>>
  where?: Maybe<Workflow_Status_Bool_Exp>
}

export type Subscription_RootWorkflow_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootWorkflow_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Workflow_Status_Stream_Cursor_Input>>
  where?: Maybe<Workflow_Status_Bool_Exp>
}

export type Subscription_RootWorkflow_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Workflow_Stream_Cursor_Input>>
  where?: Maybe<Workflow_Bool_Exp>
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>
  _gt?: Maybe<Scalars['timestamp']>
  _gte?: Maybe<Scalars['timestamp']>
  _in?: Maybe<Array<Scalars['timestamp']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['timestamp']>
  _lte?: Maybe<Scalars['timestamp']>
  _neq?: Maybe<Scalars['timestamp']>
  _nin?: Maybe<Array<Scalars['timestamp']>>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>
  _gt?: Maybe<Scalars['timestamptz']>
  _gte?: Maybe<Scalars['timestamptz']>
  _in?: Maybe<Array<Scalars['timestamptz']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['timestamptz']>
  _lte?: Maybe<Scalars['timestamptz']>
  _neq?: Maybe<Scalars['timestamptz']>
  _nin?: Maybe<Array<Scalars['timestamptz']>>
}

export type Update_Last_Seen_Notification_Center_Item_Sequential_Id_Args = {
  user_id?: Maybe<Scalars['String']>
}

export type Upsert_Notification_Preference_Args = {
  allowed?: Maybe<Scalars['Boolean']>
  allowed_communication?: Maybe<Scalars['_text']>
  communication_template?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
}

export type Used_Creator_Redirect_Args = {
  current_shop_key?: Maybe<Scalars['String']>
}

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** An array relationship */
  affiliate_transactions: Array<Affiliate_Transaction>
  /** An aggregate relationship */
  affiliate_transactions_aggregate: Affiliate_Transaction_Aggregate
  anonymous?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  app_versions: Array<User_App_Version>
  /** An aggregate relationship */
  app_versions_aggregate: User_App_Version_Aggregate
  applied_as_creator_at?: Maybe<Scalars['timestamptz']>
  bio?: Maybe<Scalars['String']>
  bio_link?: Maybe<Scalars['String']>
  /** Computed field on `user` to determine if the user can be credited for a referral (ie, extensions are installed, etc). */
  can_be_credited?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  cart_item_likes: Array<Cart_Item_Like>
  /** An aggregate relationship */
  cart_item_likes_aggregate: Cart_Item_Like_Aggregate
  /** An array relationship */
  carts: Array<Cart>
  /** An aggregate relationship */
  carts_aggregate: Cart_Aggregate
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** An array relationship */
  collection_item_claims: Array<Collection_Item_Claim>
  /** An aggregate relationship */
  collection_item_claims_aggregate: Collection_Item_Claim_Aggregate
  /** An array relationship */
  collections: Array<Collection>
  /** An aggregate relationship */
  collections_aggregate: Collection_Aggregate
  /** An array relationship */
  collections_collaborating: Array<Collection_Collaborator>
  /** An aggregate relationship */
  collections_collaborating_aggregate: Collection_Collaborator_Aggregate
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  deleted_reason?: Maybe<Scalars['String']>
  disabled_at?: Maybe<Scalars['timestamptz']>
  disabled_reason?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_display_name" */
  display_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_email_md5_hash" */
  email_md5?: Maybe<Scalars['String']>
  /** A computed field, executes function "extension_version" */
  extension_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_first_app_install" */
  first_app_install?: Maybe<Scalars['String']>
  /** An object relationship */
  flags?: Maybe<User_Flags>
  /** An array relationship */
  follows: Array<User_Items_Followed>
  /** An aggregate relationship */
  follows_aggregate: User_Items_Followed_Aggregate
  /** Computed field on `user` to calculate if the user has an `^accounts+foo@addtocarrot.com` email. */
  has_accounts_email_computed?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "user_has_billing_account" */
  has_billing_account?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "has_claimed_username_computed" */
  has_claimed_username_computed?: Maybe<Scalars['Boolean']>
  /** Computed field on `user` to calculate if the user has an email set. */
  has_email_set?: Maybe<Scalars['Boolean']>
  /** Computed field on `user` that returns whether the user has onboarded. */
  has_onboarded?: Maybe<Scalars['Boolean']>
  /** A computed field on a `user` that is a shorthand for determining whether a user has set their porfile picture. */
  has_picture_set?: Maybe<Scalars['Boolean']>
  has_premium_subscription?: Maybe<Scalars['Boolean']>
  /** A computed field on a `user` that is a shorthand for determining whether a user has set their name. */
  has_set_name_computed?: Maybe<Scalars['Boolean']>
  icon_order: Scalars['numeric']
  id: Scalars['String']
  in_on_campus_contest?: Maybe<Scalars['Boolean']>
  installed_at?: Maybe<Scalars['timestamptz']>
  internal_account?: Maybe<Scalars['Boolean']>
  ios_version?: Maybe<Scalars['String']>
  /** Computed field on `user` to calculate if the user is a "creator" */
  is_creator_computed?: Maybe<Scalars['Boolean']>
  /** Computed field on `user` to calculate if the user is a "curator" */
  is_curator_computed?: Maybe<Scalars['Boolean']>
  /** Computed field on `user` to calculate if the user is an "icon". */
  is_icon_computed?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  links: Array<User_Links>
  /** An aggregate relationship */
  links_aggregate: User_Links_Aggregate
  metadata?: Maybe<Scalars['jsonb']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** An array relationship */
  notification_center_items: Array<Notification_Center_Item>
  /** An aggregate relationship */
  notification_center_items_aggregate: Notification_Center_Item_Aggregate
  /** An array relationship */
  notification_preferences: Array<Notification_Preference>
  /** An aggregate relationship */
  notification_preferences_aggregate: Notification_Preference_Aggregate
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  onboarded?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  orphan_parent?: Maybe<User>
  orphaned_at?: Maybe<Scalars['timestamptz']>
  orphaned_by?: Maybe<Scalars['String']>
  /** An array relationship */
  orphans: Array<User>
  /** An aggregate relationship */
  orphans_aggregate: User_Aggregate
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  possible_email?: Maybe<Scalars['String']>
  probably_real?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** An array relationship */
  referred_users: Array<User>
  /** An aggregate relationship */
  referred_users_aggregate: User_Aggregate
  /** An object relationship */
  referrer_user?: Maybe<User>
  referrer_user_id?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['name']>
  safari_extension_version?: Maybe<Scalars['String']>
  shopping_preference?: Maybe<User_Shopping_Preferences_Enum>
  short_id: Scalars['name']
  show_curations: Scalars['Boolean']
  show_onboarding?: Maybe<Scalars['Boolean']>
  show_rabbithole: Scalars['Boolean']
  /** An array relationship */
  similar_products_histories: Array<Similar_Products_History>
  /** An aggregate relationship */
  similar_products_histories_aggregate: Similar_Products_History_Aggregate
  /** A computed field, executes function "user_subscription_status" */
  subscription_status?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  user_account_type?: Maybe<User_Account_Type>
  /** An array relationship */
  user_carts_onboardings: Array<User_Carts_Onboarding>
  /** An aggregate relationship */
  user_carts_onboardings_aggregate: User_Carts_Onboarding_Aggregate
  /** An array relationship */
  user_categories: Array<User_Category>
  /** An aggregate relationship */
  user_categories_aggregate: User_Category_Aggregate
  /** An array relationship */
  user_ip_addresses: Array<User_Ip_Address>
  /** An aggregate relationship */
  user_ip_addresses_aggregate: User_Ip_Address_Aggregate
  /** An array relationship */
  user_onboarding_sources: Array<User_Onboarding_Source>
  /** An aggregate relationship */
  user_onboarding_sources_aggregate: User_Onboarding_Source_Aggregate
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
  /** An array relationship */
  user_push_notification_tokens: Array<User_Push_Notification_Token>
  /** An aggregate relationship */
  user_push_notification_tokens_aggregate: User_Push_Notification_Token_Aggregate
  /** An array relationship */
  user_referrals: Array<User_Referral>
  /** An aggregate relationship */
  user_referrals_aggregate: User_Referral_Aggregate
  /** An object relationship */
  user_shopping_preference?: Maybe<User_Shopping_Preferences>
  user_type?: Maybe<User_Account_Type_Enum>
  username?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
  version?: Maybe<Scalars['String']>
  waitlist?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  workflows: Array<Workflow>
  /** An aggregate relationship */
  workflows_aggregate: Workflow_Aggregate
}

/** columns and relationships of "user" */
export type UserAffiliate_TransactionsArgs = {
  distinct_on?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Transaction_Order_By>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserAffiliate_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Transaction_Order_By>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserApp_VersionsArgs = {
  distinct_on?: Maybe<Array<User_App_Version_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_App_Version_Order_By>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserApp_Versions_AggregateArgs = {
  distinct_on?: Maybe<Array<User_App_Version_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_App_Version_Order_By>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCart_Item_LikesArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCart_Item_Likes_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCartsArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCarts_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCollection_Item_ClaimsArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Order_By>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCollection_Item_Claims_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Order_By>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCollectionsArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCollections_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCollections_CollaboratingArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCollections_Collaborating_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserFollowsArgs = {
  distinct_on?: Maybe<Array<User_Items_Followed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Items_Followed_Order_By>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserFollows_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Items_Followed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Items_Followed_Order_By>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserLinksArgs = {
  distinct_on?: Maybe<Array<User_Links_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Links_Order_By>>
  where?: Maybe<User_Links_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserLinks_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Links_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Links_Order_By>>
  where?: Maybe<User_Links_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "user" */
export type UserNotification_Center_ItemsArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserNotification_Center_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserNotification_PreferencesArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserNotification_Preferences_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserOrphansArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserOrphans_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserReferred_UsersArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserReferred_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserSimilar_Products_HistoriesArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserSimilar_Products_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Carts_OnboardingsArgs = {
  distinct_on?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Carts_Onboarding_Order_By>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Carts_Onboardings_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Carts_Onboarding_Order_By>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_CategoriesArgs = {
  distinct_on?: Maybe<Array<User_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Category_Order_By>>
  where?: Maybe<User_Category_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Category_Order_By>>
  where?: Maybe<User_Category_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Ip_AddressesArgs = {
  distinct_on?: Maybe<Array<User_Ip_Address_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Ip_Address_Order_By>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Ip_Addresses_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Ip_Address_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Ip_Address_Order_By>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Onboarding_SourcesArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_Source_Order_By>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Onboarding_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_Source_Order_By>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Push_Notification_TokensArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Token_Order_By>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Push_Notification_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Token_Order_By>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_ReferralsArgs = {
  distinct_on?: Maybe<Array<User_Referral_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Referral_Order_By>>
  where?: Maybe<User_Referral_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Referrals_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Referral_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Referral_Order_By>>
  where?: Maybe<User_Referral_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserWorkflowsArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserWorkflows_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

/** User account type enum table */
export type User_Account_Type = {
  __typename?: 'user_account_type'
  description: Scalars['String']
  value: Scalars['String']
}

/** aggregated selection of "user_account_type" */
export type User_Account_Type_Aggregate = {
  __typename?: 'user_account_type_aggregate'
  aggregate?: Maybe<User_Account_Type_Aggregate_Fields>
  nodes: Array<User_Account_Type>
}

/** aggregate fields of "user_account_type" */
export type User_Account_Type_Aggregate_Fields = {
  __typename?: 'user_account_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Account_Type_Max_Fields>
  min?: Maybe<User_Account_Type_Min_Fields>
}

/** aggregate fields of "user_account_type" */
export type User_Account_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Account_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_account_type". All fields are combined with a logical 'AND'. */
export type User_Account_Type_Bool_Exp = {
  _and?: Maybe<Array<User_Account_Type_Bool_Exp>>
  _not?: Maybe<User_Account_Type_Bool_Exp>
  _or?: Maybe<Array<User_Account_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_account_type" */
export enum User_Account_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  AccountTypePkey = 'account_type_pkey',
}

export enum User_Account_Type_Enum {
  /** Creator account type */
  Creator = 'creator',
  /** Curator user type */
  Curator = 'curator',
  /** Regular user account */
  Regular = 'regular',
}

/** Boolean expression to compare columns of type "user_account_type_enum". All fields are combined with logical 'AND'. */
export type User_Account_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Account_Type_Enum>
  _in?: Maybe<Array<User_Account_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<User_Account_Type_Enum>
  _nin?: Maybe<Array<User_Account_Type_Enum>>
}

/** input type for inserting data into table "user_account_type" */
export type User_Account_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Account_Type_Max_Fields = {
  __typename?: 'user_account_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Account_Type_Min_Fields = {
  __typename?: 'user_account_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_account_type" */
export type User_Account_Type_Mutation_Response = {
  __typename?: 'user_account_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Account_Type>
}

/** input type for inserting object relation for remote table "user_account_type" */
export type User_Account_Type_Obj_Rel_Insert_Input = {
  data: User_Account_Type_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Account_Type_On_Conflict>
}

/** on_conflict condition type for table "user_account_type" */
export type User_Account_Type_On_Conflict = {
  constraint: User_Account_Type_Constraint
  update_columns?: Array<User_Account_Type_Update_Column>
  where?: Maybe<User_Account_Type_Bool_Exp>
}

/** Ordering options when selecting data from "user_account_type". */
export type User_Account_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: user_account_type */
export type User_Account_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "user_account_type" */
export enum User_Account_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "user_account_type" */
export type User_Account_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_account_type" */
export type User_Account_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Account_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Account_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "user_account_type" */
export enum User_Account_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type User_Account_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Account_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Account_Type_Bool_Exp
}

/** Table to keep track of rewardable user actions */
export type User_Action = {
  __typename?: 'user_action'
  action: Rewardable_Action_Names_Enum
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  multiplier: Scalars['numeric']
  pending: Scalars['Boolean']
  points: Scalars['numeric']
  reference_id?: Maybe<Scalars['String']>
  reference_type?: Maybe<Rewardable_Item_Type_Enum>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_action" */
export type User_Action_Aggregate = {
  __typename?: 'user_action_aggregate'
  aggregate?: Maybe<User_Action_Aggregate_Fields>
  nodes: Array<User_Action>
}

/** aggregate fields of "user_action" */
export type User_Action_Aggregate_Fields = {
  __typename?: 'user_action_aggregate_fields'
  avg?: Maybe<User_Action_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Action_Max_Fields>
  min?: Maybe<User_Action_Min_Fields>
  stddev?: Maybe<User_Action_Stddev_Fields>
  stddev_pop?: Maybe<User_Action_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Action_Stddev_Samp_Fields>
  sum?: Maybe<User_Action_Sum_Fields>
  var_pop?: Maybe<User_Action_Var_Pop_Fields>
  var_samp?: Maybe<User_Action_Var_Samp_Fields>
  variance?: Maybe<User_Action_Variance_Fields>
}

/** aggregate fields of "user_action" */
export type User_Action_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Action_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type User_Action_Avg_Fields = {
  __typename?: 'user_action_avg_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "user_action". All fields are combined with a logical 'AND'. */
export type User_Action_Bool_Exp = {
  _and?: Maybe<Array<User_Action_Bool_Exp>>
  _not?: Maybe<User_Action_Bool_Exp>
  _or?: Maybe<Array<User_Action_Bool_Exp>>
  action?: Maybe<Rewardable_Action_Names_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  multiplier?: Maybe<Numeric_Comparison_Exp>
  pending?: Maybe<Boolean_Comparison_Exp>
  points?: Maybe<Numeric_Comparison_Exp>
  reference_id?: Maybe<String_Comparison_Exp>
  reference_type?: Maybe<Rewardable_Item_Type_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_action" */
export enum User_Action_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserActionPkey = 'user_action_pkey',
  /** unique or primary key constraint on columns "action", "user_id", "reference_type", "points", "reference_id" */
  UserActionUserIdActionReferenceTypeReferenceIdPointsK = 'user_action_user_id_action_reference_type_reference_id_points_k',
  /** unique or primary key constraint on columns "action", "user_id", "reference_type", "reference_id" */
  UserActionUserIdActionRewardableItemTypeRewardableItem = 'user_action_user_id_action_rewardable_item_type_rewardable_item',
}

/** input type for incrementing numeric columns in table "user_action" */
export type User_Action_Inc_Input = {
  multiplier?: Maybe<Scalars['numeric']>
  points?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user_action" */
export type User_Action_Insert_Input = {
  action?: Maybe<Rewardable_Action_Names_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  pending?: Maybe<Scalars['Boolean']>
  points?: Maybe<Scalars['numeric']>
  reference_id?: Maybe<Scalars['String']>
  reference_type?: Maybe<Rewardable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Action_Max_Fields = {
  __typename?: 'user_action_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  points?: Maybe<Scalars['numeric']>
  reference_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Action_Min_Fields = {
  __typename?: 'user_action_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  points?: Maybe<Scalars['numeric']>
  reference_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_action" */
export type User_Action_Mutation_Response = {
  __typename?: 'user_action_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Action>
}

/** on_conflict condition type for table "user_action" */
export type User_Action_On_Conflict = {
  constraint: User_Action_Constraint
  update_columns?: Array<User_Action_Update_Column>
  where?: Maybe<User_Action_Bool_Exp>
}

/** Ordering options when selecting data from "user_action". */
export type User_Action_Order_By = {
  action?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  multiplier?: Maybe<Order_By>
  pending?: Maybe<Order_By>
  points?: Maybe<Order_By>
  reference_id?: Maybe<Order_By>
  reference_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_action */
export type User_Action_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_action" */
export enum User_Action_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  Pending = 'pending',
  /** column name */
  Points = 'points',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  ReferenceType = 'reference_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_action" */
export type User_Action_Set_Input = {
  action?: Maybe<Rewardable_Action_Names_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  pending?: Maybe<Scalars['Boolean']>
  points?: Maybe<Scalars['numeric']>
  reference_id?: Maybe<Scalars['String']>
  reference_type?: Maybe<Rewardable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type User_Action_Stddev_Fields = {
  __typename?: 'user_action_stddev_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type User_Action_Stddev_Pop_Fields = {
  __typename?: 'user_action_stddev_pop_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type User_Action_Stddev_Samp_Fields = {
  __typename?: 'user_action_stddev_samp_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "user_action" */
export type User_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Action_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Action_Stream_Cursor_Value_Input = {
  action?: Maybe<Rewardable_Action_Names_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  pending?: Maybe<Scalars['Boolean']>
  points?: Maybe<Scalars['numeric']>
  reference_id?: Maybe<Scalars['String']>
  reference_type?: Maybe<Rewardable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Action_Sum_Fields = {
  __typename?: 'user_action_sum_fields'
  multiplier?: Maybe<Scalars['numeric']>
  points?: Maybe<Scalars['numeric']>
}

/** update columns of table "user_action" */
export enum User_Action_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  Pending = 'pending',
  /** column name */
  Points = 'points',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  ReferenceType = 'reference_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Action_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Action_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Action_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Action_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Action_Var_Pop_Fields = {
  __typename?: 'user_action_var_pop_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type User_Action_Var_Samp_Fields = {
  __typename?: 'user_action_var_samp_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type User_Action_Variance_Fields = {
  __typename?: 'user_action_variance_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate'
  aggregate?: Maybe<User_Aggregate_Fields>
  nodes: Array<User>
}

export type User_Aggregate_Bool_Exp = {
  bool_and?: Maybe<User_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<User_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<User_Aggregate_Bool_Exp_Count>
}

export type User_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields'
  avg?: Maybe<User_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Max_Fields>
  min?: Maybe<User_Min_Fields>
  stddev?: Maybe<User_Stddev_Fields>
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>
  sum?: Maybe<User_Sum_Fields>
  var_pop?: Maybe<User_Var_Pop_Fields>
  var_samp?: Maybe<User_Var_Samp_Fields>
  variance?: Maybe<User_Variance_Fields>
}

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  avg?: Maybe<User_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<User_Max_Order_By>
  min?: Maybe<User_Min_Order_By>
  stddev?: Maybe<User_Stddev_Order_By>
  stddev_pop?: Maybe<User_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<User_Stddev_Samp_Order_By>
  sum?: Maybe<User_Sum_Order_By>
  var_pop?: Maybe<User_Var_Pop_Order_By>
  var_samp?: Maybe<User_Var_Samp_Order_By>
  variance?: Maybe<User_Variance_Order_By>
}

/** A table to keep track of what versions that our users are on for our apps */
export type User_App_Version = {
  __typename?: 'user_app_version'
  build_number: Scalars['numeric']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  name: Scalars['String']
  permissions?: Maybe<Scalars['jsonb']>
  platform: Scalars['String']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
  version: Scalars['String']
}

/** A table to keep track of what versions that our users are on for our apps */
export type User_App_VersionPermissionsArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_app_version" */
export type User_App_Version_Aggregate = {
  __typename?: 'user_app_version_aggregate'
  aggregate?: Maybe<User_App_Version_Aggregate_Fields>
  nodes: Array<User_App_Version>
}

export type User_App_Version_Aggregate_Bool_Exp = {
  count?: Maybe<User_App_Version_Aggregate_Bool_Exp_Count>
}

export type User_App_Version_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_App_Version_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_App_Version_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_app_version" */
export type User_App_Version_Aggregate_Fields = {
  __typename?: 'user_app_version_aggregate_fields'
  avg?: Maybe<User_App_Version_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_App_Version_Max_Fields>
  min?: Maybe<User_App_Version_Min_Fields>
  stddev?: Maybe<User_App_Version_Stddev_Fields>
  stddev_pop?: Maybe<User_App_Version_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_App_Version_Stddev_Samp_Fields>
  sum?: Maybe<User_App_Version_Sum_Fields>
  var_pop?: Maybe<User_App_Version_Var_Pop_Fields>
  var_samp?: Maybe<User_App_Version_Var_Samp_Fields>
  variance?: Maybe<User_App_Version_Variance_Fields>
}

/** aggregate fields of "user_app_version" */
export type User_App_Version_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_App_Version_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_app_version" */
export type User_App_Version_Aggregate_Order_By = {
  avg?: Maybe<User_App_Version_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<User_App_Version_Max_Order_By>
  min?: Maybe<User_App_Version_Min_Order_By>
  stddev?: Maybe<User_App_Version_Stddev_Order_By>
  stddev_pop?: Maybe<User_App_Version_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<User_App_Version_Stddev_Samp_Order_By>
  sum?: Maybe<User_App_Version_Sum_Order_By>
  var_pop?: Maybe<User_App_Version_Var_Pop_Order_By>
  var_samp?: Maybe<User_App_Version_Var_Samp_Order_By>
  variance?: Maybe<User_App_Version_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_App_Version_Append_Input = {
  permissions?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "user_app_version" */
export type User_App_Version_Arr_Rel_Insert_Input = {
  data: Array<User_App_Version_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_App_Version_On_Conflict>
}

/** aggregate avg on columns */
export type User_App_Version_Avg_Fields = {
  __typename?: 'user_app_version_avg_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "user_app_version" */
export type User_App_Version_Avg_Order_By = {
  build_number?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_app_version". All fields are combined with a logical 'AND'. */
export type User_App_Version_Bool_Exp = {
  _and?: Maybe<Array<User_App_Version_Bool_Exp>>
  _not?: Maybe<User_App_Version_Bool_Exp>
  _or?: Maybe<Array<User_App_Version_Bool_Exp>>
  build_number?: Maybe<Numeric_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  permissions?: Maybe<Jsonb_Comparison_Exp>
  platform?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  version?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_app_version" */
export enum User_App_Version_Constraint {
  /** unique or primary key constraint on columns "id" */
  VersionPkey = 'version_pkey',
  /** unique or primary key constraint on columns "user_id", "build_number", "platform", "version", "name" */
  VersionUserIdNameVersionBuildNumberPlatformKey = 'version_user_id_name_version_build_number_platform_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_App_Version_Delete_At_Path_Input = {
  permissions?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_App_Version_Delete_Elem_Input = {
  permissions?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_App_Version_Delete_Key_Input = {
  permissions?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "user_app_version" */
export type User_App_Version_Inc_Input = {
  build_number?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user_app_version" */
export type User_App_Version_Insert_Input = {
  build_number?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  permissions?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_App_Version_Max_Fields = {
  __typename?: 'user_app_version_max_fields'
  build_number?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_app_version" */
export type User_App_Version_Max_Order_By = {
  build_number?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  platform?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  version?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_App_Version_Min_Fields = {
  __typename?: 'user_app_version_min_fields'
  build_number?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_app_version" */
export type User_App_Version_Min_Order_By = {
  build_number?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  platform?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  version?: Maybe<Order_By>
}

/** response of any mutation on the table "user_app_version" */
export type User_App_Version_Mutation_Response = {
  __typename?: 'user_app_version_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_App_Version>
}

/** on_conflict condition type for table "user_app_version" */
export type User_App_Version_On_Conflict = {
  constraint: User_App_Version_Constraint
  update_columns?: Array<User_App_Version_Update_Column>
  where?: Maybe<User_App_Version_Bool_Exp>
}

/** Ordering options when selecting data from "user_app_version". */
export type User_App_Version_Order_By = {
  build_number?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  permissions?: Maybe<Order_By>
  platform?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  version?: Maybe<Order_By>
}

/** primary key columns input for table: user_app_version */
export type User_App_Version_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_App_Version_Prepend_Input = {
  permissions?: Maybe<Scalars['jsonb']>
}

/** select columns of table "user_app_version" */
export enum User_App_Version_Select_Column {
  /** column name */
  BuildNumber = 'build_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Version = 'version',
}

/** input type for updating data in table "user_app_version" */
export type User_App_Version_Set_Input = {
  build_number?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  permissions?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type User_App_Version_Stddev_Fields = {
  __typename?: 'user_app_version_stddev_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "user_app_version" */
export type User_App_Version_Stddev_Order_By = {
  build_number?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_App_Version_Stddev_Pop_Fields = {
  __typename?: 'user_app_version_stddev_pop_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "user_app_version" */
export type User_App_Version_Stddev_Pop_Order_By = {
  build_number?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_App_Version_Stddev_Samp_Fields = {
  __typename?: 'user_app_version_stddev_samp_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "user_app_version" */
export type User_App_Version_Stddev_Samp_Order_By = {
  build_number?: Maybe<Order_By>
}

/** Streaming cursor of the table "user_app_version" */
export type User_App_Version_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_App_Version_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_App_Version_Stream_Cursor_Value_Input = {
  build_number?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  permissions?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_App_Version_Sum_Fields = {
  __typename?: 'user_app_version_sum_fields'
  build_number?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "user_app_version" */
export type User_App_Version_Sum_Order_By = {
  build_number?: Maybe<Order_By>
}

/** update columns of table "user_app_version" */
export enum User_App_Version_Update_Column {
  /** column name */
  BuildNumber = 'build_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Version = 'version',
}

export type User_App_Version_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_App_Version_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_App_Version_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_App_Version_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_App_Version_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_App_Version_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_App_Version_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_App_Version_Set_Input>
  /** filter the rows which have to be updated */
  where: User_App_Version_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_App_Version_Var_Pop_Fields = {
  __typename?: 'user_app_version_var_pop_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "user_app_version" */
export type User_App_Version_Var_Pop_Order_By = {
  build_number?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_App_Version_Var_Samp_Fields = {
  __typename?: 'user_app_version_var_samp_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "user_app_version" */
export type User_App_Version_Var_Samp_Order_By = {
  build_number?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type User_App_Version_Variance_Fields = {
  __typename?: 'user_app_version_variance_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "user_app_version" */
export type User_App_Version_Variance_Order_By = {
  build_number?: Maybe<Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_On_Conflict>
}

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by avg() on columns of table "user" */
export type User_Avg_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<User_Bool_Exp>>
  _not?: Maybe<User_Bool_Exp>
  _or?: Maybe<Array<User_Bool_Exp>>
  active_cart_items_quantity?: Maybe<Numeric_Comparison_Exp>
  affiliate_transactions?: Maybe<Affiliate_Transaction_Bool_Exp>
  affiliate_transactions_aggregate?: Maybe<Affiliate_Transaction_Aggregate_Bool_Exp>
  anonymous?: Maybe<Boolean_Comparison_Exp>
  app_versions?: Maybe<User_App_Version_Bool_Exp>
  app_versions_aggregate?: Maybe<User_App_Version_Aggregate_Bool_Exp>
  applied_as_creator_at?: Maybe<Timestamptz_Comparison_Exp>
  bio?: Maybe<String_Comparison_Exp>
  bio_link?: Maybe<String_Comparison_Exp>
  can_be_credited?: Maybe<Boolean_Comparison_Exp>
  cart_item_likes?: Maybe<Cart_Item_Like_Bool_Exp>
  cart_item_likes_aggregate?: Maybe<Cart_Item_Like_Aggregate_Bool_Exp>
  carts?: Maybe<Cart_Bool_Exp>
  carts_aggregate?: Maybe<Cart_Aggregate_Bool_Exp>
  carts_count?: Maybe<Numeric_Comparison_Exp>
  collection_item_claims?: Maybe<Collection_Item_Claim_Bool_Exp>
  collection_item_claims_aggregate?: Maybe<Collection_Item_Claim_Aggregate_Bool_Exp>
  collections?: Maybe<Collection_Bool_Exp>
  collections_aggregate?: Maybe<Collection_Aggregate_Bool_Exp>
  collections_collaborating?: Maybe<Collection_Collaborator_Bool_Exp>
  collections_collaborating_aggregate?: Maybe<Collection_Collaborator_Aggregate_Bool_Exp>
  commissions_cleared?: Maybe<Numeric_Comparison_Exp>
  commissions_pending?: Maybe<Numeric_Comparison_Exp>
  cover_image?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>
  deleted_reason?: Maybe<String_Comparison_Exp>
  disabled_at?: Maybe<Timestamptz_Comparison_Exp>
  disabled_reason?: Maybe<String_Comparison_Exp>
  display_name?: Maybe<String_Comparison_Exp>
  email?: Maybe<String_Comparison_Exp>
  email_md5?: Maybe<String_Comparison_Exp>
  extension_version?: Maybe<String_Comparison_Exp>
  first_app_install?: Maybe<String_Comparison_Exp>
  flags?: Maybe<User_Flags_Bool_Exp>
  follows?: Maybe<User_Items_Followed_Bool_Exp>
  follows_aggregate?: Maybe<User_Items_Followed_Aggregate_Bool_Exp>
  has_accounts_email_computed?: Maybe<Boolean_Comparison_Exp>
  has_billing_account?: Maybe<Boolean_Comparison_Exp>
  has_claimed_username_computed?: Maybe<Boolean_Comparison_Exp>
  has_email_set?: Maybe<Boolean_Comparison_Exp>
  has_onboarded?: Maybe<Boolean_Comparison_Exp>
  has_picture_set?: Maybe<Boolean_Comparison_Exp>
  has_premium_subscription?: Maybe<Boolean_Comparison_Exp>
  has_set_name_computed?: Maybe<Boolean_Comparison_Exp>
  icon_order?: Maybe<Numeric_Comparison_Exp>
  id?: Maybe<String_Comparison_Exp>
  in_on_campus_contest?: Maybe<Boolean_Comparison_Exp>
  installed_at?: Maybe<Timestamptz_Comparison_Exp>
  internal_account?: Maybe<Boolean_Comparison_Exp>
  ios_version?: Maybe<String_Comparison_Exp>
  is_creator_computed?: Maybe<Boolean_Comparison_Exp>
  is_curator_computed?: Maybe<Boolean_Comparison_Exp>
  is_icon_computed?: Maybe<Boolean_Comparison_Exp>
  links?: Maybe<User_Links_Bool_Exp>
  links_aggregate?: Maybe<User_Links_Aggregate_Bool_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  mobile_badge_count?: Maybe<Int_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  new_discovery_feed_items_count?: Maybe<Int_Comparison_Exp>
  notification_center_items?: Maybe<Notification_Center_Item_Bool_Exp>
  notification_center_items_aggregate?: Maybe<Notification_Center_Item_Aggregate_Bool_Exp>
  notification_preferences?: Maybe<Notification_Preference_Bool_Exp>
  notification_preferences_aggregate?: Maybe<Notification_Preference_Aggregate_Bool_Exp>
  ogimage_fingerprint?: Maybe<String_Comparison_Exp>
  ogimage_fingerprint_updated?: Maybe<String_Comparison_Exp>
  onboarded?: Maybe<Boolean_Comparison_Exp>
  orphan_parent?: Maybe<User_Bool_Exp>
  orphaned_at?: Maybe<Timestamptz_Comparison_Exp>
  orphaned_by?: Maybe<String_Comparison_Exp>
  orphans?: Maybe<User_Bool_Exp>
  orphans_aggregate?: Maybe<User_Aggregate_Bool_Exp>
  phone?: Maybe<String_Comparison_Exp>
  picture?: Maybe<String_Comparison_Exp>
  possible_email?: Maybe<String_Comparison_Exp>
  probably_real?: Maybe<Boolean_Comparison_Exp>
  referral_count_credited?: Maybe<Int_Comparison_Exp>
  referral_count_total?: Maybe<Int_Comparison_Exp>
  referral_count_within_promo_period?: Maybe<Int_Comparison_Exp>
  referred_users?: Maybe<User_Bool_Exp>
  referred_users_aggregate?: Maybe<User_Aggregate_Bool_Exp>
  referrer_user?: Maybe<User_Bool_Exp>
  referrer_user_id?: Maybe<String_Comparison_Exp>
  role?: Maybe<Name_Comparison_Exp>
  safari_extension_version?: Maybe<String_Comparison_Exp>
  shopping_preference?: Maybe<User_Shopping_Preferences_Enum_Comparison_Exp>
  short_id?: Maybe<Name_Comparison_Exp>
  show_curations?: Maybe<Boolean_Comparison_Exp>
  show_onboarding?: Maybe<Boolean_Comparison_Exp>
  show_rabbithole?: Maybe<Boolean_Comparison_Exp>
  similar_products_histories?: Maybe<Similar_Products_History_Bool_Exp>
  similar_products_histories_aggregate?: Maybe<Similar_Products_History_Aggregate_Bool_Exp>
  subscription_status?: Maybe<String_Comparison_Exp>
  unread_notification_center_items?: Maybe<Int_Comparison_Exp>
  unseen_cart_items?: Maybe<Int_Comparison_Exp>
  unviewed_notification_center_items?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_account_type?: Maybe<User_Account_Type_Bool_Exp>
  user_carts_onboardings?: Maybe<User_Carts_Onboarding_Bool_Exp>
  user_carts_onboardings_aggregate?: Maybe<User_Carts_Onboarding_Aggregate_Bool_Exp>
  user_categories?: Maybe<User_Category_Bool_Exp>
  user_categories_aggregate?: Maybe<User_Category_Aggregate_Bool_Exp>
  user_ip_addresses?: Maybe<User_Ip_Address_Bool_Exp>
  user_ip_addresses_aggregate?: Maybe<User_Ip_Address_Aggregate_Bool_Exp>
  user_onboarding_sources?: Maybe<User_Onboarding_Source_Bool_Exp>
  user_onboarding_sources_aggregate?: Maybe<User_Onboarding_Source_Aggregate_Bool_Exp>
  user_points_multiplier?: Maybe<Numeric_Comparison_Exp>
  user_push_notification_tokens?: Maybe<User_Push_Notification_Token_Bool_Exp>
  user_push_notification_tokens_aggregate?: Maybe<User_Push_Notification_Token_Aggregate_Bool_Exp>
  user_referrals?: Maybe<User_Referral_Bool_Exp>
  user_referrals_aggregate?: Maybe<User_Referral_Aggregate_Bool_Exp>
  user_shopping_preference?: Maybe<User_Shopping_Preferences_Bool_Exp>
  user_type?: Maybe<User_Account_Type_Enum_Comparison_Exp>
  username?: Maybe<String_Comparison_Exp>
  verified?: Maybe<Boolean_Comparison_Exp>
  version?: Maybe<String_Comparison_Exp>
  waitlist?: Maybe<Boolean_Comparison_Exp>
  workflows?: Maybe<Workflow_Bool_Exp>
  workflows_aggregate?: Maybe<Workflow_Aggregate_Bool_Exp>
}

/** columns and relationships of "user_carts_onboarding" */
export type User_Carts_Onboarding = {
  __typename?: 'user_carts_onboarding'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  processed_at?: Maybe<Scalars['timestamptz']>
  processing: Scalars['Boolean']
  sequential_id: Scalars['Int']
  /** An object relationship */
  shop: Shop
  shop_key: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_carts_onboarding" */
export type User_Carts_Onboarding_Aggregate = {
  __typename?: 'user_carts_onboarding_aggregate'
  aggregate?: Maybe<User_Carts_Onboarding_Aggregate_Fields>
  nodes: Array<User_Carts_Onboarding>
}

export type User_Carts_Onboarding_Aggregate_Bool_Exp = {
  bool_and?: Maybe<User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<User_Carts_Onboarding_Aggregate_Bool_Exp_Count>
}

export type User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Carts_Onboarding_Select_Column_User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Carts_Onboarding_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Carts_Onboarding_Select_Column_User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Carts_Onboarding_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Carts_Onboarding_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Carts_Onboarding_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_carts_onboarding" */
export type User_Carts_Onboarding_Aggregate_Fields = {
  __typename?: 'user_carts_onboarding_aggregate_fields'
  avg?: Maybe<User_Carts_Onboarding_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Carts_Onboarding_Max_Fields>
  min?: Maybe<User_Carts_Onboarding_Min_Fields>
  stddev?: Maybe<User_Carts_Onboarding_Stddev_Fields>
  stddev_pop?: Maybe<User_Carts_Onboarding_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Carts_Onboarding_Stddev_Samp_Fields>
  sum?: Maybe<User_Carts_Onboarding_Sum_Fields>
  var_pop?: Maybe<User_Carts_Onboarding_Var_Pop_Fields>
  var_samp?: Maybe<User_Carts_Onboarding_Var_Samp_Fields>
  variance?: Maybe<User_Carts_Onboarding_Variance_Fields>
}

/** aggregate fields of "user_carts_onboarding" */
export type User_Carts_Onboarding_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Aggregate_Order_By = {
  avg?: Maybe<User_Carts_Onboarding_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<User_Carts_Onboarding_Max_Order_By>
  min?: Maybe<User_Carts_Onboarding_Min_Order_By>
  stddev?: Maybe<User_Carts_Onboarding_Stddev_Order_By>
  stddev_pop?: Maybe<User_Carts_Onboarding_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<User_Carts_Onboarding_Stddev_Samp_Order_By>
  sum?: Maybe<User_Carts_Onboarding_Sum_Order_By>
  var_pop?: Maybe<User_Carts_Onboarding_Var_Pop_Order_By>
  var_samp?: Maybe<User_Carts_Onboarding_Var_Samp_Order_By>
  variance?: Maybe<User_Carts_Onboarding_Variance_Order_By>
}

/** input type for inserting array relation for remote table "user_carts_onboarding" */
export type User_Carts_Onboarding_Arr_Rel_Insert_Input = {
  data: Array<User_Carts_Onboarding_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Carts_Onboarding_On_Conflict>
}

/** aggregate avg on columns */
export type User_Carts_Onboarding_Avg_Fields = {
  __typename?: 'user_carts_onboarding_avg_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Avg_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_carts_onboarding". All fields are combined with a logical 'AND'. */
export type User_Carts_Onboarding_Bool_Exp = {
  _and?: Maybe<Array<User_Carts_Onboarding_Bool_Exp>>
  _not?: Maybe<User_Carts_Onboarding_Bool_Exp>
  _or?: Maybe<Array<User_Carts_Onboarding_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  processed_at?: Maybe<Timestamptz_Comparison_Exp>
  processing?: Maybe<Boolean_Comparison_Exp>
  sequential_id?: Maybe<Int_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_carts_onboarding" */
export enum User_Carts_Onboarding_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCartsOnboardingPkey = 'user_carts_onboarding_pkey',
  /** unique or primary key constraint on columns "sequential_id" */
  UserCartsOnboardingSequentialIdKey = 'user_carts_onboarding_sequential_id_key',
  /** unique or primary key constraint on columns "shop_key", "user_id" */
  UserCartsOnboardingUserIdShopKeyKey = 'user_carts_onboarding_user_id_shop_key_key',
}

/** input type for incrementing numeric columns in table "user_carts_onboarding" */
export type User_Carts_Onboarding_Inc_Input = {
  sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "user_carts_onboarding" */
export type User_Carts_Onboarding_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  processed_at?: Maybe<Scalars['timestamptz']>
  processing?: Maybe<Scalars['Boolean']>
  sequential_id?: Maybe<Scalars['Int']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Carts_Onboarding_Max_Fields = {
  __typename?: 'user_carts_onboarding_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  processed_at?: Maybe<Scalars['timestamptz']>
  sequential_id?: Maybe<Scalars['Int']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  processed_at?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Carts_Onboarding_Min_Fields = {
  __typename?: 'user_carts_onboarding_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  processed_at?: Maybe<Scalars['timestamptz']>
  sequential_id?: Maybe<Scalars['Int']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  processed_at?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_carts_onboarding" */
export type User_Carts_Onboarding_Mutation_Response = {
  __typename?: 'user_carts_onboarding_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Carts_Onboarding>
}

/** on_conflict condition type for table "user_carts_onboarding" */
export type User_Carts_Onboarding_On_Conflict = {
  constraint: User_Carts_Onboarding_Constraint
  update_columns?: Array<User_Carts_Onboarding_Update_Column>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

/** Ordering options when selecting data from "user_carts_onboarding". */
export type User_Carts_Onboarding_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  processed_at?: Maybe<Order_By>
  processing?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_carts_onboarding */
export type User_Carts_Onboarding_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_carts_onboarding" */
export enum User_Carts_Onboarding_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProcessedAt = 'processed_at',
  /** column name */
  Processing = 'processing',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "user_carts_onboarding_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_carts_onboarding" */
export enum User_Carts_Onboarding_Select_Column_User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Processing = 'processing',
}

/** select "user_carts_onboarding_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_carts_onboarding" */
export enum User_Carts_Onboarding_Select_Column_User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Processing = 'processing',
}

/** input type for updating data in table "user_carts_onboarding" */
export type User_Carts_Onboarding_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  processed_at?: Maybe<Scalars['timestamptz']>
  processing?: Maybe<Scalars['Boolean']>
  sequential_id?: Maybe<Scalars['Int']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type User_Carts_Onboarding_Stddev_Fields = {
  __typename?: 'user_carts_onboarding_stddev_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Stddev_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Carts_Onboarding_Stddev_Pop_Fields = {
  __typename?: 'user_carts_onboarding_stddev_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Stddev_Pop_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Carts_Onboarding_Stddev_Samp_Fields = {
  __typename?: 'user_carts_onboarding_stddev_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Stddev_Samp_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** Streaming cursor of the table "user_carts_onboarding" */
export type User_Carts_Onboarding_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Carts_Onboarding_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Carts_Onboarding_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  processed_at?: Maybe<Scalars['timestamptz']>
  processing?: Maybe<Scalars['Boolean']>
  sequential_id?: Maybe<Scalars['Int']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Carts_Onboarding_Sum_Fields = {
  __typename?: 'user_carts_onboarding_sum_fields'
  sequential_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Sum_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** update columns of table "user_carts_onboarding" */
export enum User_Carts_Onboarding_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProcessedAt = 'processed_at',
  /** column name */
  Processing = 'processing',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Carts_Onboarding_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Carts_Onboarding_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Carts_Onboarding_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Carts_Onboarding_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Carts_Onboarding_Var_Pop_Fields = {
  __typename?: 'user_carts_onboarding_var_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Var_Pop_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Carts_Onboarding_Var_Samp_Fields = {
  __typename?: 'user_carts_onboarding_var_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Var_Samp_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type User_Carts_Onboarding_Variance_Fields = {
  __typename?: 'user_carts_onboarding_variance_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Variance_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** columns and relationships of "user_category" */
export type User_Category = {
  __typename?: 'user_category'
  category: Shop_Category_Enum
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  shop_category: Shop_Category
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_category" */
export type User_Category_Aggregate = {
  __typename?: 'user_category_aggregate'
  aggregate?: Maybe<User_Category_Aggregate_Fields>
  nodes: Array<User_Category>
}

export type User_Category_Aggregate_Bool_Exp = {
  count?: Maybe<User_Category_Aggregate_Bool_Exp_Count>
}

export type User_Category_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Category_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Category_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_category" */
export type User_Category_Aggregate_Fields = {
  __typename?: 'user_category_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Category_Max_Fields>
  min?: Maybe<User_Category_Min_Fields>
}

/** aggregate fields of "user_category" */
export type User_Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Category_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_category" */
export type User_Category_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Category_Max_Order_By>
  min?: Maybe<User_Category_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_category" */
export type User_Category_Arr_Rel_Insert_Input = {
  data: Array<User_Category_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Category_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_category". All fields are combined with a logical 'AND'. */
export type User_Category_Bool_Exp = {
  _and?: Maybe<Array<User_Category_Bool_Exp>>
  _not?: Maybe<User_Category_Bool_Exp>
  _or?: Maybe<Array<User_Category_Bool_Exp>>
  category?: Maybe<Shop_Category_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  shop_category?: Maybe<Shop_Category_Bool_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_category" */
export enum User_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCategoryPkey = 'user_category_pkey',
  /** unique or primary key constraint on columns "user_id", "category" */
  UserCategoryUserIdCategoryKey = 'user_category_user_id_category_key',
}

/** input type for inserting data into table "user_category" */
export type User_Category_Insert_Input = {
  category?: Maybe<Shop_Category_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_category?: Maybe<Shop_Category_Obj_Rel_Insert_Input>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Category_Max_Fields = {
  __typename?: 'user_category_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_category" */
export type User_Category_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Category_Min_Fields = {
  __typename?: 'user_category_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_category" */
export type User_Category_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_category" */
export type User_Category_Mutation_Response = {
  __typename?: 'user_category_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Category>
}

/** on_conflict condition type for table "user_category" */
export type User_Category_On_Conflict = {
  constraint: User_Category_Constraint
  update_columns?: Array<User_Category_Update_Column>
  where?: Maybe<User_Category_Bool_Exp>
}

/** Ordering options when selecting data from "user_category". */
export type User_Category_Order_By = {
  category?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_category?: Maybe<Shop_Category_Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_category */
export type User_Category_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_category" */
export enum User_Category_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_category" */
export type User_Category_Set_Input = {
  category?: Maybe<Shop_Category_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_category" */
export type User_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Category_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Category_Stream_Cursor_Value_Input = {
  category?: Maybe<Shop_Category_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_category" */
export enum User_Category_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Category_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Category_Bool_Exp
}

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "phone" */
  UserPhoneKey = 'user_phone_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint on columns "short_id" */
  UserShortIdKey = 'user_short_id_key',
  /** unique or primary key constraint on columns "username" */
  UserUsernameKey = 'user_username_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** Table to keep track of account deletions */
export type User_Deletion = {
  __typename?: 'user_deletion'
  created_at: Scalars['timestamptz']
  metadata: Scalars['jsonb']
  reason?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** Table to keep track of account deletions */
export type User_DeletionMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_deletion" */
export type User_Deletion_Aggregate = {
  __typename?: 'user_deletion_aggregate'
  aggregate?: Maybe<User_Deletion_Aggregate_Fields>
  nodes: Array<User_Deletion>
}

/** aggregate fields of "user_deletion" */
export type User_Deletion_Aggregate_Fields = {
  __typename?: 'user_deletion_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Deletion_Max_Fields>
  min?: Maybe<User_Deletion_Min_Fields>
}

/** aggregate fields of "user_deletion" */
export type User_Deletion_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Deletion_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Deletion_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "user_deletion". All fields are combined with a logical 'AND'. */
export type User_Deletion_Bool_Exp = {
  _and?: Maybe<Array<User_Deletion_Bool_Exp>>
  _not?: Maybe<User_Deletion_Bool_Exp>
  _or?: Maybe<Array<User_Deletion_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  reason?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_deletion" */
export enum User_Deletion_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserDeletedPkey = 'user_deleted_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Deletion_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Deletion_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Deletion_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "user_deletion" */
export type User_Deletion_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  metadata?: Maybe<Scalars['jsonb']>
  reason?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Deletion_Max_Fields = {
  __typename?: 'user_deletion_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  reason?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Deletion_Min_Fields = {
  __typename?: 'user_deletion_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  reason?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_deletion" */
export type User_Deletion_Mutation_Response = {
  __typename?: 'user_deletion_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Deletion>
}

/** on_conflict condition type for table "user_deletion" */
export type User_Deletion_On_Conflict = {
  constraint: User_Deletion_Constraint
  update_columns?: Array<User_Deletion_Update_Column>
  where?: Maybe<User_Deletion_Bool_Exp>
}

/** Ordering options when selecting data from "user_deletion". */
export type User_Deletion_Order_By = {
  created_at?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  reason?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_deletion */
export type User_Deletion_Pk_Columns_Input = {
  user_id: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Deletion_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "user_deletion" */
export enum User_Deletion_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_deletion" */
export type User_Deletion_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  metadata?: Maybe<Scalars['jsonb']>
  reason?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_deletion" */
export type User_Deletion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Deletion_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Deletion_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  metadata?: Maybe<Scalars['jsonb']>
  reason?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_deletion" */
export enum User_Deletion_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Deletion_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Deletion_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Deletion_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Deletion_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Deletion_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Deletion_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Deletion_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Deletion_Bool_Exp
}

/** columns and relationships of "user_feedback" */
export type User_Feedback = {
  __typename?: 'user_feedback'
  created_at: Scalars['timestamptz']
  custom: Scalars['String']
  id: Scalars['uuid']
  reaction: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  /** An object relationship */
  user_feedback_item: User_Feedback_Item
  user_feedback_item_id: Scalars['uuid']
  user_feedback_type: User_Feedback_Type_Enum
  user_id: Scalars['String']
}

/** aggregated selection of "user_feedback" */
export type User_Feedback_Aggregate = {
  __typename?: 'user_feedback_aggregate'
  aggregate?: Maybe<User_Feedback_Aggregate_Fields>
  nodes: Array<User_Feedback>
}

/** aggregate fields of "user_feedback" */
export type User_Feedback_Aggregate_Fields = {
  __typename?: 'user_feedback_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Feedback_Max_Fields>
  min?: Maybe<User_Feedback_Min_Fields>
}

/** aggregate fields of "user_feedback" */
export type User_Feedback_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Feedback_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_feedback". All fields are combined with a logical 'AND'. */
export type User_Feedback_Bool_Exp = {
  _and?: Maybe<Array<User_Feedback_Bool_Exp>>
  _not?: Maybe<User_Feedback_Bool_Exp>
  _or?: Maybe<Array<User_Feedback_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  custom?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  reaction?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_feedback_item?: Maybe<User_Feedback_Item_Bool_Exp>
  user_feedback_item_id?: Maybe<Uuid_Comparison_Exp>
  user_feedback_type?: Maybe<User_Feedback_Type_Enum_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_feedback" */
export enum User_Feedback_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserFeedbackPkey = 'user_feedback_pkey',
  /** unique or primary key constraint on columns "user_id", "user_feedback_item_id" */
  UserFeedbackUserFeedbackItemIdUserIdKey = 'user_feedback_user_feedback_item_id_user_id_key',
}

/** input type for inserting data into table "user_feedback" */
export type User_Feedback_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  custom?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reaction?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_feedback_item?: Maybe<User_Feedback_Item_Obj_Rel_Insert_Input>
  user_feedback_item_id?: Maybe<Scalars['uuid']>
  user_feedback_type?: Maybe<User_Feedback_Type_Enum>
  user_id?: Maybe<Scalars['String']>
}

/** A table to keep track of all the allowable polymorphic user_feedback items */
export type User_Feedback_Item = {
  __typename?: 'user_feedback_item'
  item_id: Scalars['uuid']
  item_type: User_Feedback_Type_Enum
  /** An object relationship */
  similar_product_history?: Maybe<Similar_Products_History>
}

/** aggregated selection of "user_feedback_item" */
export type User_Feedback_Item_Aggregate = {
  __typename?: 'user_feedback_item_aggregate'
  aggregate?: Maybe<User_Feedback_Item_Aggregate_Fields>
  nodes: Array<User_Feedback_Item>
}

/** aggregate fields of "user_feedback_item" */
export type User_Feedback_Item_Aggregate_Fields = {
  __typename?: 'user_feedback_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Feedback_Item_Max_Fields>
  min?: Maybe<User_Feedback_Item_Min_Fields>
}

/** aggregate fields of "user_feedback_item" */
export type User_Feedback_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Feedback_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_feedback_item". All fields are combined with a logical 'AND'. */
export type User_Feedback_Item_Bool_Exp = {
  _and?: Maybe<Array<User_Feedback_Item_Bool_Exp>>
  _not?: Maybe<User_Feedback_Item_Bool_Exp>
  _or?: Maybe<Array<User_Feedback_Item_Bool_Exp>>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<User_Feedback_Type_Enum_Comparison_Exp>
  similar_product_history?: Maybe<Similar_Products_History_Bool_Exp>
}

/** unique or primary key constraints on table "user_feedback_item" */
export enum User_Feedback_Item_Constraint {
  /** unique or primary key constraint on columns "item_id", "item_type" */
  UserFeedbackItemItemIdItemTypeKey = 'user_feedback_item_item_id_item_type_key',
  /** unique or primary key constraint on columns "item_id" */
  UserFeedbackItemItemIdKey = 'user_feedback_item_item_id_key',
  /** unique or primary key constraint on columns "item_id", "item_type" */
  UserFeedbackItemPkey = 'user_feedback_item_pkey',
}

/** input type for inserting data into table "user_feedback_item" */
export type User_Feedback_Item_Insert_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<User_Feedback_Type_Enum>
  similar_product_history?: Maybe<Similar_Products_History_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type User_Feedback_Item_Max_Fields = {
  __typename?: 'user_feedback_item_max_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type User_Feedback_Item_Min_Fields = {
  __typename?: 'user_feedback_item_min_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "user_feedback_item" */
export type User_Feedback_Item_Mutation_Response = {
  __typename?: 'user_feedback_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Feedback_Item>
}

/** input type for inserting object relation for remote table "user_feedback_item" */
export type User_Feedback_Item_Obj_Rel_Insert_Input = {
  data: User_Feedback_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Feedback_Item_On_Conflict>
}

/** on_conflict condition type for table "user_feedback_item" */
export type User_Feedback_Item_On_Conflict = {
  constraint: User_Feedback_Item_Constraint
  update_columns?: Array<User_Feedback_Item_Update_Column>
  where?: Maybe<User_Feedback_Item_Bool_Exp>
}

/** Ordering options when selecting data from "user_feedback_item". */
export type User_Feedback_Item_Order_By = {
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  similar_product_history?: Maybe<Similar_Products_History_Order_By>
}

/** primary key columns input for table: user_feedback_item */
export type User_Feedback_Item_Pk_Columns_Input = {
  item_id: Scalars['uuid']
  item_type: User_Feedback_Type_Enum
}

/** select columns of table "user_feedback_item" */
export enum User_Feedback_Item_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

/** input type for updating data in table "user_feedback_item" */
export type User_Feedback_Item_Set_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<User_Feedback_Type_Enum>
}

/** Streaming cursor of the table "user_feedback_item" */
export type User_Feedback_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Feedback_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Feedback_Item_Stream_Cursor_Value_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<User_Feedback_Type_Enum>
}

/** update columns of table "user_feedback_item" */
export enum User_Feedback_Item_Update_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

export type User_Feedback_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Feedback_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Feedback_Item_Bool_Exp
}

/** aggregate max on columns */
export type User_Feedback_Max_Fields = {
  __typename?: 'user_feedback_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  custom?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_feedback_item_id?: Maybe<Scalars['uuid']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Feedback_Min_Fields = {
  __typename?: 'user_feedback_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  custom?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_feedback_item_id?: Maybe<Scalars['uuid']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_feedback" */
export type User_Feedback_Mutation_Response = {
  __typename?: 'user_feedback_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Feedback>
}

/** on_conflict condition type for table "user_feedback" */
export type User_Feedback_On_Conflict = {
  constraint: User_Feedback_Constraint
  update_columns?: Array<User_Feedback_Update_Column>
  where?: Maybe<User_Feedback_Bool_Exp>
}

/** Ordering options when selecting data from "user_feedback". */
export type User_Feedback_Order_By = {
  created_at?: Maybe<Order_By>
  custom?: Maybe<Order_By>
  id?: Maybe<Order_By>
  reaction?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_feedback_item?: Maybe<User_Feedback_Item_Order_By>
  user_feedback_item_id?: Maybe<Order_By>
  user_feedback_type?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_feedback */
export type User_Feedback_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_feedback" */
export enum User_Feedback_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Custom = 'custom',
  /** column name */
  Id = 'id',
  /** column name */
  Reaction = 'reaction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserFeedbackItemId = 'user_feedback_item_id',
  /** column name */
  UserFeedbackType = 'user_feedback_type',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_feedback" */
export type User_Feedback_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  custom?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reaction?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_feedback_item_id?: Maybe<Scalars['uuid']>
  user_feedback_type?: Maybe<User_Feedback_Type_Enum>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_feedback" */
export type User_Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Feedback_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Feedback_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  custom?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reaction?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_feedback_item_id?: Maybe<Scalars['uuid']>
  user_feedback_type?: Maybe<User_Feedback_Type_Enum>
  user_id?: Maybe<Scalars['String']>
}

/** An enum table to hold the different user feedback item types */
export type User_Feedback_Type = {
  __typename?: 'user_feedback_type'
  value: Scalars['String']
}

/** aggregated selection of "user_feedback_type" */
export type User_Feedback_Type_Aggregate = {
  __typename?: 'user_feedback_type_aggregate'
  aggregate?: Maybe<User_Feedback_Type_Aggregate_Fields>
  nodes: Array<User_Feedback_Type>
}

/** aggregate fields of "user_feedback_type" */
export type User_Feedback_Type_Aggregate_Fields = {
  __typename?: 'user_feedback_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Feedback_Type_Max_Fields>
  min?: Maybe<User_Feedback_Type_Min_Fields>
}

/** aggregate fields of "user_feedback_type" */
export type User_Feedback_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Feedback_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_feedback_type". All fields are combined with a logical 'AND'. */
export type User_Feedback_Type_Bool_Exp = {
  _and?: Maybe<Array<User_Feedback_Type_Bool_Exp>>
  _not?: Maybe<User_Feedback_Type_Bool_Exp>
  _or?: Maybe<Array<User_Feedback_Type_Bool_Exp>>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_feedback_type" */
export enum User_Feedback_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserFeedbackTypePkey = 'user_feedback_type_pkey',
}

export enum User_Feedback_Type_Enum {
  SimilarProducts = 'similar_products',
}

/** Boolean expression to compare columns of type "user_feedback_type_enum". All fields are combined with logical 'AND'. */
export type User_Feedback_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Feedback_Type_Enum>
  _in?: Maybe<Array<User_Feedback_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<User_Feedback_Type_Enum>
  _nin?: Maybe<Array<User_Feedback_Type_Enum>>
}

/** input type for inserting data into table "user_feedback_type" */
export type User_Feedback_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Feedback_Type_Max_Fields = {
  __typename?: 'user_feedback_type_max_fields'
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Feedback_Type_Min_Fields = {
  __typename?: 'user_feedback_type_min_fields'
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_feedback_type" */
export type User_Feedback_Type_Mutation_Response = {
  __typename?: 'user_feedback_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Feedback_Type>
}

/** on_conflict condition type for table "user_feedback_type" */
export type User_Feedback_Type_On_Conflict = {
  constraint: User_Feedback_Type_Constraint
  update_columns?: Array<User_Feedback_Type_Update_Column>
  where?: Maybe<User_Feedback_Type_Bool_Exp>
}

/** Ordering options when selecting data from "user_feedback_type". */
export type User_Feedback_Type_Order_By = {
  value?: Maybe<Order_By>
}

/** primary key columns input for table: user_feedback_type */
export type User_Feedback_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "user_feedback_type" */
export enum User_Feedback_Type_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "user_feedback_type" */
export type User_Feedback_Type_Set_Input = {
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_feedback_type" */
export type User_Feedback_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Feedback_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Feedback_Type_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>
}

/** update columns of table "user_feedback_type" */
export enum User_Feedback_Type_Update_Column {
  /** column name */
  Value = 'value',
}

export type User_Feedback_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Feedback_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Feedback_Type_Bool_Exp
}

/** update columns of table "user_feedback" */
export enum User_Feedback_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Custom = 'custom',
  /** column name */
  Id = 'id',
  /** column name */
  Reaction = 'reaction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserFeedbackItemId = 'user_feedback_item_id',
  /** column name */
  UserFeedbackType = 'user_feedback_type',
  /** column name */
  UserId = 'user_id',
}

export type User_Feedback_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Feedback_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Feedback_Bool_Exp
}

/** columns and relationships of "user_flags" */
export type User_Flags = {
  __typename?: 'user_flags'
  auto_cashback_enabled?: Maybe<Scalars['Boolean']>
  can_be_impersonated: Scalars['Boolean']
  checked_history: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  discovery_feed_sequential_id_last_set_at?: Maybe<Scalars['timestamptz']>
  features?: Maybe<Scalars['jsonb']>
  granted_full_host_permissions_current?: Maybe<Scalars['Boolean']>
  granted_full_host_permissions_ever?: Maybe<Scalars['Boolean']>
  installed_safari_extension?: Maybe<Scalars['Boolean']>
  ios_device_version?: Maybe<Scalars['String']>
  last_cart_onboarding?: Maybe<Scalars['timestamptz']>
  last_safari_extension_permission_check?: Maybe<Scalars['timestamptz']>
  last_seen_cart_items_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_sequential_id: Scalars['Int']
  onboarding_state?: Maybe<User_Onboarding_State_Enum>
  opted_into_cart_onboarding: Scalars['Boolean']
  texting_waitlist: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id: Scalars['String']
  /** An object relationship */
  user_onboarding_state?: Maybe<User_Onboarding_State>
}

/** columns and relationships of "user_flags" */
export type User_FlagsDeal_Hop_InvitesArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "user_flags" */
export type User_FlagsFeaturesArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_flags" */
export type User_Flags_Aggregate = {
  __typename?: 'user_flags_aggregate'
  aggregate?: Maybe<User_Flags_Aggregate_Fields>
  nodes: Array<User_Flags>
}

/** aggregate fields of "user_flags" */
export type User_Flags_Aggregate_Fields = {
  __typename?: 'user_flags_aggregate_fields'
  avg?: Maybe<User_Flags_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Flags_Max_Fields>
  min?: Maybe<User_Flags_Min_Fields>
  stddev?: Maybe<User_Flags_Stddev_Fields>
  stddev_pop?: Maybe<User_Flags_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Flags_Stddev_Samp_Fields>
  sum?: Maybe<User_Flags_Sum_Fields>
  var_pop?: Maybe<User_Flags_Var_Pop_Fields>
  var_samp?: Maybe<User_Flags_Var_Samp_Fields>
  variance?: Maybe<User_Flags_Variance_Fields>
}

/** aggregate fields of "user_flags" */
export type User_Flags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Flags_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Flags_Append_Input = {
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  features?: Maybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type User_Flags_Avg_Fields = {
  __typename?: 'user_flags_avg_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "user_flags". All fields are combined with a logical 'AND'. */
export type User_Flags_Bool_Exp = {
  _and?: Maybe<Array<User_Flags_Bool_Exp>>
  _not?: Maybe<User_Flags_Bool_Exp>
  _or?: Maybe<Array<User_Flags_Bool_Exp>>
  auto_cashback_enabled?: Maybe<Boolean_Comparison_Exp>
  can_be_impersonated?: Maybe<Boolean_Comparison_Exp>
  checked_history?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_gate_pending?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_gated?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_invited?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_invites?: Maybe<Jsonb_Comparison_Exp>
  deal_hop_unlocked?: Maybe<Boolean_Comparison_Exp>
  discovery_feed_sequential_id?: Maybe<Int_Comparison_Exp>
  discovery_feed_sequential_id_last_set_at?: Maybe<Timestamptz_Comparison_Exp>
  features?: Maybe<Jsonb_Comparison_Exp>
  granted_full_host_permissions_current?: Maybe<Boolean_Comparison_Exp>
  granted_full_host_permissions_ever?: Maybe<Boolean_Comparison_Exp>
  installed_safari_extension?: Maybe<Boolean_Comparison_Exp>
  ios_device_version?: Maybe<String_Comparison_Exp>
  last_cart_onboarding?: Maybe<Timestamptz_Comparison_Exp>
  last_safari_extension_permission_check?: Maybe<Timestamptz_Comparison_Exp>
  last_seen_cart_items_at?: Maybe<Timestamptz_Comparison_Exp>
  notification_center_last_viewed_at?: Maybe<Timestamptz_Comparison_Exp>
  notification_center_last_viewed_sequential_id?: Maybe<Int_Comparison_Exp>
  onboarding_state?: Maybe<User_Onboarding_State_Enum_Comparison_Exp>
  opted_into_cart_onboarding?: Maybe<Boolean_Comparison_Exp>
  texting_waitlist?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  user_onboarding_state?: Maybe<User_Onboarding_State_Bool_Exp>
}

/** unique or primary key constraints on table "user_flags" */
export enum User_Flags_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserFlagsPkey = 'user_flags_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Flags_Delete_At_Path_Input = {
  deal_hop_invites?: Maybe<Array<Scalars['String']>>
  features?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Flags_Delete_Elem_Input = {
  deal_hop_invites?: Maybe<Scalars['Int']>
  features?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Flags_Delete_Key_Input = {
  deal_hop_invites?: Maybe<Scalars['String']>
  features?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "user_flags" */
export type User_Flags_Inc_Input = {
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "user_flags" */
export type User_Flags_Insert_Input = {
  auto_cashback_enabled?: Maybe<Scalars['Boolean']>
  can_be_impersonated?: Maybe<Scalars['Boolean']>
  checked_history?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  discovery_feed_sequential_id_last_set_at?: Maybe<Scalars['timestamptz']>
  features?: Maybe<Scalars['jsonb']>
  granted_full_host_permissions_current?: Maybe<Scalars['Boolean']>
  granted_full_host_permissions_ever?: Maybe<Scalars['Boolean']>
  installed_safari_extension?: Maybe<Scalars['Boolean']>
  ios_device_version?: Maybe<Scalars['String']>
  last_cart_onboarding?: Maybe<Scalars['timestamptz']>
  last_safari_extension_permission_check?: Maybe<Scalars['timestamptz']>
  last_seen_cart_items_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
  onboarding_state?: Maybe<User_Onboarding_State_Enum>
  opted_into_cart_onboarding?: Maybe<Scalars['Boolean']>
  texting_waitlist?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  user_onboarding_state?: Maybe<User_Onboarding_State_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type User_Flags_Max_Fields = {
  __typename?: 'user_flags_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  discovery_feed_sequential_id_last_set_at?: Maybe<Scalars['timestamptz']>
  ios_device_version?: Maybe<Scalars['String']>
  last_cart_onboarding?: Maybe<Scalars['timestamptz']>
  last_safari_extension_permission_check?: Maybe<Scalars['timestamptz']>
  last_seen_cart_items_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Flags_Min_Fields = {
  __typename?: 'user_flags_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  discovery_feed_sequential_id_last_set_at?: Maybe<Scalars['timestamptz']>
  ios_device_version?: Maybe<Scalars['String']>
  last_cart_onboarding?: Maybe<Scalars['timestamptz']>
  last_safari_extension_permission_check?: Maybe<Scalars['timestamptz']>
  last_seen_cart_items_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_flags" */
export type User_Flags_Mutation_Response = {
  __typename?: 'user_flags_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Flags>
}

/** input type for inserting object relation for remote table "user_flags" */
export type User_Flags_Obj_Rel_Insert_Input = {
  data: User_Flags_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Flags_On_Conflict>
}

/** on_conflict condition type for table "user_flags" */
export type User_Flags_On_Conflict = {
  constraint: User_Flags_Constraint
  update_columns?: Array<User_Flags_Update_Column>
  where?: Maybe<User_Flags_Bool_Exp>
}

/** Ordering options when selecting data from "user_flags". */
export type User_Flags_Order_By = {
  auto_cashback_enabled?: Maybe<Order_By>
  can_be_impersonated?: Maybe<Order_By>
  checked_history?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deal_hop_gate_pending?: Maybe<Order_By>
  deal_hop_gated?: Maybe<Order_By>
  deal_hop_invited?: Maybe<Order_By>
  deal_hop_invites?: Maybe<Order_By>
  deal_hop_unlocked?: Maybe<Order_By>
  discovery_feed_sequential_id?: Maybe<Order_By>
  discovery_feed_sequential_id_last_set_at?: Maybe<Order_By>
  features?: Maybe<Order_By>
  granted_full_host_permissions_current?: Maybe<Order_By>
  granted_full_host_permissions_ever?: Maybe<Order_By>
  installed_safari_extension?: Maybe<Order_By>
  ios_device_version?: Maybe<Order_By>
  last_cart_onboarding?: Maybe<Order_By>
  last_safari_extension_permission_check?: Maybe<Order_By>
  last_seen_cart_items_at?: Maybe<Order_By>
  notification_center_last_viewed_at?: Maybe<Order_By>
  notification_center_last_viewed_sequential_id?: Maybe<Order_By>
  onboarding_state?: Maybe<Order_By>
  opted_into_cart_onboarding?: Maybe<Order_By>
  texting_waitlist?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  user_onboarding_state?: Maybe<User_Onboarding_State_Order_By>
}

/** primary key columns input for table: user_flags */
export type User_Flags_Pk_Columns_Input = {
  user_id: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Flags_Prepend_Input = {
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  features?: Maybe<Scalars['jsonb']>
}

/** select columns of table "user_flags" */
export enum User_Flags_Select_Column {
  /** column name */
  AutoCashbackEnabled = 'auto_cashback_enabled',
  /** column name */
  CanBeImpersonated = 'can_be_impersonated',
  /** column name */
  CheckedHistory = 'checked_history',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealHopGatePending = 'deal_hop_gate_pending',
  /** column name */
  DealHopGated = 'deal_hop_gated',
  /** column name */
  DealHopInvited = 'deal_hop_invited',
  /** column name */
  DealHopInvites = 'deal_hop_invites',
  /** column name */
  DealHopUnlocked = 'deal_hop_unlocked',
  /** column name */
  DiscoveryFeedSequentialId = 'discovery_feed_sequential_id',
  /** column name */
  DiscoveryFeedSequentialIdLastSetAt = 'discovery_feed_sequential_id_last_set_at',
  /** column name */
  Features = 'features',
  /** column name */
  GrantedFullHostPermissionsCurrent = 'granted_full_host_permissions_current',
  /** column name */
  GrantedFullHostPermissionsEver = 'granted_full_host_permissions_ever',
  /** column name */
  InstalledSafariExtension = 'installed_safari_extension',
  /** column name */
  IosDeviceVersion = 'ios_device_version',
  /** column name */
  LastCartOnboarding = 'last_cart_onboarding',
  /** column name */
  LastSafariExtensionPermissionCheck = 'last_safari_extension_permission_check',
  /** column name */
  LastSeenCartItemsAt = 'last_seen_cart_items_at',
  /** column name */
  NotificationCenterLastViewedAt = 'notification_center_last_viewed_at',
  /** column name */
  NotificationCenterLastViewedSequentialId = 'notification_center_last_viewed_sequential_id',
  /** column name */
  OnboardingState = 'onboarding_state',
  /** column name */
  OptedIntoCartOnboarding = 'opted_into_cart_onboarding',
  /** column name */
  TextingWaitlist = 'texting_waitlist',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_flags" */
export type User_Flags_Set_Input = {
  auto_cashback_enabled?: Maybe<Scalars['Boolean']>
  can_be_impersonated?: Maybe<Scalars['Boolean']>
  checked_history?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  discovery_feed_sequential_id_last_set_at?: Maybe<Scalars['timestamptz']>
  features?: Maybe<Scalars['jsonb']>
  granted_full_host_permissions_current?: Maybe<Scalars['Boolean']>
  granted_full_host_permissions_ever?: Maybe<Scalars['Boolean']>
  installed_safari_extension?: Maybe<Scalars['Boolean']>
  ios_device_version?: Maybe<Scalars['String']>
  last_cart_onboarding?: Maybe<Scalars['timestamptz']>
  last_safari_extension_permission_check?: Maybe<Scalars['timestamptz']>
  last_seen_cart_items_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
  onboarding_state?: Maybe<User_Onboarding_State_Enum>
  opted_into_cart_onboarding?: Maybe<Scalars['Boolean']>
  texting_waitlist?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type User_Flags_Stddev_Fields = {
  __typename?: 'user_flags_stddev_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type User_Flags_Stddev_Pop_Fields = {
  __typename?: 'user_flags_stddev_pop_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type User_Flags_Stddev_Samp_Fields = {
  __typename?: 'user_flags_stddev_samp_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "user_flags" */
export type User_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Flags_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Flags_Stream_Cursor_Value_Input = {
  auto_cashback_enabled?: Maybe<Scalars['Boolean']>
  can_be_impersonated?: Maybe<Scalars['Boolean']>
  checked_history?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  discovery_feed_sequential_id_last_set_at?: Maybe<Scalars['timestamptz']>
  features?: Maybe<Scalars['jsonb']>
  granted_full_host_permissions_current?: Maybe<Scalars['Boolean']>
  granted_full_host_permissions_ever?: Maybe<Scalars['Boolean']>
  installed_safari_extension?: Maybe<Scalars['Boolean']>
  ios_device_version?: Maybe<Scalars['String']>
  last_cart_onboarding?: Maybe<Scalars['timestamptz']>
  last_safari_extension_permission_check?: Maybe<Scalars['timestamptz']>
  last_seen_cart_items_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
  onboarding_state?: Maybe<User_Onboarding_State_Enum>
  opted_into_cart_onboarding?: Maybe<Scalars['Boolean']>
  texting_waitlist?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Flags_Sum_Fields = {
  __typename?: 'user_flags_sum_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
}

/** update columns of table "user_flags" */
export enum User_Flags_Update_Column {
  /** column name */
  AutoCashbackEnabled = 'auto_cashback_enabled',
  /** column name */
  CanBeImpersonated = 'can_be_impersonated',
  /** column name */
  CheckedHistory = 'checked_history',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealHopGatePending = 'deal_hop_gate_pending',
  /** column name */
  DealHopGated = 'deal_hop_gated',
  /** column name */
  DealHopInvited = 'deal_hop_invited',
  /** column name */
  DealHopInvites = 'deal_hop_invites',
  /** column name */
  DealHopUnlocked = 'deal_hop_unlocked',
  /** column name */
  DiscoveryFeedSequentialId = 'discovery_feed_sequential_id',
  /** column name */
  DiscoveryFeedSequentialIdLastSetAt = 'discovery_feed_sequential_id_last_set_at',
  /** column name */
  Features = 'features',
  /** column name */
  GrantedFullHostPermissionsCurrent = 'granted_full_host_permissions_current',
  /** column name */
  GrantedFullHostPermissionsEver = 'granted_full_host_permissions_ever',
  /** column name */
  InstalledSafariExtension = 'installed_safari_extension',
  /** column name */
  IosDeviceVersion = 'ios_device_version',
  /** column name */
  LastCartOnboarding = 'last_cart_onboarding',
  /** column name */
  LastSafariExtensionPermissionCheck = 'last_safari_extension_permission_check',
  /** column name */
  LastSeenCartItemsAt = 'last_seen_cart_items_at',
  /** column name */
  NotificationCenterLastViewedAt = 'notification_center_last_viewed_at',
  /** column name */
  NotificationCenterLastViewedSequentialId = 'notification_center_last_viewed_sequential_id',
  /** column name */
  OnboardingState = 'onboarding_state',
  /** column name */
  OptedIntoCartOnboarding = 'opted_into_cart_onboarding',
  /** column name */
  TextingWaitlist = 'texting_waitlist',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Flags_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Flags_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Flags_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Flags_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Flags_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Flags_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Flags_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Flags_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Flags_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Flags_Var_Pop_Fields = {
  __typename?: 'user_flags_var_pop_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type User_Flags_Var_Samp_Fields = {
  __typename?: 'user_flags_var_samp_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type User_Flags_Variance_Fields = {
  __typename?: 'user_flags_variance_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** Internal audit table to keep track of users being impersonated */
export type User_Impersonation = {
  __typename?: 'user_impersonation'
  /** An object relationship */
  admin_user: User
  admin_user_id: Scalars['String']
  created_at: Scalars['timestamptz']
  deleted_user_id?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  reason?: Maybe<Scalars['String']>
  signed_out_at?: Maybe<Scalars['timestamptz']>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user_being_impersonated?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_impersonation" */
export type User_Impersonation_Aggregate = {
  __typename?: 'user_impersonation_aggregate'
  aggregate?: Maybe<User_Impersonation_Aggregate_Fields>
  nodes: Array<User_Impersonation>
}

/** aggregate fields of "user_impersonation" */
export type User_Impersonation_Aggregate_Fields = {
  __typename?: 'user_impersonation_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Impersonation_Max_Fields>
  min?: Maybe<User_Impersonation_Min_Fields>
}

/** aggregate fields of "user_impersonation" */
export type User_Impersonation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Impersonation_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_impersonation". All fields are combined with a logical 'AND'. */
export type User_Impersonation_Bool_Exp = {
  _and?: Maybe<Array<User_Impersonation_Bool_Exp>>
  _not?: Maybe<User_Impersonation_Bool_Exp>
  _or?: Maybe<Array<User_Impersonation_Bool_Exp>>
  admin_user?: Maybe<User_Bool_Exp>
  admin_user_id?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deleted_user_id?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  reason?: Maybe<String_Comparison_Exp>
  signed_out_at?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_being_impersonated?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_impersonation" */
export enum User_Impersonation_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserImpersonationPkey = 'user_impersonation_pkey',
}

/** input type for inserting data into table "user_impersonation" */
export type User_Impersonation_Insert_Input = {
  admin_user?: Maybe<User_Obj_Rel_Insert_Input>
  admin_user_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_user_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reason?: Maybe<Scalars['String']>
  signed_out_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_being_impersonated?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Impersonation_Max_Fields = {
  __typename?: 'user_impersonation_max_fields'
  admin_user_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_user_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reason?: Maybe<Scalars['String']>
  signed_out_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Impersonation_Min_Fields = {
  __typename?: 'user_impersonation_min_fields'
  admin_user_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_user_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reason?: Maybe<Scalars['String']>
  signed_out_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_impersonation" */
export type User_Impersonation_Mutation_Response = {
  __typename?: 'user_impersonation_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Impersonation>
}

/** on_conflict condition type for table "user_impersonation" */
export type User_Impersonation_On_Conflict = {
  constraint: User_Impersonation_Constraint
  update_columns?: Array<User_Impersonation_Update_Column>
  where?: Maybe<User_Impersonation_Bool_Exp>
}

/** Ordering options when selecting data from "user_impersonation". */
export type User_Impersonation_Order_By = {
  admin_user?: Maybe<User_Order_By>
  admin_user_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_user_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  reason?: Maybe<Order_By>
  signed_out_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_being_impersonated?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_impersonation */
export type User_Impersonation_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_impersonation" */
export enum User_Impersonation_Select_Column {
  /** column name */
  AdminUserId = 'admin_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedUserId = 'deleted_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  SignedOutAt = 'signed_out_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_impersonation" */
export type User_Impersonation_Set_Input = {
  admin_user_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_user_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reason?: Maybe<Scalars['String']>
  signed_out_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_impersonation" */
export type User_Impersonation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Impersonation_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Impersonation_Stream_Cursor_Value_Input = {
  admin_user_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_user_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reason?: Maybe<Scalars['String']>
  signed_out_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_impersonation" */
export enum User_Impersonation_Update_Column {
  /** column name */
  AdminUserId = 'admin_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedUserId = 'deleted_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  SignedOutAt = 'signed_out_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Impersonation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Impersonation_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Impersonation_Bool_Exp
}

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  icon_order?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  affiliate_transactions?: Maybe<Affiliate_Transaction_Arr_Rel_Insert_Input>
  anonymous?: Maybe<Scalars['Boolean']>
  app_versions?: Maybe<User_App_Version_Arr_Rel_Insert_Input>
  applied_as_creator_at?: Maybe<Scalars['timestamptz']>
  bio?: Maybe<Scalars['String']>
  bio_link?: Maybe<Scalars['String']>
  cart_item_likes?: Maybe<Cart_Item_Like_Arr_Rel_Insert_Input>
  carts?: Maybe<Cart_Arr_Rel_Insert_Input>
  collection_item_claims?: Maybe<Collection_Item_Claim_Arr_Rel_Insert_Input>
  collections?: Maybe<Collection_Arr_Rel_Insert_Input>
  collections_collaborating?: Maybe<Collection_Collaborator_Arr_Rel_Insert_Input>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  deleted_reason?: Maybe<Scalars['String']>
  disabled_at?: Maybe<Scalars['timestamptz']>
  disabled_reason?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  flags?: Maybe<User_Flags_Obj_Rel_Insert_Input>
  follows?: Maybe<User_Items_Followed_Arr_Rel_Insert_Input>
  has_premium_subscription?: Maybe<Scalars['Boolean']>
  icon_order?: Maybe<Scalars['numeric']>
  id?: Maybe<Scalars['String']>
  installed_at?: Maybe<Scalars['timestamptz']>
  internal_account?: Maybe<Scalars['Boolean']>
  links?: Maybe<User_Links_Arr_Rel_Insert_Input>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['String']>
  notification_center_items?: Maybe<Notification_Center_Item_Arr_Rel_Insert_Input>
  notification_preferences?: Maybe<Notification_Preference_Arr_Rel_Insert_Input>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  onboarded?: Maybe<Scalars['Boolean']>
  orphan_parent?: Maybe<User_Obj_Rel_Insert_Input>
  orphaned_at?: Maybe<Scalars['timestamptz']>
  orphaned_by?: Maybe<Scalars['String']>
  orphans?: Maybe<User_Arr_Rel_Insert_Input>
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  possible_email?: Maybe<Scalars['String']>
  referred_users?: Maybe<User_Arr_Rel_Insert_Input>
  referrer_user?: Maybe<User_Obj_Rel_Insert_Input>
  referrer_user_id?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['name']>
  shopping_preference?: Maybe<User_Shopping_Preferences_Enum>
  short_id?: Maybe<Scalars['name']>
  show_curations?: Maybe<Scalars['Boolean']>
  show_onboarding?: Maybe<Scalars['Boolean']>
  show_rabbithole?: Maybe<Scalars['Boolean']>
  similar_products_histories?: Maybe<Similar_Products_History_Arr_Rel_Insert_Input>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_account_type?: Maybe<User_Account_Type_Obj_Rel_Insert_Input>
  user_carts_onboardings?: Maybe<User_Carts_Onboarding_Arr_Rel_Insert_Input>
  user_categories?: Maybe<User_Category_Arr_Rel_Insert_Input>
  user_ip_addresses?: Maybe<User_Ip_Address_Arr_Rel_Insert_Input>
  user_onboarding_sources?: Maybe<User_Onboarding_Source_Arr_Rel_Insert_Input>
  user_push_notification_tokens?: Maybe<User_Push_Notification_Token_Arr_Rel_Insert_Input>
  user_referrals?: Maybe<User_Referral_Arr_Rel_Insert_Input>
  user_shopping_preference?: Maybe<User_Shopping_Preferences_Obj_Rel_Insert_Input>
  user_type?: Maybe<User_Account_Type_Enum>
  username?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
  version?: Maybe<Scalars['String']>
  waitlist?: Maybe<Scalars['Boolean']>
  workflows?: Maybe<Workflow_Arr_Rel_Insert_Input>
}

/** To keep track of the ip addresses of users to help with debugging an issue */
export type User_Ip_Address = {
  __typename?: 'user_ip_address'
  analytics_anonymous_id?: Maybe<Scalars['uuid']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  ip_address: Scalars['cidr']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_ip_address" */
export type User_Ip_Address_Aggregate = {
  __typename?: 'user_ip_address_aggregate'
  aggregate?: Maybe<User_Ip_Address_Aggregate_Fields>
  nodes: Array<User_Ip_Address>
}

export type User_Ip_Address_Aggregate_Bool_Exp = {
  count?: Maybe<User_Ip_Address_Aggregate_Bool_Exp_Count>
}

export type User_Ip_Address_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Ip_Address_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Ip_Address_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_ip_address" */
export type User_Ip_Address_Aggregate_Fields = {
  __typename?: 'user_ip_address_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Ip_Address_Max_Fields>
  min?: Maybe<User_Ip_Address_Min_Fields>
}

/** aggregate fields of "user_ip_address" */
export type User_Ip_Address_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Ip_Address_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_ip_address" */
export type User_Ip_Address_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Ip_Address_Max_Order_By>
  min?: Maybe<User_Ip_Address_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_ip_address" */
export type User_Ip_Address_Arr_Rel_Insert_Input = {
  data: Array<User_Ip_Address_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Ip_Address_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_ip_address". All fields are combined with a logical 'AND'. */
export type User_Ip_Address_Bool_Exp = {
  _and?: Maybe<Array<User_Ip_Address_Bool_Exp>>
  _not?: Maybe<User_Ip_Address_Bool_Exp>
  _or?: Maybe<Array<User_Ip_Address_Bool_Exp>>
  analytics_anonymous_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  ip_address?: Maybe<Cidr_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_ip_address" */
export enum User_Ip_Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  IpAddressesPkey = 'ip_addresses_pkey',
  /** unique or primary key constraint on columns "ip_address", "user_id", "analytics_anonymous_id" */
  IpAddressesUserIdAnalyticsAnonymousIdIpAddressKey = 'ip_addresses_user_id_analytics_anonymous_id_ip_address_key',
}

/** input type for inserting data into table "user_ip_address" */
export type User_Ip_Address_Insert_Input = {
  analytics_anonymous_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  ip_address?: Maybe<Scalars['cidr']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Ip_Address_Max_Fields = {
  __typename?: 'user_ip_address_max_fields'
  analytics_anonymous_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_ip_address" */
export type User_Ip_Address_Max_Order_By = {
  analytics_anonymous_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Ip_Address_Min_Fields = {
  __typename?: 'user_ip_address_min_fields'
  analytics_anonymous_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_ip_address" */
export type User_Ip_Address_Min_Order_By = {
  analytics_anonymous_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_ip_address" */
export type User_Ip_Address_Mutation_Response = {
  __typename?: 'user_ip_address_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Ip_Address>
}

/** on_conflict condition type for table "user_ip_address" */
export type User_Ip_Address_On_Conflict = {
  constraint: User_Ip_Address_Constraint
  update_columns?: Array<User_Ip_Address_Update_Column>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

/** Ordering options when selecting data from "user_ip_address". */
export type User_Ip_Address_Order_By = {
  analytics_anonymous_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  ip_address?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_ip_address */
export type User_Ip_Address_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_ip_address" */
export enum User_Ip_Address_Select_Column {
  /** column name */
  AnalyticsAnonymousId = 'analytics_anonymous_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_ip_address" */
export type User_Ip_Address_Set_Input = {
  analytics_anonymous_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  ip_address?: Maybe<Scalars['cidr']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_ip_address" */
export type User_Ip_Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Ip_Address_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Ip_Address_Stream_Cursor_Value_Input = {
  analytics_anonymous_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  ip_address?: Maybe<Scalars['cidr']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_ip_address" */
export enum User_Ip_Address_Update_Column {
  /** column name */
  AnalyticsAnonymousId = 'analytics_anonymous_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Ip_Address_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Ip_Address_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Ip_Address_Bool_Exp
}

/** A table to track what items a user is following */
export type User_Items_Followed = {
  __typename?: 'user_items_followed'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  followable_item?: Maybe<Followable_Item>
  followable_item_id: Scalars['uuid']
  followable_item_type: Scalars['String']
  id: Scalars['uuid']
  notifiable_item_type: Notifiable_Item_Type_Enum
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_items_followed" */
export type User_Items_Followed_Aggregate = {
  __typename?: 'user_items_followed_aggregate'
  aggregate?: Maybe<User_Items_Followed_Aggregate_Fields>
  nodes: Array<User_Items_Followed>
}

export type User_Items_Followed_Aggregate_Bool_Exp = {
  count?: Maybe<User_Items_Followed_Aggregate_Bool_Exp_Count>
}

export type User_Items_Followed_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Items_Followed_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Items_Followed_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_items_followed" */
export type User_Items_Followed_Aggregate_Fields = {
  __typename?: 'user_items_followed_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Items_Followed_Max_Fields>
  min?: Maybe<User_Items_Followed_Min_Fields>
}

/** aggregate fields of "user_items_followed" */
export type User_Items_Followed_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Items_Followed_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_items_followed" */
export type User_Items_Followed_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Items_Followed_Max_Order_By>
  min?: Maybe<User_Items_Followed_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_items_followed" */
export type User_Items_Followed_Arr_Rel_Insert_Input = {
  data: Array<User_Items_Followed_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Items_Followed_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_items_followed". All fields are combined with a logical 'AND'. */
export type User_Items_Followed_Bool_Exp = {
  _and?: Maybe<Array<User_Items_Followed_Bool_Exp>>
  _not?: Maybe<User_Items_Followed_Bool_Exp>
  _or?: Maybe<Array<User_Items_Followed_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  followable_item?: Maybe<Followable_Item_Bool_Exp>
  followable_item_id?: Maybe<Uuid_Comparison_Exp>
  followable_item_type?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_items_followed" */
export enum User_Items_Followed_Constraint {
  /** unique or primary key constraint on columns "user_id", "followable_item_id", "followable_item_type" */
  UserFollowPkey = 'user_follow_pkey',
  /** unique or primary key constraint on columns "id" */
  UserItemsFollowedIdKey = 'user_items_followed_id_key',
}

/** input type for inserting data into table "user_items_followed" */
export type User_Items_Followed_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  followable_item?: Maybe<Followable_Item_Obj_Rel_Insert_Input>
  followable_item_id?: Maybe<Scalars['uuid']>
  followable_item_type?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Items_Followed_Max_Fields = {
  __typename?: 'user_items_followed_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  followable_item_id?: Maybe<Scalars['uuid']>
  followable_item_type?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_items_followed" */
export type User_Items_Followed_Max_Order_By = {
  created_at?: Maybe<Order_By>
  followable_item_id?: Maybe<Order_By>
  followable_item_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Items_Followed_Min_Fields = {
  __typename?: 'user_items_followed_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  followable_item_id?: Maybe<Scalars['uuid']>
  followable_item_type?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_items_followed" */
export type User_Items_Followed_Min_Order_By = {
  created_at?: Maybe<Order_By>
  followable_item_id?: Maybe<Order_By>
  followable_item_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_items_followed" */
export type User_Items_Followed_Mutation_Response = {
  __typename?: 'user_items_followed_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Items_Followed>
}

/** input type for inserting object relation for remote table "user_items_followed" */
export type User_Items_Followed_Obj_Rel_Insert_Input = {
  data: User_Items_Followed_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Items_Followed_On_Conflict>
}

/** on_conflict condition type for table "user_items_followed" */
export type User_Items_Followed_On_Conflict = {
  constraint: User_Items_Followed_Constraint
  update_columns?: Array<User_Items_Followed_Update_Column>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

/** Ordering options when selecting data from "user_items_followed". */
export type User_Items_Followed_Order_By = {
  created_at?: Maybe<Order_By>
  followable_item?: Maybe<Followable_Item_Order_By>
  followable_item_id?: Maybe<Order_By>
  followable_item_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_item_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_items_followed */
export type User_Items_Followed_Pk_Columns_Input = {
  followable_item_id: Scalars['uuid']
  followable_item_type: Scalars['String']
  user_id: Scalars['String']
}

/** select columns of table "user_items_followed" */
export enum User_Items_Followed_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FollowableItemId = 'followable_item_id',
  /** column name */
  FollowableItemType = 'followable_item_type',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_items_followed" */
export type User_Items_Followed_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  followable_item_id?: Maybe<Scalars['uuid']>
  followable_item_type?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_items_followed" */
export type User_Items_Followed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Items_Followed_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Items_Followed_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  followable_item_id?: Maybe<Scalars['uuid']>
  followable_item_type?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_items_followed" */
export enum User_Items_Followed_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FollowableItemId = 'followable_item_id',
  /** column name */
  FollowableItemType = 'followable_item_type',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Items_Followed_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Items_Followed_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Items_Followed_Bool_Exp
}

/** Used to capture user social links */
export type User_Links = {
  __typename?: 'user_links'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  link_type?: Maybe<Link_Type_Enum>
  /** An object relationship */
  type?: Maybe<Link_Type>
  updated_at: Scalars['timestamptz']
  url: Scalars['String']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_links" */
export type User_Links_Aggregate = {
  __typename?: 'user_links_aggregate'
  aggregate?: Maybe<User_Links_Aggregate_Fields>
  nodes: Array<User_Links>
}

export type User_Links_Aggregate_Bool_Exp = {
  count?: Maybe<User_Links_Aggregate_Bool_Exp_Count>
}

export type User_Links_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Links_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Links_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_links" */
export type User_Links_Aggregate_Fields = {
  __typename?: 'user_links_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Links_Max_Fields>
  min?: Maybe<User_Links_Min_Fields>
}

/** aggregate fields of "user_links" */
export type User_Links_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Links_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_links" */
export type User_Links_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Links_Max_Order_By>
  min?: Maybe<User_Links_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_links" */
export type User_Links_Arr_Rel_Insert_Input = {
  data: Array<User_Links_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Links_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_links". All fields are combined with a logical 'AND'. */
export type User_Links_Bool_Exp = {
  _and?: Maybe<Array<User_Links_Bool_Exp>>
  _not?: Maybe<User_Links_Bool_Exp>
  _or?: Maybe<Array<User_Links_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  link_type?: Maybe<Link_Type_Enum_Comparison_Exp>
  type?: Maybe<Link_Type_Bool_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  url?: Maybe<String_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_links" */
export enum User_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserLinksPkey = 'user_links_pkey',
  /** unique or primary key constraint on columns "user_id", "link_type" */
  UserLinksUserIdLinkTypeKey = 'user_links_user_id_link_type_key',
}

/** input type for inserting data into table "user_links" */
export type User_Links_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link_type?: Maybe<Link_Type_Enum>
  type?: Maybe<Link_Type_Obj_Rel_Insert_Input>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Links_Max_Fields = {
  __typename?: 'user_links_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_links" */
export type User_Links_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Links_Min_Fields = {
  __typename?: 'user_links_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_links" */
export type User_Links_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_links" */
export type User_Links_Mutation_Response = {
  __typename?: 'user_links_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Links>
}

/** on_conflict condition type for table "user_links" */
export type User_Links_On_Conflict = {
  constraint: User_Links_Constraint
  update_columns?: Array<User_Links_Update_Column>
  where?: Maybe<User_Links_Bool_Exp>
}

/** Ordering options when selecting data from "user_links". */
export type User_Links_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  link_type?: Maybe<Order_By>
  type?: Maybe<Link_Type_Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_links */
export type User_Links_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_links" */
export enum User_Links_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LinkType = 'link_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_links" */
export type User_Links_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link_type?: Maybe<Link_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_links" */
export type User_Links_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Links_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Links_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link_type?: Maybe<Link_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_links" */
export enum User_Links_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LinkType = 'link_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id',
}

export type User_Links_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Links_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Links_Bool_Exp
}

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  applied_as_creator_at?: Maybe<Scalars['timestamptz']>
  bio?: Maybe<Scalars['String']>
  bio_link?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  deleted_reason?: Maybe<Scalars['String']>
  disabled_at?: Maybe<Scalars['timestamptz']>
  disabled_reason?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_display_name" */
  display_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_email_md5_hash" */
  email_md5?: Maybe<Scalars['String']>
  /** A computed field, executes function "extension_version" */
  extension_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_first_app_install" */
  first_app_install?: Maybe<Scalars['String']>
  icon_order?: Maybe<Scalars['numeric']>
  id?: Maybe<Scalars['String']>
  installed_at?: Maybe<Scalars['timestamptz']>
  ios_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  orphaned_at?: Maybe<Scalars['timestamptz']>
  orphaned_by?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  possible_email?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  referrer_user_id?: Maybe<Scalars['String']>
  safari_extension_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_subscription_status" */
  subscription_status?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
  username?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  applied_as_creator_at?: Maybe<Order_By>
  bio?: Maybe<Order_By>
  bio_link?: Maybe<Order_By>
  cover_image?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  deleted_reason?: Maybe<Order_By>
  disabled_at?: Maybe<Order_By>
  disabled_reason?: Maybe<Order_By>
  email?: Maybe<Order_By>
  icon_order?: Maybe<Order_By>
  id?: Maybe<Order_By>
  installed_at?: Maybe<Order_By>
  name?: Maybe<Order_By>
  ogimage_fingerprint?: Maybe<Order_By>
  ogimage_fingerprint_updated?: Maybe<Order_By>
  orphaned_at?: Maybe<Order_By>
  orphaned_by?: Maybe<Order_By>
  phone?: Maybe<Order_By>
  picture?: Maybe<Order_By>
  possible_email?: Maybe<Order_By>
  referrer_user_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  username?: Maybe<Order_By>
  version?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  applied_as_creator_at?: Maybe<Scalars['timestamptz']>
  bio?: Maybe<Scalars['String']>
  bio_link?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  deleted_reason?: Maybe<Scalars['String']>
  disabled_at?: Maybe<Scalars['timestamptz']>
  disabled_reason?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_display_name" */
  display_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_email_md5_hash" */
  email_md5?: Maybe<Scalars['String']>
  /** A computed field, executes function "extension_version" */
  extension_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_first_app_install" */
  first_app_install?: Maybe<Scalars['String']>
  icon_order?: Maybe<Scalars['numeric']>
  id?: Maybe<Scalars['String']>
  installed_at?: Maybe<Scalars['timestamptz']>
  ios_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  orphaned_at?: Maybe<Scalars['timestamptz']>
  orphaned_by?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  possible_email?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  referrer_user_id?: Maybe<Scalars['String']>
  safari_extension_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_subscription_status" */
  subscription_status?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
  username?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  applied_as_creator_at?: Maybe<Order_By>
  bio?: Maybe<Order_By>
  bio_link?: Maybe<Order_By>
  cover_image?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  deleted_reason?: Maybe<Order_By>
  disabled_at?: Maybe<Order_By>
  disabled_reason?: Maybe<Order_By>
  email?: Maybe<Order_By>
  icon_order?: Maybe<Order_By>
  id?: Maybe<Order_By>
  installed_at?: Maybe<Order_By>
  name?: Maybe<Order_By>
  ogimage_fingerprint?: Maybe<Order_By>
  ogimage_fingerprint_updated?: Maybe<Order_By>
  orphaned_at?: Maybe<Order_By>
  orphaned_by?: Maybe<Order_By>
  phone?: Maybe<Order_By>
  picture?: Maybe<Order_By>
  possible_email?: Maybe<Order_By>
  referrer_user_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  username?: Maybe<Order_By>
  version?: Maybe<Order_By>
}

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User>
}

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_On_Conflict>
}

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint
  update_columns?: Array<User_Update_Column>
  where?: Maybe<User_Bool_Exp>
}

/** Onboarding source (how did you hear about us) selection by user */
export type User_Onboarding_Source = {
  __typename?: 'user_onboarding_source'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  onboarding_source: Onboarding_Sources_Enum
  other_source?: Maybe<Scalars['String']>
  /** An object relationship */
  source: Onboarding_Sources
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_onboarding_source" */
export type User_Onboarding_Source_Aggregate = {
  __typename?: 'user_onboarding_source_aggregate'
  aggregate?: Maybe<User_Onboarding_Source_Aggregate_Fields>
  nodes: Array<User_Onboarding_Source>
}

export type User_Onboarding_Source_Aggregate_Bool_Exp = {
  count?: Maybe<User_Onboarding_Source_Aggregate_Bool_Exp_Count>
}

export type User_Onboarding_Source_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Onboarding_Source_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_onboarding_source" */
export type User_Onboarding_Source_Aggregate_Fields = {
  __typename?: 'user_onboarding_source_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Onboarding_Source_Max_Fields>
  min?: Maybe<User_Onboarding_Source_Min_Fields>
}

/** aggregate fields of "user_onboarding_source" */
export type User_Onboarding_Source_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_onboarding_source" */
export type User_Onboarding_Source_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Onboarding_Source_Max_Order_By>
  min?: Maybe<User_Onboarding_Source_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_onboarding_source" */
export type User_Onboarding_Source_Arr_Rel_Insert_Input = {
  data: Array<User_Onboarding_Source_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Onboarding_Source_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_onboarding_source". All fields are combined with a logical 'AND'. */
export type User_Onboarding_Source_Bool_Exp = {
  _and?: Maybe<Array<User_Onboarding_Source_Bool_Exp>>
  _not?: Maybe<User_Onboarding_Source_Bool_Exp>
  _or?: Maybe<Array<User_Onboarding_Source_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  onboarding_source?: Maybe<Onboarding_Sources_Enum_Comparison_Exp>
  other_source?: Maybe<String_Comparison_Exp>
  source?: Maybe<Onboarding_Sources_Bool_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_onboarding_source" */
export enum User_Onboarding_Source_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserOnboardingSourcePkey = 'user_onboarding_source_pkey',
  /** unique or primary key constraint on columns "user_id" */
  UserOnboardingSourceUserIdKey = 'user_onboarding_source_user_id_key',
}

/** input type for inserting data into table "user_onboarding_source" */
export type User_Onboarding_Source_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  onboarding_source?: Maybe<Onboarding_Sources_Enum>
  other_source?: Maybe<Scalars['String']>
  source?: Maybe<Onboarding_Sources_Obj_Rel_Insert_Input>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Onboarding_Source_Max_Fields = {
  __typename?: 'user_onboarding_source_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  other_source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_onboarding_source" */
export type User_Onboarding_Source_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  other_source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Onboarding_Source_Min_Fields = {
  __typename?: 'user_onboarding_source_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  other_source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_onboarding_source" */
export type User_Onboarding_Source_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  other_source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_onboarding_source" */
export type User_Onboarding_Source_Mutation_Response = {
  __typename?: 'user_onboarding_source_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Onboarding_Source>
}

/** on_conflict condition type for table "user_onboarding_source" */
export type User_Onboarding_Source_On_Conflict = {
  constraint: User_Onboarding_Source_Constraint
  update_columns?: Array<User_Onboarding_Source_Update_Column>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

/** Ordering options when selecting data from "user_onboarding_source". */
export type User_Onboarding_Source_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  onboarding_source?: Maybe<Order_By>
  other_source?: Maybe<Order_By>
  source?: Maybe<Onboarding_Sources_Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_onboarding_source */
export type User_Onboarding_Source_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_onboarding_source" */
export enum User_Onboarding_Source_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OnboardingSource = 'onboarding_source',
  /** column name */
  OtherSource = 'other_source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_onboarding_source" */
export type User_Onboarding_Source_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  onboarding_source?: Maybe<Onboarding_Sources_Enum>
  other_source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_onboarding_source" */
export type User_Onboarding_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Onboarding_Source_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Onboarding_Source_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  onboarding_source?: Maybe<Onboarding_Sources_Enum>
  other_source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_onboarding_source" */
export enum User_Onboarding_Source_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OnboardingSource = 'onboarding_source',
  /** column name */
  OtherSource = 'other_source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Onboarding_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Onboarding_Source_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Onboarding_Source_Bool_Exp
}

/** columns and relationships of "user_onboarding_state" */
export type User_Onboarding_State = {
  __typename?: 'user_onboarding_state'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "user_onboarding_state" */
export type User_Onboarding_State_Aggregate = {
  __typename?: 'user_onboarding_state_aggregate'
  aggregate?: Maybe<User_Onboarding_State_Aggregate_Fields>
  nodes: Array<User_Onboarding_State>
}

/** aggregate fields of "user_onboarding_state" */
export type User_Onboarding_State_Aggregate_Fields = {
  __typename?: 'user_onboarding_state_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Onboarding_State_Max_Fields>
  min?: Maybe<User_Onboarding_State_Min_Fields>
}

/** aggregate fields of "user_onboarding_state" */
export type User_Onboarding_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Onboarding_State_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_onboarding_state". All fields are combined with a logical 'AND'. */
export type User_Onboarding_State_Bool_Exp = {
  _and?: Maybe<Array<User_Onboarding_State_Bool_Exp>>
  _not?: Maybe<User_Onboarding_State_Bool_Exp>
  _or?: Maybe<Array<User_Onboarding_State_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_onboarding_state" */
export enum User_Onboarding_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  OnboardingStatePkey = 'onboarding_state_pkey',
}

export enum User_Onboarding_State_Enum {
  /** Product added to Carrot via cart */
  AddedToCart = 'added_to_cart',
  /** Opened Carrot extension after add to cart */
  OpenedExtension = 'opened_extension',
}

/** Boolean expression to compare columns of type "user_onboarding_state_enum". All fields are combined with logical 'AND'. */
export type User_Onboarding_State_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Onboarding_State_Enum>
  _in?: Maybe<Array<User_Onboarding_State_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<User_Onboarding_State_Enum>
  _nin?: Maybe<Array<User_Onboarding_State_Enum>>
}

/** input type for inserting data into table "user_onboarding_state" */
export type User_Onboarding_State_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Onboarding_State_Max_Fields = {
  __typename?: 'user_onboarding_state_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Onboarding_State_Min_Fields = {
  __typename?: 'user_onboarding_state_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_onboarding_state" */
export type User_Onboarding_State_Mutation_Response = {
  __typename?: 'user_onboarding_state_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Onboarding_State>
}

/** input type for inserting object relation for remote table "user_onboarding_state" */
export type User_Onboarding_State_Obj_Rel_Insert_Input = {
  data: User_Onboarding_State_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Onboarding_State_On_Conflict>
}

/** on_conflict condition type for table "user_onboarding_state" */
export type User_Onboarding_State_On_Conflict = {
  constraint: User_Onboarding_State_Constraint
  update_columns?: Array<User_Onboarding_State_Update_Column>
  where?: Maybe<User_Onboarding_State_Bool_Exp>
}

/** Ordering options when selecting data from "user_onboarding_state". */
export type User_Onboarding_State_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: user_onboarding_state */
export type User_Onboarding_State_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "user_onboarding_state" */
export enum User_Onboarding_State_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "user_onboarding_state" */
export type User_Onboarding_State_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_onboarding_state" */
export type User_Onboarding_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Onboarding_State_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Onboarding_State_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "user_onboarding_state" */
export enum User_Onboarding_State_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type User_Onboarding_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Onboarding_State_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Onboarding_State_Bool_Exp
}

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  active_cart_items_quantity?: Maybe<Order_By>
  affiliate_transactions_aggregate?: Maybe<Affiliate_Transaction_Aggregate_Order_By>
  anonymous?: Maybe<Order_By>
  app_versions_aggregate?: Maybe<User_App_Version_Aggregate_Order_By>
  applied_as_creator_at?: Maybe<Order_By>
  bio?: Maybe<Order_By>
  bio_link?: Maybe<Order_By>
  can_be_credited?: Maybe<Order_By>
  cart_item_likes_aggregate?: Maybe<Cart_Item_Like_Aggregate_Order_By>
  carts_aggregate?: Maybe<Cart_Aggregate_Order_By>
  carts_count?: Maybe<Order_By>
  collection_item_claims_aggregate?: Maybe<Collection_Item_Claim_Aggregate_Order_By>
  collections_aggregate?: Maybe<Collection_Aggregate_Order_By>
  collections_collaborating_aggregate?: Maybe<Collection_Collaborator_Aggregate_Order_By>
  commissions_cleared?: Maybe<Order_By>
  commissions_pending?: Maybe<Order_By>
  cover_image?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  deleted_reason?: Maybe<Order_By>
  disabled_at?: Maybe<Order_By>
  disabled_reason?: Maybe<Order_By>
  display_name?: Maybe<Order_By>
  email?: Maybe<Order_By>
  email_md5?: Maybe<Order_By>
  extension_version?: Maybe<Order_By>
  first_app_install?: Maybe<Order_By>
  flags?: Maybe<User_Flags_Order_By>
  follows_aggregate?: Maybe<User_Items_Followed_Aggregate_Order_By>
  has_accounts_email_computed?: Maybe<Order_By>
  has_billing_account?: Maybe<Order_By>
  has_claimed_username_computed?: Maybe<Order_By>
  has_email_set?: Maybe<Order_By>
  has_onboarded?: Maybe<Order_By>
  has_picture_set?: Maybe<Order_By>
  has_premium_subscription?: Maybe<Order_By>
  has_set_name_computed?: Maybe<Order_By>
  icon_order?: Maybe<Order_By>
  id?: Maybe<Order_By>
  in_on_campus_contest?: Maybe<Order_By>
  installed_at?: Maybe<Order_By>
  internal_account?: Maybe<Order_By>
  ios_version?: Maybe<Order_By>
  is_creator_computed?: Maybe<Order_By>
  is_curator_computed?: Maybe<Order_By>
  is_icon_computed?: Maybe<Order_By>
  links_aggregate?: Maybe<User_Links_Aggregate_Order_By>
  metadata?: Maybe<Order_By>
  mobile_badge_count?: Maybe<Order_By>
  name?: Maybe<Order_By>
  new_discovery_feed_items_count?: Maybe<Order_By>
  notification_center_items_aggregate?: Maybe<Notification_Center_Item_Aggregate_Order_By>
  notification_preferences_aggregate?: Maybe<Notification_Preference_Aggregate_Order_By>
  ogimage_fingerprint?: Maybe<Order_By>
  ogimage_fingerprint_updated?: Maybe<Order_By>
  onboarded?: Maybe<Order_By>
  orphan_parent?: Maybe<User_Order_By>
  orphaned_at?: Maybe<Order_By>
  orphaned_by?: Maybe<Order_By>
  orphans_aggregate?: Maybe<User_Aggregate_Order_By>
  phone?: Maybe<Order_By>
  picture?: Maybe<Order_By>
  possible_email?: Maybe<Order_By>
  probably_real?: Maybe<Order_By>
  referral_count_credited?: Maybe<Order_By>
  referral_count_total?: Maybe<Order_By>
  referral_count_within_promo_period?: Maybe<Order_By>
  referred_users_aggregate?: Maybe<User_Aggregate_Order_By>
  referrer_user?: Maybe<User_Order_By>
  referrer_user_id?: Maybe<Order_By>
  role?: Maybe<Order_By>
  safari_extension_version?: Maybe<Order_By>
  shopping_preference?: Maybe<Order_By>
  short_id?: Maybe<Order_By>
  show_curations?: Maybe<Order_By>
  show_onboarding?: Maybe<Order_By>
  show_rabbithole?: Maybe<Order_By>
  similar_products_histories_aggregate?: Maybe<Similar_Products_History_Aggregate_Order_By>
  subscription_status?: Maybe<Order_By>
  unread_notification_center_items?: Maybe<Order_By>
  unseen_cart_items?: Maybe<Order_By>
  unviewed_notification_center_items?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_account_type?: Maybe<User_Account_Type_Order_By>
  user_carts_onboardings_aggregate?: Maybe<User_Carts_Onboarding_Aggregate_Order_By>
  user_categories_aggregate?: Maybe<User_Category_Aggregate_Order_By>
  user_ip_addresses_aggregate?: Maybe<User_Ip_Address_Aggregate_Order_By>
  user_onboarding_sources_aggregate?: Maybe<User_Onboarding_Source_Aggregate_Order_By>
  user_points_multiplier?: Maybe<Order_By>
  user_push_notification_tokens_aggregate?: Maybe<User_Push_Notification_Token_Aggregate_Order_By>
  user_referrals_aggregate?: Maybe<User_Referral_Aggregate_Order_By>
  user_shopping_preference?: Maybe<User_Shopping_Preferences_Order_By>
  user_type?: Maybe<Order_By>
  username?: Maybe<Order_By>
  verified?: Maybe<Order_By>
  version?: Maybe<Order_By>
  waitlist?: Maybe<Order_By>
  workflows_aggregate?: Maybe<Workflow_Aggregate_Order_By>
}

/** A table to hold a user's phone contacts */
export type User_Phone_Contact = {
  __typename?: 'user_phone_contact'
  contact_id: Scalars['String']
  contact_info: Scalars['String']
  contact_info_type: Contact_Info_Type_Enum
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  invited: Scalars['Boolean']
  inviter_id?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_phone_contact" */
export type User_Phone_Contact_Aggregate = {
  __typename?: 'user_phone_contact_aggregate'
  aggregate?: Maybe<User_Phone_Contact_Aggregate_Fields>
  nodes: Array<User_Phone_Contact>
}

/** aggregate fields of "user_phone_contact" */
export type User_Phone_Contact_Aggregate_Fields = {
  __typename?: 'user_phone_contact_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Phone_Contact_Max_Fields>
  min?: Maybe<User_Phone_Contact_Min_Fields>
}

/** aggregate fields of "user_phone_contact" */
export type User_Phone_Contact_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Phone_Contact_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_phone_contact". All fields are combined with a logical 'AND'. */
export type User_Phone_Contact_Bool_Exp = {
  _and?: Maybe<Array<User_Phone_Contact_Bool_Exp>>
  _not?: Maybe<User_Phone_Contact_Bool_Exp>
  _or?: Maybe<Array<User_Phone_Contact_Bool_Exp>>
  contact_id?: Maybe<String_Comparison_Exp>
  contact_info?: Maybe<String_Comparison_Exp>
  contact_info_type?: Maybe<Contact_Info_Type_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  invited?: Maybe<Boolean_Comparison_Exp>
  inviter_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_phone_contact" */
export enum User_Phone_Contact_Constraint {
  /** unique or primary key constraint on columns "contact_id", "inviter_id", "contact_info_type", "contact_info" */
  UserPhoneContactInviterIdContactIdContactInfoTypeCont = 'user_phone_contact_inviter_id_contact_id_contact_info_type_cont',
  /** unique or primary key constraint on columns "id" */
  UserPhoneContactPkey = 'user_phone_contact_pkey',
}

/** input type for inserting data into table "user_phone_contact" */
export type User_Phone_Contact_Insert_Input = {
  contact_id?: Maybe<Scalars['String']>
  contact_info?: Maybe<Scalars['String']>
  contact_info_type?: Maybe<Contact_Info_Type_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invited?: Maybe<Scalars['Boolean']>
  inviter_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Phone_Contact_Max_Fields = {
  __typename?: 'user_phone_contact_max_fields'
  contact_id?: Maybe<Scalars['String']>
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  inviter_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Phone_Contact_Min_Fields = {
  __typename?: 'user_phone_contact_min_fields'
  contact_id?: Maybe<Scalars['String']>
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  inviter_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_phone_contact" */
export type User_Phone_Contact_Mutation_Response = {
  __typename?: 'user_phone_contact_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Phone_Contact>
}

/** on_conflict condition type for table "user_phone_contact" */
export type User_Phone_Contact_On_Conflict = {
  constraint: User_Phone_Contact_Constraint
  update_columns?: Array<User_Phone_Contact_Update_Column>
  where?: Maybe<User_Phone_Contact_Bool_Exp>
}

/** Ordering options when selecting data from "user_phone_contact". */
export type User_Phone_Contact_Order_By = {
  contact_id?: Maybe<Order_By>
  contact_info?: Maybe<Order_By>
  contact_info_type?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  invited?: Maybe<Order_By>
  inviter_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_phone_contact */
export type User_Phone_Contact_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_phone_contact" */
export enum User_Phone_Contact_Select_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  ContactInfo = 'contact_info',
  /** column name */
  ContactInfoType = 'contact_info_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Invited = 'invited',
  /** column name */
  InviterId = 'inviter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_phone_contact" */
export type User_Phone_Contact_Set_Input = {
  contact_id?: Maybe<Scalars['String']>
  contact_info?: Maybe<Scalars['String']>
  contact_info_type?: Maybe<Contact_Info_Type_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invited?: Maybe<Scalars['Boolean']>
  inviter_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_phone_contact" */
export type User_Phone_Contact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Phone_Contact_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Phone_Contact_Stream_Cursor_Value_Input = {
  contact_id?: Maybe<Scalars['String']>
  contact_info?: Maybe<Scalars['String']>
  contact_info_type?: Maybe<Contact_Info_Type_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invited?: Maybe<Scalars['Boolean']>
  inviter_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_phone_contact" */
export enum User_Phone_Contact_Update_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  ContactInfo = 'contact_info',
  /** column name */
  ContactInfoType = 'contact_info_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Invited = 'invited',
  /** column name */
  InviterId = 'inviter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Phone_Contact_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Phone_Contact_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Phone_Contact_Bool_Exp
}

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['String']
}

/** columns and relationships of "user_points_redeemed_summary" */
export type User_Points_Redeemed_Summary = {
  __typename?: 'user_points_redeemed_summary'
  points_redeemed?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_points_redeemed_summary" */
export type User_Points_Redeemed_Summary_Aggregate = {
  __typename?: 'user_points_redeemed_summary_aggregate'
  aggregate?: Maybe<User_Points_Redeemed_Summary_Aggregate_Fields>
  nodes: Array<User_Points_Redeemed_Summary>
}

/** aggregate fields of "user_points_redeemed_summary" */
export type User_Points_Redeemed_Summary_Aggregate_Fields = {
  __typename?: 'user_points_redeemed_summary_aggregate_fields'
  avg?: Maybe<User_Points_Redeemed_Summary_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Points_Redeemed_Summary_Max_Fields>
  min?: Maybe<User_Points_Redeemed_Summary_Min_Fields>
  stddev?: Maybe<User_Points_Redeemed_Summary_Stddev_Fields>
  stddev_pop?: Maybe<User_Points_Redeemed_Summary_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Points_Redeemed_Summary_Stddev_Samp_Fields>
  sum?: Maybe<User_Points_Redeemed_Summary_Sum_Fields>
  var_pop?: Maybe<User_Points_Redeemed_Summary_Var_Pop_Fields>
  var_samp?: Maybe<User_Points_Redeemed_Summary_Var_Samp_Fields>
  variance?: Maybe<User_Points_Redeemed_Summary_Variance_Fields>
}

/** aggregate fields of "user_points_redeemed_summary" */
export type User_Points_Redeemed_Summary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Points_Redeemed_Summary_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type User_Points_Redeemed_Summary_Avg_Fields = {
  __typename?: 'user_points_redeemed_summary_avg_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "user_points_redeemed_summary". All fields are combined with a logical 'AND'. */
export type User_Points_Redeemed_Summary_Bool_Exp = {
  _and?: Maybe<Array<User_Points_Redeemed_Summary_Bool_Exp>>
  _not?: Maybe<User_Points_Redeemed_Summary_Bool_Exp>
  _or?: Maybe<Array<User_Points_Redeemed_Summary_Bool_Exp>>
  points_redeemed?: Maybe<Numeric_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type User_Points_Redeemed_Summary_Max_Fields = {
  __typename?: 'user_points_redeemed_summary_max_fields'
  points_redeemed?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Points_Redeemed_Summary_Min_Fields = {
  __typename?: 'user_points_redeemed_summary_min_fields'
  points_redeemed?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "user_points_redeemed_summary". */
export type User_Points_Redeemed_Summary_Order_By = {
  points_redeemed?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "user_points_redeemed_summary" */
export enum User_Points_Redeemed_Summary_Select_Column {
  /** column name */
  PointsRedeemed = 'points_redeemed',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type User_Points_Redeemed_Summary_Stddev_Fields = {
  __typename?: 'user_points_redeemed_summary_stddev_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type User_Points_Redeemed_Summary_Stddev_Pop_Fields = {
  __typename?: 'user_points_redeemed_summary_stddev_pop_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type User_Points_Redeemed_Summary_Stddev_Samp_Fields = {
  __typename?: 'user_points_redeemed_summary_stddev_samp_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "user_points_redeemed_summary" */
export type User_Points_Redeemed_Summary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Points_Redeemed_Summary_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Points_Redeemed_Summary_Stream_Cursor_Value_Input = {
  points_redeemed?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Points_Redeemed_Summary_Sum_Fields = {
  __typename?: 'user_points_redeemed_summary_sum_fields'
  points_redeemed?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type User_Points_Redeemed_Summary_Var_Pop_Fields = {
  __typename?: 'user_points_redeemed_summary_var_pop_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type User_Points_Redeemed_Summary_Var_Samp_Fields = {
  __typename?: 'user_points_redeemed_summary_var_samp_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type User_Points_Redeemed_Summary_Variance_Fields = {
  __typename?: 'user_points_redeemed_summary_variance_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** columns and relationships of "user_points_summary" */
export type User_Points_Summary = {
  __typename?: 'user_points_summary'
  multiplier?: Maybe<Scalars['numeric']>
  pending_points?: Maybe<Scalars['numeric']>
  total_points?: Maybe<Scalars['numeric']>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_points_summary" */
export type User_Points_Summary_Aggregate = {
  __typename?: 'user_points_summary_aggregate'
  aggregate?: Maybe<User_Points_Summary_Aggregate_Fields>
  nodes: Array<User_Points_Summary>
}

/** aggregate fields of "user_points_summary" */
export type User_Points_Summary_Aggregate_Fields = {
  __typename?: 'user_points_summary_aggregate_fields'
  avg?: Maybe<User_Points_Summary_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Points_Summary_Max_Fields>
  min?: Maybe<User_Points_Summary_Min_Fields>
  stddev?: Maybe<User_Points_Summary_Stddev_Fields>
  stddev_pop?: Maybe<User_Points_Summary_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Points_Summary_Stddev_Samp_Fields>
  sum?: Maybe<User_Points_Summary_Sum_Fields>
  var_pop?: Maybe<User_Points_Summary_Var_Pop_Fields>
  var_samp?: Maybe<User_Points_Summary_Var_Samp_Fields>
  variance?: Maybe<User_Points_Summary_Variance_Fields>
}

/** aggregate fields of "user_points_summary" */
export type User_Points_Summary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Points_Summary_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type User_Points_Summary_Avg_Fields = {
  __typename?: 'user_points_summary_avg_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "user_points_summary". All fields are combined with a logical 'AND'. */
export type User_Points_Summary_Bool_Exp = {
  _and?: Maybe<Array<User_Points_Summary_Bool_Exp>>
  _not?: Maybe<User_Points_Summary_Bool_Exp>
  _or?: Maybe<Array<User_Points_Summary_Bool_Exp>>
  multiplier?: Maybe<Numeric_Comparison_Exp>
  pending_points?: Maybe<Numeric_Comparison_Exp>
  total_points?: Maybe<Numeric_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type User_Points_Summary_Max_Fields = {
  __typename?: 'user_points_summary_max_fields'
  multiplier?: Maybe<Scalars['numeric']>
  pending_points?: Maybe<Scalars['numeric']>
  total_points?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Points_Summary_Min_Fields = {
  __typename?: 'user_points_summary_min_fields'
  multiplier?: Maybe<Scalars['numeric']>
  pending_points?: Maybe<Scalars['numeric']>
  total_points?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "user_points_summary". */
export type User_Points_Summary_Order_By = {
  multiplier?: Maybe<Order_By>
  pending_points?: Maybe<Order_By>
  total_points?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "user_points_summary" */
export enum User_Points_Summary_Select_Column {
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  PendingPoints = 'pending_points',
  /** column name */
  TotalPoints = 'total_points',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type User_Points_Summary_Stddev_Fields = {
  __typename?: 'user_points_summary_stddev_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type User_Points_Summary_Stddev_Pop_Fields = {
  __typename?: 'user_points_summary_stddev_pop_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type User_Points_Summary_Stddev_Samp_Fields = {
  __typename?: 'user_points_summary_stddev_samp_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "user_points_summary" */
export type User_Points_Summary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Points_Summary_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Points_Summary_Stream_Cursor_Value_Input = {
  multiplier?: Maybe<Scalars['numeric']>
  pending_points?: Maybe<Scalars['numeric']>
  total_points?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Points_Summary_Sum_Fields = {
  __typename?: 'user_points_summary_sum_fields'
  multiplier?: Maybe<Scalars['numeric']>
  pending_points?: Maybe<Scalars['numeric']>
  total_points?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type User_Points_Summary_Var_Pop_Fields = {
  __typename?: 'user_points_summary_var_pop_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type User_Points_Summary_Var_Samp_Fields = {
  __typename?: 'user_points_summary_var_samp_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type User_Points_Summary_Variance_Fields = {
  __typename?: 'user_points_summary_variance_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** Table to track premium subscription status of a user */
export type User_Purchase_Subscription = {
  __typename?: 'user_purchase_subscription'
  created_at: Scalars['timestamptz']
  environment: Scalars['String']
  id: Scalars['uuid']
  premium: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "user_purchase_subscription" */
export type User_Purchase_Subscription_Aggregate = {
  __typename?: 'user_purchase_subscription_aggregate'
  aggregate?: Maybe<User_Purchase_Subscription_Aggregate_Fields>
  nodes: Array<User_Purchase_Subscription>
}

/** aggregate fields of "user_purchase_subscription" */
export type User_Purchase_Subscription_Aggregate_Fields = {
  __typename?: 'user_purchase_subscription_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Purchase_Subscription_Max_Fields>
  min?: Maybe<User_Purchase_Subscription_Min_Fields>
}

/** aggregate fields of "user_purchase_subscription" */
export type User_Purchase_Subscription_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Purchase_Subscription_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_purchase_subscription". All fields are combined with a logical 'AND'. */
export type User_Purchase_Subscription_Bool_Exp = {
  _and?: Maybe<Array<User_Purchase_Subscription_Bool_Exp>>
  _not?: Maybe<User_Purchase_Subscription_Bool_Exp>
  _or?: Maybe<Array<User_Purchase_Subscription_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  environment?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  premium?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_purchase_subscription" */
export enum User_Purchase_Subscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPurchaseSubscriptionPkey = 'user_purchase_subscription_pkey',
  /** unique or primary key constraint on columns "user_id" */
  UserPurchaseSubscriptionUserIdKey = 'user_purchase_subscription_user_id_key',
}

/** input type for inserting data into table "user_purchase_subscription" */
export type User_Purchase_Subscription_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  premium?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Purchase_Subscription_Max_Fields = {
  __typename?: 'user_purchase_subscription_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Purchase_Subscription_Min_Fields = {
  __typename?: 'user_purchase_subscription_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_purchase_subscription" */
export type User_Purchase_Subscription_Mutation_Response = {
  __typename?: 'user_purchase_subscription_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Purchase_Subscription>
}

/** on_conflict condition type for table "user_purchase_subscription" */
export type User_Purchase_Subscription_On_Conflict = {
  constraint: User_Purchase_Subscription_Constraint
  update_columns?: Array<User_Purchase_Subscription_Update_Column>
  where?: Maybe<User_Purchase_Subscription_Bool_Exp>
}

/** Ordering options when selecting data from "user_purchase_subscription". */
export type User_Purchase_Subscription_Order_By = {
  created_at?: Maybe<Order_By>
  environment?: Maybe<Order_By>
  id?: Maybe<Order_By>
  premium?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_purchase_subscription */
export type User_Purchase_Subscription_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_purchase_subscription" */
export enum User_Purchase_Subscription_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Environment = 'environment',
  /** column name */
  Id = 'id',
  /** column name */
  Premium = 'premium',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_purchase_subscription" */
export type User_Purchase_Subscription_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  premium?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_purchase_subscription" */
export type User_Purchase_Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Purchase_Subscription_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Purchase_Subscription_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  premium?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_purchase_subscription" */
export enum User_Purchase_Subscription_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Environment = 'environment',
  /** column name */
  Id = 'id',
  /** column name */
  Premium = 'premium',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Purchase_Subscription_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Purchase_Subscription_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Purchase_Subscription_Bool_Exp
}

/** columns and relationships of "user_purchases" */
export type User_Purchases = {
  __typename?: 'user_purchases'
  created_at: Scalars['timestamptz']
  environment: Scalars['String']
  id: Scalars['uuid']
  original_transaction_id: Scalars['String']
  product_id: Scalars['String']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "user_purchases" */
export type User_Purchases_Aggregate = {
  __typename?: 'user_purchases_aggregate'
  aggregate?: Maybe<User_Purchases_Aggregate_Fields>
  nodes: Array<User_Purchases>
}

/** aggregate fields of "user_purchases" */
export type User_Purchases_Aggregate_Fields = {
  __typename?: 'user_purchases_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Purchases_Max_Fields>
  min?: Maybe<User_Purchases_Min_Fields>
}

/** aggregate fields of "user_purchases" */
export type User_Purchases_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Purchases_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_purchases". All fields are combined with a logical 'AND'. */
export type User_Purchases_Bool_Exp = {
  _and?: Maybe<Array<User_Purchases_Bool_Exp>>
  _not?: Maybe<User_Purchases_Bool_Exp>
  _or?: Maybe<Array<User_Purchases_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  environment?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  original_transaction_id?: Maybe<String_Comparison_Exp>
  product_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_purchases" */
export enum User_Purchases_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPurchasesPkey = 'user_purchases_pkey',
  /** unique or primary key constraint on columns "original_transaction_id", "user_id" */
  UserPurchasesUserIdOriginalTransactionIdKey = 'user_purchases_user_id_original_transaction_id_key',
}

/** input type for inserting data into table "user_purchases" */
export type User_Purchases_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  original_transaction_id?: Maybe<Scalars['String']>
  product_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Purchases_Max_Fields = {
  __typename?: 'user_purchases_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  original_transaction_id?: Maybe<Scalars['String']>
  product_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Purchases_Min_Fields = {
  __typename?: 'user_purchases_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  original_transaction_id?: Maybe<Scalars['String']>
  product_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_purchases" */
export type User_Purchases_Mutation_Response = {
  __typename?: 'user_purchases_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Purchases>
}

/** on_conflict condition type for table "user_purchases" */
export type User_Purchases_On_Conflict = {
  constraint: User_Purchases_Constraint
  update_columns?: Array<User_Purchases_Update_Column>
  where?: Maybe<User_Purchases_Bool_Exp>
}

/** Ordering options when selecting data from "user_purchases". */
export type User_Purchases_Order_By = {
  created_at?: Maybe<Order_By>
  environment?: Maybe<Order_By>
  id?: Maybe<Order_By>
  original_transaction_id?: Maybe<Order_By>
  product_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_purchases */
export type User_Purchases_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_purchases" */
export enum User_Purchases_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Environment = 'environment',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalTransactionId = 'original_transaction_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_purchases" */
export type User_Purchases_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  original_transaction_id?: Maybe<Scalars['String']>
  product_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_purchases" */
export type User_Purchases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Purchases_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Purchases_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  original_transaction_id?: Maybe<Scalars['String']>
  product_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_purchases" */
export enum User_Purchases_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Environment = 'environment',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalTransactionId = 'original_transaction_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Purchases_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Purchases_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Purchases_Bool_Exp
}

/** columns and relationships of "user_push_notification" */
export type User_Push_Notification = {
  __typename?: 'user_push_notification'
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  /** An array relationship */
  push_notification_status: Array<Push_Notification_Status>
  /** An aggregate relationship */
  push_notification_status_aggregate: Push_Notification_Status_Aggregate
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "user_push_notification" */
export type User_Push_NotificationCriteriaArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "user_push_notification" */
export type User_Push_NotificationPayloadArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "user_push_notification" */
export type User_Push_NotificationPush_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

/** columns and relationships of "user_push_notification" */
export type User_Push_NotificationPush_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

/** aggregated selection of "user_push_notification" */
export type User_Push_Notification_Aggregate = {
  __typename?: 'user_push_notification_aggregate'
  aggregate?: Maybe<User_Push_Notification_Aggregate_Fields>
  nodes: Array<User_Push_Notification>
}

/** aggregate fields of "user_push_notification" */
export type User_Push_Notification_Aggregate_Fields = {
  __typename?: 'user_push_notification_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Push_Notification_Max_Fields>
  min?: Maybe<User_Push_Notification_Min_Fields>
}

/** aggregate fields of "user_push_notification" */
export type User_Push_Notification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Push_Notification_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Push_Notification_Append_Input = {
  criteria?: Maybe<Scalars['jsonb']>
  payload?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "user_push_notification". All fields are combined with a logical 'AND'. */
export type User_Push_Notification_Bool_Exp = {
  _and?: Maybe<Array<User_Push_Notification_Bool_Exp>>
  _not?: Maybe<User_Push_Notification_Bool_Exp>
  _or?: Maybe<Array<User_Push_Notification_Bool_Exp>>
  active?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  criteria?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_id?: Maybe<String_Comparison_Exp>
  notifiable_type?: Maybe<String_Comparison_Exp>
  payload?: Maybe<Jsonb_Comparison_Exp>
  push_notification_status?: Maybe<Push_Notification_Status_Bool_Exp>
  push_notification_status_aggregate?: Maybe<Push_Notification_Status_Aggregate_Bool_Exp>
  show_after?: Maybe<Timestamptz_Comparison_Exp>
  show_before?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Push_Notification_Delete_At_Path_Input = {
  criteria?: Maybe<Array<Scalars['String']>>
  payload?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Push_Notification_Delete_Elem_Input = {
  criteria?: Maybe<Scalars['Int']>
  payload?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Push_Notification_Delete_Key_Input = {
  criteria?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "user_push_notification" */
export type User_Push_Notification_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  push_notification_status?: Maybe<Push_Notification_Status_Arr_Rel_Insert_Input>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type User_Push_Notification_Max_Fields = {
  __typename?: 'user_push_notification_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type User_Push_Notification_Min_Fields = {
  __typename?: 'user_push_notification_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "user_push_notification" */
export type User_Push_Notification_Mutation_Response = {
  __typename?: 'user_push_notification_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Push_Notification>
}

/** Ordering options when selecting data from "user_push_notification". */
export type User_Push_Notification_Order_By = {
  active?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  criteria?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  payload?: Maybe<Order_By>
  push_notification_status_aggregate?: Maybe<Push_Notification_Status_Aggregate_Order_By>
  show_after?: Maybe<Order_By>
  show_before?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Push_Notification_Prepend_Input = {
  criteria?: Maybe<Scalars['jsonb']>
  payload?: Maybe<Scalars['jsonb']>
}

/** select columns of table "user_push_notification" */
export enum User_Push_Notification_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  Payload = 'payload',
  /** column name */
  ShowAfter = 'show_after',
  /** column name */
  ShowBefore = 'show_before',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "user_push_notification" */
export type User_Push_Notification_Set_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "user_push_notification" */
export type User_Push_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Push_Notification_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Push_Notification_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** A table to associate expo push notification tokens with users */
export type User_Push_Notification_Token = {
  __typename?: 'user_push_notification_token'
  active: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  token: Scalars['String']
  type: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_push_notification_token" */
export type User_Push_Notification_Token_Aggregate = {
  __typename?: 'user_push_notification_token_aggregate'
  aggregate?: Maybe<User_Push_Notification_Token_Aggregate_Fields>
  nodes: Array<User_Push_Notification_Token>
}

export type User_Push_Notification_Token_Aggregate_Bool_Exp = {
  bool_and?: Maybe<User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<User_Push_Notification_Token_Aggregate_Bool_Exp_Count>
}

export type User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Push_Notification_Token_Select_Column_User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Push_Notification_Token_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Push_Notification_Token_Select_Column_User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Push_Notification_Token_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Push_Notification_Token_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Push_Notification_Token_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_push_notification_token" */
export type User_Push_Notification_Token_Aggregate_Fields = {
  __typename?: 'user_push_notification_token_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Push_Notification_Token_Max_Fields>
  min?: Maybe<User_Push_Notification_Token_Min_Fields>
}

/** aggregate fields of "user_push_notification_token" */
export type User_Push_Notification_Token_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_push_notification_token" */
export type User_Push_Notification_Token_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Push_Notification_Token_Max_Order_By>
  min?: Maybe<User_Push_Notification_Token_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_push_notification_token" */
export type User_Push_Notification_Token_Arr_Rel_Insert_Input = {
  data: Array<User_Push_Notification_Token_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Push_Notification_Token_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_push_notification_token". All fields are combined with a logical 'AND'. */
export type User_Push_Notification_Token_Bool_Exp = {
  _and?: Maybe<Array<User_Push_Notification_Token_Bool_Exp>>
  _not?: Maybe<User_Push_Notification_Token_Bool_Exp>
  _or?: Maybe<Array<User_Push_Notification_Token_Bool_Exp>>
  active?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  token?: Maybe<String_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_push_notification_token" */
export enum User_Push_Notification_Token_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPushNotificationTokenPkey = 'user_push_notification_token_pkey',
  /** unique or primary key constraint on columns "user_id", "token" */
  UserPushNotificationTokenUserIdTokenKey = 'user_push_notification_token_user_id_token_key',
}

/** input type for inserting data into table "user_push_notification_token" */
export type User_Push_Notification_Token_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  token?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Push_Notification_Token_Max_Fields = {
  __typename?: 'user_push_notification_token_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  token?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_push_notification_token" */
export type User_Push_Notification_Token_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  token?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Push_Notification_Token_Min_Fields = {
  __typename?: 'user_push_notification_token_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  token?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_push_notification_token" */
export type User_Push_Notification_Token_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  token?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_push_notification_token" */
export type User_Push_Notification_Token_Mutation_Response = {
  __typename?: 'user_push_notification_token_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Push_Notification_Token>
}

/** input type for inserting object relation for remote table "user_push_notification_token" */
export type User_Push_Notification_Token_Obj_Rel_Insert_Input = {
  data: User_Push_Notification_Token_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Push_Notification_Token_On_Conflict>
}

/** on_conflict condition type for table "user_push_notification_token" */
export type User_Push_Notification_Token_On_Conflict = {
  constraint: User_Push_Notification_Token_Constraint
  update_columns?: Array<User_Push_Notification_Token_Update_Column>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

/** Ordering options when selecting data from "user_push_notification_token". */
export type User_Push_Notification_Token_Order_By = {
  active?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  token?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_push_notification_token */
export type User_Push_Notification_Token_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_push_notification_token" */
export enum User_Push_Notification_Token_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "user_push_notification_token_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_push_notification_token" */
export enum User_Push_Notification_Token_Select_Column_User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** select "user_push_notification_token_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_push_notification_token" */
export enum User_Push_Notification_Token_Select_Column_User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** input type for updating data in table "user_push_notification_token" */
export type User_Push_Notification_Token_Set_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  token?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_push_notification_token" */
export type User_Push_Notification_Token_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Push_Notification_Token_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Push_Notification_Token_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  token?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_push_notification_token" */
export enum User_Push_Notification_Token_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Push_Notification_Token_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Push_Notification_Token_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Push_Notification_Token_Bool_Exp
}

export type User_Push_Notification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Push_Notification_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Push_Notification_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Push_Notification_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Push_Notification_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Push_Notification_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Push_Notification_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Push_Notification_Bool_Exp
}

/** Keep track of user referrals for cash reward payouts */
export type User_Referral = {
  __typename?: 'user_referral'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  promo_period_id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  referred_user: User
  referred_user_id: Scalars['String']
  /** An object relationship */
  referrer_user: User
  referrer_user_id: Scalars['String']
  reward_amount: Scalars['numeric']
  reward_redeemed_at?: Maybe<Scalars['timestamptz']>
  updated_at: Scalars['timestamptz']
  /** Computed field to determine if the referral is within the promo period. */
  within_promo_period?: Maybe<Scalars['Boolean']>
}

/** aggregated selection of "user_referral" */
export type User_Referral_Aggregate = {
  __typename?: 'user_referral_aggregate'
  aggregate?: Maybe<User_Referral_Aggregate_Fields>
  nodes: Array<User_Referral>
}

export type User_Referral_Aggregate_Bool_Exp = {
  count?: Maybe<User_Referral_Aggregate_Bool_Exp_Count>
}

export type User_Referral_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Referral_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Referral_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_referral" */
export type User_Referral_Aggregate_Fields = {
  __typename?: 'user_referral_aggregate_fields'
  avg?: Maybe<User_Referral_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Referral_Max_Fields>
  min?: Maybe<User_Referral_Min_Fields>
  stddev?: Maybe<User_Referral_Stddev_Fields>
  stddev_pop?: Maybe<User_Referral_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Referral_Stddev_Samp_Fields>
  sum?: Maybe<User_Referral_Sum_Fields>
  var_pop?: Maybe<User_Referral_Var_Pop_Fields>
  var_samp?: Maybe<User_Referral_Var_Samp_Fields>
  variance?: Maybe<User_Referral_Variance_Fields>
}

/** aggregate fields of "user_referral" */
export type User_Referral_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Referral_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_referral" */
export type User_Referral_Aggregate_Order_By = {
  avg?: Maybe<User_Referral_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<User_Referral_Max_Order_By>
  min?: Maybe<User_Referral_Min_Order_By>
  stddev?: Maybe<User_Referral_Stddev_Order_By>
  stddev_pop?: Maybe<User_Referral_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<User_Referral_Stddev_Samp_Order_By>
  sum?: Maybe<User_Referral_Sum_Order_By>
  var_pop?: Maybe<User_Referral_Var_Pop_Order_By>
  var_samp?: Maybe<User_Referral_Var_Samp_Order_By>
  variance?: Maybe<User_Referral_Variance_Order_By>
}

/** input type for inserting array relation for remote table "user_referral" */
export type User_Referral_Arr_Rel_Insert_Input = {
  data: Array<User_Referral_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Referral_On_Conflict>
}

/** aggregate avg on columns */
export type User_Referral_Avg_Fields = {
  __typename?: 'user_referral_avg_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "user_referral" */
export type User_Referral_Avg_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_referral". All fields are combined with a logical 'AND'. */
export type User_Referral_Bool_Exp = {
  _and?: Maybe<Array<User_Referral_Bool_Exp>>
  _not?: Maybe<User_Referral_Bool_Exp>
  _or?: Maybe<Array<User_Referral_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  promo_period_id?: Maybe<Uuid_Comparison_Exp>
  referred_user?: Maybe<User_Bool_Exp>
  referred_user_id?: Maybe<String_Comparison_Exp>
  referrer_user?: Maybe<User_Bool_Exp>
  referrer_user_id?: Maybe<String_Comparison_Exp>
  reward_amount?: Maybe<Numeric_Comparison_Exp>
  reward_redeemed_at?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  within_promo_period?: Maybe<Boolean_Comparison_Exp>
}

/** unique or primary key constraints on table "user_referral" */
export enum User_Referral_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserReferralPkey = 'user_referral_pkey',
  /** unique or primary key constraint on columns "referred_user_id" */
  UserReferredUserIdKey = 'user_referred_user_id_key',
}

/** input type for incrementing numeric columns in table "user_referral" */
export type User_Referral_Inc_Input = {
  reward_amount?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user_referral" */
export type User_Referral_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_period_id?: Maybe<Scalars['uuid']>
  referred_user?: Maybe<User_Obj_Rel_Insert_Input>
  referred_user_id?: Maybe<Scalars['String']>
  referrer_user?: Maybe<User_Obj_Rel_Insert_Input>
  referrer_user_id?: Maybe<Scalars['String']>
  reward_amount?: Maybe<Scalars['numeric']>
  reward_redeemed_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type User_Referral_Max_Fields = {
  __typename?: 'user_referral_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_period_id?: Maybe<Scalars['uuid']>
  referred_user_id?: Maybe<Scalars['String']>
  referrer_user_id?: Maybe<Scalars['String']>
  reward_amount?: Maybe<Scalars['numeric']>
  reward_redeemed_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "user_referral" */
export type User_Referral_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  promo_period_id?: Maybe<Order_By>
  referred_user_id?: Maybe<Order_By>
  referrer_user_id?: Maybe<Order_By>
  reward_amount?: Maybe<Order_By>
  reward_redeemed_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Referral_Min_Fields = {
  __typename?: 'user_referral_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_period_id?: Maybe<Scalars['uuid']>
  referred_user_id?: Maybe<Scalars['String']>
  referrer_user_id?: Maybe<Scalars['String']>
  reward_amount?: Maybe<Scalars['numeric']>
  reward_redeemed_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "user_referral" */
export type User_Referral_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  promo_period_id?: Maybe<Order_By>
  referred_user_id?: Maybe<Order_By>
  referrer_user_id?: Maybe<Order_By>
  reward_amount?: Maybe<Order_By>
  reward_redeemed_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "user_referral" */
export type User_Referral_Mutation_Response = {
  __typename?: 'user_referral_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Referral>
}

/** on_conflict condition type for table "user_referral" */
export type User_Referral_On_Conflict = {
  constraint: User_Referral_Constraint
  update_columns?: Array<User_Referral_Update_Column>
  where?: Maybe<User_Referral_Bool_Exp>
}

/** Ordering options when selecting data from "user_referral". */
export type User_Referral_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  promo_period_id?: Maybe<Order_By>
  referred_user?: Maybe<User_Order_By>
  referred_user_id?: Maybe<Order_By>
  referrer_user?: Maybe<User_Order_By>
  referrer_user_id?: Maybe<Order_By>
  reward_amount?: Maybe<Order_By>
  reward_redeemed_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  within_promo_period?: Maybe<Order_By>
}

/** primary key columns input for table: user_referral */
export type User_Referral_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_referral" */
export enum User_Referral_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PromoPeriodId = 'promo_period_id',
  /** column name */
  ReferredUserId = 'referred_user_id',
  /** column name */
  ReferrerUserId = 'referrer_user_id',
  /** column name */
  RewardAmount = 'reward_amount',
  /** column name */
  RewardRedeemedAt = 'reward_redeemed_at',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "user_referral" */
export type User_Referral_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_period_id?: Maybe<Scalars['uuid']>
  referred_user_id?: Maybe<Scalars['String']>
  referrer_user_id?: Maybe<Scalars['String']>
  reward_amount?: Maybe<Scalars['numeric']>
  reward_redeemed_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type User_Referral_Stddev_Fields = {
  __typename?: 'user_referral_stddev_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "user_referral" */
export type User_Referral_Stddev_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Referral_Stddev_Pop_Fields = {
  __typename?: 'user_referral_stddev_pop_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "user_referral" */
export type User_Referral_Stddev_Pop_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Referral_Stddev_Samp_Fields = {
  __typename?: 'user_referral_stddev_samp_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "user_referral" */
export type User_Referral_Stddev_Samp_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** Streaming cursor of the table "user_referral" */
export type User_Referral_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referral_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Referral_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_period_id?: Maybe<Scalars['uuid']>
  referred_user_id?: Maybe<Scalars['String']>
  referrer_user_id?: Maybe<Scalars['String']>
  reward_amount?: Maybe<Scalars['numeric']>
  reward_redeemed_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type User_Referral_Sum_Fields = {
  __typename?: 'user_referral_sum_fields'
  reward_amount?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "user_referral" */
export type User_Referral_Sum_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** update columns of table "user_referral" */
export enum User_Referral_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PromoPeriodId = 'promo_period_id',
  /** column name */
  ReferredUserId = 'referred_user_id',
  /** column name */
  ReferrerUserId = 'referrer_user_id',
  /** column name */
  RewardAmount = 'reward_amount',
  /** column name */
  RewardRedeemedAt = 'reward_redeemed_at',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type User_Referral_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Referral_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Referral_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Referral_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Referral_Var_Pop_Fields = {
  __typename?: 'user_referral_var_pop_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "user_referral" */
export type User_Referral_Var_Pop_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Referral_Var_Samp_Fields = {
  __typename?: 'user_referral_var_samp_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "user_referral" */
export type User_Referral_Var_Samp_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type User_Referral_Variance_Fields = {
  __typename?: 'user_referral_variance_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "user_referral" */
export type User_Referral_Variance_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** Used for one-off reward multiplier configs per user action */
export type User_Reward_Multiplier_Config = {
  __typename?: 'user_reward_multiplier_config'
  action_name?: Maybe<Rewardable_Action_Names_Enum>
  created_at: Scalars['timestamptz']
  expires_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  multiplier: Scalars['numeric']
  /** An object relationship */
  rewardable_action_name?: Maybe<Rewardable_Action_Names>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Aggregate = {
  __typename?: 'user_reward_multiplier_config_aggregate'
  aggregate?: Maybe<User_Reward_Multiplier_Config_Aggregate_Fields>
  nodes: Array<User_Reward_Multiplier_Config>
}

/** aggregate fields of "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Aggregate_Fields = {
  __typename?: 'user_reward_multiplier_config_aggregate_fields'
  avg?: Maybe<User_Reward_Multiplier_Config_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Reward_Multiplier_Config_Max_Fields>
  min?: Maybe<User_Reward_Multiplier_Config_Min_Fields>
  stddev?: Maybe<User_Reward_Multiplier_Config_Stddev_Fields>
  stddev_pop?: Maybe<User_Reward_Multiplier_Config_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Reward_Multiplier_Config_Stddev_Samp_Fields>
  sum?: Maybe<User_Reward_Multiplier_Config_Sum_Fields>
  var_pop?: Maybe<User_Reward_Multiplier_Config_Var_Pop_Fields>
  var_samp?: Maybe<User_Reward_Multiplier_Config_Var_Samp_Fields>
  variance?: Maybe<User_Reward_Multiplier_Config_Variance_Fields>
}

/** aggregate fields of "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Reward_Multiplier_Config_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type User_Reward_Multiplier_Config_Avg_Fields = {
  __typename?: 'user_reward_multiplier_config_avg_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "user_reward_multiplier_config". All fields are combined with a logical 'AND'. */
export type User_Reward_Multiplier_Config_Bool_Exp = {
  _and?: Maybe<Array<User_Reward_Multiplier_Config_Bool_Exp>>
  _not?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
  _or?: Maybe<Array<User_Reward_Multiplier_Config_Bool_Exp>>
  action_name?: Maybe<Rewardable_Action_Names_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  expires_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  multiplier?: Maybe<Numeric_Comparison_Exp>
  rewardable_action_name?: Maybe<Rewardable_Action_Names_Bool_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_reward_multiplier_config" */
export enum User_Reward_Multiplier_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRewardMultiplierConfigPkey = 'user_reward_multiplier_config_pkey',
}

/** input type for incrementing numeric columns in table "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Inc_Input = {
  multiplier?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Insert_Input = {
  action_name?: Maybe<Rewardable_Action_Names_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  expires_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  rewardable_action_name?: Maybe<Rewardable_Action_Names_Obj_Rel_Insert_Input>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Reward_Multiplier_Config_Max_Fields = {
  __typename?: 'user_reward_multiplier_config_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  expires_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Reward_Multiplier_Config_Min_Fields = {
  __typename?: 'user_reward_multiplier_config_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  expires_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Mutation_Response = {
  __typename?: 'user_reward_multiplier_config_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Reward_Multiplier_Config>
}

/** on_conflict condition type for table "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_On_Conflict = {
  constraint: User_Reward_Multiplier_Config_Constraint
  update_columns?: Array<User_Reward_Multiplier_Config_Update_Column>
  where?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
}

/** Ordering options when selecting data from "user_reward_multiplier_config". */
export type User_Reward_Multiplier_Config_Order_By = {
  action_name?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  expires_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  multiplier?: Maybe<Order_By>
  rewardable_action_name?: Maybe<Rewardable_Action_Names_Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_reward_multiplier_config */
export type User_Reward_Multiplier_Config_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_reward_multiplier_config" */
export enum User_Reward_Multiplier_Config_Select_Column {
  /** column name */
  ActionName = 'action_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Set_Input = {
  action_name?: Maybe<Rewardable_Action_Names_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  expires_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type User_Reward_Multiplier_Config_Stddev_Fields = {
  __typename?: 'user_reward_multiplier_config_stddev_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type User_Reward_Multiplier_Config_Stddev_Pop_Fields = {
  __typename?: 'user_reward_multiplier_config_stddev_pop_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type User_Reward_Multiplier_Config_Stddev_Samp_Fields = {
  __typename?: 'user_reward_multiplier_config_stddev_samp_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Reward_Multiplier_Config_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Reward_Multiplier_Config_Stream_Cursor_Value_Input = {
  action_name?: Maybe<Rewardable_Action_Names_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  expires_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Reward_Multiplier_Config_Sum_Fields = {
  __typename?: 'user_reward_multiplier_config_sum_fields'
  multiplier?: Maybe<Scalars['numeric']>
}

/** update columns of table "user_reward_multiplier_config" */
export enum User_Reward_Multiplier_Config_Update_Column {
  /** column name */
  ActionName = 'action_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Reward_Multiplier_Config_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Reward_Multiplier_Config_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Reward_Multiplier_Config_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Reward_Multiplier_Config_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Reward_Multiplier_Config_Var_Pop_Fields = {
  __typename?: 'user_reward_multiplier_config_var_pop_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type User_Reward_Multiplier_Config_Var_Samp_Fields = {
  __typename?: 'user_reward_multiplier_config_var_samp_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type User_Reward_Multiplier_Config_Variance_Fields = {
  __typename?: 'user_reward_multiplier_config_variance_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** Table to keep track of points redeemed by user based on rewards */
export type User_Reward_Redemption = {
  __typename?: 'user_reward_redemption'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  notes?: Maybe<Scalars['String']>
  points_redeemed: Scalars['numeric']
  redeemed_at?: Maybe<Scalars['timestamptz']>
  reward_reference_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
  /** An array relationship */
  user_reward_references: Array<User_Reward_Reference>
  /** An aggregate relationship */
  user_reward_references_aggregate: User_Reward_Reference_Aggregate
}

/** Table to keep track of points redeemed by user based on rewards */
export type User_Reward_RedemptionUser_Reward_ReferencesArgs = {
  distinct_on?: Maybe<Array<User_Reward_Reference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Reference_Order_By>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

/** Table to keep track of points redeemed by user based on rewards */
export type User_Reward_RedemptionUser_Reward_References_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Reference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Reference_Order_By>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

/** aggregated selection of "user_reward_redemption" */
export type User_Reward_Redemption_Aggregate = {
  __typename?: 'user_reward_redemption_aggregate'
  aggregate?: Maybe<User_Reward_Redemption_Aggregate_Fields>
  nodes: Array<User_Reward_Redemption>
}

/** aggregate fields of "user_reward_redemption" */
export type User_Reward_Redemption_Aggregate_Fields = {
  __typename?: 'user_reward_redemption_aggregate_fields'
  avg?: Maybe<User_Reward_Redemption_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Reward_Redemption_Max_Fields>
  min?: Maybe<User_Reward_Redemption_Min_Fields>
  stddev?: Maybe<User_Reward_Redemption_Stddev_Fields>
  stddev_pop?: Maybe<User_Reward_Redemption_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Reward_Redemption_Stddev_Samp_Fields>
  sum?: Maybe<User_Reward_Redemption_Sum_Fields>
  var_pop?: Maybe<User_Reward_Redemption_Var_Pop_Fields>
  var_samp?: Maybe<User_Reward_Redemption_Var_Samp_Fields>
  variance?: Maybe<User_Reward_Redemption_Variance_Fields>
}

/** aggregate fields of "user_reward_redemption" */
export type User_Reward_Redemption_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Reward_Redemption_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type User_Reward_Redemption_Avg_Fields = {
  __typename?: 'user_reward_redemption_avg_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "user_reward_redemption". All fields are combined with a logical 'AND'. */
export type User_Reward_Redemption_Bool_Exp = {
  _and?: Maybe<Array<User_Reward_Redemption_Bool_Exp>>
  _not?: Maybe<User_Reward_Redemption_Bool_Exp>
  _or?: Maybe<Array<User_Reward_Redemption_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notes?: Maybe<String_Comparison_Exp>
  points_redeemed?: Maybe<Numeric_Comparison_Exp>
  redeemed_at?: Maybe<Timestamptz_Comparison_Exp>
  reward_reference_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  user_reward_references?: Maybe<User_Reward_Reference_Bool_Exp>
  user_reward_references_aggregate?: Maybe<User_Reward_Reference_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "user_reward_redemption" */
export enum User_Reward_Redemption_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRewardRedemptionPkey = 'user_reward_redemption_pkey',
}

/** input type for incrementing numeric columns in table "user_reward_redemption" */
export type User_Reward_Redemption_Inc_Input = {
  points_redeemed?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user_reward_redemption" */
export type User_Reward_Redemption_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  points_redeemed?: Maybe<Scalars['numeric']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  reward_reference_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  user_reward_references?: Maybe<User_Reward_Reference_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type User_Reward_Redemption_Max_Fields = {
  __typename?: 'user_reward_redemption_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  points_redeemed?: Maybe<Scalars['numeric']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  reward_reference_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Reward_Redemption_Min_Fields = {
  __typename?: 'user_reward_redemption_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  points_redeemed?: Maybe<Scalars['numeric']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  reward_reference_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_reward_redemption" */
export type User_Reward_Redemption_Mutation_Response = {
  __typename?: 'user_reward_redemption_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Reward_Redemption>
}

/** input type for inserting object relation for remote table "user_reward_redemption" */
export type User_Reward_Redemption_Obj_Rel_Insert_Input = {
  data: User_Reward_Redemption_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Reward_Redemption_On_Conflict>
}

/** on_conflict condition type for table "user_reward_redemption" */
export type User_Reward_Redemption_On_Conflict = {
  constraint: User_Reward_Redemption_Constraint
  update_columns?: Array<User_Reward_Redemption_Update_Column>
  where?: Maybe<User_Reward_Redemption_Bool_Exp>
}

/** Ordering options when selecting data from "user_reward_redemption". */
export type User_Reward_Redemption_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notes?: Maybe<Order_By>
  points_redeemed?: Maybe<Order_By>
  redeemed_at?: Maybe<Order_By>
  reward_reference_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  user_reward_references_aggregate?: Maybe<User_Reward_Reference_Aggregate_Order_By>
}

/** primary key columns input for table: user_reward_redemption */
export type User_Reward_Redemption_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_reward_redemption" */
export enum User_Reward_Redemption_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PointsRedeemed = 'points_redeemed',
  /** column name */
  RedeemedAt = 'redeemed_at',
  /** column name */
  RewardReferenceId = 'reward_reference_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_reward_redemption" */
export type User_Reward_Redemption_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  points_redeemed?: Maybe<Scalars['numeric']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  reward_reference_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type User_Reward_Redemption_Stddev_Fields = {
  __typename?: 'user_reward_redemption_stddev_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type User_Reward_Redemption_Stddev_Pop_Fields = {
  __typename?: 'user_reward_redemption_stddev_pop_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type User_Reward_Redemption_Stddev_Samp_Fields = {
  __typename?: 'user_reward_redemption_stddev_samp_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "user_reward_redemption" */
export type User_Reward_Redemption_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Reward_Redemption_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Reward_Redemption_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  points_redeemed?: Maybe<Scalars['numeric']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  reward_reference_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Reward_Redemption_Sum_Fields = {
  __typename?: 'user_reward_redemption_sum_fields'
  points_redeemed?: Maybe<Scalars['numeric']>
}

/** update columns of table "user_reward_redemption" */
export enum User_Reward_Redemption_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PointsRedeemed = 'points_redeemed',
  /** column name */
  RedeemedAt = 'redeemed_at',
  /** column name */
  RewardReferenceId = 'reward_reference_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Reward_Redemption_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Reward_Redemption_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Reward_Redemption_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Reward_Redemption_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Reward_Redemption_Var_Pop_Fields = {
  __typename?: 'user_reward_redemption_var_pop_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type User_Reward_Redemption_Var_Samp_Fields = {
  __typename?: 'user_reward_redemption_var_samp_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type User_Reward_Redemption_Variance_Fields = {
  __typename?: 'user_reward_redemption_variance_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** Reference table to keep track of rewards issued to user (ie, Gift cards) */
export type User_Reward_Reference = {
  __typename?: 'user_reward_reference'
  amount: Scalars['numeric']
  created_at: Scalars['timestamptz']
  currency: Scalars['String']
  id: Scalars['uuid']
  notes?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  reference_id: Scalars['String']
  reward_details?: Maybe<Scalars['jsonb']>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
  /** An object relationship */
  user_reward_redemption?: Maybe<User_Reward_Redemption>
  user_reward_redemption_id?: Maybe<Scalars['uuid']>
}

/** Reference table to keep track of rewards issued to user (ie, Gift cards) */
export type User_Reward_ReferenceReward_DetailsArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_reward_reference" */
export type User_Reward_Reference_Aggregate = {
  __typename?: 'user_reward_reference_aggregate'
  aggregate?: Maybe<User_Reward_Reference_Aggregate_Fields>
  nodes: Array<User_Reward_Reference>
}

export type User_Reward_Reference_Aggregate_Bool_Exp = {
  count?: Maybe<User_Reward_Reference_Aggregate_Bool_Exp_Count>
}

export type User_Reward_Reference_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Reward_Reference_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Reward_Reference_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_reward_reference" */
export type User_Reward_Reference_Aggregate_Fields = {
  __typename?: 'user_reward_reference_aggregate_fields'
  avg?: Maybe<User_Reward_Reference_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Reward_Reference_Max_Fields>
  min?: Maybe<User_Reward_Reference_Min_Fields>
  stddev?: Maybe<User_Reward_Reference_Stddev_Fields>
  stddev_pop?: Maybe<User_Reward_Reference_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Reward_Reference_Stddev_Samp_Fields>
  sum?: Maybe<User_Reward_Reference_Sum_Fields>
  var_pop?: Maybe<User_Reward_Reference_Var_Pop_Fields>
  var_samp?: Maybe<User_Reward_Reference_Var_Samp_Fields>
  variance?: Maybe<User_Reward_Reference_Variance_Fields>
}

/** aggregate fields of "user_reward_reference" */
export type User_Reward_Reference_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Reward_Reference_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_reward_reference" */
export type User_Reward_Reference_Aggregate_Order_By = {
  avg?: Maybe<User_Reward_Reference_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<User_Reward_Reference_Max_Order_By>
  min?: Maybe<User_Reward_Reference_Min_Order_By>
  stddev?: Maybe<User_Reward_Reference_Stddev_Order_By>
  stddev_pop?: Maybe<User_Reward_Reference_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<User_Reward_Reference_Stddev_Samp_Order_By>
  sum?: Maybe<User_Reward_Reference_Sum_Order_By>
  var_pop?: Maybe<User_Reward_Reference_Var_Pop_Order_By>
  var_samp?: Maybe<User_Reward_Reference_Var_Samp_Order_By>
  variance?: Maybe<User_Reward_Reference_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Reward_Reference_Append_Input = {
  reward_details?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "user_reward_reference" */
export type User_Reward_Reference_Arr_Rel_Insert_Input = {
  data: Array<User_Reward_Reference_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Reward_Reference_On_Conflict>
}

/** aggregate avg on columns */
export type User_Reward_Reference_Avg_Fields = {
  __typename?: 'user_reward_reference_avg_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Avg_Order_By = {
  amount?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_reward_reference". All fields are combined with a logical 'AND'. */
export type User_Reward_Reference_Bool_Exp = {
  _and?: Maybe<Array<User_Reward_Reference_Bool_Exp>>
  _not?: Maybe<User_Reward_Reference_Bool_Exp>
  _or?: Maybe<Array<User_Reward_Reference_Bool_Exp>>
  amount?: Maybe<Numeric_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  currency?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notes?: Maybe<String_Comparison_Exp>
  provider?: Maybe<String_Comparison_Exp>
  reference_id?: Maybe<String_Comparison_Exp>
  reward_details?: Maybe<Jsonb_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  user_reward_redemption?: Maybe<User_Reward_Redemption_Bool_Exp>
  user_reward_redemption_id?: Maybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "user_reward_reference" */
export enum User_Reward_Reference_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRewardReferencePkey = 'user_reward_reference_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Reward_Reference_Delete_At_Path_Input = {
  reward_details?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Reward_Reference_Delete_Elem_Input = {
  reward_details?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Reward_Reference_Delete_Key_Input = {
  reward_details?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "user_reward_reference" */
export type User_Reward_Reference_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user_reward_reference" */
export type User_Reward_Reference_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  reference_id?: Maybe<Scalars['String']>
  reward_details?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  user_reward_redemption?: Maybe<User_Reward_Redemption_Obj_Rel_Insert_Input>
  user_reward_redemption_id?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type User_Reward_Reference_Max_Fields = {
  __typename?: 'user_reward_reference_max_fields'
  amount?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  reference_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  user_reward_redemption_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Max_Order_By = {
  amount?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notes?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  reference_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  user_reward_redemption_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Reward_Reference_Min_Fields = {
  __typename?: 'user_reward_reference_min_fields'
  amount?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  reference_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  user_reward_redemption_id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Min_Order_By = {
  amount?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notes?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  reference_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  user_reward_redemption_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_reward_reference" */
export type User_Reward_Reference_Mutation_Response = {
  __typename?: 'user_reward_reference_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Reward_Reference>
}

/** on_conflict condition type for table "user_reward_reference" */
export type User_Reward_Reference_On_Conflict = {
  constraint: User_Reward_Reference_Constraint
  update_columns?: Array<User_Reward_Reference_Update_Column>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

/** Ordering options when selecting data from "user_reward_reference". */
export type User_Reward_Reference_Order_By = {
  amount?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notes?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  reference_id?: Maybe<Order_By>
  reward_details?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  user_reward_redemption?: Maybe<User_Reward_Redemption_Order_By>
  user_reward_redemption_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_reward_reference */
export type User_Reward_Reference_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Reward_Reference_Prepend_Input = {
  reward_details?: Maybe<Scalars['jsonb']>
}

/** select columns of table "user_reward_reference" */
export enum User_Reward_Reference_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Provider = 'provider',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  RewardDetails = 'reward_details',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserRewardRedemptionId = 'user_reward_redemption_id',
}

/** input type for updating data in table "user_reward_reference" */
export type User_Reward_Reference_Set_Input = {
  amount?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  reference_id?: Maybe<Scalars['String']>
  reward_details?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  user_reward_redemption_id?: Maybe<Scalars['uuid']>
}

/** aggregate stddev on columns */
export type User_Reward_Reference_Stddev_Fields = {
  __typename?: 'user_reward_reference_stddev_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Stddev_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Reward_Reference_Stddev_Pop_Fields = {
  __typename?: 'user_reward_reference_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Reward_Reference_Stddev_Samp_Fields = {
  __typename?: 'user_reward_reference_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>
}

/** Streaming cursor of the table "user_reward_reference" */
export type User_Reward_Reference_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Reward_Reference_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Reward_Reference_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  reference_id?: Maybe<Scalars['String']>
  reward_details?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  user_reward_redemption_id?: Maybe<Scalars['uuid']>
}

/** aggregate sum on columns */
export type User_Reward_Reference_Sum_Fields = {
  __typename?: 'user_reward_reference_sum_fields'
  amount?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Sum_Order_By = {
  amount?: Maybe<Order_By>
}

/** update columns of table "user_reward_reference" */
export enum User_Reward_Reference_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Provider = 'provider',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  RewardDetails = 'reward_details',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserRewardRedemptionId = 'user_reward_redemption_id',
}

export type User_Reward_Reference_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Reward_Reference_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Reward_Reference_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Reward_Reference_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Reward_Reference_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Reward_Reference_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Reward_Reference_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Reward_Reference_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Reward_Reference_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Reward_Reference_Var_Pop_Fields = {
  __typename?: 'user_reward_reference_var_pop_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Reward_Reference_Var_Samp_Fields = {
  __typename?: 'user_reward_reference_var_samp_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type User_Reward_Reference_Variance_Fields = {
  __typename?: 'user_reward_reference_variance_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Variance_Order_By = {
  amount?: Maybe<Order_By>
}

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Anonymous = 'anonymous',
  /** column name */
  AppliedAsCreatorAt = 'applied_as_creator_at',
  /** column name */
  Bio = 'bio',
  /** column name */
  BioLink = 'bio_link',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedReason = 'deleted_reason',
  /** column name */
  DisabledAt = 'disabled_at',
  /** column name */
  DisabledReason = 'disabled_reason',
  /** column name */
  Email = 'email',
  /** column name */
  HasPremiumSubscription = 'has_premium_subscription',
  /** column name */
  IconOrder = 'icon_order',
  /** column name */
  Id = 'id',
  /** column name */
  InstalledAt = 'installed_at',
  /** column name */
  InternalAccount = 'internal_account',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OgimageFingerprint = 'ogimage_fingerprint',
  /** column name */
  OgimageFingerprintUpdated = 'ogimage_fingerprint_updated',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  OrphanedAt = 'orphaned_at',
  /** column name */
  OrphanedBy = 'orphaned_by',
  /** column name */
  Phone = 'phone',
  /** column name */
  Picture = 'picture',
  /** column name */
  PossibleEmail = 'possible_email',
  /** column name */
  ProbablyReal = 'probably_real',
  /** column name */
  ReferrerUserId = 'referrer_user_id',
  /** column name */
  Role = 'role',
  /** column name */
  ShoppingPreference = 'shopping_preference',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  ShowCurations = 'show_curations',
  /** column name */
  ShowOnboarding = 'show_onboarding',
  /** column name */
  ShowRabbithole = 'show_rabbithole',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserType = 'user_type',
  /** column name */
  Username = 'username',
  /** column name */
  Verified = 'verified',
  /** column name */
  Version = 'version',
  /** column name */
  Waitlist = 'waitlist',
}

/** select "user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Anonymous = 'anonymous',
  /** column name */
  HasPremiumSubscription = 'has_premium_subscription',
  /** column name */
  InternalAccount = 'internal_account',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  ProbablyReal = 'probably_real',
  /** column name */
  ShowCurations = 'show_curations',
  /** column name */
  ShowOnboarding = 'show_onboarding',
  /** column name */
  ShowRabbithole = 'show_rabbithole',
  /** column name */
  Verified = 'verified',
  /** column name */
  Waitlist = 'waitlist',
}

/** select "user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Anonymous = 'anonymous',
  /** column name */
  HasPremiumSubscription = 'has_premium_subscription',
  /** column name */
  InternalAccount = 'internal_account',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  ProbablyReal = 'probably_real',
  /** column name */
  ShowCurations = 'show_curations',
  /** column name */
  ShowOnboarding = 'show_onboarding',
  /** column name */
  ShowRabbithole = 'show_rabbithole',
  /** column name */
  Verified = 'verified',
  /** column name */
  Waitlist = 'waitlist',
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  anonymous?: Maybe<Scalars['Boolean']>
  applied_as_creator_at?: Maybe<Scalars['timestamptz']>
  bio?: Maybe<Scalars['String']>
  bio_link?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  deleted_reason?: Maybe<Scalars['String']>
  disabled_at?: Maybe<Scalars['timestamptz']>
  disabled_reason?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  has_premium_subscription?: Maybe<Scalars['Boolean']>
  icon_order?: Maybe<Scalars['numeric']>
  id?: Maybe<Scalars['String']>
  installed_at?: Maybe<Scalars['timestamptz']>
  internal_account?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['String']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  onboarded?: Maybe<Scalars['Boolean']>
  orphaned_at?: Maybe<Scalars['timestamptz']>
  orphaned_by?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  possible_email?: Maybe<Scalars['String']>
  referrer_user_id?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['name']>
  shopping_preference?: Maybe<User_Shopping_Preferences_Enum>
  short_id?: Maybe<Scalars['name']>
  show_curations?: Maybe<Scalars['Boolean']>
  show_onboarding?: Maybe<Scalars['Boolean']>
  show_rabbithole?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_type?: Maybe<User_Account_Type_Enum>
  username?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
  version?: Maybe<Scalars['String']>
  waitlist?: Maybe<Scalars['Boolean']>
}

/** User favorite shops */
export type User_Shop_Favorite = {
  __typename?: 'user_shop_favorite'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  shop_key: Scalars['String']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "user_shop_favorite" */
export type User_Shop_Favorite_Aggregate = {
  __typename?: 'user_shop_favorite_aggregate'
  aggregate?: Maybe<User_Shop_Favorite_Aggregate_Fields>
  nodes: Array<User_Shop_Favorite>
}

/** aggregate fields of "user_shop_favorite" */
export type User_Shop_Favorite_Aggregate_Fields = {
  __typename?: 'user_shop_favorite_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Shop_Favorite_Max_Fields>
  min?: Maybe<User_Shop_Favorite_Min_Fields>
}

/** aggregate fields of "user_shop_favorite" */
export type User_Shop_Favorite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Shop_Favorite_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_shop_favorite". All fields are combined with a logical 'AND'. */
export type User_Shop_Favorite_Bool_Exp = {
  _and?: Maybe<Array<User_Shop_Favorite_Bool_Exp>>
  _not?: Maybe<User_Shop_Favorite_Bool_Exp>
  _or?: Maybe<Array<User_Shop_Favorite_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_shop_favorite" */
export enum User_Shop_Favorite_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserShopFavoritePkey = 'user_shop_favorite_pkey',
  /** unique or primary key constraint on columns "shop_key", "user_id" */
  UserShopFavoriteUserIdShopKeyKey = 'user_shop_favorite_user_id_shop_key_key',
}

/** input type for inserting data into table "user_shop_favorite" */
export type User_Shop_Favorite_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Shop_Favorite_Max_Fields = {
  __typename?: 'user_shop_favorite_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Shop_Favorite_Min_Fields = {
  __typename?: 'user_shop_favorite_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_shop_favorite" */
export type User_Shop_Favorite_Mutation_Response = {
  __typename?: 'user_shop_favorite_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Shop_Favorite>
}

/** on_conflict condition type for table "user_shop_favorite" */
export type User_Shop_Favorite_On_Conflict = {
  constraint: User_Shop_Favorite_Constraint
  update_columns?: Array<User_Shop_Favorite_Update_Column>
  where?: Maybe<User_Shop_Favorite_Bool_Exp>
}

/** Ordering options when selecting data from "user_shop_favorite". */
export type User_Shop_Favorite_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_shop_favorite */
export type User_Shop_Favorite_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_shop_favorite" */
export enum User_Shop_Favorite_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_shop_favorite" */
export type User_Shop_Favorite_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_shop_favorite" */
export type User_Shop_Favorite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Shop_Favorite_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Shop_Favorite_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_shop_favorite" */
export enum User_Shop_Favorite_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Shop_Favorite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Shop_Favorite_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Shop_Favorite_Bool_Exp
}

/** Enum table to track user shopping preference (ie, Men's, Women's, Anything) */
export type User_Shopping_Preferences = {
  __typename?: 'user_shopping_preferences'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "user_shopping_preferences" */
export type User_Shopping_Preferences_Aggregate = {
  __typename?: 'user_shopping_preferences_aggregate'
  aggregate?: Maybe<User_Shopping_Preferences_Aggregate_Fields>
  nodes: Array<User_Shopping_Preferences>
}

/** aggregate fields of "user_shopping_preferences" */
export type User_Shopping_Preferences_Aggregate_Fields = {
  __typename?: 'user_shopping_preferences_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Shopping_Preferences_Max_Fields>
  min?: Maybe<User_Shopping_Preferences_Min_Fields>
}

/** aggregate fields of "user_shopping_preferences" */
export type User_Shopping_Preferences_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Shopping_Preferences_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_shopping_preferences". All fields are combined with a logical 'AND'. */
export type User_Shopping_Preferences_Bool_Exp = {
  _and?: Maybe<Array<User_Shopping_Preferences_Bool_Exp>>
  _not?: Maybe<User_Shopping_Preferences_Bool_Exp>
  _or?: Maybe<Array<User_Shopping_Preferences_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_shopping_preferences" */
export enum User_Shopping_Preferences_Constraint {
  /** unique or primary key constraint on columns "value" */
  ShoppingPreferencesPkey = 'shopping_preferences_pkey',
}

export enum User_Shopping_Preferences_Enum {
  /** Everything */
  Everything = 'everything',
  /** Men's */
  Mens = 'mens',
  /** Women's */
  Womens = 'womens',
}

/** Boolean expression to compare columns of type "user_shopping_preferences_enum". All fields are combined with logical 'AND'. */
export type User_Shopping_Preferences_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Shopping_Preferences_Enum>
  _in?: Maybe<Array<User_Shopping_Preferences_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<User_Shopping_Preferences_Enum>
  _nin?: Maybe<Array<User_Shopping_Preferences_Enum>>
}

/** input type for inserting data into table "user_shopping_preferences" */
export type User_Shopping_Preferences_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Shopping_Preferences_Max_Fields = {
  __typename?: 'user_shopping_preferences_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Shopping_Preferences_Min_Fields = {
  __typename?: 'user_shopping_preferences_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_shopping_preferences" */
export type User_Shopping_Preferences_Mutation_Response = {
  __typename?: 'user_shopping_preferences_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Shopping_Preferences>
}

/** input type for inserting object relation for remote table "user_shopping_preferences" */
export type User_Shopping_Preferences_Obj_Rel_Insert_Input = {
  data: User_Shopping_Preferences_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Shopping_Preferences_On_Conflict>
}

/** on_conflict condition type for table "user_shopping_preferences" */
export type User_Shopping_Preferences_On_Conflict = {
  constraint: User_Shopping_Preferences_Constraint
  update_columns?: Array<User_Shopping_Preferences_Update_Column>
  where?: Maybe<User_Shopping_Preferences_Bool_Exp>
}

/** Ordering options when selecting data from "user_shopping_preferences". */
export type User_Shopping_Preferences_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: user_shopping_preferences */
export type User_Shopping_Preferences_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "user_shopping_preferences" */
export enum User_Shopping_Preferences_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "user_shopping_preferences" */
export type User_Shopping_Preferences_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_shopping_preferences" */
export type User_Shopping_Preferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Shopping_Preferences_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Shopping_Preferences_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "user_shopping_preferences" */
export enum User_Shopping_Preferences_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type User_Shopping_Preferences_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Shopping_Preferences_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Shopping_Preferences_Bool_Exp
}

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by stddev() on columns of table "user" */
export type User_Stddev_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by stddev_pop() on columns of table "user" */
export type User_Stddev_Pop_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by stddev_samp() on columns of table "user" */
export type User_Stddev_Samp_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  anonymous?: Maybe<Scalars['Boolean']>
  applied_as_creator_at?: Maybe<Scalars['timestamptz']>
  bio?: Maybe<Scalars['String']>
  bio_link?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  deleted_reason?: Maybe<Scalars['String']>
  disabled_at?: Maybe<Scalars['timestamptz']>
  disabled_reason?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  has_premium_subscription?: Maybe<Scalars['Boolean']>
  icon_order?: Maybe<Scalars['numeric']>
  id?: Maybe<Scalars['String']>
  installed_at?: Maybe<Scalars['timestamptz']>
  internal_account?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['String']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  onboarded?: Maybe<Scalars['Boolean']>
  orphaned_at?: Maybe<Scalars['timestamptz']>
  orphaned_by?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  possible_email?: Maybe<Scalars['String']>
  probably_real?: Maybe<Scalars['Boolean']>
  referrer_user_id?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['name']>
  shopping_preference?: Maybe<User_Shopping_Preferences_Enum>
  short_id?: Maybe<Scalars['name']>
  show_curations?: Maybe<Scalars['Boolean']>
  show_onboarding?: Maybe<Scalars['Boolean']>
  show_rabbithole?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_type?: Maybe<User_Account_Type_Enum>
  username?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
  version?: Maybe<Scalars['String']>
  waitlist?: Maybe<Scalars['Boolean']>
}

/** A table to hold the relationship of what users are subscribed to other users */
export type User_Subscription = {
  __typename?: 'user_subscription'
  billing_mode: Billing_Mode_Enum
  cancel_at?: Maybe<Scalars['timestamptz']>
  created_at: Scalars['timestamptz']
  /** An object relationship */
  creator: User
  /** A computed field, executes function "user_subscription_creator_has_billing_account" */
  creator_has_billing_account?: Maybe<Scalars['Boolean']>
  creator_id: Scalars['String']
  deleted_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  plan: Scalars['jsonb']
  status: User_Subscription_Status_Enum
  /** An object relationship */
  subscriber: User
  subscription_id?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_subscription_type" */
  subscription_type?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** A table to hold the relationship of what users are subscribed to other users */
export type User_SubscriptionPlanArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_subscription" */
export type User_Subscription_Aggregate = {
  __typename?: 'user_subscription_aggregate'
  aggregate?: Maybe<User_Subscription_Aggregate_Fields>
  nodes: Array<User_Subscription>
}

/** aggregate fields of "user_subscription" */
export type User_Subscription_Aggregate_Fields = {
  __typename?: 'user_subscription_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Subscription_Max_Fields>
  min?: Maybe<User_Subscription_Min_Fields>
}

/** aggregate fields of "user_subscription" */
export type User_Subscription_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Subscription_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Subscription_Append_Input = {
  plan?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "user_subscription". All fields are combined with a logical 'AND'. */
export type User_Subscription_Bool_Exp = {
  _and?: Maybe<Array<User_Subscription_Bool_Exp>>
  _not?: Maybe<User_Subscription_Bool_Exp>
  _or?: Maybe<Array<User_Subscription_Bool_Exp>>
  billing_mode?: Maybe<Billing_Mode_Enum_Comparison_Exp>
  cancel_at?: Maybe<Timestamptz_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  creator?: Maybe<User_Bool_Exp>
  creator_has_billing_account?: Maybe<Boolean_Comparison_Exp>
  creator_id?: Maybe<String_Comparison_Exp>
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  plan?: Maybe<Jsonb_Comparison_Exp>
  status?: Maybe<User_Subscription_Status_Enum_Comparison_Exp>
  subscriber?: Maybe<User_Bool_Exp>
  subscription_id?: Maybe<String_Comparison_Exp>
  subscription_type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_subscription" */
export enum User_Subscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSubscriptionPkey = 'user_subscription_pkey',
  /** unique or primary key constraint on columns "user_id", "creator_id", "billing_mode" */
  UserSubscriptionUserIdCreatorIdBillingModeKey = 'user_subscription_user_id_creator_id_billing_mode_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Subscription_Delete_At_Path_Input = {
  plan?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Subscription_Delete_Elem_Input = {
  plan?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Subscription_Delete_Key_Input = {
  plan?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "user_subscription" */
export type User_Subscription_Insert_Input = {
  billing_mode?: Maybe<Billing_Mode_Enum>
  cancel_at?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator?: Maybe<User_Obj_Rel_Insert_Input>
  creator_id?: Maybe<Scalars['String']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  plan?: Maybe<Scalars['jsonb']>
  status?: Maybe<User_Subscription_Status_Enum>
  subscriber?: Maybe<User_Obj_Rel_Insert_Input>
  subscription_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Subscription_Max_Fields = {
  __typename?: 'user_subscription_max_fields'
  cancel_at?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  subscription_id?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_subscription_type" */
  subscription_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Subscription_Min_Fields = {
  __typename?: 'user_subscription_min_fields'
  cancel_at?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  subscription_id?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_subscription_type" */
  subscription_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_subscription" */
export type User_Subscription_Mutation_Response = {
  __typename?: 'user_subscription_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Subscription>
}

/** on_conflict condition type for table "user_subscription" */
export type User_Subscription_On_Conflict = {
  constraint: User_Subscription_Constraint
  update_columns?: Array<User_Subscription_Update_Column>
  where?: Maybe<User_Subscription_Bool_Exp>
}

/** Ordering options when selecting data from "user_subscription". */
export type User_Subscription_Order_By = {
  billing_mode?: Maybe<Order_By>
  cancel_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator?: Maybe<User_Order_By>
  creator_has_billing_account?: Maybe<Order_By>
  creator_id?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  plan?: Maybe<Order_By>
  status?: Maybe<Order_By>
  subscriber?: Maybe<User_Order_By>
  subscription_id?: Maybe<Order_By>
  subscription_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_subscription */
export type User_Subscription_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Subscription_Prepend_Input = {
  plan?: Maybe<Scalars['jsonb']>
}

/** select columns of table "user_subscription" */
export enum User_Subscription_Select_Column {
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Plan = 'plan',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_subscription" */
export type User_Subscription_Set_Input = {
  billing_mode?: Maybe<Billing_Mode_Enum>
  cancel_at?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  plan?: Maybe<Scalars['jsonb']>
  status?: Maybe<User_Subscription_Status_Enum>
  subscription_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** A table to hold the status of a user subscription */
export type User_Subscription_Status = {
  __typename?: 'user_subscription_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "user_subscription_status" */
export type User_Subscription_Status_Aggregate = {
  __typename?: 'user_subscription_status_aggregate'
  aggregate?: Maybe<User_Subscription_Status_Aggregate_Fields>
  nodes: Array<User_Subscription_Status>
}

/** aggregate fields of "user_subscription_status" */
export type User_Subscription_Status_Aggregate_Fields = {
  __typename?: 'user_subscription_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Subscription_Status_Max_Fields>
  min?: Maybe<User_Subscription_Status_Min_Fields>
}

/** aggregate fields of "user_subscription_status" */
export type User_Subscription_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Subscription_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_subscription_status". All fields are combined with a logical 'AND'. */
export type User_Subscription_Status_Bool_Exp = {
  _and?: Maybe<Array<User_Subscription_Status_Bool_Exp>>
  _not?: Maybe<User_Subscription_Status_Bool_Exp>
  _or?: Maybe<Array<User_Subscription_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_subscription_status" */
export enum User_Subscription_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  UsersSubscriptionStatusPkey = 'users_subscription_status_pkey',
}

export enum User_Subscription_Status_Enum {
  Active = 'active',
  Deleted = 'deleted',
}

/** Boolean expression to compare columns of type "user_subscription_status_enum". All fields are combined with logical 'AND'. */
export type User_Subscription_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Subscription_Status_Enum>
  _in?: Maybe<Array<User_Subscription_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<User_Subscription_Status_Enum>
  _nin?: Maybe<Array<User_Subscription_Status_Enum>>
}

/** input type for inserting data into table "user_subscription_status" */
export type User_Subscription_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Subscription_Status_Max_Fields = {
  __typename?: 'user_subscription_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Subscription_Status_Min_Fields = {
  __typename?: 'user_subscription_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_subscription_status" */
export type User_Subscription_Status_Mutation_Response = {
  __typename?: 'user_subscription_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Subscription_Status>
}

/** on_conflict condition type for table "user_subscription_status" */
export type User_Subscription_Status_On_Conflict = {
  constraint: User_Subscription_Status_Constraint
  update_columns?: Array<User_Subscription_Status_Update_Column>
  where?: Maybe<User_Subscription_Status_Bool_Exp>
}

/** Ordering options when selecting data from "user_subscription_status". */
export type User_Subscription_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: user_subscription_status */
export type User_Subscription_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "user_subscription_status" */
export enum User_Subscription_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "user_subscription_status" */
export type User_Subscription_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_subscription_status" */
export type User_Subscription_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Subscription_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Subscription_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "user_subscription_status" */
export enum User_Subscription_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type User_Subscription_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Subscription_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Subscription_Status_Bool_Exp
}

/** Streaming cursor of the table "user_subscription" */
export type User_Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Subscription_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Subscription_Stream_Cursor_Value_Input = {
  billing_mode?: Maybe<Billing_Mode_Enum>
  cancel_at?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  plan?: Maybe<Scalars['jsonb']>
  status?: Maybe<User_Subscription_Status_Enum>
  subscription_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_subscription" */
export enum User_Subscription_Update_Column {
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Plan = 'plan',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Subscription_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Subscription_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Subscription_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Subscription_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Subscription_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Subscription_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Subscription_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Subscription_Bool_Exp
}

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "user" */
export type User_Sum_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** Temp table for constraint testing */
export type User_Temp = {
  __typename?: 'user_temp'
  email?: Maybe<Scalars['String']>
  id: Scalars['uuid']
}

/** aggregated selection of "user_temp" */
export type User_Temp_Aggregate = {
  __typename?: 'user_temp_aggregate'
  aggregate?: Maybe<User_Temp_Aggregate_Fields>
  nodes: Array<User_Temp>
}

/** aggregate fields of "user_temp" */
export type User_Temp_Aggregate_Fields = {
  __typename?: 'user_temp_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Temp_Max_Fields>
  min?: Maybe<User_Temp_Min_Fields>
}

/** aggregate fields of "user_temp" */
export type User_Temp_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Temp_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_temp". All fields are combined with a logical 'AND'. */
export type User_Temp_Bool_Exp = {
  _and?: Maybe<Array<User_Temp_Bool_Exp>>
  _not?: Maybe<User_Temp_Bool_Exp>
  _or?: Maybe<Array<User_Temp_Bool_Exp>>
  email?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "user_temp" */
export enum User_Temp_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserTempEmailKey = 'user_temp_email_key',
  /** unique or primary key constraint on columns "id" */
  UserTempPkey = 'user_temp_pkey',
}

/** input type for inserting data into table "user_temp" */
export type User_Temp_Insert_Input = {
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type User_Temp_Max_Fields = {
  __typename?: 'user_temp_max_fields'
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type User_Temp_Min_Fields = {
  __typename?: 'user_temp_min_fields'
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "user_temp" */
export type User_Temp_Mutation_Response = {
  __typename?: 'user_temp_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Temp>
}

/** on_conflict condition type for table "user_temp" */
export type User_Temp_On_Conflict = {
  constraint: User_Temp_Constraint
  update_columns?: Array<User_Temp_Update_Column>
  where?: Maybe<User_Temp_Bool_Exp>
}

/** Ordering options when selecting data from "user_temp". */
export type User_Temp_Order_By = {
  email?: Maybe<Order_By>
  id?: Maybe<Order_By>
}

/** primary key columns input for table: user_temp */
export type User_Temp_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_temp" */
export enum User_Temp_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "user_temp" */
export type User_Temp_Set_Input = {
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
}

/** Streaming cursor of the table "user_temp" */
export type User_Temp_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Temp_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Temp_Stream_Cursor_Value_Input = {
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
}

/** update columns of table "user_temp" */
export enum User_Temp_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

export type User_Temp_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Temp_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Temp_Bool_Exp
}

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Anonymous = 'anonymous',
  /** column name */
  AppliedAsCreatorAt = 'applied_as_creator_at',
  /** column name */
  Bio = 'bio',
  /** column name */
  BioLink = 'bio_link',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedReason = 'deleted_reason',
  /** column name */
  DisabledAt = 'disabled_at',
  /** column name */
  DisabledReason = 'disabled_reason',
  /** column name */
  Email = 'email',
  /** column name */
  HasPremiumSubscription = 'has_premium_subscription',
  /** column name */
  IconOrder = 'icon_order',
  /** column name */
  Id = 'id',
  /** column name */
  InstalledAt = 'installed_at',
  /** column name */
  InternalAccount = 'internal_account',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OgimageFingerprint = 'ogimage_fingerprint',
  /** column name */
  OgimageFingerprintUpdated = 'ogimage_fingerprint_updated',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  OrphanedAt = 'orphaned_at',
  /** column name */
  OrphanedBy = 'orphaned_by',
  /** column name */
  Phone = 'phone',
  /** column name */
  Picture = 'picture',
  /** column name */
  PossibleEmail = 'possible_email',
  /** column name */
  ReferrerUserId = 'referrer_user_id',
  /** column name */
  Role = 'role',
  /** column name */
  ShoppingPreference = 'shopping_preference',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  ShowCurations = 'show_curations',
  /** column name */
  ShowOnboarding = 'show_onboarding',
  /** column name */
  ShowRabbithole = 'show_rabbithole',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserType = 'user_type',
  /** column name */
  Username = 'username',
  /** column name */
  Verified = 'verified',
  /** column name */
  Version = 'version',
  /** column name */
  Waitlist = 'waitlist',
}

export type User_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by var_pop() on columns of table "user" */
export type User_Var_Pop_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by var_samp() on columns of table "user" */
export type User_Var_Samp_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by variance() on columns of table "user" */
export type User_Variance_Order_By = {
  icon_order?: Maybe<Order_By>
}

export type Users_Search_Args = {
  limit?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>
  _gt?: Maybe<Scalars['uuid']>
  _gte?: Maybe<Scalars['uuid']>
  _in?: Maybe<Array<Scalars['uuid']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['uuid']>
  _lte?: Maybe<Scalars['uuid']>
  _neq?: Maybe<Scalars['uuid']>
  _nin?: Maybe<Array<Scalars['uuid']>>
}

/** Enum table to hold the different possible workflow types */
export type Worflow_Type = {
  __typename?: 'worflow_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "worflow_type" */
export type Worflow_Type_Aggregate = {
  __typename?: 'worflow_type_aggregate'
  aggregate?: Maybe<Worflow_Type_Aggregate_Fields>
  nodes: Array<Worflow_Type>
}

/** aggregate fields of "worflow_type" */
export type Worflow_Type_Aggregate_Fields = {
  __typename?: 'worflow_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Worflow_Type_Max_Fields>
  min?: Maybe<Worflow_Type_Min_Fields>
}

/** aggregate fields of "worflow_type" */
export type Worflow_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Worflow_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "worflow_type". All fields are combined with a logical 'AND'. */
export type Worflow_Type_Bool_Exp = {
  _and?: Maybe<Array<Worflow_Type_Bool_Exp>>
  _not?: Maybe<Worflow_Type_Bool_Exp>
  _or?: Maybe<Array<Worflow_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "worflow_type" */
export enum Worflow_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  WorflowTypePkey = 'worflow_type_pkey',
}

export enum Worflow_Type_Enum {
  /** For adding mobile bookmarked items into a collection */
  AddBookmarkToCollection = 'add_bookmark_to_collection',
  /** For controlling the flow of creating of creating a mobile bookmark for a user */
  MobileBookmark = 'mobile_bookmark',
  /** For controlling the flow of creating of creating a web bookmark for a user */
  WebBookmark = 'web_bookmark',
}

/** Boolean expression to compare columns of type "worflow_type_enum". All fields are combined with logical 'AND'. */
export type Worflow_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Worflow_Type_Enum>
  _in?: Maybe<Array<Worflow_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Worflow_Type_Enum>
  _nin?: Maybe<Array<Worflow_Type_Enum>>
}

/** input type for inserting data into table "worflow_type" */
export type Worflow_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Worflow_Type_Max_Fields = {
  __typename?: 'worflow_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Worflow_Type_Min_Fields = {
  __typename?: 'worflow_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "worflow_type" */
export type Worflow_Type_Mutation_Response = {
  __typename?: 'worflow_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Worflow_Type>
}

/** on_conflict condition type for table "worflow_type" */
export type Worflow_Type_On_Conflict = {
  constraint: Worflow_Type_Constraint
  update_columns?: Array<Worflow_Type_Update_Column>
  where?: Maybe<Worflow_Type_Bool_Exp>
}

/** Ordering options when selecting data from "worflow_type". */
export type Worflow_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: worflow_type */
export type Worflow_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "worflow_type" */
export enum Worflow_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "worflow_type" */
export type Worflow_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "worflow_type" */
export type Worflow_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Worflow_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Worflow_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "worflow_type" */
export enum Worflow_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Worflow_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Worflow_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Worflow_Type_Bool_Exp
}

/** A table to hold the status of pending items that require backend processing */
export type Workflow = {
  __typename?: 'workflow'
  created_at: Scalars['timestamptz']
  data: Scalars['jsonb']
  id: Scalars['uuid']
  /** An object relationship */
  parent_workflow?: Maybe<Workflow>
  parent_workflow_id?: Maybe<Scalars['uuid']>
  status: Workflow_Status_Enum
  status_details?: Maybe<Scalars['String']>
  success_item_id?: Maybe<Scalars['uuid']>
  success_item_type?: Maybe<Scalars['String']>
  type: Worflow_Type_Enum
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
  /** An array relationship */
  workflow_dependents: Array<Workflow>
  /** An aggregate relationship */
  workflow_dependents_aggregate: Workflow_Aggregate
}

/** A table to hold the status of pending items that require backend processing */
export type WorkflowDataArgs = {
  path?: Maybe<Scalars['String']>
}

/** A table to hold the status of pending items that require backend processing */
export type WorkflowWorkflow_DependentsArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

/** A table to hold the status of pending items that require backend processing */
export type WorkflowWorkflow_Dependents_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

/** aggregated selection of "workflow" */
export type Workflow_Aggregate = {
  __typename?: 'workflow_aggregate'
  aggregate?: Maybe<Workflow_Aggregate_Fields>
  nodes: Array<Workflow>
}

export type Workflow_Aggregate_Bool_Exp = {
  count?: Maybe<Workflow_Aggregate_Bool_Exp_Count>
}

export type Workflow_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Workflow_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Workflow_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "workflow" */
export type Workflow_Aggregate_Fields = {
  __typename?: 'workflow_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Workflow_Max_Fields>
  min?: Maybe<Workflow_Min_Fields>
}

/** aggregate fields of "workflow" */
export type Workflow_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Workflow_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "workflow" */
export type Workflow_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Workflow_Max_Order_By>
  min?: Maybe<Workflow_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Workflow_Append_Input = {
  data?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "workflow" */
export type Workflow_Arr_Rel_Insert_Input = {
  data: Array<Workflow_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Workflow_On_Conflict>
}

/** Boolean expression to filter rows from the table "workflow". All fields are combined with a logical 'AND'. */
export type Workflow_Bool_Exp = {
  _and?: Maybe<Array<Workflow_Bool_Exp>>
  _not?: Maybe<Workflow_Bool_Exp>
  _or?: Maybe<Array<Workflow_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  data?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  parent_workflow?: Maybe<Workflow_Bool_Exp>
  parent_workflow_id?: Maybe<Uuid_Comparison_Exp>
  status?: Maybe<Workflow_Status_Enum_Comparison_Exp>
  status_details?: Maybe<String_Comparison_Exp>
  success_item_id?: Maybe<Uuid_Comparison_Exp>
  success_item_type?: Maybe<String_Comparison_Exp>
  type?: Maybe<Worflow_Type_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  workflow_dependents?: Maybe<Workflow_Bool_Exp>
  workflow_dependents_aggregate?: Maybe<Workflow_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "workflow" */
export enum Workflow_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkflowPkey = 'workflow_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Workflow_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Workflow_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Workflow_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "workflow" */
export type Workflow_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  parent_workflow?: Maybe<Workflow_Obj_Rel_Insert_Input>
  parent_workflow_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Workflow_Status_Enum>
  status_details?: Maybe<Scalars['String']>
  success_item_id?: Maybe<Scalars['uuid']>
  success_item_type?: Maybe<Scalars['String']>
  type?: Maybe<Worflow_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  workflow_dependents?: Maybe<Workflow_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Workflow_Max_Fields = {
  __typename?: 'workflow_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  parent_workflow_id?: Maybe<Scalars['uuid']>
  status_details?: Maybe<Scalars['String']>
  success_item_id?: Maybe<Scalars['uuid']>
  success_item_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "workflow" */
export type Workflow_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  parent_workflow_id?: Maybe<Order_By>
  status_details?: Maybe<Order_By>
  success_item_id?: Maybe<Order_By>
  success_item_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Workflow_Min_Fields = {
  __typename?: 'workflow_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  parent_workflow_id?: Maybe<Scalars['uuid']>
  status_details?: Maybe<Scalars['String']>
  success_item_id?: Maybe<Scalars['uuid']>
  success_item_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "workflow" */
export type Workflow_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  parent_workflow_id?: Maybe<Order_By>
  status_details?: Maybe<Order_By>
  success_item_id?: Maybe<Order_By>
  success_item_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "workflow" */
export type Workflow_Mutation_Response = {
  __typename?: 'workflow_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Workflow>
}

/** input type for inserting object relation for remote table "workflow" */
export type Workflow_Obj_Rel_Insert_Input = {
  data: Workflow_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Workflow_On_Conflict>
}

/** on_conflict condition type for table "workflow" */
export type Workflow_On_Conflict = {
  constraint: Workflow_Constraint
  update_columns?: Array<Workflow_Update_Column>
  where?: Maybe<Workflow_Bool_Exp>
}

/** Ordering options when selecting data from "workflow". */
export type Workflow_Order_By = {
  created_at?: Maybe<Order_By>
  data?: Maybe<Order_By>
  id?: Maybe<Order_By>
  parent_workflow?: Maybe<Workflow_Order_By>
  parent_workflow_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  status_details?: Maybe<Order_By>
  success_item_id?: Maybe<Order_By>
  success_item_type?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  workflow_dependents_aggregate?: Maybe<Workflow_Aggregate_Order_By>
}

/** primary key columns input for table: workflow */
export type Workflow_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Workflow_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>
}

/** select columns of table "workflow" */
export enum Workflow_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  ParentWorkflowId = 'parent_workflow_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDetails = 'status_details',
  /** column name */
  SuccessItemId = 'success_item_id',
  /** column name */
  SuccessItemType = 'success_item_type',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "workflow" */
export type Workflow_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  parent_workflow_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Workflow_Status_Enum>
  status_details?: Maybe<Scalars['String']>
  success_item_id?: Maybe<Scalars['uuid']>
  success_item_type?: Maybe<Scalars['String']>
  type?: Maybe<Worflow_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Enum table to hold the different types of workflow statuses */
export type Workflow_Status = {
  __typename?: 'workflow_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "workflow_status" */
export type Workflow_Status_Aggregate = {
  __typename?: 'workflow_status_aggregate'
  aggregate?: Maybe<Workflow_Status_Aggregate_Fields>
  nodes: Array<Workflow_Status>
}

/** aggregate fields of "workflow_status" */
export type Workflow_Status_Aggregate_Fields = {
  __typename?: 'workflow_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Workflow_Status_Max_Fields>
  min?: Maybe<Workflow_Status_Min_Fields>
}

/** aggregate fields of "workflow_status" */
export type Workflow_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Workflow_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "workflow_status". All fields are combined with a logical 'AND'. */
export type Workflow_Status_Bool_Exp = {
  _and?: Maybe<Array<Workflow_Status_Bool_Exp>>
  _not?: Maybe<Workflow_Status_Bool_Exp>
  _or?: Maybe<Array<Workflow_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "workflow_status" */
export enum Workflow_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  WorkflowStatusPkey = 'workflow_status_pkey',
}

export enum Workflow_Status_Enum {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded',
}

/** Boolean expression to compare columns of type "workflow_status_enum". All fields are combined with logical 'AND'. */
export type Workflow_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Workflow_Status_Enum>
  _in?: Maybe<Array<Workflow_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Workflow_Status_Enum>
  _nin?: Maybe<Array<Workflow_Status_Enum>>
}

/** input type for inserting data into table "workflow_status" */
export type Workflow_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Workflow_Status_Max_Fields = {
  __typename?: 'workflow_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Workflow_Status_Min_Fields = {
  __typename?: 'workflow_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "workflow_status" */
export type Workflow_Status_Mutation_Response = {
  __typename?: 'workflow_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Workflow_Status>
}

/** on_conflict condition type for table "workflow_status" */
export type Workflow_Status_On_Conflict = {
  constraint: Workflow_Status_Constraint
  update_columns?: Array<Workflow_Status_Update_Column>
  where?: Maybe<Workflow_Status_Bool_Exp>
}

/** Ordering options when selecting data from "workflow_status". */
export type Workflow_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: workflow_status */
export type Workflow_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "workflow_status" */
export enum Workflow_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "workflow_status" */
export type Workflow_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "workflow_status" */
export type Workflow_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workflow_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Workflow_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "workflow_status" */
export enum Workflow_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Workflow_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Workflow_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Workflow_Status_Bool_Exp
}

/** Streaming cursor of the table "workflow" */
export type Workflow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workflow_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Workflow_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  parent_workflow_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Workflow_Status_Enum>
  status_details?: Maybe<Scalars['String']>
  success_item_id?: Maybe<Scalars['uuid']>
  success_item_type?: Maybe<Scalars['String']>
  type?: Maybe<Worflow_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "workflow" */
export enum Workflow_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  ParentWorkflowId = 'parent_workflow_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDetails = 'status_details',
  /** column name */
  SuccessItemId = 'success_item_id',
  /** column name */
  SuccessItemType = 'success_item_type',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Workflow_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Workflow_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Workflow_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Workflow_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Workflow_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Workflow_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Workflow_Set_Input>
  /** filter the rows which have to be updated */
  where: Workflow_Bool_Exp
}

export type FindUserForCleanupQueryVariables = Exact<{
  id: Scalars['String']
}>

export type FindUserForCleanupQuery = {
  __typename?: 'query_root'
  user?: Maybe<{
    __typename?: 'user'
    anonymous?: Maybe<boolean>
    email?: Maybe<string>
    orphaned_by?: Maybe<string>
    orphaned_at?: Maybe<any>
  }>
}

export type OrphanAnonymousUserMutationVariables = Exact<{
  userId: Scalars['String']
  orphanedBy: Scalars['String']
}>

export type OrphanAnonymousUserMutation = {
  __typename?: 'mutation_root'
  update_user?: Maybe<{
    __typename?: 'user_mutation_response'
    affected_rows: number
  }>
}

export type GetUserCollectionsForModalQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetUserCollectionsForModalQuery = {
  __typename?: 'query_root'
  collections: Array<
    {
      __typename?: 'collection'
      id: any
      name: any
      slug: any
      has_collaborators: boolean
      user?: Maybe<{
        __typename?: 'user'
        id: string
        username?: Maybe<string>
        picture?: Maybe<string>
      }>
    } & CollectionCollaboratorsDataFragment
  >
}

export type FindUserForAdminPanelQueryVariables = Exact<{
  id: Scalars['String']
}>

export type FindUserForAdminPanelQuery = {
  __typename?: 'query_root'
  user?: Maybe<
    {
      __typename?: 'user'
      extension_version?: Maybe<string>
      ios_version?: Maybe<string>
      safari_extension_version?: Maybe<string>
      flags?: Maybe<{
        __typename?: 'user_flags'
        user_id: string
        can_be_impersonated: boolean
      }>
    } & BasicUserDataFragment
  >
}

export type SaveUserForAdminPanelMutationVariables = Exact<{
  id: Scalars['String']
  user_set_input: User_Set_Input
}>

export type SaveUserForAdminPanelMutation = {
  __typename?: 'mutation_root'
  update_user_by_pk?: Maybe<{ __typename?: 'user' } & BasicUserDataFragment>
}

export type SearchUserCartItemsQueryVariables = Exact<{
  userId: Scalars['String']
  query?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
}>

export type SearchUserCartItemsQuery = {
  __typename?: 'query_root'
  cart_items_match: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
  cart_items_shop_match: Array<
    { __typename?: 'cart_item' } & CartItemDataFragment
  >
  collections_match: Array<{
    __typename?: 'collection'
    id: any
    slug: any
    name: any
    path?: Maybe<string>
    collection_items: Array<{
      __typename?: 'collection_cart_item'
      id: any
      cart_item: { __typename?: 'cart_item' } & CartItemDataFragment
    }>
  }>
}

export type SearchUserCollectionCartItemsQueryVariables = Exact<{
  collectionId: Scalars['uuid']
  query?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
}>

export type SearchUserCollectionCartItemsQuery = {
  __typename?: 'query_root'
  collection_items_match: Array<{
    __typename?: 'collection_cart_item'
    id: any
    order?: Maybe<any>
    created_at: any
    updated_at: any
    cart_item: {
      __typename?: 'cart_item'
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
    } & CartItemDataFragment
  }>
}

export type SearchUserCollectionsQueryVariables = Exact<{
  userId: Scalars['String']
  query?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  itemLimit?: Maybe<Scalars['Int']>
}>

export type SearchUserCollectionsQuery = {
  __typename?: 'query_root'
  collections_match: Array<
    {
      __typename?: 'collection'
    } & UserCollectionsDataForProfilePageDataFragment
  >
}

export type SubsUserUnseenCartItemsSubscriptionVariables = Exact<{
  id: Scalars['String']
}>

export type SubsUserUnseenCartItemsSubscription = {
  __typename?: 'subscription_root'
  user_by_pk?: Maybe<{ __typename?: 'user'; unseen_cart_items?: Maybe<number> }>
}

export type UpdateCreatorCartItemMutationVariables = Exact<{
  id: Scalars['uuid']
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  item_image_override?: Maybe<Scalars['String']>
  item_title_override?: Maybe<Scalars['String']>
  item_description_override?: Maybe<Scalars['String']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  image_metadata?: Maybe<Scalars['jsonb']>
  item_video_url?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  categories_override?: Maybe<Array<Scalars['String']> | Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
}>

export type UpdateCreatorCartItemMutation = {
  __typename?: 'mutation_root'
  update_cart_item_by_pk?: Maybe<
    { __typename?: 'cart_item' } & CartItemDataFragment
  >
}

export type GetCollectionPublishDataQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetCollectionPublishDataQuery = {
  __typename?: 'query_root'
  collection_by_pk?: Maybe<{
    __typename?: 'collection'
    id: any
    notification_preferences: any
    state?: Maybe<Collection_State_Enum>
    paid: boolean
    exclusive?: Maybe<boolean>
    email_excerpt?: Maybe<string>
    has_valid_preview_images_computed?: Maybe<boolean>
    user?: Maybe<{
      __typename?: 'user'
      id: string
      has_billing_account?: Maybe<boolean>
    }>
  }>
}

export type UpdateShopItemIsInappropriateMutationVariables = Exact<{
  id: Scalars['uuid']
  is_inappropiate: Scalars['Boolean']
}>

export type UpdateShopItemIsInappropriateMutation = {
  __typename?: 'mutation_root'
  update_shop_item_by_pk?: Maybe<{
    __typename?: 'shop_item'
    id: any
    is_inappropriate: boolean
  }>
}

export type GetDiscoveryFeedForSearchQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  itemLimit?: Maybe<Scalars['Int']>
}>

export type GetDiscoveryFeedForSearchQuery = {
  __typename?: 'query_root'
  discovery_feed: Array<{
    __typename?: 'discovery_feed'
    id: any
    feed_item: {
      __typename?: 'feed_item'
      id: any
      collection?: Maybe<
        { __typename?: 'collection' } & CollectionCarouselDataFragment
      >
    }
  }>
}

export type GetUserNotificationPreferencesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetUserNotificationPreferencesQuery = {
  __typename?: 'query_root'
  notification_preference: Array<{
    __typename?: 'notification_preference'
    id: any
    communication_template: string
    allowed: boolean
    allowable_communication: Array<string>
  }>
}

export type FindShopItemAndShopByUrlQueryVariables = Exact<{
  url: Scalars['String']
  shopSlug: Scalars['name']
}>

export type FindShopItemAndShopByUrlQuery = {
  __typename?: 'query_root'
  shop_item: Array<{
    __typename?: 'shop_item'
    id: any
    item_id: string
    url?: Maybe<string>
    title?: Maybe<string>
    image?: Maybe<string>
    display_title?: Maybe<string>
    source?: Maybe<string>
    enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum>
    shop: {
      __typename?: 'shop'
      id: any
      key: string
      type?: Maybe<any>
      name: any
      slug: any
      favicon?: Maybe<string>
    }
  }>
  shop: Array<{
    __typename?: 'shop'
    id: any
    key: string
    type?: Maybe<any>
    name: any
    slug: any
    favicon?: Maybe<string>
  }>
}

export type FindUserSubscriptionQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type FindUserSubscriptionQuery = {
  __typename?: 'query_root'
  user_subscription_by_pk?: Maybe<{
    __typename?: 'user_subscription'
    id: any
    subscription_type?: Maybe<string>
    status: User_Subscription_Status_Enum
    plan: any
    created_at: any
    updated_at: any
    cancel_at?: Maybe<any>
    creator: {
      __typename?: 'user'
      id: string
      name?: Maybe<string>
      username?: Maybe<string>
      picture?: Maybe<string>
      verified?: Maybe<boolean>
      has_billing_account?: Maybe<boolean>
    }
  }>
}

export type GetCollectionsForShopQueryVariables = Exact<{
  key: Scalars['String']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  itemLimit?: Maybe<Scalars['Int']>
}>

export type GetCollectionsForShopQuery = {
  __typename?: 'query_root'
  collections: Array<
    { __typename?: 'collection' } & CollectionCarouselDataFragment
  >
}

export type GetCreatorSubscribersQueryVariables = Exact<{
  creator_id: Scalars['String']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetCreatorSubscribersQuery = {
  __typename?: 'query_root'
  creator_user_subscriptions: Array<{
    __typename?: 'creator_user_subscriptions'
    status?: Maybe<string>
    created_at?: Maybe<any>
    interval?: Maybe<string>
    subscription_type?: Maybe<string>
    subscriber_email?: Maybe<string>
    subscriber_phone?: Maybe<string>
    subscriber?: Maybe<{
      __typename?: 'user'
      id: string
      display_name?: Maybe<string>
    }>
  }>
  creator_user_subscriptions_aggregate: {
    __typename?: 'creator_user_subscriptions_aggregate'
    aggregate?: Maybe<{
      __typename?: 'creator_user_subscriptions_aggregate_fields'
      count: number
    }>
  }
}

export type GetRelatedUsersForShopItemQueryVariables = Exact<{
  shop_item_id?: Maybe<Scalars['uuid']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetRelatedUsersForShopItemQuery = {
  __typename?: 'query_root'
  related_users_for_shop_item: Array<{
    __typename?: 'user'
    id: string
    username?: Maybe<string>
    picture?: Maybe<string>
  }>
}

export type GetSubscriberChartDataForCreatorQueryVariables = Exact<{
  creator_id: Scalars['String']
  start_date: Scalars['String']
  end_date: Scalars['String']
}>

export type GetSubscriberChartDataForCreatorQuery = {
  __typename?: 'query_root'
  total_subscribers_aggregate: {
    __typename?: 'creator_user_subscriptions_aggregate'
    aggregate?: Maybe<{
      __typename?: 'creator_user_subscriptions_aggregate_fields'
      count: number
    }>
  }
  creatorSubscriberChartData: Array<{
    __typename?: 'CreatorSubscriberChartDataOutput'
    day?: Maybe<string>
    subscribers?: Maybe<number>
    paid_subscribers?: Maybe<number>
    total_paid_subscribers?: Maybe<number>
    total_subscribers?: Maybe<number>
  }>
}

export type GetUserForSettingsPageQueryVariables = Exact<{
  id: Scalars['String']
  includeLinks?: Maybe<Scalars['Boolean']>
}>

export type GetUserForSettingsPageQuery = {
  __typename?: 'query_root'
  user?: Maybe<
    {
      __typename?: 'user'
      bio?: Maybe<string>
      bio_link?: Maybe<string>
      has_claimed_username_computed?: Maybe<boolean>
      has_set_name_computed?: Maybe<boolean>
      has_picture_set?: Maybe<boolean>
      has_billing_account?: Maybe<boolean>
      links?: Maybe<
        Array<{
          __typename?: 'user_links'
          url: string
          link_type?: Maybe<Link_Type_Enum>
          type?: Maybe<{
            __typename?: 'link_type'
            value: string
            description?: Maybe<string>
          }>
        }>
      >
    } & BasicUserDataFragment
  >
}

export type GetUserOnboardingProgressQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetUserOnboardingProgressQuery = {
  __typename?: 'query_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    username?: Maybe<string>
    display_name?: Maybe<string>
    has_claimed_username_computed?: Maybe<boolean>
    has_set_name_computed?: Maybe<boolean>
    has_picture_set?: Maybe<boolean>
    has_billing_account?: Maybe<boolean>
    metadata?: Maybe<any>
    onboarded?: Maybe<boolean>
    collections_aggregate: {
      __typename?: 'collection_aggregate'
      aggregate?: Maybe<{
        __typename?: 'collection_aggregate_fields'
        count: number
      }>
    }
  }>
}

export type GetUserPendingWebBookmarksQueryVariables = Exact<{
  user_id: Scalars['String']
}>

export type GetUserPendingWebBookmarksQuery = {
  __typename?: 'query_root'
  web_pending_bookmark_workflows: Array<{
    __typename?: 'workflow'
    id: any
    data: any
    status: Workflow_Status_Enum
  }>
}

export type GetUserSubscriptionsQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserSubscriptionsQuery = {
  __typename?: 'query_root'
  user_subscription_aggregate: {
    __typename?: 'user_subscription_aggregate'
    aggregate?: Maybe<{
      __typename?: 'user_subscription_aggregate_fields'
      count: number
    }>
  }
  user_subscription: Array<{
    __typename?: 'user_subscription'
    id: any
    subscription_type?: Maybe<string>
    status: User_Subscription_Status_Enum
    plan: any
    created_at: any
    updated_at: any
    cancel_at?: Maybe<any>
    creator: {
      __typename?: 'user'
      id: string
      name?: Maybe<string>
      username?: Maybe<string>
      picture?: Maybe<string>
      verified?: Maybe<boolean>
      has_billing_account?: Maybe<boolean>
    }
  }>
}

export type AdminFindShopByKeyQueryVariables = Exact<{
  key: Scalars['String']
}>

export type AdminFindShopByKeyQuery = {
  __typename?: 'query_root'
  shop: Array<{ __typename?: 'shop' } & AdminCategorizationShopDataFragment>
}

export type AdminFindShopBySlugQueryVariables = Exact<{
  slug: Scalars['name']
}>

export type AdminFindShopBySlugQuery = {
  __typename?: 'query_root'
  shop: Array<{ __typename?: 'shop' } & AdminCategorizationShopDataFragment>
}

export type DeleteMobileHomeBlockAdminMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type DeleteMobileHomeBlockAdminMutation = {
  __typename?: 'mutation_root'
  delete_mobile_home_by_pk?: Maybe<{ __typename?: 'mobile_home'; id: any }>
}

export type FindUserForDeletionQueryVariables = Exact<{
  email: Scalars['String']
  is_admin?: Maybe<Scalars['Boolean']>
}>

export type FindUserForDeletionQuery = {
  __typename?: 'query_root'
  users: Array<
    { __typename?: 'user'; email?: Maybe<string> } & BasicUserDataFragment
  >
}

export type GetAdminFeaturedShopsForCategrizationQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
}>

export type GetAdminFeaturedShopsForCategrizationQuery = {
  __typename?: 'query_root'
  shop_popularity: Array<{
    __typename?: 'shop_popularity'
    key?: Maybe<string>
    popularity_rank?: Maybe<any>
    shop?: Maybe<{ __typename?: 'shop' } & AdminCategorizationShopDataFragment>
  }>
}

export type GetAdminShopsForCategrizationQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
}>

export type GetAdminShopsForCategrizationQuery = {
  __typename?: 'query_root'
  shop_categories: Array<{
    __typename?: 'shop_category'
    value: string
    label?: Maybe<string>
  }>
  shop_popularity: Array<{
    __typename?: 'shop_popularity'
    key?: Maybe<string>
    popularity_rank?: Maybe<any>
    shop?: Maybe<{ __typename?: 'shop' } & AdminCategorizationShopDataFragment>
  }>
}

export type GetAdminShopsNoFaviconQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAdminShopsNoFaviconQuery = {
  __typename?: 'query_root'
  no_favicon_aggregate: {
    __typename?: 'shop_aggregate'
    aggregate?: Maybe<{ __typename?: 'shop_aggregate_fields'; count: number }>
  }
  no_favicon_shops: Array<
    { __typename?: 'shop' } & AdminCategorizationShopDataFragment
  >
}

export type GetMobileHomeBlocksAdminQueryVariables = Exact<{
  [key: string]: never
}>

export type GetMobileHomeBlocksAdminQuery = {
  __typename?: 'query_root'
  home_blocks: Array<{ __typename?: 'mobile_home' } & MobileHomeBlockFragment>
  deals_blocks: Array<{ __typename?: 'mobile_home' } & MobileHomeBlockFragment>
}

export type MobileHomeBlockFragment = {
  __typename?: 'mobile_home'
  id: any
  order: number
  asset_url: string
  link_url?: Maybe<string>
  enabled: boolean
  active: boolean
  type?: Maybe<string>
}

export type GetSimilarProductsHistoryAdminQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetSimilarProductsHistoryAdminQuery = {
  __typename?: 'query_root'
  similar_products_history: Array<{
    __typename?: 'similar_products_history'
    id: any
    similar_products: any
    image_url: string
    cart_item?: Maybe<{
      __typename?: 'cart_item'
      id: any
      display_title?: Maybe<string>
      source?: Maybe<string>
      item: { __typename?: 'shop_item'; url?: Maybe<string> }
    }>
    shop_item?: Maybe<{
      __typename?: 'shop_item'
      id: any
      display_title?: Maybe<string>
      source?: Maybe<string>
      url?: Maybe<string>
    }>
    user?: Maybe<{
      __typename?: 'user'
      id: string
      username?: Maybe<string>
      name?: Maybe<string>
      picture?: Maybe<string>
    }>
  }>
}

export type SaveShopItemCategoriesMutationVariables = Exact<{
  shopItemId: Scalars['uuid']
  categories: Array<Scalars['String']> | Scalars['String']
  keywords?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type SaveShopItemCategoriesMutation = {
  __typename?: 'mutation_root'
  update_shop_item_by_pk?: Maybe<{
    __typename?: 'shop_item'
    id: any
    categories?: Maybe<Array<string>>
    keywords?: Maybe<Array<string>>
  }>
}

export type SearchAdminShopsForCategrizationQueryVariables = Exact<{
  string_comparison_exp: String_Comparison_Exp
}>

export type SearchAdminShopsForCategrizationQuery = {
  __typename?: 'query_root'
  shops: Array<{ __typename?: 'shop' } & AdminCategorizationShopDataFragment>
}

export type UpsertMobileHomeBlocksAdminMutationVariables = Exact<{
  objects: Array<Mobile_Home_Insert_Input> | Mobile_Home_Insert_Input
}>

export type UpsertMobileHomeBlocksAdminMutation = {
  __typename?: 'mutation_root'
  insert_mobile_home?: Maybe<{
    __typename?: 'mobile_home_mutation_response'
    affected_rows: number
  }>
}

export type FindCollectionByIdForCollectionPageQueryVariables = Exact<{
  id: Scalars['uuid']
  itemLimit?: Maybe<Scalars['Int']>
  collectionItemsCount?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
  include_feed_item?: Maybe<Scalars['Boolean']>
  preloadItem?: Maybe<Scalars['Boolean']>
  cartItemId?: Maybe<Scalars['uuid']>
}>

export type FindCollectionByIdForCollectionPageQuery = {
  __typename?: 'query_root'
  collections: Array<
    {
      __typename?: 'collection'
      followed_by_user?: Maybe<boolean>
      is_a_collaborator?: Maybe<boolean>
      total_likes_count?: Maybe<any>
      collection_items_aggregate: {
        __typename?: 'collection_cart_item_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_cart_item_aggregate_fields'
          count: number
        }>
      }
      collection_collaborators_aggregate: {
        __typename?: 'collection_collaborator_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_collaborator_aggregate_fields'
          count: number
        }>
      }
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      media_enabled_join?: Maybe<
        { __typename?: 'media_enabled_item' } & MediaEnabledItemDataFragment
      >
      preloaded_collection_items: Maybe<
        Array<
          {
            __typename?: 'collection_cart_item'
          } & PreloadedCollectionCartItemDataFragment
        >
      >
    } & CollectionAndItemsDataFragment
  >
}

export type FindCollectionByUsernameAndSlugQueryVariables = Exact<{
  username: Scalars['String']
  slug: Scalars['name']
  preloadItem?: Maybe<Scalars['Boolean']>
  cartItemId?: Maybe<Scalars['uuid']>
  itemLimit?: Maybe<Scalars['Int']>
  collectionItemsCount?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
  include_feed_item?: Maybe<Scalars['Boolean']>
}>

export type FindCollectionByUsernameAndSlugQuery = {
  __typename?: 'query_root'
  collections: Array<
    {
      __typename?: 'collection'
      followed_by_user?: Maybe<boolean>
      is_a_collaborator?: Maybe<boolean>
      total_likes_count?: Maybe<any>
      collection_items_aggregate: {
        __typename?: 'collection_cart_item_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_cart_item_aggregate_fields'
          count: number
        }>
      }
      collection_collaborators_aggregate: {
        __typename?: 'collection_collaborator_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_collaborator_aggregate_fields'
          count: number
        }>
      }
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      media_enabled_join?: Maybe<
        { __typename?: 'media_enabled_item' } & MediaEnabledItemDataFragment
      >
      preloaded_collection_items: Maybe<
        Array<
          {
            __typename?: 'collection_cart_item'
          } & PreloadedCollectionCartItemDataFragment
        >
      >
    } & CollectionAndItemsDataFragment
  >
}

export type TogglePremiumCollectionMutationVariables = Exact<{
  collection_id: Scalars['uuid']
  exclusive: Scalars['Boolean']
}>

export type TogglePremiumCollectionMutation = {
  __typename?: 'mutation_root'
  collection?: Maybe<{
    __typename?: 'collection'
    id: any
    exclusive?: Maybe<boolean>
  }>
}

export type UpdateCollectionMutationVariables = Exact<{
  id: Scalars['uuid']
  set_input: Collection_Set_Input
  metadata?: Maybe<Scalars['jsonb']>
}>

export type UpdateCollectionMutation = {
  __typename?: 'mutation_root'
  update_collection_by_pk?: Maybe<{
    __typename?: 'collection'
    id: any
    name: any
    slug: any
    curation: boolean
    collection_link?: Maybe<string>
    cover_image?: Maybe<string>
    collection_cover_images?: Maybe<Array<string>>
    description?: Maybe<string>
    seo_fields?: Maybe<any>
    metadata?: Maybe<any>
  }>
}

export type DeleteMediaItemMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type DeleteMediaItemMutation = {
  __typename?: 'mutation_root'
  delete_media_item_by_pk?: Maybe<{
    __typename?: 'media_item'
    id: any
    media_type: Media_Type_Enum
    media_url: string
  }>
}

export type GetDiscoveryFeedWebQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  collectionItemsCount?: Maybe<Scalars['Int']>
}>

export type GetDiscoveryFeedWebQuery = {
  __typename?: 'query_root'
  discovery_feed: Array<{
    __typename?: 'discovery_feed'
    id: any
    sequential_id: number
    feed_item: { __typename?: 'feed_item' } & DiscoveryFeedFeedItemFragment
  }>
}

export type GetStealTheirLooksWebQueryVariables = Exact<{
  seed?: Maybe<Scalars['seed_float']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetStealTheirLooksWebQuery = {
  __typename?: 'query_root'
  steal_their_looks_random: Array<{
    __typename?: 'collection'
    id: any
    slug: any
    cover_image?: Maybe<string>
    preview_collection_items?: Maybe<
      Array<{
        __typename?: 'collection_cart_item'
        id: any
        cart_item: {
          __typename?: 'cart_item'
          id: any
          item_image_override?: Maybe<string>
          image_metadata?: Maybe<any>
          cart: {
            __typename?: 'cart'
            id: any
            user: { __typename?: 'user'; id: string }
          }
          item: {
            __typename?: 'shop_item'
            id: any
            cached_image?: Maybe<string>
            image?: Maybe<string>
            image_highres?: Maybe<string>
            image_metadata?: Maybe<any>
            metadata?: Maybe<any>
            is_inappropriate: boolean
          }
        }
      }>
    >
    user?: Maybe<{
      __typename?: 'user'
      id: string
      username?: Maybe<string>
      name?: Maybe<string>
    }>
  }>
}

export type FetchShoppingProductsQueryVariables = Exact<{
  query: Scalars['String']
  location?: Maybe<Scalars['String']>
  red?: Maybe<Scalars['Int']>
  blue?: Maybe<Scalars['Int']>
  green?: Maybe<Scalars['Int']>
}>

export type FetchShoppingProductsQuery = {
  __typename?: 'query_root'
  shoppingProducts: Array<{
    __typename?: 'ShoppingProductOutput'
    delivery?: Maybe<string>
    extracted_price?: Maybe<number>
    link?: Maybe<string>
    position?: Maybe<number>
    price?: Maybe<string>
    product_link?: Maybe<string>
    source?: Maybe<string>
    tag?: Maybe<string>
    thumbnail?: Maybe<string>
    title?: Maybe<string>
  }>
}

export type FetchSimilarProductsQueryVariables = Exact<{
  imageUrl: Scalars['String']
  shopItemId?: Maybe<Scalars['uuid']>
  cartItemId?: Maybe<Scalars['uuid']>
  allResults?: Maybe<Scalars['Boolean']>
  noCache?: Maybe<Scalars['Boolean']>
}>

export type FetchSimilarProductsQuery = {
  __typename?: 'query_root'
  similarProducts: Array<{
    __typename?: 'SimilarProductOutput'
    position?: Maybe<number>
    title?: Maybe<string>
    link?: Maybe<string>
    source?: Maybe<string>
    shop_popularity?: Maybe<number>
    source_icon?: Maybe<string>
    thumbnail?: Maybe<string>
    points?: Maybe<number>
    max_take_rate?: Maybe<number>
    price?: Maybe<{
      __typename?: 'SimilarProductPrice'
      value?: Maybe<string>
      extracted_value?: Maybe<number>
      currency?: Maybe<string>
    }>
  }>
}

export type FindCollectionByUsernameAndShortIdQueryVariables = Exact<{
  username: Scalars['String']
  shortId: Scalars['name']
  itemLimit?: Maybe<Scalars['Int']>
  collectionItemsCount?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
  include_feed_item?: Maybe<Scalars['Boolean']>
}>

export type FindCollectionByUsernameAndShortIdQuery = {
  __typename?: 'query_root'
  collections: Array<
    {
      __typename?: 'collection'
      followed_by_user?: Maybe<boolean>
      is_a_collaborator?: Maybe<boolean>
      total_likes_count?: Maybe<any>
      collection_items_aggregate: {
        __typename?: 'collection_cart_item_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_cart_item_aggregate_fields'
          count: number
        }>
      }
      collection_collaborators_aggregate: {
        __typename?: 'collection_collaborator_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_collaborator_aggregate_fields'
          count: number
        }>
      }
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      media_enabled_join?: Maybe<
        { __typename?: 'media_enabled_item' } & MediaEnabledItemDataFragment
      >
    } & CollectionAndItemsDataFragment
  >
}

export type FindCollectionCollaboratorQueryVariables = Exact<{
  collectionId: Scalars['uuid']
}>

export type FindCollectionCollaboratorQuery = {
  __typename?: 'query_root'
  collection_by_pk?: Maybe<{
    __typename?: 'collection'
    id: any
    is_a_collaborator?: Maybe<boolean>
  }>
}

export type FindMediaForItemTypeQueryVariables = Exact<{
  item_id: Scalars['uuid']
  item_type: Media_Item_Type_Enum
}>

export type FindMediaForItemTypeQuery = {
  __typename?: 'query_root'
  media_enabled_item_by_pk?: Maybe<
    { __typename?: 'media_enabled_item' } & MediaEnabledItemDataFragment
  >
}

export type FindRelatedShopByCategoryQueryVariables = Exact<{
  shopKey: Scalars['String']
}>

export type FindRelatedShopByCategoryQuery = {
  __typename?: 'query_root'
  related_shops: Array<
    {
      __typename?: 'shop'
      cover_image?: Maybe<string>
      banner_image?: Maybe<string>
      shop_categorizations: Array<{
        __typename?: 'shop_categorization'
        weight: number
        category: Shop_Category_Enum
      }>
    } & ShopDataFragment
  >
}

export type GetCreatorsToSubscribeRandomQueryVariables = Exact<{
  exclude_usernames?: Maybe<Array<Scalars['String']> | Scalars['String']>
  seed?: Maybe<Scalars['seed_float']>
  limit?: Maybe<Scalars['Int']>
  include_other_creators?: Maybe<Scalars['Boolean']>
}>

export type GetCreatorsToSubscribeRandomQuery = {
  __typename?: 'query_root'
  vip_creators: Array<{ __typename?: 'user' } & BasicUserDataFragment>
  other_creators: Maybe<Array<{ __typename?: 'user' } & BasicUserDataFragment>>
}

export type GetDataForCollectionsSitemapQueryVariables = Exact<{
  [key: string]: never
}>

export type GetDataForCollectionsSitemapQuery = {
  __typename?: 'query_root'
  collections: Array<{
    __typename?: 'collection'
    id: any
    slug: any
    seo_fields?: Maybe<any>
    user?: Maybe<{ __typename?: 'user'; username?: Maybe<string> }>
    collection_items_aggregate: {
      __typename?: 'collection_cart_item_aggregate'
      aggregate?: Maybe<{
        __typename?: 'collection_cart_item_aggregate_fields'
        count: number
      }>
    }
  }>
}

export type GetDataForProfilesSitemapQueryVariables = Exact<{
  [key: string]: never
}>

export type GetDataForProfilesSitemapQuery = {
  __typename?: 'query_root'
  users: Array<{
    __typename?: 'user'
    id: string
    username?: Maybe<string>
    is_icon_computed?: Maybe<boolean>
    is_creator_computed?: Maybe<boolean>
    is_curator_computed?: Maybe<boolean>
    verified?: Maybe<boolean>
  }>
}

export type GetOwnShopItemsForPicksQueryVariables = Exact<{
  username: Scalars['String']
  shopKey: Scalars['String']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetOwnShopItemsForPicksQuery = {
  __typename?: 'query_root'
  cart_item: Array<
    {
      __typename?: 'cart_item'
      collection_cart_items: Array<{
        __typename?: 'collection_cart_item'
        collection: { __typename?: 'collection'; id: any; name: any; slug: any }
      }>
    } & CartItemDataFragment
  >
  cart_item_aggregate: {
    __typename?: 'cart_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'cart_item_aggregate_fields'
      count: number
    }>
  }
}

export type GetRandomDiscoveryFeedForProfileQueryVariables = Exact<{
  username: Scalars['String']
  seed?: Maybe<Scalars['seed_float']>
  collectionItemsCount?: Maybe<Scalars['Int']>
}>

export type GetRandomDiscoveryFeedForProfileQuery = {
  __typename?: 'query_root'
  discovery_feed: Array<{
    __typename?: 'discovery_feed'
    id: any
    feed_item: {
      __typename?: 'feed_item'
      collection?: Maybe<
        { __typename?: 'collection' } & DiscoveryCollectionFragment
      >
    } & FeedItemDataFragment
  }>
}

export type GetShopsFeaturedForShopPageQueryVariables = Exact<{
  featured_shops_limit?: Maybe<Scalars['Int']>
}>

export type GetShopsFeaturedForShopPageQuery = {
  __typename?: 'query_root'
  shop_popularity: Array<{
    __typename?: 'shop_popularity'
    key?: Maybe<string>
    popularity_rank?: Maybe<any>
    shop?: Maybe<{
      __typename?: 'shop'
      id: any
      key: string
      slug: any
      display_name?: Maybe<string>
      cover_image?: Maybe<string>
      favicon?: Maybe<string>
      parsed_take_rate?: Maybe<any>
    }>
  }>
}

export type GetShopsForShopPageQueryVariables = Exact<{
  ignore_slugs?: Maybe<Array<Scalars['name']> | Scalars['name']>
}>

export type GetShopsForShopPageQuery = {
  __typename?: 'query_root'
  shops: Array<{
    __typename?: 'shop'
    key: string
    slug: any
    display_name?: Maybe<string>
    favicon?: Maybe<string>
  }>
  shops_all: Array<{
    __typename?: 'shop'
    key: string
    slug: any
    display_name?: Maybe<string>
  }>
}

export type GetSubscriberCollectionsQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  collectionItemsCount?: Maybe<Scalars['Int']>
}>

export type GetSubscriberCollectionsQuery = {
  __typename?: 'query_root'
  collection_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  collections: Array<
    {
      __typename?: 'collection'
    } & UserCollectionsDataForProfilePageDataFragment
  >
}

export type GetUserCartItemsQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  includeHidden?: Maybe<Scalars['Boolean']>
  includeAggregate?: Maybe<Scalars['Boolean']>
}>

export type GetUserCartItemsQuery = {
  __typename?: 'query_root'
  cart_item_aggregate?: Maybe<{
    __typename?: 'cart_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'cart_item_aggregate_fields'
      count: number
    }>
  }>
  cart_items: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type GetUserCartItemsCountQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  archived?: Maybe<Scalars['Boolean']>
}>

export type GetUserCartItemsCountQuery = {
  __typename?: 'query_root'
  saved_items_aggregate: {
    __typename?: 'cart_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'cart_item_aggregate_fields'
      count: number
    }>
  }
  archived_items_aggregate: Maybe<{
    __typename?: 'cart_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'cart_item_aggregate_fields'
      count: number
    }>
  }>
}

export type GetUserDataForPicksPageQueryVariables = Exact<{
  username: Scalars['String']
  slug: Scalars['name']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  includeUser?: Maybe<Scalars['Boolean']>
  includeShop?: Maybe<Scalars['Boolean']>
  includePrivateItems?: Maybe<Scalars['Boolean']>
}>

export type GetUserDataForPicksPageQuery = {
  __typename?: 'query_root'
  cart_item_aggregate: {
    __typename?: 'cart_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'cart_item_aggregate_fields'
      count: number
    }>
  }
  cart_items: Array<
    {
      __typename?: 'cart_item'
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      commentable_item_join?: Maybe<{
        __typename?: 'commentable_item'
        comments: Array<{
          __typename?: 'comment'
          id: any
          comment: string
          user: {
            __typename?: 'user'
            username?: Maybe<string>
            picture?: Maybe<string>
          }
        }>
        comments_aggregate: {
          __typename?: 'comment_aggregate'
          aggregate?: Maybe<{
            __typename?: 'comment_aggregate_fields'
            count: number
          }>
        }
      }>
    } & CartItemDataFragment
  >
  user?: Maybe<
    Array<{
      __typename?: 'user'
      id: string
      username?: Maybe<string>
      picture?: Maybe<string>
      is_curator_computed?: Maybe<boolean>
      is_creator_computed?: Maybe<boolean>
    }>
  >
  shop?: Maybe<
    Array<{
      __typename?: 'shop'
      key: string
      slug: any
      display_name?: Maybe<string>
      description?: Maybe<string>
      favicon?: Maybe<string>
      cover_image?: Maybe<string>
      banner_image?: Maybe<string>
      shop_categorizations: Array<{
        __typename?: 'shop_categorization'
        category: Shop_Category_Enum
      }>
    }>
  >
}

export type GetUserFeedItemCartItemLikesQueryVariables = Exact<{
  userId: Scalars['String']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserFeedItemCartItemLikesQuery = {
  __typename?: 'query_root'
  feed_item_cart_item_likes: Array<{
    __typename?: 'feed_item_like'
    id: any
    feed_item: {
      __typename?: 'feed_item'
      cart_item?: Maybe<
        {
          __typename?: 'cart_item'
          feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
        } & CartItemDataFragment
      >
    }
  }>
}

export type GetUserFeedItemCollectionLikesQueryVariables = Exact<{
  userId: Scalars['String']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserFeedItemCollectionLikesQuery = {
  __typename?: 'query_root'
  feed_item_collection_likes: Array<{
    __typename?: 'feed_item_like'
    id: any
    feed_item: {
      __typename?: 'feed_item'
      collection?: Maybe<
        {
          __typename?: 'collection'
          feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
        } & UserCollectionsDataForProfilePageDataFragment
      >
    }
  }>
}

export type GetUserFeedItemLikeCountsQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type GetUserFeedItemLikeCountsQuery = {
  __typename?: 'query_root'
  liked_collections_aggreate: {
    __typename?: 'feed_item_like_aggregate'
    aggregate?: Maybe<{
      __typename?: 'feed_item_like_aggregate_fields'
      count: number
    }>
  }
  liked_cart_item_aggreate: {
    __typename?: 'feed_item_like_aggregate'
    aggregate?: Maybe<{
      __typename?: 'feed_item_like_aggregate_fields'
      count: number
    }>
  }
  liked_featured_shop_aggreate: {
    __typename?: 'feed_item_like_aggregate'
    aggregate?: Maybe<{
      __typename?: 'feed_item_like_aggregate_fields'
      count: number
    }>
  }
}

export type GetUserFeedItemShopLikesQueryVariables = Exact<{
  userId: Scalars['String']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserFeedItemShopLikesQuery = {
  __typename?: 'query_root'
  feed_item_shop_likes: Array<{
    __typename?: 'feed_item_like'
    id: any
    feed_item: {
      __typename?: 'feed_item'
      featured_shop?: Maybe<{
        __typename?: 'featured_shop'
        shop: { __typename?: 'shop' } & ShopDataFragment
        feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      }>
    }
  }>
}

export type GetUserOtherCollectionsQueryVariables = Exact<{
  userId: Scalars['String']
  collectionIdToExclude: Scalars['uuid']
}>

export type GetUserOtherCollectionsQuery = {
  __typename?: 'query_root'
  other_collections: Array<{
    __typename?: 'collection'
    id: any
    slug: any
    name: any
    description?: Maybe<string>
    cover_image?: Maybe<string>
    cover_image_cached?: Maybe<string>
    collection_cover_images?: Maybe<Array<string>>
    has_collaborators: boolean
    cart_items_count?: Maybe<any>
    user?: Maybe<{
      __typename?: 'user'
      id: string
      username?: Maybe<string>
      picture?: Maybe<string>
      created_at?: Maybe<any>
    }>
    collection_collaborators: Array<{
      __typename?: 'collection_collaborator'
      id: any
      user: { __typename?: 'user'; id: string; picture?: Maybe<string> }
    }>
    collection_items?: Maybe<
      Array<{
        __typename?: 'collection_cart_item'
        id: any
        cart_item: {
          __typename?: 'cart_item'
          id: any
          item_image_override?: Maybe<string>
          item: {
            __typename?: 'shop_item'
            id: any
            image?: Maybe<string>
            image_highres?: Maybe<string>
            cached_image?: Maybe<string>
            cached_placeholder_image?: Maybe<string>
          }
        }
      }>
    >
  }>
}

export type GetUserPicksForShopQueryVariables = Exact<{
  slug: Scalars['name']
  excludeUsername?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserPicksForShopQuery = {
  __typename?: 'query_root'
  influencer_carts: Array<{
    __typename?: 'cart'
    updated_at: any
    shop?: Maybe<{
      __typename?: 'shop'
      slug: any
      display_name?: Maybe<string>
    }>
    cart_items_aggregate: {
      __typename?: 'cart_item_aggregate'
      aggregate?: Maybe<{
        __typename?: 'cart_item_aggregate_fields'
        count: number
      }>
    }
    cart_items: Array<{
      __typename?: 'cart_item'
      id: any
      item_image_override?: Maybe<string>
      item_title_override?: Maybe<string>
      affiliate_redirect_link_override?: Maybe<string>
      item: {
        __typename?: 'shop_item'
        id: any
        metadata?: Maybe<any>
        display_title?: Maybe<string>
        image_highres?: Maybe<string>
        cached_image?: Maybe<string>
        image?: Maybe<string>
        image_metadata?: Maybe<any>
        cached_placeholder_image?: Maybe<string>
        is_inappropriate: boolean
      }
    }>
    user: {
      __typename?: 'user'
      username?: Maybe<string>
      id: string
      picture?: Maybe<string>
    }
  }>
}

export type GetUserWebBookmarksQueryVariables = Exact<{
  user_id: Scalars['String']
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type GetUserWebBookmarksQuery = {
  __typename?: 'query_root'
  web_bookmark_workflows: Array<{
    __typename?: 'workflow'
    id: any
    data: any
    status: Workflow_Status_Enum
  }>
}

export type GetUsernameClaimsQueryVariables = Exact<{ [key: string]: never }>

export type GetUsernameClaimsQuery = {
  __typename?: 'query_root'
  icons: Array<{
    __typename?: 'user'
    id: string
    username?: Maybe<string>
    picture?: Maybe<string>
    created_at?: Maybe<any>
  }>
  users: Array<{
    __typename?: 'user'
    id: string
    username?: Maybe<string>
    picture?: Maybe<string>
    created_at?: Maybe<any>
  }>
}

export type GetUsernamesForStaticPathsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
}>

export type GetUsernamesForStaticPathsQuery = {
  __typename?: 'query_root'
  users: Array<{ __typename?: 'user'; username?: Maybe<string> }>
}

export type InsertCommentMutationVariables = Exact<{
  item_id: Scalars['uuid']
  item_type: Commentable_Item_Type_Enum
  comment: Scalars['String']
}>

export type InsertCommentMutation = {
  __typename?: 'mutation_root'
  insert_comment_one?: Maybe<{
    __typename?: 'comment'
    id: any
    sequential_id: number
    comment: string
    created_at: any
  }>
}

export type InsertNewCollectionMutationVariables = Exact<{
  name: Scalars['name']
  slug: Scalars['name']
  type?: Maybe<Collection_Type_Enum>
  private?: Maybe<Scalars['Boolean']>
  giftlist?: Maybe<Scalars['Boolean']>
  exclusive?: Maybe<Scalars['Boolean']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type InsertNewCollectionMutation = {
  __typename?: 'mutation_root'
  collection?: Maybe<{ __typename?: 'collection' } & CollectionDataFragment>
}

export type FindCartItemForPdpQueryVariables = Exact<{
  id: Scalars['uuid']
  isOwn?: Maybe<Scalars['Boolean']>
}>

export type FindCartItemForPdpQuery = {
  __typename?: 'query_root'
  cart_item?: Maybe<
    {
      __typename?: 'cart_item'
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      item: {
        __typename?: 'shop_item'
        description?: Maybe<string>
        shop: { __typename?: 'shop' } & ShopDataFragment
      } & ItemDataFragment
      commentable_item_join?: Maybe<{
        __typename?: 'commentable_item'
        comments_aggregate: {
          __typename?: 'comment_aggregate'
          aggregate?: Maybe<{
            __typename?: 'comment_aggregate_fields'
            count: number
          }>
        }
      }>
    } & CartItemDataFragment
  >
  collection_cart_item: Array<{
    __typename?: 'collection_cart_item'
    collection: {
      __typename?: 'collection'
      id: any
      name: any
      slug: any
      collection_type: Collection_Type_Enum
      metadata?: Maybe<any>
      collection_items: Array<{
        __typename?: 'collection_cart_item'
        id: any
        cart_item: {
          __typename?: 'cart_item'
          feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
          item: {
            __typename?: 'shop_item'
            description?: Maybe<string>
            shop: { __typename?: 'shop' } & ShopDataFragment
          } & ItemDataFragment
          commentable_item_join?: Maybe<{
            __typename?: 'commentable_item'
            comments: Array<{
              __typename?: 'comment'
              id: any
              comment: string
              user: {
                __typename?: 'user'
                username?: Maybe<string>
                picture?: Maybe<string>
              }
            }>
            comments_aggregate: {
              __typename?: 'comment_aggregate'
              aggregate?: Maybe<{
                __typename?: 'comment_aggregate_fields'
                count: number
              }>
            }
          }>
        } & CartItemDataFragment
      }>
    }
  }>
  collection_cart_items: Array<{
    __typename?: 'collection_cart_item'
    collection: {
      __typename?: 'collection'
      id: any
      name: any
      slug: any
      collection_type: Collection_Type_Enum
      metadata?: Maybe<any>
      collection_items: Array<{
        __typename?: 'collection_cart_item'
        id: any
        cart_item: {
          __typename?: 'cart_item'
          feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
          item: {
            __typename?: 'shop_item'
            description?: Maybe<string>
            shop: { __typename?: 'shop' } & ShopDataFragment
          } & ItemDataFragment
          commentable_item_join?: Maybe<{
            __typename?: 'commentable_item'
            comments: Array<{
              __typename?: 'comment'
              id: any
              comment: string
              user: {
                __typename?: 'user'
                username?: Maybe<string>
                picture?: Maybe<string>
              }
            }>
            comments_aggregate: {
              __typename?: 'comment_aggregate'
              aggregate?: Maybe<{
                __typename?: 'comment_aggregate_fields'
                count: number
              }>
            }
          }>
        } & CartItemDataFragment
      }>
    }
  }>
  collections: Array<{
    __typename?: 'collection'
    id: any
    name: any
    slug: any
    cart_items_count?: Maybe<any>
    user?: Maybe<{
      __typename?: 'user'
      id: string
      username?: Maybe<string>
      picture?: Maybe<string>
    }>
    collection_items: Array<{
      __typename?: 'collection_cart_item'
      id: any
      cart_item: {
        __typename?: 'cart_item'
        id: any
        item_image_override?: Maybe<string>
        image_metadata?: Maybe<any>
        cart: {
          __typename?: 'cart'
          id: any
          user: { __typename?: 'user'; id: string }
        }
        item: {
          __typename?: 'shop_item'
          id: any
          image?: Maybe<string>
          image_highres?: Maybe<string>
          cached_image?: Maybe<string>
          image_metadata?: Maybe<any>
          metadata?: Maybe<any>
          is_inappropriate: boolean
        }
      }
    }>
  }>
}

export type GetCartItemCommentsQueryVariables = Exact<{
  id: Scalars['uuid']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetCartItemCommentsQuery = {
  __typename?: 'query_root'
  comments: Array<{
    __typename?: 'comment'
    id: any
    sequential_id: number
    comment: string
    created_at: any
    user: { __typename?: 'user' } & BasicUserDataFragment
  }>
  comment_aggregate: {
    __typename?: 'comment_aggregate'
    aggregate?: Maybe<{
      __typename?: 'comment_aggregate_fields'
      count: number
    }>
  }
}

export type SubCartItemCommentsSubscriptionVariables = Exact<{
  id: Scalars['uuid']
  lastFetchedSequentialId: Scalars['Int']
}>

export type SubCartItemCommentsSubscription = {
  __typename?: 'subscription_root'
  comment: Array<{ __typename?: 'comment'; id: any; sequential_id: number }>
}

export type SaveCartItemFromWebMutationVariables = Exact<{
  shop_key: Scalars['String']
  shop_data: Shop_Insert_Input
  shop_item_data: Shop_Item_Insert_Input
  source: Scalars['String']
}>

export type SaveCartItemFromWebMutation = {
  __typename?: 'mutation_root'
  cart_item?: Maybe<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type SearchForUsersWebQueryVariables = Exact<{
  query: Scalars['String']
}>

export type SearchForUsersWebQuery = {
  __typename?: 'query_root'
  users: Array<{ __typename?: 'user' } & BasicUserDataFragment>
}

export type CreateFreeCreatorSubscriptionMutationVariables = Exact<{
  creatorId: Scalars['String']
}>

export type CreateFreeCreatorSubscriptionMutation = {
  __typename?: 'mutation_root'
  subscribeToCreator: {
    __typename?: 'CreateSubscriptionOutput'
    user_subscription?: Maybe<{
      __typename?: 'user_subscription'
      id: any
      user_id: string
      creator_id: string
      subscription_id?: Maybe<string>
    }>
  }
}

export type GetCreatorForSubscriptionsQueryVariables = Exact<{
  username: Scalars['String']
}>

export type GetCreatorForSubscriptionsQuery = {
  __typename?: 'query_root'
  user: Array<
    {
      __typename?: 'user'
      has_billing_account?: Maybe<boolean>
    } & BasicUserDataFragment
  >
}

export type GetCreatorSubscriptionsQueryVariables = Exact<{
  id: Scalars['String']
  include_subscriptions?: Maybe<Scalars['Boolean']>
}>

export type GetCreatorSubscriptionsQuery = {
  __typename?: 'query_root'
  availableSubscriptions?: Maybe<
    Array<{
      __typename?: 'Subscription'
      id: string
      name: string
      description?: Maybe<string>
      unit_amount: number
      currency: string
      interval: RecurringInterval
    }>
  >
  creator?: Maybe<{
    __typename?: 'user'
    subscription_status?: Maybe<string>
    has_billing_account?: Maybe<boolean>
  }>
}

export type UnsubscribeFromCreatorMutationVariables = Exact<{
  creatorId: Scalars['String']
}>

export type UnsubscribeFromCreatorMutation = {
  __typename?: 'mutation_root'
  fullyUnsubscribeFromCreator: {
    __typename?: 'FullyUnsubscribeOutput'
    user_subscription_id: string
  }
}

export type SubscriptionCartItemEnrichmentStatusSubscriptionVariables = Exact<{
  id: Scalars['uuid']
}>

export type SubscriptionCartItemEnrichmentStatusSubscription = {
  __typename?: 'subscription_root'
  cart_item_by_pk?: Maybe<
    {
      __typename?: 'cart_item'
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      commentable_item_join?: Maybe<{
        __typename?: 'commentable_item'
        comments: Array<{
          __typename?: 'comment'
          id: any
          comment: string
          user: {
            __typename?: 'user'
            username?: Maybe<string>
            picture?: Maybe<string>
          }
        }>
        comments_aggregate: {
          __typename?: 'comment_aggregate'
          aggregate?: Maybe<{
            __typename?: 'comment_aggregate_fields'
            count: number
          }>
        }
      }>
    } & CartItemDataFragment
  >
}

export type UpdateCollectionCollaboratorManagerModeMutationVariables = Exact<{
  id: Scalars['uuid']
  manager: Scalars['Boolean']
}>

export type UpdateCollectionCollaboratorManagerModeMutation = {
  __typename?: 'mutation_root'
  update_collection_collaborator_by_pk?: Maybe<{
    __typename?: 'collection_collaborator'
    id: any
    manager: boolean
  }>
}

export type UpdateLastSeenCartItemsAtMutationVariables = Exact<{
  id: Scalars['String']
}>

export type UpdateLastSeenCartItemsAtMutation = {
  __typename?: 'mutation_root'
  update_user_flags?: Maybe<{
    __typename?: 'user_flags_mutation_response'
    affected_rows: number
  }>
}

export type UpdateManyImpersonationSignedOutAtMutationVariables = Exact<{
  updates: Array<User_Impersonation_Updates> | User_Impersonation_Updates
}>

export type UpdateManyImpersonationSignedOutAtMutation = {
  __typename?: 'mutation_root'
  update_user_impersonation_many?: Maybe<
    Array<
      Maybe<{
        __typename?: 'user_impersonation_mutation_response'
        affected_rows: number
      }>
    >
  >
}

export type UpsertCollectionMediaMutationVariables = Exact<{
  media_enabled_item_id: Scalars['uuid']
  media_enabled_item_type: Scalars['String']
  media_item: Media_Item_Insert_Input
}>

export type UpsertCollectionMediaMutation = {
  __typename?: 'mutation_root'
  insert_media_items_one?: Maybe<{
    __typename?: 'media_items'
    media_id: any
    media_enabled_item_id: any
    media_enabled_item_type: string
    media: { __typename?: 'media_item' } & MediaDataFragment
  }>
}

export type UpsertUserNotificationPreferencesMutationVariables = Exact<{
  args: Upsert_Notification_Preference_Args
}>

export type UpsertUserNotificationPreferencesMutation = {
  __typename?: 'mutation_root'
  upsert_notification_preference: Array<{
    __typename?: 'notification_preference'
    id: any
    user_id: string
    allowable_communication: Array<string>
    allowed: boolean
  }>
}

export type AddCartItemNoteMutationVariables = Exact<{
  cart_item_id: Scalars['uuid']
  note: Scalars['String']
  private: Scalars['Boolean']
}>

export type AddCartItemNoteMutation = {
  __typename?: 'mutation_root'
  cartItemNote?: Maybe<{
    __typename?: 'cart_item_note'
    id: any
    private: boolean
    cart_item_id: any
    note?: Maybe<string>
  }>
}

export type AddCartItemReactionMutationVariables = Exact<{
  cart_item_id: Scalars['uuid']
  note?: Maybe<Scalars['String']>
  private?: Maybe<Scalars['Boolean']>
  rating?: Maybe<Scalars['numeric']>
}>

export type AddCartItemReactionMutation = {
  __typename?: 'mutation_root'
  cartItemNote?: Maybe<{
    __typename?: 'cart_item_note'
    id: any
    private: boolean
    cart_item_id: any
    note?: Maybe<string>
  }>
  cartItemRating?: Maybe<{
    __typename?: 'cart_item'
    id: any
    creator_rating?: Maybe<any>
  }>
}

export type DeleteCartItemNoteMutationVariables = Exact<{
  id: Scalars['uuid']
  cart_item_id: Scalars['uuid']
}>

export type DeleteCartItemNoteMutation = {
  __typename?: 'mutation_root'
  delete_cart_item_note_by_pk?: Maybe<{
    __typename?: 'cart_item_note'
    id: any
    note?: Maybe<string>
    private: boolean
  }>
  update_cart_item_by_pk?: Maybe<{
    __typename?: 'cart_item'
    id: any
    creator_rating?: Maybe<any>
  }>
}

export type FindUserForIntercomQueryVariables = Exact<{
  id: Scalars['String']
}>

export type FindUserForIntercomQuery = {
  __typename?: 'query_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    anonymous?: Maybe<boolean>
    display_name?: Maybe<string>
    username?: Maybe<string>
    has_claimed_username_computed?: Maybe<boolean>
    picture?: Maybe<string>
    has_picture_set?: Maybe<boolean>
    internal_account?: Maybe<boolean>
    is_creator_computed?: Maybe<boolean>
    is_curator_computed?: Maybe<boolean>
    has_accounts_email_computed?: Maybe<boolean>
    ios_version?: Maybe<string>
    extension_version?: Maybe<string>
    first_app_install?: Maybe<string>
    created_at?: Maybe<any>
    updated_at?: Maybe<any>
  }>
}

export type GetCartItemsForMultiSelectQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid']
}>

export type GetCartItemsForMultiSelectQuery = {
  __typename?: 'query_root'
  cart_item: Array<{
    __typename?: 'cart_item'
    id: any
    item_image_override?: Maybe<string>
    display_title?: Maybe<string>
    description?: Maybe<string>
    pricing?: Maybe<any>
    cart: {
      __typename?: 'cart'
      id: any
      user: { __typename?: 'user'; id: string }
    }
    item: { __typename?: 'shop_item' } & ItemDataFragment
  }>
}

export type RemoveFromCollectionMutationVariables = Exact<{
  collection_id: Scalars['uuid']
  cart_item_ids: Array<Scalars['uuid']> | Scalars['uuid']
}>

export type RemoveFromCollectionMutation = {
  __typename?: 'mutation_root'
  collectionCartItem?: Maybe<{
    __typename?: 'collection_cart_item_mutation_response'
    returning: Array<{
      __typename?: 'collection_cart_item'
      id: any
      cart_item_id: any
    }>
  }>
}

export type ExtensionFindCollectionQueryVariables = Exact<{
  collection_id: Scalars['uuid']
  itemLimit?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type ExtensionFindCollectionQuery = {
  __typename?: 'query_root'
  collection?: Maybe<{
    __typename?: 'collection'
    id: any
    user_id?: Maybe<string>
    short_id?: Maybe<any>
    name: any
    description?: Maybe<string>
    slug: any
    private: boolean
    giftlist: boolean
    all: boolean
    paid: boolean
    curation: boolean
    metadata?: Maybe<any>
    bookmarks: boolean
    ogimage_fingerprint_updated?: Maybe<string>
    cover_image?: Maybe<string>
    collection_cover_images?: Maybe<Array<string>>
    cover_image_cached?: Maybe<string>
    cart_items_quantity?: Maybe<any>
    cart_items_count?: Maybe<any>
    created_at?: Maybe<any>
    updated_at?: Maybe<any>
    budget?: Maybe<any>
    has_collaborators: boolean
    path?: Maybe<string>
    is_a_collaborator?: Maybe<boolean>
    seo_fields?: Maybe<any>
    collection_collaborators_aggregate: {
      __typename?: 'collection_collaborator_aggregate'
      aggregate?: Maybe<{
        __typename?: 'collection_collaborator_aggregate_fields'
        count: number
      }>
    }
    collection_collaborators: Array<{
      __typename?: 'collection_collaborator'
      id: any
      user: {
        __typename?: 'user'
        id: string
        name?: Maybe<string>
        username?: Maybe<string>
        picture?: Maybe<string>
        verified?: Maybe<boolean>
        created_at?: Maybe<any>
      }
    }>
    user?: Maybe<{
      __typename?: 'user'
      id: string
      name?: Maybe<string>
      username?: Maybe<string>
      picture?: Maybe<string>
      verified?: Maybe<boolean>
      created_at?: Maybe<any>
      short_id: any
      anonymous?: Maybe<boolean>
    }>
    collection_items: Array<{
      __typename?: 'collection_cart_item'
      id: any
      created_at: any
      updated_at: any
      cart_item: {
        __typename?: 'cart_item'
        id: any
        cart_id: any
        item_id: any
        shop_key: string
        quantity: any
        bookmark: boolean
        in_collection?: Maybe<boolean>
        archived_at?: Maybe<any>
        removed_at?: Maybe<any>
        removed_reason?: Maybe<Remove_Reason_Enum>
        deleted_at?: Maybe<any>
        created_at: any
        updated_at: any
        source?: Maybe<string>
        affiliate_redirect_link_override?: Maybe<string>
        item_image_override?: Maybe<string>
        like_count?: Maybe<number>
        note?: Maybe<{
          __typename?: 'cart_item_note'
          id: any
          note?: Maybe<string>
          private: boolean
        }>
        cart: {
          __typename?: 'cart'
          id: any
          user: {
            __typename?: 'user'
            id: string
            name?: Maybe<string>
            username?: Maybe<string>
            picture?: Maybe<string>
            verified?: Maybe<boolean>
            created_at?: Maybe<any>
          }
        }
        shop: {
          __typename?: 'shop'
          id: any
          key: string
          cart_path?: Maybe<string>
          type?: Maybe<any>
          platform?: Maybe<string>
          name: any
          display_name?: Maybe<string>
          cover_image?: Maybe<string>
          slug: any
          favicon?: Maybe<string>
          created_at?: Maybe<any>
          updated_at?: Maybe<any>
        }
        item: {
          __typename?: 'shop_item'
          id: any
          item_id: string
          shop_key: string
          display_title?: Maybe<string>
          url?: Maybe<string>
          image?: Maybe<string>
          image_highres?: Maybe<string>
          cached_image?: Maybe<string>
          cached_placeholder_image?: Maybe<string>
          image_metadata?: Maybe<any>
          pricing?: Maybe<any>
          parsed_pricing?: Maybe<any>
          metadata?: Maybe<any>
          created_at: any
          updated_at: any
          is_inappropriate: boolean
        }
        collection_item_claim?: Maybe<{
          __typename?: 'collection_item_claim'
          id: any
          status: Collection_Item_Claim_Status_Enum
          user: {
            __typename?: 'user'
            id: string
            name?: Maybe<string>
            username?: Maybe<string>
            picture?: Maybe<string>
            verified?: Maybe<boolean>
            created_at?: Maybe<any>
          }
        }>
        collection_cart_items: Array<{
          __typename?: 'collection_cart_item'
          collection_id: any
        }>
        user?: Maybe<
          Array<{
            __typename?: 'user'
            id: string
            picture?: Maybe<string>
            username?: Maybe<string>
          }>
        >
        feed_item?: Maybe<{
          __typename?: 'feed_item'
          id: any
          item_id: any
          item_type: Feed_Item_Type_Enum
          in_discovery_backlog?: Maybe<boolean>
          in_discovery_staging?: Maybe<boolean>
          in_discovery_feed?: Maybe<boolean>
          metadata: any
          liked_by_user?: Maybe<boolean>
          like_count?: Maybe<number>
        }>
      }
    }>
  }>
}

export type FindShopItemByUrlQueryVariables = Exact<{
  url: Scalars['String']
}>

export type FindShopItemByUrlQuery = {
  __typename?: 'query_root'
  shopItem: Array<{ __typename?: 'shop_item' } & ItemDataFragment>
}

export type InsertAddBookmarkToCollectionWorkflowMutationVariables = Exact<{
  userId: Scalars['String']
  collection_id: Scalars['String']
  parent_workflow_id: Scalars['uuid']
}>

export type InsertAddBookmarkToCollectionWorkflowMutation = {
  __typename?: 'mutation_root'
  insert_workflow_one?: Maybe<{ __typename?: 'workflow'; id: any }>
}

export type AcceptCollectionInviteMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type AcceptCollectionInviteMutation = {
  __typename?: 'mutation_root'
  update_collection_invite_by_pk?: Maybe<{
    __typename?: 'collection_invite'
    id: any
    status: Collection_Invite_Status_Enum
  }>
}

export type AddBookmarkToCollectionMutationVariables = Exact<{
  cart_item_id: Scalars['uuid']
  collection_id: Scalars['uuid']
}>

export type AddBookmarkToCollectionMutation = {
  __typename?: 'mutation_root'
  collectionCartItem?: Maybe<{ __typename?: 'collection_cart_item'; id: any }>
}

export type AddNewCollectionMutationVariables = Exact<{
  name: Scalars['name']
  slug: Scalars['name']
  description?: Maybe<Scalars['String']>
  private?: Maybe<Scalars['Boolean']>
  budget?: Maybe<Scalars['numeric']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
  type?: Maybe<Collection_Type_Enum>
  giftlist?: Maybe<Scalars['Boolean']>
}>

export type AddNewCollectionMutation = {
  __typename?: 'mutation_root'
  collection?: Maybe<
    { __typename?: 'collection' } & CollectionDataFragment &
      CollectionCollaboratorsDataFragment
  >
}

export type AddNewCollectionSlugHistoryMutationVariables = Exact<{
  slug: Scalars['name']
  collection_id: Scalars['uuid']
}>

export type AddNewCollectionSlugHistoryMutation = {
  __typename?: 'mutation_root'
  collection_slug_history?: Maybe<{
    __typename?: 'collection_slug_history'
    id: any
    slug: any
  }>
}

export type AddToCollectionMutationVariables = Exact<{
  objects:
    | Array<Collection_Cart_Item_Insert_Input>
    | Collection_Cart_Item_Insert_Input
}>

export type AddToCollectionMutation = {
  __typename?: 'mutation_root'
  collectionCartItem?: Maybe<{
    __typename?: 'collection_cart_item_mutation_response'
    returning: Array<{ __typename?: 'collection_cart_item'; id: any }>
  }>
}

export type AdminSaveCollectionSeoDataMutationVariables = Exact<{
  id: Scalars['uuid']
  set_input: Collection_Set_Input
}>

export type AdminSaveCollectionSeoDataMutation = {
  __typename?: 'mutation_root'
  update_collection_by_pk?: Maybe<{
    __typename?: 'collection'
    id: any
    name: any
    slug: any
    collection_link?: Maybe<string>
    cover_image?: Maybe<string>
    collection_cover_images?: Maybe<Array<string>>
    description?: Maybe<string>
    seo_fields?: Maybe<any>
  }>
}

export type DeleteShopItemMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type DeleteShopItemMutation = {
  __typename?: 'mutation_root'
  delete_shop_item_by_pk?: Maybe<{ __typename?: 'shop_item'; id: any }>
}

export type GetShopItemsForAdminPageQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetShopItemsForAdminPageQuery = {
  __typename?: 'query_root'
  shop_item: Array<
    {
      __typename?: 'shop_item'
      shop: { __typename?: 'shop' } & ShopDataFragment
      cart_items_aggregate: {
        __typename?: 'cart_item_aggregate'
        aggregate?: Maybe<{
          __typename?: 'cart_item_aggregate_fields'
          count: number
        }>
      }
      cart_items: Array<{
        __typename?: 'cart_item'
        id: any
        created_at: any
        cart: {
          __typename?: 'cart'
          user: { __typename?: 'user' } & BasicUserDataFragment
        }
        collection_cart_items: Array<{
          __typename?: 'collection_cart_item'
          collection: {
            __typename?: 'collection'
            id: any
            slug: any
            name: any
            user?: Maybe<{ __typename?: 'user'; username?: Maybe<string> }>
          }
        }>
      }>
    } & ItemDataFragment
  >
}

export type UserDataForAdminPushNotificationFragment = {
  __typename?: 'user'
  id: string
  name?: Maybe<string>
  username?: Maybe<string>
  picture?: Maybe<string>
  email?: Maybe<string>
  created_at?: Maybe<any>
}

export type GetTotalUserWithActivePushNotificationTokensQueryVariables = Exact<{
  [key: string]: never
}>

export type GetTotalUserWithActivePushNotificationTokensQuery = {
  __typename?: 'query_root'
  user_aggregate: {
    __typename?: 'user_aggregate'
    aggregate?: Maybe<{ __typename?: 'user_aggregate_fields'; count: number }>
  }
}

export type UsersByIdForValidationQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String']
}>

export type UsersByIdForValidationQuery = {
  __typename?: 'query_root'
  user: Array<
    { __typename?: 'user' } & UserDataForAdminPushNotificationFragment
  >
}

export type UsersWithActiveMobileDevicesQueryVariables = Exact<{
  query: Scalars['String']
  limit?: Maybe<Scalars['Int']>
}>

export type UsersWithActiveMobileDevicesQuery = {
  __typename?: 'query_root'
  users: Array<
    { __typename?: 'user' } & UserDataForAdminPushNotificationFragment
  >
}

export type ArchiveCartItemsMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type ArchiveCartItemsMutation = {
  __typename?: 'mutation_root'
  cartItem?: Maybe<{
    __typename?: 'cart_item_mutation_response'
    returning: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
  }>
}

export type BatchCreateMobilePushNotificationMutationVariables = Exact<{
  objects:
    | Array<Mobile_Push_Notification_Insert_Input>
    | Mobile_Push_Notification_Insert_Input
}>

export type BatchCreateMobilePushNotificationMutation = {
  __typename?: 'mutation_root'
  insert_mobile_push_notification?: Maybe<{
    __typename?: 'mobile_push_notification_mutation_response'
    affected_rows: number
    returning: Array<{ __typename?: 'mobile_push_notification'; id: any }>
  }>
}

export type BatchDeactiveUserPushNotificationTokensMutationVariables = Exact<{
  tokens: Array<Scalars['String']> | Scalars['String']
}>

export type BatchDeactiveUserPushNotificationTokensMutation = {
  __typename?: 'mutation_root'
  update_user_push_notification_token?: Maybe<{
    __typename?: 'user_push_notification_token_mutation_response'
    affected_rows: number
  }>
}

export type BatchUpdateFailedPushNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid']
}>

export type BatchUpdateFailedPushNotificationsMutation = {
  __typename?: 'mutation_root'
  update_mobile_push_notification?: Maybe<{
    __typename?: 'mobile_push_notification_mutation_response'
    affected_rows: number
  }>
}

export type BulkUpdateWorkflowStatusMutationVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid']
  status: Workflow_Status_Enum
  statusDetails?: Maybe<Scalars['String']>
}>

export type BulkUpdateWorkflowStatusMutation = {
  __typename?: 'mutation_root'
  update_workflow?: Maybe<{
    __typename?: 'workflow_mutation_response'
    returning: Array<{
      __typename?: 'workflow'
      id: any
      status: Workflow_Status_Enum
      status_details?: Maybe<string>
    }>
  }>
}

export type CanUserAddToCollectionQueryVariables = Exact<{
  collectionId?: Maybe<Scalars['uuid']>
  userId: Scalars['String']
}>

export type CanUserAddToCollectionQuery = {
  __typename?: 'query_root'
  collection: Array<{ __typename?: 'collection'; id: any }>
}

export type CopyItemToCartMutationVariables = Exact<{
  itemId: Scalars['uuid']
  shopKey: Scalars['String']
  source: Scalars['String']
  metadata?: Maybe<Scalars['jsonb']>
}>

export type CopyItemToCartMutation = {
  __typename?: 'mutation_root'
  cart?: Maybe<
    { __typename?: 'cart' } & CartDataFragment & CartItemsDataFragment
  >
}

export type SaveCartWithSeparatedRemovalsMutationVariables = Exact<{
  cart_items_data: Array<Cart_Item_Insert_Input> | Cart_Item_Insert_Input
  userId?: Maybe<Scalars['String']>
  shopKey?: Maybe<Scalars['String']>
  removedItemIds?: Maybe<Array<Scalars['String']> | Scalars['String']>
  withRemovals?: Maybe<Scalars['Boolean']>
}>

export type SaveCartWithSeparatedRemovalsMutation = {
  __typename?: 'mutation_root'
  cart_items?: Maybe<{
    __typename?: 'cart_item_mutation_response'
    returning: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
  }>
  update_cart_item?: Maybe<{
    __typename?: 'cart_item_mutation_response'
    returning: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
  }>
}

export type ClaimCollectionCartItemMutationVariables = Exact<{
  cart_item_id: Scalars['uuid']
  collection_id: Scalars['uuid']
}>

export type ClaimCollectionCartItemMutation = {
  __typename?: 'mutation_root'
  collectionItemClaim?: Maybe<
    { __typename?: 'collection_item_claim' } & CollectionItemClaimDataFragment
  >
}

export type CleanCollectionSlugMutationVariables = Exact<{
  collection_id: Scalars['uuid']
  slug: Scalars['name']
}>

export type CleanCollectionSlugMutation = {
  __typename?: 'mutation_root'
  collection?: Maybe<{ __typename?: 'collection'; id: any; slug: any }>
}

export type ClearCartDeletedItemsMutationVariables = Exact<{
  cart_id: Scalars['uuid']
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type ClearCartDeletedItemsMutation = {
  __typename?: 'mutation_root'
  deletedCartItems?: Maybe<{
    __typename?: 'cart_item_mutation_response'
    returning: Array<{ __typename?: 'cart_item'; id: any }>
  }>
  cart?: Maybe<{ __typename?: 'cart'; id: any }>
}

export type GetCollectionByPkQueryVariables = Exact<{
  id: Scalars['uuid']
  itemLimit?: Maybe<Scalars['Int']>
}>

export type GetCollectionByPkQuery = {
  __typename?: 'query_root'
  collection?: Maybe<
    {
      __typename?: 'collection'
      id: any
      name: any
      slug: any
      cart_items_count?: Maybe<any>
      user?: Maybe<{ __typename?: 'user'; username?: Maybe<string> }>
    } & CollectionItemsDataFragment
  >
}

export type GetCollectionCollaboratorPicturesQueryVariables = Exact<{
  collectionId?: Maybe<Scalars['uuid']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetCollectionCollaboratorPicturesQuery = {
  __typename?: 'query_root'
  collection_collaborator_aggregate: {
    __typename?: 'collection_collaborator_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_collaborator_aggregate_fields'
      count: number
    }>
  }
  collection_collaborator: Array<{
    __typename?: 'collection_collaborator'
    id: any
    user: { __typename?: 'user'; id: string; picture?: Maybe<string> }
  }>
}

export type GetCollectionCollaboratorsQueryVariables = Exact<{
  collectionId?: Maybe<Scalars['uuid']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetCollectionCollaboratorsQuery = {
  __typename?: 'query_root'
  collection_collaborator_aggregate: {
    __typename?: 'collection_collaborator_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_collaborator_aggregate_fields'
      count: number
    }>
  }
  collection_collaborator: Array<{
    __typename?: 'collection_collaborator'
    id: any
    user: {
      __typename?: 'user'
      collections_aggregate: {
        __typename?: 'collection_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_aggregate_fields'
          count: number
        }>
      }
    } & BasicUserDataFragment
  }>
}

export type GetCollectionItemsQueryVariables = Exact<{
  collectionId: Scalars['uuid']
  itemLimit?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
  itemOffset?: Maybe<Scalars['Int']>
}>

export type GetCollectionItemsQuery = {
  __typename?: 'query_root'
  collection_cart_item_aggregate: {
    __typename?: 'collection_cart_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_cart_item_aggregate_fields'
      count: number
    }>
  }
  collection_cart_item: Array<{
    __typename?: 'collection_cart_item'
    id: any
    order?: Maybe<any>
    created_at: any
    updated_at: any
    cart_item: {
      __typename?: 'cart_item'
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      commentable_item_join?: Maybe<{
        __typename?: 'commentable_item'
        comments: Array<{
          __typename?: 'comment'
          id: any
          comment: string
          user: {
            __typename?: 'user'
            username?: Maybe<string>
            picture?: Maybe<string>
          }
        }>
        comments_aggregate: {
          __typename?: 'comment_aggregate'
          aggregate?: Maybe<{
            __typename?: 'comment_aggregate_fields'
            count: number
          }>
        }
      }>
    } & CartItemDataFragment
  }>
}

export type GetShopEditorialLogosQueryVariables = Exact<{
  shopKeys?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type GetShopEditorialLogosQuery = {
  __typename?: 'query_root'
  shop: Array<{
    __typename?: 'shop'
    editorial_shop_logo?: Maybe<string>
    display_name?: Maybe<string>
  }>
}

export type SaveCollectionEmailExcerptMutationVariables = Exact<{
  collectionId: Scalars['uuid']
  email_excerpt: Scalars['String']
}>

export type SaveCollectionEmailExcerptMutation = {
  __typename?: 'mutation_root'
  update_collection_by_pk?: Maybe<{
    __typename?: 'collection'
    id: any
    email_excerpt?: Maybe<string>
  }>
}

export type ToggleCollectionStateMutationVariables = Exact<{
  collectionId: Scalars['uuid']
  state: Collection_State_Enum
  paid?: Maybe<Scalars['Boolean']>
  exclusive?: Maybe<Scalars['Boolean']>
  notificationPreferences: Scalars['jsonb']
  email_excerpt?: Maybe<Scalars['String']>
}>

export type ToggleCollectionStateMutation = {
  __typename?: 'mutation_root'
  update_collection_by_pk?: Maybe<{
    __typename?: 'collection'
    id: any
    state?: Maybe<Collection_State_Enum>
  }>
}

export type UpdateCollectionCartItemsManyMutationVariables = Exact<{
  updates: Array<Collection_Cart_Item_Updates> | Collection_Cart_Item_Updates
}>

export type UpdateCollectionCartItemsManyMutation = {
  __typename?: 'mutation_root'
  update_collection_cart_item_many?: Maybe<
    Array<
      Maybe<{
        __typename?: 'collection_cart_item_mutation_response'
        affected_rows: number
      }>
    >
  >
}

export type UpdateCollectionsManyMutationVariables = Exact<{
  updates: Array<Collection_Updates> | Collection_Updates
}>

export type UpdateCollectionsManyMutation = {
  __typename?: 'mutation_root'
  update_collection_many?: Maybe<
    Array<
      Maybe<{
        __typename?: 'collection_mutation_response'
        affected_rows: number
      }>
    >
  >
}

export type CreateNewCollectionForMobileBookmarkMutationVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  name: Scalars['name']
  description?: Maybe<Scalars['String']>
  private?: Scalars['Boolean']
  slug: Scalars['name']
}>

export type CreateNewCollectionForMobileBookmarkMutation = {
  __typename?: 'mutation_root'
  collection?: Maybe<{ __typename?: 'collection'; id: any }>
}

export type DeleteCartItemsMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type DeleteCartItemsMutation = {
  __typename?: 'mutation_root'
  cartItem?: Maybe<{
    __typename?: 'cart_item_mutation_response'
    returning: Array<{
      __typename?: 'cart_item'
      id: any
      deleted_at?: Maybe<any>
    }>
  }>
}

export type DeleteCollectionCollaboratorInviteMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type DeleteCollectionCollaboratorInviteMutation = {
  __typename?: 'mutation_root'
  delete_collection_invite_by_pk?: Maybe<{
    __typename?: 'collection_invite'
    id: any
  }>
}

export type DeleteDiscoveryFeedMutationVariables = Exact<{
  feed_item_id: Scalars['uuid']
}>

export type DeleteDiscoveryFeedMutation = {
  __typename?: 'mutation_root'
  delete_discovery_feed?: Maybe<{
    __typename?: 'discovery_feed_mutation_response'
    returning: Array<{
      __typename?: 'discovery_feed'
      id: any
      feed_item: { __typename?: 'feed_item' } & FeedItemDataFragment
    }>
  }>
}

export type DeleteDiscoveryFeedBacklogMutationVariables = Exact<{
  feed_item_id: Scalars['uuid']
}>

export type DeleteDiscoveryFeedBacklogMutation = {
  __typename?: 'mutation_root'
  delete_discovery_backlog?: Maybe<{
    __typename?: 'discovery_backlog_mutation_response'
    returning: Array<{
      __typename?: 'discovery_backlog'
      id: any
      feed_item: { __typename?: 'feed_item' } & FeedItemDataFragment
    }>
  }>
}

export type DeleteShopCategorizationMutationVariables = Exact<{
  shop_key: Scalars['String']
  weight: Scalars['Int']
}>

export type DeleteShopCategorizationMutation = {
  __typename?: 'mutation_root'
  delete_shop_categorization?: Maybe<{
    __typename?: 'shop_categorization_mutation_response'
    returning: Array<{ __typename?: 'shop_categorization'; id: any }>
  }>
}

export type EditCollectionMutationVariables = Exact<{
  id: Scalars['uuid']
  name: Scalars['name']
  description: Scalars['String']
  private?: Maybe<Scalars['Boolean']>
  slug?: Maybe<Scalars['name']>
  cover_image?: Maybe<Scalars['String']>
  budget?: Maybe<Scalars['numeric']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type EditCollectionMutation = {
  __typename?: 'mutation_root'
  collection?: Maybe<{ __typename?: 'collection' } & CollectionDataFragment>
}

export type UserForEmailDataFragment = {
  __typename?: 'user'
  id: string
  email?: Maybe<string>
  name?: Maybe<string>
}

export type GetCartItemSavedDataForEmailQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetCartItemSavedDataForEmailQuery = {
  __typename?: 'query_root'
  notification_center_item_by_pk?: Maybe<{
    __typename?: 'notification_center_item'
    id: any
    user?: Maybe<
      {
        __typename?: 'user'
        username?: Maybe<string>
      } & UserForEmailDataFragment
    >
    notifiable_item?: Maybe<{
      __typename?: 'notifiable_item'
      cart_item?: Maybe<{
        __typename?: 'cart_item'
        id: any
        item_image_override?: Maybe<string>
        item: {
          __typename?: 'shop_item'
          id: any
          display_title?: Maybe<string>
          image?: Maybe<string>
          cached_image?: Maybe<string>
        }
        shop: {
          __typename?: 'shop'
          name: any
          slug: any
          has_pdp?: Maybe<boolean>
        }
        originating_collection?: Maybe<
          Array<{
            __typename?: 'collection'
            id: any
            name: any
            metadata?: Maybe<any>
          }>
        >
      }>
    }>
  }>
}

export type GetCollabInviteAcceptedDataForEmailQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetCollabInviteAcceptedDataForEmailQuery = {
  __typename?: 'query_root'
  notification_center_item_by_pk?: Maybe<{
    __typename?: 'notification_center_item'
    id: any
    user?: Maybe<
      {
        __typename?: 'user'
        username?: Maybe<string>
      } & UserForEmailDataFragment
    >
    notifiable_item?: Maybe<{
      __typename?: 'notifiable_item'
      collection_invite?: Maybe<{
        __typename?: 'collection_invite'
        id: any
        collection: {
          __typename?: 'collection'
          id: any
          name: any
          slug: any
          metadata?: Maybe<any>
          user?: Maybe<{
            __typename?: 'user'
            id: string
            username?: Maybe<string>
          }>
          preview_collection_items?: Maybe<
            Array<{
              __typename?: 'collection_cart_item'
              id: any
              cart_item: {
                __typename?: 'cart_item'
                id: any
                item_image_override?: Maybe<string>
                display_title?: Maybe<string>
                item: {
                  __typename?: 'shop_item'
                  id: any
                  cached_image?: Maybe<string>
                  shop: { __typename?: 'shop'; display_name?: Maybe<string> }
                }
              }
            }>
          >
        }
        invitee?: Maybe<{
          __typename?: 'user'
          name?: Maybe<string>
          username?: Maybe<string>
          picture?: Maybe<string>
        }>
      }>
    }>
  }>
}

export type GetCollabInviteDataForEmailQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetCollabInviteDataForEmailQuery = {
  __typename?: 'query_root'
  collection_invite_by_pk?: Maybe<{
    __typename?: 'collection_invite'
    id: any
    invitee_email?: Maybe<string>
    invitee_id?: Maybe<string>
    requester_id: string
    collection: {
      __typename?: 'collection'
      id: any
      name: any
      cover_image_cached?: Maybe<string>
      path?: Maybe<string>
      user?: Maybe<{
        __typename?: 'user'
        name?: Maybe<string>
        username?: Maybe<string>
        picture?: Maybe<string>
      }>
    }
    invitee?: Maybe<{ __typename?: 'user' } & UserForEmailDataFragment>
  }>
}

export type GetContactInviteDataForEmailQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetContactInviteDataForEmailQuery = {
  __typename?: 'query_root'
  contact_invite_by_pk?: Maybe<{
    __typename?: 'contact_invite'
    id: any
    type: Contact_Invite_Type_Enum
    contact_info: string
    requester: {
      __typename?: 'user'
      name?: Maybe<string>
      username?: Maybe<string>
      picture?: Maybe<string>
    }
  }>
}

export type GetFeaturedCollectionInDiscoveryFeedDataQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetFeaturedCollectionInDiscoveryFeedDataQuery = {
  __typename?: 'query_root'
  notification_center_item_by_pk?: Maybe<{
    __typename?: 'notification_center_item'
    id: any
    user?: Maybe<
      {
        __typename?: 'user'
        username?: Maybe<string>
      } & UserForEmailDataFragment
    >
    notifiable_item?: Maybe<{
      __typename?: 'notifiable_item'
      collection?: Maybe<{
        __typename?: 'collection'
        id: any
        name: any
        slug: any
        collection_cover_images?: Maybe<Array<string>>
        preview_collection_items?: Maybe<
          Array<{
            __typename?: 'collection_cart_item'
            id: any
            cart_item: {
              __typename?: 'cart_item'
              id: any
              item_image_override?: Maybe<string>
              item: {
                __typename?: 'shop_item'
                id: any
                image?: Maybe<string>
                cached_image?: Maybe<string>
                shop: { __typename?: 'shop'; display_name?: Maybe<string> }
              }
            }
          }>
        >
      }>
    }>
  }>
}

export type GetNewFollowerDataForEmailQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetNewFollowerDataForEmailQuery = {
  __typename?: 'query_root'
  notification_center_item_by_pk?: Maybe<{
    __typename?: 'notification_center_item'
    id: any
    user?: Maybe<
      {
        __typename?: 'user'
        username?: Maybe<string>
      } & UserForEmailDataFragment
    >
    notifiable_item?: Maybe<{
      __typename?: 'notifiable_item'
      follow?: Maybe<{
        __typename?: 'user_items_followed'
        user: {
          __typename?: 'user'
          name?: Maybe<string>
          username?: Maybe<string>
          picture?: Maybe<string>
        }
        followable_item?: Maybe<{
          __typename?: 'followable_item'
          collection?: Maybe<{
            __typename?: 'collection'
            id: any
            name: any
            slug: any
            metadata?: Maybe<any>
            user?: Maybe<{
              __typename?: 'user'
              id: string
              username?: Maybe<string>
            }>
            preview_collection_items?: Maybe<
              Array<{
                __typename?: 'collection_cart_item'
                id: any
                cart_item: {
                  __typename?: 'cart_item'
                  id: any
                  item_image_override?: Maybe<string>
                  display_title?: Maybe<string>
                  item: {
                    __typename?: 'shop_item'
                    id: any
                    cached_image?: Maybe<string>
                    shop: { __typename?: 'shop'; display_name?: Maybe<string> }
                  }
                }
              }>
            >
          }>
        }>
      }>
    }>
  }>
}

export type GetUserForEmailQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetUserForEmailQuery = {
  __typename?: 'query_root'
  user?: Maybe<{ __typename?: 'user' } & UserForEmailDataFragment>
}

export type FindDealHopGateFlagsExtensionQueryVariables = Exact<{
  user_id: Scalars['String']
}>

export type FindDealHopGateFlagsExtensionQuery = {
  __typename?: 'query_root'
  deal_hop_gate_by_pk?: Maybe<
    { __typename?: 'deal_hop_gate' } & DealHopGateDataFragment
  >
}

export type GetSavedItemByUrlQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  itemUrl?: Maybe<Scalars['String']>
  slug: Scalars['name']
}>

export type GetSavedItemByUrlQuery = {
  __typename?: 'query_root'
  carts: Array<{
    __typename?: 'cart'
    cart_items: Array<{
      __typename?: 'cart_item'
      id: any
      points?: Maybe<any>
    }>
  }>
}

export type UpdateDealHopPendingGateTimestampExtensionMutationVariables =
  Exact<{
    userId: Scalars['String']
    timestamp: Scalars['timestamptz']
  }>

export type UpdateDealHopPendingGateTimestampExtensionMutation = {
  __typename?: 'mutation_root'
  update_deal_hop_gate_by_pk?: Maybe<
    { __typename?: 'deal_hop_gate' } & DealHopGateDataFragment
  >
}

export type PermissionHeartbeatMutationVariables = Exact<{
  userId: Scalars['String']
  update?: Maybe<User_Flags_Set_Input>
}>

export type PermissionHeartbeatMutation = {
  __typename?: 'mutation_root'
  update_user_flags_by_pk?: Maybe<{
    __typename?: 'user_flags'
    user_id: string
  }>
}

export type GetRecentUserItemsQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetRecentUserItemsQuery = {
  __typename?: 'query_root'
  cart_item: Array<{
    __typename?: 'cart_item'
    id: any
    item_image_override?: Maybe<string>
    item: {
      __typename?: 'shop_item'
      image?: Maybe<string>
      image_highres?: Maybe<string>
      cached_image?: Maybe<string>
    }
  }>
}

export type GetUserSavedCartDataQueryVariables = Exact<{
  slug: Scalars['name']
  userId: Scalars['String']
  itemUrl?: Maybe<Scalars['String']>
}>

export type GetUserSavedCartDataQuery = {
  __typename?: 'query_root'
  my_carts: Array<{
    __typename?: 'cart'
    updated_at: any
    user: { __typename?: 'user'; verified?: Maybe<boolean> }
    shop?: Maybe<{ __typename?: 'shop'; slug: any }>
    saved_items: Array<{
      __typename?: 'cart_item'
      id: any
      source?: Maybe<string>
      points?: Maybe<any>
      item: { __typename?: 'shop_item'; id: any; display_title?: Maybe<string> }
    }>
    cart_items_aggregate: {
      __typename?: 'cart_item_aggregate'
      aggregate?: Maybe<{
        __typename?: 'cart_item_aggregate_fields'
        count: number
      }>
    }
    cart_items: Array<{
      __typename?: 'cart_item'
      id: any
      item_image_override?: Maybe<string>
      item_title_override?: Maybe<string>
      affiliate_redirect_link_override?: Maybe<string>
      points?: Maybe<any>
      item: {
        __typename?: 'shop_item'
        id: any
        metadata?: Maybe<any>
        display_title?: Maybe<string>
        image_highres?: Maybe<string>
        cached_image?: Maybe<string>
        image?: Maybe<string>
        image_metadata?: Maybe<any>
        cached_placeholder_image?: Maybe<string>
        is_inappropriate: boolean
      }
      cart: { __typename?: 'cart'; user_id: string }
    }>
  }>
}

export type FindAllShopItemsToBeScrapedQueryVariables = Exact<{
  shop_types?: Maybe<Array<Scalars['name']> | Scalars['name']>
  limitToBeScraped: Scalars['Int']
  limitShopItem: Scalars['Int']
  offset: Scalars['Int']
}>

export type FindAllShopItemsToBeScrapedQuery = {
  __typename?: 'query_root'
  shop_item_to_be_scraped: Array<{
    __typename?: 'shop_item_to_be_scraped'
    id?: Maybe<any>
    url?: Maybe<string>
    pricing?: Maybe<any>
    item_id?: Maybe<string>
    shop?: Maybe<{
      __typename?: 'shop'
      type?: Maybe<any>
      key: string
      metadata: any
    }>
  }>
  shop_item: Array<{
    __typename?: 'shop_item'
    id: any
    url?: Maybe<string>
    pricing?: Maybe<any>
    item_id: string
    shop: { __typename?: 'shop'; type?: Maybe<any>; key: string; metadata: any }
  }>
}

export type FindAllShopsByKeyQueryVariables = Exact<{
  shopKeys: Array<Scalars['String']> | Scalars['String']
}>

export type FindAllShopsByKeyQuery = {
  __typename?: 'query_root'
  shop: Array<{ __typename?: 'shop'; key: string }>
}

export type FindAllShopsByMetadataKeyQueryVariables = Exact<{
  metadataKey: Scalars['String']
}>

export type FindAllShopsByMetadataKeyQuery = {
  __typename?: 'query_root'
  shop: Array<{ __typename?: 'shop'; id: any; key: string; metadata_key: any }>
}

export type FindAllShopsToRemoveAffiliateQueryVariables = Exact<{
  shopsStillAssociated: Array<Scalars['String']> | Scalars['String']
  metadataKey: Scalars['String']
}>

export type FindAllShopsToRemoveAffiliateQuery = {
  __typename?: 'query_root'
  shop: Array<{ __typename?: 'shop'; key: string }>
}

export type FindAllSubBrandsToRemoveAffiliateQueryVariables = Exact<{
  shopsStillAssociated: Array<Scalars['String']> | Scalars['String']
  metadata: Scalars['jsonb']
}>

export type FindAllSubBrandsToRemoveAffiliateQuery = {
  __typename?: 'query_root'
  shop: Array<{ __typename?: 'shop'; key: string }>
}

export type FindAllTopLevelCjShopsQueryVariables = Exact<{
  cjAffiliateIdKey: Scalars['String']
  cjParentShopKey: Scalars['String']
}>

export type FindAllTopLevelCjShopsQuery = {
  __typename?: 'query_root'
  shop: Array<{ __typename?: 'shop'; key: string; metadata: any }>
}

export type FindCartItemQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type FindCartItemQuery = {
  __typename?: 'query_root'
  cartItem?: Maybe<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type FindCartMobileQueryVariables = Exact<{
  id: Scalars['uuid']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type FindCartMobileQuery = {
  __typename?: 'query_root'
  cart?: Maybe<
    {
      __typename?: 'cart'
      cart_items: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
    } & CartDataFragment
  >
}

export type FindCollaboratorsQueryVariables = Exact<{
  query: Scalars['String']
}>

export type FindCollaboratorsQuery = {
  __typename?: 'query_root'
  users: Array<{ __typename?: 'user' } & BasicUserDataFragment>
}

export type FindCollectionQueryVariables = Exact<{
  collection_id: Scalars['uuid']
  itemLimit?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type FindCollectionQuery = {
  __typename?: 'query_root'
  collection?: Maybe<
    { __typename?: 'collection' } & CollectionAndItemsDataFragment
  >
}

export type FindCollectionPendingInviteQueryVariables = Exact<{
  inviteId?: Maybe<Scalars['uuid']>
  collectionId?: Maybe<Scalars['uuid']>
  userId?: Maybe<Scalars['String']>
}>

export type FindCollectionPendingInviteQuery = {
  __typename?: 'query_root'
  collection_invite: Array<{ __typename?: 'collection_invite'; id: any }>
}

export type FindContactUserAndInviteStatusQueryVariables = Exact<{
  [key: string]: never
}>

export type FindContactUserAndInviteStatusQuery = {
  __typename?: 'query_root'
  invites: Array<{ __typename?: 'contact_invite' } & ContactInviteDataFragment>
}

export type FindShopByKeyQueryVariables = Exact<{
  key: Scalars['String']
}>

export type FindShopByKeyQuery = {
  __typename?: 'query_root'
  shop: Array<{ __typename?: 'shop' } & ShopDataFragment>
}

export type FindShopBySlugQueryVariables = Exact<{
  slug: Scalars['name']
  include_categories?: Maybe<Scalars['Boolean']>
}>

export type FindShopBySlugQuery = {
  __typename?: 'query_root'
  shop: Array<
    {
      __typename?: 'shop'
      description?: Maybe<string>
      keywords?: Maybe<Array<string>>
      shop_faqs?: Maybe<any>
      banner_image?: Maybe<string>
      shop_categorizations?: Maybe<
        Array<{
          __typename?: 'shop_categorization'
          category: Shop_Category_Enum
          shop_category: {
            __typename?: 'shop_category'
            label?: Maybe<string>
            value: string
          }
        }>
      >
    } & ShopDataFragment
  >
}

export type FindShopPointsByKeyQueryVariables = Exact<{
  key: Scalars['String']
  includeAffiliate?: Scalars['Boolean']
}>

export type FindShopPointsByKeyQuery = {
  __typename?: 'query_root'
  shop: Array<{
    __typename?: 'shop'
    points?: Maybe<any>
    parsed_take_rate?: Maybe<any>
    affiliate?: Maybe<Affiliate_Enum>
  }>
}

export type FindUserQueryVariables = Exact<{
  id: Scalars['String']
  with_extension_version?: Maybe<Scalars['Boolean']>
  with_ios_version?: Maybe<Scalars['Boolean']>
  with_safari_extension_version?: Maybe<Scalars['Boolean']>
}>

export type FindUserQuery = {
  __typename?: 'query_root'
  user?: Maybe<{ __typename?: 'user' } & UserDataFragment>
}

export type FindUserByPhoneQueryVariables = Exact<{
  phone: Scalars['String']
  with_extension_version?: Maybe<Scalars['Boolean']>
  with_ios_version?: Maybe<Scalars['Boolean']>
  with_safari_extension_version?: Maybe<Scalars['Boolean']>
}>

export type FindUserByPhoneQuery = {
  __typename?: 'query_root'
  user: Array<{ __typename?: 'user' } & UserDataFragment>
}

export type FindUserCartbyShopKeyQueryVariables = Exact<{
  userId: Scalars['String']
  shopKey: Scalars['String']
}>

export type FindUserCartbyShopKeyQuery = {
  __typename?: 'query_root'
  carts: Array<
    { __typename?: 'cart' } & CartDataFragment & CartItemsDataFragment
  >
}

export type FindUserCollectionsQueryVariables = Exact<{
  user_id: Scalars['String']
  slug: Scalars['name']
  itemLimit?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type FindUserCollectionsQuery = {
  __typename?: 'query_root'
  collections: Array<
    { __typename?: 'collection' } & CollectionAndItemsDataFragment
  >
}

export type FindUserForAuthCheckQueryVariables = Exact<{
  id: Scalars['String']
}>

export type FindUserForAuthCheckQuery = {
  __typename?: 'query_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    anonymous?: Maybe<boolean>
    orphaned_at?: Maybe<any>
    orphaned_by?: Maybe<string>
  }>
}

export type FindUserForMobileQueryVariables = Exact<{
  id: Scalars['String']
}>

export type FindUserForMobileQuery = {
  __typename?: 'query_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    phone?: Maybe<string>
    username?: Maybe<string>
    user_type?: Maybe<User_Account_Type_Enum>
    is_creator_computed?: Maybe<boolean>
    is_curator_computed?: Maybe<boolean>
  }>
}

export type FindUserMobileQueryVariables = Exact<{
  id: Scalars['String']
}>

export type FindUserMobileQuery = {
  __typename?: 'query_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    name?: Maybe<string>
    picture?: Maybe<string>
  }>
}

export type FinishOnboardingMutationVariables = Exact<{
  id: Scalars['String']
}>

export type FinishOnboardingMutation = {
  __typename?: 'mutation_root'
  user?: Maybe<{ __typename?: 'user'; id: string; onboarded?: Maybe<boolean> }>
}

export type FollowCollectionMutationVariables = Exact<{
  collectionId: Scalars['uuid']
}>

export type FollowCollectionMutation = {
  __typename?: 'mutation_root'
  insert_user_items_followed_one?: Maybe<{
    __typename?: 'user_items_followed'
    followable_item?: Maybe<{
      __typename?: 'followable_item'
      collection?: Maybe<{
        __typename?: 'collection'
        id: any
        followed_by_user?: Maybe<boolean>
        is_a_collaborator?: Maybe<boolean>
      }>
    }>
  }>
}

export type UnfollowCollectionMutationVariables = Exact<{
  userId: Scalars['String']
  collectionId: Scalars['uuid']
}>

export type UnfollowCollectionMutation = {
  __typename?: 'mutation_root'
  delete_user_items_followed?: Maybe<{
    __typename?: 'user_items_followed_mutation_response'
    affected_rows: number
  }>
}

export type BasicUserDataFragment = {
  __typename?: 'user'
  id: string
  name?: Maybe<string>
  display_name?: Maybe<string>
  username?: Maybe<string>
  picture?: Maybe<string>
  verified?: Maybe<boolean>
  user_type?: Maybe<User_Account_Type_Enum>
  shopping_preference?: Maybe<User_Shopping_Preferences_Enum>
  internal_account?: Maybe<boolean>
  is_creator_computed?: Maybe<boolean>
  is_curator_computed?: Maybe<boolean>
  metadata?: Maybe<any>
  created_at?: Maybe<any>
}

export type UserDataFragment = {
  __typename?: 'user'
  bio?: Maybe<string>
  bio_link?: Maybe<string>
  short_id: any
  has_claimed_username_computed?: Maybe<boolean>
  has_set_name_computed?: Maybe<boolean>
  has_picture_set?: Maybe<boolean>
  has_email_set?: Maybe<boolean>
  anonymous?: Maybe<boolean>
  role?: Maybe<any>
  onboarded?: Maybe<boolean>
  has_onboarded?: Maybe<boolean>
  user_points_multiplier?: Maybe<any>
  created_at?: Maybe<any>
  updated_at?: Maybe<any>
  installed_at?: Maybe<any>
  active_cart_items_quantity?: Maybe<any>
  orphaned_at?: Maybe<any>
  orphaned_by?: Maybe<string>
  has_premium_subscription?: Maybe<boolean>
  extension_version?: Maybe<string>
  ios_version?: Maybe<string>
  safari_extension_version?: Maybe<string>
  collections_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  flags?: Maybe<{ __typename?: 'user_flags' } & UserFlagsFragment>
  referrer_user?: Maybe<{ __typename?: 'user' } & BasicUserDataFragment>
} & BasicUserDataFragment

export type UserForPushNotificationDataFragment = {
  __typename?: 'user'
  ios_version?: Maybe<string>
  push_notification_tokens: Array<{
    __typename?: 'user_push_notification_token'
    id: any
  }>
} & BasicUserDataFragment

export type UserFlagsFragment = {
  __typename?: 'user_flags'
  user_id: string
  opted_into_cart_onboarding: boolean
  last_cart_onboarding?: Maybe<any>
  checked_history: boolean
  onboarding_state?: Maybe<User_Onboarding_State_Enum>
  features?: Maybe<any>
  discovery_feed_sequential_id?: Maybe<number>
  discovery_feed_sequential_id_last_set_at?: Maybe<any>
  notification_center_last_viewed_sequential_id: number
  can_be_impersonated: boolean
  auto_cashback_enabled?: Maybe<boolean>
  installed_safari_extension?: Maybe<boolean>
  granted_full_host_permissions_current?: Maybe<boolean>
  last_safari_extension_permission_check?: Maybe<any>
}

export type CartDataFragment = {
  __typename?: 'cart'
  id: any
  user_id: string
  shop_key?: Maybe<string>
  created_at: any
  updated_at: any
  cleared_at?: Maybe<any>
  last_cart_item_created_at?: Maybe<any>
  user: { __typename?: 'user'; short_id: any } & BasicUserDataFragment
  shop?: Maybe<{ __typename?: 'shop' } & ShopDataFragment>
}

export type CartItemsDataFragment = {
  __typename?: 'cart'
  cart_items: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type CartItemsDataWithLimitFragment = {
  __typename?: 'cart'
  cart_items: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type CartItemDataFragment = {
  __typename?: 'cart_item'
  id: any
  cart_id: any
  item_id: any
  shop_key: string
  quantity: any
  bookmark: boolean
  in_collection?: Maybe<boolean>
  archived_at?: Maybe<any>
  removed_at?: Maybe<any>
  removed_reason?: Maybe<Remove_Reason_Enum>
  deleted_at?: Maybe<any>
  created_at: any
  updated_at: any
  source?: Maybe<string>
  metadata: any
  categories_override?: Maybe<Array<string>>
  affiliate_redirect_link_override?: Maybe<string>
  item_image_override?: Maybe<string>
  item_title_override?: Maybe<string>
  item_pricing_override?: Maybe<any>
  item_description_override?: Maybe<string>
  item_video_url?: Maybe<string>
  item_video_orientation?: Maybe<string>
  display_title?: Maybe<string>
  description?: Maybe<string>
  pricing?: Maybe<any>
  points?: Maybe<any>
  hidden: boolean
  image?: Maybe<string>
  image_metadata?: Maybe<any>
  creator_rating?: Maybe<any>
  image_color_palette?: Maybe<Array<any>>
  like_count?: Maybe<number>
  commentable_item_join?: Maybe<{
    __typename?: 'commentable_item'
    comments: Array<{
      __typename?: 'comment'
      id: any
      comment: string
      user: {
        __typename?: 'user'
        username?: Maybe<string>
        picture?: Maybe<string>
      }
    }>
    comments_aggregate: {
      __typename?: 'comment_aggregate'
      aggregate?: Maybe<{
        __typename?: 'comment_aggregate_fields'
        count: number
      }>
    }
  }>
  note?: Maybe<{
    __typename?: 'cart_item_note'
    id: any
    note?: Maybe<string>
    private: boolean
  }>
  cart: {
    __typename?: 'cart'
    id: any
    user: { __typename?: 'user' } & BasicUserDataFragment
  }
  shop: { __typename?: 'shop' } & ShopDataFragment
  item: { __typename?: 'shop_item' } & ItemDataFragment
  collection_item_claim?: Maybe<
    { __typename?: 'collection_item_claim' } & CollectionItemClaimDataFragment
  >
  collection_cart_items: Array<{
    __typename?: 'collection_cart_item'
    collection_id: any
  }>
  user?: Maybe<
    Array<{
      __typename?: 'user'
      id: string
      picture?: Maybe<string>
      username?: Maybe<string>
    }>
  >
}

export type PreloadedCollectionCartItemDataFragment = {
  __typename?: 'collection_cart_item'
  id: any
  collection_id: any
  cart_item: {
    __typename?: 'cart_item'
    id: any
    item_image_override?: Maybe<string>
    display_title?: Maybe<string>
    item: {
      __typename?: 'shop_item'
      shop: { __typename?: 'shop' } & ShopDataFragment
    } & ItemDataFragment
    cart: {
      __typename?: 'cart'
      user: { __typename?: 'user'; id: string; username?: Maybe<string> }
    }
  }
}

export type CollectionItemClaimDataFragment = {
  __typename?: 'collection_item_claim'
  id: any
  status: Collection_Item_Claim_Status_Enum
  user: { __typename?: 'user' } & BasicUserDataFragment
}

export type CartItemLikeDataFragment = {
  __typename?: 'cart_item_like'
  id: any
  user_id: string
  cart_item_id: any
  value: number
}

export type CollectionDataFragment = {
  __typename?: 'collection'
  id: any
  user_id?: Maybe<string>
  short_id?: Maybe<any>
  name: any
  description?: Maybe<string>
  slug: any
  state?: Maybe<Collection_State_Enum>
  paid: boolean
  exclusive?: Maybe<boolean>
  computed_is_visible?: Maybe<boolean>
  has_valid_preview_images_computed?: Maybe<boolean>
  private: boolean
  giftlist: boolean
  all: boolean
  collection_type: Collection_Type_Enum
  curation: boolean
  metadata?: Maybe<any>
  bookmarks: boolean
  ogimage_fingerprint_updated?: Maybe<string>
  collection_link?: Maybe<string>
  open_collab_link_computed?: Maybe<string>
  cover_image?: Maybe<string>
  cover_image_cached?: Maybe<string>
  collection_cover_images?: Maybe<Array<string>>
  cart_items_quantity?: Maybe<any>
  cart_items_count?: Maybe<any>
  created_at?: Maybe<any>
  updated_at?: Maybe<any>
  cart_item_max_updated_at?: Maybe<any>
  budget?: Maybe<any>
  has_collaborators: boolean
  path?: Maybe<string>
  is_a_collaborator?: Maybe<boolean>
  seo_fields?: Maybe<any>
  user?: Maybe<
    {
      __typename?: 'user'
      short_id: any
      anonymous?: Maybe<boolean>
    } & BasicUserDataFragment
  >
}

export type CollectionItemsDataFragment = {
  __typename?: 'collection'
  collection_items: Array<{
    __typename?: 'collection_cart_item'
    id: any
    created_at: any
    updated_at: any
    cart_item: {
      __typename?: 'cart_item'
      creator_rating?: Maybe<any>
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      commentable_item_join?: Maybe<{
        __typename?: 'commentable_item'
        comments: Array<{
          __typename?: 'comment'
          id: any
          comment: string
          user: {
            __typename?: 'user'
            username?: Maybe<string>
            picture?: Maybe<string>
          }
        }>
        comments_aggregate: {
          __typename?: 'comment_aggregate'
          aggregate?: Maybe<{
            __typename?: 'comment_aggregate_fields'
            count: number
          }>
        }
      }>
    } & CartItemDataFragment
  }>
}

export type CollectionCollaboratorsDataFragment = {
  __typename?: 'collection'
  collection_collaborators: Array<{
    __typename?: 'collection_collaborator'
    id: any
    manager: boolean
    user: { __typename?: 'user' } & BasicUserDataFragment
  }>
}

export type CollectionAndItemsDataFragment = {
  __typename?: 'collection'
} & CollectionDataFragment &
  CollectionItemsDataFragment &
  CollectionCollaboratorsDataFragment

export type ShopDataFragment = {
  __typename?: 'shop'
  id: any
  key: string
  cart_path?: Maybe<string>
  type?: Maybe<any>
  platform?: Maybe<string>
  name: any
  display_name?: Maybe<string>
  cover_image?: Maybe<string>
  banner_image?: Maybe<string>
  slug: any
  favicon?: Maybe<string>
  created_at?: Maybe<any>
  updated_at?: Maybe<any>
  has_pdp?: Maybe<boolean>
  editorial_shop_logo?: Maybe<string>
  parsed_take_rate?: Maybe<any>
  metadata: any
}

export type AdminCategorizationShopDataFragment = {
  __typename?: 'shop'
  id: any
  key: string
  slug: any
  name: any
  name_override?: Maybe<string>
  display_name?: Maybe<string>
  description?: Maybe<string>
  keywords?: Maybe<Array<string>>
  favicon?: Maybe<string>
  cover_image?: Maybe<string>
  banner_image?: Maybe<string>
  editorial_shop_logo?: Maybe<string>
  shop_faqs?: Maybe<any>
  featured: boolean
  points?: Maybe<any>
  metadata: any
  shop_categorizations: Array<{
    __typename?: 'shop_categorization'
    id: any
    shop_key: string
    weight: number
    curation_score: number
    category: Shop_Category_Enum
    shop_category: { __typename?: 'shop_category'; label?: Maybe<string> }
  }>
}

export type ItemDataFragment = {
  __typename?: 'shop_item'
  id: any
  item_id: string
  shop_key: string
  source?: Maybe<string>
  display_title?: Maybe<string>
  description?: Maybe<string>
  url?: Maybe<string>
  image?: Maybe<string>
  image_highres?: Maybe<string>
  cached_image?: Maybe<string>
  cached_placeholder_image?: Maybe<string>
  image_metadata?: Maybe<any>
  image_color_palette?: Maybe<Array<any>>
  pricing?: Maybe<any>
  parsed_pricing?: Maybe<any>
  points?: Maybe<any>
  metadata?: Maybe<any>
  enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum>
  is_inappropriate: boolean
  saved_by_current_user?: Maybe<boolean>
  onboarding_categories?: Maybe<Array<string>>
  categories?: Maybe<Array<string>>
  keywords?: Maybe<Array<string>>
  created_at: any
  updated_at: any
}

export type AffiliateFragment = {
  __typename?: 'affiliate'
  value: string
  meta?: Maybe<{ __typename?: 'affiliate_meta'; data: any }>
}

export type ShopAffiliateFragment = {
  __typename?: 'shop_affiliate'
  affiliate: {
    __typename?: 'affiliate'
    value: string
    meta?: Maybe<{ __typename?: 'affiliate_meta'; data: any }>
  }
}

export type FeedItemDataFragment = {
  __typename?: 'feed_item'
  id: any
  item_id: any
  item_type: Feed_Item_Type_Enum
  in_discovery_backlog?: Maybe<boolean>
  in_discovery_staging?: Maybe<boolean>
  in_discovery_feed?: Maybe<boolean>
  metadata: any
  liked_by_user?: Maybe<boolean>
  like_count?: Maybe<number>
}

export type DiscoveryCollectionCartItemFragment = {
  __typename?: 'collection_cart_item'
  id: any
  cart_item: {
    __typename?: 'cart_item'
    id: any
    created_at: any
    updated_at: any
    archived_at?: Maybe<any>
    deleted_at?: Maybe<any>
    item_image_override?: Maybe<string>
    display_title?: Maybe<string>
    description?: Maybe<string>
    pricing?: Maybe<any>
    creator_rating?: Maybe<any>
    item: { __typename?: 'shop_item' } & ItemDataFragment
    shop: {
      __typename?: 'shop'
      id: any
      key: string
      slug: any
      name: any
      display_name?: Maybe<string>
      favicon?: Maybe<string>
    }
    cart: {
      __typename?: 'cart'
      user: { __typename?: 'user' } & BasicUserDataFragment
    }
    feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
    note?: Maybe<{
      __typename?: 'cart_item_note'
      id: any
      note?: Maybe<string>
      private: boolean
    }>
    commentable_item_join?: Maybe<{
      __typename?: 'commentable_item'
      comments: Array<{
        __typename?: 'comment'
        id: any
        comment: string
        user: {
          __typename?: 'user'
          username?: Maybe<string>
          picture?: Maybe<string>
        }
      }>
      comments_aggregate: {
        __typename?: 'comment_aggregate'
        aggregate?: Maybe<{
          __typename?: 'comment_aggregate_fields'
          count: number
        }>
      }
    }>
  }
}

export type DiscoveryCollectionFragment = {
  __typename?: 'collection'
  id: any
  name: any
  slug: any
  user_id?: Maybe<string>
  description?: Maybe<string>
  cover_image?: Maybe<string>
  cover_image_cached?: Maybe<string>
  collection_cover_images?: Maybe<Array<string>>
  cart_items_count?: Maybe<any>
  has_collaborators: boolean
  followed_by_user?: Maybe<boolean>
  is_a_collaborator?: Maybe<boolean>
  metadata?: Maybe<any>
  updated_at?: Maybe<any>
  created_at?: Maybe<any>
  total_likes_count?: Maybe<any>
  feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
  user?: Maybe<{ __typename?: 'user' } & BasicUserDataFragment>
  collection_items?: Maybe<
    Array<
      {
        __typename?: 'collection_cart_item'
      } & DiscoveryCollectionCartItemFragment
    >
  >
  commentable_item_join?: Maybe<{
    __typename?: 'commentable_item'
    comments: Array<{
      __typename?: 'comment'
      id: any
      comment: string
      user: {
        __typename?: 'user'
        username?: Maybe<string>
        picture?: Maybe<string>
      }
    }>
    comments_aggregate: {
      __typename?: 'comment_aggregate'
      aggregate?: Maybe<{
        __typename?: 'comment_aggregate_fields'
        count: number
      }>
    }
  }>
}

export type FeaturedShopAndFeaturedShopItemsFragment = {
  __typename?: 'featured_shop'
  id: any
  feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
  shop: {
    __typename?: 'shop'
    id: any
    key: string
    name: any
    display_name?: Maybe<string>
    favicon?: Maybe<string>
  } & FirstCartItemFragment
  featured_shop_items: Array<{
    __typename?: 'featured_shop_item'
    id: any
    shop_item?: Maybe<{ __typename?: 'shop_item' } & ItemDataFragment>
  }>
}

export type FirstCartItemFragment = {
  __typename?: 'shop'
  cart_items: Array<{
    __typename?: 'cart_item'
    id: any
    created_at: any
    cart: {
      __typename?: 'cart'
      id: any
      user: { __typename?: 'user' } & BasicUserDataFragment
    }
  }>
}

export type DiscoveryFeedCollectionCartItemCollectionFragment = {
  __typename?: 'collection_cart_item'
  collection: {
    __typename?: 'collection'
    id: any
    name: any
    slug: any
    has_collaborators: boolean
    user?: Maybe<{ __typename?: 'user' } & BasicUserDataFragment>
  }
}

export type DiscoveryFeedFeedItemFragment = {
  __typename?: 'feed_item'
  collection?: Maybe<
    { __typename?: 'collection' } & DiscoveryCollectionFragment
  >
  featured_shop?: Maybe<
    { __typename?: 'featured_shop' } & FeaturedShopAndFeaturedShopItemsFragment
  >
  collection_cart_item?: Maybe<
    {
      __typename?: 'collection_cart_item'
      collection: { __typename?: 'collection' } & DiscoveryCollectionFragment
    } & DiscoveryCollectionCartItemFragment
  >
} & FeedItemDataFragment

export type CollectionInviteFragment = {
  __typename?: 'collection_invite'
  id: any
  invitee_id?: Maybe<string>
  collection_id: any
  status: Collection_Invite_Status_Enum
  invitee?: Maybe<{
    __typename?: 'user'
    id: string
    name?: Maybe<string>
    picture?: Maybe<string>
  }>
  collection_collaborator?: Maybe<{
    __typename?: 'collection_collaborator'
    id: any
    manager: boolean
  }>
}

export type ContactInviteDataFragment = {
  __typename?: 'contact_invite'
  id: any
  contact_info: string
  type: Contact_Invite_Type_Enum
}

export type CollectionCarouselDataFragment = {
  __typename?: 'collection'
  id: any
  slug: any
  name: any
  cart_items_count?: Maybe<any>
  user?: Maybe<{
    __typename?: 'user'
    id: string
    username?: Maybe<string>
    picture?: Maybe<string>
    created_at?: Maybe<any>
  }>
  collection_items?: Maybe<
    Array<{
      __typename?: 'collection_cart_item'
      id: any
      cart_item: {
        __typename?: 'cart_item'
        id: any
        item_image_override?: Maybe<string>
        display_title?: Maybe<string>
        description?: Maybe<string>
        pricing?: Maybe<any>
        item: {
          __typename?: 'shop_item'
          id: any
          image?: Maybe<string>
          image_highres?: Maybe<string>
          cached_image?: Maybe<string>
        }
      }
    }>
  >
}

export type MediaDataFragment = {
  __typename?: 'media_item'
  id: any
  media_url: string
  media_type: Media_Type_Enum
}

export type MediaEnabledItemDataFragment = {
  __typename?: 'media_enabled_item'
  associated_media: Array<{
    __typename?: 'media_items'
    media_id: any
    media: { __typename?: 'media_item' } & MediaDataFragment
  }>
}

export type UserCollectionsDataForProfilePageDataFragment = {
  __typename?: 'collection'
  id: any
  order?: Maybe<any>
  slug: any
  state?: Maybe<Collection_State_Enum>
  paid: boolean
  exclusive?: Maybe<boolean>
  private: boolean
  name: any
  description?: Maybe<string>
  curation: boolean
  collection_type: Collection_Type_Enum
  cover_image?: Maybe<string>
  cover_image_cached?: Maybe<string>
  collection_cover_images?: Maybe<Array<string>>
  collection_link?: Maybe<string>
  created_at?: Maybe<any>
  updated_at?: Maybe<any>
  cart_item_max_updated_at?: Maybe<any>
  total_likes_count?: Maybe<any>
  has_collaborators: boolean
  cart_items_count?: Maybe<any>
  user?: Maybe<{
    __typename?: 'user'
    id: string
    username?: Maybe<string>
    picture?: Maybe<string>
  }>
  collection_collaborators_aggregate: {
    __typename?: 'collection_collaborator_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_collaborator_aggregate_fields'
      count: number
    }>
  }
  collection_collaborators: Array<{
    __typename?: 'collection_collaborator'
    id: any
    user: { __typename?: 'user'; picture?: Maybe<string> }
  }>
  collection_items?: Maybe<
    Array<{
      __typename?: 'collection_cart_item'
      id: any
      cart_item: {
        __typename?: 'cart_item'
        id: any
        item_image_override?: Maybe<string>
        item: {
          __typename?: 'shop_item'
          id: any
          image?: Maybe<string>
          image_highres?: Maybe<string>
          cached_image?: Maybe<string>
          image_metadata?: Maybe<any>
          metadata?: Maybe<any>
          is_inappropriate: boolean
        }
      }
    }>
  >
}

export type DealShopDataFragment = {
  __typename?: 'shop'
  id: any
  key: string
  slug: any
  display_name?: Maybe<string>
  favicon?: Maybe<string>
  featured: boolean
  banner_image?: Maybe<string>
  points?: Maybe<any>
}

export type FmtcDataFragment = {
  __typename?: 'fmtc_deal'
  id: any
  title: string
  direct_url: string
  rating?: Maybe<any>
  types?: Maybe<Array<string>>
  code?: Maybe<string>
  start_date: any
  end_date?: Maybe<any>
  shop_key: string
  shop: { __typename?: 'shop' } & DealShopDataFragment
}

export type ShopPopularityWithPointsDataFragment = {
  __typename?: 'shop_popularity_with_points'
  shop?: Maybe<{ __typename?: 'shop' } & DealShopDataFragment>
}

export type BrandDealsCollectionItemDataFragment = {
  __typename?: 'collection_cart_item'
  id: any
  cart_item: {
    __typename?: 'cart_item'
    id: any
    shop_key: string
    display_title?: Maybe<string>
    image?: Maybe<string>
    pricing?: Maybe<any>
    metadata: any
    deal_ends_at?: Maybe<any>
    shop: { __typename?: 'shop' } & DealShopDataFragment
  }
}

export type DealHopGateDataFragment = {
  __typename?: 'deal_hop_gate'
  user_id: string
  deal_hop_gated?: Maybe<any>
  deal_hop_unlocked: boolean
  deal_hop_invited?: Maybe<any>
  deal_hop_gate_pending?: Maybe<any>
  paywall_gated_at?: Maybe<any>
}

export type GetAccountDeletionReasonsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAccountDeletionReasonsQuery = {
  __typename?: 'query_root'
  reasons: Array<{
    __typename?: 'account_deletion_reason'
    value: string
    description: string
  }>
}

export type GetAllUserCollectionsQueryVariables = Exact<{
  userId: Scalars['String']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  itemLimit?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type GetAllUserCollectionsQuery = {
  __typename?: 'query_root'
  collection_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  collections: Array<
    { __typename?: 'collection' } & CollectionAndItemsDataFragment
  >
}

export type GetAllYourStuffCollectionQueryVariables = Exact<{
  userId: Scalars['String']
  itemLimit?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type GetAllYourStuffCollectionQuery = {
  __typename?: 'query_root'
  collection: Array<
    { __typename?: 'collection' } & CollectionAndItemsDataFragment
  >
}

export type GetCarrotCuratedCollectionsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetCarrotCuratedCollectionsQuery = {
  __typename?: 'query_root'
  collections: Array<{
    __typename?: 'collection'
    id: any
    name: any
    slug: any
    description?: Maybe<string>
    cover_image?: Maybe<string>
    collection_items_aggregate: {
      __typename?: 'collection_cart_item_aggregate'
      aggregate?: Maybe<{
        __typename?: 'collection_cart_item_aggregate_fields'
        count: number
      }>
    }
    collection_collaborators: Array<{
      __typename?: 'collection_collaborator'
      id: any
      user: { __typename?: 'user'; picture?: Maybe<string> }
    }>
  }>
}

export type GetCartItemImagesQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid']
}>

export type GetCartItemImagesQuery = {
  __typename?: 'query_root'
  cart_items: Array<{
    __typename?: 'cart_item'
    id: any
    item: {
      __typename?: 'shop_item'
      id: any
      image?: Maybe<string>
      image_highres?: Maybe<string>
      cached_image?: Maybe<string>
    }
  }>
}

export type GetCartItemsFromIdsQueryVariables = Exact<{
  itemIds: Array<Scalars['uuid']> | Scalars['uuid']
}>

export type GetCartItemsFromIdsQuery = {
  __typename?: 'query_root'
  cart_item: Array<{
    __typename?: 'cart_item'
    id: any
    item: {
      __typename?: 'shop_item'
      id: any
      display_title?: Maybe<string>
      cached_image?: Maybe<string>
      image?: Maybe<string>
      image_highres?: Maybe<string>
    }
  }>
}

export type GetCartsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
}>

export type GetCartsQuery = {
  __typename?: 'query_root'
  carts: Array<{
    __typename?: 'cart'
    id: any
    shop?: Maybe<{ __typename?: 'shop'; id: any; slug: any }>
  }>
}

export type GetCollabInviteAcceptedDataForPushNotificationQueryVariables =
  Exact<{
    id: Scalars['uuid']
  }>

export type GetCollabInviteAcceptedDataForPushNotificationQuery = {
  __typename?: 'query_root'
  collection_invite_by_pk?: Maybe<{
    __typename?: 'collection_invite'
    id: any
    collection: {
      __typename?: 'collection'
      id: any
      name: any
      user?: Maybe<
        { __typename?: 'user' } & UserForPushNotificationDataFragment
      >
    }
    invitee?: Maybe<{ __typename?: 'user'; username?: Maybe<string> }>
  }>
}

export type GetCollabInviteDataForPushNotificationQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetCollabInviteDataForPushNotificationQuery = {
  __typename?: 'query_root'
  collection_invite_by_pk?: Maybe<{
    __typename?: 'collection_invite'
    id: any
    invitee_id?: Maybe<string>
    requester_id: string
    collection: {
      __typename?: 'collection'
      id: any
      name: any
      user?: Maybe<{ __typename?: 'user'; username?: Maybe<string> }>
    }
    invitee?: Maybe<
      { __typename?: 'user' } & UserForPushNotificationDataFragment
    >
  }>
}

export type GetCollectionCollaboratorInvitesQueryVariables = Exact<{
  collectionId: Scalars['uuid']
  is_admin?: Maybe<Scalars['Boolean']>
}>

export type GetCollectionCollaboratorInvitesQuery = {
  __typename?: 'query_root'
  invites: Array<{
    __typename?: 'collection_invite'
    id: any
    status: Collection_Invite_Status_Enum
    invitee_email?: Maybe<string>
    invitee?: Maybe<{ __typename?: 'user' } & BasicUserDataFragment>
    collection_collaborator?: Maybe<{
      __typename?: 'collection_collaborator'
      id: any
      manager: boolean
    }>
  }>
}

export type GetCollectionForSocialImageQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetCollectionForSocialImageQuery = {
  __typename?: 'query_root'
  collection?: Maybe<{
    __typename?: 'collection'
    all: boolean
    slug: any
    user_id?: Maybe<string>
    ogimage_fingerprint_updated?: Maybe<string>
    user?: Maybe<{
      __typename?: 'user'
      short_id: any
      username?: Maybe<string>
    }>
  }>
}

export type GetCollectionInviteQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetCollectionInviteQuery = {
  __typename?: 'query_root'
  collection_invite_by_pk?: Maybe<{
    __typename?: 'collection_invite'
    id: any
    invitee_id?: Maybe<string>
    status: Collection_Invite_Status_Enum
    for_new_user?: Maybe<boolean>
    collection: {
      __typename?: 'collection'
      id: any
      path?: Maybe<string>
      user?: Maybe<{ __typename?: 'user'; id: string }>
    }
  }>
}

export type GetCollectionsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
}>

export type GetCollectionsQuery = {
  __typename?: 'query_root'
  collections: Array<{
    __typename?: 'collection'
    id: any
    name: any
    slug: any
    user?: Maybe<{ __typename?: 'user'; id: string; name?: Maybe<string> }>
  }>
}

export type GetCollectionsForFirehoseQueryVariables = Exact<{
  email_exclude_list: Array<Scalars['String']> | Scalars['String']
  created_at_offset?: Maybe<Scalars['timestamptz']>
  limit?: Maybe<Scalars['Int']>
  collectionItemsCount?: Maybe<Scalars['Int']>
}>

export type GetCollectionsForFirehoseQuery = {
  __typename?: 'query_root'
  collections: Array<{
    __typename?: 'collection'
    id: any
    slug: any
    name: any
    cart_items_count?: Maybe<any>
    created_at?: Maybe<any>
    collection_items?: Maybe<
      Array<{
        __typename?: 'collection_cart_item'
        id: any
        cart_item: { __typename?: 'cart_item'; image?: Maybe<string> }
      }>
    >
    feed_item?: Maybe<{
      __typename?: 'feed_item'
      id: any
      in_discovery_backlog?: Maybe<boolean>
      in_discovery_feed?: Maybe<boolean>
      metadata: any
    }>
    user?: Maybe<{
      __typename?: 'user'
      username?: Maybe<string>
      picture?: Maybe<string>
    }>
  }>
}

export type GetCollectionsFromIdsQueryVariables = Exact<{
  collectionIds: Array<Scalars['uuid']> | Scalars['uuid']
}>

export type GetCollectionsFromIdsQuery = {
  __typename?: 'query_root'
  collection: Array<{
    __typename?: 'collection'
    id: any
    paid: boolean
    private: boolean
    name: any
    slug: any
  }>
}

export type GetContactInviteDataForSmsQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetContactInviteDataForSmsQuery = {
  __typename?: 'query_root'
  contact_invite_by_pk?: Maybe<{
    __typename?: 'contact_invite'
    id: any
    type: Contact_Invite_Type_Enum
    contact_info: string
    requester: {
      __typename?: 'user'
      name?: Maybe<string>
      username?: Maybe<string>
      picture?: Maybe<string>
      display_name?: Maybe<string>
    }
  }>
}

export type GetDataForCategoriesSitemapQueryVariables = Exact<{
  [key: string]: never
}>

export type GetDataForCategoriesSitemapQuery = {
  __typename?: 'query_root'
  shop_categories: Array<{
    __typename?: 'shop_category'
    label?: Maybe<string>
    value: string
  }>
}

export type GetDataForLandingPagesSitemapQueryVariables = Exact<{
  [key: string]: never
}>

export type GetDataForLandingPagesSitemapQuery = {
  __typename?: 'query_root'
  pages: Array<{
    __typename?: 'collection'
    id: any
    slug: any
    updated_at?: Maybe<any>
    user?: Maybe<{ __typename?: 'user'; username?: Maybe<string> }>
  }>
}

export type GetDataForShopsSitemapQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
}>

export type GetDataForShopsSitemapQuery = {
  __typename?: 'query_root'
  shops: Array<{
    __typename?: 'shop'
    key: string
    slug: any
    featured: boolean
  }>
}

export type GetDataForWeeklySummaryEmailQueryVariables = Exact<{
  startDate: Scalars['timestamptz']
  endDate: Scalars['timestamptz']
}>

export type GetDataForWeeklySummaryEmailQuery = {
  __typename?: 'query_root'
  users: Array<{
    __typename?: 'user'
    id: string
    name?: Maybe<string>
    email?: Maybe<string>
    username?: Maybe<string>
    collections: Array<{
      __typename?: 'collection'
      name: any
      slug: any
      cover_image?: Maybe<string>
      collection_items: Array<{
        __typename?: 'collection_cart_item'
        cart_item: {
          __typename?: 'cart_item'
          id: any
          item_image_override?: Maybe<string>
          item: { __typename?: 'shop_item'; cached_image?: Maybe<string> }
        }
      }>
    }>
    carts: Array<{
      __typename?: 'cart'
      shop?: Maybe<{
        __typename?: 'shop'
        key: string
        name: any
        display_name?: Maybe<string>
        favicon?: Maybe<string>
      }>
      cart_items: Array<{
        __typename?: 'cart_item'
        id: any
        created_at: any
        item: {
          __typename?: 'shop_item'
          title?: Maybe<string>
          display_title?: Maybe<string>
          cached_image?: Maybe<string>
        }
      }>
    }>
  }>
}

export type GetDependentWorkflowsQueryVariables = Exact<{
  parent_workflow_id: Scalars['uuid']
}>

export type GetDependentWorkflowsQuery = {
  __typename?: 'query_root'
  workflow: Array<{
    __typename?: 'workflow'
    data: any
    parent_workflow_id?: Maybe<any>
    id: any
    status: Workflow_Status_Enum
    user_id?: Maybe<string>
    type: Worflow_Type_Enum
    success_item_type?: Maybe<string>
    success_item_id?: Maybe<any>
    status_details?: Maybe<string>
  }>
}

export type GetDiscoveryBacklogItemQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetDiscoveryBacklogItemQuery = {
  __typename?: 'query_root'
  discovery_backlog_by_pk?: Maybe<{
    __typename?: 'discovery_backlog'
    id: any
    sequential_id: number
    feed_item: {
      __typename?: 'feed_item'
      id: any
      item_id: any
      item_type: Feed_Item_Type_Enum
    }
  }>
}

export type GetDiscoveryBacklogPreviewQueryVariables = Exact<{
  collectionItemsCount?: Maybe<Scalars['Int']>
}>

export type GetDiscoveryBacklogPreviewQuery = {
  __typename?: 'query_root'
  discovery_backlog: Array<{
    __typename?: 'discovery_backlog'
    id: any
    sequential_id: number
    feed_item: { __typename?: 'feed_item' } & DiscoveryFeedFeedItemFragment
  }>
}

export type GetDiscoveryFeedAdminPreviewQueryVariables = Exact<{
  collectionItemsCount?: Maybe<Scalars['Int']>
}>

export type GetDiscoveryFeedAdminPreviewQuery = {
  __typename?: 'query_root'
  discovery_feed: Array<{
    __typename?: 'discovery_feed'
    id: any
    sequential_id: number
    feed_item: { __typename?: 'feed_item' } & DiscoveryFeedFeedItemFragment
  }>
}

export type GetDiscoveryStagingFeedQueryVariables = Exact<{
  [key: string]: never
}>

export type GetDiscoveryStagingFeedQuery = {
  __typename?: 'query_root'
  discovery_staging: Array<{
    __typename?: 'discovery_staging'
    id: any
    sequential_id: number
    feed_item: {
      __typename?: 'feed_item'
      id: any
      item_id: any
      item_type: Feed_Item_Type_Enum
    }
  }>
}

export type GetFeaturedShopsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
}>

export type GetFeaturedShopsQuery = {
  __typename?: 'query_root'
  shop_popularity: Array<{
    __typename?: 'shop_popularity'
    key?: Maybe<string>
    popularity_rank?: Maybe<any>
    shop?: Maybe<{
      __typename?: 'shop'
      id: any
      key: string
      slug: any
      display_name?: Maybe<string>
      cover_image?: Maybe<string>
      favicon?: Maybe<string>
      parsed_take_rate?: Maybe<any>
    }>
  }>
}

export type GetFeedItemLikeDataForPushNotificationQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetFeedItemLikeDataForPushNotificationQuery = {
  __typename?: 'query_root'
  feed_item_like: Array<{
    __typename?: 'feed_item_like'
    id: any
    user: { __typename?: 'user'; username?: Maybe<string> }
    feed_item: {
      __typename?: 'feed_item'
      item_type: Feed_Item_Type_Enum
      notifiable_user?: Maybe<{
        __typename?: 'notifiable_feed_item_user'
        user?: Maybe<
          { __typename?: 'user' } & UserForPushNotificationDataFragment
        >
      }>
      featured_shop?: Maybe<{
        __typename?: 'featured_shop'
        shop: {
          __typename?: 'shop'
          id: any
          name: any
          display_name?: Maybe<string>
        }
      }>
      collection?: Maybe<{
        __typename?: 'collection'
        id: any
        name: any
        user_id?: Maybe<string>
      }>
      cart_item?: Maybe<{
        __typename?: 'cart_item'
        id: any
        item_image_override?: Maybe<string>
        item: { __typename?: 'shop_item'; display_title?: Maybe<string> }
      }>
    }
  }>
}

export type GetItemsFromIdsQueryVariables = Exact<{
  itemIds: Array<Scalars['uuid']> | Scalars['uuid']
}>

export type GetItemsFromIdsQuery = {
  __typename?: 'query_root'
  shop_item: Array<{
    __typename?: 'shop_item'
    id: any
    display_title?: Maybe<string>
    cached_image?: Maybe<string>
    image?: Maybe<string>
    image_highres?: Maybe<string>
  }>
}

export type GetLikedCartItemsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  seed?: Maybe<Scalars['seed_float']>
}>

export type GetLikedCartItemsQuery = {
  __typename?: 'query_root'
  feed_item_likes: Array<{
    __typename?: 'feed_item_like'
    feed_item: {
      __typename?: 'feed_item'
      cart_item?: Maybe<{ __typename?: 'cart_item' } & CartItemDataFragment>
    }
  }>
}

export type GetMostRecentActiveCartItemsForUserQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>
}>

export type GetMostRecentActiveCartItemsForUserQuery = {
  __typename?: 'query_root'
  cart_item: Array<{
    __typename?: 'cart_item'
    id: any
    item: {
      __typename?: 'shop_item'
      display_title?: Maybe<string>
      image?: Maybe<string>
      cached_image?: Maybe<string>
    }
  }>
}

export type GetOnboardingSourcesQueryVariables = Exact<{ [key: string]: never }>

export type GetOnboardingSourcesQuery = {
  __typename?: 'query_root'
  onboarding_sources: Array<{
    __typename?: 'onboarding_sources'
    value: string
    label?: Maybe<string>
  }>
}

export type GetPriceChangesAmazonShopifyPaginateQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetPriceChangesAmazonShopifyPaginateQuery = {
  __typename?: 'query_root'
  shop_item: Array<{
    __typename?: 'shop_item'
    id: any
    item_id: string
    created_at: any
    updated_at: any
    parsed_pricing?: Maybe<any>
    display_title?: Maybe<string>
    pricing?: Maybe<any>
    image_highres?: Maybe<string>
    image?: Maybe<string>
    cached_image?: Maybe<string>
    url?: Maybe<string>
    shop_item_histories: Array<{
      __typename?: 'shop_item_history'
      id: any
      valid: boolean
      pricing?: Maybe<any>
      updated_at: any
    }>
    shop: {
      __typename?: 'shop'
      name: any
      favicon?: Maybe<string>
      key: string
    }
    cart_items: Array<{
      __typename?: 'cart_item'
      id: any
      source?: Maybe<string>
      updated_at: any
      created_at: any
      cart: {
        __typename?: 'cart'
        user: {
          __typename?: 'user'
          id: string
          name?: Maybe<string>
          picture?: Maybe<string>
          email?: Maybe<string>
        }
      }
    }>
  }>
}

export type GetPriceDropDataForPushNotificationQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetPriceDropDataForPushNotificationQuery = {
  __typename?: 'query_root'
  push_notification_by_pk?: Maybe<{
    __typename?: 'push_notification'
    payload: any
    user?: Maybe<{ __typename?: 'user' } & UserForPushNotificationDataFragment>
  }>
}

export type GetRandomDiscoveryFeedQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  seed?: Maybe<Scalars['seed_float']>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
}>

export type GetRandomDiscoveryFeedQuery = {
  __typename?: 'query_root'
  discovery_feed: Array<{
    __typename?: 'discovery_feed'
    feed_item: {
      __typename?: 'feed_item'
      item_type: Feed_Item_Type_Enum
      collection?: Maybe<{
        __typename?: 'collection'
        id: any
        slug: any
        collection_items: Array<
          {
            __typename?: 'collection_cart_item'
          } & DiscoveryCollectionCartItemFragment
        >
      }>
      collection_cart_item?: Maybe<
        {
          __typename?: 'collection_cart_item'
        } & DiscoveryCollectionCartItemFragment &
          DiscoveryFeedCollectionCartItemCollectionFragment
      >
    }
  }>
}

export type GetRemindMeAtCartItemsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetRemindMeAtCartItemsQuery = {
  __typename?: 'query_root'
  cart_item: Array<{
    __typename?: 'cart_item'
    cart_id: any
    display_title?: Maybe<string>
    description?: Maybe<string>
    pricing?: Maybe<any>
    shop: { __typename?: 'shop'; name: any; favicon?: Maybe<string> }
    item: {
      __typename?: 'shop_item'
      display_title?: Maybe<string>
      pricing?: Maybe<any>
      parsed_pricing?: Maybe<any>
      created_at: any
    }
    cart: {
      __typename?: 'cart'
      user: {
        __typename?: 'user'
        email?: Maybe<string>
        name?: Maybe<string>
        picture?: Maybe<string>
        id: string
      }
    }
  }>
}

export type GetShopByKeyForMobileQueryVariables = Exact<{
  key: Scalars['String']
}>

export type GetShopByKeyForMobileQuery = {
  __typename?: 'query_root'
  shop: Array<{
    __typename?: 'shop'
    id: any
    key: string
    cart_path?: Maybe<string>
    type?: Maybe<any>
    platform?: Maybe<string>
    name: any
    slug: any
    favicon?: Maybe<string>
    unknown?: Maybe<boolean>
    metadata: any
    created_at?: Maybe<any>
    updated_at?: Maybe<any>
  }>
}

export type GetShopCategoriesQueryVariables = Exact<{
  block_list?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type GetShopCategoriesQuery = {
  __typename?: 'query_root'
  shop_category: Array<{
    __typename?: 'shop_category'
    value: string
    label?: Maybe<string>
    onboarding_category?: Maybe<{
      __typename?: 'onboarding_categories'
      featured: boolean
      image: string
    }>
  }>
}

export type GetShopItemDataForPriceDropPushNotificationQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetShopItemDataForPriceDropPushNotificationQuery = {
  __typename?: 'query_root'
  shop_item_by_pk?: Maybe<{
    __typename?: 'shop_item'
    display_title?: Maybe<string>
    parsed_pricing?: Maybe<any>
  }>
}

export type GetShopItemForEnrichmentQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetShopItemForEnrichmentQuery = {
  __typename?: 'query_root'
  shop_item_by_pk?: Maybe<{
    __typename?: 'shop_item'
    id: any
    item_id: string
    pricing?: Maybe<any>
    parsed_pricing?: Maybe<any>
    url?: Maybe<string>
    title?: Maybe<string>
    image?: Maybe<string>
    shop_key: string
    shop: {
      __typename?: 'shop'
      key: string
      cart_path?: Maybe<string>
      type?: Maybe<any>
      name: any
      slug: any
      favicon?: Maybe<string>
      unknown?: Maybe<boolean>
      metadata: any
    }
  }>
}

export type GetShopItemForScrapingQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetShopItemForScrapingQuery = {
  __typename?: 'query_root'
  shop_item_by_pk?: Maybe<{
    __typename?: 'shop_item'
    url?: Maybe<string>
    item_id: string
    shop_key: string
  }>
}

export type GetShopItemForShopItemHistoryProcessingQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetShopItemForShopItemHistoryProcessingQuery = {
  __typename?: 'query_root'
  shop_item_by_pk?: Maybe<{
    __typename?: 'shop_item'
    id: any
    item_id: string
    pricing?: Maybe<any>
    parsed_pricing?: Maybe<any>
    url?: Maybe<string>
    metadata?: Maybe<any>
    title?: Maybe<string>
    description?: Maybe<string>
    image?: Maybe<string>
    shop_key: string
    availability?: Maybe<Shop_Item_Availability_Enum>
    enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum>
    categories?: Maybe<Array<string>>
    keywords?: Maybe<Array<string>>
    shop: {
      __typename?: 'shop'
      type?: Maybe<any>
      platform?: Maybe<string>
      shop_categorizations: Array<{
        __typename?: 'shop_categorization'
        category: Shop_Category_Enum
      }>
    }
    cart_items: Array<{ __typename?: 'cart_item'; source?: Maybe<string> }>
    shop_item_histories_aggregate: {
      __typename?: 'shop_item_history_aggregate'
      aggregate?: Maybe<{
        __typename?: 'shop_item_history_aggregate_fields'
        count: number
      }>
    }
    shop_item_images: Array<{
      __typename?: 'shop_item_image'
      id: any
      url: string
    }>
  }>
}

export type GetShopItemToCheckRedirectQueryVariables = Exact<{
  shopKey: Scalars['String']
  offset: Scalars['Int']
  affiliateOverride: Scalars['String']
  limit?: Maybe<Scalars['Int']>
}>

export type GetShopItemToCheckRedirectQuery = {
  __typename?: 'query_root'
  shop?: Maybe<{
    __typename?: 'shop'
    id: any
    metadata: any
    parsed_take_rate?: Maybe<any>
    shop_affiliates: Array<
      { __typename?: 'shop_affiliate' } & ShopAffiliateFragment
    >
    items: Array<{ __typename?: 'shop_item'; id: any; url?: Maybe<string> }>
  }>
  affiliateOverride?: Maybe<{ __typename?: 'affiliate' } & AffiliateFragment>
}

export type GetShopMetadataQueryVariables = Exact<{
  key: Scalars['String']
}>

export type GetShopMetadataQuery = {
  __typename?: 'query_root'
  shop?: Maybe<{ __typename?: 'shop'; metadata: any }>
}

export type ShopCategorizationsQueryVariables = Exact<{ [key: string]: never }>

export type ShopCategorizationsQuery = {
  __typename?: 'query_root'
  shop_categorization: Array<{
    __typename?: 'shop_categorization'
    id: any
    category: Shop_Category_Enum
    shop_category: { __typename?: 'shop_category'; label?: Maybe<string> }
    shop: {
      __typename?: 'shop'
      id: any
      key: string
      name: any
      favicon?: Maybe<string>
    }
  }>
}

export type GetShopsForFirehoseQueryVariables = Exact<{
  shops_limit?: Maybe<Scalars['Int']>
  created_at_offset?: Maybe<Scalars['timestamptz']>
}>

export type GetShopsForFirehoseQuery = {
  __typename?: 'query_root'
  shops: Array<{
    __typename?: 'shop'
    id: any
    key: string
    display_name?: Maybe<string>
    created_at?: Maybe<any>
    cart_items: Array<{
      __typename?: 'cart_item'
      id: any
      created_at: any
      cart: {
        __typename?: 'cart'
        id: any
        user: {
          __typename?: 'user'
          display_name?: Maybe<string>
          username?: Maybe<string>
          picture?: Maybe<string>
        }
      }
    }>
    featured_shop?: Maybe<{
      __typename?: 'featured_shop'
      feed_item?: Maybe<{
        __typename?: 'feed_item'
        id: any
        in_discovery_backlog?: Maybe<boolean>
        in_discovery_feed?: Maybe<boolean>
      }>
    }>
    items: Array<{
      __typename?: 'shop_item'
      id: any
      display_title?: Maybe<string>
      url?: Maybe<string>
      image?: Maybe<string>
      cached_image?: Maybe<string>
      image_highres?: Maybe<string>
    }>
  }>
}

export type GetSingleNotificationQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type GetSingleNotificationQuery = {
  __typename?: 'query_root'
  push_notification: Array<{
    __typename?: 'push_notification'
    active: boolean
    id: any
    payload: any
    criteria: any
    show_after: any
    show_before: any
  }>
  user_notifications: Array<{
    __typename?: 'user_push_notification'
    active?: Maybe<boolean>
    id?: Maybe<any>
    payload?: Maybe<any>
    criteria?: Maybe<any>
    show_after?: Maybe<any>
    show_before?: Maybe<any>
  }>
  push_notification_status: Array<{
    __typename?: 'push_notification_status'
    created_at: any
  }>
}

export type GetSinglePriceDropItemQueryVariables = Exact<{
  shopItemId?: Maybe<Scalars['uuid']>
}>

export type GetSinglePriceDropItemQuery = {
  __typename?: 'query_root'
  shop_item: Array<{
    __typename?: 'shop_item'
    id: any
    item_id: string
    created_at: any
    updated_at: any
    parsed_pricing?: Maybe<any>
    display_title?: Maybe<string>
    pricing?: Maybe<any>
    url?: Maybe<string>
    image?: Maybe<string>
    image_highres?: Maybe<string>
    cached_image?: Maybe<string>
    shop_item_histories: Array<{
      __typename?: 'shop_item_history'
      id: any
      valid: boolean
      pricing?: Maybe<any>
      updated_at: any
    }>
    shop: {
      __typename?: 'shop'
      name: any
      favicon?: Maybe<string>
      key: string
    }
    cart_items: Array<{
      __typename?: 'cart_item'
      id: any
      created_at: any
      cart: {
        __typename?: 'cart'
        user: {
          __typename?: 'user'
          id: string
          name?: Maybe<string>
          picture?: Maybe<string>
          email?: Maybe<string>
        }
      }
    }>
  }>
}

export type GetUserAllCollectionsDataForProfilePageQueryVariables = Exact<{
  username?: Maybe<Scalars['String']>
  itemLimit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserAllCollectionsDataForProfilePageQuery = {
  __typename?: 'query_root'
  collection_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  collections: Array<
    {
      __typename?: 'collection'
    } & UserCollectionsDataForProfilePageDataFragment
  >
}

export type GetUserArchivedCartsQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetUserArchivedCartsQuery = {
  __typename?: 'query_root'
  archived_carts: Array<
    {
      __typename?: 'cart'
      cart_items: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
    } & CartDataFragment
  >
}

export type GetUserCartsQueryVariables = Exact<{
  id: Scalars['String']
  currentShopInstanceKey?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetUserCartsQuery = {
  __typename?: 'query_root'
  cart_aggregate: {
    __typename?: 'cart_aggregate'
    aggregate?: Maybe<{ __typename?: 'cart_aggregate_fields'; count: number }>
  }
  carts: Array<
    { __typename?: 'cart' } & CartDataFragment & CartItemsDataFragment
  >
  any_cart_items: Array<{ __typename?: 'cart_item'; id: any }>
}

export type GetUserCollectionsCollaboratingQueryVariables = Exact<{
  username?: Maybe<Scalars['String']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
  itemLimit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserCollectionsCollaboratingQuery = {
  __typename?: 'query_root'
  collection_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  collections: Array<
    {
      __typename?: 'collection'
    } & UserCollectionsDataForProfilePageDataFragment
  >
}

export type GetUserCollectionsDataForProfilePageQueryVariables = Exact<{
  username?: Maybe<Scalars['String']>
  publishedOrPublic?: Maybe<Scalars['Boolean']>
  itemLimit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserCollectionsDataForProfilePageQuery = {
  __typename?: 'query_root'
  collection_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  collections: Array<
    {
      __typename?: 'collection'
    } & UserCollectionsDataForProfilePageDataFragment
  >
  landing_page_collections: Array<
    {
      __typename?: 'collection'
    } & UserCollectionsDataForProfilePageDataFragment
  >
}

export type GetUserCollectionsFollowingQueryVariables = Exact<{
  username?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  itemLimit?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type GetUserCollectionsFollowingQuery = {
  __typename?: 'query_root'
  user_collections_followed: Array<{
    __typename?: 'user_items_followed'
    followable_item?: Maybe<{
      __typename?: 'followable_item'
      collection?: Maybe<
        { __typename?: 'collection' } & CollectionAndItemsDataFragment
      >
    }>
  }>
  user_collections_followed_aggregate: {
    __typename?: 'user_items_followed_aggregate'
    aggregate?: Maybe<{
      __typename?: 'user_items_followed_aggregate_fields'
      count: number
    }>
  }
}

export type GetUserCreatedCollectionsQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  withOwner?: Maybe<Scalars['Boolean']>
  collectionItemsLimit?: Maybe<Scalars['Int']>
  searchQuery?: Maybe<Scalars['String']>
}>

export type GetUserCreatedCollectionsQuery = {
  __typename?: 'query_root'
  collections: Array<
    {
      __typename?: 'collection'
      id: any
      name: any
      slug: any
      state?: Maybe<Collection_State_Enum>
      paid: boolean
      exclusive?: Maybe<boolean>
      private: boolean
      cover_image?: Maybe<string>
      cover_image_cached?: Maybe<string>
      has_collaborators: boolean
      user?: Maybe<{ __typename?: 'user'; id: string; picture?: Maybe<string> }>
      collection_items_aggregate: {
        __typename?: 'collection_cart_item_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_cart_item_aggregate_fields'
          count: number
        }>
      }
      collection_items: Array<{
        __typename?: 'collection_cart_item'
        id: any
        cart_item: {
          __typename?: 'cart_item'
          id: any
          item_image_override?: Maybe<string>
          item: {
            __typename?: 'shop_item'
            id: any
            image?: Maybe<string>
            image_highres?: Maybe<string>
            cached_image?: Maybe<string>
          }
        }
      }>
    } & CollectionCollaboratorsDataFragment
  >
  collection_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
}

export type GetUserDataForProfilePageQueryVariables = Exact<{
  username?: Maybe<Scalars['String']>
  collectionsLimit?: Maybe<Scalars['Int']>
}>

export type GetUserDataForProfilePageQuery = {
  __typename?: 'query_root'
  user: Array<
    {
      __typename?: 'user'
      cover_image?: Maybe<string>
      bio?: Maybe<string>
      bio_link?: Maybe<string>
      ogimage_fingerprint?: Maybe<string>
      has_claimed_username_computed?: Maybe<boolean>
      has_set_name_computed?: Maybe<boolean>
      has_picture_set?: Maybe<boolean>
      anonymous?: Maybe<boolean>
      links: Array<{
        __typename?: 'user_links'
        id: any
        url: string
        link_type?: Maybe<Link_Type_Enum>
        type?: Maybe<{
          __typename?: 'link_type'
          value: string
          description?: Maybe<string>
        }>
      }>
      carts_aggregate: {
        __typename?: 'cart_aggregate'
        aggregate?: Maybe<{
          __typename?: 'cart_aggregate_fields'
          count: number
        }>
      }
      collections_collaborating_aggregate: {
        __typename?: 'collection_collaborator_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_collaborator_aggregate_fields'
          count: number
        }>
      }
      collections_published_public_aggregate: {
        __typename?: 'collection_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_aggregate_fields'
          count: number
        }>
      }
      collections_draft_private_aggregate: {
        __typename?: 'collection_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_aggregate_fields'
          count: number
        }>
      }
    } & BasicUserDataFragment
  >
  collections: Array<
    {
      __typename?: 'collection'
    } & UserCollectionsDataForProfilePageDataFragment
  >
}

export type GetUserFlagsQueryVariables = Exact<{
  user_id: Scalars['String']
}>

export type GetUserFlagsQuery = {
  __typename?: 'query_root'
  user_flags_by_pk?: Maybe<{
    __typename?: 'user_flags'
    opted_into_cart_onboarding: boolean
    last_cart_onboarding?: Maybe<any>
  }>
}

export type GetUserForMixpanelQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetUserForMixpanelQuery = {
  __typename?: 'query_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    email?: Maybe<string>
    phone?: Maybe<string>
    anonymous?: Maybe<boolean>
    installed_at?: Maybe<any>
    name?: Maybe<string>
    onboarded?: Maybe<boolean>
    orphaned_at?: Maybe<any>
    orphaned_by?: Maybe<string>
    picture?: Maybe<string>
    role?: Maybe<any>
    username?: Maybe<string>
    has_claimed_username_computed?: Maybe<boolean>
    extension_version?: Maybe<string>
    ios_version?: Maybe<string>
    safari_extension_version?: Maybe<string>
    first_app_install?: Maybe<string>
    created_at?: Maybe<any>
    updated_at?: Maybe<any>
    internal_account?: Maybe<boolean>
    metadata?: Maybe<any>
  }>
}

export type GetUserForSplitioQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetUserForSplitioQuery = {
  __typename?: 'query_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    email?: Maybe<string>
    installed_at?: Maybe<any>
    onboarded?: Maybe<boolean>
    role?: Maybe<any>
    username?: Maybe<string>
    extension_version?: Maybe<string>
    created_at?: Maybe<any>
    updated_at?: Maybe<any>
    internal_account?: Maybe<boolean>
  }>
}

export type GetUserLandingPageCollectionsQueryVariables = Exact<{
  username?: Maybe<Scalars['String']>
}>

export type GetUserLandingPageCollectionsQuery = {
  __typename?: 'query_root'
  landing_page_collections: Array<
    {
      __typename?: 'collection'
    } & UserCollectionsDataForProfilePageDataFragment
  >
}

export type GetUserMobileBookmarksQueryVariables = Exact<{
  user_id: Scalars['String']
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type GetUserMobileBookmarksQuery = {
  __typename?: 'query_root'
  mobile_bookmark_workflows: Array<{
    __typename?: 'workflow'
    id: any
    data: any
    status: Workflow_Status_Enum
  }>
}

export type UserOnboardedAndSavedCartsAggregateQueryVariables = Exact<{
  id: Scalars['String']
}>

export type UserOnboardedAndSavedCartsAggregateQuery = {
  __typename?: 'query_root'
  cart_aggregate: {
    __typename?: 'cart_aggregate'
    aggregate?: Maybe<{ __typename?: 'cart_aggregate_fields'; count: number }>
  }
  user?: Maybe<{ __typename?: 'user'; onboarded?: Maybe<boolean> }>
}

export type GetUserOnboardingSourceQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type GetUserOnboardingSourceQuery = {
  __typename?: 'query_root'
  user_onboarding_source: Array<{
    __typename?: 'user_onboarding_source'
    id: any
    onboarding_source: Onboarding_Sources_Enum
    other_source?: Maybe<string>
  }>
}

export type GetUserPendingMobileBookmarksQueryVariables = Exact<{
  user_id: Scalars['String']
}>

export type GetUserPendingMobileBookmarksQuery = {
  __typename?: 'query_root'
  mobile_pending_bookmark_workflows: Array<{
    __typename?: 'workflow'
    id: any
    data: any
    status: Workflow_Status_Enum
  }>
}

export type GetUserPointsMultiplierQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetUserPointsMultiplierQuery = {
  __typename?: 'query_root'
  user: Array<{
    __typename?: 'user'
    id: string
    user_points_multiplier?: Maybe<any>
  }>
}

export type GetUserSelectedCategoriesQueryVariables = Exact<{
  user_id: Scalars['String']
}>

export type GetUserSelectedCategoriesQuery = {
  __typename?: 'query_root'
  user_category: Array<{
    __typename?: 'user_category'
    id: any
    category: Shop_Category_Enum
  }>
}

export type GetUserRecentNotificationsQueryVariables = Exact<{
  userId: Scalars['String']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetUserRecentNotificationsQuery = {
  __typename?: 'query_root'
  push_notification: Array<{
    __typename?: 'push_notification'
    id: any
    payload: any
    active: boolean
    created_at: any
  }>
}

export type GetWorkflowDetailsQueryVariables = Exact<{
  workflowId: Scalars['uuid']
  withParentWorkflow?: Maybe<Scalars['Boolean']>
}>

export type GetWorkflowDetailsQuery = {
  __typename?: 'query_root'
  workflow_by_pk?: Maybe<{
    __typename?: 'workflow'
    id: any
    data: any
    status: Workflow_Status_Enum
    status_details?: Maybe<string>
    success_item_id?: Maybe<any>
    success_item_type?: Maybe<string>
    type: Worflow_Type_Enum
    user_id?: Maybe<string>
    parent_workflow?: Maybe<{
      __typename?: 'workflow'
      id: any
      data: any
      status: Workflow_Status_Enum
      status_details?: Maybe<string>
      success_item_id?: Maybe<any>
      success_item_type?: Maybe<string>
      type: Worflow_Type_Enum
      user_id?: Maybe<string>
    }>
  }>
}

export type InsertCollectionCollaboratorMutationVariables = Exact<{
  userId: Scalars['String']
  collectionId: Scalars['uuid']
}>

export type InsertCollectionCollaboratorMutation = {
  __typename?: 'mutation_root'
  insert_collection_collaborator_one?: Maybe<{
    __typename?: 'collection_collaborator'
    id: any
  }>
}

export type InsertCollectionPromptNotificationMutationVariables = Exact<{
  user_id: Scalars['String']
  show_before: Scalars['timestamptz']
  show_after: Scalars['timestamptz']
  payload: Scalars['jsonb']
  criteria: Scalars['jsonb']
  active?: Maybe<Scalars['Boolean']>
}>

export type InsertCollectionPromptNotificationMutation = {
  __typename?: 'mutation_root'
  insert_push_notification_one?: Maybe<{
    __typename?: 'push_notification'
    id: any
  }>
}

export type InsertDiscoveryFeedBacklogMutationVariables = Exact<{
  feed_item_id: Scalars['uuid']
  metadata?: Scalars['jsonb']
}>

export type InsertDiscoveryFeedBacklogMutation = {
  __typename?: 'mutation_root'
  insert_discovery_backlog_one?: Maybe<{
    __typename?: 'discovery_backlog'
    id: any
    feed_item: { __typename?: 'feed_item' } & FeedItemDataFragment
  }>
  update_feed_item_by_pk?: Maybe<
    { __typename?: 'feed_item' } & FeedItemDataFragment
  >
}

export type InsertFeaturedShopMutationVariables = Exact<{
  shop_key: Scalars['String']
}>

export type InsertFeaturedShopMutation = {
  __typename?: 'mutation_root'
  insert_featured_shop_one?: Maybe<{
    __typename?: 'featured_shop'
    id: any
    feed_item?: Maybe<{ __typename?: 'feed_item'; id: any }>
    shop: {
      __typename?: 'shop'
      id: any
      featured_shop?: Maybe<
        {
          __typename?: 'featured_shop'
        } & FeaturedShopAndFeaturedShopItemsFragment
      >
    }
  }>
}

export type InsertIntoDiscoveryStagingMutationVariables = Exact<{
  objects:
    | Array<Discovery_Staging_Insert_Input>
    | Discovery_Staging_Insert_Input
}>

export type InsertIntoDiscoveryStagingMutation = {
  __typename?: 'mutation_root'
  insert_discovery_staging?: Maybe<{
    __typename?: 'discovery_staging_mutation_response'
    returning: Array<{
      __typename?: 'discovery_staging'
      id: any
      sequential_id: number
    }>
  }>
}

export type InsertNewBookmarkWorkflowMutationVariables = Exact<{
  userId: Scalars['String']
  url: Scalars['String']
  title?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
}>

export type InsertNewBookmarkWorkflowMutation = {
  __typename?: 'mutation_root'
  insert_workflow_one?: Maybe<{ __typename?: 'workflow'; id: any }>
}

export type InsertContactInviteMutationVariables = Exact<{
  contact_info: Scalars['String']
}>

export type InsertContactInviteMutation = {
  __typename?: 'mutation_root'
  inviteStatus?: Maybe<
    { __typename?: 'contact_invite' } & ContactInviteDataFragment
  >
}

export type InsertNotificationStatusMutationVariables = Exact<{
  notification_id: Scalars['uuid']
}>

export type InsertNotificationStatusMutation = {
  __typename?: 'mutation_root'
  notificationStatus?: Maybe<{
    __typename?: 'push_notification_status'
    id: any
  }>
}

export type InsertPriceDropNotificationMutationVariables = Exact<{
  user_id: Scalars['String']
  show_before: Scalars['timestamptz']
  show_after: Scalars['timestamptz']
  payload: Scalars['jsonb']
  criteria: Scalars['jsonb']
  active?: Maybe<Scalars['Boolean']>
}>

export type InsertPriceDropNotificationMutation = {
  __typename?: 'mutation_root'
  insert_push_notification_one?: Maybe<{
    __typename?: 'push_notification'
    id: any
  }>
}

export type InsertShopAffiliateMutationVariables = Exact<{
  shopAffiliates:
    | Array<Shop_Affiliate_Insert_Input>
    | Shop_Affiliate_Insert_Input
}>

export type InsertShopAffiliateMutation = {
  __typename?: 'mutation_root'
  insert_shop_affiliate?: Maybe<{
    __typename?: 'shop_affiliate_mutation_response'
    returning: Array<{ __typename?: 'shop_affiliate'; id: any }>
  }>
}

export type UpsertUserCategoriesMutationVariables = Exact<{
  user_id: Scalars['String']
  categories: Array<User_Category_Insert_Input> | User_Category_Insert_Input
}>

export type UpsertUserCategoriesMutation = {
  __typename?: 'mutation_root'
  delete_user_category?: Maybe<{
    __typename?: 'user_category_mutation_response'
    affected_rows: number
  }>
  insert_user_category?: Maybe<{
    __typename?: 'user_category_mutation_response'
    returning: Array<{ __typename?: 'user_category'; id: any }>
  }>
}

export type InviteCollaboratorMutationVariables = Exact<{
  collection_id: Scalars['uuid']
  user_id?: Maybe<Scalars['String']>
}>

export type InviteCollaboratorMutation = {
  __typename?: 'mutation_root'
  inviteCollaborator?: Maybe<{
    __typename?: 'CollectionInviteOutput'
    collection_invite?: Maybe<
      { __typename?: 'collection_invite' } & CollectionInviteFragment
    >
  }>
}

export type InviteCollectionCollaboratorOneMutationVariables = Exact<{
  user_id?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  collection_id: Scalars['uuid']
}>

export type InviteCollectionCollaboratorOneMutation = {
  __typename?: 'mutation_root'
  insert_collection_invite_one?: Maybe<{
    __typename?: 'collection_invite'
    id: any
  }>
}

export type InviteCollectionCollaboratorMutationVariables = Exact<{
  objects:
    | Array<Collection_Invite_Insert_Input>
    | Collection_Invite_Insert_Input
  is_admin?: Maybe<Scalars['Boolean']>
}>

export type InviteCollectionCollaboratorMutation = {
  __typename?: 'mutation_root'
  insert_collection_invite?: Maybe<{
    __typename?: 'collection_invite_mutation_response'
    affected_rows: number
    returning: Array<
      { __typename?: 'collection_invite' } & CollectionInviteFragment
    >
  }>
}

export type LikeFeedItemMutationVariables = Exact<{
  feed_item_id: Scalars['uuid']
  value?: Scalars['Int']
}>

export type LikeFeedItemMutation = {
  __typename?: 'mutation_root'
  feedItemLike?: Maybe<{ __typename?: 'feed_item_like'; id: any }>
}

export type LikeFeedItemActionMutationVariables = Exact<{
  input: FeedItemLikeInput
}>

export type LikeFeedItemActionMutation = {
  __typename?: 'mutation_root'
  likeFeedItem?: Maybe<{
    __typename?: 'FeedItemLikeOutput'
    feed_item_like?: Maybe<{
      __typename?: 'feed_item_like'
      id: any
      feed_item: { __typename?: 'feed_item' } & FeedItemDataFragment
    }>
  }>
}

export type GetCartItemQueryVariables = Exact<{
  cart_item_id: Scalars['uuid']
}>

export type GetCartItemQuery = {
  __typename?: 'query_root'
  cart_item?: Maybe<
    {
      __typename?: 'cart_item'
      id: any
      item: { __typename?: 'shop_item'; display_title?: Maybe<string> }
      note?: Maybe<{
        __typename?: 'cart_item_note'
        note?: Maybe<string>
        private: boolean
      }>
    } & CartItemDataFragment
  >
}

export type CartItemDataPartialForSavedItemsFragment = {
  __typename?: 'cart_item'
  id: any
  cart_id: any
  item_id: any
  shop_key: string
  quantity: any
  bookmark: boolean
  in_collection?: Maybe<boolean>
  archived_at?: Maybe<any>
  removed_at?: Maybe<any>
  removed_reason?: Maybe<Remove_Reason_Enum>
  deleted_at?: Maybe<any>
  created_at: any
  updated_at: any
  source?: Maybe<string>
  affiliate_redirect_link_override?: Maybe<string>
  item_image_override?: Maybe<string>
  item_title_override?: Maybe<string>
  item_pricing_override?: Maybe<any>
  item_description_override?: Maybe<string>
  item_video_url?: Maybe<string>
  item_video_orientation?: Maybe<string>
  display_title?: Maybe<string>
  description?: Maybe<string>
  pricing?: Maybe<any>
  hidden: boolean
  image_metadata?: Maybe<any>
  creator_rating?: Maybe<any>
  like_count?: Maybe<number>
  note?: Maybe<{
    __typename?: 'cart_item_note'
    id: any
    note?: Maybe<string>
    private: boolean
  }>
  cart: {
    __typename?: 'cart'
    id: any
    user: { __typename?: 'user' } & BasicUserDataFragment
  }
  shop: { __typename?: 'shop' } & ShopDataFragment
  item: { __typename?: 'shop_item' } & ItemDataFragment
  collection_item_claim?: Maybe<
    { __typename?: 'collection_item_claim' } & CollectionItemClaimDataFragment
  >
  collection_cart_items: Array<{
    __typename?: 'collection_cart_item'
    collection_id: any
  }>
  user?: Maybe<
    Array<{
      __typename?: 'user'
      id: string
      picture?: Maybe<string>
      username?: Maybe<string>
    }>
  >
}

export type GetMobileUserSavedItemsQueryVariables = Exact<{
  userId: Scalars['String']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  withArchived?: Maybe<Scalars['Boolean']>
  withAll?: Maybe<Scalars['Boolean']>
  withDealHop?: Maybe<Scalars['Boolean']>
}>

export type GetMobileUserSavedItemsQuery = {
  __typename?: 'query_root'
  all_items: Maybe<
    Array<
      { __typename?: 'cart_item' } & CartItemDataPartialForSavedItemsFragment
    >
  >
  all_items_aggregate_count: Maybe<{
    __typename?: 'cart_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'cart_item_aggregate_fields'
      count: number
    }>
  }>
  dealhop_items: Maybe<
    Array<
      { __typename?: 'cart_item' } & CartItemDataPartialForSavedItemsFragment
    >
  >
  dealhop_items_aggregate_count: Maybe<{
    __typename?: 'cart_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'cart_item_aggregate_fields'
      count: number
    }>
  }>
  archived_items: Maybe<
    Array<
      { __typename?: 'cart_item' } & CartItemDataPartialForSavedItemsFragment
    >
  >
  archived_items_aggregate_count: Maybe<{
    __typename?: 'cart_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'cart_item_aggregate_fields'
      count: number
    }>
  }>
}

export type FindCollectionMobileQueryVariables = Exact<{
  collection_id: Scalars['uuid']
  user_id: Scalars['String']
}>

export type FindCollectionMobileQuery = {
  __typename?: 'query_root'
  collection?: Maybe<
    {
      __typename?: 'collection'
      followed_by_user?: Maybe<boolean>
      is_a_collaborator?: Maybe<boolean>
      state?: Maybe<Collection_State_Enum>
      paid: boolean
      exclusive?: Maybe<boolean>
      has_valid_preview_images_computed?: Maybe<boolean>
      notification_preferences: any
      email_excerpt?: Maybe<string>
      total_likes_count?: Maybe<any>
      collection_type: Collection_Type_Enum
      open_collab_link_computed?: Maybe<string>
      cover_image?: Maybe<string>
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      media_enabled_join?: Maybe<
        { __typename?: 'media_enabled_item' } & MediaEnabledItemDataFragment
      >
      preview_collection_items?: Maybe<
        Array<{
          __typename?: 'collection_cart_item'
          id: any
          cart_item: {
            __typename?: 'cart_item'
            id: any
            item_image_override?: Maybe<string>
            item: { __typename?: 'shop_item'; cached_image?: Maybe<string> }
          }
        }>
      >
      user?: Maybe<{
        __typename?: 'user'
        id: string
        name?: Maybe<string>
        username?: Maybe<string>
        picture?: Maybe<string>
        created_at?: Maybe<any>
        has_billing_account?: Maybe<boolean>
      }>
      collection_collaborators_aggregate: {
        __typename?: 'collection_collaborator_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_collaborator_aggregate_fields'
          count: number
        }>
      }
      collection_collaborators: Array<{
        __typename?: 'collection_collaborator'
        id: any
        user: {
          __typename?: 'user'
          id: string
          name?: Maybe<string>
          picture?: Maybe<string>
        }
      }>
    } & BasicCollectionDataFragment
  >
  is_collaborator: {
    __typename?: 'collection_collaborator_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_collaborator_aggregate_fields'
      count: number
    }>
  }
}

export type BasicCollectionDataFragment = {
  __typename?: 'collection'
  id: any
  short_id?: Maybe<any>
  user_id?: Maybe<string>
  slug: any
  name: any
  description?: Maybe<string>
  seo_fields?: Maybe<any>
  private: boolean
  giftlist: boolean
  cart_items_count?: Maybe<any>
  has_collaborators: boolean
  metadata?: Maybe<any>
}

export type GetCollectionCollaboratorsMobileQueryVariables = Exact<{
  collection_id: Scalars['uuid']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetCollectionCollaboratorsMobileQuery = {
  __typename?: 'query_root'
  collection_collaborator: Array<{
    __typename?: 'collection_collaborator'
    user: {
      __typename?: 'user'
      id: string
      username?: Maybe<string>
      created_at?: Maybe<any>
      name?: Maybe<string>
      picture?: Maybe<string>
      verified?: Maybe<boolean>
      collections_aggregate: {
        __typename?: 'collection_aggregate'
        aggregate?: Maybe<{
          __typename?: 'collection_aggregate_fields'
          count: number
        }>
      }
    }
  }>
}

export type GetUserCollectionsQueryVariables = Exact<{
  userId: Scalars['String']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  itemLimit?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type GetUserCollectionsQuery = {
  __typename?: 'query_root'
  collections_personal: Array<
    { __typename?: 'collection' } & CollectionAndItemsDataFragment
  >
  collections_personal_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  collections_collaborating: Array<
    { __typename?: 'collection' } & CollectionAndItemsDataFragment
  >
  collections_collaborating_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  user_collections_followed: Array<{
    __typename?: 'user_items_followed'
    followable_item?: Maybe<{
      __typename?: 'followable_item'
      collection?: Maybe<
        { __typename?: 'collection' } & CollectionAndItemsDataFragment
      >
    }>
  }>
  user_collections_followed_aggregate: {
    __typename?: 'user_items_followed_aggregate'
    aggregate?: Maybe<{
      __typename?: 'user_items_followed_aggregate_fields'
      count: number
    }>
  }
}

export type CollectionPartialForCollectionListFragment = {
  __typename?: 'collection'
  id: any
  cover_image?: Maybe<string>
  cover_image_cached?: Maybe<string>
  has_collaborators: boolean
  description?: Maybe<string>
  name: any
  state?: Maybe<Collection_State_Enum>
  collection_type: Collection_Type_Enum
  private: boolean
  cart_items_quantity?: Maybe<any>
  cart_items_count?: Maybe<any>
  updated_at?: Maybe<any>
  user?: Maybe<{
    __typename?: 'user'
    picture?: Maybe<string>
    verified?: Maybe<boolean>
    username?: Maybe<string>
  }>
  collection_collaborators: Array<{
    __typename?: 'collection_collaborator'
    user: { __typename?: 'user'; picture?: Maybe<string> }
  }>
  collection_collaborators_aggregate: {
    __typename?: 'collection_collaborator_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_collaborator_aggregate_fields'
      count: number
    }>
  }
  collection_items?: Maybe<
    Array<{
      __typename?: 'collection_cart_item'
      id: any
      cart_item: {
        __typename?: 'cart_item'
        id: any
        item_image_override?: Maybe<string>
        display_title?: Maybe<string>
        item: {
          __typename?: 'shop_item'
          id: any
          image?: Maybe<string>
          image_highres?: Maybe<string>
          cached_image?: Maybe<string>
          cached_placeholder_image?: Maybe<string>
          image_metadata?: Maybe<any>
          metadata?: Maybe<any>
          is_inappropriate: boolean
        }
      }
    }>
  >
}

export type GetUserCollectionsPartialQueryVariables = Exact<{
  userId: Scalars['String']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  itemLimit?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
  withPersonal?: Maybe<Scalars['Boolean']>
  withCollaborating?: Maybe<Scalars['Boolean']>
  withFollowing?: Maybe<Scalars['Boolean']>
  withVisible?: Maybe<Scalars['Boolean']>
  withHidden?: Maybe<Scalars['Boolean']>
}>

export type GetUserCollectionsPartialQuery = {
  __typename?: 'query_root'
  collections_all: Maybe<
    Array<
      { __typename?: 'collection' } & CollectionPartialForCollectionListFragment
    >
  >
  collections_visible: Maybe<
    Array<
      { __typename?: 'collection' } & CollectionPartialForCollectionListFragment
    >
  >
  collections_hidden: Maybe<
    Array<
      { __typename?: 'collection' } & CollectionPartialForCollectionListFragment
    >
  >
  collections_collaborating: Maybe<
    Array<
      { __typename?: 'collection' } & CollectionPartialForCollectionListFragment
    >
  >
  user_collections_followed: Maybe<
    Array<{
      __typename?: 'user_items_followed'
      followable_item?: Maybe<{
        __typename?: 'followable_item'
        collection?: Maybe<
          {
            __typename?: 'collection'
          } & CollectionPartialForCollectionListFragment
        >
      }>
    }>
  >
}

export type MobileCollectionCollaboratorsDataFragment = {
  __typename?: 'collection'
  collection_collaborators: Array<{
    __typename?: 'collection_collaborator'
    id: any
    user: { __typename?: 'user' } & BasicUserDataFragment
  }>
}

export type MobileCollectionCollaboratorsAggregateFragment = {
  __typename?: 'collection'
  collection_collaborators_aggregate: {
    __typename?: 'collection_collaborator_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_collaborator_aggregate_fields'
      count: number
    }>
  }
}

export type MobileCollectionAndItemsDataFragment = {
  __typename?: 'collection'
} & CollectionDataFragment &
  CollectionItemsDataFragment &
  MobileCollectionCollaboratorsDataFragment &
  MobileCollectionCollaboratorsAggregateFragment

export type GetAllUserCollectionsMobileQueryVariables = Exact<{
  userId: Scalars['String']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  itemLimit?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type GetAllUserCollectionsMobileQuery = {
  __typename?: 'query_root'
  collection_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  collections: Array<
    { __typename?: 'collection' } & MobileCollectionAndItemsDataFragment
  >
}

export type GetCreatorSubscriptionsMobileQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetCreatorSubscriptionsMobileQuery = {
  __typename?: 'query_root'
  availableSubscriptions: Array<{
    __typename?: 'Subscription'
    id: string
    name: string
    description?: Maybe<string>
    unit_amount: number
    currency: string
    interval: RecurringInterval
  }>
  creator?: Maybe<{
    __typename?: 'user'
    subscription_status?: Maybe<string>
    has_billing_account?: Maybe<boolean>
  }>
}

export type GetUserIdFromUsernameQueryVariables = Exact<{
  username: Scalars['String']
}>

export type GetUserIdFromUsernameQuery = {
  __typename?: 'query_root'
  user: Array<{ __typename?: 'user'; id: string }>
}

export type GetDataForHomeTabQueryVariables = Exact<{ [key: string]: never }>

export type GetDataForHomeTabQuery = {
  __typename?: 'query_root'
  top_block_enabled: Array<{
    __typename?: 'mobile_app_home'
    block_enabled?: Maybe<boolean>
  }>
  top_block: Array<{
    __typename?: 'mobile_app_home'
    block_link?: Maybe<string>
    block_image?: Maybe<string>
    link_type?: Maybe<string>
    link_type_value?: Maybe<string>
    deep_link?: Maybe<string>
  }>
  top_shopper_enabled: Array<{
    __typename?: 'mobile_app_home'
    block_enabled?: Maybe<boolean>
  }>
  top_shopper_block: Array<{
    __typename?: 'mobile_app_home'
    block_link?: Maybe<string>
    block_image?: Maybe<string>
    link_type?: Maybe<string>
  }>
}

export type FindCartItemsForDealHopNotificationQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type FindCartItemsForDealHopNotificationQuery = {
  __typename?: 'query_root'
  cart_items: Array<{
    __typename?: 'cart_item'
    id: any
    item_image_override?: Maybe<string>
    item: {
      __typename?: 'shop_item'
      image?: Maybe<string>
      cached_image?: Maybe<string>
    }
  }>
}

export type NotificationCenterItemDataFragment = {
  __typename?: 'notification_center_item'
  body?: Maybe<string>
  created_at: any
  id: any
  link?: Maybe<string>
  main_image?: Maybe<string>
  read_at?: Maybe<any>
  secondary_image?: Maybe<string>
  sequential_id: number
  communication_template?: Maybe<Communication_Template_Enum>
  banner?: Maybe<boolean>
  type: Notification_Center_Item_Type_Enum
  notifiable_item?: Maybe<{
    __typename?: 'notifiable_item'
    collection_invite?: Maybe<
      {
        __typename?: 'collection_invite'
      } & CollectionInviteForNotificationCenterFragment
    >
    feed_item_like?: Maybe<
      {
        __typename?: 'feed_item_like'
      } & FeedItemLikeForNotificationCenterFragment
    >
    collection_cart_item?: Maybe<
      {
        __typename?: 'collection_cart_item'
      } & CollectionCartItemForNotificationCenterFragment
    >
    follow?: Maybe<
      { __typename?: 'user_items_followed' } & UserItemsFollowedFragment
    >
    collection?: Maybe<
      { __typename?: 'collection' } & CollectionForNotificationCenterFragment
    >
    comment?: Maybe<
      { __typename?: 'comment' } & CommentForNotificationCenterFragment
    >
    cart_item?: Maybe<
      { __typename?: 'cart_item' } & CartItemForNotificationCenterFragment
    >
  }>
}

export type CollectionInviteForNotificationCenterFragment = {
  __typename?: 'collection_invite'
  id: any
  status: Collection_Invite_Status_Enum
  collection: {
    __typename?: 'collection'
    id: any
    name: any
    cover_image_cached?: Maybe<string>
    user?: Maybe<{
      __typename?: 'user'
      id: string
      username?: Maybe<string>
      picture?: Maybe<string>
    }>
  }
  invitee?: Maybe<{
    __typename?: 'user'
    id: string
    name?: Maybe<string>
    username?: Maybe<string>
    picture?: Maybe<string>
  }>
  requester: {
    __typename?: 'user'
    id: string
    username?: Maybe<string>
    picture?: Maybe<string>
  }
}

export type FeedItemLikeForNotificationCenterFragment = {
  __typename?: 'feed_item_like'
  user: {
    __typename?: 'user'
    picture?: Maybe<string>
    username?: Maybe<string>
  }
  feed_item: {
    __typename?: 'feed_item'
    item_type: Feed_Item_Type_Enum
    featured_shop?: Maybe<{
      __typename?: 'featured_shop'
      shop: {
        __typename?: 'shop'
        id: any
        name: any
        favicon?: Maybe<string>
        display_name?: Maybe<string>
      }
    }>
    collection?: Maybe<{
      __typename?: 'collection'
      id: any
      cover_image_cached?: Maybe<string>
      name: any
    }>
    cart_item?: Maybe<{
      __typename?: 'cart_item'
      id: any
      item_image_override?: Maybe<string>
      item: {
        __typename?: 'shop_item'
        cached_image?: Maybe<string>
        display_title?: Maybe<string>
      }
    }>
  }
}

export type CollectionCartItemForNotificationCenterFragment = {
  __typename?: 'collection_cart_item'
  id: any
  collection: { __typename?: 'collection'; id: any; name: any }
  cart_item: {
    __typename?: 'cart_item'
    item_image_override?: Maybe<string>
    item: { __typename?: 'shop_item'; cached_image?: Maybe<string> }
    cart: {
      __typename?: 'cart'
      user: {
        __typename?: 'user'
        name?: Maybe<string>
        username?: Maybe<string>
        picture?: Maybe<string>
      }
    }
  }
}

export type UserItemsFollowedFragment = {
  __typename?: 'user_items_followed'
  user: {
    __typename?: 'user'
    id: string
    picture?: Maybe<string>
    username?: Maybe<string>
  }
  followable_item?: Maybe<{
    __typename?: 'followable_item'
    collection?: Maybe<
      { __typename?: 'collection' } & CollectionForFollowFragment
    >
  }>
}

export type CollectionForFollowFragment = {
  __typename?: 'collection'
  id: any
  name: any
  cover_image_cached?: Maybe<string>
}

export type CollectionForNotificationCenterFragment = {
  __typename?: 'collection'
  id: any
  name: any
  cover_image_cached?: Maybe<string>
  user?: Maybe<{
    __typename?: 'user'
    picture?: Maybe<string>
    username?: Maybe<string>
  }>
}

export type WrappedNotificationCenterItemDataFragment = {
  __typename?: 'WrappedNotificationCenterItem'
  body?: Maybe<string>
  created_at: string
  id: string
  link?: Maybe<string>
  navigation?: Maybe<any>
  main_image?: Maybe<string>
  read_at?: Maybe<string>
  secondary_image?: Maybe<string>
  sequential_id: number
  type: string
}

export type CommentForNotificationCenterFragment = {
  __typename?: 'comment'
  user: {
    __typename?: 'user'
    id: string
    username?: Maybe<string>
    picture?: Maybe<string>
  }
  commentable_item?: Maybe<{
    __typename?: 'commentable_item'
    cart_item?: Maybe<{
      __typename?: 'cart_item'
      id: any
      display_title?: Maybe<string>
      item_image_override?: Maybe<string>
      item: { __typename?: 'shop_item'; cached_image?: Maybe<string> }
    }>
  }>
}

export type CartItemForNotificationCenterFragment = {
  __typename?: 'cart_item'
  id: any
  item_image_override?: Maybe<string>
  item_id: any
  display_title?: Maybe<string>
  item: {
    __typename?: 'shop_item'
    cached_image?: Maybe<string>
    image?: Maybe<string>
  }
  originating_cart_item?: Maybe<
    Array<{
      __typename?: 'cart_item'
      id: any
      item_image_override?: Maybe<string>
      item: { __typename?: 'shop_item'; cached_image?: Maybe<string> }
      shop: { __typename?: 'shop'; name: any }
    }>
  >
  originating_collection?: Maybe<
    Array<{
      __typename?: 'collection'
      id: any
      collection_cover_image?: Maybe<string>
      name: any
    }>
  >
}

export type GetNotificationCenterItemsActionQueryVariables = Exact<{
  userId: Scalars['String']
  tasks: Scalars['Boolean']
  read?: Maybe<Scalars['Boolean']>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  customWhereClause?: Maybe<Notification_Center_Item_Bool_Exp>
}>

export type GetNotificationCenterItemsActionQuery = {
  __typename?: 'query_root'
  notification_center_feed_for_user: Array<
    {
      __typename?: 'notification_center_item'
    } & NotificationCenterItemDataFragment
  >
}

export type GetNotificationCenterItemsForUserQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetNotificationCenterItemsForUserQuery = {
  __typename?: 'query_root'
  notification_center_item: Array<
    {
      __typename?: 'WrappedNotificationCenterItem'
    } & WrappedNotificationCenterItemDataFragment
  >
}

export type GetNotificationCenterTasksForUserQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  read?: Maybe<Scalars['Boolean']>
  withTotal?: Maybe<Scalars['Boolean']>
}>

export type GetNotificationCenterTasksForUserQuery = {
  __typename?: 'query_root'
  notification_center_item: Array<
    {
      __typename?: 'WrappedNotificationCenterItem'
    } & WrappedNotificationCenterItemDataFragment
  >
  total_tasks: Maybe<{
    __typename?: 'notification_center_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'notification_center_item_aggregate_fields'
      count: number
    }>
  }>
}

export type SetNotificationCenterItemAsReadMutationVariables = Exact<{
  notification_center_item_id: Scalars['uuid']
}>

export type SetNotificationCenterItemAsReadMutation = {
  __typename?: 'mutation_root'
  insert_notification_center_item_read_status_one?: Maybe<{
    __typename?: 'notification_center_item_read_status'
    created_at: any
  }>
}

export type UpdateNotificationCenterLastViewedSequentialIdMutationVariables =
  Exact<{ [key: string]: never }>

export type UpdateNotificationCenterLastViewedSequentialIdMutation = {
  __typename?: 'mutation_root'
  update_last_seen_notification_center_item_sequential_id: Array<
    { __typename?: 'user_flags' } & UserFlagsFragment
  >
}

export type FindShopItemForPdpQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type FindShopItemForPdpQuery = {
  __typename?: 'query_root'
  shop_item?: Maybe<
    {
      __typename?: 'shop_item'
      shop: { __typename?: 'shop' } & ShopDataFragment
    } & ItemDataFragment
  >
}

export type UserSavedItemQueryVariables = Exact<{
  id: Scalars['uuid']
  userId: Scalars['String']
}>

export type UserSavedItemQuery = {
  __typename?: 'query_root'
  cart_item: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type PushNotificationHistoryForAdminQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type PushNotificationHistoryForAdminQuery = {
  __typename?: 'query_root'
  mobile_push_notification: Array<{
    __typename?: 'mobile_push_notification'
    id: any
    message_payload: any
    created_at: any
    attempts: number
    status: Mobile_Push_Notification_Status_Enum
    user?: Maybe<{
      __typename?: 'user'
      id: string
      name?: Maybe<string>
      picture?: Maybe<string>
    }>
  }>
}

export type GetCartItemSavedDealHopPushNotificationQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetCartItemSavedDealHopPushNotificationQuery = {
  __typename?: 'query_root'
  notification_center_item_by_pk?: Maybe<{
    __typename?: 'notification_center_item'
    id: any
    user?: Maybe<{ __typename?: 'user' } & UserForPushNotificationDataFragment>
    notifiable_item?: Maybe<{
      __typename?: 'notifiable_item'
      cart_item?: Maybe<{
        __typename?: 'cart_item'
        id: any
        display_title?: Maybe<string>
        pricing?: Maybe<any>
        item_image_override?: Maybe<string>
        item_id: any
        item: {
          __typename?: 'shop_item'
          image?: Maybe<string>
          cached_image?: Maybe<string>
          pricing?: Maybe<any>
        }
      }>
    }>
  }>
}

export type GetCartItemSavedForPushNotificationQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetCartItemSavedForPushNotificationQuery = {
  __typename?: 'query_root'
  notification_center_item_by_pk?: Maybe<{
    __typename?: 'notification_center_item'
    id: any
    user?: Maybe<{ __typename?: 'user' } & UserForPushNotificationDataFragment>
    notifiable_item?: Maybe<{
      __typename?: 'notifiable_item'
      cart_item?: Maybe<{
        __typename?: 'cart_item'
        id: any
        item_id: any
        originating_cart_item?: Maybe<
          Array<{
            __typename?: 'cart_item'
            id: any
            shop: { __typename?: 'shop'; name: any; key: string }
          }>
        >
        originating_collection?: Maybe<
          Array<{ __typename?: 'collection'; id: any; name: any; slug: any }>
        >
      }>
    }>
  }>
}

export type GetCollabCollectionItemAddedForPushNotificationQueryVariables =
  Exact<{
    id: Scalars['uuid']
  }>

export type GetCollabCollectionItemAddedForPushNotificationQuery = {
  __typename?: 'query_root'
  notification_center_item_by_pk?: Maybe<{
    __typename?: 'notification_center_item'
    id: any
    user?: Maybe<{ __typename?: 'user' } & UserForPushNotificationDataFragment>
    notifiable_item?: Maybe<{
      __typename?: 'notifiable_item'
      collection_cart_item?: Maybe<{
        __typename?: 'collection_cart_item'
        collection: {
          __typename?: 'collection'
          id: any
          name: any
          user?: Maybe<{ __typename?: 'user'; id: string }>
        }
        cart_item: {
          __typename?: 'cart_item'
          item_image_override?: Maybe<string>
          cart: {
            __typename?: 'cart'
            user: { __typename?: 'user'; username?: Maybe<string> }
          }
        }
      }>
    }>
  }>
}

export type GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQueryVariables =
  Exact<{
    id: Scalars['uuid']
  }>

export type GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQuery =
  {
    __typename?: 'query_root'
    notification_center_item_by_pk?: Maybe<{
      __typename?: 'notification_center_item'
      id: any
      user?: Maybe<
        { __typename?: 'user' } & UserForPushNotificationDataFragment
      >
      notifiable_item?: Maybe<{
        __typename?: 'notifiable_item'
        collection_invite?: Maybe<{
          __typename?: 'collection_invite'
          collection: {
            __typename?: 'collection'
            id: any
            name: any
            user?: Maybe<{ __typename?: 'user'; id: string }>
          }
          invitee?: Maybe<{ __typename?: 'user'; username?: Maybe<string> }>
        }>
      }>
    }>
  }

export type GetNewFollowerForPushNotificationQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetNewFollowerForPushNotificationQuery = {
  __typename?: 'query_root'
  notification_center_item_by_pk?: Maybe<{
    __typename?: 'notification_center_item'
    id: any
    user?: Maybe<{ __typename?: 'user' } & UserForPushNotificationDataFragment>
    notifiable_item?: Maybe<{
      __typename?: 'notifiable_item'
      follow?: Maybe<{
        __typename?: 'user_items_followed'
        user: { __typename?: 'user'; username?: Maybe<string> }
        followable_item?: Maybe<{
          __typename?: 'followable_item'
          collection?: Maybe<{
            __typename?: 'collection'
            id: any
            name: any
            user?: Maybe<{ __typename?: 'user'; id: string }>
          }>
        }>
      }>
    }>
  }>
}

export type GetUserCommentedForPushNotificationQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetUserCommentedForPushNotificationQuery = {
  __typename?: 'query_root'
  notification_center_item_by_pk?: Maybe<{
    __typename?: 'notification_center_item'
    id: any
    user?: Maybe<{ __typename?: 'user' } & UserForPushNotificationDataFragment>
    notifiable_item?: Maybe<{
      __typename?: 'notifiable_item'
      comment?: Maybe<{
        __typename?: 'comment'
        commentable_item_type: Commentable_Item_Type_Enum
        commentable_item_id: any
        user: { __typename?: 'user'; username?: Maybe<string> }
        commentable_item?: Maybe<{
          __typename?: 'commentable_item'
          cart_item?: Maybe<{
            __typename?: 'cart_item'
            display_title?: Maybe<string>
            cart: { __typename?: 'cart'; user_id: string }
          }>
          collection?: Maybe<{
            __typename?: 'collection'
            name: any
            user_id?: Maybe<string>
          }>
        }>
      }>
    }>
  }>
}

export type RegisterUserMutationVariables = Exact<{
  short_id: Scalars['name']
  email?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  installed_at?: Maybe<Scalars['timestamptz']>
  anonymous?: Maybe<Scalars['Boolean']>
  referrer_user_id?: Maybe<Scalars['String']>
  app_version?: Maybe<User_App_Version_Arr_Rel_Insert_Input>
  metadata?: Maybe<Scalars['jsonb']>
  with_extension_version?: Maybe<Scalars['Boolean']>
  with_ios_version?: Maybe<Scalars['Boolean']>
  with_safari_extension_version?: Maybe<Scalars['Boolean']>
}>

export type RegisterUserMutation = {
  __typename?: 'mutation_root'
  user?: Maybe<{ __typename?: 'user' } & UserDataFragment>
}

export type RemoveCollectionMutationVariables = Exact<{
  collection_id: Scalars['uuid']
}>

export type RemoveCollectionMutation = {
  __typename?: 'mutation_root'
  collection?: Maybe<{ __typename?: 'collection'; id: any }>
}

export type SaveCartMutationVariables = Exact<{
  shop_data: Shop_Insert_Input
  cart_items_data: Array<Cart_Item_Insert_Input> | Cart_Item_Insert_Input
}>

export type SaveCartMutation = {
  __typename?: 'mutation_root'
  cart?: Maybe<
    { __typename?: 'cart' } & CartDataFragment & CartItemsDataFragment
  >
}

export type GetCartItemsByItemIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String']
  shopKey?: Maybe<Scalars['String']>
  userId: Scalars['String']
}>

export type GetCartItemsByItemIdsQuery = {
  __typename?: 'query_root'
  cart_item: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type SaveCartItemFromMobileMutationVariables = Exact<{
  user_id: Scalars['String']
  shop_key: Scalars['String']
  shop_data: Shop_Insert_Input
  shop_item_data: Shop_Item_Insert_Input
  source: Scalars['String']
}>

export type SaveCartItemFromMobileMutation = {
  __typename?: 'mutation_root'
  cart_item?: Maybe<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type SaveShopMutationVariables = Exact<{
  shop_object: Shop_Insert_Input
}>

export type SaveShopMutation = {
  __typename?: 'mutation_root'
  shop?: Maybe<{ __typename?: 'shop' } & ShopDataFragment>
}

export type SaveShopCategorizationMutationVariables = Exact<{
  category: Shop_Category_Enum
  shop_key: Scalars['String']
  weight: Scalars['Int']
  curation_score?: Maybe<Scalars['Int']>
}>

export type SaveShopCategorizationMutation = {
  __typename?: 'mutation_root'
  insert_shop_categorization_one?: Maybe<{
    __typename?: 'shop_categorization'
    id: any
    shop_key: string
    category: Shop_Category_Enum
    weight: number
    curation_score: number
  }>
}

export type SaveUserOnboardingSourceMutationVariables = Exact<{
  source: Onboarding_Sources_Enum
  other_source?: Maybe<Scalars['String']>
}>

export type SaveUserOnboardingSourceMutation = {
  __typename?: 'mutation_root'
  insert_user_onboarding_source_one?: Maybe<{
    __typename?: 'user_onboarding_source'
    id: any
    onboarding_source: Onboarding_Sources_Enum
    other_source?: Maybe<string>
  }>
}

export type SearchItemDataFragment = {
  __typename?: 'shop_item'
  id: any
  item_id: string
  shop_key: string
  display_title?: Maybe<string>
  url?: Maybe<string>
  image?: Maybe<string>
  image_highres?: Maybe<string>
  cached_image?: Maybe<string>
  cached_placeholder_image?: Maybe<string>
  saved_by_current_user?: Maybe<boolean>
  image_metadata?: Maybe<any>
  is_inappropriate: boolean
  pricing?: Maybe<any>
  parsed_pricing?: Maybe<any>
  metadata?: Maybe<any>
  created_at: any
  updated_at: any
  shop: {
    __typename?: 'shop'
    id: any
    key: string
    cart_path?: Maybe<string>
    type?: Maybe<any>
    platform?: Maybe<string>
    name: any
    display_name?: Maybe<string>
    cover_image?: Maybe<string>
    slug: any
    favicon?: Maybe<string>
    created_at?: Maybe<any>
    updated_at?: Maybe<any>
    has_pdp?: Maybe<boolean>
  }
}

export type GetCollectionsFromSearchQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid']
  collectionItemsCount?: Maybe<Scalars['Int']>
}>

export type GetCollectionsFromSearchQuery = {
  __typename?: 'query_root'
  collection: Array<
    {
      __typename?: 'collection'
      state?: Maybe<Collection_State_Enum>
      private: boolean
    } & DiscoveryCollectionFragment
  >
}

export type GetShopItemsBySearchQueryVariables = Exact<{
  query: Scalars['String']
  pagination?: Maybe<Scalars['String']>
}>

export type GetShopItemsBySearchQuery = {
  __typename?: 'query_root'
  searchProducts: {
    __typename?: 'SearchProductsOutput'
    id?: Maybe<any>
    pagination?: Maybe<string>
  }
}

export type GetShopItemsBySearchEngineSearchQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid']
}>

export type GetShopItemsBySearchEngineSearchQuery = {
  __typename?: 'query_root'
  shop_item: Array<{ __typename?: 'shop_item' } & SearchItemDataFragment>
}

export type NotificationStatusClickedMutationVariables = Exact<{
  notification_id: Scalars['uuid']
}>

export type NotificationStatusClickedMutation = {
  __typename?: 'mutation_root'
  notificationStatus?: Maybe<{
    __typename?: 'push_notification_status'
    id: any
    clicked: boolean
  }>
}

export type UpdateShopItemDataMutationVariables = Exact<{
  input: Shop_Item_Set_Input
  id: Scalars['uuid']
}>

export type UpdateShopItemDataMutation = {
  __typename?: 'mutation_root'
  update_shop_item_by_pk?: Maybe<
    { __typename?: 'shop_item' } & ItemDataFragment
  >
}

export type GetShopsListQueryVariables = Exact<{
  shops_limit?: Maybe<Scalars['Int']>
  shops_offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<Shop_Order_By> | Shop_Order_By>
  cartItemsClause?: Numeric_Comparison_Exp
  categoryClause?: Shop_Category_Enum_Comparison_Exp
  nameQuery?: String_Comparison_Exp
}>

export type GetShopsListQuery = {
  __typename?: 'query_root'
  shops: Array<{
    __typename?: 'shop'
    id: any
    key: string
    slug: any
    display_name?: Maybe<string>
    favicon?: Maybe<string>
    cover_image?: Maybe<string>
    cart_items_count?: Maybe<any>
  }>
  total_results: {
    __typename?: 'shop_aggregate'
    aggregate?: Maybe<{ __typename?: 'shop_aggregate_fields'; count: number }>
  }
}

export type InsertSimilarProductsHistoryMutationVariables = Exact<{
  input: Similar_Products_History_Insert_Input
}>

export type InsertSimilarProductsHistoryMutation = {
  __typename?: 'mutation_root'
  insert_similar_products_history_one?: Maybe<{
    __typename?: 'similar_products_history'
    id: any
  }>
}

export type SubProductClaimsForCollectionSubscriptionVariables = Exact<{
  id: Scalars['uuid']
}>

export type SubProductClaimsForCollectionSubscription = {
  __typename?: 'subscription_root'
  collection_item_claim: Array<{
    __typename?: 'collection_item_claim'
    id: any
    status: Collection_Item_Claim_Status_Enum
    cart_item_id: any
    user: { __typename?: 'user'; id: string; name?: Maybe<string> }
  }>
}

export type SubsUserCartsOnboardingSubscriptionVariables = Exact<{
  user_id: Scalars['String']
  last_cart_onboarding: Scalars['timestamptz']
}>

export type SubsUserCartsOnboardingSubscription = {
  __typename?: 'subscription_root'
  user_carts_onboarding: Array<{
    __typename?: 'user_carts_onboarding'
    id: any
    processing: boolean
    processed_at?: Maybe<any>
    shop: {
      __typename?: 'shop'
      id: any
      key: string
      name: any
      favicon?: Maybe<string>
      cart_items: Array<{
        __typename?: 'cart_item'
        id: any
        item: {
          __typename?: 'shop_item'
          display_title?: Maybe<string>
          url?: Maybe<string>
          image?: Maybe<string>
        }
      }>
    }
  }>
}

export type SubsUserCartsSinceLastOpenedSubscriptionVariables = Exact<{
  lastViewed: Scalars['timestamptz']
  id: Scalars['String']
}>

export type SubsUserCartsSinceLastOpenedSubscription = {
  __typename?: 'subscription_root'
  cart_item_aggregate: {
    __typename?: 'cart_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'cart_item_aggregate_fields'
      sum?: Maybe<{
        __typename?: 'cart_item_sum_fields'
        quantity?: Maybe<any>
      }>
    }>
  }
}

export type SubsUserFlagsSubscriptionVariables = Exact<{
  id: Scalars['String']
}>

export type SubsUserFlagsSubscription = {
  __typename?: 'subscription_root'
  user_flags_by_pk?: Maybe<{ __typename?: 'user_flags' } & UserFlagsFragment>
}

export type ToggleCollectionGiftlistMutationVariables = Exact<{
  collection_id: Scalars['uuid']
  giftlist: Scalars['Boolean']
}>

export type ToggleCollectionGiftlistMutation = {
  __typename?: 'mutation_root'
  collection?: Maybe<{ __typename?: 'collection'; id: any; giftlist: boolean }>
}

export type ToggleCollectionPrivacyMutationVariables = Exact<{
  collection_id: Scalars['uuid']
  private: Scalars['Boolean']
}>

export type ToggleCollectionPrivacyMutation = {
  __typename?: 'mutation_root'
  collection?: Maybe<{ __typename?: 'collection'; id: any; private: boolean }>
}

export type ToggleCollectionTypeMutationVariables = Exact<{
  id: Scalars['uuid']
  type?: Maybe<Collection_Type_Enum>
}>

export type ToggleCollectionTypeMutation = {
  __typename?: 'mutation_root'
  update_collection_by_pk?: Maybe<{
    __typename?: 'collection'
    id: any
    collection_type: Collection_Type_Enum
  }>
}

export type UnarchiveCartItemsMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type UnarchiveCartItemsMutation = {
  __typename?: 'mutation_root'
  cartItem?: Maybe<{
    __typename?: 'cart_item_mutation_response'
    returning: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
  }>
}

export type UnclaimCollectionCartItemMutationVariables = Exact<{
  claim_id: Scalars['uuid']
}>

export type UnclaimCollectionCartItemMutation = {
  __typename?: 'mutation_root'
  delete_collection_item_claim_by_pk?: Maybe<{
    __typename?: 'collection_item_claim'
    id: any
  }>
}

export type AdminUpdateShopMutationVariables = Exact<{
  shopKey: Scalars['String']
  name_override?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']> | Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  banner_image?: Maybe<Scalars['String']>
  editorial_shop_logo?: Maybe<Scalars['String']>
  shop_faqs?: Maybe<Scalars['jsonb']>
  featured?: Maybe<Scalars['Boolean']>
  points?: Maybe<Scalars['Int']>
}>

export type AdminUpdateShopMutation = {
  __typename?: 'mutation_root'
  update_shop_by_pk?: Maybe<
    { __typename?: 'shop' } & AdminCategorizationShopDataFragment
  >
}

export type UpdateAffiliateRedirectOverrideLinkMutationVariables = Exact<{
  id: Scalars['uuid']
  link?: Maybe<Scalars['String']>
}>

export type UpdateAffiliateRedirectOverrideLinkMutation = {
  __typename?: 'mutation_root'
  update_cart_item_by_pk?: Maybe<
    { __typename?: 'cart_item' } & CartItemDataFragment
  >
}

export type UpdateAppendMetadataShopMutationVariables = Exact<{
  key: Scalars['String']
  data: Scalars['jsonb']
}>

export type UpdateAppendMetadataShopMutation = {
  __typename?: 'mutation_root'
  update_shop?: Maybe<{
    __typename?: 'shop_mutation_response'
    affected_rows: number
    returning: Array<{ __typename?: 'shop'; metadata: any }>
  }>
}

export type UpdateAppendSchemaMetadataShopItemMutationVariables = Exact<{
  id: Scalars['uuid']
  data: Scalars['jsonb']
}>

export type UpdateAppendSchemaMetadataShopItemMutation = {
  __typename?: 'mutation_root'
  update_shop_item_by_pk?: Maybe<{
    __typename?: 'shop_item'
    schema_metadata?: Maybe<any>
  }>
}

export type UpdateBookmarkWorkflowMutationVariables = Exact<{
  id: Scalars['uuid']
  status: Workflow_Status_Enum
  statusDetails?: Maybe<Scalars['String']>
  successItemId?: Maybe<Scalars['uuid']>
  successItemType?: Maybe<Scalars['String']>
}>

export type UpdateBookmarkWorkflowMutation = {
  __typename?: 'mutation_root'
  update_workflow_by_pk?: Maybe<{
    __typename?: 'workflow'
    id: any
    status: Workflow_Status_Enum
    status_details?: Maybe<string>
  }>
}

export type SetCartItemHiddenMutationVariables = Exact<{
  id: Scalars['uuid']
  hidden: Scalars['Boolean']
}>

export type SetCartItemHiddenMutation = {
  __typename?: 'mutation_root'
  cartItem?: Maybe<{
    __typename?: 'cart_item_mutation_response'
    returning: Array<{ __typename?: 'cart_item'; id: any; hidden: boolean }>
  }>
}

export type SetCartItemRemindMeAtMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type SetCartItemRemindMeAtMutation = {
  __typename?: 'mutation_root'
  cartItem?: Maybe<{
    __typename?: 'cart_item_mutation_response'
    returning: Array<{ __typename?: 'cart_item'; id: any }>
  }>
}

export type UpdateCollectionMetadataMutationVariables = Exact<{
  id: Scalars['uuid']
  data?: Maybe<Scalars['jsonb']>
}>

export type UpdateCollectionMetadataMutation = {
  __typename?: 'mutation_root'
  update_collection_by_pk?: Maybe<{
    __typename?: 'collection'
    id: any
    metadata?: Maybe<any>
  }>
}

export type UpdateDiscoveryFeedBacklogMutationVariables = Exact<{
  feed_item_id: Scalars['uuid']
  metadata?: Scalars['jsonb']
}>

export type UpdateDiscoveryFeedBacklogMutation = {
  __typename?: 'mutation_root'
  update_feed_item_by_pk?: Maybe<
    { __typename?: 'feed_item' } & FeedItemDataFragment
  >
}

export type UpdateDiscoveryFeedSequentialIdMutationVariables = Exact<{
  userId: Scalars['String']
  newSequentialId: Scalars['Int']
}>

export type UpdateDiscoveryFeedSequentialIdMutation = {
  __typename?: 'mutation_root'
  update_user_flags_by_pk?: Maybe<
    { __typename?: 'user_flags' } & UserFlagsFragment
  >
}

export type UpdateHighResImageMutationVariables = Exact<{
  id: Scalars['uuid']
  highResImage: Scalars['String']
}>

export type UpdateHighResImageMutation = {
  __typename?: 'mutation_root'
  update_shop_item_by_pk?: Maybe<
    { __typename?: 'shop_item' } & ItemDataFragment
  >
}

export type UpdateItemsToArchiveMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type UpdateItemsToArchiveMutation = {
  __typename?: 'mutation_root'
  update_cart_item?: Maybe<{
    __typename?: 'cart_item_mutation_response'
    returning: Array<{
      __typename?: 'cart_item'
      id: any
      archived?: Maybe<boolean>
      archived_at?: Maybe<any>
    }>
  }>
}

export type UpdateItemsToUnarchiveMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type UpdateItemsToUnarchiveMutation = {
  __typename?: 'mutation_root'
  update_cart_item?: Maybe<{
    __typename?: 'cart_item_mutation_response'
    returning: Array<{
      __typename?: 'cart_item'
      id: any
      archived?: Maybe<boolean>
      archived_at?: Maybe<any>
    }>
  }>
}

export type UpdateShopFaviconMutationVariables = Exact<{
  shopKey: Scalars['String']
  favicon: Scalars['String']
}>

export type UpdateShopFaviconMutation = {
  __typename?: 'mutation_root'
  update_shop_by_pk?: Maybe<{ __typename?: 'shop' } & ShopDataFragment>
}

export type UpdateShopItemMetadataMutationVariables = Exact<{
  id: Scalars['uuid']
  metadata: Scalars['jsonb']
}>

export type UpdateShopItemMetadataMutation = {
  __typename?: 'mutation_root'
  update_shop_item_by_pk?: Maybe<
    { __typename?: 'shop_item' } & ItemDataFragment
  >
}

export type UpdateUserAppVersionMutationVariables = Exact<{
  app_version: User_App_Version_Arr_Rel_Insert_Input
  with_extension_version?: Maybe<Scalars['Boolean']>
  with_ios_version?: Maybe<Scalars['Boolean']>
  with_safari_extension_version?: Maybe<Scalars['Boolean']>
}>

export type UpdateUserAppVersionMutation = {
  __typename?: 'mutation_root'
  insert_user_one?: Maybe<{ __typename?: 'user' } & UserDataFragment>
}

export type UpdateUserDetailsMutationVariables = Exact<{
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  anonymous: Scalars['Boolean']
}>

export type UpdateUserDetailsMutation = {
  __typename?: 'mutation_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    name?: Maybe<string>
    email?: Maybe<string>
    picture?: Maybe<string>
    anonymous?: Maybe<boolean>
  }>
}

export type UpdateUserEmailMutationVariables = Exact<{
  id: Scalars['String']
  email: Scalars['String']
}>

export type UpdateUserEmailMutation = {
  __typename?: 'mutation_root'
  user?: Maybe<{ __typename?: 'user'; id: string; email?: Maybe<string> }>
}

export type UpdateUserInstalledAtMutationVariables = Exact<{
  id: Scalars['String']
}>

export type UpdateUserInstalledAtMutation = {
  __typename?: 'mutation_root'
  user?: Maybe<{ __typename?: 'user'; id: string; installed_at?: Maybe<any> }>
}

export type UpdateUserMetadataMutationVariables = Exact<{
  id: Scalars['String']
  data?: Maybe<Scalars['jsonb']>
}>

export type UpdateUserMetadataMutation = {
  __typename?: 'mutation_root'
  update_user_by_pk?: Maybe<{
    __typename?: 'user'
    id: string
    metadata?: Maybe<any>
  }>
}

export type UpdateUserNameMutationVariables = Exact<{
  id: Scalars['String']
  name: Scalars['String']
}>

export type UpdateUserNameMutation = {
  __typename?: 'mutation_root'
  user?: Maybe<{ __typename?: 'user'; id: string; name?: Maybe<string> }>
}

export type UpdateUserNameAndUsernameMutationVariables = Exact<{
  id: Scalars['String']
  username: Scalars['String']
  name?: Maybe<Scalars['String']>
}>

export type UpdateUserNameAndUsernameMutation = {
  __typename?: 'mutation_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    username?: Maybe<string>
    name?: Maybe<string>
  }>
}

export type UpdateUserPossibleEmailMutationVariables = Exact<{
  id: Scalars['String']
  email: Scalars['String']
}>

export type UpdateUserPossibleEmailMutation = {
  __typename?: 'mutation_root'
  user?: Maybe<{ __typename?: 'user'; id: string; email?: Maybe<string> }>
}

export type UpdateUserUsernameMutationVariables = Exact<{
  id: Scalars['String']
  username: Scalars['String']
}>

export type UpdateUserUsernameMutation = {
  __typename?: 'mutation_root'
  user?: Maybe<{ __typename?: 'user'; id: string; username?: Maybe<string> }>
}

export type UpsertPendingCollectionInviteForPublicCollectionMutationVariables =
  Exact<{
    collection_id: Scalars['uuid']
    user_id: Scalars['String']
  }>

export type UpsertPendingCollectionInviteForPublicCollectionMutation = {
  __typename?: 'mutation_root'
  insert_collection_invite_one?: Maybe<{
    __typename?: 'collection_invite'
    id: any
    status: Collection_Invite_Status_Enum
  }>
}

export type UpsertUserAppVersionMutationVariables = Exact<{
  input: Array<User_App_Version_Insert_Input> | User_App_Version_Insert_Input
}>

export type UpsertUserAppVersionMutation = {
  __typename?: 'mutation_root'
  insert_user_app_version?: Maybe<{
    __typename?: 'user_app_version_mutation_response'
    affected_rows: number
  }>
}

export type UpsertUserPushNotificationTokenMutationVariables = Exact<{
  objects:
    | Array<User_Push_Notification_Token_Insert_Input>
    | User_Push_Notification_Token_Insert_Input
}>

export type UpsertUserPushNotificationTokenMutation = {
  __typename?: 'mutation_root'
  insert_user_push_notification_token?: Maybe<{
    __typename?: 'user_push_notification_token_mutation_response'
    affected_rows: number
  }>
}

export type DeletedAccountMetadataQueryVariables = Exact<{
  id: Scalars['String']
}>

export type DeletedAccountMetadataQuery = {
  __typename?: 'query_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    created_at?: Maybe<any>
    updated_at?: Maybe<any>
    deleted_at?: Maybe<any>
    active_cart_items_quantity?: Maybe<any>
    first_app_install?: Maybe<string>
    carts_aggregate: {
      __typename?: 'cart_aggregate'
      aggregate?: Maybe<{ __typename?: 'cart_aggregate_fields'; count: number }>
    }
    collections_aggregate: {
      __typename?: 'collection_aggregate'
      aggregate?: Maybe<{
        __typename?: 'collection_aggregate_fields'
        count: number
      }>
    }
    app_versions: Array<{
      __typename?: 'user_app_version'
      created_at: any
      name: string
      platform: string
      version: string
    }>
  }>
}

export type UserDataForProfileScreenFragment = {
  __typename?: 'user'
  bio?: Maybe<string>
  bio_link?: Maybe<string>
  links: Array<{
    __typename?: 'user_links'
    id: any
    url: string
    link_type?: Maybe<Link_Type_Enum>
  }>
} & BasicUserDataFragment

export type GetCollectionsDataForMobileUserProfilePageQueryVariables = Exact<{
  userId: Scalars['String']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  includePaidCollections?: Maybe<Scalars['Boolean']>
}>

export type GetCollectionsDataForMobileUserProfilePageQuery = {
  __typename?: 'query_root'
  collections: Array<{
    __typename?: 'collection'
    id: any
    slug: any
    name: any
    state?: Maybe<Collection_State_Enum>
    computed_is_visible?: Maybe<boolean>
    description?: Maybe<string>
    cover_image?: Maybe<string>
    cover_image_cached?: Maybe<string>
    total_likes_count?: Maybe<any>
    has_collaborators: boolean
    cart_items_count?: Maybe<any>
    user?: Maybe<{ __typename?: 'user' } & BasicUserDataFragment>
    collection_collaborators: Array<{
      __typename?: 'collection_collaborator'
      id: any
      user: { __typename?: 'user' } & BasicUserDataFragment
    }>
    preview_collection_items?: Maybe<
      Array<{
        __typename?: 'collection_cart_item'
        id: any
        cart_item: {
          __typename?: 'cart_item'
          id: any
          item_image_override?: Maybe<string>
          item: { __typename?: 'shop_item' } & ItemDataFragment
        }
      }>
    >
    feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
  }>
}

export type GetDataForMobileProfilePageQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetDataForMobileProfilePageQuery = {
  __typename?: 'query_root'
  user?: Maybe<{ __typename?: 'user' } & UserDataForProfileScreenFragment>
}

export type GetUserDataForMobileUserProfilePageQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type GetUserDataForMobileUserProfilePageQuery = {
  __typename?: 'query_root'
  user?: Maybe<{ __typename?: 'user' } & UserDataForProfileScreenFragment>
}

export type ProcessAccountDeletionMutationVariables = Exact<{
  id: Scalars['String']
}>

export type ProcessAccountDeletionMutation = {
  __typename?: 'mutation_root'
  update_user_by_pk?: Maybe<{ __typename?: 'user'; id: string }>
}

export type SaveUserProfileMutationVariables = Exact<{
  id: Scalars['String']
  user_set_input: User_Set_Input
}>

export type SaveUserProfileMutation = {
  __typename?: 'mutation_root'
  update_user_by_pk?: Maybe<
    {
      __typename?: 'user'
      bio?: Maybe<string>
      bio_link?: Maybe<string>
      has_claimed_username_computed?: Maybe<boolean>
      has_set_name_computed?: Maybe<boolean>
      has_picture_set?: Maybe<boolean>
    } & BasicUserDataFragment
  >
}

export type SaveUserProfileLinksMutationVariables = Exact<{
  link_objects: Array<User_Links_Insert_Input> | User_Links_Insert_Input
}>

export type SaveUserProfileLinksMutation = {
  __typename?: 'mutation_root'
  insert_user_links?: Maybe<{
    __typename?: 'user_links_mutation_response'
    affected_rows: number
  }>
}

export type SubsUnviewedNotificationCenterItemsSubscriptionVariables = Exact<{
  id: Scalars['String']
}>

export type SubsUnviewedNotificationCenterItemsSubscription = {
  __typename?: 'subscription_root'
  user_by_pk?: Maybe<{
    __typename?: 'user'
    unviewed_notification_center_items?: Maybe<number>
    mobile_badge_count?: Maybe<number>
  }>
}

export type UpdateUserIosDeviceVersionMutationVariables = Exact<{
  id: Scalars['String']
  ios_device_version: Scalars['String']
}>

export type UpdateUserIosDeviceVersionMutation = {
  __typename?: 'mutation_root'
  update_user_flags_by_pk?: Maybe<
    { __typename?: 'user_flags' } & UserFlagsFragment
  >
}

export type UserUpdateOnboardingStateMutationVariables = Exact<{
  id: Scalars['String']
  state: User_Onboarding_State_Enum
}>

export type UserUpdateOnboardingStateMutation = {
  __typename?: 'mutation_root'
  update_user_flags_by_pk?: Maybe<
    { __typename?: 'user_flags' } & UserFlagsFragment
  >
}

export type FindShopCategoryQueryVariables = Exact<{
  category: Scalars['String']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type FindShopCategoryQuery = {
  __typename?: 'query_root'
  shop_category?: Maybe<{
    __typename?: 'shop_category'
    label?: Maybe<string>
    value: string
    featured_shop_categorizations: Array<{
      __typename?: 'shop_categorization'
      weight: number
      curation_score: number
      shop: { __typename?: 'shop' } & ShopDataFragment
    }>
    shop_categorizations: Array<{
      __typename?: 'shop_categorization'
      weight: number
      curation_score: number
      shop: { __typename?: 'shop' } & ShopDataFragment
    }>
  }>
}

export type GetCartItemsForShopQueryVariables = Exact<{
  key: Scalars['String']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetCartItemsForShopQuery = {
  __typename?: 'query_root'
  cart_item_aggregate: {
    __typename?: 'cart_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'cart_item_aggregate_fields'
      count: number
    }>
  }
  cart_item: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type GetCollectionsForItemQueryVariables = Exact<{
  itemId: Scalars['uuid']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  itemLimit?: Maybe<Scalars['Int']>
}>

export type GetCollectionsForItemQuery = {
  __typename?: 'query_root'
  collection_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  collection: Array<{
    __typename?: 'collection'
    id: any
    slug: any
    name: any
    cart_items_count?: Maybe<any>
    user?: Maybe<{
      __typename?: 'user'
      id: string
      username?: Maybe<string>
      picture?: Maybe<string>
      created_at?: Maybe<any>
    }>
    collection_items: Array<{
      __typename?: 'collection_cart_item'
      id: any
      cart_item: {
        __typename?: 'cart_item'
        id: any
        item_image_override?: Maybe<string>
        item: {
          __typename?: 'shop_item'
          id: any
          image?: Maybe<string>
          image_highres?: Maybe<string>
          cached_image?: Maybe<string>
        }
      }
    }>
  }>
}

export type GetShopCategoriesForStaticPathsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetShopCategoriesForStaticPathsQuery = {
  __typename?: 'query_root'
  shop_categories: Array<{
    __typename?: 'shop_category'
    label?: Maybe<string>
    value: string
  }>
}

export type GetShopItemsForShopQueryVariables = Exact<{
  slug: Scalars['name']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetShopItemsForShopQuery = {
  __typename?: 'query_root'
  shop_item_aggregate: {
    __typename?: 'shop_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'shop_item_aggregate_fields'
      count: number
    }>
  }
  shop_item: Array<
    {
      __typename?: 'shop_item'
      has_broken_image?: Maybe<boolean>
    } & ItemDataFragment
  >
}

export type GetUserArchivedCartItemsQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserArchivedCartItemsQuery = {
  __typename?: 'query_root'
  cart_items: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type FetchSimilarProductsExtensionQueryVariables = Exact<{
  imageUrl: Scalars['String']
  shopItemId?: Maybe<Scalars['uuid']>
  cartItemId?: Maybe<Scalars['uuid']>
  allResults?: Maybe<Scalars['Boolean']>
  noCache?: Maybe<Scalars['Boolean']>
}>

export type FetchSimilarProductsExtensionQuery = {
  __typename?: 'query_root'
  similarProducts: Array<{
    __typename?: 'SimilarProductOutput'
    position?: Maybe<number>
    title?: Maybe<string>
    link?: Maybe<string>
    source?: Maybe<string>
    source_icon?: Maybe<string>
    shop_popularity?: Maybe<number>
    thumbnail?: Maybe<string>
    points?: Maybe<number>
    price?: Maybe<{
      __typename?: 'SimilarProductPrice'
      value?: Maybe<string>
      extracted_value?: Maybe<number>
      currency?: Maybe<string>
    }>
  }>
}

export type FindCheckoutByOrderIdQueryVariables = Exact<{
  order_id: Scalars['String']
}>

export type FindCheckoutByOrderIdQuery = {
  __typename?: 'query_root'
  checkout: Array<{ __typename?: 'checkout'; id: any; order_id: string }>
}

export type FindShopByKeyForAffiliateQueryVariables = Exact<{
  key: Scalars['String']
}>

export type FindShopByKeyForAffiliateQuery = {
  __typename?: 'query_root'
  shop: Array<{
    __typename?: 'shop'
    id: any
    metadata: any
    parsed_take_rate?: Maybe<any>
  }>
}

export type SaveAutoCashbackOptionsMutationVariables = Exact<{
  userId: Scalars['String']
  autoCashbackEnabled?: Maybe<Scalars['Boolean']>
}>

export type SaveAutoCashbackOptionsMutation = {
  __typename?: 'mutation_root'
  update_user_flags_by_pk?: Maybe<{
    __typename?: 'user_flags'
    user_id: string
    auto_cashback_enabled?: Maybe<boolean>
  }>
}

export type SaveCheckoutDataMutationVariables = Exact<{
  input: Checkout_Insert_Input
}>

export type SaveCheckoutDataMutation = {
  __typename?: 'mutation_root'
  insert_checkout_one?: Maybe<{
    __typename?: 'checkout'
    id: any
    order_id: string
  }>
}

export type UpdateCartItemsCheckoutIdMutationVariables = Exact<{
  checkoutId: Scalars['uuid']
  userId: Scalars['String']
  shopKey: Scalars['String']
  itemIds: Array<Scalars['String']> | Scalars['String']
}>

export type UpdateCartItemsCheckoutIdMutation = {
  __typename?: 'mutation_root'
  update_cart_item?: Maybe<{
    __typename?: 'cart_item_mutation_response'
    affected_rows: number
  }>
}

export type GetShopCartIdQueryVariables = Exact<{
  shopKey: Scalars['String']
  userId: Scalars['String']
}>

export type GetShopCartIdQuery = {
  __typename?: 'query_root'
  cart: Array<{ __typename?: 'cart'; id: any; shop_key?: Maybe<string> }>
}

export type SaveCartShopMutationVariables = Exact<{
  cartData: Array<Cart_Insert_Input> | Cart_Insert_Input
}>

export type SaveCartShopMutation = {
  __typename?: 'mutation_root'
  cart?: Maybe<{
    __typename?: 'cart_mutation_response'
    returning: Array<{ __typename?: 'cart'; id: any; shop_key?: Maybe<string> }>
  }>
}

export type BatchUpsertUserCartsOnboardingMutationVariables = Exact<{
  shops:
    | Array<User_Carts_Onboarding_Insert_Input>
    | User_Carts_Onboarding_Insert_Input
  last_cart_onboarding: Scalars['timestamptz']
}>

export type BatchUpsertUserCartsOnboardingMutation = {
  __typename?: 'mutation_root'
  insert_user_carts_onboarding?: Maybe<{
    __typename?: 'user_carts_onboarding_mutation_response'
    returning: Array<{
      __typename?: 'user_carts_onboarding'
      id: any
      processed_at?: Maybe<any>
    }>
  }>
}

export type CheckedUserHistoryMutationVariables = Exact<{
  id: Scalars['String']
}>

export type CheckedUserHistoryMutation = {
  __typename?: 'mutation_root'
  update_user_flags_by_pk?: Maybe<
    { __typename?: 'user_flags' } & UserFlagsFragment
  >
}

export type GetShopsForOnboardingQueryVariables = Exact<{
  shopKeys: Array<Scalars['String']> | Scalars['String']
}>

export type GetShopsForOnboardingQuery = {
  __typename?: 'query_root'
  shops: Array<{ __typename?: 'shop'; key: string }>
}

export type SubsForNextUnprocessedShopsSubscriptionVariables = Exact<{
  [key: string]: never
}>

export type SubsForNextUnprocessedShopsSubscription = {
  __typename?: 'subscription_root'
  nextUserCartToOnboard: Array<{
    __typename?: 'user_carts_onboarding'
    id: any
    shop_key: string
  }>
}

export type UpdateUserCartOnboardingMutationVariables = Exact<{
  id: Scalars['uuid']
  processing: Scalars['Boolean']
  processedAt: Scalars['timestamptz']
}>

export type UpdateUserCartOnboardingMutation = {
  __typename?: 'mutation_root'
  update_user_carts_onboarding_by_pk?: Maybe<{
    __typename?: 'user_carts_onboarding'
    id: any
    processing: boolean
    processed_at?: Maybe<any>
  }>
}

export type UserCartOnboardingOptInMutationVariables = Exact<{
  id: Scalars['String']
  checked_history: Scalars['Boolean']
}>

export type UserCartOnboardingOptInMutation = {
  __typename?: 'mutation_root'
  update_user_flags_by_pk?: Maybe<
    { __typename?: 'user_flags' } & UserFlagsFragment
  >
}

export type SubscribeToNewDiscoveryFeedItemsCountSubscriptionVariables = Exact<{
  userId: Scalars['String']
}>

export type SubscribeToNewDiscoveryFeedItemsCountSubscription = {
  __typename?: 'subscription_root'
  user_by_pk?: Maybe<{
    __typename?: 'user'
    new_discovery_feed_items_count?: Maybe<number>
    flags?: Maybe<{
      __typename?: 'user_flags'
      user_id: string
      discovery_feed_sequential_id?: Maybe<number>
    }>
  }>
}

export type GetShopsForCategoriesMobileQueryVariables = Exact<{
  categories?: Maybe<Array<Scalars['String']> | Scalars['String']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetShopsForCategoriesMobileQuery = {
  __typename?: 'query_root'
  shop_categorized_deals_aggregate: {
    __typename?: 'shop_categorized_deals_aggregate'
    aggregate?: Maybe<{
      __typename?: 'shop_categorized_deals_aggregate_fields'
      count: number
    }>
  }
  shop_categorized_deals: Array<{
    __typename?: 'shop_categorized_deals'
    key?: Maybe<string>
    deals_count?: Maybe<any>
    categories?: Maybe<Array<string>>
    shop?: Maybe<{ __typename?: 'shop' } & DealShopDataFragment>
  }>
}

export type CreateFreeCreatorSubscriptionMobileMutationVariables = Exact<{
  creatorId: Scalars['String']
}>

export type CreateFreeCreatorSubscriptionMobileMutation = {
  __typename?: 'mutation_root'
  subscribeToCreator: {
    __typename?: 'CreateSubscriptionOutput'
    user_subscription?: Maybe<{
      __typename?: 'user_subscription'
      id: any
      user_id: string
      creator_id: string
      subscription_id?: Maybe<string>
    }>
  }
}

export type FetchSimilarProductsMobileQueryVariables = Exact<{
  imageUrl: Scalars['String']
  shopItemId?: Maybe<Scalars['uuid']>
  cartItemId?: Maybe<Scalars['uuid']>
  allResults?: Maybe<Scalars['Boolean']>
  noCache?: Maybe<Scalars['Boolean']>
}>

export type FetchSimilarProductsMobileQuery = {
  __typename?: 'query_root'
  similarProducts: Array<{
    __typename?: 'SimilarProductOutput'
    position?: Maybe<number>
    title?: Maybe<string>
    link?: Maybe<string>
    source?: Maybe<string>
    source_icon?: Maybe<string>
    shop_popularity?: Maybe<number>
    points?: Maybe<number>
    thumbnail?: Maybe<string>
    price?: Maybe<{
      __typename?: 'SimilarProductPrice'
      value?: Maybe<string>
      extracted_value?: Maybe<number>
      currency?: Maybe<string>
    }>
  }>
}

export type FindCartItemForEditingMobileQueryVariables = Exact<{
  id: Scalars['uuid']
  isOwn?: Maybe<Scalars['Boolean']>
}>

export type FindCartItemForEditingMobileQuery = {
  __typename?: 'query_root'
  cart_item?: Maybe<
    {
      __typename?: 'cart_item'
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      item: {
        __typename?: 'shop_item'
        description?: Maybe<string>
        shop: { __typename?: 'shop' } & ShopDataFragment
      } & ItemDataFragment
      commentable_item_join?: Maybe<{
        __typename?: 'commentable_item'
        comments_aggregate: {
          __typename?: 'comment_aggregate'
          aggregate?: Maybe<{
            __typename?: 'comment_aggregate_fields'
            count: number
          }>
        }
      }>
    } & CartItemDataFragment
  >
}

export type FindCartItemForMobilePdpQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type FindCartItemForMobilePdpQuery = {
  __typename?: 'query_root'
  cart_item?: Maybe<{
    __typename?: 'cart_item'
    feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
  }>
}

export type FindDealHopGateStatusQueryVariables = Exact<{
  user_id: Scalars['String']
}>

export type FindDealHopGateStatusQuery = {
  __typename?: 'query_root'
  deal_hop_gate_by_pk?: Maybe<
    { __typename?: 'deal_hop_gate' } & DealHopGateDataFragment
  >
}

export type FindDealHopInvitesQueryVariables = Exact<{
  user_id: Scalars['String']
}>

export type FindDealHopInvitesQuery = {
  __typename?: 'query_root'
  deal_hop_invites?: Maybe<{
    __typename?: 'deal_hop_gate'
    user_id: string
    deal_hop_invites?: Maybe<any>
  }>
}

export type FindReferrerUserQueryVariables = Exact<{
  id: Scalars['String']
}>

export type FindReferrerUserQuery = {
  __typename?: 'query_root'
  user?: Maybe<{ __typename?: 'user' } & BasicUserDataFragment>
}

export type FindShopCategoryByValueMobileQueryVariables = Exact<{
  category: Scalars['String']
}>

export type FindShopCategoryByValueMobileQuery = {
  __typename?: 'query_root'
  shop_category?: Maybe<{
    __typename?: 'shop_category'
    label?: Maybe<string>
    value: string
  }>
}

export type FindUserForCollectionInviteQueryVariables = Exact<{
  id: Scalars['String']
}>

export type FindUserForCollectionInviteQuery = {
  __typename?: 'query_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    username?: Maybe<string>
    email?: Maybe<string>
  }>
}

export type GetAllCollectionAndItemCommentsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type GetAllCollectionAndItemCommentsQuery = {
  __typename?: 'query_root'
  comments: Array<{
    __typename?: 'comment'
    id: any
    sequential_id: number
    comment: string
    commentable_item_type: Commentable_Item_Type_Enum
    created_at: any
    commentable_item?: Maybe<{
      __typename?: 'commentable_item'
      cart_item?: Maybe<{
        __typename?: 'cart_item'
        item: { __typename?: 'shop_item' } & ItemDataFragment
      }>
    }>
    user: { __typename?: 'user' } & BasicUserDataFragment
  }>
  comment_aggregate: {
    __typename?: 'comment_aggregate'
    aggregate?: Maybe<{
      __typename?: 'comment_aggregate_fields'
      count: number
    }>
  }
}

export type GetBrandDealsMobileQueryVariables = Exact<{
  shop_key: Scalars['String']
  types?: Maybe<Array<Scalars['String']> | Scalars['String']>
  end_date?: Maybe<Scalars['timestamptz']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetBrandDealsMobileQuery = {
  __typename?: 'query_root'
  shop: Array<{ __typename?: 'shop' } & DealShopDataFragment>
  fmtc_deal: Array<{ __typename?: 'fmtc_deal' } & FmtcDataFragment>
}

export type GetBrandDealsYouLoveMobileQueryVariables = Exact<{
  categories_override?: Maybe<Array<Scalars['String']> | Scalars['String']>
  shop_categories?: Maybe<Array<Shop_Category_Enum> | Shop_Category_Enum>
  include_personalized?: Maybe<Scalars['Boolean']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetBrandDealsYouLoveMobileQuery = {
  __typename?: 'query_root'
  collection_by_pk?: Maybe<{
    __typename?: 'collection'
    personalized_collection_items: Maybe<
      Array<
        {
          __typename?: 'collection_cart_item'
        } & BrandDealsCollectionItemDataFragment
      >
    >
    all_collection_items: Array<
      {
        __typename?: 'collection_cart_item'
      } & BrandDealsCollectionItemDataFragment
    >
  }>
}

export type GetCarrotFeaturedCollectionsQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  collectionItemsCount?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type GetCarrotFeaturedCollectionsQuery = {
  __typename?: 'query_root'
  collections: Array<
    {
      __typename?: 'collection'
      collection_items: Array<{
        __typename?: 'collection_cart_item'
        id: any
        cart_item: {
          __typename?: 'cart_item'
          id: any
          display_title?: Maybe<string>
          image?: Maybe<string>
          shop_key: string
        }
      }>
    } & CollectionDataFragment
  >
}

export type GetCollectionItemsMobileQueryVariables = Exact<{
  collectionId: Scalars['uuid']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type GetCollectionItemsMobileQuery = {
  __typename?: 'query_root'
  collection_cart_item_aggregate: {
    __typename?: 'collection_cart_item_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_cart_item_aggregate_fields'
      count: number
    }>
  }
  collection_cart_item: Array<{
    __typename?: 'collection_cart_item'
    id: any
    order?: Maybe<any>
    created_at: any
    updated_at: any
    cart_item: {
      __typename?: 'cart_item'
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      commentable_item_join?: Maybe<{
        __typename?: 'commentable_item'
        comments: Array<{
          __typename?: 'comment'
          id: any
          comment: string
          user: {
            __typename?: 'user'
            username?: Maybe<string>
            picture?: Maybe<string>
          }
        }>
        comments_aggregate: {
          __typename?: 'comment_aggregate'
          aggregate?: Maybe<{
            __typename?: 'comment_aggregate_fields'
            count: number
          }>
        }
      }>
    } & CartItemDataFragment
  }>
}

export type GetCommentableItemCommentsQueryVariables = Exact<{
  id: Scalars['uuid']
  type: Commentable_Item_Type_Enum
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetCommentableItemCommentsQuery = {
  __typename?: 'query_root'
  comments: Array<{
    __typename?: 'comment'
    id: any
    sequential_id: number
    comment: string
    created_at: any
    user: { __typename?: 'user' } & BasicUserDataFragment
  }>
  comment_aggregate: {
    __typename?: 'comment_aggregate'
    aggregate?: Maybe<{
      __typename?: 'comment_aggregate_fields'
      count: number
    }>
  }
}

export type GetContactConnectionsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offsetConnections?: Maybe<Scalars['Int']>
}>

export type GetContactConnectionsQuery = {
  __typename?: 'query_root'
  contactConnections: Array<{
    __typename?: 'ContactConnection'
    contact_id: string
    friends_on_platform: number
  }>
}

export type GetCreatorsToSubscribeRandomMobileQueryVariables = Exact<{
  exclude_usernames?: Maybe<Array<Scalars['String']> | Scalars['String']>
  seed?: Maybe<Scalars['seed_float']>
  limit?: Maybe<Scalars['Int']>
  include_other_creators?: Maybe<Scalars['Boolean']>
}>

export type GetCreatorsToSubscribeRandomMobileQuery = {
  __typename?: 'query_root'
  vip_creators: Array<{ __typename?: 'user' } & BasicUserDataFragment>
  other_creators: Maybe<Array<{ __typename?: 'user' } & BasicUserDataFragment>>
}

export type GetDealsBlocksQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
}>

export type GetDealsBlocksQuery = {
  __typename?: 'query_root'
  mobile_home: Array<{
    __typename?: 'mobile_home'
    id: any
    order: number
    asset_url: string
    link_url?: Maybe<string>
  }>
}

export type GetDealsOfTheDayMobileQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
}>

export type GetDealsOfTheDayMobileQuery = {
  __typename?: 'query_root'
  collection_by_pk?: Maybe<{
    __typename?: 'collection'
    collection_items: Array<{
      __typename?: 'collection_cart_item'
      id: any
      cart_item: { __typename?: 'cart_item' } & DealOfTheDayCartItemDataFragment
    }>
  }>
}

export type DealOfTheDayCartItemDataFragment = {
  __typename?: 'cart_item'
  id: any
  shop_key: string
  display_title?: Maybe<string>
  description?: Maybe<string>
  affiliate_redirect_link_override?: Maybe<string>
  image?: Maybe<string>
  pricing?: Maybe<any>
  points?: Maybe<any>
  metadata: any
}

export type GetDiscountedForYouMobileQueryVariables = Exact<{
  categories_override?: Maybe<Array<Scalars['String']> | Scalars['String']>
  shop_categories?: Maybe<Array<Shop_Category_Enum> | Shop_Category_Enum>
  limit?: Maybe<Scalars['Int']>
}>

export type GetDiscountedForYouMobileQuery = {
  __typename?: 'query_root'
  collection_by_pk?: Maybe<{
    __typename?: 'collection'
    personalized_collection_items: Array<{
      __typename?: 'collection_cart_item'
      id: any
      cart_item: {
        __typename?: 'cart_item'
        id: any
        shop_key: string
        display_title?: Maybe<string>
        image?: Maybe<string>
        pricing?: Maybe<any>
        points?: Maybe<any>
        metadata: any
      }
    }>
    all_collection_items: Array<{
      __typename?: 'collection_cart_item'
      id: any
      cart_item: {
        __typename?: 'cart_item'
        id: any
        shop_key: string
        display_title?: Maybe<string>
        image?: Maybe<string>
        pricing?: Maybe<any>
        points?: Maybe<any>
        metadata: any
      }
    }>
  }>
}

export type GetDiscoverDataByUsernameQueryVariables = Exact<{
  username: Scalars['String']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  collectionItemsCount?: Maybe<Scalars['Int']>
}>

export type GetDiscoverDataByUsernameQuery = {
  __typename?: 'query_root'
  collections: Array<{
    __typename?: 'collection'
    id: any
    feed_item?: Maybe<
      { __typename?: 'feed_item' } & DiscoveryFeedFeedItemFragment
    >
  }>
}

export type GetDiscoveryCollectionFeedQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sequentialId: Scalars['Int']
  collectionItemsCount?: Maybe<Scalars['Int']>
}>

export type GetDiscoveryCollectionFeedQuery = {
  __typename?: 'query_root'
  discovery_feed: Array<{
    __typename?: 'discovery_feed'
    id: any
    sequential_id: number
    feed_item: { __typename?: 'feed_item' } & DiscoveryFeedFeedItemFragment
  }>
}

export type GetDiscoveryFeedQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sequentialId: Scalars['Int']
  collectionItemsCount?: Maybe<Scalars['Int']>
}>

export type GetDiscoveryFeedQuery = {
  __typename?: 'query_root'
  discovery_feed: Array<{
    __typename?: 'discovery_feed'
    id: any
    sequential_id: number
    feed_item: { __typename?: 'feed_item' } & DiscoveryFeedFeedItemFragment
  }>
}

export type GetDiscoveryLastSequentialIdQueryVariables = Exact<{
  [key: string]: never
}>

export type GetDiscoveryLastSequentialIdQuery = {
  __typename?: 'query_root'
  discovery_feed: Array<{
    __typename?: 'discovery_feed'
    id: any
    sequential_id: number
  }>
}

export type GetFeaturedShopsForOnboardingQueryVariables = Exact<{
  [key: string]: never
}>

export type GetFeaturedShopsForOnboardingQuery = {
  __typename?: 'query_root'
  featured_shops_for_onboarding: Array<{
    __typename?: 'shop'
    key: string
    favicon?: Maybe<string>
  }>
}

export type GetFeedItemsForStealTheirLookQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  collectionItemsCount?: Maybe<Scalars['Int']>
  loadCelebAccounts?: Maybe<Scalars['Boolean']>
}>

export type GetFeedItemsForStealTheirLookQuery = {
  __typename?: 'query_root'
  celeb_accounts: Maybe<Array<{ __typename?: 'user' } & BasicUserDataFragment>>
  feed_items_for_style_accounts: Array<
    { __typename?: 'feed_item' } & DiscoveryFeedFeedItemFragment
  >
}

export type GetFeedItemsForUserQueryVariables = Exact<{
  username: Scalars['String']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  collectionItemsCount?: Maybe<Scalars['Int']>
}>

export type GetFeedItemsForUserQuery = {
  __typename?: 'query_root'
  feed_items_for_user: Array<
    { __typename?: 'feed_item' } & DiscoveryFeedFeedItemFragment
  >
}

export type GetFeedItemsForUserSubscriptionsQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  collectionItemsCount?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['String']>
  includeSubscriptionUsers?: Maybe<Scalars['Boolean']>
  creatorsToExclude?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type GetFeedItemsForUserSubscriptionsQuery = {
  __typename?: 'query_root'
  user_subscription?: Maybe<
    Array<{
      __typename?: 'user_subscription'
      creator: { __typename?: 'user' } & BasicUserDataFragment
    }>
  >
  feed_items_for_user_subscriptions: Array<
    { __typename?: 'feed_item' } & DiscoveryFeedFeedItemFragment
  >
}

export type GetHomeBlocksQueryVariables = Exact<{ [key: string]: never }>

export type GetHomeBlocksQuery = {
  __typename?: 'query_root'
  mobile_home: Array<{
    __typename?: 'mobile_home'
    id: any
    order: number
    asset_url: string
    link_url?: Maybe<string>
  }>
}

export type GetMobileCollectionCollaboratorInvitesQueryVariables = Exact<{
  collectionId: Scalars['uuid']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetMobileCollectionCollaboratorInvitesQuery = {
  __typename?: 'query_root'
  invites: Array<{
    __typename?: 'collection_invite'
    id: any
    status: Collection_Invite_Status_Enum
    invitee_email?: Maybe<string>
    invitee?: Maybe<{ __typename?: 'user' } & BasicUserDataFragment>
    collection_collaborator?: Maybe<{
      __typename?: 'collection_collaborator'
      id: any
      manager: boolean
    }>
  }>
}

export type GetOnboardingCategoriesQueryVariables = Exact<{
  block_list?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type GetOnboardingCategoriesQuery = {
  __typename?: 'query_root'
  shop_category: Array<{
    __typename?: 'shop_category'
    value: string
    label?: Maybe<string>
    onboarding_category?: Maybe<{
      __typename?: 'onboarding_categories'
      featured: boolean
      image: string
    }>
  }>
}

export type GetOnboardingCategoryBrandsQueryVariables = Exact<{
  categories: Array<Scalars['String']> | Scalars['String']
  query?: Maybe<Scalars['String']>
  searching?: Maybe<Scalars['Boolean']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetOnboardingCategoryBrandsQuery = {
  __typename?: 'query_root'
  shop_popularity?: Maybe<
    Array<{
      __typename?: 'shop_popularity'
      shop?: Maybe<{ __typename?: 'shop' } & DealShopDataFragment>
    }>
  >
  search_shops?: Maybe<Array<{ __typename?: 'shop' } & DealShopDataFragment>>
}

export type GetPdpFromIdQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetPdpFromIdQuery = {
  __typename?: 'query_root'
  shop_item?: Maybe<
    {
      __typename?: 'shop_item'
      description?: Maybe<string>
      shop: { __typename?: 'shop' } & ShopDataFragment
    } & ItemDataFragment
  >
  cart_item?: Maybe<
    {
      __typename?: 'cart_item'
      creator_rating?: Maybe<any>
      commentable_item_join?: Maybe<{
        __typename?: 'commentable_item'
        comments: Array<{
          __typename?: 'comment'
          id: any
          comment: string
          user: {
            __typename?: 'user'
            username?: Maybe<string>
            picture?: Maybe<string>
          }
        }>
        comments_aggregate: {
          __typename?: 'comment_aggregate'
          aggregate?: Maybe<{
            __typename?: 'comment_aggregate_fields'
            count: number
          }>
        }
      }>
      feed_item?: Maybe<{ __typename?: 'feed_item' } & FeedItemDataFragment>
      item: {
        __typename?: 'shop_item'
        description?: Maybe<string>
        shop: { __typename?: 'shop' } & ShopDataFragment
      } & ItemDataFragment
    } & CartItemDataFragment
  >
}

export type GetRandomDiscoveryFeedItemsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  seed?: Maybe<Scalars['seed_float']>
  excludedPreviousDiscoveryIds?: Maybe<Scalars['_uuid']>
  collectionItemsCount?: Maybe<Scalars['Int']>
}>

export type GetRandomDiscoveryFeedItemsQuery = {
  __typename?: 'query_root'
  random_discovery_feed_items: Array<{
    __typename?: 'discovery_feed'
    id: any
    sequential_id: number
    feed_item: { __typename?: 'feed_item' } & DiscoveryFeedFeedItemFragment
  }>
}

export type GetShopsForEarnCashbackQueryVariables = Exact<{
  shop_categories?: Maybe<Array<Shop_Category_Enum> | Shop_Category_Enum>
  limit?: Maybe<Scalars['Int']>
}>

export type GetShopsForEarnCashbackQuery = {
  __typename?: 'query_root'
  all_shops: Array<{
    __typename?: 'shop'
    id: any
    key: string
    display_name?: Maybe<string>
    favicon?: Maybe<string>
    parsed_take_rate?: Maybe<any>
    points?: Maybe<any>
    banner_image?: Maybe<string>
    metadata: any
  }>
}

export type GetSimilarProductsHistoryMobileQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  itemId: Scalars['uuid']
  imageUrl?: Maybe<Scalars['String']>
}>

export type GetSimilarProductsHistoryMobileQuery = {
  __typename?: 'query_root'
  similar_products_history: Array<{
    __typename?: 'similar_products_history'
    id: any
  }>
}

export type GetStealTheirLooksDataQueryVariables = Exact<{
  seed?: Maybe<Scalars['seed_float']>
  limit?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type GetStealTheirLooksDataQuery = {
  __typename?: 'query_root'
  steal_their_looks_random: Array<
    {
      __typename?: 'collection'
      preview_collection_items?: Maybe<
        Array<{
          __typename?: 'collection_cart_item'
          id: any
          cart_item: {
            __typename?: 'cart_item'
            id: any
            item_image_override?: Maybe<string>
            item: { __typename?: 'shop_item'; cached_image?: Maybe<string> }
          }
        }>
      >
      user?: Maybe<{
        __typename?: 'user'
        id: string
        name?: Maybe<string>
        username?: Maybe<string>
      }>
    } & CollectionDataFragment
  >
}

export type GetSubscriptionSuggestionQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offsetFriends?: Maybe<Scalars['Int']>
  offsetMayKnow?: Maybe<Scalars['Int']>
}>

export type GetSubscriptionSuggestionQuery = {
  __typename?: 'query_root'
  friends: Array<{
    __typename?: 'ContactSubscriptionSuggestion'
    contact_id?: Maybe<string>
    mutual_connections?: Maybe<number>
    degrees_of_separation: number
    user?: Maybe<{
      __typename?: 'user'
      id: string
      username?: Maybe<string>
      picture?: Maybe<string>
    }>
  }>
  peopleYouMayKnow: Array<{
    __typename?: 'ContactSubscriptionSuggestion'
    contact_id?: Maybe<string>
    mutual_connections?: Maybe<number>
    degrees_of_separation: number
    user?: Maybe<{
      __typename?: 'user'
      id: string
      username?: Maybe<string>
      picture?: Maybe<string>
    }>
  }>
}

export type GetTopBrandDealsByShopMobileQueryVariables = Exact<{
  shop_categories?: Maybe<Array<Shop_Category_Enum> | Shop_Category_Enum>
  types?: Maybe<Array<Scalars['String']> | Scalars['String']>
  end_date?: Maybe<Scalars['timestamptz']>
  limit?: Maybe<Scalars['Int']>
  include_personal_shops?: Maybe<Scalars['Boolean']>
  include_all_shops?: Maybe<Scalars['Boolean']>
}>

export type GetTopBrandDealsByShopMobileQuery = {
  __typename?: 'query_root'
  personalized_deals: Array<{ __typename?: 'fmtc_deal' } & FmtcDataFragment>
  all_deals: Array<{ __typename?: 'fmtc_deal' } & FmtcDataFragment>
  personalized_results: Maybe<
    Array<
      {
        __typename?: 'shop_popularity_with_points'
      } & ShopPopularityWithPointsDataFragment
    >
  >
  all_results: Maybe<
    Array<
      {
        __typename?: 'shop_popularity_with_points'
      } & ShopPopularityWithPointsDataFragment
    >
  >
}

export type GetTopBrandDealsMobileQueryVariables = Exact<{
  types?: Maybe<Array<Scalars['String']> | Scalars['String']>
  end_date?: Maybe<Scalars['timestamptz']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetTopBrandDealsMobileQuery = {
  __typename?: 'query_root'
  all_deals: Array<{ __typename?: 'fmtc_deal' } & FmtcDataFragment>
}

export type GetTopCommissionShopsQueryVariables = Exact<{
  user_id: Scalars['String']
}>

export type GetTopCommissionShopsQuery = {
  __typename?: 'query_root'
  pageview_event_summary: Array<{
    __typename?: 'pageview_event_summary'
    key?: Maybe<string>
    shop?: Maybe<{
      __typename?: 'shop'
      id: any
      key: string
      display_name?: Maybe<string>
      favicon?: Maybe<string>
      parsed_take_rate?: Maybe<any>
      points?: Maybe<any>
    }>
  }>
  cart: Array<{
    __typename?: 'cart'
    id: any
    shop?: Maybe<{
      __typename?: 'shop'
      id: any
      key: string
      display_name?: Maybe<string>
      favicon?: Maybe<string>
      parsed_take_rate?: Maybe<any>
      points?: Maybe<any>
    }>
  }>
  shop_popularity: Array<{
    __typename?: 'shop_popularity'
    key?: Maybe<string>
    shop?: Maybe<{
      __typename?: 'shop'
      id: any
      key: string
      slug: any
      display_name?: Maybe<string>
      favicon?: Maybe<string>
      parsed_take_rate?: Maybe<any>
      points?: Maybe<any>
    }>
  }>
}

export type GetTopDealShopsMobileQueryVariables = Exact<{
  end_date?: Maybe<Scalars['timestamptz']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetTopDealShopsMobileQuery = {
  __typename?: 'query_root'
  all_deals: Array<{
    __typename?: 'fmtc_deal'
    id: any
    shop: { __typename?: 'shop' } & DealShopDataFragment
  }>
}

export type GetTrendingShopsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
}>

export type GetTrendingShopsQuery = {
  __typename?: 'query_root'
  shop_popularity: Array<{
    __typename?: 'shop_popularity'
    key?: Maybe<string>
    popularity_rank?: Maybe<any>
    shop?: Maybe<{
      __typename?: 'shop'
      id: any
      key: string
      slug: any
      display_name?: Maybe<string>
      cover_image?: Maybe<string>
      favicon?: Maybe<string>
      parsed_take_rate?: Maybe<any>
    }>
  }>
}

export type GetSingleUnviewedFeaturedEventQueryVariables = Exact<{
  userId: Scalars['String']
  type: Scalars['String']
}>

export type GetSingleUnviewedFeaturedEventQuery = {
  __typename?: 'query_root'
  single_unread_notification_banner_item_for_user?: Maybe<{
    __typename?: 'notification_center_item'
    id: any
    notifiable_id?: Maybe<any>
    notifiable_type?: Maybe<string>
    banner?: Maybe<boolean>
    communication_template?: Maybe<Communication_Template_Enum>
  }>
}

export type GetUserCategoriesMobileQueryVariables = Exact<{
  [key: string]: never
}>

export type GetUserCategoriesMobileQuery = {
  __typename?: 'query_root'
  user_category: Array<{
    __typename?: 'user_category'
    category: Shop_Category_Enum
  }>
}

export type GetUserCollectionsAggregatesQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type GetUserCollectionsAggregatesQuery = {
  __typename?: 'query_root'
  collections_all_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  collections_collaborating_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  collections_followed_aggregate: {
    __typename?: 'user_items_followed_aggregate'
    aggregate?: Maybe<{
      __typename?: 'user_items_followed_aggregate_fields'
      count: number
    }>
  }
  collections_visible_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  collections_hidden_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
}

export type GetUserCollectionsMobileQueryVariables = Exact<{
  userId: Scalars['String']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  itemLimit?: Maybe<Scalars['Int']>
  include_seo_fields?: Maybe<Scalars['Boolean']>
}>

export type GetUserCollectionsMobileQuery = {
  __typename?: 'query_root'
  collection_aggregate: {
    __typename?: 'collection_aggregate'
    aggregate?: Maybe<{
      __typename?: 'collection_aggregate_fields'
      count: number
    }>
  }
  collections: Array<
    { __typename?: 'collection' } & CollectionPartialForCollectionListFragment
  >
}

export type GetUserDealHopsQueryVariables = Exact<{
  user_id: Scalars['String']
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserDealHopsQuery = {
  __typename?: 'query_root'
  similar_products_history_unique: Array<{
    __typename?: 'similar_products_history'
    id: any
    image_url: string
    similar_products: any
  }>
}

export type GetUserNotificationPreferencesMobileQueryVariables = Exact<{
  [key: string]: never
}>

export type GetUserNotificationPreferencesMobileQuery = {
  __typename?: 'query_root'
  notification_preference: Array<{
    __typename?: 'notification_preference'
    id: any
    communication_template: string
    allowed: boolean
    allowable_communication: Array<string>
  }>
}

export type GetUserPointsSummaryMobileQueryVariables = Exact<{
  [key: string]: never
}>

export type GetUserPointsSummaryMobileQuery = {
  __typename?: 'query_root'
  user_points_summary: Array<{
    __typename?: 'user_points_summary'
    user_id?: Maybe<string>
    total_points?: Maybe<any>
    pending_points?: Maybe<any>
    multiplier?: Maybe<any>
  }>
  user_points_redeemed_summary: Array<{
    __typename?: 'user_points_redeemed_summary'
    user_id?: Maybe<string>
    points_redeemed?: Maybe<any>
  }>
}

export type GetUserPopularShopsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserPopularShopsQuery = {
  __typename?: 'query_root'
  shop_popularity: Array<{
    __typename?: 'shop_popularity'
    key?: Maybe<string>
    shop?: Maybe<{
      __typename?: 'shop'
      id: any
      key: string
      display_name?: Maybe<string>
      favicon?: Maybe<string>
      parsed_take_rate?: Maybe<any>
      metadata: any
    }>
  }>
}

export type GetUserRecentCartsQueryVariables = Exact<{
  user_id: Scalars['String']
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserRecentCartsQuery = {
  __typename?: 'query_root'
  cart: Array<{
    __typename?: 'cart'
    id: any
    created_at: any
    cart_items_aggregate: {
      __typename?: 'cart_item_aggregate'
      aggregate?: Maybe<{
        __typename?: 'cart_item_aggregate_fields'
        count: number
      }>
    }
    shop?: Maybe<{
      __typename?: 'shop'
      id: any
      key: string
      display_name?: Maybe<string>
      favicon?: Maybe<string>
      parsed_take_rate?: Maybe<any>
      metadata: any
    }>
  }>
}

export type GetUserRecentSavesQueryVariables = Exact<{
  user_id: Scalars['String']
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserRecentSavesQuery = {
  __typename?: 'query_root'
  cart_item: Array<{ __typename?: 'cart_item' } & CartItemDataFragment>
}

export type GetUserRecentViewsQueryVariables = Exact<{
  user_id: Scalars['String']
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserRecentViewsQuery = {
  __typename?: 'query_root'
  pageview_event_summary: Array<{
    __typename?: 'pageview_event_summary'
    user_id?: Maybe<string>
    key?: Maybe<string>
    created_at?: Maybe<any>
    count?: Maybe<any>
    shop?: Maybe<{
      __typename?: 'shop'
      id: any
      key: string
      display_name?: Maybe<string>
      favicon?: Maybe<string>
      parsed_take_rate?: Maybe<any>
      metadata: any
    }>
  }>
}

export type GetUserReferralsMobileQueryVariables = Exact<{
  user_id: Scalars['String']
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}>

export type GetUserReferralsMobileQuery = {
  __typename?: 'query_root'
  user_referrals: Array<{
    __typename?: 'user_referral'
    id: any
    reward_amount: any
    referred_user: {
      __typename?: 'user'
      id: string
      display_name?: Maybe<string>
      username?: Maybe<string>
      picture?: Maybe<string>
      created_at?: Maybe<any>
      can_be_credited?: Maybe<boolean>
      ios_version?: Maybe<string>
      safari_extension_version?: Maybe<string>
      extension_version?: Maybe<string>
    }
  }>
}

export type GetUserReferredCountsMobileQueryVariables = Exact<{
  user_id: Scalars['String']
}>

export type GetUserReferredCountsMobileQuery = {
  __typename?: 'query_root'
  user: Array<{
    __typename?: 'user'
    id: string
    referral_count_total?: Maybe<number>
    referral_count_within_promo_period?: Maybe<number>
    referral_count_credited?: Maybe<number>
  }>
}

export type GetUserSelectedShoppingCategoriesQueryVariables = Exact<{
  user_id: Scalars['String']
}>

export type GetUserSelectedShoppingCategoriesQuery = {
  __typename?: 'query_root'
  user_category: Array<{
    __typename?: 'user_category'
    category: Shop_Category_Enum
  }>
}

export type GetUserSubsMobileQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserSubsMobileQuery = {
  __typename?: 'query_root'
  user_subscription: Array<{
    __typename?: 'user_subscription'
    id: any
    subscription_type?: Maybe<string>
    status: User_Subscription_Status_Enum
    plan: any
    created_at: any
    updated_at: any
    cancel_at?: Maybe<any>
    creator: {
      __typename?: 'user'
      id: string
      name?: Maybe<string>
      username?: Maybe<string>
      picture?: Maybe<string>
      verified?: Maybe<boolean>
      has_billing_account?: Maybe<boolean>
    }
  }>
}

export type GetUserSubscribersMobileQueryVariables = Exact<{
  id: Scalars['String']
  collectionItemsCount?: Maybe<Scalars['Int']>
  creatorsToExclude?: Maybe<Array<Scalars['String']> | Scalars['String']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetUserSubscribersMobileQuery = {
  __typename?: 'query_root'
  user_subscription: Array<{
    __typename?: 'user_subscription'
    creator: { __typename?: 'user' } & BasicUserDataFragment
  }>
}

export type GetUsersReferredMobileQueryVariables = Exact<{
  [key: string]: never
}>

export type GetUsersReferredMobileQuery = {
  __typename?: 'query_root'
  user_referral: Array<{
    __typename?: 'user_referral'
    id: any
    referred_user: {
      __typename?: 'user'
      extension_version?: Maybe<string>
      ios_version?: Maybe<string>
      safari_extension_version?: Maybe<string>
    } & BasicUserDataFragment
  }>
}

export type InsertUserActionMobileMutationVariables = Exact<{
  action: Rewardable_Action_Names_Enum
  reference_type?: Maybe<Rewardable_Item_Type_Enum>
  reference_id?: Maybe<Scalars['String']>
}>

export type InsertUserActionMobileMutation = {
  __typename?: 'mutation_root'
  insert_user_action_one?: Maybe<{
    __typename?: 'user_action'
    id: any
    points: any
  }>
}

export type InsertUserFeedbackMutationVariables = Exact<{
  user_feedback_item_id?: Maybe<Scalars['uuid']>
  user_feedback_type?: Maybe<User_Feedback_Type_Enum>
  reaction?: Maybe<Scalars['Boolean']>
  custom?: Maybe<Scalars['String']>
}>

export type InsertUserFeedbackMutation = {
  __typename?: 'mutation_root'
  insert_user_feedback?: Maybe<{
    __typename?: 'user_feedback_mutation_response'
    returning: Array<{ __typename?: 'user_feedback'; id: any }>
  }>
}

export type PostCommentMutationVariables = Exact<{
  item_id: Scalars['uuid']
  item_type: Commentable_Item_Type_Enum
  comment: Scalars['String']
}>

export type PostCommentMutation = {
  __typename?: 'mutation_root'
  insert_comment_one?: Maybe<{
    __typename?: 'comment'
    id: any
    sequential_id: number
    commentable_item_type: Commentable_Item_Type_Enum
    commentable_item_id: any
    comment: string
    created_at: any
  }>
}

export type GetProductShopSlugLikesQueryVariables = Exact<{
  shop_item_id?: Maybe<Scalars['uuid']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetProductShopSlugLikesQuery = {
  __typename?: 'query_root'
  related_users_for_shop_item: Array<{
    __typename?: 'user'
    id: string
    username?: Maybe<string>
    picture?: Maybe<string>
  }>
}

export type SaveUserShoppingCategoriesMutationVariables = Exact<{
  user_id: Scalars['String']
  categories: Array<User_Category_Insert_Input> | User_Category_Insert_Input
}>

export type SaveUserShoppingCategoriesMutation = {
  __typename?: 'mutation_root'
  delete_user_category?: Maybe<{
    __typename?: 'user_category_mutation_response'
    affected_rows: number
  }>
  insert_user_category?: Maybe<{
    __typename?: 'user_category_mutation_response'
    returning: Array<{ __typename?: 'user_category'; id: any }>
  }>
}

export type SaveUserShoppingPreferenceMutationVariables = Exact<{
  id: Scalars['String']
  value?: Maybe<User_Shopping_Preferences_Enum>
}>

export type SaveUserShoppingPreferenceMutation = {
  __typename?: 'mutation_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    shopping_preference?: Maybe<User_Shopping_Preferences_Enum>
  }>
}

export type SearchForShopsMobileQueryVariables = Exact<{
  query: Scalars['String']
  limit?: Maybe<Scalars['Int']>
}>

export type SearchForShopsMobileQuery = {
  __typename?: 'query_root'
  shops: Array<{ __typename?: 'shop' } & DealShopDataFragment>
}

export type SearchUsersForMobileQueryVariables = Exact<{
  query: Scalars['String']
}>

export type SearchUsersForMobileQuery = {
  __typename?: 'query_root'
  users: Array<{ __typename?: 'user' } & BasicUserDataFragment>
}

export type SubUserPointsRedeemedSummarySubscriptionVariables = Exact<{
  [key: string]: never
}>

export type SubUserPointsRedeemedSummarySubscription = {
  __typename?: 'subscription_root'
  user_points_redeemed_summary: Array<{
    __typename?: 'user_points_redeemed_summary'
    user_id?: Maybe<string>
    points_redeemed?: Maybe<any>
  }>
}

export type SubUserPointsSummarySubscriptionVariables = Exact<{
  [key: string]: never
}>

export type SubUserPointsSummarySubscription = {
  __typename?: 'subscription_root'
  user_points_summary: Array<{
    __typename?: 'user_points_summary'
    user_id?: Maybe<string>
    total_points?: Maybe<any>
    pending_points?: Maybe<any>
  }>
}

export type ToggleAutoCashbackMobileMutationVariables = Exact<{
  userId: Scalars['String']
  autoCashbackEnabled?: Maybe<Scalars['Boolean']>
}>

export type ToggleAutoCashbackMobileMutation = {
  __typename?: 'mutation_root'
  update_user_flags_by_pk?: Maybe<{
    __typename?: 'user_flags'
    user_id: string
    auto_cashback_enabled?: Maybe<boolean>
  }>
}

export type UnsubscribeFromCreatorMobileMutationVariables = Exact<{
  creatorId: Scalars['String']
}>

export type UnsubscribeFromCreatorMobileMutation = {
  __typename?: 'mutation_root'
  fullyUnsubscribeFromCreator: {
    __typename?: 'FullyUnsubscribeOutput'
    user_subscription_id: string
  }
}

export type UpdateCreatorCartItemMobileMutationVariables = Exact<{
  id: Scalars['uuid']
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  item_image_override?: Maybe<Scalars['String']>
  item_title_override?: Maybe<Scalars['String']>
  item_description_override?: Maybe<Scalars['String']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  image_metadata?: Maybe<Scalars['jsonb']>
  item_video_url?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
}>

export type UpdateCreatorCartItemMobileMutation = {
  __typename?: 'mutation_root'
  update_cart_item_by_pk?: Maybe<
    { __typename?: 'cart_item' } & CartItemDataFragment
  >
}

export type UpdateDealHopGateInvitedFlagMutationVariables = Exact<{
  userId: Scalars['String']
  unlocked: Scalars['Boolean']
}>

export type UpdateDealHopGateInvitedFlagMutation = {
  __typename?: 'mutation_root'
  update_deal_hop_gate_by_pk?: Maybe<{
    __typename?: 'deal_hop_gate'
    user_id: string
    deal_hop_invited?: Maybe<any>
    deal_hop_gated?: Maybe<any>
    paywall_gated_at?: Maybe<any>
  }>
}

export type UpdateDealHopInvitesMutationVariables = Exact<{
  userId: Scalars['String']
  invites: Scalars['jsonb']
}>

export type UpdateDealHopInvitesMutation = {
  __typename?: 'mutation_root'
  update_deal_hop_gate_by_pk?: Maybe<{
    __typename?: 'deal_hop_gate'
    user_id: string
    deal_hop_invites?: Maybe<any>
  }>
}

export type UpdateDealHopPendingGateTimestampMutationVariables = Exact<{
  userId: Scalars['String']
  timestamp: Scalars['timestamptz']
}>

export type UpdateDealHopPendingGateTimestampMutation = {
  __typename?: 'mutation_root'
  update_deal_hop_gate_by_pk?: Maybe<
    { __typename?: 'deal_hop_gate' } & DealHopGateDataFragment
  >
}

export type UpdateUserNameAndPhotoMutationVariables = Exact<{
  id: Scalars['String']
  name: Scalars['String']
  picture?: Maybe<Scalars['String']>
}>

export type UpdateUserNameAndPhotoMutation = {
  __typename?: 'mutation_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    name?: Maybe<string>
    picture?: Maybe<string>
  }>
}

export type UpdateUserPhoneMobileMutationVariables = Exact<{
  id: Scalars['String']
  phone: Scalars['String']
}>

export type UpdateUserPhoneMobileMutation = {
  __typename?: 'mutation_root'
  user?: Maybe<{ __typename?: 'user'; id: string; phone?: Maybe<string> }>
}

export type InsertPhoneContactMutationVariables = Exact<{
  objects:
    | Array<User_Phone_Contact_Insert_Input>
    | User_Phone_Contact_Insert_Input
}>

export type InsertPhoneContactMutation = {
  __typename?: 'mutation_root'
  insert_user_phone_contact?: Maybe<{
    __typename?: 'user_phone_contact_mutation_response'
    returning: Array<{
      __typename?: 'user_phone_contact'
      id: any
      invited: boolean
      contact_id: string
      contact_info: string
      contact_info_type: Contact_Info_Type_Enum
      user_id?: Maybe<string>
    }>
  }>
}

export type UpsertUserNotificationPreferencesMobileMutationVariables = Exact<{
  args: Upsert_Notification_Preference_Args
}>

export type UpsertUserNotificationPreferencesMobileMutation = {
  __typename?: 'mutation_root'
  upsert_notification_preference: Array<{
    __typename?: 'notification_preference'
    id: any
    user_id: string
    allowable_communication: Array<string>
    allowed: boolean
  }>
}

export type WeightedSearchUsersForMobileQueryVariables = Exact<{
  query: Scalars['String']
  page?: Scalars['Int']
}>

export type WeightedSearchUsersForMobileQuery = {
  __typename?: 'query_root'
  users: Array<{
    __typename?: 'user'
    id: string
    name?: Maybe<string>
    username?: Maybe<string>
    verified?: Maybe<boolean>
    picture?: Maybe<string>
  }>
}

export type CreateNewCollectionForCanopyMigrationMutationVariables = Exact<{
  collection_input: Collection_Insert_Input
}>

export type CreateNewCollectionForCanopyMigrationMutation = {
  __typename?: 'mutation_root'
  insert_collection_one?: Maybe<{
    __typename?: 'collection'
    id: any
    name: any
    collection_items_aggregate: {
      __typename?: 'collection_cart_item_aggregate'
      aggregate?: Maybe<{
        __typename?: 'collection_cart_item_aggregate_fields'
        count: number
      }>
    }
  }>
}

export type BaseCreatorDataForEmailFragment = {
  __typename?: 'user'
  id: string
  name?: Maybe<string>
  username?: Maybe<string>
  picture?: Maybe<string>
}

export type GetUserForBeehiivQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetUserForBeehiivQuery = {
  __typename?: 'query_root'
  user?: Maybe<{
    __typename?: 'user'
    id: string
    email?: Maybe<string>
    phone?: Maybe<string>
    anonymous?: Maybe<boolean>
    installed_at?: Maybe<any>
    name?: Maybe<string>
    onboarded?: Maybe<boolean>
    orphaned_at?: Maybe<any>
    orphaned_by?: Maybe<string>
    picture?: Maybe<string>
    role?: Maybe<any>
    username?: Maybe<string>
    has_claimed_username_computed?: Maybe<boolean>
    extension_version?: Maybe<string>
    ios_version?: Maybe<string>
    safari_extension_version?: Maybe<string>
    first_app_install?: Maybe<string>
    created_at?: Maybe<any>
    updated_at?: Maybe<any>
    internal_account?: Maybe<boolean>
    metadata?: Maybe<any>
  }>
}

export type GetFeaturedInDiscoveryFeedDataForPushNotificationQueryVariables =
  Exact<{
    id: Scalars['uuid']
  }>

export type GetFeaturedInDiscoveryFeedDataForPushNotificationQuery = {
  __typename?: 'query_root'
  discovery_feed_by_pk?: Maybe<{
    __typename?: 'discovery_feed'
    feed_item: {
      __typename?: 'feed_item'
      collection?: Maybe<{
        __typename?: 'collection'
        id: any
        name: any
        user?: Maybe<
          { __typename?: 'user' } & UserForPushNotificationDataFragment
        >
      }>
    }
  }>
}

export type GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQueryVariables =
  Exact<{
    id: Scalars['uuid']
  }>

export type GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQuery = {
  __typename?: 'query_root'
  notification_center_item_by_pk?: Maybe<{
    __typename?: 'notification_center_item'
    id: any
    user?: Maybe<{ __typename?: 'user' } & UserForEmailDataFragment>
    notifiable_item?: Maybe<{
      __typename?: 'notifiable_item'
      collection?: Maybe<{
        __typename?: 'collection'
        id: any
        name: any
        path?: Maybe<string>
        collection_cover_images?: Maybe<Array<string>>
        email_excerpt?: Maybe<string>
        cart_items_count?: Maybe<any>
        user?: Maybe<{ __typename?: 'user' } & BaseCreatorDataForEmailFragment>
        media_enabled_join?: Maybe<{
          __typename?: 'media_enabled_item'
          associated_media: Array<{
            __typename?: 'media_items'
            media_id: any
            media: {
              __typename?: 'media_item'
              id: any
              media_url: string
              media_type: Media_Type_Enum
            }
          }>
        }>
        preview_collection_items?: Maybe<
          Array<{
            __typename?: 'collection_cart_item'
            id: any
            cart_item: {
              __typename?: 'cart_item'
              id: any
              item_image_override?: Maybe<string>
              display_title?: Maybe<string>
              item: {
                __typename?: 'shop_item'
                id: any
                cached_image?: Maybe<string>
                shop: { __typename?: 'shop'; display_name?: Maybe<string> }
              }
            }
          }>
        >
      }>
    }>
  }>
}

export type GetUserCommentedDataForEmailQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetUserCommentedDataForEmailQuery = {
  __typename?: 'query_root'
  notification_center_item_by_pk?: Maybe<{
    __typename?: 'notification_center_item'
    id: any
    user?: Maybe<
      {
        __typename?: 'user'
        username?: Maybe<string>
      } & UserForEmailDataFragment
    >
    notifiable_item?: Maybe<{
      __typename?: 'notifiable_item'
      comment?: Maybe<{
        __typename?: 'comment'
        comment: string
        commentable_item?: Maybe<{
          __typename?: 'commentable_item'
          cart_item?: Maybe<{
            __typename?: 'cart_item'
            id: any
            display_title?: Maybe<string>
            item_image_override?: Maybe<string>
            item: { __typename?: 'shop_item'; cached_image?: Maybe<string> }
          }>
        }>
        commenter: {
          __typename?: 'user'
          id: string
          username?: Maybe<string>
          picture?: Maybe<string>
        }
      }>
    }>
  }>
}

export type GetCollabCollectionItemsAddedDataForEmailQueryVariables = Exact<{
  collectionId: Scalars['uuid']
  userId: Scalars['String']
  cartItemsAddedAfter: Scalars['timestamptz']
}>

export type GetCollabCollectionItemsAddedDataForEmailQuery = {
  __typename?: 'query_root'
  collection_by_pk?: Maybe<{
    __typename?: 'collection'
    id: any
    name: any
    path?: Maybe<string>
    metadata?: Maybe<any>
    collection_cover_images?: Maybe<Array<string>>
    email_excerpt?: Maybe<string>
    user?: Maybe<{ __typename?: 'user' } & BaseCreatorDataForEmailFragment>
    preview_collection_items?: Maybe<
      Array<{
        __typename?: 'collection_cart_item'
        id: any
        cart_item: {
          __typename?: 'cart_item'
          id: any
          item_image_override?: Maybe<string>
          display_title?: Maybe<string>
          item: {
            __typename?: 'shop_item'
            id: any
            cached_image?: Maybe<string>
            shop: { __typename?: 'shop'; display_name?: Maybe<string> }
          }
        }
      }>
    >
    recent_collection_items?: Maybe<
      Array<{
        __typename?: 'collection_cart_item'
        id: any
        cart_item: {
          __typename?: 'cart_item'
          id: any
          item_image_override?: Maybe<string>
          display_title?: Maybe<string>
          item: {
            __typename?: 'shop_item'
            id: any
            cached_image?: Maybe<string>
            shop: { __typename?: 'shop'; display_name?: Maybe<string> }
          }
          cart: {
            __typename?: 'cart'
            user: {
              __typename?: 'user'
              id: string
              username?: Maybe<string>
              picture?: Maybe<string>
            }
          }
        }
      }>
    >
  }>
  user_by_pk?: Maybe<
    { __typename?: 'user'; username?: Maybe<string> } & UserForEmailDataFragment
  >
}

export type GetCreatorSubscriberUpgradesToPaidDataForEmailQueryVariables =
  Exact<{
    id: Scalars['uuid']
  }>

export type GetCreatorSubscriberUpgradesToPaidDataForEmailQuery = {
  __typename?: 'query_root'
  user_subscription_by_pk?: Maybe<{
    __typename?: 'user_subscription'
    creator: { __typename?: 'user' } & UserForEmailDataFragment
    subscriber: { __typename?: 'user'; email?: Maybe<string> }
  }>
}

export type GetSubscriberFullyUnsubscribesDataForEmailQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetSubscriberFullyUnsubscribesDataForEmailQuery = {
  __typename?: 'query_root'
  user_subscription_by_pk?: Maybe<{
    __typename?: 'user_subscription'
    subscriber: { __typename?: 'user' } & UserForEmailDataFragment
    creator: { __typename?: 'user' } & BaseCreatorDataForEmailFragment
  }>
}

export type GetSubscriberPaidSubscriptionEndedDataForEmailQueryVariables =
  Exact<{
    id: Scalars['uuid']
  }>

export type GetSubscriberPaidSubscriptionEndedDataForEmailQuery = {
  __typename?: 'query_root'
  user_subscription_by_pk?: Maybe<{
    __typename?: 'user_subscription'
    subscriber: { __typename?: 'user' } & UserForEmailDataFragment
    creator: { __typename?: 'user' } & BaseCreatorDataForEmailFragment
  }>
}

export type GetSubscriberThankYouFromCreatorDataForEmailQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetSubscriberThankYouFromCreatorDataForEmailQuery = {
  __typename?: 'query_root'
  user_subscription_by_pk?: Maybe<{
    __typename?: 'user_subscription'
    creator_has_billing_account?: Maybe<boolean>
    subscriber: { __typename?: 'user' } & UserForEmailDataFragment
    creator: {
      __typename?: 'user'
      is_icon_computed?: Maybe<boolean>
    } & BaseCreatorDataForEmailFragment
  }>
}

export type GetSubscriberUpgradesToPaidSubscriptionDataForEmailQueryVariables =
  Exact<{
    id: Scalars['uuid']
  }>

export type GetSubscriberUpgradesToPaidSubscriptionDataForEmailQuery = {
  __typename?: 'query_root'
  user_subscription_by_pk?: Maybe<{
    __typename?: 'user_subscription'
    subscriber: { __typename?: 'user' } & UserForEmailDataFragment
    creator: { __typename?: 'user' } & BaseCreatorDataForEmailFragment
  }>
}

export type GetCollectionForTypesenseSyncQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetCollectionForTypesenseSyncQuery = {
  __typename?: 'query_root'
  collection_by_pk?: Maybe<{
    __typename?: 'collection'
    name: any
    private: boolean
    all: boolean
    description?: Maybe<string>
    metadata?: Maybe<any>
    path?: Maybe<string>
    seo_fields?: Maybe<any>
    slug: any
    user?: Maybe<{ __typename?: 'user'; id: string; username?: Maybe<string> }>
    collection_items: Array<{
      __typename?: 'collection_cart_item'
      cart_item: {
        __typename?: 'cart_item'
        item_image_override?: Maybe<string>
        item: {
          __typename?: 'shop_item'
          title?: Maybe<string>
          shop: {
            __typename?: 'shop'
            name: any
            key: string
            shop_categorizations: Array<{
              __typename?: 'shop_categorization'
              shop_category: {
                __typename?: 'shop_category'
                value: string
                label?: Maybe<string>
              }
            }>
          }
        }
      }
    }>
  }>
}

export type GetShopItemForTypesenseSyncQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetShopItemForTypesenseSyncQuery = {
  __typename?: 'query_root'
  shop_item_by_pk?: Maybe<{
    __typename?: 'shop_item'
    display_title?: Maybe<string>
    has_broken_image?: Maybe<boolean>
    description?: Maybe<string>
    source?: Maybe<string>
    cached_image?: Maybe<string>
    url?: Maybe<string>
    availability?: Maybe<Shop_Item_Availability_Enum>
    pricing?: Maybe<any>
    parsed_pricing?: Maybe<any>
    enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum>
    categories?: Maybe<Array<string>>
    keywords?: Maybe<Array<string>>
    pdp_views?: Maybe<number>
    metadata?: Maybe<any>
    number_of_collections?: Maybe<number>
    shop: {
      __typename?: 'shop'
      name: any
      key: string
      shop_categorizations: Array<{
        __typename?: 'shop_categorization'
        shop_category: {
          __typename?: 'shop_category'
          value: string
          label?: Maybe<string>
        }
      }>
    }
    cart_items_aggregate: {
      __typename?: 'cart_item_aggregate'
      aggregate?: Maybe<{
        __typename?: 'cart_item_aggregate_fields'
        count: number
      }>
    }
  }>
}

export type GetUserForTypesenseSyncQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetUserForTypesenseSyncQuery = {
  __typename?: 'query_root'
  user_by_pk?: Maybe<{
    __typename?: 'user'
    id: string
    email?: Maybe<string>
    name?: Maybe<string>
    username?: Maybe<string>
    picture?: Maybe<string>
    verified?: Maybe<boolean>
    icon_order: any
    is_creator_computed?: Maybe<boolean>
    is_curator_computed?: Maybe<boolean>
  }>
}

export const MobileHomeBlockFragmentDoc = gql`
  fragment mobileHomeBlock on mobile_home {
    id
    order
    asset_url
    link_url
    enabled
    active
    type
  }
`
export const UserDataForAdminPushNotificationFragmentDoc = gql`
  fragment userDataForAdminPushNotification on user {
    id
    name
    username
    picture
    email
    created_at
  }
`
export const UserForEmailDataFragmentDoc = gql`
  fragment userForEmailData on user {
    id
    email
    name
  }
`
export const BasicUserDataFragmentDoc = gql`
  fragment basicUserData on user {
    id
    name
    display_name
    username
    picture
    verified
    user_type
    shopping_preference
    internal_account
    is_creator_computed
    is_curator_computed
    metadata
    created_at
  }
`
export const UserFlagsFragmentDoc = gql`
  fragment userFlags on user_flags {
    user_id
    opted_into_cart_onboarding
    last_cart_onboarding
    checked_history
    onboarding_state
    features
    discovery_feed_sequential_id
    discovery_feed_sequential_id_last_set_at
    notification_center_last_viewed_sequential_id
    can_be_impersonated
    auto_cashback_enabled
    installed_safari_extension
    granted_full_host_permissions_current
    last_safari_extension_permission_check
  }
`
export const UserDataFragmentDoc = gql`
  fragment userData on user {
    ...basicUserData
    bio
    bio_link
    short_id
    has_claimed_username_computed
    has_set_name_computed
    has_picture_set
    has_email_set
    anonymous
    role
    onboarded
    has_onboarded
    user_points_multiplier
    created_at
    updated_at
    installed_at
    active_cart_items_quantity
    orphaned_at
    orphaned_by
    has_premium_subscription
    collections_aggregate(where: { computed_is_visible: { _eq: true } }) {
      aggregate {
        count
      }
    }
    extension_version @include(if: $with_extension_version)
    ios_version @include(if: $with_ios_version)
    safari_extension_version @include(if: $with_safari_extension_version)
    flags {
      ...userFlags
    }
    referrer_user {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
  ${UserFlagsFragmentDoc}
`
export const UserForPushNotificationDataFragmentDoc = gql`
  fragment userForPushNotificationData on user {
    ...basicUserData
    ios_version
    push_notification_tokens: user_push_notification_tokens(
      where: { active: { _eq: true } }
      limit: 1
    ) {
      id
    }
  }
  ${BasicUserDataFragmentDoc}
`
export const ShopDataFragmentDoc = gql`
  fragment shopData on shop {
    id
    key
    cart_path
    type
    platform
    name
    display_name
    cover_image
    banner_image
    slug
    favicon
    created_at
    updated_at
    has_pdp
    editorial_shop_logo
    parsed_take_rate
    metadata
  }
`
export const CartDataFragmentDoc = gql`
  fragment cartData on cart {
    id
    user_id
    shop_key
    created_at
    updated_at
    cleared_at
    last_cart_item_created_at
    user {
      ...basicUserData
      short_id
    }
    shop {
      ...shopData
    }
  }
  ${BasicUserDataFragmentDoc}
  ${ShopDataFragmentDoc}
`
export const ItemDataFragmentDoc = gql`
  fragment itemData on shop_item {
    id
    item_id
    shop_key
    source
    display_title
    description
    url
    image
    image_highres
    cached_image
    cached_placeholder_image
    image_metadata
    image_color_palette
    pricing
    parsed_pricing
    points
    metadata
    enrichment_status
    is_inappropriate
    saved_by_current_user
    onboarding_categories
    categories
    keywords
    created_at
    updated_at
  }
`
export const CollectionItemClaimDataFragmentDoc = gql`
  fragment collectionItemClaimData on collection_item_claim {
    id
    status
    user {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
`
export const CartItemDataFragmentDoc = gql`
  fragment cartItemData on cart_item {
    id
    cart_id
    item_id
    shop_key
    quantity
    bookmark
    in_collection
    archived_at
    removed_at
    removed_reason
    deleted_at
    created_at
    updated_at
    source
    metadata
    categories_override
    affiliate_redirect_link_override
    item_image_override
    item_title_override
    item_pricing_override
    item_description_override
    item_video_url
    item_video_orientation
    display_title
    description
    pricing
    points
    hidden
    image
    image_metadata
    creator_rating
    commentable_item_join {
      comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
        id
        comment
        user {
          username
          picture
        }
      }
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    image_color_palette
    note {
      id
      note
      private
    }
    cart {
      id
      user {
        ...basicUserData
      }
    }
    shop {
      ...shopData
    }
    item {
      ...itemData
    }
    like_count
    collection_item_claim {
      ...collectionItemClaimData
    }
    collection_cart_items {
      collection_id
    }
    user {
      id
      picture
      username
    }
  }
  ${BasicUserDataFragmentDoc}
  ${ShopDataFragmentDoc}
  ${ItemDataFragmentDoc}
  ${CollectionItemClaimDataFragmentDoc}
`
export const CartItemsDataFragmentDoc = gql`
  fragment cartItemsData on cart {
    cart_items(
      limit: 50
      where: { deleted_at: { _is_null: true }, archived_at: { _is_null: true } }
      order_by: { created_at: desc_nulls_last }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`
export const CartItemsDataWithLimitFragmentDoc = gql`
  fragment cartItemsDataWithLimit on cart {
    cart_items(
      limit: $cartItemsLimit
      where: { deleted_at: { _is_null: true }, archived_at: { _is_null: true } }
      order_by: { created_at: desc_nulls_last }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`
export const PreloadedCollectionCartItemDataFragmentDoc = gql`
  fragment preloadedCollectionCartItemData on collection_cart_item {
    id
    collection_id
    cart_item {
      id
      item_image_override
      display_title
      item {
        ...itemData
        shop {
          ...shopData
        }
      }
      cart {
        user {
          id
          username
        }
      }
    }
  }
  ${ItemDataFragmentDoc}
  ${ShopDataFragmentDoc}
`
export const CartItemLikeDataFragmentDoc = gql`
  fragment cartItemLikeData on cart_item_like {
    id
    user_id
    cart_item_id
    value
  }
`
export const CollectionDataFragmentDoc = gql`
  fragment collectionData on collection {
    id
    user_id
    short_id
    name
    description
    slug
    state
    paid
    exclusive
    computed_is_visible
    has_valid_preview_images_computed
    private
    giftlist
    all
    collection_type
    curation
    metadata
    bookmarks
    ogimage_fingerprint_updated
    collection_link
    open_collab_link_computed
    cover_image
    cover_image_cached
    collection_cover_images
    cart_items_quantity
    cart_items_count
    created_at
    updated_at
    cart_item_max_updated_at
    budget
    has_collaborators
    path
    is_a_collaborator
    user {
      ...basicUserData
      short_id
      anonymous
    }
    seo_fields @include(if: $include_seo_fields)
  }
  ${BasicUserDataFragmentDoc}
`
export const FeedItemDataFragmentDoc = gql`
  fragment feedItemData on feed_item {
    id
    item_id
    item_type
    in_discovery_backlog
    in_discovery_staging
    in_discovery_feed
    metadata
    liked_by_user
    like_count
  }
`
export const CollectionItemsDataFragmentDoc = gql`
  fragment collectionItemsData on collection {
    collection_items(
      where: { cart_item: { deleted_at: { _is_null: true } } }
      order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
      limit: $itemLimit
    ) {
      id
      created_at
      updated_at
      cart_item {
        ...cartItemData
        feed_item {
          ...feedItemData
        }
        creator_rating
        commentable_item_join {
          comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
            id
            comment
            user {
              username
              picture
            }
          }
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
  ${CartItemDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`
export const CollectionCollaboratorsDataFragmentDoc = gql`
  fragment collectionCollaboratorsData on collection {
    collection_collaborators {
      id
      manager
      user {
        ...basicUserData
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`
export const CollectionAndItemsDataFragmentDoc = gql`
  fragment collectionAndItemsData on collection {
    ...collectionData
    ...collectionItemsData
    ...collectionCollaboratorsData
  }
  ${CollectionDataFragmentDoc}
  ${CollectionItemsDataFragmentDoc}
  ${CollectionCollaboratorsDataFragmentDoc}
`
export const AdminCategorizationShopDataFragmentDoc = gql`
  fragment adminCategorizationShopData on shop {
    id
    key
    slug
    name
    name_override
    display_name
    description
    keywords
    favicon
    cover_image
    banner_image
    editorial_shop_logo
    shop_faqs
    featured
    points
    metadata
    shop_categorizations {
      id
      shop_key
      weight
      curation_score
      category
      shop_category {
        label
      }
    }
  }
`
export const AffiliateFragmentDoc = gql`
  fragment affiliate on affiliate {
    value
    meta {
      data
    }
  }
`
export const ShopAffiliateFragmentDoc = gql`
  fragment shopAffiliate on shop_affiliate {
    affiliate {
      value
      meta {
        data
      }
    }
  }
`
export const DiscoveryFeedCollectionCartItemCollectionFragmentDoc = gql`
  fragment discoveryFeedCollectionCartItemCollection on collection_cart_item {
    collection {
      id
      name
      slug
      has_collaborators
      user {
        ...basicUserData
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`
export const DiscoveryCollectionCartItemFragmentDoc = gql`
  fragment discoveryCollectionCartItem on collection_cart_item {
    id
    cart_item {
      id
      created_at
      updated_at
      archived_at
      deleted_at
      item_image_override
      display_title
      description
      pricing
      item {
        ...itemData
      }
      shop {
        id
        key
        slug
        name
        display_name
        favicon
      }
      cart {
        user {
          ...basicUserData
        }
      }
      feed_item {
        ...feedItemData
      }
      note {
        id
        note
        private
      }
      creator_rating
      commentable_item_join {
        comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
          id
          comment
          user {
            username
            picture
          }
        }
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
  ${ItemDataFragmentDoc}
  ${BasicUserDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`
export const DiscoveryCollectionFragmentDoc = gql`
  fragment discoveryCollection on collection {
    id
    name
    slug
    user_id
    description
    cover_image
    cover_image_cached
    collection_cover_images
    cart_items_count
    has_collaborators
    followed_by_user
    is_a_collaborator
    metadata
    updated_at
    created_at
    total_likes_count
    feed_item {
      ...feedItemData
    }
    user {
      ...basicUserData
    }
    collection_items: preview_collection_items(limit: $collectionItemsCount) {
      ...discoveryCollectionCartItem
    }
    commentable_item_join {
      comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
        id
        comment
        user {
          username
          picture
        }
      }
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
  ${FeedItemDataFragmentDoc}
  ${BasicUserDataFragmentDoc}
  ${DiscoveryCollectionCartItemFragmentDoc}
`
export const FirstCartItemFragmentDoc = gql`
  fragment firstCartItem on shop {
    cart_items(order_by: { created_at: asc_nulls_last }, limit: 1) {
      id
      created_at
      cart {
        id
        user {
          ...basicUserData
        }
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`
export const FeaturedShopAndFeaturedShopItemsFragmentDoc = gql`
  fragment featuredShopAndFeaturedShopItems on featured_shop {
    id
    feed_item {
      ...feedItemData
    }
    shop {
      id
      key
      name
      display_name
      favicon
      ...firstCartItem
    }
    featured_shop_items {
      id
      shop_item {
        ...itemData
      }
    }
  }
  ${FeedItemDataFragmentDoc}
  ${FirstCartItemFragmentDoc}
  ${ItemDataFragmentDoc}
`
export const DiscoveryFeedFeedItemFragmentDoc = gql`
  fragment discoveryFeedFeedItem on feed_item {
    ...feedItemData
    collection {
      ...discoveryCollection
    }
    featured_shop {
      ...featuredShopAndFeaturedShopItems
    }
    collection_cart_item {
      ...discoveryCollectionCartItem
      collection {
        ...discoveryCollection
      }
    }
  }
  ${FeedItemDataFragmentDoc}
  ${DiscoveryCollectionFragmentDoc}
  ${FeaturedShopAndFeaturedShopItemsFragmentDoc}
  ${DiscoveryCollectionCartItemFragmentDoc}
`
export const CollectionInviteFragmentDoc = gql`
  fragment collectionInvite on collection_invite {
    id
    invitee_id
    collection_id
    status
    invitee {
      id
      name
      picture
    }
    collection_collaborator {
      id
      manager
    }
  }
`
export const ContactInviteDataFragmentDoc = gql`
  fragment contactInviteData on contact_invite {
    id
    contact_info
    type
  }
`
export const CollectionCarouselDataFragmentDoc = gql`
  fragment collectionCarouselData on collection {
    id
    slug
    name
    user {
      id
      username
      picture
      created_at
    }
    cart_items_count
    collection_items: preview_collection_items(limit: $itemLimit) {
      id
      cart_item {
        id
        item_image_override
        display_title
        description
        pricing
        item {
          id
          image
          image_highres
          cached_image
        }
      }
    }
  }
`
export const MediaDataFragmentDoc = gql`
  fragment mediaData on media_item {
    id
    media_url
    media_type
  }
`
export const MediaEnabledItemDataFragmentDoc = gql`
  fragment mediaEnabledItemData on media_enabled_item {
    associated_media {
      media_id
      media {
        ...mediaData
      }
    }
  }
  ${MediaDataFragmentDoc}
`
export const UserCollectionsDataForProfilePageDataFragmentDoc = gql`
  fragment userCollectionsDataForProfilePageData on collection {
    id
    order
    slug
    state
    paid
    exclusive
    private
    name
    description
    state
    curation
    collection_type
    cover_image
    cover_image_cached
    collection_cover_images
    collection_link
    created_at
    updated_at
    cart_item_max_updated_at
    total_likes_count
    user {
      id
      username
      picture
    }
    has_collaborators
    collection_collaborators_aggregate {
      aggregate {
        count
      }
    }
    collection_collaborators(
      order_by: { created_at: desc_nulls_last }
      limit: 4
    ) {
      id
      user {
        picture
      }
    }
    cart_items_count
    collection_items: preview_collection_items(limit: 3) {
      id
      cart_item {
        id
        item_image_override
        item {
          id
          image
          image_highres
          cached_image
          image_metadata
          metadata
          is_inappropriate
        }
      }
    }
  }
`
export const DealShopDataFragmentDoc = gql`
  fragment dealShopData on shop {
    id
    key
    slug
    display_name
    favicon
    featured
    banner_image
    points
  }
`
export const FmtcDataFragmentDoc = gql`
  fragment fmtcData on fmtc_deal {
    id
    title
    direct_url
    rating
    types
    code
    start_date
    end_date
    shop_key
    shop {
      ...dealShopData
    }
  }
  ${DealShopDataFragmentDoc}
`
export const ShopPopularityWithPointsDataFragmentDoc = gql`
  fragment shopPopularityWithPointsData on shop_popularity_with_points {
    shop {
      ...dealShopData
    }
  }
  ${DealShopDataFragmentDoc}
`
export const BrandDealsCollectionItemDataFragmentDoc = gql`
  fragment brandDealsCollectionItemData on collection_cart_item {
    id
    cart_item {
      id
      shop_key
      display_title
      image
      pricing
      metadata
      deal_ends_at
      shop {
        ...dealShopData
      }
    }
  }
  ${DealShopDataFragmentDoc}
`
export const DealHopGateDataFragmentDoc = gql`
  fragment dealHopGateData on deal_hop_gate {
    user_id
    deal_hop_gated
    deal_hop_unlocked
    deal_hop_invited
    deal_hop_gate_pending
    paywall_gated_at
  }
`
export const CartItemDataPartialForSavedItemsFragmentDoc = gql`
  fragment cartItemDataPartialForSavedItems on cart_item {
    id
    cart_id
    item_id
    shop_key
    quantity
    bookmark
    in_collection
    archived_at
    removed_at
    removed_reason
    deleted_at
    created_at
    updated_at
    source
    affiliate_redirect_link_override
    item_image_override
    item_title_override
    item_pricing_override
    item_description_override
    item_video_url
    item_video_orientation
    display_title
    description
    pricing
    hidden
    image_metadata
    creator_rating
    note {
      id
      note
      private
    }
    cart {
      id
      user {
        ...basicUserData
      }
    }
    shop {
      ...shopData
    }
    item {
      ...itemData
    }
    like_count
    collection_item_claim {
      ...collectionItemClaimData
    }
    collection_cart_items {
      collection_id
    }
    user {
      id
      picture
      username
    }
  }
  ${BasicUserDataFragmentDoc}
  ${ShopDataFragmentDoc}
  ${ItemDataFragmentDoc}
  ${CollectionItemClaimDataFragmentDoc}
`
export const BasicCollectionDataFragmentDoc = gql`
  fragment basicCollectionData on collection {
    id
    short_id
    user_id
    slug
    name
    description
    seo_fields
    private
    giftlist
    cart_items_count
    has_collaborators
    metadata
  }
`
export const CollectionPartialForCollectionListFragmentDoc = gql`
  fragment collectionPartialForCollectionList on collection {
    id
    cover_image
    cover_image_cached
    has_collaborators
    description
    name
    state
    collection_type
    private
    cart_items_quantity
    cart_items_count
    updated_at
    user {
      picture
      verified
      username
    }
    collection_collaborators(limit: 4) {
      user {
        picture
      }
    }
    collection_collaborators_aggregate {
      aggregate {
        count
      }
    }
    collection_items: preview_collection_items(limit: 3) {
      id
      cart_item {
        id
        item_image_override
        display_title
        item {
          id
          image
          image_highres
          cached_image
          cached_placeholder_image
          image_metadata
          metadata
          is_inappropriate
        }
      }
    }
  }
`
export const MobileCollectionCollaboratorsDataFragmentDoc = gql`
  fragment mobileCollectionCollaboratorsData on collection {
    collection_collaborators(limit: 10) {
      id
      user {
        ...basicUserData
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`
export const MobileCollectionCollaboratorsAggregateFragmentDoc = gql`
  fragment mobileCollectionCollaboratorsAggregate on collection {
    collection_collaborators_aggregate {
      aggregate {
        count
      }
    }
  }
`
export const MobileCollectionAndItemsDataFragmentDoc = gql`
  fragment mobileCollectionAndItemsData on collection {
    ...collectionData
    ...collectionItemsData
    ...mobileCollectionCollaboratorsData
    ...mobileCollectionCollaboratorsAggregate
  }
  ${CollectionDataFragmentDoc}
  ${CollectionItemsDataFragmentDoc}
  ${MobileCollectionCollaboratorsDataFragmentDoc}
  ${MobileCollectionCollaboratorsAggregateFragmentDoc}
`
export const CollectionInviteForNotificationCenterFragmentDoc = gql`
  fragment collectionInviteForNotificationCenter on collection_invite {
    id
    status
    collection {
      id
      name
      cover_image_cached
      user {
        id
        username
        picture
      }
    }
    invitee {
      id
      name
      username
      picture
    }
    requester {
      id
      username
      picture
    }
  }
`
export const FeedItemLikeForNotificationCenterFragmentDoc = gql`
  fragment feedItemLikeForNotificationCenter on feed_item_like {
    user {
      picture
      username
    }
    feed_item {
      item_type
      featured_shop {
        shop {
          id
          name
          favicon
          display_name
        }
      }
      collection {
        id
        cover_image_cached
        name
      }
      cart_item {
        id
        item_image_override
        item {
          cached_image
          display_title
        }
      }
    }
  }
`
export const CollectionCartItemForNotificationCenterFragmentDoc = gql`
  fragment collectionCartItemForNotificationCenter on collection_cart_item {
    id
    collection {
      id
      name
    }
    cart_item {
      item_image_override
      item {
        cached_image
      }
      cart {
        user {
          name
          username
          picture
        }
      }
    }
  }
`
export const CollectionForFollowFragmentDoc = gql`
  fragment collectionForFollow on collection {
    id
    name
    cover_image_cached
  }
`
export const UserItemsFollowedFragmentDoc = gql`
  fragment userItemsFollowed on user_items_followed {
    user {
      id
      picture
      username
    }
    followable_item {
      collection {
        ...collectionForFollow
      }
    }
  }
  ${CollectionForFollowFragmentDoc}
`
export const CollectionForNotificationCenterFragmentDoc = gql`
  fragment collectionForNotificationCenter on collection {
    id
    name
    cover_image_cached
    user {
      picture
      username
    }
  }
`
export const CommentForNotificationCenterFragmentDoc = gql`
  fragment commentForNotificationCenter on comment {
    user {
      id
      username
      picture
    }
    commentable_item {
      cart_item {
        id
        display_title
        item_image_override
        item {
          cached_image
        }
      }
    }
  }
`
export const CartItemForNotificationCenterFragmentDoc = gql`
  fragment cartItemForNotificationCenter on cart_item {
    id
    item_image_override
    item_id
    display_title
    item {
      cached_image
      image
    }
    originating_cart_item {
      id
      item_image_override
      item {
        cached_image
      }
      shop {
        name
      }
    }
    originating_collection {
      id
      collection_cover_image
      name
    }
  }
`
export const NotificationCenterItemDataFragmentDoc = gql`
  fragment notificationCenterItemData on notification_center_item {
    body
    created_at
    id
    link
    main_image
    read_at
    secondary_image
    sequential_id
    communication_template
    banner
    type
    notifiable_item {
      collection_invite {
        ...collectionInviteForNotificationCenter
      }
      feed_item_like {
        ...feedItemLikeForNotificationCenter
      }
      collection_cart_item {
        ...collectionCartItemForNotificationCenter
      }
      follow {
        ...userItemsFollowed
      }
      collection {
        ...collectionForNotificationCenter
      }
      comment {
        ...commentForNotificationCenter
      }
      cart_item {
        ...cartItemForNotificationCenter
      }
    }
  }
  ${CollectionInviteForNotificationCenterFragmentDoc}
  ${FeedItemLikeForNotificationCenterFragmentDoc}
  ${CollectionCartItemForNotificationCenterFragmentDoc}
  ${UserItemsFollowedFragmentDoc}
  ${CollectionForNotificationCenterFragmentDoc}
  ${CommentForNotificationCenterFragmentDoc}
  ${CartItemForNotificationCenterFragmentDoc}
`
export const WrappedNotificationCenterItemDataFragmentDoc = gql`
  fragment wrappedNotificationCenterItemData on WrappedNotificationCenterItem {
    body
    created_at
    id
    link
    navigation
    main_image
    read_at
    secondary_image
    sequential_id
    type
  }
`
export const SearchItemDataFragmentDoc = gql`
  fragment searchItemData on shop_item {
    id
    item_id
    shop_key
    display_title
    url
    image
    image_highres
    cached_image
    cached_placeholder_image
    saved_by_current_user
    image_metadata
    is_inappropriate
    pricing
    parsed_pricing
    metadata
    created_at
    updated_at
    shop {
      id
      key
      cart_path
      type
      platform
      name
      display_name
      cover_image
      slug
      favicon
      created_at
      updated_at
      has_pdp
    }
  }
`
export const UserDataForProfileScreenFragmentDoc = gql`
  fragment userDataForProfileScreen on user {
    ...basicUserData
    bio
    bio_link
    links {
      id
      url
      link_type
    }
  }
  ${BasicUserDataFragmentDoc}
`
export const DealOfTheDayCartItemDataFragmentDoc = gql`
  fragment dealOfTheDayCartItemData on cart_item {
    id
    shop_key
    display_title
    description
    affiliate_redirect_link_override
    image
    pricing
    points
    metadata
  }
`
export const BaseCreatorDataForEmailFragmentDoc = gql`
  fragment baseCreatorDataForEmail on user {
    id
    name
    username
    picture
  }
`
export const FindUserForCleanupDocument = gql`
  query FindUserForCleanup($id: String!) {
    user: user_by_pk(id: $id) {
      anonymous
      email
      orphaned_by
      orphaned_at
    }
  }
`

/**
 * __useFindUserForCleanupQuery__
 *
 * To run a query within a React component, call `useFindUserForCleanupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserForCleanupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserForCleanupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindUserForCleanupQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserForCleanupQuery,
    FindUserForCleanupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindUserForCleanupQuery,
    FindUserForCleanupQueryVariables
  >(FindUserForCleanupDocument, options)
}
export function useFindUserForCleanupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserForCleanupQuery,
    FindUserForCleanupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindUserForCleanupQuery,
    FindUserForCleanupQueryVariables
  >(FindUserForCleanupDocument, options)
}
export type FindUserForCleanupQueryHookResult = ReturnType<
  typeof useFindUserForCleanupQuery
>
export type FindUserForCleanupLazyQueryHookResult = ReturnType<
  typeof useFindUserForCleanupLazyQuery
>
export type FindUserForCleanupQueryResult = Apollo.QueryResult<
  FindUserForCleanupQuery,
  FindUserForCleanupQueryVariables
>
export const OrphanAnonymousUserDocument = gql`
  mutation OrphanAnonymousUser($userId: String!, $orphanedBy: String!) {
    update_user(
      where: {
        anonymous: { _eq: true }
        id: { _eq: $userId }
        has_email_set: { _eq: false }
        orphaned_at: { _is_null: true }
        orphaned_by: { _is_null: true }
      }
      _set: { orphaned_at: "now()", orphaned_by: $orphanedBy }
    ) {
      affected_rows
    }
  }
`
export type OrphanAnonymousUserMutationFn = Apollo.MutationFunction<
  OrphanAnonymousUserMutation,
  OrphanAnonymousUserMutationVariables
>

/**
 * __useOrphanAnonymousUserMutation__
 *
 * To run a mutation, you first call `useOrphanAnonymousUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrphanAnonymousUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orphanAnonymousUserMutation, { data, loading, error }] = useOrphanAnonymousUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      orphanedBy: // value for 'orphanedBy'
 *   },
 * });
 */
export function useOrphanAnonymousUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrphanAnonymousUserMutation,
    OrphanAnonymousUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OrphanAnonymousUserMutation,
    OrphanAnonymousUserMutationVariables
  >(OrphanAnonymousUserDocument, options)
}
export type OrphanAnonymousUserMutationHookResult = ReturnType<
  typeof useOrphanAnonymousUserMutation
>
export type OrphanAnonymousUserMutationResult =
  Apollo.MutationResult<OrphanAnonymousUserMutation>
export type OrphanAnonymousUserMutationOptions = Apollo.BaseMutationOptions<
  OrphanAnonymousUserMutation,
  OrphanAnonymousUserMutationVariables
>
export const GetUserCollectionsForModalDocument = gql`
  query GetUserCollectionsForModal(
    $userId: String
    $limit: Int = 25
    $offset: Int = 0
  ) {
    collections: collection(
      where: {
        all: { _eq: false }
        _or: [
          { user_id: { _eq: $userId } }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
      order_by: [
        { has_collaborators: asc }
        { order: asc }
        { updated_at: desc_nulls_last }
      ]
      limit: $limit
      offset: $offset
    ) {
      id
      name
      slug
      user {
        id
        username
        picture
      }
      has_collaborators
      ...collectionCollaboratorsData
    }
  }
  ${CollectionCollaboratorsDataFragmentDoc}
`

/**
 * __useGetUserCollectionsForModalQuery__
 *
 * To run a query within a React component, call `useGetUserCollectionsForModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCollectionsForModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCollectionsForModalQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetUserCollectionsForModalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserCollectionsForModalQuery,
    GetUserCollectionsForModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCollectionsForModalQuery,
    GetUserCollectionsForModalQueryVariables
  >(GetUserCollectionsForModalDocument, options)
}
export function useGetUserCollectionsForModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCollectionsForModalQuery,
    GetUserCollectionsForModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCollectionsForModalQuery,
    GetUserCollectionsForModalQueryVariables
  >(GetUserCollectionsForModalDocument, options)
}
export type GetUserCollectionsForModalQueryHookResult = ReturnType<
  typeof useGetUserCollectionsForModalQuery
>
export type GetUserCollectionsForModalLazyQueryHookResult = ReturnType<
  typeof useGetUserCollectionsForModalLazyQuery
>
export type GetUserCollectionsForModalQueryResult = Apollo.QueryResult<
  GetUserCollectionsForModalQuery,
  GetUserCollectionsForModalQueryVariables
>
export const FindUserForAdminPanelDocument = gql`
  query FindUserForAdminPanel($id: String!) {
    user: user_by_pk(id: $id) {
      ...basicUserData
      extension_version
      ios_version
      safari_extension_version
      flags {
        user_id
        can_be_impersonated
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useFindUserForAdminPanelQuery__
 *
 * To run a query within a React component, call `useFindUserForAdminPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserForAdminPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserForAdminPanelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindUserForAdminPanelQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserForAdminPanelQuery,
    FindUserForAdminPanelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindUserForAdminPanelQuery,
    FindUserForAdminPanelQueryVariables
  >(FindUserForAdminPanelDocument, options)
}
export function useFindUserForAdminPanelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserForAdminPanelQuery,
    FindUserForAdminPanelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindUserForAdminPanelQuery,
    FindUserForAdminPanelQueryVariables
  >(FindUserForAdminPanelDocument, options)
}
export type FindUserForAdminPanelQueryHookResult = ReturnType<
  typeof useFindUserForAdminPanelQuery
>
export type FindUserForAdminPanelLazyQueryHookResult = ReturnType<
  typeof useFindUserForAdminPanelLazyQuery
>
export type FindUserForAdminPanelQueryResult = Apollo.QueryResult<
  FindUserForAdminPanelQuery,
  FindUserForAdminPanelQueryVariables
>
export const SaveUserForAdminPanelDocument = gql`
  mutation SaveUserForAdminPanel(
    $id: String!
    $user_set_input: user_set_input!
  ) {
    update_user_by_pk(pk_columns: { id: $id }, _set: $user_set_input) {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
`
export type SaveUserForAdminPanelMutationFn = Apollo.MutationFunction<
  SaveUserForAdminPanelMutation,
  SaveUserForAdminPanelMutationVariables
>

/**
 * __useSaveUserForAdminPanelMutation__
 *
 * To run a mutation, you first call `useSaveUserForAdminPanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserForAdminPanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserForAdminPanelMutation, { data, loading, error }] = useSaveUserForAdminPanelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user_set_input: // value for 'user_set_input'
 *   },
 * });
 */
export function useSaveUserForAdminPanelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserForAdminPanelMutation,
    SaveUserForAdminPanelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveUserForAdminPanelMutation,
    SaveUserForAdminPanelMutationVariables
  >(SaveUserForAdminPanelDocument, options)
}
export type SaveUserForAdminPanelMutationHookResult = ReturnType<
  typeof useSaveUserForAdminPanelMutation
>
export type SaveUserForAdminPanelMutationResult =
  Apollo.MutationResult<SaveUserForAdminPanelMutation>
export type SaveUserForAdminPanelMutationOptions = Apollo.BaseMutationOptions<
  SaveUserForAdminPanelMutation,
  SaveUserForAdminPanelMutationVariables
>
export const SearchUserCartItemsDocument = gql`
  query SearchUserCartItems(
    $userId: String!
    $query: String = ""
    $limit: Int = 20
  ) {
    cart_items_match: cart_item(
      where: {
        cart: { user_id: { _eq: $userId } }
        deleted_at: { _is_null: true }
        item: { display_title: { _iregex: $query } }
      }
      limit: $limit
      order_by: { updated_at: desc_nulls_last }
    ) {
      ...cartItemData
    }
    cart_items_shop_match: cart_item(
      where: {
        cart: { user_id: { _eq: $userId } }
        deleted_at: { _is_null: true }
        shop: { display_name: { _iregex: $query } }
      }
      limit: $limit
      order_by: { updated_at: desc_nulls_last }
    ) {
      ...cartItemData
    }
    collections_match: collection(
      where: {
        user_id: { _eq: $userId }
        _or: [
          { searchable_name: { _iregex: $query } }
          { description: { _iregex: $query } }
        ]
      }
      limit: $limit
      order_by: { updated_at: desc_nulls_last }
    ) {
      id
      slug
      name
      path
      collection_items {
        id
        cart_item {
          ...cartItemData
        }
      }
    }
  }
  ${CartItemDataFragmentDoc}
`

/**
 * __useSearchUserCartItemsQuery__
 *
 * To run a query within a React component, call `useSearchUserCartItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserCartItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserCartItemsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchUserCartItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchUserCartItemsQuery,
    SearchUserCartItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchUserCartItemsQuery,
    SearchUserCartItemsQueryVariables
  >(SearchUserCartItemsDocument, options)
}
export function useSearchUserCartItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUserCartItemsQuery,
    SearchUserCartItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchUserCartItemsQuery,
    SearchUserCartItemsQueryVariables
  >(SearchUserCartItemsDocument, options)
}
export type SearchUserCartItemsQueryHookResult = ReturnType<
  typeof useSearchUserCartItemsQuery
>
export type SearchUserCartItemsLazyQueryHookResult = ReturnType<
  typeof useSearchUserCartItemsLazyQuery
>
export type SearchUserCartItemsQueryResult = Apollo.QueryResult<
  SearchUserCartItemsQuery,
  SearchUserCartItemsQueryVariables
>
export const SearchUserCollectionCartItemsDocument = gql`
  query SearchUserCollectionCartItems(
    $collectionId: uuid!
    $query: String
    $limit: Int = 20
  ) {
    collection_items_match: collection_cart_item(
      where: {
        collection_id: { _eq: $collectionId }
        cart_item: {
          _or: [
            { item: { display_title: { _iregex: $query } } }
            { shop: { display_name: { _iregex: $query } } }
          ]
        }
      }
      limit: $limit
      order_by: { updated_at: desc_nulls_last }
    ) {
      id
      order
      created_at
      updated_at
      cart_item {
        ...cartItemData
        feed_item {
          ...feedItemData
        }
      }
    }
  }
  ${CartItemDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`

/**
 * __useSearchUserCollectionCartItemsQuery__
 *
 * To run a query within a React component, call `useSearchUserCollectionCartItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserCollectionCartItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserCollectionCartItemsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchUserCollectionCartItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchUserCollectionCartItemsQuery,
    SearchUserCollectionCartItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchUserCollectionCartItemsQuery,
    SearchUserCollectionCartItemsQueryVariables
  >(SearchUserCollectionCartItemsDocument, options)
}
export function useSearchUserCollectionCartItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUserCollectionCartItemsQuery,
    SearchUserCollectionCartItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchUserCollectionCartItemsQuery,
    SearchUserCollectionCartItemsQueryVariables
  >(SearchUserCollectionCartItemsDocument, options)
}
export type SearchUserCollectionCartItemsQueryHookResult = ReturnType<
  typeof useSearchUserCollectionCartItemsQuery
>
export type SearchUserCollectionCartItemsLazyQueryHookResult = ReturnType<
  typeof useSearchUserCollectionCartItemsLazyQuery
>
export type SearchUserCollectionCartItemsQueryResult = Apollo.QueryResult<
  SearchUserCollectionCartItemsQuery,
  SearchUserCollectionCartItemsQueryVariables
>
export const SearchUserCollectionsDocument = gql`
  query SearchUserCollections(
    $userId: String!
    $query: String
    $limit: Int = 20
    $itemLimit: Int = 3
  ) {
    collections_match: collection(
      where: {
        all: { _eq: false }
        _and: [
          {
            _or: [
              { user_id: { _eq: $userId } }
              { collection_collaborators: { user_id: { _eq: $userId } } }
            ]
          }
          {
            _or: [
              { searchable_name: { _iregex: $query } }
              { description: { _iregex: $query } }
              { user: { name: { _iregex: $query } } }
              { user: { username: { _iregex: $query } } }
            ]
          }
        ]
      }
      limit: $limit
      order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
    ) {
      ...userCollectionsDataForProfilePageData
    }
  }
  ${UserCollectionsDataForProfilePageDataFragmentDoc}
`

/**
 * __useSearchUserCollectionsQuery__
 *
 * To run a query within a React component, call `useSearchUserCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserCollectionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      itemLimit: // value for 'itemLimit'
 *   },
 * });
 */
export function useSearchUserCollectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchUserCollectionsQuery,
    SearchUserCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchUserCollectionsQuery,
    SearchUserCollectionsQueryVariables
  >(SearchUserCollectionsDocument, options)
}
export function useSearchUserCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUserCollectionsQuery,
    SearchUserCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchUserCollectionsQuery,
    SearchUserCollectionsQueryVariables
  >(SearchUserCollectionsDocument, options)
}
export type SearchUserCollectionsQueryHookResult = ReturnType<
  typeof useSearchUserCollectionsQuery
>
export type SearchUserCollectionsLazyQueryHookResult = ReturnType<
  typeof useSearchUserCollectionsLazyQuery
>
export type SearchUserCollectionsQueryResult = Apollo.QueryResult<
  SearchUserCollectionsQuery,
  SearchUserCollectionsQueryVariables
>
export const SubsUserUnseenCartItemsDocument = gql`
  subscription SubsUserUnseenCartItems($id: String!) {
    user_by_pk(id: $id) {
      unseen_cart_items
    }
  }
`

/**
 * __useSubsUserUnseenCartItemsSubscription__
 *
 * To run a query within a React component, call `useSubsUserUnseenCartItemsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubsUserUnseenCartItemsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubsUserUnseenCartItemsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubsUserUnseenCartItemsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubsUserUnseenCartItemsSubscription,
    SubsUserUnseenCartItemsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubsUserUnseenCartItemsSubscription,
    SubsUserUnseenCartItemsSubscriptionVariables
  >(SubsUserUnseenCartItemsDocument, options)
}
export type SubsUserUnseenCartItemsSubscriptionHookResult = ReturnType<
  typeof useSubsUserUnseenCartItemsSubscription
>
export type SubsUserUnseenCartItemsSubscriptionResult =
  Apollo.SubscriptionResult<SubsUserUnseenCartItemsSubscription>
export const UpdateCreatorCartItemDocument = gql`
  mutation UpdateCreatorCartItem(
    $id: uuid!
    $affiliate_redirect_link_override: String
    $item_image_override: String
    $item_title_override: String
    $item_description_override: String
    $item_pricing_override: jsonb
    $image_metadata: jsonb
    $item_video_url: String
    $item_video_orientation: String
    $categories_override: [String!]
    $metadata: jsonb = {}
  ) {
    update_cart_item_by_pk(
      pk_columns: { id: $id }
      _set: {
        affiliate_redirect_link_override: $affiliate_redirect_link_override
        item_image_override: $item_image_override
        item_title_override: $item_title_override
        item_description_override: $item_description_override
        item_pricing_override: $item_pricing_override
        image_metadata: $image_metadata
        item_video_url: $item_video_url
        item_video_orientation: $item_video_orientation
        categories_override: $categories_override
      }
      _append: { metadata: $metadata }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`
export type UpdateCreatorCartItemMutationFn = Apollo.MutationFunction<
  UpdateCreatorCartItemMutation,
  UpdateCreatorCartItemMutationVariables
>

/**
 * __useUpdateCreatorCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCreatorCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreatorCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreatorCartItemMutation, { data, loading, error }] = useUpdateCreatorCartItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      affiliate_redirect_link_override: // value for 'affiliate_redirect_link_override'
 *      item_image_override: // value for 'item_image_override'
 *      item_title_override: // value for 'item_title_override'
 *      item_description_override: // value for 'item_description_override'
 *      item_pricing_override: // value for 'item_pricing_override'
 *      image_metadata: // value for 'image_metadata'
 *      item_video_url: // value for 'item_video_url'
 *      item_video_orientation: // value for 'item_video_orientation'
 *      categories_override: // value for 'categories_override'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateCreatorCartItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCreatorCartItemMutation,
    UpdateCreatorCartItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCreatorCartItemMutation,
    UpdateCreatorCartItemMutationVariables
  >(UpdateCreatorCartItemDocument, options)
}
export type UpdateCreatorCartItemMutationHookResult = ReturnType<
  typeof useUpdateCreatorCartItemMutation
>
export type UpdateCreatorCartItemMutationResult =
  Apollo.MutationResult<UpdateCreatorCartItemMutation>
export type UpdateCreatorCartItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateCreatorCartItemMutation,
  UpdateCreatorCartItemMutationVariables
>
export const GetCollectionPublishDataDocument = gql`
  query GetCollectionPublishData($id: uuid!) {
    collection_by_pk(id: $id) {
      id
      notification_preferences
      state
      paid
      exclusive
      email_excerpt
      has_valid_preview_images_computed
      user {
        id
        has_billing_account
      }
    }
  }
`

/**
 * __useGetCollectionPublishDataQuery__
 *
 * To run a query within a React component, call `useGetCollectionPublishDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionPublishDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionPublishDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionPublishDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionPublishDataQuery,
    GetCollectionPublishDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionPublishDataQuery,
    GetCollectionPublishDataQueryVariables
  >(GetCollectionPublishDataDocument, options)
}
export function useGetCollectionPublishDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionPublishDataQuery,
    GetCollectionPublishDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionPublishDataQuery,
    GetCollectionPublishDataQueryVariables
  >(GetCollectionPublishDataDocument, options)
}
export type GetCollectionPublishDataQueryHookResult = ReturnType<
  typeof useGetCollectionPublishDataQuery
>
export type GetCollectionPublishDataLazyQueryHookResult = ReturnType<
  typeof useGetCollectionPublishDataLazyQuery
>
export type GetCollectionPublishDataQueryResult = Apollo.QueryResult<
  GetCollectionPublishDataQuery,
  GetCollectionPublishDataQueryVariables
>
export const UpdateShopItemIsInappropriateDocument = gql`
  mutation updateShopItemIsInappropriate(
    $id: uuid!
    $is_inappropiate: Boolean!
  ) {
    update_shop_item_by_pk(
      pk_columns: { id: $id }
      _set: { is_inappropriate: $is_inappropiate }
    ) {
      id
      is_inappropriate
    }
  }
`
export type UpdateShopItemIsInappropriateMutationFn = Apollo.MutationFunction<
  UpdateShopItemIsInappropriateMutation,
  UpdateShopItemIsInappropriateMutationVariables
>

/**
 * __useUpdateShopItemIsInappropriateMutation__
 *
 * To run a mutation, you first call `useUpdateShopItemIsInappropriateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopItemIsInappropriateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopItemIsInappropriateMutation, { data, loading, error }] = useUpdateShopItemIsInappropriateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      is_inappropiate: // value for 'is_inappropiate'
 *   },
 * });
 */
export function useUpdateShopItemIsInappropriateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShopItemIsInappropriateMutation,
    UpdateShopItemIsInappropriateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateShopItemIsInappropriateMutation,
    UpdateShopItemIsInappropriateMutationVariables
  >(UpdateShopItemIsInappropriateDocument, options)
}
export type UpdateShopItemIsInappropriateMutationHookResult = ReturnType<
  typeof useUpdateShopItemIsInappropriateMutation
>
export type UpdateShopItemIsInappropriateMutationResult =
  Apollo.MutationResult<UpdateShopItemIsInappropriateMutation>
export type UpdateShopItemIsInappropriateMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateShopItemIsInappropriateMutation,
    UpdateShopItemIsInappropriateMutationVariables
  >
export const GetDiscoveryFeedForSearchDocument = gql`
  query GetDiscoveryFeedForSearch($limit: Int = 25, $itemLimit: Int = 3) {
    discovery_feed(
      where: { feed_item: { item_type: { _eq: collection } } }
      limit: $limit
      order_by: { sequential_id: desc }
    ) {
      id
      feed_item {
        id
        collection {
          ...collectionCarouselData
        }
      }
    }
  }
  ${CollectionCarouselDataFragmentDoc}
`

/**
 * __useGetDiscoveryFeedForSearchQuery__
 *
 * To run a query within a React component, call `useGetDiscoveryFeedForSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoveryFeedForSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoveryFeedForSearchQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      itemLimit: // value for 'itemLimit'
 *   },
 * });
 */
export function useGetDiscoveryFeedForSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDiscoveryFeedForSearchQuery,
    GetDiscoveryFeedForSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDiscoveryFeedForSearchQuery,
    GetDiscoveryFeedForSearchQueryVariables
  >(GetDiscoveryFeedForSearchDocument, options)
}
export function useGetDiscoveryFeedForSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscoveryFeedForSearchQuery,
    GetDiscoveryFeedForSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDiscoveryFeedForSearchQuery,
    GetDiscoveryFeedForSearchQueryVariables
  >(GetDiscoveryFeedForSearchDocument, options)
}
export type GetDiscoveryFeedForSearchQueryHookResult = ReturnType<
  typeof useGetDiscoveryFeedForSearchQuery
>
export type GetDiscoveryFeedForSearchLazyQueryHookResult = ReturnType<
  typeof useGetDiscoveryFeedForSearchLazyQuery
>
export type GetDiscoveryFeedForSearchQueryResult = Apollo.QueryResult<
  GetDiscoveryFeedForSearchQuery,
  GetDiscoveryFeedForSearchQueryVariables
>
export const GetUserNotificationPreferencesDocument = gql`
  query GetUserNotificationPreferences {
    notification_preference {
      id
      communication_template
      allowed
      allowable_communication
    }
  }
`

/**
 * __useGetUserNotificationPreferencesQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserNotificationPreferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserNotificationPreferencesQuery,
    GetUserNotificationPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserNotificationPreferencesQuery,
    GetUserNotificationPreferencesQueryVariables
  >(GetUserNotificationPreferencesDocument, options)
}
export function useGetUserNotificationPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserNotificationPreferencesQuery,
    GetUserNotificationPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserNotificationPreferencesQuery,
    GetUserNotificationPreferencesQueryVariables
  >(GetUserNotificationPreferencesDocument, options)
}
export type GetUserNotificationPreferencesQueryHookResult = ReturnType<
  typeof useGetUserNotificationPreferencesQuery
>
export type GetUserNotificationPreferencesLazyQueryHookResult = ReturnType<
  typeof useGetUserNotificationPreferencesLazyQuery
>
export type GetUserNotificationPreferencesQueryResult = Apollo.QueryResult<
  GetUserNotificationPreferencesQuery,
  GetUserNotificationPreferencesQueryVariables
>
export const FindShopItemAndShopByUrlDocument = gql`
  query FindShopItemAndShopByUrl($url: String!, $shopSlug: name!) {
    shop_item(where: { url: { _eq: $url } }) {
      id
      item_id
      url
      title
      image
      display_title
      source
      enrichment_status
      shop {
        id
        key
        type
        name
        slug
        favicon
      }
    }
    shop(
      where: { slug: { _eq: $shopSlug } }
      order_by: { cart_items_count: desc }
      limit: 1
    ) {
      id
      key
      type
      name
      slug
      favicon
    }
  }
`

/**
 * __useFindShopItemAndShopByUrlQuery__
 *
 * To run a query within a React component, call `useFindShopItemAndShopByUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShopItemAndShopByUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShopItemAndShopByUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *      shopSlug: // value for 'shopSlug'
 *   },
 * });
 */
export function useFindShopItemAndShopByUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindShopItemAndShopByUrlQuery,
    FindShopItemAndShopByUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindShopItemAndShopByUrlQuery,
    FindShopItemAndShopByUrlQueryVariables
  >(FindShopItemAndShopByUrlDocument, options)
}
export function useFindShopItemAndShopByUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindShopItemAndShopByUrlQuery,
    FindShopItemAndShopByUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindShopItemAndShopByUrlQuery,
    FindShopItemAndShopByUrlQueryVariables
  >(FindShopItemAndShopByUrlDocument, options)
}
export type FindShopItemAndShopByUrlQueryHookResult = ReturnType<
  typeof useFindShopItemAndShopByUrlQuery
>
export type FindShopItemAndShopByUrlLazyQueryHookResult = ReturnType<
  typeof useFindShopItemAndShopByUrlLazyQuery
>
export type FindShopItemAndShopByUrlQueryResult = Apollo.QueryResult<
  FindShopItemAndShopByUrlQuery,
  FindShopItemAndShopByUrlQueryVariables
>
export const FindUserSubscriptionDocument = gql`
  query FindUserSubscription($id: uuid!) {
    user_subscription_by_pk(id: $id) {
      id
      subscription_type
      status
      plan
      created_at
      updated_at
      cancel_at
      creator {
        id
        name
        username
        picture
        verified
        has_billing_account
      }
    }
  }
`

/**
 * __useFindUserSubscriptionQuery__
 *
 * To run a query within a React component, call `useFindUserSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindUserSubscriptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserSubscriptionQuery,
    FindUserSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindUserSubscriptionQuery,
    FindUserSubscriptionQueryVariables
  >(FindUserSubscriptionDocument, options)
}
export function useFindUserSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserSubscriptionQuery,
    FindUserSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindUserSubscriptionQuery,
    FindUserSubscriptionQueryVariables
  >(FindUserSubscriptionDocument, options)
}
export type FindUserSubscriptionQueryHookResult = ReturnType<
  typeof useFindUserSubscriptionQuery
>
export type FindUserSubscriptionLazyQueryHookResult = ReturnType<
  typeof useFindUserSubscriptionLazyQuery
>
export type FindUserSubscriptionQueryResult = Apollo.QueryResult<
  FindUserSubscriptionQuery,
  FindUserSubscriptionQueryVariables
>
export const GetCollectionsForShopDocument = gql`
  query GetCollectionsForShop(
    $key: String!
    $limit: Int = 30
    $offset: Int = 0
    $itemLimit: Int = 3
  ) {
    collections: shop_collections(
      args: { shop_key: $key }
      limit: $limit
      offset: $offset
    ) {
      ...collectionCarouselData
    }
  }
  ${CollectionCarouselDataFragmentDoc}
`

/**
 * __useGetCollectionsForShopQuery__
 *
 * To run a query within a React component, call `useGetCollectionsForShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsForShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsForShopQuery({
 *   variables: {
 *      key: // value for 'key'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      itemLimit: // value for 'itemLimit'
 *   },
 * });
 */
export function useGetCollectionsForShopQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionsForShopQuery,
    GetCollectionsForShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionsForShopQuery,
    GetCollectionsForShopQueryVariables
  >(GetCollectionsForShopDocument, options)
}
export function useGetCollectionsForShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionsForShopQuery,
    GetCollectionsForShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionsForShopQuery,
    GetCollectionsForShopQueryVariables
  >(GetCollectionsForShopDocument, options)
}
export type GetCollectionsForShopQueryHookResult = ReturnType<
  typeof useGetCollectionsForShopQuery
>
export type GetCollectionsForShopLazyQueryHookResult = ReturnType<
  typeof useGetCollectionsForShopLazyQuery
>
export type GetCollectionsForShopQueryResult = Apollo.QueryResult<
  GetCollectionsForShopQuery,
  GetCollectionsForShopQueryVariables
>
export const GetCreatorSubscribersDocument = gql`
  query GetCreatorSubscribers(
    $creator_id: String!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    creator_user_subscriptions(
      where: {
        creator_id: { _eq: $creator_id }
        subscriber: { deleted_at: { _is_null: true } }
      }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      status
      created_at
      interval
      status
      subscription_type
      subscriber_email
      subscriber_phone
      subscriber {
        id
        display_name
      }
    }
    creator_user_subscriptions_aggregate(
      where: {
        creator_id: { _eq: $creator_id }
        subscriber: { deleted_at: { _is_null: true } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

/**
 * __useGetCreatorSubscribersQuery__
 *
 * To run a query within a React component, call `useGetCreatorSubscribersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatorSubscribersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatorSubscribersQuery({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCreatorSubscribersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreatorSubscribersQuery,
    GetCreatorSubscribersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCreatorSubscribersQuery,
    GetCreatorSubscribersQueryVariables
  >(GetCreatorSubscribersDocument, options)
}
export function useGetCreatorSubscribersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreatorSubscribersQuery,
    GetCreatorSubscribersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCreatorSubscribersQuery,
    GetCreatorSubscribersQueryVariables
  >(GetCreatorSubscribersDocument, options)
}
export type GetCreatorSubscribersQueryHookResult = ReturnType<
  typeof useGetCreatorSubscribersQuery
>
export type GetCreatorSubscribersLazyQueryHookResult = ReturnType<
  typeof useGetCreatorSubscribersLazyQuery
>
export type GetCreatorSubscribersQueryResult = Apollo.QueryResult<
  GetCreatorSubscribersQuery,
  GetCreatorSubscribersQueryVariables
>
export const GetRelatedUsersForShopItemDocument = gql`
  query GetRelatedUsersForShopItem($shop_item_id: uuid, $limit: Int = 10)
  @cached {
    related_users_for_shop_item(
      args: { shop_item_id: $shop_item_id }
      where: { has_claimed_username_computed: { _eq: true } }
      limit: $limit
    ) {
      id
      username
      picture
    }
  }
`

/**
 * __useGetRelatedUsersForShopItemQuery__
 *
 * To run a query within a React component, call `useGetRelatedUsersForShopItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedUsersForShopItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedUsersForShopItemQuery({
 *   variables: {
 *      shop_item_id: // value for 'shop_item_id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetRelatedUsersForShopItemQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRelatedUsersForShopItemQuery,
    GetRelatedUsersForShopItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetRelatedUsersForShopItemQuery,
    GetRelatedUsersForShopItemQueryVariables
  >(GetRelatedUsersForShopItemDocument, options)
}
export function useGetRelatedUsersForShopItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRelatedUsersForShopItemQuery,
    GetRelatedUsersForShopItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetRelatedUsersForShopItemQuery,
    GetRelatedUsersForShopItemQueryVariables
  >(GetRelatedUsersForShopItemDocument, options)
}
export type GetRelatedUsersForShopItemQueryHookResult = ReturnType<
  typeof useGetRelatedUsersForShopItemQuery
>
export type GetRelatedUsersForShopItemLazyQueryHookResult = ReturnType<
  typeof useGetRelatedUsersForShopItemLazyQuery
>
export type GetRelatedUsersForShopItemQueryResult = Apollo.QueryResult<
  GetRelatedUsersForShopItemQuery,
  GetRelatedUsersForShopItemQueryVariables
>
export const GetSubscriberChartDataForCreatorDocument = gql`
  query GetSubscriberChartDataForCreator(
    $creator_id: String!
    $start_date: String!
    $end_date: String!
  ) {
    total_subscribers_aggregate: creator_user_subscriptions_aggregate(
      where: {
        creator_id: { _eq: $creator_id }
        subscriber: { deleted_at: { _is_null: true } }
      }
    ) {
      aggregate {
        count
      }
    }
    creatorSubscriberChartData(
      creator_id: $creator_id
      start_date: $start_date
      end_date: $end_date
    ) {
      day
      subscribers
      paid_subscribers
      total_paid_subscribers
      total_subscribers
    }
  }
`

/**
 * __useGetSubscriberChartDataForCreatorQuery__
 *
 * To run a query within a React component, call `useGetSubscriberChartDataForCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriberChartDataForCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriberChartDataForCreatorQuery({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function useGetSubscriberChartDataForCreatorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriberChartDataForCreatorQuery,
    GetSubscriberChartDataForCreatorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSubscriberChartDataForCreatorQuery,
    GetSubscriberChartDataForCreatorQueryVariables
  >(GetSubscriberChartDataForCreatorDocument, options)
}
export function useGetSubscriberChartDataForCreatorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriberChartDataForCreatorQuery,
    GetSubscriberChartDataForCreatorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSubscriberChartDataForCreatorQuery,
    GetSubscriberChartDataForCreatorQueryVariables
  >(GetSubscriberChartDataForCreatorDocument, options)
}
export type GetSubscriberChartDataForCreatorQueryHookResult = ReturnType<
  typeof useGetSubscriberChartDataForCreatorQuery
>
export type GetSubscriberChartDataForCreatorLazyQueryHookResult = ReturnType<
  typeof useGetSubscriberChartDataForCreatorLazyQuery
>
export type GetSubscriberChartDataForCreatorQueryResult = Apollo.QueryResult<
  GetSubscriberChartDataForCreatorQuery,
  GetSubscriberChartDataForCreatorQueryVariables
>
export const GetUserForSettingsPageDocument = gql`
  query GetUserForSettingsPage($id: String!, $includeLinks: Boolean = false) {
    user: user_by_pk(id: $id) {
      ...basicUserData
      bio
      bio_link
      has_claimed_username_computed
      has_set_name_computed
      has_picture_set
      has_billing_account
      links @include(if: $includeLinks) {
        url
        link_type
        type {
          value
          description
        }
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetUserForSettingsPageQuery__
 *
 * To run a query within a React component, call `useGetUserForSettingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForSettingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForSettingsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeLinks: // value for 'includeLinks'
 *   },
 * });
 */
export function useGetUserForSettingsPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserForSettingsPageQuery,
    GetUserForSettingsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserForSettingsPageQuery,
    GetUserForSettingsPageQueryVariables
  >(GetUserForSettingsPageDocument, options)
}
export function useGetUserForSettingsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserForSettingsPageQuery,
    GetUserForSettingsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserForSettingsPageQuery,
    GetUserForSettingsPageQueryVariables
  >(GetUserForSettingsPageDocument, options)
}
export type GetUserForSettingsPageQueryHookResult = ReturnType<
  typeof useGetUserForSettingsPageQuery
>
export type GetUserForSettingsPageLazyQueryHookResult = ReturnType<
  typeof useGetUserForSettingsPageLazyQuery
>
export type GetUserForSettingsPageQueryResult = Apollo.QueryResult<
  GetUserForSettingsPageQuery,
  GetUserForSettingsPageQueryVariables
>
export const GetUserOnboardingProgressDocument = gql`
  query GetUserOnboardingProgress($id: String!) {
    user: user_by_pk(id: $id) {
      id
      username
      display_name
      has_claimed_username_computed
      has_set_name_computed
      has_picture_set
      has_billing_account
      metadata
      onboarded
      collections_aggregate(where: { computed_is_visible: { _eq: true } }) {
        aggregate {
          count
        }
      }
    }
  }
`

/**
 * __useGetUserOnboardingProgressQuery__
 *
 * To run a query within a React component, call `useGetUserOnboardingProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOnboardingProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOnboardingProgressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserOnboardingProgressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserOnboardingProgressQuery,
    GetUserOnboardingProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserOnboardingProgressQuery,
    GetUserOnboardingProgressQueryVariables
  >(GetUserOnboardingProgressDocument, options)
}
export function useGetUserOnboardingProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserOnboardingProgressQuery,
    GetUserOnboardingProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserOnboardingProgressQuery,
    GetUserOnboardingProgressQueryVariables
  >(GetUserOnboardingProgressDocument, options)
}
export type GetUserOnboardingProgressQueryHookResult = ReturnType<
  typeof useGetUserOnboardingProgressQuery
>
export type GetUserOnboardingProgressLazyQueryHookResult = ReturnType<
  typeof useGetUserOnboardingProgressLazyQuery
>
export type GetUserOnboardingProgressQueryResult = Apollo.QueryResult<
  GetUserOnboardingProgressQuery,
  GetUserOnboardingProgressQueryVariables
>
export const GetUserPendingWebBookmarksDocument = gql`
  query GetUserPendingWebBookmarks($user_id: String!) {
    web_pending_bookmark_workflows: workflow(
      where: {
        status: { _eq: pending }
        type: { _eq: web_bookmark }
        user_id: { _eq: $user_id }
      }
      order_by: { created_at: desc_nulls_last }
      limit: 5
    ) {
      id
      data
      status
    }
  }
`

/**
 * __useGetUserPendingWebBookmarksQuery__
 *
 * To run a query within a React component, call `useGetUserPendingWebBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPendingWebBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPendingWebBookmarksQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserPendingWebBookmarksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserPendingWebBookmarksQuery,
    GetUserPendingWebBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserPendingWebBookmarksQuery,
    GetUserPendingWebBookmarksQueryVariables
  >(GetUserPendingWebBookmarksDocument, options)
}
export function useGetUserPendingWebBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPendingWebBookmarksQuery,
    GetUserPendingWebBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserPendingWebBookmarksQuery,
    GetUserPendingWebBookmarksQueryVariables
  >(GetUserPendingWebBookmarksDocument, options)
}
export type GetUserPendingWebBookmarksQueryHookResult = ReturnType<
  typeof useGetUserPendingWebBookmarksQuery
>
export type GetUserPendingWebBookmarksLazyQueryHookResult = ReturnType<
  typeof useGetUserPendingWebBookmarksLazyQuery
>
export type GetUserPendingWebBookmarksQueryResult = Apollo.QueryResult<
  GetUserPendingWebBookmarksQuery,
  GetUserPendingWebBookmarksQueryVariables
>
export const GetUserSubscriptionsDocument = gql`
  query GetUserSubscriptions($id: String, $offset: Int = 0, $limit: Int = 10) {
    user_subscription_aggregate(
      where: { user_id: { _eq: $id }, status: { _eq: active } }
    ) {
      aggregate {
        count
      }
    }
    user_subscription(
      where: { user_id: { _eq: $id }, status: { _eq: active } }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      subscription_type
      status
      plan
      created_at
      updated_at
      cancel_at
      creator {
        id
        name
        username
        picture
        verified
        has_billing_account
      }
    }
  }
`

/**
 * __useGetUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserSubscriptionsQuery,
    GetUserSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserSubscriptionsQuery,
    GetUserSubscriptionsQueryVariables
  >(GetUserSubscriptionsDocument, options)
}
export function useGetUserSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSubscriptionsQuery,
    GetUserSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserSubscriptionsQuery,
    GetUserSubscriptionsQueryVariables
  >(GetUserSubscriptionsDocument, options)
}
export type GetUserSubscriptionsQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionsQuery
>
export type GetUserSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionsLazyQuery
>
export type GetUserSubscriptionsQueryResult = Apollo.QueryResult<
  GetUserSubscriptionsQuery,
  GetUserSubscriptionsQueryVariables
>
export const AdminFindShopByKeyDocument = gql`
  query AdminFindShopByKey($key: String!) {
    shop(
      where: { key: { _eq: $key } }
      order_by: { cart_items_count: desc }
      limit: 1
    ) {
      ...adminCategorizationShopData
    }
  }
  ${AdminCategorizationShopDataFragmentDoc}
`

/**
 * __useAdminFindShopByKeyQuery__
 *
 * To run a query within a React component, call `useAdminFindShopByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFindShopByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFindShopByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useAdminFindShopByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminFindShopByKeyQuery,
    AdminFindShopByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFindShopByKeyQuery,
    AdminFindShopByKeyQueryVariables
  >(AdminFindShopByKeyDocument, options)
}
export function useAdminFindShopByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFindShopByKeyQuery,
    AdminFindShopByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFindShopByKeyQuery,
    AdminFindShopByKeyQueryVariables
  >(AdminFindShopByKeyDocument, options)
}
export type AdminFindShopByKeyQueryHookResult = ReturnType<
  typeof useAdminFindShopByKeyQuery
>
export type AdminFindShopByKeyLazyQueryHookResult = ReturnType<
  typeof useAdminFindShopByKeyLazyQuery
>
export type AdminFindShopByKeyQueryResult = Apollo.QueryResult<
  AdminFindShopByKeyQuery,
  AdminFindShopByKeyQueryVariables
>
export const AdminFindShopBySlugDocument = gql`
  query AdminFindShopBySlug($slug: name!) {
    shop(
      where: { slug: { _eq: $slug } }
      order_by: { cart_items_count: desc }
      limit: 1
    ) {
      ...adminCategorizationShopData
    }
  }
  ${AdminCategorizationShopDataFragmentDoc}
`

/**
 * __useAdminFindShopBySlugQuery__
 *
 * To run a query within a React component, call `useAdminFindShopBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFindShopBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFindShopBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAdminFindShopBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminFindShopBySlugQuery,
    AdminFindShopBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFindShopBySlugQuery,
    AdminFindShopBySlugQueryVariables
  >(AdminFindShopBySlugDocument, options)
}
export function useAdminFindShopBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFindShopBySlugQuery,
    AdminFindShopBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFindShopBySlugQuery,
    AdminFindShopBySlugQueryVariables
  >(AdminFindShopBySlugDocument, options)
}
export type AdminFindShopBySlugQueryHookResult = ReturnType<
  typeof useAdminFindShopBySlugQuery
>
export type AdminFindShopBySlugLazyQueryHookResult = ReturnType<
  typeof useAdminFindShopBySlugLazyQuery
>
export type AdminFindShopBySlugQueryResult = Apollo.QueryResult<
  AdminFindShopBySlugQuery,
  AdminFindShopBySlugQueryVariables
>
export const DeleteMobileHomeBlockAdminDocument = gql`
  mutation DeleteMobileHomeBlockAdmin($id: uuid!) {
    delete_mobile_home_by_pk(id: $id) {
      id
    }
  }
`
export type DeleteMobileHomeBlockAdminMutationFn = Apollo.MutationFunction<
  DeleteMobileHomeBlockAdminMutation,
  DeleteMobileHomeBlockAdminMutationVariables
>

/**
 * __useDeleteMobileHomeBlockAdminMutation__
 *
 * To run a mutation, you first call `useDeleteMobileHomeBlockAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMobileHomeBlockAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMobileHomeBlockAdminMutation, { data, loading, error }] = useDeleteMobileHomeBlockAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMobileHomeBlockAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMobileHomeBlockAdminMutation,
    DeleteMobileHomeBlockAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteMobileHomeBlockAdminMutation,
    DeleteMobileHomeBlockAdminMutationVariables
  >(DeleteMobileHomeBlockAdminDocument, options)
}
export type DeleteMobileHomeBlockAdminMutationHookResult = ReturnType<
  typeof useDeleteMobileHomeBlockAdminMutation
>
export type DeleteMobileHomeBlockAdminMutationResult =
  Apollo.MutationResult<DeleteMobileHomeBlockAdminMutation>
export type DeleteMobileHomeBlockAdminMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteMobileHomeBlockAdminMutation,
    DeleteMobileHomeBlockAdminMutationVariables
  >
export const FindUserForDeletionDocument = gql`
  query FindUserForDeletion($email: String!, $is_admin: Boolean = false) {
    users: user(where: { email: { _eq: $email } }) {
      ...basicUserData
      email @include(if: $is_admin)
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useFindUserForDeletionQuery__
 *
 * To run a query within a React component, call `useFindUserForDeletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserForDeletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserForDeletionQuery({
 *   variables: {
 *      email: // value for 'email'
 *      is_admin: // value for 'is_admin'
 *   },
 * });
 */
export function useFindUserForDeletionQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserForDeletionQuery,
    FindUserForDeletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindUserForDeletionQuery,
    FindUserForDeletionQueryVariables
  >(FindUserForDeletionDocument, options)
}
export function useFindUserForDeletionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserForDeletionQuery,
    FindUserForDeletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindUserForDeletionQuery,
    FindUserForDeletionQueryVariables
  >(FindUserForDeletionDocument, options)
}
export type FindUserForDeletionQueryHookResult = ReturnType<
  typeof useFindUserForDeletionQuery
>
export type FindUserForDeletionLazyQueryHookResult = ReturnType<
  typeof useFindUserForDeletionLazyQuery
>
export type FindUserForDeletionQueryResult = Apollo.QueryResult<
  FindUserForDeletionQuery,
  FindUserForDeletionQueryVariables
>
export const GetAdminFeaturedShopsForCategrizationDocument = gql`
  query GetAdminFeaturedShopsForCategrization($limit: Int = 100) {
    shop_popularity(
      limit: $limit
      order_by: { popularity_rank: desc_nulls_last }
    ) {
      key
      popularity_rank
      shop {
        ...adminCategorizationShopData
      }
    }
  }
  ${AdminCategorizationShopDataFragmentDoc}
`

/**
 * __useGetAdminFeaturedShopsForCategrizationQuery__
 *
 * To run a query within a React component, call `useGetAdminFeaturedShopsForCategrizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminFeaturedShopsForCategrizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminFeaturedShopsForCategrizationQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAdminFeaturedShopsForCategrizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminFeaturedShopsForCategrizationQuery,
    GetAdminFeaturedShopsForCategrizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAdminFeaturedShopsForCategrizationQuery,
    GetAdminFeaturedShopsForCategrizationQueryVariables
  >(GetAdminFeaturedShopsForCategrizationDocument, options)
}
export function useGetAdminFeaturedShopsForCategrizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminFeaturedShopsForCategrizationQuery,
    GetAdminFeaturedShopsForCategrizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAdminFeaturedShopsForCategrizationQuery,
    GetAdminFeaturedShopsForCategrizationQueryVariables
  >(GetAdminFeaturedShopsForCategrizationDocument, options)
}
export type GetAdminFeaturedShopsForCategrizationQueryHookResult = ReturnType<
  typeof useGetAdminFeaturedShopsForCategrizationQuery
>
export type GetAdminFeaturedShopsForCategrizationLazyQueryHookResult =
  ReturnType<typeof useGetAdminFeaturedShopsForCategrizationLazyQuery>
export type GetAdminFeaturedShopsForCategrizationQueryResult =
  Apollo.QueryResult<
    GetAdminFeaturedShopsForCategrizationQuery,
    GetAdminFeaturedShopsForCategrizationQueryVariables
  >
export const GetAdminShopsForCategrizationDocument = gql`
  query GetAdminShopsForCategrization($limit: Int = 25) @cached {
    shop_categories: shop_category(order_by: { value: asc }) {
      value
      label
    }
    shop_popularity(
      where: { shop: { _not: { shop_categorizations: {} }, cart_items: {} } }
      order_by: { popularity_rank: desc_nulls_last }
      limit: $limit
    ) {
      key
      popularity_rank
      shop {
        ...adminCategorizationShopData
      }
    }
  }
  ${AdminCategorizationShopDataFragmentDoc}
`

/**
 * __useGetAdminShopsForCategrizationQuery__
 *
 * To run a query within a React component, call `useGetAdminShopsForCategrizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminShopsForCategrizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminShopsForCategrizationQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAdminShopsForCategrizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminShopsForCategrizationQuery,
    GetAdminShopsForCategrizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAdminShopsForCategrizationQuery,
    GetAdminShopsForCategrizationQueryVariables
  >(GetAdminShopsForCategrizationDocument, options)
}
export function useGetAdminShopsForCategrizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminShopsForCategrizationQuery,
    GetAdminShopsForCategrizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAdminShopsForCategrizationQuery,
    GetAdminShopsForCategrizationQueryVariables
  >(GetAdminShopsForCategrizationDocument, options)
}
export type GetAdminShopsForCategrizationQueryHookResult = ReturnType<
  typeof useGetAdminShopsForCategrizationQuery
>
export type GetAdminShopsForCategrizationLazyQueryHookResult = ReturnType<
  typeof useGetAdminShopsForCategrizationLazyQuery
>
export type GetAdminShopsForCategrizationQueryResult = Apollo.QueryResult<
  GetAdminShopsForCategrizationQuery,
  GetAdminShopsForCategrizationQueryVariables
>
export const GetAdminShopsNoFaviconDocument = gql`
  query GetAdminShopsNoFavicon {
    no_favicon_aggregate: shop_aggregate(
      where: {
        _or: [{ favicon: { _eq: "" } }, { favicon: { _is_null: true } }]
      }
    ) {
      aggregate {
        count
      }
    }
    no_favicon_shops: shop(
      where: {
        _or: [{ favicon: { _eq: "" } }, { favicon: { _is_null: true } }]
      }
      order_by: { cart_items_count: desc }
      limit: 50
    ) {
      ...adminCategorizationShopData
    }
  }
  ${AdminCategorizationShopDataFragmentDoc}
`

/**
 * __useGetAdminShopsNoFaviconQuery__
 *
 * To run a query within a React component, call `useGetAdminShopsNoFaviconQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminShopsNoFaviconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminShopsNoFaviconQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminShopsNoFaviconQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminShopsNoFaviconQuery,
    GetAdminShopsNoFaviconQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAdminShopsNoFaviconQuery,
    GetAdminShopsNoFaviconQueryVariables
  >(GetAdminShopsNoFaviconDocument, options)
}
export function useGetAdminShopsNoFaviconLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminShopsNoFaviconQuery,
    GetAdminShopsNoFaviconQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAdminShopsNoFaviconQuery,
    GetAdminShopsNoFaviconQueryVariables
  >(GetAdminShopsNoFaviconDocument, options)
}
export type GetAdminShopsNoFaviconQueryHookResult = ReturnType<
  typeof useGetAdminShopsNoFaviconQuery
>
export type GetAdminShopsNoFaviconLazyQueryHookResult = ReturnType<
  typeof useGetAdminShopsNoFaviconLazyQuery
>
export type GetAdminShopsNoFaviconQueryResult = Apollo.QueryResult<
  GetAdminShopsNoFaviconQuery,
  GetAdminShopsNoFaviconQueryVariables
>
export const GetMobileHomeBlocksAdminDocument = gql`
  query GetMobileHomeBlocksAdmin {
    home_blocks: mobile_home(
      where: { type: { _eq: "home" } }
      order_by: { order: asc_nulls_last }
    ) {
      ...mobileHomeBlock
    }
    deals_blocks: mobile_home(
      where: { type: { _eq: "deals" } }
      order_by: { order: asc_nulls_last }
    ) {
      ...mobileHomeBlock
    }
  }
  ${MobileHomeBlockFragmentDoc}
`

/**
 * __useGetMobileHomeBlocksAdminQuery__
 *
 * To run a query within a React component, call `useGetMobileHomeBlocksAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileHomeBlocksAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileHomeBlocksAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMobileHomeBlocksAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMobileHomeBlocksAdminQuery,
    GetMobileHomeBlocksAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMobileHomeBlocksAdminQuery,
    GetMobileHomeBlocksAdminQueryVariables
  >(GetMobileHomeBlocksAdminDocument, options)
}
export function useGetMobileHomeBlocksAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMobileHomeBlocksAdminQuery,
    GetMobileHomeBlocksAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMobileHomeBlocksAdminQuery,
    GetMobileHomeBlocksAdminQueryVariables
  >(GetMobileHomeBlocksAdminDocument, options)
}
export type GetMobileHomeBlocksAdminQueryHookResult = ReturnType<
  typeof useGetMobileHomeBlocksAdminQuery
>
export type GetMobileHomeBlocksAdminLazyQueryHookResult = ReturnType<
  typeof useGetMobileHomeBlocksAdminLazyQuery
>
export type GetMobileHomeBlocksAdminQueryResult = Apollo.QueryResult<
  GetMobileHomeBlocksAdminQuery,
  GetMobileHomeBlocksAdminQueryVariables
>
export const GetSimilarProductsHistoryAdminDocument = gql`
  query GetSimilarProductsHistoryAdmin($id: uuid!) {
    similar_products_history(where: { id: { _eq: $id } }, limit: 1) {
      id
      similar_products
      image_url
      cart_item {
        id
        display_title
        source
        item {
          url
        }
      }
      shop_item {
        id
        display_title
        source
        url
      }
      user {
        id
        username
        name
        picture
      }
    }
  }
`

/**
 * __useGetSimilarProductsHistoryAdminQuery__
 *
 * To run a query within a React component, call `useGetSimilarProductsHistoryAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimilarProductsHistoryAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimilarProductsHistoryAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSimilarProductsHistoryAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSimilarProductsHistoryAdminQuery,
    GetSimilarProductsHistoryAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSimilarProductsHistoryAdminQuery,
    GetSimilarProductsHistoryAdminQueryVariables
  >(GetSimilarProductsHistoryAdminDocument, options)
}
export function useGetSimilarProductsHistoryAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSimilarProductsHistoryAdminQuery,
    GetSimilarProductsHistoryAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSimilarProductsHistoryAdminQuery,
    GetSimilarProductsHistoryAdminQueryVariables
  >(GetSimilarProductsHistoryAdminDocument, options)
}
export type GetSimilarProductsHistoryAdminQueryHookResult = ReturnType<
  typeof useGetSimilarProductsHistoryAdminQuery
>
export type GetSimilarProductsHistoryAdminLazyQueryHookResult = ReturnType<
  typeof useGetSimilarProductsHistoryAdminLazyQuery
>
export type GetSimilarProductsHistoryAdminQueryResult = Apollo.QueryResult<
  GetSimilarProductsHistoryAdminQuery,
  GetSimilarProductsHistoryAdminQueryVariables
>
export const SaveShopItemCategoriesDocument = gql`
  mutation SaveShopItemCategories(
    $shopItemId: uuid!
    $categories: [String!]!
    $keywords: [String!]
  ) {
    update_shop_item_by_pk(
      pk_columns: { id: $shopItemId }
      _set: { categories: $categories, keywords: $keywords }
    ) {
      id
      categories
      keywords
    }
  }
`
export type SaveShopItemCategoriesMutationFn = Apollo.MutationFunction<
  SaveShopItemCategoriesMutation,
  SaveShopItemCategoriesMutationVariables
>

/**
 * __useSaveShopItemCategoriesMutation__
 *
 * To run a mutation, you first call `useSaveShopItemCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveShopItemCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveShopItemCategoriesMutation, { data, loading, error }] = useSaveShopItemCategoriesMutation({
 *   variables: {
 *      shopItemId: // value for 'shopItemId'
 *      categories: // value for 'categories'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useSaveShopItemCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveShopItemCategoriesMutation,
    SaveShopItemCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveShopItemCategoriesMutation,
    SaveShopItemCategoriesMutationVariables
  >(SaveShopItemCategoriesDocument, options)
}
export type SaveShopItemCategoriesMutationHookResult = ReturnType<
  typeof useSaveShopItemCategoriesMutation
>
export type SaveShopItemCategoriesMutationResult =
  Apollo.MutationResult<SaveShopItemCategoriesMutation>
export type SaveShopItemCategoriesMutationOptions = Apollo.BaseMutationOptions<
  SaveShopItemCategoriesMutation,
  SaveShopItemCategoriesMutationVariables
>
export const SearchAdminShopsForCategrizationDocument = gql`
  query SearchAdminShopsForCategrization(
    $string_comparison_exp: String_comparison_exp!
  ) {
    shops: shop(
      where: { key: $string_comparison_exp }
      order_by: { name: asc }
      limit: 100
    ) {
      ...adminCategorizationShopData
    }
  }
  ${AdminCategorizationShopDataFragmentDoc}
`

/**
 * __useSearchAdminShopsForCategrizationQuery__
 *
 * To run a query within a React component, call `useSearchAdminShopsForCategrizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAdminShopsForCategrizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAdminShopsForCategrizationQuery({
 *   variables: {
 *      string_comparison_exp: // value for 'string_comparison_exp'
 *   },
 * });
 */
export function useSearchAdminShopsForCategrizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAdminShopsForCategrizationQuery,
    SearchAdminShopsForCategrizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchAdminShopsForCategrizationQuery,
    SearchAdminShopsForCategrizationQueryVariables
  >(SearchAdminShopsForCategrizationDocument, options)
}
export function useSearchAdminShopsForCategrizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAdminShopsForCategrizationQuery,
    SearchAdminShopsForCategrizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchAdminShopsForCategrizationQuery,
    SearchAdminShopsForCategrizationQueryVariables
  >(SearchAdminShopsForCategrizationDocument, options)
}
export type SearchAdminShopsForCategrizationQueryHookResult = ReturnType<
  typeof useSearchAdminShopsForCategrizationQuery
>
export type SearchAdminShopsForCategrizationLazyQueryHookResult = ReturnType<
  typeof useSearchAdminShopsForCategrizationLazyQuery
>
export type SearchAdminShopsForCategrizationQueryResult = Apollo.QueryResult<
  SearchAdminShopsForCategrizationQuery,
  SearchAdminShopsForCategrizationQueryVariables
>
export const UpsertMobileHomeBlocksAdminDocument = gql`
  mutation UpsertMobileHomeBlocksAdmin($objects: [mobile_home_insert_input!]!) {
    insert_mobile_home(
      objects: $objects
      on_conflict: {
        constraint: mobile_home_asset_url_link_url_type_key
        update_columns: [order, asset_url, link_url, enabled, active, type]
      }
    ) {
      affected_rows
    }
  }
`
export type UpsertMobileHomeBlocksAdminMutationFn = Apollo.MutationFunction<
  UpsertMobileHomeBlocksAdminMutation,
  UpsertMobileHomeBlocksAdminMutationVariables
>

/**
 * __useUpsertMobileHomeBlocksAdminMutation__
 *
 * To run a mutation, you first call `useUpsertMobileHomeBlocksAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMobileHomeBlocksAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMobileHomeBlocksAdminMutation, { data, loading, error }] = useUpsertMobileHomeBlocksAdminMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertMobileHomeBlocksAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertMobileHomeBlocksAdminMutation,
    UpsertMobileHomeBlocksAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertMobileHomeBlocksAdminMutation,
    UpsertMobileHomeBlocksAdminMutationVariables
  >(UpsertMobileHomeBlocksAdminDocument, options)
}
export type UpsertMobileHomeBlocksAdminMutationHookResult = ReturnType<
  typeof useUpsertMobileHomeBlocksAdminMutation
>
export type UpsertMobileHomeBlocksAdminMutationResult =
  Apollo.MutationResult<UpsertMobileHomeBlocksAdminMutation>
export type UpsertMobileHomeBlocksAdminMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertMobileHomeBlocksAdminMutation,
    UpsertMobileHomeBlocksAdminMutationVariables
  >
export const FindCollectionByIdForCollectionPageDocument = gql`
  query FindCollectionByIdForCollectionPage(
    $id: uuid!
    $itemLimit: Int = 25
    $collectionItemsCount: Int = 4
    $include_seo_fields: Boolean = true
    $include_feed_item: Boolean = false
    $preloadItem: Boolean = false
    $cartItemId: uuid
  ) {
    collections: collection(
      where: { id: { _eq: $id } }
      order_by: [{ order: asc }, { cart_item_max_updated_at: desc_nulls_last }]
      limit: 1
    ) {
      collection_items_aggregate {
        aggregate {
          count
        }
      }
      collection_collaborators_aggregate {
        aggregate {
          count
        }
      }
      followed_by_user
      is_a_collaborator
      total_likes_count
      feed_item @include(if: $include_feed_item) {
        ...feedItemData
      }
      media_enabled_join {
        ...mediaEnabledItemData
      }
      ...collectionAndItemsData
      preloaded_collection_items: collection_items(
        where: { cart_item_id: { _eq: $cartItemId } }
        limit: 1
      ) @include(if: $preloadItem) {
        ...preloadedCollectionCartItemData
      }
    }
  }
  ${FeedItemDataFragmentDoc}
  ${MediaEnabledItemDataFragmentDoc}
  ${CollectionAndItemsDataFragmentDoc}
  ${PreloadedCollectionCartItemDataFragmentDoc}
`

/**
 * __useFindCollectionByIdForCollectionPageQuery__
 *
 * To run a query within a React component, call `useFindCollectionByIdForCollectionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCollectionByIdForCollectionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCollectionByIdForCollectionPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      itemLimit: // value for 'itemLimit'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *      include_seo_fields: // value for 'include_seo_fields'
 *      include_feed_item: // value for 'include_feed_item'
 *      preloadItem: // value for 'preloadItem'
 *      cartItemId: // value for 'cartItemId'
 *   },
 * });
 */
export function useFindCollectionByIdForCollectionPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCollectionByIdForCollectionPageQuery,
    FindCollectionByIdForCollectionPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCollectionByIdForCollectionPageQuery,
    FindCollectionByIdForCollectionPageQueryVariables
  >(FindCollectionByIdForCollectionPageDocument, options)
}
export function useFindCollectionByIdForCollectionPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCollectionByIdForCollectionPageQuery,
    FindCollectionByIdForCollectionPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCollectionByIdForCollectionPageQuery,
    FindCollectionByIdForCollectionPageQueryVariables
  >(FindCollectionByIdForCollectionPageDocument, options)
}
export type FindCollectionByIdForCollectionPageQueryHookResult = ReturnType<
  typeof useFindCollectionByIdForCollectionPageQuery
>
export type FindCollectionByIdForCollectionPageLazyQueryHookResult = ReturnType<
  typeof useFindCollectionByIdForCollectionPageLazyQuery
>
export type FindCollectionByIdForCollectionPageQueryResult = Apollo.QueryResult<
  FindCollectionByIdForCollectionPageQuery,
  FindCollectionByIdForCollectionPageQueryVariables
>
export const FindCollectionByUsernameAndSlugDocument = gql`
  query FindCollectionByUsernameAndSlug(
    $username: String!
    $slug: name!
    $preloadItem: Boolean = false
    $cartItemId: uuid
    $itemLimit: Int = 25
    $collectionItemsCount: Int = 4
    $include_seo_fields: Boolean = true
    $include_feed_item: Boolean = false
  ) {
    collections: collection(
      where: { user: { username: { _eq: $username } }, slug: { _eq: $slug } }
      order_by: [{ order: asc }, { cart_item_max_updated_at: desc_nulls_last }]
      limit: 1
    ) {
      collection_items_aggregate {
        aggregate {
          count
        }
      }
      collection_collaborators_aggregate {
        aggregate {
          count
        }
      }
      followed_by_user
      is_a_collaborator
      total_likes_count
      feed_item @include(if: $include_feed_item) {
        ...feedItemData
      }
      media_enabled_join {
        ...mediaEnabledItemData
      }
      ...collectionAndItemsData
      preloaded_collection_items: collection_items(
        where: { cart_item_id: { _eq: $cartItemId } }
        limit: 1
      ) @include(if: $preloadItem) {
        ...preloadedCollectionCartItemData
      }
    }
  }
  ${FeedItemDataFragmentDoc}
  ${MediaEnabledItemDataFragmentDoc}
  ${CollectionAndItemsDataFragmentDoc}
  ${PreloadedCollectionCartItemDataFragmentDoc}
`

/**
 * __useFindCollectionByUsernameAndSlugQuery__
 *
 * To run a query within a React component, call `useFindCollectionByUsernameAndSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCollectionByUsernameAndSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCollectionByUsernameAndSlugQuery({
 *   variables: {
 *      username: // value for 'username'
 *      slug: // value for 'slug'
 *      preloadItem: // value for 'preloadItem'
 *      cartItemId: // value for 'cartItemId'
 *      itemLimit: // value for 'itemLimit'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *      include_seo_fields: // value for 'include_seo_fields'
 *      include_feed_item: // value for 'include_feed_item'
 *   },
 * });
 */
export function useFindCollectionByUsernameAndSlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCollectionByUsernameAndSlugQuery,
    FindCollectionByUsernameAndSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCollectionByUsernameAndSlugQuery,
    FindCollectionByUsernameAndSlugQueryVariables
  >(FindCollectionByUsernameAndSlugDocument, options)
}
export function useFindCollectionByUsernameAndSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCollectionByUsernameAndSlugQuery,
    FindCollectionByUsernameAndSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCollectionByUsernameAndSlugQuery,
    FindCollectionByUsernameAndSlugQueryVariables
  >(FindCollectionByUsernameAndSlugDocument, options)
}
export type FindCollectionByUsernameAndSlugQueryHookResult = ReturnType<
  typeof useFindCollectionByUsernameAndSlugQuery
>
export type FindCollectionByUsernameAndSlugLazyQueryHookResult = ReturnType<
  typeof useFindCollectionByUsernameAndSlugLazyQuery
>
export type FindCollectionByUsernameAndSlugQueryResult = Apollo.QueryResult<
  FindCollectionByUsernameAndSlugQuery,
  FindCollectionByUsernameAndSlugQueryVariables
>
export const TogglePremiumCollectionDocument = gql`
  mutation TogglePremiumCollection(
    $collection_id: uuid!
    $exclusive: Boolean!
  ) {
    collection: update_collection_by_pk(
      _set: { exclusive: $exclusive }
      pk_columns: { id: $collection_id }
    ) {
      id
      exclusive
    }
  }
`
export type TogglePremiumCollectionMutationFn = Apollo.MutationFunction<
  TogglePremiumCollectionMutation,
  TogglePremiumCollectionMutationVariables
>

/**
 * __useTogglePremiumCollectionMutation__
 *
 * To run a mutation, you first call `useTogglePremiumCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePremiumCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePremiumCollectionMutation, { data, loading, error }] = useTogglePremiumCollectionMutation({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *      exclusive: // value for 'exclusive'
 *   },
 * });
 */
export function useTogglePremiumCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TogglePremiumCollectionMutation,
    TogglePremiumCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TogglePremiumCollectionMutation,
    TogglePremiumCollectionMutationVariables
  >(TogglePremiumCollectionDocument, options)
}
export type TogglePremiumCollectionMutationHookResult = ReturnType<
  typeof useTogglePremiumCollectionMutation
>
export type TogglePremiumCollectionMutationResult =
  Apollo.MutationResult<TogglePremiumCollectionMutation>
export type TogglePremiumCollectionMutationOptions = Apollo.BaseMutationOptions<
  TogglePremiumCollectionMutation,
  TogglePremiumCollectionMutationVariables
>
export const UpdateCollectionDocument = gql`
  mutation UpdateCollection(
    $id: uuid!
    $set_input: collection_set_input!
    $metadata: jsonb = {}
  ) {
    update_collection_by_pk(
      pk_columns: { id: $id }
      _set: $set_input
      _append: { metadata: $metadata }
    ) {
      id
      name
      slug
      curation
      collection_link
      cover_image
      collection_cover_images
      description
      seo_fields
      metadata
    }
  }
`
export type UpdateCollectionMutationFn = Apollo.MutationFunction<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMutation, { data, loading, error }] = useUpdateCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set_input: // value for 'set_input'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectionMutation,
    UpdateCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCollectionMutation,
    UpdateCollectionMutationVariables
  >(UpdateCollectionDocument, options)
}
export type UpdateCollectionMutationHookResult = ReturnType<
  typeof useUpdateCollectionMutation
>
export type UpdateCollectionMutationResult =
  Apollo.MutationResult<UpdateCollectionMutation>
export type UpdateCollectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>
export const DeleteMediaItemDocument = gql`
  mutation DeleteMediaItem($id: uuid!) {
    delete_media_item_by_pk(id: $id) {
      id
      media_type
      media_url
    }
  }
`
export type DeleteMediaItemMutationFn = Apollo.MutationFunction<
  DeleteMediaItemMutation,
  DeleteMediaItemMutationVariables
>

/**
 * __useDeleteMediaItemMutation__
 *
 * To run a mutation, you first call `useDeleteMediaItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediaItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediaItemMutation, { data, loading, error }] = useDeleteMediaItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMediaItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMediaItemMutation,
    DeleteMediaItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteMediaItemMutation,
    DeleteMediaItemMutationVariables
  >(DeleteMediaItemDocument, options)
}
export type DeleteMediaItemMutationHookResult = ReturnType<
  typeof useDeleteMediaItemMutation
>
export type DeleteMediaItemMutationResult =
  Apollo.MutationResult<DeleteMediaItemMutation>
export type DeleteMediaItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteMediaItemMutation,
  DeleteMediaItemMutationVariables
>
export const GetDiscoveryFeedWebDocument = gql`
  query GetDiscoveryFeedWeb(
    $limit: Int = 10
    $offset: Int = 0
    $collectionItemsCount: Int = 5
  ) {
    discovery_feed(
      offset: $offset
      order_by: { sequential_id: desc }
      limit: $limit
    ) {
      id
      sequential_id
      feed_item {
        ...discoveryFeedFeedItem
      }
    }
  }
  ${DiscoveryFeedFeedItemFragmentDoc}
`

/**
 * __useGetDiscoveryFeedWebQuery__
 *
 * To run a query within a React component, call `useGetDiscoveryFeedWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoveryFeedWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoveryFeedWebQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *   },
 * });
 */
export function useGetDiscoveryFeedWebQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDiscoveryFeedWebQuery,
    GetDiscoveryFeedWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDiscoveryFeedWebQuery,
    GetDiscoveryFeedWebQueryVariables
  >(GetDiscoveryFeedWebDocument, options)
}
export function useGetDiscoveryFeedWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscoveryFeedWebQuery,
    GetDiscoveryFeedWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDiscoveryFeedWebQuery,
    GetDiscoveryFeedWebQueryVariables
  >(GetDiscoveryFeedWebDocument, options)
}
export type GetDiscoveryFeedWebQueryHookResult = ReturnType<
  typeof useGetDiscoveryFeedWebQuery
>
export type GetDiscoveryFeedWebLazyQueryHookResult = ReturnType<
  typeof useGetDiscoveryFeedWebLazyQuery
>
export type GetDiscoveryFeedWebQueryResult = Apollo.QueryResult<
  GetDiscoveryFeedWebQuery,
  GetDiscoveryFeedWebQueryVariables
>
export const GetStealTheirLooksWebDocument = gql`
  query GetStealTheirLooksWeb($seed: seed_float = "0.0", $limit: Int = 3) {
    steal_their_looks_random(args: { seed: $seed }, limit: $limit) {
      id
      slug
      cover_image
      preview_collection_items(limit: 1) {
        id
        cart_item {
          id
          item_image_override
          image_metadata
          cart {
            id
            user {
              id
            }
          }
          item {
            id
            cached_image
            image
            image_highres
            cached_image
            image_metadata
            metadata
            is_inappropriate
          }
        }
      }
      user {
        id
        username
        name
      }
    }
  }
`

/**
 * __useGetStealTheirLooksWebQuery__
 *
 * To run a query within a React component, call `useGetStealTheirLooksWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStealTheirLooksWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStealTheirLooksWebQuery({
 *   variables: {
 *      seed: // value for 'seed'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetStealTheirLooksWebQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStealTheirLooksWebQuery,
    GetStealTheirLooksWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetStealTheirLooksWebQuery,
    GetStealTheirLooksWebQueryVariables
  >(GetStealTheirLooksWebDocument, options)
}
export function useGetStealTheirLooksWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStealTheirLooksWebQuery,
    GetStealTheirLooksWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetStealTheirLooksWebQuery,
    GetStealTheirLooksWebQueryVariables
  >(GetStealTheirLooksWebDocument, options)
}
export type GetStealTheirLooksWebQueryHookResult = ReturnType<
  typeof useGetStealTheirLooksWebQuery
>
export type GetStealTheirLooksWebLazyQueryHookResult = ReturnType<
  typeof useGetStealTheirLooksWebLazyQuery
>
export type GetStealTheirLooksWebQueryResult = Apollo.QueryResult<
  GetStealTheirLooksWebQuery,
  GetStealTheirLooksWebQueryVariables
>
export const FetchShoppingProductsDocument = gql`
  query FetchShoppingProducts(
    $query: String!
    $location: String
    $red: Int
    $blue: Int
    $green: Int
  ) {
    shoppingProducts(
      query: $query
      location: $location
      red: $red
      blue: $blue
      green: $green
    ) {
      delivery
      extracted_price
      link
      position
      price
      product_link
      source
      tag
      thumbnail
      title
    }
  }
`

/**
 * __useFetchShoppingProductsQuery__
 *
 * To run a query within a React component, call `useFetchShoppingProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchShoppingProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchShoppingProductsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      location: // value for 'location'
 *      red: // value for 'red'
 *      blue: // value for 'blue'
 *      green: // value for 'green'
 *   },
 * });
 */
export function useFetchShoppingProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchShoppingProductsQuery,
    FetchShoppingProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchShoppingProductsQuery,
    FetchShoppingProductsQueryVariables
  >(FetchShoppingProductsDocument, options)
}
export function useFetchShoppingProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchShoppingProductsQuery,
    FetchShoppingProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchShoppingProductsQuery,
    FetchShoppingProductsQueryVariables
  >(FetchShoppingProductsDocument, options)
}
export type FetchShoppingProductsQueryHookResult = ReturnType<
  typeof useFetchShoppingProductsQuery
>
export type FetchShoppingProductsLazyQueryHookResult = ReturnType<
  typeof useFetchShoppingProductsLazyQuery
>
export type FetchShoppingProductsQueryResult = Apollo.QueryResult<
  FetchShoppingProductsQuery,
  FetchShoppingProductsQueryVariables
>
export const FetchSimilarProductsDocument = gql`
  query FetchSimilarProducts(
    $imageUrl: String!
    $shopItemId: uuid
    $cartItemId: uuid
    $allResults: Boolean = false
    $noCache: Boolean = false
  ) @cached(ttl: 300, refresh: $noCache) {
    similarProducts(
      imageUrl: $imageUrl
      shopItemId: $shopItemId
      cartItemId: $cartItemId
      allResults: $allResults
      noCache: $noCache
    ) {
      position
      title
      link
      source
      shop_popularity
      source_icon
      price {
        value
        extracted_value
        currency
      }
      thumbnail
      points
      max_take_rate
    }
  }
`

/**
 * __useFetchSimilarProductsQuery__
 *
 * To run a query within a React component, call `useFetchSimilarProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSimilarProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSimilarProductsQuery({
 *   variables: {
 *      imageUrl: // value for 'imageUrl'
 *      shopItemId: // value for 'shopItemId'
 *      cartItemId: // value for 'cartItemId'
 *      allResults: // value for 'allResults'
 *      noCache: // value for 'noCache'
 *   },
 * });
 */
export function useFetchSimilarProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSimilarProductsQuery,
    FetchSimilarProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchSimilarProductsQuery,
    FetchSimilarProductsQueryVariables
  >(FetchSimilarProductsDocument, options)
}
export function useFetchSimilarProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSimilarProductsQuery,
    FetchSimilarProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchSimilarProductsQuery,
    FetchSimilarProductsQueryVariables
  >(FetchSimilarProductsDocument, options)
}
export type FetchSimilarProductsQueryHookResult = ReturnType<
  typeof useFetchSimilarProductsQuery
>
export type FetchSimilarProductsLazyQueryHookResult = ReturnType<
  typeof useFetchSimilarProductsLazyQuery
>
export type FetchSimilarProductsQueryResult = Apollo.QueryResult<
  FetchSimilarProductsQuery,
  FetchSimilarProductsQueryVariables
>
export const FindCollectionByUsernameAndShortIdDocument = gql`
  query FindCollectionByUsernameAndShortId(
    $username: String!
    $shortId: name!
    $itemLimit: Int = 25
    $collectionItemsCount: Int = 4
    $include_seo_fields: Boolean = true
    $include_feed_item: Boolean = false
  ) {
    collections: collection(
      where: {
        user: { username: { _eq: $username } }
        short_id: { _eq: $shortId }
      }
      order_by: [{ order: asc }, { cart_item_max_updated_at: desc_nulls_last }]
      limit: 1
    ) {
      collection_items_aggregate {
        aggregate {
          count
        }
      }
      collection_collaborators_aggregate {
        aggregate {
          count
        }
      }
      followed_by_user
      is_a_collaborator
      total_likes_count
      feed_item @include(if: $include_feed_item) {
        ...feedItemData
      }
      media_enabled_join {
        ...mediaEnabledItemData
      }
      ...collectionAndItemsData
    }
  }
  ${FeedItemDataFragmentDoc}
  ${MediaEnabledItemDataFragmentDoc}
  ${CollectionAndItemsDataFragmentDoc}
`

/**
 * __useFindCollectionByUsernameAndShortIdQuery__
 *
 * To run a query within a React component, call `useFindCollectionByUsernameAndShortIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCollectionByUsernameAndShortIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCollectionByUsernameAndShortIdQuery({
 *   variables: {
 *      username: // value for 'username'
 *      shortId: // value for 'shortId'
 *      itemLimit: // value for 'itemLimit'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *      include_seo_fields: // value for 'include_seo_fields'
 *      include_feed_item: // value for 'include_feed_item'
 *   },
 * });
 */
export function useFindCollectionByUsernameAndShortIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCollectionByUsernameAndShortIdQuery,
    FindCollectionByUsernameAndShortIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCollectionByUsernameAndShortIdQuery,
    FindCollectionByUsernameAndShortIdQueryVariables
  >(FindCollectionByUsernameAndShortIdDocument, options)
}
export function useFindCollectionByUsernameAndShortIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCollectionByUsernameAndShortIdQuery,
    FindCollectionByUsernameAndShortIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCollectionByUsernameAndShortIdQuery,
    FindCollectionByUsernameAndShortIdQueryVariables
  >(FindCollectionByUsernameAndShortIdDocument, options)
}
export type FindCollectionByUsernameAndShortIdQueryHookResult = ReturnType<
  typeof useFindCollectionByUsernameAndShortIdQuery
>
export type FindCollectionByUsernameAndShortIdLazyQueryHookResult = ReturnType<
  typeof useFindCollectionByUsernameAndShortIdLazyQuery
>
export type FindCollectionByUsernameAndShortIdQueryResult = Apollo.QueryResult<
  FindCollectionByUsernameAndShortIdQuery,
  FindCollectionByUsernameAndShortIdQueryVariables
>
export const FindCollectionCollaboratorDocument = gql`
  query FindCollectionCollaborator($collectionId: uuid!) {
    collection_by_pk(id: $collectionId) {
      id
      is_a_collaborator
    }
  }
`

/**
 * __useFindCollectionCollaboratorQuery__
 *
 * To run a query within a React component, call `useFindCollectionCollaboratorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCollectionCollaboratorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCollectionCollaboratorQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useFindCollectionCollaboratorQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCollectionCollaboratorQuery,
    FindCollectionCollaboratorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCollectionCollaboratorQuery,
    FindCollectionCollaboratorQueryVariables
  >(FindCollectionCollaboratorDocument, options)
}
export function useFindCollectionCollaboratorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCollectionCollaboratorQuery,
    FindCollectionCollaboratorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCollectionCollaboratorQuery,
    FindCollectionCollaboratorQueryVariables
  >(FindCollectionCollaboratorDocument, options)
}
export type FindCollectionCollaboratorQueryHookResult = ReturnType<
  typeof useFindCollectionCollaboratorQuery
>
export type FindCollectionCollaboratorLazyQueryHookResult = ReturnType<
  typeof useFindCollectionCollaboratorLazyQuery
>
export type FindCollectionCollaboratorQueryResult = Apollo.QueryResult<
  FindCollectionCollaboratorQuery,
  FindCollectionCollaboratorQueryVariables
>
export const FindMediaForItemTypeDocument = gql`
  query FindMediaForItemType(
    $item_id: uuid!
    $item_type: media_item_type_enum!
  ) {
    media_enabled_item_by_pk(item_id: $item_id, item_type: $item_type) {
      ...mediaEnabledItemData
    }
  }
  ${MediaEnabledItemDataFragmentDoc}
`

/**
 * __useFindMediaForItemTypeQuery__
 *
 * To run a query within a React component, call `useFindMediaForItemTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMediaForItemTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMediaForItemTypeQuery({
 *   variables: {
 *      item_id: // value for 'item_id'
 *      item_type: // value for 'item_type'
 *   },
 * });
 */
export function useFindMediaForItemTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindMediaForItemTypeQuery,
    FindMediaForItemTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindMediaForItemTypeQuery,
    FindMediaForItemTypeQueryVariables
  >(FindMediaForItemTypeDocument, options)
}
export function useFindMediaForItemTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindMediaForItemTypeQuery,
    FindMediaForItemTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindMediaForItemTypeQuery,
    FindMediaForItemTypeQueryVariables
  >(FindMediaForItemTypeDocument, options)
}
export type FindMediaForItemTypeQueryHookResult = ReturnType<
  typeof useFindMediaForItemTypeQuery
>
export type FindMediaForItemTypeLazyQueryHookResult = ReturnType<
  typeof useFindMediaForItemTypeLazyQuery
>
export type FindMediaForItemTypeQueryResult = Apollo.QueryResult<
  FindMediaForItemTypeQuery,
  FindMediaForItemTypeQueryVariables
>
export const FindRelatedShopByCategoryDocument = gql`
  query FindRelatedShopByCategory($shopKey: String!) @cached {
    related_shops: related_shops_by_category(
      args: { shop_key: $shopKey }
      limit: 8
    ) {
      ...shopData
      cover_image
      banner_image
      shop_categorizations {
        weight
        category
      }
    }
  }
  ${ShopDataFragmentDoc}
`

/**
 * __useFindRelatedShopByCategoryQuery__
 *
 * To run a query within a React component, call `useFindRelatedShopByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRelatedShopByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRelatedShopByCategoryQuery({
 *   variables: {
 *      shopKey: // value for 'shopKey'
 *   },
 * });
 */
export function useFindRelatedShopByCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindRelatedShopByCategoryQuery,
    FindRelatedShopByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindRelatedShopByCategoryQuery,
    FindRelatedShopByCategoryQueryVariables
  >(FindRelatedShopByCategoryDocument, options)
}
export function useFindRelatedShopByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindRelatedShopByCategoryQuery,
    FindRelatedShopByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindRelatedShopByCategoryQuery,
    FindRelatedShopByCategoryQueryVariables
  >(FindRelatedShopByCategoryDocument, options)
}
export type FindRelatedShopByCategoryQueryHookResult = ReturnType<
  typeof useFindRelatedShopByCategoryQuery
>
export type FindRelatedShopByCategoryLazyQueryHookResult = ReturnType<
  typeof useFindRelatedShopByCategoryLazyQuery
>
export type FindRelatedShopByCategoryQueryResult = Apollo.QueryResult<
  FindRelatedShopByCategoryQuery,
  FindRelatedShopByCategoryQueryVariables
>
export const GetCreatorsToSubscribeRandomDocument = gql`
  query getCreatorsToSubscribeRandom(
    $exclude_usernames: [String!] = []
    $seed: seed_float = "0.0"
    $limit: Int = 15
    $include_other_creators: Boolean = true
  ) {
    vip_creators: creator_subscribers_random(
      where: {
        _and: [
          { username: { _nin: $exclude_usernames } }
          { username: { _niregex: "(test|carrot4)" } }
          { subscription_status: { _eq: "none" } }
          { icon_order: { _lt: 0 } }
        ]
      }
      args: { seed: $seed }
      limit: $limit
    ) {
      ...basicUserData
    }
    other_creators: creator_subscribers_random(
      where: {
        _and: [
          { username: { _nin: $exclude_usernames } }
          { username: { _niregex: "(test|carrot4)" } }
          { subscription_status: { _eq: "none" } }
          { icon_order: { _gte: 0 } }
        ]
      }
      args: { seed: $seed }
      limit: $limit
    ) @include(if: $include_other_creators) {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetCreatorsToSubscribeRandomQuery__
 *
 * To run a query within a React component, call `useGetCreatorsToSubscribeRandomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatorsToSubscribeRandomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatorsToSubscribeRandomQuery({
 *   variables: {
 *      exclude_usernames: // value for 'exclude_usernames'
 *      seed: // value for 'seed'
 *      limit: // value for 'limit'
 *      include_other_creators: // value for 'include_other_creators'
 *   },
 * });
 */
export function useGetCreatorsToSubscribeRandomQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCreatorsToSubscribeRandomQuery,
    GetCreatorsToSubscribeRandomQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCreatorsToSubscribeRandomQuery,
    GetCreatorsToSubscribeRandomQueryVariables
  >(GetCreatorsToSubscribeRandomDocument, options)
}
export function useGetCreatorsToSubscribeRandomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreatorsToSubscribeRandomQuery,
    GetCreatorsToSubscribeRandomQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCreatorsToSubscribeRandomQuery,
    GetCreatorsToSubscribeRandomQueryVariables
  >(GetCreatorsToSubscribeRandomDocument, options)
}
export type GetCreatorsToSubscribeRandomQueryHookResult = ReturnType<
  typeof useGetCreatorsToSubscribeRandomQuery
>
export type GetCreatorsToSubscribeRandomLazyQueryHookResult = ReturnType<
  typeof useGetCreatorsToSubscribeRandomLazyQuery
>
export type GetCreatorsToSubscribeRandomQueryResult = Apollo.QueryResult<
  GetCreatorsToSubscribeRandomQuery,
  GetCreatorsToSubscribeRandomQueryVariables
>
export const GetDataForCollectionsSitemapDocument = gql`
  query GetDataForCollectionsSitemap {
    collections: collection(
      where: {
        all: { _eq: false }
        _and: [
          { private: { _eq: false } }
          {
            _or: [{ state: { _eq: published } }, { state: { _is_null: true } }]
          }
        ]
        collection_items_aggregate: { count: { predicate: { _gte: 3 } } }
      }
      order_by: { cart_item_max_updated_at: desc_nulls_last }
      limit: 2000
    ) {
      id
      slug
      seo_fields
      user {
        username
      }
      collection_items_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

/**
 * __useGetDataForCollectionsSitemapQuery__
 *
 * To run a query within a React component, call `useGetDataForCollectionsSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForCollectionsSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForCollectionsSitemapQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDataForCollectionsSitemapQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDataForCollectionsSitemapQuery,
    GetDataForCollectionsSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDataForCollectionsSitemapQuery,
    GetDataForCollectionsSitemapQueryVariables
  >(GetDataForCollectionsSitemapDocument, options)
}
export function useGetDataForCollectionsSitemapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataForCollectionsSitemapQuery,
    GetDataForCollectionsSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDataForCollectionsSitemapQuery,
    GetDataForCollectionsSitemapQueryVariables
  >(GetDataForCollectionsSitemapDocument, options)
}
export type GetDataForCollectionsSitemapQueryHookResult = ReturnType<
  typeof useGetDataForCollectionsSitemapQuery
>
export type GetDataForCollectionsSitemapLazyQueryHookResult = ReturnType<
  typeof useGetDataForCollectionsSitemapLazyQuery
>
export type GetDataForCollectionsSitemapQueryResult = Apollo.QueryResult<
  GetDataForCollectionsSitemapQuery,
  GetDataForCollectionsSitemapQueryVariables
>
export const GetDataForProfilesSitemapDocument = gql`
  query GetDataForProfilesSitemap {
    users: user(
      where: {
        anonymous: { _eq: false }
        has_claimed_username_computed: { _eq: true }
        collections_aggregate: { count: { predicate: { _gt: 2 } } }
        collections: { computed_is_visible: { _eq: true } }
      }
      order_by: [
        { icon_order: asc }
        { verified: desc_nulls_last }
        { is_creator_computed: desc }
        { is_curator_computed: desc }
        { collections_aggregate: { count: desc_nulls_last } }
      ]
      limit: 2000
    ) {
      id
      username
      is_icon_computed
      is_creator_computed
      is_curator_computed
      verified
    }
  }
`

/**
 * __useGetDataForProfilesSitemapQuery__
 *
 * To run a query within a React component, call `useGetDataForProfilesSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForProfilesSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForProfilesSitemapQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDataForProfilesSitemapQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDataForProfilesSitemapQuery,
    GetDataForProfilesSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDataForProfilesSitemapQuery,
    GetDataForProfilesSitemapQueryVariables
  >(GetDataForProfilesSitemapDocument, options)
}
export function useGetDataForProfilesSitemapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataForProfilesSitemapQuery,
    GetDataForProfilesSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDataForProfilesSitemapQuery,
    GetDataForProfilesSitemapQueryVariables
  >(GetDataForProfilesSitemapDocument, options)
}
export type GetDataForProfilesSitemapQueryHookResult = ReturnType<
  typeof useGetDataForProfilesSitemapQuery
>
export type GetDataForProfilesSitemapLazyQueryHookResult = ReturnType<
  typeof useGetDataForProfilesSitemapLazyQuery
>
export type GetDataForProfilesSitemapQueryResult = Apollo.QueryResult<
  GetDataForProfilesSitemapQuery,
  GetDataForProfilesSitemapQueryVariables
>
export const GetOwnShopItemsForPicksDocument = gql`
  query GetOwnShopItemsForPicks(
    $username: String!
    $shopKey: String!
    $limit: Int = 25
    $offset: Int = 0
  ) {
    cart_item(
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc_nulls_last }
      where: {
        cart: {
          shop_key: { _eq: $shopKey }
          user: { username: { _eq: $username } }
        }
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
        hidden: { _eq: false }
      }
    ) {
      ...cartItemData
      collection_cart_items {
        collection {
          id
          name
          slug
        }
      }
    }
    cart_item_aggregate(
      where: {
        cart: {
          shop_key: { _eq: $shopKey }
          user: { username: { _eq: $username } }
        }
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
        hidden: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
  }
  ${CartItemDataFragmentDoc}
`

/**
 * __useGetOwnShopItemsForPicksQuery__
 *
 * To run a query within a React component, call `useGetOwnShopItemsForPicksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnShopItemsForPicksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnShopItemsForPicksQuery({
 *   variables: {
 *      username: // value for 'username'
 *      shopKey: // value for 'shopKey'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOwnShopItemsForPicksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOwnShopItemsForPicksQuery,
    GetOwnShopItemsForPicksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOwnShopItemsForPicksQuery,
    GetOwnShopItemsForPicksQueryVariables
  >(GetOwnShopItemsForPicksDocument, options)
}
export function useGetOwnShopItemsForPicksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnShopItemsForPicksQuery,
    GetOwnShopItemsForPicksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOwnShopItemsForPicksQuery,
    GetOwnShopItemsForPicksQueryVariables
  >(GetOwnShopItemsForPicksDocument, options)
}
export type GetOwnShopItemsForPicksQueryHookResult = ReturnType<
  typeof useGetOwnShopItemsForPicksQuery
>
export type GetOwnShopItemsForPicksLazyQueryHookResult = ReturnType<
  typeof useGetOwnShopItemsForPicksLazyQuery
>
export type GetOwnShopItemsForPicksQueryResult = Apollo.QueryResult<
  GetOwnShopItemsForPicksQuery,
  GetOwnShopItemsForPicksQueryVariables
>
export const GetRandomDiscoveryFeedForProfileDocument = gql`
  query GetRandomDiscoveryFeedForProfile(
    $username: String!
    $seed: seed_float = "0.0"
    $collectionItemsCount: Int = 3
  ) {
    discovery_feed: discovery_feed_random(
      args: { seed: $seed }
      where: {
        feed_item: {
          item_type: { _eq: collection }
          collection: {
            computed_is_visible: { _eq: true }
            user: { username: { _neq: $username } }
          }
        }
      }
      limit: 6
    ) {
      id
      feed_item {
        ...feedItemData
        collection {
          ...discoveryCollection
        }
      }
    }
  }
  ${FeedItemDataFragmentDoc}
  ${DiscoveryCollectionFragmentDoc}
`

/**
 * __useGetRandomDiscoveryFeedForProfileQuery__
 *
 * To run a query within a React component, call `useGetRandomDiscoveryFeedForProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRandomDiscoveryFeedForProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRandomDiscoveryFeedForProfileQuery({
 *   variables: {
 *      username: // value for 'username'
 *      seed: // value for 'seed'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *   },
 * });
 */
export function useGetRandomDiscoveryFeedForProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRandomDiscoveryFeedForProfileQuery,
    GetRandomDiscoveryFeedForProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetRandomDiscoveryFeedForProfileQuery,
    GetRandomDiscoveryFeedForProfileQueryVariables
  >(GetRandomDiscoveryFeedForProfileDocument, options)
}
export function useGetRandomDiscoveryFeedForProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRandomDiscoveryFeedForProfileQuery,
    GetRandomDiscoveryFeedForProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetRandomDiscoveryFeedForProfileQuery,
    GetRandomDiscoveryFeedForProfileQueryVariables
  >(GetRandomDiscoveryFeedForProfileDocument, options)
}
export type GetRandomDiscoveryFeedForProfileQueryHookResult = ReturnType<
  typeof useGetRandomDiscoveryFeedForProfileQuery
>
export type GetRandomDiscoveryFeedForProfileLazyQueryHookResult = ReturnType<
  typeof useGetRandomDiscoveryFeedForProfileLazyQuery
>
export type GetRandomDiscoveryFeedForProfileQueryResult = Apollo.QueryResult<
  GetRandomDiscoveryFeedForProfileQuery,
  GetRandomDiscoveryFeedForProfileQueryVariables
>
export const GetShopsFeaturedForShopPageDocument = gql`
  query GetShopsFeaturedForShopPage($featured_shops_limit: Int = 15) {
    shop_popularity(
      where: { shop: { cover_image: { _is_null: false, _neq: "" } } }
      limit: $featured_shops_limit
      order_by: { popularity_rank: desc_nulls_last }
    ) {
      key
      popularity_rank
      shop {
        id
        key
        slug
        display_name
        cover_image
        favicon
        parsed_take_rate
      }
    }
  }
`

/**
 * __useGetShopsFeaturedForShopPageQuery__
 *
 * To run a query within a React component, call `useGetShopsFeaturedForShopPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopsFeaturedForShopPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopsFeaturedForShopPageQuery({
 *   variables: {
 *      featured_shops_limit: // value for 'featured_shops_limit'
 *   },
 * });
 */
export function useGetShopsFeaturedForShopPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetShopsFeaturedForShopPageQuery,
    GetShopsFeaturedForShopPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopsFeaturedForShopPageQuery,
    GetShopsFeaturedForShopPageQueryVariables
  >(GetShopsFeaturedForShopPageDocument, options)
}
export function useGetShopsFeaturedForShopPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopsFeaturedForShopPageQuery,
    GetShopsFeaturedForShopPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopsFeaturedForShopPageQuery,
    GetShopsFeaturedForShopPageQueryVariables
  >(GetShopsFeaturedForShopPageDocument, options)
}
export type GetShopsFeaturedForShopPageQueryHookResult = ReturnType<
  typeof useGetShopsFeaturedForShopPageQuery
>
export type GetShopsFeaturedForShopPageLazyQueryHookResult = ReturnType<
  typeof useGetShopsFeaturedForShopPageLazyQuery
>
export type GetShopsFeaturedForShopPageQueryResult = Apollo.QueryResult<
  GetShopsFeaturedForShopPageQuery,
  GetShopsFeaturedForShopPageQueryVariables
>
export const GetShopsForShopPageDocument = gql`
  query GetShopsForShopPage($ignore_slugs: [name!] = []) {
    shops: other_shops_for_shop_page(
      where: { slug: { _nin: $ignore_slugs } }
      limit: 24
    ) {
      key
      slug
      display_name
      favicon
    }
    shops_all: all_shops_for_shop_page(
      where: { slug: { _nin: $ignore_slugs } }
      limit: 2000
    ) {
      key
      slug
      display_name
    }
  }
`

/**
 * __useGetShopsForShopPageQuery__
 *
 * To run a query within a React component, call `useGetShopsForShopPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopsForShopPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopsForShopPageQuery({
 *   variables: {
 *      ignore_slugs: // value for 'ignore_slugs'
 *   },
 * });
 */
export function useGetShopsForShopPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetShopsForShopPageQuery,
    GetShopsForShopPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopsForShopPageQuery,
    GetShopsForShopPageQueryVariables
  >(GetShopsForShopPageDocument, options)
}
export function useGetShopsForShopPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopsForShopPageQuery,
    GetShopsForShopPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopsForShopPageQuery,
    GetShopsForShopPageQueryVariables
  >(GetShopsForShopPageDocument, options)
}
export type GetShopsForShopPageQueryHookResult = ReturnType<
  typeof useGetShopsForShopPageQuery
>
export type GetShopsForShopPageLazyQueryHookResult = ReturnType<
  typeof useGetShopsForShopPageLazyQuery
>
export type GetShopsForShopPageQueryResult = Apollo.QueryResult<
  GetShopsForShopPageQuery,
  GetShopsForShopPageQueryVariables
>
export const GetSubscriberCollectionsDocument = gql`
  query GetSubscriberCollections(
    $userId: String
    $limit: Int = 3
    $collectionItemsCount: Int = 3
  ) {
    collection_aggregate(
      where: {
        all: { _eq: false }
        computed_is_visible: { _eq: true }
        cart_items_count: { _gt: 0 }
        user_id: { _eq: $userId }
        collection_items: {
          cart_item: { item: { has_broken_image: { _eq: false } } }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    collections: collection(
      where: {
        all: { _eq: false }
        computed_is_visible: { _eq: true }
        cart_items_count: { _gt: 0 }
        user_id: { _eq: $userId }
        collection_items: {
          cart_item: { item: { has_broken_image: { _eq: false } } }
        }
      }
      limit: $limit
      order_by: [{ order: asc }, { cart_item_max_updated_at: desc_nulls_last }]
    ) {
      ...userCollectionsDataForProfilePageData
    }
  }
  ${UserCollectionsDataForProfilePageDataFragmentDoc}
`

/**
 * __useGetSubscriberCollectionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriberCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriberCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriberCollectionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *   },
 * });
 */
export function useGetSubscriberCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriberCollectionsQuery,
    GetSubscriberCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSubscriberCollectionsQuery,
    GetSubscriberCollectionsQueryVariables
  >(GetSubscriberCollectionsDocument, options)
}
export function useGetSubscriberCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriberCollectionsQuery,
    GetSubscriberCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSubscriberCollectionsQuery,
    GetSubscriberCollectionsQueryVariables
  >(GetSubscriberCollectionsDocument, options)
}
export type GetSubscriberCollectionsQueryHookResult = ReturnType<
  typeof useGetSubscriberCollectionsQuery
>
export type GetSubscriberCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetSubscriberCollectionsLazyQuery
>
export type GetSubscriberCollectionsQueryResult = Apollo.QueryResult<
  GetSubscriberCollectionsQuery,
  GetSubscriberCollectionsQueryVariables
>
export const GetUserCartItemsDocument = gql`
  query GetUserCartItems(
    $userId: String
    $offset: Int = 0
    $limit: Int = 20
    $includeHidden: Boolean = false
    $includeAggregate: Boolean = false
  ) {
    cart_item_aggregate(
      where: {
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
        cart: { user_id: { _eq: $userId } }
        _or: [{ hidden: { _eq: $includeHidden } }, { hidden: { _eq: false } }]
      }
    ) @include(if: $includeAggregate) {
      aggregate {
        count
      }
    }
    cart_items: cart_item(
      where: {
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
        cart: { user_id: { _eq: $userId } }
        _or: [{ hidden: { _eq: $includeHidden } }, { hidden: { _eq: false } }]
      }
      offset: $offset
      limit: $limit
      order_by: { updated_at: desc_nulls_last }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`

/**
 * __useGetUserCartItemsQuery__
 *
 * To run a query within a React component, call `useGetUserCartItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCartItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCartItemsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      includeHidden: // value for 'includeHidden'
 *      includeAggregate: // value for 'includeAggregate'
 *   },
 * });
 */
export function useGetUserCartItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserCartItemsQuery,
    GetUserCartItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserCartItemsQuery, GetUserCartItemsQueryVariables>(
    GetUserCartItemsDocument,
    options
  )
}
export function useGetUserCartItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCartItemsQuery,
    GetUserCartItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCartItemsQuery,
    GetUserCartItemsQueryVariables
  >(GetUserCartItemsDocument, options)
}
export type GetUserCartItemsQueryHookResult = ReturnType<
  typeof useGetUserCartItemsQuery
>
export type GetUserCartItemsLazyQueryHookResult = ReturnType<
  typeof useGetUserCartItemsLazyQuery
>
export type GetUserCartItemsQueryResult = Apollo.QueryResult<
  GetUserCartItemsQuery,
  GetUserCartItemsQueryVariables
>
export const GetUserCartItemsCountDocument = gql`
  query GetUserCartItemsCount($userId: String, $archived: Boolean = false) {
    saved_items_aggregate: cart_item_aggregate(
      where: {
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
        cart: { user_id: { _eq: $userId } }
      }
    ) {
      aggregate {
        count
      }
    }
    archived_items_aggregate: cart_item_aggregate(
      where: {
        deleted_at: { _is_null: true }
        archived_at: { _is_null: false }
        cart: { user_id: { _eq: $userId } }
      }
    ) @include(if: $archived) {
      aggregate {
        count
      }
    }
  }
`

/**
 * __useGetUserCartItemsCountQuery__
 *
 * To run a query within a React component, call `useGetUserCartItemsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCartItemsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCartItemsCountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useGetUserCartItemsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserCartItemsCountQuery,
    GetUserCartItemsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCartItemsCountQuery,
    GetUserCartItemsCountQueryVariables
  >(GetUserCartItemsCountDocument, options)
}
export function useGetUserCartItemsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCartItemsCountQuery,
    GetUserCartItemsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCartItemsCountQuery,
    GetUserCartItemsCountQueryVariables
  >(GetUserCartItemsCountDocument, options)
}
export type GetUserCartItemsCountQueryHookResult = ReturnType<
  typeof useGetUserCartItemsCountQuery
>
export type GetUserCartItemsCountLazyQueryHookResult = ReturnType<
  typeof useGetUserCartItemsCountLazyQuery
>
export type GetUserCartItemsCountQueryResult = Apollo.QueryResult<
  GetUserCartItemsCountQuery,
  GetUserCartItemsCountQueryVariables
>
export const GetUserDataForPicksPageDocument = gql`
  query GetUserDataForPicksPage(
    $username: String!
    $slug: name!
    $limit: Int = 25
    $offset: Int = 0
    $includeUser: Boolean = false
    $includeShop: Boolean = false
    $includePrivateItems: Boolean = false
  ) {
    cart_item_aggregate(
      where: {
        cart: { user: { username: { _eq: $username } } }
        shop: { slug: { _eq: $slug } }
        deleted_at: { _is_null: true }
        hidden: { _eq: false }
        item: { has_broken_image: { _eq: false } }
        collection_cart_items: {
          collection: {
            _or: [
              { private: { _eq: false } }
              { private: { _eq: $includePrivateItems } }
            ]
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    cart_items: cart_item(
      where: {
        cart: { user: { username: { _eq: $username } } }
        shop: { slug: { _eq: $slug } }
        deleted_at: { _is_null: true }
        hidden: { _eq: false }
        item: { has_broken_image: { _eq: false } }
        collection_cart_items: {
          collection: {
            _or: [
              { private: { _eq: false } }
              { private: { _eq: $includePrivateItems } }
            ]
          }
        }
      }
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc_nulls_last }
    ) {
      ...cartItemData
      feed_item {
        ...feedItemData
      }
      commentable_item_join {
        comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
          id
          comment
          user {
            username
            picture
          }
        }
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
    user(where: { username: { _eq: $username } }, limit: 1)
      @include(if: $includeUser) {
      id
      username
      picture
      is_curator_computed
      is_creator_computed
    }
    shop(
      where: { slug: { _eq: $slug } }
      order_by: { cart_items_count: desc_nulls_last }
      limit: 1
    ) @include(if: $includeShop) {
      key
      slug
      display_name
      description
      favicon
      cover_image
      banner_image
      shop_categorizations {
        category
      }
    }
  }
  ${CartItemDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`

/**
 * __useGetUserDataForPicksPageQuery__
 *
 * To run a query within a React component, call `useGetUserDataForPicksPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDataForPicksPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDataForPicksPageQuery({
 *   variables: {
 *      username: // value for 'username'
 *      slug: // value for 'slug'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      includeUser: // value for 'includeUser'
 *      includeShop: // value for 'includeShop'
 *      includePrivateItems: // value for 'includePrivateItems'
 *   },
 * });
 */
export function useGetUserDataForPicksPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserDataForPicksPageQuery,
    GetUserDataForPicksPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserDataForPicksPageQuery,
    GetUserDataForPicksPageQueryVariables
  >(GetUserDataForPicksPageDocument, options)
}
export function useGetUserDataForPicksPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDataForPicksPageQuery,
    GetUserDataForPicksPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserDataForPicksPageQuery,
    GetUserDataForPicksPageQueryVariables
  >(GetUserDataForPicksPageDocument, options)
}
export type GetUserDataForPicksPageQueryHookResult = ReturnType<
  typeof useGetUserDataForPicksPageQuery
>
export type GetUserDataForPicksPageLazyQueryHookResult = ReturnType<
  typeof useGetUserDataForPicksPageLazyQuery
>
export type GetUserDataForPicksPageQueryResult = Apollo.QueryResult<
  GetUserDataForPicksPageQuery,
  GetUserDataForPicksPageQueryVariables
>
export const GetUserFeedItemCartItemLikesDocument = gql`
  query GetUserFeedItemCartItemLikes(
    $userId: String!
    $offset: Int = 0
    $limit: Int = 20
  ) {
    feed_item_cart_item_likes: feed_item_like(
      where: {
        user_id: { _eq: $userId }
        feed_item: { cart_item: { liked_by_user: { _eq: true } } }
      }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      feed_item {
        cart_item {
          ...cartItemData
          feed_item {
            ...feedItemData
          }
        }
      }
    }
  }
  ${CartItemDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`

/**
 * __useGetUserFeedItemCartItemLikesQuery__
 *
 * To run a query within a React component, call `useGetUserFeedItemCartItemLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFeedItemCartItemLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFeedItemCartItemLikesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserFeedItemCartItemLikesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserFeedItemCartItemLikesQuery,
    GetUserFeedItemCartItemLikesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserFeedItemCartItemLikesQuery,
    GetUserFeedItemCartItemLikesQueryVariables
  >(GetUserFeedItemCartItemLikesDocument, options)
}
export function useGetUserFeedItemCartItemLikesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFeedItemCartItemLikesQuery,
    GetUserFeedItemCartItemLikesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserFeedItemCartItemLikesQuery,
    GetUserFeedItemCartItemLikesQueryVariables
  >(GetUserFeedItemCartItemLikesDocument, options)
}
export type GetUserFeedItemCartItemLikesQueryHookResult = ReturnType<
  typeof useGetUserFeedItemCartItemLikesQuery
>
export type GetUserFeedItemCartItemLikesLazyQueryHookResult = ReturnType<
  typeof useGetUserFeedItemCartItemLikesLazyQuery
>
export type GetUserFeedItemCartItemLikesQueryResult = Apollo.QueryResult<
  GetUserFeedItemCartItemLikesQuery,
  GetUserFeedItemCartItemLikesQueryVariables
>
export const GetUserFeedItemCollectionLikesDocument = gql`
  query GetUserFeedItemCollectionLikes(
    $userId: String!
    $offset: Int = 0
    $limit: Int = 20
  ) {
    feed_item_collection_likes: feed_item_like(
      where: {
        user_id: { _eq: $userId }
        feed_item: { collection: { liked_by_user: { _eq: true } } }
      }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      feed_item {
        collection {
          ...userCollectionsDataForProfilePageData
          feed_item {
            ...feedItemData
          }
        }
      }
    }
  }
  ${UserCollectionsDataForProfilePageDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`

/**
 * __useGetUserFeedItemCollectionLikesQuery__
 *
 * To run a query within a React component, call `useGetUserFeedItemCollectionLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFeedItemCollectionLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFeedItemCollectionLikesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserFeedItemCollectionLikesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserFeedItemCollectionLikesQuery,
    GetUserFeedItemCollectionLikesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserFeedItemCollectionLikesQuery,
    GetUserFeedItemCollectionLikesQueryVariables
  >(GetUserFeedItemCollectionLikesDocument, options)
}
export function useGetUserFeedItemCollectionLikesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFeedItemCollectionLikesQuery,
    GetUserFeedItemCollectionLikesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserFeedItemCollectionLikesQuery,
    GetUserFeedItemCollectionLikesQueryVariables
  >(GetUserFeedItemCollectionLikesDocument, options)
}
export type GetUserFeedItemCollectionLikesQueryHookResult = ReturnType<
  typeof useGetUserFeedItemCollectionLikesQuery
>
export type GetUserFeedItemCollectionLikesLazyQueryHookResult = ReturnType<
  typeof useGetUserFeedItemCollectionLikesLazyQuery
>
export type GetUserFeedItemCollectionLikesQueryResult = Apollo.QueryResult<
  GetUserFeedItemCollectionLikesQuery,
  GetUserFeedItemCollectionLikesQueryVariables
>
export const GetUserFeedItemLikeCountsDocument = gql`
  query GetUserFeedItemLikeCounts($userId: String!) {
    liked_collections_aggreate: feed_item_like_aggregate(
      where: {
        user_id: { _eq: $userId }
        feed_item: { collection: { liked_by_user: { _eq: true } } }
      }
    ) {
      aggregate {
        count
      }
    }
    liked_cart_item_aggreate: feed_item_like_aggregate(
      where: {
        user_id: { _eq: $userId }
        feed_item: { cart_item: { liked_by_user: { _eq: true } } }
      }
    ) {
      aggregate {
        count
      }
    }
    liked_featured_shop_aggreate: feed_item_like_aggregate(
      where: {
        user_id: { _eq: $userId }
        feed_item: { featured_shop: { liked_by_user: { _eq: true } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

/**
 * __useGetUserFeedItemLikeCountsQuery__
 *
 * To run a query within a React component, call `useGetUserFeedItemLikeCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFeedItemLikeCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFeedItemLikeCountsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserFeedItemLikeCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserFeedItemLikeCountsQuery,
    GetUserFeedItemLikeCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserFeedItemLikeCountsQuery,
    GetUserFeedItemLikeCountsQueryVariables
  >(GetUserFeedItemLikeCountsDocument, options)
}
export function useGetUserFeedItemLikeCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFeedItemLikeCountsQuery,
    GetUserFeedItemLikeCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserFeedItemLikeCountsQuery,
    GetUserFeedItemLikeCountsQueryVariables
  >(GetUserFeedItemLikeCountsDocument, options)
}
export type GetUserFeedItemLikeCountsQueryHookResult = ReturnType<
  typeof useGetUserFeedItemLikeCountsQuery
>
export type GetUserFeedItemLikeCountsLazyQueryHookResult = ReturnType<
  typeof useGetUserFeedItemLikeCountsLazyQuery
>
export type GetUserFeedItemLikeCountsQueryResult = Apollo.QueryResult<
  GetUserFeedItemLikeCountsQuery,
  GetUserFeedItemLikeCountsQueryVariables
>
export const GetUserFeedItemShopLikesDocument = gql`
  query GetUserFeedItemShopLikes(
    $userId: String!
    $offset: Int = 0
    $limit: Int = 20
  ) {
    feed_item_shop_likes: feed_item_like(
      where: {
        user_id: { _eq: $userId }
        feed_item: { featured_shop: { liked_by_user: { _eq: true } } }
      }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      feed_item {
        featured_shop {
          shop {
            ...shopData
          }
          feed_item {
            ...feedItemData
          }
        }
      }
    }
  }
  ${ShopDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`

/**
 * __useGetUserFeedItemShopLikesQuery__
 *
 * To run a query within a React component, call `useGetUserFeedItemShopLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFeedItemShopLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFeedItemShopLikesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserFeedItemShopLikesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserFeedItemShopLikesQuery,
    GetUserFeedItemShopLikesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserFeedItemShopLikesQuery,
    GetUserFeedItemShopLikesQueryVariables
  >(GetUserFeedItemShopLikesDocument, options)
}
export function useGetUserFeedItemShopLikesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFeedItemShopLikesQuery,
    GetUserFeedItemShopLikesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserFeedItemShopLikesQuery,
    GetUserFeedItemShopLikesQueryVariables
  >(GetUserFeedItemShopLikesDocument, options)
}
export type GetUserFeedItemShopLikesQueryHookResult = ReturnType<
  typeof useGetUserFeedItemShopLikesQuery
>
export type GetUserFeedItemShopLikesLazyQueryHookResult = ReturnType<
  typeof useGetUserFeedItemShopLikesLazyQuery
>
export type GetUserFeedItemShopLikesQueryResult = Apollo.QueryResult<
  GetUserFeedItemShopLikesQuery,
  GetUserFeedItemShopLikesQueryVariables
>
export const GetUserOtherCollectionsDocument = gql`
  query GetUserOtherCollections(
    $userId: String!
    $collectionIdToExclude: uuid!
  ) {
    other_collections: collection(
      where: {
        all: { _eq: false }
        computed_is_visible: { _eq: true }
        cart_items_count: { _gt: 0 }
        user_id: { _eq: $userId }
        id: { _neq: $collectionIdToExclude }
      }
      order_by: [{ order: asc }, { cart_item_max_updated_at: desc_nulls_last }]
      limit: 6
    ) {
      id
      slug
      name
      description
      cover_image
      cover_image_cached
      collection_cover_images
      user {
        id
        username
        picture
        created_at
      }
      has_collaborators
      collection_collaborators(
        order_by: { created_at: desc_nulls_last }
        limit: 4
      ) {
        id
        user {
          id
          picture
        }
      }
      cart_items_count
      collection_items: preview_collection_items(limit: 3) {
        id
        cart_item {
          id
          item_image_override
          item {
            id
            image
            image_highres
            cached_image
            cached_placeholder_image
          }
        }
      }
    }
  }
`

/**
 * __useGetUserOtherCollectionsQuery__
 *
 * To run a query within a React component, call `useGetUserOtherCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOtherCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOtherCollectionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      collectionIdToExclude: // value for 'collectionIdToExclude'
 *   },
 * });
 */
export function useGetUserOtherCollectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserOtherCollectionsQuery,
    GetUserOtherCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserOtherCollectionsQuery,
    GetUserOtherCollectionsQueryVariables
  >(GetUserOtherCollectionsDocument, options)
}
export function useGetUserOtherCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserOtherCollectionsQuery,
    GetUserOtherCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserOtherCollectionsQuery,
    GetUserOtherCollectionsQueryVariables
  >(GetUserOtherCollectionsDocument, options)
}
export type GetUserOtherCollectionsQueryHookResult = ReturnType<
  typeof useGetUserOtherCollectionsQuery
>
export type GetUserOtherCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetUserOtherCollectionsLazyQuery
>
export type GetUserOtherCollectionsQueryResult = Apollo.QueryResult<
  GetUserOtherCollectionsQuery,
  GetUserOtherCollectionsQueryVariables
>
export const GetUserPicksForShopDocument = gql`
  query GetUserPicksForShop(
    $slug: name!
    $excludeUsername: String = ""
    $limit: Int = 8
  ) @cached {
    influencer_carts: cart(
      where: {
        shop: { slug: { _eq: $slug } }
        user: {
          user_type: { _in: [creator, curator] }
          username: { _neq: $excludeUsername }
        }
        cart_items: {
          collection_cart_items: { collection: { private: { _eq: false } } }
          deleted_at: { _is_null: true }
          hidden: { _eq: false }
        }
      }
      limit: $limit
    ) {
      updated_at
      shop {
        slug
        display_name
      }
      cart_items_aggregate(
        where: {
          collection_cart_items: { collection: { private: { _eq: false } } }
          deleted_at: { _is_null: true }
          hidden: { _eq: false }
        }
      ) {
        aggregate {
          count
        }
      }
      cart_items(
        where: {
          collection_cart_items: { collection: { private: { _eq: false } } }
          deleted_at: { _is_null: true }
          hidden: { _eq: false }
        }
        limit: 3
      ) {
        id
        item_image_override
        item_title_override
        affiliate_redirect_link_override
        item {
          id
          metadata
          display_title
          image_highres
          cached_image
          image
          image_metadata
          cached_placeholder_image
          is_inappropriate
        }
      }
      user {
        username
        id
        picture
      }
    }
  }
`

/**
 * __useGetUserPicksForShopQuery__
 *
 * To run a query within a React component, call `useGetUserPicksForShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPicksForShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPicksForShopQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      excludeUsername: // value for 'excludeUsername'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserPicksForShopQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserPicksForShopQuery,
    GetUserPicksForShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserPicksForShopQuery,
    GetUserPicksForShopQueryVariables
  >(GetUserPicksForShopDocument, options)
}
export function useGetUserPicksForShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPicksForShopQuery,
    GetUserPicksForShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserPicksForShopQuery,
    GetUserPicksForShopQueryVariables
  >(GetUserPicksForShopDocument, options)
}
export type GetUserPicksForShopQueryHookResult = ReturnType<
  typeof useGetUserPicksForShopQuery
>
export type GetUserPicksForShopLazyQueryHookResult = ReturnType<
  typeof useGetUserPicksForShopLazyQuery
>
export type GetUserPicksForShopQueryResult = Apollo.QueryResult<
  GetUserPicksForShopQuery,
  GetUserPicksForShopQueryVariables
>
export const GetUserWebBookmarksDocument = gql`
  query GetUserWebBookmarks($user_id: String!, $ids: [uuid!]) {
    web_bookmark_workflows: workflow(
      where: { user_id: { _eq: $user_id }, id: { _in: $ids } }
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      data
      status
    }
  }
`

/**
 * __useGetUserWebBookmarksQuery__
 *
 * To run a query within a React component, call `useGetUserWebBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWebBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWebBookmarksQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetUserWebBookmarksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserWebBookmarksQuery,
    GetUserWebBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserWebBookmarksQuery,
    GetUserWebBookmarksQueryVariables
  >(GetUserWebBookmarksDocument, options)
}
export function useGetUserWebBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserWebBookmarksQuery,
    GetUserWebBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserWebBookmarksQuery,
    GetUserWebBookmarksQueryVariables
  >(GetUserWebBookmarksDocument, options)
}
export type GetUserWebBookmarksQueryHookResult = ReturnType<
  typeof useGetUserWebBookmarksQuery
>
export type GetUserWebBookmarksLazyQueryHookResult = ReturnType<
  typeof useGetUserWebBookmarksLazyQuery
>
export type GetUserWebBookmarksQueryResult = Apollo.QueryResult<
  GetUserWebBookmarksQuery,
  GetUserWebBookmarksQueryVariables
>
export const GetUsernameClaimsDocument = gql`
  query GetUsernameClaims {
    icons: user(where: { is_icon_computed: { _eq: true } }, limit: 5) {
      id
      username
      picture
      created_at
    }
    users: user(
      where: { has_claimed_username_computed: { _eq: true } }
      order_by: { created_at: desc_nulls_last }
      limit: 25
    ) {
      id
      username
      picture
      created_at
    }
  }
`

/**
 * __useGetUsernameClaimsQuery__
 *
 * To run a query within a React component, call `useGetUsernameClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsernameClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsernameClaimsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsernameClaimsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsernameClaimsQuery,
    GetUsernameClaimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUsernameClaimsQuery,
    GetUsernameClaimsQueryVariables
  >(GetUsernameClaimsDocument, options)
}
export function useGetUsernameClaimsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsernameClaimsQuery,
    GetUsernameClaimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUsernameClaimsQuery,
    GetUsernameClaimsQueryVariables
  >(GetUsernameClaimsDocument, options)
}
export type GetUsernameClaimsQueryHookResult = ReturnType<
  typeof useGetUsernameClaimsQuery
>
export type GetUsernameClaimsLazyQueryHookResult = ReturnType<
  typeof useGetUsernameClaimsLazyQuery
>
export type GetUsernameClaimsQueryResult = Apollo.QueryResult<
  GetUsernameClaimsQuery,
  GetUsernameClaimsQueryVariables
>
export const GetUsernamesForStaticPathsDocument = gql`
  query GetUsernamesForStaticPaths($limit: Int = 500) {
    users: user(where: { user_type: { _eq: creator } }, limit: $limit) {
      username
    }
  }
`

/**
 * __useGetUsernamesForStaticPathsQuery__
 *
 * To run a query within a React component, call `useGetUsernamesForStaticPathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsernamesForStaticPathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsernamesForStaticPathsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUsernamesForStaticPathsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsernamesForStaticPathsQuery,
    GetUsernamesForStaticPathsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUsernamesForStaticPathsQuery,
    GetUsernamesForStaticPathsQueryVariables
  >(GetUsernamesForStaticPathsDocument, options)
}
export function useGetUsernamesForStaticPathsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsernamesForStaticPathsQuery,
    GetUsernamesForStaticPathsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUsernamesForStaticPathsQuery,
    GetUsernamesForStaticPathsQueryVariables
  >(GetUsernamesForStaticPathsDocument, options)
}
export type GetUsernamesForStaticPathsQueryHookResult = ReturnType<
  typeof useGetUsernamesForStaticPathsQuery
>
export type GetUsernamesForStaticPathsLazyQueryHookResult = ReturnType<
  typeof useGetUsernamesForStaticPathsLazyQuery
>
export type GetUsernamesForStaticPathsQueryResult = Apollo.QueryResult<
  GetUsernamesForStaticPathsQuery,
  GetUsernamesForStaticPathsQueryVariables
>
export const InsertCommentDocument = gql`
  mutation InsertComment(
    $item_id: uuid!
    $item_type: commentable_item_type_enum!
    $comment: String!
  ) {
    insert_comment_one(
      object: {
        comment: $comment
        commentable_item_id: $item_id
        commentable_item_type: $item_type
      }
    ) {
      id
      sequential_id
      comment
      created_at
    }
  }
`
export type InsertCommentMutationFn = Apollo.MutationFunction<
  InsertCommentMutation,
  InsertCommentMutationVariables
>

/**
 * __useInsertCommentMutation__
 *
 * To run a mutation, you first call `useInsertCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCommentMutation, { data, loading, error }] = useInsertCommentMutation({
 *   variables: {
 *      item_id: // value for 'item_id'
 *      item_type: // value for 'item_type'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useInsertCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertCommentMutation,
    InsertCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertCommentMutation,
    InsertCommentMutationVariables
  >(InsertCommentDocument, options)
}
export type InsertCommentMutationHookResult = ReturnType<
  typeof useInsertCommentMutation
>
export type InsertCommentMutationResult =
  Apollo.MutationResult<InsertCommentMutation>
export type InsertCommentMutationOptions = Apollo.BaseMutationOptions<
  InsertCommentMutation,
  InsertCommentMutationVariables
>
export const InsertNewCollectionDocument = gql`
  mutation InsertNewCollection(
    $name: name!
    $slug: name!
    $type: collection_type_enum = default
    $private: Boolean = false
    $giftlist: Boolean = false
    $exclusive: Boolean = false
    $include_seo_fields: Boolean = false
  ) {
    collection: insert_collection_one(
      object: {
        name: $name
        slug: $slug
        collection_type: $type
        private: $private
        giftlist: $giftlist
        exclusive: $exclusive
      }
      on_conflict: {
        constraint: collection_user_id_slug_key
        update_columns: [name, slug]
      }
    ) {
      ...collectionData
    }
  }
  ${CollectionDataFragmentDoc}
`
export type InsertNewCollectionMutationFn = Apollo.MutationFunction<
  InsertNewCollectionMutation,
  InsertNewCollectionMutationVariables
>

/**
 * __useInsertNewCollectionMutation__
 *
 * To run a mutation, you first call `useInsertNewCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNewCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNewCollectionMutation, { data, loading, error }] = useInsertNewCollectionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      slug: // value for 'slug'
 *      type: // value for 'type'
 *      private: // value for 'private'
 *      giftlist: // value for 'giftlist'
 *      exclusive: // value for 'exclusive'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useInsertNewCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertNewCollectionMutation,
    InsertNewCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertNewCollectionMutation,
    InsertNewCollectionMutationVariables
  >(InsertNewCollectionDocument, options)
}
export type InsertNewCollectionMutationHookResult = ReturnType<
  typeof useInsertNewCollectionMutation
>
export type InsertNewCollectionMutationResult =
  Apollo.MutationResult<InsertNewCollectionMutation>
export type InsertNewCollectionMutationOptions = Apollo.BaseMutationOptions<
  InsertNewCollectionMutation,
  InsertNewCollectionMutationVariables
>
export const FindCartItemForPdpDocument = gql`
  query FindCartItemForPDP($id: uuid!, $isOwn: Boolean = false) {
    cart_item: cart_item_by_pk(id: $id) {
      ...cartItemData
      feed_item {
        ...feedItemData
      }
      item {
        ...itemData
        description
        shop {
          ...shopData
        }
      }
      commentable_item_join {
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
    collection_cart_item: collection_cart_item(where: { id: { _eq: $id } }) {
      collection {
        id
        name
        slug
        collection_type
        metadata
        collection_items(where: { id: { _eq: $id } }) {
          id
          cart_item {
            ...cartItemData
            feed_item {
              ...feedItemData
            }
            item {
              ...itemData
              description
              shop {
                ...shopData
              }
            }
            commentable_item_join {
              comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
                id
                comment
                user {
                  username
                  picture
                }
              }
              comments_aggregate {
                aggregate {
                  count
                }
              }
            }
          }
        }
      }
    }
    collection_cart_items: collection_cart_item(where: { id: { _eq: $id } }) {
      collection {
        id
        name
        slug
        collection_type
        metadata
        collection_items(where: { id: { _neq: $id } }, limit: 5) {
          id
          cart_item {
            ...cartItemData
            feed_item {
              ...feedItemData
            }
            item {
              ...itemData
              description
              shop {
                ...shopData
              }
            }
            commentable_item_join {
              comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
                id
                comment
                user {
                  username
                  picture
                }
              }
              comments_aggregate {
                aggregate {
                  count
                }
              }
            }
          }
        }
      }
    }
    collections: collection(
      where: {
        collection_items: { cart_item_id: { _eq: $id } }
        _or: [{ private: { _eq: false } }, { private: { _eq: $isOwn } }]
      }
    ) {
      id
      name
      slug
      user {
        id
        username
        picture
      }
      cart_items_count
      collection_items(limit: 3) {
        id
        cart_item {
          id
          item_image_override
          image_metadata
          cart {
            id
            user {
              id
            }
          }
          item {
            id
            image
            image_highres
            cached_image
            image_metadata
            metadata
            is_inappropriate
          }
        }
      }
    }
  }
  ${CartItemDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
  ${ItemDataFragmentDoc}
  ${ShopDataFragmentDoc}
`

/**
 * __useFindCartItemForPdpQuery__
 *
 * To run a query within a React component, call `useFindCartItemForPdpQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCartItemForPdpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCartItemForPdpQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isOwn: // value for 'isOwn'
 *   },
 * });
 */
export function useFindCartItemForPdpQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCartItemForPdpQuery,
    FindCartItemForPdpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCartItemForPdpQuery,
    FindCartItemForPdpQueryVariables
  >(FindCartItemForPdpDocument, options)
}
export function useFindCartItemForPdpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCartItemForPdpQuery,
    FindCartItemForPdpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCartItemForPdpQuery,
    FindCartItemForPdpQueryVariables
  >(FindCartItemForPdpDocument, options)
}
export type FindCartItemForPdpQueryHookResult = ReturnType<
  typeof useFindCartItemForPdpQuery
>
export type FindCartItemForPdpLazyQueryHookResult = ReturnType<
  typeof useFindCartItemForPdpLazyQuery
>
export type FindCartItemForPdpQueryResult = Apollo.QueryResult<
  FindCartItemForPdpQuery,
  FindCartItemForPdpQueryVariables
>
export const GetCartItemCommentsDocument = gql`
  query GetCartItemComments($id: uuid!, $limit: Int = 10, $offset: Int = 0) {
    comments: comment(
      where: {
        commentable_item: {
          item_id: { _eq: $id }
          item_type: { _eq: cart_item }
        }
      }
      limit: $limit
      offset: $offset
      order_by: { sequential_id: desc }
    ) {
      id
      sequential_id
      comment
      created_at
      user {
        ...basicUserData
      }
    }
    comment_aggregate(
      where: {
        commentable_item: {
          item_id: { _eq: $id }
          item_type: { _eq: cart_item }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetCartItemCommentsQuery__
 *
 * To run a query within a React component, call `useGetCartItemCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCartItemCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCartItemCommentsQuery,
    GetCartItemCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCartItemCommentsQuery,
    GetCartItemCommentsQueryVariables
  >(GetCartItemCommentsDocument, options)
}
export function useGetCartItemCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCartItemCommentsQuery,
    GetCartItemCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCartItemCommentsQuery,
    GetCartItemCommentsQueryVariables
  >(GetCartItemCommentsDocument, options)
}
export type GetCartItemCommentsQueryHookResult = ReturnType<
  typeof useGetCartItemCommentsQuery
>
export type GetCartItemCommentsLazyQueryHookResult = ReturnType<
  typeof useGetCartItemCommentsLazyQuery
>
export type GetCartItemCommentsQueryResult = Apollo.QueryResult<
  GetCartItemCommentsQuery,
  GetCartItemCommentsQueryVariables
>
export const SubCartItemCommentsDocument = gql`
  subscription subCartItemComments($id: uuid!, $lastFetchedSequentialId: Int!) {
    comment(
      where: {
        commentable_item_id: { _eq: $id }
        commentable_item_type: { _eq: cart_item }
        sequential_id: { _gt: $lastFetchedSequentialId }
      }
      limit: 1
      order_by: { sequential_id: asc }
    ) {
      id
      sequential_id
    }
  }
`

/**
 * __useSubCartItemCommentsSubscription__
 *
 * To run a query within a React component, call `useSubCartItemCommentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubCartItemCommentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubCartItemCommentsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      lastFetchedSequentialId: // value for 'lastFetchedSequentialId'
 *   },
 * });
 */
export function useSubCartItemCommentsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubCartItemCommentsSubscription,
    SubCartItemCommentsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubCartItemCommentsSubscription,
    SubCartItemCommentsSubscriptionVariables
  >(SubCartItemCommentsDocument, options)
}
export type SubCartItemCommentsSubscriptionHookResult = ReturnType<
  typeof useSubCartItemCommentsSubscription
>
export type SubCartItemCommentsSubscriptionResult =
  Apollo.SubscriptionResult<SubCartItemCommentsSubscription>
export const SaveCartItemFromWebDocument = gql`
  mutation SaveCartItemFromWeb(
    $shop_key: String!
    $shop_data: shop_insert_input!
    $shop_item_data: shop_item_insert_input!
    $source: String!
  ) {
    cart_item: insert_cart_item_one(
      object: {
        shop_key: $shop_key
        quantity: 1
        bookmark: true
        source: $source
        item: {
          data: $shop_item_data
          on_conflict: {
            constraint: shop_item_shop_key_item_id_key
            update_columns: [shop_key]
          }
        }
        cart: {
          data: {
            shop: {
              data: $shop_data
              on_conflict: { constraint: shop_pkey, update_columns: [key] }
            }
          }
          on_conflict: {
            constraint: cart_user_id_shop_key_key
            update_columns: [shop_key]
          }
        }
      }
      on_conflict: {
        constraint: cart_item_item_id_shop_key_cart_id_key
        update_columns: [shop_key]
      }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`
export type SaveCartItemFromWebMutationFn = Apollo.MutationFunction<
  SaveCartItemFromWebMutation,
  SaveCartItemFromWebMutationVariables
>

/**
 * __useSaveCartItemFromWebMutation__
 *
 * To run a mutation, you first call `useSaveCartItemFromWebMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCartItemFromWebMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCartItemFromWebMutation, { data, loading, error }] = useSaveCartItemFromWebMutation({
 *   variables: {
 *      shop_key: // value for 'shop_key'
 *      shop_data: // value for 'shop_data'
 *      shop_item_data: // value for 'shop_item_data'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useSaveCartItemFromWebMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCartItemFromWebMutation,
    SaveCartItemFromWebMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveCartItemFromWebMutation,
    SaveCartItemFromWebMutationVariables
  >(SaveCartItemFromWebDocument, options)
}
export type SaveCartItemFromWebMutationHookResult = ReturnType<
  typeof useSaveCartItemFromWebMutation
>
export type SaveCartItemFromWebMutationResult =
  Apollo.MutationResult<SaveCartItemFromWebMutation>
export type SaveCartItemFromWebMutationOptions = Apollo.BaseMutationOptions<
  SaveCartItemFromWebMutation,
  SaveCartItemFromWebMutationVariables
>
export const SearchForUsersWebDocument = gql`
  query SearchForUsersWeb($query: String!) {
    users: users_search(args: { search: $query }) {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useSearchForUsersWebQuery__
 *
 * To run a query within a React component, call `useSearchForUsersWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchForUsersWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchForUsersWebQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchForUsersWebQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchForUsersWebQuery,
    SearchForUsersWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchForUsersWebQuery,
    SearchForUsersWebQueryVariables
  >(SearchForUsersWebDocument, options)
}
export function useSearchForUsersWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchForUsersWebQuery,
    SearchForUsersWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchForUsersWebQuery,
    SearchForUsersWebQueryVariables
  >(SearchForUsersWebDocument, options)
}
export type SearchForUsersWebQueryHookResult = ReturnType<
  typeof useSearchForUsersWebQuery
>
export type SearchForUsersWebLazyQueryHookResult = ReturnType<
  typeof useSearchForUsersWebLazyQuery
>
export type SearchForUsersWebQueryResult = Apollo.QueryResult<
  SearchForUsersWebQuery,
  SearchForUsersWebQueryVariables
>
export const CreateFreeCreatorSubscriptionDocument = gql`
  mutation CreateFreeCreatorSubscription($creatorId: String!) {
    subscribeToCreator(creatorId: $creatorId) {
      user_subscription {
        id
        user_id
        creator_id
        subscription_id
      }
    }
  }
`
export type CreateFreeCreatorSubscriptionMutationFn = Apollo.MutationFunction<
  CreateFreeCreatorSubscriptionMutation,
  CreateFreeCreatorSubscriptionMutationVariables
>

/**
 * __useCreateFreeCreatorSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateFreeCreatorSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFreeCreatorSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFreeCreatorSubscriptionMutation, { data, loading, error }] = useCreateFreeCreatorSubscriptionMutation({
 *   variables: {
 *      creatorId: // value for 'creatorId'
 *   },
 * });
 */
export function useCreateFreeCreatorSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFreeCreatorSubscriptionMutation,
    CreateFreeCreatorSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateFreeCreatorSubscriptionMutation,
    CreateFreeCreatorSubscriptionMutationVariables
  >(CreateFreeCreatorSubscriptionDocument, options)
}
export type CreateFreeCreatorSubscriptionMutationHookResult = ReturnType<
  typeof useCreateFreeCreatorSubscriptionMutation
>
export type CreateFreeCreatorSubscriptionMutationResult =
  Apollo.MutationResult<CreateFreeCreatorSubscriptionMutation>
export type CreateFreeCreatorSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateFreeCreatorSubscriptionMutation,
    CreateFreeCreatorSubscriptionMutationVariables
  >
export const GetCreatorForSubscriptionsDocument = gql`
  query GetCreatorForSubscriptions($username: String!) {
    user(where: { username: { _eq: $username } }) {
      ...basicUserData
      has_billing_account
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetCreatorForSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetCreatorForSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatorForSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatorForSubscriptionsQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetCreatorForSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreatorForSubscriptionsQuery,
    GetCreatorForSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCreatorForSubscriptionsQuery,
    GetCreatorForSubscriptionsQueryVariables
  >(GetCreatorForSubscriptionsDocument, options)
}
export function useGetCreatorForSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreatorForSubscriptionsQuery,
    GetCreatorForSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCreatorForSubscriptionsQuery,
    GetCreatorForSubscriptionsQueryVariables
  >(GetCreatorForSubscriptionsDocument, options)
}
export type GetCreatorForSubscriptionsQueryHookResult = ReturnType<
  typeof useGetCreatorForSubscriptionsQuery
>
export type GetCreatorForSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useGetCreatorForSubscriptionsLazyQuery
>
export type GetCreatorForSubscriptionsQueryResult = Apollo.QueryResult<
  GetCreatorForSubscriptionsQuery,
  GetCreatorForSubscriptionsQueryVariables
>
export const GetCreatorSubscriptionsDocument = gql`
  query GetCreatorSubscriptions(
    $id: String!
    $include_subscriptions: Boolean = false
  ) {
    availableSubscriptions(creatorId: $id)
      @include(if: $include_subscriptions) {
      id
      name
      description
      unit_amount
      currency
      interval
    }
    creator: user_by_pk(id: $id) {
      subscription_status
      has_billing_account
    }
  }
`

/**
 * __useGetCreatorSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetCreatorSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatorSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatorSubscriptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      include_subscriptions: // value for 'include_subscriptions'
 *   },
 * });
 */
export function useGetCreatorSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreatorSubscriptionsQuery,
    GetCreatorSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCreatorSubscriptionsQuery,
    GetCreatorSubscriptionsQueryVariables
  >(GetCreatorSubscriptionsDocument, options)
}
export function useGetCreatorSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreatorSubscriptionsQuery,
    GetCreatorSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCreatorSubscriptionsQuery,
    GetCreatorSubscriptionsQueryVariables
  >(GetCreatorSubscriptionsDocument, options)
}
export type GetCreatorSubscriptionsQueryHookResult = ReturnType<
  typeof useGetCreatorSubscriptionsQuery
>
export type GetCreatorSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useGetCreatorSubscriptionsLazyQuery
>
export type GetCreatorSubscriptionsQueryResult = Apollo.QueryResult<
  GetCreatorSubscriptionsQuery,
  GetCreatorSubscriptionsQueryVariables
>
export const UnsubscribeFromCreatorDocument = gql`
  mutation UnsubscribeFromCreator($creatorId: String!) {
    fullyUnsubscribeFromCreator(creatorId: $creatorId) {
      user_subscription_id
    }
  }
`
export type UnsubscribeFromCreatorMutationFn = Apollo.MutationFunction<
  UnsubscribeFromCreatorMutation,
  UnsubscribeFromCreatorMutationVariables
>

/**
 * __useUnsubscribeFromCreatorMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromCreatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromCreatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromCreatorMutation, { data, loading, error }] = useUnsubscribeFromCreatorMutation({
 *   variables: {
 *      creatorId: // value for 'creatorId'
 *   },
 * });
 */
export function useUnsubscribeFromCreatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeFromCreatorMutation,
    UnsubscribeFromCreatorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnsubscribeFromCreatorMutation,
    UnsubscribeFromCreatorMutationVariables
  >(UnsubscribeFromCreatorDocument, options)
}
export type UnsubscribeFromCreatorMutationHookResult = ReturnType<
  typeof useUnsubscribeFromCreatorMutation
>
export type UnsubscribeFromCreatorMutationResult =
  Apollo.MutationResult<UnsubscribeFromCreatorMutation>
export type UnsubscribeFromCreatorMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeFromCreatorMutation,
  UnsubscribeFromCreatorMutationVariables
>
export const SubscriptionCartItemEnrichmentStatusDocument = gql`
  subscription SubscriptionCartItemEnrichmentStatus($id: uuid!) {
    cart_item_by_pk(id: $id) {
      ...cartItemData
      feed_item {
        ...feedItemData
      }
      commentable_item_join {
        comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
          id
          comment
          user {
            username
            picture
          }
        }
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
  ${CartItemDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`

/**
 * __useSubscriptionCartItemEnrichmentStatusSubscription__
 *
 * To run a query within a React component, call `useSubscriptionCartItemEnrichmentStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionCartItemEnrichmentStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionCartItemEnrichmentStatusSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscriptionCartItemEnrichmentStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscriptionCartItemEnrichmentStatusSubscription,
    SubscriptionCartItemEnrichmentStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubscriptionCartItemEnrichmentStatusSubscription,
    SubscriptionCartItemEnrichmentStatusSubscriptionVariables
  >(SubscriptionCartItemEnrichmentStatusDocument, options)
}
export type SubscriptionCartItemEnrichmentStatusSubscriptionHookResult =
  ReturnType<typeof useSubscriptionCartItemEnrichmentStatusSubscription>
export type SubscriptionCartItemEnrichmentStatusSubscriptionResult =
  Apollo.SubscriptionResult<SubscriptionCartItemEnrichmentStatusSubscription>
export const UpdateCollectionCollaboratorManagerModeDocument = gql`
  mutation UpdateCollectionCollaboratorManagerMode(
    $id: uuid!
    $manager: Boolean!
  ) {
    update_collection_collaborator_by_pk(
      pk_columns: { id: $id }
      _set: { manager: $manager }
    ) {
      id
      manager
    }
  }
`
export type UpdateCollectionCollaboratorManagerModeMutationFn =
  Apollo.MutationFunction<
    UpdateCollectionCollaboratorManagerModeMutation,
    UpdateCollectionCollaboratorManagerModeMutationVariables
  >

/**
 * __useUpdateCollectionCollaboratorManagerModeMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionCollaboratorManagerModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionCollaboratorManagerModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionCollaboratorManagerModeMutation, { data, loading, error }] = useUpdateCollectionCollaboratorManagerModeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      manager: // value for 'manager'
 *   },
 * });
 */
export function useUpdateCollectionCollaboratorManagerModeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectionCollaboratorManagerModeMutation,
    UpdateCollectionCollaboratorManagerModeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCollectionCollaboratorManagerModeMutation,
    UpdateCollectionCollaboratorManagerModeMutationVariables
  >(UpdateCollectionCollaboratorManagerModeDocument, options)
}
export type UpdateCollectionCollaboratorManagerModeMutationHookResult =
  ReturnType<typeof useUpdateCollectionCollaboratorManagerModeMutation>
export type UpdateCollectionCollaboratorManagerModeMutationResult =
  Apollo.MutationResult<UpdateCollectionCollaboratorManagerModeMutation>
export type UpdateCollectionCollaboratorManagerModeMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCollectionCollaboratorManagerModeMutation,
    UpdateCollectionCollaboratorManagerModeMutationVariables
  >
export const UpdateLastSeenCartItemsAtDocument = gql`
  mutation UpdateLastSeenCartItemsAt($id: String!) {
    update_user_flags(
      where: { user_id: { _eq: $id } }
      _set: { last_seen_cart_items_at: "now()" }
    ) {
      affected_rows
    }
  }
`
export type UpdateLastSeenCartItemsAtMutationFn = Apollo.MutationFunction<
  UpdateLastSeenCartItemsAtMutation,
  UpdateLastSeenCartItemsAtMutationVariables
>

/**
 * __useUpdateLastSeenCartItemsAtMutation__
 *
 * To run a mutation, you first call `useUpdateLastSeenCartItemsAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastSeenCartItemsAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastSeenCartItemsAtMutation, { data, loading, error }] = useUpdateLastSeenCartItemsAtMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLastSeenCartItemsAtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLastSeenCartItemsAtMutation,
    UpdateLastSeenCartItemsAtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLastSeenCartItemsAtMutation,
    UpdateLastSeenCartItemsAtMutationVariables
  >(UpdateLastSeenCartItemsAtDocument, options)
}
export type UpdateLastSeenCartItemsAtMutationHookResult = ReturnType<
  typeof useUpdateLastSeenCartItemsAtMutation
>
export type UpdateLastSeenCartItemsAtMutationResult =
  Apollo.MutationResult<UpdateLastSeenCartItemsAtMutation>
export type UpdateLastSeenCartItemsAtMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLastSeenCartItemsAtMutation,
    UpdateLastSeenCartItemsAtMutationVariables
  >
export const UpdateManyImpersonationSignedOutAtDocument = gql`
  mutation UpdateManyImpersonationSignedOutAt(
    $updates: [user_impersonation_updates!]!
  ) {
    update_user_impersonation_many(updates: $updates) {
      affected_rows
    }
  }
`
export type UpdateManyImpersonationSignedOutAtMutationFn =
  Apollo.MutationFunction<
    UpdateManyImpersonationSignedOutAtMutation,
    UpdateManyImpersonationSignedOutAtMutationVariables
  >

/**
 * __useUpdateManyImpersonationSignedOutAtMutation__
 *
 * To run a mutation, you first call `useUpdateManyImpersonationSignedOutAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyImpersonationSignedOutAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyImpersonationSignedOutAtMutation, { data, loading, error }] = useUpdateManyImpersonationSignedOutAtMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateManyImpersonationSignedOutAtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManyImpersonationSignedOutAtMutation,
    UpdateManyImpersonationSignedOutAtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateManyImpersonationSignedOutAtMutation,
    UpdateManyImpersonationSignedOutAtMutationVariables
  >(UpdateManyImpersonationSignedOutAtDocument, options)
}
export type UpdateManyImpersonationSignedOutAtMutationHookResult = ReturnType<
  typeof useUpdateManyImpersonationSignedOutAtMutation
>
export type UpdateManyImpersonationSignedOutAtMutationResult =
  Apollo.MutationResult<UpdateManyImpersonationSignedOutAtMutation>
export type UpdateManyImpersonationSignedOutAtMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateManyImpersonationSignedOutAtMutation,
    UpdateManyImpersonationSignedOutAtMutationVariables
  >
export const UpsertCollectionMediaDocument = gql`
  mutation UpsertCollectionMedia(
    $media_enabled_item_id: uuid!
    $media_enabled_item_type: String!
    $media_item: media_item_insert_input!
  ) {
    insert_media_items_one(
      object: {
        media_enabled_item_id: $media_enabled_item_id
        media_enabled_item_type: $media_enabled_item_type
        media: {
          data: $media_item
          on_conflict: {
            constraint: media_item_pkey
            update_columns: [media_type, media_url]
          }
        }
      }
      on_conflict: { constraint: media_items_pkey, update_columns: [] }
    ) {
      media_id
      media_enabled_item_id
      media_enabled_item_type
      media {
        ...mediaData
      }
    }
  }
  ${MediaDataFragmentDoc}
`
export type UpsertCollectionMediaMutationFn = Apollo.MutationFunction<
  UpsertCollectionMediaMutation,
  UpsertCollectionMediaMutationVariables
>

/**
 * __useUpsertCollectionMediaMutation__
 *
 * To run a mutation, you first call `useUpsertCollectionMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCollectionMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCollectionMediaMutation, { data, loading, error }] = useUpsertCollectionMediaMutation({
 *   variables: {
 *      media_enabled_item_id: // value for 'media_enabled_item_id'
 *      media_enabled_item_type: // value for 'media_enabled_item_type'
 *      media_item: // value for 'media_item'
 *   },
 * });
 */
export function useUpsertCollectionMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertCollectionMediaMutation,
    UpsertCollectionMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertCollectionMediaMutation,
    UpsertCollectionMediaMutationVariables
  >(UpsertCollectionMediaDocument, options)
}
export type UpsertCollectionMediaMutationHookResult = ReturnType<
  typeof useUpsertCollectionMediaMutation
>
export type UpsertCollectionMediaMutationResult =
  Apollo.MutationResult<UpsertCollectionMediaMutation>
export type UpsertCollectionMediaMutationOptions = Apollo.BaseMutationOptions<
  UpsertCollectionMediaMutation,
  UpsertCollectionMediaMutationVariables
>
export const UpsertUserNotificationPreferencesDocument = gql`
  mutation UpsertUserNotificationPreferences(
    $args: upsert_notification_preference_args!
  ) {
    upsert_notification_preference(args: $args) {
      id
      user_id
      allowable_communication
      allowed
    }
  }
`
export type UpsertUserNotificationPreferencesMutationFn =
  Apollo.MutationFunction<
    UpsertUserNotificationPreferencesMutation,
    UpsertUserNotificationPreferencesMutationVariables
  >

/**
 * __useUpsertUserNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useUpsertUserNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserNotificationPreferencesMutation, { data, loading, error }] = useUpsertUserNotificationPreferencesMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUpsertUserNotificationPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserNotificationPreferencesMutation,
    UpsertUserNotificationPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertUserNotificationPreferencesMutation,
    UpsertUserNotificationPreferencesMutationVariables
  >(UpsertUserNotificationPreferencesDocument, options)
}
export type UpsertUserNotificationPreferencesMutationHookResult = ReturnType<
  typeof useUpsertUserNotificationPreferencesMutation
>
export type UpsertUserNotificationPreferencesMutationResult =
  Apollo.MutationResult<UpsertUserNotificationPreferencesMutation>
export type UpsertUserNotificationPreferencesMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertUserNotificationPreferencesMutation,
    UpsertUserNotificationPreferencesMutationVariables
  >
export const AddCartItemNoteDocument = gql`
  mutation AddCartItemNote(
    $cart_item_id: uuid!
    $note: String!
    $private: Boolean!
  ) {
    cartItemNote: insert_cart_item_note_one(
      object: { cart_item_id: $cart_item_id, note: $note, private: $private }
      on_conflict: {
        constraint: cart_item_note_cart_item_id_key
        update_columns: [note, private]
      }
    ) {
      id
      private
      cart_item_id
      note
    }
  }
`
export type AddCartItemNoteMutationFn = Apollo.MutationFunction<
  AddCartItemNoteMutation,
  AddCartItemNoteMutationVariables
>

/**
 * __useAddCartItemNoteMutation__
 *
 * To run a mutation, you first call `useAddCartItemNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCartItemNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCartItemNoteMutation, { data, loading, error }] = useAddCartItemNoteMutation({
 *   variables: {
 *      cart_item_id: // value for 'cart_item_id'
 *      note: // value for 'note'
 *      private: // value for 'private'
 *   },
 * });
 */
export function useAddCartItemNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCartItemNoteMutation,
    AddCartItemNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddCartItemNoteMutation,
    AddCartItemNoteMutationVariables
  >(AddCartItemNoteDocument, options)
}
export type AddCartItemNoteMutationHookResult = ReturnType<
  typeof useAddCartItemNoteMutation
>
export type AddCartItemNoteMutationResult =
  Apollo.MutationResult<AddCartItemNoteMutation>
export type AddCartItemNoteMutationOptions = Apollo.BaseMutationOptions<
  AddCartItemNoteMutation,
  AddCartItemNoteMutationVariables
>
export const AddCartItemReactionDocument = gql`
  mutation AddCartItemReaction(
    $cart_item_id: uuid!
    $note: String = ""
    $private: Boolean = false
    $rating: numeric
  ) {
    cartItemNote: insert_cart_item_note_one(
      object: { cart_item_id: $cart_item_id, note: $note, private: $private }
      on_conflict: {
        constraint: cart_item_note_cart_item_id_key
        update_columns: [note, private]
      }
    ) {
      id
      private
      cart_item_id
      note
    }
    cartItemRating: update_cart_item_by_pk(
      pk_columns: { id: $cart_item_id }
      _set: { creator_rating: $rating }
    ) {
      id
      creator_rating
    }
  }
`
export type AddCartItemReactionMutationFn = Apollo.MutationFunction<
  AddCartItemReactionMutation,
  AddCartItemReactionMutationVariables
>

/**
 * __useAddCartItemReactionMutation__
 *
 * To run a mutation, you first call `useAddCartItemReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCartItemReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCartItemReactionMutation, { data, loading, error }] = useAddCartItemReactionMutation({
 *   variables: {
 *      cart_item_id: // value for 'cart_item_id'
 *      note: // value for 'note'
 *      private: // value for 'private'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useAddCartItemReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCartItemReactionMutation,
    AddCartItemReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddCartItemReactionMutation,
    AddCartItemReactionMutationVariables
  >(AddCartItemReactionDocument, options)
}
export type AddCartItemReactionMutationHookResult = ReturnType<
  typeof useAddCartItemReactionMutation
>
export type AddCartItemReactionMutationResult =
  Apollo.MutationResult<AddCartItemReactionMutation>
export type AddCartItemReactionMutationOptions = Apollo.BaseMutationOptions<
  AddCartItemReactionMutation,
  AddCartItemReactionMutationVariables
>
export const DeleteCartItemNoteDocument = gql`
  mutation DeleteCartItemNote($id: uuid!, $cart_item_id: uuid!) {
    delete_cart_item_note_by_pk(id: $id) {
      id
      note
      private
    }
    update_cart_item_by_pk(
      pk_columns: { id: $cart_item_id }
      _set: { creator_rating: null }
    ) {
      id
      creator_rating
    }
  }
`
export type DeleteCartItemNoteMutationFn = Apollo.MutationFunction<
  DeleteCartItemNoteMutation,
  DeleteCartItemNoteMutationVariables
>

/**
 * __useDeleteCartItemNoteMutation__
 *
 * To run a mutation, you first call `useDeleteCartItemNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCartItemNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCartItemNoteMutation, { data, loading, error }] = useDeleteCartItemNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      cart_item_id: // value for 'cart_item_id'
 *   },
 * });
 */
export function useDeleteCartItemNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCartItemNoteMutation,
    DeleteCartItemNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCartItemNoteMutation,
    DeleteCartItemNoteMutationVariables
  >(DeleteCartItemNoteDocument, options)
}
export type DeleteCartItemNoteMutationHookResult = ReturnType<
  typeof useDeleteCartItemNoteMutation
>
export type DeleteCartItemNoteMutationResult =
  Apollo.MutationResult<DeleteCartItemNoteMutation>
export type DeleteCartItemNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteCartItemNoteMutation,
  DeleteCartItemNoteMutationVariables
>
export const FindUserForIntercomDocument = gql`
  query FindUserForIntercom($id: String!) {
    user: user_by_pk(id: $id) {
      id
      anonymous
      display_name
      username
      has_claimed_username_computed
      picture
      has_picture_set
      internal_account
      is_creator_computed
      is_curator_computed
      has_accounts_email_computed
      ios_version
      extension_version
      first_app_install
      created_at
      updated_at
    }
  }
`

/**
 * __useFindUserForIntercomQuery__
 *
 * To run a query within a React component, call `useFindUserForIntercomQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserForIntercomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserForIntercomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindUserForIntercomQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserForIntercomQuery,
    FindUserForIntercomQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindUserForIntercomQuery,
    FindUserForIntercomQueryVariables
  >(FindUserForIntercomDocument, options)
}
export function useFindUserForIntercomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserForIntercomQuery,
    FindUserForIntercomQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindUserForIntercomQuery,
    FindUserForIntercomQueryVariables
  >(FindUserForIntercomDocument, options)
}
export type FindUserForIntercomQueryHookResult = ReturnType<
  typeof useFindUserForIntercomQuery
>
export type FindUserForIntercomLazyQueryHookResult = ReturnType<
  typeof useFindUserForIntercomLazyQuery
>
export type FindUserForIntercomQueryResult = Apollo.QueryResult<
  FindUserForIntercomQuery,
  FindUserForIntercomQueryVariables
>
export const GetCartItemsForMultiSelectDocument = gql`
  query GetCartItemsForMultiSelect($ids: [uuid!]!) {
    cart_item(where: { id: { _in: $ids } }) {
      id
      item_image_override
      display_title
      description
      pricing
      cart {
        id
        user {
          id
        }
      }
      item {
        ...itemData
      }
    }
  }
  ${ItemDataFragmentDoc}
`

/**
 * __useGetCartItemsForMultiSelectQuery__
 *
 * To run a query within a React component, call `useGetCartItemsForMultiSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemsForMultiSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemsForMultiSelectQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetCartItemsForMultiSelectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCartItemsForMultiSelectQuery,
    GetCartItemsForMultiSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCartItemsForMultiSelectQuery,
    GetCartItemsForMultiSelectQueryVariables
  >(GetCartItemsForMultiSelectDocument, options)
}
export function useGetCartItemsForMultiSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCartItemsForMultiSelectQuery,
    GetCartItemsForMultiSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCartItemsForMultiSelectQuery,
    GetCartItemsForMultiSelectQueryVariables
  >(GetCartItemsForMultiSelectDocument, options)
}
export type GetCartItemsForMultiSelectQueryHookResult = ReturnType<
  typeof useGetCartItemsForMultiSelectQuery
>
export type GetCartItemsForMultiSelectLazyQueryHookResult = ReturnType<
  typeof useGetCartItemsForMultiSelectLazyQuery
>
export type GetCartItemsForMultiSelectQueryResult = Apollo.QueryResult<
  GetCartItemsForMultiSelectQuery,
  GetCartItemsForMultiSelectQueryVariables
>
export const RemoveFromCollectionDocument = gql`
  mutation RemoveFromCollection(
    $collection_id: uuid!
    $cart_item_ids: [uuid!]!
  ) {
    collectionCartItem: delete_collection_cart_item(
      where: {
        collection_id: { _eq: $collection_id }
        cart_item_id: { _in: $cart_item_ids }
      }
    ) {
      returning {
        id
        cart_item_id
      }
    }
  }
`
export type RemoveFromCollectionMutationFn = Apollo.MutationFunction<
  RemoveFromCollectionMutation,
  RemoveFromCollectionMutationVariables
>

/**
 * __useRemoveFromCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromCollectionMutation, { data, loading, error }] = useRemoveFromCollectionMutation({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *      cart_item_ids: // value for 'cart_item_ids'
 *   },
 * });
 */
export function useRemoveFromCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFromCollectionMutation,
    RemoveFromCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveFromCollectionMutation,
    RemoveFromCollectionMutationVariables
  >(RemoveFromCollectionDocument, options)
}
export type RemoveFromCollectionMutationHookResult = ReturnType<
  typeof useRemoveFromCollectionMutation
>
export type RemoveFromCollectionMutationResult =
  Apollo.MutationResult<RemoveFromCollectionMutation>
export type RemoveFromCollectionMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromCollectionMutation,
  RemoveFromCollectionMutationVariables
>
export const ExtensionFindCollectionDocument = gql`
  query ExtensionFindCollection(
    $collection_id: uuid!
    $itemLimit: Int = 100
    $include_seo_fields: Boolean = false
  ) {
    collection: collection_by_pk(id: $collection_id) {
      collection_collaborators_aggregate {
        aggregate {
          count
        }
      }
      collection_collaborators(limit: 4) {
        id
        user {
          id
          name
          username
          picture
          verified
          created_at
        }
      }
      id
      user_id
      short_id
      name
      description
      slug
      private
      giftlist
      all
      paid
      curation
      metadata
      bookmarks
      ogimage_fingerprint_updated
      cover_image
      collection_cover_images
      cover_image_cached
      cart_items_quantity
      cart_items_count
      created_at
      updated_at
      budget
      has_collaborators
      path
      is_a_collaborator
      user {
        id
        name
        username
        picture
        verified
        created_at
        short_id
        anonymous
      }
      seo_fields @include(if: $include_seo_fields)
      collection_items(
        where: { cart_item: { deleted_at: { _is_null: true } } }
        order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
        limit: $itemLimit
      ) {
        id
        created_at
        updated_at
        cart_item {
          id
          cart_id
          item_id
          shop_key
          quantity
          bookmark
          in_collection
          archived_at
          removed_at
          removed_reason
          deleted_at
          created_at
          updated_at
          source
          affiliate_redirect_link_override
          item_image_override
          note {
            id
            note
            private
          }
          cart {
            id
            user {
              id
              name
              username
              picture
              verified
              created_at
            }
          }
          shop {
            id
            key
            cart_path
            type
            platform
            name
            display_name
            cover_image
            slug
            favicon
            created_at
            updated_at
          }
          item {
            id
            item_id
            shop_key
            display_title
            url
            image
            image_highres
            cached_image
            cached_placeholder_image
            image_metadata
            pricing
            parsed_pricing
            metadata
            created_at
            updated_at
            is_inappropriate
          }
          like_count
          collection_item_claim {
            id
            status
            user {
              id
              name
              username
              picture
              verified
              created_at
            }
          }
          collection_cart_items {
            collection_id
          }
          user {
            id
            picture
            username
          }
          feed_item {
            id
            item_id
            item_type
            in_discovery_backlog
            in_discovery_staging
            in_discovery_feed
            metadata
            liked_by_user
            like_count
          }
        }
      }
    }
  }
`

/**
 * __useExtensionFindCollectionQuery__
 *
 * To run a query within a React component, call `useExtensionFindCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useExtensionFindCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExtensionFindCollectionQuery({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *      itemLimit: // value for 'itemLimit'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useExtensionFindCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExtensionFindCollectionQuery,
    ExtensionFindCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ExtensionFindCollectionQuery,
    ExtensionFindCollectionQueryVariables
  >(ExtensionFindCollectionDocument, options)
}
export function useExtensionFindCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExtensionFindCollectionQuery,
    ExtensionFindCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ExtensionFindCollectionQuery,
    ExtensionFindCollectionQueryVariables
  >(ExtensionFindCollectionDocument, options)
}
export type ExtensionFindCollectionQueryHookResult = ReturnType<
  typeof useExtensionFindCollectionQuery
>
export type ExtensionFindCollectionLazyQueryHookResult = ReturnType<
  typeof useExtensionFindCollectionLazyQuery
>
export type ExtensionFindCollectionQueryResult = Apollo.QueryResult<
  ExtensionFindCollectionQuery,
  ExtensionFindCollectionQueryVariables
>
export const FindShopItemByUrlDocument = gql`
  query FindShopItemByUrl($url: String!) {
    shopItem: shop_item(where: { url: { _regex: $url } }) {
      ...itemData
    }
  }
  ${ItemDataFragmentDoc}
`

/**
 * __useFindShopItemByUrlQuery__
 *
 * To run a query within a React component, call `useFindShopItemByUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShopItemByUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShopItemByUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useFindShopItemByUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindShopItemByUrlQuery,
    FindShopItemByUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindShopItemByUrlQuery,
    FindShopItemByUrlQueryVariables
  >(FindShopItemByUrlDocument, options)
}
export function useFindShopItemByUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindShopItemByUrlQuery,
    FindShopItemByUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindShopItemByUrlQuery,
    FindShopItemByUrlQueryVariables
  >(FindShopItemByUrlDocument, options)
}
export type FindShopItemByUrlQueryHookResult = ReturnType<
  typeof useFindShopItemByUrlQuery
>
export type FindShopItemByUrlLazyQueryHookResult = ReturnType<
  typeof useFindShopItemByUrlLazyQuery
>
export type FindShopItemByUrlQueryResult = Apollo.QueryResult<
  FindShopItemByUrlQuery,
  FindShopItemByUrlQueryVariables
>
export const InsertAddBookmarkToCollectionWorkflowDocument = gql`
  mutation InsertAddBookmarkToCollectionWorkflow(
    $userId: String!
    $collection_id: String!
    $parent_workflow_id: uuid!
  ) {
    insert_workflow_one(
      object: {
        user_id: $userId
        data: { collection_id: $collection_id }
        type: add_bookmark_to_collection
        parent_workflow_id: $parent_workflow_id
      }
    ) {
      id
    }
  }
`
export type InsertAddBookmarkToCollectionWorkflowMutationFn =
  Apollo.MutationFunction<
    InsertAddBookmarkToCollectionWorkflowMutation,
    InsertAddBookmarkToCollectionWorkflowMutationVariables
  >

/**
 * __useInsertAddBookmarkToCollectionWorkflowMutation__
 *
 * To run a mutation, you first call `useInsertAddBookmarkToCollectionWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAddBookmarkToCollectionWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAddBookmarkToCollectionWorkflowMutation, { data, loading, error }] = useInsertAddBookmarkToCollectionWorkflowMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      collection_id: // value for 'collection_id'
 *      parent_workflow_id: // value for 'parent_workflow_id'
 *   },
 * });
 */
export function useInsertAddBookmarkToCollectionWorkflowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertAddBookmarkToCollectionWorkflowMutation,
    InsertAddBookmarkToCollectionWorkflowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertAddBookmarkToCollectionWorkflowMutation,
    InsertAddBookmarkToCollectionWorkflowMutationVariables
  >(InsertAddBookmarkToCollectionWorkflowDocument, options)
}
export type InsertAddBookmarkToCollectionWorkflowMutationHookResult =
  ReturnType<typeof useInsertAddBookmarkToCollectionWorkflowMutation>
export type InsertAddBookmarkToCollectionWorkflowMutationResult =
  Apollo.MutationResult<InsertAddBookmarkToCollectionWorkflowMutation>
export type InsertAddBookmarkToCollectionWorkflowMutationOptions =
  Apollo.BaseMutationOptions<
    InsertAddBookmarkToCollectionWorkflowMutation,
    InsertAddBookmarkToCollectionWorkflowMutationVariables
  >
export const AcceptCollectionInviteDocument = gql`
  mutation AcceptCollectionInvite($id: uuid!) {
    update_collection_invite_by_pk(
      pk_columns: { id: $id }
      _set: { status: accepted }
    ) {
      id
      status
    }
  }
`
export type AcceptCollectionInviteMutationFn = Apollo.MutationFunction<
  AcceptCollectionInviteMutation,
  AcceptCollectionInviteMutationVariables
>

/**
 * __useAcceptCollectionInviteMutation__
 *
 * To run a mutation, you first call `useAcceptCollectionInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCollectionInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCollectionInviteMutation, { data, loading, error }] = useAcceptCollectionInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptCollectionInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptCollectionInviteMutation,
    AcceptCollectionInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AcceptCollectionInviteMutation,
    AcceptCollectionInviteMutationVariables
  >(AcceptCollectionInviteDocument, options)
}
export type AcceptCollectionInviteMutationHookResult = ReturnType<
  typeof useAcceptCollectionInviteMutation
>
export type AcceptCollectionInviteMutationResult =
  Apollo.MutationResult<AcceptCollectionInviteMutation>
export type AcceptCollectionInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptCollectionInviteMutation,
  AcceptCollectionInviteMutationVariables
>
export const AddBookmarkToCollectionDocument = gql`
  mutation AddBookmarkToCollection(
    $cart_item_id: uuid!
    $collection_id: uuid!
  ) {
    collectionCartItem: insert_collection_cart_item_one(
      object: { cart_item_id: $cart_item_id, collection_id: $collection_id }
      on_conflict: {
        constraint: collection_cart_item_collection_id_cart_item_id_key
        update_columns: [collection_id, cart_item_id]
      }
    ) {
      id
    }
  }
`
export type AddBookmarkToCollectionMutationFn = Apollo.MutationFunction<
  AddBookmarkToCollectionMutation,
  AddBookmarkToCollectionMutationVariables
>

/**
 * __useAddBookmarkToCollectionMutation__
 *
 * To run a mutation, you first call `useAddBookmarkToCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBookmarkToCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBookmarkToCollectionMutation, { data, loading, error }] = useAddBookmarkToCollectionMutation({
 *   variables: {
 *      cart_item_id: // value for 'cart_item_id'
 *      collection_id: // value for 'collection_id'
 *   },
 * });
 */
export function useAddBookmarkToCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBookmarkToCollectionMutation,
    AddBookmarkToCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddBookmarkToCollectionMutation,
    AddBookmarkToCollectionMutationVariables
  >(AddBookmarkToCollectionDocument, options)
}
export type AddBookmarkToCollectionMutationHookResult = ReturnType<
  typeof useAddBookmarkToCollectionMutation
>
export type AddBookmarkToCollectionMutationResult =
  Apollo.MutationResult<AddBookmarkToCollectionMutation>
export type AddBookmarkToCollectionMutationOptions = Apollo.BaseMutationOptions<
  AddBookmarkToCollectionMutation,
  AddBookmarkToCollectionMutationVariables
>
export const AddNewCollectionDocument = gql`
  mutation AddNewCollection(
    $name: name!
    $slug: name!
    $description: String
    $private: Boolean = false
    $budget: numeric
    $include_seo_fields: Boolean = false
    $type: collection_type_enum = default
    $giftlist: Boolean = false
  ) {
    collection: insert_collection_one(
      object: {
        name: $name
        description: $description
        private: $private
        slug: $slug
        budget: $budget
        collection_type: $type
        giftlist: $giftlist
      }
      on_conflict: {
        constraint: collection_user_id_slug_key
        update_columns: [name, slug]
      }
    ) {
      ...collectionData
      ...collectionCollaboratorsData
    }
  }
  ${CollectionDataFragmentDoc}
  ${CollectionCollaboratorsDataFragmentDoc}
`
export type AddNewCollectionMutationFn = Apollo.MutationFunction<
  AddNewCollectionMutation,
  AddNewCollectionMutationVariables
>

/**
 * __useAddNewCollectionMutation__
 *
 * To run a mutation, you first call `useAddNewCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewCollectionMutation, { data, loading, error }] = useAddNewCollectionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      slug: // value for 'slug'
 *      description: // value for 'description'
 *      private: // value for 'private'
 *      budget: // value for 'budget'
 *      include_seo_fields: // value for 'include_seo_fields'
 *      type: // value for 'type'
 *      giftlist: // value for 'giftlist'
 *   },
 * });
 */
export function useAddNewCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNewCollectionMutation,
    AddNewCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddNewCollectionMutation,
    AddNewCollectionMutationVariables
  >(AddNewCollectionDocument, options)
}
export type AddNewCollectionMutationHookResult = ReturnType<
  typeof useAddNewCollectionMutation
>
export type AddNewCollectionMutationResult =
  Apollo.MutationResult<AddNewCollectionMutation>
export type AddNewCollectionMutationOptions = Apollo.BaseMutationOptions<
  AddNewCollectionMutation,
  AddNewCollectionMutationVariables
>
export const AddNewCollectionSlugHistoryDocument = gql`
  mutation AddNewCollectionSlugHistory($slug: name!, $collection_id: uuid!) {
    collection_slug_history: insert_collection_slug_history_one(
      object: { collection_id: $collection_id, slug: $slug }
      on_conflict: {
        constraint: collection_slug_history_pkey
        update_columns: []
      }
    ) {
      id
      slug
    }
  }
`
export type AddNewCollectionSlugHistoryMutationFn = Apollo.MutationFunction<
  AddNewCollectionSlugHistoryMutation,
  AddNewCollectionSlugHistoryMutationVariables
>

/**
 * __useAddNewCollectionSlugHistoryMutation__
 *
 * To run a mutation, you first call `useAddNewCollectionSlugHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewCollectionSlugHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewCollectionSlugHistoryMutation, { data, loading, error }] = useAddNewCollectionSlugHistoryMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      collection_id: // value for 'collection_id'
 *   },
 * });
 */
export function useAddNewCollectionSlugHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNewCollectionSlugHistoryMutation,
    AddNewCollectionSlugHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddNewCollectionSlugHistoryMutation,
    AddNewCollectionSlugHistoryMutationVariables
  >(AddNewCollectionSlugHistoryDocument, options)
}
export type AddNewCollectionSlugHistoryMutationHookResult = ReturnType<
  typeof useAddNewCollectionSlugHistoryMutation
>
export type AddNewCollectionSlugHistoryMutationResult =
  Apollo.MutationResult<AddNewCollectionSlugHistoryMutation>
export type AddNewCollectionSlugHistoryMutationOptions =
  Apollo.BaseMutationOptions<
    AddNewCollectionSlugHistoryMutation,
    AddNewCollectionSlugHistoryMutationVariables
  >
export const AddToCollectionDocument = gql`
  mutation AddToCollection($objects: [collection_cart_item_insert_input!]!) {
    collectionCartItem: insert_collection_cart_item(
      objects: $objects
      on_conflict: {
        constraint: collection_cart_item_collection_id_cart_item_id_key
        update_columns: [collection_id, cart_item_id]
      }
    ) {
      returning {
        id
      }
    }
  }
`
export type AddToCollectionMutationFn = Apollo.MutationFunction<
  AddToCollectionMutation,
  AddToCollectionMutationVariables
>

/**
 * __useAddToCollectionMutation__
 *
 * To run a mutation, you first call `useAddToCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCollectionMutation, { data, loading, error }] = useAddToCollectionMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddToCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddToCollectionMutation,
    AddToCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddToCollectionMutation,
    AddToCollectionMutationVariables
  >(AddToCollectionDocument, options)
}
export type AddToCollectionMutationHookResult = ReturnType<
  typeof useAddToCollectionMutation
>
export type AddToCollectionMutationResult =
  Apollo.MutationResult<AddToCollectionMutation>
export type AddToCollectionMutationOptions = Apollo.BaseMutationOptions<
  AddToCollectionMutation,
  AddToCollectionMutationVariables
>
export const AdminSaveCollectionSeoDataDocument = gql`
  mutation AdminSaveCollectionSeoData(
    $id: uuid!
    $set_input: collection_set_input!
  ) {
    update_collection_by_pk(pk_columns: { id: $id }, _set: $set_input) {
      id
      name
      slug
      collection_link
      cover_image
      collection_cover_images
      description
      seo_fields
    }
  }
`
export type AdminSaveCollectionSeoDataMutationFn = Apollo.MutationFunction<
  AdminSaveCollectionSeoDataMutation,
  AdminSaveCollectionSeoDataMutationVariables
>

/**
 * __useAdminSaveCollectionSeoDataMutation__
 *
 * To run a mutation, you first call `useAdminSaveCollectionSeoDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSaveCollectionSeoDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSaveCollectionSeoDataMutation, { data, loading, error }] = useAdminSaveCollectionSeoDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set_input: // value for 'set_input'
 *   },
 * });
 */
export function useAdminSaveCollectionSeoDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminSaveCollectionSeoDataMutation,
    AdminSaveCollectionSeoDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminSaveCollectionSeoDataMutation,
    AdminSaveCollectionSeoDataMutationVariables
  >(AdminSaveCollectionSeoDataDocument, options)
}
export type AdminSaveCollectionSeoDataMutationHookResult = ReturnType<
  typeof useAdminSaveCollectionSeoDataMutation
>
export type AdminSaveCollectionSeoDataMutationResult =
  Apollo.MutationResult<AdminSaveCollectionSeoDataMutation>
export type AdminSaveCollectionSeoDataMutationOptions =
  Apollo.BaseMutationOptions<
    AdminSaveCollectionSeoDataMutation,
    AdminSaveCollectionSeoDataMutationVariables
  >
export const DeleteShopItemDocument = gql`
  mutation DeleteShopItem($id: uuid!) {
    delete_shop_item_by_pk(id: $id) {
      id
    }
  }
`
export type DeleteShopItemMutationFn = Apollo.MutationFunction<
  DeleteShopItemMutation,
  DeleteShopItemMutationVariables
>

/**
 * __useDeleteShopItemMutation__
 *
 * To run a mutation, you first call `useDeleteShopItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShopItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShopItemMutation, { data, loading, error }] = useDeleteShopItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteShopItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteShopItemMutation,
    DeleteShopItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteShopItemMutation,
    DeleteShopItemMutationVariables
  >(DeleteShopItemDocument, options)
}
export type DeleteShopItemMutationHookResult = ReturnType<
  typeof useDeleteShopItemMutation
>
export type DeleteShopItemMutationResult =
  Apollo.MutationResult<DeleteShopItemMutation>
export type DeleteShopItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteShopItemMutation,
  DeleteShopItemMutationVariables
>
export const GetShopItemsForAdminPageDocument = gql`
  query GetShopItemsForAdminPage($offset: Int = 0, $limit: Int = 20) {
    shop_item(
      where: {
        image_highres: { _is_null: true }
        _or: [{ image: { _is_null: true } }, { image: { _eq: "" } }]
        shop: {
          shop_categorizations: { category: { _nin: [social_platform] } }
        }
      }
      limit: $limit
      offset: $offset
    ) {
      ...itemData
      shop {
        ...shopData
      }
      cart_items_aggregate {
        aggregate {
          count
        }
      }
      cart_items(order_by: { created_at: desc_nulls_last }) {
        id
        created_at
        cart {
          user {
            ...basicUserData
          }
        }
        collection_cart_items {
          collection {
            id
            slug
            name
            user {
              username
            }
          }
        }
      }
    }
  }
  ${ItemDataFragmentDoc}
  ${ShopDataFragmentDoc}
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetShopItemsForAdminPageQuery__
 *
 * To run a query within a React component, call `useGetShopItemsForAdminPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemsForAdminPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemsForAdminPageQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetShopItemsForAdminPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetShopItemsForAdminPageQuery,
    GetShopItemsForAdminPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopItemsForAdminPageQuery,
    GetShopItemsForAdminPageQueryVariables
  >(GetShopItemsForAdminPageDocument, options)
}
export function useGetShopItemsForAdminPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopItemsForAdminPageQuery,
    GetShopItemsForAdminPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopItemsForAdminPageQuery,
    GetShopItemsForAdminPageQueryVariables
  >(GetShopItemsForAdminPageDocument, options)
}
export type GetShopItemsForAdminPageQueryHookResult = ReturnType<
  typeof useGetShopItemsForAdminPageQuery
>
export type GetShopItemsForAdminPageLazyQueryHookResult = ReturnType<
  typeof useGetShopItemsForAdminPageLazyQuery
>
export type GetShopItemsForAdminPageQueryResult = Apollo.QueryResult<
  GetShopItemsForAdminPageQuery,
  GetShopItemsForAdminPageQueryVariables
>
export const GetTotalUserWithActivePushNotificationTokensDocument = gql`
  query GetTotalUserWithActivePushNotificationTokens {
    user_aggregate(
      where: { user_push_notification_tokens: { active: { _eq: true } } }
    ) {
      aggregate {
        count
      }
    }
  }
`

/**
 * __useGetTotalUserWithActivePushNotificationTokensQuery__
 *
 * To run a query within a React component, call `useGetTotalUserWithActivePushNotificationTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalUserWithActivePushNotificationTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalUserWithActivePushNotificationTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTotalUserWithActivePushNotificationTokensQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTotalUserWithActivePushNotificationTokensQuery,
    GetTotalUserWithActivePushNotificationTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTotalUserWithActivePushNotificationTokensQuery,
    GetTotalUserWithActivePushNotificationTokensQueryVariables
  >(GetTotalUserWithActivePushNotificationTokensDocument, options)
}
export function useGetTotalUserWithActivePushNotificationTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTotalUserWithActivePushNotificationTokensQuery,
    GetTotalUserWithActivePushNotificationTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTotalUserWithActivePushNotificationTokensQuery,
    GetTotalUserWithActivePushNotificationTokensQueryVariables
  >(GetTotalUserWithActivePushNotificationTokensDocument, options)
}
export type GetTotalUserWithActivePushNotificationTokensQueryHookResult =
  ReturnType<typeof useGetTotalUserWithActivePushNotificationTokensQuery>
export type GetTotalUserWithActivePushNotificationTokensLazyQueryHookResult =
  ReturnType<typeof useGetTotalUserWithActivePushNotificationTokensLazyQuery>
export type GetTotalUserWithActivePushNotificationTokensQueryResult =
  Apollo.QueryResult<
    GetTotalUserWithActivePushNotificationTokensQuery,
    GetTotalUserWithActivePushNotificationTokensQueryVariables
  >
export const UsersByIdForValidationDocument = gql`
  query UsersByIdForValidation($ids: [String!]!) {
    user(where: { id: { _in: $ids } }) {
      ...userDataForAdminPushNotification
    }
  }
  ${UserDataForAdminPushNotificationFragmentDoc}
`

/**
 * __useUsersByIdForValidationQuery__
 *
 * To run a query within a React component, call `useUsersByIdForValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByIdForValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByIdForValidationQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUsersByIdForValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersByIdForValidationQuery,
    UsersByIdForValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UsersByIdForValidationQuery,
    UsersByIdForValidationQueryVariables
  >(UsersByIdForValidationDocument, options)
}
export function useUsersByIdForValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersByIdForValidationQuery,
    UsersByIdForValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UsersByIdForValidationQuery,
    UsersByIdForValidationQueryVariables
  >(UsersByIdForValidationDocument, options)
}
export type UsersByIdForValidationQueryHookResult = ReturnType<
  typeof useUsersByIdForValidationQuery
>
export type UsersByIdForValidationLazyQueryHookResult = ReturnType<
  typeof useUsersByIdForValidationLazyQuery
>
export type UsersByIdForValidationQueryResult = Apollo.QueryResult<
  UsersByIdForValidationQuery,
  UsersByIdForValidationQueryVariables
>
export const UsersWithActiveMobileDevicesDocument = gql`
  query UsersWithActiveMobileDevices($query: String!, $limit: Int = 5) {
    users: search_users(
      args: { search: $query, limit: $limit }
      where: { user_push_notification_tokens: { active: { _eq: true } } }
    ) {
      ...userDataForAdminPushNotification
    }
  }
  ${UserDataForAdminPushNotificationFragmentDoc}
`

/**
 * __useUsersWithActiveMobileDevicesQuery__
 *
 * To run a query within a React component, call `useUsersWithActiveMobileDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersWithActiveMobileDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersWithActiveMobileDevicesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUsersWithActiveMobileDevicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersWithActiveMobileDevicesQuery,
    UsersWithActiveMobileDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UsersWithActiveMobileDevicesQuery,
    UsersWithActiveMobileDevicesQueryVariables
  >(UsersWithActiveMobileDevicesDocument, options)
}
export function useUsersWithActiveMobileDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersWithActiveMobileDevicesQuery,
    UsersWithActiveMobileDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UsersWithActiveMobileDevicesQuery,
    UsersWithActiveMobileDevicesQueryVariables
  >(UsersWithActiveMobileDevicesDocument, options)
}
export type UsersWithActiveMobileDevicesQueryHookResult = ReturnType<
  typeof useUsersWithActiveMobileDevicesQuery
>
export type UsersWithActiveMobileDevicesLazyQueryHookResult = ReturnType<
  typeof useUsersWithActiveMobileDevicesLazyQuery
>
export type UsersWithActiveMobileDevicesQueryResult = Apollo.QueryResult<
  UsersWithActiveMobileDevicesQuery,
  UsersWithActiveMobileDevicesQueryVariables
>
export const ArchiveCartItemsDocument = gql`
  mutation ArchiveCartItems($ids: [uuid!]) {
    cartItem: update_cart_item(
      where: { id: { _in: $ids } }
      _set: { archived_at: "now()" }
    ) {
      returning {
        ...cartItemData
      }
    }
  }
  ${CartItemDataFragmentDoc}
`
export type ArchiveCartItemsMutationFn = Apollo.MutationFunction<
  ArchiveCartItemsMutation,
  ArchiveCartItemsMutationVariables
>

/**
 * __useArchiveCartItemsMutation__
 *
 * To run a mutation, you first call `useArchiveCartItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCartItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCartItemsMutation, { data, loading, error }] = useArchiveCartItemsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveCartItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveCartItemsMutation,
    ArchiveCartItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveCartItemsMutation,
    ArchiveCartItemsMutationVariables
  >(ArchiveCartItemsDocument, options)
}
export type ArchiveCartItemsMutationHookResult = ReturnType<
  typeof useArchiveCartItemsMutation
>
export type ArchiveCartItemsMutationResult =
  Apollo.MutationResult<ArchiveCartItemsMutation>
export type ArchiveCartItemsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveCartItemsMutation,
  ArchiveCartItemsMutationVariables
>
export const BatchCreateMobilePushNotificationDocument = gql`
  mutation BatchCreateMobilePushNotification(
    $objects: [mobile_push_notification_insert_input!]!
  ) {
    insert_mobile_push_notification(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export type BatchCreateMobilePushNotificationMutationFn =
  Apollo.MutationFunction<
    BatchCreateMobilePushNotificationMutation,
    BatchCreateMobilePushNotificationMutationVariables
  >

/**
 * __useBatchCreateMobilePushNotificationMutation__
 *
 * To run a mutation, you first call `useBatchCreateMobilePushNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchCreateMobilePushNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchCreateMobilePushNotificationMutation, { data, loading, error }] = useBatchCreateMobilePushNotificationMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useBatchCreateMobilePushNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchCreateMobilePushNotificationMutation,
    BatchCreateMobilePushNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BatchCreateMobilePushNotificationMutation,
    BatchCreateMobilePushNotificationMutationVariables
  >(BatchCreateMobilePushNotificationDocument, options)
}
export type BatchCreateMobilePushNotificationMutationHookResult = ReturnType<
  typeof useBatchCreateMobilePushNotificationMutation
>
export type BatchCreateMobilePushNotificationMutationResult =
  Apollo.MutationResult<BatchCreateMobilePushNotificationMutation>
export type BatchCreateMobilePushNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    BatchCreateMobilePushNotificationMutation,
    BatchCreateMobilePushNotificationMutationVariables
  >
export const BatchDeactiveUserPushNotificationTokensDocument = gql`
  mutation BatchDeactiveUserPushNotificationTokens($tokens: [String!]!) {
    update_user_push_notification_token(
      where: { token: { _in: $tokens }, active: { _eq: true } }
      _set: { active: false }
    ) {
      affected_rows
    }
  }
`
export type BatchDeactiveUserPushNotificationTokensMutationFn =
  Apollo.MutationFunction<
    BatchDeactiveUserPushNotificationTokensMutation,
    BatchDeactiveUserPushNotificationTokensMutationVariables
  >

/**
 * __useBatchDeactiveUserPushNotificationTokensMutation__
 *
 * To run a mutation, you first call `useBatchDeactiveUserPushNotificationTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchDeactiveUserPushNotificationTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchDeactiveUserPushNotificationTokensMutation, { data, loading, error }] = useBatchDeactiveUserPushNotificationTokensMutation({
 *   variables: {
 *      tokens: // value for 'tokens'
 *   },
 * });
 */
export function useBatchDeactiveUserPushNotificationTokensMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchDeactiveUserPushNotificationTokensMutation,
    BatchDeactiveUserPushNotificationTokensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BatchDeactiveUserPushNotificationTokensMutation,
    BatchDeactiveUserPushNotificationTokensMutationVariables
  >(BatchDeactiveUserPushNotificationTokensDocument, options)
}
export type BatchDeactiveUserPushNotificationTokensMutationHookResult =
  ReturnType<typeof useBatchDeactiveUserPushNotificationTokensMutation>
export type BatchDeactiveUserPushNotificationTokensMutationResult =
  Apollo.MutationResult<BatchDeactiveUserPushNotificationTokensMutation>
export type BatchDeactiveUserPushNotificationTokensMutationOptions =
  Apollo.BaseMutationOptions<
    BatchDeactiveUserPushNotificationTokensMutation,
    BatchDeactiveUserPushNotificationTokensMutationVariables
  >
export const BatchUpdateFailedPushNotificationsDocument = gql`
  mutation BatchUpdateFailedPushNotifications($ids: [uuid!]!) {
    update_mobile_push_notification(
      where: { status: { _eq: sending }, id: { _in: $ids } }
      _set: { status: pending }
      _inc: { attempts: 1 }
    ) {
      affected_rows
    }
  }
`
export type BatchUpdateFailedPushNotificationsMutationFn =
  Apollo.MutationFunction<
    BatchUpdateFailedPushNotificationsMutation,
    BatchUpdateFailedPushNotificationsMutationVariables
  >

/**
 * __useBatchUpdateFailedPushNotificationsMutation__
 *
 * To run a mutation, you first call `useBatchUpdateFailedPushNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchUpdateFailedPushNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchUpdateFailedPushNotificationsMutation, { data, loading, error }] = useBatchUpdateFailedPushNotificationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBatchUpdateFailedPushNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchUpdateFailedPushNotificationsMutation,
    BatchUpdateFailedPushNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BatchUpdateFailedPushNotificationsMutation,
    BatchUpdateFailedPushNotificationsMutationVariables
  >(BatchUpdateFailedPushNotificationsDocument, options)
}
export type BatchUpdateFailedPushNotificationsMutationHookResult = ReturnType<
  typeof useBatchUpdateFailedPushNotificationsMutation
>
export type BatchUpdateFailedPushNotificationsMutationResult =
  Apollo.MutationResult<BatchUpdateFailedPushNotificationsMutation>
export type BatchUpdateFailedPushNotificationsMutationOptions =
  Apollo.BaseMutationOptions<
    BatchUpdateFailedPushNotificationsMutation,
    BatchUpdateFailedPushNotificationsMutationVariables
  >
export const BulkUpdateWorkflowStatusDocument = gql`
  mutation BulkUpdateWorkflowStatus(
    $ids: [uuid!]!
    $status: workflow_status_enum!
    $statusDetails: String
  ) {
    update_workflow(
      where: { id: { _in: $ids }, status: { _eq: pending } }
      _set: { status: $status, status_details: $statusDetails }
    ) {
      returning {
        id
        status
        status_details
      }
    }
  }
`
export type BulkUpdateWorkflowStatusMutationFn = Apollo.MutationFunction<
  BulkUpdateWorkflowStatusMutation,
  BulkUpdateWorkflowStatusMutationVariables
>

/**
 * __useBulkUpdateWorkflowStatusMutation__
 *
 * To run a mutation, you first call `useBulkUpdateWorkflowStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateWorkflowStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateWorkflowStatusMutation, { data, loading, error }] = useBulkUpdateWorkflowStatusMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      status: // value for 'status'
 *      statusDetails: // value for 'statusDetails'
 *   },
 * });
 */
export function useBulkUpdateWorkflowStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateWorkflowStatusMutation,
    BulkUpdateWorkflowStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BulkUpdateWorkflowStatusMutation,
    BulkUpdateWorkflowStatusMutationVariables
  >(BulkUpdateWorkflowStatusDocument, options)
}
export type BulkUpdateWorkflowStatusMutationHookResult = ReturnType<
  typeof useBulkUpdateWorkflowStatusMutation
>
export type BulkUpdateWorkflowStatusMutationResult =
  Apollo.MutationResult<BulkUpdateWorkflowStatusMutation>
export type BulkUpdateWorkflowStatusMutationOptions =
  Apollo.BaseMutationOptions<
    BulkUpdateWorkflowStatusMutation,
    BulkUpdateWorkflowStatusMutationVariables
  >
export const CanUserAddToCollectionDocument = gql`
  query CanUserAddToCollection($collectionId: uuid, $userId: String!) {
    collection(
      where: {
        id: { _eq: $collectionId }
        _or: [
          { user_id: { _eq: $userId } }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
    ) {
      id
    }
  }
`

/**
 * __useCanUserAddToCollectionQuery__
 *
 * To run a query within a React component, call `useCanUserAddToCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanUserAddToCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanUserAddToCollectionQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCanUserAddToCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    CanUserAddToCollectionQuery,
    CanUserAddToCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CanUserAddToCollectionQuery,
    CanUserAddToCollectionQueryVariables
  >(CanUserAddToCollectionDocument, options)
}
export function useCanUserAddToCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CanUserAddToCollectionQuery,
    CanUserAddToCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CanUserAddToCollectionQuery,
    CanUserAddToCollectionQueryVariables
  >(CanUserAddToCollectionDocument, options)
}
export type CanUserAddToCollectionQueryHookResult = ReturnType<
  typeof useCanUserAddToCollectionQuery
>
export type CanUserAddToCollectionLazyQueryHookResult = ReturnType<
  typeof useCanUserAddToCollectionLazyQuery
>
export type CanUserAddToCollectionQueryResult = Apollo.QueryResult<
  CanUserAddToCollectionQuery,
  CanUserAddToCollectionQueryVariables
>
export const CopyItemToCartDocument = gql`
  mutation CopyItemToCart(
    $itemId: uuid!
    $shopKey: String!
    $source: String!
    $metadata: jsonb = {}
  ) {
    cart: insert_cart_one(
      object: {
        shop_key: $shopKey
        cart_items: {
          data: {
            item_id: $itemId
            source: $source
            shop_key: $shopKey
            bookmark: true
            metadata: $metadata
          }
          on_conflict: {
            constraint: cart_item_item_id_shop_key_cart_id_key
            update_columns: [shop_key, updated_at]
          }
        }
      }
      on_conflict: {
        constraint: cart_user_id_shop_key_key
        update_columns: [shop_key]
      }
    ) {
      ...cartData
      ...cartItemsData
    }
  }
  ${CartDataFragmentDoc}
  ${CartItemsDataFragmentDoc}
`
export type CopyItemToCartMutationFn = Apollo.MutationFunction<
  CopyItemToCartMutation,
  CopyItemToCartMutationVariables
>

/**
 * __useCopyItemToCartMutation__
 *
 * To run a mutation, you first call `useCopyItemToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyItemToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyItemToCartMutation, { data, loading, error }] = useCopyItemToCartMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      shopKey: // value for 'shopKey'
 *      source: // value for 'source'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCopyItemToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CopyItemToCartMutation,
    CopyItemToCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CopyItemToCartMutation,
    CopyItemToCartMutationVariables
  >(CopyItemToCartDocument, options)
}
export type CopyItemToCartMutationHookResult = ReturnType<
  typeof useCopyItemToCartMutation
>
export type CopyItemToCartMutationResult =
  Apollo.MutationResult<CopyItemToCartMutation>
export type CopyItemToCartMutationOptions = Apollo.BaseMutationOptions<
  CopyItemToCartMutation,
  CopyItemToCartMutationVariables
>
export const SaveCartWithSeparatedRemovalsDocument = gql`
  mutation SaveCartWithSeparatedRemovals(
    $cart_items_data: [cart_item_insert_input!]!
    $userId: String
    $shopKey: String
    $removedItemIds: [String!] = []
    $withRemovals: Boolean = false
  ) {
    cart_items: insert_cart_item(
      objects: $cart_items_data
      on_conflict: {
        constraint: cart_item_item_id_shop_key_cart_id_key
        update_columns: [shop_key]
      }
    ) {
      returning {
        ...cartItemData
      }
    }
    update_cart_item(
      where: {
        cart: { user_id: { _eq: $userId }, shop_key: { _eq: $shopKey } }
        item: { item_id: { _in: $removedItemIds } }
      }
      _set: { removed_at: "now()" }
    ) @include(if: $withRemovals) {
      returning {
        ...cartItemData
      }
    }
  }
  ${CartItemDataFragmentDoc}
`
export type SaveCartWithSeparatedRemovalsMutationFn = Apollo.MutationFunction<
  SaveCartWithSeparatedRemovalsMutation,
  SaveCartWithSeparatedRemovalsMutationVariables
>

/**
 * __useSaveCartWithSeparatedRemovalsMutation__
 *
 * To run a mutation, you first call `useSaveCartWithSeparatedRemovalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCartWithSeparatedRemovalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCartWithSeparatedRemovalsMutation, { data, loading, error }] = useSaveCartWithSeparatedRemovalsMutation({
 *   variables: {
 *      cart_items_data: // value for 'cart_items_data'
 *      userId: // value for 'userId'
 *      shopKey: // value for 'shopKey'
 *      removedItemIds: // value for 'removedItemIds'
 *      withRemovals: // value for 'withRemovals'
 *   },
 * });
 */
export function useSaveCartWithSeparatedRemovalsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCartWithSeparatedRemovalsMutation,
    SaveCartWithSeparatedRemovalsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveCartWithSeparatedRemovalsMutation,
    SaveCartWithSeparatedRemovalsMutationVariables
  >(SaveCartWithSeparatedRemovalsDocument, options)
}
export type SaveCartWithSeparatedRemovalsMutationHookResult = ReturnType<
  typeof useSaveCartWithSeparatedRemovalsMutation
>
export type SaveCartWithSeparatedRemovalsMutationResult =
  Apollo.MutationResult<SaveCartWithSeparatedRemovalsMutation>
export type SaveCartWithSeparatedRemovalsMutationOptions =
  Apollo.BaseMutationOptions<
    SaveCartWithSeparatedRemovalsMutation,
    SaveCartWithSeparatedRemovalsMutationVariables
  >
export const ClaimCollectionCartItemDocument = gql`
  mutation ClaimCollectionCartItem(
    $cart_item_id: uuid!
    $collection_id: uuid!
  ) {
    collectionItemClaim: insert_collection_item_claim_one(
      object: { cart_item_id: $cart_item_id, collection_id: $collection_id }
    ) {
      ...collectionItemClaimData
    }
  }
  ${CollectionItemClaimDataFragmentDoc}
`
export type ClaimCollectionCartItemMutationFn = Apollo.MutationFunction<
  ClaimCollectionCartItemMutation,
  ClaimCollectionCartItemMutationVariables
>

/**
 * __useClaimCollectionCartItemMutation__
 *
 * To run a mutation, you first call `useClaimCollectionCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimCollectionCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimCollectionCartItemMutation, { data, loading, error }] = useClaimCollectionCartItemMutation({
 *   variables: {
 *      cart_item_id: // value for 'cart_item_id'
 *      collection_id: // value for 'collection_id'
 *   },
 * });
 */
export function useClaimCollectionCartItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimCollectionCartItemMutation,
    ClaimCollectionCartItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClaimCollectionCartItemMutation,
    ClaimCollectionCartItemMutationVariables
  >(ClaimCollectionCartItemDocument, options)
}
export type ClaimCollectionCartItemMutationHookResult = ReturnType<
  typeof useClaimCollectionCartItemMutation
>
export type ClaimCollectionCartItemMutationResult =
  Apollo.MutationResult<ClaimCollectionCartItemMutation>
export type ClaimCollectionCartItemMutationOptions = Apollo.BaseMutationOptions<
  ClaimCollectionCartItemMutation,
  ClaimCollectionCartItemMutationVariables
>
export const CleanCollectionSlugDocument = gql`
  mutation CleanCollectionSlug($collection_id: uuid!, $slug: name!) {
    collection: update_collection_by_pk(
      pk_columns: { id: $collection_id }
      _set: { slug: $slug }
    ) {
      id
      slug
    }
  }
`
export type CleanCollectionSlugMutationFn = Apollo.MutationFunction<
  CleanCollectionSlugMutation,
  CleanCollectionSlugMutationVariables
>

/**
 * __useCleanCollectionSlugMutation__
 *
 * To run a mutation, you first call `useCleanCollectionSlugMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCleanCollectionSlugMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cleanCollectionSlugMutation, { data, loading, error }] = useCleanCollectionSlugMutation({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCleanCollectionSlugMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CleanCollectionSlugMutation,
    CleanCollectionSlugMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CleanCollectionSlugMutation,
    CleanCollectionSlugMutationVariables
  >(CleanCollectionSlugDocument, options)
}
export type CleanCollectionSlugMutationHookResult = ReturnType<
  typeof useCleanCollectionSlugMutation
>
export type CleanCollectionSlugMutationResult =
  Apollo.MutationResult<CleanCollectionSlugMutation>
export type CleanCollectionSlugMutationOptions = Apollo.BaseMutationOptions<
  CleanCollectionSlugMutation,
  CleanCollectionSlugMutationVariables
>
export const ClearCartDeletedItemsDocument = gql`
  mutation ClearCartDeletedItems($cart_id: uuid!, $ids: [uuid!]) {
    deletedCartItems: delete_cart_item(
      where: { cart_id: { _eq: $cart_id }, id: { _in: $ids } }
    ) {
      returning {
        id
      }
    }
    cart: update_cart_by_pk(
      pk_columns: { id: $cart_id }
      _set: { cleared_at: "now()" }
    ) {
      id
    }
  }
`
export type ClearCartDeletedItemsMutationFn = Apollo.MutationFunction<
  ClearCartDeletedItemsMutation,
  ClearCartDeletedItemsMutationVariables
>

/**
 * __useClearCartDeletedItemsMutation__
 *
 * To run a mutation, you first call `useClearCartDeletedItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearCartDeletedItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearCartDeletedItemsMutation, { data, loading, error }] = useClearCartDeletedItemsMutation({
 *   variables: {
 *      cart_id: // value for 'cart_id'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useClearCartDeletedItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearCartDeletedItemsMutation,
    ClearCartDeletedItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClearCartDeletedItemsMutation,
    ClearCartDeletedItemsMutationVariables
  >(ClearCartDeletedItemsDocument, options)
}
export type ClearCartDeletedItemsMutationHookResult = ReturnType<
  typeof useClearCartDeletedItemsMutation
>
export type ClearCartDeletedItemsMutationResult =
  Apollo.MutationResult<ClearCartDeletedItemsMutation>
export type ClearCartDeletedItemsMutationOptions = Apollo.BaseMutationOptions<
  ClearCartDeletedItemsMutation,
  ClearCartDeletedItemsMutationVariables
>
export const GetCollectionByPkDocument = gql`
  query GetCollectionByPk($id: uuid!, $itemLimit: Int = 0) {
    collection: collection_by_pk(id: $id) {
      id
      name
      slug
      cart_items_count
      user {
        username
      }
      ...collectionItemsData
    }
  }
  ${CollectionItemsDataFragmentDoc}
`

/**
 * __useGetCollectionByPkQuery__
 *
 * To run a query within a React component, call `useGetCollectionByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *      itemLimit: // value for 'itemLimit'
 *   },
 * });
 */
export function useGetCollectionByPkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionByPkQuery,
    GetCollectionByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionByPkQuery,
    GetCollectionByPkQueryVariables
  >(GetCollectionByPkDocument, options)
}
export function useGetCollectionByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionByPkQuery,
    GetCollectionByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionByPkQuery,
    GetCollectionByPkQueryVariables
  >(GetCollectionByPkDocument, options)
}
export type GetCollectionByPkQueryHookResult = ReturnType<
  typeof useGetCollectionByPkQuery
>
export type GetCollectionByPkLazyQueryHookResult = ReturnType<
  typeof useGetCollectionByPkLazyQuery
>
export type GetCollectionByPkQueryResult = Apollo.QueryResult<
  GetCollectionByPkQuery,
  GetCollectionByPkQueryVariables
>
export const GetCollectionCollaboratorPicturesDocument = gql`
  query GetCollectionCollaboratorPictures(
    $collectionId: uuid
    $offset: Int = 0
    $limit: Int = 4
  ) {
    collection_collaborator_aggregate(
      where: { collection_id: { _eq: $collectionId } }
    ) {
      aggregate {
        count
      }
    }
    collection_collaborator(
      where: { collection_id: { _eq: $collectionId } }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      user {
        id
        picture
      }
    }
  }
`

/**
 * __useGetCollectionCollaboratorPicturesQuery__
 *
 * To run a query within a React component, call `useGetCollectionCollaboratorPicturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionCollaboratorPicturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionCollaboratorPicturesQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCollectionCollaboratorPicturesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCollectionCollaboratorPicturesQuery,
    GetCollectionCollaboratorPicturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionCollaboratorPicturesQuery,
    GetCollectionCollaboratorPicturesQueryVariables
  >(GetCollectionCollaboratorPicturesDocument, options)
}
export function useGetCollectionCollaboratorPicturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionCollaboratorPicturesQuery,
    GetCollectionCollaboratorPicturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionCollaboratorPicturesQuery,
    GetCollectionCollaboratorPicturesQueryVariables
  >(GetCollectionCollaboratorPicturesDocument, options)
}
export type GetCollectionCollaboratorPicturesQueryHookResult = ReturnType<
  typeof useGetCollectionCollaboratorPicturesQuery
>
export type GetCollectionCollaboratorPicturesLazyQueryHookResult = ReturnType<
  typeof useGetCollectionCollaboratorPicturesLazyQuery
>
export type GetCollectionCollaboratorPicturesQueryResult = Apollo.QueryResult<
  GetCollectionCollaboratorPicturesQuery,
  GetCollectionCollaboratorPicturesQueryVariables
>
export const GetCollectionCollaboratorsDocument = gql`
  query GetCollectionCollaborators(
    $collectionId: uuid
    $offset: Int = 0
    $limit: Int = 500
  ) {
    collection_collaborator_aggregate(
      where: { collection_id: { _eq: $collectionId } }
    ) {
      aggregate {
        count
      }
    }
    collection_collaborator(
      where: { collection_id: { _eq: $collectionId } }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      user {
        ...basicUserData
        collections_aggregate(where: { computed_is_visible: { _eq: true } }) {
          aggregate {
            count
          }
        }
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetCollectionCollaboratorsQuery__
 *
 * To run a query within a React component, call `useGetCollectionCollaboratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionCollaboratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionCollaboratorsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCollectionCollaboratorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCollectionCollaboratorsQuery,
    GetCollectionCollaboratorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionCollaboratorsQuery,
    GetCollectionCollaboratorsQueryVariables
  >(GetCollectionCollaboratorsDocument, options)
}
export function useGetCollectionCollaboratorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionCollaboratorsQuery,
    GetCollectionCollaboratorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionCollaboratorsQuery,
    GetCollectionCollaboratorsQueryVariables
  >(GetCollectionCollaboratorsDocument, options)
}
export type GetCollectionCollaboratorsQueryHookResult = ReturnType<
  typeof useGetCollectionCollaboratorsQuery
>
export type GetCollectionCollaboratorsLazyQueryHookResult = ReturnType<
  typeof useGetCollectionCollaboratorsLazyQuery
>
export type GetCollectionCollaboratorsQueryResult = Apollo.QueryResult<
  GetCollectionCollaboratorsQuery,
  GetCollectionCollaboratorsQueryVariables
>
export const GetCollectionItemsDocument = gql`
  query GetCollectionItems(
    $collectionId: uuid!
    $itemLimit: Int = 25
    $include_seo_fields: Boolean = true
    $itemOffset: Int = 0
  ) {
    collection_cart_item_aggregate(
      where: {
        collection_id: { _eq: $collectionId }
        cart_item: { deleted_at: { _is_null: true } }
      }
    ) {
      aggregate {
        count
      }
    }
    collection_cart_item(
      where: {
        collection_id: { _eq: $collectionId }
        cart_item: { deleted_at: { _is_null: true } }
      }
      order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
      limit: $itemLimit
      offset: $itemOffset
    ) {
      id
      order
      created_at
      updated_at
      cart_item {
        ...cartItemData
        feed_item {
          ...feedItemData
        }
        commentable_item_join {
          comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
            id
            comment
            user {
              username
              picture
            }
          }
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
  ${CartItemDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`

/**
 * __useGetCollectionItemsQuery__
 *
 * To run a query within a React component, call `useGetCollectionItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionItemsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      itemLimit: // value for 'itemLimit'
 *      include_seo_fields: // value for 'include_seo_fields'
 *      itemOffset: // value for 'itemOffset'
 *   },
 * });
 */
export function useGetCollectionItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionItemsQuery,
    GetCollectionItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionItemsQuery,
    GetCollectionItemsQueryVariables
  >(GetCollectionItemsDocument, options)
}
export function useGetCollectionItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionItemsQuery,
    GetCollectionItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionItemsQuery,
    GetCollectionItemsQueryVariables
  >(GetCollectionItemsDocument, options)
}
export type GetCollectionItemsQueryHookResult = ReturnType<
  typeof useGetCollectionItemsQuery
>
export type GetCollectionItemsLazyQueryHookResult = ReturnType<
  typeof useGetCollectionItemsLazyQuery
>
export type GetCollectionItemsQueryResult = Apollo.QueryResult<
  GetCollectionItemsQuery,
  GetCollectionItemsQueryVariables
>
export const GetShopEditorialLogosDocument = gql`
  query getShopEditorialLogos($shopKeys: [String!] = "") {
    shop(
      where: {
        key: { _in: $shopKeys }
        editorial_shop_logo: { _is_null: false }
      }
      limit: 10
    ) {
      editorial_shop_logo
      display_name
    }
  }
`

/**
 * __useGetShopEditorialLogosQuery__
 *
 * To run a query within a React component, call `useGetShopEditorialLogosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopEditorialLogosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopEditorialLogosQuery({
 *   variables: {
 *      shopKeys: // value for 'shopKeys'
 *   },
 * });
 */
export function useGetShopEditorialLogosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetShopEditorialLogosQuery,
    GetShopEditorialLogosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopEditorialLogosQuery,
    GetShopEditorialLogosQueryVariables
  >(GetShopEditorialLogosDocument, options)
}
export function useGetShopEditorialLogosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopEditorialLogosQuery,
    GetShopEditorialLogosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopEditorialLogosQuery,
    GetShopEditorialLogosQueryVariables
  >(GetShopEditorialLogosDocument, options)
}
export type GetShopEditorialLogosQueryHookResult = ReturnType<
  typeof useGetShopEditorialLogosQuery
>
export type GetShopEditorialLogosLazyQueryHookResult = ReturnType<
  typeof useGetShopEditorialLogosLazyQuery
>
export type GetShopEditorialLogosQueryResult = Apollo.QueryResult<
  GetShopEditorialLogosQuery,
  GetShopEditorialLogosQueryVariables
>
export const SaveCollectionEmailExcerptDocument = gql`
  mutation SaveCollectionEmailExcerpt(
    $collectionId: uuid!
    $email_excerpt: String!
  ) {
    update_collection_by_pk(
      pk_columns: { id: $collectionId }
      _set: { email_excerpt: $email_excerpt }
    ) {
      id
      email_excerpt
    }
  }
`
export type SaveCollectionEmailExcerptMutationFn = Apollo.MutationFunction<
  SaveCollectionEmailExcerptMutation,
  SaveCollectionEmailExcerptMutationVariables
>

/**
 * __useSaveCollectionEmailExcerptMutation__
 *
 * To run a mutation, you first call `useSaveCollectionEmailExcerptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCollectionEmailExcerptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCollectionEmailExcerptMutation, { data, loading, error }] = useSaveCollectionEmailExcerptMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      email_excerpt: // value for 'email_excerpt'
 *   },
 * });
 */
export function useSaveCollectionEmailExcerptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCollectionEmailExcerptMutation,
    SaveCollectionEmailExcerptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveCollectionEmailExcerptMutation,
    SaveCollectionEmailExcerptMutationVariables
  >(SaveCollectionEmailExcerptDocument, options)
}
export type SaveCollectionEmailExcerptMutationHookResult = ReturnType<
  typeof useSaveCollectionEmailExcerptMutation
>
export type SaveCollectionEmailExcerptMutationResult =
  Apollo.MutationResult<SaveCollectionEmailExcerptMutation>
export type SaveCollectionEmailExcerptMutationOptions =
  Apollo.BaseMutationOptions<
    SaveCollectionEmailExcerptMutation,
    SaveCollectionEmailExcerptMutationVariables
  >
export const ToggleCollectionStateDocument = gql`
  mutation toggleCollectionState(
    $collectionId: uuid!
    $state: collection_state_enum!
    $paid: Boolean = false
    $exclusive: Boolean = false
    $notificationPreferences: jsonb!
    $email_excerpt: String
  ) {
    update_collection_by_pk(
      pk_columns: { id: $collectionId }
      _set: {
        state: $state
        paid: $paid
        exclusive: $exclusive
        notification_preferences: $notificationPreferences
        email_excerpt: $email_excerpt
      }
    ) {
      id
      state
    }
  }
`
export type ToggleCollectionStateMutationFn = Apollo.MutationFunction<
  ToggleCollectionStateMutation,
  ToggleCollectionStateMutationVariables
>

/**
 * __useToggleCollectionStateMutation__
 *
 * To run a mutation, you first call `useToggleCollectionStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCollectionStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCollectionStateMutation, { data, loading, error }] = useToggleCollectionStateMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      state: // value for 'state'
 *      paid: // value for 'paid'
 *      exclusive: // value for 'exclusive'
 *      notificationPreferences: // value for 'notificationPreferences'
 *      email_excerpt: // value for 'email_excerpt'
 *   },
 * });
 */
export function useToggleCollectionStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleCollectionStateMutation,
    ToggleCollectionStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ToggleCollectionStateMutation,
    ToggleCollectionStateMutationVariables
  >(ToggleCollectionStateDocument, options)
}
export type ToggleCollectionStateMutationHookResult = ReturnType<
  typeof useToggleCollectionStateMutation
>
export type ToggleCollectionStateMutationResult =
  Apollo.MutationResult<ToggleCollectionStateMutation>
export type ToggleCollectionStateMutationOptions = Apollo.BaseMutationOptions<
  ToggleCollectionStateMutation,
  ToggleCollectionStateMutationVariables
>
export const UpdateCollectionCartItemsManyDocument = gql`
  mutation UpdateCollectionCartItemsMany(
    $updates: [collection_cart_item_updates!]!
  ) {
    update_collection_cart_item_many(updates: $updates) {
      affected_rows
    }
  }
`
export type UpdateCollectionCartItemsManyMutationFn = Apollo.MutationFunction<
  UpdateCollectionCartItemsManyMutation,
  UpdateCollectionCartItemsManyMutationVariables
>

/**
 * __useUpdateCollectionCartItemsManyMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionCartItemsManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionCartItemsManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionCartItemsManyMutation, { data, loading, error }] = useUpdateCollectionCartItemsManyMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateCollectionCartItemsManyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectionCartItemsManyMutation,
    UpdateCollectionCartItemsManyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCollectionCartItemsManyMutation,
    UpdateCollectionCartItemsManyMutationVariables
  >(UpdateCollectionCartItemsManyDocument, options)
}
export type UpdateCollectionCartItemsManyMutationHookResult = ReturnType<
  typeof useUpdateCollectionCartItemsManyMutation
>
export type UpdateCollectionCartItemsManyMutationResult =
  Apollo.MutationResult<UpdateCollectionCartItemsManyMutation>
export type UpdateCollectionCartItemsManyMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCollectionCartItemsManyMutation,
    UpdateCollectionCartItemsManyMutationVariables
  >
export const UpdateCollectionsManyDocument = gql`
  mutation UpdateCollectionsMany($updates: [collection_updates!]!) {
    update_collection_many(updates: $updates) {
      affected_rows
    }
  }
`
export type UpdateCollectionsManyMutationFn = Apollo.MutationFunction<
  UpdateCollectionsManyMutation,
  UpdateCollectionsManyMutationVariables
>

/**
 * __useUpdateCollectionsManyMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionsManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionsManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionsManyMutation, { data, loading, error }] = useUpdateCollectionsManyMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateCollectionsManyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectionsManyMutation,
    UpdateCollectionsManyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCollectionsManyMutation,
    UpdateCollectionsManyMutationVariables
  >(UpdateCollectionsManyDocument, options)
}
export type UpdateCollectionsManyMutationHookResult = ReturnType<
  typeof useUpdateCollectionsManyMutation
>
export type UpdateCollectionsManyMutationResult =
  Apollo.MutationResult<UpdateCollectionsManyMutation>
export type UpdateCollectionsManyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollectionsManyMutation,
  UpdateCollectionsManyMutationVariables
>
export const CreateNewCollectionForMobileBookmarkDocument = gql`
  mutation CreateNewCollectionForMobileBookmark(
    $userId: String
    $name: name!
    $description: String
    $private: Boolean! = false
    $slug: name!
  ) {
    collection: insert_collection_one(
      object: {
        user_id: $userId
        name: $name
        description: $description
        private: $private
        slug: $slug
      }
      on_conflict: {
        constraint: collection_user_id_slug_key
        update_columns: [name, slug]
      }
    ) {
      id
    }
  }
`
export type CreateNewCollectionForMobileBookmarkMutationFn =
  Apollo.MutationFunction<
    CreateNewCollectionForMobileBookmarkMutation,
    CreateNewCollectionForMobileBookmarkMutationVariables
  >

/**
 * __useCreateNewCollectionForMobileBookmarkMutation__
 *
 * To run a mutation, you first call `useCreateNewCollectionForMobileBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewCollectionForMobileBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewCollectionForMobileBookmarkMutation, { data, loading, error }] = useCreateNewCollectionForMobileBookmarkMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      private: // value for 'private'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCreateNewCollectionForMobileBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewCollectionForMobileBookmarkMutation,
    CreateNewCollectionForMobileBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateNewCollectionForMobileBookmarkMutation,
    CreateNewCollectionForMobileBookmarkMutationVariables
  >(CreateNewCollectionForMobileBookmarkDocument, options)
}
export type CreateNewCollectionForMobileBookmarkMutationHookResult = ReturnType<
  typeof useCreateNewCollectionForMobileBookmarkMutation
>
export type CreateNewCollectionForMobileBookmarkMutationResult =
  Apollo.MutationResult<CreateNewCollectionForMobileBookmarkMutation>
export type CreateNewCollectionForMobileBookmarkMutationOptions =
  Apollo.BaseMutationOptions<
    CreateNewCollectionForMobileBookmarkMutation,
    CreateNewCollectionForMobileBookmarkMutationVariables
  >
export const DeleteCartItemsDocument = gql`
  mutation DeleteCartItems($ids: [uuid!]) {
    cartItem: update_cart_item(
      where: { id: { _in: $ids } }
      _set: { deleted_at: "now()" }
    ) {
      returning {
        id
        deleted_at
      }
    }
  }
`
export type DeleteCartItemsMutationFn = Apollo.MutationFunction<
  DeleteCartItemsMutation,
  DeleteCartItemsMutationVariables
>

/**
 * __useDeleteCartItemsMutation__
 *
 * To run a mutation, you first call `useDeleteCartItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCartItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCartItemsMutation, { data, loading, error }] = useDeleteCartItemsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteCartItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCartItemsMutation,
    DeleteCartItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCartItemsMutation,
    DeleteCartItemsMutationVariables
  >(DeleteCartItemsDocument, options)
}
export type DeleteCartItemsMutationHookResult = ReturnType<
  typeof useDeleteCartItemsMutation
>
export type DeleteCartItemsMutationResult =
  Apollo.MutationResult<DeleteCartItemsMutation>
export type DeleteCartItemsMutationOptions = Apollo.BaseMutationOptions<
  DeleteCartItemsMutation,
  DeleteCartItemsMutationVariables
>
export const DeleteCollectionCollaboratorInviteDocument = gql`
  mutation DeleteCollectionCollaboratorInvite($id: uuid!) {
    delete_collection_invite_by_pk(id: $id) {
      id
    }
  }
`
export type DeleteCollectionCollaboratorInviteMutationFn =
  Apollo.MutationFunction<
    DeleteCollectionCollaboratorInviteMutation,
    DeleteCollectionCollaboratorInviteMutationVariables
  >

/**
 * __useDeleteCollectionCollaboratorInviteMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionCollaboratorInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionCollaboratorInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionCollaboratorInviteMutation, { data, loading, error }] = useDeleteCollectionCollaboratorInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCollectionCollaboratorInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCollectionCollaboratorInviteMutation,
    DeleteCollectionCollaboratorInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCollectionCollaboratorInviteMutation,
    DeleteCollectionCollaboratorInviteMutationVariables
  >(DeleteCollectionCollaboratorInviteDocument, options)
}
export type DeleteCollectionCollaboratorInviteMutationHookResult = ReturnType<
  typeof useDeleteCollectionCollaboratorInviteMutation
>
export type DeleteCollectionCollaboratorInviteMutationResult =
  Apollo.MutationResult<DeleteCollectionCollaboratorInviteMutation>
export type DeleteCollectionCollaboratorInviteMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCollectionCollaboratorInviteMutation,
    DeleteCollectionCollaboratorInviteMutationVariables
  >
export const DeleteDiscoveryFeedDocument = gql`
  mutation DeleteDiscoveryFeed($feed_item_id: uuid!) {
    delete_discovery_feed(where: { feed_item_id: { _eq: $feed_item_id } }) {
      returning {
        id
        feed_item {
          ...feedItemData
        }
      }
    }
  }
  ${FeedItemDataFragmentDoc}
`
export type DeleteDiscoveryFeedMutationFn = Apollo.MutationFunction<
  DeleteDiscoveryFeedMutation,
  DeleteDiscoveryFeedMutationVariables
>

/**
 * __useDeleteDiscoveryFeedMutation__
 *
 * To run a mutation, you first call `useDeleteDiscoveryFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscoveryFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscoveryFeedMutation, { data, loading, error }] = useDeleteDiscoveryFeedMutation({
 *   variables: {
 *      feed_item_id: // value for 'feed_item_id'
 *   },
 * });
 */
export function useDeleteDiscoveryFeedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDiscoveryFeedMutation,
    DeleteDiscoveryFeedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteDiscoveryFeedMutation,
    DeleteDiscoveryFeedMutationVariables
  >(DeleteDiscoveryFeedDocument, options)
}
export type DeleteDiscoveryFeedMutationHookResult = ReturnType<
  typeof useDeleteDiscoveryFeedMutation
>
export type DeleteDiscoveryFeedMutationResult =
  Apollo.MutationResult<DeleteDiscoveryFeedMutation>
export type DeleteDiscoveryFeedMutationOptions = Apollo.BaseMutationOptions<
  DeleteDiscoveryFeedMutation,
  DeleteDiscoveryFeedMutationVariables
>
export const DeleteDiscoveryFeedBacklogDocument = gql`
  mutation DeleteDiscoveryFeedBacklog($feed_item_id: uuid!) {
    delete_discovery_backlog(where: { feed_item_id: { _eq: $feed_item_id } }) {
      returning {
        id
        feed_item {
          ...feedItemData
        }
      }
    }
  }
  ${FeedItemDataFragmentDoc}
`
export type DeleteDiscoveryFeedBacklogMutationFn = Apollo.MutationFunction<
  DeleteDiscoveryFeedBacklogMutation,
  DeleteDiscoveryFeedBacklogMutationVariables
>

/**
 * __useDeleteDiscoveryFeedBacklogMutation__
 *
 * To run a mutation, you first call `useDeleteDiscoveryFeedBacklogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscoveryFeedBacklogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscoveryFeedBacklogMutation, { data, loading, error }] = useDeleteDiscoveryFeedBacklogMutation({
 *   variables: {
 *      feed_item_id: // value for 'feed_item_id'
 *   },
 * });
 */
export function useDeleteDiscoveryFeedBacklogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDiscoveryFeedBacklogMutation,
    DeleteDiscoveryFeedBacklogMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteDiscoveryFeedBacklogMutation,
    DeleteDiscoveryFeedBacklogMutationVariables
  >(DeleteDiscoveryFeedBacklogDocument, options)
}
export type DeleteDiscoveryFeedBacklogMutationHookResult = ReturnType<
  typeof useDeleteDiscoveryFeedBacklogMutation
>
export type DeleteDiscoveryFeedBacklogMutationResult =
  Apollo.MutationResult<DeleteDiscoveryFeedBacklogMutation>
export type DeleteDiscoveryFeedBacklogMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteDiscoveryFeedBacklogMutation,
    DeleteDiscoveryFeedBacklogMutationVariables
  >
export const DeleteShopCategorizationDocument = gql`
  mutation DeleteShopCategorization($shop_key: String!, $weight: Int!) {
    delete_shop_categorization(
      where: { shop_key: { _eq: $shop_key }, weight: { _eq: $weight } }
    ) {
      returning {
        id
      }
    }
  }
`
export type DeleteShopCategorizationMutationFn = Apollo.MutationFunction<
  DeleteShopCategorizationMutation,
  DeleteShopCategorizationMutationVariables
>

/**
 * __useDeleteShopCategorizationMutation__
 *
 * To run a mutation, you first call `useDeleteShopCategorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShopCategorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShopCategorizationMutation, { data, loading, error }] = useDeleteShopCategorizationMutation({
 *   variables: {
 *      shop_key: // value for 'shop_key'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useDeleteShopCategorizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteShopCategorizationMutation,
    DeleteShopCategorizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteShopCategorizationMutation,
    DeleteShopCategorizationMutationVariables
  >(DeleteShopCategorizationDocument, options)
}
export type DeleteShopCategorizationMutationHookResult = ReturnType<
  typeof useDeleteShopCategorizationMutation
>
export type DeleteShopCategorizationMutationResult =
  Apollo.MutationResult<DeleteShopCategorizationMutation>
export type DeleteShopCategorizationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteShopCategorizationMutation,
    DeleteShopCategorizationMutationVariables
  >
export const EditCollectionDocument = gql`
  mutation EditCollection(
    $id: uuid!
    $name: name!
    $description: String!
    $private: Boolean
    $slug: name
    $cover_image: String
    $budget: numeric
    $include_seo_fields: Boolean = false
  ) {
    collection: update_collection_by_pk(
      pk_columns: { id: $id }
      _set: {
        cover_image: $cover_image
        name: $name
        description: $description
        private: $private
        slug: $slug
        budget: $budget
      }
    ) {
      ...collectionData
    }
  }
  ${CollectionDataFragmentDoc}
`
export type EditCollectionMutationFn = Apollo.MutationFunction<
  EditCollectionMutation,
  EditCollectionMutationVariables
>

/**
 * __useEditCollectionMutation__
 *
 * To run a mutation, you first call `useEditCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCollectionMutation, { data, loading, error }] = useEditCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      private: // value for 'private'
 *      slug: // value for 'slug'
 *      cover_image: // value for 'cover_image'
 *      budget: // value for 'budget'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useEditCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditCollectionMutation,
    EditCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EditCollectionMutation,
    EditCollectionMutationVariables
  >(EditCollectionDocument, options)
}
export type EditCollectionMutationHookResult = ReturnType<
  typeof useEditCollectionMutation
>
export type EditCollectionMutationResult =
  Apollo.MutationResult<EditCollectionMutation>
export type EditCollectionMutationOptions = Apollo.BaseMutationOptions<
  EditCollectionMutation,
  EditCollectionMutationVariables
>
export const GetCartItemSavedDataForEmailDocument = gql`
  query getCartItemSavedDataForEmail($id: uuid!) {
    notification_center_item_by_pk(id: $id) {
      id
      user {
        ...userForEmailData
        username
      }
      notifiable_item {
        cart_item {
          id
          item_image_override
          item {
            id
            display_title
            image
            cached_image
          }
          shop {
            name
            slug
            has_pdp
          }
          originating_collection {
            id
            name
            metadata
          }
        }
      }
    }
  }
  ${UserForEmailDataFragmentDoc}
`

/**
 * __useGetCartItemSavedDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetCartItemSavedDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemSavedDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemSavedDataForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCartItemSavedDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCartItemSavedDataForEmailQuery,
    GetCartItemSavedDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCartItemSavedDataForEmailQuery,
    GetCartItemSavedDataForEmailQueryVariables
  >(GetCartItemSavedDataForEmailDocument, options)
}
export function useGetCartItemSavedDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCartItemSavedDataForEmailQuery,
    GetCartItemSavedDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCartItemSavedDataForEmailQuery,
    GetCartItemSavedDataForEmailQueryVariables
  >(GetCartItemSavedDataForEmailDocument, options)
}
export type GetCartItemSavedDataForEmailQueryHookResult = ReturnType<
  typeof useGetCartItemSavedDataForEmailQuery
>
export type GetCartItemSavedDataForEmailLazyQueryHookResult = ReturnType<
  typeof useGetCartItemSavedDataForEmailLazyQuery
>
export type GetCartItemSavedDataForEmailQueryResult = Apollo.QueryResult<
  GetCartItemSavedDataForEmailQuery,
  GetCartItemSavedDataForEmailQueryVariables
>
export const GetCollabInviteAcceptedDataForEmailDocument = gql`
  query GetCollabInviteAcceptedDataForEmail($id: uuid!) {
    notification_center_item_by_pk(id: $id) {
      id
      user {
        ...userForEmailData
        username
      }
      notifiable_item {
        collection_invite {
          id
          collection {
            id
            name
            slug
            metadata
            user {
              id
              username
            }
            preview_collection_items(limit: 3) {
              id
              cart_item {
                id
                item_image_override
                display_title
                item {
                  id
                  cached_image
                  shop {
                    display_name
                  }
                }
              }
            }
          }
          invitee {
            name
            username
            picture
          }
        }
      }
    }
  }
  ${UserForEmailDataFragmentDoc}
`

/**
 * __useGetCollabInviteAcceptedDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetCollabInviteAcceptedDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollabInviteAcceptedDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollabInviteAcceptedDataForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollabInviteAcceptedDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollabInviteAcceptedDataForEmailQuery,
    GetCollabInviteAcceptedDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollabInviteAcceptedDataForEmailQuery,
    GetCollabInviteAcceptedDataForEmailQueryVariables
  >(GetCollabInviteAcceptedDataForEmailDocument, options)
}
export function useGetCollabInviteAcceptedDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollabInviteAcceptedDataForEmailQuery,
    GetCollabInviteAcceptedDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollabInviteAcceptedDataForEmailQuery,
    GetCollabInviteAcceptedDataForEmailQueryVariables
  >(GetCollabInviteAcceptedDataForEmailDocument, options)
}
export type GetCollabInviteAcceptedDataForEmailQueryHookResult = ReturnType<
  typeof useGetCollabInviteAcceptedDataForEmailQuery
>
export type GetCollabInviteAcceptedDataForEmailLazyQueryHookResult = ReturnType<
  typeof useGetCollabInviteAcceptedDataForEmailLazyQuery
>
export type GetCollabInviteAcceptedDataForEmailQueryResult = Apollo.QueryResult<
  GetCollabInviteAcceptedDataForEmailQuery,
  GetCollabInviteAcceptedDataForEmailQueryVariables
>
export const GetCollabInviteDataForEmailDocument = gql`
  query GetCollabInviteDataForEmail($id: uuid!) {
    collection_invite_by_pk(id: $id) {
      id
      collection {
        id
        name
        cover_image_cached
        path
        user {
          name
          username
          picture
        }
      }
      invitee_email
      invitee_id
      requester_id
      invitee {
        ...userForEmailData
      }
    }
  }
  ${UserForEmailDataFragmentDoc}
`

/**
 * __useGetCollabInviteDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetCollabInviteDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollabInviteDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollabInviteDataForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollabInviteDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollabInviteDataForEmailQuery,
    GetCollabInviteDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollabInviteDataForEmailQuery,
    GetCollabInviteDataForEmailQueryVariables
  >(GetCollabInviteDataForEmailDocument, options)
}
export function useGetCollabInviteDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollabInviteDataForEmailQuery,
    GetCollabInviteDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollabInviteDataForEmailQuery,
    GetCollabInviteDataForEmailQueryVariables
  >(GetCollabInviteDataForEmailDocument, options)
}
export type GetCollabInviteDataForEmailQueryHookResult = ReturnType<
  typeof useGetCollabInviteDataForEmailQuery
>
export type GetCollabInviteDataForEmailLazyQueryHookResult = ReturnType<
  typeof useGetCollabInviteDataForEmailLazyQuery
>
export type GetCollabInviteDataForEmailQueryResult = Apollo.QueryResult<
  GetCollabInviteDataForEmailQuery,
  GetCollabInviteDataForEmailQueryVariables
>
export const GetContactInviteDataForEmailDocument = gql`
  query GetContactInviteDataForEmail($id: uuid!) {
    contact_invite_by_pk(id: $id) {
      id
      type
      contact_info
      requester {
        name
        username
        picture
      }
    }
  }
`

/**
 * __useGetContactInviteDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetContactInviteDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactInviteDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactInviteDataForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactInviteDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactInviteDataForEmailQuery,
    GetContactInviteDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContactInviteDataForEmailQuery,
    GetContactInviteDataForEmailQueryVariables
  >(GetContactInviteDataForEmailDocument, options)
}
export function useGetContactInviteDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactInviteDataForEmailQuery,
    GetContactInviteDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContactInviteDataForEmailQuery,
    GetContactInviteDataForEmailQueryVariables
  >(GetContactInviteDataForEmailDocument, options)
}
export type GetContactInviteDataForEmailQueryHookResult = ReturnType<
  typeof useGetContactInviteDataForEmailQuery
>
export type GetContactInviteDataForEmailLazyQueryHookResult = ReturnType<
  typeof useGetContactInviteDataForEmailLazyQuery
>
export type GetContactInviteDataForEmailQueryResult = Apollo.QueryResult<
  GetContactInviteDataForEmailQuery,
  GetContactInviteDataForEmailQueryVariables
>
export const GetFeaturedCollectionInDiscoveryFeedDataDocument = gql`
  query getFeaturedCollectionInDiscoveryFeedData($id: uuid!) {
    notification_center_item_by_pk(id: $id) {
      id
      user {
        ...userForEmailData
        username
      }
      notifiable_item {
        collection {
          id
          name
          slug
          collection_cover_images
          preview_collection_items(limit: 3) {
            id
            cart_item {
              id
              item_image_override
              item {
                id
                image
                cached_image
                shop {
                  display_name
                }
              }
            }
          }
        }
      }
    }
  }
  ${UserForEmailDataFragmentDoc}
`

/**
 * __useGetFeaturedCollectionInDiscoveryFeedDataQuery__
 *
 * To run a query within a React component, call `useGetFeaturedCollectionInDiscoveryFeedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedCollectionInDiscoveryFeedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedCollectionInDiscoveryFeedDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeaturedCollectionInDiscoveryFeedDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeaturedCollectionInDiscoveryFeedDataQuery,
    GetFeaturedCollectionInDiscoveryFeedDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetFeaturedCollectionInDiscoveryFeedDataQuery,
    GetFeaturedCollectionInDiscoveryFeedDataQueryVariables
  >(GetFeaturedCollectionInDiscoveryFeedDataDocument, options)
}
export function useGetFeaturedCollectionInDiscoveryFeedDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeaturedCollectionInDiscoveryFeedDataQuery,
    GetFeaturedCollectionInDiscoveryFeedDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetFeaturedCollectionInDiscoveryFeedDataQuery,
    GetFeaturedCollectionInDiscoveryFeedDataQueryVariables
  >(GetFeaturedCollectionInDiscoveryFeedDataDocument, options)
}
export type GetFeaturedCollectionInDiscoveryFeedDataQueryHookResult =
  ReturnType<typeof useGetFeaturedCollectionInDiscoveryFeedDataQuery>
export type GetFeaturedCollectionInDiscoveryFeedDataLazyQueryHookResult =
  ReturnType<typeof useGetFeaturedCollectionInDiscoveryFeedDataLazyQuery>
export type GetFeaturedCollectionInDiscoveryFeedDataQueryResult =
  Apollo.QueryResult<
    GetFeaturedCollectionInDiscoveryFeedDataQuery,
    GetFeaturedCollectionInDiscoveryFeedDataQueryVariables
  >
export const GetNewFollowerDataForEmailDocument = gql`
  query GetNewFollowerDataForEmail($id: uuid!) {
    notification_center_item_by_pk(id: $id) {
      id
      user {
        ...userForEmailData
        username
      }
      notifiable_item {
        follow {
          user {
            name
            username
            picture
          }
          followable_item {
            collection {
              id
              name
              slug
              metadata
              user {
                id
                username
              }
              preview_collection_items(limit: 3) {
                id
                cart_item {
                  id
                  item_image_override
                  display_title
                  item {
                    id
                    cached_image
                    shop {
                      display_name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${UserForEmailDataFragmentDoc}
`

/**
 * __useGetNewFollowerDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetNewFollowerDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewFollowerDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewFollowerDataForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNewFollowerDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNewFollowerDataForEmailQuery,
    GetNewFollowerDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetNewFollowerDataForEmailQuery,
    GetNewFollowerDataForEmailQueryVariables
  >(GetNewFollowerDataForEmailDocument, options)
}
export function useGetNewFollowerDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNewFollowerDataForEmailQuery,
    GetNewFollowerDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetNewFollowerDataForEmailQuery,
    GetNewFollowerDataForEmailQueryVariables
  >(GetNewFollowerDataForEmailDocument, options)
}
export type GetNewFollowerDataForEmailQueryHookResult = ReturnType<
  typeof useGetNewFollowerDataForEmailQuery
>
export type GetNewFollowerDataForEmailLazyQueryHookResult = ReturnType<
  typeof useGetNewFollowerDataForEmailLazyQuery
>
export type GetNewFollowerDataForEmailQueryResult = Apollo.QueryResult<
  GetNewFollowerDataForEmailQuery,
  GetNewFollowerDataForEmailQueryVariables
>
export const GetUserForEmailDocument = gql`
  query GetUserForEmail($id: String!) {
    user: user_by_pk(id: $id) {
      ...userForEmailData
    }
  }
  ${UserForEmailDataFragmentDoc}
`

/**
 * __useGetUserForEmailQuery__
 *
 * To run a query within a React component, call `useGetUserForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserForEmailQuery,
    GetUserForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserForEmailQuery, GetUserForEmailQueryVariables>(
    GetUserForEmailDocument,
    options
  )
}
export function useGetUserForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserForEmailQuery,
    GetUserForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserForEmailQuery,
    GetUserForEmailQueryVariables
  >(GetUserForEmailDocument, options)
}
export type GetUserForEmailQueryHookResult = ReturnType<
  typeof useGetUserForEmailQuery
>
export type GetUserForEmailLazyQueryHookResult = ReturnType<
  typeof useGetUserForEmailLazyQuery
>
export type GetUserForEmailQueryResult = Apollo.QueryResult<
  GetUserForEmailQuery,
  GetUserForEmailQueryVariables
>
export const FindDealHopGateFlagsExtensionDocument = gql`
  query findDealHopGateFlagsExtension($user_id: String!) {
    deal_hop_gate_by_pk(user_id: $user_id) {
      ...dealHopGateData
    }
  }
  ${DealHopGateDataFragmentDoc}
`

/**
 * __useFindDealHopGateFlagsExtensionQuery__
 *
 * To run a query within a React component, call `useFindDealHopGateFlagsExtensionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDealHopGateFlagsExtensionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDealHopGateFlagsExtensionQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useFindDealHopGateFlagsExtensionQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindDealHopGateFlagsExtensionQuery,
    FindDealHopGateFlagsExtensionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindDealHopGateFlagsExtensionQuery,
    FindDealHopGateFlagsExtensionQueryVariables
  >(FindDealHopGateFlagsExtensionDocument, options)
}
export function useFindDealHopGateFlagsExtensionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindDealHopGateFlagsExtensionQuery,
    FindDealHopGateFlagsExtensionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindDealHopGateFlagsExtensionQuery,
    FindDealHopGateFlagsExtensionQueryVariables
  >(FindDealHopGateFlagsExtensionDocument, options)
}
export type FindDealHopGateFlagsExtensionQueryHookResult = ReturnType<
  typeof useFindDealHopGateFlagsExtensionQuery
>
export type FindDealHopGateFlagsExtensionLazyQueryHookResult = ReturnType<
  typeof useFindDealHopGateFlagsExtensionLazyQuery
>
export type FindDealHopGateFlagsExtensionQueryResult = Apollo.QueryResult<
  FindDealHopGateFlagsExtensionQuery,
  FindDealHopGateFlagsExtensionQueryVariables
>
export const GetSavedItemByUrlDocument = gql`
  query GetSavedItemByUrl(
    $userId: String
    $itemUrl: String = ""
    $slug: name!
  ) {
    carts: cart(
      where: {
        shop: { slug: { _eq: $slug } }
        user: { id: { _eq: $userId } }
        cart_items: {
          deleted_at: { _is_null: true }
          archived_at: { _is_null: true }
          hidden: { _eq: false }
          item: { has_broken_image: { _eq: false } }
        }
      }
    ) {
      cart_items(where: { item: { url: { _ilike: $itemUrl } } }) {
        id
        points
      }
    }
  }
`

/**
 * __useGetSavedItemByUrlQuery__
 *
 * To run a query within a React component, call `useGetSavedItemByUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedItemByUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedItemByUrlQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      itemUrl: // value for 'itemUrl'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetSavedItemByUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSavedItemByUrlQuery,
    GetSavedItemByUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSavedItemByUrlQuery,
    GetSavedItemByUrlQueryVariables
  >(GetSavedItemByUrlDocument, options)
}
export function useGetSavedItemByUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSavedItemByUrlQuery,
    GetSavedItemByUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSavedItemByUrlQuery,
    GetSavedItemByUrlQueryVariables
  >(GetSavedItemByUrlDocument, options)
}
export type GetSavedItemByUrlQueryHookResult = ReturnType<
  typeof useGetSavedItemByUrlQuery
>
export type GetSavedItemByUrlLazyQueryHookResult = ReturnType<
  typeof useGetSavedItemByUrlLazyQuery
>
export type GetSavedItemByUrlQueryResult = Apollo.QueryResult<
  GetSavedItemByUrlQuery,
  GetSavedItemByUrlQueryVariables
>
export const UpdateDealHopPendingGateTimestampExtensionDocument = gql`
  mutation UpdateDealHopPendingGateTimestampExtension(
    $userId: String!
    $timestamp: timestamptz!
  ) {
    update_deal_hop_gate_by_pk(
      pk_columns: { user_id: $userId }
      _set: {
        deal_hop_gate_pending: null
        deal_hop_gated: $timestamp
        paywall_gated_at: $timestamp
      }
    ) {
      ...dealHopGateData
    }
  }
  ${DealHopGateDataFragmentDoc}
`
export type UpdateDealHopPendingGateTimestampExtensionMutationFn =
  Apollo.MutationFunction<
    UpdateDealHopPendingGateTimestampExtensionMutation,
    UpdateDealHopPendingGateTimestampExtensionMutationVariables
  >

/**
 * __useUpdateDealHopPendingGateTimestampExtensionMutation__
 *
 * To run a mutation, you first call `useUpdateDealHopPendingGateTimestampExtensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealHopPendingGateTimestampExtensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealHopPendingGateTimestampExtensionMutation, { data, loading, error }] = useUpdateDealHopPendingGateTimestampExtensionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useUpdateDealHopPendingGateTimestampExtensionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDealHopPendingGateTimestampExtensionMutation,
    UpdateDealHopPendingGateTimestampExtensionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDealHopPendingGateTimestampExtensionMutation,
    UpdateDealHopPendingGateTimestampExtensionMutationVariables
  >(UpdateDealHopPendingGateTimestampExtensionDocument, options)
}
export type UpdateDealHopPendingGateTimestampExtensionMutationHookResult =
  ReturnType<typeof useUpdateDealHopPendingGateTimestampExtensionMutation>
export type UpdateDealHopPendingGateTimestampExtensionMutationResult =
  Apollo.MutationResult<UpdateDealHopPendingGateTimestampExtensionMutation>
export type UpdateDealHopPendingGateTimestampExtensionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDealHopPendingGateTimestampExtensionMutation,
    UpdateDealHopPendingGateTimestampExtensionMutationVariables
  >
export const PermissionHeartbeatDocument = gql`
  mutation permissionHeartbeat(
    $userId: String!
    $update: user_flags_set_input
  ) {
    update_user_flags_by_pk(pk_columns: { user_id: $userId }, _set: $update) {
      user_id
    }
  }
`
export type PermissionHeartbeatMutationFn = Apollo.MutationFunction<
  PermissionHeartbeatMutation,
  PermissionHeartbeatMutationVariables
>

/**
 * __usePermissionHeartbeatMutation__
 *
 * To run a mutation, you first call `usePermissionHeartbeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermissionHeartbeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permissionHeartbeatMutation, { data, loading, error }] = usePermissionHeartbeatMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function usePermissionHeartbeatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PermissionHeartbeatMutation,
    PermissionHeartbeatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PermissionHeartbeatMutation,
    PermissionHeartbeatMutationVariables
  >(PermissionHeartbeatDocument, options)
}
export type PermissionHeartbeatMutationHookResult = ReturnType<
  typeof usePermissionHeartbeatMutation
>
export type PermissionHeartbeatMutationResult =
  Apollo.MutationResult<PermissionHeartbeatMutation>
export type PermissionHeartbeatMutationOptions = Apollo.BaseMutationOptions<
  PermissionHeartbeatMutation,
  PermissionHeartbeatMutationVariables
>
export const GetRecentUserItemsDocument = gql`
  query GetRecentUserItems($userId: String, $limit: Int = 9) {
    cart_item(
      where: {
        cart: { user_id: { _eq: $userId } }
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
      }
      order_by: { updated_at: desc_nulls_last }
      limit: $limit
    ) {
      id
      item {
        image
        image_highres
        cached_image
      }
      item_image_override
    }
  }
`

/**
 * __useGetRecentUserItemsQuery__
 *
 * To run a query within a React component, call `useGetRecentUserItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentUserItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentUserItemsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetRecentUserItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecentUserItemsQuery,
    GetRecentUserItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetRecentUserItemsQuery,
    GetRecentUserItemsQueryVariables
  >(GetRecentUserItemsDocument, options)
}
export function useGetRecentUserItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecentUserItemsQuery,
    GetRecentUserItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetRecentUserItemsQuery,
    GetRecentUserItemsQueryVariables
  >(GetRecentUserItemsDocument, options)
}
export type GetRecentUserItemsQueryHookResult = ReturnType<
  typeof useGetRecentUserItemsQuery
>
export type GetRecentUserItemsLazyQueryHookResult = ReturnType<
  typeof useGetRecentUserItemsLazyQuery
>
export type GetRecentUserItemsQueryResult = Apollo.QueryResult<
  GetRecentUserItemsQuery,
  GetRecentUserItemsQueryVariables
>
export const GetUserSavedCartDataDocument = gql`
  query GetUserSavedCartData(
    $slug: name!
    $userId: String!
    $itemUrl: String = ""
  ) {
    my_carts: cart(
      where: {
        shop: { slug: { _eq: $slug } }
        user: { id: { _eq: $userId } }
        cart_items: {
          deleted_at: { _is_null: true }
          archived_at: { _is_null: true }
          hidden: { _eq: false }
          item: { has_broken_image: { _eq: false } }
        }
      }
    ) {
      updated_at
      user {
        verified
      }
      shop {
        slug
      }
      saved_items: cart_items(
        where: { item: { url: { _ilike: $itemUrl } } }
        limit: 1
      ) {
        id
        source
        item {
          id
          display_title
        }
        points
      }
      cart_items_aggregate(
        where: {
          deleted_at: { _is_null: true }
          archived_at: { _is_null: true }
          hidden: { _eq: false }
        }
      ) {
        aggregate {
          count
        }
      }
      cart_items(
        where: {
          deleted_at: { _is_null: true }
          archived_at: { _is_null: true }
          hidden: { _eq: false }
          item: { has_broken_image: { _eq: false } }
        }
        limit: 3
        order_by: { updated_at: desc_nulls_last }
      ) {
        id
        item_image_override
        item_title_override
        affiliate_redirect_link_override
        points
        item {
          id
          metadata
          display_title
          image_highres
          cached_image
          image
          image_metadata
          cached_placeholder_image
          is_inappropriate
        }
        cart {
          user_id
        }
      }
    }
  }
`

/**
 * __useGetUserSavedCartDataQuery__
 *
 * To run a query within a React component, call `useGetUserSavedCartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSavedCartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSavedCartDataQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      userId: // value for 'userId'
 *      itemUrl: // value for 'itemUrl'
 *   },
 * });
 */
export function useGetUserSavedCartDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserSavedCartDataQuery,
    GetUserSavedCartDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserSavedCartDataQuery,
    GetUserSavedCartDataQueryVariables
  >(GetUserSavedCartDataDocument, options)
}
export function useGetUserSavedCartDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSavedCartDataQuery,
    GetUserSavedCartDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserSavedCartDataQuery,
    GetUserSavedCartDataQueryVariables
  >(GetUserSavedCartDataDocument, options)
}
export type GetUserSavedCartDataQueryHookResult = ReturnType<
  typeof useGetUserSavedCartDataQuery
>
export type GetUserSavedCartDataLazyQueryHookResult = ReturnType<
  typeof useGetUserSavedCartDataLazyQuery
>
export type GetUserSavedCartDataQueryResult = Apollo.QueryResult<
  GetUserSavedCartDataQuery,
  GetUserSavedCartDataQueryVariables
>
export const FindAllShopItemsToBeScrapedDocument = gql`
  query FindAllShopItemsToBeScraped(
    $shop_types: [name!]
    $limitToBeScraped: Int!
    $limitShopItem: Int!
    $offset: Int!
  ) {
    shop_item_to_be_scraped(
      where: {
        shop: { type: { _in: $shop_types } }
        to_be_scraped: { _eq: false }
      }
      limit: $limitToBeScraped
      offset: $offset
    ) {
      id
      shop {
        type
        key
        metadata
      }
      url
      pricing
      item_id
    }
    shop_item(
      where: { to_be_scraped: { _eq: true } }
      limit: $limitShopItem
      offset: $offset
    ) {
      id
      shop {
        type
        key
        metadata
      }
      url
      pricing
      item_id
    }
  }
`

/**
 * __useFindAllShopItemsToBeScrapedQuery__
 *
 * To run a query within a React component, call `useFindAllShopItemsToBeScrapedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllShopItemsToBeScrapedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllShopItemsToBeScrapedQuery({
 *   variables: {
 *      shop_types: // value for 'shop_types'
 *      limitToBeScraped: // value for 'limitToBeScraped'
 *      limitShopItem: // value for 'limitShopItem'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFindAllShopItemsToBeScrapedQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAllShopItemsToBeScrapedQuery,
    FindAllShopItemsToBeScrapedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindAllShopItemsToBeScrapedQuery,
    FindAllShopItemsToBeScrapedQueryVariables
  >(FindAllShopItemsToBeScrapedDocument, options)
}
export function useFindAllShopItemsToBeScrapedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllShopItemsToBeScrapedQuery,
    FindAllShopItemsToBeScrapedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindAllShopItemsToBeScrapedQuery,
    FindAllShopItemsToBeScrapedQueryVariables
  >(FindAllShopItemsToBeScrapedDocument, options)
}
export type FindAllShopItemsToBeScrapedQueryHookResult = ReturnType<
  typeof useFindAllShopItemsToBeScrapedQuery
>
export type FindAllShopItemsToBeScrapedLazyQueryHookResult = ReturnType<
  typeof useFindAllShopItemsToBeScrapedLazyQuery
>
export type FindAllShopItemsToBeScrapedQueryResult = Apollo.QueryResult<
  FindAllShopItemsToBeScrapedQuery,
  FindAllShopItemsToBeScrapedQueryVariables
>
export const FindAllShopsByKeyDocument = gql`
  query FindAllShopsByKey($shopKeys: [String!]!) {
    shop(where: { key: { _in: $shopKeys } }) {
      key
    }
  }
`

/**
 * __useFindAllShopsByKeyQuery__
 *
 * To run a query within a React component, call `useFindAllShopsByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllShopsByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllShopsByKeyQuery({
 *   variables: {
 *      shopKeys: // value for 'shopKeys'
 *   },
 * });
 */
export function useFindAllShopsByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAllShopsByKeyQuery,
    FindAllShopsByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindAllShopsByKeyQuery,
    FindAllShopsByKeyQueryVariables
  >(FindAllShopsByKeyDocument, options)
}
export function useFindAllShopsByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllShopsByKeyQuery,
    FindAllShopsByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindAllShopsByKeyQuery,
    FindAllShopsByKeyQueryVariables
  >(FindAllShopsByKeyDocument, options)
}
export type FindAllShopsByKeyQueryHookResult = ReturnType<
  typeof useFindAllShopsByKeyQuery
>
export type FindAllShopsByKeyLazyQueryHookResult = ReturnType<
  typeof useFindAllShopsByKeyLazyQuery
>
export type FindAllShopsByKeyQueryResult = Apollo.QueryResult<
  FindAllShopsByKeyQuery,
  FindAllShopsByKeyQueryVariables
>
export const FindAllShopsByMetadataKeyDocument = gql`
  query FindAllShopsByMetadataKey($metadataKey: String!) {
    shop(where: { metadata: { _has_key: $metadataKey } }) {
      id
      key
      metadata_key: metadata(path: $metadataKey)
    }
  }
`

/**
 * __useFindAllShopsByMetadataKeyQuery__
 *
 * To run a query within a React component, call `useFindAllShopsByMetadataKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllShopsByMetadataKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllShopsByMetadataKeyQuery({
 *   variables: {
 *      metadataKey: // value for 'metadataKey'
 *   },
 * });
 */
export function useFindAllShopsByMetadataKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAllShopsByMetadataKeyQuery,
    FindAllShopsByMetadataKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindAllShopsByMetadataKeyQuery,
    FindAllShopsByMetadataKeyQueryVariables
  >(FindAllShopsByMetadataKeyDocument, options)
}
export function useFindAllShopsByMetadataKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllShopsByMetadataKeyQuery,
    FindAllShopsByMetadataKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindAllShopsByMetadataKeyQuery,
    FindAllShopsByMetadataKeyQueryVariables
  >(FindAllShopsByMetadataKeyDocument, options)
}
export type FindAllShopsByMetadataKeyQueryHookResult = ReturnType<
  typeof useFindAllShopsByMetadataKeyQuery
>
export type FindAllShopsByMetadataKeyLazyQueryHookResult = ReturnType<
  typeof useFindAllShopsByMetadataKeyLazyQuery
>
export type FindAllShopsByMetadataKeyQueryResult = Apollo.QueryResult<
  FindAllShopsByMetadataKeyQuery,
  FindAllShopsByMetadataKeyQueryVariables
>
export const FindAllShopsToRemoveAffiliateDocument = gql`
  query FindAllShopsToRemoveAffiliate(
    $shopsStillAssociated: [String!]!
    $metadataKey: String!
  ) {
    shop(
      where: {
        key: { _nin: $shopsStillAssociated }
        metadata: { _has_key: $metadataKey }
      }
    ) {
      key
    }
  }
`

/**
 * __useFindAllShopsToRemoveAffiliateQuery__
 *
 * To run a query within a React component, call `useFindAllShopsToRemoveAffiliateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllShopsToRemoveAffiliateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllShopsToRemoveAffiliateQuery({
 *   variables: {
 *      shopsStillAssociated: // value for 'shopsStillAssociated'
 *      metadataKey: // value for 'metadataKey'
 *   },
 * });
 */
export function useFindAllShopsToRemoveAffiliateQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAllShopsToRemoveAffiliateQuery,
    FindAllShopsToRemoveAffiliateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindAllShopsToRemoveAffiliateQuery,
    FindAllShopsToRemoveAffiliateQueryVariables
  >(FindAllShopsToRemoveAffiliateDocument, options)
}
export function useFindAllShopsToRemoveAffiliateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllShopsToRemoveAffiliateQuery,
    FindAllShopsToRemoveAffiliateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindAllShopsToRemoveAffiliateQuery,
    FindAllShopsToRemoveAffiliateQueryVariables
  >(FindAllShopsToRemoveAffiliateDocument, options)
}
export type FindAllShopsToRemoveAffiliateQueryHookResult = ReturnType<
  typeof useFindAllShopsToRemoveAffiliateQuery
>
export type FindAllShopsToRemoveAffiliateLazyQueryHookResult = ReturnType<
  typeof useFindAllShopsToRemoveAffiliateLazyQuery
>
export type FindAllShopsToRemoveAffiliateQueryResult = Apollo.QueryResult<
  FindAllShopsToRemoveAffiliateQuery,
  FindAllShopsToRemoveAffiliateQueryVariables
>
export const FindAllSubBrandsToRemoveAffiliateDocument = gql`
  query FindAllSubBrandsToRemoveAffiliate(
    $shopsStillAssociated: [String!]!
    $metadata: jsonb!
  ) {
    shop(
      where: {
        key: { _nin: $shopsStillAssociated }
        metadata: { _contains: $metadata }
      }
    ) {
      key
    }
  }
`

/**
 * __useFindAllSubBrandsToRemoveAffiliateQuery__
 *
 * To run a query within a React component, call `useFindAllSubBrandsToRemoveAffiliateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllSubBrandsToRemoveAffiliateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllSubBrandsToRemoveAffiliateQuery({
 *   variables: {
 *      shopsStillAssociated: // value for 'shopsStillAssociated'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useFindAllSubBrandsToRemoveAffiliateQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAllSubBrandsToRemoveAffiliateQuery,
    FindAllSubBrandsToRemoveAffiliateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindAllSubBrandsToRemoveAffiliateQuery,
    FindAllSubBrandsToRemoveAffiliateQueryVariables
  >(FindAllSubBrandsToRemoveAffiliateDocument, options)
}
export function useFindAllSubBrandsToRemoveAffiliateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllSubBrandsToRemoveAffiliateQuery,
    FindAllSubBrandsToRemoveAffiliateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindAllSubBrandsToRemoveAffiliateQuery,
    FindAllSubBrandsToRemoveAffiliateQueryVariables
  >(FindAllSubBrandsToRemoveAffiliateDocument, options)
}
export type FindAllSubBrandsToRemoveAffiliateQueryHookResult = ReturnType<
  typeof useFindAllSubBrandsToRemoveAffiliateQuery
>
export type FindAllSubBrandsToRemoveAffiliateLazyQueryHookResult = ReturnType<
  typeof useFindAllSubBrandsToRemoveAffiliateLazyQuery
>
export type FindAllSubBrandsToRemoveAffiliateQueryResult = Apollo.QueryResult<
  FindAllSubBrandsToRemoveAffiliateQuery,
  FindAllSubBrandsToRemoveAffiliateQueryVariables
>
export const FindAllTopLevelCjShopsDocument = gql`
  query FindAllTopLevelCjShops(
    $cjAffiliateIdKey: String!
    $cjParentShopKey: String!
  ) {
    shop(
      where: {
        _and: [
          { metadata: { _has_key: $cjAffiliateIdKey } }
          { _not: { metadata: { _has_key: $cjParentShopKey } } }
          { _not: { metadata: { _contains: { cj_ignore: true } } } }
        ]
      }
    ) {
      key
      metadata
    }
  }
`

/**
 * __useFindAllTopLevelCjShopsQuery__
 *
 * To run a query within a React component, call `useFindAllTopLevelCjShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllTopLevelCjShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllTopLevelCjShopsQuery({
 *   variables: {
 *      cjAffiliateIdKey: // value for 'cjAffiliateIdKey'
 *      cjParentShopKey: // value for 'cjParentShopKey'
 *   },
 * });
 */
export function useFindAllTopLevelCjShopsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAllTopLevelCjShopsQuery,
    FindAllTopLevelCjShopsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindAllTopLevelCjShopsQuery,
    FindAllTopLevelCjShopsQueryVariables
  >(FindAllTopLevelCjShopsDocument, options)
}
export function useFindAllTopLevelCjShopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllTopLevelCjShopsQuery,
    FindAllTopLevelCjShopsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindAllTopLevelCjShopsQuery,
    FindAllTopLevelCjShopsQueryVariables
  >(FindAllTopLevelCjShopsDocument, options)
}
export type FindAllTopLevelCjShopsQueryHookResult = ReturnType<
  typeof useFindAllTopLevelCjShopsQuery
>
export type FindAllTopLevelCjShopsLazyQueryHookResult = ReturnType<
  typeof useFindAllTopLevelCjShopsLazyQuery
>
export type FindAllTopLevelCjShopsQueryResult = Apollo.QueryResult<
  FindAllTopLevelCjShopsQuery,
  FindAllTopLevelCjShopsQueryVariables
>
export const FindCartItemDocument = gql`
  query FindCartItem($id: uuid!) {
    cartItem: cart_item_by_pk(id: $id) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`

/**
 * __useFindCartItemQuery__
 *
 * To run a query within a React component, call `useFindCartItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCartItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCartItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindCartItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCartItemQuery,
    FindCartItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindCartItemQuery, FindCartItemQueryVariables>(
    FindCartItemDocument,
    options
  )
}
export function useFindCartItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCartItemQuery,
    FindCartItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindCartItemQuery, FindCartItemQueryVariables>(
    FindCartItemDocument,
    options
  )
}
export type FindCartItemQueryHookResult = ReturnType<
  typeof useFindCartItemQuery
>
export type FindCartItemLazyQueryHookResult = ReturnType<
  typeof useFindCartItemLazyQuery
>
export type FindCartItemQueryResult = Apollo.QueryResult<
  FindCartItemQuery,
  FindCartItemQueryVariables
>
export const FindCartMobileDocument = gql`
  query FindCartMobile($id: uuid!, $limit: Int = 25, $offset: Int = 0) {
    cart: cart_by_pk(id: $id) {
      ...cartData
      cart_items(
        limit: $limit
        offset: $offset
        where: {
          deleted_at: { _is_null: true }
          archived_at: { _is_null: true }
        }
        order_by: { created_at: desc_nulls_last }
      ) {
        ...cartItemData
      }
    }
  }
  ${CartDataFragmentDoc}
  ${CartItemDataFragmentDoc}
`

/**
 * __useFindCartMobileQuery__
 *
 * To run a query within a React component, call `useFindCartMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCartMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCartMobileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFindCartMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCartMobileQuery,
    FindCartMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindCartMobileQuery, FindCartMobileQueryVariables>(
    FindCartMobileDocument,
    options
  )
}
export function useFindCartMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCartMobileQuery,
    FindCartMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindCartMobileQuery, FindCartMobileQueryVariables>(
    FindCartMobileDocument,
    options
  )
}
export type FindCartMobileQueryHookResult = ReturnType<
  typeof useFindCartMobileQuery
>
export type FindCartMobileLazyQueryHookResult = ReturnType<
  typeof useFindCartMobileLazyQuery
>
export type FindCartMobileQueryResult = Apollo.QueryResult<
  FindCartMobileQuery,
  FindCartMobileQueryVariables
>
export const FindCollaboratorsDocument = gql`
  query FindCollaborators($query: String!) {
    users: search_users(args: { search: $query }) {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useFindCollaboratorsQuery__
 *
 * To run a query within a React component, call `useFindCollaboratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCollaboratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCollaboratorsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useFindCollaboratorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCollaboratorsQuery,
    FindCollaboratorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCollaboratorsQuery,
    FindCollaboratorsQueryVariables
  >(FindCollaboratorsDocument, options)
}
export function useFindCollaboratorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCollaboratorsQuery,
    FindCollaboratorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCollaboratorsQuery,
    FindCollaboratorsQueryVariables
  >(FindCollaboratorsDocument, options)
}
export type FindCollaboratorsQueryHookResult = ReturnType<
  typeof useFindCollaboratorsQuery
>
export type FindCollaboratorsLazyQueryHookResult = ReturnType<
  typeof useFindCollaboratorsLazyQuery
>
export type FindCollaboratorsQueryResult = Apollo.QueryResult<
  FindCollaboratorsQuery,
  FindCollaboratorsQueryVariables
>
export const FindCollectionDocument = gql`
  query FindCollection(
    $collection_id: uuid!
    $itemLimit: Int = 100
    $include_seo_fields: Boolean = false
  ) {
    collection: collection_by_pk(id: $collection_id) {
      ...collectionAndItemsData
    }
  }
  ${CollectionAndItemsDataFragmentDoc}
`

/**
 * __useFindCollectionQuery__
 *
 * To run a query within a React component, call `useFindCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCollectionQuery({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *      itemLimit: // value for 'itemLimit'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useFindCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCollectionQuery,
    FindCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindCollectionQuery, FindCollectionQueryVariables>(
    FindCollectionDocument,
    options
  )
}
export function useFindCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCollectionQuery,
    FindCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindCollectionQuery, FindCollectionQueryVariables>(
    FindCollectionDocument,
    options
  )
}
export type FindCollectionQueryHookResult = ReturnType<
  typeof useFindCollectionQuery
>
export type FindCollectionLazyQueryHookResult = ReturnType<
  typeof useFindCollectionLazyQuery
>
export type FindCollectionQueryResult = Apollo.QueryResult<
  FindCollectionQuery,
  FindCollectionQueryVariables
>
export const FindCollectionPendingInviteDocument = gql`
  query FindCollectionPendingInvite(
    $inviteId: uuid
    $collectionId: uuid
    $userId: String
  ) {
    collection_invite(
      where: {
        id: { _eq: $inviteId }
        invitee_id: { _eq: $userId }
        collection_id: { _eq: $collectionId }
        status: { _eq: pending }
      }
    ) {
      id
    }
  }
`

/**
 * __useFindCollectionPendingInviteQuery__
 *
 * To run a query within a React component, call `useFindCollectionPendingInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCollectionPendingInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCollectionPendingInviteQuery({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *      collectionId: // value for 'collectionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFindCollectionPendingInviteQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindCollectionPendingInviteQuery,
    FindCollectionPendingInviteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCollectionPendingInviteQuery,
    FindCollectionPendingInviteQueryVariables
  >(FindCollectionPendingInviteDocument, options)
}
export function useFindCollectionPendingInviteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCollectionPendingInviteQuery,
    FindCollectionPendingInviteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCollectionPendingInviteQuery,
    FindCollectionPendingInviteQueryVariables
  >(FindCollectionPendingInviteDocument, options)
}
export type FindCollectionPendingInviteQueryHookResult = ReturnType<
  typeof useFindCollectionPendingInviteQuery
>
export type FindCollectionPendingInviteLazyQueryHookResult = ReturnType<
  typeof useFindCollectionPendingInviteLazyQuery
>
export type FindCollectionPendingInviteQueryResult = Apollo.QueryResult<
  FindCollectionPendingInviteQuery,
  FindCollectionPendingInviteQueryVariables
>
export const FindContactUserAndInviteStatusDocument = gql`
  query FindContactUserAndInviteStatus {
    invites: contact_invite {
      ...contactInviteData
    }
  }
  ${ContactInviteDataFragmentDoc}
`

/**
 * __useFindContactUserAndInviteStatusQuery__
 *
 * To run a query within a React component, call `useFindContactUserAndInviteStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindContactUserAndInviteStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindContactUserAndInviteStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindContactUserAndInviteStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindContactUserAndInviteStatusQuery,
    FindContactUserAndInviteStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindContactUserAndInviteStatusQuery,
    FindContactUserAndInviteStatusQueryVariables
  >(FindContactUserAndInviteStatusDocument, options)
}
export function useFindContactUserAndInviteStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindContactUserAndInviteStatusQuery,
    FindContactUserAndInviteStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindContactUserAndInviteStatusQuery,
    FindContactUserAndInviteStatusQueryVariables
  >(FindContactUserAndInviteStatusDocument, options)
}
export type FindContactUserAndInviteStatusQueryHookResult = ReturnType<
  typeof useFindContactUserAndInviteStatusQuery
>
export type FindContactUserAndInviteStatusLazyQueryHookResult = ReturnType<
  typeof useFindContactUserAndInviteStatusLazyQuery
>
export type FindContactUserAndInviteStatusQueryResult = Apollo.QueryResult<
  FindContactUserAndInviteStatusQuery,
  FindContactUserAndInviteStatusQueryVariables
>
export const FindShopByKeyDocument = gql`
  query FindShopByKey($key: String!) {
    shop(where: { key: { _eq: $key } }) {
      ...shopData
    }
  }
  ${ShopDataFragmentDoc}
`

/**
 * __useFindShopByKeyQuery__
 *
 * To run a query within a React component, call `useFindShopByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShopByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShopByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useFindShopByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindShopByKeyQuery,
    FindShopByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindShopByKeyQuery, FindShopByKeyQueryVariables>(
    FindShopByKeyDocument,
    options
  )
}
export function useFindShopByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindShopByKeyQuery,
    FindShopByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindShopByKeyQuery, FindShopByKeyQueryVariables>(
    FindShopByKeyDocument,
    options
  )
}
export type FindShopByKeyQueryHookResult = ReturnType<
  typeof useFindShopByKeyQuery
>
export type FindShopByKeyLazyQueryHookResult = ReturnType<
  typeof useFindShopByKeyLazyQuery
>
export type FindShopByKeyQueryResult = Apollo.QueryResult<
  FindShopByKeyQuery,
  FindShopByKeyQueryVariables
>
export const FindShopBySlugDocument = gql`
  query FindShopBySlug($slug: name!, $include_categories: Boolean = false) {
    shop(
      where: { slug: { _eq: $slug } }
      order_by: { cart_items_count: desc_nulls_last }
      limit: 1
    ) {
      ...shopData
      description
      keywords
      shop_faqs
      banner_image
      shop_categorizations @include(if: $include_categories) {
        category
        shop_category {
          label
          value
        }
      }
    }
  }
  ${ShopDataFragmentDoc}
`

/**
 * __useFindShopBySlugQuery__
 *
 * To run a query within a React component, call `useFindShopBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShopBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShopBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      include_categories: // value for 'include_categories'
 *   },
 * });
 */
export function useFindShopBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindShopBySlugQuery,
    FindShopBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindShopBySlugQuery, FindShopBySlugQueryVariables>(
    FindShopBySlugDocument,
    options
  )
}
export function useFindShopBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindShopBySlugQuery,
    FindShopBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindShopBySlugQuery, FindShopBySlugQueryVariables>(
    FindShopBySlugDocument,
    options
  )
}
export type FindShopBySlugQueryHookResult = ReturnType<
  typeof useFindShopBySlugQuery
>
export type FindShopBySlugLazyQueryHookResult = ReturnType<
  typeof useFindShopBySlugLazyQuery
>
export type FindShopBySlugQueryResult = Apollo.QueryResult<
  FindShopBySlugQuery,
  FindShopBySlugQueryVariables
>
export const FindShopPointsByKeyDocument = gql`
  query FindShopPointsByKey(
    $key: String!
    $includeAffiliate: Boolean! = false
  ) {
    shop(where: { key: { _eq: $key } }) {
      points
      parsed_take_rate
      affiliate @include(if: $includeAffiliate)
    }
  }
`

/**
 * __useFindShopPointsByKeyQuery__
 *
 * To run a query within a React component, call `useFindShopPointsByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShopPointsByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShopPointsByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *      includeAffiliate: // value for 'includeAffiliate'
 *   },
 * });
 */
export function useFindShopPointsByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindShopPointsByKeyQuery,
    FindShopPointsByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindShopPointsByKeyQuery,
    FindShopPointsByKeyQueryVariables
  >(FindShopPointsByKeyDocument, options)
}
export function useFindShopPointsByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindShopPointsByKeyQuery,
    FindShopPointsByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindShopPointsByKeyQuery,
    FindShopPointsByKeyQueryVariables
  >(FindShopPointsByKeyDocument, options)
}
export type FindShopPointsByKeyQueryHookResult = ReturnType<
  typeof useFindShopPointsByKeyQuery
>
export type FindShopPointsByKeyLazyQueryHookResult = ReturnType<
  typeof useFindShopPointsByKeyLazyQuery
>
export type FindShopPointsByKeyQueryResult = Apollo.QueryResult<
  FindShopPointsByKeyQuery,
  FindShopPointsByKeyQueryVariables
>
export const FindUserDocument = gql`
  query FindUser(
    $id: String!
    $with_extension_version: Boolean = false
    $with_ios_version: Boolean = false
    $with_safari_extension_version: Boolean = false
  ) {
    user: user_by_pk(id: $id) {
      ...userData
    }
  }
  ${UserDataFragmentDoc}
`

/**
 * __useFindUserQuery__
 *
 * To run a query within a React component, call `useFindUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      with_extension_version: // value for 'with_extension_version'
 *      with_ios_version: // value for 'with_ios_version'
 *      with_safari_extension_version: // value for 'with_safari_extension_version'
 *   },
 * });
 */
export function useFindUserQuery(
  baseOptions: Apollo.QueryHookOptions<FindUserQuery, FindUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindUserQuery, FindUserQueryVariables>(
    FindUserDocument,
    options
  )
}
export function useFindUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserQuery,
    FindUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindUserQuery, FindUserQueryVariables>(
    FindUserDocument,
    options
  )
}
export type FindUserQueryHookResult = ReturnType<typeof useFindUserQuery>
export type FindUserLazyQueryHookResult = ReturnType<
  typeof useFindUserLazyQuery
>
export type FindUserQueryResult = Apollo.QueryResult<
  FindUserQuery,
  FindUserQueryVariables
>
export const FindUserByPhoneDocument = gql`
  query FindUserByPhone(
    $phone: String!
    $with_extension_version: Boolean = false
    $with_ios_version: Boolean = false
    $with_safari_extension_version: Boolean = false
  ) {
    user(where: { phone: { _eq: $phone } }) {
      ...userData
    }
  }
  ${UserDataFragmentDoc}
`

/**
 * __useFindUserByPhoneQuery__
 *
 * To run a query within a React component, call `useFindUserByPhoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserByPhoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserByPhoneQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *      with_extension_version: // value for 'with_extension_version'
 *      with_ios_version: // value for 'with_ios_version'
 *      with_safari_extension_version: // value for 'with_safari_extension_version'
 *   },
 * });
 */
export function useFindUserByPhoneQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserByPhoneQuery,
    FindUserByPhoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindUserByPhoneQuery, FindUserByPhoneQueryVariables>(
    FindUserByPhoneDocument,
    options
  )
}
export function useFindUserByPhoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserByPhoneQuery,
    FindUserByPhoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindUserByPhoneQuery,
    FindUserByPhoneQueryVariables
  >(FindUserByPhoneDocument, options)
}
export type FindUserByPhoneQueryHookResult = ReturnType<
  typeof useFindUserByPhoneQuery
>
export type FindUserByPhoneLazyQueryHookResult = ReturnType<
  typeof useFindUserByPhoneLazyQuery
>
export type FindUserByPhoneQueryResult = Apollo.QueryResult<
  FindUserByPhoneQuery,
  FindUserByPhoneQueryVariables
>
export const FindUserCartbyShopKeyDocument = gql`
  query FindUserCartbyShopKey($userId: String!, $shopKey: String!) {
    carts: cart(
      where: { user: { id: { _eq: $userId } }, shop_key: { _eq: $shopKey } }
      order_by: { last_cart_item_created_at: desc_nulls_last }
    ) {
      ...cartData
      ...cartItemsData
    }
  }
  ${CartDataFragmentDoc}
  ${CartItemsDataFragmentDoc}
`

/**
 * __useFindUserCartbyShopKeyQuery__
 *
 * To run a query within a React component, call `useFindUserCartbyShopKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserCartbyShopKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserCartbyShopKeyQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      shopKey: // value for 'shopKey'
 *   },
 * });
 */
export function useFindUserCartbyShopKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserCartbyShopKeyQuery,
    FindUserCartbyShopKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindUserCartbyShopKeyQuery,
    FindUserCartbyShopKeyQueryVariables
  >(FindUserCartbyShopKeyDocument, options)
}
export function useFindUserCartbyShopKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserCartbyShopKeyQuery,
    FindUserCartbyShopKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindUserCartbyShopKeyQuery,
    FindUserCartbyShopKeyQueryVariables
  >(FindUserCartbyShopKeyDocument, options)
}
export type FindUserCartbyShopKeyQueryHookResult = ReturnType<
  typeof useFindUserCartbyShopKeyQuery
>
export type FindUserCartbyShopKeyLazyQueryHookResult = ReturnType<
  typeof useFindUserCartbyShopKeyLazyQuery
>
export type FindUserCartbyShopKeyQueryResult = Apollo.QueryResult<
  FindUserCartbyShopKeyQuery,
  FindUserCartbyShopKeyQueryVariables
>
export const FindUserCollectionsDocument = gql`
  query FindUserCollections(
    $user_id: String!
    $slug: name!
    $itemLimit: Int = 100
    $include_seo_fields: Boolean = false
  ) {
    collections: collection(
      where: { user_id: { _eq: $user_id }, slug: { _eq: $slug } }
      order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
    ) {
      ...collectionAndItemsData
    }
  }
  ${CollectionAndItemsDataFragmentDoc}
`

/**
 * __useFindUserCollectionsQuery__
 *
 * To run a query within a React component, call `useFindUserCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserCollectionsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      slug: // value for 'slug'
 *      itemLimit: // value for 'itemLimit'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useFindUserCollectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserCollectionsQuery,
    FindUserCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindUserCollectionsQuery,
    FindUserCollectionsQueryVariables
  >(FindUserCollectionsDocument, options)
}
export function useFindUserCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserCollectionsQuery,
    FindUserCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindUserCollectionsQuery,
    FindUserCollectionsQueryVariables
  >(FindUserCollectionsDocument, options)
}
export type FindUserCollectionsQueryHookResult = ReturnType<
  typeof useFindUserCollectionsQuery
>
export type FindUserCollectionsLazyQueryHookResult = ReturnType<
  typeof useFindUserCollectionsLazyQuery
>
export type FindUserCollectionsQueryResult = Apollo.QueryResult<
  FindUserCollectionsQuery,
  FindUserCollectionsQueryVariables
>
export const FindUserForAuthCheckDocument = gql`
  query FindUserForAuthCheck($id: String!) {
    user: user_by_pk(id: $id) {
      id
      anonymous
      orphaned_at
      orphaned_by
    }
  }
`

/**
 * __useFindUserForAuthCheckQuery__
 *
 * To run a query within a React component, call `useFindUserForAuthCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserForAuthCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserForAuthCheckQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindUserForAuthCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserForAuthCheckQuery,
    FindUserForAuthCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindUserForAuthCheckQuery,
    FindUserForAuthCheckQueryVariables
  >(FindUserForAuthCheckDocument, options)
}
export function useFindUserForAuthCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserForAuthCheckQuery,
    FindUserForAuthCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindUserForAuthCheckQuery,
    FindUserForAuthCheckQueryVariables
  >(FindUserForAuthCheckDocument, options)
}
export type FindUserForAuthCheckQueryHookResult = ReturnType<
  typeof useFindUserForAuthCheckQuery
>
export type FindUserForAuthCheckLazyQueryHookResult = ReturnType<
  typeof useFindUserForAuthCheckLazyQuery
>
export type FindUserForAuthCheckQueryResult = Apollo.QueryResult<
  FindUserForAuthCheckQuery,
  FindUserForAuthCheckQueryVariables
>
export const FindUserForMobileDocument = gql`
  query FindUserForMobile($id: String!) {
    user: user_by_pk(id: $id) {
      id
      phone
      username
      user_type
      is_creator_computed
      is_curator_computed
    }
  }
`

/**
 * __useFindUserForMobileQuery__
 *
 * To run a query within a React component, call `useFindUserForMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserForMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserForMobileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindUserForMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserForMobileQuery,
    FindUserForMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindUserForMobileQuery,
    FindUserForMobileQueryVariables
  >(FindUserForMobileDocument, options)
}
export function useFindUserForMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserForMobileQuery,
    FindUserForMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindUserForMobileQuery,
    FindUserForMobileQueryVariables
  >(FindUserForMobileDocument, options)
}
export type FindUserForMobileQueryHookResult = ReturnType<
  typeof useFindUserForMobileQuery
>
export type FindUserForMobileLazyQueryHookResult = ReturnType<
  typeof useFindUserForMobileLazyQuery
>
export type FindUserForMobileQueryResult = Apollo.QueryResult<
  FindUserForMobileQuery,
  FindUserForMobileQueryVariables
>
export const FindUserMobileDocument = gql`
  query FindUserMobile($id: String!) {
    user: user_by_pk(id: $id) {
      id
      name
      picture
    }
  }
`

/**
 * __useFindUserMobileQuery__
 *
 * To run a query within a React component, call `useFindUserMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserMobileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindUserMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserMobileQuery,
    FindUserMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindUserMobileQuery, FindUserMobileQueryVariables>(
    FindUserMobileDocument,
    options
  )
}
export function useFindUserMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserMobileQuery,
    FindUserMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindUserMobileQuery, FindUserMobileQueryVariables>(
    FindUserMobileDocument,
    options
  )
}
export type FindUserMobileQueryHookResult = ReturnType<
  typeof useFindUserMobileQuery
>
export type FindUserMobileLazyQueryHookResult = ReturnType<
  typeof useFindUserMobileLazyQuery
>
export type FindUserMobileQueryResult = Apollo.QueryResult<
  FindUserMobileQuery,
  FindUserMobileQueryVariables
>
export const FinishOnboardingDocument = gql`
  mutation FinishOnboarding($id: String!) {
    user: update_user_by_pk(
      _set: { onboarded: true }
      pk_columns: { id: $id }
    ) {
      id
      onboarded
    }
  }
`
export type FinishOnboardingMutationFn = Apollo.MutationFunction<
  FinishOnboardingMutation,
  FinishOnboardingMutationVariables
>

/**
 * __useFinishOnboardingMutation__
 *
 * To run a mutation, you first call `useFinishOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishOnboardingMutation, { data, loading, error }] = useFinishOnboardingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinishOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinishOnboardingMutation,
    FinishOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FinishOnboardingMutation,
    FinishOnboardingMutationVariables
  >(FinishOnboardingDocument, options)
}
export type FinishOnboardingMutationHookResult = ReturnType<
  typeof useFinishOnboardingMutation
>
export type FinishOnboardingMutationResult =
  Apollo.MutationResult<FinishOnboardingMutation>
export type FinishOnboardingMutationOptions = Apollo.BaseMutationOptions<
  FinishOnboardingMutation,
  FinishOnboardingMutationVariables
>
export const FollowCollectionDocument = gql`
  mutation FollowCollection($collectionId: uuid!) {
    insert_user_items_followed_one(
      object: {
        followable_item_id: $collectionId
        followable_item_type: "collection"
      }
      on_conflict: {
        constraint: user_follow_pkey
        update_columns: [updated_at]
      }
    ) {
      followable_item {
        collection {
          id
          followed_by_user
          is_a_collaborator
        }
      }
    }
  }
`
export type FollowCollectionMutationFn = Apollo.MutationFunction<
  FollowCollectionMutation,
  FollowCollectionMutationVariables
>

/**
 * __useFollowCollectionMutation__
 *
 * To run a mutation, you first call `useFollowCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followCollectionMutation, { data, loading, error }] = useFollowCollectionMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useFollowCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FollowCollectionMutation,
    FollowCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FollowCollectionMutation,
    FollowCollectionMutationVariables
  >(FollowCollectionDocument, options)
}
export type FollowCollectionMutationHookResult = ReturnType<
  typeof useFollowCollectionMutation
>
export type FollowCollectionMutationResult =
  Apollo.MutationResult<FollowCollectionMutation>
export type FollowCollectionMutationOptions = Apollo.BaseMutationOptions<
  FollowCollectionMutation,
  FollowCollectionMutationVariables
>
export const UnfollowCollectionDocument = gql`
  mutation UnfollowCollection($userId: String!, $collectionId: uuid!) {
    delete_user_items_followed(
      where: {
        user_id: { _eq: $userId }
        followable_item_type: { _eq: "collection" }
        followable_item_id: { _eq: $collectionId }
      }
    ) {
      affected_rows
    }
  }
`
export type UnfollowCollectionMutationFn = Apollo.MutationFunction<
  UnfollowCollectionMutation,
  UnfollowCollectionMutationVariables
>

/**
 * __useUnfollowCollectionMutation__
 *
 * To run a mutation, you first call `useUnfollowCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowCollectionMutation, { data, loading, error }] = useUnfollowCollectionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useUnfollowCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnfollowCollectionMutation,
    UnfollowCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnfollowCollectionMutation,
    UnfollowCollectionMutationVariables
  >(UnfollowCollectionDocument, options)
}
export type UnfollowCollectionMutationHookResult = ReturnType<
  typeof useUnfollowCollectionMutation
>
export type UnfollowCollectionMutationResult =
  Apollo.MutationResult<UnfollowCollectionMutation>
export type UnfollowCollectionMutationOptions = Apollo.BaseMutationOptions<
  UnfollowCollectionMutation,
  UnfollowCollectionMutationVariables
>
export const GetAccountDeletionReasonsDocument = gql`
  query GetAccountDeletionReasons {
    reasons: account_deletion_reason {
      value
      description
    }
  }
`

/**
 * __useGetAccountDeletionReasonsQuery__
 *
 * To run a query within a React component, call `useGetAccountDeletionReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountDeletionReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountDeletionReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountDeletionReasonsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountDeletionReasonsQuery,
    GetAccountDeletionReasonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAccountDeletionReasonsQuery,
    GetAccountDeletionReasonsQueryVariables
  >(GetAccountDeletionReasonsDocument, options)
}
export function useGetAccountDeletionReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountDeletionReasonsQuery,
    GetAccountDeletionReasonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAccountDeletionReasonsQuery,
    GetAccountDeletionReasonsQueryVariables
  >(GetAccountDeletionReasonsDocument, options)
}
export type GetAccountDeletionReasonsQueryHookResult = ReturnType<
  typeof useGetAccountDeletionReasonsQuery
>
export type GetAccountDeletionReasonsLazyQueryHookResult = ReturnType<
  typeof useGetAccountDeletionReasonsLazyQuery
>
export type GetAccountDeletionReasonsQueryResult = Apollo.QueryResult<
  GetAccountDeletionReasonsQuery,
  GetAccountDeletionReasonsQueryVariables
>
export const GetAllUserCollectionsDocument = gql`
  query GetAllUserCollections(
    $userId: String!
    $offset: Int = 0
    $limit: Int = 10
    $itemLimit: Int = 1
    $include_seo_fields: Boolean = false
  ) {
    collection_aggregate(
      where: {
        all: { _eq: false }
        _or: [
          { user_id: { _eq: $userId } }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    collections: collection(
      where: {
        all: { _eq: false }
        _or: [
          { user_id: { _eq: $userId } }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
      order_by: [
        { has_collaborators: asc }
        { order: asc }
        { updated_at: desc_nulls_last }
      ]
      limit: $limit
      offset: $offset
    ) {
      ...collectionAndItemsData
    }
  }
  ${CollectionAndItemsDataFragmentDoc}
`

/**
 * __useGetAllUserCollectionsQuery__
 *
 * To run a query within a React component, call `useGetAllUserCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserCollectionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      itemLimit: // value for 'itemLimit'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useGetAllUserCollectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllUserCollectionsQuery,
    GetAllUserCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAllUserCollectionsQuery,
    GetAllUserCollectionsQueryVariables
  >(GetAllUserCollectionsDocument, options)
}
export function useGetAllUserCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllUserCollectionsQuery,
    GetAllUserCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAllUserCollectionsQuery,
    GetAllUserCollectionsQueryVariables
  >(GetAllUserCollectionsDocument, options)
}
export type GetAllUserCollectionsQueryHookResult = ReturnType<
  typeof useGetAllUserCollectionsQuery
>
export type GetAllUserCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetAllUserCollectionsLazyQuery
>
export type GetAllUserCollectionsQueryResult = Apollo.QueryResult<
  GetAllUserCollectionsQuery,
  GetAllUserCollectionsQueryVariables
>
export const GetAllYourStuffCollectionDocument = gql`
  query GetAllYourStuffCollection(
    $userId: String!
    $itemLimit: Int = 1
    $include_seo_fields: Boolean = false
  ) {
    collection(where: { user_id: { _eq: $userId }, all: { _eq: true } }) {
      ...collectionAndItemsData
    }
  }
  ${CollectionAndItemsDataFragmentDoc}
`

/**
 * __useGetAllYourStuffCollectionQuery__
 *
 * To run a query within a React component, call `useGetAllYourStuffCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllYourStuffCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllYourStuffCollectionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      itemLimit: // value for 'itemLimit'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useGetAllYourStuffCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllYourStuffCollectionQuery,
    GetAllYourStuffCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAllYourStuffCollectionQuery,
    GetAllYourStuffCollectionQueryVariables
  >(GetAllYourStuffCollectionDocument, options)
}
export function useGetAllYourStuffCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllYourStuffCollectionQuery,
    GetAllYourStuffCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAllYourStuffCollectionQuery,
    GetAllYourStuffCollectionQueryVariables
  >(GetAllYourStuffCollectionDocument, options)
}
export type GetAllYourStuffCollectionQueryHookResult = ReturnType<
  typeof useGetAllYourStuffCollectionQuery
>
export type GetAllYourStuffCollectionLazyQueryHookResult = ReturnType<
  typeof useGetAllYourStuffCollectionLazyQuery
>
export type GetAllYourStuffCollectionQueryResult = Apollo.QueryResult<
  GetAllYourStuffCollectionQuery,
  GetAllYourStuffCollectionQueryVariables
>
export const GetCarrotCuratedCollectionsDocument = gql`
  query GetCarrotCuratedCollections {
    collections: collection(
      where: {
        user_id: { _eq: "5nXPBMJqqUWn6LNehDjSlvwJrS92" }
        all: { _neq: true }
        private: { _neq: true }
      }
    ) {
      id
      name
      slug
      description
      cover_image
      collection_items_aggregate {
        aggregate {
          count
        }
      }
      collection_collaborators {
        id
        user {
          picture
        }
      }
    }
  }
`

/**
 * __useGetCarrotCuratedCollectionsQuery__
 *
 * To run a query within a React component, call `useGetCarrotCuratedCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarrotCuratedCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarrotCuratedCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCarrotCuratedCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCarrotCuratedCollectionsQuery,
    GetCarrotCuratedCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCarrotCuratedCollectionsQuery,
    GetCarrotCuratedCollectionsQueryVariables
  >(GetCarrotCuratedCollectionsDocument, options)
}
export function useGetCarrotCuratedCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCarrotCuratedCollectionsQuery,
    GetCarrotCuratedCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCarrotCuratedCollectionsQuery,
    GetCarrotCuratedCollectionsQueryVariables
  >(GetCarrotCuratedCollectionsDocument, options)
}
export type GetCarrotCuratedCollectionsQueryHookResult = ReturnType<
  typeof useGetCarrotCuratedCollectionsQuery
>
export type GetCarrotCuratedCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetCarrotCuratedCollectionsLazyQuery
>
export type GetCarrotCuratedCollectionsQueryResult = Apollo.QueryResult<
  GetCarrotCuratedCollectionsQuery,
  GetCarrotCuratedCollectionsQueryVariables
>
export const GetCartItemImagesDocument = gql`
  query GetCartItemImages($ids: [uuid!]!) {
    cart_items: cart_item(where: { id: { _in: $ids } }) {
      id
      item {
        id
        image
        image_highres
        cached_image
      }
    }
  }
`

/**
 * __useGetCartItemImagesQuery__
 *
 * To run a query within a React component, call `useGetCartItemImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemImagesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetCartItemImagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCartItemImagesQuery,
    GetCartItemImagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCartItemImagesQuery,
    GetCartItemImagesQueryVariables
  >(GetCartItemImagesDocument, options)
}
export function useGetCartItemImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCartItemImagesQuery,
    GetCartItemImagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCartItemImagesQuery,
    GetCartItemImagesQueryVariables
  >(GetCartItemImagesDocument, options)
}
export type GetCartItemImagesQueryHookResult = ReturnType<
  typeof useGetCartItemImagesQuery
>
export type GetCartItemImagesLazyQueryHookResult = ReturnType<
  typeof useGetCartItemImagesLazyQuery
>
export type GetCartItemImagesQueryResult = Apollo.QueryResult<
  GetCartItemImagesQuery,
  GetCartItemImagesQueryVariables
>
export const GetCartItemsFromIdsDocument = gql`
  query GetCartItemsFromIds($itemIds: [uuid!]!) {
    cart_item(where: { id: { _in: $itemIds } }) {
      id
      item {
        id
        display_title
        cached_image
        image
        image_highres
      }
    }
  }
`

/**
 * __useGetCartItemsFromIdsQuery__
 *
 * To run a query within a React component, call `useGetCartItemsFromIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemsFromIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemsFromIdsQuery({
 *   variables: {
 *      itemIds: // value for 'itemIds'
 *   },
 * });
 */
export function useGetCartItemsFromIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCartItemsFromIdsQuery,
    GetCartItemsFromIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCartItemsFromIdsQuery,
    GetCartItemsFromIdsQueryVariables
  >(GetCartItemsFromIdsDocument, options)
}
export function useGetCartItemsFromIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCartItemsFromIdsQuery,
    GetCartItemsFromIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCartItemsFromIdsQuery,
    GetCartItemsFromIdsQueryVariables
  >(GetCartItemsFromIdsDocument, options)
}
export type GetCartItemsFromIdsQueryHookResult = ReturnType<
  typeof useGetCartItemsFromIdsQuery
>
export type GetCartItemsFromIdsLazyQueryHookResult = ReturnType<
  typeof useGetCartItemsFromIdsLazyQuery
>
export type GetCartItemsFromIdsQueryResult = Apollo.QueryResult<
  GetCartItemsFromIdsQuery,
  GetCartItemsFromIdsQueryVariables
>
export const GetCartsDocument = gql`
  query GetCarts($limit: Int = 50) {
    carts: cart(limit: $limit) {
      id
      shop {
        id
        slug
      }
    }
  }
`

/**
 * __useGetCartsQuery__
 *
 * To run a query within a React component, call `useGetCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCartsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCartsQuery, GetCartsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCartsQuery, GetCartsQueryVariables>(
    GetCartsDocument,
    options
  )
}
export function useGetCartsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCartsQuery,
    GetCartsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCartsQuery, GetCartsQueryVariables>(
    GetCartsDocument,
    options
  )
}
export type GetCartsQueryHookResult = ReturnType<typeof useGetCartsQuery>
export type GetCartsLazyQueryHookResult = ReturnType<
  typeof useGetCartsLazyQuery
>
export type GetCartsQueryResult = Apollo.QueryResult<
  GetCartsQuery,
  GetCartsQueryVariables
>
export const GetCollabInviteAcceptedDataForPushNotificationDocument = gql`
  query GetCollabInviteAcceptedDataForPushNotification($id: uuid!) {
    collection_invite_by_pk(id: $id) {
      id
      collection {
        id
        name
        user {
          ...userForPushNotificationData
        }
      }
      invitee {
        username
      }
    }
  }
  ${UserForPushNotificationDataFragmentDoc}
`

/**
 * __useGetCollabInviteAcceptedDataForPushNotificationQuery__
 *
 * To run a query within a React component, call `useGetCollabInviteAcceptedDataForPushNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollabInviteAcceptedDataForPushNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollabInviteAcceptedDataForPushNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollabInviteAcceptedDataForPushNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollabInviteAcceptedDataForPushNotificationQuery,
    GetCollabInviteAcceptedDataForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollabInviteAcceptedDataForPushNotificationQuery,
    GetCollabInviteAcceptedDataForPushNotificationQueryVariables
  >(GetCollabInviteAcceptedDataForPushNotificationDocument, options)
}
export function useGetCollabInviteAcceptedDataForPushNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollabInviteAcceptedDataForPushNotificationQuery,
    GetCollabInviteAcceptedDataForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollabInviteAcceptedDataForPushNotificationQuery,
    GetCollabInviteAcceptedDataForPushNotificationQueryVariables
  >(GetCollabInviteAcceptedDataForPushNotificationDocument, options)
}
export type GetCollabInviteAcceptedDataForPushNotificationQueryHookResult =
  ReturnType<typeof useGetCollabInviteAcceptedDataForPushNotificationQuery>
export type GetCollabInviteAcceptedDataForPushNotificationLazyQueryHookResult =
  ReturnType<typeof useGetCollabInviteAcceptedDataForPushNotificationLazyQuery>
export type GetCollabInviteAcceptedDataForPushNotificationQueryResult =
  Apollo.QueryResult<
    GetCollabInviteAcceptedDataForPushNotificationQuery,
    GetCollabInviteAcceptedDataForPushNotificationQueryVariables
  >
export const GetCollabInviteDataForPushNotificationDocument = gql`
  query GetCollabInviteDataForPushNotification($id: uuid!) {
    collection_invite_by_pk(id: $id) {
      id
      collection {
        id
        name
        user {
          username
        }
      }
      invitee_id
      requester_id
      invitee {
        ...userForPushNotificationData
      }
    }
  }
  ${UserForPushNotificationDataFragmentDoc}
`

/**
 * __useGetCollabInviteDataForPushNotificationQuery__
 *
 * To run a query within a React component, call `useGetCollabInviteDataForPushNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollabInviteDataForPushNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollabInviteDataForPushNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollabInviteDataForPushNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollabInviteDataForPushNotificationQuery,
    GetCollabInviteDataForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollabInviteDataForPushNotificationQuery,
    GetCollabInviteDataForPushNotificationQueryVariables
  >(GetCollabInviteDataForPushNotificationDocument, options)
}
export function useGetCollabInviteDataForPushNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollabInviteDataForPushNotificationQuery,
    GetCollabInviteDataForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollabInviteDataForPushNotificationQuery,
    GetCollabInviteDataForPushNotificationQueryVariables
  >(GetCollabInviteDataForPushNotificationDocument, options)
}
export type GetCollabInviteDataForPushNotificationQueryHookResult = ReturnType<
  typeof useGetCollabInviteDataForPushNotificationQuery
>
export type GetCollabInviteDataForPushNotificationLazyQueryHookResult =
  ReturnType<typeof useGetCollabInviteDataForPushNotificationLazyQuery>
export type GetCollabInviteDataForPushNotificationQueryResult =
  Apollo.QueryResult<
    GetCollabInviteDataForPushNotificationQuery,
    GetCollabInviteDataForPushNotificationQueryVariables
  >
export const GetCollectionCollaboratorInvitesDocument = gql`
  query GetCollectionCollaboratorInvites(
    $collectionId: uuid!
    $is_admin: Boolean = false
  ) {
    invites: collection_invite(
      where: { collection_id: { _eq: $collectionId } }
      order_by: { status: desc, created_at: asc }
    ) {
      id
      status
      invitee_email
      invitee {
        ...basicUserData
      }
      collection_collaborator {
        id
        manager
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetCollectionCollaboratorInvitesQuery__
 *
 * To run a query within a React component, call `useGetCollectionCollaboratorInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionCollaboratorInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionCollaboratorInvitesQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      is_admin: // value for 'is_admin'
 *   },
 * });
 */
export function useGetCollectionCollaboratorInvitesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionCollaboratorInvitesQuery,
    GetCollectionCollaboratorInvitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionCollaboratorInvitesQuery,
    GetCollectionCollaboratorInvitesQueryVariables
  >(GetCollectionCollaboratorInvitesDocument, options)
}
export function useGetCollectionCollaboratorInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionCollaboratorInvitesQuery,
    GetCollectionCollaboratorInvitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionCollaboratorInvitesQuery,
    GetCollectionCollaboratorInvitesQueryVariables
  >(GetCollectionCollaboratorInvitesDocument, options)
}
export type GetCollectionCollaboratorInvitesQueryHookResult = ReturnType<
  typeof useGetCollectionCollaboratorInvitesQuery
>
export type GetCollectionCollaboratorInvitesLazyQueryHookResult = ReturnType<
  typeof useGetCollectionCollaboratorInvitesLazyQuery
>
export type GetCollectionCollaboratorInvitesQueryResult = Apollo.QueryResult<
  GetCollectionCollaboratorInvitesQuery,
  GetCollectionCollaboratorInvitesQueryVariables
>
export const GetCollectionForSocialImageDocument = gql`
  query GetCollectionForSocialImage($id: uuid!) {
    collection: collection_by_pk(id: $id) {
      all
      slug
      user_id
      ogimage_fingerprint_updated
      user {
        short_id
        username
      }
    }
  }
`

/**
 * __useGetCollectionForSocialImageQuery__
 *
 * To run a query within a React component, call `useGetCollectionForSocialImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionForSocialImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionForSocialImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionForSocialImageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionForSocialImageQuery,
    GetCollectionForSocialImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionForSocialImageQuery,
    GetCollectionForSocialImageQueryVariables
  >(GetCollectionForSocialImageDocument, options)
}
export function useGetCollectionForSocialImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionForSocialImageQuery,
    GetCollectionForSocialImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionForSocialImageQuery,
    GetCollectionForSocialImageQueryVariables
  >(GetCollectionForSocialImageDocument, options)
}
export type GetCollectionForSocialImageQueryHookResult = ReturnType<
  typeof useGetCollectionForSocialImageQuery
>
export type GetCollectionForSocialImageLazyQueryHookResult = ReturnType<
  typeof useGetCollectionForSocialImageLazyQuery
>
export type GetCollectionForSocialImageQueryResult = Apollo.QueryResult<
  GetCollectionForSocialImageQuery,
  GetCollectionForSocialImageQueryVariables
>
export const GetCollectionInviteDocument = gql`
  query GetCollectionInvite($id: uuid!) {
    collection_invite_by_pk(id: $id) {
      id
      invitee_id
      status
      for_new_user
      collection {
        id
        path
        user {
          id
        }
      }
    }
  }
`

/**
 * __useGetCollectionInviteQuery__
 *
 * To run a query within a React component, call `useGetCollectionInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionInviteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionInviteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionInviteQuery,
    GetCollectionInviteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionInviteQuery,
    GetCollectionInviteQueryVariables
  >(GetCollectionInviteDocument, options)
}
export function useGetCollectionInviteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionInviteQuery,
    GetCollectionInviteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionInviteQuery,
    GetCollectionInviteQueryVariables
  >(GetCollectionInviteDocument, options)
}
export type GetCollectionInviteQueryHookResult = ReturnType<
  typeof useGetCollectionInviteQuery
>
export type GetCollectionInviteLazyQueryHookResult = ReturnType<
  typeof useGetCollectionInviteLazyQuery
>
export type GetCollectionInviteQueryResult = Apollo.QueryResult<
  GetCollectionInviteQuery,
  GetCollectionInviteQueryVariables
>
export const GetCollectionsDocument = gql`
  query GetCollections($limit: Int = 50) {
    collections: collection(
      limit: $limit
      where: { all: { _eq: false }, computed_is_visible: { _eq: true } }
      order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
    ) {
      id
      name
      slug
      user {
        id
        name
      }
    }
  }
`

/**
 * __useGetCollectionsQuery__
 *
 * To run a query within a React component, call `useGetCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCollectionsQuery,
    GetCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCollectionsQuery, GetCollectionsQueryVariables>(
    GetCollectionsDocument,
    options
  )
}
export function useGetCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionsQuery,
    GetCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCollectionsQuery, GetCollectionsQueryVariables>(
    GetCollectionsDocument,
    options
  )
}
export type GetCollectionsQueryHookResult = ReturnType<
  typeof useGetCollectionsQuery
>
export type GetCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetCollectionsLazyQuery
>
export type GetCollectionsQueryResult = Apollo.QueryResult<
  GetCollectionsQuery,
  GetCollectionsQueryVariables
>
export const GetCollectionsForFirehoseDocument = gql`
  query GetCollectionsForFirehose(
    $email_exclude_list: [String!]!
    $created_at_offset: timestamptz = "now()"
    $limit: Int = 10
    $collectionItemsCount: Int = 4
  ) {
    collections: collection(
      where: {
        all: { _eq: false }
        cart_items_count: { _gt: 4 }
        computed_is_visible: { _eq: true }
        user: { email: { _nin: $email_exclude_list } }
        created_at: { _lt: $created_at_offset }
      }
      limit: $limit
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      slug
      name
      cart_items_count
      created_at
      collection_items: preview_collection_items(limit: $collectionItemsCount) {
        id
        cart_item {
          image
        }
      }
      feed_item {
        id
        in_discovery_backlog
        in_discovery_feed
        metadata
      }
      user {
        username
        picture
      }
    }
  }
`

/**
 * __useGetCollectionsForFirehoseQuery__
 *
 * To run a query within a React component, call `useGetCollectionsForFirehoseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsForFirehoseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsForFirehoseQuery({
 *   variables: {
 *      email_exclude_list: // value for 'email_exclude_list'
 *      created_at_offset: // value for 'created_at_offset'
 *      limit: // value for 'limit'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *   },
 * });
 */
export function useGetCollectionsForFirehoseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionsForFirehoseQuery,
    GetCollectionsForFirehoseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionsForFirehoseQuery,
    GetCollectionsForFirehoseQueryVariables
  >(GetCollectionsForFirehoseDocument, options)
}
export function useGetCollectionsForFirehoseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionsForFirehoseQuery,
    GetCollectionsForFirehoseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionsForFirehoseQuery,
    GetCollectionsForFirehoseQueryVariables
  >(GetCollectionsForFirehoseDocument, options)
}
export type GetCollectionsForFirehoseQueryHookResult = ReturnType<
  typeof useGetCollectionsForFirehoseQuery
>
export type GetCollectionsForFirehoseLazyQueryHookResult = ReturnType<
  typeof useGetCollectionsForFirehoseLazyQuery
>
export type GetCollectionsForFirehoseQueryResult = Apollo.QueryResult<
  GetCollectionsForFirehoseQuery,
  GetCollectionsForFirehoseQueryVariables
>
export const GetCollectionsFromIdsDocument = gql`
  query GetCollectionsFromIds($collectionIds: [uuid!]!) {
    collection(where: { id: { _in: $collectionIds } }) {
      id
      paid
      private
      name
      slug
    }
  }
`

/**
 * __useGetCollectionsFromIdsQuery__
 *
 * To run a query within a React component, call `useGetCollectionsFromIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsFromIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsFromIdsQuery({
 *   variables: {
 *      collectionIds: // value for 'collectionIds'
 *   },
 * });
 */
export function useGetCollectionsFromIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionsFromIdsQuery,
    GetCollectionsFromIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionsFromIdsQuery,
    GetCollectionsFromIdsQueryVariables
  >(GetCollectionsFromIdsDocument, options)
}
export function useGetCollectionsFromIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionsFromIdsQuery,
    GetCollectionsFromIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionsFromIdsQuery,
    GetCollectionsFromIdsQueryVariables
  >(GetCollectionsFromIdsDocument, options)
}
export type GetCollectionsFromIdsQueryHookResult = ReturnType<
  typeof useGetCollectionsFromIdsQuery
>
export type GetCollectionsFromIdsLazyQueryHookResult = ReturnType<
  typeof useGetCollectionsFromIdsLazyQuery
>
export type GetCollectionsFromIdsQueryResult = Apollo.QueryResult<
  GetCollectionsFromIdsQuery,
  GetCollectionsFromIdsQueryVariables
>
export const GetContactInviteDataForSmsDocument = gql`
  query GetContactInviteDataForSMS($id: uuid!) {
    contact_invite_by_pk(id: $id) {
      id
      type
      contact_info
      requester {
        name
        username
        picture
        display_name
      }
    }
  }
`

/**
 * __useGetContactInviteDataForSmsQuery__
 *
 * To run a query within a React component, call `useGetContactInviteDataForSmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactInviteDataForSmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactInviteDataForSmsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactInviteDataForSmsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactInviteDataForSmsQuery,
    GetContactInviteDataForSmsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContactInviteDataForSmsQuery,
    GetContactInviteDataForSmsQueryVariables
  >(GetContactInviteDataForSmsDocument, options)
}
export function useGetContactInviteDataForSmsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactInviteDataForSmsQuery,
    GetContactInviteDataForSmsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContactInviteDataForSmsQuery,
    GetContactInviteDataForSmsQueryVariables
  >(GetContactInviteDataForSmsDocument, options)
}
export type GetContactInviteDataForSmsQueryHookResult = ReturnType<
  typeof useGetContactInviteDataForSmsQuery
>
export type GetContactInviteDataForSmsLazyQueryHookResult = ReturnType<
  typeof useGetContactInviteDataForSmsLazyQuery
>
export type GetContactInviteDataForSmsQueryResult = Apollo.QueryResult<
  GetContactInviteDataForSmsQuery,
  GetContactInviteDataForSmsQueryVariables
>
export const GetDataForCategoriesSitemapDocument = gql`
  query GetDataForCategoriesSitemap {
    shop_categories: shop_category(order_by: { value: asc }) {
      label
      value
    }
  }
`

/**
 * __useGetDataForCategoriesSitemapQuery__
 *
 * To run a query within a React component, call `useGetDataForCategoriesSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForCategoriesSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForCategoriesSitemapQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDataForCategoriesSitemapQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDataForCategoriesSitemapQuery,
    GetDataForCategoriesSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDataForCategoriesSitemapQuery,
    GetDataForCategoriesSitemapQueryVariables
  >(GetDataForCategoriesSitemapDocument, options)
}
export function useGetDataForCategoriesSitemapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataForCategoriesSitemapQuery,
    GetDataForCategoriesSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDataForCategoriesSitemapQuery,
    GetDataForCategoriesSitemapQueryVariables
  >(GetDataForCategoriesSitemapDocument, options)
}
export type GetDataForCategoriesSitemapQueryHookResult = ReturnType<
  typeof useGetDataForCategoriesSitemapQuery
>
export type GetDataForCategoriesSitemapLazyQueryHookResult = ReturnType<
  typeof useGetDataForCategoriesSitemapLazyQuery
>
export type GetDataForCategoriesSitemapQueryResult = Apollo.QueryResult<
  GetDataForCategoriesSitemapQuery,
  GetDataForCategoriesSitemapQueryVariables
>
export const GetDataForLandingPagesSitemapDocument = gql`
  query GetDataForLandingPagesSitemap {
    pages: collection(
      where: {
        collection_type: { _eq: landing_page }
        state: { _eq: published }
      }
      order_by: [{ created_at: asc }, { name: asc }]
      limit: 1000
    ) {
      id
      slug
      updated_at
      user {
        username
      }
    }
  }
`

/**
 * __useGetDataForLandingPagesSitemapQuery__
 *
 * To run a query within a React component, call `useGetDataForLandingPagesSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForLandingPagesSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForLandingPagesSitemapQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDataForLandingPagesSitemapQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDataForLandingPagesSitemapQuery,
    GetDataForLandingPagesSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDataForLandingPagesSitemapQuery,
    GetDataForLandingPagesSitemapQueryVariables
  >(GetDataForLandingPagesSitemapDocument, options)
}
export function useGetDataForLandingPagesSitemapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataForLandingPagesSitemapQuery,
    GetDataForLandingPagesSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDataForLandingPagesSitemapQuery,
    GetDataForLandingPagesSitemapQueryVariables
  >(GetDataForLandingPagesSitemapDocument, options)
}
export type GetDataForLandingPagesSitemapQueryHookResult = ReturnType<
  typeof useGetDataForLandingPagesSitemapQuery
>
export type GetDataForLandingPagesSitemapLazyQueryHookResult = ReturnType<
  typeof useGetDataForLandingPagesSitemapLazyQuery
>
export type GetDataForLandingPagesSitemapQueryResult = Apollo.QueryResult<
  GetDataForLandingPagesSitemapQuery,
  GetDataForLandingPagesSitemapQueryVariables
>
export const GetDataForShopsSitemapDocument = gql`
  query GetDataForShopsSitemap($limit: Int = 20000) {
    shops: shop(
      where: {
        shop_categorizations: { category: { _nin: [social_platform] } }
        cart_items_count: { _gt: 25 }
      }
      order_by: [{ cart_items_count: desc }, { name: asc }]
      limit: $limit
    ) {
      key
      slug
      featured
    }
  }
`

/**
 * __useGetDataForShopsSitemapQuery__
 *
 * To run a query within a React component, call `useGetDataForShopsSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForShopsSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForShopsSitemapQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDataForShopsSitemapQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDataForShopsSitemapQuery,
    GetDataForShopsSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDataForShopsSitemapQuery,
    GetDataForShopsSitemapQueryVariables
  >(GetDataForShopsSitemapDocument, options)
}
export function useGetDataForShopsSitemapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataForShopsSitemapQuery,
    GetDataForShopsSitemapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDataForShopsSitemapQuery,
    GetDataForShopsSitemapQueryVariables
  >(GetDataForShopsSitemapDocument, options)
}
export type GetDataForShopsSitemapQueryHookResult = ReturnType<
  typeof useGetDataForShopsSitemapQuery
>
export type GetDataForShopsSitemapLazyQueryHookResult = ReturnType<
  typeof useGetDataForShopsSitemapLazyQuery
>
export type GetDataForShopsSitemapQueryResult = Apollo.QueryResult<
  GetDataForShopsSitemapQuery,
  GetDataForShopsSitemapQueryVariables
>
export const GetDataForWeeklySummaryEmailDocument = gql`
  query GetDataForWeeklySummaryEmail(
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    users: user(
      where: {
        anonymous: { _neq: true }
        carts: {
          cart_items: {
            _and: [
              { created_at: { _gte: $startDate } }
              { created_at: { _lt: $endDate } }
              { source: { _neq: "onboarding" } }
            ]
          }
        }
      }
      limit: 500
    ) {
      id
      name
      email
      username
      collections(
        where: {
          all: { _eq: false }
          _and: [
            { created_at: { _gte: $startDate } }
            { created_at: { _lt: $endDate } }
          ]
        }
      ) {
        name
        slug
        cover_image
        collection_items(limit: 1) {
          cart_item {
            id
            item_image_override
            item {
              cached_image
            }
          }
        }
      }
      carts(
        where: {
          cart_items: {
            _and: [
              { source: { _neq: "onboarding" } }
              { created_at: { _gte: $startDate } }
              { created_at: { _lt: $endDate } }
            ]
          }
        }
        order_by: { created_at: desc_nulls_last }
      ) {
        shop {
          key
          name
          display_name
          favicon
        }
        cart_items(
          where: {
            _and: [
              { source: { _neq: "onboarding" } }
              { deleted_at: { _is_null: true } }
              { archived_at: { _is_null: true } }
              { created_at: { _gte: $startDate } }
              { created_at: { _lt: $endDate } }
            ]
          }
          order_by: { created_at: desc_nulls_last }
        ) {
          id
          created_at
          item {
            title
            display_title
            cached_image
          }
        }
      }
    }
  }
`

/**
 * __useGetDataForWeeklySummaryEmailQuery__
 *
 * To run a query within a React component, call `useGetDataForWeeklySummaryEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForWeeklySummaryEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForWeeklySummaryEmailQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDataForWeeklySummaryEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDataForWeeklySummaryEmailQuery,
    GetDataForWeeklySummaryEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDataForWeeklySummaryEmailQuery,
    GetDataForWeeklySummaryEmailQueryVariables
  >(GetDataForWeeklySummaryEmailDocument, options)
}
export function useGetDataForWeeklySummaryEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataForWeeklySummaryEmailQuery,
    GetDataForWeeklySummaryEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDataForWeeklySummaryEmailQuery,
    GetDataForWeeklySummaryEmailQueryVariables
  >(GetDataForWeeklySummaryEmailDocument, options)
}
export type GetDataForWeeklySummaryEmailQueryHookResult = ReturnType<
  typeof useGetDataForWeeklySummaryEmailQuery
>
export type GetDataForWeeklySummaryEmailLazyQueryHookResult = ReturnType<
  typeof useGetDataForWeeklySummaryEmailLazyQuery
>
export type GetDataForWeeklySummaryEmailQueryResult = Apollo.QueryResult<
  GetDataForWeeklySummaryEmailQuery,
  GetDataForWeeklySummaryEmailQueryVariables
>
export const GetDependentWorkflowsDocument = gql`
  query GetDependentWorkflows($parent_workflow_id: uuid!) {
    workflow(
      where: {
        parent_workflow_id: { _eq: $parent_workflow_id }
        status: { _eq: pending }
      }
    ) {
      data
      parent_workflow_id
      id
      status
      user_id
      type
      success_item_type
      success_item_id
      status_details
    }
  }
`

/**
 * __useGetDependentWorkflowsQuery__
 *
 * To run a query within a React component, call `useGetDependentWorkflowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDependentWorkflowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDependentWorkflowsQuery({
 *   variables: {
 *      parent_workflow_id: // value for 'parent_workflow_id'
 *   },
 * });
 */
export function useGetDependentWorkflowsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDependentWorkflowsQuery,
    GetDependentWorkflowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDependentWorkflowsQuery,
    GetDependentWorkflowsQueryVariables
  >(GetDependentWorkflowsDocument, options)
}
export function useGetDependentWorkflowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDependentWorkflowsQuery,
    GetDependentWorkflowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDependentWorkflowsQuery,
    GetDependentWorkflowsQueryVariables
  >(GetDependentWorkflowsDocument, options)
}
export type GetDependentWorkflowsQueryHookResult = ReturnType<
  typeof useGetDependentWorkflowsQuery
>
export type GetDependentWorkflowsLazyQueryHookResult = ReturnType<
  typeof useGetDependentWorkflowsLazyQuery
>
export type GetDependentWorkflowsQueryResult = Apollo.QueryResult<
  GetDependentWorkflowsQuery,
  GetDependentWorkflowsQueryVariables
>
export const GetDiscoveryBacklogItemDocument = gql`
  query GetDiscoveryBacklogItem($id: uuid!) {
    discovery_backlog_by_pk(id: $id) {
      id
      sequential_id
      feed_item {
        id
        item_id
        item_type
      }
    }
  }
`

/**
 * __useGetDiscoveryBacklogItemQuery__
 *
 * To run a query within a React component, call `useGetDiscoveryBacklogItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoveryBacklogItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoveryBacklogItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDiscoveryBacklogItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDiscoveryBacklogItemQuery,
    GetDiscoveryBacklogItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDiscoveryBacklogItemQuery,
    GetDiscoveryBacklogItemQueryVariables
  >(GetDiscoveryBacklogItemDocument, options)
}
export function useGetDiscoveryBacklogItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscoveryBacklogItemQuery,
    GetDiscoveryBacklogItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDiscoveryBacklogItemQuery,
    GetDiscoveryBacklogItemQueryVariables
  >(GetDiscoveryBacklogItemDocument, options)
}
export type GetDiscoveryBacklogItemQueryHookResult = ReturnType<
  typeof useGetDiscoveryBacklogItemQuery
>
export type GetDiscoveryBacklogItemLazyQueryHookResult = ReturnType<
  typeof useGetDiscoveryBacklogItemLazyQuery
>
export type GetDiscoveryBacklogItemQueryResult = Apollo.QueryResult<
  GetDiscoveryBacklogItemQuery,
  GetDiscoveryBacklogItemQueryVariables
>
export const GetDiscoveryBacklogPreviewDocument = gql`
  query GetDiscoveryBacklogPreview($collectionItemsCount: Int = 4) {
    discovery_backlog(order_by: { sequential_id: desc }) {
      id
      sequential_id
      feed_item {
        ...discoveryFeedFeedItem
      }
    }
  }
  ${DiscoveryFeedFeedItemFragmentDoc}
`

/**
 * __useGetDiscoveryBacklogPreviewQuery__
 *
 * To run a query within a React component, call `useGetDiscoveryBacklogPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoveryBacklogPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoveryBacklogPreviewQuery({
 *   variables: {
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *   },
 * });
 */
export function useGetDiscoveryBacklogPreviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDiscoveryBacklogPreviewQuery,
    GetDiscoveryBacklogPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDiscoveryBacklogPreviewQuery,
    GetDiscoveryBacklogPreviewQueryVariables
  >(GetDiscoveryBacklogPreviewDocument, options)
}
export function useGetDiscoveryBacklogPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscoveryBacklogPreviewQuery,
    GetDiscoveryBacklogPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDiscoveryBacklogPreviewQuery,
    GetDiscoveryBacklogPreviewQueryVariables
  >(GetDiscoveryBacklogPreviewDocument, options)
}
export type GetDiscoveryBacklogPreviewQueryHookResult = ReturnType<
  typeof useGetDiscoveryBacklogPreviewQuery
>
export type GetDiscoveryBacklogPreviewLazyQueryHookResult = ReturnType<
  typeof useGetDiscoveryBacklogPreviewLazyQuery
>
export type GetDiscoveryBacklogPreviewQueryResult = Apollo.QueryResult<
  GetDiscoveryBacklogPreviewQuery,
  GetDiscoveryBacklogPreviewQueryVariables
>
export const GetDiscoveryFeedAdminPreviewDocument = gql`
  query GetDiscoveryFeedAdminPreview($collectionItemsCount: Int = 4) {
    discovery_feed(order_by: { sequential_id: desc }, limit: 50) {
      id
      sequential_id
      feed_item {
        ...discoveryFeedFeedItem
      }
    }
  }
  ${DiscoveryFeedFeedItemFragmentDoc}
`

/**
 * __useGetDiscoveryFeedAdminPreviewQuery__
 *
 * To run a query within a React component, call `useGetDiscoveryFeedAdminPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoveryFeedAdminPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoveryFeedAdminPreviewQuery({
 *   variables: {
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *   },
 * });
 */
export function useGetDiscoveryFeedAdminPreviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDiscoveryFeedAdminPreviewQuery,
    GetDiscoveryFeedAdminPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDiscoveryFeedAdminPreviewQuery,
    GetDiscoveryFeedAdminPreviewQueryVariables
  >(GetDiscoveryFeedAdminPreviewDocument, options)
}
export function useGetDiscoveryFeedAdminPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscoveryFeedAdminPreviewQuery,
    GetDiscoveryFeedAdminPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDiscoveryFeedAdminPreviewQuery,
    GetDiscoveryFeedAdminPreviewQueryVariables
  >(GetDiscoveryFeedAdminPreviewDocument, options)
}
export type GetDiscoveryFeedAdminPreviewQueryHookResult = ReturnType<
  typeof useGetDiscoveryFeedAdminPreviewQuery
>
export type GetDiscoveryFeedAdminPreviewLazyQueryHookResult = ReturnType<
  typeof useGetDiscoveryFeedAdminPreviewLazyQuery
>
export type GetDiscoveryFeedAdminPreviewQueryResult = Apollo.QueryResult<
  GetDiscoveryFeedAdminPreviewQuery,
  GetDiscoveryFeedAdminPreviewQueryVariables
>
export const GetDiscoveryStagingFeedDocument = gql`
  query GetDiscoveryStagingFeed {
    discovery_staging(order_by: { sequential_id: asc }) {
      id
      sequential_id
      feed_item {
        id
        item_id
        item_type
      }
    }
  }
`

/**
 * __useGetDiscoveryStagingFeedQuery__
 *
 * To run a query within a React component, call `useGetDiscoveryStagingFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoveryStagingFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoveryStagingFeedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDiscoveryStagingFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDiscoveryStagingFeedQuery,
    GetDiscoveryStagingFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDiscoveryStagingFeedQuery,
    GetDiscoveryStagingFeedQueryVariables
  >(GetDiscoveryStagingFeedDocument, options)
}
export function useGetDiscoveryStagingFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscoveryStagingFeedQuery,
    GetDiscoveryStagingFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDiscoveryStagingFeedQuery,
    GetDiscoveryStagingFeedQueryVariables
  >(GetDiscoveryStagingFeedDocument, options)
}
export type GetDiscoveryStagingFeedQueryHookResult = ReturnType<
  typeof useGetDiscoveryStagingFeedQuery
>
export type GetDiscoveryStagingFeedLazyQueryHookResult = ReturnType<
  typeof useGetDiscoveryStagingFeedLazyQuery
>
export type GetDiscoveryStagingFeedQueryResult = Apollo.QueryResult<
  GetDiscoveryStagingFeedQuery,
  GetDiscoveryStagingFeedQueryVariables
>
export const GetFeaturedShopsDocument = gql`
  query GetFeaturedShops($limit: Int = 20) {
    shop_popularity(
      where: { shop: { cover_image: { _is_null: false, _neq: "" } } }
      limit: $limit
      order_by: { popularity_rank: desc_nulls_last }
    ) {
      key
      popularity_rank
      shop {
        id
        key
        slug
        display_name
        cover_image
        favicon
        parsed_take_rate
      }
    }
  }
`

/**
 * __useGetFeaturedShopsQuery__
 *
 * To run a query within a React component, call `useGetFeaturedShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedShopsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetFeaturedShopsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeaturedShopsQuery,
    GetFeaturedShopsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFeaturedShopsQuery, GetFeaturedShopsQueryVariables>(
    GetFeaturedShopsDocument,
    options
  )
}
export function useGetFeaturedShopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeaturedShopsQuery,
    GetFeaturedShopsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetFeaturedShopsQuery,
    GetFeaturedShopsQueryVariables
  >(GetFeaturedShopsDocument, options)
}
export type GetFeaturedShopsQueryHookResult = ReturnType<
  typeof useGetFeaturedShopsQuery
>
export type GetFeaturedShopsLazyQueryHookResult = ReturnType<
  typeof useGetFeaturedShopsLazyQuery
>
export type GetFeaturedShopsQueryResult = Apollo.QueryResult<
  GetFeaturedShopsQuery,
  GetFeaturedShopsQueryVariables
>
export const GetFeedItemLikeDataForPushNotificationDocument = gql`
  query GetFeedItemLikeDataForPushNotification($id: uuid!) {
    feed_item_like(where: { id: { _eq: $id } }) {
      id
      user {
        username
      }
      feed_item {
        notifiable_user {
          user {
            ...userForPushNotificationData
          }
        }
        item_type
        featured_shop {
          shop {
            id
            name
            display_name
          }
        }
        collection {
          id
          name
          user_id
        }
        cart_item {
          id
          item_image_override
          item {
            display_title
          }
        }
      }
    }
  }
  ${UserForPushNotificationDataFragmentDoc}
`

/**
 * __useGetFeedItemLikeDataForPushNotificationQuery__
 *
 * To run a query within a React component, call `useGetFeedItemLikeDataForPushNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedItemLikeDataForPushNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedItemLikeDataForPushNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeedItemLikeDataForPushNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeedItemLikeDataForPushNotificationQuery,
    GetFeedItemLikeDataForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetFeedItemLikeDataForPushNotificationQuery,
    GetFeedItemLikeDataForPushNotificationQueryVariables
  >(GetFeedItemLikeDataForPushNotificationDocument, options)
}
export function useGetFeedItemLikeDataForPushNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedItemLikeDataForPushNotificationQuery,
    GetFeedItemLikeDataForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetFeedItemLikeDataForPushNotificationQuery,
    GetFeedItemLikeDataForPushNotificationQueryVariables
  >(GetFeedItemLikeDataForPushNotificationDocument, options)
}
export type GetFeedItemLikeDataForPushNotificationQueryHookResult = ReturnType<
  typeof useGetFeedItemLikeDataForPushNotificationQuery
>
export type GetFeedItemLikeDataForPushNotificationLazyQueryHookResult =
  ReturnType<typeof useGetFeedItemLikeDataForPushNotificationLazyQuery>
export type GetFeedItemLikeDataForPushNotificationQueryResult =
  Apollo.QueryResult<
    GetFeedItemLikeDataForPushNotificationQuery,
    GetFeedItemLikeDataForPushNotificationQueryVariables
  >
export const GetItemsFromIdsDocument = gql`
  query GetItemsFromIds($itemIds: [uuid!]!) {
    shop_item(where: { id: { _in: $itemIds } }) {
      id
      display_title
      cached_image
      image
      image_highres
    }
  }
`

/**
 * __useGetItemsFromIdsQuery__
 *
 * To run a query within a React component, call `useGetItemsFromIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsFromIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsFromIdsQuery({
 *   variables: {
 *      itemIds: // value for 'itemIds'
 *   },
 * });
 */
export function useGetItemsFromIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetItemsFromIdsQuery,
    GetItemsFromIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetItemsFromIdsQuery, GetItemsFromIdsQueryVariables>(
    GetItemsFromIdsDocument,
    options
  )
}
export function useGetItemsFromIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetItemsFromIdsQuery,
    GetItemsFromIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetItemsFromIdsQuery,
    GetItemsFromIdsQueryVariables
  >(GetItemsFromIdsDocument, options)
}
export type GetItemsFromIdsQueryHookResult = ReturnType<
  typeof useGetItemsFromIdsQuery
>
export type GetItemsFromIdsLazyQueryHookResult = ReturnType<
  typeof useGetItemsFromIdsLazyQuery
>
export type GetItemsFromIdsQueryResult = Apollo.QueryResult<
  GetItemsFromIdsQuery,
  GetItemsFromIdsQueryVariables
>
export const GetLikedCartItemsDocument = gql`
  query GetLikedCartItems($limit: Int = 50, $seed: seed_float = "0.0") {
    feed_item_likes: feed_item_like_random(
      args: { seed: $seed }
      where: {
        feed_item: { cart_item: { item: { has_broken_image: { _eq: false } } } }
        value: { _eq: 1 }
      }
      limit: $limit
    ) {
      feed_item {
        cart_item {
          ...cartItemData
        }
      }
    }
  }
  ${CartItemDataFragmentDoc}
`

/**
 * __useGetLikedCartItemsQuery__
 *
 * To run a query within a React component, call `useGetLikedCartItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLikedCartItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLikedCartItemsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      seed: // value for 'seed'
 *   },
 * });
 */
export function useGetLikedCartItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLikedCartItemsQuery,
    GetLikedCartItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetLikedCartItemsQuery,
    GetLikedCartItemsQueryVariables
  >(GetLikedCartItemsDocument, options)
}
export function useGetLikedCartItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLikedCartItemsQuery,
    GetLikedCartItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLikedCartItemsQuery,
    GetLikedCartItemsQueryVariables
  >(GetLikedCartItemsDocument, options)
}
export type GetLikedCartItemsQueryHookResult = ReturnType<
  typeof useGetLikedCartItemsQuery
>
export type GetLikedCartItemsLazyQueryHookResult = ReturnType<
  typeof useGetLikedCartItemsLazyQuery
>
export type GetLikedCartItemsQueryResult = Apollo.QueryResult<
  GetLikedCartItemsQuery,
  GetLikedCartItemsQueryVariables
>
export const GetMostRecentActiveCartItemsForUserDocument = gql`
  query GetMostRecentActiveCartItemsForUser($userId: String) {
    cart_item(
      order_by: { created_at: desc_nulls_last }
      limit: 6
      where: {
        cart: { user_id: { _eq: $userId } }
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
      }
    ) {
      id
      item {
        display_title
        image
        cached_image
      }
    }
  }
`

/**
 * __useGetMostRecentActiveCartItemsForUserQuery__
 *
 * To run a query within a React component, call `useGetMostRecentActiveCartItemsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentActiveCartItemsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentActiveCartItemsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetMostRecentActiveCartItemsForUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMostRecentActiveCartItemsForUserQuery,
    GetMostRecentActiveCartItemsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMostRecentActiveCartItemsForUserQuery,
    GetMostRecentActiveCartItemsForUserQueryVariables
  >(GetMostRecentActiveCartItemsForUserDocument, options)
}
export function useGetMostRecentActiveCartItemsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMostRecentActiveCartItemsForUserQuery,
    GetMostRecentActiveCartItemsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMostRecentActiveCartItemsForUserQuery,
    GetMostRecentActiveCartItemsForUserQueryVariables
  >(GetMostRecentActiveCartItemsForUserDocument, options)
}
export type GetMostRecentActiveCartItemsForUserQueryHookResult = ReturnType<
  typeof useGetMostRecentActiveCartItemsForUserQuery
>
export type GetMostRecentActiveCartItemsForUserLazyQueryHookResult = ReturnType<
  typeof useGetMostRecentActiveCartItemsForUserLazyQuery
>
export type GetMostRecentActiveCartItemsForUserQueryResult = Apollo.QueryResult<
  GetMostRecentActiveCartItemsForUserQuery,
  GetMostRecentActiveCartItemsForUserQueryVariables
>
export const GetOnboardingSourcesDocument = gql`
  query GetOnboardingSources {
    onboarding_sources {
      value
      label
    }
  }
`

/**
 * __useGetOnboardingSourcesQuery__
 *
 * To run a query within a React component, call `useGetOnboardingSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOnboardingSourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOnboardingSourcesQuery,
    GetOnboardingSourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOnboardingSourcesQuery,
    GetOnboardingSourcesQueryVariables
  >(GetOnboardingSourcesDocument, options)
}
export function useGetOnboardingSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnboardingSourcesQuery,
    GetOnboardingSourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOnboardingSourcesQuery,
    GetOnboardingSourcesQueryVariables
  >(GetOnboardingSourcesDocument, options)
}
export type GetOnboardingSourcesQueryHookResult = ReturnType<
  typeof useGetOnboardingSourcesQuery
>
export type GetOnboardingSourcesLazyQueryHookResult = ReturnType<
  typeof useGetOnboardingSourcesLazyQuery
>
export type GetOnboardingSourcesQueryResult = Apollo.QueryResult<
  GetOnboardingSourcesQuery,
  GetOnboardingSourcesQueryVariables
>
export const GetPriceChangesAmazonShopifyPaginateDocument = gql`
  query GetPriceChangesAmazonShopifyPaginate($limit: Int, $offset: Int) {
    shop_item(
      order_by: { updated_at: desc_nulls_last }
      where: {
        pricing: { _neq: {} }
        parsed_pricing: { _neq: {} }
        shop: { type: { _in: ["Amazon", "Shopify"] } }
        cart_items: {
          cart: {
            user: { anonymous: { _eq: false }, orphaned_at: { _is_null: true } }
          }
        }
      }
      limit: $limit
      offset: $offset
    ) {
      id
      item_id
      created_at
      updated_at
      parsed_pricing
      display_title
      pricing
      image_highres
      image
      cached_image
      url
      shop_item_histories(
        order_by: { updated_at: desc_nulls_last }
        limit: 5
        where: { valid: { _eq: true } }
      ) {
        id
        valid
        pricing
        updated_at
      }
      shop {
        name
        favicon
        key
      }
      cart_items(limit: 10) {
        id
        source
        updated_at
        created_at
        cart {
          user {
            id
            name
            picture
            email
          }
        }
      }
    }
  }
`

/**
 * __useGetPriceChangesAmazonShopifyPaginateQuery__
 *
 * To run a query within a React component, call `useGetPriceChangesAmazonShopifyPaginateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceChangesAmazonShopifyPaginateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceChangesAmazonShopifyPaginateQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPriceChangesAmazonShopifyPaginateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPriceChangesAmazonShopifyPaginateQuery,
    GetPriceChangesAmazonShopifyPaginateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPriceChangesAmazonShopifyPaginateQuery,
    GetPriceChangesAmazonShopifyPaginateQueryVariables
  >(GetPriceChangesAmazonShopifyPaginateDocument, options)
}
export function useGetPriceChangesAmazonShopifyPaginateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPriceChangesAmazonShopifyPaginateQuery,
    GetPriceChangesAmazonShopifyPaginateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPriceChangesAmazonShopifyPaginateQuery,
    GetPriceChangesAmazonShopifyPaginateQueryVariables
  >(GetPriceChangesAmazonShopifyPaginateDocument, options)
}
export type GetPriceChangesAmazonShopifyPaginateQueryHookResult = ReturnType<
  typeof useGetPriceChangesAmazonShopifyPaginateQuery
>
export type GetPriceChangesAmazonShopifyPaginateLazyQueryHookResult =
  ReturnType<typeof useGetPriceChangesAmazonShopifyPaginateLazyQuery>
export type GetPriceChangesAmazonShopifyPaginateQueryResult =
  Apollo.QueryResult<
    GetPriceChangesAmazonShopifyPaginateQuery,
    GetPriceChangesAmazonShopifyPaginateQueryVariables
  >
export const GetPriceDropDataForPushNotificationDocument = gql`
  query GetPriceDropDataForPushNotification($id: uuid!) {
    push_notification_by_pk(id: $id) {
      payload
      user {
        ...userForPushNotificationData
      }
    }
  }
  ${UserForPushNotificationDataFragmentDoc}
`

/**
 * __useGetPriceDropDataForPushNotificationQuery__
 *
 * To run a query within a React component, call `useGetPriceDropDataForPushNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceDropDataForPushNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceDropDataForPushNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPriceDropDataForPushNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPriceDropDataForPushNotificationQuery,
    GetPriceDropDataForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPriceDropDataForPushNotificationQuery,
    GetPriceDropDataForPushNotificationQueryVariables
  >(GetPriceDropDataForPushNotificationDocument, options)
}
export function useGetPriceDropDataForPushNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPriceDropDataForPushNotificationQuery,
    GetPriceDropDataForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPriceDropDataForPushNotificationQuery,
    GetPriceDropDataForPushNotificationQueryVariables
  >(GetPriceDropDataForPushNotificationDocument, options)
}
export type GetPriceDropDataForPushNotificationQueryHookResult = ReturnType<
  typeof useGetPriceDropDataForPushNotificationQuery
>
export type GetPriceDropDataForPushNotificationLazyQueryHookResult = ReturnType<
  typeof useGetPriceDropDataForPushNotificationLazyQuery
>
export type GetPriceDropDataForPushNotificationQueryResult = Apollo.QueryResult<
  GetPriceDropDataForPushNotificationQuery,
  GetPriceDropDataForPushNotificationQueryVariables
>
export const GetRandomDiscoveryFeedDocument = gql`
  query GetRandomDiscoveryFeed(
    $limit: Int = 50
    $seed: seed_float = "0.0"
    $created_at: timestamptz_comparison_exp = {}
  ) {
    discovery_feed: discovery_feed_random(
      args: { seed: $seed }
      where: {
        feed_item: { item_type: { _in: [collection, collection_cart_item] } }
        created_at: $created_at
      }
      limit: $limit
    ) {
      feed_item {
        item_type
        collection {
          id
          slug
          collection_items(
            where: {
              cart_item: {
                deleted_at: { _is_null: true }
                item: { has_broken_image: { _eq: false } }
              }
            }
            order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
            limit: 5
          ) {
            ...discoveryCollectionCartItem
          }
        }
        collection_cart_item {
          ...discoveryCollectionCartItem
          ...discoveryFeedCollectionCartItemCollection
        }
      }
    }
  }
  ${DiscoveryCollectionCartItemFragmentDoc}
  ${DiscoveryFeedCollectionCartItemCollectionFragmentDoc}
`

/**
 * __useGetRandomDiscoveryFeedQuery__
 *
 * To run a query within a React component, call `useGetRandomDiscoveryFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRandomDiscoveryFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRandomDiscoveryFeedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      seed: // value for 'seed'
 *      created_at: // value for 'created_at'
 *   },
 * });
 */
export function useGetRandomDiscoveryFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRandomDiscoveryFeedQuery,
    GetRandomDiscoveryFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetRandomDiscoveryFeedQuery,
    GetRandomDiscoveryFeedQueryVariables
  >(GetRandomDiscoveryFeedDocument, options)
}
export function useGetRandomDiscoveryFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRandomDiscoveryFeedQuery,
    GetRandomDiscoveryFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetRandomDiscoveryFeedQuery,
    GetRandomDiscoveryFeedQueryVariables
  >(GetRandomDiscoveryFeedDocument, options)
}
export type GetRandomDiscoveryFeedQueryHookResult = ReturnType<
  typeof useGetRandomDiscoveryFeedQuery
>
export type GetRandomDiscoveryFeedLazyQueryHookResult = ReturnType<
  typeof useGetRandomDiscoveryFeedLazyQuery
>
export type GetRandomDiscoveryFeedQueryResult = Apollo.QueryResult<
  GetRandomDiscoveryFeedQuery,
  GetRandomDiscoveryFeedQueryVariables
>
export const GetRemindMeAtCartItemsDocument = gql`
  query GetRemindMeAtCartItems($limit: Int, $offset: Int) {
    cart_item(
      where: { remind_me_at: { _is_null: false } }
      order_by: { remind_me_at: asc }
      limit: $limit
      offset: $offset
    ) {
      cart_id
      display_title
      description
      pricing
      shop {
        name
        favicon
      }
      item {
        display_title
        pricing
        parsed_pricing
        created_at
      }
      cart {
        user {
          email
          name
          picture
          id
        }
      }
    }
  }
`

/**
 * __useGetRemindMeAtCartItemsQuery__
 *
 * To run a query within a React component, call `useGetRemindMeAtCartItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemindMeAtCartItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemindMeAtCartItemsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetRemindMeAtCartItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRemindMeAtCartItemsQuery,
    GetRemindMeAtCartItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetRemindMeAtCartItemsQuery,
    GetRemindMeAtCartItemsQueryVariables
  >(GetRemindMeAtCartItemsDocument, options)
}
export function useGetRemindMeAtCartItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRemindMeAtCartItemsQuery,
    GetRemindMeAtCartItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetRemindMeAtCartItemsQuery,
    GetRemindMeAtCartItemsQueryVariables
  >(GetRemindMeAtCartItemsDocument, options)
}
export type GetRemindMeAtCartItemsQueryHookResult = ReturnType<
  typeof useGetRemindMeAtCartItemsQuery
>
export type GetRemindMeAtCartItemsLazyQueryHookResult = ReturnType<
  typeof useGetRemindMeAtCartItemsLazyQuery
>
export type GetRemindMeAtCartItemsQueryResult = Apollo.QueryResult<
  GetRemindMeAtCartItemsQuery,
  GetRemindMeAtCartItemsQueryVariables
>
export const GetShopByKeyForMobileDocument = gql`
  query GetShopByKeyForMobile($key: String!) {
    shop(where: { key: { _eq: $key } }) {
      id
      key
      cart_path
      type
      platform
      name
      slug
      favicon
      unknown
      metadata
      created_at
      updated_at
    }
  }
`

/**
 * __useGetShopByKeyForMobileQuery__
 *
 * To run a query within a React component, call `useGetShopByKeyForMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopByKeyForMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopByKeyForMobileQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetShopByKeyForMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopByKeyForMobileQuery,
    GetShopByKeyForMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopByKeyForMobileQuery,
    GetShopByKeyForMobileQueryVariables
  >(GetShopByKeyForMobileDocument, options)
}
export function useGetShopByKeyForMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopByKeyForMobileQuery,
    GetShopByKeyForMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopByKeyForMobileQuery,
    GetShopByKeyForMobileQueryVariables
  >(GetShopByKeyForMobileDocument, options)
}
export type GetShopByKeyForMobileQueryHookResult = ReturnType<
  typeof useGetShopByKeyForMobileQuery
>
export type GetShopByKeyForMobileLazyQueryHookResult = ReturnType<
  typeof useGetShopByKeyForMobileLazyQuery
>
export type GetShopByKeyForMobileQueryResult = Apollo.QueryResult<
  GetShopByKeyForMobileQuery,
  GetShopByKeyForMobileQueryVariables
>
export const GetShopCategoriesDocument = gql`
  query GetShopCategories(
    $block_list: [String!] = ["omnishop", "social_platform"]
  ) {
    shop_category(
      where: { _not: { value: { _in: $block_list } } }
      order_by: [
        { onboarding_category: { featured: desc_nulls_last } }
        { value: asc_nulls_last }
      ]
    ) {
      value
      label
      onboarding_category {
        featured
        image
      }
    }
  }
`

/**
 * __useGetShopCategoriesQuery__
 *
 * To run a query within a React component, call `useGetShopCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopCategoriesQuery({
 *   variables: {
 *      block_list: // value for 'block_list'
 *   },
 * });
 */
export function useGetShopCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetShopCategoriesQuery,
    GetShopCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopCategoriesQuery,
    GetShopCategoriesQueryVariables
  >(GetShopCategoriesDocument, options)
}
export function useGetShopCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopCategoriesQuery,
    GetShopCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopCategoriesQuery,
    GetShopCategoriesQueryVariables
  >(GetShopCategoriesDocument, options)
}
export type GetShopCategoriesQueryHookResult = ReturnType<
  typeof useGetShopCategoriesQuery
>
export type GetShopCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetShopCategoriesLazyQuery
>
export type GetShopCategoriesQueryResult = Apollo.QueryResult<
  GetShopCategoriesQuery,
  GetShopCategoriesQueryVariables
>
export const GetShopItemDataForPriceDropPushNotificationDocument = gql`
  query GetShopItemDataForPriceDropPushNotification($id: uuid!) {
    shop_item_by_pk(id: $id) {
      display_title
      parsed_pricing
    }
  }
`

/**
 * __useGetShopItemDataForPriceDropPushNotificationQuery__
 *
 * To run a query within a React component, call `useGetShopItemDataForPriceDropPushNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemDataForPriceDropPushNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemDataForPriceDropPushNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShopItemDataForPriceDropPushNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopItemDataForPriceDropPushNotificationQuery,
    GetShopItemDataForPriceDropPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopItemDataForPriceDropPushNotificationQuery,
    GetShopItemDataForPriceDropPushNotificationQueryVariables
  >(GetShopItemDataForPriceDropPushNotificationDocument, options)
}
export function useGetShopItemDataForPriceDropPushNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopItemDataForPriceDropPushNotificationQuery,
    GetShopItemDataForPriceDropPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopItemDataForPriceDropPushNotificationQuery,
    GetShopItemDataForPriceDropPushNotificationQueryVariables
  >(GetShopItemDataForPriceDropPushNotificationDocument, options)
}
export type GetShopItemDataForPriceDropPushNotificationQueryHookResult =
  ReturnType<typeof useGetShopItemDataForPriceDropPushNotificationQuery>
export type GetShopItemDataForPriceDropPushNotificationLazyQueryHookResult =
  ReturnType<typeof useGetShopItemDataForPriceDropPushNotificationLazyQuery>
export type GetShopItemDataForPriceDropPushNotificationQueryResult =
  Apollo.QueryResult<
    GetShopItemDataForPriceDropPushNotificationQuery,
    GetShopItemDataForPriceDropPushNotificationQueryVariables
  >
export const GetShopItemForEnrichmentDocument = gql`
  query GetShopItemForEnrichment($id: uuid!) {
    shop_item_by_pk(id: $id) {
      id
      item_id
      pricing
      parsed_pricing
      url
      title
      image
      shop_key
      shop {
        key
        cart_path
        type
        name
        slug
        favicon
        unknown
        metadata
      }
    }
  }
`

/**
 * __useGetShopItemForEnrichmentQuery__
 *
 * To run a query within a React component, call `useGetShopItemForEnrichmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemForEnrichmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemForEnrichmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShopItemForEnrichmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopItemForEnrichmentQuery,
    GetShopItemForEnrichmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopItemForEnrichmentQuery,
    GetShopItemForEnrichmentQueryVariables
  >(GetShopItemForEnrichmentDocument, options)
}
export function useGetShopItemForEnrichmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopItemForEnrichmentQuery,
    GetShopItemForEnrichmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopItemForEnrichmentQuery,
    GetShopItemForEnrichmentQueryVariables
  >(GetShopItemForEnrichmentDocument, options)
}
export type GetShopItemForEnrichmentQueryHookResult = ReturnType<
  typeof useGetShopItemForEnrichmentQuery
>
export type GetShopItemForEnrichmentLazyQueryHookResult = ReturnType<
  typeof useGetShopItemForEnrichmentLazyQuery
>
export type GetShopItemForEnrichmentQueryResult = Apollo.QueryResult<
  GetShopItemForEnrichmentQuery,
  GetShopItemForEnrichmentQueryVariables
>
export const GetShopItemForScrapingDocument = gql`
  query GetShopItemForScraping($id: uuid!) {
    shop_item_by_pk(id: $id) {
      url
      item_id
      shop_key
    }
  }
`

/**
 * __useGetShopItemForScrapingQuery__
 *
 * To run a query within a React component, call `useGetShopItemForScrapingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemForScrapingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemForScrapingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShopItemForScrapingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopItemForScrapingQuery,
    GetShopItemForScrapingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopItemForScrapingQuery,
    GetShopItemForScrapingQueryVariables
  >(GetShopItemForScrapingDocument, options)
}
export function useGetShopItemForScrapingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopItemForScrapingQuery,
    GetShopItemForScrapingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopItemForScrapingQuery,
    GetShopItemForScrapingQueryVariables
  >(GetShopItemForScrapingDocument, options)
}
export type GetShopItemForScrapingQueryHookResult = ReturnType<
  typeof useGetShopItemForScrapingQuery
>
export type GetShopItemForScrapingLazyQueryHookResult = ReturnType<
  typeof useGetShopItemForScrapingLazyQuery
>
export type GetShopItemForScrapingQueryResult = Apollo.QueryResult<
  GetShopItemForScrapingQuery,
  GetShopItemForScrapingQueryVariables
>
export const GetShopItemForShopItemHistoryProcessingDocument = gql`
  query GetShopItemForShopItemHistoryProcessing($id: uuid!) {
    shop_item_by_pk(id: $id) {
      id
      item_id
      pricing
      parsed_pricing
      url
      metadata
      title
      description
      image
      shop_key
      availability
      enrichment_status
      categories
      keywords
      shop {
        type
        platform
        shop_categorizations {
          category
        }
      }
      cart_items(order_by: { created_at: desc_nulls_last }, limit: 1) {
        source
      }
      shop_item_histories_aggregate {
        aggregate {
          count
        }
      }
      shop_item_images {
        id
        url
      }
    }
  }
`

/**
 * __useGetShopItemForShopItemHistoryProcessingQuery__
 *
 * To run a query within a React component, call `useGetShopItemForShopItemHistoryProcessingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemForShopItemHistoryProcessingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemForShopItemHistoryProcessingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShopItemForShopItemHistoryProcessingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopItemForShopItemHistoryProcessingQuery,
    GetShopItemForShopItemHistoryProcessingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopItemForShopItemHistoryProcessingQuery,
    GetShopItemForShopItemHistoryProcessingQueryVariables
  >(GetShopItemForShopItemHistoryProcessingDocument, options)
}
export function useGetShopItemForShopItemHistoryProcessingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopItemForShopItemHistoryProcessingQuery,
    GetShopItemForShopItemHistoryProcessingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopItemForShopItemHistoryProcessingQuery,
    GetShopItemForShopItemHistoryProcessingQueryVariables
  >(GetShopItemForShopItemHistoryProcessingDocument, options)
}
export type GetShopItemForShopItemHistoryProcessingQueryHookResult = ReturnType<
  typeof useGetShopItemForShopItemHistoryProcessingQuery
>
export type GetShopItemForShopItemHistoryProcessingLazyQueryHookResult =
  ReturnType<typeof useGetShopItemForShopItemHistoryProcessingLazyQuery>
export type GetShopItemForShopItemHistoryProcessingQueryResult =
  Apollo.QueryResult<
    GetShopItemForShopItemHistoryProcessingQuery,
    GetShopItemForShopItemHistoryProcessingQueryVariables
  >
export const GetShopItemToCheckRedirectDocument = gql`
  query GetShopItemToCheckRedirect(
    $shopKey: String!
    $offset: Int!
    $affiliateOverride: String!
    $limit: Int = 1
  ) {
    shop: shop_by_pk(key: $shopKey) {
      id
      metadata
      parsed_take_rate
      shop_affiliates {
        ...shopAffiliate
      }
      items(
        limit: $limit
        offset: $offset
        where: { url: { _neq: $shopKey } }
      ) {
        id
        url
      }
    }
    affiliateOverride: affiliate_by_pk(value: $affiliateOverride) {
      ...affiliate
    }
  }
  ${ShopAffiliateFragmentDoc}
  ${AffiliateFragmentDoc}
`

/**
 * __useGetShopItemToCheckRedirectQuery__
 *
 * To run a query within a React component, call `useGetShopItemToCheckRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemToCheckRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemToCheckRedirectQuery({
 *   variables: {
 *      shopKey: // value for 'shopKey'
 *      offset: // value for 'offset'
 *      affiliateOverride: // value for 'affiliateOverride'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetShopItemToCheckRedirectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopItemToCheckRedirectQuery,
    GetShopItemToCheckRedirectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopItemToCheckRedirectQuery,
    GetShopItemToCheckRedirectQueryVariables
  >(GetShopItemToCheckRedirectDocument, options)
}
export function useGetShopItemToCheckRedirectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopItemToCheckRedirectQuery,
    GetShopItemToCheckRedirectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopItemToCheckRedirectQuery,
    GetShopItemToCheckRedirectQueryVariables
  >(GetShopItemToCheckRedirectDocument, options)
}
export type GetShopItemToCheckRedirectQueryHookResult = ReturnType<
  typeof useGetShopItemToCheckRedirectQuery
>
export type GetShopItemToCheckRedirectLazyQueryHookResult = ReturnType<
  typeof useGetShopItemToCheckRedirectLazyQuery
>
export type GetShopItemToCheckRedirectQueryResult = Apollo.QueryResult<
  GetShopItemToCheckRedirectQuery,
  GetShopItemToCheckRedirectQueryVariables
>
export const GetShopMetadataDocument = gql`
  query GetShopMetadata($key: String!) {
    shop: shop_by_pk(key: $key) {
      metadata
    }
  }
`

/**
 * __useGetShopMetadataQuery__
 *
 * To run a query within a React component, call `useGetShopMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopMetadataQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetShopMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopMetadataQuery,
    GetShopMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetShopMetadataQuery, GetShopMetadataQueryVariables>(
    GetShopMetadataDocument,
    options
  )
}
export function useGetShopMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopMetadataQuery,
    GetShopMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopMetadataQuery,
    GetShopMetadataQueryVariables
  >(GetShopMetadataDocument, options)
}
export type GetShopMetadataQueryHookResult = ReturnType<
  typeof useGetShopMetadataQuery
>
export type GetShopMetadataLazyQueryHookResult = ReturnType<
  typeof useGetShopMetadataLazyQuery
>
export type GetShopMetadataQueryResult = Apollo.QueryResult<
  GetShopMetadataQuery,
  GetShopMetadataQueryVariables
>
export const ShopCategorizationsDocument = gql`
  query ShopCategorizations {
    shop_categorization(
      where: { weight: { _eq: 3 } }
      order_by: { category: asc }
    ) {
      id
      category
      shop_category {
        label
      }
      shop {
        id
        key
        name
        favicon
      }
    }
  }
`

/**
 * __useShopCategorizationsQuery__
 *
 * To run a query within a React component, call `useShopCategorizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopCategorizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopCategorizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopCategorizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ShopCategorizationsQuery,
    ShopCategorizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ShopCategorizationsQuery,
    ShopCategorizationsQueryVariables
  >(ShopCategorizationsDocument, options)
}
export function useShopCategorizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShopCategorizationsQuery,
    ShopCategorizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ShopCategorizationsQuery,
    ShopCategorizationsQueryVariables
  >(ShopCategorizationsDocument, options)
}
export type ShopCategorizationsQueryHookResult = ReturnType<
  typeof useShopCategorizationsQuery
>
export type ShopCategorizationsLazyQueryHookResult = ReturnType<
  typeof useShopCategorizationsLazyQuery
>
export type ShopCategorizationsQueryResult = Apollo.QueryResult<
  ShopCategorizationsQuery,
  ShopCategorizationsQueryVariables
>
export const GetShopsForFirehoseDocument = gql`
  query GetShopsForFirehose(
    $shops_limit: Int = 24
    $created_at_offset: timestamptz = "now()"
  ) {
    shops: shop(
      where: {
        created_at: { _lt: $created_at_offset }
        shop_items_count: { _gte: 5 }
        key: {
          _nin: [
            "https://www.amazon.com"
            "https://smile.amazon.com"
            "https://www.target.com"
            "https://www.homedepot.com"
            "https://www.walmart.com"
          ]
        }
        carts: { user: { anonymous: { _eq: false } } }
        cart_items: {
          collection_cart_items: {
            cart_item: {
              removed_at: { _is_null: true }
              deleted_at: { _is_null: true }
            }
          }
        }
      }
      limit: $shops_limit
      order_by: [{ created_at: desc_nulls_last }, { shop_items_count: desc }]
    ) {
      id
      key
      display_name
      created_at
      cart_items(order_by: { created_at: asc_nulls_last }, limit: 1) {
        id
        created_at
        cart {
          id
          user {
            display_name
            username
            picture
          }
        }
      }
      featured_shop {
        feed_item {
          id
          in_discovery_backlog
          in_discovery_feed
        }
      }
      items(
        where: { title: { _niregex: "(route|navidium)" } }
        limit: 4
        order_by: { updated_at: desc_nulls_last }
      ) {
        id
        display_title
        url
        image
        cached_image
        image_highres
      }
    }
  }
`

/**
 * __useGetShopsForFirehoseQuery__
 *
 * To run a query within a React component, call `useGetShopsForFirehoseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopsForFirehoseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopsForFirehoseQuery({
 *   variables: {
 *      shops_limit: // value for 'shops_limit'
 *      created_at_offset: // value for 'created_at_offset'
 *   },
 * });
 */
export function useGetShopsForFirehoseQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetShopsForFirehoseQuery,
    GetShopsForFirehoseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopsForFirehoseQuery,
    GetShopsForFirehoseQueryVariables
  >(GetShopsForFirehoseDocument, options)
}
export function useGetShopsForFirehoseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopsForFirehoseQuery,
    GetShopsForFirehoseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopsForFirehoseQuery,
    GetShopsForFirehoseQueryVariables
  >(GetShopsForFirehoseDocument, options)
}
export type GetShopsForFirehoseQueryHookResult = ReturnType<
  typeof useGetShopsForFirehoseQuery
>
export type GetShopsForFirehoseLazyQueryHookResult = ReturnType<
  typeof useGetShopsForFirehoseLazyQuery
>
export type GetShopsForFirehoseQueryResult = Apollo.QueryResult<
  GetShopsForFirehoseQuery,
  GetShopsForFirehoseQueryVariables
>
export const GetSingleNotificationDocument = gql`
  query GetSingleNotification($userId: String!) {
    push_notification(
      where: {
        active: { _eq: true }
        show_after: { _lte: "NOW()" }
        show_before: { _gte: "NOW()" }
        _not: { push_notification_status: { user_id: { _eq: $userId } } }
        notifiable_type: { _eq: "global" }
        notifiable_id: { _is_null: true }
      }
      limit: 1
      order_by: { created_at: asc }
    ) {
      active
      id
      payload
      criteria
      show_after
      show_before
    }
    user_notifications: user_push_notification(
      where: {
        active: { _eq: true }
        _not: { push_notification_status: { user_id: { _eq: $userId } } }
        show_after: { _lte: "NOW()" }
        show_before: { _gte: "NOW()" }
        notifiable_id: { _eq: $userId }
        notifiable_type: { _eq: "user" }
      }
      limit: 1
      order_by: { created_at: asc }
    ) {
      active
      id
      payload
      criteria
      show_after
      show_before
    }
    push_notification_status(
      where: { user_id: { _eq: $userId } }
      limit: 1
      order_by: { created_at: desc_nulls_last }
    ) {
      created_at
    }
  }
`

/**
 * __useGetSingleNotificationQuery__
 *
 * To run a query within a React component, call `useGetSingleNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleNotificationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetSingleNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSingleNotificationQuery,
    GetSingleNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSingleNotificationQuery,
    GetSingleNotificationQueryVariables
  >(GetSingleNotificationDocument, options)
}
export function useGetSingleNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSingleNotificationQuery,
    GetSingleNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSingleNotificationQuery,
    GetSingleNotificationQueryVariables
  >(GetSingleNotificationDocument, options)
}
export type GetSingleNotificationQueryHookResult = ReturnType<
  typeof useGetSingleNotificationQuery
>
export type GetSingleNotificationLazyQueryHookResult = ReturnType<
  typeof useGetSingleNotificationLazyQuery
>
export type GetSingleNotificationQueryResult = Apollo.QueryResult<
  GetSingleNotificationQuery,
  GetSingleNotificationQueryVariables
>
export const GetSinglePriceDropItemDocument = gql`
  query GetSinglePriceDropItem($shopItemId: uuid) {
    shop_item(
      where: {
        shop: { type: { _in: ["Amazon", "Shopify"] } }
        id: { _eq: $shopItemId }
        cart_items: {
          cart: {
            user: { anonymous: { _eq: false }, orphaned_at: { _is_null: true } }
          }
        }
      }
      order_by: { updated_at: desc_nulls_last }
    ) {
      id
      item_id
      created_at
      updated_at
      parsed_pricing
      display_title
      pricing
      url
      image
      image_highres
      cached_image
      shop_item_histories(
        order_by: { updated_at: desc_nulls_last }
        limit: 5
        where: { valid: { _eq: true } }
      ) {
        id
        valid
        pricing
        updated_at
      }
      shop {
        name
        favicon
        key
      }
      cart_items {
        id
        created_at
        cart {
          user {
            id
            name
            picture
            email
          }
        }
      }
    }
  }
`

/**
 * __useGetSinglePriceDropItemQuery__
 *
 * To run a query within a React component, call `useGetSinglePriceDropItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSinglePriceDropItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSinglePriceDropItemQuery({
 *   variables: {
 *      shopItemId: // value for 'shopItemId'
 *   },
 * });
 */
export function useGetSinglePriceDropItemQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSinglePriceDropItemQuery,
    GetSinglePriceDropItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSinglePriceDropItemQuery,
    GetSinglePriceDropItemQueryVariables
  >(GetSinglePriceDropItemDocument, options)
}
export function useGetSinglePriceDropItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSinglePriceDropItemQuery,
    GetSinglePriceDropItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSinglePriceDropItemQuery,
    GetSinglePriceDropItemQueryVariables
  >(GetSinglePriceDropItemDocument, options)
}
export type GetSinglePriceDropItemQueryHookResult = ReturnType<
  typeof useGetSinglePriceDropItemQuery
>
export type GetSinglePriceDropItemLazyQueryHookResult = ReturnType<
  typeof useGetSinglePriceDropItemLazyQuery
>
export type GetSinglePriceDropItemQueryResult = Apollo.QueryResult<
  GetSinglePriceDropItemQuery,
  GetSinglePriceDropItemQueryVariables
>
export const GetUserAllCollectionsDataForProfilePageDocument = gql`
  query GetUserAllCollectionsDataForProfilePage(
    $username: String
    $itemLimit: Int = 3
    $offset: Int = 0
    $limit: Int = 15
  ) {
    collection_aggregate(
      where: { all: { _eq: false }, user: { username: { _eq: $username } } }
    ) {
      aggregate {
        count
      }
    }
    collections: collection(
      where: { all: { _eq: false }, user: { username: { _eq: $username } } }
      order_by: [
        { cart_item_max_updated_at: desc_nulls_last }
        { updated_at: desc_nulls_last }
      ]
      offset: $offset
      limit: $limit
    ) {
      ...userCollectionsDataForProfilePageData
    }
  }
  ${UserCollectionsDataForProfilePageDataFragmentDoc}
`

/**
 * __useGetUserAllCollectionsDataForProfilePageQuery__
 *
 * To run a query within a React component, call `useGetUserAllCollectionsDataForProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAllCollectionsDataForProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAllCollectionsDataForProfilePageQuery({
 *   variables: {
 *      username: // value for 'username'
 *      itemLimit: // value for 'itemLimit'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserAllCollectionsDataForProfilePageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserAllCollectionsDataForProfilePageQuery,
    GetUserAllCollectionsDataForProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserAllCollectionsDataForProfilePageQuery,
    GetUserAllCollectionsDataForProfilePageQueryVariables
  >(GetUserAllCollectionsDataForProfilePageDocument, options)
}
export function useGetUserAllCollectionsDataForProfilePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAllCollectionsDataForProfilePageQuery,
    GetUserAllCollectionsDataForProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserAllCollectionsDataForProfilePageQuery,
    GetUserAllCollectionsDataForProfilePageQueryVariables
  >(GetUserAllCollectionsDataForProfilePageDocument, options)
}
export type GetUserAllCollectionsDataForProfilePageQueryHookResult = ReturnType<
  typeof useGetUserAllCollectionsDataForProfilePageQuery
>
export type GetUserAllCollectionsDataForProfilePageLazyQueryHookResult =
  ReturnType<typeof useGetUserAllCollectionsDataForProfilePageLazyQuery>
export type GetUserAllCollectionsDataForProfilePageQueryResult =
  Apollo.QueryResult<
    GetUserAllCollectionsDataForProfilePageQuery,
    GetUserAllCollectionsDataForProfilePageQueryVariables
  >
export const GetUserArchivedCartsDocument = gql`
  query GetUserArchivedCarts($id: String!) {
    archived_carts: cart(
      limit: 100
      where: {
        user: { id: { _eq: $id } }
        cart_items: {
          archived_at: { _is_null: false }
          deleted_at: { _is_null: true }
        }
      }
      order_by: { updated_at: desc_nulls_last }
    ) {
      ...cartData
      cart_items(
        limit: 100
        where: {
          archived_at: { _is_null: false }
          deleted_at: { _is_null: true }
        }
        order_by: { archived_at: desc_nulls_last }
      ) {
        ...cartItemData
      }
    }
  }
  ${CartDataFragmentDoc}
  ${CartItemDataFragmentDoc}
`

/**
 * __useGetUserArchivedCartsQuery__
 *
 * To run a query within a React component, call `useGetUserArchivedCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserArchivedCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserArchivedCartsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserArchivedCartsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserArchivedCartsQuery,
    GetUserArchivedCartsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserArchivedCartsQuery,
    GetUserArchivedCartsQueryVariables
  >(GetUserArchivedCartsDocument, options)
}
export function useGetUserArchivedCartsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserArchivedCartsQuery,
    GetUserArchivedCartsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserArchivedCartsQuery,
    GetUserArchivedCartsQueryVariables
  >(GetUserArchivedCartsDocument, options)
}
export type GetUserArchivedCartsQueryHookResult = ReturnType<
  typeof useGetUserArchivedCartsQuery
>
export type GetUserArchivedCartsLazyQueryHookResult = ReturnType<
  typeof useGetUserArchivedCartsLazyQuery
>
export type GetUserArchivedCartsQueryResult = Apollo.QueryResult<
  GetUserArchivedCartsQuery,
  GetUserArchivedCartsQueryVariables
>
export const GetUserCartsDocument = gql`
  query GetUserCarts(
    $id: String!
    $currentShopInstanceKey: String = ""
    $limit: Int = 10
    $offset: Int = 0
  ) {
    cart_aggregate(
      where: {
        user: { id: { _eq: $id } }
        shop_key: { _neq: $currentShopInstanceKey }
        cart_items: {
          deleted_at: { _is_null: true }
          archived_at: { _is_null: true }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    carts: cart(
      limit: $limit
      offset: $offset
      where: {
        user: { id: { _eq: $id } }
        shop_key: { _neq: $currentShopInstanceKey }
        cart_items: {
          deleted_at: { _is_null: true }
          archived_at: { _is_null: true }
        }
      }
      order_by: { last_cart_item_created_at: desc_nulls_last }
    ) {
      ...cartData
      ...cartItemsData
    }
    any_cart_items: cart_item(
      where: {
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
        cart: { user_id: { _eq: $id } }
      }
      limit: 1
    ) {
      id
    }
  }
  ${CartDataFragmentDoc}
  ${CartItemsDataFragmentDoc}
`

/**
 * __useGetUserCartsQuery__
 *
 * To run a query within a React component, call `useGetUserCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCartsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      currentShopInstanceKey: // value for 'currentShopInstanceKey'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetUserCartsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCartsQuery,
    GetUserCartsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserCartsQuery, GetUserCartsQueryVariables>(
    GetUserCartsDocument,
    options
  )
}
export function useGetUserCartsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCartsQuery,
    GetUserCartsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserCartsQuery, GetUserCartsQueryVariables>(
    GetUserCartsDocument,
    options
  )
}
export type GetUserCartsQueryHookResult = ReturnType<
  typeof useGetUserCartsQuery
>
export type GetUserCartsLazyQueryHookResult = ReturnType<
  typeof useGetUserCartsLazyQuery
>
export type GetUserCartsQueryResult = Apollo.QueryResult<
  GetUserCartsQuery,
  GetUserCartsQueryVariables
>
export const GetUserCollectionsCollaboratingDocument = gql`
  query GetUserCollectionsCollaborating(
    $username: String
    $include_seo_fields: Boolean = false
    $itemLimit: Int = 3
    $offset: Int = 0
    $limit: Int = 15
  ) {
    collection_aggregate(
      where: {
        collection_collaborators: { user: { username: { _eq: $username } } }
      }
    ) {
      aggregate {
        count
      }
    }
    collections: collection(
      where: {
        collection_collaborators: { user: { username: { _eq: $username } } }
      }
      limit: $limit
      offset: $offset
      order_by: { cart_item_max_updated_at: desc_nulls_last }
    ) {
      ...userCollectionsDataForProfilePageData
    }
  }
  ${UserCollectionsDataForProfilePageDataFragmentDoc}
`

/**
 * __useGetUserCollectionsCollaboratingQuery__
 *
 * To run a query within a React component, call `useGetUserCollectionsCollaboratingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCollectionsCollaboratingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCollectionsCollaboratingQuery({
 *   variables: {
 *      username: // value for 'username'
 *      include_seo_fields: // value for 'include_seo_fields'
 *      itemLimit: // value for 'itemLimit'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserCollectionsCollaboratingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserCollectionsCollaboratingQuery,
    GetUserCollectionsCollaboratingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCollectionsCollaboratingQuery,
    GetUserCollectionsCollaboratingQueryVariables
  >(GetUserCollectionsCollaboratingDocument, options)
}
export function useGetUserCollectionsCollaboratingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCollectionsCollaboratingQuery,
    GetUserCollectionsCollaboratingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCollectionsCollaboratingQuery,
    GetUserCollectionsCollaboratingQueryVariables
  >(GetUserCollectionsCollaboratingDocument, options)
}
export type GetUserCollectionsCollaboratingQueryHookResult = ReturnType<
  typeof useGetUserCollectionsCollaboratingQuery
>
export type GetUserCollectionsCollaboratingLazyQueryHookResult = ReturnType<
  typeof useGetUserCollectionsCollaboratingLazyQuery
>
export type GetUserCollectionsCollaboratingQueryResult = Apollo.QueryResult<
  GetUserCollectionsCollaboratingQuery,
  GetUserCollectionsCollaboratingQueryVariables
>
export const GetUserCollectionsDataForProfilePageDocument = gql`
  query GetUserCollectionsDataForProfilePage(
    $username: String
    $publishedOrPublic: Boolean = true
    $itemLimit: Int = 3
    $offset: Int = 0
    $limit: Int = 15
  ) {
    collection_aggregate(
      where: {
        all: { _eq: false }
        computed_is_visible: { _eq: $publishedOrPublic }
        user: { username: { _eq: $username } }
      }
    ) {
      aggregate {
        count
      }
    }
    collections: collection(
      where: {
        all: { _eq: false }
        computed_is_visible: { _eq: $publishedOrPublic }
        user: { username: { _eq: $username } }
      }
      offset: $offset
      limit: $limit
      order_by: [{ order: asc }, { cart_item_max_updated_at: desc_nulls_last }]
    ) {
      ...userCollectionsDataForProfilePageData
    }
    landing_page_collections: collection(
      where: {
        collection_type: { _eq: landing_page }
        user: { username: { _eq: $username } }
      }
      order_by: [
        { cart_item_max_updated_at: desc_nulls_last }
        { updated_at: desc_nulls_last }
      ]
    ) {
      ...userCollectionsDataForProfilePageData
    }
  }
  ${UserCollectionsDataForProfilePageDataFragmentDoc}
`

/**
 * __useGetUserCollectionsDataForProfilePageQuery__
 *
 * To run a query within a React component, call `useGetUserCollectionsDataForProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCollectionsDataForProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCollectionsDataForProfilePageQuery({
 *   variables: {
 *      username: // value for 'username'
 *      publishedOrPublic: // value for 'publishedOrPublic'
 *      itemLimit: // value for 'itemLimit'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserCollectionsDataForProfilePageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserCollectionsDataForProfilePageQuery,
    GetUserCollectionsDataForProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCollectionsDataForProfilePageQuery,
    GetUserCollectionsDataForProfilePageQueryVariables
  >(GetUserCollectionsDataForProfilePageDocument, options)
}
export function useGetUserCollectionsDataForProfilePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCollectionsDataForProfilePageQuery,
    GetUserCollectionsDataForProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCollectionsDataForProfilePageQuery,
    GetUserCollectionsDataForProfilePageQueryVariables
  >(GetUserCollectionsDataForProfilePageDocument, options)
}
export type GetUserCollectionsDataForProfilePageQueryHookResult = ReturnType<
  typeof useGetUserCollectionsDataForProfilePageQuery
>
export type GetUserCollectionsDataForProfilePageLazyQueryHookResult =
  ReturnType<typeof useGetUserCollectionsDataForProfilePageLazyQuery>
export type GetUserCollectionsDataForProfilePageQueryResult =
  Apollo.QueryResult<
    GetUserCollectionsDataForProfilePageQuery,
    GetUserCollectionsDataForProfilePageQueryVariables
  >
export const GetUserCollectionsFollowingDocument = gql`
  query GetUserCollectionsFollowing(
    $username: String
    $offset: Int = 0
    $limit: Int = 50
    $itemLimit: Int = 3
    $include_seo_fields: Boolean = false
  ) {
    user_collections_followed: user_items_followed(
      where: {
        user: { username: { _eq: $username } }
        followable_item: { collection: {} }
      }
    ) {
      followable_item {
        collection {
          ...collectionAndItemsData
        }
      }
    }
    user_collections_followed_aggregate: user_items_followed_aggregate(
      where: {
        user: { username: { _eq: $username } }
        followable_item: { collection: {} }
      }
    ) {
      aggregate {
        count
      }
    }
  }
  ${CollectionAndItemsDataFragmentDoc}
`

/**
 * __useGetUserCollectionsFollowingQuery__
 *
 * To run a query within a React component, call `useGetUserCollectionsFollowingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCollectionsFollowingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCollectionsFollowingQuery({
 *   variables: {
 *      username: // value for 'username'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      itemLimit: // value for 'itemLimit'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useGetUserCollectionsFollowingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserCollectionsFollowingQuery,
    GetUserCollectionsFollowingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCollectionsFollowingQuery,
    GetUserCollectionsFollowingQueryVariables
  >(GetUserCollectionsFollowingDocument, options)
}
export function useGetUserCollectionsFollowingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCollectionsFollowingQuery,
    GetUserCollectionsFollowingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCollectionsFollowingQuery,
    GetUserCollectionsFollowingQueryVariables
  >(GetUserCollectionsFollowingDocument, options)
}
export type GetUserCollectionsFollowingQueryHookResult = ReturnType<
  typeof useGetUserCollectionsFollowingQuery
>
export type GetUserCollectionsFollowingLazyQueryHookResult = ReturnType<
  typeof useGetUserCollectionsFollowingLazyQuery
>
export type GetUserCollectionsFollowingQueryResult = Apollo.QueryResult<
  GetUserCollectionsFollowingQuery,
  GetUserCollectionsFollowingQueryVariables
>
export const GetUserCreatedCollectionsDocument = gql`
  query GetUserCreatedCollections(
    $userId: String
    $limit: Int = 30
    $offset: Int = 0
    $withOwner: Boolean = false
    $collectionItemsLimit: Int = 1
    $searchQuery: String = "%%"
  ) {
    collections: collection(
      where: {
        _or: [
          { user_id: { _eq: $userId } }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
        searchable_name: { _ilike: $searchQuery }
        all: { _eq: false }
      }
      order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
      limit: $limit
      offset: $offset
    ) {
      id
      name
      slug
      state
      paid
      exclusive
      private
      cover_image
      cover_image_cached
      has_collaborators
      user @include(if: $withOwner) {
        id
        picture
      }
      collection_items_aggregate(
        where: { cart_item: { deleted_at: { _is_null: true } } }
      ) {
        aggregate {
          count
        }
      }
      collection_items(
        where: { cart_item: { deleted_at: { _is_null: true } } }
        order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
        limit: $collectionItemsLimit
      ) {
        id
        cart_item {
          id
          item_image_override
          item {
            id
            image
            image_highres
            cached_image
          }
        }
      }
      ...collectionCollaboratorsData
    }
    collection_aggregate(
      where: {
        all: { _eq: false }
        searchable_name: { _ilike: $searchQuery }
        _or: [
          { user_id: { _eq: $userId } }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
  ${CollectionCollaboratorsDataFragmentDoc}
`

/**
 * __useGetUserCreatedCollectionsQuery__
 *
 * To run a query within a React component, call `useGetUserCreatedCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCreatedCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCreatedCollectionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      withOwner: // value for 'withOwner'
 *      collectionItemsLimit: // value for 'collectionItemsLimit'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useGetUserCreatedCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserCreatedCollectionsQuery,
    GetUserCreatedCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCreatedCollectionsQuery,
    GetUserCreatedCollectionsQueryVariables
  >(GetUserCreatedCollectionsDocument, options)
}
export function useGetUserCreatedCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCreatedCollectionsQuery,
    GetUserCreatedCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCreatedCollectionsQuery,
    GetUserCreatedCollectionsQueryVariables
  >(GetUserCreatedCollectionsDocument, options)
}
export type GetUserCreatedCollectionsQueryHookResult = ReturnType<
  typeof useGetUserCreatedCollectionsQuery
>
export type GetUserCreatedCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetUserCreatedCollectionsLazyQuery
>
export type GetUserCreatedCollectionsQueryResult = Apollo.QueryResult<
  GetUserCreatedCollectionsQuery,
  GetUserCreatedCollectionsQueryVariables
>
export const GetUserDataForProfilePageDocument = gql`
  query GetUserDataForProfilePage(
    $username: String
    $collectionsLimit: Int = 15
  ) {
    user(where: { username: { _eq: $username } }) {
      ...basicUserData
      cover_image
      bio
      bio_link
      links {
        id
        url
        link_type
        type {
          value
          description
        }
      }
      ogimage_fingerprint
      has_claimed_username_computed
      has_set_name_computed
      has_picture_set
      anonymous
      carts_aggregate {
        aggregate {
          count
        }
      }
      collections_collaborating_aggregate {
        aggregate {
          count
        }
      }
      collections_published_public_aggregate: collections_aggregate(
        where: { all: { _eq: false }, computed_is_visible: { _eq: true } }
      ) {
        aggregate {
          count
        }
      }
      collections_draft_private_aggregate: collections_aggregate(
        where: { all: { _eq: false }, computed_is_visible: { _eq: false } }
      ) {
        aggregate {
          count
        }
      }
    }
    collections: collection(
      where: {
        all: { _eq: false }
        computed_is_visible: { _eq: true }
        cart_items_count: { _gt: 0 }
        user: { username: { _eq: $username } }
        collection_items: {
          cart_item: { item: { has_broken_image: { _eq: false } } }
        }
      }
      order_by: [{ order: asc }, { cart_item_max_updated_at: desc_nulls_last }]
      limit: $collectionsLimit
    ) {
      ...userCollectionsDataForProfilePageData
    }
  }
  ${BasicUserDataFragmentDoc}
  ${UserCollectionsDataForProfilePageDataFragmentDoc}
`

/**
 * __useGetUserDataForProfilePageQuery__
 *
 * To run a query within a React component, call `useGetUserDataForProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDataForProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDataForProfilePageQuery({
 *   variables: {
 *      username: // value for 'username'
 *      collectionsLimit: // value for 'collectionsLimit'
 *   },
 * });
 */
export function useGetUserDataForProfilePageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserDataForProfilePageQuery,
    GetUserDataForProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserDataForProfilePageQuery,
    GetUserDataForProfilePageQueryVariables
  >(GetUserDataForProfilePageDocument, options)
}
export function useGetUserDataForProfilePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDataForProfilePageQuery,
    GetUserDataForProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserDataForProfilePageQuery,
    GetUserDataForProfilePageQueryVariables
  >(GetUserDataForProfilePageDocument, options)
}
export type GetUserDataForProfilePageQueryHookResult = ReturnType<
  typeof useGetUserDataForProfilePageQuery
>
export type GetUserDataForProfilePageLazyQueryHookResult = ReturnType<
  typeof useGetUserDataForProfilePageLazyQuery
>
export type GetUserDataForProfilePageQueryResult = Apollo.QueryResult<
  GetUserDataForProfilePageQuery,
  GetUserDataForProfilePageQueryVariables
>
export const GetUserFlagsDocument = gql`
  query GetUserFlags($user_id: String!) {
    user_flags_by_pk(user_id: $user_id) {
      opted_into_cart_onboarding
      last_cart_onboarding
    }
  }
`

/**
 * __useGetUserFlagsQuery__
 *
 * To run a query within a React component, call `useGetUserFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFlagsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserFlagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserFlagsQuery,
    GetUserFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserFlagsQuery, GetUserFlagsQueryVariables>(
    GetUserFlagsDocument,
    options
  )
}
export function useGetUserFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFlagsQuery,
    GetUserFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserFlagsQuery, GetUserFlagsQueryVariables>(
    GetUserFlagsDocument,
    options
  )
}
export type GetUserFlagsQueryHookResult = ReturnType<
  typeof useGetUserFlagsQuery
>
export type GetUserFlagsLazyQueryHookResult = ReturnType<
  typeof useGetUserFlagsLazyQuery
>
export type GetUserFlagsQueryResult = Apollo.QueryResult<
  GetUserFlagsQuery,
  GetUserFlagsQueryVariables
>
export const GetUserForMixpanelDocument = gql`
  query GetUserForMixpanel($id: String!) {
    user: user_by_pk(id: $id) {
      id
      email
      phone
      anonymous
      installed_at
      name
      onboarded
      orphaned_at
      orphaned_by
      picture
      role
      username
      has_claimed_username_computed
      extension_version
      ios_version
      safari_extension_version
      first_app_install
      created_at
      updated_at
      internal_account
      metadata
    }
  }
`

/**
 * __useGetUserForMixpanelQuery__
 *
 * To run a query within a React component, call `useGetUserForMixpanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForMixpanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForMixpanelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserForMixpanelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserForMixpanelQuery,
    GetUserForMixpanelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserForMixpanelQuery,
    GetUserForMixpanelQueryVariables
  >(GetUserForMixpanelDocument, options)
}
export function useGetUserForMixpanelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserForMixpanelQuery,
    GetUserForMixpanelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserForMixpanelQuery,
    GetUserForMixpanelQueryVariables
  >(GetUserForMixpanelDocument, options)
}
export type GetUserForMixpanelQueryHookResult = ReturnType<
  typeof useGetUserForMixpanelQuery
>
export type GetUserForMixpanelLazyQueryHookResult = ReturnType<
  typeof useGetUserForMixpanelLazyQuery
>
export type GetUserForMixpanelQueryResult = Apollo.QueryResult<
  GetUserForMixpanelQuery,
  GetUserForMixpanelQueryVariables
>
export const GetUserForSplitioDocument = gql`
  query GetUserForSplitio($id: String!) {
    user: user_by_pk(id: $id) {
      id
      email
      installed_at
      onboarded
      role
      username
      extension_version
      created_at
      updated_at
      internal_account
    }
  }
`

/**
 * __useGetUserForSplitioQuery__
 *
 * To run a query within a React component, call `useGetUserForSplitioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForSplitioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForSplitioQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserForSplitioQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserForSplitioQuery,
    GetUserForSplitioQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserForSplitioQuery,
    GetUserForSplitioQueryVariables
  >(GetUserForSplitioDocument, options)
}
export function useGetUserForSplitioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserForSplitioQuery,
    GetUserForSplitioQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserForSplitioQuery,
    GetUserForSplitioQueryVariables
  >(GetUserForSplitioDocument, options)
}
export type GetUserForSplitioQueryHookResult = ReturnType<
  typeof useGetUserForSplitioQuery
>
export type GetUserForSplitioLazyQueryHookResult = ReturnType<
  typeof useGetUserForSplitioLazyQuery
>
export type GetUserForSplitioQueryResult = Apollo.QueryResult<
  GetUserForSplitioQuery,
  GetUserForSplitioQueryVariables
>
export const GetUserLandingPageCollectionsDocument = gql`
  query GetUserLandingPageCollections($username: String) {
    landing_page_collections: collection(
      where: {
        collection_type: { _eq: landing_page }
        user: { username: { _eq: $username } }
      }
      order_by: [
        { cart_item_max_updated_at: desc_nulls_last }
        { updated_at: desc_nulls_last }
      ]
    ) {
      ...userCollectionsDataForProfilePageData
    }
  }
  ${UserCollectionsDataForProfilePageDataFragmentDoc}
`

/**
 * __useGetUserLandingPageCollectionsQuery__
 *
 * To run a query within a React component, call `useGetUserLandingPageCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLandingPageCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLandingPageCollectionsQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserLandingPageCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserLandingPageCollectionsQuery,
    GetUserLandingPageCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserLandingPageCollectionsQuery,
    GetUserLandingPageCollectionsQueryVariables
  >(GetUserLandingPageCollectionsDocument, options)
}
export function useGetUserLandingPageCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserLandingPageCollectionsQuery,
    GetUserLandingPageCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserLandingPageCollectionsQuery,
    GetUserLandingPageCollectionsQueryVariables
  >(GetUserLandingPageCollectionsDocument, options)
}
export type GetUserLandingPageCollectionsQueryHookResult = ReturnType<
  typeof useGetUserLandingPageCollectionsQuery
>
export type GetUserLandingPageCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetUserLandingPageCollectionsLazyQuery
>
export type GetUserLandingPageCollectionsQueryResult = Apollo.QueryResult<
  GetUserLandingPageCollectionsQuery,
  GetUserLandingPageCollectionsQueryVariables
>
export const GetUserMobileBookmarksDocument = gql`
  query GetUserMobileBookmarks($user_id: String!, $ids: [uuid!]) {
    mobile_bookmark_workflows: workflow(
      where: { user_id: { _eq: $user_id }, id: { _in: $ids } }
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      data
      status
    }
  }
`

/**
 * __useGetUserMobileBookmarksQuery__
 *
 * To run a query within a React component, call `useGetUserMobileBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMobileBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMobileBookmarksQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetUserMobileBookmarksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserMobileBookmarksQuery,
    GetUserMobileBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserMobileBookmarksQuery,
    GetUserMobileBookmarksQueryVariables
  >(GetUserMobileBookmarksDocument, options)
}
export function useGetUserMobileBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserMobileBookmarksQuery,
    GetUserMobileBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserMobileBookmarksQuery,
    GetUserMobileBookmarksQueryVariables
  >(GetUserMobileBookmarksDocument, options)
}
export type GetUserMobileBookmarksQueryHookResult = ReturnType<
  typeof useGetUserMobileBookmarksQuery
>
export type GetUserMobileBookmarksLazyQueryHookResult = ReturnType<
  typeof useGetUserMobileBookmarksLazyQuery
>
export type GetUserMobileBookmarksQueryResult = Apollo.QueryResult<
  GetUserMobileBookmarksQuery,
  GetUserMobileBookmarksQueryVariables
>
export const UserOnboardedAndSavedCartsAggregateDocument = gql`
  query UserOnboardedAndSavedCartsAggregate($id: String!) {
    cart_aggregate(
      where: {
        user_id: { _eq: $id }
        cart_items: { source: { _neq: "onboarding" } }
      }
    ) {
      aggregate {
        count
      }
    }
    user: user_by_pk(id: $id) {
      onboarded
    }
  }
`

/**
 * __useUserOnboardedAndSavedCartsAggregateQuery__
 *
 * To run a query within a React component, call `useUserOnboardedAndSavedCartsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOnboardedAndSavedCartsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOnboardedAndSavedCartsAggregateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserOnboardedAndSavedCartsAggregateQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserOnboardedAndSavedCartsAggregateQuery,
    UserOnboardedAndSavedCartsAggregateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserOnboardedAndSavedCartsAggregateQuery,
    UserOnboardedAndSavedCartsAggregateQueryVariables
  >(UserOnboardedAndSavedCartsAggregateDocument, options)
}
export function useUserOnboardedAndSavedCartsAggregateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserOnboardedAndSavedCartsAggregateQuery,
    UserOnboardedAndSavedCartsAggregateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserOnboardedAndSavedCartsAggregateQuery,
    UserOnboardedAndSavedCartsAggregateQueryVariables
  >(UserOnboardedAndSavedCartsAggregateDocument, options)
}
export type UserOnboardedAndSavedCartsAggregateQueryHookResult = ReturnType<
  typeof useUserOnboardedAndSavedCartsAggregateQuery
>
export type UserOnboardedAndSavedCartsAggregateLazyQueryHookResult = ReturnType<
  typeof useUserOnboardedAndSavedCartsAggregateLazyQuery
>
export type UserOnboardedAndSavedCartsAggregateQueryResult = Apollo.QueryResult<
  UserOnboardedAndSavedCartsAggregateQuery,
  UserOnboardedAndSavedCartsAggregateQueryVariables
>
export const GetUserOnboardingSourceDocument = gql`
  query GetUserOnboardingSource($userId: String!) {
    user_onboarding_source(where: { user_id: { _eq: $userId } }) {
      id
      onboarding_source
      other_source
    }
  }
`

/**
 * __useGetUserOnboardingSourceQuery__
 *
 * To run a query within a React component, call `useGetUserOnboardingSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOnboardingSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOnboardingSourceQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserOnboardingSourceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserOnboardingSourceQuery,
    GetUserOnboardingSourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserOnboardingSourceQuery,
    GetUserOnboardingSourceQueryVariables
  >(GetUserOnboardingSourceDocument, options)
}
export function useGetUserOnboardingSourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserOnboardingSourceQuery,
    GetUserOnboardingSourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserOnboardingSourceQuery,
    GetUserOnboardingSourceQueryVariables
  >(GetUserOnboardingSourceDocument, options)
}
export type GetUserOnboardingSourceQueryHookResult = ReturnType<
  typeof useGetUserOnboardingSourceQuery
>
export type GetUserOnboardingSourceLazyQueryHookResult = ReturnType<
  typeof useGetUserOnboardingSourceLazyQuery
>
export type GetUserOnboardingSourceQueryResult = Apollo.QueryResult<
  GetUserOnboardingSourceQuery,
  GetUserOnboardingSourceQueryVariables
>
export const GetUserPendingMobileBookmarksDocument = gql`
  query GetUserPendingMobileBookmarks($user_id: String!) {
    mobile_pending_bookmark_workflows: workflow(
      where: {
        status: { _eq: pending }
        type: { _eq: mobile_bookmark }
        user_id: { _eq: $user_id }
      }
      order_by: { created_at: desc_nulls_last }
      limit: 5
    ) {
      id
      data
      status
    }
  }
`

/**
 * __useGetUserPendingMobileBookmarksQuery__
 *
 * To run a query within a React component, call `useGetUserPendingMobileBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPendingMobileBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPendingMobileBookmarksQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserPendingMobileBookmarksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserPendingMobileBookmarksQuery,
    GetUserPendingMobileBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserPendingMobileBookmarksQuery,
    GetUserPendingMobileBookmarksQueryVariables
  >(GetUserPendingMobileBookmarksDocument, options)
}
export function useGetUserPendingMobileBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPendingMobileBookmarksQuery,
    GetUserPendingMobileBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserPendingMobileBookmarksQuery,
    GetUserPendingMobileBookmarksQueryVariables
  >(GetUserPendingMobileBookmarksDocument, options)
}
export type GetUserPendingMobileBookmarksQueryHookResult = ReturnType<
  typeof useGetUserPendingMobileBookmarksQuery
>
export type GetUserPendingMobileBookmarksLazyQueryHookResult = ReturnType<
  typeof useGetUserPendingMobileBookmarksLazyQuery
>
export type GetUserPendingMobileBookmarksQueryResult = Apollo.QueryResult<
  GetUserPendingMobileBookmarksQuery,
  GetUserPendingMobileBookmarksQueryVariables
>
export const GetUserPointsMultiplierDocument = gql`
  query GetUserPointsMultiplier($id: String!) {
    user(where: { id: { _eq: $id } }) {
      id
      user_points_multiplier
    }
  }
`

/**
 * __useGetUserPointsMultiplierQuery__
 *
 * To run a query within a React component, call `useGetUserPointsMultiplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPointsMultiplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPointsMultiplierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserPointsMultiplierQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserPointsMultiplierQuery,
    GetUserPointsMultiplierQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserPointsMultiplierQuery,
    GetUserPointsMultiplierQueryVariables
  >(GetUserPointsMultiplierDocument, options)
}
export function useGetUserPointsMultiplierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPointsMultiplierQuery,
    GetUserPointsMultiplierQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserPointsMultiplierQuery,
    GetUserPointsMultiplierQueryVariables
  >(GetUserPointsMultiplierDocument, options)
}
export type GetUserPointsMultiplierQueryHookResult = ReturnType<
  typeof useGetUserPointsMultiplierQuery
>
export type GetUserPointsMultiplierLazyQueryHookResult = ReturnType<
  typeof useGetUserPointsMultiplierLazyQuery
>
export type GetUserPointsMultiplierQueryResult = Apollo.QueryResult<
  GetUserPointsMultiplierQuery,
  GetUserPointsMultiplierQueryVariables
>
export const GetUserSelectedCategoriesDocument = gql`
  query GetUserSelectedCategories($user_id: String!) {
    user_category(where: { user_id: { _eq: $user_id } }) {
      id
      category
    }
  }
`

/**
 * __useGetUserSelectedCategoriesQuery__
 *
 * To run a query within a React component, call `useGetUserSelectedCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSelectedCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSelectedCategoriesQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserSelectedCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserSelectedCategoriesQuery,
    GetUserSelectedCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserSelectedCategoriesQuery,
    GetUserSelectedCategoriesQueryVariables
  >(GetUserSelectedCategoriesDocument, options)
}
export function useGetUserSelectedCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSelectedCategoriesQuery,
    GetUserSelectedCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserSelectedCategoriesQuery,
    GetUserSelectedCategoriesQueryVariables
  >(GetUserSelectedCategoriesDocument, options)
}
export type GetUserSelectedCategoriesQueryHookResult = ReturnType<
  typeof useGetUserSelectedCategoriesQuery
>
export type GetUserSelectedCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetUserSelectedCategoriesLazyQuery
>
export type GetUserSelectedCategoriesQueryResult = Apollo.QueryResult<
  GetUserSelectedCategoriesQuery,
  GetUserSelectedCategoriesQueryVariables
>
export const GetUserRecentNotificationsDocument = gql`
  query GetUserRecentNotifications(
    $userId: String!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    push_notification(
      where: {
        notifiable_type: { _eq: "user" }
        notifiable_id: { _eq: $userId }
        active: { _eq: true }
      }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      payload
      active
      created_at
    }
  }
`

/**
 * __useGetUserRecentNotificationsQuery__
 *
 * To run a query within a React component, call `useGetUserRecentNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRecentNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRecentNotificationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetUserRecentNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserRecentNotificationsQuery,
    GetUserRecentNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserRecentNotificationsQuery,
    GetUserRecentNotificationsQueryVariables
  >(GetUserRecentNotificationsDocument, options)
}
export function useGetUserRecentNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRecentNotificationsQuery,
    GetUserRecentNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserRecentNotificationsQuery,
    GetUserRecentNotificationsQueryVariables
  >(GetUserRecentNotificationsDocument, options)
}
export type GetUserRecentNotificationsQueryHookResult = ReturnType<
  typeof useGetUserRecentNotificationsQuery
>
export type GetUserRecentNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetUserRecentNotificationsLazyQuery
>
export type GetUserRecentNotificationsQueryResult = Apollo.QueryResult<
  GetUserRecentNotificationsQuery,
  GetUserRecentNotificationsQueryVariables
>
export const GetWorkflowDetailsDocument = gql`
  query GetWorkflowDetails(
    $workflowId: uuid!
    $withParentWorkflow: Boolean = true
  ) {
    workflow_by_pk(id: $workflowId) {
      id
      data
      status
      status_details
      success_item_id
      success_item_type
      type
      user_id
      parent_workflow @include(if: $withParentWorkflow) {
        id
        data
        status
        status_details
        success_item_id
        success_item_type
        type
        user_id
      }
    }
  }
`

/**
 * __useGetWorkflowDetailsQuery__
 *
 * To run a query within a React component, call `useGetWorkflowDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowDetailsQuery({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      withParentWorkflow: // value for 'withParentWorkflow'
 *   },
 * });
 */
export function useGetWorkflowDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkflowDetailsQuery,
    GetWorkflowDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetWorkflowDetailsQuery,
    GetWorkflowDetailsQueryVariables
  >(GetWorkflowDetailsDocument, options)
}
export function useGetWorkflowDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkflowDetailsQuery,
    GetWorkflowDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetWorkflowDetailsQuery,
    GetWorkflowDetailsQueryVariables
  >(GetWorkflowDetailsDocument, options)
}
export type GetWorkflowDetailsQueryHookResult = ReturnType<
  typeof useGetWorkflowDetailsQuery
>
export type GetWorkflowDetailsLazyQueryHookResult = ReturnType<
  typeof useGetWorkflowDetailsLazyQuery
>
export type GetWorkflowDetailsQueryResult = Apollo.QueryResult<
  GetWorkflowDetailsQuery,
  GetWorkflowDetailsQueryVariables
>
export const InsertCollectionCollaboratorDocument = gql`
  mutation InsertCollectionCollaborator(
    $userId: String!
    $collectionId: uuid!
  ) {
    insert_collection_collaborator_one(
      object: { collection_id: $collectionId, user_id: $userId }
      on_conflict: {
        constraint: collection_collaborator_collection_id_user_id_key
        update_columns: []
      }
    ) {
      id
    }
  }
`
export type InsertCollectionCollaboratorMutationFn = Apollo.MutationFunction<
  InsertCollectionCollaboratorMutation,
  InsertCollectionCollaboratorMutationVariables
>

/**
 * __useInsertCollectionCollaboratorMutation__
 *
 * To run a mutation, you first call `useInsertCollectionCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCollectionCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCollectionCollaboratorMutation, { data, loading, error }] = useInsertCollectionCollaboratorMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useInsertCollectionCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertCollectionCollaboratorMutation,
    InsertCollectionCollaboratorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertCollectionCollaboratorMutation,
    InsertCollectionCollaboratorMutationVariables
  >(InsertCollectionCollaboratorDocument, options)
}
export type InsertCollectionCollaboratorMutationHookResult = ReturnType<
  typeof useInsertCollectionCollaboratorMutation
>
export type InsertCollectionCollaboratorMutationResult =
  Apollo.MutationResult<InsertCollectionCollaboratorMutation>
export type InsertCollectionCollaboratorMutationOptions =
  Apollo.BaseMutationOptions<
    InsertCollectionCollaboratorMutation,
    InsertCollectionCollaboratorMutationVariables
  >
export const InsertCollectionPromptNotificationDocument = gql`
  mutation InsertCollectionPromptNotification(
    $user_id: String!
    $show_before: timestamptz!
    $show_after: timestamptz!
    $payload: jsonb!
    $criteria: jsonb!
    $active: Boolean = false
  ) {
    insert_push_notification_one(
      object: {
        show_before: $show_before
        show_after: $show_after
        payload: $payload
        criteria: $criteria
        active: $active
        notifiable_type: "user"
        notifiable_id: $user_id
      }
    ) {
      id
    }
  }
`
export type InsertCollectionPromptNotificationMutationFn =
  Apollo.MutationFunction<
    InsertCollectionPromptNotificationMutation,
    InsertCollectionPromptNotificationMutationVariables
  >

/**
 * __useInsertCollectionPromptNotificationMutation__
 *
 * To run a mutation, you first call `useInsertCollectionPromptNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCollectionPromptNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCollectionPromptNotificationMutation, { data, loading, error }] = useInsertCollectionPromptNotificationMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      show_before: // value for 'show_before'
 *      show_after: // value for 'show_after'
 *      payload: // value for 'payload'
 *      criteria: // value for 'criteria'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useInsertCollectionPromptNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertCollectionPromptNotificationMutation,
    InsertCollectionPromptNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertCollectionPromptNotificationMutation,
    InsertCollectionPromptNotificationMutationVariables
  >(InsertCollectionPromptNotificationDocument, options)
}
export type InsertCollectionPromptNotificationMutationHookResult = ReturnType<
  typeof useInsertCollectionPromptNotificationMutation
>
export type InsertCollectionPromptNotificationMutationResult =
  Apollo.MutationResult<InsertCollectionPromptNotificationMutation>
export type InsertCollectionPromptNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    InsertCollectionPromptNotificationMutation,
    InsertCollectionPromptNotificationMutationVariables
  >
export const InsertDiscoveryFeedBacklogDocument = gql`
  mutation InsertDiscoveryFeedBacklog(
    $feed_item_id: uuid!
    $metadata: jsonb! = {}
  ) {
    insert_discovery_backlog_one(object: { feed_item_id: $feed_item_id }) {
      id
      feed_item {
        ...feedItemData
      }
    }
    update_feed_item_by_pk(
      pk_columns: { id: $feed_item_id }
      _set: { metadata: $metadata }
    ) {
      ...feedItemData
    }
  }
  ${FeedItemDataFragmentDoc}
`
export type InsertDiscoveryFeedBacklogMutationFn = Apollo.MutationFunction<
  InsertDiscoveryFeedBacklogMutation,
  InsertDiscoveryFeedBacklogMutationVariables
>

/**
 * __useInsertDiscoveryFeedBacklogMutation__
 *
 * To run a mutation, you first call `useInsertDiscoveryFeedBacklogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDiscoveryFeedBacklogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDiscoveryFeedBacklogMutation, { data, loading, error }] = useInsertDiscoveryFeedBacklogMutation({
 *   variables: {
 *      feed_item_id: // value for 'feed_item_id'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useInsertDiscoveryFeedBacklogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertDiscoveryFeedBacklogMutation,
    InsertDiscoveryFeedBacklogMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertDiscoveryFeedBacklogMutation,
    InsertDiscoveryFeedBacklogMutationVariables
  >(InsertDiscoveryFeedBacklogDocument, options)
}
export type InsertDiscoveryFeedBacklogMutationHookResult = ReturnType<
  typeof useInsertDiscoveryFeedBacklogMutation
>
export type InsertDiscoveryFeedBacklogMutationResult =
  Apollo.MutationResult<InsertDiscoveryFeedBacklogMutation>
export type InsertDiscoveryFeedBacklogMutationOptions =
  Apollo.BaseMutationOptions<
    InsertDiscoveryFeedBacklogMutation,
    InsertDiscoveryFeedBacklogMutationVariables
  >
export const InsertFeaturedShopDocument = gql`
  mutation InsertFeaturedShop($shop_key: String!) {
    insert_featured_shop_one(
      object: { shop_key: $shop_key }
      on_conflict: {
        constraint: featured_shop_shop_key_key
        update_columns: [shop_key]
      }
    ) {
      id
      feed_item {
        id
      }
      shop {
        id
        featured_shop {
          ...featuredShopAndFeaturedShopItems
        }
      }
    }
  }
  ${FeaturedShopAndFeaturedShopItemsFragmentDoc}
`
export type InsertFeaturedShopMutationFn = Apollo.MutationFunction<
  InsertFeaturedShopMutation,
  InsertFeaturedShopMutationVariables
>

/**
 * __useInsertFeaturedShopMutation__
 *
 * To run a mutation, you first call `useInsertFeaturedShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFeaturedShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFeaturedShopMutation, { data, loading, error }] = useInsertFeaturedShopMutation({
 *   variables: {
 *      shop_key: // value for 'shop_key'
 *   },
 * });
 */
export function useInsertFeaturedShopMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertFeaturedShopMutation,
    InsertFeaturedShopMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertFeaturedShopMutation,
    InsertFeaturedShopMutationVariables
  >(InsertFeaturedShopDocument, options)
}
export type InsertFeaturedShopMutationHookResult = ReturnType<
  typeof useInsertFeaturedShopMutation
>
export type InsertFeaturedShopMutationResult =
  Apollo.MutationResult<InsertFeaturedShopMutation>
export type InsertFeaturedShopMutationOptions = Apollo.BaseMutationOptions<
  InsertFeaturedShopMutation,
  InsertFeaturedShopMutationVariables
>
export const InsertIntoDiscoveryStagingDocument = gql`
  mutation InsertIntoDiscoveryStaging(
    $objects: [discovery_staging_insert_input!]!
  ) {
    insert_discovery_staging(
      objects: $objects
      on_conflict: {
        constraint: discovery_staging_sequential_id_key
        update_columns: []
      }
    ) {
      returning {
        id
        sequential_id
      }
    }
  }
`
export type InsertIntoDiscoveryStagingMutationFn = Apollo.MutationFunction<
  InsertIntoDiscoveryStagingMutation,
  InsertIntoDiscoveryStagingMutationVariables
>

/**
 * __useInsertIntoDiscoveryStagingMutation__
 *
 * To run a mutation, you first call `useInsertIntoDiscoveryStagingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertIntoDiscoveryStagingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertIntoDiscoveryStagingMutation, { data, loading, error }] = useInsertIntoDiscoveryStagingMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertIntoDiscoveryStagingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertIntoDiscoveryStagingMutation,
    InsertIntoDiscoveryStagingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertIntoDiscoveryStagingMutation,
    InsertIntoDiscoveryStagingMutationVariables
  >(InsertIntoDiscoveryStagingDocument, options)
}
export type InsertIntoDiscoveryStagingMutationHookResult = ReturnType<
  typeof useInsertIntoDiscoveryStagingMutation
>
export type InsertIntoDiscoveryStagingMutationResult =
  Apollo.MutationResult<InsertIntoDiscoveryStagingMutation>
export type InsertIntoDiscoveryStagingMutationOptions =
  Apollo.BaseMutationOptions<
    InsertIntoDiscoveryStagingMutation,
    InsertIntoDiscoveryStagingMutationVariables
  >
export const InsertNewBookmarkWorkflowDocument = gql`
  mutation InsertNewBookmarkWorkflow(
    $userId: String!
    $url: String!
    $title: String
    $image: String
  ) {
    insert_workflow_one(
      object: {
        user_id: $userId
        data: { url: $url, title: $title, image: $image }
        type: mobile_bookmark
      }
    ) {
      id
    }
  }
`
export type InsertNewBookmarkWorkflowMutationFn = Apollo.MutationFunction<
  InsertNewBookmarkWorkflowMutation,
  InsertNewBookmarkWorkflowMutationVariables
>

/**
 * __useInsertNewBookmarkWorkflowMutation__
 *
 * To run a mutation, you first call `useInsertNewBookmarkWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNewBookmarkWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNewBookmarkWorkflowMutation, { data, loading, error }] = useInsertNewBookmarkWorkflowMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      url: // value for 'url'
 *      title: // value for 'title'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useInsertNewBookmarkWorkflowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertNewBookmarkWorkflowMutation,
    InsertNewBookmarkWorkflowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertNewBookmarkWorkflowMutation,
    InsertNewBookmarkWorkflowMutationVariables
  >(InsertNewBookmarkWorkflowDocument, options)
}
export type InsertNewBookmarkWorkflowMutationHookResult = ReturnType<
  typeof useInsertNewBookmarkWorkflowMutation
>
export type InsertNewBookmarkWorkflowMutationResult =
  Apollo.MutationResult<InsertNewBookmarkWorkflowMutation>
export type InsertNewBookmarkWorkflowMutationOptions =
  Apollo.BaseMutationOptions<
    InsertNewBookmarkWorkflowMutation,
    InsertNewBookmarkWorkflowMutationVariables
  >
export const InsertContactInviteDocument = gql`
  mutation InsertContactInvite($contact_info: String!) {
    inviteStatus: insert_contact_invite_one(
      object: { contact_info: $contact_info }
      on_conflict: {
        constraint: contact_invite_requester_id_contact_info_key
        update_columns: []
      }
    ) {
      ...contactInviteData
    }
  }
  ${ContactInviteDataFragmentDoc}
`
export type InsertContactInviteMutationFn = Apollo.MutationFunction<
  InsertContactInviteMutation,
  InsertContactInviteMutationVariables
>

/**
 * __useInsertContactInviteMutation__
 *
 * To run a mutation, you first call `useInsertContactInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContactInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactInviteMutation, { data, loading, error }] = useInsertContactInviteMutation({
 *   variables: {
 *      contact_info: // value for 'contact_info'
 *   },
 * });
 */
export function useInsertContactInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertContactInviteMutation,
    InsertContactInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertContactInviteMutation,
    InsertContactInviteMutationVariables
  >(InsertContactInviteDocument, options)
}
export type InsertContactInviteMutationHookResult = ReturnType<
  typeof useInsertContactInviteMutation
>
export type InsertContactInviteMutationResult =
  Apollo.MutationResult<InsertContactInviteMutation>
export type InsertContactInviteMutationOptions = Apollo.BaseMutationOptions<
  InsertContactInviteMutation,
  InsertContactInviteMutationVariables
>
export const InsertNotificationStatusDocument = gql`
  mutation insertNotificationStatus($notification_id: uuid!) {
    notificationStatus: insert_push_notification_status_one(
      object: { notification_id: $notification_id }
      on_conflict: {
        constraint: push_notification_status_notification_id_user_id_key
        update_columns: []
      }
    ) {
      id
    }
  }
`
export type InsertNotificationStatusMutationFn = Apollo.MutationFunction<
  InsertNotificationStatusMutation,
  InsertNotificationStatusMutationVariables
>

/**
 * __useInsertNotificationStatusMutation__
 *
 * To run a mutation, you first call `useInsertNotificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNotificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNotificationStatusMutation, { data, loading, error }] = useInsertNotificationStatusMutation({
 *   variables: {
 *      notification_id: // value for 'notification_id'
 *   },
 * });
 */
export function useInsertNotificationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertNotificationStatusMutation,
    InsertNotificationStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertNotificationStatusMutation,
    InsertNotificationStatusMutationVariables
  >(InsertNotificationStatusDocument, options)
}
export type InsertNotificationStatusMutationHookResult = ReturnType<
  typeof useInsertNotificationStatusMutation
>
export type InsertNotificationStatusMutationResult =
  Apollo.MutationResult<InsertNotificationStatusMutation>
export type InsertNotificationStatusMutationOptions =
  Apollo.BaseMutationOptions<
    InsertNotificationStatusMutation,
    InsertNotificationStatusMutationVariables
  >
export const InsertPriceDropNotificationDocument = gql`
  mutation InsertPriceDropNotification(
    $user_id: String!
    $show_before: timestamptz!
    $show_after: timestamptz!
    $payload: jsonb!
    $criteria: jsonb!
    $active: Boolean = false
  ) {
    insert_push_notification_one(
      object: {
        show_before: $show_before
        show_after: $show_after
        payload: $payload
        criteria: $criteria
        active: $active
        notifiable_type: "user"
        notifiable_id: $user_id
      }
    ) {
      id
    }
  }
`
export type InsertPriceDropNotificationMutationFn = Apollo.MutationFunction<
  InsertPriceDropNotificationMutation,
  InsertPriceDropNotificationMutationVariables
>

/**
 * __useInsertPriceDropNotificationMutation__
 *
 * To run a mutation, you first call `useInsertPriceDropNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPriceDropNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPriceDropNotificationMutation, { data, loading, error }] = useInsertPriceDropNotificationMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      show_before: // value for 'show_before'
 *      show_after: // value for 'show_after'
 *      payload: // value for 'payload'
 *      criteria: // value for 'criteria'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useInsertPriceDropNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertPriceDropNotificationMutation,
    InsertPriceDropNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertPriceDropNotificationMutation,
    InsertPriceDropNotificationMutationVariables
  >(InsertPriceDropNotificationDocument, options)
}
export type InsertPriceDropNotificationMutationHookResult = ReturnType<
  typeof useInsertPriceDropNotificationMutation
>
export type InsertPriceDropNotificationMutationResult =
  Apollo.MutationResult<InsertPriceDropNotificationMutation>
export type InsertPriceDropNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    InsertPriceDropNotificationMutation,
    InsertPriceDropNotificationMutationVariables
  >
export const InsertShopAffiliateDocument = gql`
  mutation InsertShopAffiliate(
    $shopAffiliates: [shop_affiliate_insert_input!]!
  ) {
    insert_shop_affiliate(
      objects: $shopAffiliates
      on_conflict: {
        constraint: shop_affiliate_shop_key_affiliate_key
        update_columns: []
      }
    ) {
      returning {
        id
      }
    }
  }
`
export type InsertShopAffiliateMutationFn = Apollo.MutationFunction<
  InsertShopAffiliateMutation,
  InsertShopAffiliateMutationVariables
>

/**
 * __useInsertShopAffiliateMutation__
 *
 * To run a mutation, you first call `useInsertShopAffiliateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertShopAffiliateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertShopAffiliateMutation, { data, loading, error }] = useInsertShopAffiliateMutation({
 *   variables: {
 *      shopAffiliates: // value for 'shopAffiliates'
 *   },
 * });
 */
export function useInsertShopAffiliateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertShopAffiliateMutation,
    InsertShopAffiliateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertShopAffiliateMutation,
    InsertShopAffiliateMutationVariables
  >(InsertShopAffiliateDocument, options)
}
export type InsertShopAffiliateMutationHookResult = ReturnType<
  typeof useInsertShopAffiliateMutation
>
export type InsertShopAffiliateMutationResult =
  Apollo.MutationResult<InsertShopAffiliateMutation>
export type InsertShopAffiliateMutationOptions = Apollo.BaseMutationOptions<
  InsertShopAffiliateMutation,
  InsertShopAffiliateMutationVariables
>
export const UpsertUserCategoriesDocument = gql`
  mutation UpsertUserCategories(
    $user_id: String!
    $categories: [user_category_insert_input!]!
  ) {
    delete_user_category(where: { user_id: { _eq: $user_id } }) {
      affected_rows
    }
    insert_user_category(
      objects: $categories
      on_conflict: {
        constraint: user_category_user_id_category_key
        update_columns: []
      }
    ) {
      returning {
        id
      }
    }
  }
`
export type UpsertUserCategoriesMutationFn = Apollo.MutationFunction<
  UpsertUserCategoriesMutation,
  UpsertUserCategoriesMutationVariables
>

/**
 * __useUpsertUserCategoriesMutation__
 *
 * To run a mutation, you first call `useUpsertUserCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserCategoriesMutation, { data, loading, error }] = useUpsertUserCategoriesMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useUpsertUserCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserCategoriesMutation,
    UpsertUserCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertUserCategoriesMutation,
    UpsertUserCategoriesMutationVariables
  >(UpsertUserCategoriesDocument, options)
}
export type UpsertUserCategoriesMutationHookResult = ReturnType<
  typeof useUpsertUserCategoriesMutation
>
export type UpsertUserCategoriesMutationResult =
  Apollo.MutationResult<UpsertUserCategoriesMutation>
export type UpsertUserCategoriesMutationOptions = Apollo.BaseMutationOptions<
  UpsertUserCategoriesMutation,
  UpsertUserCategoriesMutationVariables
>
export const InviteCollaboratorDocument = gql`
  mutation InviteCollaborator($collection_id: uuid!, $user_id: String) {
    inviteCollaborator(
      invite: { user_id: $user_id, collection_id: $collection_id }
    ) {
      collection_invite {
        ...collectionInvite
      }
    }
  }
  ${CollectionInviteFragmentDoc}
`
export type InviteCollaboratorMutationFn = Apollo.MutationFunction<
  InviteCollaboratorMutation,
  InviteCollaboratorMutationVariables
>

/**
 * __useInviteCollaboratorMutation__
 *
 * To run a mutation, you first call `useInviteCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteCollaboratorMutation, { data, loading, error }] = useInviteCollaboratorMutation({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useInviteCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteCollaboratorMutation,
    InviteCollaboratorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InviteCollaboratorMutation,
    InviteCollaboratorMutationVariables
  >(InviteCollaboratorDocument, options)
}
export type InviteCollaboratorMutationHookResult = ReturnType<
  typeof useInviteCollaboratorMutation
>
export type InviteCollaboratorMutationResult =
  Apollo.MutationResult<InviteCollaboratorMutation>
export type InviteCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  InviteCollaboratorMutation,
  InviteCollaboratorMutationVariables
>
export const InviteCollectionCollaboratorOneDocument = gql`
  mutation InviteCollectionCollaboratorOne(
    $user_id: String
    $email: String
    $collection_id: uuid!
  ) {
    insert_collection_invite_one(
      object: {
        invitee_id: $user_id
        invitee_email: $email
        collection_id: $collection_id
      }
      on_conflict: {
        constraint: collection_invite_collection_id_invitee_id_key
        update_columns: []
      }
    ) {
      id
    }
  }
`
export type InviteCollectionCollaboratorOneMutationFn = Apollo.MutationFunction<
  InviteCollectionCollaboratorOneMutation,
  InviteCollectionCollaboratorOneMutationVariables
>

/**
 * __useInviteCollectionCollaboratorOneMutation__
 *
 * To run a mutation, you first call `useInviteCollectionCollaboratorOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteCollectionCollaboratorOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteCollectionCollaboratorOneMutation, { data, loading, error }] = useInviteCollectionCollaboratorOneMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      email: // value for 'email'
 *      collection_id: // value for 'collection_id'
 *   },
 * });
 */
export function useInviteCollectionCollaboratorOneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteCollectionCollaboratorOneMutation,
    InviteCollectionCollaboratorOneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InviteCollectionCollaboratorOneMutation,
    InviteCollectionCollaboratorOneMutationVariables
  >(InviteCollectionCollaboratorOneDocument, options)
}
export type InviteCollectionCollaboratorOneMutationHookResult = ReturnType<
  typeof useInviteCollectionCollaboratorOneMutation
>
export type InviteCollectionCollaboratorOneMutationResult =
  Apollo.MutationResult<InviteCollectionCollaboratorOneMutation>
export type InviteCollectionCollaboratorOneMutationOptions =
  Apollo.BaseMutationOptions<
    InviteCollectionCollaboratorOneMutation,
    InviteCollectionCollaboratorOneMutationVariables
  >
export const InviteCollectionCollaboratorDocument = gql`
  mutation InviteCollectionCollaborator(
    $objects: [collection_invite_insert_input!]!
    $is_admin: Boolean = false
  ) {
    insert_collection_invite(
      objects: $objects
      on_conflict: {
        constraint: collection_invite_collection_id_invitee_id_key
        update_columns: []
      }
    ) {
      affected_rows
      returning {
        ...collectionInvite
      }
    }
  }
  ${CollectionInviteFragmentDoc}
`
export type InviteCollectionCollaboratorMutationFn = Apollo.MutationFunction<
  InviteCollectionCollaboratorMutation,
  InviteCollectionCollaboratorMutationVariables
>

/**
 * __useInviteCollectionCollaboratorMutation__
 *
 * To run a mutation, you first call `useInviteCollectionCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteCollectionCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteCollectionCollaboratorMutation, { data, loading, error }] = useInviteCollectionCollaboratorMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      is_admin: // value for 'is_admin'
 *   },
 * });
 */
export function useInviteCollectionCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteCollectionCollaboratorMutation,
    InviteCollectionCollaboratorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InviteCollectionCollaboratorMutation,
    InviteCollectionCollaboratorMutationVariables
  >(InviteCollectionCollaboratorDocument, options)
}
export type InviteCollectionCollaboratorMutationHookResult = ReturnType<
  typeof useInviteCollectionCollaboratorMutation
>
export type InviteCollectionCollaboratorMutationResult =
  Apollo.MutationResult<InviteCollectionCollaboratorMutation>
export type InviteCollectionCollaboratorMutationOptions =
  Apollo.BaseMutationOptions<
    InviteCollectionCollaboratorMutation,
    InviteCollectionCollaboratorMutationVariables
  >
export const LikeFeedItemDocument = gql`
  mutation LikeFeedItem($feed_item_id: uuid!, $value: Int! = 1) {
    feedItemLike: insert_feed_item_like_one(
      object: { feed_item_id: $feed_item_id, value: $value }
      on_conflict: { constraint: feed_item_like_pkey, update_columns: [value] }
    ) {
      id
    }
  }
`
export type LikeFeedItemMutationFn = Apollo.MutationFunction<
  LikeFeedItemMutation,
  LikeFeedItemMutationVariables
>

/**
 * __useLikeFeedItemMutation__
 *
 * To run a mutation, you first call `useLikeFeedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeFeedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeFeedItemMutation, { data, loading, error }] = useLikeFeedItemMutation({
 *   variables: {
 *      feed_item_id: // value for 'feed_item_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useLikeFeedItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LikeFeedItemMutation,
    LikeFeedItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LikeFeedItemMutation,
    LikeFeedItemMutationVariables
  >(LikeFeedItemDocument, options)
}
export type LikeFeedItemMutationHookResult = ReturnType<
  typeof useLikeFeedItemMutation
>
export type LikeFeedItemMutationResult =
  Apollo.MutationResult<LikeFeedItemMutation>
export type LikeFeedItemMutationOptions = Apollo.BaseMutationOptions<
  LikeFeedItemMutation,
  LikeFeedItemMutationVariables
>
export const LikeFeedItemActionDocument = gql`
  mutation LikeFeedItemAction($input: FeedItemLikeInput!) {
    likeFeedItem(likeData: $input) {
      feed_item_like {
        id
        feed_item {
          ...feedItemData
        }
      }
    }
  }
  ${FeedItemDataFragmentDoc}
`
export type LikeFeedItemActionMutationFn = Apollo.MutationFunction<
  LikeFeedItemActionMutation,
  LikeFeedItemActionMutationVariables
>

/**
 * __useLikeFeedItemActionMutation__
 *
 * To run a mutation, you first call `useLikeFeedItemActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeFeedItemActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeFeedItemActionMutation, { data, loading, error }] = useLikeFeedItemActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLikeFeedItemActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LikeFeedItemActionMutation,
    LikeFeedItemActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LikeFeedItemActionMutation,
    LikeFeedItemActionMutationVariables
  >(LikeFeedItemActionDocument, options)
}
export type LikeFeedItemActionMutationHookResult = ReturnType<
  typeof useLikeFeedItemActionMutation
>
export type LikeFeedItemActionMutationResult =
  Apollo.MutationResult<LikeFeedItemActionMutation>
export type LikeFeedItemActionMutationOptions = Apollo.BaseMutationOptions<
  LikeFeedItemActionMutation,
  LikeFeedItemActionMutationVariables
>
export const GetCartItemDocument = gql`
  query GetCartItem($cart_item_id: uuid!) {
    cart_item: cart_item_by_pk(id: $cart_item_id) {
      id
      item {
        display_title
      }
      note {
        note
        private
      }
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`

/**
 * __useGetCartItemQuery__
 *
 * To run a query within a React component, call `useGetCartItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemQuery({
 *   variables: {
 *      cart_item_id: // value for 'cart_item_id'
 *   },
 * });
 */
export function useGetCartItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCartItemQuery,
    GetCartItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCartItemQuery, GetCartItemQueryVariables>(
    GetCartItemDocument,
    options
  )
}
export function useGetCartItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCartItemQuery,
    GetCartItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCartItemQuery, GetCartItemQueryVariables>(
    GetCartItemDocument,
    options
  )
}
export type GetCartItemQueryHookResult = ReturnType<typeof useGetCartItemQuery>
export type GetCartItemLazyQueryHookResult = ReturnType<
  typeof useGetCartItemLazyQuery
>
export type GetCartItemQueryResult = Apollo.QueryResult<
  GetCartItemQuery,
  GetCartItemQueryVariables
>
export const GetMobileUserSavedItemsDocument = gql`
  query GetMobileUserSavedItems(
    $userId: String!
    $offset: Int = 0
    $limit: Int = 20
    $withArchived: Boolean = false
    $withAll: Boolean = false
    $withDealHop: Boolean = false
  ) {
    all_items: cart_item(
      where: {
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
        cart: { user_id: { _eq: $userId } }
        source: { _neq: "deal_hop" }
      }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc_nulls_last }
    ) @include(if: $withAll) {
      ...cartItemDataPartialForSavedItems
    }
    all_items_aggregate_count: cart_item_aggregate(
      where: {
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
        cart: { user_id: { _eq: $userId } }
        source: { _neq: "deal_hop" }
      }
    ) @include(if: $withAll) {
      aggregate {
        count
      }
    }
    dealhop_items: cart_item(
      where: {
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
        cart: { user_id: { _eq: $userId } }
        source: { _eq: "deal_hop" }
      }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc_nulls_last }
    ) @include(if: $withDealHop) {
      ...cartItemDataPartialForSavedItems
    }
    dealhop_items_aggregate_count: cart_item_aggregate(
      where: {
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
        cart: { user_id: { _eq: $userId } }
        source: { _eq: "deal_hop" }
      }
    ) @include(if: $withDealHop) {
      aggregate {
        count
      }
    }
    archived_items: cart_item(
      where: {
        deleted_at: { _is_null: true }
        archived_at: { _is_null: false }
        cart: { user_id: { _eq: $userId } }
      }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc_nulls_last }
    ) @include(if: $withArchived) {
      ...cartItemDataPartialForSavedItems
    }
    archived_items_aggregate_count: cart_item_aggregate(
      where: {
        deleted_at: { _is_null: true }
        archived_at: { _is_null: false }
        cart: { user_id: { _eq: $userId } }
      }
    ) @include(if: $withArchived) {
      aggregate {
        count
      }
    }
  }
  ${CartItemDataPartialForSavedItemsFragmentDoc}
`

/**
 * __useGetMobileUserSavedItemsQuery__
 *
 * To run a query within a React component, call `useGetMobileUserSavedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileUserSavedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileUserSavedItemsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      withArchived: // value for 'withArchived'
 *      withAll: // value for 'withAll'
 *      withDealHop: // value for 'withDealHop'
 *   },
 * });
 */
export function useGetMobileUserSavedItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMobileUserSavedItemsQuery,
    GetMobileUserSavedItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMobileUserSavedItemsQuery,
    GetMobileUserSavedItemsQueryVariables
  >(GetMobileUserSavedItemsDocument, options)
}
export function useGetMobileUserSavedItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMobileUserSavedItemsQuery,
    GetMobileUserSavedItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMobileUserSavedItemsQuery,
    GetMobileUserSavedItemsQueryVariables
  >(GetMobileUserSavedItemsDocument, options)
}
export type GetMobileUserSavedItemsQueryHookResult = ReturnType<
  typeof useGetMobileUserSavedItemsQuery
>
export type GetMobileUserSavedItemsLazyQueryHookResult = ReturnType<
  typeof useGetMobileUserSavedItemsLazyQuery
>
export type GetMobileUserSavedItemsQueryResult = Apollo.QueryResult<
  GetMobileUserSavedItemsQuery,
  GetMobileUserSavedItemsQueryVariables
>
export const FindCollectionMobileDocument = gql`
  query FindCollectionMobile($collection_id: uuid!, $user_id: String!) {
    collection: collection_by_pk(id: $collection_id) {
      ...basicCollectionData
      followed_by_user
      is_a_collaborator
      state
      paid
      exclusive
      has_valid_preview_images_computed
      notification_preferences
      email_excerpt
      total_likes_count
      collection_type
      feed_item {
        ...feedItemData
      }
      open_collab_link_computed
      cover_image
      media_enabled_join {
        ...mediaEnabledItemData
      }
      preview_collection_items(limit: 1) {
        id
        cart_item {
          id
          item_image_override
          item {
            cached_image
          }
        }
      }
      user {
        id
        name
        username
        picture
        created_at
        has_billing_account
      }
      collection_collaborators_aggregate {
        aggregate {
          count
        }
      }
      collection_collaborators(limit: 10) {
        id
        user {
          id
          name
          picture
        }
      }
    }
    is_collaborator: collection_collaborator_aggregate(
      where: {
        collection_id: { _eq: $collection_id }
        user_id: { _eq: $user_id }
      }
    ) {
      aggregate {
        count
      }
    }
  }
  ${BasicCollectionDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
  ${MediaEnabledItemDataFragmentDoc}
`

/**
 * __useFindCollectionMobileQuery__
 *
 * To run a query within a React component, call `useFindCollectionMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCollectionMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCollectionMobileQuery({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useFindCollectionMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCollectionMobileQuery,
    FindCollectionMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCollectionMobileQuery,
    FindCollectionMobileQueryVariables
  >(FindCollectionMobileDocument, options)
}
export function useFindCollectionMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCollectionMobileQuery,
    FindCollectionMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCollectionMobileQuery,
    FindCollectionMobileQueryVariables
  >(FindCollectionMobileDocument, options)
}
export type FindCollectionMobileQueryHookResult = ReturnType<
  typeof useFindCollectionMobileQuery
>
export type FindCollectionMobileLazyQueryHookResult = ReturnType<
  typeof useFindCollectionMobileLazyQuery
>
export type FindCollectionMobileQueryResult = Apollo.QueryResult<
  FindCollectionMobileQuery,
  FindCollectionMobileQueryVariables
>
export const GetCollectionCollaboratorsMobileDocument = gql`
  query GetCollectionCollaboratorsMobile(
    $collection_id: uuid!
    $offset: Int = 0
    $limit: Int = 10
  ) {
    collection_collaborator(
      where: { collection_id: { _eq: $collection_id } }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc_nulls_last }
    ) {
      user {
        id
        username
        created_at
        name
        picture
        verified
        collections_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`

/**
 * __useGetCollectionCollaboratorsMobileQuery__
 *
 * To run a query within a React component, call `useGetCollectionCollaboratorsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionCollaboratorsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionCollaboratorsMobileQuery({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCollectionCollaboratorsMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionCollaboratorsMobileQuery,
    GetCollectionCollaboratorsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionCollaboratorsMobileQuery,
    GetCollectionCollaboratorsMobileQueryVariables
  >(GetCollectionCollaboratorsMobileDocument, options)
}
export function useGetCollectionCollaboratorsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionCollaboratorsMobileQuery,
    GetCollectionCollaboratorsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionCollaboratorsMobileQuery,
    GetCollectionCollaboratorsMobileQueryVariables
  >(GetCollectionCollaboratorsMobileDocument, options)
}
export type GetCollectionCollaboratorsMobileQueryHookResult = ReturnType<
  typeof useGetCollectionCollaboratorsMobileQuery
>
export type GetCollectionCollaboratorsMobileLazyQueryHookResult = ReturnType<
  typeof useGetCollectionCollaboratorsMobileLazyQuery
>
export type GetCollectionCollaboratorsMobileQueryResult = Apollo.QueryResult<
  GetCollectionCollaboratorsMobileQuery,
  GetCollectionCollaboratorsMobileQueryVariables
>
export const GetUserCollectionsDocument = gql`
  query GetUserCollections(
    $userId: String!
    $offset: Int = 0
    $limit: Int = 10
    $itemLimit: Int = 1
    $include_seo_fields: Boolean = false
  ) {
    collections_personal: collection(
      where: { all: { _eq: false }, user_id: { _eq: $userId } }
      order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
      limit: $limit
      offset: $offset
    ) {
      ...collectionAndItemsData
    }
    collections_personal_aggregate: collection_aggregate(
      where: { all: { _eq: false }, user_id: { _eq: $userId } }
    ) {
      aggregate {
        count
      }
    }
    collections_collaborating: collection(
      where: { collection_collaborators: { user_id: { _eq: $userId } } }
      order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
      limit: $limit
      offset: $offset
    ) {
      ...collectionAndItemsData
    }
    collections_collaborating_aggregate: collection_aggregate(
      where: { collection_collaborators: { user_id: { _eq: $userId } } }
    ) {
      aggregate {
        count
      }
    }
    user_collections_followed: user_items_followed(
      where: { user_id: { _eq: $userId }, followable_item: { collection: {} } }
    ) {
      followable_item {
        collection {
          ...collectionAndItemsData
        }
      }
    }
    user_collections_followed_aggregate: user_items_followed_aggregate(
      where: { user_id: { _eq: $userId }, followable_item: { collection: {} } }
    ) {
      aggregate {
        count
      }
    }
  }
  ${CollectionAndItemsDataFragmentDoc}
`

/**
 * __useGetUserCollectionsQuery__
 *
 * To run a query within a React component, call `useGetUserCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCollectionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      itemLimit: // value for 'itemLimit'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useGetUserCollectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCollectionsQuery,
    GetUserCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCollectionsQuery,
    GetUserCollectionsQueryVariables
  >(GetUserCollectionsDocument, options)
}
export function useGetUserCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCollectionsQuery,
    GetUserCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCollectionsQuery,
    GetUserCollectionsQueryVariables
  >(GetUserCollectionsDocument, options)
}
export type GetUserCollectionsQueryHookResult = ReturnType<
  typeof useGetUserCollectionsQuery
>
export type GetUserCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetUserCollectionsLazyQuery
>
export type GetUserCollectionsQueryResult = Apollo.QueryResult<
  GetUserCollectionsQuery,
  GetUserCollectionsQueryVariables
>
export const GetUserCollectionsPartialDocument = gql`
  query GetUserCollectionsPartial(
    $userId: String!
    $offset: Int = 0
    $limit: Int = 10
    $itemLimit: Int = 1
    $include_seo_fields: Boolean = false
    $withPersonal: Boolean = false
    $withCollaborating: Boolean = false
    $withFollowing: Boolean = false
    $withVisible: Boolean = false
    $withHidden: Boolean = false
  ) {
    collections_all: collection(
      where: {
        all: { _eq: false }
        _or: [
          { user_id: { _eq: $userId } }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
      order_by: [
        { cart_item_max_updated_at: desc_nulls_last }
        { updated_at: desc_nulls_last }
      ]
      limit: $limit
      offset: $offset
    ) @include(if: $withPersonal) {
      ...collectionPartialForCollectionList
    }
    collections_visible: collection(
      where: {
        all: { _eq: false }
        user_id: { _eq: $userId }
        computed_is_visible: { _eq: true }
      }
      order_by: [{ order: asc }, { cart_item_max_updated_at: desc_nulls_last }]
      limit: $limit
      offset: $offset
    ) @include(if: $withVisible) {
      ...collectionPartialForCollectionList
    }
    collections_hidden: collection(
      where: {
        all: { _eq: false }
        user_id: { _eq: $userId }
        computed_is_visible: { _eq: false }
      }
      order_by: [{ order: asc }, { cart_item_max_updated_at: desc_nulls_last }]
      limit: $limit
      offset: $offset
    ) @include(if: $withHidden) {
      ...collectionPartialForCollectionList
    }
    collections_collaborating: collection(
      where: {
        _or: [
          {
            _and: [
              { user_id: { _eq: $userId } }
              { collection_type: { _eq: shared } }
            ]
          }
          {
            _and: [
              { user_id: { _eq: $userId } }
              { has_collaborators: { _eq: true } }
            ]
          }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
      order_by: [
        { cart_item_max_updated_at: desc_nulls_last }
        { updated_at: desc_nulls_last }
      ]
      limit: $limit
      offset: $offset
    ) @include(if: $withCollaborating) {
      ...collectionPartialForCollectionList
    }
    user_collections_followed: user_items_followed(
      where: { user_id: { _eq: $userId }, followable_item: { collection: {} } }
      order_by: { created_at: desc_nulls_last }
      limit: $limit
      offset: $offset
    ) @include(if: $withFollowing) {
      followable_item {
        collection {
          ...collectionPartialForCollectionList
        }
      }
    }
  }
  ${CollectionPartialForCollectionListFragmentDoc}
`

/**
 * __useGetUserCollectionsPartialQuery__
 *
 * To run a query within a React component, call `useGetUserCollectionsPartialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCollectionsPartialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCollectionsPartialQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      itemLimit: // value for 'itemLimit'
 *      include_seo_fields: // value for 'include_seo_fields'
 *      withPersonal: // value for 'withPersonal'
 *      withCollaborating: // value for 'withCollaborating'
 *      withFollowing: // value for 'withFollowing'
 *      withVisible: // value for 'withVisible'
 *      withHidden: // value for 'withHidden'
 *   },
 * });
 */
export function useGetUserCollectionsPartialQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCollectionsPartialQuery,
    GetUserCollectionsPartialQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCollectionsPartialQuery,
    GetUserCollectionsPartialQueryVariables
  >(GetUserCollectionsPartialDocument, options)
}
export function useGetUserCollectionsPartialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCollectionsPartialQuery,
    GetUserCollectionsPartialQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCollectionsPartialQuery,
    GetUserCollectionsPartialQueryVariables
  >(GetUserCollectionsPartialDocument, options)
}
export type GetUserCollectionsPartialQueryHookResult = ReturnType<
  typeof useGetUserCollectionsPartialQuery
>
export type GetUserCollectionsPartialLazyQueryHookResult = ReturnType<
  typeof useGetUserCollectionsPartialLazyQuery
>
export type GetUserCollectionsPartialQueryResult = Apollo.QueryResult<
  GetUserCollectionsPartialQuery,
  GetUserCollectionsPartialQueryVariables
>
export const GetAllUserCollectionsMobileDocument = gql`
  query GetAllUserCollectionsMobile(
    $userId: String!
    $offset: Int = 0
    $limit: Int = 10
    $itemLimit: Int = 1
    $include_seo_fields: Boolean = false
  ) {
    collection_aggregate(
      where: {
        all: { _eq: false }
        _or: [
          { user_id: { _eq: $userId } }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    collections: collection(
      where: {
        all: { _eq: false }
        _or: [
          { user_id: { _eq: $userId } }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
      order_by: [
        { cart_item_max_updated_at: desc_nulls_last }
        { updated_at: desc_nulls_last }
      ]
      limit: $limit
      offset: $offset
    ) {
      ...mobileCollectionAndItemsData
    }
  }
  ${MobileCollectionAndItemsDataFragmentDoc}
`

/**
 * __useGetAllUserCollectionsMobileQuery__
 *
 * To run a query within a React component, call `useGetAllUserCollectionsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserCollectionsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserCollectionsMobileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      itemLimit: // value for 'itemLimit'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useGetAllUserCollectionsMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllUserCollectionsMobileQuery,
    GetAllUserCollectionsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAllUserCollectionsMobileQuery,
    GetAllUserCollectionsMobileQueryVariables
  >(GetAllUserCollectionsMobileDocument, options)
}
export function useGetAllUserCollectionsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllUserCollectionsMobileQuery,
    GetAllUserCollectionsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAllUserCollectionsMobileQuery,
    GetAllUserCollectionsMobileQueryVariables
  >(GetAllUserCollectionsMobileDocument, options)
}
export type GetAllUserCollectionsMobileQueryHookResult = ReturnType<
  typeof useGetAllUserCollectionsMobileQuery
>
export type GetAllUserCollectionsMobileLazyQueryHookResult = ReturnType<
  typeof useGetAllUserCollectionsMobileLazyQuery
>
export type GetAllUserCollectionsMobileQueryResult = Apollo.QueryResult<
  GetAllUserCollectionsMobileQuery,
  GetAllUserCollectionsMobileQueryVariables
>
export const GetCreatorSubscriptionsMobileDocument = gql`
  query GetCreatorSubscriptionsMobile($id: String!) {
    availableSubscriptions(creatorId: $id) {
      id
      name
      description
      unit_amount
      currency
      interval
    }
    creator: user_by_pk(id: $id) {
      subscription_status
      has_billing_account
    }
  }
`

/**
 * __useGetCreatorSubscriptionsMobileQuery__
 *
 * To run a query within a React component, call `useGetCreatorSubscriptionsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatorSubscriptionsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatorSubscriptionsMobileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCreatorSubscriptionsMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreatorSubscriptionsMobileQuery,
    GetCreatorSubscriptionsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCreatorSubscriptionsMobileQuery,
    GetCreatorSubscriptionsMobileQueryVariables
  >(GetCreatorSubscriptionsMobileDocument, options)
}
export function useGetCreatorSubscriptionsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreatorSubscriptionsMobileQuery,
    GetCreatorSubscriptionsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCreatorSubscriptionsMobileQuery,
    GetCreatorSubscriptionsMobileQueryVariables
  >(GetCreatorSubscriptionsMobileDocument, options)
}
export type GetCreatorSubscriptionsMobileQueryHookResult = ReturnType<
  typeof useGetCreatorSubscriptionsMobileQuery
>
export type GetCreatorSubscriptionsMobileLazyQueryHookResult = ReturnType<
  typeof useGetCreatorSubscriptionsMobileLazyQuery
>
export type GetCreatorSubscriptionsMobileQueryResult = Apollo.QueryResult<
  GetCreatorSubscriptionsMobileQuery,
  GetCreatorSubscriptionsMobileQueryVariables
>
export const GetUserIdFromUsernameDocument = gql`
  query GetUserIdFromUsername($username: String!) {
    user(where: { username: { _eq: $username } }) {
      id
    }
  }
`

/**
 * __useGetUserIdFromUsernameQuery__
 *
 * To run a query within a React component, call `useGetUserIdFromUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserIdFromUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserIdFromUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserIdFromUsernameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserIdFromUsernameQuery,
    GetUserIdFromUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserIdFromUsernameQuery,
    GetUserIdFromUsernameQueryVariables
  >(GetUserIdFromUsernameDocument, options)
}
export function useGetUserIdFromUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserIdFromUsernameQuery,
    GetUserIdFromUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserIdFromUsernameQuery,
    GetUserIdFromUsernameQueryVariables
  >(GetUserIdFromUsernameDocument, options)
}
export type GetUserIdFromUsernameQueryHookResult = ReturnType<
  typeof useGetUserIdFromUsernameQuery
>
export type GetUserIdFromUsernameLazyQueryHookResult = ReturnType<
  typeof useGetUserIdFromUsernameLazyQuery
>
export type GetUserIdFromUsernameQueryResult = Apollo.QueryResult<
  GetUserIdFromUsernameQuery,
  GetUserIdFromUsernameQueryVariables
>
export const GetDataForHomeTabDocument = gql`
  query GetDataForHomeTab {
    top_block_enabled: mobile_app_home(
      where: { parent: { _eq: true }, block_type: { _eq: "top" } }
    ) {
      block_enabled
    }
    top_block: mobile_app_home(
      where: { block_type: { _eq: "top" }, parent: { _neq: true } }
    ) {
      block_link
      block_image
      link_type
      link_type_value
      deep_link
    }
    top_shopper_enabled: mobile_app_home(
      where: { parent: { _eq: true }, block_type: { _eq: "top_shopper" } }
    ) {
      block_enabled
    }
    top_shopper_block: mobile_app_home(
      where: { block_type: { _eq: "top_shopper" }, parent: { _neq: true } }
      order_by: { order: asc_nulls_last }
    ) {
      block_link
      block_image
      link_type
    }
  }
`

/**
 * __useGetDataForHomeTabQuery__
 *
 * To run a query within a React component, call `useGetDataForHomeTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForHomeTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForHomeTabQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDataForHomeTabQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDataForHomeTabQuery,
    GetDataForHomeTabQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDataForHomeTabQuery,
    GetDataForHomeTabQueryVariables
  >(GetDataForHomeTabDocument, options)
}
export function useGetDataForHomeTabLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataForHomeTabQuery,
    GetDataForHomeTabQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDataForHomeTabQuery,
    GetDataForHomeTabQueryVariables
  >(GetDataForHomeTabDocument, options)
}
export type GetDataForHomeTabQueryHookResult = ReturnType<
  typeof useGetDataForHomeTabQuery
>
export type GetDataForHomeTabLazyQueryHookResult = ReturnType<
  typeof useGetDataForHomeTabLazyQuery
>
export type GetDataForHomeTabQueryResult = Apollo.QueryResult<
  GetDataForHomeTabQuery,
  GetDataForHomeTabQueryVariables
>
export const FindCartItemsForDealHopNotificationDocument = gql`
  query FindCartItemsForDealHopNotification($ids: [uuid!]) {
    cart_items: cart_item(where: { id: { _in: $ids } }) {
      id
      item_image_override
      item {
        image
        cached_image
      }
    }
  }
`

/**
 * __useFindCartItemsForDealHopNotificationQuery__
 *
 * To run a query within a React component, call `useFindCartItemsForDealHopNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCartItemsForDealHopNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCartItemsForDealHopNotificationQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFindCartItemsForDealHopNotificationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindCartItemsForDealHopNotificationQuery,
    FindCartItemsForDealHopNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCartItemsForDealHopNotificationQuery,
    FindCartItemsForDealHopNotificationQueryVariables
  >(FindCartItemsForDealHopNotificationDocument, options)
}
export function useFindCartItemsForDealHopNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCartItemsForDealHopNotificationQuery,
    FindCartItemsForDealHopNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCartItemsForDealHopNotificationQuery,
    FindCartItemsForDealHopNotificationQueryVariables
  >(FindCartItemsForDealHopNotificationDocument, options)
}
export type FindCartItemsForDealHopNotificationQueryHookResult = ReturnType<
  typeof useFindCartItemsForDealHopNotificationQuery
>
export type FindCartItemsForDealHopNotificationLazyQueryHookResult = ReturnType<
  typeof useFindCartItemsForDealHopNotificationLazyQuery
>
export type FindCartItemsForDealHopNotificationQueryResult = Apollo.QueryResult<
  FindCartItemsForDealHopNotificationQuery,
  FindCartItemsForDealHopNotificationQueryVariables
>
export const GetNotificationCenterItemsActionDocument = gql`
  query GetNotificationCenterItemsAction(
    $userId: String!
    $tasks: Boolean!
    $read: Boolean
    $limit: Int = 10
    $offset: Int = 0
    $customWhereClause: notification_center_item_bool_exp
  ) {
    notification_center_feed_for_user(
      args: { user_id: $userId, tasks: $tasks, read: $read }
      where: $customWhereClause
      limit: $limit
      offset: $offset
    ) {
      ...notificationCenterItemData
    }
  }
  ${NotificationCenterItemDataFragmentDoc}
`

/**
 * __useGetNotificationCenterItemsActionQuery__
 *
 * To run a query within a React component, call `useGetNotificationCenterItemsActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationCenterItemsActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationCenterItemsActionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      tasks: // value for 'tasks'
 *      read: // value for 'read'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      customWhereClause: // value for 'customWhereClause'
 *   },
 * });
 */
export function useGetNotificationCenterItemsActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationCenterItemsActionQuery,
    GetNotificationCenterItemsActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetNotificationCenterItemsActionQuery,
    GetNotificationCenterItemsActionQueryVariables
  >(GetNotificationCenterItemsActionDocument, options)
}
export function useGetNotificationCenterItemsActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationCenterItemsActionQuery,
    GetNotificationCenterItemsActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetNotificationCenterItemsActionQuery,
    GetNotificationCenterItemsActionQueryVariables
  >(GetNotificationCenterItemsActionDocument, options)
}
export type GetNotificationCenterItemsActionQueryHookResult = ReturnType<
  typeof useGetNotificationCenterItemsActionQuery
>
export type GetNotificationCenterItemsActionLazyQueryHookResult = ReturnType<
  typeof useGetNotificationCenterItemsActionLazyQuery
>
export type GetNotificationCenterItemsActionQueryResult = Apollo.QueryResult<
  GetNotificationCenterItemsActionQuery,
  GetNotificationCenterItemsActionQueryVariables
>
export const GetNotificationCenterItemsForUserDocument = gql`
  query GetNotificationCenterItemsForUser($limit: Int = 10, $offset: Int = 0) {
    notification_center_item: notificationCenterItems(
      tasks: false
      limit: $limit
      offset: $offset
    ) {
      ...wrappedNotificationCenterItemData
    }
  }
  ${WrappedNotificationCenterItemDataFragmentDoc}
`

/**
 * __useGetNotificationCenterItemsForUserQuery__
 *
 * To run a query within a React component, call `useGetNotificationCenterItemsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationCenterItemsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationCenterItemsForUserQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetNotificationCenterItemsForUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNotificationCenterItemsForUserQuery,
    GetNotificationCenterItemsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetNotificationCenterItemsForUserQuery,
    GetNotificationCenterItemsForUserQueryVariables
  >(GetNotificationCenterItemsForUserDocument, options)
}
export function useGetNotificationCenterItemsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationCenterItemsForUserQuery,
    GetNotificationCenterItemsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetNotificationCenterItemsForUserQuery,
    GetNotificationCenterItemsForUserQueryVariables
  >(GetNotificationCenterItemsForUserDocument, options)
}
export type GetNotificationCenterItemsForUserQueryHookResult = ReturnType<
  typeof useGetNotificationCenterItemsForUserQuery
>
export type GetNotificationCenterItemsForUserLazyQueryHookResult = ReturnType<
  typeof useGetNotificationCenterItemsForUserLazyQuery
>
export type GetNotificationCenterItemsForUserQueryResult = Apollo.QueryResult<
  GetNotificationCenterItemsForUserQuery,
  GetNotificationCenterItemsForUserQueryVariables
>
export const GetNotificationCenterTasksForUserDocument = gql`
  query GetNotificationCenterTasksForUser(
    $limit: Int = 10
    $offset: Int = 0
    $read: Boolean
    $withTotal: Boolean = true
  ) {
    notification_center_item: notificationCenterItems(
      tasks: true
      read: $read
      limit: $limit
      offset: $offset
    ) {
      ...wrappedNotificationCenterItemData
    }
    total_tasks: notification_center_item_aggregate(
      where: { type: { _eq: task } }
    ) @include(if: $withTotal) {
      aggregate {
        count
      }
    }
  }
  ${WrappedNotificationCenterItemDataFragmentDoc}
`

/**
 * __useGetNotificationCenterTasksForUserQuery__
 *
 * To run a query within a React component, call `useGetNotificationCenterTasksForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationCenterTasksForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationCenterTasksForUserQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      read: // value for 'read'
 *      withTotal: // value for 'withTotal'
 *   },
 * });
 */
export function useGetNotificationCenterTasksForUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNotificationCenterTasksForUserQuery,
    GetNotificationCenterTasksForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetNotificationCenterTasksForUserQuery,
    GetNotificationCenterTasksForUserQueryVariables
  >(GetNotificationCenterTasksForUserDocument, options)
}
export function useGetNotificationCenterTasksForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationCenterTasksForUserQuery,
    GetNotificationCenterTasksForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetNotificationCenterTasksForUserQuery,
    GetNotificationCenterTasksForUserQueryVariables
  >(GetNotificationCenterTasksForUserDocument, options)
}
export type GetNotificationCenterTasksForUserQueryHookResult = ReturnType<
  typeof useGetNotificationCenterTasksForUserQuery
>
export type GetNotificationCenterTasksForUserLazyQueryHookResult = ReturnType<
  typeof useGetNotificationCenterTasksForUserLazyQuery
>
export type GetNotificationCenterTasksForUserQueryResult = Apollo.QueryResult<
  GetNotificationCenterTasksForUserQuery,
  GetNotificationCenterTasksForUserQueryVariables
>
export const SetNotificationCenterItemAsReadDocument = gql`
  mutation SetNotificationCenterItemAsRead(
    $notification_center_item_id: uuid!
  ) {
    insert_notification_center_item_read_status_one(
      object: { notification_center_item_id: $notification_center_item_id }
      on_conflict: {
        constraint: notification_center_item_read_status_pkey
        update_columns: [notification_center_item_id]
      }
    ) {
      created_at
    }
  }
`
export type SetNotificationCenterItemAsReadMutationFn = Apollo.MutationFunction<
  SetNotificationCenterItemAsReadMutation,
  SetNotificationCenterItemAsReadMutationVariables
>

/**
 * __useSetNotificationCenterItemAsReadMutation__
 *
 * To run a mutation, you first call `useSetNotificationCenterItemAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNotificationCenterItemAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNotificationCenterItemAsReadMutation, { data, loading, error }] = useSetNotificationCenterItemAsReadMutation({
 *   variables: {
 *      notification_center_item_id: // value for 'notification_center_item_id'
 *   },
 * });
 */
export function useSetNotificationCenterItemAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetNotificationCenterItemAsReadMutation,
    SetNotificationCenterItemAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetNotificationCenterItemAsReadMutation,
    SetNotificationCenterItemAsReadMutationVariables
  >(SetNotificationCenterItemAsReadDocument, options)
}
export type SetNotificationCenterItemAsReadMutationHookResult = ReturnType<
  typeof useSetNotificationCenterItemAsReadMutation
>
export type SetNotificationCenterItemAsReadMutationResult =
  Apollo.MutationResult<SetNotificationCenterItemAsReadMutation>
export type SetNotificationCenterItemAsReadMutationOptions =
  Apollo.BaseMutationOptions<
    SetNotificationCenterItemAsReadMutation,
    SetNotificationCenterItemAsReadMutationVariables
  >
export const UpdateNotificationCenterLastViewedSequentialIdDocument = gql`
  mutation UpdateNotificationCenterLastViewedSequentialId {
    update_last_seen_notification_center_item_sequential_id(args: {}) {
      ...userFlags
    }
  }
  ${UserFlagsFragmentDoc}
`
export type UpdateNotificationCenterLastViewedSequentialIdMutationFn =
  Apollo.MutationFunction<
    UpdateNotificationCenterLastViewedSequentialIdMutation,
    UpdateNotificationCenterLastViewedSequentialIdMutationVariables
  >

/**
 * __useUpdateNotificationCenterLastViewedSequentialIdMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationCenterLastViewedSequentialIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationCenterLastViewedSequentialIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationCenterLastViewedSequentialIdMutation, { data, loading, error }] = useUpdateNotificationCenterLastViewedSequentialIdMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateNotificationCenterLastViewedSequentialIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationCenterLastViewedSequentialIdMutation,
    UpdateNotificationCenterLastViewedSequentialIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateNotificationCenterLastViewedSequentialIdMutation,
    UpdateNotificationCenterLastViewedSequentialIdMutationVariables
  >(UpdateNotificationCenterLastViewedSequentialIdDocument, options)
}
export type UpdateNotificationCenterLastViewedSequentialIdMutationHookResult =
  ReturnType<typeof useUpdateNotificationCenterLastViewedSequentialIdMutation>
export type UpdateNotificationCenterLastViewedSequentialIdMutationResult =
  Apollo.MutationResult<UpdateNotificationCenterLastViewedSequentialIdMutation>
export type UpdateNotificationCenterLastViewedSequentialIdMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateNotificationCenterLastViewedSequentialIdMutation,
    UpdateNotificationCenterLastViewedSequentialIdMutationVariables
  >
export const FindShopItemForPdpDocument = gql`
  query FindShopItemForPDP($id: uuid!) {
    shop_item: shop_item_by_pk(id: $id) {
      ...itemData
      shop {
        ...shopData
      }
    }
  }
  ${ItemDataFragmentDoc}
  ${ShopDataFragmentDoc}
`

/**
 * __useFindShopItemForPdpQuery__
 *
 * To run a query within a React component, call `useFindShopItemForPdpQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShopItemForPdpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShopItemForPdpQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindShopItemForPdpQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindShopItemForPdpQuery,
    FindShopItemForPdpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindShopItemForPdpQuery,
    FindShopItemForPdpQueryVariables
  >(FindShopItemForPdpDocument, options)
}
export function useFindShopItemForPdpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindShopItemForPdpQuery,
    FindShopItemForPdpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindShopItemForPdpQuery,
    FindShopItemForPdpQueryVariables
  >(FindShopItemForPdpDocument, options)
}
export type FindShopItemForPdpQueryHookResult = ReturnType<
  typeof useFindShopItemForPdpQuery
>
export type FindShopItemForPdpLazyQueryHookResult = ReturnType<
  typeof useFindShopItemForPdpLazyQuery
>
export type FindShopItemForPdpQueryResult = Apollo.QueryResult<
  FindShopItemForPdpQuery,
  FindShopItemForPdpQueryVariables
>
export const UserSavedItemDocument = gql`
  query UserSavedItem($id: uuid!, $userId: String!) {
    cart_item(
      where: { item_id: { _eq: $id }, user: { id: { _eq: $userId } } }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`

/**
 * __useUserSavedItemQuery__
 *
 * To run a query within a React component, call `useUserSavedItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSavedItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSavedItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserSavedItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserSavedItemQuery,
    UserSavedItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserSavedItemQuery, UserSavedItemQueryVariables>(
    UserSavedItemDocument,
    options
  )
}
export function useUserSavedItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSavedItemQuery,
    UserSavedItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserSavedItemQuery, UserSavedItemQueryVariables>(
    UserSavedItemDocument,
    options
  )
}
export type UserSavedItemQueryHookResult = ReturnType<
  typeof useUserSavedItemQuery
>
export type UserSavedItemLazyQueryHookResult = ReturnType<
  typeof useUserSavedItemLazyQuery
>
export type UserSavedItemQueryResult = Apollo.QueryResult<
  UserSavedItemQuery,
  UserSavedItemQueryVariables
>
export const PushNotificationHistoryForAdminDocument = gql`
  query PushNotificationHistoryForAdmin($limit: Int = 20, $offset: Int = 0) {
    mobile_push_notification(
      limit: $limit
      offset: $offset
      order_by: [{ created_at: desc_nulls_last }, { id: asc }]
    ) {
      id
      message_payload
      user {
        id
        name
        picture
      }
      created_at
      attempts
      status
    }
  }
`

/**
 * __usePushNotificationHistoryForAdminQuery__
 *
 * To run a query within a React component, call `usePushNotificationHistoryForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `usePushNotificationHistoryForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePushNotificationHistoryForAdminQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePushNotificationHistoryForAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PushNotificationHistoryForAdminQuery,
    PushNotificationHistoryForAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PushNotificationHistoryForAdminQuery,
    PushNotificationHistoryForAdminQueryVariables
  >(PushNotificationHistoryForAdminDocument, options)
}
export function usePushNotificationHistoryForAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PushNotificationHistoryForAdminQuery,
    PushNotificationHistoryForAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PushNotificationHistoryForAdminQuery,
    PushNotificationHistoryForAdminQueryVariables
  >(PushNotificationHistoryForAdminDocument, options)
}
export type PushNotificationHistoryForAdminQueryHookResult = ReturnType<
  typeof usePushNotificationHistoryForAdminQuery
>
export type PushNotificationHistoryForAdminLazyQueryHookResult = ReturnType<
  typeof usePushNotificationHistoryForAdminLazyQuery
>
export type PushNotificationHistoryForAdminQueryResult = Apollo.QueryResult<
  PushNotificationHistoryForAdminQuery,
  PushNotificationHistoryForAdminQueryVariables
>
export const GetCartItemSavedDealHopPushNotificationDocument = gql`
  query GetCartItemSavedDealHopPushNotification($id: uuid!) {
    notification_center_item_by_pk(id: $id) {
      id
      user {
        ...userForPushNotificationData
      }
      notifiable_item {
        cart_item {
          id
          display_title
          pricing
          item_image_override
          item_id
          item {
            image
            cached_image
            pricing
          }
        }
      }
    }
  }
  ${UserForPushNotificationDataFragmentDoc}
`

/**
 * __useGetCartItemSavedDealHopPushNotificationQuery__
 *
 * To run a query within a React component, call `useGetCartItemSavedDealHopPushNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemSavedDealHopPushNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemSavedDealHopPushNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCartItemSavedDealHopPushNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCartItemSavedDealHopPushNotificationQuery,
    GetCartItemSavedDealHopPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCartItemSavedDealHopPushNotificationQuery,
    GetCartItemSavedDealHopPushNotificationQueryVariables
  >(GetCartItemSavedDealHopPushNotificationDocument, options)
}
export function useGetCartItemSavedDealHopPushNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCartItemSavedDealHopPushNotificationQuery,
    GetCartItemSavedDealHopPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCartItemSavedDealHopPushNotificationQuery,
    GetCartItemSavedDealHopPushNotificationQueryVariables
  >(GetCartItemSavedDealHopPushNotificationDocument, options)
}
export type GetCartItemSavedDealHopPushNotificationQueryHookResult = ReturnType<
  typeof useGetCartItemSavedDealHopPushNotificationQuery
>
export type GetCartItemSavedDealHopPushNotificationLazyQueryHookResult =
  ReturnType<typeof useGetCartItemSavedDealHopPushNotificationLazyQuery>
export type GetCartItemSavedDealHopPushNotificationQueryResult =
  Apollo.QueryResult<
    GetCartItemSavedDealHopPushNotificationQuery,
    GetCartItemSavedDealHopPushNotificationQueryVariables
  >
export const GetCartItemSavedForPushNotificationDocument = gql`
  query GetCartItemSavedForPushNotification($id: uuid!) {
    notification_center_item_by_pk(id: $id) {
      id
      user {
        ...userForPushNotificationData
      }
      notifiable_item {
        cart_item {
          id
          item_id
          originating_cart_item {
            id
            shop {
              name
              key
            }
          }
          originating_collection {
            id
            name
            slug
          }
        }
      }
    }
  }
  ${UserForPushNotificationDataFragmentDoc}
`

/**
 * __useGetCartItemSavedForPushNotificationQuery__
 *
 * To run a query within a React component, call `useGetCartItemSavedForPushNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemSavedForPushNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemSavedForPushNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCartItemSavedForPushNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCartItemSavedForPushNotificationQuery,
    GetCartItemSavedForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCartItemSavedForPushNotificationQuery,
    GetCartItemSavedForPushNotificationQueryVariables
  >(GetCartItemSavedForPushNotificationDocument, options)
}
export function useGetCartItemSavedForPushNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCartItemSavedForPushNotificationQuery,
    GetCartItemSavedForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCartItemSavedForPushNotificationQuery,
    GetCartItemSavedForPushNotificationQueryVariables
  >(GetCartItemSavedForPushNotificationDocument, options)
}
export type GetCartItemSavedForPushNotificationQueryHookResult = ReturnType<
  typeof useGetCartItemSavedForPushNotificationQuery
>
export type GetCartItemSavedForPushNotificationLazyQueryHookResult = ReturnType<
  typeof useGetCartItemSavedForPushNotificationLazyQuery
>
export type GetCartItemSavedForPushNotificationQueryResult = Apollo.QueryResult<
  GetCartItemSavedForPushNotificationQuery,
  GetCartItemSavedForPushNotificationQueryVariables
>
export const GetCollabCollectionItemAddedForPushNotificationDocument = gql`
  query GetCollabCollectionItemAddedForPushNotification($id: uuid!) {
    notification_center_item_by_pk(id: $id) {
      id
      user {
        ...userForPushNotificationData
      }
      notifiable_item {
        collection_cart_item {
          collection {
            id
            name
            user {
              id
            }
          }
          cart_item {
            item_image_override
            cart {
              user {
                username
              }
            }
          }
        }
      }
    }
  }
  ${UserForPushNotificationDataFragmentDoc}
`

/**
 * __useGetCollabCollectionItemAddedForPushNotificationQuery__
 *
 * To run a query within a React component, call `useGetCollabCollectionItemAddedForPushNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollabCollectionItemAddedForPushNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollabCollectionItemAddedForPushNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollabCollectionItemAddedForPushNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollabCollectionItemAddedForPushNotificationQuery,
    GetCollabCollectionItemAddedForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollabCollectionItemAddedForPushNotificationQuery,
    GetCollabCollectionItemAddedForPushNotificationQueryVariables
  >(GetCollabCollectionItemAddedForPushNotificationDocument, options)
}
export function useGetCollabCollectionItemAddedForPushNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollabCollectionItemAddedForPushNotificationQuery,
    GetCollabCollectionItemAddedForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollabCollectionItemAddedForPushNotificationQuery,
    GetCollabCollectionItemAddedForPushNotificationQueryVariables
  >(GetCollabCollectionItemAddedForPushNotificationDocument, options)
}
export type GetCollabCollectionItemAddedForPushNotificationQueryHookResult =
  ReturnType<typeof useGetCollabCollectionItemAddedForPushNotificationQuery>
export type GetCollabCollectionItemAddedForPushNotificationLazyQueryHookResult =
  ReturnType<typeof useGetCollabCollectionItemAddedForPushNotificationLazyQuery>
export type GetCollabCollectionItemAddedForPushNotificationQueryResult =
  Apollo.QueryResult<
    GetCollabCollectionItemAddedForPushNotificationQuery,
    GetCollabCollectionItemAddedForPushNotificationQueryVariables
  >
export const GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationDocument = gql`
  query GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotification(
    $id: uuid!
  ) {
    notification_center_item_by_pk(id: $id) {
      id
      user {
        ...userForPushNotificationData
      }
      notifiable_item {
        collection_invite {
          collection {
            id
            name
            user {
              id
            }
          }
          invitee {
            username
          }
        }
      }
    }
  }
  ${UserForPushNotificationDataFragmentDoc}
`

/**
 * __useGetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQuery__
 *
 * To run a query within a React component, call `useGetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQuery,
    GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQuery,
    GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQueryVariables
  >(
    GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationDocument,
    options
  )
}
export function useGetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQuery,
    GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQuery,
    GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQueryVariables
  >(
    GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationDocument,
    options
  )
}
export type GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQueryHookResult =
  ReturnType<
    typeof useGetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQuery
  >
export type GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationLazyQueryHookResult =
  ReturnType<
    typeof useGetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationLazyQuery
  >
export type GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQueryResult =
  Apollo.QueryResult<
    GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQuery,
    GetCollabInviteAcceptedDataFromNotificationCenterItemForPushNotificationQueryVariables
  >
export const GetNewFollowerForPushNotificationDocument = gql`
  query GetNewFollowerForPushNotification($id: uuid!) {
    notification_center_item_by_pk(id: $id) {
      id
      user {
        ...userForPushNotificationData
      }
      notifiable_item {
        follow {
          user {
            username
          }
          followable_item {
            collection {
              id
              name
              user {
                id
              }
            }
          }
        }
      }
    }
  }
  ${UserForPushNotificationDataFragmentDoc}
`

/**
 * __useGetNewFollowerForPushNotificationQuery__
 *
 * To run a query within a React component, call `useGetNewFollowerForPushNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewFollowerForPushNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewFollowerForPushNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNewFollowerForPushNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNewFollowerForPushNotificationQuery,
    GetNewFollowerForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetNewFollowerForPushNotificationQuery,
    GetNewFollowerForPushNotificationQueryVariables
  >(GetNewFollowerForPushNotificationDocument, options)
}
export function useGetNewFollowerForPushNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNewFollowerForPushNotificationQuery,
    GetNewFollowerForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetNewFollowerForPushNotificationQuery,
    GetNewFollowerForPushNotificationQueryVariables
  >(GetNewFollowerForPushNotificationDocument, options)
}
export type GetNewFollowerForPushNotificationQueryHookResult = ReturnType<
  typeof useGetNewFollowerForPushNotificationQuery
>
export type GetNewFollowerForPushNotificationLazyQueryHookResult = ReturnType<
  typeof useGetNewFollowerForPushNotificationLazyQuery
>
export type GetNewFollowerForPushNotificationQueryResult = Apollo.QueryResult<
  GetNewFollowerForPushNotificationQuery,
  GetNewFollowerForPushNotificationQueryVariables
>
export const GetUserCommentedForPushNotificationDocument = gql`
  query GetUserCommentedForPushNotification($id: uuid!) {
    notification_center_item_by_pk(id: $id) {
      id
      user {
        ...userForPushNotificationData
      }
      notifiable_item {
        comment {
          user {
            username
          }
          commentable_item_type
          commentable_item_id
          commentable_item {
            cart_item {
              display_title
              cart {
                user_id
              }
            }
            collection {
              name
              user_id
            }
          }
        }
      }
    }
  }
  ${UserForPushNotificationDataFragmentDoc}
`

/**
 * __useGetUserCommentedForPushNotificationQuery__
 *
 * To run a query within a React component, call `useGetUserCommentedForPushNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCommentedForPushNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCommentedForPushNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserCommentedForPushNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCommentedForPushNotificationQuery,
    GetUserCommentedForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCommentedForPushNotificationQuery,
    GetUserCommentedForPushNotificationQueryVariables
  >(GetUserCommentedForPushNotificationDocument, options)
}
export function useGetUserCommentedForPushNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCommentedForPushNotificationQuery,
    GetUserCommentedForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCommentedForPushNotificationQuery,
    GetUserCommentedForPushNotificationQueryVariables
  >(GetUserCommentedForPushNotificationDocument, options)
}
export type GetUserCommentedForPushNotificationQueryHookResult = ReturnType<
  typeof useGetUserCommentedForPushNotificationQuery
>
export type GetUserCommentedForPushNotificationLazyQueryHookResult = ReturnType<
  typeof useGetUserCommentedForPushNotificationLazyQuery
>
export type GetUserCommentedForPushNotificationQueryResult = Apollo.QueryResult<
  GetUserCommentedForPushNotificationQuery,
  GetUserCommentedForPushNotificationQueryVariables
>
export const RegisterUserDocument = gql`
  mutation RegisterUser(
    $short_id: name!
    $email: String
    $phone: String
    $name: String
    $picture: String
    $installed_at: timestamptz
    $anonymous: Boolean
    $referrer_user_id: String
    $app_version: user_app_version_arr_rel_insert_input
    $metadata: jsonb = {}
    $with_extension_version: Boolean = false
    $with_ios_version: Boolean = false
    $with_safari_extension_version: Boolean = false
  ) {
    user: insert_user_one(
      object: {
        short_id: $short_id
        email: $email
        phone: $phone
        name: $name
        picture: $picture
        installed_at: $installed_at
        anonymous: $anonymous
        metadata: $metadata
        collections: {
          data: { name: "All", slug: "all", all: true }
          on_conflict: {
            constraint: collection_user_id_slug_key
            update_columns: []
          }
        }
        app_versions: $app_version
        referrer_user_id: $referrer_user_id
      }
      on_conflict: { constraint: user_pkey, update_columns: [updated_at] }
    ) {
      ...userData
    }
  }
  ${UserDataFragmentDoc}
`
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      short_id: // value for 'short_id'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      name: // value for 'name'
 *      picture: // value for 'picture'
 *      installed_at: // value for 'installed_at'
 *      anonymous: // value for 'anonymous'
 *      referrer_user_id: // value for 'referrer_user_id'
 *      app_version: // value for 'app_version'
 *      metadata: // value for 'metadata'
 *      with_extension_version: // value for 'with_extension_version'
 *      with_ios_version: // value for 'with_ios_version'
 *      with_safari_extension_version: // value for 'with_safari_extension_version'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, options)
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>
export type RegisterUserMutationResult =
  Apollo.MutationResult<RegisterUserMutation>
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>
export const RemoveCollectionDocument = gql`
  mutation RemoveCollection($collection_id: uuid!) {
    collection: delete_collection_by_pk(id: $collection_id) {
      id
    }
  }
`
export type RemoveCollectionMutationFn = Apollo.MutationFunction<
  RemoveCollectionMutation,
  RemoveCollectionMutationVariables
>

/**
 * __useRemoveCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCollectionMutation, { data, loading, error }] = useRemoveCollectionMutation({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *   },
 * });
 */
export function useRemoveCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCollectionMutation,
    RemoveCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveCollectionMutation,
    RemoveCollectionMutationVariables
  >(RemoveCollectionDocument, options)
}
export type RemoveCollectionMutationHookResult = ReturnType<
  typeof useRemoveCollectionMutation
>
export type RemoveCollectionMutationResult =
  Apollo.MutationResult<RemoveCollectionMutation>
export type RemoveCollectionMutationOptions = Apollo.BaseMutationOptions<
  RemoveCollectionMutation,
  RemoveCollectionMutationVariables
>
export const SaveCartDocument = gql`
  mutation SaveCart(
    $shop_data: shop_insert_input!
    $cart_items_data: [cart_item_insert_input!]!
  ) {
    cart: insert_cart_one(
      object: {
        shop: {
          data: $shop_data
          on_conflict: {
            constraint: shop_pkey
            update_columns: [type, platform]
          }
        }
        cart_items: {
          data: $cart_items_data
          on_conflict: {
            constraint: cart_item_item_id_shop_key_cart_id_key
            update_columns: [shop_key, quantity, removed_at]
          }
        }
      }
      on_conflict: {
        constraint: cart_user_id_shop_key_key
        update_columns: [shop_key]
      }
    ) {
      ...cartData
      ...cartItemsData
    }
  }
  ${CartDataFragmentDoc}
  ${CartItemsDataFragmentDoc}
`
export type SaveCartMutationFn = Apollo.MutationFunction<
  SaveCartMutation,
  SaveCartMutationVariables
>

/**
 * __useSaveCartMutation__
 *
 * To run a mutation, you first call `useSaveCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCartMutation, { data, loading, error }] = useSaveCartMutation({
 *   variables: {
 *      shop_data: // value for 'shop_data'
 *      cart_items_data: // value for 'cart_items_data'
 *   },
 * });
 */
export function useSaveCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCartMutation,
    SaveCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveCartMutation, SaveCartMutationVariables>(
    SaveCartDocument,
    options
  )
}
export type SaveCartMutationHookResult = ReturnType<typeof useSaveCartMutation>
export type SaveCartMutationResult = Apollo.MutationResult<SaveCartMutation>
export type SaveCartMutationOptions = Apollo.BaseMutationOptions<
  SaveCartMutation,
  SaveCartMutationVariables
>
export const GetCartItemsByItemIdsDocument = gql`
  query GetCartItemsByItemIds(
    $ids: [String!]!
    $shopKey: String
    $userId: String!
  ) {
    cart_item(
      where: {
        item: { item_id: { _in: $ids } }
        cart: { shop_key: { _eq: $shopKey }, user_id: { _eq: $userId } }
      }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`

/**
 * __useGetCartItemsByItemIdsQuery__
 *
 * To run a query within a React component, call `useGetCartItemsByItemIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemsByItemIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemsByItemIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      shopKey: // value for 'shopKey'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCartItemsByItemIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCartItemsByItemIdsQuery,
    GetCartItemsByItemIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCartItemsByItemIdsQuery,
    GetCartItemsByItemIdsQueryVariables
  >(GetCartItemsByItemIdsDocument, options)
}
export function useGetCartItemsByItemIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCartItemsByItemIdsQuery,
    GetCartItemsByItemIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCartItemsByItemIdsQuery,
    GetCartItemsByItemIdsQueryVariables
  >(GetCartItemsByItemIdsDocument, options)
}
export type GetCartItemsByItemIdsQueryHookResult = ReturnType<
  typeof useGetCartItemsByItemIdsQuery
>
export type GetCartItemsByItemIdsLazyQueryHookResult = ReturnType<
  typeof useGetCartItemsByItemIdsLazyQuery
>
export type GetCartItemsByItemIdsQueryResult = Apollo.QueryResult<
  GetCartItemsByItemIdsQuery,
  GetCartItemsByItemIdsQueryVariables
>
export const SaveCartItemFromMobileDocument = gql`
  mutation SaveCartItemFromMobile(
    $user_id: String!
    $shop_key: String!
    $shop_data: shop_insert_input!
    $shop_item_data: shop_item_insert_input!
    $source: String!
  ) {
    cart_item: insert_cart_item_one(
      object: {
        shop_key: $shop_key
        quantity: 1
        bookmark: true
        source: $source
        item: {
          data: $shop_item_data
          on_conflict: {
            constraint: shop_item_shop_key_item_id_key
            update_columns: [shop_key, item_id, title, url, image, pricing]
          }
        }
        cart: {
          data: {
            user_id: $user_id
            shop: {
              data: $shop_data
              on_conflict: { constraint: shop_pkey, update_columns: [key] }
            }
          }
          on_conflict: {
            constraint: cart_user_id_shop_key_key
            update_columns: [shop_key]
          }
        }
      }
      on_conflict: {
        constraint: cart_item_item_id_shop_key_cart_id_key
        update_columns: [shop_key]
      }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`
export type SaveCartItemFromMobileMutationFn = Apollo.MutationFunction<
  SaveCartItemFromMobileMutation,
  SaveCartItemFromMobileMutationVariables
>

/**
 * __useSaveCartItemFromMobileMutation__
 *
 * To run a mutation, you first call `useSaveCartItemFromMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCartItemFromMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCartItemFromMobileMutation, { data, loading, error }] = useSaveCartItemFromMobileMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      shop_key: // value for 'shop_key'
 *      shop_data: // value for 'shop_data'
 *      shop_item_data: // value for 'shop_item_data'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useSaveCartItemFromMobileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCartItemFromMobileMutation,
    SaveCartItemFromMobileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveCartItemFromMobileMutation,
    SaveCartItemFromMobileMutationVariables
  >(SaveCartItemFromMobileDocument, options)
}
export type SaveCartItemFromMobileMutationHookResult = ReturnType<
  typeof useSaveCartItemFromMobileMutation
>
export type SaveCartItemFromMobileMutationResult =
  Apollo.MutationResult<SaveCartItemFromMobileMutation>
export type SaveCartItemFromMobileMutationOptions = Apollo.BaseMutationOptions<
  SaveCartItemFromMobileMutation,
  SaveCartItemFromMobileMutationVariables
>
export const SaveShopDocument = gql`
  mutation SaveShop($shop_object: shop_insert_input!) {
    shop: insert_shop_one(
      object: $shop_object
      on_conflict: {
        constraint: shop_pkey
        update_columns: [key, type, platform, name, slug, unknown]
      }
    ) {
      ...shopData
    }
  }
  ${ShopDataFragmentDoc}
`
export type SaveShopMutationFn = Apollo.MutationFunction<
  SaveShopMutation,
  SaveShopMutationVariables
>

/**
 * __useSaveShopMutation__
 *
 * To run a mutation, you first call `useSaveShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveShopMutation, { data, loading, error }] = useSaveShopMutation({
 *   variables: {
 *      shop_object: // value for 'shop_object'
 *   },
 * });
 */
export function useSaveShopMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveShopMutation,
    SaveShopMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveShopMutation, SaveShopMutationVariables>(
    SaveShopDocument,
    options
  )
}
export type SaveShopMutationHookResult = ReturnType<typeof useSaveShopMutation>
export type SaveShopMutationResult = Apollo.MutationResult<SaveShopMutation>
export type SaveShopMutationOptions = Apollo.BaseMutationOptions<
  SaveShopMutation,
  SaveShopMutationVariables
>
export const SaveShopCategorizationDocument = gql`
  mutation SaveShopCategorization(
    $category: shop_category_enum!
    $shop_key: String!
    $weight: Int!
    $curation_score: Int = 1
  ) {
    insert_shop_categorization_one(
      object: {
        category: $category
        shop_key: $shop_key
        weight: $weight
        curation_score: $curation_score
      }
      on_conflict: {
        constraint: shop_categorization_shop_key_weight_key
        update_columns: [category, curation_score]
      }
    ) {
      id
      shop_key
      category
      weight
      curation_score
    }
  }
`
export type SaveShopCategorizationMutationFn = Apollo.MutationFunction<
  SaveShopCategorizationMutation,
  SaveShopCategorizationMutationVariables
>

/**
 * __useSaveShopCategorizationMutation__
 *
 * To run a mutation, you first call `useSaveShopCategorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveShopCategorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveShopCategorizationMutation, { data, loading, error }] = useSaveShopCategorizationMutation({
 *   variables: {
 *      category: // value for 'category'
 *      shop_key: // value for 'shop_key'
 *      weight: // value for 'weight'
 *      curation_score: // value for 'curation_score'
 *   },
 * });
 */
export function useSaveShopCategorizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveShopCategorizationMutation,
    SaveShopCategorizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveShopCategorizationMutation,
    SaveShopCategorizationMutationVariables
  >(SaveShopCategorizationDocument, options)
}
export type SaveShopCategorizationMutationHookResult = ReturnType<
  typeof useSaveShopCategorizationMutation
>
export type SaveShopCategorizationMutationResult =
  Apollo.MutationResult<SaveShopCategorizationMutation>
export type SaveShopCategorizationMutationOptions = Apollo.BaseMutationOptions<
  SaveShopCategorizationMutation,
  SaveShopCategorizationMutationVariables
>
export const SaveUserOnboardingSourceDocument = gql`
  mutation SaveUserOnboardingSource(
    $source: onboarding_sources_enum!
    $other_source: String = ""
  ) {
    insert_user_onboarding_source_one(
      object: { onboarding_source: $source, other_source: $other_source }
      on_conflict: {
        constraint: user_onboarding_source_user_id_key
        update_columns: [onboarding_source, other_source]
      }
    ) {
      id
      onboarding_source
      other_source
    }
  }
`
export type SaveUserOnboardingSourceMutationFn = Apollo.MutationFunction<
  SaveUserOnboardingSourceMutation,
  SaveUserOnboardingSourceMutationVariables
>

/**
 * __useSaveUserOnboardingSourceMutation__
 *
 * To run a mutation, you first call `useSaveUserOnboardingSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserOnboardingSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserOnboardingSourceMutation, { data, loading, error }] = useSaveUserOnboardingSourceMutation({
 *   variables: {
 *      source: // value for 'source'
 *      other_source: // value for 'other_source'
 *   },
 * });
 */
export function useSaveUserOnboardingSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserOnboardingSourceMutation,
    SaveUserOnboardingSourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveUserOnboardingSourceMutation,
    SaveUserOnboardingSourceMutationVariables
  >(SaveUserOnboardingSourceDocument, options)
}
export type SaveUserOnboardingSourceMutationHookResult = ReturnType<
  typeof useSaveUserOnboardingSourceMutation
>
export type SaveUserOnboardingSourceMutationResult =
  Apollo.MutationResult<SaveUserOnboardingSourceMutation>
export type SaveUserOnboardingSourceMutationOptions =
  Apollo.BaseMutationOptions<
    SaveUserOnboardingSourceMutation,
    SaveUserOnboardingSourceMutationVariables
  >
export const GetCollectionsFromSearchDocument = gql`
  query GetCollectionsFromSearch(
    $ids: [uuid!]!
    $collectionItemsCount: Int = 3
  ) {
    collection(where: { id: { _in: $ids } }) {
      state
      private
      ...discoveryCollection
    }
  }
  ${DiscoveryCollectionFragmentDoc}
`

/**
 * __useGetCollectionsFromSearchQuery__
 *
 * To run a query within a React component, call `useGetCollectionsFromSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsFromSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsFromSearchQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *   },
 * });
 */
export function useGetCollectionsFromSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionsFromSearchQuery,
    GetCollectionsFromSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionsFromSearchQuery,
    GetCollectionsFromSearchQueryVariables
  >(GetCollectionsFromSearchDocument, options)
}
export function useGetCollectionsFromSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionsFromSearchQuery,
    GetCollectionsFromSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionsFromSearchQuery,
    GetCollectionsFromSearchQueryVariables
  >(GetCollectionsFromSearchDocument, options)
}
export type GetCollectionsFromSearchQueryHookResult = ReturnType<
  typeof useGetCollectionsFromSearchQuery
>
export type GetCollectionsFromSearchLazyQueryHookResult = ReturnType<
  typeof useGetCollectionsFromSearchLazyQuery
>
export type GetCollectionsFromSearchQueryResult = Apollo.QueryResult<
  GetCollectionsFromSearchQuery,
  GetCollectionsFromSearchQueryVariables
>
export const GetShopItemsBySearchDocument = gql`
  query GetShopItemsBySearch($query: String!, $pagination: String) {
    searchProducts(query: $query, pagination: $pagination) {
      id
      pagination
    }
  }
`

/**
 * __useGetShopItemsBySearchQuery__
 *
 * To run a query within a React component, call `useGetShopItemsBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemsBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemsBySearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetShopItemsBySearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopItemsBySearchQuery,
    GetShopItemsBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopItemsBySearchQuery,
    GetShopItemsBySearchQueryVariables
  >(GetShopItemsBySearchDocument, options)
}
export function useGetShopItemsBySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopItemsBySearchQuery,
    GetShopItemsBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopItemsBySearchQuery,
    GetShopItemsBySearchQueryVariables
  >(GetShopItemsBySearchDocument, options)
}
export type GetShopItemsBySearchQueryHookResult = ReturnType<
  typeof useGetShopItemsBySearchQuery
>
export type GetShopItemsBySearchLazyQueryHookResult = ReturnType<
  typeof useGetShopItemsBySearchLazyQuery
>
export type GetShopItemsBySearchQueryResult = Apollo.QueryResult<
  GetShopItemsBySearchQuery,
  GetShopItemsBySearchQueryVariables
>
export const GetShopItemsBySearchEngineSearchDocument = gql`
  query GetShopItemsBySearchEngineSearch($ids: [uuid!]!) {
    shop_item(where: { id: { _in: $ids } }) {
      ...searchItemData
    }
  }
  ${SearchItemDataFragmentDoc}
`

/**
 * __useGetShopItemsBySearchEngineSearchQuery__
 *
 * To run a query within a React component, call `useGetShopItemsBySearchEngineSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemsBySearchEngineSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemsBySearchEngineSearchQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetShopItemsBySearchEngineSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopItemsBySearchEngineSearchQuery,
    GetShopItemsBySearchEngineSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopItemsBySearchEngineSearchQuery,
    GetShopItemsBySearchEngineSearchQueryVariables
  >(GetShopItemsBySearchEngineSearchDocument, options)
}
export function useGetShopItemsBySearchEngineSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopItemsBySearchEngineSearchQuery,
    GetShopItemsBySearchEngineSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopItemsBySearchEngineSearchQuery,
    GetShopItemsBySearchEngineSearchQueryVariables
  >(GetShopItemsBySearchEngineSearchDocument, options)
}
export type GetShopItemsBySearchEngineSearchQueryHookResult = ReturnType<
  typeof useGetShopItemsBySearchEngineSearchQuery
>
export type GetShopItemsBySearchEngineSearchLazyQueryHookResult = ReturnType<
  typeof useGetShopItemsBySearchEngineSearchLazyQuery
>
export type GetShopItemsBySearchEngineSearchQueryResult = Apollo.QueryResult<
  GetShopItemsBySearchEngineSearchQuery,
  GetShopItemsBySearchEngineSearchQueryVariables
>
export const NotificationStatusClickedDocument = gql`
  mutation NotificationStatusClicked($notification_id: uuid!) {
    notificationStatus: insert_push_notification_status_one(
      object: { notification_id: $notification_id, clicked: true }
      on_conflict: {
        constraint: push_notification_status_notification_id_user_id_key
        update_columns: [clicked]
      }
    ) {
      id
      clicked
    }
  }
`
export type NotificationStatusClickedMutationFn = Apollo.MutationFunction<
  NotificationStatusClickedMutation,
  NotificationStatusClickedMutationVariables
>

/**
 * __useNotificationStatusClickedMutation__
 *
 * To run a mutation, you first call `useNotificationStatusClickedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationStatusClickedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationStatusClickedMutation, { data, loading, error }] = useNotificationStatusClickedMutation({
 *   variables: {
 *      notification_id: // value for 'notification_id'
 *   },
 * });
 */
export function useNotificationStatusClickedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationStatusClickedMutation,
    NotificationStatusClickedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    NotificationStatusClickedMutation,
    NotificationStatusClickedMutationVariables
  >(NotificationStatusClickedDocument, options)
}
export type NotificationStatusClickedMutationHookResult = ReturnType<
  typeof useNotificationStatusClickedMutation
>
export type NotificationStatusClickedMutationResult =
  Apollo.MutationResult<NotificationStatusClickedMutation>
export type NotificationStatusClickedMutationOptions =
  Apollo.BaseMutationOptions<
    NotificationStatusClickedMutation,
    NotificationStatusClickedMutationVariables
  >
export const UpdateShopItemDataDocument = gql`
  mutation UpdateShopItemData($input: shop_item_set_input!, $id: uuid!) {
    update_shop_item_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...itemData
    }
  }
  ${ItemDataFragmentDoc}
`
export type UpdateShopItemDataMutationFn = Apollo.MutationFunction<
  UpdateShopItemDataMutation,
  UpdateShopItemDataMutationVariables
>

/**
 * __useUpdateShopItemDataMutation__
 *
 * To run a mutation, you first call `useUpdateShopItemDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopItemDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopItemDataMutation, { data, loading, error }] = useUpdateShopItemDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateShopItemDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShopItemDataMutation,
    UpdateShopItemDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateShopItemDataMutation,
    UpdateShopItemDataMutationVariables
  >(UpdateShopItemDataDocument, options)
}
export type UpdateShopItemDataMutationHookResult = ReturnType<
  typeof useUpdateShopItemDataMutation
>
export type UpdateShopItemDataMutationResult =
  Apollo.MutationResult<UpdateShopItemDataMutation>
export type UpdateShopItemDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateShopItemDataMutation,
  UpdateShopItemDataMutationVariables
>
export const GetShopsListDocument = gql`
  query GetShopsList(
    $shops_limit: Int = 20
    $shops_offset: Int = 0
    $orderBy: [shop_order_by!]
    $cartItemsClause: numeric_comparison_exp! = {}
    $categoryClause: shop_category_enum_comparison_exp! = {
      _nin: [omnishop, social_platform]
    }
    $nameQuery: String_comparison_exp! = {}
  ) {
    shops: shop(
      where: {
        shop_categorizations: { category: $categoryClause }
        slug: { _nin: ["amazon", "target", "walmart"] }
        cart_items_count: $cartItemsClause
        display_name: $nameQuery
      }
      distinct_on: [slug]
      order_by: $orderBy
      limit: $shops_limit
      offset: $shops_offset
    ) {
      id
      key
      slug
      display_name
      favicon
      cover_image
      cart_items_count
    }
    total_results: shop_aggregate(
      where: {
        shop_categorizations: { category: $categoryClause }
        slug: { _nin: ["amazon", "target", "walmart"] }
        cart_items_count: $cartItemsClause
        display_name: $nameQuery
      }
      distinct_on: [slug]
    ) {
      aggregate {
        count
      }
    }
  }
`

/**
 * __useGetShopsListQuery__
 *
 * To run a query within a React component, call `useGetShopsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopsListQuery({
 *   variables: {
 *      shops_limit: // value for 'shops_limit'
 *      shops_offset: // value for 'shops_offset'
 *      orderBy: // value for 'orderBy'
 *      cartItemsClause: // value for 'cartItemsClause'
 *      categoryClause: // value for 'categoryClause'
 *      nameQuery: // value for 'nameQuery'
 *   },
 * });
 */
export function useGetShopsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetShopsListQuery,
    GetShopsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetShopsListQuery, GetShopsListQueryVariables>(
    GetShopsListDocument,
    options
  )
}
export function useGetShopsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopsListQuery,
    GetShopsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetShopsListQuery, GetShopsListQueryVariables>(
    GetShopsListDocument,
    options
  )
}
export type GetShopsListQueryHookResult = ReturnType<
  typeof useGetShopsListQuery
>
export type GetShopsListLazyQueryHookResult = ReturnType<
  typeof useGetShopsListLazyQuery
>
export type GetShopsListQueryResult = Apollo.QueryResult<
  GetShopsListQuery,
  GetShopsListQueryVariables
>
export const InsertSimilarProductsHistoryDocument = gql`
  mutation InsertSimilarProductsHistory(
    $input: similar_products_history_insert_input!
  ) {
    insert_similar_products_history_one(object: $input) {
      id
    }
  }
`
export type InsertSimilarProductsHistoryMutationFn = Apollo.MutationFunction<
  InsertSimilarProductsHistoryMutation,
  InsertSimilarProductsHistoryMutationVariables
>

/**
 * __useInsertSimilarProductsHistoryMutation__
 *
 * To run a mutation, you first call `useInsertSimilarProductsHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSimilarProductsHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSimilarProductsHistoryMutation, { data, loading, error }] = useInsertSimilarProductsHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertSimilarProductsHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertSimilarProductsHistoryMutation,
    InsertSimilarProductsHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertSimilarProductsHistoryMutation,
    InsertSimilarProductsHistoryMutationVariables
  >(InsertSimilarProductsHistoryDocument, options)
}
export type InsertSimilarProductsHistoryMutationHookResult = ReturnType<
  typeof useInsertSimilarProductsHistoryMutation
>
export type InsertSimilarProductsHistoryMutationResult =
  Apollo.MutationResult<InsertSimilarProductsHistoryMutation>
export type InsertSimilarProductsHistoryMutationOptions =
  Apollo.BaseMutationOptions<
    InsertSimilarProductsHistoryMutation,
    InsertSimilarProductsHistoryMutationVariables
  >
export const SubProductClaimsForCollectionDocument = gql`
  subscription SubProductClaimsForCollection($id: uuid!) {
    collection_item_claim(where: { collection_id: { _eq: $id } }) {
      id
      status
      cart_item_id
      user {
        id
        name
      }
    }
  }
`

/**
 * __useSubProductClaimsForCollectionSubscription__
 *
 * To run a query within a React component, call `useSubProductClaimsForCollectionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubProductClaimsForCollectionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubProductClaimsForCollectionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubProductClaimsForCollectionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubProductClaimsForCollectionSubscription,
    SubProductClaimsForCollectionSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubProductClaimsForCollectionSubscription,
    SubProductClaimsForCollectionSubscriptionVariables
  >(SubProductClaimsForCollectionDocument, options)
}
export type SubProductClaimsForCollectionSubscriptionHookResult = ReturnType<
  typeof useSubProductClaimsForCollectionSubscription
>
export type SubProductClaimsForCollectionSubscriptionResult =
  Apollo.SubscriptionResult<SubProductClaimsForCollectionSubscription>
export const SubsUserCartsOnboardingDocument = gql`
  subscription SubsUserCartsOnboarding(
    $user_id: String!
    $last_cart_onboarding: timestamptz!
  ) {
    user_carts_onboarding(
      where: {
        _or: [
          { processed_at: { _gt: $last_cart_onboarding } }
          { processed_at: { _is_null: true } }
        ]
      }
      order_by: { sequential_id: asc }
    ) {
      id
      processing
      processed_at
      shop {
        id
        key
        name
        favicon
        cart_items(
          where: {
            source: { _eq: "onboarding" }
            archived_at: { _is_null: true }
            deleted_at: { _is_null: true }
            created_at: { _gt: $last_cart_onboarding }
            cart: { user: { id: { _eq: $user_id } } }
          }
        ) {
          id
          item {
            display_title
            url
            image
          }
        }
      }
    }
  }
`

/**
 * __useSubsUserCartsOnboardingSubscription__
 *
 * To run a query within a React component, call `useSubsUserCartsOnboardingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubsUserCartsOnboardingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubsUserCartsOnboardingSubscription({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      last_cart_onboarding: // value for 'last_cart_onboarding'
 *   },
 * });
 */
export function useSubsUserCartsOnboardingSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubsUserCartsOnboardingSubscription,
    SubsUserCartsOnboardingSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubsUserCartsOnboardingSubscription,
    SubsUserCartsOnboardingSubscriptionVariables
  >(SubsUserCartsOnboardingDocument, options)
}
export type SubsUserCartsOnboardingSubscriptionHookResult = ReturnType<
  typeof useSubsUserCartsOnboardingSubscription
>
export type SubsUserCartsOnboardingSubscriptionResult =
  Apollo.SubscriptionResult<SubsUserCartsOnboardingSubscription>
export const SubsUserCartsSinceLastOpenedDocument = gql`
  subscription SubsUserCartsSinceLastOpened(
    $lastViewed: timestamptz!
    $id: String!
  ) {
    cart_item_aggregate(
      where: {
        cart: { user: { id: { _eq: $id } } }
        archived_at: { _is_null: true }
        deleted_at: { _is_null: true }
        created_at: { _gt: $lastViewed }
      }
    ) {
      aggregate {
        sum {
          quantity
        }
      }
    }
  }
`

/**
 * __useSubsUserCartsSinceLastOpenedSubscription__
 *
 * To run a query within a React component, call `useSubsUserCartsSinceLastOpenedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubsUserCartsSinceLastOpenedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubsUserCartsSinceLastOpenedSubscription({
 *   variables: {
 *      lastViewed: // value for 'lastViewed'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubsUserCartsSinceLastOpenedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubsUserCartsSinceLastOpenedSubscription,
    SubsUserCartsSinceLastOpenedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubsUserCartsSinceLastOpenedSubscription,
    SubsUserCartsSinceLastOpenedSubscriptionVariables
  >(SubsUserCartsSinceLastOpenedDocument, options)
}
export type SubsUserCartsSinceLastOpenedSubscriptionHookResult = ReturnType<
  typeof useSubsUserCartsSinceLastOpenedSubscription
>
export type SubsUserCartsSinceLastOpenedSubscriptionResult =
  Apollo.SubscriptionResult<SubsUserCartsSinceLastOpenedSubscription>
export const SubsUserFlagsDocument = gql`
  subscription SubsUserFlags($id: String!) {
    user_flags_by_pk(user_id: $id) {
      ...userFlags
    }
  }
  ${UserFlagsFragmentDoc}
`

/**
 * __useSubsUserFlagsSubscription__
 *
 * To run a query within a React component, call `useSubsUserFlagsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubsUserFlagsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubsUserFlagsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubsUserFlagsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubsUserFlagsSubscription,
    SubsUserFlagsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubsUserFlagsSubscription,
    SubsUserFlagsSubscriptionVariables
  >(SubsUserFlagsDocument, options)
}
export type SubsUserFlagsSubscriptionHookResult = ReturnType<
  typeof useSubsUserFlagsSubscription
>
export type SubsUserFlagsSubscriptionResult =
  Apollo.SubscriptionResult<SubsUserFlagsSubscription>
export const ToggleCollectionGiftlistDocument = gql`
  mutation ToggleCollectionGiftlist(
    $collection_id: uuid!
    $giftlist: Boolean!
  ) {
    collection: update_collection_by_pk(
      _set: { giftlist: $giftlist }
      pk_columns: { id: $collection_id }
    ) {
      id
      giftlist
    }
  }
`
export type ToggleCollectionGiftlistMutationFn = Apollo.MutationFunction<
  ToggleCollectionGiftlistMutation,
  ToggleCollectionGiftlistMutationVariables
>

/**
 * __useToggleCollectionGiftlistMutation__
 *
 * To run a mutation, you first call `useToggleCollectionGiftlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCollectionGiftlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCollectionGiftlistMutation, { data, loading, error }] = useToggleCollectionGiftlistMutation({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *      giftlist: // value for 'giftlist'
 *   },
 * });
 */
export function useToggleCollectionGiftlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleCollectionGiftlistMutation,
    ToggleCollectionGiftlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ToggleCollectionGiftlistMutation,
    ToggleCollectionGiftlistMutationVariables
  >(ToggleCollectionGiftlistDocument, options)
}
export type ToggleCollectionGiftlistMutationHookResult = ReturnType<
  typeof useToggleCollectionGiftlistMutation
>
export type ToggleCollectionGiftlistMutationResult =
  Apollo.MutationResult<ToggleCollectionGiftlistMutation>
export type ToggleCollectionGiftlistMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleCollectionGiftlistMutation,
    ToggleCollectionGiftlistMutationVariables
  >
export const ToggleCollectionPrivacyDocument = gql`
  mutation ToggleCollectionPrivacy($collection_id: uuid!, $private: Boolean!) {
    collection: update_collection_by_pk(
      _set: { private: $private }
      pk_columns: { id: $collection_id }
    ) {
      id
      private
    }
  }
`
export type ToggleCollectionPrivacyMutationFn = Apollo.MutationFunction<
  ToggleCollectionPrivacyMutation,
  ToggleCollectionPrivacyMutationVariables
>

/**
 * __useToggleCollectionPrivacyMutation__
 *
 * To run a mutation, you first call `useToggleCollectionPrivacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCollectionPrivacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCollectionPrivacyMutation, { data, loading, error }] = useToggleCollectionPrivacyMutation({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *      private: // value for 'private'
 *   },
 * });
 */
export function useToggleCollectionPrivacyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleCollectionPrivacyMutation,
    ToggleCollectionPrivacyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ToggleCollectionPrivacyMutation,
    ToggleCollectionPrivacyMutationVariables
  >(ToggleCollectionPrivacyDocument, options)
}
export type ToggleCollectionPrivacyMutationHookResult = ReturnType<
  typeof useToggleCollectionPrivacyMutation
>
export type ToggleCollectionPrivacyMutationResult =
  Apollo.MutationResult<ToggleCollectionPrivacyMutation>
export type ToggleCollectionPrivacyMutationOptions = Apollo.BaseMutationOptions<
  ToggleCollectionPrivacyMutation,
  ToggleCollectionPrivacyMutationVariables
>
export const ToggleCollectionTypeDocument = gql`
  mutation ToggleCollectionType(
    $id: uuid!
    $type: collection_type_enum = default
  ) {
    update_collection_by_pk(
      pk_columns: { id: $id }
      _set: { collection_type: $type }
    ) {
      id
      collection_type
    }
  }
`
export type ToggleCollectionTypeMutationFn = Apollo.MutationFunction<
  ToggleCollectionTypeMutation,
  ToggleCollectionTypeMutationVariables
>

/**
 * __useToggleCollectionTypeMutation__
 *
 * To run a mutation, you first call `useToggleCollectionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCollectionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCollectionTypeMutation, { data, loading, error }] = useToggleCollectionTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useToggleCollectionTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleCollectionTypeMutation,
    ToggleCollectionTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ToggleCollectionTypeMutation,
    ToggleCollectionTypeMutationVariables
  >(ToggleCollectionTypeDocument, options)
}
export type ToggleCollectionTypeMutationHookResult = ReturnType<
  typeof useToggleCollectionTypeMutation
>
export type ToggleCollectionTypeMutationResult =
  Apollo.MutationResult<ToggleCollectionTypeMutation>
export type ToggleCollectionTypeMutationOptions = Apollo.BaseMutationOptions<
  ToggleCollectionTypeMutation,
  ToggleCollectionTypeMutationVariables
>
export const UnarchiveCartItemsDocument = gql`
  mutation UnarchiveCartItems($ids: [uuid!]) {
    cartItem: update_cart_item(
      where: { id: { _in: $ids } }
      _set: { archived_at: null }
    ) {
      returning {
        ...cartItemData
      }
    }
  }
  ${CartItemDataFragmentDoc}
`
export type UnarchiveCartItemsMutationFn = Apollo.MutationFunction<
  UnarchiveCartItemsMutation,
  UnarchiveCartItemsMutationVariables
>

/**
 * __useUnarchiveCartItemsMutation__
 *
 * To run a mutation, you first call `useUnarchiveCartItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveCartItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveCartItemsMutation, { data, loading, error }] = useUnarchiveCartItemsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUnarchiveCartItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveCartItemsMutation,
    UnarchiveCartItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnarchiveCartItemsMutation,
    UnarchiveCartItemsMutationVariables
  >(UnarchiveCartItemsDocument, options)
}
export type UnarchiveCartItemsMutationHookResult = ReturnType<
  typeof useUnarchiveCartItemsMutation
>
export type UnarchiveCartItemsMutationResult =
  Apollo.MutationResult<UnarchiveCartItemsMutation>
export type UnarchiveCartItemsMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveCartItemsMutation,
  UnarchiveCartItemsMutationVariables
>
export const UnclaimCollectionCartItemDocument = gql`
  mutation UnclaimCollectionCartItem($claim_id: uuid!) {
    delete_collection_item_claim_by_pk(id: $claim_id) {
      id
    }
  }
`
export type UnclaimCollectionCartItemMutationFn = Apollo.MutationFunction<
  UnclaimCollectionCartItemMutation,
  UnclaimCollectionCartItemMutationVariables
>

/**
 * __useUnclaimCollectionCartItemMutation__
 *
 * To run a mutation, you first call `useUnclaimCollectionCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnclaimCollectionCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unclaimCollectionCartItemMutation, { data, loading, error }] = useUnclaimCollectionCartItemMutation({
 *   variables: {
 *      claim_id: // value for 'claim_id'
 *   },
 * });
 */
export function useUnclaimCollectionCartItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnclaimCollectionCartItemMutation,
    UnclaimCollectionCartItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnclaimCollectionCartItemMutation,
    UnclaimCollectionCartItemMutationVariables
  >(UnclaimCollectionCartItemDocument, options)
}
export type UnclaimCollectionCartItemMutationHookResult = ReturnType<
  typeof useUnclaimCollectionCartItemMutation
>
export type UnclaimCollectionCartItemMutationResult =
  Apollo.MutationResult<UnclaimCollectionCartItemMutation>
export type UnclaimCollectionCartItemMutationOptions =
  Apollo.BaseMutationOptions<
    UnclaimCollectionCartItemMutation,
    UnclaimCollectionCartItemMutationVariables
  >
export const AdminUpdateShopDocument = gql`
  mutation AdminUpdateShop(
    $shopKey: String!
    $name_override: String
    $description: String
    $keywords: [String!]
    $favicon: String
    $cover_image: String
    $banner_image: String
    $editorial_shop_logo: String
    $shop_faqs: jsonb
    $featured: Boolean = false
    $points: Int
  ) {
    update_shop_by_pk(
      pk_columns: { key: $shopKey }
      _set: {
        name_override: $name_override
        description: $description
        keywords: $keywords
        favicon: $favicon
        cover_image: $cover_image
        banner_image: $banner_image
        editorial_shop_logo: $editorial_shop_logo
        shop_faqs: $shop_faqs
        featured: $featured
      }
      _append: { metadata: { points: $points } }
    ) {
      ...adminCategorizationShopData
    }
  }
  ${AdminCategorizationShopDataFragmentDoc}
`
export type AdminUpdateShopMutationFn = Apollo.MutationFunction<
  AdminUpdateShopMutation,
  AdminUpdateShopMutationVariables
>

/**
 * __useAdminUpdateShopMutation__
 *
 * To run a mutation, you first call `useAdminUpdateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateShopMutation, { data, loading, error }] = useAdminUpdateShopMutation({
 *   variables: {
 *      shopKey: // value for 'shopKey'
 *      name_override: // value for 'name_override'
 *      description: // value for 'description'
 *      keywords: // value for 'keywords'
 *      favicon: // value for 'favicon'
 *      cover_image: // value for 'cover_image'
 *      banner_image: // value for 'banner_image'
 *      editorial_shop_logo: // value for 'editorial_shop_logo'
 *      shop_faqs: // value for 'shop_faqs'
 *      featured: // value for 'featured'
 *      points: // value for 'points'
 *   },
 * });
 */
export function useAdminUpdateShopMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateShopMutation,
    AdminUpdateShopMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminUpdateShopMutation,
    AdminUpdateShopMutationVariables
  >(AdminUpdateShopDocument, options)
}
export type AdminUpdateShopMutationHookResult = ReturnType<
  typeof useAdminUpdateShopMutation
>
export type AdminUpdateShopMutationResult =
  Apollo.MutationResult<AdminUpdateShopMutation>
export type AdminUpdateShopMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateShopMutation,
  AdminUpdateShopMutationVariables
>
export const UpdateAffiliateRedirectOverrideLinkDocument = gql`
  mutation UpdateAffiliateRedirectOverrideLink($id: uuid!, $link: String) {
    update_cart_item_by_pk(
      pk_columns: { id: $id }
      _set: { affiliate_redirect_link_override: $link }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`
export type UpdateAffiliateRedirectOverrideLinkMutationFn =
  Apollo.MutationFunction<
    UpdateAffiliateRedirectOverrideLinkMutation,
    UpdateAffiliateRedirectOverrideLinkMutationVariables
  >

/**
 * __useUpdateAffiliateRedirectOverrideLinkMutation__
 *
 * To run a mutation, you first call `useUpdateAffiliateRedirectOverrideLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAffiliateRedirectOverrideLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAffiliateRedirectOverrideLinkMutation, { data, loading, error }] = useUpdateAffiliateRedirectOverrideLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useUpdateAffiliateRedirectOverrideLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAffiliateRedirectOverrideLinkMutation,
    UpdateAffiliateRedirectOverrideLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAffiliateRedirectOverrideLinkMutation,
    UpdateAffiliateRedirectOverrideLinkMutationVariables
  >(UpdateAffiliateRedirectOverrideLinkDocument, options)
}
export type UpdateAffiliateRedirectOverrideLinkMutationHookResult = ReturnType<
  typeof useUpdateAffiliateRedirectOverrideLinkMutation
>
export type UpdateAffiliateRedirectOverrideLinkMutationResult =
  Apollo.MutationResult<UpdateAffiliateRedirectOverrideLinkMutation>
export type UpdateAffiliateRedirectOverrideLinkMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAffiliateRedirectOverrideLinkMutation,
    UpdateAffiliateRedirectOverrideLinkMutationVariables
  >
export const UpdateAppendMetadataShopDocument = gql`
  mutation UpdateAppendMetadataShop($key: String!, $data: jsonb!) {
    update_shop(where: { key: { _eq: $key } }, _append: { metadata: $data }) {
      affected_rows
      returning {
        metadata
      }
    }
  }
`
export type UpdateAppendMetadataShopMutationFn = Apollo.MutationFunction<
  UpdateAppendMetadataShopMutation,
  UpdateAppendMetadataShopMutationVariables
>

/**
 * __useUpdateAppendMetadataShopMutation__
 *
 * To run a mutation, you first call `useUpdateAppendMetadataShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppendMetadataShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppendMetadataShopMutation, { data, loading, error }] = useUpdateAppendMetadataShopMutation({
 *   variables: {
 *      key: // value for 'key'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAppendMetadataShopMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppendMetadataShopMutation,
    UpdateAppendMetadataShopMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAppendMetadataShopMutation,
    UpdateAppendMetadataShopMutationVariables
  >(UpdateAppendMetadataShopDocument, options)
}
export type UpdateAppendMetadataShopMutationHookResult = ReturnType<
  typeof useUpdateAppendMetadataShopMutation
>
export type UpdateAppendMetadataShopMutationResult =
  Apollo.MutationResult<UpdateAppendMetadataShopMutation>
export type UpdateAppendMetadataShopMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAppendMetadataShopMutation,
    UpdateAppendMetadataShopMutationVariables
  >
export const UpdateAppendSchemaMetadataShopItemDocument = gql`
  mutation UpdateAppendSchemaMetadataShopItem($id: uuid!, $data: jsonb!) {
    update_shop_item_by_pk(
      pk_columns: { id: $id }
      _append: { schema_metadata: $data }
    ) {
      schema_metadata
    }
  }
`
export type UpdateAppendSchemaMetadataShopItemMutationFn =
  Apollo.MutationFunction<
    UpdateAppendSchemaMetadataShopItemMutation,
    UpdateAppendSchemaMetadataShopItemMutationVariables
  >

/**
 * __useUpdateAppendSchemaMetadataShopItemMutation__
 *
 * To run a mutation, you first call `useUpdateAppendSchemaMetadataShopItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppendSchemaMetadataShopItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppendSchemaMetadataShopItemMutation, { data, loading, error }] = useUpdateAppendSchemaMetadataShopItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAppendSchemaMetadataShopItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppendSchemaMetadataShopItemMutation,
    UpdateAppendSchemaMetadataShopItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAppendSchemaMetadataShopItemMutation,
    UpdateAppendSchemaMetadataShopItemMutationVariables
  >(UpdateAppendSchemaMetadataShopItemDocument, options)
}
export type UpdateAppendSchemaMetadataShopItemMutationHookResult = ReturnType<
  typeof useUpdateAppendSchemaMetadataShopItemMutation
>
export type UpdateAppendSchemaMetadataShopItemMutationResult =
  Apollo.MutationResult<UpdateAppendSchemaMetadataShopItemMutation>
export type UpdateAppendSchemaMetadataShopItemMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAppendSchemaMetadataShopItemMutation,
    UpdateAppendSchemaMetadataShopItemMutationVariables
  >
export const UpdateBookmarkWorkflowDocument = gql`
  mutation UpdateBookmarkWorkflow(
    $id: uuid!
    $status: workflow_status_enum!
    $statusDetails: String
    $successItemId: uuid
    $successItemType: String
  ) {
    update_workflow_by_pk(
      pk_columns: { id: $id }
      _set: {
        status: $status
        status_details: $statusDetails
        success_item_id: $successItemId
        success_item_type: $successItemType
      }
    ) {
      id
      status
      status_details
    }
  }
`
export type UpdateBookmarkWorkflowMutationFn = Apollo.MutationFunction<
  UpdateBookmarkWorkflowMutation,
  UpdateBookmarkWorkflowMutationVariables
>

/**
 * __useUpdateBookmarkWorkflowMutation__
 *
 * To run a mutation, you first call `useUpdateBookmarkWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookmarkWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookmarkWorkflowMutation, { data, loading, error }] = useUpdateBookmarkWorkflowMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      statusDetails: // value for 'statusDetails'
 *      successItemId: // value for 'successItemId'
 *      successItemType: // value for 'successItemType'
 *   },
 * });
 */
export function useUpdateBookmarkWorkflowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBookmarkWorkflowMutation,
    UpdateBookmarkWorkflowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateBookmarkWorkflowMutation,
    UpdateBookmarkWorkflowMutationVariables
  >(UpdateBookmarkWorkflowDocument, options)
}
export type UpdateBookmarkWorkflowMutationHookResult = ReturnType<
  typeof useUpdateBookmarkWorkflowMutation
>
export type UpdateBookmarkWorkflowMutationResult =
  Apollo.MutationResult<UpdateBookmarkWorkflowMutation>
export type UpdateBookmarkWorkflowMutationOptions = Apollo.BaseMutationOptions<
  UpdateBookmarkWorkflowMutation,
  UpdateBookmarkWorkflowMutationVariables
>
export const SetCartItemHiddenDocument = gql`
  mutation SetCartItemHidden($id: uuid!, $hidden: Boolean!) {
    cartItem: update_cart_item(
      where: { id: { _eq: $id } }
      _set: { hidden: $hidden }
    ) {
      returning {
        id
        hidden
      }
    }
  }
`
export type SetCartItemHiddenMutationFn = Apollo.MutationFunction<
  SetCartItemHiddenMutation,
  SetCartItemHiddenMutationVariables
>

/**
 * __useSetCartItemHiddenMutation__
 *
 * To run a mutation, you first call `useSetCartItemHiddenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCartItemHiddenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCartItemHiddenMutation, { data, loading, error }] = useSetCartItemHiddenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      hidden: // value for 'hidden'
 *   },
 * });
 */
export function useSetCartItemHiddenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCartItemHiddenMutation,
    SetCartItemHiddenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetCartItemHiddenMutation,
    SetCartItemHiddenMutationVariables
  >(SetCartItemHiddenDocument, options)
}
export type SetCartItemHiddenMutationHookResult = ReturnType<
  typeof useSetCartItemHiddenMutation
>
export type SetCartItemHiddenMutationResult =
  Apollo.MutationResult<SetCartItemHiddenMutation>
export type SetCartItemHiddenMutationOptions = Apollo.BaseMutationOptions<
  SetCartItemHiddenMutation,
  SetCartItemHiddenMutationVariables
>
export const SetCartItemRemindMeAtDocument = gql`
  mutation SetCartItemRemindMeAt($id: uuid!) {
    cartItem: update_cart_item(
      where: { id: { _eq: $id } }
      _set: { remind_me_at: "now()" }
    ) {
      returning {
        id
      }
    }
  }
`
export type SetCartItemRemindMeAtMutationFn = Apollo.MutationFunction<
  SetCartItemRemindMeAtMutation,
  SetCartItemRemindMeAtMutationVariables
>

/**
 * __useSetCartItemRemindMeAtMutation__
 *
 * To run a mutation, you first call `useSetCartItemRemindMeAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCartItemRemindMeAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCartItemRemindMeAtMutation, { data, loading, error }] = useSetCartItemRemindMeAtMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetCartItemRemindMeAtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCartItemRemindMeAtMutation,
    SetCartItemRemindMeAtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetCartItemRemindMeAtMutation,
    SetCartItemRemindMeAtMutationVariables
  >(SetCartItemRemindMeAtDocument, options)
}
export type SetCartItemRemindMeAtMutationHookResult = ReturnType<
  typeof useSetCartItemRemindMeAtMutation
>
export type SetCartItemRemindMeAtMutationResult =
  Apollo.MutationResult<SetCartItemRemindMeAtMutation>
export type SetCartItemRemindMeAtMutationOptions = Apollo.BaseMutationOptions<
  SetCartItemRemindMeAtMutation,
  SetCartItemRemindMeAtMutationVariables
>
export const UpdateCollectionMetadataDocument = gql`
  mutation UpdateCollectionMetadata($id: uuid!, $data: jsonb) {
    update_collection_by_pk(
      pk_columns: { id: $id }
      _append: { metadata: $data }
    ) {
      id
      metadata
    }
  }
`
export type UpdateCollectionMetadataMutationFn = Apollo.MutationFunction<
  UpdateCollectionMetadataMutation,
  UpdateCollectionMetadataMutationVariables
>

/**
 * __useUpdateCollectionMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMetadataMutation, { data, loading, error }] = useUpdateCollectionMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCollectionMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectionMetadataMutation,
    UpdateCollectionMetadataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCollectionMetadataMutation,
    UpdateCollectionMetadataMutationVariables
  >(UpdateCollectionMetadataDocument, options)
}
export type UpdateCollectionMetadataMutationHookResult = ReturnType<
  typeof useUpdateCollectionMetadataMutation
>
export type UpdateCollectionMetadataMutationResult =
  Apollo.MutationResult<UpdateCollectionMetadataMutation>
export type UpdateCollectionMetadataMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCollectionMetadataMutation,
    UpdateCollectionMetadataMutationVariables
  >
export const UpdateDiscoveryFeedBacklogDocument = gql`
  mutation UpdateDiscoveryFeedBacklog(
    $feed_item_id: uuid!
    $metadata: jsonb! = {}
  ) {
    update_feed_item_by_pk(
      pk_columns: { id: $feed_item_id }
      _set: { metadata: $metadata }
    ) {
      ...feedItemData
    }
  }
  ${FeedItemDataFragmentDoc}
`
export type UpdateDiscoveryFeedBacklogMutationFn = Apollo.MutationFunction<
  UpdateDiscoveryFeedBacklogMutation,
  UpdateDiscoveryFeedBacklogMutationVariables
>

/**
 * __useUpdateDiscoveryFeedBacklogMutation__
 *
 * To run a mutation, you first call `useUpdateDiscoveryFeedBacklogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscoveryFeedBacklogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscoveryFeedBacklogMutation, { data, loading, error }] = useUpdateDiscoveryFeedBacklogMutation({
 *   variables: {
 *      feed_item_id: // value for 'feed_item_id'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateDiscoveryFeedBacklogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDiscoveryFeedBacklogMutation,
    UpdateDiscoveryFeedBacklogMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDiscoveryFeedBacklogMutation,
    UpdateDiscoveryFeedBacklogMutationVariables
  >(UpdateDiscoveryFeedBacklogDocument, options)
}
export type UpdateDiscoveryFeedBacklogMutationHookResult = ReturnType<
  typeof useUpdateDiscoveryFeedBacklogMutation
>
export type UpdateDiscoveryFeedBacklogMutationResult =
  Apollo.MutationResult<UpdateDiscoveryFeedBacklogMutation>
export type UpdateDiscoveryFeedBacklogMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDiscoveryFeedBacklogMutation,
    UpdateDiscoveryFeedBacklogMutationVariables
  >
export const UpdateDiscoveryFeedSequentialIdDocument = gql`
  mutation UpdateDiscoveryFeedSequentialId(
    $userId: String!
    $newSequentialId: Int!
  ) {
    update_user_flags_by_pk(
      pk_columns: { user_id: $userId }
      _set: {
        discovery_feed_sequential_id_last_set_at: "now()"
        discovery_feed_sequential_id: $newSequentialId
      }
    ) {
      ...userFlags
    }
  }
  ${UserFlagsFragmentDoc}
`
export type UpdateDiscoveryFeedSequentialIdMutationFn = Apollo.MutationFunction<
  UpdateDiscoveryFeedSequentialIdMutation,
  UpdateDiscoveryFeedSequentialIdMutationVariables
>

/**
 * __useUpdateDiscoveryFeedSequentialIdMutation__
 *
 * To run a mutation, you first call `useUpdateDiscoveryFeedSequentialIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscoveryFeedSequentialIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscoveryFeedSequentialIdMutation, { data, loading, error }] = useUpdateDiscoveryFeedSequentialIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      newSequentialId: // value for 'newSequentialId'
 *   },
 * });
 */
export function useUpdateDiscoveryFeedSequentialIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDiscoveryFeedSequentialIdMutation,
    UpdateDiscoveryFeedSequentialIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDiscoveryFeedSequentialIdMutation,
    UpdateDiscoveryFeedSequentialIdMutationVariables
  >(UpdateDiscoveryFeedSequentialIdDocument, options)
}
export type UpdateDiscoveryFeedSequentialIdMutationHookResult = ReturnType<
  typeof useUpdateDiscoveryFeedSequentialIdMutation
>
export type UpdateDiscoveryFeedSequentialIdMutationResult =
  Apollo.MutationResult<UpdateDiscoveryFeedSequentialIdMutation>
export type UpdateDiscoveryFeedSequentialIdMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDiscoveryFeedSequentialIdMutation,
    UpdateDiscoveryFeedSequentialIdMutationVariables
  >
export const UpdateHighResImageDocument = gql`
  mutation UpdateHighResImage($id: uuid!, $highResImage: String!) {
    update_shop_item_by_pk(
      pk_columns: { id: $id }
      _set: { image_highres: $highResImage }
    ) {
      ...itemData
    }
  }
  ${ItemDataFragmentDoc}
`
export type UpdateHighResImageMutationFn = Apollo.MutationFunction<
  UpdateHighResImageMutation,
  UpdateHighResImageMutationVariables
>

/**
 * __useUpdateHighResImageMutation__
 *
 * To run a mutation, you first call `useUpdateHighResImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHighResImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHighResImageMutation, { data, loading, error }] = useUpdateHighResImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      highResImage: // value for 'highResImage'
 *   },
 * });
 */
export function useUpdateHighResImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHighResImageMutation,
    UpdateHighResImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateHighResImageMutation,
    UpdateHighResImageMutationVariables
  >(UpdateHighResImageDocument, options)
}
export type UpdateHighResImageMutationHookResult = ReturnType<
  typeof useUpdateHighResImageMutation
>
export type UpdateHighResImageMutationResult =
  Apollo.MutationResult<UpdateHighResImageMutation>
export type UpdateHighResImageMutationOptions = Apollo.BaseMutationOptions<
  UpdateHighResImageMutation,
  UpdateHighResImageMutationVariables
>
export const UpdateItemsToArchiveDocument = gql`
  mutation UpdateItemsToArchive($ids: [uuid!]) {
    update_cart_item(
      where: { id: { _in: $ids } }
      _set: { archived: true, archived_at: "now()" }
    ) {
      returning {
        id
        archived
        archived_at
      }
    }
  }
`
export type UpdateItemsToArchiveMutationFn = Apollo.MutationFunction<
  UpdateItemsToArchiveMutation,
  UpdateItemsToArchiveMutationVariables
>

/**
 * __useUpdateItemsToArchiveMutation__
 *
 * To run a mutation, you first call `useUpdateItemsToArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemsToArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemsToArchiveMutation, { data, loading, error }] = useUpdateItemsToArchiveMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUpdateItemsToArchiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateItemsToArchiveMutation,
    UpdateItemsToArchiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateItemsToArchiveMutation,
    UpdateItemsToArchiveMutationVariables
  >(UpdateItemsToArchiveDocument, options)
}
export type UpdateItemsToArchiveMutationHookResult = ReturnType<
  typeof useUpdateItemsToArchiveMutation
>
export type UpdateItemsToArchiveMutationResult =
  Apollo.MutationResult<UpdateItemsToArchiveMutation>
export type UpdateItemsToArchiveMutationOptions = Apollo.BaseMutationOptions<
  UpdateItemsToArchiveMutation,
  UpdateItemsToArchiveMutationVariables
>
export const UpdateItemsToUnarchiveDocument = gql`
  mutation UpdateItemsToUnarchive($ids: [uuid!]) {
    update_cart_item(
      where: { id: { _in: $ids } }
      _set: { archived: false, archived_at: null }
    ) {
      returning {
        id
        archived
        archived_at
      }
    }
  }
`
export type UpdateItemsToUnarchiveMutationFn = Apollo.MutationFunction<
  UpdateItemsToUnarchiveMutation,
  UpdateItemsToUnarchiveMutationVariables
>

/**
 * __useUpdateItemsToUnarchiveMutation__
 *
 * To run a mutation, you first call `useUpdateItemsToUnarchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemsToUnarchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemsToUnarchiveMutation, { data, loading, error }] = useUpdateItemsToUnarchiveMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUpdateItemsToUnarchiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateItemsToUnarchiveMutation,
    UpdateItemsToUnarchiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateItemsToUnarchiveMutation,
    UpdateItemsToUnarchiveMutationVariables
  >(UpdateItemsToUnarchiveDocument, options)
}
export type UpdateItemsToUnarchiveMutationHookResult = ReturnType<
  typeof useUpdateItemsToUnarchiveMutation
>
export type UpdateItemsToUnarchiveMutationResult =
  Apollo.MutationResult<UpdateItemsToUnarchiveMutation>
export type UpdateItemsToUnarchiveMutationOptions = Apollo.BaseMutationOptions<
  UpdateItemsToUnarchiveMutation,
  UpdateItemsToUnarchiveMutationVariables
>
export const UpdateShopFaviconDocument = gql`
  mutation UpdateShopFavicon($shopKey: String!, $favicon: String!) {
    update_shop_by_pk(
      pk_columns: { key: $shopKey }
      _set: { favicon: $favicon }
    ) {
      ...shopData
    }
  }
  ${ShopDataFragmentDoc}
`
export type UpdateShopFaviconMutationFn = Apollo.MutationFunction<
  UpdateShopFaviconMutation,
  UpdateShopFaviconMutationVariables
>

/**
 * __useUpdateShopFaviconMutation__
 *
 * To run a mutation, you first call `useUpdateShopFaviconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopFaviconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopFaviconMutation, { data, loading, error }] = useUpdateShopFaviconMutation({
 *   variables: {
 *      shopKey: // value for 'shopKey'
 *      favicon: // value for 'favicon'
 *   },
 * });
 */
export function useUpdateShopFaviconMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShopFaviconMutation,
    UpdateShopFaviconMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateShopFaviconMutation,
    UpdateShopFaviconMutationVariables
  >(UpdateShopFaviconDocument, options)
}
export type UpdateShopFaviconMutationHookResult = ReturnType<
  typeof useUpdateShopFaviconMutation
>
export type UpdateShopFaviconMutationResult =
  Apollo.MutationResult<UpdateShopFaviconMutation>
export type UpdateShopFaviconMutationOptions = Apollo.BaseMutationOptions<
  UpdateShopFaviconMutation,
  UpdateShopFaviconMutationVariables
>
export const UpdateShopItemMetadataDocument = gql`
  mutation UpdateShopItemMetadata($id: uuid!, $metadata: jsonb!) {
    update_shop_item_by_pk(
      pk_columns: { id: $id }
      _append: { metadata: $metadata }
    ) {
      ...itemData
    }
  }
  ${ItemDataFragmentDoc}
`
export type UpdateShopItemMetadataMutationFn = Apollo.MutationFunction<
  UpdateShopItemMetadataMutation,
  UpdateShopItemMetadataMutationVariables
>

/**
 * __useUpdateShopItemMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateShopItemMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopItemMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopItemMetadataMutation, { data, loading, error }] = useUpdateShopItemMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateShopItemMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShopItemMetadataMutation,
    UpdateShopItemMetadataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateShopItemMetadataMutation,
    UpdateShopItemMetadataMutationVariables
  >(UpdateShopItemMetadataDocument, options)
}
export type UpdateShopItemMetadataMutationHookResult = ReturnType<
  typeof useUpdateShopItemMetadataMutation
>
export type UpdateShopItemMetadataMutationResult =
  Apollo.MutationResult<UpdateShopItemMetadataMutation>
export type UpdateShopItemMetadataMutationOptions = Apollo.BaseMutationOptions<
  UpdateShopItemMetadataMutation,
  UpdateShopItemMetadataMutationVariables
>
export const UpdateUserAppVersionDocument = gql`
  mutation UpdateUserAppVersion(
    $app_version: user_app_version_arr_rel_insert_input!
    $with_extension_version: Boolean = false
    $with_ios_version: Boolean = false
    $with_safari_extension_version: Boolean = false
  ) {
    insert_user_one(
      object: { short_id: "fake-short-id", app_versions: $app_version }
      on_conflict: { constraint: user_pkey, update_columns: [updated_at] }
    ) {
      ...userData
    }
  }
  ${UserDataFragmentDoc}
`
export type UpdateUserAppVersionMutationFn = Apollo.MutationFunction<
  UpdateUserAppVersionMutation,
  UpdateUserAppVersionMutationVariables
>

/**
 * __useUpdateUserAppVersionMutation__
 *
 * To run a mutation, you first call `useUpdateUserAppVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAppVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAppVersionMutation, { data, loading, error }] = useUpdateUserAppVersionMutation({
 *   variables: {
 *      app_version: // value for 'app_version'
 *      with_extension_version: // value for 'with_extension_version'
 *      with_ios_version: // value for 'with_ios_version'
 *      with_safari_extension_version: // value for 'with_safari_extension_version'
 *   },
 * });
 */
export function useUpdateUserAppVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAppVersionMutation,
    UpdateUserAppVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserAppVersionMutation,
    UpdateUserAppVersionMutationVariables
  >(UpdateUserAppVersionDocument, options)
}
export type UpdateUserAppVersionMutationHookResult = ReturnType<
  typeof useUpdateUserAppVersionMutation
>
export type UpdateUserAppVersionMutationResult =
  Apollo.MutationResult<UpdateUserAppVersionMutation>
export type UpdateUserAppVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserAppVersionMutation,
  UpdateUserAppVersionMutationVariables
>
export const UpdateUserDetailsDocument = gql`
  mutation UpdateUserDetails(
    $id: String!
    $name: String
    $email: String
    $picture: String
    $anonymous: Boolean!
  ) {
    user: update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        email: $email
        picture: $picture
        anonymous: $anonymous
      }
    ) {
      id
      name
      email
      picture
      anonymous
    }
  }
`
export type UpdateUserDetailsMutationFn = Apollo.MutationFunction<
  UpdateUserDetailsMutation,
  UpdateUserDetailsMutationVariables
>

/**
 * __useUpdateUserDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateUserDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDetailsMutation, { data, loading, error }] = useUpdateUserDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      picture: // value for 'picture'
 *      anonymous: // value for 'anonymous'
 *   },
 * });
 */
export function useUpdateUserDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserDetailsMutation,
    UpdateUserDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserDetailsMutation,
    UpdateUserDetailsMutationVariables
  >(UpdateUserDetailsDocument, options)
}
export type UpdateUserDetailsMutationHookResult = ReturnType<
  typeof useUpdateUserDetailsMutation
>
export type UpdateUserDetailsMutationResult =
  Apollo.MutationResult<UpdateUserDetailsMutation>
export type UpdateUserDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserDetailsMutation,
  UpdateUserDetailsMutationVariables
>
export const UpdateUserEmailDocument = gql`
  mutation UpdateUserEmail($id: String!, $email: String!) {
    user: update_user_by_pk(pk_columns: { id: $id }, _set: { email: $email }) {
      id
      email
    }
  }
`
export type UpdateUserEmailMutationFn = Apollo.MutationFunction<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>

/**
 * __useUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailMutation, { data, loading, error }] = useUpdateUserEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateUserEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >(UpdateUserEmailDocument, options)
}
export type UpdateUserEmailMutationHookResult = ReturnType<
  typeof useUpdateUserEmailMutation
>
export type UpdateUserEmailMutationResult =
  Apollo.MutationResult<UpdateUserEmailMutation>
export type UpdateUserEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>
export const UpdateUserInstalledAtDocument = gql`
  mutation UpdateUserInstalledAt($id: String!) {
    user: update_user_by_pk(
      pk_columns: { id: $id }
      _set: { installed_at: "now()" }
    ) {
      id
      installed_at
    }
  }
`
export type UpdateUserInstalledAtMutationFn = Apollo.MutationFunction<
  UpdateUserInstalledAtMutation,
  UpdateUserInstalledAtMutationVariables
>

/**
 * __useUpdateUserInstalledAtMutation__
 *
 * To run a mutation, you first call `useUpdateUserInstalledAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInstalledAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInstalledAtMutation, { data, loading, error }] = useUpdateUserInstalledAtMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserInstalledAtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserInstalledAtMutation,
    UpdateUserInstalledAtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserInstalledAtMutation,
    UpdateUserInstalledAtMutationVariables
  >(UpdateUserInstalledAtDocument, options)
}
export type UpdateUserInstalledAtMutationHookResult = ReturnType<
  typeof useUpdateUserInstalledAtMutation
>
export type UpdateUserInstalledAtMutationResult =
  Apollo.MutationResult<UpdateUserInstalledAtMutation>
export type UpdateUserInstalledAtMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserInstalledAtMutation,
  UpdateUserInstalledAtMutationVariables
>
export const UpdateUserMetadataDocument = gql`
  mutation UpdateUserMetadata($id: String!, $data: jsonb) {
    update_user_by_pk(pk_columns: { id: $id }, _append: { metadata: $data }) {
      id
      metadata
    }
  }
`
export type UpdateUserMetadataMutationFn = Apollo.MutationFunction<
  UpdateUserMetadataMutation,
  UpdateUserMetadataMutationVariables
>

/**
 * __useUpdateUserMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateUserMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMetadataMutation, { data, loading, error }] = useUpdateUserMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMetadataMutation,
    UpdateUserMetadataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserMetadataMutation,
    UpdateUserMetadataMutationVariables
  >(UpdateUserMetadataDocument, options)
}
export type UpdateUserMetadataMutationHookResult = ReturnType<
  typeof useUpdateUserMetadataMutation
>
export type UpdateUserMetadataMutationResult =
  Apollo.MutationResult<UpdateUserMetadataMutation>
export type UpdateUserMetadataMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMetadataMutation,
  UpdateUserMetadataMutationVariables
>
export const UpdateUserNameDocument = gql`
  mutation UpdateUserName($id: String!, $name: String!) {
    user: update_user_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
      name
    }
  }
`
export type UpdateUserNameMutationFn = Apollo.MutationFunction<
  UpdateUserNameMutation,
  UpdateUserNameMutationVariables
>

/**
 * __useUpdateUserNameMutation__
 *
 * To run a mutation, you first call `useUpdateUserNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNameMutation, { data, loading, error }] = useUpdateUserNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserNameMutation,
    UpdateUserNameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserNameMutation,
    UpdateUserNameMutationVariables
  >(UpdateUserNameDocument, options)
}
export type UpdateUserNameMutationHookResult = ReturnType<
  typeof useUpdateUserNameMutation
>
export type UpdateUserNameMutationResult =
  Apollo.MutationResult<UpdateUserNameMutation>
export type UpdateUserNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserNameMutation,
  UpdateUserNameMutationVariables
>
export const UpdateUserNameAndUsernameDocument = gql`
  mutation UpdateUserNameAndUsername(
    $id: String!
    $username: String!
    $name: String
  ) {
    user: update_user_by_pk(
      pk_columns: { id: $id }
      _set: { username: $username, name: $name }
    ) {
      id
      username
      name
    }
  }
`
export type UpdateUserNameAndUsernameMutationFn = Apollo.MutationFunction<
  UpdateUserNameAndUsernameMutation,
  UpdateUserNameAndUsernameMutationVariables
>

/**
 * __useUpdateUserNameAndUsernameMutation__
 *
 * To run a mutation, you first call `useUpdateUserNameAndUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNameAndUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNameAndUsernameMutation, { data, loading, error }] = useUpdateUserNameAndUsernameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserNameAndUsernameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserNameAndUsernameMutation,
    UpdateUserNameAndUsernameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserNameAndUsernameMutation,
    UpdateUserNameAndUsernameMutationVariables
  >(UpdateUserNameAndUsernameDocument, options)
}
export type UpdateUserNameAndUsernameMutationHookResult = ReturnType<
  typeof useUpdateUserNameAndUsernameMutation
>
export type UpdateUserNameAndUsernameMutationResult =
  Apollo.MutationResult<UpdateUserNameAndUsernameMutation>
export type UpdateUserNameAndUsernameMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserNameAndUsernameMutation,
    UpdateUserNameAndUsernameMutationVariables
  >
export const UpdateUserPossibleEmailDocument = gql`
  mutation UpdateUserPossibleEmail($id: String!, $email: String!) {
    user: update_user_by_pk(
      pk_columns: { id: $id }
      _set: { possible_email: $email }
    ) {
      id
      email
    }
  }
`
export type UpdateUserPossibleEmailMutationFn = Apollo.MutationFunction<
  UpdateUserPossibleEmailMutation,
  UpdateUserPossibleEmailMutationVariables
>

/**
 * __useUpdateUserPossibleEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserPossibleEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPossibleEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPossibleEmailMutation, { data, loading, error }] = useUpdateUserPossibleEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateUserPossibleEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPossibleEmailMutation,
    UpdateUserPossibleEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserPossibleEmailMutation,
    UpdateUserPossibleEmailMutationVariables
  >(UpdateUserPossibleEmailDocument, options)
}
export type UpdateUserPossibleEmailMutationHookResult = ReturnType<
  typeof useUpdateUserPossibleEmailMutation
>
export type UpdateUserPossibleEmailMutationResult =
  Apollo.MutationResult<UpdateUserPossibleEmailMutation>
export type UpdateUserPossibleEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPossibleEmailMutation,
  UpdateUserPossibleEmailMutationVariables
>
export const UpdateUserUsernameDocument = gql`
  mutation UpdateUserUsername($id: String!, $username: String!) {
    user: update_user_by_pk(
      pk_columns: { id: $id }
      _set: { username: $username }
    ) {
      id
      username
    }
  }
`
export type UpdateUserUsernameMutationFn = Apollo.MutationFunction<
  UpdateUserUsernameMutation,
  UpdateUserUsernameMutationVariables
>

/**
 * __useUpdateUserUsernameMutation__
 *
 * To run a mutation, you first call `useUpdateUserUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserUsernameMutation, { data, loading, error }] = useUpdateUserUsernameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUpdateUserUsernameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserUsernameMutation,
    UpdateUserUsernameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserUsernameMutation,
    UpdateUserUsernameMutationVariables
  >(UpdateUserUsernameDocument, options)
}
export type UpdateUserUsernameMutationHookResult = ReturnType<
  typeof useUpdateUserUsernameMutation
>
export type UpdateUserUsernameMutationResult =
  Apollo.MutationResult<UpdateUserUsernameMutation>
export type UpdateUserUsernameMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserUsernameMutation,
  UpdateUserUsernameMutationVariables
>
export const UpsertPendingCollectionInviteForPublicCollectionDocument = gql`
  mutation UpsertPendingCollectionInviteForPublicCollection(
    $collection_id: uuid!
    $user_id: String!
  ) {
    insert_collection_invite_one(
      object: { invitee_id: $user_id, collection_id: $collection_id }
      on_conflict: {
        constraint: collection_invite_collection_id_invitee_id_key
        update_columns: [updated_at]
      }
    ) {
      id
      status
    }
  }
`
export type UpsertPendingCollectionInviteForPublicCollectionMutationFn =
  Apollo.MutationFunction<
    UpsertPendingCollectionInviteForPublicCollectionMutation,
    UpsertPendingCollectionInviteForPublicCollectionMutationVariables
  >

/**
 * __useUpsertPendingCollectionInviteForPublicCollectionMutation__
 *
 * To run a mutation, you first call `useUpsertPendingCollectionInviteForPublicCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPendingCollectionInviteForPublicCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPendingCollectionInviteForPublicCollectionMutation, { data, loading, error }] = useUpsertPendingCollectionInviteForPublicCollectionMutation({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUpsertPendingCollectionInviteForPublicCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertPendingCollectionInviteForPublicCollectionMutation,
    UpsertPendingCollectionInviteForPublicCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertPendingCollectionInviteForPublicCollectionMutation,
    UpsertPendingCollectionInviteForPublicCollectionMutationVariables
  >(UpsertPendingCollectionInviteForPublicCollectionDocument, options)
}
export type UpsertPendingCollectionInviteForPublicCollectionMutationHookResult =
  ReturnType<typeof useUpsertPendingCollectionInviteForPublicCollectionMutation>
export type UpsertPendingCollectionInviteForPublicCollectionMutationResult =
  Apollo.MutationResult<UpsertPendingCollectionInviteForPublicCollectionMutation>
export type UpsertPendingCollectionInviteForPublicCollectionMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertPendingCollectionInviteForPublicCollectionMutation,
    UpsertPendingCollectionInviteForPublicCollectionMutationVariables
  >
export const UpsertUserAppVersionDocument = gql`
  mutation UpsertUserAppVersion($input: [user_app_version_insert_input!]!) {
    insert_user_app_version(
      objects: $input
      on_conflict: {
        constraint: version_user_id_name_version_build_number_platform_key
        update_columns: [updated_at, permissions]
      }
    ) {
      affected_rows
    }
  }
`
export type UpsertUserAppVersionMutationFn = Apollo.MutationFunction<
  UpsertUserAppVersionMutation,
  UpsertUserAppVersionMutationVariables
>

/**
 * __useUpsertUserAppVersionMutation__
 *
 * To run a mutation, you first call `useUpsertUserAppVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserAppVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserAppVersionMutation, { data, loading, error }] = useUpsertUserAppVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertUserAppVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserAppVersionMutation,
    UpsertUserAppVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertUserAppVersionMutation,
    UpsertUserAppVersionMutationVariables
  >(UpsertUserAppVersionDocument, options)
}
export type UpsertUserAppVersionMutationHookResult = ReturnType<
  typeof useUpsertUserAppVersionMutation
>
export type UpsertUserAppVersionMutationResult =
  Apollo.MutationResult<UpsertUserAppVersionMutation>
export type UpsertUserAppVersionMutationOptions = Apollo.BaseMutationOptions<
  UpsertUserAppVersionMutation,
  UpsertUserAppVersionMutationVariables
>
export const UpsertUserPushNotificationTokenDocument = gql`
  mutation UpsertUserPushNotificationToken(
    $objects: [user_push_notification_token_insert_input!]!
  ) {
    insert_user_push_notification_token(
      objects: $objects
      on_conflict: {
        constraint: user_push_notification_token_user_id_token_key
        update_columns: [active]
      }
    ) {
      affected_rows
    }
  }
`
export type UpsertUserPushNotificationTokenMutationFn = Apollo.MutationFunction<
  UpsertUserPushNotificationTokenMutation,
  UpsertUserPushNotificationTokenMutationVariables
>

/**
 * __useUpsertUserPushNotificationTokenMutation__
 *
 * To run a mutation, you first call `useUpsertUserPushNotificationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserPushNotificationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserPushNotificationTokenMutation, { data, loading, error }] = useUpsertUserPushNotificationTokenMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertUserPushNotificationTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserPushNotificationTokenMutation,
    UpsertUserPushNotificationTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertUserPushNotificationTokenMutation,
    UpsertUserPushNotificationTokenMutationVariables
  >(UpsertUserPushNotificationTokenDocument, options)
}
export type UpsertUserPushNotificationTokenMutationHookResult = ReturnType<
  typeof useUpsertUserPushNotificationTokenMutation
>
export type UpsertUserPushNotificationTokenMutationResult =
  Apollo.MutationResult<UpsertUserPushNotificationTokenMutation>
export type UpsertUserPushNotificationTokenMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertUserPushNotificationTokenMutation,
    UpsertUserPushNotificationTokenMutationVariables
  >
export const DeletedAccountMetadataDocument = gql`
  query DeletedAccountMetadata($id: String!) {
    user: user_by_pk(id: $id) {
      id
      created_at
      updated_at
      deleted_at
      active_cart_items_quantity
      carts_aggregate {
        aggregate {
          count
        }
      }
      collections_aggregate(where: { all: { _eq: false } }) {
        aggregate {
          count
        }
      }
      first_app_install
      app_versions {
        created_at
        name
        platform
        version
      }
    }
  }
`

/**
 * __useDeletedAccountMetadataQuery__
 *
 * To run a query within a React component, call `useDeletedAccountMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedAccountMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedAccountMetadataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletedAccountMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    DeletedAccountMetadataQuery,
    DeletedAccountMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    DeletedAccountMetadataQuery,
    DeletedAccountMetadataQueryVariables
  >(DeletedAccountMetadataDocument, options)
}
export function useDeletedAccountMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeletedAccountMetadataQuery,
    DeletedAccountMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DeletedAccountMetadataQuery,
    DeletedAccountMetadataQueryVariables
  >(DeletedAccountMetadataDocument, options)
}
export type DeletedAccountMetadataQueryHookResult = ReturnType<
  typeof useDeletedAccountMetadataQuery
>
export type DeletedAccountMetadataLazyQueryHookResult = ReturnType<
  typeof useDeletedAccountMetadataLazyQuery
>
export type DeletedAccountMetadataQueryResult = Apollo.QueryResult<
  DeletedAccountMetadataQuery,
  DeletedAccountMetadataQueryVariables
>
export const GetCollectionsDataForMobileUserProfilePageDocument = gql`
  query GetCollectionsDataForMobileUserProfilePage(
    $userId: String!
    $limit: Int = 10
    $offset: Int = 0
    $includePaidCollections: Boolean = false
  ) {
    collections: collection(
      where: {
        user_id: { _eq: $userId }
        all: { _eq: false }
        computed_is_visible: { _eq: true }
        cart_items_count: { _gt: 0 }
        paid: { _in: [false, $includePaidCollections] }
      }
      order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
      limit: $limit
      offset: $offset
    ) {
      id
      slug
      name
      state
      computed_is_visible
      description
      cover_image
      cover_image_cached
      total_likes_count
      user {
        ...basicUserData
      }
      has_collaborators
      cart_items_count
      collection_collaborators {
        id
        user {
          ...basicUserData
        }
      }
      preview_collection_items {
        id
        cart_item {
          id
          item_image_override
          item {
            ...itemData
          }
        }
      }
      feed_item {
        ...feedItemData
      }
    }
  }
  ${BasicUserDataFragmentDoc}
  ${ItemDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`

/**
 * __useGetCollectionsDataForMobileUserProfilePageQuery__
 *
 * To run a query within a React component, call `useGetCollectionsDataForMobileUserProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsDataForMobileUserProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsDataForMobileUserProfilePageQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      includePaidCollections: // value for 'includePaidCollections'
 *   },
 * });
 */
export function useGetCollectionsDataForMobileUserProfilePageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionsDataForMobileUserProfilePageQuery,
    GetCollectionsDataForMobileUserProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionsDataForMobileUserProfilePageQuery,
    GetCollectionsDataForMobileUserProfilePageQueryVariables
  >(GetCollectionsDataForMobileUserProfilePageDocument, options)
}
export function useGetCollectionsDataForMobileUserProfilePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionsDataForMobileUserProfilePageQuery,
    GetCollectionsDataForMobileUserProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionsDataForMobileUserProfilePageQuery,
    GetCollectionsDataForMobileUserProfilePageQueryVariables
  >(GetCollectionsDataForMobileUserProfilePageDocument, options)
}
export type GetCollectionsDataForMobileUserProfilePageQueryHookResult =
  ReturnType<typeof useGetCollectionsDataForMobileUserProfilePageQuery>
export type GetCollectionsDataForMobileUserProfilePageLazyQueryHookResult =
  ReturnType<typeof useGetCollectionsDataForMobileUserProfilePageLazyQuery>
export type GetCollectionsDataForMobileUserProfilePageQueryResult =
  Apollo.QueryResult<
    GetCollectionsDataForMobileUserProfilePageQuery,
    GetCollectionsDataForMobileUserProfilePageQueryVariables
  >
export const GetDataForMobileProfilePageDocument = gql`
  query GetDataForMobileProfilePage($id: String!) {
    user: user_by_pk(id: $id) {
      ...userDataForProfileScreen
    }
  }
  ${UserDataForProfileScreenFragmentDoc}
`

/**
 * __useGetDataForMobileProfilePageQuery__
 *
 * To run a query within a React component, call `useGetDataForMobileProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForMobileProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForMobileProfilePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataForMobileProfilePageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDataForMobileProfilePageQuery,
    GetDataForMobileProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDataForMobileProfilePageQuery,
    GetDataForMobileProfilePageQueryVariables
  >(GetDataForMobileProfilePageDocument, options)
}
export function useGetDataForMobileProfilePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataForMobileProfilePageQuery,
    GetDataForMobileProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDataForMobileProfilePageQuery,
    GetDataForMobileProfilePageQueryVariables
  >(GetDataForMobileProfilePageDocument, options)
}
export type GetDataForMobileProfilePageQueryHookResult = ReturnType<
  typeof useGetDataForMobileProfilePageQuery
>
export type GetDataForMobileProfilePageLazyQueryHookResult = ReturnType<
  typeof useGetDataForMobileProfilePageLazyQuery
>
export type GetDataForMobileProfilePageQueryResult = Apollo.QueryResult<
  GetDataForMobileProfilePageQuery,
  GetDataForMobileProfilePageQueryVariables
>
export const GetUserDataForMobileUserProfilePageDocument = gql`
  query GetUserDataForMobileUserProfilePage($userId: String!) {
    user: user_by_pk(id: $userId) {
      ...userDataForProfileScreen
    }
  }
  ${UserDataForProfileScreenFragmentDoc}
`

/**
 * __useGetUserDataForMobileUserProfilePageQuery__
 *
 * To run a query within a React component, call `useGetUserDataForMobileUserProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDataForMobileUserProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDataForMobileUserProfilePageQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserDataForMobileUserProfilePageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserDataForMobileUserProfilePageQuery,
    GetUserDataForMobileUserProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserDataForMobileUserProfilePageQuery,
    GetUserDataForMobileUserProfilePageQueryVariables
  >(GetUserDataForMobileUserProfilePageDocument, options)
}
export function useGetUserDataForMobileUserProfilePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDataForMobileUserProfilePageQuery,
    GetUserDataForMobileUserProfilePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserDataForMobileUserProfilePageQuery,
    GetUserDataForMobileUserProfilePageQueryVariables
  >(GetUserDataForMobileUserProfilePageDocument, options)
}
export type GetUserDataForMobileUserProfilePageQueryHookResult = ReturnType<
  typeof useGetUserDataForMobileUserProfilePageQuery
>
export type GetUserDataForMobileUserProfilePageLazyQueryHookResult = ReturnType<
  typeof useGetUserDataForMobileUserProfilePageLazyQuery
>
export type GetUserDataForMobileUserProfilePageQueryResult = Apollo.QueryResult<
  GetUserDataForMobileUserProfilePageQuery,
  GetUserDataForMobileUserProfilePageQueryVariables
>
export const ProcessAccountDeletionDocument = gql`
  mutation ProcessAccountDeletion($id: String!) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        email: null
        username: $id
        name: "[DELETED]"
        picture: null
        phone: null
      }
    ) {
      id
    }
  }
`
export type ProcessAccountDeletionMutationFn = Apollo.MutationFunction<
  ProcessAccountDeletionMutation,
  ProcessAccountDeletionMutationVariables
>

/**
 * __useProcessAccountDeletionMutation__
 *
 * To run a mutation, you first call `useProcessAccountDeletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessAccountDeletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processAccountDeletionMutation, { data, loading, error }] = useProcessAccountDeletionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessAccountDeletionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProcessAccountDeletionMutation,
    ProcessAccountDeletionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ProcessAccountDeletionMutation,
    ProcessAccountDeletionMutationVariables
  >(ProcessAccountDeletionDocument, options)
}
export type ProcessAccountDeletionMutationHookResult = ReturnType<
  typeof useProcessAccountDeletionMutation
>
export type ProcessAccountDeletionMutationResult =
  Apollo.MutationResult<ProcessAccountDeletionMutation>
export type ProcessAccountDeletionMutationOptions = Apollo.BaseMutationOptions<
  ProcessAccountDeletionMutation,
  ProcessAccountDeletionMutationVariables
>
export const SaveUserProfileDocument = gql`
  mutation SaveUserProfile($id: String!, $user_set_input: user_set_input!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: $user_set_input) {
      ...basicUserData
      bio
      bio_link
      has_claimed_username_computed
      has_set_name_computed
      has_picture_set
    }
  }
  ${BasicUserDataFragmentDoc}
`
export type SaveUserProfileMutationFn = Apollo.MutationFunction<
  SaveUserProfileMutation,
  SaveUserProfileMutationVariables
>

/**
 * __useSaveUserProfileMutation__
 *
 * To run a mutation, you first call `useSaveUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserProfileMutation, { data, loading, error }] = useSaveUserProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user_set_input: // value for 'user_set_input'
 *   },
 * });
 */
export function useSaveUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserProfileMutation,
    SaveUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveUserProfileMutation,
    SaveUserProfileMutationVariables
  >(SaveUserProfileDocument, options)
}
export type SaveUserProfileMutationHookResult = ReturnType<
  typeof useSaveUserProfileMutation
>
export type SaveUserProfileMutationResult =
  Apollo.MutationResult<SaveUserProfileMutation>
export type SaveUserProfileMutationOptions = Apollo.BaseMutationOptions<
  SaveUserProfileMutation,
  SaveUserProfileMutationVariables
>
export const SaveUserProfileLinksDocument = gql`
  mutation SaveUserProfileLinks($link_objects: [user_links_insert_input!]!) {
    insert_user_links(
      objects: $link_objects
      on_conflict: {
        constraint: user_links_user_id_link_type_key
        update_columns: [url]
      }
    ) {
      affected_rows
    }
  }
`
export type SaveUserProfileLinksMutationFn = Apollo.MutationFunction<
  SaveUserProfileLinksMutation,
  SaveUserProfileLinksMutationVariables
>

/**
 * __useSaveUserProfileLinksMutation__
 *
 * To run a mutation, you first call `useSaveUserProfileLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserProfileLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserProfileLinksMutation, { data, loading, error }] = useSaveUserProfileLinksMutation({
 *   variables: {
 *      link_objects: // value for 'link_objects'
 *   },
 * });
 */
export function useSaveUserProfileLinksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserProfileLinksMutation,
    SaveUserProfileLinksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveUserProfileLinksMutation,
    SaveUserProfileLinksMutationVariables
  >(SaveUserProfileLinksDocument, options)
}
export type SaveUserProfileLinksMutationHookResult = ReturnType<
  typeof useSaveUserProfileLinksMutation
>
export type SaveUserProfileLinksMutationResult =
  Apollo.MutationResult<SaveUserProfileLinksMutation>
export type SaveUserProfileLinksMutationOptions = Apollo.BaseMutationOptions<
  SaveUserProfileLinksMutation,
  SaveUserProfileLinksMutationVariables
>
export const SubsUnviewedNotificationCenterItemsDocument = gql`
  subscription SubsUnviewedNotificationCenterItems($id: String!) {
    user_by_pk(id: $id) {
      unviewed_notification_center_items
      mobile_badge_count
    }
  }
`

/**
 * __useSubsUnviewedNotificationCenterItemsSubscription__
 *
 * To run a query within a React component, call `useSubsUnviewedNotificationCenterItemsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubsUnviewedNotificationCenterItemsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubsUnviewedNotificationCenterItemsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubsUnviewedNotificationCenterItemsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubsUnviewedNotificationCenterItemsSubscription,
    SubsUnviewedNotificationCenterItemsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubsUnviewedNotificationCenterItemsSubscription,
    SubsUnviewedNotificationCenterItemsSubscriptionVariables
  >(SubsUnviewedNotificationCenterItemsDocument, options)
}
export type SubsUnviewedNotificationCenterItemsSubscriptionHookResult =
  ReturnType<typeof useSubsUnviewedNotificationCenterItemsSubscription>
export type SubsUnviewedNotificationCenterItemsSubscriptionResult =
  Apollo.SubscriptionResult<SubsUnviewedNotificationCenterItemsSubscription>
export const UpdateUserIosDeviceVersionDocument = gql`
  mutation UpdateUserIOSDeviceVersion(
    $id: String!
    $ios_device_version: String!
  ) {
    update_user_flags_by_pk(
      _set: { ios_device_version: $ios_device_version }
      pk_columns: { user_id: $id }
    ) {
      ...userFlags
    }
  }
  ${UserFlagsFragmentDoc}
`
export type UpdateUserIosDeviceVersionMutationFn = Apollo.MutationFunction<
  UpdateUserIosDeviceVersionMutation,
  UpdateUserIosDeviceVersionMutationVariables
>

/**
 * __useUpdateUserIosDeviceVersionMutation__
 *
 * To run a mutation, you first call `useUpdateUserIosDeviceVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserIosDeviceVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserIosDeviceVersionMutation, { data, loading, error }] = useUpdateUserIosDeviceVersionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ios_device_version: // value for 'ios_device_version'
 *   },
 * });
 */
export function useUpdateUserIosDeviceVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserIosDeviceVersionMutation,
    UpdateUserIosDeviceVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserIosDeviceVersionMutation,
    UpdateUserIosDeviceVersionMutationVariables
  >(UpdateUserIosDeviceVersionDocument, options)
}
export type UpdateUserIosDeviceVersionMutationHookResult = ReturnType<
  typeof useUpdateUserIosDeviceVersionMutation
>
export type UpdateUserIosDeviceVersionMutationResult =
  Apollo.MutationResult<UpdateUserIosDeviceVersionMutation>
export type UpdateUserIosDeviceVersionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserIosDeviceVersionMutation,
    UpdateUserIosDeviceVersionMutationVariables
  >
export const UserUpdateOnboardingStateDocument = gql`
  mutation UserUpdateOnboardingState(
    $id: String!
    $state: user_onboarding_state_enum!
  ) {
    update_user_flags_by_pk(
      _set: { onboarding_state: $state }
      pk_columns: { user_id: $id }
    ) {
      ...userFlags
    }
  }
  ${UserFlagsFragmentDoc}
`
export type UserUpdateOnboardingStateMutationFn = Apollo.MutationFunction<
  UserUpdateOnboardingStateMutation,
  UserUpdateOnboardingStateMutationVariables
>

/**
 * __useUserUpdateOnboardingStateMutation__
 *
 * To run a mutation, you first call `useUserUpdateOnboardingStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateOnboardingStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateOnboardingStateMutation, { data, loading, error }] = useUserUpdateOnboardingStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUserUpdateOnboardingStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserUpdateOnboardingStateMutation,
    UserUpdateOnboardingStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UserUpdateOnboardingStateMutation,
    UserUpdateOnboardingStateMutationVariables
  >(UserUpdateOnboardingStateDocument, options)
}
export type UserUpdateOnboardingStateMutationHookResult = ReturnType<
  typeof useUserUpdateOnboardingStateMutation
>
export type UserUpdateOnboardingStateMutationResult =
  Apollo.MutationResult<UserUpdateOnboardingStateMutation>
export type UserUpdateOnboardingStateMutationOptions =
  Apollo.BaseMutationOptions<
    UserUpdateOnboardingStateMutation,
    UserUpdateOnboardingStateMutationVariables
  >
export const FindShopCategoryDocument = gql`
  query FindShopCategory(
    $category: String!
    $limit: Int = 100
    $offset: Int = 0
  ) {
    shop_category: shop_category_by_pk(value: $category) {
      label
      value
      featured_shop_categorizations: shop_categorizations(
        where: { shop: { featured: { _eq: true } } }
        order_by: { shop: { parsed_take_rate: desc } }
      ) {
        weight
        curation_score
        shop {
          ...shopData
        }
      }
      shop_categorizations(
        where: { shop: { featured: { _eq: false } } }
        limit: $limit
        offset: $offset
        order_by: { shop: { parsed_take_rate: desc } }
      ) {
        weight
        curation_score
        shop {
          ...shopData
        }
      }
    }
  }
  ${ShopDataFragmentDoc}
`

/**
 * __useFindShopCategoryQuery__
 *
 * To run a query within a React component, call `useFindShopCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShopCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShopCategoryQuery({
 *   variables: {
 *      category: // value for 'category'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFindShopCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindShopCategoryQuery,
    FindShopCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindShopCategoryQuery, FindShopCategoryQueryVariables>(
    FindShopCategoryDocument,
    options
  )
}
export function useFindShopCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindShopCategoryQuery,
    FindShopCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindShopCategoryQuery,
    FindShopCategoryQueryVariables
  >(FindShopCategoryDocument, options)
}
export type FindShopCategoryQueryHookResult = ReturnType<
  typeof useFindShopCategoryQuery
>
export type FindShopCategoryLazyQueryHookResult = ReturnType<
  typeof useFindShopCategoryLazyQuery
>
export type FindShopCategoryQueryResult = Apollo.QueryResult<
  FindShopCategoryQuery,
  FindShopCategoryQueryVariables
>
export const GetCartItemsForShopDocument = gql`
  query GetCartItemsForShop($key: String!, $limit: Int = 20, $offset: Int = 0) {
    cart_item_aggregate(
      where: {
        shop_key: { _eq: $key }
        item: {
          _and: [
            { image: { _is_null: false } }
            { image: { _neq: "" } }
            {
              _not: {
                metadata: {
                  _has_keys_all: [
                    "cached_image_failed"
                    "original_image_failed"
                  ]
                }
              }
            }
          ]
        }
      }
      distinct_on: item_id
    ) {
      aggregate {
        count
      }
    }
    cart_item(
      where: {
        shop_key: { _eq: $key }
        item: {
          _and: [
            { image: { _is_null: false } }
            { image: { _neq: "" } }
            {
              _not: {
                metadata: {
                  _has_keys_all: [
                    "cached_image_failed"
                    "original_image_failed"
                  ]
                }
              }
            }
          ]
        }
      }
      distinct_on: item_id
      limit: $limit
      offset: $offset
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`

/**
 * __useGetCartItemsForShopQuery__
 *
 * To run a query within a React component, call `useGetCartItemsForShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemsForShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemsForShopQuery({
 *   variables: {
 *      key: // value for 'key'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCartItemsForShopQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCartItemsForShopQuery,
    GetCartItemsForShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCartItemsForShopQuery,
    GetCartItemsForShopQueryVariables
  >(GetCartItemsForShopDocument, options)
}
export function useGetCartItemsForShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCartItemsForShopQuery,
    GetCartItemsForShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCartItemsForShopQuery,
    GetCartItemsForShopQueryVariables
  >(GetCartItemsForShopDocument, options)
}
export type GetCartItemsForShopQueryHookResult = ReturnType<
  typeof useGetCartItemsForShopQuery
>
export type GetCartItemsForShopLazyQueryHookResult = ReturnType<
  typeof useGetCartItemsForShopLazyQuery
>
export type GetCartItemsForShopQueryResult = Apollo.QueryResult<
  GetCartItemsForShopQuery,
  GetCartItemsForShopQueryVariables
>
export const GetCollectionsForItemDocument = gql`
  query GetCollectionsForItem(
    $itemId: uuid!
    $limit: Int = 10
    $offset: Int = 0
    $itemLimit: Int = 1
  ) {
    collection_aggregate(
      where: {
        computed_is_visible: { _eq: true }
        all: { _eq: false }
        collection_items: { cart_item: { item_id: { _eq: $itemId } } }
      }
    ) {
      aggregate {
        count
      }
    }
    collection(
      where: {
        computed_is_visible: { _eq: true }
        all: { _eq: false }
        collection_items: { cart_item: { item_id: { _eq: $itemId } } }
      }
      limit: $limit
      offset: $offset
      order_by: [
        { updated_at: desc_nulls_last }
        { cart_items_count: desc_nulls_last }
      ]
    ) {
      id
      slug
      name
      user {
        id
        username
        picture
        created_at
      }
      cart_items_count
      collection_items(
        where: {
          cart_item: {
            deleted_at: { _is_null: true }
            item: { has_broken_image: { _eq: false } }
          }
        }
        order_by: { updated_at: desc_nulls_last }
        limit: $itemLimit
      ) {
        id
        cart_item {
          id
          item_image_override
          item {
            id
            image
            image_highres
            cached_image
          }
        }
      }
    }
  }
`

/**
 * __useGetCollectionsForItemQuery__
 *
 * To run a query within a React component, call `useGetCollectionsForItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsForItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsForItemQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      itemLimit: // value for 'itemLimit'
 *   },
 * });
 */
export function useGetCollectionsForItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionsForItemQuery,
    GetCollectionsForItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionsForItemQuery,
    GetCollectionsForItemQueryVariables
  >(GetCollectionsForItemDocument, options)
}
export function useGetCollectionsForItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionsForItemQuery,
    GetCollectionsForItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionsForItemQuery,
    GetCollectionsForItemQueryVariables
  >(GetCollectionsForItemDocument, options)
}
export type GetCollectionsForItemQueryHookResult = ReturnType<
  typeof useGetCollectionsForItemQuery
>
export type GetCollectionsForItemLazyQueryHookResult = ReturnType<
  typeof useGetCollectionsForItemLazyQuery
>
export type GetCollectionsForItemQueryResult = Apollo.QueryResult<
  GetCollectionsForItemQuery,
  GetCollectionsForItemQueryVariables
>
export const GetShopCategoriesForStaticPathsDocument = gql`
  query GetShopCategoriesForStaticPaths {
    shop_categories: shop_category(
      where: {
        shop_categorizations: {
          category: { _nin: [omnishop, travel, social_platform] }
        }
      }
      order_by: { value: asc }
    ) {
      label
      value
    }
  }
`

/**
 * __useGetShopCategoriesForStaticPathsQuery__
 *
 * To run a query within a React component, call `useGetShopCategoriesForStaticPathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopCategoriesForStaticPathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopCategoriesForStaticPathsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShopCategoriesForStaticPathsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetShopCategoriesForStaticPathsQuery,
    GetShopCategoriesForStaticPathsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopCategoriesForStaticPathsQuery,
    GetShopCategoriesForStaticPathsQueryVariables
  >(GetShopCategoriesForStaticPathsDocument, options)
}
export function useGetShopCategoriesForStaticPathsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopCategoriesForStaticPathsQuery,
    GetShopCategoriesForStaticPathsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopCategoriesForStaticPathsQuery,
    GetShopCategoriesForStaticPathsQueryVariables
  >(GetShopCategoriesForStaticPathsDocument, options)
}
export type GetShopCategoriesForStaticPathsQueryHookResult = ReturnType<
  typeof useGetShopCategoriesForStaticPathsQuery
>
export type GetShopCategoriesForStaticPathsLazyQueryHookResult = ReturnType<
  typeof useGetShopCategoriesForStaticPathsLazyQuery
>
export type GetShopCategoriesForStaticPathsQueryResult = Apollo.QueryResult<
  GetShopCategoriesForStaticPathsQuery,
  GetShopCategoriesForStaticPathsQueryVariables
>
export const GetShopItemsForShopDocument = gql`
  query GetShopItemsForShop($slug: name!, $limit: Int = 25, $offset: Int = 0)
  @cached {
    shop_item_aggregate(where: { shop: { slug: { _eq: $slug } } }) {
      aggregate {
        count
      }
    }
    shop_item(
      where: { shop: { slug: { _eq: $slug } } }
      limit: $limit
      offset: $offset
    ) {
      ...itemData
      has_broken_image
    }
  }
  ${ItemDataFragmentDoc}
`

/**
 * __useGetShopItemsForShopQuery__
 *
 * To run a query within a React component, call `useGetShopItemsForShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemsForShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemsForShopQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetShopItemsForShopQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopItemsForShopQuery,
    GetShopItemsForShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopItemsForShopQuery,
    GetShopItemsForShopQueryVariables
  >(GetShopItemsForShopDocument, options)
}
export function useGetShopItemsForShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopItemsForShopQuery,
    GetShopItemsForShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopItemsForShopQuery,
    GetShopItemsForShopQueryVariables
  >(GetShopItemsForShopDocument, options)
}
export type GetShopItemsForShopQueryHookResult = ReturnType<
  typeof useGetShopItemsForShopQuery
>
export type GetShopItemsForShopLazyQueryHookResult = ReturnType<
  typeof useGetShopItemsForShopLazyQuery
>
export type GetShopItemsForShopQueryResult = Apollo.QueryResult<
  GetShopItemsForShopQuery,
  GetShopItemsForShopQueryVariables
>
export const GetUserArchivedCartItemsDocument = gql`
  query GetUserArchivedCartItems(
    $userId: String
    $offset: Int = 0
    $limit: Int = 20
  ) {
    cart_items: cart_item(
      where: {
        deleted_at: { _is_null: true }
        archived_at: { _is_null: false }
        cart: { user_id: { _eq: $userId } }
      }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc_nulls_last }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`

/**
 * __useGetUserArchivedCartItemsQuery__
 *
 * To run a query within a React component, call `useGetUserArchivedCartItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserArchivedCartItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserArchivedCartItemsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserArchivedCartItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserArchivedCartItemsQuery,
    GetUserArchivedCartItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserArchivedCartItemsQuery,
    GetUserArchivedCartItemsQueryVariables
  >(GetUserArchivedCartItemsDocument, options)
}
export function useGetUserArchivedCartItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserArchivedCartItemsQuery,
    GetUserArchivedCartItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserArchivedCartItemsQuery,
    GetUserArchivedCartItemsQueryVariables
  >(GetUserArchivedCartItemsDocument, options)
}
export type GetUserArchivedCartItemsQueryHookResult = ReturnType<
  typeof useGetUserArchivedCartItemsQuery
>
export type GetUserArchivedCartItemsLazyQueryHookResult = ReturnType<
  typeof useGetUserArchivedCartItemsLazyQuery
>
export type GetUserArchivedCartItemsQueryResult = Apollo.QueryResult<
  GetUserArchivedCartItemsQuery,
  GetUserArchivedCartItemsQueryVariables
>
export const FetchSimilarProductsExtensionDocument = gql`
  query FetchSimilarProductsExtension(
    $imageUrl: String!
    $shopItemId: uuid
    $cartItemId: uuid
    $allResults: Boolean = false
    $noCache: Boolean = false
  ) @cached(ttl: 300, refresh: $noCache) {
    similarProducts(
      imageUrl: $imageUrl
      shopItemId: $shopItemId
      cartItemId: $cartItemId
      allResults: $allResults
      noCache: $noCache
    ) {
      position
      title
      link
      source
      source_icon
      shop_popularity
      price {
        value
        extracted_value
        currency
      }
      thumbnail
      points
    }
  }
`

/**
 * __useFetchSimilarProductsExtensionQuery__
 *
 * To run a query within a React component, call `useFetchSimilarProductsExtensionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSimilarProductsExtensionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSimilarProductsExtensionQuery({
 *   variables: {
 *      imageUrl: // value for 'imageUrl'
 *      shopItemId: // value for 'shopItemId'
 *      cartItemId: // value for 'cartItemId'
 *      allResults: // value for 'allResults'
 *      noCache: // value for 'noCache'
 *   },
 * });
 */
export function useFetchSimilarProductsExtensionQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSimilarProductsExtensionQuery,
    FetchSimilarProductsExtensionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchSimilarProductsExtensionQuery,
    FetchSimilarProductsExtensionQueryVariables
  >(FetchSimilarProductsExtensionDocument, options)
}
export function useFetchSimilarProductsExtensionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSimilarProductsExtensionQuery,
    FetchSimilarProductsExtensionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchSimilarProductsExtensionQuery,
    FetchSimilarProductsExtensionQueryVariables
  >(FetchSimilarProductsExtensionDocument, options)
}
export type FetchSimilarProductsExtensionQueryHookResult = ReturnType<
  typeof useFetchSimilarProductsExtensionQuery
>
export type FetchSimilarProductsExtensionLazyQueryHookResult = ReturnType<
  typeof useFetchSimilarProductsExtensionLazyQuery
>
export type FetchSimilarProductsExtensionQueryResult = Apollo.QueryResult<
  FetchSimilarProductsExtensionQuery,
  FetchSimilarProductsExtensionQueryVariables
>
export const FindCheckoutByOrderIdDocument = gql`
  query FindCheckoutByOrderId($order_id: String!) {
    checkout(where: { order_id: { _eq: $order_id } }) {
      id
      order_id
    }
  }
`

/**
 * __useFindCheckoutByOrderIdQuery__
 *
 * To run a query within a React component, call `useFindCheckoutByOrderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCheckoutByOrderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCheckoutByOrderIdQuery({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useFindCheckoutByOrderIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCheckoutByOrderIdQuery,
    FindCheckoutByOrderIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCheckoutByOrderIdQuery,
    FindCheckoutByOrderIdQueryVariables
  >(FindCheckoutByOrderIdDocument, options)
}
export function useFindCheckoutByOrderIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCheckoutByOrderIdQuery,
    FindCheckoutByOrderIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCheckoutByOrderIdQuery,
    FindCheckoutByOrderIdQueryVariables
  >(FindCheckoutByOrderIdDocument, options)
}
export type FindCheckoutByOrderIdQueryHookResult = ReturnType<
  typeof useFindCheckoutByOrderIdQuery
>
export type FindCheckoutByOrderIdLazyQueryHookResult = ReturnType<
  typeof useFindCheckoutByOrderIdLazyQuery
>
export type FindCheckoutByOrderIdQueryResult = Apollo.QueryResult<
  FindCheckoutByOrderIdQuery,
  FindCheckoutByOrderIdQueryVariables
>
export const FindShopByKeyForAffiliateDocument = gql`
  query FindShopByKeyForAffiliate($key: String!) {
    shop(where: { key: { _eq: $key } }) {
      id
      metadata
      parsed_take_rate
    }
  }
`

/**
 * __useFindShopByKeyForAffiliateQuery__
 *
 * To run a query within a React component, call `useFindShopByKeyForAffiliateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShopByKeyForAffiliateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShopByKeyForAffiliateQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useFindShopByKeyForAffiliateQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindShopByKeyForAffiliateQuery,
    FindShopByKeyForAffiliateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindShopByKeyForAffiliateQuery,
    FindShopByKeyForAffiliateQueryVariables
  >(FindShopByKeyForAffiliateDocument, options)
}
export function useFindShopByKeyForAffiliateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindShopByKeyForAffiliateQuery,
    FindShopByKeyForAffiliateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindShopByKeyForAffiliateQuery,
    FindShopByKeyForAffiliateQueryVariables
  >(FindShopByKeyForAffiliateDocument, options)
}
export type FindShopByKeyForAffiliateQueryHookResult = ReturnType<
  typeof useFindShopByKeyForAffiliateQuery
>
export type FindShopByKeyForAffiliateLazyQueryHookResult = ReturnType<
  typeof useFindShopByKeyForAffiliateLazyQuery
>
export type FindShopByKeyForAffiliateQueryResult = Apollo.QueryResult<
  FindShopByKeyForAffiliateQuery,
  FindShopByKeyForAffiliateQueryVariables
>
export const SaveAutoCashbackOptionsDocument = gql`
  mutation SaveAutoCashbackOptions(
    $userId: String!
    $autoCashbackEnabled: Boolean = true
  ) {
    update_user_flags_by_pk(
      pk_columns: { user_id: $userId }
      _set: { auto_cashback_enabled: $autoCashbackEnabled }
    ) {
      user_id
      auto_cashback_enabled
    }
  }
`
export type SaveAutoCashbackOptionsMutationFn = Apollo.MutationFunction<
  SaveAutoCashbackOptionsMutation,
  SaveAutoCashbackOptionsMutationVariables
>

/**
 * __useSaveAutoCashbackOptionsMutation__
 *
 * To run a mutation, you first call `useSaveAutoCashbackOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAutoCashbackOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAutoCashbackOptionsMutation, { data, loading, error }] = useSaveAutoCashbackOptionsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      autoCashbackEnabled: // value for 'autoCashbackEnabled'
 *   },
 * });
 */
export function useSaveAutoCashbackOptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveAutoCashbackOptionsMutation,
    SaveAutoCashbackOptionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveAutoCashbackOptionsMutation,
    SaveAutoCashbackOptionsMutationVariables
  >(SaveAutoCashbackOptionsDocument, options)
}
export type SaveAutoCashbackOptionsMutationHookResult = ReturnType<
  typeof useSaveAutoCashbackOptionsMutation
>
export type SaveAutoCashbackOptionsMutationResult =
  Apollo.MutationResult<SaveAutoCashbackOptionsMutation>
export type SaveAutoCashbackOptionsMutationOptions = Apollo.BaseMutationOptions<
  SaveAutoCashbackOptionsMutation,
  SaveAutoCashbackOptionsMutationVariables
>
export const SaveCheckoutDataDocument = gql`
  mutation SaveCheckoutData($input: checkout_insert_input!) {
    insert_checkout_one(
      object: $input
      on_conflict: {
        constraint: checkout_shop_key_order_id_key
        update_columns: [
          source
          customer_id
          shop_key
          currency
          total_price
          subtotal_price
        ]
      }
    ) {
      id
      order_id
    }
  }
`
export type SaveCheckoutDataMutationFn = Apollo.MutationFunction<
  SaveCheckoutDataMutation,
  SaveCheckoutDataMutationVariables
>

/**
 * __useSaveCheckoutDataMutation__
 *
 * To run a mutation, you first call `useSaveCheckoutDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCheckoutDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCheckoutDataMutation, { data, loading, error }] = useSaveCheckoutDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCheckoutDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCheckoutDataMutation,
    SaveCheckoutDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveCheckoutDataMutation,
    SaveCheckoutDataMutationVariables
  >(SaveCheckoutDataDocument, options)
}
export type SaveCheckoutDataMutationHookResult = ReturnType<
  typeof useSaveCheckoutDataMutation
>
export type SaveCheckoutDataMutationResult =
  Apollo.MutationResult<SaveCheckoutDataMutation>
export type SaveCheckoutDataMutationOptions = Apollo.BaseMutationOptions<
  SaveCheckoutDataMutation,
  SaveCheckoutDataMutationVariables
>
export const UpdateCartItemsCheckoutIdDocument = gql`
  mutation UpdateCartItemsCheckoutId(
    $checkoutId: uuid!
    $userId: String!
    $shopKey: String!
    $itemIds: [String!]!
  ) {
    update_cart_item(
      _set: { checkout_id: $checkoutId }
      where: {
        shop_key: { _eq: $shopKey }
        cart: { user_id: { _eq: $userId } }
        item: { item_id: { _in: $itemIds } }
      }
    ) {
      affected_rows
    }
  }
`
export type UpdateCartItemsCheckoutIdMutationFn = Apollo.MutationFunction<
  UpdateCartItemsCheckoutIdMutation,
  UpdateCartItemsCheckoutIdMutationVariables
>

/**
 * __useUpdateCartItemsCheckoutIdMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemsCheckoutIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemsCheckoutIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemsCheckoutIdMutation, { data, loading, error }] = useUpdateCartItemsCheckoutIdMutation({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *      userId: // value for 'userId'
 *      shopKey: // value for 'shopKey'
 *      itemIds: // value for 'itemIds'
 *   },
 * });
 */
export function useUpdateCartItemsCheckoutIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCartItemsCheckoutIdMutation,
    UpdateCartItemsCheckoutIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCartItemsCheckoutIdMutation,
    UpdateCartItemsCheckoutIdMutationVariables
  >(UpdateCartItemsCheckoutIdDocument, options)
}
export type UpdateCartItemsCheckoutIdMutationHookResult = ReturnType<
  typeof useUpdateCartItemsCheckoutIdMutation
>
export type UpdateCartItemsCheckoutIdMutationResult =
  Apollo.MutationResult<UpdateCartItemsCheckoutIdMutation>
export type UpdateCartItemsCheckoutIdMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCartItemsCheckoutIdMutation,
    UpdateCartItemsCheckoutIdMutationVariables
  >
export const GetShopCartIdDocument = gql`
  query GetShopCartId($shopKey: String!, $userId: String!) {
    cart(where: { shop_key: { _eq: $shopKey }, user_id: { _eq: $userId } }) {
      id
      shop_key
    }
  }
`

/**
 * __useGetShopCartIdQuery__
 *
 * To run a query within a React component, call `useGetShopCartIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopCartIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopCartIdQuery({
 *   variables: {
 *      shopKey: // value for 'shopKey'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetShopCartIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopCartIdQuery,
    GetShopCartIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetShopCartIdQuery, GetShopCartIdQueryVariables>(
    GetShopCartIdDocument,
    options
  )
}
export function useGetShopCartIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopCartIdQuery,
    GetShopCartIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetShopCartIdQuery, GetShopCartIdQueryVariables>(
    GetShopCartIdDocument,
    options
  )
}
export type GetShopCartIdQueryHookResult = ReturnType<
  typeof useGetShopCartIdQuery
>
export type GetShopCartIdLazyQueryHookResult = ReturnType<
  typeof useGetShopCartIdLazyQuery
>
export type GetShopCartIdQueryResult = Apollo.QueryResult<
  GetShopCartIdQuery,
  GetShopCartIdQueryVariables
>
export const SaveCartShopDocument = gql`
  mutation SaveCartShop($cartData: [cart_insert_input!]!) {
    cart: insert_cart(
      objects: $cartData
      on_conflict: {
        constraint: cart_user_id_shop_key_key
        update_columns: [shop_key]
      }
    ) {
      returning {
        id
        shop_key
      }
    }
  }
`
export type SaveCartShopMutationFn = Apollo.MutationFunction<
  SaveCartShopMutation,
  SaveCartShopMutationVariables
>

/**
 * __useSaveCartShopMutation__
 *
 * To run a mutation, you first call `useSaveCartShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCartShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCartShopMutation, { data, loading, error }] = useSaveCartShopMutation({
 *   variables: {
 *      cartData: // value for 'cartData'
 *   },
 * });
 */
export function useSaveCartShopMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCartShopMutation,
    SaveCartShopMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveCartShopMutation,
    SaveCartShopMutationVariables
  >(SaveCartShopDocument, options)
}
export type SaveCartShopMutationHookResult = ReturnType<
  typeof useSaveCartShopMutation
>
export type SaveCartShopMutationResult =
  Apollo.MutationResult<SaveCartShopMutation>
export type SaveCartShopMutationOptions = Apollo.BaseMutationOptions<
  SaveCartShopMutation,
  SaveCartShopMutationVariables
>
export const BatchUpsertUserCartsOnboardingDocument = gql`
  mutation BatchUpsertUserCartsOnboarding(
    $shops: [user_carts_onboarding_insert_input!]!
    $last_cart_onboarding: timestamptz!
  ) {
    insert_user_carts_onboarding(
      objects: $shops
      on_conflict: {
        constraint: user_carts_onboarding_user_id_shop_key_key
        where: {
          _or: [
            { processed_at: { _lt: $last_cart_onboarding } }
            { processed_at: { _is_null: true } }
          ]
        }
        update_columns: [processed_at]
      }
    ) {
      returning {
        id
        processed_at
      }
    }
  }
`
export type BatchUpsertUserCartsOnboardingMutationFn = Apollo.MutationFunction<
  BatchUpsertUserCartsOnboardingMutation,
  BatchUpsertUserCartsOnboardingMutationVariables
>

/**
 * __useBatchUpsertUserCartsOnboardingMutation__
 *
 * To run a mutation, you first call `useBatchUpsertUserCartsOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchUpsertUserCartsOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchUpsertUserCartsOnboardingMutation, { data, loading, error }] = useBatchUpsertUserCartsOnboardingMutation({
 *   variables: {
 *      shops: // value for 'shops'
 *      last_cart_onboarding: // value for 'last_cart_onboarding'
 *   },
 * });
 */
export function useBatchUpsertUserCartsOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchUpsertUserCartsOnboardingMutation,
    BatchUpsertUserCartsOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BatchUpsertUserCartsOnboardingMutation,
    BatchUpsertUserCartsOnboardingMutationVariables
  >(BatchUpsertUserCartsOnboardingDocument, options)
}
export type BatchUpsertUserCartsOnboardingMutationHookResult = ReturnType<
  typeof useBatchUpsertUserCartsOnboardingMutation
>
export type BatchUpsertUserCartsOnboardingMutationResult =
  Apollo.MutationResult<BatchUpsertUserCartsOnboardingMutation>
export type BatchUpsertUserCartsOnboardingMutationOptions =
  Apollo.BaseMutationOptions<
    BatchUpsertUserCartsOnboardingMutation,
    BatchUpsertUserCartsOnboardingMutationVariables
  >
export const CheckedUserHistoryDocument = gql`
  mutation CheckedUserHistory($id: String!) {
    update_user_flags_by_pk(
      _set: { checked_history: true }
      pk_columns: { user_id: $id }
    ) {
      ...userFlags
    }
  }
  ${UserFlagsFragmentDoc}
`
export type CheckedUserHistoryMutationFn = Apollo.MutationFunction<
  CheckedUserHistoryMutation,
  CheckedUserHistoryMutationVariables
>

/**
 * __useCheckedUserHistoryMutation__
 *
 * To run a mutation, you first call `useCheckedUserHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckedUserHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkedUserHistoryMutation, { data, loading, error }] = useCheckedUserHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckedUserHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckedUserHistoryMutation,
    CheckedUserHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CheckedUserHistoryMutation,
    CheckedUserHistoryMutationVariables
  >(CheckedUserHistoryDocument, options)
}
export type CheckedUserHistoryMutationHookResult = ReturnType<
  typeof useCheckedUserHistoryMutation
>
export type CheckedUserHistoryMutationResult =
  Apollo.MutationResult<CheckedUserHistoryMutation>
export type CheckedUserHistoryMutationOptions = Apollo.BaseMutationOptions<
  CheckedUserHistoryMutation,
  CheckedUserHistoryMutationVariables
>
export const GetShopsForOnboardingDocument = gql`
  query GetShopsForOnboarding($shopKeys: [String!]!) {
    shops: shop(
      where: {
        carts: { cart_items: { bookmark: { _eq: false } } }
        key: { _in: $shopKeys }
      }
      order_by: { has_pdp: desc_nulls_last }
    ) {
      key
    }
  }
`

/**
 * __useGetShopsForOnboardingQuery__
 *
 * To run a query within a React component, call `useGetShopsForOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopsForOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopsForOnboardingQuery({
 *   variables: {
 *      shopKeys: // value for 'shopKeys'
 *   },
 * });
 */
export function useGetShopsForOnboardingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopsForOnboardingQuery,
    GetShopsForOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopsForOnboardingQuery,
    GetShopsForOnboardingQueryVariables
  >(GetShopsForOnboardingDocument, options)
}
export function useGetShopsForOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopsForOnboardingQuery,
    GetShopsForOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopsForOnboardingQuery,
    GetShopsForOnboardingQueryVariables
  >(GetShopsForOnboardingDocument, options)
}
export type GetShopsForOnboardingQueryHookResult = ReturnType<
  typeof useGetShopsForOnboardingQuery
>
export type GetShopsForOnboardingLazyQueryHookResult = ReturnType<
  typeof useGetShopsForOnboardingLazyQuery
>
export type GetShopsForOnboardingQueryResult = Apollo.QueryResult<
  GetShopsForOnboardingQuery,
  GetShopsForOnboardingQueryVariables
>
export const SubsForNextUnprocessedShopsDocument = gql`
  subscription SubsForNextUnprocessedShops {
    nextUserCartToOnboard: user_carts_onboarding(
      where: { processed_at: { _is_null: true } }
      order_by: { sequential_id: asc }
      limit: 1
    ) {
      id
      shop_key
    }
  }
`

/**
 * __useSubsForNextUnprocessedShopsSubscription__
 *
 * To run a query within a React component, call `useSubsForNextUnprocessedShopsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubsForNextUnprocessedShopsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubsForNextUnprocessedShopsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubsForNextUnprocessedShopsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubsForNextUnprocessedShopsSubscription,
    SubsForNextUnprocessedShopsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubsForNextUnprocessedShopsSubscription,
    SubsForNextUnprocessedShopsSubscriptionVariables
  >(SubsForNextUnprocessedShopsDocument, options)
}
export type SubsForNextUnprocessedShopsSubscriptionHookResult = ReturnType<
  typeof useSubsForNextUnprocessedShopsSubscription
>
export type SubsForNextUnprocessedShopsSubscriptionResult =
  Apollo.SubscriptionResult<SubsForNextUnprocessedShopsSubscription>
export const UpdateUserCartOnboardingDocument = gql`
  mutation UpdateUserCartOnboarding(
    $id: uuid!
    $processing: Boolean!
    $processedAt: timestamptz!
  ) {
    update_user_carts_onboarding_by_pk(
      _set: { processing: $processing, processed_at: $processedAt }
      pk_columns: { id: $id }
    ) {
      id
      processing
      processed_at
    }
  }
`
export type UpdateUserCartOnboardingMutationFn = Apollo.MutationFunction<
  UpdateUserCartOnboardingMutation,
  UpdateUserCartOnboardingMutationVariables
>

/**
 * __useUpdateUserCartOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateUserCartOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCartOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCartOnboardingMutation, { data, loading, error }] = useUpdateUserCartOnboardingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      processing: // value for 'processing'
 *      processedAt: // value for 'processedAt'
 *   },
 * });
 */
export function useUpdateUserCartOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserCartOnboardingMutation,
    UpdateUserCartOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserCartOnboardingMutation,
    UpdateUserCartOnboardingMutationVariables
  >(UpdateUserCartOnboardingDocument, options)
}
export type UpdateUserCartOnboardingMutationHookResult = ReturnType<
  typeof useUpdateUserCartOnboardingMutation
>
export type UpdateUserCartOnboardingMutationResult =
  Apollo.MutationResult<UpdateUserCartOnboardingMutation>
export type UpdateUserCartOnboardingMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserCartOnboardingMutation,
    UpdateUserCartOnboardingMutationVariables
  >
export const UserCartOnboardingOptInDocument = gql`
  mutation UserCartOnboardingOptIn($id: String!, $checked_history: Boolean!) {
    update_user_flags_by_pk(
      _set: {
        opted_into_cart_onboarding: true
        last_cart_onboarding: "now()"
        checked_history: $checked_history
      }
      pk_columns: { user_id: $id }
    ) {
      ...userFlags
    }
  }
  ${UserFlagsFragmentDoc}
`
export type UserCartOnboardingOptInMutationFn = Apollo.MutationFunction<
  UserCartOnboardingOptInMutation,
  UserCartOnboardingOptInMutationVariables
>

/**
 * __useUserCartOnboardingOptInMutation__
 *
 * To run a mutation, you first call `useUserCartOnboardingOptInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCartOnboardingOptInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCartOnboardingOptInMutation, { data, loading, error }] = useUserCartOnboardingOptInMutation({
 *   variables: {
 *      id: // value for 'id'
 *      checked_history: // value for 'checked_history'
 *   },
 * });
 */
export function useUserCartOnboardingOptInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserCartOnboardingOptInMutation,
    UserCartOnboardingOptInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UserCartOnboardingOptInMutation,
    UserCartOnboardingOptInMutationVariables
  >(UserCartOnboardingOptInDocument, options)
}
export type UserCartOnboardingOptInMutationHookResult = ReturnType<
  typeof useUserCartOnboardingOptInMutation
>
export type UserCartOnboardingOptInMutationResult =
  Apollo.MutationResult<UserCartOnboardingOptInMutation>
export type UserCartOnboardingOptInMutationOptions = Apollo.BaseMutationOptions<
  UserCartOnboardingOptInMutation,
  UserCartOnboardingOptInMutationVariables
>
export const SubscribeToNewDiscoveryFeedItemsCountDocument = gql`
  subscription SubscribeToNewDiscoveryFeedItemsCount($userId: String!) {
    user_by_pk(id: $userId) {
      new_discovery_feed_items_count
      flags {
        user_id
        discovery_feed_sequential_id
      }
    }
  }
`

/**
 * __useSubscribeToNewDiscoveryFeedItemsCountSubscription__
 *
 * To run a query within a React component, call `useSubscribeToNewDiscoveryFeedItemsCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToNewDiscoveryFeedItemsCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToNewDiscoveryFeedItemsCountSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSubscribeToNewDiscoveryFeedItemsCountSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToNewDiscoveryFeedItemsCountSubscription,
    SubscribeToNewDiscoveryFeedItemsCountSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubscribeToNewDiscoveryFeedItemsCountSubscription,
    SubscribeToNewDiscoveryFeedItemsCountSubscriptionVariables
  >(SubscribeToNewDiscoveryFeedItemsCountDocument, options)
}
export type SubscribeToNewDiscoveryFeedItemsCountSubscriptionHookResult =
  ReturnType<typeof useSubscribeToNewDiscoveryFeedItemsCountSubscription>
export type SubscribeToNewDiscoveryFeedItemsCountSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToNewDiscoveryFeedItemsCountSubscription>
export const GetShopsForCategoriesMobileDocument = gql`
  query GetShopsForCategoriesMobile(
    $categories: [String!] = []
    $offset: Int = 0
    $limit: Int = 50
  ) @cached {
    shop_categorized_deals_aggregate(
      where: { categories: { _contains: $categories } }
    ) {
      aggregate {
        count
      }
    }
    shop_categorized_deals(
      where: { categories: { _contains: $categories } }
      order_by: { popularity_rank: desc_nulls_last }
      offset: $offset
      limit: $limit
    ) {
      key
      deals_count
      categories
      shop {
        ...dealShopData
      }
    }
  }
  ${DealShopDataFragmentDoc}
`

/**
 * __useGetShopsForCategoriesMobileQuery__
 *
 * To run a query within a React component, call `useGetShopsForCategoriesMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopsForCategoriesMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopsForCategoriesMobileQuery({
 *   variables: {
 *      categories: // value for 'categories'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetShopsForCategoriesMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetShopsForCategoriesMobileQuery,
    GetShopsForCategoriesMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopsForCategoriesMobileQuery,
    GetShopsForCategoriesMobileQueryVariables
  >(GetShopsForCategoriesMobileDocument, options)
}
export function useGetShopsForCategoriesMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopsForCategoriesMobileQuery,
    GetShopsForCategoriesMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopsForCategoriesMobileQuery,
    GetShopsForCategoriesMobileQueryVariables
  >(GetShopsForCategoriesMobileDocument, options)
}
export type GetShopsForCategoriesMobileQueryHookResult = ReturnType<
  typeof useGetShopsForCategoriesMobileQuery
>
export type GetShopsForCategoriesMobileLazyQueryHookResult = ReturnType<
  typeof useGetShopsForCategoriesMobileLazyQuery
>
export type GetShopsForCategoriesMobileQueryResult = Apollo.QueryResult<
  GetShopsForCategoriesMobileQuery,
  GetShopsForCategoriesMobileQueryVariables
>
export const CreateFreeCreatorSubscriptionMobileDocument = gql`
  mutation CreateFreeCreatorSubscriptionMobile($creatorId: String!) {
    subscribeToCreator(creatorId: $creatorId) {
      user_subscription {
        id
        user_id
        creator_id
        subscription_id
      }
    }
  }
`
export type CreateFreeCreatorSubscriptionMobileMutationFn =
  Apollo.MutationFunction<
    CreateFreeCreatorSubscriptionMobileMutation,
    CreateFreeCreatorSubscriptionMobileMutationVariables
  >

/**
 * __useCreateFreeCreatorSubscriptionMobileMutation__
 *
 * To run a mutation, you first call `useCreateFreeCreatorSubscriptionMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFreeCreatorSubscriptionMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFreeCreatorSubscriptionMobileMutation, { data, loading, error }] = useCreateFreeCreatorSubscriptionMobileMutation({
 *   variables: {
 *      creatorId: // value for 'creatorId'
 *   },
 * });
 */
export function useCreateFreeCreatorSubscriptionMobileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFreeCreatorSubscriptionMobileMutation,
    CreateFreeCreatorSubscriptionMobileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateFreeCreatorSubscriptionMobileMutation,
    CreateFreeCreatorSubscriptionMobileMutationVariables
  >(CreateFreeCreatorSubscriptionMobileDocument, options)
}
export type CreateFreeCreatorSubscriptionMobileMutationHookResult = ReturnType<
  typeof useCreateFreeCreatorSubscriptionMobileMutation
>
export type CreateFreeCreatorSubscriptionMobileMutationResult =
  Apollo.MutationResult<CreateFreeCreatorSubscriptionMobileMutation>
export type CreateFreeCreatorSubscriptionMobileMutationOptions =
  Apollo.BaseMutationOptions<
    CreateFreeCreatorSubscriptionMobileMutation,
    CreateFreeCreatorSubscriptionMobileMutationVariables
  >
export const FetchSimilarProductsMobileDocument = gql`
  query FetchSimilarProductsMobile(
    $imageUrl: String!
    $shopItemId: uuid
    $cartItemId: uuid
    $allResults: Boolean = false
    $noCache: Boolean = false
  ) @cached(ttl: 300, refresh: $noCache) {
    similarProducts(
      imageUrl: $imageUrl
      shopItemId: $shopItemId
      cartItemId: $cartItemId
      allResults: $allResults
      noCache: $noCache
    ) {
      position
      title
      link
      source
      source_icon
      shop_popularity
      points
      price {
        value
        extracted_value
        currency
      }
      thumbnail
    }
  }
`

/**
 * __useFetchSimilarProductsMobileQuery__
 *
 * To run a query within a React component, call `useFetchSimilarProductsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSimilarProductsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSimilarProductsMobileQuery({
 *   variables: {
 *      imageUrl: // value for 'imageUrl'
 *      shopItemId: // value for 'shopItemId'
 *      cartItemId: // value for 'cartItemId'
 *      allResults: // value for 'allResults'
 *      noCache: // value for 'noCache'
 *   },
 * });
 */
export function useFetchSimilarProductsMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSimilarProductsMobileQuery,
    FetchSimilarProductsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchSimilarProductsMobileQuery,
    FetchSimilarProductsMobileQueryVariables
  >(FetchSimilarProductsMobileDocument, options)
}
export function useFetchSimilarProductsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSimilarProductsMobileQuery,
    FetchSimilarProductsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchSimilarProductsMobileQuery,
    FetchSimilarProductsMobileQueryVariables
  >(FetchSimilarProductsMobileDocument, options)
}
export type FetchSimilarProductsMobileQueryHookResult = ReturnType<
  typeof useFetchSimilarProductsMobileQuery
>
export type FetchSimilarProductsMobileLazyQueryHookResult = ReturnType<
  typeof useFetchSimilarProductsMobileLazyQuery
>
export type FetchSimilarProductsMobileQueryResult = Apollo.QueryResult<
  FetchSimilarProductsMobileQuery,
  FetchSimilarProductsMobileQueryVariables
>
export const FindCartItemForEditingMobileDocument = gql`
  query FindCartItemForEditingMobile($id: uuid!, $isOwn: Boolean = false) {
    cart_item: cart_item_by_pk(id: $id) {
      ...cartItemData
      feed_item {
        ...feedItemData
      }
      item {
        ...itemData
        description
        shop {
          ...shopData
        }
      }
      commentable_item_join {
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
  ${CartItemDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
  ${ItemDataFragmentDoc}
  ${ShopDataFragmentDoc}
`

/**
 * __useFindCartItemForEditingMobileQuery__
 *
 * To run a query within a React component, call `useFindCartItemForEditingMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCartItemForEditingMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCartItemForEditingMobileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isOwn: // value for 'isOwn'
 *   },
 * });
 */
export function useFindCartItemForEditingMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCartItemForEditingMobileQuery,
    FindCartItemForEditingMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCartItemForEditingMobileQuery,
    FindCartItemForEditingMobileQueryVariables
  >(FindCartItemForEditingMobileDocument, options)
}
export function useFindCartItemForEditingMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCartItemForEditingMobileQuery,
    FindCartItemForEditingMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCartItemForEditingMobileQuery,
    FindCartItemForEditingMobileQueryVariables
  >(FindCartItemForEditingMobileDocument, options)
}
export type FindCartItemForEditingMobileQueryHookResult = ReturnType<
  typeof useFindCartItemForEditingMobileQuery
>
export type FindCartItemForEditingMobileLazyQueryHookResult = ReturnType<
  typeof useFindCartItemForEditingMobileLazyQuery
>
export type FindCartItemForEditingMobileQueryResult = Apollo.QueryResult<
  FindCartItemForEditingMobileQuery,
  FindCartItemForEditingMobileQueryVariables
>
export const FindCartItemForMobilePdpDocument = gql`
  query FindCartItemForMobilePDP($id: uuid!) {
    cart_item: cart_item_by_pk(id: $id) {
      feed_item {
        ...feedItemData
      }
    }
  }
  ${FeedItemDataFragmentDoc}
`

/**
 * __useFindCartItemForMobilePdpQuery__
 *
 * To run a query within a React component, call `useFindCartItemForMobilePdpQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCartItemForMobilePdpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCartItemForMobilePdpQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindCartItemForMobilePdpQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCartItemForMobilePdpQuery,
    FindCartItemForMobilePdpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCartItemForMobilePdpQuery,
    FindCartItemForMobilePdpQueryVariables
  >(FindCartItemForMobilePdpDocument, options)
}
export function useFindCartItemForMobilePdpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCartItemForMobilePdpQuery,
    FindCartItemForMobilePdpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCartItemForMobilePdpQuery,
    FindCartItemForMobilePdpQueryVariables
  >(FindCartItemForMobilePdpDocument, options)
}
export type FindCartItemForMobilePdpQueryHookResult = ReturnType<
  typeof useFindCartItemForMobilePdpQuery
>
export type FindCartItemForMobilePdpLazyQueryHookResult = ReturnType<
  typeof useFindCartItemForMobilePdpLazyQuery
>
export type FindCartItemForMobilePdpQueryResult = Apollo.QueryResult<
  FindCartItemForMobilePdpQuery,
  FindCartItemForMobilePdpQueryVariables
>
export const FindDealHopGateStatusDocument = gql`
  query findDealHopGateStatus($user_id: String!) {
    deal_hop_gate_by_pk(user_id: $user_id) {
      ...dealHopGateData
    }
  }
  ${DealHopGateDataFragmentDoc}
`

/**
 * __useFindDealHopGateStatusQuery__
 *
 * To run a query within a React component, call `useFindDealHopGateStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDealHopGateStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDealHopGateStatusQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useFindDealHopGateStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindDealHopGateStatusQuery,
    FindDealHopGateStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindDealHopGateStatusQuery,
    FindDealHopGateStatusQueryVariables
  >(FindDealHopGateStatusDocument, options)
}
export function useFindDealHopGateStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindDealHopGateStatusQuery,
    FindDealHopGateStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindDealHopGateStatusQuery,
    FindDealHopGateStatusQueryVariables
  >(FindDealHopGateStatusDocument, options)
}
export type FindDealHopGateStatusQueryHookResult = ReturnType<
  typeof useFindDealHopGateStatusQuery
>
export type FindDealHopGateStatusLazyQueryHookResult = ReturnType<
  typeof useFindDealHopGateStatusLazyQuery
>
export type FindDealHopGateStatusQueryResult = Apollo.QueryResult<
  FindDealHopGateStatusQuery,
  FindDealHopGateStatusQueryVariables
>
export const FindDealHopInvitesDocument = gql`
  query findDealHopInvites($user_id: String!) {
    deal_hop_invites: deal_hop_gate_by_pk(user_id: $user_id) {
      user_id
      deal_hop_invites
    }
  }
`

/**
 * __useFindDealHopInvitesQuery__
 *
 * To run a query within a React component, call `useFindDealHopInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDealHopInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDealHopInvitesQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useFindDealHopInvitesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindDealHopInvitesQuery,
    FindDealHopInvitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindDealHopInvitesQuery,
    FindDealHopInvitesQueryVariables
  >(FindDealHopInvitesDocument, options)
}
export function useFindDealHopInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindDealHopInvitesQuery,
    FindDealHopInvitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindDealHopInvitesQuery,
    FindDealHopInvitesQueryVariables
  >(FindDealHopInvitesDocument, options)
}
export type FindDealHopInvitesQueryHookResult = ReturnType<
  typeof useFindDealHopInvitesQuery
>
export type FindDealHopInvitesLazyQueryHookResult = ReturnType<
  typeof useFindDealHopInvitesLazyQuery
>
export type FindDealHopInvitesQueryResult = Apollo.QueryResult<
  FindDealHopInvitesQuery,
  FindDealHopInvitesQueryVariables
>
export const FindReferrerUserDocument = gql`
  query FindReferrerUser($id: String!) {
    user: user_by_pk(id: $id) {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useFindReferrerUserQuery__
 *
 * To run a query within a React component, call `useFindReferrerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindReferrerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindReferrerUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindReferrerUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindReferrerUserQuery,
    FindReferrerUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindReferrerUserQuery, FindReferrerUserQueryVariables>(
    FindReferrerUserDocument,
    options
  )
}
export function useFindReferrerUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindReferrerUserQuery,
    FindReferrerUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindReferrerUserQuery,
    FindReferrerUserQueryVariables
  >(FindReferrerUserDocument, options)
}
export type FindReferrerUserQueryHookResult = ReturnType<
  typeof useFindReferrerUserQuery
>
export type FindReferrerUserLazyQueryHookResult = ReturnType<
  typeof useFindReferrerUserLazyQuery
>
export type FindReferrerUserQueryResult = Apollo.QueryResult<
  FindReferrerUserQuery,
  FindReferrerUserQueryVariables
>
export const FindShopCategoryByValueMobileDocument = gql`
  query FindShopCategoryByValueMobile($category: String!) {
    shop_category: shop_category_by_pk(value: $category) {
      label
      value
    }
  }
`

/**
 * __useFindShopCategoryByValueMobileQuery__
 *
 * To run a query within a React component, call `useFindShopCategoryByValueMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShopCategoryByValueMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShopCategoryByValueMobileQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useFindShopCategoryByValueMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindShopCategoryByValueMobileQuery,
    FindShopCategoryByValueMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindShopCategoryByValueMobileQuery,
    FindShopCategoryByValueMobileQueryVariables
  >(FindShopCategoryByValueMobileDocument, options)
}
export function useFindShopCategoryByValueMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindShopCategoryByValueMobileQuery,
    FindShopCategoryByValueMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindShopCategoryByValueMobileQuery,
    FindShopCategoryByValueMobileQueryVariables
  >(FindShopCategoryByValueMobileDocument, options)
}
export type FindShopCategoryByValueMobileQueryHookResult = ReturnType<
  typeof useFindShopCategoryByValueMobileQuery
>
export type FindShopCategoryByValueMobileLazyQueryHookResult = ReturnType<
  typeof useFindShopCategoryByValueMobileLazyQuery
>
export type FindShopCategoryByValueMobileQueryResult = Apollo.QueryResult<
  FindShopCategoryByValueMobileQuery,
  FindShopCategoryByValueMobileQueryVariables
>
export const FindUserForCollectionInviteDocument = gql`
  query FindUserForCollectionInvite($id: String!) {
    user: user_by_pk(id: $id) {
      id
      username
      email
    }
  }
`

/**
 * __useFindUserForCollectionInviteQuery__
 *
 * To run a query within a React component, call `useFindUserForCollectionInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserForCollectionInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserForCollectionInviteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindUserForCollectionInviteQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserForCollectionInviteQuery,
    FindUserForCollectionInviteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindUserForCollectionInviteQuery,
    FindUserForCollectionInviteQueryVariables
  >(FindUserForCollectionInviteDocument, options)
}
export function useFindUserForCollectionInviteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserForCollectionInviteQuery,
    FindUserForCollectionInviteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindUserForCollectionInviteQuery,
    FindUserForCollectionInviteQueryVariables
  >(FindUserForCollectionInviteDocument, options)
}
export type FindUserForCollectionInviteQueryHookResult = ReturnType<
  typeof useFindUserForCollectionInviteQuery
>
export type FindUserForCollectionInviteLazyQueryHookResult = ReturnType<
  typeof useFindUserForCollectionInviteLazyQuery
>
export type FindUserForCollectionInviteQueryResult = Apollo.QueryResult<
  FindUserForCollectionInviteQuery,
  FindUserForCollectionInviteQueryVariables
>
export const GetAllCollectionAndItemCommentsDocument = gql`
  query GetAllCollectionAndItemComments(
    $limit: Int = 10
    $offset: Int = 0
    $ids: [uuid!] = ""
  ) {
    comments: comment(
      where: { commentable_item: { item_id: { _in: $ids } } }
      limit: $limit
      offset: $offset
      order_by: { sequential_id: desc }
    ) {
      id
      sequential_id
      comment
      commentable_item_type
      commentable_item {
        cart_item {
          item {
            ...itemData
          }
        }
      }
      created_at
      user {
        ...basicUserData
      }
    }
    comment_aggregate(where: { commentable_item: { item_id: { _in: $ids } } }) {
      aggregate {
        count
      }
    }
  }
  ${ItemDataFragmentDoc}
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetAllCollectionAndItemCommentsQuery__
 *
 * To run a query within a React component, call `useGetAllCollectionAndItemCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCollectionAndItemCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCollectionAndItemCommentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetAllCollectionAndItemCommentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCollectionAndItemCommentsQuery,
    GetAllCollectionAndItemCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAllCollectionAndItemCommentsQuery,
    GetAllCollectionAndItemCommentsQueryVariables
  >(GetAllCollectionAndItemCommentsDocument, options)
}
export function useGetAllCollectionAndItemCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCollectionAndItemCommentsQuery,
    GetAllCollectionAndItemCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAllCollectionAndItemCommentsQuery,
    GetAllCollectionAndItemCommentsQueryVariables
  >(GetAllCollectionAndItemCommentsDocument, options)
}
export type GetAllCollectionAndItemCommentsQueryHookResult = ReturnType<
  typeof useGetAllCollectionAndItemCommentsQuery
>
export type GetAllCollectionAndItemCommentsLazyQueryHookResult = ReturnType<
  typeof useGetAllCollectionAndItemCommentsLazyQuery
>
export type GetAllCollectionAndItemCommentsQueryResult = Apollo.QueryResult<
  GetAllCollectionAndItemCommentsQuery,
  GetAllCollectionAndItemCommentsQueryVariables
>
export const GetBrandDealsMobileDocument = gql`
  query GetBrandDealsMobile(
    $shop_key: String!
    $types: [String!] = []
    $end_date: timestamptz = "now()"
    $limit: Int = 50
  ) @cached {
    shop(where: { key: { _eq: $shop_key } }) {
      ...dealShopData
    }
    fmtc_deal(
      where: {
        shop_key: { _eq: $shop_key }
        end_date: { _gte: $end_date }
        metadata: { _contains: { aTypes: $types } }
      }
      order_by: { rating: desc_nulls_last }
      limit: $limit
    ) {
      ...fmtcData
    }
  }
  ${DealShopDataFragmentDoc}
  ${FmtcDataFragmentDoc}
`

/**
 * __useGetBrandDealsMobileQuery__
 *
 * To run a query within a React component, call `useGetBrandDealsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandDealsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandDealsMobileQuery({
 *   variables: {
 *      shop_key: // value for 'shop_key'
 *      types: // value for 'types'
 *      end_date: // value for 'end_date'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetBrandDealsMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBrandDealsMobileQuery,
    GetBrandDealsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetBrandDealsMobileQuery,
    GetBrandDealsMobileQueryVariables
  >(GetBrandDealsMobileDocument, options)
}
export function useGetBrandDealsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrandDealsMobileQuery,
    GetBrandDealsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBrandDealsMobileQuery,
    GetBrandDealsMobileQueryVariables
  >(GetBrandDealsMobileDocument, options)
}
export type GetBrandDealsMobileQueryHookResult = ReturnType<
  typeof useGetBrandDealsMobileQuery
>
export type GetBrandDealsMobileLazyQueryHookResult = ReturnType<
  typeof useGetBrandDealsMobileLazyQuery
>
export type GetBrandDealsMobileQueryResult = Apollo.QueryResult<
  GetBrandDealsMobileQuery,
  GetBrandDealsMobileQueryVariables
>
export const GetBrandDealsYouLoveMobileDocument = gql`
  query GetBrandDealsYouLoveMobile(
    $categories_override: [String!] = "{}"
    $shop_categories: [shop_category_enum!] = []
    $include_personalized: Boolean = false
    $limit: Int = 5
  ) {
    collection_by_pk(id: "a2a1516e-eed2-4172-a88a-d7719b6399f1") {
      personalized_collection_items: collection_items(
        where: {
          cart_item: {
            _and: [
              {
                _or: [
                  { deal_ends_at: { _gt: "now()" } }
                  { deal_ends_at: { _is_null: true } }
                ]
              }
              {
                _or: [
                  { categories_override: { _contains: $categories_override } }
                  {
                    shop: {
                      shop_categorizations: {
                        category: { _in: $shop_categories }
                      }
                    }
                  }
                ]
              }
            ]
          }
        }
        order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
        limit: $limit
      ) @include(if: $include_personalized) {
        ...brandDealsCollectionItemData
      }
      all_collection_items: collection_items(
        where: {
          cart_item: {
            _and: [
              {
                _or: [
                  { deal_ends_at: { _gt: "now()" } }
                  { deal_ends_at: { _is_null: true } }
                ]
              }
              {
                _not: {
                  categories_override: { _contains: $categories_override }
                  shop: {
                    shop_categorizations: {
                      category: { _in: $shop_categories }
                    }
                  }
                }
              }
            ]
          }
        }
        order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
        limit: $limit
      ) {
        ...brandDealsCollectionItemData
      }
    }
  }
  ${BrandDealsCollectionItemDataFragmentDoc}
`

/**
 * __useGetBrandDealsYouLoveMobileQuery__
 *
 * To run a query within a React component, call `useGetBrandDealsYouLoveMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandDealsYouLoveMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandDealsYouLoveMobileQuery({
 *   variables: {
 *      categories_override: // value for 'categories_override'
 *      shop_categories: // value for 'shop_categories'
 *      include_personalized: // value for 'include_personalized'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetBrandDealsYouLoveMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBrandDealsYouLoveMobileQuery,
    GetBrandDealsYouLoveMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetBrandDealsYouLoveMobileQuery,
    GetBrandDealsYouLoveMobileQueryVariables
  >(GetBrandDealsYouLoveMobileDocument, options)
}
export function useGetBrandDealsYouLoveMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrandDealsYouLoveMobileQuery,
    GetBrandDealsYouLoveMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBrandDealsYouLoveMobileQuery,
    GetBrandDealsYouLoveMobileQueryVariables
  >(GetBrandDealsYouLoveMobileDocument, options)
}
export type GetBrandDealsYouLoveMobileQueryHookResult = ReturnType<
  typeof useGetBrandDealsYouLoveMobileQuery
>
export type GetBrandDealsYouLoveMobileLazyQueryHookResult = ReturnType<
  typeof useGetBrandDealsYouLoveMobileLazyQuery
>
export type GetBrandDealsYouLoveMobileQueryResult = Apollo.QueryResult<
  GetBrandDealsYouLoveMobileQuery,
  GetBrandDealsYouLoveMobileQueryVariables
>
export const GetCarrotFeaturedCollectionsDocument = gql`
  query GetCarrotFeaturedCollections(
    $offset: Int = 0
    $limit: Int = 5
    $collectionItemsCount: Int = 10
    $include_seo_fields: Boolean = false
  ) @cached {
    collections: collection(
      where: { user: {}, curation: { _eq: true } }
      offset: $offset
      limit: $limit
      order_by: { updated_at: desc_nulls_last }
    ) {
      ...collectionData
      collection_items(limit: $collectionItemsCount) {
        id
        cart_item {
          id
          display_title
          image
          shop_key
        }
      }
    }
  }
  ${CollectionDataFragmentDoc}
`

/**
 * __useGetCarrotFeaturedCollectionsQuery__
 *
 * To run a query within a React component, call `useGetCarrotFeaturedCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarrotFeaturedCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarrotFeaturedCollectionsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useGetCarrotFeaturedCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCarrotFeaturedCollectionsQuery,
    GetCarrotFeaturedCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCarrotFeaturedCollectionsQuery,
    GetCarrotFeaturedCollectionsQueryVariables
  >(GetCarrotFeaturedCollectionsDocument, options)
}
export function useGetCarrotFeaturedCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCarrotFeaturedCollectionsQuery,
    GetCarrotFeaturedCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCarrotFeaturedCollectionsQuery,
    GetCarrotFeaturedCollectionsQueryVariables
  >(GetCarrotFeaturedCollectionsDocument, options)
}
export type GetCarrotFeaturedCollectionsQueryHookResult = ReturnType<
  typeof useGetCarrotFeaturedCollectionsQuery
>
export type GetCarrotFeaturedCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetCarrotFeaturedCollectionsLazyQuery
>
export type GetCarrotFeaturedCollectionsQueryResult = Apollo.QueryResult<
  GetCarrotFeaturedCollectionsQuery,
  GetCarrotFeaturedCollectionsQueryVariables
>
export const GetCollectionItemsMobileDocument = gql`
  query GetCollectionItemsMobile(
    $collectionId: uuid!
    $limit: Int = 8
    $offset: Int = 0
    $include_seo_fields: Boolean = true
  ) {
    collection_cart_item_aggregate(
      where: {
        collection_id: { _eq: $collectionId }
        cart_item: { deleted_at: { _is_null: true } }
      }
    ) {
      aggregate {
        count
      }
    }
    collection_cart_item(
      where: {
        collection_id: { _eq: $collectionId }
        cart_item: { deleted_at: { _is_null: true } }
      }
      order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
      limit: $limit
      offset: $offset
    ) {
      id
      order
      created_at
      updated_at
      cart_item {
        ...cartItemData
        feed_item {
          ...feedItemData
        }
        commentable_item_join {
          comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
            id
            comment
            user {
              username
              picture
            }
          }
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
  ${CartItemDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`

/**
 * __useGetCollectionItemsMobileQuery__
 *
 * To run a query within a React component, call `useGetCollectionItemsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionItemsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionItemsMobileQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useGetCollectionItemsMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionItemsMobileQuery,
    GetCollectionItemsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionItemsMobileQuery,
    GetCollectionItemsMobileQueryVariables
  >(GetCollectionItemsMobileDocument, options)
}
export function useGetCollectionItemsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionItemsMobileQuery,
    GetCollectionItemsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionItemsMobileQuery,
    GetCollectionItemsMobileQueryVariables
  >(GetCollectionItemsMobileDocument, options)
}
export type GetCollectionItemsMobileQueryHookResult = ReturnType<
  typeof useGetCollectionItemsMobileQuery
>
export type GetCollectionItemsMobileLazyQueryHookResult = ReturnType<
  typeof useGetCollectionItemsMobileLazyQuery
>
export type GetCollectionItemsMobileQueryResult = Apollo.QueryResult<
  GetCollectionItemsMobileQuery,
  GetCollectionItemsMobileQueryVariables
>
export const GetCommentableItemCommentsDocument = gql`
  query GetCommentableItemComments(
    $id: uuid!
    $type: commentable_item_type_enum!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    comments: comment(
      where: {
        commentable_item: { item_id: { _eq: $id }, item_type: { _eq: $type } }
      }
      limit: $limit
      offset: $offset
      order_by: { sequential_id: desc }
    ) {
      id
      sequential_id
      comment
      created_at
      user {
        ...basicUserData
      }
    }
    comment_aggregate(
      where: {
        commentable_item: { item_id: { _eq: $id }, item_type: { _eq: $type } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetCommentableItemCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentableItemCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentableItemCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentableItemCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCommentableItemCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommentableItemCommentsQuery,
    GetCommentableItemCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCommentableItemCommentsQuery,
    GetCommentableItemCommentsQueryVariables
  >(GetCommentableItemCommentsDocument, options)
}
export function useGetCommentableItemCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommentableItemCommentsQuery,
    GetCommentableItemCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCommentableItemCommentsQuery,
    GetCommentableItemCommentsQueryVariables
  >(GetCommentableItemCommentsDocument, options)
}
export type GetCommentableItemCommentsQueryHookResult = ReturnType<
  typeof useGetCommentableItemCommentsQuery
>
export type GetCommentableItemCommentsLazyQueryHookResult = ReturnType<
  typeof useGetCommentableItemCommentsLazyQuery
>
export type GetCommentableItemCommentsQueryResult = Apollo.QueryResult<
  GetCommentableItemCommentsQuery,
  GetCommentableItemCommentsQueryVariables
>
export const GetContactConnectionsDocument = gql`
  query GetContactConnections($limit: Int = 10, $offsetConnections: Int = 0) {
    contactConnections(limit: $limit, offset: $offsetConnections) {
      contact_id
      friends_on_platform
    }
  }
`

/**
 * __useGetContactConnectionsQuery__
 *
 * To run a query within a React component, call `useGetContactConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactConnectionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offsetConnections: // value for 'offsetConnections'
 *   },
 * });
 */
export function useGetContactConnectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContactConnectionsQuery,
    GetContactConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContactConnectionsQuery,
    GetContactConnectionsQueryVariables
  >(GetContactConnectionsDocument, options)
}
export function useGetContactConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactConnectionsQuery,
    GetContactConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContactConnectionsQuery,
    GetContactConnectionsQueryVariables
  >(GetContactConnectionsDocument, options)
}
export type GetContactConnectionsQueryHookResult = ReturnType<
  typeof useGetContactConnectionsQuery
>
export type GetContactConnectionsLazyQueryHookResult = ReturnType<
  typeof useGetContactConnectionsLazyQuery
>
export type GetContactConnectionsQueryResult = Apollo.QueryResult<
  GetContactConnectionsQuery,
  GetContactConnectionsQueryVariables
>
export const GetCreatorsToSubscribeRandomMobileDocument = gql`
  query getCreatorsToSubscribeRandomMobile(
    $exclude_usernames: [String!] = []
    $seed: seed_float = "0.0"
    $limit: Int = 15
    $include_other_creators: Boolean = true
  ) {
    vip_creators: creator_subscribers_random(
      where: {
        _and: [
          { username: { _nin: $exclude_usernames } }
          { username: { _niregex: "(test|carrot4)" } }
          { subscription_status: { _eq: "none" } }
          { icon_order: { _lt: 0 } }
        ]
      }
      args: { seed: $seed }
      limit: $limit
    ) {
      ...basicUserData
    }
    other_creators: creator_subscribers_random(
      where: {
        _and: [
          { username: { _nin: $exclude_usernames } }
          { username: { _niregex: "(test|carrot4)" } }
          { subscription_status: { _eq: "none" } }
          { icon_order: { _gte: 0 } }
        ]
      }
      args: { seed: $seed }
      limit: $limit
    ) @include(if: $include_other_creators) {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetCreatorsToSubscribeRandomMobileQuery__
 *
 * To run a query within a React component, call `useGetCreatorsToSubscribeRandomMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatorsToSubscribeRandomMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatorsToSubscribeRandomMobileQuery({
 *   variables: {
 *      exclude_usernames: // value for 'exclude_usernames'
 *      seed: // value for 'seed'
 *      limit: // value for 'limit'
 *      include_other_creators: // value for 'include_other_creators'
 *   },
 * });
 */
export function useGetCreatorsToSubscribeRandomMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCreatorsToSubscribeRandomMobileQuery,
    GetCreatorsToSubscribeRandomMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCreatorsToSubscribeRandomMobileQuery,
    GetCreatorsToSubscribeRandomMobileQueryVariables
  >(GetCreatorsToSubscribeRandomMobileDocument, options)
}
export function useGetCreatorsToSubscribeRandomMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreatorsToSubscribeRandomMobileQuery,
    GetCreatorsToSubscribeRandomMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCreatorsToSubscribeRandomMobileQuery,
    GetCreatorsToSubscribeRandomMobileQueryVariables
  >(GetCreatorsToSubscribeRandomMobileDocument, options)
}
export type GetCreatorsToSubscribeRandomMobileQueryHookResult = ReturnType<
  typeof useGetCreatorsToSubscribeRandomMobileQuery
>
export type GetCreatorsToSubscribeRandomMobileLazyQueryHookResult = ReturnType<
  typeof useGetCreatorsToSubscribeRandomMobileLazyQuery
>
export type GetCreatorsToSubscribeRandomMobileQueryResult = Apollo.QueryResult<
  GetCreatorsToSubscribeRandomMobileQuery,
  GetCreatorsToSubscribeRandomMobileQueryVariables
>
export const GetDealsBlocksDocument = gql`
  query getDealsBlocks($limit: Int = 1) {
    mobile_home(
      where: {
        enabled: { _eq: false }
        active: { _eq: true }
        type: { _eq: "deals" }
      }
      limit: $limit
      order_by: [{ order: asc_nulls_last }, { created_at: desc_nulls_last }]
    ) {
      id
      order
      asset_url
      link_url
    }
  }
`

/**
 * __useGetDealsBlocksQuery__
 *
 * To run a query within a React component, call `useGetDealsBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealsBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealsBlocksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDealsBlocksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDealsBlocksQuery,
    GetDealsBlocksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDealsBlocksQuery, GetDealsBlocksQueryVariables>(
    GetDealsBlocksDocument,
    options
  )
}
export function useGetDealsBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDealsBlocksQuery,
    GetDealsBlocksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDealsBlocksQuery, GetDealsBlocksQueryVariables>(
    GetDealsBlocksDocument,
    options
  )
}
export type GetDealsBlocksQueryHookResult = ReturnType<
  typeof useGetDealsBlocksQuery
>
export type GetDealsBlocksLazyQueryHookResult = ReturnType<
  typeof useGetDealsBlocksLazyQuery
>
export type GetDealsBlocksQueryResult = Apollo.QueryResult<
  GetDealsBlocksQuery,
  GetDealsBlocksQueryVariables
>
export const GetDealsOfTheDayMobileDocument = gql`
  query GetDealsOfTheDayMobile($limit: Int = 3) @cached {
    collection_by_pk(id: "26f1573c-9c3d-4e39-8575-e48007eecdf9") {
      collection_items(
        where: {
          cart_item: {
            pricing: { _has_keys_all: ["price", "retailPrice", "currency"] }
          }
        }
        order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
        limit: $limit
      ) {
        id
        cart_item {
          ...dealOfTheDayCartItemData
        }
      }
    }
  }
  ${DealOfTheDayCartItemDataFragmentDoc}
`

/**
 * __useGetDealsOfTheDayMobileQuery__
 *
 * To run a query within a React component, call `useGetDealsOfTheDayMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealsOfTheDayMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealsOfTheDayMobileQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDealsOfTheDayMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDealsOfTheDayMobileQuery,
    GetDealsOfTheDayMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDealsOfTheDayMobileQuery,
    GetDealsOfTheDayMobileQueryVariables
  >(GetDealsOfTheDayMobileDocument, options)
}
export function useGetDealsOfTheDayMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDealsOfTheDayMobileQuery,
    GetDealsOfTheDayMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDealsOfTheDayMobileQuery,
    GetDealsOfTheDayMobileQueryVariables
  >(GetDealsOfTheDayMobileDocument, options)
}
export type GetDealsOfTheDayMobileQueryHookResult = ReturnType<
  typeof useGetDealsOfTheDayMobileQuery
>
export type GetDealsOfTheDayMobileLazyQueryHookResult = ReturnType<
  typeof useGetDealsOfTheDayMobileLazyQuery
>
export type GetDealsOfTheDayMobileQueryResult = Apollo.QueryResult<
  GetDealsOfTheDayMobileQuery,
  GetDealsOfTheDayMobileQueryVariables
>
export const GetDiscountedForYouMobileDocument = gql`
  query GetDiscountedForYouMobile(
    $categories_override: [String!] = "{}"
    $shop_categories: [shop_category_enum!] = []
    $limit: Int = 5
  ) @cached {
    collection_by_pk(id: "16b81546-3531-4723-a2d1-01b474d18e7c") {
      personalized_collection_items: collection_items(
        where: {
          cart_item: {
            points: { _is_null: false }
            _or: [
              { categories_override: { _contains: $categories_override } }
              {
                shop: {
                  shop_categorizations: { category: { _in: $shop_categories } }
                }
              }
            ]
          }
        }
        order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
        limit: $limit
      ) {
        id
        cart_item {
          id
          shop_key
          display_title
          image
          pricing
          points
          metadata
        }
      }
      all_collection_items: collection_items(
        where: { cart_item: { points: { _is_null: false } } }
        order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
        limit: $limit
      ) {
        id
        cart_item {
          id
          shop_key
          display_title
          image
          pricing
          points
          metadata
        }
      }
    }
  }
`

/**
 * __useGetDiscountedForYouMobileQuery__
 *
 * To run a query within a React component, call `useGetDiscountedForYouMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountedForYouMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountedForYouMobileQuery({
 *   variables: {
 *      categories_override: // value for 'categories_override'
 *      shop_categories: // value for 'shop_categories'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDiscountedForYouMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDiscountedForYouMobileQuery,
    GetDiscountedForYouMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDiscountedForYouMobileQuery,
    GetDiscountedForYouMobileQueryVariables
  >(GetDiscountedForYouMobileDocument, options)
}
export function useGetDiscountedForYouMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscountedForYouMobileQuery,
    GetDiscountedForYouMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDiscountedForYouMobileQuery,
    GetDiscountedForYouMobileQueryVariables
  >(GetDiscountedForYouMobileDocument, options)
}
export type GetDiscountedForYouMobileQueryHookResult = ReturnType<
  typeof useGetDiscountedForYouMobileQuery
>
export type GetDiscountedForYouMobileLazyQueryHookResult = ReturnType<
  typeof useGetDiscountedForYouMobileLazyQuery
>
export type GetDiscountedForYouMobileQueryResult = Apollo.QueryResult<
  GetDiscountedForYouMobileQuery,
  GetDiscountedForYouMobileQueryVariables
>
export const GetDiscoverDataByUsernameDocument = gql`
  query GetDiscoverDataByUsername(
    $username: String!
    $offset: Int = 0
    $limit: Int = 10
    $collectionItemsCount: Int = 5
  ) @cached {
    collections: collection(
      where: {
        user: { username: { _eq: $username } }
        all: { _eq: false }
        computed_is_visible: { _eq: true }
      }
      offset: $offset
      limit: $limit
      order_by: [{ order: asc }, { updated_at: desc_nulls_last }]
    ) {
      id
      feed_item {
        ...discoveryFeedFeedItem
      }
    }
  }
  ${DiscoveryFeedFeedItemFragmentDoc}
`

/**
 * __useGetDiscoverDataByUsernameQuery__
 *
 * To run a query within a React component, call `useGetDiscoverDataByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoverDataByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoverDataByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *   },
 * });
 */
export function useGetDiscoverDataByUsernameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDiscoverDataByUsernameQuery,
    GetDiscoverDataByUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDiscoverDataByUsernameQuery,
    GetDiscoverDataByUsernameQueryVariables
  >(GetDiscoverDataByUsernameDocument, options)
}
export function useGetDiscoverDataByUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscoverDataByUsernameQuery,
    GetDiscoverDataByUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDiscoverDataByUsernameQuery,
    GetDiscoverDataByUsernameQueryVariables
  >(GetDiscoverDataByUsernameDocument, options)
}
export type GetDiscoverDataByUsernameQueryHookResult = ReturnType<
  typeof useGetDiscoverDataByUsernameQuery
>
export type GetDiscoverDataByUsernameLazyQueryHookResult = ReturnType<
  typeof useGetDiscoverDataByUsernameLazyQuery
>
export type GetDiscoverDataByUsernameQueryResult = Apollo.QueryResult<
  GetDiscoverDataByUsernameQuery,
  GetDiscoverDataByUsernameQueryVariables
>
export const GetDiscoveryCollectionFeedDocument = gql`
  query GetDiscoveryCollectionFeed(
    $limit: Int = 10
    $offset: Int = 0
    $sequentialId: Int!
    $collectionItemsCount: Int = 3
  ) {
    discovery_feed(
      offset: $offset
      order_by: { sequential_id: desc }
      limit: $limit
      where: {
        sequential_id: { _lte: $sequentialId }
        feed_item: { item_type: { _in: [collection, collection_cart_item] } }
      }
    ) {
      id
      sequential_id
      feed_item {
        ...discoveryFeedFeedItem
      }
    }
  }
  ${DiscoveryFeedFeedItemFragmentDoc}
`

/**
 * __useGetDiscoveryCollectionFeedQuery__
 *
 * To run a query within a React component, call `useGetDiscoveryCollectionFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoveryCollectionFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoveryCollectionFeedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sequentialId: // value for 'sequentialId'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *   },
 * });
 */
export function useGetDiscoveryCollectionFeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDiscoveryCollectionFeedQuery,
    GetDiscoveryCollectionFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDiscoveryCollectionFeedQuery,
    GetDiscoveryCollectionFeedQueryVariables
  >(GetDiscoveryCollectionFeedDocument, options)
}
export function useGetDiscoveryCollectionFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscoveryCollectionFeedQuery,
    GetDiscoveryCollectionFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDiscoveryCollectionFeedQuery,
    GetDiscoveryCollectionFeedQueryVariables
  >(GetDiscoveryCollectionFeedDocument, options)
}
export type GetDiscoveryCollectionFeedQueryHookResult = ReturnType<
  typeof useGetDiscoveryCollectionFeedQuery
>
export type GetDiscoveryCollectionFeedLazyQueryHookResult = ReturnType<
  typeof useGetDiscoveryCollectionFeedLazyQuery
>
export type GetDiscoveryCollectionFeedQueryResult = Apollo.QueryResult<
  GetDiscoveryCollectionFeedQuery,
  GetDiscoveryCollectionFeedQueryVariables
>
export const GetDiscoveryFeedDocument = gql`
  query GetDiscoveryFeed(
    $limit: Int = 10
    $offset: Int = 0
    $sequentialId: Int!
    $collectionItemsCount: Int = 5
  ) {
    discovery_feed(
      offset: $offset
      order_by: { sequential_id: desc }
      limit: $limit
      where: { sequential_id: { _lte: $sequentialId } }
    ) {
      id
      sequential_id
      feed_item {
        ...discoveryFeedFeedItem
      }
    }
  }
  ${DiscoveryFeedFeedItemFragmentDoc}
`

/**
 * __useGetDiscoveryFeedQuery__
 *
 * To run a query within a React component, call `useGetDiscoveryFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoveryFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoveryFeedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sequentialId: // value for 'sequentialId'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *   },
 * });
 */
export function useGetDiscoveryFeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDiscoveryFeedQuery,
    GetDiscoveryFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDiscoveryFeedQuery, GetDiscoveryFeedQueryVariables>(
    GetDiscoveryFeedDocument,
    options
  )
}
export function useGetDiscoveryFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscoveryFeedQuery,
    GetDiscoveryFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDiscoveryFeedQuery,
    GetDiscoveryFeedQueryVariables
  >(GetDiscoveryFeedDocument, options)
}
export type GetDiscoveryFeedQueryHookResult = ReturnType<
  typeof useGetDiscoveryFeedQuery
>
export type GetDiscoveryFeedLazyQueryHookResult = ReturnType<
  typeof useGetDiscoveryFeedLazyQuery
>
export type GetDiscoveryFeedQueryResult = Apollo.QueryResult<
  GetDiscoveryFeedQuery,
  GetDiscoveryFeedQueryVariables
>
export const GetDiscoveryLastSequentialIdDocument = gql`
  query GetDiscoveryLastSequentialId {
    discovery_feed(order_by: { sequential_id: desc }, limit: 1) {
      id
      sequential_id
    }
  }
`

/**
 * __useGetDiscoveryLastSequentialIdQuery__
 *
 * To run a query within a React component, call `useGetDiscoveryLastSequentialIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoveryLastSequentialIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoveryLastSequentialIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDiscoveryLastSequentialIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDiscoveryLastSequentialIdQuery,
    GetDiscoveryLastSequentialIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDiscoveryLastSequentialIdQuery,
    GetDiscoveryLastSequentialIdQueryVariables
  >(GetDiscoveryLastSequentialIdDocument, options)
}
export function useGetDiscoveryLastSequentialIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscoveryLastSequentialIdQuery,
    GetDiscoveryLastSequentialIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDiscoveryLastSequentialIdQuery,
    GetDiscoveryLastSequentialIdQueryVariables
  >(GetDiscoveryLastSequentialIdDocument, options)
}
export type GetDiscoveryLastSequentialIdQueryHookResult = ReturnType<
  typeof useGetDiscoveryLastSequentialIdQuery
>
export type GetDiscoveryLastSequentialIdLazyQueryHookResult = ReturnType<
  typeof useGetDiscoveryLastSequentialIdLazyQuery
>
export type GetDiscoveryLastSequentialIdQueryResult = Apollo.QueryResult<
  GetDiscoveryLastSequentialIdQuery,
  GetDiscoveryLastSequentialIdQueryVariables
>
export const GetFeaturedShopsForOnboardingDocument = gql`
  query GetFeaturedShopsForOnboarding {
    featured_shops_for_onboarding {
      key
      favicon
    }
  }
`

/**
 * __useGetFeaturedShopsForOnboardingQuery__
 *
 * To run a query within a React component, call `useGetFeaturedShopsForOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedShopsForOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedShopsForOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturedShopsForOnboardingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeaturedShopsForOnboardingQuery,
    GetFeaturedShopsForOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetFeaturedShopsForOnboardingQuery,
    GetFeaturedShopsForOnboardingQueryVariables
  >(GetFeaturedShopsForOnboardingDocument, options)
}
export function useGetFeaturedShopsForOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeaturedShopsForOnboardingQuery,
    GetFeaturedShopsForOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetFeaturedShopsForOnboardingQuery,
    GetFeaturedShopsForOnboardingQueryVariables
  >(GetFeaturedShopsForOnboardingDocument, options)
}
export type GetFeaturedShopsForOnboardingQueryHookResult = ReturnType<
  typeof useGetFeaturedShopsForOnboardingQuery
>
export type GetFeaturedShopsForOnboardingLazyQueryHookResult = ReturnType<
  typeof useGetFeaturedShopsForOnboardingLazyQuery
>
export type GetFeaturedShopsForOnboardingQueryResult = Apollo.QueryResult<
  GetFeaturedShopsForOnboardingQuery,
  GetFeaturedShopsForOnboardingQueryVariables
>
export const GetFeedItemsForStealTheirLookDocument = gql`
  query GetFeedItemsForStealTheirLook(
    $offset: Int = 0
    $limit: Int = 10
    $collectionItemsCount: Int = 5
    $loadCelebAccounts: Boolean = false
  ) {
    celeb_accounts: user(
      where: {
        username: { _regex: "\\\\.style" }
        email: { _regex: "accounts\\\\+" }
      }
    ) @include(if: $loadCelebAccounts) {
      ...basicUserData
    }
    feed_items_for_style_accounts(offset: $offset, limit: $limit) {
      ...discoveryFeedFeedItem
    }
  }
  ${BasicUserDataFragmentDoc}
  ${DiscoveryFeedFeedItemFragmentDoc}
`

/**
 * __useGetFeedItemsForStealTheirLookQuery__
 *
 * To run a query within a React component, call `useGetFeedItemsForStealTheirLookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedItemsForStealTheirLookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedItemsForStealTheirLookQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *      loadCelebAccounts: // value for 'loadCelebAccounts'
 *   },
 * });
 */
export function useGetFeedItemsForStealTheirLookQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeedItemsForStealTheirLookQuery,
    GetFeedItemsForStealTheirLookQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetFeedItemsForStealTheirLookQuery,
    GetFeedItemsForStealTheirLookQueryVariables
  >(GetFeedItemsForStealTheirLookDocument, options)
}
export function useGetFeedItemsForStealTheirLookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedItemsForStealTheirLookQuery,
    GetFeedItemsForStealTheirLookQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetFeedItemsForStealTheirLookQuery,
    GetFeedItemsForStealTheirLookQueryVariables
  >(GetFeedItemsForStealTheirLookDocument, options)
}
export type GetFeedItemsForStealTheirLookQueryHookResult = ReturnType<
  typeof useGetFeedItemsForStealTheirLookQuery
>
export type GetFeedItemsForStealTheirLookLazyQueryHookResult = ReturnType<
  typeof useGetFeedItemsForStealTheirLookLazyQuery
>
export type GetFeedItemsForStealTheirLookQueryResult = Apollo.QueryResult<
  GetFeedItemsForStealTheirLookQuery,
  GetFeedItemsForStealTheirLookQueryVariables
>
export const GetFeedItemsForUserDocument = gql`
  query GetFeedItemsForUser(
    $username: String!
    $offset: Int = 0
    $limit: Int = 10
    $collectionItemsCount: Int = 5
  ) @cached {
    feed_items_for_user(
      args: { in_username: $username }
      offset: $offset
      limit: $limit
      order_by: [
        { cart_item: { updated_at: desc_nulls_last } }
        { collection_cart_item: { updated_at: desc_nulls_last } }
        { collection: { cart_item_max_updated_at: desc_nulls_last } }
      ]
    ) {
      ...discoveryFeedFeedItem
    }
  }
  ${DiscoveryFeedFeedItemFragmentDoc}
`

/**
 * __useGetFeedItemsForUserQuery__
 *
 * To run a query within a React component, call `useGetFeedItemsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedItemsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedItemsForUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *   },
 * });
 */
export function useGetFeedItemsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeedItemsForUserQuery,
    GetFeedItemsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetFeedItemsForUserQuery,
    GetFeedItemsForUserQueryVariables
  >(GetFeedItemsForUserDocument, options)
}
export function useGetFeedItemsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedItemsForUserQuery,
    GetFeedItemsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetFeedItemsForUserQuery,
    GetFeedItemsForUserQueryVariables
  >(GetFeedItemsForUserDocument, options)
}
export type GetFeedItemsForUserQueryHookResult = ReturnType<
  typeof useGetFeedItemsForUserQuery
>
export type GetFeedItemsForUserLazyQueryHookResult = ReturnType<
  typeof useGetFeedItemsForUserLazyQuery
>
export type GetFeedItemsForUserQueryResult = Apollo.QueryResult<
  GetFeedItemsForUserQuery,
  GetFeedItemsForUserQueryVariables
>
export const GetFeedItemsForUserSubscriptionsDocument = gql`
  query GetFeedItemsForUserSubscriptions(
    $offset: Int = 0
    $limit: Int = 10
    $collectionItemsCount: Int = 5
    $id: String
    $includeSubscriptionUsers: Boolean = false
    $creatorsToExclude: [String!] = []
  ) {
    user_subscription(
      where: {
        user_id: { _eq: $id }
        creator_id: { _nin: $creatorsToExclude }
        status: { _eq: active }
      }
      limit: 100
      order_by: { created_at: desc_nulls_last }
    ) @include(if: $includeSubscriptionUsers) {
      creator {
        ...basicUserData
      }
    }
    feed_items_for_user_subscriptions(offset: $offset, limit: $limit) {
      ...discoveryFeedFeedItem
    }
  }
  ${BasicUserDataFragmentDoc}
  ${DiscoveryFeedFeedItemFragmentDoc}
`

/**
 * __useGetFeedItemsForUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetFeedItemsForUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedItemsForUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedItemsForUserSubscriptionsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *      id: // value for 'id'
 *      includeSubscriptionUsers: // value for 'includeSubscriptionUsers'
 *      creatorsToExclude: // value for 'creatorsToExclude'
 *   },
 * });
 */
export function useGetFeedItemsForUserSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeedItemsForUserSubscriptionsQuery,
    GetFeedItemsForUserSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetFeedItemsForUserSubscriptionsQuery,
    GetFeedItemsForUserSubscriptionsQueryVariables
  >(GetFeedItemsForUserSubscriptionsDocument, options)
}
export function useGetFeedItemsForUserSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedItemsForUserSubscriptionsQuery,
    GetFeedItemsForUserSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetFeedItemsForUserSubscriptionsQuery,
    GetFeedItemsForUserSubscriptionsQueryVariables
  >(GetFeedItemsForUserSubscriptionsDocument, options)
}
export type GetFeedItemsForUserSubscriptionsQueryHookResult = ReturnType<
  typeof useGetFeedItemsForUserSubscriptionsQuery
>
export type GetFeedItemsForUserSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useGetFeedItemsForUserSubscriptionsLazyQuery
>
export type GetFeedItemsForUserSubscriptionsQueryResult = Apollo.QueryResult<
  GetFeedItemsForUserSubscriptionsQuery,
  GetFeedItemsForUserSubscriptionsQueryVariables
>
export const GetHomeBlocksDocument = gql`
  query getHomeBlocks {
    mobile_home(
      where: { enabled: { _eq: true }, type: { _eq: "home" } }
      order_by: { order: asc_nulls_last }
    ) {
      id
      order
      asset_url
      link_url
    }
  }
`

/**
 * __useGetHomeBlocksQuery__
 *
 * To run a query within a React component, call `useGetHomeBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeBlocksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeBlocksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHomeBlocksQuery,
    GetHomeBlocksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetHomeBlocksQuery, GetHomeBlocksQueryVariables>(
    GetHomeBlocksDocument,
    options
  )
}
export function useGetHomeBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHomeBlocksQuery,
    GetHomeBlocksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetHomeBlocksQuery, GetHomeBlocksQueryVariables>(
    GetHomeBlocksDocument,
    options
  )
}
export type GetHomeBlocksQueryHookResult = ReturnType<
  typeof useGetHomeBlocksQuery
>
export type GetHomeBlocksLazyQueryHookResult = ReturnType<
  typeof useGetHomeBlocksLazyQuery
>
export type GetHomeBlocksQueryResult = Apollo.QueryResult<
  GetHomeBlocksQuery,
  GetHomeBlocksQueryVariables
>
export const GetMobileCollectionCollaboratorInvitesDocument = gql`
  query GetMobileCollectionCollaboratorInvites(
    $collectionId: uuid!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    invites: collection_invite(
      where: { collection_id: { _eq: $collectionId } }
      order_by: { status: desc, created_at: asc_nulls_last }
      limit: $limit
      offset: $offset
    ) {
      id
      status
      invitee_email
      invitee {
        ...basicUserData
      }
      collection_collaborator {
        id
        manager
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetMobileCollectionCollaboratorInvitesQuery__
 *
 * To run a query within a React component, call `useGetMobileCollectionCollaboratorInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileCollectionCollaboratorInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileCollectionCollaboratorInvitesQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetMobileCollectionCollaboratorInvitesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMobileCollectionCollaboratorInvitesQuery,
    GetMobileCollectionCollaboratorInvitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMobileCollectionCollaboratorInvitesQuery,
    GetMobileCollectionCollaboratorInvitesQueryVariables
  >(GetMobileCollectionCollaboratorInvitesDocument, options)
}
export function useGetMobileCollectionCollaboratorInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMobileCollectionCollaboratorInvitesQuery,
    GetMobileCollectionCollaboratorInvitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMobileCollectionCollaboratorInvitesQuery,
    GetMobileCollectionCollaboratorInvitesQueryVariables
  >(GetMobileCollectionCollaboratorInvitesDocument, options)
}
export type GetMobileCollectionCollaboratorInvitesQueryHookResult = ReturnType<
  typeof useGetMobileCollectionCollaboratorInvitesQuery
>
export type GetMobileCollectionCollaboratorInvitesLazyQueryHookResult =
  ReturnType<typeof useGetMobileCollectionCollaboratorInvitesLazyQuery>
export type GetMobileCollectionCollaboratorInvitesQueryResult =
  Apollo.QueryResult<
    GetMobileCollectionCollaboratorInvitesQuery,
    GetMobileCollectionCollaboratorInvitesQueryVariables
  >
export const GetOnboardingCategoriesDocument = gql`
  query GetOnboardingCategories(
    $block_list: [String!] = ["omnishop", "social_platform"]
  ) {
    shop_category(
      where: { _not: { value: { _in: $block_list } }, onboarding_category: {} }
      order_by: [
        { onboarding_category: { featured: desc_nulls_last } }
        { value: asc_nulls_last }
      ]
    ) {
      value
      label
      onboarding_category {
        featured
        image
      }
    }
  }
`

/**
 * __useGetOnboardingCategoriesQuery__
 *
 * To run a query within a React component, call `useGetOnboardingCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingCategoriesQuery({
 *   variables: {
 *      block_list: // value for 'block_list'
 *   },
 * });
 */
export function useGetOnboardingCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOnboardingCategoriesQuery,
    GetOnboardingCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOnboardingCategoriesQuery,
    GetOnboardingCategoriesQueryVariables
  >(GetOnboardingCategoriesDocument, options)
}
export function useGetOnboardingCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnboardingCategoriesQuery,
    GetOnboardingCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOnboardingCategoriesQuery,
    GetOnboardingCategoriesQueryVariables
  >(GetOnboardingCategoriesDocument, options)
}
export type GetOnboardingCategoriesQueryHookResult = ReturnType<
  typeof useGetOnboardingCategoriesQuery
>
export type GetOnboardingCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetOnboardingCategoriesLazyQuery
>
export type GetOnboardingCategoriesQueryResult = Apollo.QueryResult<
  GetOnboardingCategoriesQuery,
  GetOnboardingCategoriesQueryVariables
>
export const GetOnboardingCategoryBrandsDocument = gql`
  query GetOnboardingCategoryBrands(
    $categories: [String!]!
    $query: String = "%%"
    $searching: Boolean = false
    $limit: Int = 25
  ) {
    shop_popularity(
      where: {
        shop: {
          shop_categorizations: {
            shop_category: { value: { _in: $categories } }
          }
          parsed_take_rate: { _cast: { String: { _neq: "{}" } } }
          slug: { _neq: "factoryoutletstore" }
        }
      }
      limit: $limit
      order_by: [
        { shop: { display_name: asc } }
        { popularity_rank: desc_nulls_last }
      ]
    ) @skip(if: $searching) {
      shop {
        ...dealShopData
      }
    }
    search_shops(args: { search: $query }, limit: $limit)
      @include(if: $searching) {
      ...dealShopData
    }
  }
  ${DealShopDataFragmentDoc}
`

/**
 * __useGetOnboardingCategoryBrandsQuery__
 *
 * To run a query within a React component, call `useGetOnboardingCategoryBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingCategoryBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingCategoryBrandsQuery({
 *   variables: {
 *      categories: // value for 'categories'
 *      query: // value for 'query'
 *      searching: // value for 'searching'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetOnboardingCategoryBrandsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnboardingCategoryBrandsQuery,
    GetOnboardingCategoryBrandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOnboardingCategoryBrandsQuery,
    GetOnboardingCategoryBrandsQueryVariables
  >(GetOnboardingCategoryBrandsDocument, options)
}
export function useGetOnboardingCategoryBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnboardingCategoryBrandsQuery,
    GetOnboardingCategoryBrandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOnboardingCategoryBrandsQuery,
    GetOnboardingCategoryBrandsQueryVariables
  >(GetOnboardingCategoryBrandsDocument, options)
}
export type GetOnboardingCategoryBrandsQueryHookResult = ReturnType<
  typeof useGetOnboardingCategoryBrandsQuery
>
export type GetOnboardingCategoryBrandsLazyQueryHookResult = ReturnType<
  typeof useGetOnboardingCategoryBrandsLazyQuery
>
export type GetOnboardingCategoryBrandsQueryResult = Apollo.QueryResult<
  GetOnboardingCategoryBrandsQuery,
  GetOnboardingCategoryBrandsQueryVariables
>
export const GetPdpFromIdDocument = gql`
  query getPDPFromId($id: uuid!) {
    shop_item: shop_item_by_pk(id: $id) {
      ...itemData
      description
      shop {
        ...shopData
      }
    }
    cart_item: cart_item_by_pk(id: $id) {
      ...cartItemData
      creator_rating
      commentable_item_join {
        comments(limit: 2, order_by: { created_at: desc_nulls_last }) {
          id
          comment
          user {
            username
            picture
          }
        }
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      feed_item {
        ...feedItemData
      }
      item {
        ...itemData
        description
        shop {
          ...shopData
        }
      }
    }
  }
  ${ItemDataFragmentDoc}
  ${ShopDataFragmentDoc}
  ${CartItemDataFragmentDoc}
  ${FeedItemDataFragmentDoc}
`

/**
 * __useGetPdpFromIdQuery__
 *
 * To run a query within a React component, call `useGetPdpFromIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPdpFromIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPdpFromIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPdpFromIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPdpFromIdQuery,
    GetPdpFromIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPdpFromIdQuery, GetPdpFromIdQueryVariables>(
    GetPdpFromIdDocument,
    options
  )
}
export function useGetPdpFromIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPdpFromIdQuery,
    GetPdpFromIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPdpFromIdQuery, GetPdpFromIdQueryVariables>(
    GetPdpFromIdDocument,
    options
  )
}
export type GetPdpFromIdQueryHookResult = ReturnType<
  typeof useGetPdpFromIdQuery
>
export type GetPdpFromIdLazyQueryHookResult = ReturnType<
  typeof useGetPdpFromIdLazyQuery
>
export type GetPdpFromIdQueryResult = Apollo.QueryResult<
  GetPdpFromIdQuery,
  GetPdpFromIdQueryVariables
>
export const GetRandomDiscoveryFeedItemsDocument = gql`
  query GetRandomDiscoveryFeedItems(
    $limit: Int = 10
    $offset: Int = 0
    $seed: seed_float = "0.0"
    $excludedPreviousDiscoveryIds: _uuid = "{}"
    $collectionItemsCount: Int = 5
  ) {
    random_discovery_feed_items(
      args: {
        discover_feed_ids: $excludedPreviousDiscoveryIds
        offset: $offset
        seed: $seed
      }
      limit: $limit
    ) {
      id
      sequential_id
      feed_item {
        ...discoveryFeedFeedItem
      }
    }
  }
  ${DiscoveryFeedFeedItemFragmentDoc}
`

/**
 * __useGetRandomDiscoveryFeedItemsQuery__
 *
 * To run a query within a React component, call `useGetRandomDiscoveryFeedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRandomDiscoveryFeedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRandomDiscoveryFeedItemsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      seed: // value for 'seed'
 *      excludedPreviousDiscoveryIds: // value for 'excludedPreviousDiscoveryIds'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *   },
 * });
 */
export function useGetRandomDiscoveryFeedItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRandomDiscoveryFeedItemsQuery,
    GetRandomDiscoveryFeedItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetRandomDiscoveryFeedItemsQuery,
    GetRandomDiscoveryFeedItemsQueryVariables
  >(GetRandomDiscoveryFeedItemsDocument, options)
}
export function useGetRandomDiscoveryFeedItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRandomDiscoveryFeedItemsQuery,
    GetRandomDiscoveryFeedItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetRandomDiscoveryFeedItemsQuery,
    GetRandomDiscoveryFeedItemsQueryVariables
  >(GetRandomDiscoveryFeedItemsDocument, options)
}
export type GetRandomDiscoveryFeedItemsQueryHookResult = ReturnType<
  typeof useGetRandomDiscoveryFeedItemsQuery
>
export type GetRandomDiscoveryFeedItemsLazyQueryHookResult = ReturnType<
  typeof useGetRandomDiscoveryFeedItemsLazyQuery
>
export type GetRandomDiscoveryFeedItemsQueryResult = Apollo.QueryResult<
  GetRandomDiscoveryFeedItemsQuery,
  GetRandomDiscoveryFeedItemsQueryVariables
>
export const GetShopsForEarnCashbackDocument = gql`
  query GetShopsForEarnCashback(
    $shop_categories: [shop_category_enum!] = []
    $limit: Int = 10
  ) @cached {
    all_shops: shop(
      where: { featured: { _eq: true }, parsed_take_rate: { _neq: {} } }
      order_by: [{ updated_at: desc_nulls_last }]
      limit: $limit
    ) {
      id
      key
      display_name
      favicon
      parsed_take_rate
      points
      banner_image
      metadata
    }
  }
`

/**
 * __useGetShopsForEarnCashbackQuery__
 *
 * To run a query within a React component, call `useGetShopsForEarnCashbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopsForEarnCashbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopsForEarnCashbackQuery({
 *   variables: {
 *      shop_categories: // value for 'shop_categories'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetShopsForEarnCashbackQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetShopsForEarnCashbackQuery,
    GetShopsForEarnCashbackQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopsForEarnCashbackQuery,
    GetShopsForEarnCashbackQueryVariables
  >(GetShopsForEarnCashbackDocument, options)
}
export function useGetShopsForEarnCashbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopsForEarnCashbackQuery,
    GetShopsForEarnCashbackQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopsForEarnCashbackQuery,
    GetShopsForEarnCashbackQueryVariables
  >(GetShopsForEarnCashbackDocument, options)
}
export type GetShopsForEarnCashbackQueryHookResult = ReturnType<
  typeof useGetShopsForEarnCashbackQuery
>
export type GetShopsForEarnCashbackLazyQueryHookResult = ReturnType<
  typeof useGetShopsForEarnCashbackLazyQuery
>
export type GetShopsForEarnCashbackQueryResult = Apollo.QueryResult<
  GetShopsForEarnCashbackQuery,
  GetShopsForEarnCashbackQueryVariables
>
export const GetSimilarProductsHistoryMobileDocument = gql`
  query GetSimilarProductsHistoryMobile(
    $userId: String = ""
    $itemId: uuid!
    $imageUrl: String = ""
  ) {
    similar_products_history(
      where: {
        user_id: { _eq: $userId }
        _or: [
          { shop_item_id: { _eq: $itemId } }
          { cart_item_id: { _eq: $itemId } }
          { image_url: { _eq: $imageUrl } }
        ]
      }
      order_by: { updated_at: desc_nulls_last }
      limit: 1
    ) {
      id
    }
  }
`

/**
 * __useGetSimilarProductsHistoryMobileQuery__
 *
 * To run a query within a React component, call `useGetSimilarProductsHistoryMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimilarProductsHistoryMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimilarProductsHistoryMobileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      itemId: // value for 'itemId'
 *      imageUrl: // value for 'imageUrl'
 *   },
 * });
 */
export function useGetSimilarProductsHistoryMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSimilarProductsHistoryMobileQuery,
    GetSimilarProductsHistoryMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSimilarProductsHistoryMobileQuery,
    GetSimilarProductsHistoryMobileQueryVariables
  >(GetSimilarProductsHistoryMobileDocument, options)
}
export function useGetSimilarProductsHistoryMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSimilarProductsHistoryMobileQuery,
    GetSimilarProductsHistoryMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSimilarProductsHistoryMobileQuery,
    GetSimilarProductsHistoryMobileQueryVariables
  >(GetSimilarProductsHistoryMobileDocument, options)
}
export type GetSimilarProductsHistoryMobileQueryHookResult = ReturnType<
  typeof useGetSimilarProductsHistoryMobileQuery
>
export type GetSimilarProductsHistoryMobileLazyQueryHookResult = ReturnType<
  typeof useGetSimilarProductsHistoryMobileLazyQuery
>
export type GetSimilarProductsHistoryMobileQueryResult = Apollo.QueryResult<
  GetSimilarProductsHistoryMobileQuery,
  GetSimilarProductsHistoryMobileQueryVariables
>
export const GetStealTheirLooksDataDocument = gql`
  query GetStealTheirLooksData(
    $seed: seed_float = "0.0"
    $limit: Int = 20
    $include_seo_fields: Boolean = false
  ) {
    steal_their_looks_random(args: { seed: $seed }, limit: $limit) {
      ...collectionData
      preview_collection_items(limit: 1) {
        id
        cart_item {
          id
          item_image_override
          item {
            cached_image
          }
        }
      }
      user {
        id
        name
        username
      }
    }
  }
  ${CollectionDataFragmentDoc}
`

/**
 * __useGetStealTheirLooksDataQuery__
 *
 * To run a query within a React component, call `useGetStealTheirLooksDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStealTheirLooksDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStealTheirLooksDataQuery({
 *   variables: {
 *      seed: // value for 'seed'
 *      limit: // value for 'limit'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useGetStealTheirLooksDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStealTheirLooksDataQuery,
    GetStealTheirLooksDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetStealTheirLooksDataQuery,
    GetStealTheirLooksDataQueryVariables
  >(GetStealTheirLooksDataDocument, options)
}
export function useGetStealTheirLooksDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStealTheirLooksDataQuery,
    GetStealTheirLooksDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetStealTheirLooksDataQuery,
    GetStealTheirLooksDataQueryVariables
  >(GetStealTheirLooksDataDocument, options)
}
export type GetStealTheirLooksDataQueryHookResult = ReturnType<
  typeof useGetStealTheirLooksDataQuery
>
export type GetStealTheirLooksDataLazyQueryHookResult = ReturnType<
  typeof useGetStealTheirLooksDataLazyQuery
>
export type GetStealTheirLooksDataQueryResult = Apollo.QueryResult<
  GetStealTheirLooksDataQuery,
  GetStealTheirLooksDataQueryVariables
>
export const GetSubscriptionSuggestionDocument = gql`
  query GetSubscriptionSuggestion(
    $limit: Int = 10
    $offsetFriends: Int = 0
    $offsetMayKnow: Int = 0
  ) @cached(ttl: 300) {
    friends: subscriptionSuggestions(
      degreesOfSeparation: 1
      limit: $limit
      offset: $offsetFriends
    ) {
      contact_id
      mutual_connections
      degrees_of_separation
      user {
        id
        username
        picture
      }
    }
    peopleYouMayKnow: subscriptionSuggestions(
      degreesOfSeparation: 2
      limit: $limit
      offset: $offsetMayKnow
    ) {
      contact_id
      mutual_connections
      degrees_of_separation
      user {
        id
        username
        picture
      }
    }
  }
`

/**
 * __useGetSubscriptionSuggestionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionSuggestionQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offsetFriends: // value for 'offsetFriends'
 *      offsetMayKnow: // value for 'offsetMayKnow'
 *   },
 * });
 */
export function useGetSubscriptionSuggestionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionSuggestionQuery,
    GetSubscriptionSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSubscriptionSuggestionQuery,
    GetSubscriptionSuggestionQueryVariables
  >(GetSubscriptionSuggestionDocument, options)
}
export function useGetSubscriptionSuggestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionSuggestionQuery,
    GetSubscriptionSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSubscriptionSuggestionQuery,
    GetSubscriptionSuggestionQueryVariables
  >(GetSubscriptionSuggestionDocument, options)
}
export type GetSubscriptionSuggestionQueryHookResult = ReturnType<
  typeof useGetSubscriptionSuggestionQuery
>
export type GetSubscriptionSuggestionLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionSuggestionLazyQuery
>
export type GetSubscriptionSuggestionQueryResult = Apollo.QueryResult<
  GetSubscriptionSuggestionQuery,
  GetSubscriptionSuggestionQueryVariables
>
export const GetTopBrandDealsByShopMobileDocument = gql`
  query GetTopBrandDealsByShopMobile(
    $shop_categories: [shop_category_enum!] = []
    $types: [String!] = []
    $end_date: timestamptz = "now()"
    $limit: Int = 25
    $include_personal_shops: Boolean = true
    $include_all_shops: Boolean = true
  ) @cached {
    personalized_deals: fmtc_deal(
      where: {
        end_date: { _gte: $end_date }
        metadata: { _contains: { aTypes: $types } }
        shop: {
          shop_categorizations: { category: { _in: $shop_categories } }
          parsed_take_rate: { _cast: { String: { _neq: "{}" } } }
          slug: { _neq: "factoryoutletstore" }
        }
      }
      distinct_on: shop_key
      order_by: { rating: desc, shop_key: asc }
      limit: $limit
    ) {
      ...fmtcData
    }
    all_deals: fmtc_deal(
      where: {
        end_date: { _gte: $end_date }
        metadata: { _contains: { aTypes: $types } }
        shop: {
          _not: {
            shop_categorizations: { category: { _in: $shop_categories } }
          }
          parsed_take_rate: { _cast: { String: { _neq: "{}" } } }
          slug: { _neq: "factoryoutletstore" }
        }
      }
      distinct_on: shop_key
      order_by: { rating: desc, shop_key: asc }
      limit: $limit
    ) {
      ...fmtcData
    }
    personalized_results: shop_popularity_with_points(
      where: {
        shop: {
          shop_categorizations: { category: { _in: $shop_categories } }
          parsed_take_rate: { _cast: { String: { _neq: "{}" } } }
          slug: { _neq: "factoryoutletstore" }
        }
      }
      limit: $limit
      order_by: { popularity_rank: desc_nulls_last }
    ) @include(if: $include_personal_shops) {
      ...shopPopularityWithPointsData
    }
    all_results: shop_popularity_with_points(
      where: {
        _not: {
          shop: {
            shop_categorizations: { category: { _in: $shop_categories } }
            parsed_take_rate: { _cast: { String: { _neq: "{}" } } }
            slug: { _neq: "factoryoutletstore" }
          }
        }
      }
      limit: $limit
      order_by: { popularity_rank: desc_nulls_last }
    ) @include(if: $include_all_shops) {
      ...shopPopularityWithPointsData
    }
  }
  ${FmtcDataFragmentDoc}
  ${ShopPopularityWithPointsDataFragmentDoc}
`

/**
 * __useGetTopBrandDealsByShopMobileQuery__
 *
 * To run a query within a React component, call `useGetTopBrandDealsByShopMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopBrandDealsByShopMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopBrandDealsByShopMobileQuery({
 *   variables: {
 *      shop_categories: // value for 'shop_categories'
 *      types: // value for 'types'
 *      end_date: // value for 'end_date'
 *      limit: // value for 'limit'
 *      include_personal_shops: // value for 'include_personal_shops'
 *      include_all_shops: // value for 'include_all_shops'
 *   },
 * });
 */
export function useGetTopBrandDealsByShopMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTopBrandDealsByShopMobileQuery,
    GetTopBrandDealsByShopMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTopBrandDealsByShopMobileQuery,
    GetTopBrandDealsByShopMobileQueryVariables
  >(GetTopBrandDealsByShopMobileDocument, options)
}
export function useGetTopBrandDealsByShopMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTopBrandDealsByShopMobileQuery,
    GetTopBrandDealsByShopMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTopBrandDealsByShopMobileQuery,
    GetTopBrandDealsByShopMobileQueryVariables
  >(GetTopBrandDealsByShopMobileDocument, options)
}
export type GetTopBrandDealsByShopMobileQueryHookResult = ReturnType<
  typeof useGetTopBrandDealsByShopMobileQuery
>
export type GetTopBrandDealsByShopMobileLazyQueryHookResult = ReturnType<
  typeof useGetTopBrandDealsByShopMobileLazyQuery
>
export type GetTopBrandDealsByShopMobileQueryResult = Apollo.QueryResult<
  GetTopBrandDealsByShopMobileQuery,
  GetTopBrandDealsByShopMobileQueryVariables
>
export const GetTopBrandDealsMobileDocument = gql`
  query GetTopBrandDealsMobile(
    $types: [String!] = []
    $end_date: timestamptz = "now()"
    $limit: Int = 500
  ) @cached {
    all_deals: fmtc_deal(
      where: {
        end_date: { _gte: $end_date }
        metadata: { _contains: { aTypes: $types } }
        shop: {
          parsed_take_rate: { _cast: { String: { _neq: "{}" } } }
          slug: { _neq: "factoryoutletstore" }
        }
      }
      order_by: { rating: desc_nulls_last }
      limit: $limit
    ) {
      ...fmtcData
    }
  }
  ${FmtcDataFragmentDoc}
`

/**
 * __useGetTopBrandDealsMobileQuery__
 *
 * To run a query within a React component, call `useGetTopBrandDealsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopBrandDealsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopBrandDealsMobileQuery({
 *   variables: {
 *      types: // value for 'types'
 *      end_date: // value for 'end_date'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTopBrandDealsMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTopBrandDealsMobileQuery,
    GetTopBrandDealsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTopBrandDealsMobileQuery,
    GetTopBrandDealsMobileQueryVariables
  >(GetTopBrandDealsMobileDocument, options)
}
export function useGetTopBrandDealsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTopBrandDealsMobileQuery,
    GetTopBrandDealsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTopBrandDealsMobileQuery,
    GetTopBrandDealsMobileQueryVariables
  >(GetTopBrandDealsMobileDocument, options)
}
export type GetTopBrandDealsMobileQueryHookResult = ReturnType<
  typeof useGetTopBrandDealsMobileQuery
>
export type GetTopBrandDealsMobileLazyQueryHookResult = ReturnType<
  typeof useGetTopBrandDealsMobileLazyQuery
>
export type GetTopBrandDealsMobileQueryResult = Apollo.QueryResult<
  GetTopBrandDealsMobileQuery,
  GetTopBrandDealsMobileQueryVariables
>
export const GetTopCommissionShopsDocument = gql`
  query GetTopCommissionShops($user_id: String!) {
    pageview_event_summary(
      where: {
        user_id: { _eq: $user_id }
        shop: { parsed_take_rate: { _neq: {} }, points: { _is_null: false } }
      }
      order_by: { created_at: desc_nulls_last }
      limit: 10
    ) {
      key
      shop {
        id
        key
        display_name
        favicon
        parsed_take_rate
        points
      }
    }
    cart(
      where: {
        user_id: { _eq: $user_id }
        shop: { parsed_take_rate: { _neq: {} }, points: { _is_null: false } }
      }
      order_by: { last_cart_item_created_at: desc_nulls_last }
      limit: 10
    ) {
      id
      shop {
        id
        key
        display_name
        favicon
        parsed_take_rate
        points
      }
    }
    shop_popularity(
      where: {
        shop: { parsed_take_rate: { _neq: {} }, points: { _is_null: false } }
      }
      limit: 50
      order_by: { popularity_rank: desc_nulls_last }
    ) {
      key
      shop {
        id
        key
        slug
        display_name
        favicon
        parsed_take_rate
        points
      }
    }
  }
`

/**
 * __useGetTopCommissionShopsQuery__
 *
 * To run a query within a React component, call `useGetTopCommissionShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopCommissionShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopCommissionShopsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetTopCommissionShopsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTopCommissionShopsQuery,
    GetTopCommissionShopsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTopCommissionShopsQuery,
    GetTopCommissionShopsQueryVariables
  >(GetTopCommissionShopsDocument, options)
}
export function useGetTopCommissionShopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTopCommissionShopsQuery,
    GetTopCommissionShopsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTopCommissionShopsQuery,
    GetTopCommissionShopsQueryVariables
  >(GetTopCommissionShopsDocument, options)
}
export type GetTopCommissionShopsQueryHookResult = ReturnType<
  typeof useGetTopCommissionShopsQuery
>
export type GetTopCommissionShopsLazyQueryHookResult = ReturnType<
  typeof useGetTopCommissionShopsLazyQuery
>
export type GetTopCommissionShopsQueryResult = Apollo.QueryResult<
  GetTopCommissionShopsQuery,
  GetTopCommissionShopsQueryVariables
>
export const GetTopDealShopsMobileDocument = gql`
  query GetTopDealShopsMobile(
    $end_date: timestamptz = "now()"
    $limit: Int = 100
  ) @cached {
    all_deals: fmtc_deal(
      where: {
        end_date: { _gte: $end_date }
        shop: {
          parsed_take_rate: { _cast: { String: { _neq: "{}" } } }
          slug: { _neq: "factoryoutletstore" }
        }
      }
      distinct_on: shop_key
      order_by: [{ shop_key: asc }, { rating: desc_nulls_last }]
      limit: $limit
    ) {
      id
      shop {
        ...dealShopData
      }
    }
  }
  ${DealShopDataFragmentDoc}
`

/**
 * __useGetTopDealShopsMobileQuery__
 *
 * To run a query within a React component, call `useGetTopDealShopsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopDealShopsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopDealShopsMobileQuery({
 *   variables: {
 *      end_date: // value for 'end_date'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTopDealShopsMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTopDealShopsMobileQuery,
    GetTopDealShopsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTopDealShopsMobileQuery,
    GetTopDealShopsMobileQueryVariables
  >(GetTopDealShopsMobileDocument, options)
}
export function useGetTopDealShopsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTopDealShopsMobileQuery,
    GetTopDealShopsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTopDealShopsMobileQuery,
    GetTopDealShopsMobileQueryVariables
  >(GetTopDealShopsMobileDocument, options)
}
export type GetTopDealShopsMobileQueryHookResult = ReturnType<
  typeof useGetTopDealShopsMobileQuery
>
export type GetTopDealShopsMobileLazyQueryHookResult = ReturnType<
  typeof useGetTopDealShopsMobileLazyQuery
>
export type GetTopDealShopsMobileQueryResult = Apollo.QueryResult<
  GetTopDealShopsMobileQuery,
  GetTopDealShopsMobileQueryVariables
>
export const GetTrendingShopsDocument = gql`
  query GetTrendingShops($limit: Int = 20) {
    shop_popularity(
      where: { shop: { cover_image: { _is_null: false, _neq: "" } } }
      limit: $limit
      order_by: { popularity_rank: desc_nulls_last }
    ) {
      key
      popularity_rank
      shop {
        id
        key
        slug
        display_name
        cover_image
        favicon
        parsed_take_rate
      }
    }
  }
`

/**
 * __useGetTrendingShopsQuery__
 *
 * To run a query within a React component, call `useGetTrendingShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrendingShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrendingShopsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTrendingShopsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTrendingShopsQuery,
    GetTrendingShopsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTrendingShopsQuery, GetTrendingShopsQueryVariables>(
    GetTrendingShopsDocument,
    options
  )
}
export function useGetTrendingShopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTrendingShopsQuery,
    GetTrendingShopsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTrendingShopsQuery,
    GetTrendingShopsQueryVariables
  >(GetTrendingShopsDocument, options)
}
export type GetTrendingShopsQueryHookResult = ReturnType<
  typeof useGetTrendingShopsQuery
>
export type GetTrendingShopsLazyQueryHookResult = ReturnType<
  typeof useGetTrendingShopsLazyQuery
>
export type GetTrendingShopsQueryResult = Apollo.QueryResult<
  GetTrendingShopsQuery,
  GetTrendingShopsQueryVariables
>
export const GetSingleUnviewedFeaturedEventDocument = gql`
  query GetSingleUnviewedFeaturedEvent($userId: String!, $type: String!) {
    single_unread_notification_banner_item_for_user(
      args: { user_id: $userId, notifiable_type: $type }
    ) {
      id
      notifiable_id
      notifiable_type
      banner
      communication_template
    }
  }
`

/**
 * __useGetSingleUnviewedFeaturedEventQuery__
 *
 * To run a query within a React component, call `useGetSingleUnviewedFeaturedEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleUnviewedFeaturedEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleUnviewedFeaturedEventQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetSingleUnviewedFeaturedEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSingleUnviewedFeaturedEventQuery,
    GetSingleUnviewedFeaturedEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSingleUnviewedFeaturedEventQuery,
    GetSingleUnviewedFeaturedEventQueryVariables
  >(GetSingleUnviewedFeaturedEventDocument, options)
}
export function useGetSingleUnviewedFeaturedEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSingleUnviewedFeaturedEventQuery,
    GetSingleUnviewedFeaturedEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSingleUnviewedFeaturedEventQuery,
    GetSingleUnviewedFeaturedEventQueryVariables
  >(GetSingleUnviewedFeaturedEventDocument, options)
}
export type GetSingleUnviewedFeaturedEventQueryHookResult = ReturnType<
  typeof useGetSingleUnviewedFeaturedEventQuery
>
export type GetSingleUnviewedFeaturedEventLazyQueryHookResult = ReturnType<
  typeof useGetSingleUnviewedFeaturedEventLazyQuery
>
export type GetSingleUnviewedFeaturedEventQueryResult = Apollo.QueryResult<
  GetSingleUnviewedFeaturedEventQuery,
  GetSingleUnviewedFeaturedEventQueryVariables
>
export const GetUserCategoriesMobileDocument = gql`
  query GetUserCategoriesMobile {
    user_category {
      category
    }
  }
`

/**
 * __useGetUserCategoriesMobileQuery__
 *
 * To run a query within a React component, call `useGetUserCategoriesMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCategoriesMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCategoriesMobileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserCategoriesMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserCategoriesMobileQuery,
    GetUserCategoriesMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCategoriesMobileQuery,
    GetUserCategoriesMobileQueryVariables
  >(GetUserCategoriesMobileDocument, options)
}
export function useGetUserCategoriesMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCategoriesMobileQuery,
    GetUserCategoriesMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCategoriesMobileQuery,
    GetUserCategoriesMobileQueryVariables
  >(GetUserCategoriesMobileDocument, options)
}
export type GetUserCategoriesMobileQueryHookResult = ReturnType<
  typeof useGetUserCategoriesMobileQuery
>
export type GetUserCategoriesMobileLazyQueryHookResult = ReturnType<
  typeof useGetUserCategoriesMobileLazyQuery
>
export type GetUserCategoriesMobileQueryResult = Apollo.QueryResult<
  GetUserCategoriesMobileQuery,
  GetUserCategoriesMobileQueryVariables
>
export const GetUserCollectionsAggregatesDocument = gql`
  query GetUserCollectionsAggregates($userId: String!) {
    collections_all_aggregate: collection_aggregate(
      where: {
        all: { _eq: false }
        _or: [
          { user_id: { _eq: $userId } }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
      limit: 1000
    ) {
      aggregate {
        count
      }
    }
    collections_collaborating_aggregate: collection_aggregate(
      where: {
        _or: [
          {
            _and: [
              { user_id: { _eq: $userId } }
              { collection_type: { _eq: shared } }
            ]
          }
          {
            _and: [
              { user_id: { _eq: $userId } }
              { has_collaborators: { _eq: true } }
            ]
          }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    collections_followed_aggregate: user_items_followed_aggregate(
      where: { user_id: { _eq: $userId }, followable_item: { collection: {} } }
    ) {
      aggregate {
        count
      }
    }
    collections_visible_aggregate: collection_aggregate(
      where: {
        all: { _eq: false }
        user_id: { _eq: $userId }
        computed_is_visible: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
    collections_hidden_aggregate: collection_aggregate(
      where: {
        all: { _eq: false }
        user_id: { _eq: $userId }
        computed_is_visible: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

/**
 * __useGetUserCollectionsAggregatesQuery__
 *
 * To run a query within a React component, call `useGetUserCollectionsAggregatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCollectionsAggregatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCollectionsAggregatesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserCollectionsAggregatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCollectionsAggregatesQuery,
    GetUserCollectionsAggregatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCollectionsAggregatesQuery,
    GetUserCollectionsAggregatesQueryVariables
  >(GetUserCollectionsAggregatesDocument, options)
}
export function useGetUserCollectionsAggregatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCollectionsAggregatesQuery,
    GetUserCollectionsAggregatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCollectionsAggregatesQuery,
    GetUserCollectionsAggregatesQueryVariables
  >(GetUserCollectionsAggregatesDocument, options)
}
export type GetUserCollectionsAggregatesQueryHookResult = ReturnType<
  typeof useGetUserCollectionsAggregatesQuery
>
export type GetUserCollectionsAggregatesLazyQueryHookResult = ReturnType<
  typeof useGetUserCollectionsAggregatesLazyQuery
>
export type GetUserCollectionsAggregatesQueryResult = Apollo.QueryResult<
  GetUserCollectionsAggregatesQuery,
  GetUserCollectionsAggregatesQueryVariables
>
export const GetUserCollectionsMobileDocument = gql`
  query GetUserCollectionsMobile(
    $userId: String!
    $offset: Int = 0
    $limit: Int = 10
    $itemLimit: Int = 1
    $include_seo_fields: Boolean = false
  ) {
    collection_aggregate(
      where: {
        all: { _eq: false }
        _or: [
          { user_id: { _eq: $userId } }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    collections: collection(
      where: {
        all: { _eq: false }
        _or: [
          { user_id: { _eq: $userId } }
          { collection_collaborators: { user_id: { _eq: $userId } } }
        ]
      }
      order_by: [
        { cart_item_max_updated_at: desc_nulls_last }
        { updated_at: desc_nulls_last }
      ]
      limit: $limit
      offset: $offset
    ) {
      ...collectionPartialForCollectionList
    }
  }
  ${CollectionPartialForCollectionListFragmentDoc}
`

/**
 * __useGetUserCollectionsMobileQuery__
 *
 * To run a query within a React component, call `useGetUserCollectionsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCollectionsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCollectionsMobileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      itemLimit: // value for 'itemLimit'
 *      include_seo_fields: // value for 'include_seo_fields'
 *   },
 * });
 */
export function useGetUserCollectionsMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCollectionsMobileQuery,
    GetUserCollectionsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCollectionsMobileQuery,
    GetUserCollectionsMobileQueryVariables
  >(GetUserCollectionsMobileDocument, options)
}
export function useGetUserCollectionsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCollectionsMobileQuery,
    GetUserCollectionsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCollectionsMobileQuery,
    GetUserCollectionsMobileQueryVariables
  >(GetUserCollectionsMobileDocument, options)
}
export type GetUserCollectionsMobileQueryHookResult = ReturnType<
  typeof useGetUserCollectionsMobileQuery
>
export type GetUserCollectionsMobileLazyQueryHookResult = ReturnType<
  typeof useGetUserCollectionsMobileLazyQuery
>
export type GetUserCollectionsMobileQueryResult = Apollo.QueryResult<
  GetUserCollectionsMobileQuery,
  GetUserCollectionsMobileQueryVariables
>
export const GetUserDealHopsDocument = gql`
  query GetUserDealHops($user_id: String!, $limit: Int = 20) {
    similar_products_history_unique(
      args: { user_id_arg: $user_id }
      limit: $limit
    ) {
      id
      image_url
      similar_products
    }
  }
`

/**
 * __useGetUserDealHopsQuery__
 *
 * To run a query within a React component, call `useGetUserDealHopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDealHopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDealHopsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserDealHopsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserDealHopsQuery,
    GetUserDealHopsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserDealHopsQuery, GetUserDealHopsQueryVariables>(
    GetUserDealHopsDocument,
    options
  )
}
export function useGetUserDealHopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDealHopsQuery,
    GetUserDealHopsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserDealHopsQuery,
    GetUserDealHopsQueryVariables
  >(GetUserDealHopsDocument, options)
}
export type GetUserDealHopsQueryHookResult = ReturnType<
  typeof useGetUserDealHopsQuery
>
export type GetUserDealHopsLazyQueryHookResult = ReturnType<
  typeof useGetUserDealHopsLazyQuery
>
export type GetUserDealHopsQueryResult = Apollo.QueryResult<
  GetUserDealHopsQuery,
  GetUserDealHopsQueryVariables
>
export const GetUserNotificationPreferencesMobileDocument = gql`
  query GetUserNotificationPreferencesMobile {
    notification_preference {
      id
      communication_template
      allowed
      allowable_communication
    }
  }
`

/**
 * __useGetUserNotificationPreferencesMobileQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationPreferencesMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationPreferencesMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationPreferencesMobileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserNotificationPreferencesMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserNotificationPreferencesMobileQuery,
    GetUserNotificationPreferencesMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserNotificationPreferencesMobileQuery,
    GetUserNotificationPreferencesMobileQueryVariables
  >(GetUserNotificationPreferencesMobileDocument, options)
}
export function useGetUserNotificationPreferencesMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserNotificationPreferencesMobileQuery,
    GetUserNotificationPreferencesMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserNotificationPreferencesMobileQuery,
    GetUserNotificationPreferencesMobileQueryVariables
  >(GetUserNotificationPreferencesMobileDocument, options)
}
export type GetUserNotificationPreferencesMobileQueryHookResult = ReturnType<
  typeof useGetUserNotificationPreferencesMobileQuery
>
export type GetUserNotificationPreferencesMobileLazyQueryHookResult =
  ReturnType<typeof useGetUserNotificationPreferencesMobileLazyQuery>
export type GetUserNotificationPreferencesMobileQueryResult =
  Apollo.QueryResult<
    GetUserNotificationPreferencesMobileQuery,
    GetUserNotificationPreferencesMobileQueryVariables
  >
export const GetUserPointsSummaryMobileDocument = gql`
  query GetUserPointsSummaryMobile {
    user_points_summary {
      user_id
      total_points
      pending_points
      multiplier
    }
    user_points_redeemed_summary {
      user_id
      points_redeemed
    }
  }
`

/**
 * __useGetUserPointsSummaryMobileQuery__
 *
 * To run a query within a React component, call `useGetUserPointsSummaryMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPointsSummaryMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPointsSummaryMobileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPointsSummaryMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserPointsSummaryMobileQuery,
    GetUserPointsSummaryMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserPointsSummaryMobileQuery,
    GetUserPointsSummaryMobileQueryVariables
  >(GetUserPointsSummaryMobileDocument, options)
}
export function useGetUserPointsSummaryMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPointsSummaryMobileQuery,
    GetUserPointsSummaryMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserPointsSummaryMobileQuery,
    GetUserPointsSummaryMobileQueryVariables
  >(GetUserPointsSummaryMobileDocument, options)
}
export type GetUserPointsSummaryMobileQueryHookResult = ReturnType<
  typeof useGetUserPointsSummaryMobileQuery
>
export type GetUserPointsSummaryMobileLazyQueryHookResult = ReturnType<
  typeof useGetUserPointsSummaryMobileLazyQuery
>
export type GetUserPointsSummaryMobileQueryResult = Apollo.QueryResult<
  GetUserPointsSummaryMobileQuery,
  GetUserPointsSummaryMobileQueryVariables
>
export const GetUserPopularShopsDocument = gql`
  query GetUserPopularShops($limit: Int = 8) {
    shop_popularity(order_by: { popularity_rank: desc }, limit: $limit) {
      key
      shop {
        id
        key
        display_name
        favicon
        parsed_take_rate
        metadata
      }
    }
  }
`

/**
 * __useGetUserPopularShopsQuery__
 *
 * To run a query within a React component, call `useGetUserPopularShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPopularShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPopularShopsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserPopularShopsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserPopularShopsQuery,
    GetUserPopularShopsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserPopularShopsQuery,
    GetUserPopularShopsQueryVariables
  >(GetUserPopularShopsDocument, options)
}
export function useGetUserPopularShopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPopularShopsQuery,
    GetUserPopularShopsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserPopularShopsQuery,
    GetUserPopularShopsQueryVariables
  >(GetUserPopularShopsDocument, options)
}
export type GetUserPopularShopsQueryHookResult = ReturnType<
  typeof useGetUserPopularShopsQuery
>
export type GetUserPopularShopsLazyQueryHookResult = ReturnType<
  typeof useGetUserPopularShopsLazyQuery
>
export type GetUserPopularShopsQueryResult = Apollo.QueryResult<
  GetUserPopularShopsQuery,
  GetUserPopularShopsQueryVariables
>
export const GetUserRecentCartsDocument = gql`
  query GetUserRecentCarts($user_id: String!, $limit: Int = 8) {
    cart(
      where: { user_id: { _eq: $user_id } }
      limit: $limit
      order_by: [
        { last_cart_item_created_at: desc_nulls_last }
        { shop: { shop_popularity: { popularity_rank: desc_nulls_last } } }
        { cart_items_aggregate: { count: desc } }
      ]
    ) {
      id
      created_at
      cart_items_aggregate(
        where: {
          archived_at: { _is_null: true }
          deleted_at: { _is_null: true }
        }
      ) {
        aggregate {
          count
        }
      }
      shop {
        id
        key
        display_name
        favicon
        parsed_take_rate
        metadata
      }
    }
  }
`

/**
 * __useGetUserRecentCartsQuery__
 *
 * To run a query within a React component, call `useGetUserRecentCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRecentCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRecentCartsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserRecentCartsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserRecentCartsQuery,
    GetUserRecentCartsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserRecentCartsQuery,
    GetUserRecentCartsQueryVariables
  >(GetUserRecentCartsDocument, options)
}
export function useGetUserRecentCartsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRecentCartsQuery,
    GetUserRecentCartsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserRecentCartsQuery,
    GetUserRecentCartsQueryVariables
  >(GetUserRecentCartsDocument, options)
}
export type GetUserRecentCartsQueryHookResult = ReturnType<
  typeof useGetUserRecentCartsQuery
>
export type GetUserRecentCartsLazyQueryHookResult = ReturnType<
  typeof useGetUserRecentCartsLazyQuery
>
export type GetUserRecentCartsQueryResult = Apollo.QueryResult<
  GetUserRecentCartsQuery,
  GetUserRecentCartsQueryVariables
>
export const GetUserRecentSavesDocument = gql`
  query GetUserRecentSaves($user_id: String!, $limit: Int = 20) {
    cart_item(
      where: {
        cart: { user_id: { _eq: $user_id } }
        archived_at: { _is_null: true }
        deleted_at: { _is_null: true }
        source: { _neq: "deal_hop" }
      }
      order_by: { created_at: desc_nulls_last }
      limit: $limit
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`

/**
 * __useGetUserRecentSavesQuery__
 *
 * To run a query within a React component, call `useGetUserRecentSavesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRecentSavesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRecentSavesQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserRecentSavesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserRecentSavesQuery,
    GetUserRecentSavesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserRecentSavesQuery,
    GetUserRecentSavesQueryVariables
  >(GetUserRecentSavesDocument, options)
}
export function useGetUserRecentSavesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRecentSavesQuery,
    GetUserRecentSavesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserRecentSavesQuery,
    GetUserRecentSavesQueryVariables
  >(GetUserRecentSavesDocument, options)
}
export type GetUserRecentSavesQueryHookResult = ReturnType<
  typeof useGetUserRecentSavesQuery
>
export type GetUserRecentSavesLazyQueryHookResult = ReturnType<
  typeof useGetUserRecentSavesLazyQuery
>
export type GetUserRecentSavesQueryResult = Apollo.QueryResult<
  GetUserRecentSavesQuery,
  GetUserRecentSavesQueryVariables
>
export const GetUserRecentViewsDocument = gql`
  query GetUserRecentViews($user_id: String!, $limit: Int = 8) @cached {
    pageview_event_summary(
      where: { user_id: { _eq: $user_id } }
      limit: $limit
      order_by: { created_at: desc_nulls_last }
    ) {
      user_id
      key
      created_at
      count
      shop {
        id
        key
        display_name
        favicon
        parsed_take_rate
        metadata
      }
    }
  }
`

/**
 * __useGetUserRecentViewsQuery__
 *
 * To run a query within a React component, call `useGetUserRecentViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRecentViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRecentViewsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserRecentViewsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserRecentViewsQuery,
    GetUserRecentViewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserRecentViewsQuery,
    GetUserRecentViewsQueryVariables
  >(GetUserRecentViewsDocument, options)
}
export function useGetUserRecentViewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRecentViewsQuery,
    GetUserRecentViewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserRecentViewsQuery,
    GetUserRecentViewsQueryVariables
  >(GetUserRecentViewsDocument, options)
}
export type GetUserRecentViewsQueryHookResult = ReturnType<
  typeof useGetUserRecentViewsQuery
>
export type GetUserRecentViewsLazyQueryHookResult = ReturnType<
  typeof useGetUserRecentViewsLazyQuery
>
export type GetUserRecentViewsQueryResult = Apollo.QueryResult<
  GetUserRecentViewsQuery,
  GetUserRecentViewsQueryVariables
>
export const GetUserReferralsMobileDocument = gql`
  query GetUserReferralsMobile(
    $user_id: String!
    $limit: Int = 100
    $offset: Int = 0
  ) {
    user_referrals: user_referral(
      where: { referrer_user_id: { _eq: $user_id } }
      limit: $limit
      offset: $offset
      order_by: { referred_user: { can_be_credited: desc_nulls_last } }
    ) {
      id
      reward_amount
      referred_user {
        id
        display_name
        username
        picture
        created_at
        can_be_credited
        ios_version
        safari_extension_version
        extension_version
      }
    }
  }
`

/**
 * __useGetUserReferralsMobileQuery__
 *
 * To run a query within a React component, call `useGetUserReferralsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserReferralsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserReferralsMobileQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetUserReferralsMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserReferralsMobileQuery,
    GetUserReferralsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserReferralsMobileQuery,
    GetUserReferralsMobileQueryVariables
  >(GetUserReferralsMobileDocument, options)
}
export function useGetUserReferralsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserReferralsMobileQuery,
    GetUserReferralsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserReferralsMobileQuery,
    GetUserReferralsMobileQueryVariables
  >(GetUserReferralsMobileDocument, options)
}
export type GetUserReferralsMobileQueryHookResult = ReturnType<
  typeof useGetUserReferralsMobileQuery
>
export type GetUserReferralsMobileLazyQueryHookResult = ReturnType<
  typeof useGetUserReferralsMobileLazyQuery
>
export type GetUserReferralsMobileQueryResult = Apollo.QueryResult<
  GetUserReferralsMobileQuery,
  GetUserReferralsMobileQueryVariables
>
export const GetUserReferredCountsMobileDocument = gql`
  query GetUserReferredCountsMobile($user_id: String!) {
    user(where: { id: { _eq: $user_id } }) {
      id
      referral_count_total
      referral_count_within_promo_period
      referral_count_credited
    }
  }
`

/**
 * __useGetUserReferredCountsMobileQuery__
 *
 * To run a query within a React component, call `useGetUserReferredCountsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserReferredCountsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserReferredCountsMobileQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserReferredCountsMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserReferredCountsMobileQuery,
    GetUserReferredCountsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserReferredCountsMobileQuery,
    GetUserReferredCountsMobileQueryVariables
  >(GetUserReferredCountsMobileDocument, options)
}
export function useGetUserReferredCountsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserReferredCountsMobileQuery,
    GetUserReferredCountsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserReferredCountsMobileQuery,
    GetUserReferredCountsMobileQueryVariables
  >(GetUserReferredCountsMobileDocument, options)
}
export type GetUserReferredCountsMobileQueryHookResult = ReturnType<
  typeof useGetUserReferredCountsMobileQuery
>
export type GetUserReferredCountsMobileLazyQueryHookResult = ReturnType<
  typeof useGetUserReferredCountsMobileLazyQuery
>
export type GetUserReferredCountsMobileQueryResult = Apollo.QueryResult<
  GetUserReferredCountsMobileQuery,
  GetUserReferredCountsMobileQueryVariables
>
export const GetUserSelectedShoppingCategoriesDocument = gql`
  query GetUserSelectedShoppingCategories($user_id: String!) {
    user_category(where: { user_id: { _eq: $user_id } }) {
      category
    }
  }
`

/**
 * __useGetUserSelectedShoppingCategoriesQuery__
 *
 * To run a query within a React component, call `useGetUserSelectedShoppingCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSelectedShoppingCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSelectedShoppingCategoriesQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserSelectedShoppingCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserSelectedShoppingCategoriesQuery,
    GetUserSelectedShoppingCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserSelectedShoppingCategoriesQuery,
    GetUserSelectedShoppingCategoriesQueryVariables
  >(GetUserSelectedShoppingCategoriesDocument, options)
}
export function useGetUserSelectedShoppingCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSelectedShoppingCategoriesQuery,
    GetUserSelectedShoppingCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserSelectedShoppingCategoriesQuery,
    GetUserSelectedShoppingCategoriesQueryVariables
  >(GetUserSelectedShoppingCategoriesDocument, options)
}
export type GetUserSelectedShoppingCategoriesQueryHookResult = ReturnType<
  typeof useGetUserSelectedShoppingCategoriesQuery
>
export type GetUserSelectedShoppingCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetUserSelectedShoppingCategoriesLazyQuery
>
export type GetUserSelectedShoppingCategoriesQueryResult = Apollo.QueryResult<
  GetUserSelectedShoppingCategoriesQuery,
  GetUserSelectedShoppingCategoriesQueryVariables
>
export const GetUserSubsMobileDocument = gql`
  query GetUserSubsMobile($id: String, $offset: Int = 0, $limit: Int = 10) {
    user_subscription(
      where: { user_id: { _eq: $id }, status: { _eq: active } }
      order_by: { created_at: asc }
      offset: $offset
      limit: $limit
    ) {
      id
      subscription_type
      status
      plan
      created_at
      updated_at
      cancel_at
      creator {
        id
        name
        username
        picture
        verified
        has_billing_account
      }
    }
  }
`

/**
 * __useGetUserSubsMobileQuery__
 *
 * To run a query within a React component, call `useGetUserSubsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubsMobileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserSubsMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserSubsMobileQuery,
    GetUserSubsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserSubsMobileQuery,
    GetUserSubsMobileQueryVariables
  >(GetUserSubsMobileDocument, options)
}
export function useGetUserSubsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSubsMobileQuery,
    GetUserSubsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserSubsMobileQuery,
    GetUserSubsMobileQueryVariables
  >(GetUserSubsMobileDocument, options)
}
export type GetUserSubsMobileQueryHookResult = ReturnType<
  typeof useGetUserSubsMobileQuery
>
export type GetUserSubsMobileLazyQueryHookResult = ReturnType<
  typeof useGetUserSubsMobileLazyQuery
>
export type GetUserSubsMobileQueryResult = Apollo.QueryResult<
  GetUserSubsMobileQuery,
  GetUserSubsMobileQueryVariables
>
export const GetUserSubscribersMobileDocument = gql`
  query GetUserSubscribersMobile(
    $id: String!
    $collectionItemsCount: Int = 5
    $creatorsToExclude: [String!] = []
    $limit: Int = 10
  ) @cached {
    user_subscription(
      where: {
        user_id: { _eq: $id }
        creator_id: { _nin: $creatorsToExclude }
        status: { _eq: active }
      }
      limit: $limit
      order_by: { created_at: desc_nulls_last }
    ) {
      creator {
        ...basicUserData
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetUserSubscribersMobileQuery__
 *
 * To run a query within a React component, call `useGetUserSubscribersMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscribersMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscribersMobileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      collectionItemsCount: // value for 'collectionItemsCount'
 *      creatorsToExclude: // value for 'creatorsToExclude'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserSubscribersMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserSubscribersMobileQuery,
    GetUserSubscribersMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserSubscribersMobileQuery,
    GetUserSubscribersMobileQueryVariables
  >(GetUserSubscribersMobileDocument, options)
}
export function useGetUserSubscribersMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSubscribersMobileQuery,
    GetUserSubscribersMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserSubscribersMobileQuery,
    GetUserSubscribersMobileQueryVariables
  >(GetUserSubscribersMobileDocument, options)
}
export type GetUserSubscribersMobileQueryHookResult = ReturnType<
  typeof useGetUserSubscribersMobileQuery
>
export type GetUserSubscribersMobileLazyQueryHookResult = ReturnType<
  typeof useGetUserSubscribersMobileLazyQuery
>
export type GetUserSubscribersMobileQueryResult = Apollo.QueryResult<
  GetUserSubscribersMobileQuery,
  GetUserSubscribersMobileQueryVariables
>
export const GetUsersReferredMobileDocument = gql`
  query GetUsersReferredMobile {
    user_referral {
      id
      referred_user {
        ...basicUserData
        extension_version
        ios_version
        safari_extension_version
      }
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useGetUsersReferredMobileQuery__
 *
 * To run a query within a React component, call `useGetUsersReferredMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersReferredMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersReferredMobileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersReferredMobileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersReferredMobileQuery,
    GetUsersReferredMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUsersReferredMobileQuery,
    GetUsersReferredMobileQueryVariables
  >(GetUsersReferredMobileDocument, options)
}
export function useGetUsersReferredMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersReferredMobileQuery,
    GetUsersReferredMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUsersReferredMobileQuery,
    GetUsersReferredMobileQueryVariables
  >(GetUsersReferredMobileDocument, options)
}
export type GetUsersReferredMobileQueryHookResult = ReturnType<
  typeof useGetUsersReferredMobileQuery
>
export type GetUsersReferredMobileLazyQueryHookResult = ReturnType<
  typeof useGetUsersReferredMobileLazyQuery
>
export type GetUsersReferredMobileQueryResult = Apollo.QueryResult<
  GetUsersReferredMobileQuery,
  GetUsersReferredMobileQueryVariables
>
export const InsertUserActionMobileDocument = gql`
  mutation InsertUserActionMobile(
    $action: rewardable_action_names_enum!
    $reference_type: rewardable_item_type_enum
    $reference_id: String
  ) {
    insert_user_action_one(
      object: {
        action: $action
        reference_type: $reference_type
        reference_id: $reference_id
      }
      on_conflict: {
        constraint: user_action_user_id_action_rewardable_item_type_rewardable_item
        update_columns: action
      }
    ) {
      id
      points
    }
  }
`
export type InsertUserActionMobileMutationFn = Apollo.MutationFunction<
  InsertUserActionMobileMutation,
  InsertUserActionMobileMutationVariables
>

/**
 * __useInsertUserActionMobileMutation__
 *
 * To run a mutation, you first call `useInsertUserActionMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserActionMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserActionMobileMutation, { data, loading, error }] = useInsertUserActionMobileMutation({
 *   variables: {
 *      action: // value for 'action'
 *      reference_type: // value for 'reference_type'
 *      reference_id: // value for 'reference_id'
 *   },
 * });
 */
export function useInsertUserActionMobileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertUserActionMobileMutation,
    InsertUserActionMobileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertUserActionMobileMutation,
    InsertUserActionMobileMutationVariables
  >(InsertUserActionMobileDocument, options)
}
export type InsertUserActionMobileMutationHookResult = ReturnType<
  typeof useInsertUserActionMobileMutation
>
export type InsertUserActionMobileMutationResult =
  Apollo.MutationResult<InsertUserActionMobileMutation>
export type InsertUserActionMobileMutationOptions = Apollo.BaseMutationOptions<
  InsertUserActionMobileMutation,
  InsertUserActionMobileMutationVariables
>
export const InsertUserFeedbackDocument = gql`
  mutation InsertUserFeedback(
    $user_feedback_item_id: uuid = ""
    $user_feedback_type: user_feedback_type_enum = similar_products
    $reaction: Boolean = false
    $custom: String = ""
  ) {
    insert_user_feedback(
      objects: {
        reaction: $reaction
        custom: $custom
        user_feedback_type: $user_feedback_type
        user_feedback_item: {
          data: {
            item_id: $user_feedback_item_id
            item_type: $user_feedback_type
          }
          on_conflict: {
            constraint: user_feedback_item_pkey
            update_columns: [item_type]
          }
        }
      }
      on_conflict: {
        constraint: user_feedback_user_feedback_item_id_user_id_key
        update_columns: reaction
      }
    ) {
      returning {
        id
      }
    }
  }
`
export type InsertUserFeedbackMutationFn = Apollo.MutationFunction<
  InsertUserFeedbackMutation,
  InsertUserFeedbackMutationVariables
>

/**
 * __useInsertUserFeedbackMutation__
 *
 * To run a mutation, you first call `useInsertUserFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserFeedbackMutation, { data, loading, error }] = useInsertUserFeedbackMutation({
 *   variables: {
 *      user_feedback_item_id: // value for 'user_feedback_item_id'
 *      user_feedback_type: // value for 'user_feedback_type'
 *      reaction: // value for 'reaction'
 *      custom: // value for 'custom'
 *   },
 * });
 */
export function useInsertUserFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertUserFeedbackMutation,
    InsertUserFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertUserFeedbackMutation,
    InsertUserFeedbackMutationVariables
  >(InsertUserFeedbackDocument, options)
}
export type InsertUserFeedbackMutationHookResult = ReturnType<
  typeof useInsertUserFeedbackMutation
>
export type InsertUserFeedbackMutationResult =
  Apollo.MutationResult<InsertUserFeedbackMutation>
export type InsertUserFeedbackMutationOptions = Apollo.BaseMutationOptions<
  InsertUserFeedbackMutation,
  InsertUserFeedbackMutationVariables
>
export const PostCommentDocument = gql`
  mutation PostComment(
    $item_id: uuid!
    $item_type: commentable_item_type_enum!
    $comment: String!
  ) {
    insert_comment_one(
      object: {
        comment: $comment
        commentable_item_id: $item_id
        commentable_item_type: $item_type
      }
    ) {
      id
      sequential_id
      commentable_item_type
      commentable_item_id
      comment
      created_at
    }
  }
`
export type PostCommentMutationFn = Apollo.MutationFunction<
  PostCommentMutation,
  PostCommentMutationVariables
>

/**
 * __usePostCommentMutation__
 *
 * To run a mutation, you first call `usePostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCommentMutation, { data, loading, error }] = usePostCommentMutation({
 *   variables: {
 *      item_id: // value for 'item_id'
 *      item_type: // value for 'item_type'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function usePostCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PostCommentMutation,
    PostCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PostCommentMutation, PostCommentMutationVariables>(
    PostCommentDocument,
    options
  )
}
export type PostCommentMutationHookResult = ReturnType<
  typeof usePostCommentMutation
>
export type PostCommentMutationResult =
  Apollo.MutationResult<PostCommentMutation>
export type PostCommentMutationOptions = Apollo.BaseMutationOptions<
  PostCommentMutation,
  PostCommentMutationVariables
>
export const GetProductShopSlugLikesDocument = gql`
  query GetProductShopSlugLikes($shop_item_id: uuid, $limit: Int = 7)
  @cached(ttl: 300) {
    related_users_for_shop_item(
      args: { shop_item_id: $shop_item_id }
      where: { internal_account: { _eq: false } }
      limit: $limit
    ) {
      id
      username
      picture
    }
  }
`

/**
 * __useGetProductShopSlugLikesQuery__
 *
 * To run a query within a React component, call `useGetProductShopSlugLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductShopSlugLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductShopSlugLikesQuery({
 *   variables: {
 *      shop_item_id: // value for 'shop_item_id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProductShopSlugLikesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductShopSlugLikesQuery,
    GetProductShopSlugLikesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetProductShopSlugLikesQuery,
    GetProductShopSlugLikesQueryVariables
  >(GetProductShopSlugLikesDocument, options)
}
export function useGetProductShopSlugLikesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductShopSlugLikesQuery,
    GetProductShopSlugLikesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetProductShopSlugLikesQuery,
    GetProductShopSlugLikesQueryVariables
  >(GetProductShopSlugLikesDocument, options)
}
export type GetProductShopSlugLikesQueryHookResult = ReturnType<
  typeof useGetProductShopSlugLikesQuery
>
export type GetProductShopSlugLikesLazyQueryHookResult = ReturnType<
  typeof useGetProductShopSlugLikesLazyQuery
>
export type GetProductShopSlugLikesQueryResult = Apollo.QueryResult<
  GetProductShopSlugLikesQuery,
  GetProductShopSlugLikesQueryVariables
>
export const SaveUserShoppingCategoriesDocument = gql`
  mutation SaveUserShoppingCategories(
    $user_id: String!
    $categories: [user_category_insert_input!]!
  ) {
    delete_user_category(where: { user_id: { _eq: $user_id } }) {
      affected_rows
    }
    insert_user_category(
      objects: $categories
      on_conflict: {
        constraint: user_category_user_id_category_key
        update_columns: []
      }
    ) {
      returning {
        id
      }
    }
  }
`
export type SaveUserShoppingCategoriesMutationFn = Apollo.MutationFunction<
  SaveUserShoppingCategoriesMutation,
  SaveUserShoppingCategoriesMutationVariables
>

/**
 * __useSaveUserShoppingCategoriesMutation__
 *
 * To run a mutation, you first call `useSaveUserShoppingCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserShoppingCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserShoppingCategoriesMutation, { data, loading, error }] = useSaveUserShoppingCategoriesMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useSaveUserShoppingCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserShoppingCategoriesMutation,
    SaveUserShoppingCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveUserShoppingCategoriesMutation,
    SaveUserShoppingCategoriesMutationVariables
  >(SaveUserShoppingCategoriesDocument, options)
}
export type SaveUserShoppingCategoriesMutationHookResult = ReturnType<
  typeof useSaveUserShoppingCategoriesMutation
>
export type SaveUserShoppingCategoriesMutationResult =
  Apollo.MutationResult<SaveUserShoppingCategoriesMutation>
export type SaveUserShoppingCategoriesMutationOptions =
  Apollo.BaseMutationOptions<
    SaveUserShoppingCategoriesMutation,
    SaveUserShoppingCategoriesMutationVariables
  >
export const SaveUserShoppingPreferenceDocument = gql`
  mutation SaveUserShoppingPreference(
    $id: String!
    $value: user_shopping_preferences_enum
  ) {
    user: update_user_by_pk(
      pk_columns: { id: $id }
      _set: { shopping_preference: $value }
    ) {
      id
      shopping_preference
    }
  }
`
export type SaveUserShoppingPreferenceMutationFn = Apollo.MutationFunction<
  SaveUserShoppingPreferenceMutation,
  SaveUserShoppingPreferenceMutationVariables
>

/**
 * __useSaveUserShoppingPreferenceMutation__
 *
 * To run a mutation, you first call `useSaveUserShoppingPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserShoppingPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserShoppingPreferenceMutation, { data, loading, error }] = useSaveUserShoppingPreferenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSaveUserShoppingPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserShoppingPreferenceMutation,
    SaveUserShoppingPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveUserShoppingPreferenceMutation,
    SaveUserShoppingPreferenceMutationVariables
  >(SaveUserShoppingPreferenceDocument, options)
}
export type SaveUserShoppingPreferenceMutationHookResult = ReturnType<
  typeof useSaveUserShoppingPreferenceMutation
>
export type SaveUserShoppingPreferenceMutationResult =
  Apollo.MutationResult<SaveUserShoppingPreferenceMutation>
export type SaveUserShoppingPreferenceMutationOptions =
  Apollo.BaseMutationOptions<
    SaveUserShoppingPreferenceMutation,
    SaveUserShoppingPreferenceMutationVariables
  >
export const SearchForShopsMobileDocument = gql`
  query SearchForShopsMobile($query: String!, $limit: Int = 10) @cached {
    shops: search_shops(args: { search: $query }, limit: $limit) {
      ...dealShopData
    }
  }
  ${DealShopDataFragmentDoc}
`

/**
 * __useSearchForShopsMobileQuery__
 *
 * To run a query within a React component, call `useSearchForShopsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchForShopsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchForShopsMobileQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchForShopsMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchForShopsMobileQuery,
    SearchForShopsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchForShopsMobileQuery,
    SearchForShopsMobileQueryVariables
  >(SearchForShopsMobileDocument, options)
}
export function useSearchForShopsMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchForShopsMobileQuery,
    SearchForShopsMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchForShopsMobileQuery,
    SearchForShopsMobileQueryVariables
  >(SearchForShopsMobileDocument, options)
}
export type SearchForShopsMobileQueryHookResult = ReturnType<
  typeof useSearchForShopsMobileQuery
>
export type SearchForShopsMobileLazyQueryHookResult = ReturnType<
  typeof useSearchForShopsMobileLazyQuery
>
export type SearchForShopsMobileQueryResult = Apollo.QueryResult<
  SearchForShopsMobileQuery,
  SearchForShopsMobileQueryVariables
>
export const SearchUsersForMobileDocument = gql`
  query SearchUsersForMobile($query: String!) {
    users: users_search(args: { search: $query }) {
      ...basicUserData
    }
  }
  ${BasicUserDataFragmentDoc}
`

/**
 * __useSearchUsersForMobileQuery__
 *
 * To run a query within a React component, call `useSearchUsersForMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersForMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersForMobileQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchUsersForMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchUsersForMobileQuery,
    SearchUsersForMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchUsersForMobileQuery,
    SearchUsersForMobileQueryVariables
  >(SearchUsersForMobileDocument, options)
}
export function useSearchUsersForMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUsersForMobileQuery,
    SearchUsersForMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchUsersForMobileQuery,
    SearchUsersForMobileQueryVariables
  >(SearchUsersForMobileDocument, options)
}
export type SearchUsersForMobileQueryHookResult = ReturnType<
  typeof useSearchUsersForMobileQuery
>
export type SearchUsersForMobileLazyQueryHookResult = ReturnType<
  typeof useSearchUsersForMobileLazyQuery
>
export type SearchUsersForMobileQueryResult = Apollo.QueryResult<
  SearchUsersForMobileQuery,
  SearchUsersForMobileQueryVariables
>
export const SubUserPointsRedeemedSummaryDocument = gql`
  subscription SubUserPointsRedeemedSummary {
    user_points_redeemed_summary {
      user_id
      points_redeemed
    }
  }
`

/**
 * __useSubUserPointsRedeemedSummarySubscription__
 *
 * To run a query within a React component, call `useSubUserPointsRedeemedSummarySubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubUserPointsRedeemedSummarySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubUserPointsRedeemedSummarySubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubUserPointsRedeemedSummarySubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubUserPointsRedeemedSummarySubscription,
    SubUserPointsRedeemedSummarySubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubUserPointsRedeemedSummarySubscription,
    SubUserPointsRedeemedSummarySubscriptionVariables
  >(SubUserPointsRedeemedSummaryDocument, options)
}
export type SubUserPointsRedeemedSummarySubscriptionHookResult = ReturnType<
  typeof useSubUserPointsRedeemedSummarySubscription
>
export type SubUserPointsRedeemedSummarySubscriptionResult =
  Apollo.SubscriptionResult<SubUserPointsRedeemedSummarySubscription>
export const SubUserPointsSummaryDocument = gql`
  subscription SubUserPointsSummary {
    user_points_summary {
      user_id
      total_points
      pending_points
    }
  }
`

/**
 * __useSubUserPointsSummarySubscription__
 *
 * To run a query within a React component, call `useSubUserPointsSummarySubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubUserPointsSummarySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubUserPointsSummarySubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubUserPointsSummarySubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubUserPointsSummarySubscription,
    SubUserPointsSummarySubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    SubUserPointsSummarySubscription,
    SubUserPointsSummarySubscriptionVariables
  >(SubUserPointsSummaryDocument, options)
}
export type SubUserPointsSummarySubscriptionHookResult = ReturnType<
  typeof useSubUserPointsSummarySubscription
>
export type SubUserPointsSummarySubscriptionResult =
  Apollo.SubscriptionResult<SubUserPointsSummarySubscription>
export const ToggleAutoCashbackMobileDocument = gql`
  mutation ToggleAutoCashbackMobile(
    $userId: String!
    $autoCashbackEnabled: Boolean = true
  ) {
    update_user_flags_by_pk(
      pk_columns: { user_id: $userId }
      _set: { auto_cashback_enabled: $autoCashbackEnabled }
    ) {
      user_id
      auto_cashback_enabled
    }
  }
`
export type ToggleAutoCashbackMobileMutationFn = Apollo.MutationFunction<
  ToggleAutoCashbackMobileMutation,
  ToggleAutoCashbackMobileMutationVariables
>

/**
 * __useToggleAutoCashbackMobileMutation__
 *
 * To run a mutation, you first call `useToggleAutoCashbackMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAutoCashbackMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAutoCashbackMobileMutation, { data, loading, error }] = useToggleAutoCashbackMobileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      autoCashbackEnabled: // value for 'autoCashbackEnabled'
 *   },
 * });
 */
export function useToggleAutoCashbackMobileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleAutoCashbackMobileMutation,
    ToggleAutoCashbackMobileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ToggleAutoCashbackMobileMutation,
    ToggleAutoCashbackMobileMutationVariables
  >(ToggleAutoCashbackMobileDocument, options)
}
export type ToggleAutoCashbackMobileMutationHookResult = ReturnType<
  typeof useToggleAutoCashbackMobileMutation
>
export type ToggleAutoCashbackMobileMutationResult =
  Apollo.MutationResult<ToggleAutoCashbackMobileMutation>
export type ToggleAutoCashbackMobileMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleAutoCashbackMobileMutation,
    ToggleAutoCashbackMobileMutationVariables
  >
export const UnsubscribeFromCreatorMobileDocument = gql`
  mutation UnsubscribeFromCreatorMobile($creatorId: String!) {
    fullyUnsubscribeFromCreator(creatorId: $creatorId) {
      user_subscription_id
    }
  }
`
export type UnsubscribeFromCreatorMobileMutationFn = Apollo.MutationFunction<
  UnsubscribeFromCreatorMobileMutation,
  UnsubscribeFromCreatorMobileMutationVariables
>

/**
 * __useUnsubscribeFromCreatorMobileMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromCreatorMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromCreatorMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromCreatorMobileMutation, { data, loading, error }] = useUnsubscribeFromCreatorMobileMutation({
 *   variables: {
 *      creatorId: // value for 'creatorId'
 *   },
 * });
 */
export function useUnsubscribeFromCreatorMobileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeFromCreatorMobileMutation,
    UnsubscribeFromCreatorMobileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnsubscribeFromCreatorMobileMutation,
    UnsubscribeFromCreatorMobileMutationVariables
  >(UnsubscribeFromCreatorMobileDocument, options)
}
export type UnsubscribeFromCreatorMobileMutationHookResult = ReturnType<
  typeof useUnsubscribeFromCreatorMobileMutation
>
export type UnsubscribeFromCreatorMobileMutationResult =
  Apollo.MutationResult<UnsubscribeFromCreatorMobileMutation>
export type UnsubscribeFromCreatorMobileMutationOptions =
  Apollo.BaseMutationOptions<
    UnsubscribeFromCreatorMobileMutation,
    UnsubscribeFromCreatorMobileMutationVariables
  >
export const UpdateCreatorCartItemMobileDocument = gql`
  mutation UpdateCreatorCartItemMobile(
    $id: uuid!
    $affiliate_redirect_link_override: String
    $item_image_override: String
    $item_title_override: String
    $item_description_override: String
    $item_pricing_override: jsonb
    $image_metadata: jsonb
    $item_video_url: String
    $item_video_orientation: String
  ) {
    update_cart_item_by_pk(
      pk_columns: { id: $id }
      _set: {
        affiliate_redirect_link_override: $affiliate_redirect_link_override
        item_image_override: $item_image_override
        item_title_override: $item_title_override
        item_description_override: $item_description_override
        item_pricing_override: $item_pricing_override
        image_metadata: $image_metadata
        item_video_url: $item_video_url
        item_video_orientation: $item_video_orientation
      }
    ) {
      ...cartItemData
    }
  }
  ${CartItemDataFragmentDoc}
`
export type UpdateCreatorCartItemMobileMutationFn = Apollo.MutationFunction<
  UpdateCreatorCartItemMobileMutation,
  UpdateCreatorCartItemMobileMutationVariables
>

/**
 * __useUpdateCreatorCartItemMobileMutation__
 *
 * To run a mutation, you first call `useUpdateCreatorCartItemMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreatorCartItemMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreatorCartItemMobileMutation, { data, loading, error }] = useUpdateCreatorCartItemMobileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      affiliate_redirect_link_override: // value for 'affiliate_redirect_link_override'
 *      item_image_override: // value for 'item_image_override'
 *      item_title_override: // value for 'item_title_override'
 *      item_description_override: // value for 'item_description_override'
 *      item_pricing_override: // value for 'item_pricing_override'
 *      image_metadata: // value for 'image_metadata'
 *      item_video_url: // value for 'item_video_url'
 *      item_video_orientation: // value for 'item_video_orientation'
 *   },
 * });
 */
export function useUpdateCreatorCartItemMobileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCreatorCartItemMobileMutation,
    UpdateCreatorCartItemMobileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCreatorCartItemMobileMutation,
    UpdateCreatorCartItemMobileMutationVariables
  >(UpdateCreatorCartItemMobileDocument, options)
}
export type UpdateCreatorCartItemMobileMutationHookResult = ReturnType<
  typeof useUpdateCreatorCartItemMobileMutation
>
export type UpdateCreatorCartItemMobileMutationResult =
  Apollo.MutationResult<UpdateCreatorCartItemMobileMutation>
export type UpdateCreatorCartItemMobileMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCreatorCartItemMobileMutation,
    UpdateCreatorCartItemMobileMutationVariables
  >
export const UpdateDealHopGateInvitedFlagDocument = gql`
  mutation UpdateDealHopGateInvitedFlag($userId: String!, $unlocked: Boolean!) {
    update_deal_hop_gate_by_pk(
      pk_columns: { user_id: $userId }
      _set: { deal_hop_invited: "now()", deal_hop_unlocked: $unlocked }
    ) {
      user_id
      deal_hop_invited
      deal_hop_gated
      paywall_gated_at
    }
  }
`
export type UpdateDealHopGateInvitedFlagMutationFn = Apollo.MutationFunction<
  UpdateDealHopGateInvitedFlagMutation,
  UpdateDealHopGateInvitedFlagMutationVariables
>

/**
 * __useUpdateDealHopGateInvitedFlagMutation__
 *
 * To run a mutation, you first call `useUpdateDealHopGateInvitedFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealHopGateInvitedFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealHopGateInvitedFlagMutation, { data, loading, error }] = useUpdateDealHopGateInvitedFlagMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      unlocked: // value for 'unlocked'
 *   },
 * });
 */
export function useUpdateDealHopGateInvitedFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDealHopGateInvitedFlagMutation,
    UpdateDealHopGateInvitedFlagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDealHopGateInvitedFlagMutation,
    UpdateDealHopGateInvitedFlagMutationVariables
  >(UpdateDealHopGateInvitedFlagDocument, options)
}
export type UpdateDealHopGateInvitedFlagMutationHookResult = ReturnType<
  typeof useUpdateDealHopGateInvitedFlagMutation
>
export type UpdateDealHopGateInvitedFlagMutationResult =
  Apollo.MutationResult<UpdateDealHopGateInvitedFlagMutation>
export type UpdateDealHopGateInvitedFlagMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDealHopGateInvitedFlagMutation,
    UpdateDealHopGateInvitedFlagMutationVariables
  >
export const UpdateDealHopInvitesDocument = gql`
  mutation UpdateDealHopInvites($userId: String!, $invites: jsonb!) {
    update_deal_hop_gate_by_pk(
      pk_columns: { user_id: $userId }
      _set: { deal_hop_invites: $invites }
    ) {
      user_id
      deal_hop_invites
    }
  }
`
export type UpdateDealHopInvitesMutationFn = Apollo.MutationFunction<
  UpdateDealHopInvitesMutation,
  UpdateDealHopInvitesMutationVariables
>

/**
 * __useUpdateDealHopInvitesMutation__
 *
 * To run a mutation, you first call `useUpdateDealHopInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealHopInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealHopInvitesMutation, { data, loading, error }] = useUpdateDealHopInvitesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      invites: // value for 'invites'
 *   },
 * });
 */
export function useUpdateDealHopInvitesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDealHopInvitesMutation,
    UpdateDealHopInvitesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDealHopInvitesMutation,
    UpdateDealHopInvitesMutationVariables
  >(UpdateDealHopInvitesDocument, options)
}
export type UpdateDealHopInvitesMutationHookResult = ReturnType<
  typeof useUpdateDealHopInvitesMutation
>
export type UpdateDealHopInvitesMutationResult =
  Apollo.MutationResult<UpdateDealHopInvitesMutation>
export type UpdateDealHopInvitesMutationOptions = Apollo.BaseMutationOptions<
  UpdateDealHopInvitesMutation,
  UpdateDealHopInvitesMutationVariables
>
export const UpdateDealHopPendingGateTimestampDocument = gql`
  mutation UpdateDealHopPendingGateTimestamp(
    $userId: String!
    $timestamp: timestamptz!
  ) {
    update_deal_hop_gate_by_pk(
      pk_columns: { user_id: $userId }
      _set: {
        deal_hop_gate_pending: null
        deal_hop_gated: $timestamp
        paywall_gated_at: $timestamp
      }
    ) {
      ...dealHopGateData
    }
  }
  ${DealHopGateDataFragmentDoc}
`
export type UpdateDealHopPendingGateTimestampMutationFn =
  Apollo.MutationFunction<
    UpdateDealHopPendingGateTimestampMutation,
    UpdateDealHopPendingGateTimestampMutationVariables
  >

/**
 * __useUpdateDealHopPendingGateTimestampMutation__
 *
 * To run a mutation, you first call `useUpdateDealHopPendingGateTimestampMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealHopPendingGateTimestampMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealHopPendingGateTimestampMutation, { data, loading, error }] = useUpdateDealHopPendingGateTimestampMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useUpdateDealHopPendingGateTimestampMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDealHopPendingGateTimestampMutation,
    UpdateDealHopPendingGateTimestampMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDealHopPendingGateTimestampMutation,
    UpdateDealHopPendingGateTimestampMutationVariables
  >(UpdateDealHopPendingGateTimestampDocument, options)
}
export type UpdateDealHopPendingGateTimestampMutationHookResult = ReturnType<
  typeof useUpdateDealHopPendingGateTimestampMutation
>
export type UpdateDealHopPendingGateTimestampMutationResult =
  Apollo.MutationResult<UpdateDealHopPendingGateTimestampMutation>
export type UpdateDealHopPendingGateTimestampMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDealHopPendingGateTimestampMutation,
    UpdateDealHopPendingGateTimestampMutationVariables
  >
export const UpdateUserNameAndPhotoDocument = gql`
  mutation UpdateUserNameAndPhoto(
    $id: String!
    $name: String!
    $picture: String
  ) {
    user: update_user_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, picture: $picture }
    ) {
      id
      name
      picture
    }
  }
`
export type UpdateUserNameAndPhotoMutationFn = Apollo.MutationFunction<
  UpdateUserNameAndPhotoMutation,
  UpdateUserNameAndPhotoMutationVariables
>

/**
 * __useUpdateUserNameAndPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateUserNameAndPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNameAndPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNameAndPhotoMutation, { data, loading, error }] = useUpdateUserNameAndPhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      picture: // value for 'picture'
 *   },
 * });
 */
export function useUpdateUserNameAndPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserNameAndPhotoMutation,
    UpdateUserNameAndPhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserNameAndPhotoMutation,
    UpdateUserNameAndPhotoMutationVariables
  >(UpdateUserNameAndPhotoDocument, options)
}
export type UpdateUserNameAndPhotoMutationHookResult = ReturnType<
  typeof useUpdateUserNameAndPhotoMutation
>
export type UpdateUserNameAndPhotoMutationResult =
  Apollo.MutationResult<UpdateUserNameAndPhotoMutation>
export type UpdateUserNameAndPhotoMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserNameAndPhotoMutation,
  UpdateUserNameAndPhotoMutationVariables
>
export const UpdateUserPhoneMobileDocument = gql`
  mutation UpdateUserPhoneMobile($id: String!, $phone: String!) {
    user: update_user_by_pk(pk_columns: { id: $id }, _set: { phone: $phone }) {
      id
      phone
    }
  }
`
export type UpdateUserPhoneMobileMutationFn = Apollo.MutationFunction<
  UpdateUserPhoneMobileMutation,
  UpdateUserPhoneMobileMutationVariables
>

/**
 * __useUpdateUserPhoneMobileMutation__
 *
 * To run a mutation, you first call `useUpdateUserPhoneMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPhoneMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPhoneMobileMutation, { data, loading, error }] = useUpdateUserPhoneMobileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateUserPhoneMobileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPhoneMobileMutation,
    UpdateUserPhoneMobileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserPhoneMobileMutation,
    UpdateUserPhoneMobileMutationVariables
  >(UpdateUserPhoneMobileDocument, options)
}
export type UpdateUserPhoneMobileMutationHookResult = ReturnType<
  typeof useUpdateUserPhoneMobileMutation
>
export type UpdateUserPhoneMobileMutationResult =
  Apollo.MutationResult<UpdateUserPhoneMobileMutation>
export type UpdateUserPhoneMobileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPhoneMobileMutation,
  UpdateUserPhoneMobileMutationVariables
>
export const InsertPhoneContactDocument = gql`
  mutation InsertPhoneContact($objects: [user_phone_contact_insert_input!]!) {
    insert_user_phone_contact(
      objects: $objects
      on_conflict: {
        constraint: user_phone_contact_inviter_id_contact_id_contact_info_type_cont
        update_columns: [invited]
      }
    ) {
      returning {
        id
        invited
        contact_id
        contact_info
        contact_info_type
        user_id
      }
    }
  }
`
export type InsertPhoneContactMutationFn = Apollo.MutationFunction<
  InsertPhoneContactMutation,
  InsertPhoneContactMutationVariables
>

/**
 * __useInsertPhoneContactMutation__
 *
 * To run a mutation, you first call `useInsertPhoneContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPhoneContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPhoneContactMutation, { data, loading, error }] = useInsertPhoneContactMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertPhoneContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertPhoneContactMutation,
    InsertPhoneContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertPhoneContactMutation,
    InsertPhoneContactMutationVariables
  >(InsertPhoneContactDocument, options)
}
export type InsertPhoneContactMutationHookResult = ReturnType<
  typeof useInsertPhoneContactMutation
>
export type InsertPhoneContactMutationResult =
  Apollo.MutationResult<InsertPhoneContactMutation>
export type InsertPhoneContactMutationOptions = Apollo.BaseMutationOptions<
  InsertPhoneContactMutation,
  InsertPhoneContactMutationVariables
>
export const UpsertUserNotificationPreferencesMobileDocument = gql`
  mutation UpsertUserNotificationPreferencesMobile(
    $args: upsert_notification_preference_args!
  ) {
    upsert_notification_preference(args: $args) {
      id
      user_id
      allowable_communication
      allowed
    }
  }
`
export type UpsertUserNotificationPreferencesMobileMutationFn =
  Apollo.MutationFunction<
    UpsertUserNotificationPreferencesMobileMutation,
    UpsertUserNotificationPreferencesMobileMutationVariables
  >

/**
 * __useUpsertUserNotificationPreferencesMobileMutation__
 *
 * To run a mutation, you first call `useUpsertUserNotificationPreferencesMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserNotificationPreferencesMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserNotificationPreferencesMobileMutation, { data, loading, error }] = useUpsertUserNotificationPreferencesMobileMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUpsertUserNotificationPreferencesMobileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserNotificationPreferencesMobileMutation,
    UpsertUserNotificationPreferencesMobileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertUserNotificationPreferencesMobileMutation,
    UpsertUserNotificationPreferencesMobileMutationVariables
  >(UpsertUserNotificationPreferencesMobileDocument, options)
}
export type UpsertUserNotificationPreferencesMobileMutationHookResult =
  ReturnType<typeof useUpsertUserNotificationPreferencesMobileMutation>
export type UpsertUserNotificationPreferencesMobileMutationResult =
  Apollo.MutationResult<UpsertUserNotificationPreferencesMobileMutation>
export type UpsertUserNotificationPreferencesMobileMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertUserNotificationPreferencesMobileMutation,
    UpsertUserNotificationPreferencesMobileMutationVariables
  >
export const WeightedSearchUsersForMobileDocument = gql`
  query WeightedSearchUsersForMobile($query: String!, $page: Int! = 1) {
    users: mention_search_users(
      args: { search_string: $query, page_size: 10, page_num: $page }
    ) {
      id
      name
      username
      verified
      picture
    }
  }
`

/**
 * __useWeightedSearchUsersForMobileQuery__
 *
 * To run a query within a React component, call `useWeightedSearchUsersForMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeightedSearchUsersForMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeightedSearchUsersForMobileQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useWeightedSearchUsersForMobileQuery(
  baseOptions: Apollo.QueryHookOptions<
    WeightedSearchUsersForMobileQuery,
    WeightedSearchUsersForMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    WeightedSearchUsersForMobileQuery,
    WeightedSearchUsersForMobileQueryVariables
  >(WeightedSearchUsersForMobileDocument, options)
}
export function useWeightedSearchUsersForMobileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WeightedSearchUsersForMobileQuery,
    WeightedSearchUsersForMobileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    WeightedSearchUsersForMobileQuery,
    WeightedSearchUsersForMobileQueryVariables
  >(WeightedSearchUsersForMobileDocument, options)
}
export type WeightedSearchUsersForMobileQueryHookResult = ReturnType<
  typeof useWeightedSearchUsersForMobileQuery
>
export type WeightedSearchUsersForMobileLazyQueryHookResult = ReturnType<
  typeof useWeightedSearchUsersForMobileLazyQuery
>
export type WeightedSearchUsersForMobileQueryResult = Apollo.QueryResult<
  WeightedSearchUsersForMobileQuery,
  WeightedSearchUsersForMobileQueryVariables
>
export const CreateNewCollectionForCanopyMigrationDocument = gql`
  mutation CreateNewCollectionForCanopyMigration(
    $collection_input: collection_insert_input!
  ) {
    insert_collection_one(
      object: $collection_input
      on_conflict: {
        constraint: collection_user_id_slug_key
        update_columns: [name, slug]
      }
    ) {
      id
      name
      collection_items_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`
export type CreateNewCollectionForCanopyMigrationMutationFn =
  Apollo.MutationFunction<
    CreateNewCollectionForCanopyMigrationMutation,
    CreateNewCollectionForCanopyMigrationMutationVariables
  >

/**
 * __useCreateNewCollectionForCanopyMigrationMutation__
 *
 * To run a mutation, you first call `useCreateNewCollectionForCanopyMigrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewCollectionForCanopyMigrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewCollectionForCanopyMigrationMutation, { data, loading, error }] = useCreateNewCollectionForCanopyMigrationMutation({
 *   variables: {
 *      collection_input: // value for 'collection_input'
 *   },
 * });
 */
export function useCreateNewCollectionForCanopyMigrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewCollectionForCanopyMigrationMutation,
    CreateNewCollectionForCanopyMigrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateNewCollectionForCanopyMigrationMutation,
    CreateNewCollectionForCanopyMigrationMutationVariables
  >(CreateNewCollectionForCanopyMigrationDocument, options)
}
export type CreateNewCollectionForCanopyMigrationMutationHookResult =
  ReturnType<typeof useCreateNewCollectionForCanopyMigrationMutation>
export type CreateNewCollectionForCanopyMigrationMutationResult =
  Apollo.MutationResult<CreateNewCollectionForCanopyMigrationMutation>
export type CreateNewCollectionForCanopyMigrationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateNewCollectionForCanopyMigrationMutation,
    CreateNewCollectionForCanopyMigrationMutationVariables
  >
export const GetUserForBeehiivDocument = gql`
  query GetUserForBeehiiv($id: String!) {
    user: user_by_pk(id: $id) {
      id
      email
      phone
      anonymous
      installed_at
      name
      onboarded
      orphaned_at
      orphaned_by
      picture
      role
      username
      has_claimed_username_computed
      extension_version
      ios_version
      safari_extension_version
      first_app_install
      created_at
      updated_at
      internal_account
      metadata
    }
  }
`

/**
 * __useGetUserForBeehiivQuery__
 *
 * To run a query within a React component, call `useGetUserForBeehiivQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForBeehiivQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForBeehiivQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserForBeehiivQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserForBeehiivQuery,
    GetUserForBeehiivQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserForBeehiivQuery,
    GetUserForBeehiivQueryVariables
  >(GetUserForBeehiivDocument, options)
}
export function useGetUserForBeehiivLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserForBeehiivQuery,
    GetUserForBeehiivQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserForBeehiivQuery,
    GetUserForBeehiivQueryVariables
  >(GetUserForBeehiivDocument, options)
}
export type GetUserForBeehiivQueryHookResult = ReturnType<
  typeof useGetUserForBeehiivQuery
>
export type GetUserForBeehiivLazyQueryHookResult = ReturnType<
  typeof useGetUserForBeehiivLazyQuery
>
export type GetUserForBeehiivQueryResult = Apollo.QueryResult<
  GetUserForBeehiivQuery,
  GetUserForBeehiivQueryVariables
>
export const GetFeaturedInDiscoveryFeedDataForPushNotificationDocument = gql`
  query GetFeaturedInDiscoveryFeedDataForPushNotification($id: uuid!) {
    discovery_feed_by_pk(id: $id) {
      feed_item {
        collection {
          id
          name
          user {
            ...userForPushNotificationData
          }
        }
      }
    }
  }
  ${UserForPushNotificationDataFragmentDoc}
`

/**
 * __useGetFeaturedInDiscoveryFeedDataForPushNotificationQuery__
 *
 * To run a query within a React component, call `useGetFeaturedInDiscoveryFeedDataForPushNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedInDiscoveryFeedDataForPushNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedInDiscoveryFeedDataForPushNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeaturedInDiscoveryFeedDataForPushNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeaturedInDiscoveryFeedDataForPushNotificationQuery,
    GetFeaturedInDiscoveryFeedDataForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetFeaturedInDiscoveryFeedDataForPushNotificationQuery,
    GetFeaturedInDiscoveryFeedDataForPushNotificationQueryVariables
  >(GetFeaturedInDiscoveryFeedDataForPushNotificationDocument, options)
}
export function useGetFeaturedInDiscoveryFeedDataForPushNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeaturedInDiscoveryFeedDataForPushNotificationQuery,
    GetFeaturedInDiscoveryFeedDataForPushNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetFeaturedInDiscoveryFeedDataForPushNotificationQuery,
    GetFeaturedInDiscoveryFeedDataForPushNotificationQueryVariables
  >(GetFeaturedInDiscoveryFeedDataForPushNotificationDocument, options)
}
export type GetFeaturedInDiscoveryFeedDataForPushNotificationQueryHookResult =
  ReturnType<typeof useGetFeaturedInDiscoveryFeedDataForPushNotificationQuery>
export type GetFeaturedInDiscoveryFeedDataForPushNotificationLazyQueryHookResult =
  ReturnType<
    typeof useGetFeaturedInDiscoveryFeedDataForPushNotificationLazyQuery
  >
export type GetFeaturedInDiscoveryFeedDataForPushNotificationQueryResult =
  Apollo.QueryResult<
    GetFeaturedInDiscoveryFeedDataForPushNotificationQuery,
    GetFeaturedInDiscoveryFeedDataForPushNotificationQueryVariables
  >
export const GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailDocument = gql`
  query GetSubscriberNotifiedOfNewPublishedCollectionDataForEmail($id: uuid!) {
    notification_center_item_by_pk(id: $id) {
      id
      user {
        ...userForEmailData
      }
      notifiable_item {
        collection {
          id
          name
          path
          collection_cover_images
          email_excerpt
          cart_items_count
          user {
            ...baseCreatorDataForEmail
          }
          media_enabled_join {
            associated_media {
              media_id
              media {
                id
                media_url
                media_type
              }
            }
          }
          preview_collection_items(limit: 9) {
            id
            cart_item {
              id
              item_image_override
              display_title
              item {
                id
                cached_image
                shop {
                  display_name
                }
              }
            }
          }
        }
      }
    }
  }
  ${UserForEmailDataFragmentDoc}
  ${BaseCreatorDataForEmailFragmentDoc}
`

/**
 * __useGetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQuery,
    GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQuery,
    GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQueryVariables
  >(GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailDocument, options)
}
export function useGetSubscriberNotifiedOfNewPublishedCollectionDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQuery,
    GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQuery,
    GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQueryVariables
  >(GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailDocument, options)
}
export type GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQueryHookResult =
  ReturnType<
    typeof useGetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQuery
  >
export type GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailLazyQueryHookResult =
  ReturnType<
    typeof useGetSubscriberNotifiedOfNewPublishedCollectionDataForEmailLazyQuery
  >
export type GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQueryResult =
  Apollo.QueryResult<
    GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQuery,
    GetSubscriberNotifiedOfNewPublishedCollectionDataForEmailQueryVariables
  >
export const GetUserCommentedDataForEmailDocument = gql`
  query GetUserCommentedDataForEmail($id: uuid!) {
    notification_center_item_by_pk(id: $id) {
      id
      user {
        ...userForEmailData
        username
      }
      notifiable_item {
        comment {
          commentable_item {
            cart_item {
              id
              display_title
              item_image_override
              item {
                cached_image
              }
            }
          }
          commenter: user {
            id
            username
            picture
          }
          comment
        }
      }
    }
  }
  ${UserForEmailDataFragmentDoc}
`

/**
 * __useGetUserCommentedDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetUserCommentedDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCommentedDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCommentedDataForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserCommentedDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCommentedDataForEmailQuery,
    GetUserCommentedDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserCommentedDataForEmailQuery,
    GetUserCommentedDataForEmailQueryVariables
  >(GetUserCommentedDataForEmailDocument, options)
}
export function useGetUserCommentedDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCommentedDataForEmailQuery,
    GetUserCommentedDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserCommentedDataForEmailQuery,
    GetUserCommentedDataForEmailQueryVariables
  >(GetUserCommentedDataForEmailDocument, options)
}
export type GetUserCommentedDataForEmailQueryHookResult = ReturnType<
  typeof useGetUserCommentedDataForEmailQuery
>
export type GetUserCommentedDataForEmailLazyQueryHookResult = ReturnType<
  typeof useGetUserCommentedDataForEmailLazyQuery
>
export type GetUserCommentedDataForEmailQueryResult = Apollo.QueryResult<
  GetUserCommentedDataForEmailQuery,
  GetUserCommentedDataForEmailQueryVariables
>
export const GetCollabCollectionItemsAddedDataForEmailDocument = gql`
  query GetCollabCollectionItemsAddedDataForEmail(
    $collectionId: uuid!
    $userId: String!
    $cartItemsAddedAfter: timestamptz!
  ) {
    collection_by_pk(id: $collectionId) {
      id
      name
      path
      metadata
      collection_cover_images
      email_excerpt
      user {
        ...baseCreatorDataForEmail
      }
      preview_collection_items(limit: 10) {
        id
        cart_item {
          id
          item_image_override
          display_title
          item {
            id
            cached_image
            shop {
              display_name
            }
          }
        }
      }
      recent_collection_items: viewable_collection_items(
        where: { created_at: { _gte: $cartItemsAddedAfter } }
        limit: 8
      ) {
        id
        cart_item {
          id
          item_image_override
          display_title
          item {
            id
            cached_image
            shop {
              display_name
            }
          }
          cart {
            user {
              id
              username
              picture
            }
          }
        }
      }
    }
    user_by_pk(id: $userId) {
      ...userForEmailData
      username
    }
  }
  ${BaseCreatorDataForEmailFragmentDoc}
  ${UserForEmailDataFragmentDoc}
`

/**
 * __useGetCollabCollectionItemsAddedDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetCollabCollectionItemsAddedDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollabCollectionItemsAddedDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollabCollectionItemsAddedDataForEmailQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      userId: // value for 'userId'
 *      cartItemsAddedAfter: // value for 'cartItemsAddedAfter'
 *   },
 * });
 */
export function useGetCollabCollectionItemsAddedDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollabCollectionItemsAddedDataForEmailQuery,
    GetCollabCollectionItemsAddedDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollabCollectionItemsAddedDataForEmailQuery,
    GetCollabCollectionItemsAddedDataForEmailQueryVariables
  >(GetCollabCollectionItemsAddedDataForEmailDocument, options)
}
export function useGetCollabCollectionItemsAddedDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollabCollectionItemsAddedDataForEmailQuery,
    GetCollabCollectionItemsAddedDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollabCollectionItemsAddedDataForEmailQuery,
    GetCollabCollectionItemsAddedDataForEmailQueryVariables
  >(GetCollabCollectionItemsAddedDataForEmailDocument, options)
}
export type GetCollabCollectionItemsAddedDataForEmailQueryHookResult =
  ReturnType<typeof useGetCollabCollectionItemsAddedDataForEmailQuery>
export type GetCollabCollectionItemsAddedDataForEmailLazyQueryHookResult =
  ReturnType<typeof useGetCollabCollectionItemsAddedDataForEmailLazyQuery>
export type GetCollabCollectionItemsAddedDataForEmailQueryResult =
  Apollo.QueryResult<
    GetCollabCollectionItemsAddedDataForEmailQuery,
    GetCollabCollectionItemsAddedDataForEmailQueryVariables
  >
export const GetCreatorSubscriberUpgradesToPaidDataForEmailDocument = gql`
  query GetCreatorSubscriberUpgradesToPaidDataForEmail($id: uuid!) {
    user_subscription_by_pk(id: $id) {
      creator {
        ...userForEmailData
      }
      subscriber {
        email
      }
    }
  }
  ${UserForEmailDataFragmentDoc}
`

/**
 * __useGetCreatorSubscriberUpgradesToPaidDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetCreatorSubscriberUpgradesToPaidDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatorSubscriberUpgradesToPaidDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatorSubscriberUpgradesToPaidDataForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCreatorSubscriberUpgradesToPaidDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreatorSubscriberUpgradesToPaidDataForEmailQuery,
    GetCreatorSubscriberUpgradesToPaidDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCreatorSubscriberUpgradesToPaidDataForEmailQuery,
    GetCreatorSubscriberUpgradesToPaidDataForEmailQueryVariables
  >(GetCreatorSubscriberUpgradesToPaidDataForEmailDocument, options)
}
export function useGetCreatorSubscriberUpgradesToPaidDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreatorSubscriberUpgradesToPaidDataForEmailQuery,
    GetCreatorSubscriberUpgradesToPaidDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCreatorSubscriberUpgradesToPaidDataForEmailQuery,
    GetCreatorSubscriberUpgradesToPaidDataForEmailQueryVariables
  >(GetCreatorSubscriberUpgradesToPaidDataForEmailDocument, options)
}
export type GetCreatorSubscriberUpgradesToPaidDataForEmailQueryHookResult =
  ReturnType<typeof useGetCreatorSubscriberUpgradesToPaidDataForEmailQuery>
export type GetCreatorSubscriberUpgradesToPaidDataForEmailLazyQueryHookResult =
  ReturnType<typeof useGetCreatorSubscriberUpgradesToPaidDataForEmailLazyQuery>
export type GetCreatorSubscriberUpgradesToPaidDataForEmailQueryResult =
  Apollo.QueryResult<
    GetCreatorSubscriberUpgradesToPaidDataForEmailQuery,
    GetCreatorSubscriberUpgradesToPaidDataForEmailQueryVariables
  >
export const GetSubscriberFullyUnsubscribesDataForEmailDocument = gql`
  query GetSubscriberFullyUnsubscribesDataForEmail($id: uuid!) {
    user_subscription_by_pk(id: $id) {
      subscriber {
        ...userForEmailData
      }
      creator {
        ...baseCreatorDataForEmail
      }
    }
  }
  ${UserForEmailDataFragmentDoc}
  ${BaseCreatorDataForEmailFragmentDoc}
`

/**
 * __useGetSubscriberFullyUnsubscribesDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetSubscriberFullyUnsubscribesDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriberFullyUnsubscribesDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriberFullyUnsubscribesDataForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubscriberFullyUnsubscribesDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriberFullyUnsubscribesDataForEmailQuery,
    GetSubscriberFullyUnsubscribesDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSubscriberFullyUnsubscribesDataForEmailQuery,
    GetSubscriberFullyUnsubscribesDataForEmailQueryVariables
  >(GetSubscriberFullyUnsubscribesDataForEmailDocument, options)
}
export function useGetSubscriberFullyUnsubscribesDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriberFullyUnsubscribesDataForEmailQuery,
    GetSubscriberFullyUnsubscribesDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSubscriberFullyUnsubscribesDataForEmailQuery,
    GetSubscriberFullyUnsubscribesDataForEmailQueryVariables
  >(GetSubscriberFullyUnsubscribesDataForEmailDocument, options)
}
export type GetSubscriberFullyUnsubscribesDataForEmailQueryHookResult =
  ReturnType<typeof useGetSubscriberFullyUnsubscribesDataForEmailQuery>
export type GetSubscriberFullyUnsubscribesDataForEmailLazyQueryHookResult =
  ReturnType<typeof useGetSubscriberFullyUnsubscribesDataForEmailLazyQuery>
export type GetSubscriberFullyUnsubscribesDataForEmailQueryResult =
  Apollo.QueryResult<
    GetSubscriberFullyUnsubscribesDataForEmailQuery,
    GetSubscriberFullyUnsubscribesDataForEmailQueryVariables
  >
export const GetSubscriberPaidSubscriptionEndedDataForEmailDocument = gql`
  query GetSubscriberPaidSubscriptionEndedDataForEmail($id: uuid!) {
    user_subscription_by_pk(id: $id) {
      subscriber {
        ...userForEmailData
      }
      creator {
        ...baseCreatorDataForEmail
      }
    }
  }
  ${UserForEmailDataFragmentDoc}
  ${BaseCreatorDataForEmailFragmentDoc}
`

/**
 * __useGetSubscriberPaidSubscriptionEndedDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetSubscriberPaidSubscriptionEndedDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriberPaidSubscriptionEndedDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriberPaidSubscriptionEndedDataForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubscriberPaidSubscriptionEndedDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriberPaidSubscriptionEndedDataForEmailQuery,
    GetSubscriberPaidSubscriptionEndedDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSubscriberPaidSubscriptionEndedDataForEmailQuery,
    GetSubscriberPaidSubscriptionEndedDataForEmailQueryVariables
  >(GetSubscriberPaidSubscriptionEndedDataForEmailDocument, options)
}
export function useGetSubscriberPaidSubscriptionEndedDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriberPaidSubscriptionEndedDataForEmailQuery,
    GetSubscriberPaidSubscriptionEndedDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSubscriberPaidSubscriptionEndedDataForEmailQuery,
    GetSubscriberPaidSubscriptionEndedDataForEmailQueryVariables
  >(GetSubscriberPaidSubscriptionEndedDataForEmailDocument, options)
}
export type GetSubscriberPaidSubscriptionEndedDataForEmailQueryHookResult =
  ReturnType<typeof useGetSubscriberPaidSubscriptionEndedDataForEmailQuery>
export type GetSubscriberPaidSubscriptionEndedDataForEmailLazyQueryHookResult =
  ReturnType<typeof useGetSubscriberPaidSubscriptionEndedDataForEmailLazyQuery>
export type GetSubscriberPaidSubscriptionEndedDataForEmailQueryResult =
  Apollo.QueryResult<
    GetSubscriberPaidSubscriptionEndedDataForEmailQuery,
    GetSubscriberPaidSubscriptionEndedDataForEmailQueryVariables
  >
export const GetSubscriberThankYouFromCreatorDataForEmailDocument = gql`
  query GetSubscriberThankYouFromCreatorDataForEmail($id: uuid!) {
    user_subscription_by_pk(id: $id) {
      creator_has_billing_account
      subscriber {
        ...userForEmailData
      }
      creator {
        ...baseCreatorDataForEmail
        is_icon_computed
      }
    }
  }
  ${UserForEmailDataFragmentDoc}
  ${BaseCreatorDataForEmailFragmentDoc}
`

/**
 * __useGetSubscriberThankYouFromCreatorDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetSubscriberThankYouFromCreatorDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriberThankYouFromCreatorDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriberThankYouFromCreatorDataForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubscriberThankYouFromCreatorDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriberThankYouFromCreatorDataForEmailQuery,
    GetSubscriberThankYouFromCreatorDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSubscriberThankYouFromCreatorDataForEmailQuery,
    GetSubscriberThankYouFromCreatorDataForEmailQueryVariables
  >(GetSubscriberThankYouFromCreatorDataForEmailDocument, options)
}
export function useGetSubscriberThankYouFromCreatorDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriberThankYouFromCreatorDataForEmailQuery,
    GetSubscriberThankYouFromCreatorDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSubscriberThankYouFromCreatorDataForEmailQuery,
    GetSubscriberThankYouFromCreatorDataForEmailQueryVariables
  >(GetSubscriberThankYouFromCreatorDataForEmailDocument, options)
}
export type GetSubscriberThankYouFromCreatorDataForEmailQueryHookResult =
  ReturnType<typeof useGetSubscriberThankYouFromCreatorDataForEmailQuery>
export type GetSubscriberThankYouFromCreatorDataForEmailLazyQueryHookResult =
  ReturnType<typeof useGetSubscriberThankYouFromCreatorDataForEmailLazyQuery>
export type GetSubscriberThankYouFromCreatorDataForEmailQueryResult =
  Apollo.QueryResult<
    GetSubscriberThankYouFromCreatorDataForEmailQuery,
    GetSubscriberThankYouFromCreatorDataForEmailQueryVariables
  >
export const GetSubscriberUpgradesToPaidSubscriptionDataForEmailDocument = gql`
  query GetSubscriberUpgradesToPaidSubscriptionDataForEmail($id: uuid!) {
    user_subscription_by_pk(id: $id) {
      subscriber {
        ...userForEmailData
      }
      creator {
        ...baseCreatorDataForEmail
      }
    }
  }
  ${UserForEmailDataFragmentDoc}
  ${BaseCreatorDataForEmailFragmentDoc}
`

/**
 * __useGetSubscriberUpgradesToPaidSubscriptionDataForEmailQuery__
 *
 * To run a query within a React component, call `useGetSubscriberUpgradesToPaidSubscriptionDataForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriberUpgradesToPaidSubscriptionDataForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriberUpgradesToPaidSubscriptionDataForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubscriberUpgradesToPaidSubscriptionDataForEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriberUpgradesToPaidSubscriptionDataForEmailQuery,
    GetSubscriberUpgradesToPaidSubscriptionDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSubscriberUpgradesToPaidSubscriptionDataForEmailQuery,
    GetSubscriberUpgradesToPaidSubscriptionDataForEmailQueryVariables
  >(GetSubscriberUpgradesToPaidSubscriptionDataForEmailDocument, options)
}
export function useGetSubscriberUpgradesToPaidSubscriptionDataForEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriberUpgradesToPaidSubscriptionDataForEmailQuery,
    GetSubscriberUpgradesToPaidSubscriptionDataForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSubscriberUpgradesToPaidSubscriptionDataForEmailQuery,
    GetSubscriberUpgradesToPaidSubscriptionDataForEmailQueryVariables
  >(GetSubscriberUpgradesToPaidSubscriptionDataForEmailDocument, options)
}
export type GetSubscriberUpgradesToPaidSubscriptionDataForEmailQueryHookResult =
  ReturnType<typeof useGetSubscriberUpgradesToPaidSubscriptionDataForEmailQuery>
export type GetSubscriberUpgradesToPaidSubscriptionDataForEmailLazyQueryHookResult =
  ReturnType<
    typeof useGetSubscriberUpgradesToPaidSubscriptionDataForEmailLazyQuery
  >
export type GetSubscriberUpgradesToPaidSubscriptionDataForEmailQueryResult =
  Apollo.QueryResult<
    GetSubscriberUpgradesToPaidSubscriptionDataForEmailQuery,
    GetSubscriberUpgradesToPaidSubscriptionDataForEmailQueryVariables
  >
export const GetCollectionForTypesenseSyncDocument = gql`
  query GetCollectionForTypesenseSync($id: uuid!) {
    collection_by_pk(id: $id) {
      name
      private
      all
      description
      metadata
      path
      seo_fields
      slug
      user {
        id
        username
      }
      collection_items(
        where: {
          cart_item: {
            deleted_at: { _is_null: true }
            archived_at: { _is_null: true }
          }
        }
      ) {
        cart_item {
          item_image_override
          item {
            title
            shop {
              name
              key
              shop_categorizations(
                where: {
                  shop_category: {
                    value: { _nin: ["omnishop", "social_platform", "travel"] }
                  }
                }
              ) {
                shop_category {
                  value
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetCollectionForTypesenseSyncQuery__
 *
 * To run a query within a React component, call `useGetCollectionForTypesenseSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionForTypesenseSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionForTypesenseSyncQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionForTypesenseSyncQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionForTypesenseSyncQuery,
    GetCollectionForTypesenseSyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCollectionForTypesenseSyncQuery,
    GetCollectionForTypesenseSyncQueryVariables
  >(GetCollectionForTypesenseSyncDocument, options)
}
export function useGetCollectionForTypesenseSyncLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionForTypesenseSyncQuery,
    GetCollectionForTypesenseSyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCollectionForTypesenseSyncQuery,
    GetCollectionForTypesenseSyncQueryVariables
  >(GetCollectionForTypesenseSyncDocument, options)
}
export type GetCollectionForTypesenseSyncQueryHookResult = ReturnType<
  typeof useGetCollectionForTypesenseSyncQuery
>
export type GetCollectionForTypesenseSyncLazyQueryHookResult = ReturnType<
  typeof useGetCollectionForTypesenseSyncLazyQuery
>
export type GetCollectionForTypesenseSyncQueryResult = Apollo.QueryResult<
  GetCollectionForTypesenseSyncQuery,
  GetCollectionForTypesenseSyncQueryVariables
>
export const GetShopItemForTypesenseSyncDocument = gql`
  query GetShopItemForTypesenseSync($id: uuid!) {
    shop_item_by_pk(id: $id) {
      display_title
      has_broken_image
      description
      source
      cached_image
      url
      availability
      pricing
      parsed_pricing
      enrichment_status
      categories
      keywords
      pdp_views
      metadata
      shop {
        name
        key
        shop_categorizations(
          where: {
            shop_category: {
              value: { _nin: ["omnishop", "social_platform", "travel"] }
            }
          }
        ) {
          shop_category {
            value
            label
          }
        }
      }
      number_of_collections
      cart_items_aggregate(
        where: {
          deleted_at: { _is_null: true }
          archived_at: { _is_null: true }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

/**
 * __useGetShopItemForTypesenseSyncQuery__
 *
 * To run a query within a React component, call `useGetShopItemForTypesenseSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemForTypesenseSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemForTypesenseSyncQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShopItemForTypesenseSyncQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopItemForTypesenseSyncQuery,
    GetShopItemForTypesenseSyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetShopItemForTypesenseSyncQuery,
    GetShopItemForTypesenseSyncQueryVariables
  >(GetShopItemForTypesenseSyncDocument, options)
}
export function useGetShopItemForTypesenseSyncLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopItemForTypesenseSyncQuery,
    GetShopItemForTypesenseSyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetShopItemForTypesenseSyncQuery,
    GetShopItemForTypesenseSyncQueryVariables
  >(GetShopItemForTypesenseSyncDocument, options)
}
export type GetShopItemForTypesenseSyncQueryHookResult = ReturnType<
  typeof useGetShopItemForTypesenseSyncQuery
>
export type GetShopItemForTypesenseSyncLazyQueryHookResult = ReturnType<
  typeof useGetShopItemForTypesenseSyncLazyQuery
>
export type GetShopItemForTypesenseSyncQueryResult = Apollo.QueryResult<
  GetShopItemForTypesenseSyncQuery,
  GetShopItemForTypesenseSyncQueryVariables
>
export const GetUserForTypesenseSyncDocument = gql`
  query GetUserForTypesenseSync($id: String!) {
    user_by_pk(id: $id) {
      id
      email
      name
      username
      picture
      verified
      icon_order
      is_creator_computed
      is_curator_computed
    }
  }
`

/**
 * __useGetUserForTypesenseSyncQuery__
 *
 * To run a query within a React component, call `useGetUserForTypesenseSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForTypesenseSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForTypesenseSyncQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserForTypesenseSyncQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserForTypesenseSyncQuery,
    GetUserForTypesenseSyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserForTypesenseSyncQuery,
    GetUserForTypesenseSyncQueryVariables
  >(GetUserForTypesenseSyncDocument, options)
}
export function useGetUserForTypesenseSyncLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserForTypesenseSyncQuery,
    GetUserForTypesenseSyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserForTypesenseSyncQuery,
    GetUserForTypesenseSyncQueryVariables
  >(GetUserForTypesenseSyncDocument, options)
}
export type GetUserForTypesenseSyncQueryHookResult = ReturnType<
  typeof useGetUserForTypesenseSyncQuery
>
export type GetUserForTypesenseSyncLazyQueryHookResult = ReturnType<
  typeof useGetUserForTypesenseSyncLazyQuery
>
export type GetUserForTypesenseSyncQueryResult = Apollo.QueryResult<
  GetUserForTypesenseSyncQuery,
  GetUserForTypesenseSyncQueryVariables
>
