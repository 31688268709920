import { useCallback } from 'react'
import type { FetchResult } from '@apollo/client'
import {
  CreateFreeCreatorSubscriptionMutation,
  useCreateFreeCreatorSubscriptionMutation,
} from '@carrotcart/app/data/subscribe/createFreeCreatorSubscription.generated'

const useSubscribeToCreator = (): {
  subscribeCallback: (
    userId: string
  ) => Promise<
    FetchResult<
      CreateFreeCreatorSubscriptionMutation,
      Record<string, any>,
      Record<string, any>
    >
  >
  subscribing: boolean
} => {
  const [subscribeMutation, { loading: subscribing }] =
    useCreateFreeCreatorSubscriptionMutation({
      fetchPolicy: 'no-cache',
    })

  const subscribeCallback = useCallback(
    async (userId: string) => {
      return await subscribeMutation({
        variables: {
          creatorId: userId,
        },
      })
    },
    [subscribeMutation]
  )

  return { subscribeCallback, subscribing }
}

export default useSubscribeToCreator
