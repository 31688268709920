import React from 'react'
import useAuthUser from '../hooks/useAuthUser'

interface ICreatorContext {
  cid: string | undefined
  uid: string | undefined
}

const defaultContext: ICreatorContext = {
  cid: undefined,
  uid: undefined,
}

export const CreatorContext = React.createContext(defaultContext)

export const useCreatorContext = (): ICreatorContext => {
  return React.useContext<ICreatorContext>(CreatorContext)
}

interface Props {
  cid: string
}

const CreatorProvider: React.FC<Props> = ({ children, cid }) => {
  const { authUser } = useAuthUser()
  const uid = authUser?.uid

  return (
    <CreatorContext.Provider
      value={{
        cid,
        uid,
      }}
    >
      {children}
    </CreatorContext.Provider>
  )
}

export default CreatorProvider
