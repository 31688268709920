import React from 'react'
import clsx from 'clsx'
import Image from 'next/legacy/image'
import imageKitLoader from '@carrotcart/client-common/lib/imageKitLoader'

export interface PictureAvatarProps {
  picture?: string | undefined | null
  bgColor?: string
  borderColor?: string
  rounded?: boolean
  border?: boolean
  borderStyle?: string
  shadow?: boolean
  size?:
    | 'default'
    | 'tiny'
    | 'xx-small'
    | 'small'
    | 'large'
    | 'x-large'
    | 'xx-large'
    | 'xxx-large'
    | 'menu'
    | 'og-image'
    | 'onboarding'
  className?: string
  style?: React.CSSProperties
  imgClassName?: string
  eagerLoad?: boolean
  customSize?: number
}

const PictureAvatar: React.FC<PictureAvatarProps> = ({
  children,
  picture,
  bgColor = 'bg-gray-200',
  borderColor = 'border-white',
  borderStyle = 'border-solid',
  rounded = true,
  shadow,
  size,
  className,
  style,
  imgClassName,
  eagerLoad,
  customSize,
}) => {
  let avatarSizeClasses = 'w-8 h-8'
  let avatarSize = 32

  if (customSize) {
    avatarSize = customSize
    avatarSizeClasses = ``
    style = {
      ...style,
      width: `${avatarSize}px`,
      height: `${avatarSize}px`,
    }
  }

  switch (size) {
    case 'xx-small':
      avatarSizeClasses = 'w-4 h-4'
      avatarSize = 14
      break
    case 'tiny':
      avatarSizeClasses = 'w-6 h-6'
      avatarSize = 24
      break
    case 'small':
      avatarSizeClasses = 'w-7 h-7'
      avatarSize = 25
      break
    case 'large':
      avatarSizeClasses = 'w-9 h-9'
      avatarSize = 36
      break
    case 'x-large':
      avatarSizeClasses = 'w-16 h-16'
      avatarSize = 64
      break
    case 'xx-large':
      avatarSizeClasses = 'w-28 h-28'
      avatarSize = 112
      break
    case 'xxx-large':
      avatarSizeClasses = 'w-64 h-64'
      avatarSize = 256
      break
    case 'menu':
      avatarSizeClasses = 'w-12 h-12'
      avatarSize = 48
      break
    case 'og-image':
      avatarSizeClasses = 'w-[194px] h-[194px]'
      avatarSize = 194
      break
    case 'onboarding':
      avatarSizeClasses = 'w-[150px] h-[150px]'
      avatarSize = 150
      break
    default:
      break
  }

  return (
    <div
      className={clsx(
        'flex justify-center items-center overflow-hidden',
        bgColor,
        borderColor,
        borderStyle,
        avatarSizeClasses,
        className,
        {
          'rounded-full border': rounded,
          'rounded-xl': !rounded,
          shadow: shadow,
        }
      )}
      style={style}
    >
      {children ? (
        children
      ) : picture ? (
        <Image
          loader={({ src }) =>
            imageKitLoader({ src, width: avatarSize * 2, rounded })
          }
          src={picture}
          // src={imageKitLoader({ src: picture, width: avatarSize * 2, rounded })}
          className={clsx('block', imgClassName)}
          width={avatarSize}
          height={avatarSize}
          priority={eagerLoad}
        />
      ) : (
        <div />
      )}
    </div>
  )
}

export default PictureAvatar
