import { useCallback, useEffect, useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import {
  GetCreatorSubscriptionsQuery,
  useGetCreatorSubscriptionsLazyQuery,
} from '@carrotcart/app/data/subscribe/getCreatorSubscriptions.generated'
import { BasicUserDataFragment } from '@carrotcart/data/generated'
import type { CreatorSubscriptionStatus } from '@carrotcart/common/types'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'

interface Props {
  creator: BasicUserDataFragment
  includeSubscriptions?: boolean
}

const useSubscriptionStatus = ({
  creator,
  includeSubscriptions,
}: Props): {
  creator: BasicUserDataFragment
  loading: boolean
  status: CreatorSubscriptionStatus
  hasBillingAccount: boolean
  subscriptions: GetCreatorSubscriptionsQuery['availableSubscriptions']
  refetch: () => Promise<ApolloQueryResult<GetCreatorSubscriptionsQuery>>
} => {
  const { persistedAuthUserId, loadingPersistedAuthUserId } = useAuthUser()
  const [subscriptionStatus, setSubscriptionStatus] = useState<{
    loading: boolean
    status: CreatorSubscriptionStatus
    hasBillingAccount: boolean
    subscriptions: GetCreatorSubscriptionsQuery['availableSubscriptions']
  }>({
    loading: false,
    status: undefined,
    hasBillingAccount: false,
    subscriptions: undefined,
  })

  const [query, { refetch }] = useGetCreatorSubscriptionsLazyQuery({
    variables: {
      id: creator?.id as string,
      include_subscriptions: includeSubscriptions,
    },
    fetchPolicy: 'no-cache',
  })

  const handleQueryResp = useCallback(
    (data: GetCreatorSubscriptionsQuery) => {
      if (!data) {
        setSubscriptionStatus((existingStatus) => ({
          ...existingStatus,
          loading: false,
        }))
        return
      }
      const { availableSubscriptions, creator } = data

      setSubscriptionStatus((existingStatus) => ({
        ...existingStatus,
        loading: false,
        status: persistedAuthUserId
          ? (creator?.subscription_status as CreatorSubscriptionStatus)
          : undefined,
        hasBillingAccount: creator?.has_billing_account,
        subscriptions: availableSubscriptions,
      }))
    },
    [persistedAuthUserId]
  )

  const refetchCreatorSubscriptions = async (): Promise<
    ApolloQueryResult<GetCreatorSubscriptionsQuery>
  > => {
    const resp = await refetch()
    handleQueryResp(resp.data)
    return resp
  }

  useEffect(() => {
    if (!persistedAuthUserId) return
    if (loadingPersistedAuthUserId) return
    if (subscriptionStatus?.status || persistedAuthUserId === creator?.id) {
      setSubscriptionStatus((existingStatus) => ({
        ...existingStatus,
        loading: false,
      }))
      return
    }

    ;(async () => {
      const resp = await query()
      handleQueryResp(resp.data)
    })()
  }, [
    handleQueryResp,
    query,
    creator?.id,
    persistedAuthUserId,
    subscriptionStatus?.status,
    includeSubscriptions,
    loadingPersistedAuthUserId,
  ])

  return {
    creator,
    ...subscriptionStatus,
    refetch: refetchCreatorSubscriptions,
  }
}

export default useSubscriptionStatus
